import React, { ReactNode } from 'react';
import FractionField from '../NewOptionFields/FractionField';
import { PortalShadeOption } from '../../Store/entities/assemblies/types';


type CoupledShadeTableProps = {
	bandCountOption: PortalShadeOption<number>,
	widthsOption: PortalShadeOption<number[]>,
	disabled: boolean
}


const CoupledShadeTable = ({
	bandCountOption,
	widthsOption,
	disabled
}: CoupledShadeTableProps) => {

	const widths = widthsOption.getValue();
	const bandCountOptions = bandCountOption.options?.map((option) => {
		return <option key={option.value} value={option.value}>{option.label}</option>
	});


	const rows = [] as ReactNode[];

	let shadeCount = 0;

	let first = true;

	const newWidth = (columnId: number, newWidth: number) => {
		const newWidths = widths.map((w, wI) => {
			return wI == (columnId) ? newWidth : w;
		})
		widthsOption.setValue(newWidths);

	}

	const newBandCount = (newBandCount: number) => {
		bandCountOption.setValue(newBandCount)
	}

	widths.forEach((si, columnCoordinate) => {
		if (first) {
			first = false;
			rows.push(
				<tr>
					<td style={{
						backgroundColor: "#8dc63f",
						color: 'white',
						borderRight: '2px solid gray'
					}}
					>
						Left Shade
					</td>
					<td>
						<div key={`holder-coupled-width-column-${columnCoordinate}`}
							className="td-couple-shades">
							<FractionField
								key={`coupled-width-column-${columnCoordinate}`}
								value={si}
								minValue={0}
								maxValue={240}
								disabled={disabled}
								varName={"coupled-width"}
								onBlur={(e) => {
									newWidth(columnCoordinate, e)
								}}
							/>
						</div>
					</td>

				</tr>
			);
			shadeCount++;
		} else {
			rows.push(
				<tr>
					<td>
						Shade
						{shadeCount + 1}
					</td>
					<td className={`coupled-shades-td`} >
						<div>
							<FractionField
								key={`coupled-width-column-${columnCoordinate}`}
								value={si}
								minValue={0}
								maxValue={240}
								disabled={disabled}
								varName={"coupled-width"}
								onBlur={(e) => {
									newWidth(columnCoordinate, e)
								}}
							/>
						</div>
					</td>

				</tr>
			);
			shadeCount++;
		}
	});

	const cleanValue = (value: number) => {
		// Check each 1/16th of an inch, and if it is within 1/32nd of an inch, make it match the closest 1/16th of an inch and return
		// The initial value starts at the whole value
		const init = value - (value % 1);
		for (let i = init; i <= 240; i += 1 / 16) {
			if (Math.abs(value - i) < 1 / 32) {
				return i;
			}
		}

		return value;
	}

	const totalWidth = widths.reduce((aggregate, width) => aggregate + cleanValue(width), 0);

	return (
		<div
			className="showing-description-modal coupled-table-wrapper"
			style={{
				display: "flex",
				overflow: "hidden",
				flexDirection: "column",
				border: "2px solid gray",
				borderRadius: ".5em",
			}}
		>
			<table
				className="coupled-shades"
				style={{
					width: "100%",
					borderCollapse: "collapse",
					borderRadius: ".25em",
					textAlign: "center",

				}}
			>
				<thead>
					<tr>
						<th style={{ padding: ".5em 2em .5em 2em" }}>
							<div>
								Band Count
								<select value={bandCountOption.getValue()} disabled={disabled} onChange={(e) => newBandCount(Number(e.target.value))}>
									{bandCountOptions}
								</select>
							</div>
						</th>
						<th>Width</th>
					</tr>
				</thead>
				<tbody style={{
					transform: 'translateY(0px)'
				}}
				>
					{rows}
					<tr>
						<td>Total Calculated Width: </td>
						<td>
							{totalWidth}
							{' '}
						</td>
					</tr>
				</tbody>
			</table>
		</div>

	);
}

export default CoupledShadeTable;
