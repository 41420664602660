// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChipContainer-module__chip___UQe6A{margin-left:5px}.ChipContainer-module__chip-header___hMIhm{font-size:.8em}.ChipContainer-module__chip-remover___vq36l{margin-left:.25em}.ChipContainer-module__chip-remover___vq36l:hover{transition:color .25s;cursor:pointer;color:#ffb7b6}.ChipContainer-module__chip-container___e4MuO{-webkit-user-select:none;-moz-user-select:none;user-select:none}`, "",{"version":3,"sources":["webpack://./src/Home/Filters/ChipContainer.module.scss","webpack://./src/assets/css/_variables-light.scss"],"names":[],"mappings":"AAEA,oCACC,eAAA,CAGD,2CACC,cAAA,CAGD,4CACC,iBAAA,CACA,kDACC,qBAAA,CACA,cAAA,CACA,aCKa,CDDf,8CACC,wBAAA,CAAA,qBAAA,CAAA,gBAAA","sourcesContent":["@import \"../../assets/css/variables-light.scss\";\n\n.chip {\n\tmargin-left: 5px;\n}\n\n.chip-header {\n\tfont-size: 0.8em;\n}\n\n.chip-remover {\n\tmargin-left: 0.25em;\n\t&:hover {\n\t\ttransition: color 0.25s;\n\t\tcursor: pointer;\n\t\tcolor: $ps-light-red;\n\t}\n}\n\n.chip-container {\n\tuser-select: none;\n}","$ps-green: #8dc63f;\n$ps-dark-green: #7fb239;\n$ps-darker-green: #72A033;\n$ps-gray: #6d6e71;\n$ps-light-gray: #a7a9ac;\n$ps-lighter-gray: #dedede;\n$ps-lightest-gray: #F2F2F2;\n$ps-dark-gray: #57585a;\n$mobile-threshold: 993px;\n$error-color: #e30000;\n$error-row-background-color: #ff00006e;\n$delete-red: #e60d0d;\n$pending-orange: #e37204;\n$ps-black: #020202;\n$ps-rma: #bb4430;\n$ps-bid: #9611d0;\n$ps-rework: #469e95;\n$ps-light-green: #ddeec5;\n$ps-light-blue: #b3e6f5;\n$ps-light-yellow: #fbe7c6;\n$ps-light-red: #ffb7b6;\n$ps-dark-red: #822f21;\n\n$seam-color: #f8dc3f;\n$sag-color: #f39422;\n$seam-sag-color: #e289dd;\n$large-outdoor-color: #5589e7;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chip": `ChipContainer-module__chip___UQe6A`,
	"chip-header": `ChipContainer-module__chip-header___hMIhm`,
	"chipHeader": `ChipContainer-module__chip-header___hMIhm`,
	"chip-remover": `ChipContainer-module__chip-remover___vq36l`,
	"chipRemover": `ChipContainer-module__chip-remover___vq36l`,
	"chip-container": `ChipContainer-module__chip-container___e4MuO`,
	"chipContainer": `ChipContainer-module__chip-container___e4MuO`
};
export default ___CSS_LOADER_EXPORT___;
