import { useState } from "react";
import Popup from "reactjs-popup";
import Button from "../../Parts/Button";
import Money from "../../Parts/Money";

const BidSpecControl = (props) => {
	const [open, setOpen] = useState(false);

	const shadeAssemblies = props.shadeAssemblies ?? [];

	const {
		setShadeBidData
	} = props;

	const rows = shadeAssemblies.map((sa) => (
		<BidSpecControlRow

			shade={sa}
			setShadeBidData={setShadeBidData}
		/>
	));

	const total = shadeAssemblies.reduce((total, sa) => total + sa.msrp ?? 0, 0);

	const labor = shadeAssemblies.reduce((total, sa) => total + sa.labor ?? 0, 0);

	const shipping = shadeAssemblies.reduce((total, sa) => total + sa.shipping ?? 0, 0);

	const overhead = shadeAssemblies.reduce((total, sa) => total + sa.overhead ?? 0, 0);
	const markup = shadeAssemblies.reduce((total, sa) => total + sa.markup ?? 0, 0);

	const distPrice = total * 0.425;

	const applyOverhead = (percentage) => {
		const percentageFloat = percentage / 100;

		// foreach assembly set the shade overhead cost.
		const amounts = [];
		shadeAssemblies
			.forEach((sa) => {
				const { sequence_id } = sa;
				const overhead = (sa.msrp + sa.labor) * 0.425 * percentageFloat;
				amounts.push({
					sequence_id: sequence_id,
					overhead,
				})
			});
		setShadeBidData({
			Amount_Sets: amounts,
			quote_id: props.quoteId,
		})
	};

	const applyMarkup = (percentage) => {
		const percentageFloat = percentage / 100;

		// foreach assembly set the shade overhead cost.
		shadeAssemblies
			.forEach((sa) => {
				const { sequenceId } = sa;
				const overhead = (sa.msrp + (sa.installation_labor_cost + sa.overhead ?? 0) + (sa.overhead ?? 0)) * 0.425 * percentageFloat;

				setShadeMarkup(sequenceId, overhead);
			});
	};

	return (
		<>

			<Popup
				open={open}
				onClose={() => setOpen(false)}
				closeOnDocumentClick={false}
				className="edit"
			>
				<>
					<div>
						<h2>BidSpec Control</h2>
						<div style={{ overflowX: 'scroll', maxHeight: '65vh' }}>
							<table>
								<tr>
									<th />
									<th />
									<th />
									<th />
									<th>
										<select
											onChange={(e) => {
												applyOverhead(e.target.value);
											}}
											value={0}
										>
											<option value={0}>Apply Percentage</option>
											<option value={5}>5%</option>
											<option value={10}>10%</option>
											<option value={15}>15%</option>
											<option value={20}>20%</option>
										</select>
									</th>
									<th>
										<select
											onChange={(e) => {
												applyMarkup(e.target.value);
											}}
											value={0}
										>
											<option value={0}>Apply Percentage</option>
											<option value={10}>10%</option>
											<option value={15}>15%</option>
											<option value={20}>20%</option>
											<option value={25}>25%</option>
											<option value={30}>30%</option>
											<option value={35}>35%</option>
											<option value={40}>40%</option>
											<option value={42.857}>30 % Gross</option>
											<option value={45}>45%</option>
											<option value={50}>50%</option>
										</select>
									</th>
									<th />
									<th />
								</tr>
								<tr>
									<th>Room Name</th>
									<th>Shade Name</th>
									<th>Distributor Sale Price</th>
									<th>Labor Cost</th>
									<th>Overhead</th>
									<th>Markup</th>
									<th>Actual Sale Price</th>
									<th>Actions</th>
								</tr>
								{rows}
								<tr>
									<th />
									<th>Totals:</th>
									<th><Money value={distPrice} /></th>
									<th><Money value={labor} /></th>
									<th><Money value={overhead} /></th>
									<th><Money value={markup} /></th>
									<th><Money value={markup + overhead + labor + distPrice} /></th>
									<th>Actions</th>
								</tr>
							</table>
						</div>
					</div>
					<br />

					<PriceRow>
						{`Distributor Cost : `}
						{' '}
						<Money value={distPrice} />
						{' '}
					</PriceRow>
					<PriceRow>
						{`Labor : `}
						{' '}
						<Money value={labor} />
						{' '}
					</PriceRow>
					<PriceRow>
						Overhead :
						{' '}
						<Money value={overhead} />
					</PriceRow>
					<PriceRow>
						{`Markup : `}
						{' '}
						<Money value={markup} />
					</PriceRow>
					<PriceRow>
						{`Final Price : `}
						{' '}
						<Money value={markup + overhead + labor + distPrice} />
					</PriceRow>

					<Button onClick={() => setOpen(false)}> Exit</Button>
				</>
			</Popup>

			<Button color={props?.quoteColor?.trim() ?? 'green'} fluid fullWidth onClick={() => setOpen(true)}>Bid Spec Control</Button>
		</>
	);
};

export default BidSpecControl;

const PriceRow = (props) => (

	<>
		<span style={{
			textAlign: 'right', display: 'inline-block', width: '100%'
		}}
		>
			{props.children}
		</span>
		{props.no_hr ? null : <hr />}
	</>
);

export const BidSpecControlRow = (props) => {
	const {
		shade,
		setShadeBidData
	} = props;

	const {
		msrp,
		room_name,
		shade_name,
		labor,
		sequence_id,
		overhead,
		markup,
		quote_id
	} = shades;

	const [editing, setEditing] = useState(false);
	const [laborLocal, setLaborLocal] = useState(installation_labor_cost);
	const [overheadLocal, setOverheadLocal] = useState(overhead);
	const [markupLocal, setMarkupLocal] = useState(markup);

	const saveLabor = () => {
		const laborFloat = parseFloat(laborLocal);

		const laborString = laborFloat.toString();

		if (laborString != laborLocal) {
			// User did not input a valid value
			setLaborLocal(installation_labor_cost);
		} else {
			// User did input a valid value
			setLaborLocal(laborFloat);
			setShadeBidData({
				quoteId: props.quoteId,
				Amount_Sets: [{
					sequence_id,
					labor: laborFloat,
				}]
			})
		}
	};

	const saveOverhead = () => {
		const overheadFloat = parseFloat(overheadLocal);

		const overheadString = overheadFloat.toString();

		if (overheadString != overheadLocal) {
			// User did not input a valid value
			setOverheadLocal(overhead);
		} else {
			// User did input a valid value
			setOverheadLocal(overheadFloat);
			setShadeBidData({
				quoteId: props.quoteId,
				Amount_Sets: [{
					sequence_id,
					overhead: overheadFloat,
				}]
			})
		}
	};

	const saveMarkup = () => {
		const markupFloat = parseFloat(markupLocal);

		const markupString = markupFloat.toString();

		if (markupString != markupLocal) {
			// User did not input a valid value
			setMarkupLocal(markup);
		} else {
			// User did input a valid value
			setMarkupLocal(markupFloat);
			setShadeBidData({
				quoteId: props.quoteId,
				Amount_Sets: [{
					sequence_id,
					markup: markupFloat,
				}]
			})
		}
	};

	const save = () => {
		saveLabor();

		saveOverhead();

		saveMarkup();

		setEditing(false);
	};

	const salePrice = msrp * ((0.425)) + installation_labor_cost + overhead + markup;
	const buyPrice = msrp * 0.425;

	return (
		<tr>
			<td>{room_name}</td>
			<td>{shade_name}</td>
			<td>
				<Money value={buyPrice} />
			</td>
			<td>
				{editing ? (
					<input
						value={laborLocal}
						onChange={(e) => {
							setLaborLocal(e.target.value);
						}}
					/>
				) : (
					<Money value={installation_labor_cost ?? 0} />
				)}
			</td>
			<td>
				{editing ? (
					<input
						value={overheadLocal}
						onChange={(e) => {
							setOverheadLocal(e.target.value);
						}}
					/>
				) : (
					<Money value={overhead ?? 0} />
				)}
			</td>
			<td>
				{editing ? (
					<input
						value={markupLocal}
						onChange={(e) => {
							setMarkupLocal(e.target.value);
						}}
					/>
				) : (
					<Money value={markup ?? 0} />
				)}
			</td>
			<td>
				<Money value={salePrice} />
			</td>
			<td>
				{editing ? (
					<Button
						color="green"
						onClick={() => {
							save();
						}}
					>
						Save
					</Button>
				) : (
					<Button
						color="gray"
						onClick={() => {
							setEditing(true);
						}}
					>
						Edit
					</Button>
				)}
			</td>
		</tr>
	);
};
