import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';

import api from '../../../PowerShadesAPI';
import PaymentStep from '../PaymentStep';

const DealerTermsPayment = ({
	setProcessing,
	willNeedRetainer,
	setWillNeedRetainer,
	setSubmit,
	showSubmit,
	newQuote,
	halfPrice
}) => {
	const [, setWaiting] = useState(false);
	const [poNumber, setPoNumber] = useState(null);
	const [, setError] = useState(null);

	const { QuoteSubstitution } = newQuote;

	const referenceNumber = QuoteSubstitution.reference_number;

	const quoteId = QuoteSubstitution.ID;

	const isEmptyOrSpaces = (str) => str == null || str.toString().trim() === '';

	const placeHolder = isEmptyOrSpaces(referenceNumber) ? `PS${quoteId}` : referenceNumber;

	const submit = () => {
		setWaiting(true);

		setProcessing(true);

		const po = isEmptyOrSpaces(poNumber) ? placeHolder : poNumber;

		return new Promise((resolve, reject) => {
			api.submitDealerTermsPayment(quoteId, po).then((resp) => {
				const data = resp?.data;

				const success = data?.success;

				if (success) {
					resolve('Payment successful.');
				} else {
					const errorMessage = data?.message ?? 'Something went wrong.';
					console.error(resp);
					Sentry.captureException('Dealer terms payment failed. Check console for details.');
					reject(errorMessage);
					setWaiting(false);
					setError(errorMessage);
				}
			});
		});
	};

	useEffect(() => {
		setSubmit(() => submit());
		showSubmit();

		api.termsRetainerNeeded(quoteId).then((resp) => {
			setWillNeedRetainer(resp.data.is_retainer_required);
		}).catch((err) => {
			console.error(err);
			throw new Error('Something went wrong while checking if retainer is needed. Check console logs for details.');
		});
	}, []);

	return (
		<div>
			<div>
				Please enter PO number:
			</div>
			<input
				type="text"
				onChange={(e) => setPoNumber(e.target.value)}
				placeholder={placeHolder}
				value={poNumber}
			/>
			{willNeedRetainer ? (
				<div>
					{`This order will require a retainer equal to $${halfPrice} to be paid before the shades are made.`}
				</div>
			) : null}
			<PaymentStep />
		</div>
	);
};

export default DealerTermsPayment;
