


import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkAPI } from "../types";
import apiCalls from "../../../PowerShadesAPIFunctions";
import { QuoteCombinations, QuoteFasciaCombinations } from "../multipliers/types";
import { AxiosError } from "axios";
import { extraEntityReducersFunction } from "../entitiesType";

export type CombineFasciaThunkInput = {
    quote_id: number;
    sequence_ids: number[];
};

const combineFascia = createAsyncThunk<
    QuoteCombinations, CombineFasciaThunkInput, ThunkAPI
>(
    'entities/combinations/combineFascia',
    async ({quote_id, sequence_ids}, { rejectWithValue }) => {
        let combinations: QuoteFasciaCombinations[];

        try {
            const resp = await apiCalls.combineFascia(quote_id, sequence_ids);

            combinations = resp.data.fascia_combinations as QuoteFasciaCombinations[];

        } catch (err: any) {
            const error: AxiosError = err;

            console.error(error);

            return rejectWithValue(false);
        }

        return {
            Fascias: combinations,
        } as QuoteCombinations;
    }
)

export type DeleteCombinedFasciaThunkInput = {
    quote_id: number;
    fascia_combination_id: number;
};

const deleteCombinedFascia = createAsyncThunk<
    QuoteCombinations, DeleteCombinedFasciaThunkInput, ThunkAPI
>(
    'entities/combinations/deleteCombinedFascia',
    async ({quote_id, fascia_combination_id}, { rejectWithValue }) => {
        let combinations: QuoteFasciaCombinations[];

        try {
            const resp = await apiCalls.deleteCombinedFascia(quote_id, fascia_combination_id);

            combinations = resp.data.fascia_combinations as QuoteFasciaCombinations[];

        } catch (err: any) {
            const error: AxiosError = err;

            console.error(error);

            return rejectWithValue(false);
        }

        return {
            Fascias: combinations,
        } as QuoteCombinations;
    }
)

export type UpdateCombinedFasciaThunkInput = {
    quote_id: number;
    fascia_combination_id: number;
    total_width: number;
};

const updateCombinedFascia = createAsyncThunk<
    QuoteCombinations, UpdateCombinedFasciaThunkInput, ThunkAPI
>(
    'entities/combinations/updateCombinedFascia',
    async ({quote_id, fascia_combination_id, total_width}, { rejectWithValue }) => {
        let combinations: QuoteFasciaCombinations[];

        try {
            const resp = await apiCalls.updateCombinedFascia(quote_id, fascia_combination_id, total_width);

            combinations = resp.data.fascia_combinations as QuoteFasciaCombinations[];

        } catch (err: any) {
            const error: AxiosError = err;

            console.error(error);

            return rejectWithValue(false);
        }

        return {
            Fascias: combinations,
        } as QuoteCombinations;
    }
)


const combinationsExtraReducers: extraEntityReducersFunction = (builder: any) => {
 
    builder.addCase(combineFascia.fulfilled, (state: any, action: any) => {
        state.quoteAssemblyCombinations[action.meta.arg.quote_id] = action.payload;
    });

    builder.addCase(deleteCombinedFascia.fulfilled, (state: any, action: any) => {
        state.quoteAssemblyCombinations[action.meta.arg.quote_id] = action.payload;
    });

    builder.addCase(updateCombinedFascia.fulfilled, (state: any, action: any) => {
        state.quoteAssemblyCombinations[action.meta.arg.quote_id] = action.payload;
    });
}


export {
    combineFascia,
    deleteCombinedFascia,
    updateCombinedFascia,
    combinationsExtraReducers
}