import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, FormSelect } from "react-bootstrap";

import QuantityInput from '../../QuantityInput';
import Button from '../../../Parts/Button';

const Hr = () => (

	<hr style={{
		background: 'gray', border: 'none', height: '1px', margin: '1.4em 1em 1em 1em'
	}}
	/>
);

class 	NewFabricForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			overMax: false,
			selectedFabric: '',
			quoteSamples: props.quoteSamples
            
		};

		this.functions = {};
	}

	changeSamples(fabric_id, quantity) {
		let {
			indoorFabrics,
			outdoorFabrics,
			quoteSamples
		} = this.props;

		const fabs = [...indoorFabrics, ...outdoorFabrics];

		let sample = quoteSamples.find((s) => s.id === fabric_id);

		if (!sample) {
			sample = {
				id: fabric_id,
				name: fabric_id
			};
			quoteSamples.push(sample);
		}

		ROOT.toast(`Added ${sample.name} sample`, { type: "success" });
		sample.quantity = quantity;

		this.setState({
			quoteSamples
		});

		this.props.onChange(fabric_id, quantity);
	}

	render() {
		
		let { props } = this;
		let {
			indoorFabrics,
			outdoorFabrics,
			quoteSamples
		} = props;

		const fabs = [...indoorFabrics, ...outdoorFabrics];

		const isOverMax = () => (props.quote.getFabricSamplesQuantity() > 10);
		const updateIsOverMax = () => setOverMax(isOverMax());
		const addSelected = () => {
			if (selectedFabric != null && (`${selectedFabric}`).length) {
				const unncleanFabName = fabs.find((f) => selectedFabric == f.name)?.name ?? selectedFabric;
				const cleanFabricName = unncleanFabName.includes("-undefined") ? unncleanFabName.replace("-undefined", "") : unncleanFabName;
				this.changeSamples(cleanFabricName, 1);

				setTimeout(() => this.setState({ selectedFabric: '' }), 200);
			}
		};

		const inFabs = QUOTE_GLOBALS.INDOOR_FABRICS ?? indoorFabrics;
		const outFabs = QUOTE_GLOBALS.OUTDOOR_FABRICS ?? outdoorFabrics;

		const in_options = inFabs?.map((fabric) => <option key={fabric.id} value={fabric.id}>{`${fabric.name}-${fabric.opacity}`}</option>) ?? indoorFabrics ?? [];
		const out_options = outFabs?.map((fabric) => <option key={fabric.id} value={fabric.id}>{fabric.name}</option>) ?? outdoorFabrics ?? [];

		let {
			overMax, selectedFabric
		} = this.state;
        
		const { quoteType } = props;

		return (
			<Container className="local-bootstrap">
				<Row>
					<Col>
						<h5 className={`text-${quoteType}`}>Order Fabric Samples</h5>
					</Col>
				</Row>
				<Row className="mb-2">
					<Col>
						<NewFabricSampleForm
							quoteType={quoteType}
							onChange={props.onChange}
							samples={[...in_options, ...out_options]}
							quoteSamples={quoteSamples}
							maxQuantity={10}
							updateQuoteSamples={this.Updates}
							fabrics={fabs}
						/>
					</Col>
				</Row>
				<Hr />

				{overMax ? (
					<div className="error">
							Please limit fabric sample orders to 10 or less.
					</div>
				) : null}

				<Row className="mb-2">
					<Col xs={8}>
						<FormSelect
							size="sm"
							value={selectedFabric}
							onChange={(e) =>
								this.setState({ selectedFabric: e.target.value })}
						>
							<option value="">- Select Indoor Fabric -</option>
							{in_options}
						</FormSelect>
					</Col>
					<Col xs={4}>
						<Button
							color={quoteType ?? 'green'}
							fullWidth
							fluid
							size="sm"
							onClick={addSelected}
						>
							<FontAwesomeIcon icon={faPlus} />
							{' '}
							Add
						</Button>
					</Col>
				</Row>

				<Row className="mb-4 mt-2">
					<Col xs={8}>
						<FormSelect
							size="sm"
							value={selectedFabric}
							onChange={(e) =>
								this.setState({ selectedFabric: e.target.value })}
						>
							<option value="">- Select Outdoor Fabric -</option>
							{out_options}
						</FormSelect>
					</Col>
					<Col xs={4}>
						<Button
							fullWidth
							size="sm"
							fluid
							color={quoteType ?? 'green'}
							onClick={addSelected}
						>
							<FontAwesomeIcon icon={faPlus} />
							{' '}
							Add
						</Button>
					</Col>
				</Row>

				<Row className="mb-2 justify-content-end">
					<Col className="text-end" lg={4}>
						<Button
							fullWidth
							color={quoteType ?? 'green'}
							onClick={props.onDone}
						>
                            Done
						</Button>
					</Col>
				</Row>
			</Container>
		);
	}
}

class NewFabricSampleForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			samples: props.samples,
		};
	}

	render() {
		const { props } = this;
		const {
			samples, isManufacturer, quoteSamples, editable, fabrics
		} = props;

		const rows = quoteSamples.filter((qs) => qs.Quantity > 0).reduce((list, quoteSample) => {
			let fabric = (QUOTE_GLOBALS.FABRICS ?? fabrics ?? []).find((f) => f.name == quoteSample.Name);

			if (fabric == null) {
				fabric = {
					name: quoteSample.Name,
					id: quoteSample.Id
				};	
			}

			const { Quantity :quantity } = quoteSample;
			list.push(<FabricSampleRow quoteType={props.quoteType} key={`${fabric.name}-${editable}`} editable={editable} fabric={fabric} quantity={quantity} isManufacturer={isManufacturer} onChange={props.onChange} />);

			return list;
		}, []);

		const WrapperTag = Fragment;
		const wrapper_props = props.invoiceTable ? {} : {
			className: 'invoice-table', border: '0', cellSpacing: '0', cellPadding: '0'
		};

		// No fabric samples to show.
		// Don't render.
		if (rows.length < 1) return null;

		// Render table body.
		return (

			<tbody>
				{
					props.invoiceTable
						? (
							<tr className={`bold-row ${props.quoteType}`}>
								<th colSpan={(props.editable ? 4 : 3) - (isManufacturer ? 1 : 0)}>Fabric Samples</th>
							</tr>
						)
						: null

				}

				<tr className={props.invoiceTable ? '' : `bold-row ${props.quoteType}`}>
					<th className={props.quoteType}>Fabric Name</th>
					<th className={props.quoteType}>Quantity</th>

					{
						props.invoiceTable & !isManufacturer
							? <th>Price</th>
							: null

					}

					{
						props.editable

							? <th className={props.quoteType}>Actions</th>
							: null
					}
				</tr>

				{rows}
			</tbody>
		);
	}
}

class FabricSampleRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			quantity: props.quantity
		};
	}

	render() {
		const { props } = this;

		const {
			isManufacturer, editable, fabric, maxQuantity
		} = props;

		const { quantity } = this.state;

		return (
			<tr>
				<td>{fabric.name}</td>
				<td>
					<QuantityInput
						initialValue={quantity}
						disabled={!editable}
						onChange={(new_value) => {
							ROOT.toast(`Updated ${fabric.name} sample QTY to ${new_value}`, { type: "success" });
							props.onChange(fabric.name, new_value);
						}}
					/>
				</td>

				{props.invoiceTable & !isManufacturer ? <td>$0.00</td> : null}

				{props.editable ? (
					<td className="local-bootstrap">
						<Button
							title="Delete"
							color="light"
							size="sm"
							fullWidth
							onClick={() => {
								ROOT.toast(`${fabric.name} samples deleted`, {
									type: "success"
								});
								props.onChange(fabric.name, 0);
							}}
						>
							<FontAwesomeIcon icon={faTrashAlt} />
						</Button>
					</td>
				) : null}
			</tr>
		);
	}
}

export {
	NewFabricForm,
	NewFabricSampleForm,
};
