
/*
public class UpdateQuotePricingInput
{
    public int QuoteId { get; set; }
    public decimal FreightShippingTotal { get; set; }
    public decimal ParcelShippingTotal { get; set; }
    public decimal Total { get; set; }
    public List<UpdateQuotePricingInputAssembly> Assemblies { get; set; }
    public List<UpdateQuotePricingInputAccessory> Accessories { get; set; }
    public List<UpdateQuotePricingHWItem> HardwareItems { get; set; }

}

public class UpdateQuotePricingInputAssembly
{
    public int SequenceId { get; set; }
    public decimal MSRP { get; set; }
    public decimal Shipping { get; set; }
    public DateTime TimePriced { get; set; }
    public List<UpdateQuotePricingInputShade> Shades { get; set; }

}

public class UpdateQuotePricingInputShade
{
    public int Row { get; set; }
    public int Column { get; set; }
    public decimal TorqueNeeded { get; set; }
    public decimal RollDiameter { get; set; }
    public decimal Deflection { get; set; }
    public decimal ClearanceDiameter { get; set; }
    public decimal Weight { get; set; }
    public string TubeName { get; set; }
    public string TubeSku { get; set; }
    public string MotorSku { get; set; }
    public string BearingPinTypeSku { get; set; }

}

public class UpdateQuotePricingInputAccessory
{
    public int LineNumber { get; set; }
    public int Quantity { get; set; }
    public decimal Cost { get; set; }
    public decimal MSRP { get; set; }
    public decimal Shipping { get; set; }
}

public class UpdateQuotePricingHWItem
{
    public int LineNumber { get; set; }
    public int Quantity { get; set; }
    public decimal Cost { get; set; }
    public decimal MSRP { get; set; }
    public decimal Shipping { get; set; }
}

*/

import { UserState } from "../../../powershadesApiTypeExtensions";
import { AssemblyOptionsViewModel, AssemblyViewModel, FabricStore, loadStatusType } from "../../../powershadesApiTypes";
import { loadStatus, QuoteStore } from "../entitiesType";

type QuotePricingInput = {
	quote_id: number;
	// freight_shipping_total: number;
	// parcel_shipping_total: number;
	// total: number;
	// assemblies: QuotePricingInputAssembly[];
	// accessories: QuotePricingInputAccessory[];
	// hardware_items: QuotePricingInputHWItem[];

};

type PortalShadeOptionItem<T> = {
	label: string;
	value: T;
	title: string;
	isActive?: boolean;
	default_colors?: { [key: string]: string };
	hexCode?: string;
};
type CardinalDirectionType = 'Unknown' | 'North' | 'Northeast' | 'East' | 'Southeast' | 'South' | 'Southwest' | 'West' | 'Northwest';

/* PSOUTDOOR OPTIONS */
type PSOutdoorHeaderMountType = 'Side' | 'Face' | 'Top';
type PSOutdoorHeaderTypeSku = 'Box 190' | 'Box 120' | 'Open Roll 120' | 'Open Roll Extreme';
type PSOutdoorColor = 'Black' | 'Bronze' | 'Paper Bark';
type PSOutdoorControlSide = 'Left' | 'Right';
type PSOutdoorSideGuides = 'Wire' | 'Zip' | 'Channel';
type PSOutdoorSideGuideMountTypes = 'Floor' | 'Side' | 'Face Fixed';

/* INDOOR OPTIONS */
type IndoorShadeConfiguration = 'single' | 'dual' | 'coupled' | 'dual_coupled';
type IndoorDriveType = 'manual' | 'motorized';
type IndoorManualChain = 'metal' | 'plastic' | 'spring';
type IndoorMotorType = 'poe' | 'high_voltage' | 'low_voltage_hw' | 'low_voltage' | 'poe 2';
type IndoorMountType = 'Inside' | 'Outside' | 'Fabric Width';
type IndoorRollDirection = 'standard' | 'reverse';
type IndoorHeaderType = 'OR_1234' | 'OR_9876' | 'OR_0002' | 'OR_COULLISE_LARGE_BALANCED' | 'OR_5678' | 'OR_ZMC_LARGE' // Open Roll
| 'RC3051-A' | 'RC3051-BK' | 'RC3051-DBR' | 'RC3051-LB' | 'RC3051-W' | 'RC3051-AN' // 3" cassette
| 'RC3029-A' | 'RC3029-BK' | 'RC3029-W' | 'RC3029-LB' // 3" square cassette
| 'RB88-0150-050490' | 'RB88-0150-069490' | 'RB88-0150-020490' | 'RB88-0150-080490' | 'RB88-0150-414490' // 3" flat fabric wrapped cassette
| 'RC3080-A' | 'RC3080-AN' | 'RC3080-BK' | 'RC3080-DBR' | 'RC3080-LB' | 'RC3080-W' // 3" fascia
| 'RC4039-A' | 'RC4039-AN' | 'RC4039-BK' | 'RC4039-DBR' | 'RC4039-LB' | 'RC4039-W' // 5" fascia
| 'RS-SU1' | 'RS-SU2' | 'RS-SU3' | 'RS-SU4' | 'RS-SU5' // 4" cassette
| 'RS-SC1' | 'RS-SC2' | 'RS-SC3' | 'RS-SC4' | 'RS-SC5' // 4" fascia
| 'RS-SV4' | 'RS-SV2' | 'RS-SV1' | 'RS-SK4A' | 'RS-SK5A' | 'RS-SK2A' | 'RS-SK1A' // 5.5" fascia
| 'RC3158-A' | 'RC3158-AN' | 'RC3158-BK' | 'RC3158-DBR' | 'RC3158-LB' | 'RC3158-W'; // 4.0 COU Fascia
type IndoorEndCaps = 'Metal End Caps' | 'Plastic End Caps';
type IndoorFasciaSplit = 'match_mullion' | 'single_piece' | 'no_fascia';
type IndoorHardwareColor = 'Black' | 'White' | 'Anodized' | 'Bronze' | 'Gray' | 'Off-White' | 'Anthracite'
| 'Black Nickel' | 'Chrome' | 'Natural Aluminum' | 'Old Brass' | 'Stainless Steel';
type IndoorHembarType = 'internal_sealed' | 'internal_oval' | 'external_metal' | 'external_cable' | 'external_handle' | 'internal_oval_fabric_wrapped' | 'internal_magnetic' | 'oval_magnetic' | 'external_magnetic';
type IndoorControlSide = 'Left' | 'Right';
type IndoorBalancedLightgaps = 'balanced' | 'unbalanced';
type IndoorHembarColor = 'White' | 'Black' | 'Anodized' ;
type IndoorSideChannels = 'None' | 'Side Channels' | 'Sill Channel' | 'Both';
type IndoorSideChannelsColor = 'White' | 'Black' | 'Anodized';
type IndoorManualChainColor = 'Off-White' | 'Gray' | 'Brown' | 'White' | 'Black' | 'Stainless Steel';
type IndoorLvPowerSource = 'wall_charger' | 'dc_whip';

type CORDriveType = 'motorized';
type COROutdoorHeaderMountType = 'Ceiling' | 'Wall' ;
type CORHeaderTypeSku = 'outdoor_harmony_premium_square_front' | 'outdoor_harmony_standard_round_front' | 'outdoor_apex' | 'outdoor_meridian';
type COROutdoorColor = 'outdoor_anthracite' | 'outdoor_carbon' | 'outdoor_corten' | 'outdoor_dune' | 'outdoor_glacier' | 'outdoor_granite' | 'outdoor_urban_bronze' | 'outdoor_white_quartz';
type COROutdoorControlSide = 'Left' | 'Right';
type COROutdoorHembarType = 'outdoor_heavy_duty_bottom_bar' ;
type CORSideChannels = 'outdoor_lightning_track' | 'outdoor_wall_mount_cable' | 'outdoor_floor_mount_cable';

type COROutdoorShadeOptionsDict = {
	DriveType: PortalShadeOption<CORDriveType>;
	HeaderMountType: PortalShadeOption<COROutdoorHeaderMountType>;
	Width: PortalShadeOption<number>;
	Height: PortalShadeOption<number>;
	HeaderType: PortalShadeOption<CORHeaderTypeSku>;
	Color: PortalShadeOption<COROutdoorColor>;
	ControlSide: PortalShadeOption<COROutdoorControlSide>;
	Fabric: PortalShadeOption<string>;
	// HembarType: PortalShadeOption<COROutdoorHembarType>;
	SideGuides: PortalShadeOption<CORSideChannels>;
	DirectionFacing: PortalShadeOption<CardinalDirectionType>;
	RoomName: PortalShadeOption<string>;
	ShadeName: PortalShadeOption<string>;
	Floor: PortalShadeOption<number>;
	Colors: PortalShadeOption<string>[];
};

type PortalShadeOptionNumeric = {
	minValue: number;
	maxValue: number;
	options?: PortalShadeOptionItem<void>[];
};

type PortalShadeOptionDropdown<T> = {
	minValue?: number;
	maxValue?: number;
	options: PortalShadeOptionItem<T>[];
};

type PortalShadeOption<T> = (PortalShadeOptionDropdown<T> | PortalShadeOptionNumeric) & {
	varName: string; // Should Match With Dictionary Key
	label: string; // Generated based on AssemblyStore
	error: string[]; // Generated based on AssemblyStore + other items
	valuePopup?: any; // Picture that will popup on the icon
	info: string[]; // Generated based on AssemblyStore + other items
	getValue: () => T; // Generated based on AssemblyStore
	// Generated based on AssemblyStore
	setValue: (newVal: T) => void; // Generated based on AssemblyStore
	clearValue: (...args: PortalShadeOption<any>[]) => void; // Generated based on AssemblyStore
	getSelectedOption: () => PortalShadeOptionItem<T>;
	active?: boolean;
};

type IndoorDriveTypeOption = PortalShadeOption<IndoorDriveType> & {
	setValueAndClearMotorOptions: (newVal: IndoorDriveType) => void;
};

type IndoorShadeOptionsSingleDict = {
    
	Fabric: PortalShadeOption<string>;
	RollDirection: PortalShadeOption<IndoorRollDirection>;

};

type IndoorShadeOptionsDualDict = {
	FrontFabric: PortalShadeOption<string>;
	BackFabric: PortalShadeOption<string>;
	FrontRollDirection: PortalShadeOption<IndoorRollDirection>;
	BackRollDirection: PortalShadeOption<IndoorRollDirection>;
};

type IndoorShadeOptionsCoupledDict = {
	FasciaSplit: PortalShadeOption<IndoorFasciaSplit>;
	Fabric: PortalShadeOption<string>;
	CoupledWidth: PortalShadeOption<number[]>;
	RollDirection: PortalShadeOption<IndoorRollDirection>;
	BandCount: PortalShadeOption<number>;
};

type IndoorShadeOptionsDualCoupledDict = {
	FasciaSplit: PortalShadeOption<IndoorFasciaSplit>;
	FrontFabric: PortalShadeOption<string>;
	BackFabric: PortalShadeOption<string>;
	CoupledWidth: PortalShadeOption<number[]>;
	FrontRollDirection: PortalShadeOption<IndoorRollDirection>;
	BackRollDirection: PortalShadeOption<IndoorRollDirection>;
	BandCount: PortalShadeOption<number>;
};

type IndoorShadeOptionsAllDict = {
	HeaderMountType: PortalShadeOption<IndoorMountType>;
	ShadeConfiguration: PortalShadeOption<IndoorShadeConfiguration>;
	DirectionFacing: PortalShadeOption<string>;
	RoomName: PortalShadeOption<string>;
	ShadeName: PortalShadeOption<string>;
	Floor: PortalShadeOption<number>;
	SideChannels: PortalShadeOption<IndoorSideChannels>;
	// SideChannelsColor: PortalShadeOption<IndoorSideChannelsColor>;
	DriveType: IndoorDriveTypeOption;
	MotorType: PortalShadeOption<IndoorMotorType>;
	ManualType: PortalShadeOption<IndoorManualChain>;
	TotalWidth: PortalShadeOption<number>;
	Height: PortalShadeOption<number>;
	WindowJambDepth: PortalShadeOption<number>;
	// HembarType: PortalShadeOption<IndoorHembarType>;
	HembarStyle: PortalShadeOption<string>;
	HembarLine: PortalShadeOption<string>;
	// HembarColor: PortalShadeOption<IndoorHembarColor>;
	LVPowerSupply: PortalShadeOption<IndoorLvPowerSource>;
	// ManualChainColor: PortalShadeOption<IndoorManualChainColor>;
	ControlSide: PortalShadeOption<IndoorControlSide>;
	HeaderType: PortalShadeOption<IndoorHeaderType>;
	BalancedLightGaps: PortalShadeOption<IndoorBalancedLightgaps>;
	// HardwareColor: PortalShadeOption<IndoorHardwareColor>;

	EndCaps: PortalShadeOption<IndoorEndCaps>;
	CustomFabricWidth: PortalShadeOption<number>;
	FabricAlignment: PortalShadeOption<string>;

	ColorPalettes: PortalShadeOption<string>; // Comes in as Default Color Palettes
	Colors: PortalShadeOption<string>[]; // Comes in as Color Palettes

};
type IndoorShadeOptionsDict = (IndoorShadeOptionsSingleDict
| IndoorShadeOptionsDualDict
| IndoorShadeOptionsCoupledDict
| IndoorShadeOptionsDualCoupledDict) & IndoorShadeOptionsAllDict;

type PSOutdoorShadeOptionsDict = {
	Width: PortalShadeOption<number>;
	Height: PortalShadeOption<number>;
	HeaderMountType: PortalShadeOption<PSOutdoorHeaderMountType>;
	HeaderType: PortalShadeOption<PSOutdoorHeaderTypeSku>;
	// Color: PortalShadeOption<PSOutdoorColor>;
	Fabric: PortalShadeOption<string>;
	ControlSide: PortalShadeOption<PSOutdoorControlSide>;
	SideGuides: PortalShadeOption<PSOutdoorSideGuides>;
	SideGuideMountTypes: PortalShadeOption<PSOutdoorSideGuideMountTypes>;
	RoomName: PortalShadeOption<string>;
	ShadeName: PortalShadeOption<string>;
	DirectionFacing: PortalShadeOption<string>;
	Floor: PortalShadeOption<number>;
	ColorPalettes: PortalShadeOption<string>;
	Colors: PortalShadeOption<string>[];
};

type PSOutdoorShadeOptionsFunc = (
	assembly: AssemblyStore,
	setAssembly: (assembly: AssemblyStore) => void,
	externalData: ExternalShadeData,
	allOptionOverride?: boolean
) => PSOutdoorShadeOptionsDict;

type IndoorShadeOptionsFunc = (
	assembly: AssemblyStore,
	setAssembly: (assembly: AssemblyStore) => void,
	externalData: ExternalShadeData,
	allOptionOverride?: boolean
) => IndoorShadeOptionsDict;

type COROutdoorShadeOptionsFunc = (
	assembly: AssemblyStore,
	setAssembly: (assembly: AssemblyStore) => void,
	externalData: ExternalShadeData,
	allOptionOverride?: boolean
) => COROutdoorShadeOptionsDict;

type ExternalShadeData = {
	fabrics: FabricStore[];
	user: UserState;
	quote: QuoteStore;
	assemblyOptions: AssemblyOptionsViewModel;
};

type CurrentPSOutdoorShadeOptionsFunc = (quoteId: number) => (sequenceId: number) => PSOutdoorShadeOptionsDict;
type CurrentIndoorShadeOptionsFunc = (quoteId: number) => (sequenceId: number) => IndoorShadeOptionsDict;
type CurrentCOROutdoorShadeOptionsFunc = (quoteId: number) => (sequenceId: number) => COROutdoorShadeOptionsDict;

export type ShadeStore = {
	id: number;

	row_coordinate: number;
	column_coordinate: number;

	api_motor_id: number;

	roll_diameter: number;
	clearence_diameter: number;
	torque_needed: number;
	deflection: number;
	height: number;
	width: number;
	weight: number;

	fabric_name: string;
	shade_type: string;
	mfg_status: string;
	hembar_line: string;
	hembar_style: string;
	lv_power_source: string;
	manual_chain: string;
	manual_chain_color: string;
	motor: string;
	motor_type: string;
	roll_direction: string;
	tube_name: string;
	bearing_pin_sku: string;
	tube_sku: string;

	custom_fabric_width: number;
	fabric_alignment: string;

	make_this_shade: boolean;

};

type AssemblyStore = {

	loadStatus: loadStatusType;
	timeLoaded: number;

	shades: ShadeStore[];

	id: number;
	control_side: string;
	direction_facing: string;
	mfg_status: string;
	room_name: string;
	shade_name: string;
	error_messages?: string;
	hardware_color: string;
	side_channels: string;
	side_channels_color: string;
	side_channels_mount_type: string;
	header_line: string;
	balanced_lightgaps: "balanced" | "unbalanced";
	end_caps: string;
	recipe_sku: string;
	// mount_type: string;
	mount_style: string;
	palette_color: string;


	front_row_torque: number;
	back_row_torque: number;
	front_row_weight: number;
	back_row_weight: number;

	window_jamb_depth: number;
	crank_length: number;
	total_width: number;
	band_count: number;
	floor: number;
	shade_type_id: number;
	quote_id: number;
	sequence_id: number;
	last_time_priced: number;
	last_time_priced_value_saved: number;
	series_id: number;

	labor: number;
	overhead: number;
	markup: number;

	will_be_seamed: boolean;
	has_sag_warning: boolean;
	fabric_will_rub: boolean;
	is_dual: boolean;
	is_coupled: boolean;
	is_channels_only: boolean;
	is_hardware_only: boolean;
	is_header_extrusion_only: boolean;
	is_motor_only: boolean;
	is_tube_and_fabric_only: boolean;
	is_crown_and_drive_only: boolean;

	is_shade_service: boolean;
	is_tube_to_be_cut: boolean;
	is_header_extrusion_to_be_cut: boolean;
	is_sill_channels_to_be_cut: boolean;
	is_side_channels_to_be_cut: boolean;
	is_fabric_to_be_cut: boolean;
	is_hembar_to_be_cut: boolean;

	motor_pricing_override: string;
	tube_pricing_override: string;

	fascia_split: string;

	single_dual: string;

	msrp: number;
	shipping: number;
	cost: number;

	unlocked: boolean;
	selected: boolean;
	colors: { [key: string]: string };
};

const AssemblyStoreFromAssemblyViewModel = (assembly: AssemblyViewModel): AssemblyStore => ({
	loadStatus: loadStatus.fullyLoaded,
	timeLoaded: Date.now(),

	shades: assembly.shades.map((shade) => ({
		id: shade.id,
		row_coordinate: shade.row_coordinate,
		column_coordinate: shade.column_coordinate,
		api_motor_id: shade.api_motor_id,
		roll_diameter: shade.roll_diameter,
		clearence_diameter: shade.clearence_diameter,
		torque_needed: shade.torque_needed,
		deflection: shade.deflection,
		height: shade.height,
		width: shade.width,
		weight: shade.weight,
		fabric_name: shade.fabric_name,
		shade_type: shade.shade_type,
		mfg_status: shade.mfg_status,
		hembar_line: shade.hembar_line,
		hembar_style: shade.hembar_style,
		lv_power_source: shade.lv_power_source,
		manual_chain: shade.manual_chain,
		manual_chain_color: shade.manual_chain_color,
		motor: shade.motor,
		motor_type: shade.motor_type,
		roll_direction: shade.roll_direction,
		tube_name: shade.tube_name,
		bearing_pin_sku: shade.bearing_pin_sku,
		tube_sku: shade.tube_sku,
		make_this_shade: shade.make_this_shade,
		custom_fabric_width: shade.custom_fabric_width,
		fabric_alignment: shade.fabric_alignment
	})),

	id: assembly.id,
	control_side: assembly.control_side,
	direction_facing: assembly.direction_facing,
	mfg_status: assembly.mfg_status,
	room_name: assembly.room_name,
	shade_name: assembly.shade_name,
	error_messages: assembly.error_messages,
	hardware_color: assembly.hardware_color,
	side_channels: assembly.side_channels,
	side_channels_color: assembly.side_channels_color,
	side_channels_mount_type: assembly.side_channels_mount_type,
	header_line: assembly.header_line,
	balanced_lightgaps: assembly.balanced_lightgaps,
	end_caps: assembly.end_caps,
	recipe_sku: assembly.recipe_sku,
	mount_style: assembly.mount_style,
	palette_color: assembly.palette_color,

	window_jamb_depth: assembly.window_jamb_depth,
	crank_length: assembly.crank_length,
	total_width: assembly.total_width,
	band_count: assembly.band_count,
	floor: assembly.floor,
	shade_type_id: assembly.shade_type_id,
	quote_id: assembly.quote_id,
	sequence_id: assembly.sequence_id,
	last_time_priced: assembly.last_time_priced,
	last_time_priced_value_saved: assembly.last_time_priced_value_saved,
	series_id: assembly.series_id,

	will_be_seamed: assembly.will_be_seamed,
	has_sag_warning: assembly.has_sag_warning,
	fabric_will_rub: assembly.fabric_will_rub,
	is_dual: assembly.is_dual,
	is_coupled: assembly.is_coupled,
	is_channels_only: assembly.is_channels_only,
	is_hardware_only: assembly.is_hardware_only,
	is_header_extrusion_only: assembly.is_header_extrusion_only,
	is_motor_only: assembly.is_motor_only,
	is_tube_and_fabric_only: assembly.is_tube_and_fabric_only,
	is_crown_and_drive_only: assembly.is_crown_and_drive_only,

	is_shade_service: assembly.is_service_shade,
	is_fabric_to_be_cut: assembly.is_fabric_to_be_cut,
	is_header_extrusion_to_be_cut: assembly.is_header_to_be_cut,
	is_hembar_to_be_cut: assembly.is_hembar_to_be_cut,
	is_sill_channels_to_be_cut: assembly.is_sill_to_be_cut,
	is_side_channels_to_be_cut: assembly.is_side_to_be_cut,
	is_tube_to_be_cut: assembly.is_tube_to_be_cut,

	labor: assembly.labor,
	overhead: assembly.overhead,
	markup: assembly.markup,

	fascia_split: assembly.fascia_split,
	single_dual: assembly.single_dual,

	motor_pricing_override: assembly.motor_pricing_override,
	tube_pricing_override: assembly.tube_pricing_override,

	msrp: assembly.msrp,
	shipping: 0,
	cost: 0,

	unlocked: false,
	selected: false,
	colors: assembly.colors,

	front_row_torque: 0,
	front_row_weight: 0,
	back_row_torque: 0,
	back_row_weight: 0
}
);

const AssemblyViewModelFromAssemblyStore = (assembly: AssemblyStore): AssemblyViewModel => {
	const assy: AssemblyViewModel = {
		shades: assembly.shades.map((shade) => ({
			id: shade.id,
			row_coordinate: shade.row_coordinate,
			column_coordinate: shade.column_coordinate,
			api_motor_id: shade.api_motor_id,
			roll_diameter: shade.roll_diameter,
			clearence_diameter: shade.clearence_diameter,
			torque_needed: shade.torque_needed,
			deflection: shade.deflection,
			height: shade.height,
			width: shade.width,
			weight: shade.weight,
			fabric_name: shade.fabric_name,
			shade_type: shade.shade_type,
			mfg_status: shade.mfg_status,
			hembar_style: shade.hembar_style,
			hembar_line: shade.hembar_line,
			lv_power_source: shade.lv_power_source,
			manual_chain: shade.manual_chain,
			manual_chain_color: shade.manual_chain_color,
			motor: shade.motor,
			motor_type: shade.motor_type,
			roll_direction: shade.roll_direction,
			tube_name: shade.tube_name,
			bearing_pin_sku: shade.bearing_pin_sku,
			tube_sku: shade.tube_sku,
			make_this_shade: shade.make_this_shade,
			custom_fabric_width: shade.custom_fabric_width,
			fabric_alignment: shade.fabric_alignment
		})),

		id: assembly.id,
		control_side: assembly.control_side,
		direction_facing: assembly.direction_facing,
		mfg_status: assembly.mfg_status,
		room_name: assembly.room_name,
		shade_name: assembly.shade_name,
		error_messages: assembly.error_messages ?? "",
		hardware_color: assembly.hardware_color,
		side_channels: assembly.side_channels,
		side_channels_color: assembly.side_channels_color,
		side_channels_mount_type: assembly.side_channels_mount_type,
		header_line: assembly.header_line,
		balanced_lightgaps: assembly.balanced_lightgaps,
		end_caps: assembly.end_caps,
		recipe_sku: assembly.recipe_sku,
		mount_style: assembly.mount_style,
		palette_color: assembly.palette_color,

		window_jamb_depth: assembly.window_jamb_depth,
		crank_length: assembly.crank_length,
		total_width: assembly.total_width,
		band_count: assembly.band_count,
		floor: assembly.floor,
		shade_type_id: assembly.shade_type_id,
		quote_id: assembly.quote_id,
		sequence_id: assembly.sequence_id,
		last_time_priced: assembly.last_time_priced,
		last_time_priced_value_saved: assembly.last_time_priced_value_saved,
		series_id: assembly.series_id,

		labor: assembly.labor,
		overhead: assembly.overhead,
		markup: assembly.markup,

		will_be_seamed: assembly.will_be_seamed,
		has_sag_warning: assembly.has_sag_warning,
		fabric_will_rub: assembly.fabric_will_rub,
		is_dual: assembly.is_dual,
		is_coupled: assembly.is_coupled,
		is_channels_only: assembly.is_channels_only,
		is_hardware_only: assembly.is_hardware_only,
		is_header_extrusion_only: assembly.is_header_extrusion_only,
		is_motor_only: assembly.is_motor_only,
		is_tube_and_fabric_only: assembly.is_tube_and_fabric_only,
		is_crown_and_drive_only: assembly.is_crown_and_drive_only,

		is_service_shade: assembly.is_shade_service,
		is_fabric_to_be_cut: assembly.is_fabric_to_be_cut,
		is_header_to_be_cut: assembly.is_header_extrusion_to_be_cut,
		is_hembar_to_be_cut: assembly.is_hembar_to_be_cut,
		is_sill_to_be_cut: assembly.is_sill_channels_to_be_cut,
		is_side_to_be_cut: assembly.is_side_channels_to_be_cut,
		is_tube_to_be_cut: assembly.is_tube_to_be_cut,

		fascia_split: assembly.fascia_split,
		single_dual: assembly.single_dual,

		motor_pricing_override: assembly.motor_pricing_override,
		tube_pricing_override: assembly.tube_pricing_override,

		msrp: assembly.msrp,
		loadStatus: 1,
		timeLoaded: 0,
		colors: assembly.colors
	};
    
	return assy;
};

export {
	PSOutdoorShadeOptionsFunc,
	PortalShadeOptionItem,
	PSOutdoorHeaderMountType,
	PSOutdoorSideGuideMountTypes,
	PSOutdoorShadeOptionsDict,
	PSOutdoorHeaderTypeSku,
	PSOutdoorColor,
	PSOutdoorControlSide,
	PSOutdoorSideGuides,
	PortalShadeOption,
	IndoorDriveTypeOption,
	PortalShadeOptionDropdown,
	PortalShadeOptionNumeric,
	CurrentPSOutdoorShadeOptionsFunc,
	QuotePricingInput,
	AssemblyStoreFromAssemblyViewModel,
	IndoorShadeOptionsFunc,
	IndoorShadeOptionsDict,
	CurrentIndoorShadeOptionsFunc,
	IndoorShadeOptionsSingleDict,
	IndoorShadeOptionsDualDict,
	IndoorShadeOptionsCoupledDict,
	IndoorShadeOptionsDualCoupledDict,
	IndoorRollDirection,
	IndoorFasciaSplit,
	IndoorShadeOptionsAllDict,
	IndoorMountType,
	IndoorDriveType,
	IndoorManualChain,
	IndoorMotorType,
	IndoorEndCaps,
	IndoorHardwareColor,
	IndoorHembarType,
	IndoorControlSide,
	IndoorBalancedLightgaps,
	IndoorHembarColor,
	IndoorSideChannels,
	IndoorSideChannelsColor,
	IndoorManualChainColor,
	IndoorLvPowerSource,
	IndoorHeaderType,
	IndoorShadeConfiguration,
	AssemblyViewModelFromAssemblyStore,
	CurrentCOROutdoorShadeOptionsFunc,
	COROutdoorShadeOptionsDict,
	COROutdoorHeaderMountType,
	CORHeaderTypeSku,
	COROutdoorColor,
	COROutdoorControlSide,
	COROutdoorHembarType,
	CORSideChannels,
	CORDriveType,
	CardinalDirectionType,
	COROutdoorShadeOptionsFunc,
	ExternalShadeData
};
export { AssemblyStore };
