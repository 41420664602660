import React from "react";
import { Row, Col, Form, Placeholder } from "react-bootstrap";

import StateSelectorTyped from "../Parts/StateSelectorTyped";

import type { BillingInformationHandlerProps } from "./types";
import CountrySelectorTyped from "../Parts/CountrySelectorTyped";
import { AddressStore } from "../powershadesApiTypes";

const BillingInformationHandler = ({
	address,
	disabled,
	onChange,
	loading,
}: BillingInformationHandlerProps) => {
	const editing = !disabled;
	const handleEdit: {
		(addressMutator: { (addressOriginal: AddressStore): AddressStore }): void;
	} = (mutator) => {
		const newWorkingAddress = mutator({ ...address });
		onChange(newWorkingAddress);
	};

	if (loading) {
		return (
			<Placeholder animation="glow" className="row mb-4 ms-0 me-0">
				<Col xs={12} lg={6} className="mt-2">
					<Placeholder xs={8} size="lg" className="mb-1" />
					<Placeholder xs={12} size="lg" />
				</Col>
				<Col xs={12} lg={6} className="mt-2">
					<Placeholder xs={8} size="lg" className="mb-1" />
					<Placeholder xs={12} size="lg" />
				</Col>
			</Placeholder>
		);
	}

	return (
		<>
			<Row>
				<Col xs={12}>
					<h4>Billing Information</h4>
				</Col>
			</Row>
			<Row className="mb-2">
				<Col sm={12} md={6}>
					<h6>Primary Contact: First Name</h6>
					<Form.Control
						disabled={!editing}
						type="text"
						value={address?.first_name ?? ''}
						onChange={(e) => {
							const firstName = e.target.value;
							handleEdit((addressOriginal) => ({
								...addressOriginal,
								first_name: firstName,
							}));
						}}
					/>
				</Col>
				<Col sm={12} md={6}>
					<h6>Primary Contact: Last Name</h6>
					<Form.Control
						disabled={!editing}
						type="text"
						value={address?.last_name ?? ''}
						onChange={(e) => {
							const lastName = e.target.value;

							handleEdit((addressOriginal) => ({
								...addressOriginal,
								last_name: lastName,
							}));
						}}
					/>
				</Col>
			</Row>
			<Row className="mb-2">
				<Col sm={12} md={6}>
					<h6>Address</h6>
					<Form.Control
						disabled={!editing}
						type="text"
						value={address?.address ?? ''}
						onChange={(e) => {
							const address1 = e.target.value;
							handleEdit((addressOriginal) => ({ ...addressOriginal, address: address1 }));
						}}
					/>
				</Col>
				<Col sm={12} md={6}>
					<h6>Address 2</h6>
					<Form.Control
						disabled={!editing}
						type="text"
						value={address?.address2 ?? ''}
						onChange={(e) => {
							const address2 = e.target.value;
							handleEdit((addressOriginal) => ({ ...addressOriginal, address2 }));
						}}
					/>
				</Col>
			</Row>
			<Row className="mb-2">
				<Col sm={6} md={6}>
					<h6>City</h6>
					<Form.Control
						disabled={!editing}
						type="text"
						value={address?.city ?? ''}
						onChange={(e) => {
							const city = e.target.value;
							handleEdit((addressOriginal) => ({ ...addressOriginal, city }));
						}}
					/>
				</Col>
				<Col sm={6} md={6}>
					<h6>Postal Code</h6>
					<Form.Control
						disabled={!editing}
						type="text"
						value={address?.zip ?? ''}
						onChange={(e) => {
							const zip = e.target.value;
							handleEdit((addressOriginal) => ({ ...addressOriginal, zip }));
						}}
					/>
				</Col>
			</Row>
			<Row className="mb-2">
				<Col sm={6} md={6}>
					<StateSelectorTyped
						currentStateCode={address?.state_code ?? ''}
						onChange={(stateCode) =>
							handleEdit((addressOriginal) => ({
								...addressOriginal,
								state_code: stateCode
							}))
						}
						disabled={!editing}
					/>
				</Col>
				<Col sm={6} md={6}>
					<CountrySelectorTyped
						currentCountryCode={address?.country_code ?? ''}
						onChange={(countryName) =>
							handleEdit((addressOriginal) => ({
								...addressOriginal,
								country: countryName,
							}))}
						disabled={!editing}
					/>
				</Col>
			</Row>
			<hr />
		</>
	);
};

export default BillingInformationHandler;
