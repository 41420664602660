import React, { useState } from "react";
import ReactPaginate from "react-paginate";

import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useQuoteById, useQuoteCounts,  useSetFilterSettings } from "../../Store/entities/quotes/hooks";
import { useFilterSettings, useVisibleQuoteIds } from "../../Store/ui/hooks";
import { useGetQuoteHomeColumns } from "./hooks";
import QuoteHomeActions from "./QuoteHomeActions";
import SkeletonRow from "./SkeletonRow";
import InternalLink from "../../Parts/InternalLink";
import { useUserMultiplier } from "../../hooks";

const HomeQuoteTable = () => {
	const [manualPageNumber, setManualPageNumber] = useState("");

	const setFilterSettings = useSetFilterSettings(true);
	const filterSettings = useFilterSettings();
	const counts = useQuoteCounts();
	const getColumns = useGetQuoteHomeColumns();
	const visibleQuoteIds = useVisibleQuoteIds();

	const {
		filter_names: filterNames,
		page_number: pageNumber,
		results_per_page: resultsPerPage
	} = filterSettings;

	const totalPages = counts[filterNames[0] ?? "all"].count / resultsPerPage;

	const setPage = (page: number) => {
		setFilterSettings({
			...filterSettings,
			page_number: page
		});
	};

	const confirmManualPage = () => {
		setManualPageNumber("");
		setPage(Number(manualPageNumber));
	};

	const firstOr0 = visibleQuoteIds.find(() => true) ?? 0;
	const headers = getColumns(firstOr0).columns.map((column) => (
		<th key={column.key} className={column.key}>
			{column.title}
		</th>
	));

	headers.push(
		<th key="actions" className="actions">
			Actions
		</th>
	);



	const rows = visibleQuoteIds.map((qId) => {
		return <HomeQuoteRow key={qId} quoteId={qId} />;
	});

	return (
		<Col className="quote-table">
			<Row>
				<Col className="table-responsive">
					<table className="quote-table table table-striped">
						<thead>{headers}</thead>
						<tbody>{rows}</tbody>
					</table>
				</Col>
			</Row>
			<Row className="ps-3 page-selector">
				<ReactPaginate
					pageCount={totalPages}
					pageRangeDisplayed={4}
					marginPagesDisplayed={1}
					forcePage={pageNumber - 1}
					onPageChange={(e) => setPage(e.selected + 1)}
					nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
					previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
				/>

				<div style={{ display: "none" }}>
					Jump to Page:&nbsp;&nbsp;
					<input
						type="number"
						value={manualPageNumber || ""}
						onBlur={() => confirmManualPage()}
						onChange={(e) => setManualPageNumber(e.target.value)}
						style={{
							width: "30px",
							height: "23px",
							marginTop: "8px"
						}}
					/>
				</div>
			</Row>
		</Col>
	);
};

const HomeQuoteRow = ({ quoteId }) => {
	const getColumns = useGetQuoteHomeColumns();
	const quote = useQuoteById(quoteId);
	const cols = getColumns(quoteId).columns;
	const multiplier = useUserMultiplier();

	if ((quote?.loadStatus ?? 0) < 2) return <SkeletonRow key={quoteId} columnCount={cols.length} />;

	const values = cols.map((column) => {
		const inner = (
			<td key={column.key} className={column.key}>
				{column.accessor()}
			</td>
		);

		if (column.href) {
			return (
				<td key={column.key} className={column.key}>
					<InternalLink key={`id-${quoteId}`} content={column.href}>
						{column.accessor()}
					</InternalLink>
				</td>
			);
		}

		if (column.key === "msrp") {
			const msrp = +column.accessor().replace(/[$,]/g, "") * multiplier;
			return (
				<td key={column.key} className={column.key}>
					{`$${msrp.toFixed(2)}`}
				</td>
			);
		}

		return inner;
	});

	values.push(
		<td key="actions" className="actions">
			<QuoteHomeActions quoteId={quoteId} />
		</td>
	);

	return <tr key={quoteId}>{values}</tr>;
};


export default HomeQuoteTable;
