import React, { useEffect, useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import PaymentStep from '../PaymentStep';
import TextDivider from '../../../Parts/TextDivider';
import { RMAItems, RMAPaymentOverrideType, rmaSubmitItem } from './RMAPayment/types';
import TextArea from '../../../Parts/TextArea';
import Button from '../../../Parts/Button';
import { useQuoteIdAndDispatch } from '../../../Store/entities/quotes/hooks';
import RMAList from './RMAPayment/RMAList';
import { useRmaLoadStatus, useRmas } from '../../../Store/entities/rmas/hooks';
import { loadStatus } from '../../../Store/entities/entitiesType';

const RMAPaymentOverride = ({ quoteId }: RMAPaymentOverrideType) => {
	const quoteType = '';

	const quote = useQuoteIdAndDispatch(quoteId);
	const originalQuoteId = quote?.previous_quote_id ?? 0;
	const originalQuote = useQuoteIdAndDispatch(originalQuoteId);

	const [rmaData, setRmaData] = useState<rmaSubmitItem>({
		ticket_number: '',
		expidited_shipping: false,
		root_cause_summary: '',
	});

	const initialRmaItems: RMAItems[] = useRmas(quoteId);
	const rmasLoaded = useRmaLoadStatus();
	const [primaryRmas, setPrimaryRmas] = useState<RMAItems[]>([]);
	const [secondaryRmas, setSecondaryRmas] = useState<RMAItems[]>([]);

	useEffect(() => {
		setPrimaryRmas([]);
		setSecondaryRmas([]);
	}, [quoteId]);

	useEffect(() => {
		if (rmasLoaded === loadStatus.fullyLoaded && primaryRmas.length === 0 && secondaryRmas.length === 0 && initialRmaItems.length) {
			setPrimaryRmas(initialRmaItems.filter((item) => item && !item.parentRmaId));
			setSecondaryRmas(initialRmaItems.filter((item) => item && item.parentRmaId));
		}
	}, [rmasLoaded, initialRmaItems]);

	const dateNow = Date.now();
	const dateOriginalPlaced = originalQuote?.ordered_time ?? 0;
	const dateOriginalShipped = originalQuote?.shipments?.reduce((mostRecentTime, quoteShip) => (mostRecentTime > quoteShip.time ? mostRecentTime : quoteShip.time), 0) ?? 0;

	return (
		<PaymentStep>
			<TextDivider>
				<h3 className={`text-${quoteType}`} style={{ fontWeight: 'normal' }}>
					RMA Configuration
				</h3>
			</TextDivider>
			<Row>
				<Col>
					<Row>
						<RMAList
							rmas={primaryRmas}
							setRmas={setPrimaryRmas}
							quoteId={quoteId}
						/>
					</Row>
					<Row>
						<RMAList
							primaryRmas={primaryRmas}
							rmas={secondaryRmas}
							setRmas={setSecondaryRmas}
							quoteId={quoteId}
						/>
					</Row>
					<Row />
				</Col>
			</Row>
			<Col className='d-grid gap-3 pt-2'>
				<Row>
					<label className='p-0'>
						Ticket Number
						<input
							className='form-control'
							value={rmaData.ticket_number}
							onChange={(e) => {
								setRmaData({
									...rmaData,
									ticket_number: e.target.value,
								});
							}}
						/>
					</label>
				</Row>
				<Row>
					<Col className='p-0'>
						<TextArea
							label="Root Cause Summary"
							className="w-100 form-control"
							onChange={(newVal) => {
								setRmaData({
									...rmaData,
									root_cause_summary: newVal,
								});
							}}
							maxLength={20000}
						/>
					</Col>
				</Row>
				<Row>
					<Button
						radioButton
						className={`p-3 bg-${rmaData.expidited_shipping ? "rma" : "body-secondary"}`}
						color=''
						fluid
						fullWidth
						onClick={() => setRmaData({
							...rmaData,
							expidited_shipping: !rmaData.expidited_shipping
						})}
					>
						Expedited Shipping &nbsp;
						{
							rmaData.expidited_shipping
								? (<FontAwesomeIcon icon={faCheck} />)
								: (<FontAwesomeIcon icon={faTimes} />)
						}
					</Button>
				</Row>
				<Row>
					<Accordion className='p-0'>
						<Accordion.Item eventKey='shippingDetails'>
							<Accordion.Header className='rma rounded'>Shipping Details</Accordion.Header>
							<Accordion.Body className=' gap-2 d-grid'>
								<Row>
									<text className='underlined'>Date Original Ordered</text>
									<text className='text-body'>{`${new Date(dateOriginalPlaced * 1000)}`}</text>
								</Row>
								<Row>
									<text className='underlined'>Date Original Shipped</text>
									<text className='text-body'>{`${new Date(dateOriginalShipped * 1000)}`}</text>
								</Row>
								<Row>
									<text className='underlined'>Date RMA Generated</text>
									<text className='text-body'>{`${new Date(dateNow)}`}</text>
								</Row>
								<Row>
									<text className='underlined'>Date RMA Shipped</text>
									<text className='text-body'>Unknown</text>
								</Row>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</Row>
			</Col>
		</PaymentStep>
	);
};

export default RMAPaymentOverride;
