/* eslint-disable camelcase */
import { camelCase, isEqual, isObject, mapKeys, mapValues, snakeCase } from "lodash";
import axios, { AxiosError } from "axios";
import { createSelectorCreator, defaultMemoize } from "reselect";
import { captureException as sentryCaptureException } from "@sentry/react";
import type { CaptureContext } from "@sentry/types";
import { EnsureConnectionMade } from './Store/signalR/connection';

import type {
	PossibleAPIError,
	PowerShadesAPIResponse,
	QuoteShipment,
	successResp
} from "./powershadesApiTypes";

import type {
	AssemblyShadePayload,
	UserRole,
	UserRoleNames
} from "./powershadesApiTypeExtensions";
import { wentToQuote } from "./Store/entities/quotes";
import { PortalShadeOptionItem } from "./Store/entities/assemblies/types";

/**
 * Binds all methods of an object to the object itself.
 *
 * @deprecated This function is not recommended for use due to lack of typing.
 *
 * @param obj - The object whose methods should be bound.
 * @param args - The names of the methods to bind.
 */
export const bindAll = (obj: any, ...args: any) => {
	let array: any;

	if (args[1] && Array.isArray(args[1])) {
		array = args[1];
	} else {
		array = args;
	}

	array.forEach((func_name) => {
		obj[func_name] = obj[func_name].bind(obj);
	});
};

/**
 * @deprecated in 1.10.0 - use ROOT.get
 */
export const loadGetVars = () => false;

export const getToken = () => {
	const goods = localStorage.getItem("user_data") ?? "";
	const goodsObj = goods === "" ? {} : JSON.parse(goods);

	const jwt = goodsObj?.jwt ?? "";

	return jwt;
};

/**
 * Generates a random string of the specified length.
 *
 * @param stringLength - The length of the string to generate.
 * @returns A random string of the specified length.
 */
export const randomString = (stringLength: number) => {
	// The characters that can be used to generate the random string.
	const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

	let text = "";
	for (let i = 0; i < stringLength; i++) {
		// Choose a random character from the possible characters and add it to the string.
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}

	return text;
};

/**
 * Returns a string representing the time elapsed since the given timestamp.
 *
 * @param timestamp - The timestamp to calculate the time elapsed from.
 * @returns A string representing the time elapsed since the given timestamp.
 */
export const timeAgo = (timestamp: number): string => {
	const secondsPast = (Date.now() - timestamp) / 1000;
	if (secondsPast < 60) {
		return `${Math.floor(secondsPast)} seconds ago`;
	}
	if (secondsPast < 3600) {
		return `${Math.floor(secondsPast / 60)} minutes ago`;
	}
	if (secondsPast <= 86400) {
		return `${Math.floor(secondsPast / 3600)} hours ago`;
	}
	if (secondsPast > 86400) {
		const day = Math.floor(secondsPast / 86400);
		return `${day} day${day > 1 ? "s" : ""} ago`;
	}
	return "";
};

/**
 * Decodes a base64 encoded string.
 *
 * @param token - The base64 encoded string to decode.
 * @returns The decoded string.
 */
export const atobReplacement = (token: string) => {
	if ((token?.length ?? 0) < 1) {
		return "";
	}
	const bufferedToken = Buffer.from(token, "base64");
	const bufferedString = bufferedToken.toString("base64");

	return bufferedString;
};

/**
 * Returns a link to view a quote with the given ID.
 *
 * @param id - The ID of the quote to view.
 * @returns A link to view the quote.
 */
export const getQuoteLink = (id: number | string) => `/#/Quote?quoteID=${id}`;

/**
 * Returns a link to view a dealer with the given ID.
 *
 * @param id - The ID of the dealer to view.
 * @returns A link to view the dealer.
 */
export const getDealerLink = (id: number | string) => `/#/Dealer?dealer_id=${id}`;

/**
 * Returns a link to view a distributor with the given ID.
 *
 * @param id - The ID of the distributor to view.
 * @returns A link to view the distributor.
 */
export const getDistributorLink = (id: number | string) => `/#/Distributor?distributor_id=${id}`;

/**
 * Returns a link to view a distributor location with the given ID.
 *
 * @param id - The ID of the distributor location to view.
 * @returns A link to view the distributor location.
 */
export const getDistributorLocationLink = (id: number | string) =>
	`/#/DistributorLocations?distributor_location_id=${id}`;

/**
 * Returns a link to view a manufacturer with the given ID.
 *
 * @param id - The ID of the manufacturer to view.
 * @returns A link to view the manufacturer.
 */
export const getManufacturerLink = (id: number | string) => `/#/Manufacturer?manufacturer_id=${id}`;

/**
 * Returns a link to view a representative with the given ID.
 *
 * @param id - The ID of the representative to view.
 * @returns A link to view the representative.
 */
export const getRepresentativeLink = (id: number | string) =>
	`/#/Representative?representative_id=${id}`;

/**
 * Returns a link to view a user with the given ID.
 *
 * @param id - The ID of the user to view.
 * @returns A link to view the user.
 */
export const getUserLink = (id: number | string) => `/#/User?user_id=${id}`;

/**
 * Returns a link to view a territory with the given ID.
 *
 * @param id - The ID of the territory to view.
 * @returns A link to view the territory.
 */
export const getTerritoryLink = (id: number | string) => `Territory?territory_id=${id}`;

const singularDictionary = {
	territories: "territory",
	dealers: "dealer",
	distributors: "distributor",
	representatives: "representative",
	users: "user",
	distribution_emails: "distribution_email",
	quotes: "quote"
};

const pluralDictionary = {
	territory: "territories",
	dealer: "dealers",
	distributor: "distributors",
	representative: "representatives",
	user: "users",
	distribution_email: "distribution_emails",
	quote: "quotes"
};

/**
 * Returns the singular form of a plural noun, if it exists in the singular dictionary.
 * If the singular form does not exist in the dictionary, the original string is returned.
 *
 * @param str - The plural noun to singularize.
 * @returns The singular form of the input string, if it exists in the singular dictionary.
 * Otherwise, the original string.
 */
export const singularize = (str: string): string => {
	const workingString = snakeCase(str.toLowerCase());
	return singularDictionary[workingString] ?? str;
};

/**
 * Returns the plural form of a singular noun, if it exists in the plural dictionary.
 * If the plural form does not exist in the dictionary, the original string is returned.
 *
 * @param str - The singular noun to pluralize.
 * @returns The plural form of the input string, if it exists in the plural dictionary.
 * Otherwise, the original string.
 */
export const pluralize = (str: string): string => {
	const workingString = snakeCase(str.toLowerCase());
	return pluralDictionary[workingString] ?? str;
};

/**
 * Checks if the given search string matches the provided string.
 * @param search - The search string to match.
 * @param activeSearch - The string to match against.
 * @returns Returns true if the search string matches the provided string, false otherwise.
 */
export const isSearchMatch = (search: string, activeSearch: string): boolean => {
	if (activeSearch.length !== 0) {
		return search?.toLocaleLowerCase().includes(activeSearch?.toLocaleLowerCase() ?? "") ?? false;
	}
	return true;
};

export type EntityTypeStrings =
	| "dealer"
	| "distributor"
	| "distributor_location"
	| "rep"
	| "powershades"
	| "manufacturer"
	| "unknown";

/**
 * Returns the entity type string for the given role name.
 *
 * @param workingRoleName - The role name to get the entity type string for.
 * @returns The entity type string for the given role name.
 * @returns "unknown" if the role name is not recognized.
 */
export const getEntityTypeFromRoleName = (workingRoleName: typeof UserRoleNames[number]): EntityTypeStrings => {
	switch (workingRoleName) {
		case "dealer_admin":
		case "dealer_user":
		case "dealer_purchasing":
		case "dealer_salesperson":
			return "dealer";
		case "distributor_admin":
		case "distributor_user":
			return "distributor";
		case "distributor_location_admin":
		case "distributor_location_user":
			return "distributor_location";
		case "rep_admin":
		case "rep_user":
			return "rep";
		case "powershades_admin":
			return "powershades";
		case "manufacturer_admin":
		case "manufacturer_user":
			return "manufacturer";
		default:
			return "unknown";
	}
};

/**
 * Returns the entity page link for the given role.
 *
 * @param workingRole - The role to get the entity page link for.
 * @returns The entity page link for the given role.
 * @throws An error if the entity type cannot be determined from the role name.
 * @throws An error if the entity link cannot be determined from the entity type.
 */
export const getEntityLinkFromRole = (workingRole: UserRole, removeHash = false) => {
	const { role_name } = workingRole;
	const entityType = getEntityTypeFromRoleName(role_name);
	if (entityType === "unknown") {
		throw new Error("Something went wrong trying to get the entity type from the user.");
	}
	switch (entityType) {
		case "dealer": {
			const dealerLink = getDealerLink(workingRole.entity_id);
			if (removeHash) {
				const dealerRemovedSlash = dealerLink.slice(1);
				return dealerRemovedSlash.replace("#", "");
			}
			return dealerLink;
		}
		case "distributor": {
			const distributorLink = getDistributorLink(workingRole.entity_id);
			if (removeHash) {
				const distributorRemovedSlash = distributorLink.slice(1);
				return distributorRemovedSlash.replace("#", "");
			}
			return distributorLink;
		}

		case "distributor_location": {
			const distributorLocationLink = getDistributorLocationLink(workingRole.entity_id);
			if (removeHash) {
				const distributorLocationRemovedSlash = distributorLocationLink.slice(1);
				return distributorLocationRemovedSlash.replace("#", "");
			}
			return distributorLocationLink;
		}
		case "rep": {
			const representativeLink = getRepresentativeLink(workingRole.entity_id);
			if (removeHash) {
				const representativeRemovedSlash = representativeLink.slice(1);
				return representativeRemovedSlash.replace("#", "");
			}
			return representativeLink;
		}
		case "manufacturer": {
			const manufacturerLink = getManufacturerLink(workingRole.entity_id);
			if (removeHash) {
				const manufacturerRemovedSlash = manufacturerLink.slice(1);
				return manufacturerRemovedSlash.replace("#", "");
			}
			return manufacturerLink;
		}
		case "powershades":
			return "";
		default:
			throw new Error("Something went wrong trying to get the entity link from the user.");
	}
};

/**
 * TypeCheck for API responses that may contain an error.
 * @returns true if the response contains an error, false otherwise.
 * @param response - The response to check for an error.
 */
export const hasErrorInAPIResponse = (response: any): response is { error?: string } =>
	response && typeof response.error === "string";

export const shortenMotorType = (motorType: string) => {
	switch (motorType) {
		case "low_voltage":
			return "Li-Ion";
		case "high_voltage":
			return "AC";
		case "low_voltage-hw":
			return "LV";
		case "poe":
			return "PoE";
		default:
			return motorType;
	}
};

export const prettifySideChannels = (shade: AssemblyShadePayload) => {
	const color = shade.side_channels_color ?? "";
	switch (shade.side_channels) {
		case "None":
			return "No Side Channels";
		case "Sill Channels":
			return `${color} Sill Channels`;
		case "Side Channels":
			return `${color} Side Channels`;
		case "Both":
			return `${color} Side & Sill Channels`;
		default:
			return shade.side_channels;
	}
};

export const API_OPTIONS = (callLocation: string, options = {}) => ({
	headers: { Authorization: `Bearer ${getToken()}` },
	timeout: 20000,
	timeoutErrorMessage: callLocation
		? `Our servers are taking longer than usual to respond. ${callLocation} call failed.`
		: "Our servers are taking longer than usual to respond. Please try again later.",
	...options
});

/**
 * @desc Helper function for running an axios get function, but only
 * for calls returning a file. This function auto downloads the file.
 * @params url - The backend url to call.
 * @params fileName - The name of the file to download.
 */
export const apiGetFile = (url: string, fileName: string) =>
	axios({
		url: `${process.env.BACKEND_ROOT}/api/${url}`,
		method: "GET",
		responseType: "blob",
		...API_OPTIONS(url)
	}).then((response) => {
		// create file link in browser's memory
		const href = URL.createObjectURL(response.data);

		// create "a" HTML element with href to file & click
		const link = document.createElement("a");
		link.href = href;
		link.setAttribute("download", fileName); // or any other extension
		document.body.appendChild(link);
		link.click();

		// clean up "a" element & remove ObjectURL
		document.body.removeChild(link);
		URL.revokeObjectURL(href);
	});

/**
 * @desc Helper function for an axios post to upload a file
 * to the backend.
 * @params url - The backend url to call.
 * @params formData - The form data to send.
 * @returns A promise that resolves to a boolean, being the success status.
 */
export const apiUploadFile = (url: string, formData: FormData) => new Promise<boolean>((resolve, reject) => {
	axios
		.post(`${process.env.BACKEND_ROOT}/api/${url}`, formData, { ...API_OPTIONS(url) })
		.then((resp) => {
			if (resp.status === 200) {
				resolve(true);
			} else {
				resolve(false);
			}
		})
		.catch((err) => {
			reject(err);
		});
});

/**
 * @desc Helper function for running an axios get function.
 * @params url - The backend url to call.
 */
export const apiGet = <Type>(
	url: string,
	options: any = {}
): Promise<{
	data: Type;
	status: number;
	error?: any;
	headers?: any;
}> =>
	axios.get(`${process.env.BACKEND_ROOT}/api/${url}`, API_OPTIONS(url, options))
		.catch((_err) => {
			// let errorMessage = '';
			// if (err.response?.status === 403) {
			// 	errorMessage = 'You do not have permission to access this page.';
			// } else {
			// 	errorMessage = 'Something went wrong. Please try again later.';
			// }
			// alert({ quickFormat: 'error', text: `${errorMessage}` });
			const data: Type = _err.response?.data ?? null;
			return new Promise((resolve) => {
				resolve({
					data,
					status: 400,
					error: _err
				});
			});
		});

/**
 * @desc Helper function for running an axios post function.
 * @params url - The backend url to call.
 */
export const apiPost = <Type>(
	url: string,
	body?: any,
	options = {}
): Promise<{ data: Type, error?: any, status: number }> =>
	axios
		.post(`${process.env.BACKEND_ROOT}/api/${url}`, body, API_OPTIONS(url, options))
		.then((response) => ({
			data: response.data,
			status: response.status,
		}))
		.catch((error) => {
			const data: Type = error.response?.data ?? null;
			const customError = new Error('API request failed');
			(customError as any).data = data;
			(customError as any).status = error.response?.status ?? 500;
			(customError as any).originalError = error;
			return Promise.reject(customError);
		});

/**
 * @desc Legacy call helper function that allows us to route back legacy calls to the backend
 * using our new methodology.
 * @params action - The action to call.
 * @params body - The body of the request (if any).
 */
// ? Is the 'extends any' necessary here?
// eslint-disable-next-line
export const legacyCall = <oType extends any>(
	action: string,
	body?: any,
	options: any = {}
): Promise<{ data: oType, status: number, error?: any }> => {
	const finalBody = {
		...body,
		action,
		user_jwt: getToken()
	};

	EnsureConnectionMade();

	if (body?.quote_id !== undefined) {
		const quoteId = body.quote_id;
		wentToQuote(quoteId);
	}

	return new Promise((
		resolve,
			error // eslint-disable-line
	) =>
	// eslint-disable-next-line
			axios
			.post(
				`${process.env.BACKEND_ROOT}/api/Legacy?action=${action}`,
				finalBody,
				API_OPTIONS(`Legacy/${action}`, options)
			)
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				error(err);
			}));
};

/**
 * @desc Helper function for running an axios delete function.
 * @params url - The backend url to call.
 */
export const apiDelete = <Type>(
	url: string,
	options = {}
): Promise<{ data: Type, error?: any, status: number }> =>
	axios
		.delete(`${process.env.BACKEND_ROOT}/api/${url}`, { ...API_OPTIONS(url), ...options })
		.catch((_err) => {
			const data: Type = _err.response?.data ?? null;
			return new Promise((resolve) => {
				resolve({
					data,
					status: 400,
					error: _err
				});
			});
		});

/**
 * @desc Helper function for running an axios put function.
 * @params url - The backend url to call.
 */
export const apiPut = <Type>(
	url: string,
	body?: any,
	options = {}
): Promise<{ data: Type, status: number, error?: any }> =>
	axios
		.put(`${process.env.BACKEND_ROOT}/api/${url}`, body, { ...API_OPTIONS(url), ...options })
		.catch((_err) => new Promise((resolve) => {
			const data: Type = _err.response?.data ?? null;
			resolve({
				data,
				status: 400,
				error: _err
			});
		}));

/**
 * Selector that does a deep comparison of the previous and current state.
 */
export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const isAxiosError = (error: any): error is AxiosError => error && error.isAxiosError;

export const hasDataError = (error: any): error is { data: { error: string } } => error
	&& error.data
	&& error.data.error;

export const hasResponseDataError = (error: any): error is {
	response: {
		data: { error: string };
	};
} => error
	&& error.response
	&& error.response.data
	&& error.response.data.error;

export const hasSuccessBoolean = (response: PowerShadesAPIResponse<any>):
	response is PowerShadesAPIResponse<successResp> => response
	&& typeof response?.data?.success === "boolean";

export const hasPropertyOnObject = <T extends Record<string, unknown>, K extends keyof T>(
	obj: T,
	key: K
): obj is T & Record<K, T[K]> => obj && key in obj;

export const isPossibleAPIError = (error: any): error is PossibleAPIError =>
	error instanceof AxiosError || (error && 'status' in error);

export const hasErrorMessage = (error: PossibleAPIError): boolean => {
	if (isAxiosError(error) && error.response?.data?.error) {
		return true;
	}

	if (hasDataError(error)) {
		return true;
	}

	if (hasResponseDataError(error)) {
		return true;
	}

	return false;
};

export const extractErrorMessage = (error: PossibleAPIError): string => {
	if (isAxiosError(error) && error.response?.data?.error) {
		return error.response.data.error;
	}

	if (hasDataError(error)) {
		return error.data.error;
	}

	if (hasResponseDataError(error)) {
		return error.response.data.error;
	}

	return "Something went wrong and no error message was provided.";
};

// Overloads
export function isFailedApiCall<T extends Record<string, unknown>>(
	response: PowerShadesAPIResponse<T> | undefined,
	key?: keyof T
): boolean;
export function isFailedApiCall(
	response: PowerShadesAPIResponse<unknown> | undefined
): boolean;

// Function implementation
export function isFailedApiCall(
	response: PowerShadesAPIResponse<any> | undefined,
	key?: string | number | symbol
): boolean {
	//console.log("Checking API response for failures:", response);

	// Check for failed status codes, e.g., anything other than 2xx
	if (response && (response.status < 200 || response.status >= 300)) {
		console.log("Failed API call due to status code:", response.status);
		return true;
	}

	const responseIsObject = isObject(response);
	const hasDataObject = responseIsObject && hasPropertyOnObject(response, "data");

	// If key passed through in parameter, check if it exists on the data object
	if (responseIsObject && hasDataObject && key) {
		const hasKey = key in response.data;
		if (!hasKey) {
			console.log(`Warning: Key '${String(key)}' is missing in response data, but status code is 2xx`);
		}
	}

	// Check using success boolean
	if (response && hasSuccessBoolean(response)) {
		const { success } = response.data;
		if (!success) {
			console.log("Failed API call due to success boolean being false");
			return true;
		}
	}

	// Check using extractErrorMessage or any other error indicators
	if (response?.error && isPossibleAPIError(response.error) && hasErrorMessage(response.error)) {
		console.log("Failed API call due to error message:", extractErrorMessage(response.error));
		return true;
	}

	return false;
}

export const captureSentryError = (exception: any, captureContext?: CaptureContext) => {
	console.error(exception);
	if (isAxiosError(exception)) {
		const axiosErrorContext: CaptureContext = {
			tags: {
				section: "api"
			}
		};
		const combinedContext = { ...axiosErrorContext, ...captureContext };
		sentryCaptureException(exception, combinedContext);
		return;
	}
	sentryCaptureException(exception, captureContext);
	const debug = sentryCaptureException;
	console.log(debug);
};

/**
 * Checks if the given string is in the format "Mon DD". Does not check for year.
 * This is a soft comparison - meaning it will return true if the string
 * contains a substring that matches the format.
 * @param dateString The date string to check
 * @returns Boolean indicating if the string is in the format "Mon DD"
 */
export const checkIfAbbreviatedMonthDayFormat = (dateString: string): boolean => {
	const dateRegex = /\b(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\s+\d{1,2},\s+\d{4}\b/g;
	return dateRegex.test(dateString);
};

/**
 * Gets a tracking link for the given tracking number and carrier.
 * @param trackingNumber - The tracking number to get a link for.
 * @param carrier - The carrier to get a link for.
 * @returns A string containing a link to track the given tracking number.
 */
export const getTrackingLink = (trackingNumber: string | undefined, carrier: string | undefined) => {
	if (!trackingNumber || !carrier) return undefined;
	const carrierLower = carrier.toLowerCase();
	if (carrierLower.includes("fedex")) {
		return `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${trackingNumber}`;
	}
	if (carrierLower.includes("ups")) {
		return `https://www.ups.com/track?loc=en_US&tracknum=${trackingNumber}`;
	}
	if (carrierLower.includes("usps")) {
		return `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`;
	}
	return undefined;
};

export const isQuoteShipment = (shipment: any): shipment is QuoteShipment =>
	(shipment as QuoteShipment).carrier !== undefined;

export const isOptionObject = (option: any): option is Record<string, any> =>
	typeof option === "object" && option !== null && !Array.isArray(option);

export const hasCustomOptionProperty = <T>(option: T): option is T & { customOption: any } =>
	!!option && typeof option === 'object' && 'customOption' in option;

export const hasCustomOptionAndLabelProperty = <T>(option: T): option is T & { customOption: any, label: string } =>
	!!option && typeof option === 'object' && 'customOption' in option && 'label' in option;

export const toCamelCase = (obj) => {
	if (Array.isArray(obj)) {
		return obj.map((item) => toCamelCase(item));
	} else if (isObject(obj)) {
		return mapValues(
			mapKeys(obj, (_value, key) => camelCase(key)),
			(value) => toCamelCase(value)
		);
	}
	return obj;
};

// Filter and sort
export const onlyActive = (options: (any | undefined)) => (
	options?.filter((option) => option?.isActive ?? true)?.sort((o1, o2) => o1.value?.localeCompare(o2?.value ?? "") ?? 0) ?? []
) as PortalShadeOptionItem<string>[];
