import { useState, useEffect } from 'react';

import HookImplementation from './Parts/HookImplementation';
import api from './PowerShadesAPI';

export default function QuotePricing(quote) {
	const hooks = new HookImplementation();
	let pricing_data = {};

	function setPricingData(new_data) {
		pricing_data = new_data;

		hooks.execute('updated');
		quote.shades.forEach((s) => s.pricingUpdated());
	}

	function getPricingData() {
		return pricing_data;
	}

	function getShadeAssemblyPricingData(shade_assembly) {
		if (shade_assembly.id) {
			return (pricing_data.shade_assemblies || []).find((sa) => (sa.id && parseInt(sa.id) === parseInt(shade_assembly.id)));
		}

		return (pricing_data.shade_assemblies || []).find((sa) => (sa.local_id && parseInt(sa.local_id) === parseInt(shade_assembly.getLocalID())));
	}

	function duplicateShadeAssembly(shade_assembly_id, local_id) {
		const new_shade = JSON.parse(JSON.stringify(pricing_data.shade_assemblies.find((s) => s.id === shade_assembly_id)));
	
		new_shade.id = 0;
		new_shade.local_id = local_id;
	
		pricing_data.shade_assemblies.push(new_shade);
	
		return (id) => new_shade.id = id;
	}

	return {
		hooks,
		getPricingData,
		setPricingData,
		getShadeAssemblyPricingData,
		duplicateShadeAssembly
	};
}

export function usePricingData(quote_pricing_object) {
	const [data, setData] = useState(null);

	function refresh() {
		setData(quote_pricing_object.getPricingData());
	}

	useEffect(() => {
		if (quote_pricing_object) {
			const h_id = quote_pricing_object.hooks.add('updated', refresh);

			return () => quote_pricing_object.hooks.remove(h_id);
		}
	}, []);

	if (!quote_pricing_object) {
		return null;
	}

	return data;
}

export function usePricingDataField(quote_pricing_object, field_name) {
	const pricing_data = usePricingData(quote_pricing_object);

	return pricing_data.field_name;
}

export function usePricingDataAccessory(quote_pricing_object, accessory_id) {
	const pricing_data = usePricingData(quote_pricing_object);
	
	if (!pricing_data) {
		return null;
	}

	const accessory = (pricing_data.accessories || []).find((acc) => acc.accessory_id && parseInt(acc.accessory_id) === parseInt(accessory_id));

	return accessory;
}

export function usePricingShadeAssembly(quote_pricing_object, shade_assembly) {
	const pricing_data = usePricingData(quote_pricing_object);
		
	if (!pricing_data) {
		return null;
	}

	if (shade_assembly.id) {
		return (pricing_data.shade_assemblies || []).find((sa) => (sa.id && parseInt(sa.id) === parseInt(shade_assembly.id)));
	}

	return (pricing_data.shade_assemblies || []).find((sa) => (sa.local_id && parseInt(sa.local_id) === parseInt(shade_assembly.getLocalID())));

	return null;
}

export function useCalculateShadeMSRP(quote, shade_assembly) {
	const [pricing_data, setPricingData] = useState(null);

	useEffect(() => {
		api.calculateShadeMSRPForQuote(quote.ID, shade_assembly.savableObject())
		   .then(({ data }) => {
		   		setPricingData(data.msrp);
		   });
	}, []);

	return pricing_data;
}
