import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InfoBox = (props) => {
	let { message } = props;

	if (!Array.isArray(message)) {
		message = [message];
	}

	let first = true;

	const messageItems = message.map((m) => {
		const disp = (
			<>
				{!first ? <br /> : null}
				{m}
			</>
		);
		first = false;

		return disp;
	});

	return (

		<div className="info-box">
			{(props.hide ? null : (
				<span>
					{/* <FontAwesomeIcon icon={faInfoCircle} className={`${props.quoteType ?? null}`} /> */}
					{messageItems}
				</span>
			))}
		</div>
	);
};

export default InfoBox;
