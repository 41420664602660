
import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { Card, Col, Form, Row, Table } from 'react-bootstrap';
import Button from '../Parts/Button';
import { useActiveRMATypes, useRMADepartmentList, useRMARootCauseList } from '../Store/entities/rmaTypes/hooks';
import { UsePortalDispatch } from '../Store';
import { addRMADepartment, addRMARootCause, addRMAType, archiveRMAType } from '../Store/entities/rmaTypes/index';
import { useModal } from '../Store/ui/hooks';
import PowerShadesTypeahead from '../Parts/PowerShadesTypeahead';

const RMATypes = () => {
	const rmatypes = useActiveRMATypes();
	const [newName, setNewName] = useState('');
	const [newDescription, setNewDescription] = useState('');
	const [newDepartmentId, setNewDepartmentId] = useState(0);
	const [newRootCauseId, setNewRootCauseId] = useState(0);
	const [openAddRMATypeModal, setOpenAddRMATypeModal] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const departmentList = useRMADepartmentList();

	const [currentTab, setCurrentTab] = useState("RMATypes")

	const departmentListOptions = departmentList.map((department) => {
		return {
			label: department.name ?? "",
			value: department.id ?? ""
		};
	});

	const selectedDepartmentOption = departmentListOptions.find((department) => department.value === newDepartmentId);

	const rootCauseList = useRMARootCauseList();

	const rootCauseListOptions = rootCauseList.map((rootCause) => {
		return {
			label: rootCause.name,
			value: rootCause.id
		};
	});

	const selectedRootOption = rootCauseListOptions.find((rootCause) => rootCause.value === newRootCauseId);

	const dispatch = UsePortalDispatch();

	const modal = useModal();

	const onRMAAdd = () => {
		setIsSubmitting(true);

		dispatch(addRMAType({
			id: 0, name: newName, description: newDescription, default_department: selectedDepartmentOption?.label ?? "", archived: false, default_root_cause: selectedRootOption?.label ?? ""
		})).then(() => {
			setOpenAddRMATypeModal(false);
			setNewName('');
			setIsSubmitting(false);
			setNewDescription('');
		});
	};

	const onDepartmentAdd = () => {
		setIsSubmitting(true);

		dispatch(addRMADepartment({
			id: 0, name: newName, description: newDescription, archived: false
		})).then(() => {
			setOpenAddRMATypeModal(false);
			setNewName('');
			setIsSubmitting(false);
			setNewDescription('');
		});
	}

	const onRootCauseAdd = () => {
		setIsSubmitting(true);

		dispatch(addRMARootCause({
			id: 0, name: newName, description: newDescription, archived: false
		})).then(() => {
			setOpenAddRMATypeModal(false);
			setNewName('');
			setIsSubmitting(false);
			setNewDescription('');
		});
	}

	const openModal = () => {
		setOpenAddRMATypeModal(true);
	};

	const onDelete = (id: number) => {
		modal({
			title: 'Are you sure?',
			text: 'Are you sure you want to delete this RMA Type?',
			onCancel: () => { setOpenAddRMATypeModal(false); },
			onConfirm: () => {
				dispatch(archiveRMAType(id));
			}
		});
	};

	const tabs = [
		{ name: 'RMATypes' },
		{ name: 'Departments' },
		{ name: 'Root Causes' }
	];

	const rmaTypeTable = (
		<Row>
			<Table>
				<thead>
					<tr>
						<th>Id</th>
						<th>Name</th>
						<th>Description</th>
						<th>Default Root Cause</th>
						<th>Default Department</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{rmatypes.map((RMAType) => {
						return (
							<tr key={`${RMAType.id}-${RMAType.name}`}>
								<td>{RMAType.id}</td>
								<td>{RMAType.name}</td>
								<td>{RMAType.description}</td>
								<td>{RMAType.default_department}</td>
								<td>{RMAType.default_root_cause}</td>
								<td>
									<Button
										onClick={() => onDelete(RMAType.id)}
									>
										Delete
									</Button>
								</td>
							</tr>
						)
					})}
					<tr key="add_type">
						<td colSpan={5}>
							<Button onClick={() => openModal()}>Add RMAType</Button>
						</td>
					</tr>
				</tbody>
			</Table>
			<Popup
				open={openAddRMATypeModal}
				onClose={() => setOpenAddRMATypeModal(false)}
				className="mobile-modal"
			>
				<Row>
					<h1>
						Add RMA Type
					</h1>
				</Row>
				<Row>
					<Col>
						<Row>
							<Col>
								<label>Name</label>
							</Col>
							<Col>
								<Form.Control value={newName} onChange={(e) => setNewName(e.target.value)} name="name" id="name" />

							</Col>
						</Row>
						<Row>
							<Col>
								<label>Description</label>
							</Col>
							<Col>
								<Form.Control value={newDescription} onChange={(e) => setNewDescription(e.target.value)} name="description" id="description" />
							</Col>
						</Row>
						<Row>
							<Col>
								<label>Department</label>
							</Col>
							<Col>
								<PowerShadesTypeahead
									id="department-list"
									options={departmentListOptions}
									// Options in Type Ahead make no sense.
									onChange={(e) => setNewDepartmentId((e[0] as { value: number } ?? { value: 0 }).value)}
									selected={selectedDepartmentOption ? [selectedDepartmentOption] : []}
									labelKey={"label"}
								/>
								{/* <Form.Control value={newDepartment} onChange={(e) => setNewDepartment(e.target.value)} name="department" id="department" /> */}
							</Col>
						</Row>
						<Row>
							<Col>
								<label>Root Cause</label>
							</Col>
							<Col>
								<PowerShadesTypeahead
									id="root-cause-list"
									options={rootCauseListOptions}
									// Options in Type Ahead make no sense.
									onChange={(e) => setNewRootCauseId((e[0] as { value: number } ?? { value: 0 }).value)}
									selected={selectedRootOption ? [selectedRootOption] : []}
									labelKey={"label"}
									/>
								{/* <Form.Control value={newDepartment} onChange={(e) => setNewDepartment(e.target.value)} name="department" id="department" /> */}
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Button
						disabled={isSubmitting}
						onClick={onRMAAdd}
					>
						Add
					</Button>
				</Row>
			</Popup>
		</Row>
	)

	const rmaDepartmentTable = (
		<Row>
			<Table>
				<thead>
					<tr>
						<th>Id</th>
						<th>Name</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{departmentList.map((department) => {
						return (
							<tr key={`${department.id}-${department.name}`}>
								<td>{department.id}</td>
								<td>{department.name}</td>
								<td>
									<Button
										onClick={() => onDelete(department.id)}
									>
										Delete
									</Button>
								</td>
							</tr>
						)
					})}
					<tr key="add_department">
						<td colSpan={3}>
							<Button onClick={() => openModal()}>Add Department</Button>
						</td>
					</tr>
				</tbody>
			</Table>
			<Popup
				open={openAddRMATypeModal}
				onClose={() => setOpenAddRMATypeModal(false)}
				className="mobile-modal"
			>
				<Row>
					<h1>
						Add RMA Department
					</h1>
				</Row>
				<Row>
					<Col>
						<Row>
							<Col>
								<label>Name</label>
							</Col>
							<Col>
								<Form.Control value={newName} onChange={(e) => setNewName(e.target.value)} name="name" id="name" />

							</Col>
						</Row>
						<Row>
							<Col>
								<label>Description</label>
							</Col>
							<Col>
								<Form.Control value={newDescription} onChange={(e) => setNewDescription(e.target.value)} name="description" id="description" />
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Button
						disabled={isSubmitting}
						onClick={onDepartmentAdd}
					>
						Add
					</Button>
				</Row>
			</Popup>
		</Row>
	)

	const rmaRootCauseTable = (
		<Row>
			<Table>
				<thead>
					<tr>
						<th>Id</th>
						<th>Name</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{rootCauseList.map((rootCause) => {
						return (
							<tr key={`${rootCause.id}-${rootCause.name}`}>
								<td>{rootCause.id}</td>
								<td>{rootCause.name}</td>
								<td>
									<Button
										onClick={() => onDelete(rootCause.id)}
									>
										Delete
									</Button>
								</td>
							</tr>
						)
					})}
					<tr key="add_root_cause">
						<td colSpan={3}>
							<Button onClick={() => openModal()}>Add Root Cause</Button>
						</td>
					</tr>
				</tbody>
			</Table>
			<Popup
				open={openAddRMATypeModal}
				onClose={() => setOpenAddRMATypeModal(false)}
				className="mobile-modal"
			>
				<Row>
					<h1>
						Add RMA Root Cause
					</h1>
				</Row>
				<Row>
					<Col>
						<Row>
							<Col>
								<label>Name</label>
							</Col>
							<Col>
								<Form.Control value={newName} onChange={(e) => setNewName(e.target.value)} name="name" id="name" />

							</Col>
						</Row>
						<Row>
							<Col>
								<label>Description</label>
							</Col>
							<Col>
								<Form.Control value={newDescription} onChange={(e) => setNewDescription(e.target.value)} name="description" id="description" />
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Button
						disabled={isSubmitting}
						onClick={onRootCauseAdd}
					>
						Add
					</Button>
				</Row>
			</Popup>
		</Row>
	)

	return (
		<Card>
			<Row>
				{
					tabs.map((tab) => {
						return (
							<Col className='m-4'>
								<h2>
									<label
										className={`${tab.name == currentTab ? "underlined" : ""}`}
										onClick={() => setCurrentTab(tab.name)}
									>{tab.name}</label>
								</h2>
							</Col>
						)
					})
				}
			</Row>
			{currentTab == "RMATypes" && rmaTypeTable}
			{currentTab == "Departments" && rmaDepartmentTable}
			{currentTab == "Root Causes" && rmaRootCauseTable}
		</Card>
	);
};

export default RMATypes;
