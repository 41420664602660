import Button from "../Parts/Button";

const QuoteLocked = (props) => {
	const { viewingUser, quoteID } = props;

	return (
		<div className="modal-holder">
			<div
				className="standard-modal"
				style={{ maxWidth: '29em', padding: '1em 2em 2em 2em' }}
			>
				<h2 style={{ textAlign: 'left' }}>Quote Locked</h2>

				<div>
					Quote
					#{quoteID}
					is currently being viewed by another user and can't be edited.
					<br />
					<span style={{ display: 'inline-block', marginTop: '1em' }}>
						User:
						<b>{viewingUser.email}</b>
					</span>
				</div>

				<br />
				<br />

				<div
					style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-evenly',
						marginBottom: '1em',
					}}
				>
					<Button color="gray" onClick={props.cancel}>
						Cancel
					</Button>
					<Button
						color="green"
						onClick={props.readOnlyMode}
					>
						View in Read-Only Mode
					</Button>
				</div>
			</div>
		</div>
	);
};

export default QuoteLocked;
