import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIsPowerShadesAdmin, useUserData } from '../hooks';
import {
	useDealers,
	useDistributors,
	useRepresentatives,
	useTerritories,
} from '../Store/entities/hooks';
import TerritorySelect from '../Parts/TerritorySelect';
import DistributorSelect from '../Parts/DistributorSelect';
import RepresentativeSelect from '../Parts/RepresentativeSelect';
import DealerSelect from '../Parts/DealerSelect';
import { initialUserState } from '../Store/user';
import Button from '../Parts/Button';
import ManufacturerSelect from '../Parts/ManufacturerSelect';
import { UserState } from '../powershadesApiTypeExtensions';

const AddUser = () => {
	const [currentTerritoryId, setCurrentTerritoryId] = useState(0);
	const [currentRepId, setCurrentRepId] = useState(0);
	const [currentDistributorId, setCurrentDistributorId] = useState(0);
	const [currentDealerId, setCurrentDealerId] = useState(0);
	const [currentManufacturerId, setCurrentManufacturerId] = useState(0);

	const [selectedUserRoleId, setSelectedUserRoleId] = useState(0);

	const distributors = useDistributors();
	const territories = useTerritories();
	const representatives = useRepresentatives();
	const dealers = useDealers();
	const userData = useUserData();

	/*
const role_opts = [
		<optgroup key="g0" label="Select Role">
			<option value={0}>Select Role</option>
		</optgroup>,

		<optgroup key="g1" label="Dealer">
			<option value={13}>Dealer Admin</option>
			<option value={14}>Dealer Salesperson</option>
			<option value={15}>Dealer User</option>
			<option value={20}>Dealer Purchasing</option>
		</optgroup>,

		<optgroup key="g2" label="Distributor">
			<option value={11}>Distributor Admin</option>
			<option value={12}>Distributor User</option>
		</optgroup>,

		<optgroup key="g6" label="Distributor Location">
			<option value={11}>Distributor Admin</option>
			<option value={12}>Distributor User</option>
		</optgroup>,

		<optgroup key="g3" label="Representative">
			<option value={9}>Representative Admin</option>
			<option value={10}>Representative User</option>
		</optgroup>,

		<optgroup key="g5" label="Manufacturer">
			<option value={16}>Manufacturer Admin</option>
			<option value={17}>Manufacturer User</option>
		</optgroup>,

		<optgroup key="g4" label="PowerShades User">
			<option value={8}>PowerShades Admin</option>
		</optgroup>,
	];
	*/

	const allRoles = [
		{ id: 13, name: 'Dealer Admin' },
		{ id: 14, name: 'Dealer Salesperson' },
		{ id: 15, name: 'Dealer User' },
		{ id: 20, name: 'Dealer Purchasing' },
		{ id: 11, name: 'Distributor Admin' },
		{ id: 12, name: 'Distributor User' },
		{ id: 9, name: 'Representative Admin' },
		{ id: 10, name: 'Representative User' },
		{ id: 16, name: 'Manufacturer Admin' },
		{ id: 17, name: 'Manufacturer User' },
		{ id: 8, name: 'PowerShades Admin' },
	];

	const isPsAdmin = useIsPowerShadesAdmin();

	const [workingUser, setWorkingUser] = useState({
		...initialUserState,
		password: "",
		confirm_password: ""
	});

	const updateUser = (updateObject: Partial<
		UserState
		& { password: string, confirm_password: string }
	>) => {
		setWorkingUser({ ...workingUser, ...updateObject });
	};

	const [autoPass, setAutoPass] = useState(true);
	const [confirmPassword, setConfirmPassword] = useState('');

	const displayTerritorySelector = isPsAdmin || territories.length > 1;
	const displayDealerSelector = isPsAdmin || dealers.length > 1;
	const displayDistributorSelector = isPsAdmin || distributors.length > 1;
	const displayRepresentativeSelector = isPsAdmin || representatives.length > 1;

	const userDistributor = distributors.find((d) => d.id === userData?.roles[0]?.entity_id);
	const currentDistributor = userDistributor
		?? distributors.find((di) => di.id === currentDistributorId);
	const userRep = representatives.find((d) => d.id === userData?.roles[0]?.entity_id);
	const currentRep = userRep ?? representatives.find((re) => re.id === currentRepId);

	// Filters the representatives based on the selected territory.
	const filteredReps = representatives.filter((rep) => {
		if (currentTerritoryId === 0 || !currentTerritoryId) return true;
		const repHasTerritory = rep.territory_ids.includes(currentTerritoryId);
		return repHasTerritory;
	});

	// Filters the distributors based on the selected territory.
	const filteredDistributors = distributors.filter((dist) => {
		if (currentTerritoryId === 0 || !currentTerritoryId) return true;
		const distHasTerritory = dist.territory_ids.includes(currentTerritoryId);
		return distHasTerritory;
	});

	// Filters the dealers based on the selected territory.
	const filteredDealers = dealers.filter((dealer) => {
		if (currentTerritoryId === 0 || !currentTerritoryId) return true;
		const dealerHasTerritory = dealer?.territory_ids?.includes(currentTerritoryId);
		return dealerHasTerritory;
	});

	console.debug(currentDealerId);
	console.debug(currentDistributorId);
	console.debug(currentRepId);
	console.debug(currentTerritoryId);

	const territorySelected = currentTerritoryId !== 0;
	const distributorSelected = currentDistributorId !== 0;
	const repSelected = currentRepId !== 0;
	const dealerSelected = currentDealerId !== 0;
	const manufacturerSelected = currentManufacturerId !== 0;
	const disableDistributor = !territorySelected
		|| repSelected
		|| dealerSelected
		|| manufacturerSelected;
	const disableRepresentative = !territorySelected
		|| distributorSelected
		|| dealerSelected
		|| manufacturerSelected;
	const disableDealer = !territorySelected
		|| distributorSelected
		|| repSelected
		|| manufacturerSelected;
	const disableManufacturer = !territorySelected
		|| distributorSelected
		|| repSelected
		|| dealerSelected;
	const selectionMade = distributorSelected
		|| repSelected
		|| dealerSelected;
	console.debug(selectionMade);

	useEffect(() => {
		if (territories.length <= 1) {
			if (currentRep) {
				const repTerritory = currentRep.territory_ids[0];
				if (repTerritory) setCurrentTerritoryId(repTerritory);
			}
		}
	}, [territories]);

	useEffect(() => {
		if (distributors.length <= 1) {
			if (currentDistributor) {
				const distTerritory = currentDistributor.territory_ids[0];
				if (distTerritory) setCurrentTerritoryId(distTerritory);
			}
		}
	}, [distributors]);

	const alowedRoles = allRoles.filter((role) => {
		if (dealerSelected) {
			return role.name.includes('Dealer');
		}

		if (distributorSelected) {
			return role.name.includes('Distributor');
		}

		if (repSelected) {
			return role.name.includes('Representative');
		}

		if (manufacturerSelected) {
			return role.name.includes('Manufacturer');
		}

		return false;
	});

	const createUser = () => {
		/* filling soon */
	};

	const roleGroups = alowedRoles.map((role) => (
		<option key={role.id} value={role.id}>
			{role.name}
		</option>
	));

	return (
		<Container>
			<Card body className="entity-card">
				<Row>
					{displayTerritorySelector && (
						<Col>
							<TerritorySelect handleChange={setCurrentTerritoryId} />
						</Col>
					)}
					{displayDistributorSelector && (
						<Col>
							<DistributorSelect
								disableOverride={disableDistributor}
								optionsOverride={filteredDistributors}
								handleChange={setCurrentDistributorId}
							/>
						</Col>
					)}
					{displayRepresentativeSelector && (
						<Col>
							<RepresentativeSelect
								disableOverride={disableRepresentative}
								optionsOverride={filteredReps}
								handleChange={setCurrentRepId}
							/>
						</Col>
					)}
					{displayDealerSelector && (
						<Col>
							<DealerSelect
								disableOverride={disableDealer}
								optionsOverride={filteredDealers}
								handleChange={setCurrentDealerId}
							/>
						</Col>
					)}
					{displayDealerSelector && (
						<Col>
							<ManufacturerSelect
								disableOverride={disableManufacturer}
								handleChange={setCurrentManufacturerId}
							/>
						</Col>
					)}
				</Row>
				<Row>
					<Col>
						<Row>
							<Col>
								<label>Name</label>
								<br />
								<input
									type="text"
									value={workingUser.name}
									onChange={(e) => updateUser({ name: e.target.value })}
								/>
							</Col>
							<Col>
								<label>Email</label>
								<br />
								<input
									type="email"
									value={workingUser.email}
									onChange={(e) => updateUser({ email: e.target.value })}
								/>
							</Col>

						</Row>

						<Row>
							<label>Role</label>
							<br />
							<select
								value={selectedUserRoleId}
								onChange={(e) => setSelectedUserRoleId(parseInt(e.target.value, 10))}
							>
								{roleGroups}
							</select>
						</Row>

						<Row className="auto-select-password" style={{ width: '100%', marginTop: '20px' }}>
							<input type="checkbox" checked={autoPass} onChange={() => setAutoPass(!autoPass)} />
							<label>Auto Select Password</label>
						</Row>

						<Row className="full-width">
							<div>
								<label>Password</label>
								<br />
								<input
									type={autoPass ? 'text' : 'password'}
									readOnly={autoPass}
									value={workingUser.password}
									onChange={(e) => updateUser({ password: e.target.value })}
								/>
							</div>

							{autoPass ? null : (
								<div>
									<label>Confirm Password</label>
									<br />
									<input
										type="password"
										value={confirmPassword}
										onChange={(e) => setConfirmPassword(e.target.value)}
									/>
								</div>
							)}
						</Row>

						<Row className="button-holder">
							<Button disabled={!selectionMade} color="green" onClick={createUser}>
								<FontAwesomeIcon icon={faPlus} />
								{' '}
								Create
							</Button>
						</Row>
					</Col>
				</Row>
			</Card>
		</Container>
	);
};

export default AddUser;
