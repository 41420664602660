import React, { useCallback, useEffect, useState } from "react";
import {
	Card, Col, Container, Row, Table
} from "react-bootstrap";

import apiCalls from '../PowerShadesAPIFunctions';
import ContractorAdd from "./Modals/ContractorAdd";

import { Contractor } from "./contractors";

const ContractorList = ({
	add, view, channels, contractorString
}) => {
	const [contractors, setContractors] = useState<Contractor[]>([]);

	const inputContractors = useCallback(() => {
		apiCalls.listOrganizations(contractorString).then((resp) => {
			const { data } = resp;

			if (data.success) {
				setContractors(data.contractors);
			}
		});
	}, [contractorString]);

	useEffect(() => {
		inputContractors();
	}, [inputContractors]);

	const addContract = (inp, ...callbacks) => {
		const newCallbacks = [
			...callbacks,
			() => {
				inputContractors();
			},
		];

		add(inp, ...newCallbacks);
	};

	const contractorRows = contractors.map((c) => (
		<tr onClick={() => view(c.id)}>
			<td>{c.name}</td>
			<td>{c.email}</td>
			<td>{c.phone}</td>
		</tr>
	));

	return (
		<Container className="local-bootstrap">
			<Card>
				<Card.Body>
					<Row className="mb-4">
						<Col>
							<h4 className="text-green">Contractors</h4>
						</Col>
						<Col className="text-end">
							<ContractorAdd channels={channels} add={addContract} />
						</Col>
					</Row>
					<Row>
						<Col>
							<Table striped hover responsive className="clickable-table">
								<tbody>
									<tr className="bold-row">
										<th>
											Name
										</th>
										<th>
											Email
										</th>
										<th>
											Phone
										</th>
									</tr>

									{contractorRows}
								</tbody>
							</Table>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Container>
	);
};

export default ContractorList;
