import { unstable_useBlocker as useBlocker } from 'react-router';
import { createSelector } from 'reselect';
import { useEffect } from 'react';
import { UsePortalSelector } from '.';
import type { AppState } from '.';
import { SelectModelOpen } from './ui';
import { ModalProps } from './ui/alert';
import { AlertProps } from '../FakeRootType';

export const useOldPrompt = (
	alertFunc: (alertParams: AlertProps) => Promise<void>,
	when = true
) => {
	const blocker = useBlocker(when);
	useEffect(() => {
		if (blocker.state === 'blocked' && when) {
			alertFunc({
				title: 'Changes Not Saved',
				text: 'You have unsaved changes. Are you sure you want to leave this page?',
				icon: 'warning',
			})
				.then(() => {
					blocker.proceed();
				})
				.catch(() => {
					blocker.reset();
				});
		}
	}, [blocker, when]);
};

export const usePrompt = (modalFunc: ((modalParams: ModalProps) => void), when = true) => {
	const blocker = useBlocker(when);
	useEffect(() => {
		if (blocker.state === "blocked" && when) {
			modalFunc({
				title: 'Changes Not Saved',
				text: 'You have unsaved changes. Are you sure you want to leave this page?',
				icon: 'warning',
				confirmButtonText: 'Leave',
				onConfirm: () => {
					blocker.proceed();
				},
				onCancel: () => {
					blocker.reset();
				},
			});
		}
	});
};

export const UseModalOpen = (modalKey: string) => UsePortalSelector(SelectModelOpen(modalKey));

/** 
 * Selects the application state (non-memoized).
 * @param state - The application state.
 * @returns The application state.
 */
const selectStateUnsafe = (state: AppState) => state;

/**
 * Selects the application state.
 * @returns The application state.
 */
const selectState = createSelector(selectStateUnsafe,
	(state) => state);

/**
 * Hook that selects the application state.
 * @returns The application state.
 */
export const useStatePortalState = () => UsePortalSelector(selectState);
