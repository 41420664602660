import React, { ReactNode, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { PortalShadeOption } from "../Store/entities/assemblies/types";
import InfoBox from "../Quote/ShadeForm/InfoBox";
import ImageToolTip from "../Quote/ImageTooltip";

const GroupHeader = (groupName: string, quoteType?: string) => (
	<Row className={`form-group-title text-center m-2  text-green border-success border-bottom text-${quoteType ?? "primary"}`}>
		<Col>
			{groupName}
		</Col>
	</Row>
);

type OptionFooterProps = {
	option: PortalShadeOption<any>;
	quoteType: string;
	optionField?: ReactNode;
};

const OptionFooter: (opt: OptionFooterProps) => JSX.Element = ({ option, quoteType, optionField }: OptionFooterProps) => {
	const {
		info,
		label,
		varName
	} = option;
	const [openFooter, setOpenFooter] = useState(varName);
	const clearOpenFooter = () => setOpenFooter("");

	const setOpen = () => setOpenFooter(varName);
	const open = openFooter === varName;
	if (!info || info.length === 0) return (optionField ?? <></>) as JSX.Element;

	const resp: JSX.Element = (
		open ? (

			(info && info.length > 0) ? (
				<>
					<div className="d-flex flex-col justify-content-between flex-grow-1">
						{optionField && <Col className="col-10">{optionField}</Col>}
						<Col>
							<Button
								color="green"
								onClick={() => clearOpenFooter()}
								style={{ minWidth: "40px", borderRadius: "6px", height: "38px", marginLeft: "5px" }}
							>
								<FontAwesomeIcon icon={faAngleDown} />
							</Button>
						</Col>
					</div>
					<Row>
						<InfoBox quoteType={quoteType} key={`info-box-${label}`} message={info} />
					</Row>
				</>
			) : (<></>)
		) : (

			<div className="d-flex flex-col justify-content-between flex-grow-1">
				{optionField && <Col>{optionField}</Col>}
				<Button
					color="green"
					onClick={() => setOpen()}
					style={{ minWidth: "40px", borderRadius: "6px", height: "38px", marginLeft: "5px" }}
				>
					<FontAwesomeIcon icon={faExclamationTriangle} />
				</Button>
			</div>
		)
	);

	return resp;
};

const OptionLabel = (option: PortalShadeOption<any>, toolTip = true) => {
	const { label, valuePopup = null } = option;

	return (

		<Row className="text-center mb-1 px-3" xs={12}>
			{
				// Only show icon and tooltip with these labels
				valuePopup && toolTip
					? <div className="d-flex justify-content-center position-relative z-50">
						<label className="w-100">
							{label}
						</label>
						<ImageToolTip option={option} label={label} />
					</div>
					: <label>{label}</label>
			}
		</Row>
	);
};

type COROptionLabelProps = {
	option: PortalShadeOption<any>;
	quoteType: string;
	showErrorsInitially: boolean | undefined;
};

const COROptionLabel = ({ option, quoteType, showErrorsInitially }: COROptionLabelProps) => (
	<Row className="text-center mb-1" xs={12 - (option.info.length + option.error.length)} style={{ margin: "0 auto" }}>
		<label >{option.label}</label>
		{option.info.length > 0 && (
			<InfoBox quoteType={quoteType} key={`info-box-${option.label}`} message={option.info} />
		)}
		{option.error.length > 0 && showErrorsInitially && (
			<InfoBox quoteType={quoteType} key={`info-box-${option.label}`} message={option.error} />
		)}
	</Row>
);

export {
	GroupHeader, OptionFooter, OptionLabel, COROptionLabel
};
