import React, { useEffect, useMemo, useState } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

import EntityPageHeader from '../Parts/EntityPageHeader';

import { useRepresentativeById, useTerritories, useUsersList } from '../Store/entities/hooks';
import { useQuoteMetaDispatch, useQuotes } from '../Store/entities/quotes/hooks';
import { UsePortalDispatch } from '../Store';

import BillingInformationHandler from '../ManageEntities/BillingInformationHandler';
import EntityPaymentOptions from '../ManageEntities/EntityPaymentOptions';
import EntityTaxInfo from '../ManageEntities/EntityTaxInfo';
import TabPaneWithSearchFilter from '../Parts/TabPaneWithSearchFilter';
import AddTerritory from '../Modals/AddTerritory';
import AddEmailDistribution from '../Modals/AddEmailDistribution';

import { initialRepresentativeState, updateRepresentativeFull } from '../Store/entities/organizations/representatives';
import { QuotePrefixById } from '../powershadesApiTypeExtensions';
import type { DistributionEmailType } from '../Dealer/types';
import type { TabPaneSelections } from '../Parts/types';
import type { RepresentativeViewProps } from './types';
import type { ChannelId, QuoteTypeIds } from '../powershadesApiTypes';
import { getTerritoryLink, getUserLink } from '../psUtil';
import { useModal } from '../Store/ui/hooks';
import { usePrompt } from '../Store/hooks';
import AddUserModal from '../User/AddUserModal';
import UserLookup from '../Modals/UserLookup';

const RepresentativeView = ({ ROOT }: RepresentativeViewProps) => {
	const {
		GET, navigate, setTitle
	} = ROOT;

	const [editing, setEditing] = useState(false);
	const [activeKey, setActiveKey] = useState(0);
	const [activeSearch, setActiveSearch] = useState('');
	const [showAddTerritoryModal, setShowAddTerritoryModal] = useState(false);
	const [showAddUserModal, setShowAddUserModal] = useState(false);
	const [showAddEmailDistributionModal, setShowAddEmailDistributionModal] = useState(false);
	const [showLookupUserModal, setShowLookupUserModal] = useState(false);

	const dispatch = UsePortalDispatch();

	const territories = useTerritories();
	const dispatchQuotesMetaData = useQuoteMetaDispatch();
	const quotes = useQuotes();
	const users = useUsersList();
	const modal = useModal();

	const representativeId = parseInt(GET.representative_id ?? '0', 10) ?? 0;

	const selectedRepresentative = useRepresentativeById(representativeId);
	const [workingRep, setWorkingRep] = useState(
		selectedRepresentative ?? initialRepresentativeState()
	);

	const changesHaveBeenMade = useMemo(() => (
		JSON.stringify(workingRep) !== JSON.stringify(selectedRepresentative)
	), 	[workingRep, selectedRepresentative]);

	usePrompt(modal, changesHaveBeenMade);

	/**
	 * Function that deletes a distribution email from the working rep
	 * @param email - The email to be deleted
	 * @param deleteVal - The type of email to delete
	 */
	const deleteDistributionEmail = (email: string, deleteVal: DistributionEmailType = 'all') => {
		const distributionEmails = [...workingRep.distribution_emails];
		switch (deleteVal) {
			case 'all':
				return setWorkingRep({
					...workingRep,
					distribution_emails: distributionEmails.filter((de) => de.email !== email),
				});
			case 'order':
				return setWorkingRep({
					...workingRep,
					distribution_emails: distributionEmails.map((de) =>
						(de.email === email ? { ...de, is_receiving_order_placed_emails: false } : de)
					),
				});
			case 'invoice':
				return setWorkingRep({
					...workingRep,
					distribution_emails: distributionEmails.map((de) =>
						(de.email === email ? { ...de, is_receiving_invoice_emails: false } : de)
					),
				});
			default:
		}
	};

	/**
	 * Function that saves the working representative by dispatching an update action.
	 * @returns void
	 */
	const saveWorkingEntity = async () => {
		try {
			const updateResponse = await dispatch(updateRepresentativeFull(workingRep));
			if (updateRepresentativeFull.fulfilled.match(updateResponse)) {
				toast.success(`Representative Updated `);
			}
			if (updateRepresentativeFull.rejected.match(updateResponse)) {
				toast.error(`Representative Update Failed: ${updateResponse.payload} `);
				setWorkingRep(selectedRepresentative ?? initialRepresentativeState());
			}
		} catch (e: any) {
			toast.error(`Representative Update Failed! PowerShades engineering has been notified.`);
			setWorkingRep(selectedRepresentative ?? initialRepresentativeState());
			throw new Error(e);
		}
	};

	useEffect(() => {
		if (
			!editing
			&& representativeId !== 0
			&& selectedRepresentative?.id !== 0
			&& selectedRepresentative
		) {
			setWorkingRep(selectedRepresentative);
		}
	}, [selectedRepresentative, editing]);

	useEffect(() => {
		if (selectedRepresentative) {
			setTitle(`View Representative | ${selectedRepresentative.name}`);
		}
	}, [setTitle, selectedRepresentative]);

	const getTabSelectionsEntity = (): TabPaneSelections[] => {
		const workingTabPanes: TabPaneSelections[] = [];

		const territoryTabPane: TabPaneSelections = {
			id: 0,
			title: 'Territories',
			key: 'territory_pane',
			items: [],
		};

		workingRep?.territory_ids?.forEach((territoryId) => {
			const territory = territories.find((wt) => wt.id === territoryId);
			if (!territory) return;
			const territoryLink = getTerritoryLink(territoryId);
			territoryTabPane.items.push({
				id: `${territory.name}`,
				type: "territory",
				name: territory.name,
				title: territory.name,
				onClick: () => {
					navigate(territoryLink);
				},
				onDelete: () => {
					modal({
						title: `Remove ${territory.name}?`,
						text: `Are you sure you want to remove ${territory.name} from ${workingRep.name}?`,
						confirmButtonText: 'Remove',
						onConfirm: () => {
							try {
								setWorkingRep({
									...workingRep,
									territory_ids: workingRep.territory_ids?.filter(
										(t) => t !== territory.id
									),
								});
								toast.success(
									`${territory.name} removed from ${workingRep.name}`
								);
							} catch (err) {
								toast.error('Something went wrong trying to remove the territory');
								throw err;
							}
						},
						onCancel: () => {
							toast.info('Territory removal canceled');
						},
					});
				}
			});
		});

		if (editing) {
			territoryTabPane.items.unshift({
				id: 'Add',
				type: "territory",
				name: 'Add Territory...',
				title: 'Add Territory...',
				onClick: () => {
					setShowAddTerritoryModal(true);
				},
			});
		}
		workingTabPanes.push(territoryTabPane);

		const quotesTabPane: TabPaneSelections = {
			id: 4,
			key: 'quote_pane',
			title: 'Quotes',
			filters: ['all', 'active', 'placed', 'RMA', 'rework', 'demo', 'archived'],
			items: [],
			editable: false,
		};

		if (workingRep.quote_ids.length > 0) {
			dispatchQuotesMetaData(workingRep.quote_ids);
		}

		workingRep.quote_ids.forEach((quoteId) => {
			const quote = quotes[quoteId];

			if (!quote) {
				return;
			}

			const quoteTypeId: QuoteTypeIds = quote?.quote_type_id ?? 5;
			const quotePrefix = QuotePrefixById[quoteTypeId ?? 5] ?? 'PS';

			quotesTabPane.items.push({
				id: `${quote.id}`,
				type: "quote",
				name: `${quotePrefix}${quote.id} - 
					${quote.title === '' || quote.title === null ? 'No Title' : quote.title}`,
				title: 'Quote ',
				onClick: () => {
					navigate(`/Quote?quoteID=${quote.id}`);
				},
			});
		});
		workingTabPanes.push(quotesTabPane);

		const usersTabPane: TabPaneSelections = {
			id: 5,
			key: 'user_pane',
			title: 'Users',
			items: [],
			editable: false,
		};

		workingRep.user_ids.forEach((userId) => {
			const user = users[userId];
			if (!user) return;

			usersTabPane.items.push({
				id: `${user.id}`,
				type: "user",
				name: `${user.name}`,
				title: `${user.name}`,
				onClick: () => {
					navigate(getUserLink(user.id));
				},
				onDelete: () => {
					modal({
						title: `Remove ${user.name}?`,
						text: `Are you sure you want to remove ${user.name} from ${workingRep.name}?`,
						confirmButtonText: 'Remove',
						onConfirm: () => {
							try {
								setWorkingRep({
									...workingRep,
									user_ids: workingRep.user_ids?.filter(
										(u) => u !== user.id
									),
								});
								toast.success(`${user.name} removed from ${workingRep.name}`);
							} catch (err) {
								toast.error('Something went wrong trying to remove the user');
								throw err;
							}
						},
						onCancel: () => {
							toast.info('User removal canceled');
						},
					});
				}
			});
		});
		if (editing) {
			usersTabPane.items.unshift({
				id: 'Add',
				type: "user",
				name: 'Add User...',
				title: 'Add User...',
				onClick: () => {
					modal({
						title: 'Add or Lookup User?',
						text: 'Would you like to add a new user or lookup an existing user?',
						confirmButtonText: 'Add',
						cancelButtonText: 'Lookup',
						onConfirm: () => {
							setShowAddUserModal(true);
						},
						onCancel: () => {
							setShowLookupUserModal(true);
						},
					});
				},
			});
		}
		workingTabPanes.push(usersTabPane);

		const distributionEmailsTabPane: TabPaneSelections = {
			id: 6,
			title: 'Distribution Emails',
			key: 'distribution_emails_pane',
			filters: ['all', 'order placed access', 'invoice access'],
			items: [],
		};
		workingRep.distribution_emails.forEach((distributionEmail) => {
			if (!distributionEmail) return;

			const hasInvoiceAccess = distributionEmail.is_receiving_invoice_emails;
			const hasOrderPlacedAccess = distributionEmail.is_receiving_order_placed_emails;
			if (hasInvoiceAccess) {
				distributionEmailsTabPane.items.push({
					id: `inv-${distributionEmail.email}`,
					type: "distributionEmail",
					name: `${distributionEmail.email} - Invoice Access`,
					title: distributionEmail.email,
					onClick: () => null,
					onDelete: () => {
						modal({
							title: `Remove ${distributionEmail.email} invoice access?`,
							text: `Are you sure you want to remove ${distributionEmail.email} invoice access from ${workingRep.name}?`,
							confirmButtonText: 'Remove',
							onConfirm: () => {
								try {
									deleteDistributionEmail(distributionEmail.email, 'invoice');
									toast.success(`${distributionEmail.email} invoice access removed`);
								} catch (err) {
									toast.error('Something went wrong trying to remove invoice access');
									throw err;
								}
							},
							onCancel: () => {
								toast.info('Invoice access removal canceled');
							}
						});
					},
				});
			}
			if (hasOrderPlacedAccess) {
				distributionEmailsTabPane.items.push({
					id: `ord-${distributionEmail.email}`,
					type: "distributionEmail",
					name: `${distributionEmail.email} - Order Access`,
					title: distributionEmail.email,
					onClick: () => null,
					onDelete: () => {
						modal({
							title: `Remove ${distributionEmail.email} order access?`,
							text: `Are you sure you want to remove ${distributionEmail.email} order access from ${workingRep.name}?`,
							confirmButtonText: 'Remove',
							onConfirm: () => {
								try {
									deleteDistributionEmail(distributionEmail.email, 'order');
									toast.success(`${distributionEmail.email} order access removed`);
								} catch (err) {
									toast.error('Something went wrong trying to remove order access');
									throw err;
								}
							},
							onCancel: () => {
								toast.info('Order access removal canceled');
							}
						});
					},
				});
			}

			if (!hasInvoiceAccess && !hasOrderPlacedAccess) {
				distributionEmailsTabPane.items.push({
					id: `non-${distributionEmail.email}`,
					type: "distributionEmail",
					name: `${distributionEmail.email} - No Access`,
					title: distributionEmail.email,
					onClick: () => null,
					onDelete: () => {
						modal({
							title: `Remove ${distributionEmail.email} from distribution emails?`,
							text: `Are you sure you want to remove ${distributionEmail.email} from ${workingRep.name}? This will remove the 
							email entirely.`,
							confirmButtonText: 'Remove',
							onConfirm: () => {
								try {
									deleteDistributionEmail(distributionEmail.email);
									toast.success(`${distributionEmail.email} removed`);
								} catch (err) {
									toast.error('Something went wrong trying to remove the email');
									throw err;
								}
							},
							onCancel: () => {
								toast.info('Email removal canceled');
							}
						});
					},
				});
			}
		});
		if (editing) {
			distributionEmailsTabPane.items.unshift({
				id: 'Add',
				type: "distributionEmail",
				name: 'Add or Edit Email Distribution Rule...',
				title: 'Add or Edit Email Distribution Rule...',
				onClick: () => {
					setShowAddEmailDistributionModal(true);
				},
			});
		}
		workingTabPanes.push(distributionEmailsTabPane);
		return workingTabPanes;
	};

	if (!selectedRepresentative) {
		return <>ope</>;
	}

	const loading = selectedRepresentative.loadStatus <= 3;

	const tabSelectionCurrent = getTabSelectionsEntity();

	return (
		<Container className="local-bootstrap">
			<Card body className="entity-card">
				<Row>
					<Col>
						<span>
							<EntityPageHeader
								name={workingRep.name ?? ''}
								email={workingRep.email ?? ''}
								phone={workingRep.phone ?? ''}
								loading={loading}
								editable={editing}
								setEditable={(e) => setEditing(e)}
								saveEnabled={changesHaveBeenMade}
								onChange={(fields) => {
									const {
										name, email, phone
									} = fields;

									setWorkingRep({
										...workingRep,
										name,
										email: email ?? '',
										phone: phone ?? '',
									});
								}}
								onSave={() => {
									saveWorkingEntity();
									setEditing(false);
								}}
							/>

							<BillingInformationHandler
								disabled={!editing}
								address={workingRep.billing_address}
								onChange={(address) => {
									setWorkingRep({
										...workingRep,
										billing_address: { ...address },
									});
								}}
								loading={loading}
							/>

							<EntityTaxInfo
								disabled={!editing}
								stateTaxCode={workingRep.sales_tax_exempt_certificate_number ?? ''}
								stateTaxStatus={workingRep.sales_tax_exempt_status ?? ''}
								stateTaxLink={
									workingRep.sales_tax_exempt_certificate_location ?? ''
								}
								isTaxable={workingRep.sales_tax_exempt_status !== 'tax_exempt'}
								federalTaxLink={workingRep.federal_tax_certificate_location ?? ''}
								federalTaxCode={workingRep.federal_tax_id ?? ''}
								onChange={(goods) => {
									const {
										stateTaxCode,
										stateTaxLink,
										isTaxable,
										federalTaxLink,
										federalTaxCode,
									} = goods;

									/* idk yet */
									setWorkingRep({
										...workingRep,
										sales_tax_exempt_certificate_number: stateTaxCode,
										sales_tax_exempt_status: isTaxable
											? 'tax_exempt'
											: 'non_exempt',
										sales_tax_exempt_certificate_location: stateTaxLink,
										federal_tax_certificate_location: federalTaxLink,
										federal_tax_id: federalTaxCode,
									});
								}}
								entityType="dealer"
								entityId={workingRep.id.toString()}
								loading={loading}
							/>

							<EntityPaymentOptions
								disabled={!editing}
								channelId={workingRep.channel_id ?? 0}
								multiplier={undefined}
								isUsingPricingOverride={false}
								onChange={(goods) => {
									const { channelId } = goods;

									setWorkingRep({
										...workingRep,
										channel_id: channelId as ChannelId,
									});
								}}
								loading={loading}
								isAllowingCreditCardPayments={undefined}
								allowCreditCardPayments={undefined}
								hasCreditTerms={undefined}
								termsId={undefined}
								buyingGroupIds={undefined}
								hasFabricBookOverride={undefined}
								currentPortfolio={workingRep.current_portfolio ?? ''}
							/>

							<TabPaneWithSearchFilter
								key="dealer-view"
								disabled={!editing}
								loading={loading}
								selections={tabSelectionCurrent}
								setActiveKey={setActiveKey}
								activeKey={activeKey}
								searchFilter={activeSearch}
								setSearchFilter={setActiveSearch}
							/>

							<AddTerritory
								key="addTerritoryRepView"
								open={showAddTerritoryModal}
								selectedTerritoryIds={workingRep.territory_ids ?? []}
								onClose={() => setShowAddTerritoryModal(false)}
								handleAdd={(territoryIds) => {
									setWorkingRep({
										...workingRep,
										territory_ids: [...workingRep.territory_ids ?? [], ...territoryIds]
									});
								}}
							/>
							<AddUserModal
								open={showAddUserModal}
								onClose={() => setShowAddUserModal(false)}
								callBack={(userId) => {
									setWorkingRep({
										...workingRep,
										user_ids: [...workingRep.user_ids ?? [], userId]
									});
								}}
								allowEntitySelection={false}
								entityType="rep"
								inheritedEntityId={workingRep.id}
								inheritedEntityName={workingRep.name}
							/>
							<UserLookup
								open={showLookupUserModal}
								onClose={() => setShowLookupUserModal(false)}
								handleAdd={(user) => {
									setWorkingRep({
										...workingRep,
										user_ids: [...workingRep.user_ids ?? [], user.id]
									});
								}}
							/>

							<AddEmailDistribution
								key="add-dist-email-rep-view"
								open={showAddEmailDistributionModal}
								onClose={() => setShowAddEmailDistributionModal(false)}
								handleAdd={(existingEmailDistributions) => {
									console.debug(existingEmailDistributions);
								}}
								existingEmailDistributions={workingRep.distribution_emails ?? []}
							/>
						</span>
					</Col>
				</Row>
			</Card>
		</Container>
	);
};

export default RepresentativeView;
