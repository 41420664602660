import { faGhost } from '@fortawesome/pro-regular-svg-icons';
import { faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import {
	Placeholder, Row, Col, Nav, Tab, ListGroup, Form
} from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

import type { TabPaneProps } from './types';
import TabPaneLink from './TabPaneLink';

const TabPaneInner = ({
	selections,
	activeKey,
	setActiveKey,
	loading,
	disabled
}: TabPaneProps) => {
	const itemsPerPage = 100;

	const hasOneSelection = selections.length === 1;

	const currentTitleTab = hasOneSelection
		? selections[0]
		: selections.find((selection) => selection.id === activeKey);

	const rightViewItems = currentTitleTab?.items ?? [];

	const [displaySelections, setDisplaySelections] = useState(rightViewItems.slice(0, itemsPerPage));
	const [tabSelections, setTabSelections] = useState(selections);

	const loadMoreDisplaySelections = () => {
		setDisplaySelections((prevItems) => [
			...prevItems,
			...rightViewItems.slice(prevItems.length, prevItems.length + itemsPerPage),
		]);
	};

	const loadMoreTabSelections = () => {
		setTabSelections((prevItems) => [
			...prevItems,
			...selections.slice(prevItems.length, prevItems.length + itemsPerPage),
		]);
	};

	useEffect(() => {
		setDisplaySelections(rightViewItems.slice(0, itemsPerPage));
	}, [rightViewItems]);

	useEffect(() => {
		setTabSelections(selections.slice(0, itemsPerPage));
	}, [selections]);

	if (loading) {
		return (
			<Placeholder animation="glow" className="mb-4 ms-0 me-0 entity-container">
				<Row>
					<Col xs={12} md={7}>
						<Row>
							<Col className="mb-2">
								<Placeholder.Button variant="green" md={3} xs={6} />
								<Placeholder.Button variant="gray" md={3} xs={6} />
								<Placeholder.Button variant="gray" md={3} xs={6} />
								<Placeholder.Button variant="gray" md={3} xs={6} />
							</Col>
						</Row>
					</Col>
					<Col className="mb-2" xs={12} md={5}>
						<Form.Control disabled placeholder="Loading..." />
					</Col>
				</Row>
				<hr className="d-xl-none mt-2" />
				<Row>
					<Col xs={12} xl={3} className="entity-nav">
						<Placeholder xs={12} size="lg" bg="green" className="mb-2" />
						<Placeholder xs={12} size="lg" className="mb-2" />
						<Placeholder xs={12} size="lg" className="mb-2" />
						<Placeholder xs={12} size="lg" className="mb-2" />
						<Placeholder xs={12} size="lg" className="mb-2" />
						<Placeholder xs={12} size="lg" className="mb-2" />
						<Placeholder xs={12} size="lg" className="mb-2" />
						<Placeholder xs={12} size="lg" className="mb-2" />
					</Col>
					<hr className="d-xl-none mt-2" />
					<Col xs={12} xl={9} className="tab-content">
						<Row className="mb-2">
							<Col xs={12} xl={6} className="mb-1">
								<Placeholder size="lg" xs={12} />
							</Col>
							<Col xs={12} xl={6}>
								<Placeholder size="lg" xs={12} />
							</Col>
						</Row>
						<Row className="mb-2">
							<Col xs={12} xl={6} className="mb-1">
								<Placeholder size="lg" xs={12} />
							</Col>
							<Col xs={12} xl={6}>
								<Placeholder size="lg" xs={12} />
							</Col>
						</Row>
						<Row className="mb-2">
							<Col xs={12} xl={6} className="mb-1">
								<Placeholder size="lg" xs={12} />
							</Col>
							<Col xs={12} xl={6}>
								<Placeholder size="lg" xs={12} />
							</Col>
						</Row>
						<Row className="mb-2">
							<Col xs={12} xl={6} className="mb-1">
								<Placeholder size="lg" xs={12} />
							</Col>
							<Col xs={12} xl={6}>
								<Placeholder size="lg" xs={12} />
							</Col>
						</Row>
						<Row className="mb-2">
							<Col xs={12} xl={6} className="mb-1">
								<Placeholder size="lg" xs={12} />
							</Col>
							<Col xs={12} xl={6}>
								<Placeholder size="lg" xs={12} />
							</Col>
						</Row>
						<Row className="mb-2">
							<Col xs={12} xl={6} className="mb-1">
								<Placeholder size="lg" xs={12} />
							</Col>
							<Col xs={12} xl={6}>
								<Placeholder size="lg" xs={12} />
							</Col>
						</Row>
						<Row className="mb-2">
							<Col xs={12} xl={6} className="mb-1">
								<Placeholder size="lg" xs={12} />
							</Col>
							<Col xs={12} xl={6}>
								<Placeholder size="lg" xs={12} />
							</Col>
						</Row>
						<Row className="mb-2">
							<Col xs={12} xl={6} className="mb-1">
								<Placeholder size="lg" xs={12} />
							</Col>
							<Col xs={12} xl={6}>
								<Placeholder size="lg" xs={12} />
							</Col>
						</Row>
						<Row className="mb-2">
							<Col xs={12} xl={6} className="mb-1">
								<Placeholder size="lg" xs={12} />
							</Col>
							<Col xs={12} xl={6}>
								<Placeholder size="lg" xs={12} />
							</Col>
						</Row>
						<Row className="mb-2">
							<Col xs={12} xl={6} className="mb-1">
								<Placeholder size="lg" xs={12} />
							</Col>
							<Col xs={12} xl={6}>
								<Placeholder size="lg" xs={12} />
							</Col>
						</Row>
					</Col>
				</Row>
			</Placeholder>
		);
	}

	const hasMoreSelectionItems = displaySelections.length < rightViewItems.length;
	const hasMoreTabs = tabSelections.length < selections.length;

	return (
		<Tab.Container activeKey={activeKey}>
			<Col xs={12} xl={3} className="entity-nav" id="title-nav">
				<Nav variant="pills" className="flex-column">
					{tabSelections.length === 0 && (
						<Nav.Item>
							<Nav.Link disabled>No Selections</Nav.Link>
						</Nav.Item>
					)}
					<InfiniteScroll
						dataLength={tabSelections.length}
						next={loadMoreTabSelections}
						hasMore={hasMoreTabs}
						loader={(
							<Nav.Item>
								<Nav.Link disabled>
									<Placeholder xs={6} animation="glow" />
								</Nav.Link>
							</Nav.Item>
						)}
						scrollableTarget="title-nav"
					>
						{tabSelections.map((selection, index) => (
							<Nav.Item
								key={hasOneSelection ? index : selection.id}
								onClick={() => {
									setActiveKey(hasOneSelection ? index : selection.id);
								}}
							>
								<Nav.Link eventKey={hasOneSelection ? index : selection.id}>
									{selection.title}
								</Nav.Link>
							</Nav.Item>
						))}
					</InfiniteScroll>
				</Nav>
			</Col>
			<hr className="d-xl-none mt-2" />
			<Col xs={12} xl={9}>
				<Tab.Content id="tab-content">
					{displaySelections.length === 0 && (
						<Col className="text-center text-muted mt-2 mb-4">
							<div className="user-select-none mb-3">
								Nothing here! Try adjusting your filters.
							</div>
							<FontAwesomeIcon className="mt-4" bounce size="3x" icon={faGhost} />
						</Col>
					)}
					<InfiniteScroll
						dataLength={displaySelections.length}
						next={loadMoreDisplaySelections}
						hasMore={hasMoreSelectionItems}
						loader={(
							<Col xs={12} sm={6}>
								<ListGroup.Item>
									<Row>
										<Col xs={11}>
											<Placeholder xs={6} size="lg" animation="glow" />
										</Col>
									</Row>
								</ListGroup.Item>
							</Col>
						)}
						scrollableTarget="tab-content"
					>
						<ListGroup>
							<Row className="g-0">
								{displaySelections.map((selection) => (
									<Col
										xs={12}
										sm={6}
										key={`${selection.id} - ${selection.title}`}
									>
										<ListGroup.Item action className={`${(!disabled && selection.onDelete ? "tab-pane-delete" : "")}${(selection.id === "Add" ? "tab-pane-add" : "")}`}>
											<Row>
												<Col
													title={selection.title}
													xs={11}
													onClick={() => {
														!disabled
															&& selection
															&& !selection.onDelete
															&& selection.onClick
															&& selection.onClick();
														!disabled
															&& selection
															&& selection.onDelete
															&& selection.onDelete();
														disabled
															&& selection
															&& selection.onClick
															&& selection.onClick();
													}}
												>
													{selection.id === "Add" ? (
														<div className="text-muted fw-light fst-italic">
															<span className="me-1">
																<FontAwesomeIcon icon={faPlus} />
															</span>
															<span>
																{selection.name}
															</span>
														</div>
													) : <TabPaneLink selection={selection} />}
												</Col>
												{!disabled && selection.onDelete && (
													<Col
														xs={1}
														className={
															!disabled ? 'display-block' : 'd-none'
														}
													>
														<FontAwesomeIcon
															className="delete-button"
															icon={faX}
															onClick={selection.onDelete}
														/>
													</Col>
												)}
											</Row>
										</ListGroup.Item>
									</Col>
								))}
							</Row>
						</ListGroup>
					</InfiniteScroll>
				</Tab.Content>
			</Col>
		</Tab.Container>
	);
};

// TabPane.whyDidYouRender = true;
export default TabPaneInner;
