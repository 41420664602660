import React from "react";
import { UseAssembly } from "../Store/entities/assemblies/hooks";
import Popup from "reactjs-popup";
import { useQuoteById } from "../Store/entities/quotes/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLockOpen, faLock, faCheck } from "@fortawesome/free-solid-svg-icons";
import { AppDispatch } from "../Store";
import { repriceAssemblies, setAssembliesSelected, setAssembliesUnlocked } from "../Store/entities/assemblies";
import { useDispatch } from "react-redux";

type QuoteAssemblyLockSelectProps = {
    quoteId: number;
    sequenceId: number;
    unlockShadeBySequenceId: (sequenceId: number) => void;
};

const QuoteAssemblyLockSelect = ({ quoteId, sequenceId, unlockShadeBySequenceId }: QuoteAssemblyLockSelectProps) => {

    const quoteIsEditable = useQuoteById(quoteId)?.editable ?? false; // TODO, make selector for editability
    const quoteType = "CEDIA"; // TODO, get this from the store by selector
    const assembly = UseAssembly(quoteId, sequenceId);
    const dispatch = useDispatch<AppDispatch>();
    const
        {
            selected,
            unlocked: unlockedStore
        } = assembly ?? { selected: false, unlocked: false };

    const unlocked = unlockedStore && quoteIsEditable;

    let classNameUnlock = unlocked ? '' : `icon-lock ${quoteType}`;
    let classNameLock = unlocked ? ' ' : `icon-unlock ${quoteType}`;

    const isOld = false; // TODO, get this from assembly store if pricing is dated or missing.

    if (isOld) {
        classNameLock += ' old';
        classNameUnlock += ' old';
    }

    const isErrored =  assembly?.msrp === 0;


    const onLockClick = () => {
        if (isErrored) {
            dispatch(repriceAssemblies({ quoteId, sequenceIds: [sequenceId], acc_line_numbers: [] }));
        }
        if (quoteIsEditable && unlocked) {
            dispatch(setAssembliesSelected({ quoteId, sequenceIds: [sequenceId], selected: !selected }));
        } else if (!quoteIsEditable) {
            unlockShadeBySequenceId(sequenceId);
        }
        else {
            dispatch(setAssembliesUnlocked({ quoteId, sequenceIds: [sequenceId], unlocked: !unlocked }));
        }
    };
    return (
        <Popup
            key={`tp-${sequenceId}`}
            trigger={(
                <span
                    onClick={() => {
                        onLockClick();
                    }}
                    className={`fa-stack lock ${quoteType}`}
                >
                    <span onClick={() => (!unlocked ? () => { } : () => dispatch(setAssembliesSelected({ quoteId, sequenceIds: [sequenceId], selected: !selected })))} className="fa-layers fa-fw">
                        <FontAwesomeIcon className={classNameUnlock} transform="grow-10 right-4" icon={faLockOpen} />
                        {unlocked ? null : <FontAwesomeIcon className={classNameLock} transform="grow-10" icon={faLock} />}
                        {!selected ? null : <FontAwesomeIcon icon={faCheck} transform="shrink-4 right-1 down-6" color="blue" />}
                    </span>
                </span>
            )}
            position={['right center']}
            on={['hover', 'focus']}
        >
            <div>Edit or Unlock Shade</div>
        </Popup>
    );
};

export default QuoteAssemblyLockSelect;