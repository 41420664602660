import { useState, } from 'react';

import {
	useIsManufacturer,
	useUserMyMultiplier,
	useQuoteMSRPMultiplier,
	useIsDistributor,
	useIsPsAdmin,
} from '../hooks';
import Button from '../Parts/Button';

const ExportModal = (props) => {
	const [choice, setChoice] = useState(3);
	const isManufacturer = useIsManufacturer();
	const userMyMultiplier = useUserMyMultiplier();
	const quoteMsrpMultiplier = useQuoteMSRPMultiplier(props.quote);
	const isDistributor = useIsDistributor();
	const isPsAdmin = useIsPsAdmin();

	const exportToExcel = () => {
		let multiplier = 1;
		let manufacturing = false;

		if (choice == 3) {
			multiplier = quoteMsrpMultiplier;
		} else if (choice == 1) {
			multiplier = userMyMultiplier;

			isDistributor || (multiplier *= quoteMsrpMultiplier);
		} else if (choice == 5) {
			multiplier = 0;
			manufacturing = true;
		} else {
			// MSRP
			multiplier = quoteMsrpMultiplier;
		}
	};

	return (
		<div id="order-confirmation-holder">
			<div className="export-modal">
				<h2>Export Quote</h2>

				<div id="order-confirm-description" className="export-modal-selections">
					<div>
						<input
							type="checkbox"
							checked={choice == 3}
							onChange={() => setChoice(3)}
						/>
						<label>MSRP</label>
					</div>

					{!isPsAdmin & !isManufacturer ? (
						<div>
							<input
								type="checkbox"
								checked={choice == 1}
								onChange={() => setChoice(1)}
							/>
							<label>Price</label>
						</div>
					) : null}
				</div>

				<div className="button-holder">
					<Button color="gray" onClick={props.close}>
						Cancel
					</Button>
					<Button color="green" onClick={exportToExcel}>
						Export
					</Button>
				</div>
			</div>
		</div>
	);
};

export default ExportModal;
