/* eslint-disable react/button-has-type */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";

import type { RadioButtonProps } from "./types";

import "./button.scss";

const RadioButton = ({
	type = "button",
	fullWidth = false,
	blockLevel = false,
	disabled = false,
	color = "green",
	size = "standard",
	hoverColor = "green",
	className = "",
	id = "",
	onClick,
	state,
	displayText,
	style = {},
}: RadioButtonProps): JSX.Element => (
	<button
		id={id || undefined}
		style={style || undefined}
		disabled={disabled}
		className={`${size === "standard" ? "btn" : `btn btn-${size}`} 
		${state ? `btn-${color}` : `btn-gray`}
		radio-button 
		${hoverColor === "green" ? "" : ` btn-light-${hoverColor} `}
		${fullWidth ? " full-width " : ""}${blockLevel ? " btn-block " : ""} 
		${className}`}
		onClick={(idk) => onClick && onClick(idk)}
		type={type as "button" | "submit" | "reset"}
	>
		{displayText}
		&nbsp;
		{state ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faXmark} />}
	</button>
);

export default RadioButton;
