import { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';

import Table from '../Parts/Table';
import api from '../PowerShadesAPI';
import Button from '../Parts/Button';

const QuoteReviews = (props) => {
	const { setTitle, loaded } = props;

	const { alert } = props.ROOT;

	const [currentQuoteId, setCurrentQuoteId] = useState(0);
	const openQuoteView = currentQuoteId != 0;
	const [quoteReviews, setQuoteReviews] = useState([]);
	const [salesPeople, setSalespeople] = useState([]);
	const [activeOnly, setActiveOnly] = useState(true);
	const [isRejectingOrder, setIsRejectingOrder] = useState(false);
	const [rejectionText, setRejectionText] = useState('');

	const currentQuoteReview = quoteReviews.find((qr) => qr.quoteId == currentQuoteId);

	const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

	const notes = currentQuoteReview ? currentQuoteReview.notes : '';

	const approveAndSubmitOrder = (quoteId) => {
		setSubmitButtonDisabled(true);
		api.approveQuoteReview(quoteId).then((res) => {
			const data = res.data ?? {};

			const { success } = data;

			if (!success) {
				ROOT.alert({
					title: `PS${currentQuoteId}`,
					text: `Approval failed. Content: ${JSON.stringify(data)}`,
					quickFormat: 'error',
				});

				return;
			}

			const newReviews = quoteReviews.map((qr) =>
				qr.quoteId == currentQuoteId ? { ...qr, status: '1' } : qr
			);
			setQuoteReviews(newReviews);
			setCurrentQuoteId(0);

			setSubmitButtonDisabled(false);
		});
	};

	const rejectOrder = (quoteId, notes) => {
		api.rejectQuoteReview(quoteId, notes)
			.then((resp) => {
				const { data } = resp;

				const { success } = data;

				// alert({title, text, noCancelButton, acceptButtonText, cancelButtonText, onAccept, onCancel, style})
				if (!success) {
					ROOT.alert({
						title: 'Rejection Failure',
						text: "Please alert engineering (if you're engineering talk to Lucy).",
						quickFormat: 'error',
					});
				} else {
					setCurrentQuoteId(0);
					populateReviews();
				}
			})
			.catch((e) => {
				const { data } = e.response;

				ROOT.alert({
					title: 'Rejection Failure',
					text: data.message
						? data.message
						: "Please alert engineering (if you're engineering talk to Lucy).",
					quickFormat: 'error',
				});

				setSubmitButtonDisabled(false);
			});
	};

	let populateReviews = () => {
		api.getQuoteReviews().then((resp) => {
			const reviews = resp.data.quotes;

			setQuoteReviews(reviews);

			loaded();
		});
	};

	useEffect(() => {
		setTitle('Engineering Reviews');

		populateReviews();

		api.getSalespeople().then((resp) => {
			const salespeopleData = resp.data.salespeople ?? [];
			setSalespeople(salespeopleData);
		});
	}, []);

	const dateConverter = (date) => {
		const formattedDate = new Date(date);
		const newDate = new Date();
		newDate.setTime(formattedDate.getTime()); // A really bad timezone shift that doesn't tak into account daylight savings
		return formattedDate.toLocaleString();
	};

	const rows = quoteReviews
		.filter((qr) => (activeOnly ? qr.status == '0' : true))
		.map((qr) => {
			const { quoteId, displayId } = qr;

			const orderedTimestamp = qr.requestedTime;

			const salesPerson = salesPeople.find((sp) => sp.Id == qr.salespersonId);
			const salesPersonName = salesPerson ? salesPerson.name : '';
			return (
				<tr>
					<td>
						{' '}
						<a href={`#/Quote?quoteID=${quoteId}`}>{displayId}</a>
					</td>
					<td>{dateConverter(orderedTimestamp)}</td>
					<td>{salesPersonName}</td>
					<td>
						<Button color="green" onClick={() => setCurrentQuoteId(quoteId)}>
							View
						</Button>
					</td>
				</tr>
			);
		});

	return (
		<>
			<div style={{ overflowX: 'auto' }}>
				<Table style={{ margin: 'auto', maxWidth: '75%' }}>
					<tr className="bold-row">
						<th colSpan={2}>Quotes Under Review</th>
						<th colSpan={1}>Only Show Pending</th>
						<th colSpan={1}>
							<input
								type="checkbox"
								checked={activeOnly}
								onClick={() => setActiveOnly(!activeOnly)}
							/>
						</th>
					</tr>

					<tr>
						<th>Quote Id</th>
						<th>Review Requested Time</th>
						<th>Sales Person</th>
						<th>Actions</th>
					</tr>

					{rows}
				</Table>
			</div>
			<Popup
				open={openQuoteView}
				onClose={() => setCurrentQuoteId(0)}
				className="eqr mobile-modal"
			>
				<div>
					<h3>{`PS${currentQuoteId}`}</h3>
					<div>
						Notes:
						{notes}
					</div>
					<Button onClick={() => setIsRejectingOrder(true)} color="gray">
						Reject
					</Button>
					<Button
						onClick={() => approveAndSubmitOrder(currentQuoteId)}
						disabled={submitButtonDisabled}
						color="green"
					>
						Approve and Submit
					</Button>
					{isRejectingOrder ? (
						<div>
							<h3>Why?</h3>
							<textarea
								rows="12"
								cols="60"
								value={rejectionText}
								onChange={(e) => setRejectionText(e.target.value)}
							/>
							<br />
							<Button
								color="gray"
								onClick={() => setIsRejectingOrder(false)}
							>
								Cancel
							</Button>
							<Button
								color="green"
								onClick={() => rejectOrder(currentQuoteId, rejectionText)}
							>
								Submit
							</Button>
						</div>
					) : null}
				</div>
			</Popup>
		</>
	);
};

export default QuoteReviews;
