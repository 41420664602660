import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Popup from "reactjs-popup";

import ChannelSelect from "../../Parts/ChannelSelect";
import Button from "../../Parts/Button";

/* eslint-disable camelcase */

const ContractorAdd = ({ add, channels }) => {
	const [open, setOpen] = useState(false);

	const [contractor, setContractor] = useState({
		name: '',
		phone: '',
		email: '',
		channel_id: 1,
	});

	const setContractorObj = (obj) => {
		setContractor({ ...contractor, ...obj });
	};

	const saveAndRedirect = () => {
		add(contractor, () => {
			setOpen(false);
		});
	};

	return (
		<>
			<Popup
				open={open}
				onClose={() => {
					setOpen(false);
				}}
				className="mobile-modal"
			>
				<Container className="local-bootstrap">
					<Row>
						<Col>
							<h4 className="text-green">Add Contractor</h4>
						</Col>
					</Row>
					<Row className="mb-2">
						<Col>
							<div className="mb-2">Name</div>
							<Form.Control
								size="sm"
								type="text"
								value={contractor.name}
								onChange={(e) => setContractorObj({ name: e.target.value })}
							/>
						</Col>
						<Col>
							<div className="mb-2">Channel</div>
							<ChannelSelect
								noLabel
								bootstrap
								channelsOverride={channels}
								title={false}
								channelId={contractor.channel_id}
								onChange={(new_channel_id) =>
									setContractorObj({ channel_id: new_channel_id })}
							/>
						</Col>
					</Row>
					<Row className="mb-2">
						<Col>
							<div className="mb-2">Phone</div>
							<Form.Control
								size="sm"
								type="text"
								value={contractor.phone}
								onChange={(e) => setContractorObj({ phone: e.target.value })}
							/>
						</Col>
						<Col>
							<div className="mb-2">Email</div>
							<Form.Control
								size="sm"
								type="text"
								value={contractor.email}
								onChange={(e) => setContractorObj({ email: e.target.value })}
							/>
						</Col>
					</Row>

					<Row className="mt-4">
						<Col className="text-end">
							<Button
								color="green"
								onClick={() => {
									saveAndRedirect();
									setOpen(false);
								}}
							>
								Save
							</Button>
						</Col>
					</Row>
				</Container>
			</Popup>
			<Button
				color="green"
				onClick={() => {
					setOpen(true);
				}}
			>
				Add Contractor
			</Button>
		</>
	);
};

export default ContractorAdd;
