import React from 'react';

import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Form, Placeholder } from 'react-bootstrap';

import Button from '../Parts/Button';
import FileUpload from '../Parts/FileUploader';

import type { EntityTaxChange, EntityTaxInfoProps } from './types';
import DisplayEntityPdf from './DisplayEntityPdf';
import type { SalesTaxExemptStatus } from '../powershadesApiTypes';

const EntityTaxInfo = ({
	federalTaxLink,
	federalTaxCode,
	stateTaxCode,
	stateTaxLink,
	stateTaxStatus,
	isTaxable,
	onChange,
	disabled = false,
	entityType,
	entityId,
	loading,
}: EntityTaxInfoProps) => {
	// TODO: Fix radio button (showing x, but green and clicking does not do anything)
	/**
	 * Updates the entity tax information with the provided fields and calls
	 * the onChange callback with the updated information.
	 * @param fields - An object containing the fields to update and their new values.
	 */
	const onChangeInternal = (fields: { [key in keyof EntityTaxChange]?: EntityTaxChange[key] }) => {
		const updatedFields = {
			federalTaxLink,
			federalTaxCode,
			stateTaxCode,
			stateTaxLink,
			stateTaxStatus,
			isTaxable,
			...fields,
		};

		onChange(updatedFields);
	};

	const salesExemptIcon = isTaxable ? faTimes : faCheck;

	const disabledOrTaxExempt = disabled || isTaxable;

	if (loading) {
		return (
			<Placeholder animation="glow" className="row mb-4 ms-0 me-0">
				<Col xs={12} lg={6} className="mt-2">
					<Placeholder xs={8} size="lg" className="mb-1" />
					<Placeholder xs={12} size="lg" />
				</Col>
				<Col xs={12} lg={6} className="mt-2">
					<Placeholder xs={8} size="lg" className="mb-1" />
					<Placeholder xs={12} size="lg" />
				</Col>
			</Placeholder>
		);
	}

	return (
		<>
			<Row>
				<Col xs={12}>
					<h4>Sales Tax Information</h4>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col sm={12} md={3} className="mb-2">
					<Button
						fluid
						fullWidth
						disabled={disabled}
						color={isTaxable ? 'gray' : 'green'}
						radioButton
						onClick={() => onChangeInternal({ isTaxable: !isTaxable })}
					>
						Sales Tax Exempt&nbsp;
						<FontAwesomeIcon icon={salesExemptIcon} />
					</Button>
				</Col>
				<Col sm={12} md={9}>
					<select
						className="form-select"
						disabled={disabledOrTaxExempt}
						id="sales_tax_exemption_type"
						value={stateTaxStatus}
						onChange={(e) => onChangeInternal({ stateTaxStatus: e.target.value as SalesTaxExemptStatus })}
					>
						<option value="non_exempt" selected disabled>
							Select Exemption Type
						</option>
						<option value="government">Government</option>
						<option value="nonprofit">Non-Profit</option>
						<option value="non_exempt">Not Exempt</option>
						<option value="wholesale">Wholesale</option>
					</select>
				</Col>
			</Row>
			<Row className="mb-2">
				<Col sm={12} md={6} className="mb-2">
					<h6>State Tax Exempt Certificate Number</h6>
					<Form.Control
						disabled={disabledOrTaxExempt}
						type="text"
						value={stateTaxCode}
						onChange={(e) => {
							const newNumber = e.target.value;

							onChangeInternal({ stateTaxCode: newNumber });
						}}
					/>
				</Col>
				<Col sm={12} md={6} className="mb-2">


					<Row>
						<h6>Upload State Tax Exempt Certificate</h6>
					</Row>

					<Row>
						<DisplayEntityPdf
							key={`${stateTaxLink}-state`}
							entityType={entityType}
							entityId={parseInt(entityId, 10)}
							certType="state"
						/>
					</Row>

					<Row>
						<FileUpload
							disabled={disabledOrTaxExempt}
							certType="state"
							entityType={entityType}
							entityID={entityId}
							onSuccess={(url) => {
								onChangeInternal({ stateTaxLink: url });
							}}
						// onSuccess={setFederalCert}
						/>
					</Row>
				</Col>
			</Row>
			<Row className="mb-2">
				<Col sm={12} md={6} className="mb-2">
					<h6>Federal Tax Exempt Certificate Number</h6>
					<Form.Control
						disabled={disabledOrTaxExempt}
						type="text"
						value={federalTaxCode}
						onChange={(e) => onChangeInternal({ federalTaxCode: e.target.value })}
					/>
				</Col>
				<Col sm={12} md={6} className="mb-2">
					<Row>
						<h6>Upload Federal Tax Exempt Certificate</h6>
					</Row>
					<Row>
						<DisplayEntityPdf
							key={`${federalTaxLink}-fed`}
							entityType={entityType}
							entityId={parseInt(entityId, 10)}
							certType="federal"
						/>
					</Row>
					<Row>
						<FileUpload
							disabled={disabledOrTaxExempt}
							certType="federal"
							entityType="dealer"
							entityID={entityId}
							onSuccess={(url) => {
								onChangeInternal({ federalTaxLink: url });
							}}
						// onSuccess={setFederalCert}
						/>
					</Row>
				</Col>
			</Row>
			<hr />
		</>
	);
};

export default EntityTaxInfo;
