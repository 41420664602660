/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-self-compare */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-useless-return */
import { InitialAssemblyState } from "..";
import { UsePortalSelector } from "../../..";
import { AssemblyOptionsViewModel } from "../../../../powershadesApiTypes";
import { useUser } from "../../../user/hooks";
import { useAssemblyOptions } from "../../assemblyOptions/hooks";
import { useQuoteCombination } from "../../combinations/hooks";
import { QuoteStore } from "../../entitiesType";
import { useIndoorFabrics, useOutdoorFabrics, usePSOutdoorFabrics } from "../../hooks";
import { QuoteCombinations } from "../../multipliers/types";
import { useQuoteById } from "../../quotes/hooks";
import useNewCOROutdoorHooks, { useCurrentCOROutdoorHooks } from "../COROutdoorHooks";
import { useAssembliesByQuoteId } from "../hooks";
import { useCurrentIndoorShadeOptions, useNewIndoorShadeOptions } from "../IndoorHooks.ts";
import { useCurrentPSOutdoorShadeOptions, useNewPSOutdoorShadeOptions } from "../PSOutdoorHooks";
import { AssemblyStore, PortalShadeOption } from "../types";

// import apiCalls from '../../../../PowerShadesAPIFunctions';
// import { FabricViewModel, loadStatusType} from "../../../../powershadesApiTypes";

export type ErrorSet = {
	varName: string;
	message: string;
};

export const selectErrorsByAllNeededOBjects = (
	currentAssembly: AssemblyStore,
	quote: QuoteStore,
	allAssemblies: AssemblyStore[],
	quoteCombinations: QuoteCombinations,
	currentShadeOptions: { [key: string]: (PortalShadeOption<any> | PortalShadeOption<string>[]) },
	allShadeOptions: { [key: string]: (PortalShadeOption<any> | PortalShadeOption<string>[]) },
	assemblyOptions: AssemblyOptionsViewModel,
	includeBEErrorMessage = false
) => {
	const shadeOptions = currentShadeOptions;

	const assyName = (shadeOptions?.ShadeName as PortalShadeOption<string>).getValue();

	const numberOfAssembliesWithSameName = allAssemblies.filter((a) => a.shade_name === assyName).length;

	const hasBeenOrdered = quote.order_status !== "active_quote";
	const allOptions = allShadeOptions;

	const errorSets: ErrorSet[] = [];

	// Length based on Fascia Type
	// These are also in the fascia combinations
	// RC3080 (3 inch) 210
	// RC4039 (5 inch) 210
	// RS-SK (7 inch) 186

	const getMaxExtrusionLength = (headerName: string) => {
		const fasciaItem = assemblyOptions.headers?.find((h) => h.name === headerName);

		return fasciaItem?.max_extrusion_width ?? 0;
	};

	const fasciaTypesAllowedToBeCombined = [
		"Fascia 4 Inch",
		"Fascia 3 Inch",
		"Fascia 5 Inch",
		"Fascia 7 Inch"
	];

	const fasciaItems = fasciaTypesAllowedToBeCombined.map((ft) => ({
		sku: ft,
		maxLength: getMaxExtrusionLength(ft),
		name: ft
	}));
	const fasciaCombinations = quoteCombinations?.Fascias ?? [];

	const fasciaCombination = fasciaCombinations.find((fc) =>
		fc.assemblies.map((a) => a.sequence_id).includes(currentAssembly.sequence_id));

	const shadeConfigOption = shadeOptions?.ShadeConfiguration as PortalShadeOption<string>;

	const shadeConfiguration = shadeConfigOption?.getSelectedOption()?.value ?? "";

	Object.entries(allOptions).forEach(([key, opt]) => {
		// GIO
		// if (key =="Fabric"){
		// 	console.log(`🥶 Processing option with key: ${key}`);
		// 	console.log("🥶 Content of opt:", opt);
		// }
		if (key === "ColorPalettes") {
			return;
		} else if (key === "Colors") {
			const options = opt as PortalShadeOption<string>[];
			if (!hasBeenOrdered && options.some((o) =>
				o.getValue() === "" || o.getValue() === "Missing Default Color")) {
				errorSets.push({
					varName: "Colors",
					message: "Please finish selecting colors."
				});
			}
		} else if (Array.isArray(opt)) {
			return;
		} else {
			const allOptions = opt.options;
			const currentOption = shadeOptions[key];
			if (Array.isArray(currentOption)) {
				return;
			}
			if (!(currentOption?.active ?? true)) return;
			const selectedOption = currentOption?.getValue();

			// GIO
			// if (key =="Fabric")
			// console.log("🥶 SelectedOption", selectedOption);

			if (
				(currentAssembly.shades[0]?.shade_type === 'manual' && (key === 'HembarType' || key === 'ControlSide'))
				|| (
					currentAssembly.shades[0]?.shade_type === 'motorized'
					&& (key === 'manual_chain' || key === 'manual_chain_color')
				)
			) {
				return;
			}

			if (selectedOption || selectedOption === '') {
				const foundOptionInAll = allOptions?.find((o) => o.value === selectedOption);
				const foundOptionInAllowd = currentOption?.options?.filter((o) => o.isActive)
					.find((o) => o.value === selectedOption);

				// GIO
				// if (key =="Fabric"){
				// 	console.log("🥶 foundOptionInAll", foundOptionInAll);
				// 	console.log("🥶 foundOptionInAllowd", foundOptionInAllowd);
				// }

				if (!hasBeenOrdered && !foundOptionInAll && (allOptions?.length ?? 0) > 0) {
					errorSets.push({
						varName: opt.varName,
						message: `•• Please select a valid option for "${opt.label}" !A.`
					});

					// GIO
					// if (key =="Fabric"){
					// 	console.log("🥶 messageERROR !A", `•• Please select a valid option for "${opt.label}" !A.`);
					// }

					// GIO
					// Object.entries(allOptions ?? {}).forEach(([key, value]) => {
					// 	console.log(`Key: ${key}`, "Value:", value);
					// });

					// console.log("🥶🥶 Hooks 1 SelectedOption", selectedOption);
					// console.log("🥶🥶 Hooks 1 VarName", opt.varName);
					// console.log("🥶🥶 Hooks 1 Label", opt.label);
					// console.log("🥶🥶 Hooks 1 Value", opt.getValue);
					// console.log("🥶🥶 Hooks 1 Active", opt.active);
					// console.log("++++++++++++++++++++++++++++++++++++++++++++");
				} else if (!hasBeenOrdered && !foundOptionInAllowd && (currentOption?.options?.length ?? 0) > 0) {
					errorSets.push({
						varName: opt.varName,
						message: `•• Please select a valid option for "${opt.label}" !AL.`
					});
					// GIO
					// if (key =="Fabric"){
					// console.log("🥶 messageERROR !AL", `•• Please select a valid option for "${opt.label}" !AL.`);
					// }
				}

				if (currentOption?.error) {
					// Combine Errors to one Message.
					const errorMessage = currentOption.error.reduce((acc, curr) => acc + curr, "");

					if (errorMessage) {
						errorSets.push({
							varName: key,
							message: errorMessage
						});

						// GIO
						// if (key =="Fabric"){
						//  console.log("🥶 messageERROR CurrentOption.error", errorMessage);
						// }
					}
				}
			}
		}
	});

	if (fasciaCombination) {
		const selectedShadeObjects = fasciaCombination.assemblies.map((a) =>
			allAssemblies.find((s) => s.sequence_id === a.sequence_id));
		const headerTypes = selectedShadeObjects.map((s) => s?.header_line ?? null);

		const headerType = headerTypes.find((s) => s !== null) ?? "";

		const allSameHeaderType = (function (headerTypes, headerType) {
			return !headerTypes.some((h) => h !== headerType);
		})(headerTypes, headerType);

		if (!allSameHeaderType) {
			// Error Message
			errorSets.push({
				varName: "header_fascia.",
				message: "This header must match the others in it's fascia combination."
			});
		}

		// Differing rooms

		const roomNames = selectedShadeObjects.map((s) => s?.room_name ?? "(Empty Room Name)");

		const allSameRoom = (function (roomNames) {
			const roomName = roomNames.find(() => true);

			return !roomNames.some((h) => h !== roomName);
		})(roomNames);

		if (!allSameRoom) {
			errorSets.push({
				varName: "room_fascia.",
				message: "This room must match the others in it's fascia combination."
			});

			// Error Message
		}

		const mountTypes = selectedShadeObjects.map((s) => s?.mount_style ?? "Empty Mount Type");

		const allSameMountTypes = (function (mountTypes) {
			const mountType = mountTypes.find(() => true);

			return !mountTypes.some((h) => h !== mountType);
		})(mountTypes);

		if (!allSameMountTypes) {
			errorSets.push({
				varName: "mount_fascia.",
				message: "This mount must match the others in it's fascia combination."
			});
		}

		const directions = selectedShadeObjects.map((s) => s?.direction_facing ?? "Empty Direction");

		const direction = directions.find(() => true);

		const hasMoreThanOneDirection = directions.some((d) => d !== direction);

		if (hasMoreThanOneDirection) {
			errorSets.push({
				varName: "direction_fascia.",
				message:
					"This direction must match the direction of other assemblies in the fascia combination."
			});
		}

		const fasciaType = fasciaItems.find((fi) => headerType?.includes(fi.sku));

		if (fasciaType === null) {
			errorSets.push({
				varName: "type_fascia.",
				message:
					"This fascia must have the same type of fascia as others in a fascia combination."
			});
		}
	}

	if (numberOfAssembliesWithSameName > 1) {
		errorSets.push({
			varName: "shade_name",
			message: "Shade name must be unique."
		});
	}

	if (["dual_coupled", "coupled"].includes(shadeConfiguration)) {
		let totalWidth = 0;
		currentAssembly?.shades.map((shade) => shade.row_coordinate === 0 && (totalWidth += shade.width));

		currentAssembly && (Math.abs(totalWidth - currentAssembly?.total_width) > 0.03)
			&& errorSets.push({
				varName: "coupled_width",
				message: `Coupled shades' width must add up to the total width.`
			});
	}

	if (currentAssembly.msrp <= 0) {
		if ((currentAssembly.error_messages?.length ?? 0 > 0) && includeBEErrorMessage) {
			errorSets.push({
				varName: "msrp",
				message: currentAssembly.error_messages ?? ""
			});
		} else {
			errorSets.push({
				varName: "msrp",
				message: "Something Went Wrong."
			});
		}
	}

	return errorSets;
};

export const useAssemblyErrorsByStore = (
	currentAssembly: AssemblyStore,
	quote: QuoteStore,
	allAssemblies: AssemblyStore[],
	quoteCombinations: QuoteCombinations,
	shadeOptions: { [key: string]: (PortalShadeOption<any> | PortalShadeOption<string>[]) }
) => {
	const shadeTypeId = currentAssembly?.shade_type_id;
	const isIndoor = shadeTypeId === 1;
	const isCOROutdoor = shadeTypeId === 2;

	const indoorFabrics = useIndoorFabrics(quote.id);
	const outdoorFabrics = useOutdoorFabrics(quote.id);
	// console.log('🔹🔹🔹🔹🔹🔹 Hooks errores', quote.id);
	const psOutdoorFabrics = usePSOutdoorFabrics(quote.id);

	const assemblyOptions = UsePortalSelector(useAssemblyOptions(quote.id));

	const user = useUser();

	const allIndoorShadeOptions = useNewIndoorShadeOptions(
		currentAssembly,
		() => { },
		{ fabrics: indoorFabrics, user, quote, assemblyOptions },
		true
	);
	const allCOROutdoorShadeOptions = useNewCOROutdoorHooks(
		currentAssembly,
		() => { },
		{ fabrics: outdoorFabrics, user, quote, assemblyOptions },
		true
	);
	const allPSOutdoorShadeOptions = useNewPSOutdoorShadeOptions(
		currentAssembly,
		() => { },
		{ fabrics: psOutdoorFabrics, user, quote, assemblyOptions },
		true
	);

	const allOptions = isIndoor
		? allIndoorShadeOptions
		: isCOROutdoor
			? allCOROutdoorShadeOptions
			: allPSOutdoorShadeOptions;

	return selectErrorsByAllNeededOBjects(
		currentAssembly,
		quote,
		allAssemblies,
		quoteCombinations,
		shadeOptions,
		allOptions,
		assemblyOptions
	);

	// const errorSets: ErrorSet[] = [];

	// // Length based on Fascia Type
	// // These are also in the fascia combinations
	// // RC3080 (3 inch) 210
	// // RC4039 (5 inch) 210
	// // RS-SK (7 inch) 186
	// const fasciaItems = [
	// 	// 3 inch
	// 	{
	// 		sku: "RC3080",
	// 		maxLength: 210,
	// 		name: '3" Fascia'
	// 	},
	// 	// 4 inch
	// 	{
	// 		sku: "RC3158",
	// 		maxLength: 210,
	// 		name: '4" Fascia'
	// 	},
	// 	// 5 inch
	// 	{
	// 		sku: "RC4039",
	// 		maxLength: 210,
	// 		name: '5" Fascia'
	// 	},
	// 	// 7 inch
	// 	{
	// 		sku: "RS-SK",
	// 		maxLength: 150,
	// 		name: '7" Fascia'
	// 	}
	// ];

	// const fasciaCombinations = quoteCombinations?.Fascias ?? [];

	// const fasciaCombination = fasciaCombinations.find((fc) =>
	// 	fc.assemblies.map((a) => a.sequence_id).includes(currentAssembly.sequence_id));

	// const shadeConfiguration = shadeOptions?.ShadeConfiguration?.getSelectedOption().value;

	// Object.entries(allOptions).forEach(([key, opt]) => {
	// 	const allOptions = opt.options;
	// 	const currentOption = shadeOptions[key];
	// 	if (!(currentOption?.active ?? true)) return;
	// 	const selectedOption = currentOption?.getValue();

	// 	if (selectedOption || selectedOption === '') {
	// 		const foundOptionInAll = allOptions?.find((o) => o.value === selectedOption);

	// 		const foundOptionInAllowd = currentOption?.options?.filter(o => o.isActive)
	// 			.find((o) => o.value === selectedOption);

	// 		if (!hasBeenOrdered && !foundOptionInAll && (allOptions?.length ?? 0) > 0) {
	// 			errorSets.push({
	// 				varName: opt.varName,
	// 				message: `Please select a valid option for "${opt.label}".`
	// 			});
	// 		} else if (!hasBeenOrdered && !foundOptionInAllowd && (currentOption?.options?.length ?? 0) > 0) {
	// 			errorSets.push({
	// 				varName: opt.varName,
	// 				message: `Please select a valid option for "${opt.label}".`
	// 			});
	// 		}

	// 		if (currentOption?.error) {
	// 			// Combine Errors to one Message.
	// 			const errorMessage = currentOption.error.reduce((acc, curr) => acc + curr, "");

	// 			if (errorMessage) {
	// 				errorSets.push({
	// 					varName: key,
	// 					message: errorMessage
	// 				});
	// 			}
	// 		}
	// 	}
	// });

	// if (fasciaCombination) {
	// 	const selectedShadeObjects = fasciaCombination.assemblies.map((a) =>
	// 		allAssemblies.find((s) => s.sequence_id === a.sequence_id));
	// 	const headerTypes = selectedShadeObjects.map((s) => s?.header_type ?? null);

	// 	const headerType = headerTypes.find((s) => s !== null) ?? "";

	// 	const allSameHeaderType = (function (headerTypes, headerType) {
	// 		return !headerTypes.some((h) => h !== headerType);
	// 	})(headerTypes, headerType);

	// 	if (!allSameHeaderType) {
	// 		// Error Message
	// 		errorSets.push({
	// 			varName: "header_fascia.",
	// 			message: "This header must match the others in it's fascia combination."
	// 		});
	// 	}

	// 	// Differing rooms

	// 	const roomNames = selectedShadeObjects.map((s) => s?.room_name ?? "(Empty Room Name)");

	// 	const allSameRoom = (function (roomNames) {
	// 		const roomName = roomNames.find(() => true);

	// 		return !roomNames.some((h) => h !== roomName);
	// 	})(roomNames);

	// 	if (!allSameRoom) {
	// 		errorSets.push({
	// 			varName: "room_fascia.",
	// 			message: "This room must match the others in it's fascia combination."
	// 		});

	// 		// Error Message
	// 	}

	// 	const mountTypes = selectedShadeObjects.map((s) => s?.mount_type ?? "Empty Mount Type");

	// 	const allSameMountTypes = (function (mountTypes) {
	// 		const mountType = mountTypes.find(() => true);

	// 		return !mountTypes.some((h) => h !== mountType);
	// 	})(mountTypes);

	// 	if (!allSameMountTypes) {
	// 		errorSets.push({
	// 			varName: "mount_fascia.",
	// 			message: "This mount must match the others in it's fascia combination."
	// 		});
	// 	}

	// 	const directions = selectedShadeObjects.map((s) => s?.direction_facing ?? "Empty Direction");

	// 	const direction = directions.find(() => true);

	// 	const hasMoreThanOneDirection = directions.some((d) => d !== direction);

	// 	if (hasMoreThanOneDirection) {
	// 		errorSets.push({
	// 			varName: "direction_fascia.",
	// 			message:
	// 				"This direction must match the direction of other assemblies in the fascia combination."
	// 		});
	// 	}

	// 	const fasciaType = fasciaItems.find((fi) => headerType.includes(fi.sku));

	// 	if (fasciaType === null) {
	// 		errorSets.push({
	// 			varName: "type_fascia.",
	// 			message:
	// 				"This fascia must have the same type of fascia as others in a fascia combination."
	// 		});
	// 	}
	// }

	// if (numberOfAssembliesWithSameName > 1) {
	// 	errorSets.push({
	// 		varName: "shade_name",
	// 		message: "Shade name must be unique."
	// 	});
	// }

	// if (["dual coupled", "single coupled"].includes(shadeConfiguration)) {
	// 	let totalWidth = 0;
	// 	currentAssembly?.shades.map((shade) => shade.row_coordinate === 0 && (totalWidth += shade.width));

	// 	currentAssembly && (Math.abs(totalWidth - currentAssembly?.total_width) > 0.03)
	// 		&& errorSets.push({
	// 			varName: "coupled_width",
	// 			message: `Coupled shades' width must add up to the total width.`
	// 		});
	// }

	// return errorSets;
};

export const useErrorsBySeqAndQuoteId = (quoteId: number, sequenceId: number) => {
	const allAssemblies = useAssembliesByQuoteId(quoteId);
	const quote = useQuoteById(quoteId);
	// const hasBeenOrdered = quote.order_status !== "active_quote";
	const currentAssembly = allAssemblies.find((a) => a.sequence_id === sequenceId);

	const shadeTypeId = currentAssembly?.shade_type_id;

	// Hardcoded values for shade types
	const isIndoor = shadeTypeId === 1;
	const isCOROutdoor = shadeTypeId === 2;
	// const isPSOutdoor = shadeTypeId === 3;

	const indoorShadeOptions = useCurrentIndoorShadeOptions(quoteId)(sequenceId);
	const outdoorShadeOptions = useCurrentCOROutdoorHooks(quoteId)(sequenceId);
	const psOutdoorShadeOptions = useCurrentPSOutdoorShadeOptions(quoteId)(sequenceId);

	const shadeOptions = isIndoor ? indoorShadeOptions : isCOROutdoor ? outdoorShadeOptions : psOutdoorShadeOptions;

	const quoteCombinations = useQuoteCombination(quoteId);

	return useAssemblyErrorsByStore(
		currentAssembly ?? InitialAssemblyState(),
		quote,
		allAssemblies,
		quoteCombinations ?? ({ Fascias: [] } as QuoteCombinations),
		shadeOptions
	);
};

export const useErrorsByQuoteId = (quoteId: number) => {
	const allAssembleis = useAssembliesByQuoteId(quoteId);

	const quote = useQuoteById(quoteId);
	const assemblyOptions = UsePortalSelector(useAssemblyOptions(quoteId));

	const quoteCombinations = useQuoteCombination(quoteId) ?? { Fascias: [] };

	const indoorFabrics = useIndoorFabrics(quoteId);
	const outdoorFabrics = useOutdoorFabrics(quoteId);
	const psOutdoorFabrics = usePSOutdoorFabrics(quoteId);

	const user = useUser();

	const allIndoorShadeOptions = useNewIndoorShadeOptions(
		InitialAssemblyState(),
		() => { },
		{ fabrics: indoorFabrics, user, quote, assemblyOptions },
		true
	);
	const allCOROutdoorShadeOptions = useNewCOROutdoorHooks(
		InitialAssemblyState(),
		() => { },
		{ fabrics: outdoorFabrics, user, quote, assemblyOptions },
		true
	);
	const allPSOutdoorShadeOptions = useNewPSOutdoorShadeOptions(
		InitialAssemblyState(),
		() => { },
		{ fabrics: psOutdoorFabrics, user, quote, assemblyOptions },
		true
	);

	const allAssemblyErrors = allAssembleis.reduce((list, a) => {
		const shadeTypeId = a?.shade_type_id;
		const isIndoor = shadeTypeId === 1;
		const isCOROutdoor = shadeTypeId === 2;

		const allOptions = isIndoor
			? allIndoorShadeOptions
			: isCOROutdoor
				? allCOROutdoorShadeOptions
				: allPSOutdoorShadeOptions;

		const indoorOptions = useNewIndoorShadeOptions(
			a,
			() => { },
			{ fabrics: indoorFabrics, user, quote, assemblyOptions }
		);
		const outdoorOptions = useNewCOROutdoorHooks(
			a,
			() => { },
			{ fabrics: outdoorFabrics, user, quote, assemblyOptions }
		);
		const psOutdoorOptions = useNewPSOutdoorShadeOptions(
			a,
			() => { },
			{ fabrics: psOutdoorFabrics, user, quote, assemblyOptions }
		);

		const shadeOptions = isIndoor ? indoorOptions : isCOROutdoor ? outdoorOptions : psOutdoorOptions;

		const newErrors = selectErrorsByAllNeededOBjects(
			a,
			quote,
			allAssembleis,
			quoteCombinations,
			shadeOptions,
			allOptions,
			assemblyOptions
		);

		return newErrors.length > 0 ? [...newErrors, ...list] : list;
	}, [] as ErrorSet[]);

	return allAssemblyErrors;

	/*
(
	currentAssembly: AssemblyStore,
	quote: QuoteStore,
	allAssemblies: AssemblyStore[],
	quoteCombinations: QuoteCombinations,
	currentShadeOptions: { [key: string]: PortalShadeOption<any> },
	allShadeOptions: { [key: string]: PortalShadeOption<any> }
	*/
};
