import IndoorShadeFormFields from '../Quote/IndoorShadeFormFields';
import Button from '../Parts/Button';
import { upperFirst } from 'lodash';

const EXCLUDE_FIELDS = [
	'msrp',
	'id',
	'shade_id',
	'mfg_status',
	'manufacturing_status',
	'quote_id',
	'shades',
	'last_hash',
];

function displayValue(key, value) {
	const sff = IndoorShadeFormFields();

	const option = sff.find((opt) => opt.varName === key);

	if (option && option.type === 'select') {
		// If the option's choices are a function, we can't read them here because we are working with a shade pojo and not a function generated object.
		const choices = typeof option.choices === 'function' ? [] : option.choices;

		const choice = choices.find((c) =>
			c.value != undefined ? c.value === value : c.name === value
		);

		if (choice && choice.name) {
			return choice.name;
		}
	}

	return value;
}

const shadeFieldRows = (shade) =>
	Object.keys(shade)
		.filter((k) => !EXCLUDE_FIELDS.includes(k))
		.map((key) => (
			<tr key={key}>
				<th>{upperFirst(`${key}`.replace(/_/g, ' '))}</th>

				<td>{`${displayValue(key, shade[key])}` || 'N/A'}</td>
			</tr>
		));

const ShadeSummary = ({ quote, onClose, shade }) => (
	<div className="modal-holder">
		<div className="shipment-modal">
			<h1>
				Shade:
				{shade.shade_name}
			</h1>
			<h2 style={{ marginTop: '0' }}>
				{quote.is_order ? 'Order' : `Quote ${quote.ID}`}
			</h2>

			<br />

			<table className="shade-summary-table" border="0" cellSpacing="0" cellPadding="0">
				<tbody>{shadeFieldRows(shade)}</tbody>
			</table>

			<br />

			<div className="align-right">
				<Button color="green" onClick={onClose}>
					Done
				</Button>
			</div>

			<br />
		</div>
	</div>
);

export default ShadeSummary;
