import { useState, useEffect } from 'react';
import { isFunction } from 'lodash';

export default function FractionField(props) {
	const { option } = props;

	const optionValue = option.value ? option.value : '0';

	const [value, setValue] = useState(parseFloat(optionValue));
	      const [displayValue, setDisplayValue] = useState(Math.floor(optionValue));

	function blurred() {
		props.onChange();
		props.onBlur();
	}

	function fractionValue() {
		const valid_fractions = [0.063, 0.125, 0.188, 0.25, 0.313, 0.375, 0.438, 0.5, 0.563, 0.625, 0.688, 0.75, 0.813, 0.875, 0.938];

		const fraction_value = `0.${(`${value}`).split('.')[1]}`;
		
		if (valid_fractions.includes(parseFloat(fraction_value))) {
			return fraction_value;
		}

		return '0';
	}

	function textInputBlurred(new_val) {
		new_val = parseFloat(new_val);

		const min = (option.minValue !== null && option.maxValue !== undefined) ? (isFunction(option.minValue) ? option.minValue(option.shade) : option.minValue) : Number.MIN_VALUE;
			  const max = (option.maxValue !== null && option.maxValue !== undefined) ? (isFunction(option.maxValue) ? option.maxValue(option.shade) : option.maxValue) : Number.MAX_VALUE;

	    const update = new_val < min || new_val > max;

		if (new_val < min) {
			new_val = min;
		} else if (new_val > max) {
			new_val = max;
		}
		
		if (update) {
			numberChanged(new_val);
		}

		setDisplayValue(Math.floor(value));

		blurred();
	}

	function numberChanged(new_val) {
		// Check if the new value of a number input
		// is greater than the minimum value and less than the maximum value.
		// If not, bring it back into range.
		if (new_val > option.maxValue) {
			new_val = option.maxValue;
		}

		if ((`${new_val}`).includes('-')) {
			new_val = Math.abs(new_val);
		}

		// Set the value of the corresponding option with our validated value.
		option.value = new_val;

		setDisplayValue(option.value);

		setValue(parseFloat(option.value));
	}

	useEffect(() => {
		if (value != props.option.value) {
			numberChanged(props.option.value);
		}
	});

	return (

		<>
			<input
				disabled={props.disabled}
				name={option.field_name}
				type="number"
				value={displayValue == null ? '' : displayValue}
				onBlur={(e) => textInputBlurred(e.target.value)}
				onChange={(e) => numberChanged(e.target.value)}
				placeholder={props.placeholderLabel ? option.label : '-'}
			/>
			<select
				disabled={props.disabled}
				className="fraction-select"
				onChange={(e) => { numberChanged(Math.floor(value) + parseFloat(e.target.value)); setDisplayValue(Math.floor(value)); }}
				value={`${fractionValue()}` || ''}
			>
				<option value="0">0</option>
				<option value="0.063">1/16</option>
				<option value="0.125">1/8</option>
				<option value="0.188">3/16</option>
				<option value="0.25">1/4</option>
				<option value="0.313">5/16</option>
				<option value="0.375">3/8</option>
				<option value="0.438">7/16</option>
				<option value="0.5">1/2</option>
				<option value="0.563">9/16</option>
				<option value="0.625">5/8</option>
				<option value="0.688">11/16</option>
				<option value="0.75">3/4</option>
				<option value="0.813">13/16</option>
				<option value="0.875">7/8</option>
				<option value="0.938">15/16</option>
			</select>
		</>
	);
}
