import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useSelector } from 'react-redux';
import {
	Container, Col, Row, Form, FormSelect
} from 'react-bootstrap';
import { toast } from 'react-toastify';

import store, { UsePortalDispatch, UsePortalSelector } from '../../Store';
import { selectIsDistributorAdmin } from '../../Store/user/userPermissions';

import api from '../../PowerShadesAPI';
import { getQuoteType } from '../QuoteObjectExtensions.ts';
import Button from '../../Parts/Button';
import { useDistributorLocationsByDistributorId, useDistributors, useTerritoryById } from '../../Store/entities/hooks';
import { useActiveRMATypes, useAllRMATypes } from '../../Store/entities/rmaTypes/hooks';
import { useIsEngineering, useIsService } from '../../hooks';
import { captureSentryError, isFailedApiCall } from '../../psUtil';
import { changeQuoteMultiplier } from '../../Store/entities/multipliers';
import { UseMultipliersByQuoteId, useQuoteById } from '../../Store/entities/quotes/hooks';
import { loadQuoteById, repriceQuote } from '../../Store/entities/quotes';
import { setAssembliesUnlocked } from '../../Store/entities/assemblies';
import { useAssembliesByQuoteId } from '../../Store/entities/assemblies/hooks';
import { usePortfolios } from '../../Store/entities/portfolios/hooks';
import { updateQuotePortfolioCode } from '../../Store/entities/portfolios';

const QuoteLevelVariables = ({ newQuote, isPsAdmin, isRepresentative, onChange }) => {
	const quoteObj = newQuote.QuoteSubstitution;
	const isEditable = newQuote.QuoteSubstitution.editable;
	const userData = store.getState().user;

	const [open, setOpen] = useState(false);
	const [representatives, setRepresentatives] = useState(false);
	const [salespeople, setSalespeople] = useState([]);
	const [repId, setRepId] = useState(quoteObj.representativeId);
	const [distId, setDistId] = useState(quoteObj.distributorId);
	const [distLocationId, setDistLocationId] = useState(quoteObj.distributorLocationId);
	const [salespersonId, setSalespersonId] = useState(quoteObj.salespersonId);
	const [willShip, setWillShip] = useState(quoteObj.willShip);
	const [currentPricing, setCurrentPricing] = useState(newQuote.Money.currentMultiplierName);

	const [isLightgapEnabled, setIsLightgapEnabled] = useState(
		newQuote.Data.quote?.IsLightgapChoiceActive == true
	);
	const [isRailroadPreventionDisabled, setIsRailroadPreventionDisabled] = useState(
		newQuote.Data.quote?.IsRailroadPreventionDeactivated == true
	);
	const [convertingToDemo, setConvertingToDemo] = useState(false);

	const distributors = useDistributors();

	const { preShipWhips, is_order } = quoteObj;

	const { setDistributor, setRepresentative, setToEvolution, setDistributorLocation } = newQuote.Data;

	const quoteType = getQuoteType(newQuote);

	const distributorLocations = useDistributorLocationsByDistributorId(distId);

	const dispatch = UsePortalDispatch();

	const quote = useQuoteById(newQuote.Id);
	const territory = useTerritoryById(quote.territory_id ?? 0 );

	const contentStyle = {
		overflowY: 'auto',
		borderRadius: '5px',
		padding: '1em',
	};

	const overlayStyle = {
		overflowY: 'auto',
	};

	const isDistributorAdmin = useSelector(selectIsDistributorAdmin);

	const isEvolution =
		userData?.roles?.some((role) => role?.entity_name?.includes('Evolution')) ?? false;

	const hasAccessToUnlockAndReprice = (() => {
		if (isPsAdmin) {
			return true;
		}
		if (isEvolution && isDistributorAdmin) {
			return true;
		}
		return false;
	})();

	useEffect(() => {
		api.getRepresentatives().then((resp) => {
			if (!resp.data) return;
			if (!resp?.data?.representatives) return;
			const { representatives } = resp.data;
			setRepresentatives(representatives);
		});

		api.getSalespeople().then((resp) => {
			const salespeopleData = resp.data.salespeople ?? [];

			if (salespeopleData) {
				salespeopleData.sort((a, b) => `${a.name}`.localeCompare(b.name));

				setSalespeople(salespeopleData);
			}
		});
	}, []);

	const sortByName = function (a, b) {
		if (a.name < b.name) {
			return -1;
		}
		if (a.name > b.name) {
			return 1;
		}
		return 0;
	};

	const shippingOptions = [];

	shippingOptions.push(
		<option key="donotship" value={0} selected={willShip != 1}>
			Do not ship this quote
		</option>
	);
	shippingOptions.push(
		<option key="ship" value={1} selected={willShip == 1}>
			Ship this quote
		</option>
	);

	const whipShippingOptions = [];

	whipShippingOptions.push(
		<option key="donotpreshipwhips" value={0} selected={preShipWhips != 1}>
			Ship with entire order
		</option>
	);
	whipShippingOptions.push(
		<option key="preshipwhips" value={1} selected={preShipWhips == 1}>
			Ship before entire order
		</option>
	);

	let distOptions = [];

	distOptions.push(
		<option key={distId} selected={distId == 0 || distId == null} value={0}>
			None
		</option>
	);

	if (distributors) {
		const adds = distributors.sort(sortByName).map((d) => (
			<option key={d.id} selected={d.id == distId} value={d.id}>
				{d.name}
			</option>
		));

		distOptions = distOptions.concat(adds);
	}
	let distLocationOptions = [];

	distLocationOptions.push(
		<option key={distLocationId} selected={distLocationId == 0 || distLocationId == null} value={0}>
			None
		</option>
	);

	if (distributorLocations) {
		const adds = distributorLocations.sort(sortByName).map((d) => (
			<option key={d.id} selected={d.id == distId} value={d.id}>
				{d.name}
			</option>
		));

		distLocationOptions = distLocationOptions.concat(adds);
	}

	let repOptions = [];

	repOptions.push(
		<option key={repId} selected={repId == 0 || repId == null}>
			None
		</option>
	);

	if (representatives) {
		const adds = representatives.sort(sortByName).map((d) => (
			<option key={d.id} selected={d.id == repId} value={d.id}>
				{d.name}
			</option>
		));

		repOptions = repOptions.concat(adds);
	}

	const costOptions = [];
	costOptions.push(
		<option key="MSRP" value="MSRP" selected={currentPricing === 'MSRP'}>
			MSRP
		</option>
	);

	const isSalesperson = UsePortalSelector((state) =>
		state.user?.roles?.some((r1) => r1.role_name == 'dealer_salesperson')
	);

	if (!isSalesperson) {
		costOptions.push(
			<option key="pricing" value="buy" selected={currentPricing === 'buy'}>
				{isRepresentative ? 'Dealer Price' : 'My Price'}
			</option>
		);
	}
		
	if (newQuote.Money.multipliers?.find((m) => m.name == 'paid')) {
		costOptions.push(
			<option key="invoicesnapshot" value="paid" selected={currentPricing == 'paid'}>
				Invoice Snapshot
			</option>
		);
	}

	if (newQuote.IsBidSpec) {
		costOptions.push(
			<option key="bidspec" value="bidspec" selected={currentPricing == 'bidspec'}>
				BidSpec
			</option>
		);
	}

	// if((territory?.msrp_multiplier ?? 1) != 1)
	// {
	// 	const territoryCurrency = territory.iso_4217_currency_code_sell ;
	// 	costOptions.push(
	// 		<option key="non-us-currency" value={`MSRP-${territoryCurrency}`} selected={currentPricing == 'non-us-currency'}>
	// 			{`MSRP (${territoryCurrency})`}
	// 		</option>
	// 	);
	// }

	const quoteMultipliers = UseMultipliersByQuoteId(newQuote.Id);

	const costOptions2 = quoteMultipliers?.list.map(qm => {
		return (
			<option key={qm.id} value={qm.name} selected={currentPricing == qm.name}>
				{qm.name}
			</option>
		);
	}) ?? [];

	const salespersonItems = [];

	salespeople.forEach((sp) => {
		salespersonItems.push(
			<option key={sp.id} value={sp.id} selected={salespersonId == sp.id}>
				{sp.name}
			</option>
		);
	});

	const [localPreviousQuoteId, setLocalPreviousQuoteId] = useState(
		quoteObj.previousQuoteId ?? ''
	);
	const [editingPreviousQuoteId, setEditingPreviousQuoteId] = useState(false);

	const localPreviousQuoteSave = () => {
		// API call to BE
		newQuote.setPreviousQuoteId(localPreviousQuoteId, (success, error) => {
			// Do the checks
			if (!success) {
				// Set error;
				ROOT.alert({
					text: error,
					title: 'Quote Id Error',
					quickFormat: 'error',
				})
					.then(() => {
						// If bad it back to it's base
						setLocalPreviousQuoteId(quoteObj.previousQuoteId ?? '-');
						setEditingPreviousQuoteId(false);
					})
					.catch(() => { });
				// TODO Set error message
			} else {
				// else set it locally
				setLocalPreviousQuoteId(localPreviousQuoteId);
				setEditingPreviousQuoteId(false);
			}
			// either way stop edit mode
		});

		// either way stop edit mode
	};

	const localPreviousQuoteCancel = () => {
		// set it back to it's base
		setLocalPreviousQuoteId(quoteObj.previousQuoteId);

		// stop edit mode
		setEditingPreviousQuoteId(false);
	};

	const quoteId = newQuote.Id;

	const demoizeQuote = () => {
		setConvertingToDemo(true);
		ROOT.alert({
			text: 'Make this Quote a Demo Quote?',
			title: 'Demo Quote',
			acceptButtonText: 'Make Demo',
			onAccept: () => {
				api.makeQuoteDemo(quoteId).then((resp) => {
					if (isFailedApiCall(resp)) {
						captureSentryError(new Error('Quote conversion to demo cancelled!'));
						toast.error('Quote conversion to demo failed');
						setConvertingToDemo(false);
						return;
					}
					toast.success('Quote converted to demo successfully');
					setConvertingToDemo(false);
					setOpen(false);
					newQuote.forceRerender();
				});
			},
			onCancel: () => {
				setConvertingToDemo(false);
				toast.info('Quote conversion to demo cancelled');
			}
		});
	};

	const lightGapOptions = [
		<option key="lightgapdisabled" value={false}>
			Disabled
		</option>,
		<option key="lightgapenabled" value>
			Enabled
		</option>,
	];

	const railrodPreventionOptions = [
		<option key="railroadenabled" value>
			Enabled
		</option>,
		<option key="railroaddisabled" value={false}>
			Disabled
		</option>,
	];

	const assemblies = useAssembliesByQuoteId(quoteId);
	const assyLineNumbers = assemblies.map((a) => a.sequence_id);

	const UnlockAndRepriceEachShade = () => {
		dispatch(repriceQuote({quoteId}));
		dispatch(setAssembliesUnlocked({
			quoteId,
			unlocked: true,
			sequenceIds: assyLineNumbers
		}))
		setOpen(false);
		ROOT.toast('Shades unlocked and repricing...', { type: 'success' });
	};

	const shipToEvoOptions = [
		<option key="shipToEvoDisabled" value={"false"}>
			Ship Normaly
		</option>,
		<option key="shipToEvoEnabled" value={"true"}>
			Ship to Evolution
		</option>
	]

	const shipToEvoActive = newQuote.Data.getEvo();

	const RMATypes = is_order ? useAllRMATypes() : useActiveRMATypes() ;
	const isService = useIsService();

	const rmaTypeOptions = RMATypes.map((rmaType) => (
		<option key={rmaType.id} value={rmaType.id}>
			{rmaType.name}
		</option>
	));

	const rmaTypeId = newQuote.Data.quote?.rma_type_id;

	// Hardcoded one for now, we'll redux it later.
	const isRma = newQuote.Data.quote?.quote_type_id === 1;

	const isEngineering = useIsEngineering();

	const portfolios = usePortfolios();
	const portfolioOptions = portfolios.map((portfolio) => (
		<option key={portfolio.code} value={portfolio.code}>
			{portfolio.name}
		</option>
	));

	const updatePortfolio = (portfolioCode) => {
		dispatch(updateQuotePortfolioCode({quoteId, portfolioCode})).then(() =>{
			window.location.reload();
		});
	};

	return (
		<>
			<Button
				color={quoteType ?? 'green'}
				fullWidth
				title="Change Quote Level Advanced settings"
				key="advanced-settings"
				onClick={() => setOpen(true)}
			>
				Quote Settings
			</Button>
			<Popup
				className="mobile-modal"
				key="advanced-setings-modal"
				open={open}
				{...{ contentStyle }}
				{...{ overlayStyle }}
				onClose={() => setOpen(false)}
				closeOnDocumentClick={true}
			>
				<Container className="local-bootstrap">
					<Row>
						<Col xs={6}>
							<Row className="mb-4">
								<Col>
									<h5 className={`text-${quoteType}`}>Shipment Selection</h5>
									<Form>
										<Form.Group>
											<FormSelect
												size="sm"
												disabled={!isEditable}
												onChange={(e) => {
													onChange(
														'ship_order',
														parseInt(e.target.value),
														true
													);
													setWillShip(e.target.value);
												}}
											>
												{shippingOptions}
											</FormSelect>
										</Form.Group>
									</Form>
								</Col>
							</Row>
						</Col>
						<Col xs={6}>
							<Row className="mb-4">
								<Col>
									<h5 className={`text-${quoteType}`}>
										Whip Shipment Time Selection
									</h5>
									<Form>
										<Form.Group>
											<FormSelect
												size="sm"
												disabled={!isEditable}
												onChange={(e) => {
													onChange(
														'pre_ship_whips',
														parseInt(e.target.value)
													);
												}}
											>
												{whipShippingOptions}
											</FormSelect>
										</Form.Group>
									</Form>
								</Col>
							</Row>
						</Col>
						<Col xs={6}>
							<Row className="mb-4">
								<Col>
									<h5 className={`text-${quoteType}`}>Quote Display Price</h5>
									<Form>
										<Form.Group>
											<FormSelect
												size="sm"
												onChange={(e) => {
													newQuote.Money.ApplyMSRPByName(
														e.target.value,
														true
													);
													dispatch(changeQuoteMultiplier({quoteId, quoteMultiplier: e.target.value}));
													setCurrentPricing(e.target.value);
												}}
												value={currentPricing}
											>
												{costOptions2}
											</FormSelect>
										</Form.Group>
									</Form>
								</Col>
							</Row>
						</Col>
						<Col xs={6}>
							<Row className="mb-4">
								<Col>
									<h5 className={`text-${quoteType}`}>
										Override Default Light Gap
									</h5>
									<Form>
										<Form.Group>
											<FormSelect
												size="sm"
												value={isLightgapEnabled}
												disabled={!isEditable}
												onChange={(e) => {
													onChange(
														'IsLightgapChoiceActive',
														e.target.value == 'true'
													);
													setIsLightgapEnabled(e.target.value);
													newQuote.Data.BalanceAllAssemblies();
													dispatch(loadQuoteById(quoteId));
												}}
											>
												{lightGapOptions}
											</FormSelect>
										</Form.Group>
									</Form>
								</Col>
							</Row>
						</Col>
						{isEvolution || isPsAdmin ? <Col xs={6}>
							<Row className="mb-4">
								<Col>
									<h5 className={`text-${quoteType}`}>
										Ship to Evolution
									</h5>
									<Form>
										<Form.Group>
											<FormSelect
												size="sm"
												disabled={!isEditable}
												value={shipToEvoActive}
												onChange={(e) => {
													newQuote.Data.setToEvolution(e.target.value);
												}}
											>
												{shipToEvoOptions}
											</FormSelect>
										</Form.Group>
									</Form>
								</Col>
							</Row>
						</Col> : null}
						{!hasAccessToUnlockAndReprice ? null : (
							<Col xs={6}>
								<Row className="mb-4">
									<Col>
										<Row>
											<Col xs={10}>
												<h5 className={`text-${quoteType}`}>
													Unlock and Reprice Shades
												</h5>
											</Col>
											<Col xs={2}>
												<i
													className={`hoverable fas fa-info-circle text-${quoteType}`}
													onClick={() => {
														ROOT.alert({
															quickFormat: 'info',
															text: 'This will unlock all shades and reprice. The quote must be unlocked in order to do this. This can take some time to complete.',
														});
													}}
												/>
											</Col>
										</Row>
										<Button
											color={quoteType ?? 'green'}
											style={{
												paddingLeft: '16px',
												paddingRight: '16px',
												paddingBottom: 0,
												paddingTop: 0,
											}}
											disabled={!isEditable}
											onClick={() => UnlockAndRepriceEachShade()}
											title={
												isEditable
													? 'Reprice all shades'
													: 'The quote needs to be unlocked to use this button.'
											}
										>
											Reprice
										</Button>
									</Col>
								</Row>
							</Col>
						)}
						{!isPsAdmin ? null : (
							<>
								<Col xs={6}>
									<Row className="mb-4">
										<Col>
											<h5 className={`text-${quoteType}`}>
												Override Fabric Railroad
											</h5>
											<Form>
												<Form.Group>
													<FormSelect
														size="sm"
														value={isRailroadPreventionDisabled}
														disabled
														onChange={(e) => {
															onChange(
																'IsRailroadPreventionDeactivated',
																e.target.value == 'true'
															);
															setIsRailroadPreventionDisabled(
																e.target.value
															);
															newQuote.Data.RePriceWholeQuote();
														}}
														title="Please contact Engineering to activate."
													>
														{railrodPreventionOptions}
													</FormSelect>
												</Form.Group>
											</Form>
										</Col>
									</Row>
								</Col>
								<Col xs={6}>
									<Row className="mb-4">
										<Col>
											<h5 className={`text-${quoteType}`}>
												Previous Quote Id
											</h5>
											<Form>
												<Form.Group>
													<Form.Control
														size="sm"
														type="text"
														disabled={!editingPreviousQuoteId}
														value={localPreviousQuoteId}
														onChange={(e) =>
															setLocalPreviousQuoteId(e.target.value)
														}
													/>
												</Form.Group>
											</Form>
											<Row>
												{editingPreviousQuoteId ? (
													<>
														<Col xs={6}>
															<Button
																size="sm"
																style={{
																	paddingBottom: 0,
																	paddingTop: 0,
																}}
																color={quoteType ?? 'green'}
																onClick={localPreviousQuoteCancel}
															>
																Cancel
															</Button>
														</Col>
														<Col xs={6} className="text-end">
															<Button
																size="sm"
																color={quoteType ?? 'green'}
																onClick={localPreviousQuoteSave}
															>
																Save
															</Button>
														</Col>
													</>
												) : (
													<Col x={6}>
														<Button
															size="sm"
															color={quoteType ?? 'green'}
															onClick={() =>
																setEditingPreviousQuoteId(true)
															}
														>
															Edit
														</Button>
													</Col>
												)}
											</Row>
										</Col>
									</Row>
								</Col>
								<Col xs={6}>
									<Row className="mb-4">
										<Col>
											<h5 className={`text-${quoteType}`}>
												Distributor Select
											</h5>
											<Form>
												<Form.Group>
													<FormSelect
														size="sm"
														disabled={!isEditable}
														onChange={(e) => {
															setDistributor(parseInt(e.target.value, 10));
															setDistId(e.target.value);
														}}
													>
														{distOptions}
													</FormSelect>
												</Form.Group>
											</Form>
										</Col>
									</Row>
								</Col>
								<Col xs={6}>
									<Row className="mb-4">
										<Col>
											<h5 className={`text-${quoteType}`}>
												Distributor Location Select
											</h5>
											<Form>
												<Form.Group>
													<FormSelect
														size="sm"
														disabled={!isEditable}
														onChange={(e) => {
															setDistributorLocation(parseInt(e.target.value, 10));
															setDistLocationId(e.target.value);
														}}
													>
														{distLocationOptions}
													</FormSelect>
												</Form.Group>
											</Form>
										</Col>
									</Row>
								</Col>
								<Col xs={6}>
									<Row className="mb-4">
										<Col>
											<h5 className={`text-${quoteType}`}>
												Representative Select
											</h5>
											<Form>
												<Form.Group>
													<FormSelect
														size="sm"
														disabled={!isEditable}
														onChange={(e) => {
															const value = e.target.value == "None" ? 0 : parseInt(e.target.value, 10);
															setRepresentative(value);
															setRepId(value);
														}}
													>
														{repOptions}
													</FormSelect>
												</Form.Group>
											</Form>
										</Col>
									</Row>
								</Col>

								<Col xs={6}>
									<Row className="mb-4">
										<Col>
											<h5 className={`text-${quoteType}`}>
												Salesperson Select
											</h5>
											<Form>
												<Form.Group>
													<FormSelect
														size="sm"
														disabled={!isEditable}
														onChange={(e) => {
															onChange(
																'salesperson_id',
																parseInt(e.target.value)
															);
															setSalespersonId(e.target.value);
														}}
													>
														{salespersonItems}
													</FormSelect>
												</Form.Group>
											</Form>
										</Col>
									</Row>
								</Col>

								{ (isService || isEngineering ) && (<Col xs={6}>
									<Row className="mb-4">
										<Col>
											<h5 className={`text-${quoteType}`}>
												Portfolio Select
											</h5>
											<Form>
												<Form.Group>
													<FormSelect
														size="sm"
														disabled={!isEditable}
														value={quote.portfolio_code}
														onChange={(e) => {
															updatePortfolio(e.target.value);
														}}
													>
														{portfolioOptions}
													</FormSelect>
												</Form.Group>
											</Form>
										</Col>
									</Row>

								</Col>)}
							</>
						)}
						{!newQuote.IsDemo && isEditable ? (
							<Col xs={6}>
								<Row className="mb-4">
									<Col>
										<h5 className={`text-${quoteType}`}>
											Convert to Demo Quote
										</h5>
										<Button
											onClick={() => demoizeQuote()}
											loading={convertingToDemo}
											color={quoteType ?? "green"}
											size="sm"
										>
											Convert
										</Button>
									</Col>
								</Row>
							</Col>
						) : null}
						{
							((isService || isEngineering) && isRma) && (
								<Col xs={6}>
									<Row className="mb-4">
										<Col>

											<h5 className={`text-${quoteType}`}>
												RMA Type
											</h5>
											<Form>
												<Form.Group>
													<FormSelect
														size="sm"
														disabled={!isEditable}
														value={rmaTypeId}
														onChange={(e) => {
															onChange(
																'rma_type_id',
																parseInt(e.target.value, 10)
															);
														}}
													>
														{rmaTypeOptions}
													</FormSelect>
												</Form.Group>
											</Form>
										</Col>
									</Row>
								</Col>
							)
						}

					</Row>
					<Row>
						<Col>
							<Row className="justify-content-end">
								<Col className="text-end">
									<Button
										title="Close"
										key="advanced-settings-close"
										color={quoteType ?? 'green'}
										onClick={() => setOpen(false)}
									>
										Close
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Popup>
		</>
	);
};

export default QuoteLevelVariables;
