import React from 'react';

class SelectBox extends React.Component {
	constructor(props) {
		super(props);

		const { onChange, Id } = props;

		this.state = {
			Id,
			Checked: props.Checked
		};
	}

	render() {
		return (
			<input type="checkbox" checked={this.state.Checked} onChange={this.props.onChange} disabled={this.props.disabled} />
		);
	}
}

export default SelectBox;
