import xl from 'excel4node';
import { saveAs } from 'file-saver';

import accessoriesSection from './AccessoriesSection';
import fabricSamplesSection from './FabricSamplesSection';
import shadesSection from './ShadesSection';

/* eslint-disable camelcase, import/prefer-default-export */

function addressBlock({
	workbook, worksheet, startRow, label, address
}) {
	const ws = worksheet;

	let row = startRow;

	const HEADER_STYLE = workbook.createStyle({
		font: {
			size: 16,
			bold: true,
		},
	});

	ws.cell(row++, 1)
		.string(label)
		.style(HEADER_STYLE);

	ws.cell(row, 1).string('Company');

	ws.cell(row++, 2).string(address?.company_name ?? "");

	ws.cell(row, 1).string('Name');

	ws.cell(row++, 2).string(`${address?.first_name || ''} ${address?.last_name || ''}`);

	ws.cell(row, 1).string('Address');

	ws.cell(row++, 2).string(address?.address || '');

	ws.cell(row, 1).string('Address Line 2');

	ws.cell(row++, 2).string(address?.address2 || '');

	ws.cell(row, 1).string('State/Province');

	ws.cell(row++, 2).string(address?.state || '');

	ws.cell(row, 1).string('City');

	ws.cell(row++, 2).string(address?.city || '');

	ws.cell(row, 1).string('Zip/Postal Code');

	ws.cell(row++, 2).string(address?.zip ? `${address?.zip}` : '');

	ws.cell(row, 1).string('Phone');

	ws.cell(row++, 2).string(address?.phone || '');

	ws.cell(row, 1).string('Email');

	ws.cell(row++, 2).string(address?.email || '');
}

function overviewWorksheet({
	workbook,
	worksheet,
	quote,
	quote_data,
	userMultiplier,
	hidePrice,
	currencyData,
}) {
	const ws = worksheet;

	let current_line = 1;

	ws.cell(current_line, 1).string('Date Created');

	ws.cell(current_line++, 2).string(quote.created || '');

	ws.cell(current_line, 1).string(quote.is_order ? 'Order #' : 'Quote #');

	ws.cell(current_line++, 2).string(quote.ID ? `${quote.ID}` : '');

	ws.cell(current_line, 1).string('Title');

	ws.cell(current_line++, 2).string(quote_data.title);

	ws.cell(current_line, 1).string('Notes');

	ws.cell(current_line++, 2).string(quote_data.notes || '');

	addressBlock({
		workbook,
		worksheet: ws,
		label: 'Shipping Address',
		address: quote.shipping_address,
		startRow: ++current_line,
	});

	current_line = 17;

	addressBlock({
		workbook,
		worksheet: ws,
		label: 'Billing Address',
		address: quote.billing_address,
		startRow: current_line,
	});

	current_line = 28;

	addressBlock({
		workbook,
		worksheet: ws,
		label: 'Property Address',
		address: quote.property_address,
		startRow: current_line,
	});

	current_line = 40;

	// BEGIN SHADES
	current_line = shadesSection({
		workbook,
		worksheet: ws,
		quote,
		userMultiplier,
		row: current_line,
		hidePrice,
		currencyData,
	});

	// BEGIN ACCESSORIES
	current_line = accessoriesSection({
		workbook,
		worksheet: ws,
		quote,
		quote_data,
		userMultiplier,
		row: current_line,
		hidePrice,
		currencyData,
	});

	// BEGIN FABRIC SAMPLES
	current_line = fabricSamplesSection({
		workbook,
		worksheet: ws,
		quote,
		quote_data,
		userMultiplier,
		row: ++current_line,
		hidePrice,
		currencyData,
	});
}

export async function exportQuote(quoteStore, user_data, newQuote, assemblyList, multiplier) {
	const  quote = {
		shades: assemblyList,
		...quoteStore,
	};
	const quote_data = quote;
	const userMultiplier = {
		multiplier
	}
	const isManufacturer = user_data.roles.some((ur) => ur.role_name.includes('manufact'));

	const currencyData = {
		ISO: userMultiplier.multiplier.currency,
		// MSRP
		multiplier: userMultiplier.multiplier.currency_multiplier * 1,
		symbol: userMultiplier.multiplier.symbol ?? '$',
	};

	// Create a new instance of a Workbook class
	const workbook = new xl.Workbook();

	const hidePrice = isManufacturer;

	const { quoteType } = quote;

	const prefix = (() => {
		switch (quoteType) {
			case 'CEDIA':
				return `PS`;
			case 'RMA':
				return `RMA`;
			case 'Rework':
				return `RWK`;
			case 'Demo':
				return `DEMO`;
			case 'BidSpec':
				return `BID`;
			case 'Internal':
				return `INT`;
			default:
				return `PS`;
		}
	})();

	// Add Worksheets to the workbook
	overviewWorksheet({
		workbook,
		worksheet: workbook.addWorksheet('Overview'),
		quote,
		quote_data,
		userMultiplier,
		hidePrice,
		currencyData,
	});
	shadesSection({
		workbook,
		worksheet: workbook.addWorksheet('Shades'),
		quote,
		quote_data,
		userMultiplier,
		row: 1,
		hidePrice,
		currencyData,
	});
	accessoriesSection({
		workbook,
		worksheet: workbook.addWorksheet('Accessories'),
		quote,
		quote_data,
		userMultiplier,
		row: 1,
		hidePrice,
		currencyData,
	});
	fabricSamplesSection({
		workbook,
		worksheet: workbook.addWorksheet('Fabric Samples'),
		quote,
		quote_data,
		userMultiplier,
		row: 1,
		hidePrice,
	});

	workbook.writeToBuffer().then((result) => {
		const blob = new Blob([result.buffer], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		});

		saveAs(blob, `${quote.is_order ? 'Order' : 'Quote'} - ${quote.display_id}.xlsx`);
	});
}
