import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonWalking } from '@fortawesome/free-solid-svg-icons';
import { faConveyorBeltEmpty } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import type { InProductionIconProps } from './types';

const InProductionIcon = ({
	size = '1x',
	bottomColorOverride = '',
	topColorOverride = '',
	className = '',
}: InProductionIconProps) => (
	<span className={`fa-stack ${className}`}>
		<FontAwesomeIcon
			icon={faConveyorBeltEmpty as IconProp}
			size={size}
			className={`fa-stack-2x ${bottomColorOverride && `text-${bottomColorOverride}`} ps-4`}
		/>
		<FontAwesomeIcon
			icon={faPersonWalking}
			size={size}
			className={`fa-stack-2x stacked-logo text-${topColorOverride} pe-4`}
		/>
	</span>
);

export default InProductionIcon;
