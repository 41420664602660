import React, { useMemo } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

import { useDealers, useDealersLoadStatus } from '../../Store/entities/hooks';

import type { DealerFilterProps } from './types';
import { loadStatus } from '../../Store/entities/entitiesType';

const DealerFilter = (
	{
		setNewDealerIds,
		selectedDealerIds,
		allowedDealerIds
	}: DealerFilterProps) => {
	const dealers = useDealers();
	const dealersLoading = useDealersLoadStatus();
	const loading = dealersLoading <= loadStatus.loading;

	const formattedDealers = useMemo(
		() => dealers
			.filter((dealer) => dealer.status === "approved")
			.filter(({ id }) => !selectedDealerIds.includes(id))
			.filter(({ id }) => allowedDealerIds.includes(id))
			.slice()
			.map(({ id, name }) => ({ id: id ?? "", label: name ?? ""})),
		[dealers, selectedDealerIds]
	);

	const setNewDealerId = (newDealerId: number) => {
		setNewDealerIds([...selectedDealerIds, newDealerId]);
	};

	return (
		<Typeahead
			id="dealer-filter"
			paginate
			multiple
			positionFixed
			labelKey="label"
			flip
			renderToken={() => (
				<>
					{ /* EMPTY */}
					{ /* EMPTY */}
				</>
			)
			}
			placeholder={loading ? "Loading Dealers..." : "Select Dealers..."}
			options={formattedDealers}
			isLoading={loading}
			disabled={loading}
			onChange={(selected) => setNewDealerId((selected[0] as { id: number }).id)}
			selected={[]}
		/>
	);
};

export default DealerFilter;
