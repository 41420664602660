import Popup from "reactjs-popup";
import {
	ButtonGroup,
	DropdownButton,
	Dropdown,
	Col,
	Row,
} from "react-bootstrap";
import React, { useState } from 'react';

// import TagSelector from "./TagSelector.js";
// import { useIsPsAdmin } from "../hooks.js";
import QuoteWarningsHistory from "./NewItems/QuoteWarningsHistory";
import { getQuoteType } from "./QuoteObjectExtensions";
import apiCalls from "../PowerShadesAPIFunctions";
import { toast } from "react-toastify";

type QuoteAdminBarProps = {
	showCostBreakdown: () => void;
	showHistory: () => void;
	showFabricAllowedIndoor: () => void;
	showFabricAllowedOutdoor: () => void;
	showPopulateDashboardTasks: () => void;
	newQuote: any;
	setIsAddingCOROutdoorShade: (isAdding: boolean) => void;
	forceRerenderFunction: () => void;
};
// TODO:
// * Type newQuote
// * Type quote

const QuoteAdminBar = ({
	showCostBreakdown,
	showHistory,
	showFabricAllowedIndoor,
	showFabricAllowedOutdoor,
	showPopulateDashboardTasks,
	newQuote,
	setIsAddingCOROutdoorShade,
	forceRerenderFunction
}: QuoteAdminBarProps) => {
	
	// Verifica que el prop showFabricAllowed se está recibiendo
	//console.log("Received showFabricAllowedIndoor prop in QuoteAdminBar:", showFabricAllowedIndoor);
	
	// Verifica que el prop showFabricAllowed se está recibiendo
	//console.log("Received showFabricAllowedOutdoor prop in QuoteAdminBar:", showFabricAllowedOutdoor);
	


	// const isPsAdmin = useIsPsAdmin();
	const [quoteWarningsOpen, setQuoteWarningsOpen] = useState(false);

	const quoteType: string = getQuoteType(newQuote);

	const { archived, ID: quoteId } = newQuote.Data.quote;

	const restoreQuote = async () => {
		try {
			await apiCalls.restoreQuote(quoteId).then(() => forceRerenderFunction());
			toast.success("Quote Restored!");
		} catch {
			toast.error("Failed to Restore Quote.");
		}
	};

	return (
		<Row className="justify-content-end">
			<Col xl={9} lg={12} md={6} sm={6} xs={12} className="mt-2">
				<DropdownButton
					as={ButtonGroup}
					variant={quoteType || 'cedia'}
					title="Admin Actions"
				>
					<Dropdown.Item eventKey="1" onClick={showCostBreakdown}>
						View Cost Breakdown
					</Dropdown.Item>
					<Dropdown.Item eventKey="2" onClick={showHistory}>
						View History
					</Dropdown.Item>
					<Dropdown.Item eventKey="3" onClick={showPopulateDashboardTasks}>
						Dashboard Tasks
					</Dropdown.Item>
					<Dropdown.Item onClick={() => setQuoteWarningsOpen(true)}>Quote Warnings</Dropdown.Item>
					<Dropdown.Item onClick={() => setIsAddingCOROutdoorShade(true)}>Add Corradi Outdoor</Dropdown.Item>
					<Dropdown.Item hidden={!archived} onClick={restoreQuote}>Restore Quote</Dropdown.Item>
					<Dropdown.Item eventKey="4" onClick={() => {
    															console.log("Dropdown Item clicked");
   																 showFabricAllowedIndoor();
  															   }}>
						Enable Disc. Indoor Fabrics
					</Dropdown.Item>
					<Dropdown.Item eventKey="5" onClick={() => {
    															console.log("Dropdown Item clicked");
   																 showFabricAllowedOutdoor();
  															   }}>
						Enable Disc. PsOutdoor Fabrics
					</Dropdown.Item>
				</DropdownButton>
			</Col>
			<Col xl={9} lg={12} md={6} sm={6} xs={12} className="mt-2">
				{/* {isPsAdmin ? <TagSelector fullWidth quote={quote} variant={quoteType} /> : null} */}
			</Col>
			<Popup
				modal
				className="qwh mobile-modal"
				open={quoteWarningsOpen}
			>
				<QuoteWarningsHistory
					newQuote={newQuote}
					close={() => setQuoteWarningsOpen(false)}
				/>
			</Popup>
		</Row>
	);
};

export default QuoteAdminBar;
