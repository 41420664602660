// This is a list of legacy endpoints, it must match the legacy backend's version. Ideally this isn't updated past this point.
const legacyEnpointsArr = {
	addAdminEmailAddress:
    {
    	required:
            [
            	"email_address"
            ]
    },
	addCoupon:
    {
    	required:
            [
            	"code",
            	"note",
            	"discount_type",
            	"discount_amount",
            	"expires",
            	"max_total_uses",
            	"max_uses_per_dealer"
            ]
    },
	addCouponToQuote:
    {
    	required:
            [
            	"code",
            	"quote_id",
            	"removal"
            ]
    },
	addDashboardTask:
    {
    	required:
            [
            	"quote_id",
            	"dashboard_task_id"
            ]
    },
	addDownloadLink:
    {
    	required:
            [
            	"category_id",
            	"name",
            	"url",
            	"button_text"
            ]
    },
	addDownloadLinkCategory:
    {
    	required:
            [
            	"name"
            ]
    },
	addTag:
    {
    	required:
            [
            	"text",
            	"color"
            ]
    },
	approveOrder:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	archiveQuote:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	assignDealer:
    {
    	required:
            [
            	"dealer_id",
            	"quote_id"
            ]
    },
	assignManufacturerToOrder:
    {
    	required:
            [
            	"manufacturer_id",
            	"quote_id"
            ]
    },
	convertToOrder:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	createDistributor:
    {
    	required:
            [
            	"distributor"
            ]
    },
	createGlobalNotification:
    {
    	required:
            [
            	"title",
            	"description",
            	"link"
            ]
    },
	createManufacturer:
    {
    	required:
            [
            	"manufacturer"
            ]
    },
	createRepresentative:
    {
    	required:
            [
            	"representative"
            ]
    },
	dealerCreateUser:
    {
    	required:
            [
            	"dealer_id",
            	"user"
            ]
    },
	createUser:
    {
    	required:
            [
            	"user"
            ]
    },
	createTerritory:
    {
    	required:
            [
            	"territory"
            ]
    },
	dealerSignup:
    {
    	required:
            [
            	"form_data"
            ]
    },
	deleteAdminEmailAddress:
    {
    	required:
            [
            	"email_address_id"
            ]
    },
	deleteCoupon:
    {
    	required:
            [
            	"code"
            ]
    },
	deleteDownloadLink:
    {
    	required:
            [
            	"download_link_id"
            ]
    },
	deleteDownloadLinkCategory:
    {
    	required:
            [
            	"category_id"
            ]
    },
	duplicateQuote:
    {
    	required:
            [
            	"quote_id",
            	"quote_type",
            	"motors"
            ]
    },
	forceSaveQuote:
    {
    	required:
            [
            	"quote_id",
            	"save_data"
            ]
    },
	getAccessories:
    {
    	required:
            []
    },
	getAdminEmailAddresses:
    {
    	required:
            []
    },
	getCardOnFile:
    {
    	required:
            []
    },
	getCountrySubdivisions:
    {
    	required:
            []
    },
	getCoupons:
    {
    	required:
            []
    },
	getCouponForQuote:
    {
    	required:
            [
            	"quote_id",
            	"code"
            ]
    },
	getEmailTemplatesList:
    {
    	required:
            []
    },
	getIndoorFabrics:
    {
    	required:
            []
    },
	getOutdoorFabrics:
    {
    	required:
            []
    },
	getDealer:
    {
    	required:
            [
            	"dealer_id"
            ]
    },
	getDealers:
    {
    	required:
            []
    },
	getDealersByTerritory:
    {
    	required:
            [
            	"territory_id"
            ]
    },
	getPendingDealers:
    {
    	required:
            []
    },
	getDealerUsers:
    {
    	required:
            [
            	"dealer_id"
            ]
    },
	getDistributor:
    {
    	required:
            [
            	"distributor_id"
            ]
    },
	getDistributors:
    {
    	required:
            []
    },
	getDistributorsMeta:
    {
    	required:
            []
    },
	getManufacturer:
    {
    	required:
            [
            	"manufacturer_id"
            ]
    },
	getManufacturers:
    {
    	required:
            []
    },
	getRepresentative:
    {
    	required:
            [
            	"representative_id"
            ]
    },
	getRepresentatives:
    {
    	required:
            []
    },
	getRepresentativesMeta:
    {
    	required:
            []
    },
	getDownloadLinkCategories:
    {
    	required:
            []
    },
	getDownloadLinks:
    {
    	required:
            []
    },
	saveQuoteAdminPayment:
    {
    	required:
            [
            	"quote_id",
            	"final_quote_data",
            	"quote_type"
            ]
    },
	getManufacturerAcceptedOrders:
    {
    	required:
            [
            	"page_num",
            	"results_per_page",
            	"search_query",
            	"order_by",
            	"sort_direction",
            	"filter_tag",
            	"skip_totals",
            	"territory_filter",
            	"dealer_filter"
            ]
    },
	getManufacturerShippedOrders:
    {
    	required:
            [
            	"page_num",
            	"results_per_page",
            	"search_query",
            	"order_by",
            	"sort_direction",
            	"filter_tag",
            	"skip_totals",
            	"territory_filter",
            	"dealer_filter"
            ]
    },
	getManufacturerSubmittedOrders:
    {
    	required:
            [
            	"page_num",
            	"results_per_page",
            	"search_query",
            	"order_by",
            	"sort_direction",
            	"filter_tag",
            	"skip_totals",
            	"territory_filter",
            	"dealer_filter"
            ]
    },
	getSubmittedOrdersByManufacturer:
    {
    	required:
            [
            	"manufacturer_id",
            	"page_num",
            	"results_per_page",
            	"search_query",
            	"order_by",
            	"sort_direction"
            ]
    },
	getTags:
    {
    	required:
            []
    },
	getQuoteTags:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	getUser:
    {
    	required:
            [
            	"user_id"
            ]
    },
	getUsers:
    {
    	required:
            []
    },
	getUsersList:
    {
    	required:
            []
    },
	getInProductionOrdersByManufacturer:
    {
    	required:
            [
            	"manufacturer_id",
            	"page_num",
            	"results_per_page",
            	"search_query",
            	"order_by",
            	"sort_direction"
            ]
    },
	getSentEmails:
    {
    	required:
            [
            	"template_name"
            ]
    },
	getShippedOrdersByManufacturer:
    {
    	required:
            [
            	"manufacturer_id",
            	"page_num",
            	"results_per_page",
            	"search_query",
            	"order_by",
            	"sort_direction"
            ]
    },
	getSubmittedOrders:
    {
    	required:
            [
            	"page_num",
            	"results_per_page",
            	"search_query",
            	"order_by",
            	"sort_direction",
            	"filter_tag",
            	"skip_totals",
            	"territory_filter",
            	"dealer_filter"
            ]
    },
	getNotSubmittedOrders:
    {
    	required:
            [
            	"page_num",
            	"results_per_page",
            	"search_query",
            	"order_by",
            	"sort_direction",
            	"filter_tag",
            	"skip_totals",
            	"territory_filter",
            	"dealer_filter"
            ]
    },
	getInProductionOrders:
    {
    	required:
            [
            	"page_num",
            	"results_per_page",
            	"search_query",
            	"order_by",
            	"sort_direction",
            	"filter_tag",
            	"skip_totals",
            	"territory_filter",
            	"dealer_filter"
            ]
    },
	getPopulateDashboardProperties:
    {
    	required:
            [
            	"dealer_id",
            	"company_name"
            ]
    },
	getShippedOrders:
    {
    	required:
            [
            	"page_num",
            	"results_per_page",
            	"search_query",
            	"order_by",
            	"sort_direction",
            	"filter_tag",
            	"skip_totals",
            	"territory_filter",
            	"dealer_filter"
            ]
    },
	getOrders:
    {
    	required:
            [
            	"page_num",
            	"results_per_page",
            	"search_query",
            	"order_by",
            	"sort_direction",
            	"filter_tag",
            	"skip_totals",
            	"territory_filter",
            	"dealer_filter"
            ]
    },
	getPendingOrders:
    {
    	required:
            [
            	"page_num",
            	"results_per_page",
            	"search_query",
            	"order_by",
            	"sort_direction",
            	"filter_tag",
            	"skip_totals",
            	"territory_filter",
            	"dealer_filter"
            ]
    },
	getCreditDeclinedQuotes:
    {
    	required:
            [
            	"page_num",
            	"results_per_page",
            	"search_query",
            	"order_by",
            	"sort_direction",
            	"filter_tag",
            	"skip_totals",
            	"territory_filter",
            	"dealer_filter"
            ]
    },
	calculateQuoteMSRP:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	calculateShadeMSRPForQuote:
    {
    	required:
            [
            	"quote_id",
            	"shade_data"
            ]
    },
	updateShadesToSprings:
    {
    	required:
            [
            	"data"
            ]
    },
	getDealerTerms:
    {
    	required:
            []
    },
	getQuoteMSRP:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	getQuotes:
    {
    	required:
            [
            	"page_num",
            	"results_per_page",
            	"search_query",
            	"order_by",
            	"sort_direction",
            	"filter_tag",
            	"skip_totals",
            	"territory_filter",
            	"dealer_filter"
            ]
    },
	getTerritory:
    {
    	required:
            [
            	"territory_id"
            ]
    },
	getTerritories:
    {
    	required:
            []
    },
	getTerritoriesMeta:
    {
    	required:
            []
    },
	getUserInfo:
    {
    	required:
            [
            	"user_id"
            ]
    },
	getUserName:
    {
    	required:
            [
            	"user_id"
            ]
    },
	getLastUpdatedQuotes:
    {
    	required:
            [
            	"page_num",
            	"results_per_page",
            	"search_query",
            	"order_by",
            	"sort_direction",
            	"filter_tag",
            	"skip_totals",
            	"territory_filter",
            	"dealer_filter"
            ]
    },
	populateDashboard:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	getQuoteAddressSnapshot:
    {
    	required:
            [
            	"property_name",
            	"dealer_id"
            ]
    },
	uploadFederalCert:
    {
    	required:
            [
            	"file"
            ]
    },
	getQuoteHistory:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	upsertQuoteToZoho:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	fiveMinuteRoutine:
    {
    	required:
            []
    },
	getCreditCards:
    {
    	required:
            []
    },
	getCreditCardLink:
    {
    	required:
            []
    },
	getPaymentMethods:
    {
    	required:
            []
    },
	bulkDealerUpdate:
    {
    	required:
            []
    },
	upsertSpecificDealers:
    {
    	required:
            [
            	"name"
            ]
    },
	bulkDistributorUpdate:
    {
    	required:
            []
    },
	bulkRepresentativeUpdate:
    {
    	required:
            []
    },
	listChannelTypes:
    {
    	required:
            []
    },
	listBuyingGroupsTypes:
    {
    	required:
            []
    },
	dealerNameExists:
    {
    	required:
            [
            	"dealer_name",
            	"dealer_id"
            ]
    },
	oneHourTimer:
    {
    	required:
            []
    },
	changeAccessoryQuantity:
    {
    	required:
            [
            	"quote_id",
            	"accessory_sku",
            	"quantity"
            ]
    },
	changeFabricSampleQuantity:
    {
    	required:
            [
            	"quote_id",
            	"fabric_id",
            	"quantity",
            	"name"
            ]
    },
	getQuoteCounts:
    {
    	required:
            [
            	"search_query",
            	"filter_tag",
            	"territory_filter",
            	"dealer_filter"
            ]
    },
	getRecentNotifications:
    {
    	required:
            []
    },
	ensureBigCommerceOrders:
    {
    	required:
            []
    },
	getAllNotifications:
    {
    	required:
            []
    },
	getMyBillingAddresses:
    {
    	required:
            [
            	"page_num",
            	"results_per_page",
            	"search_query"
            ]
    },
	getMyPropertyAddresses:
    {
    	required:
            [
            	"page_num",
            	"results_per_page",
            	"search_query"
            ]
    },
	getMyShippingAddresses:
    {
    	required:
            [
            	"page_num",
            	"results_per_page",
            	"search_query"
            ]
    },
	isQuoteLocked:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	calculateAssemblyPrice:
    {
    	required:
            [
            	"assemblies",
            	"quote_id"
            ]
    },
	calculateAndSaveAssemblyPrice:
    {
    	required:
            [
            	"quote",
            	"quoteId",
            	"no_repricing"
            ]
    },
	DeleteAssembly:
    {
    	required:
            [
            	"quote_id",
            	"sequence_ids"
            ]
    },
	AddAssembly:
    {
    	required:
            [
            	"quote_id",
            	"shade_type"
            ]
    },
	loadQuote:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	updateZohoInvoice:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	loadQuoteTwo:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	duplicateShade:
    {
    	required:
            [
            	"new_shade_info",
            	"quote_id"
            ]
    },
	getInvoiceData:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	makePayment:
    {
    	required:
            [
            	"quote_id",
            	"payment_type",
            	"payment_args"
            ]
    },
	getPricingData:
    {
    	required:
            []
    },
	markAsPaid:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	markRequirePayment:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	manufacturerAcceptOrder:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	removeDealerFromQuote:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	checkAndSaveErrors:
    {
    	required:
            [
            	"quote_id",
            	"front_end_errors"
            ]
    },
	removeUserFromDealer:
    {
    	required:
            [
            	"user_id",
            	"dealer_id"
            ]
    },
	removeUserFromDistributor:
    {
    	required:
            [
            	"user_id",
            	"distributor_id"
            ]
    },
	removeUserFromRepresentative:
    {
    	required:
            [
            	"user_id",
            	"representative_id"
            ]
    },
	saveQuote:
    {
    	required:
            [
            	"quote_id",
            	"save_data"
            ]
    },
	saveQuoteAddress:
    {
    	required:
            [
            	"quote_id",
            	"address",
            	"address_type"
            ]
    },
	saveQuoteTwo:
    {
    	required:
            [
            	"quoteJson",
            	"quote_id"
            ]
    },
	createQuote:
    {
    	required:
            [
            	"territory_id",
            	"quote_type_id"
            ]
    },
	sendDealerInvite:
    {
    	required:
            [
            	"signup_link_query",
            	"recipients"
            ]
    },
	setAccessoryMfgStatus:
    {
    	required:
            [
            	"quote_id",
            	"accessory_id",
            	"status"
            ]
    },
	setDealerStatus:
    {
    	required:
            [
            	"dealer_id",
            	"status"
            ]
    },
	setQuoteTags:
    {
    	required:
            [
            	"quote_id",
            	"quote_tags"
            ]
    },
	setShadeAssemblyMfgStatus:
    {
    	required:
            [
            	"quote_id",
            	"shade_id",
            	"status"
            ]
    },
	shipOrder:
    {
    	required:
            [
            	"quote_id",
            	"carrier_name",
            	"tracking_number"
            ]
    },
	unlockedQuote:
    {
    	required:
            [
            	"quote_id",
            	"reason"
            ]
    },
	updateAdminEmailAddress:
    {
    	required:
            [
            	"email_address_id",
            	"receives_dealer_signup_info",
            	"receives_order_conversion_emails"
            ]
    },
	updateDealer:
    {
    	required:
            [
            	"dealer_id",
            	"dealer"
            ]
    },
	updateDistributor:
    {
    	required:
            [
            	"distributor_id",
            	"distributor"
            ]
    },
	updateManufacturer:
    {
    	required:
            [
            	"manufacturer_id",
            	"manufacturer"
            ]
    },
	updateRepresentative:
    {
    	required:
            [
            	"representative_id",
            	"representative"
            ]
    },
	updateTerritory:
    {
    	required:
            [
            	"territory_id",
            	"territory"
            ]
    },
	updateJWT:
    {
    	required:
            [
            	"user_jwt"
            ]
    },
	userReadNotifications:
    {
    	required:
            []
    },
	usersOnline:
    {
    	required:
            []
    },
	quoteExists:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	viewingPage:
    {
    	required:
            [
            	"url"
            ]
    },
	updateAcessories:
    {
    	required:
            []
    },
	bulkUpsertDealsToZoho:
    {
    	required:
            [
            	"all_quotes"
            ]
    },
	cancelMFGOrder:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	readyToSubmited:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	submitedToProduction:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	getFabricCsv:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	saveQuoteVerifications:
    {
    	required:
            [
            	"quote_id",
            	"warnings"
            ]
    },
	quoteWarningsHistory:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	readyToSubmitted:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	submittedToProduction:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	willNeedRetainer:
    {
    	required:
            [
            	"quote_id",
            	"payment_type"
            ]
    },
	orderShipped:
    {
    	required:
            [
            	"json_data"
            ]
    },
	getAccessableEntities:
    {
    	required:
            []
    },
	getDealerEmails:
    {
    	required:
            [
            	"dealer_id"
            ]
    },
	getDistributorEmails:
    {
    	required:
            [
            	"distributor_id"
            ]
    },
	getRepresentativeEmails:
    {
    	required:
            [
            	"representative_id"
            ]
    },
	saveDealerDistributionEmails:
    {
    	required:
            [
            	"dealer_id",
            	"emails"
            ]
    },
	saveDistributorDistributionEmails:
    {
    	required:
            [
            	"distributor_id",
            	"emails"
            ]
    },
	saveRepresentativeDistributionEmails:
    {
    	required:
            [
            	"representative_id",
            	"emails"
            ]
    },
	getShippingInfo:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	changeShadeOrder:
    {
    	required:
            [
            	"quote_id",
            	"initial_order",
            	"final_order"
            ]
    },
	combineFascia:
    {
    	required:
            [
            	"quote_id",
            	"sequence_ids"
            ]
    },
	willOrderShip:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	deleteCombinedFascia:
    {
    	required:
            [
            	"quote_id",
            	"fascia_combination_id"
            ]
    },
	updateCombinedFascia:
    {
    	required:
            [
            	"quote_id",
            	"fascia_combination_id",
            	"total_width"
            ]
    },
	getSalespeople:
    {
    	required:
            []
    },
	saveUserAttribute:
    {
    	required:
            [
            	"user_id",
            	"attribute_name",
            	"attribute_value"
            ]
    },
	getQuoteTypes:
    {
    	required:
            []
    },
	listOrganizations:
    {
    	required:
            [
            	"type"
            ]
    },
	createOrganization:
    {
    	required:
            [
            	"options"
            ]
    },
	readOrganization:
    {
    	required:
            [
            	"organization_id"
            ]
    },
	updateOrganization:
    {
    	required:
            [
            	"organization_id",
            	"options"
            ]
    },
	deleteOrganiztion:
    {
    	required:
            [
            	"organization_id"
            ]
    },
	assignContractor:
    {
    	required:
            [
            	"quote_id",
            	"contractor_id"
            ]
    },
	saveOrganizationAddress:
    {
    	required:
            [
            	"organization_id",
            	"address_options"
            ]
    },
	setShadeLabor:
    {
    	required:
            [
            	"quote_id",
            	"sequence_id",
            	"labor_amount"
            ]
    },
	setShadeOverhead:
    {
    	required:
            [
            	"quote_id",
            	"sequence_id",
            	"overhead_amount"
            ]
    },
	setShadeMarkup:
    {
    	required:
            [
            	"quote_id",
            	"sequence_id",
            	"markup_amount"
            ]
    },
	getShadeAssembliesAsPricing:
    {
    	required:
            [
            	"quote_id",
            	"increment"
            ]
    },
	GetQuoteInfo:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	GetQuoteInfo2:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	SetQuotePONum:
    {
    	required:
            [
            	"quote_id",
            	"po_num"
            ]
    },
	GetQuotePONum:
    {
    	required:
            [
            	"quote_id"
            ]
    },
	SetPreviousQuoteId:
    {
    	required:
            [
            	"quote_id",
            	"previous_quote_id"
            ]
    },
	GetFakeJwt:
    {
    	required:
            [
            	"new_id"
            ]
    },
	BuyerData:
    {
    	required:
            [
            	"quote_id",
            	"user_id",
            	"payment_type"
            ]
    },
	submitOrder:
    {
    	required:
            [
            	"quote_id",
            	"user_id",
            	"representative_commision",
            	"distributor_commision"
            ]
    },
	GetServerJWT:
    {
    	required:
            []
    },
	oneTimeRecipe:
    {
    	required:
            [
            	"key2"
            ]
    },
	emailerTest:
    {
    	required:
            []
    }
};

const GetLegacyEndpoints = () => legacyEnpointsArr;

export default GetLegacyEndpoints;
