// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GoToQuote-module__search-bar___FyI5O{max-width:100%}`, "",{"version":3,"sources":["webpack://./src/Parts/GoToQuote/GoToQuote.module.scss"],"names":[],"mappings":"AAAA,sCACC,cAAA","sourcesContent":[".search-bar {\n\tmax-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search-bar": `GoToQuote-module__search-bar___FyI5O`,
	"searchBar": `GoToQuote-module__search-bar___FyI5O`
};
export default ___CSS_LOADER_EXPORT___;
