/* eslint-disable camelcase */
import React from 'react';
import {
	Card, Col, Row,
} from 'react-bootstrap';

import type { QuoteCardProps } from './types';

import styles from './QuoteCards.module.scss';
import { useQuoteById, } from '../../Store/entities/quotes/hooks';
import SkeletonQuoteCard from './SkeletonCard';
import QuoteHomeActions from './QuoteHomeActions';
import { useGetQuoteHomeColumns } from './hooks';
import InternalLink from '../../Parts/InternalLink';

const QuoteCard = ({ quoteId }: QuoteCardProps) => {
	const quote = useQuoteById(quoteId);
	const valsToDisplay = useGetQuoteHomeColumns()(quote?.id);

	if (!quote) return <SkeletonQuoteCard />;


	const customDisplayColumns = valsToDisplay.columns.filter((column) =>
		column.key !== 'company'
		&& column.key !== 'created_by'
		&& column.key !== 'id'
	);
	const displayId = valsToDisplay.columns.find((column) => column.key === 'id');
	const company = valsToDisplay.columns.find((column) => column.key === 'company');
	const createdBy = valsToDisplay.columns.find((column) => column.key === 'created_by');

	return (
		<Col md={6} className="mb-2">
			<Card className={`mb-1 ${styles.card} h-100 quote-card`}>
				<Card.Body className={styles.cardBody}>
					<Card.Title>
						<Row>
							<Col xs={10} className={styles.cardTitle} href={displayId?.href}>
								<InternalLink content={displayId?.href}>
									<h5>
										{displayId?.accessor()}
									</h5>
								</InternalLink>
							</Col>
							<Col xs={1} sm={2} className="text-end">
								<QuoteHomeActions quoteId={quote.id} />
							</Col>
						</Row>
					</Card.Title>
					<Card.Subtitle className="mb-2 text-muted">
						<div className={styles.companyName}>
							<InternalLink content={company?.href}>
								{company?.accessor()}
							</InternalLink>
						</div>
						<div className={styles.createdBy}>
							<InternalLink content={createdBy?.href}>
								{createdBy?.accessor()}
							</InternalLink>
						</div>
					</Card.Subtitle>
					<Card.Text className={styles.cardText}>
						{
							customDisplayColumns.map((column) => (
								<Row key={column.key} className={column.key} href={column.href ?? ""}>
									<Col>
										{column.title}
										: &nbsp;
										{column.accessor()}
									</Col>
								</Row>
							))}

					</Card.Text>
				</Card.Body>
			</Card>
		</Col>
	);
};

export default QuoteCard;
