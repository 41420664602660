import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import {
	Container, Row, Col, Card, Table
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useSearchParams } from 'react-router-dom';

import Button from '../Parts/Button';

import setTitle from '../setTitle';
import { useDealerById, useDistributorById, useRepresentativeById } from '../Store/entities/hooks';
import { DistributionEmail } from '../powershadesApiTypes';
import { UsePortalDispatch } from '../Store';
import { updateDealerFull } from '../Store/entities/organizations/dealers';
import { updateDistributorFull } from '../Store/entities/organizations/distributors';
import { updateRepresentativeFull } from '../Store/entities/organizations/representatives';

const EntityEmails = (props) => {
	const {
		loaded
	} = props;

	const [searchParms,] = useSearchParams();

	const [currentEditableEmailAddress, setCurrentEditableEmailAddress] = useState('');
	const [error, setError] = useState("");

	const entityType = searchParms.get("entityType");
	const entityId = searchParms.get("entityId");

	const [workingEmails, setWorkingEmails] = useState<DistributionEmail[]>([]);

	const dealer = useDealerById(Number(entityId));
	const distributor = useDistributorById(Number(entityId));
	const representative = useRepresentativeById(Number(entityId));

	const entity = (entityType === 'Dealers' ? dealer : entityType === 'Distributors' ? distributor : representative) ?? { name: "", distribution_emails: ([] as DistributionEmail[]) };

	const dispatch = UsePortalDispatch();

	const {
		name,
		distribution_emails: emails
	} = entity;

	const saveGoods = () => {
		const newEmails = workingEmails;

		if (entityType === 'Dealers') {
			if (dealer) {
				dispatch(updateDealerFull({ ...dealer, distribution_emails: newEmails }));
			}
		} else if (entityType === 'Distributors') {
			if (distributor) {
				dispatch(updateDistributorFull({ ...distributor, distribution_emails: newEmails }));
			}
		} else if (entityType === 'Representatives') {
			if (representative) {
				dispatch(updateRepresentativeFull({ ...representative, distribution_emails: newEmails }));
			}
		}
	};

	const setEmails = (newEmails: DistributionEmail[]) => {
		setWorkingEmails(newEmails);
	};

	useEffect(() => {
		setWorkingEmails(entity.distribution_emails);
	}, [entity]);

	const statusChanged = (emailAddressNew: string, status: boolean) => {
		const email = workingEmails.find((em) => em.email === emailAddressNew);

		if (email) {
			const newEmail = { ...email, is_receiving_order_placed_emails: status };

			const newEmails = workingEmails.map((em) => (em.email !== emailAddressNew ? em : newEmail));

			setEmails(newEmails);
		}
	};

	const invoiceStatusChanged = (emailAddressNew: string, status: boolean) => {
		const email = workingEmails.find((em) => em.email === emailAddressNew);

		if (email) {
			const newEmail = { ...email, is_receiving_invoice_emails: status };

			const newEmails = workingEmails.map((em) => (em.email !== emailAddressNew ? em : newEmail));

			setEmails(newEmails);
		}
	};

	const deleteEmail = (emailAddressNew: string) => {
		const emailItem = emails.find((e) => e.email === emailAddressNew);

		if (emailItem) {
			const index = emails.indexOf(emailItem);

			emails.splice(index, 1);

			setEmails([...emails]);
		}
	};

	useEffect(() => {
		setTitle('Email Distribution');
	}, []);

	useEffect(() => {
		setTitle('Email Distribution');
	}, [entityType, entityId]);

	useEffect(() => {
		if (entity && emails) {
			loaded();
		}
	}, [entity, emails, loaded]);

	const rows = (workingEmails ?? []).map((e) => {
		const emailAddress = e.email;

		return (
			<tr>
				<td>{emailAddress}</td>
				<td>
					<input
						checked={e.is_receiving_order_placed_emails}
						onChange={(ev) => statusChanged(emailAddress, ev.target.checked)}
						type="checkbox"
					/>
				</td>
				<td>
					<input
						checked={e.is_receiving_invoice_emails}
						onChange={(ev) => invoiceStatusChanged(emailAddress, ev.target.checked)}
						type="checkbox"
					/>
				</td>
				<td>
					<Button
						color="light"
						size="sm"
						onClick={() => deleteEmail(emailAddress)}
					>
						<FontAwesomeIcon icon={faTrashAlt} />
					</Button>
				</td>
			</tr>
		)
	});

	const disableSaveButton = JSON.stringify(emails) === JSON.stringify(workingEmails);

	function ValidateEmail(mail) {
		if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
			return true;
		}
		return false;
	}

	const close = () => {
		setCurrentEditableEmailAddress('');
		setError("");
	};

	return workingEmails && entity ? (
		<Container className="local-bootstrap">
			<Card className="m-4">
				<Card.Body>
					<Row>
						<Col>
							<h4 className="green-text">
								Email Distributution for
								{' '}
								{name}
							</h4>
						</Col>
					</Row>
					<div className="overflow-x-wrapper">
						<Table striped bordered hover>
							<thead>
								<th>Email Address</th>
								<th>Notifications?</th>
								<th>Invoices?</th>
								<th>Delete</th>
							</thead>
							<tbody>{rows}</tbody>
						</Table>
					</div>
					<Row>
						<Col>
							<Popup
								className="email mobile-modal"
								trigger={(
									<Button color="green">
										<FontAwesomeIcon icon={faPlus} />
										{' '}
										Add New Email
									</Button>
								)}
								open={currentEditableEmailAddress !== ''}
								closeOnDocumentClick={false}
								onClose={() => {
									setCurrentEditableEmailAddress('');
									setError("");
								}}
								modal
							>
								<div className="local-bootstrap">
									<Container>
										<Row>
											<Col>
												<h4 className="text-green">
													Input Email Address
												</h4>
											</Col>
										</Row>
										<Row>
											<Col>
												<input
													className="form-control"
													value={currentEditableEmailAddress}
													onChange={(e) =>
														setCurrentEditableEmailAddress(e.target.value)}
												/>
											</Col>
										</Row>
										<Row
											className={
												error ? `d-inline text-danger` : `d-none`
											}
										>
											<Col>
												<FontAwesomeIcon
													className="me-2"
													icon={faExclamationTriangle}
												/>
												<span>{error}</span>
											</Col>
										</Row>
										<Row>
											<Col>
												<Button
													color="gray"
													onClick={close}
												>
													Close
												</Button>
											</Col>
											<Col className="text-end">
												<Button
													color="green"
													onClick={() => {
														if (
															emails
																.map((e) =>
																	e.email.trim())
																.includes(
																	currentEditableEmailAddress.trim()
																)
														) {
															setError(
																'This email address already exists.'
															);
														} else if (
															!ValidateEmail(currentEditableEmailAddress)
														) {
															setError(
																'Please enter a valid email address.'
															);
														} else {
															setEmails([
																...emails,
																{
																	email:
																		currentEditableEmailAddress,
																	is_receiving_order_placed_emails: false,
																	is_receiving_invoice_emails: false,
																},
															]);
															setCurrentEditableEmailAddress('');
															setError("");
															close();
														}
													}}
												>
													Add
												</Button>
											</Col>
										</Row>
									</Container>
								</div>
							</Popup>
						</Col>
						<Col className="text-end">
							<Button
								color="green"
								disabled={disableSaveButton}
								onClick={() => {
									saveGoods();
								}}
							>
								Save
							</Button>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Container>
	) : (
		<div> Loading</div>
	);
};

export default EntityEmails;
