import { Router } from '@remix-run/router';
import extractJwtData from "./extractJwtData";
import { AppDispatch, AppState } from "./Store";
import { logout } from "./Store/upperLevelReducers";
import {
	attemptRefresh, loadUser, loadUserData, UserDataJwt, UserDataRoles, userNotLoading
} from "./Store/user";
import userService from "./userService";
import  { EnsureConnectionMade } from './Store/signalR/connection';

export default class UserTimer {
	dispatch: AppDispatch;

	timeOut: NodeJS.Timeout | null;

	// How often the timer runs in milliseconds.
	period: number;

	active: boolean;

	getState: () => AppState;

	router: Router;

	constructor(dispatch: AppDispatch, getState: () => AppState, router: Router) {
		this.dispatch = dispatch;
		this.timeOut = null;
		this.period = 5000;
		this.active = false;
		this.getState = getState;
		this.router = router;
	}

	overrideDispatch(dispatch: AppDispatch) {
		this.dispatch = dispatch;
	}

	start() {
		const checkIt = () => {
			if (this.active) return;

			this.active = true;

			try {
				const userObjstr = localStorage.getItem("user_data");

				if (userObjstr) {
					// Check user obj
					const userObj: UserDataJwt = JSON.parse(userObjstr);

					const { user } = this.getState();
					// If not authenticated  
					// If bad, logout.
					// If good, extract jwt.
					// If jwt bad, or expired, Check Refresh
					// If refresh good, try refresh for new jwt
					// If jwt good login
					// if jwt bad logout
					// if Refresh bad logout
					// if jwt good login.
					// If authenticated
					// check if jwt will expire soon
					// If yes, get a new one
					// if no, continue

					if (!user.authenticated) {
						const jwtObj = extractJwtData(userObj.jwt);

						const now: number = Date.now();
						const expiration = (jwtObj?.exp ?? 0) * 1000;

						// Expires within 20 or is already expired
						if (now > expiration - 20000) {
							if (userObj.refreshJwt) {
								this.dispatch(attemptRefresh({
									refreshJwt: userObj.refreshJwt
								})).then((resp) => {
									// console.log(resp);
									// console.log("Hey1");

									if (resp.payload === false) {
										this.dispatch(logout());
									} else {
										this.router.navigate("/Home");
									}
								}).catch(() => this.dispatch(logout()));
							}
						} else {
							if (!jwtObj) {
								this.dispatch((logout()));
								return;
							}
							this.dispatch(loadUser({
								id: jwtObj.id,
								email: jwtObj.email,
								name: jwtObj.name,
								jwt: userObj.jwt,
								refreshJwt: userObj.refreshJwt
							}));

							const transformedUserData: UserDataRoles = {
								id: jwtObj.id,
								name: jwtObj.name,
								email: jwtObj.email,
								roles: userObj?.user_data?.roles.map((r) => ({
									entity_name: r.entity_name,
									entity_id: r.entity_id,
									role_name: r.role_name
								})) ?? [],
								is_sales_person: userObj?.user_data?.is_sales_person ?? false,
								is_disallowed_force_edit: userObj?.user_data?.is_disallowed_force_edit ?? false,
								is_engineering: userObj?.user_data?.is_engineering ?? false,
								is_service: userObj?.user_data?.is_service ?? false,
								is_manufacturing: userObj?.user_data?.is_manufacturing ?? false,
								is_using_old_homepage: userObj?.user_data?.is_using_old_homepage ?? false,
								is_allowed_parts_on_cedia: userObj?.user_data?.is_allowed_parts_on_cedia ?? false,
								theme: userObj?.user_data?.theme ?? "light"
							};

							this.dispatch(loadUserData({
								user_data: transformedUserData,
								email: jwtObj.email,
								name: jwtObj.name,
								jwt: userObj.jwt,
								refreshJwt: userObj.refreshJwt,
								id: jwtObj.id
							}));

							userService.ensureUserData();

							const routerState = this.router.state;

							if (routerState.location.pathname.toLocaleLowerCase().includes("login")) {
								this.router.navigate("/Home");
							}

							// console.log(routerState);
							// Login
						}
					} else {
						const jwtObj = extractJwtData(userObj.jwt);
						
						// Check if the jwt is expired (and still present) or if the jwt is not present and the refresh is present.
						const jwtNeedsRefreshed = (jwtObj && (jwtObj.exp - 20) * 1000 < Date.now()) || (!jwtObj && userObj.refreshJwt);

						if (jwtNeedsRefreshed) {
							this.dispatch(attemptRefresh({
								refreshJwt: userObj.refreshJwt
							})).then((resp) => {
								if (resp.payload === false) {
									this.dispatch(logout());
								} else {
									this.router.navigate("/Home");
								}
							}).catch(() => this.dispatch(logout()));
						} else if (!jwtObj) {
							this.dispatch(logout());
							return;
						}

						userService.ensureUserData();
						EnsureConnectionMade()
					}
				} else {
					const goods = userNotLoading();

					this.dispatch(goods);

					const noLoginRedirectRoutes = ['login', 'dealersignup', 'confirmpasswordreset', 'resetpassword'];

					const RouterState = this.router.state;

					const isInNeedOfReRerouting = !noLoginRedirectRoutes
						.some((noRoute) => RouterState.location.pathname.toLocaleLowerCase().includes(noRoute));

					if (isInNeedOfReRerouting) {
						this.router.navigate("/Login");
					}
				}
			} catch (exception) {
				// console.log(exception);
				if (typeof exception === "string") {
					throw new Error(exception);
				}

				throw exception;
			}

			setTimeout(() => {
				this.active = false;
			}, 5000);
		};

		this.timeOut = setInterval(checkIt, this.period);
	}

	stop() {
		if (this.timeOut !== null) {
			clearTimeout(this.timeOut);
		}
	}
}
