/* eslint-disable no-param-reassign */
import React from 'react';
import { redirect, RouteObject } from 'react-router-dom';
import PageNotFound from './404/Content';
import AdminEmails from './AdminEmails/Content';
import AdminPanel from './AdminPanel/Content';
import ChangeLog from './ChangeLog/Content';
import ConfirmPasswordReset from './ConfirmPasswordReset/Content';
import Contractors from './Contractors/Content';
import CreateNotifications from './CreateNotifications/Content';
import CreatePassword from './CreatePassword/Content';
import DealerSignup from './Dealer/DealerSignup';
import DealerZohoUpsert from './DealerZohoUpsert/Content';
import EngineeringScreen from './EngineeringScreen/Content';
import EntityEmails from './EntityEmails/Content';
import { fakeRootType } from "./FakeRootType";
import Home from './Home/Content';
import Login from './Login/Content';
import ManageCoupons from './ManageCoupons/Content';
import ManageQuoteTags from './ManageQuoteTags/Content';
import Manufacturing from './Manufacturing/Content';
// import MfgHome from './MfgHome/Content';
import Notifications from './Notifications/Content';
import PaymentMethods from './PaymentMethods/Content';
import Quote from './Quote/Content';
import QuoteEvents from './QuoteEvents/Content';
import QuoteReviews from './QuoteReviews/Content';
import Reports from './Reports/Content';
import RequestedConfigurations from './RequestedConfigurations/Content';
import ResetPassword from './ResetPassword/Content';
import { AppState } from './Store';
import Dealers from './Dealer/DealerView';
import DealerList from './Dealer/DealerList';
import TopView from './MacroReports/TopView';
// import { Route } from "react-router-dom";
import DistributorList from './Distributor/DistributorList';
import DistributorView from './Distributor/DistributorView';
import RepresentativeView from './Representatives/RepresentativeView';
import RepresentativeList from './Representatives/RepresentativeList';
import DistributorLocationList from './DistributorLocation/DistributorLocationList';
import DistributorLocationView from './DistributorLocation/DistributorLocationView';
import UserList from './User/UserList';
import UserView from './User/UserView';
import AddDealer from './Dealer/AddDealer';
import TerritoryList from './Territory/TerritoryList';
import TerritoryView from './Territory/TerritoryView';
import AddUser from './User/AddUser';
import RMATypes from './RMATypes/Content';
import ShadeCardTesting from './ShadeCardTesting/Content';
import QuoteIconTesting from './QuoteIconTesting/Content';
import AccessoryView from './Accessories/AccessoryView';
import NewQuote from './NewQuote/Content';
import QuoteDetails from './NewQuote/Details';
import ZUpdateTesting from './ZUpdateTesting/ZUpdateTesting';
import UnderConstruction from './UnderConstruction/Content';

/* eslint-disable arrow-body-style, react/jsx-props-no-spreading */

const PortalRoutes: (fakeRoot: fakeRootType, getState: () => AppState) => RouteObject[] = (
	fakeRoot,
	getState
) => {
	// Roots
	const goods: RouteObject[] = [
		{
			path: '/',
			element: <Home ROOT={fakeRoot} />,
			loader: () => {
				const { authenticated } = getState().user;

				return authenticated ? redirect('/Home') : redirect('/login');
			},
			errorElement: <PageNotFound ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/login',
			element: <Login ROOT={fakeRoot} />,
		},
		{
			path: '/dealersignup',
			element: <DealerSignup ROOT={fakeRoot} />,
		},

		{
			path: '/resetpassword',
			element: <ResetPassword ROOT={fakeRoot} />,
		},
		{
			path: '/home',
			element: <Home ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/Home',
			element: <Home ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/quote',
			element: <Quote ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/accessories',
			element: <AccessoryView />,
		},

		{
			path: '/adminemails',
			element: <AdminEmails ROOT={fakeRoot} />,
		},
		{
			path: '/adminpanel',
			element: <AdminPanel ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/changelog',
			element: <ChangeLog ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/confirmpasswordreset',
			element: <ConfirmPasswordReset ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/contractors',
			element: <Contractors ROOT={fakeRoot} />,
		},
		{
			path: '/createnotifications',
			element: <CreateNotifications ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/createpassword',
			element: <CreatePassword ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/Dealer',
			element: <Dealers ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/Dealer/Add',
			element: <AddDealer ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/User/Add',
			element: <AddUser />,
		},
		{
			path: '/Dealers',
			element: <DealerList ROOT={fakeRoot} />,
		},
		
		{
			path: '/dealersignup',
			element: <DealerSignup ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/dealerzohoupsert',
			element: <DealerZohoUpsert setTitle={fakeRoot.setTitle} />,
		},
		{
			path: '/Distributor',
			element: <DistributorView ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/Distributors',
			element: <DistributorList ROOT={fakeRoot} />,
		},
		{
			path: '/DistributorLocation',
			element: <DistributorLocationView ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/DistributorLocations',
			element: <DistributorLocationList ROOT={fakeRoot} />,
		},
		{
			path: '/emaillogs',
			element: <div> Broke</div>,
		},
		{
			path: '/engineeringscreen',
			element: <EngineeringScreen ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/entityemails',
			element: <EntityEmails ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/managecoupons',
			element: <ManageCoupons ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/ManageEntities',
			element: <div>broke</div>,
			loader: (inp) => {
				const { url } = inp.request;

				const queryString = url.split("?")[1];

				const urlParams = new URLSearchParams(queryString);

				const entityType = urlParams.get("group");
				const entityId = urlParams.get("entity_id");

				let newUrl = `/${entityType}`;

				if (entityId !== null && entityId !== '0') {
					newUrl = `/${entityType}?entity_id=${entityId}`;
				}

				let first = true;

				urlParams.forEach((value, key) => {
					if (key === 'group' || key === 'entity_id') return;
					if (!newUrl.includes('?') && first) {
						first = false;
						newUrl += `?${key}=${value}`;
					} else {
						newUrl += `&${key}=${value}`;
					}
				});

				return redirect(newUrl);
			},
		},

		{
			path: '/ManageQuoteTags',
			element: <ManageQuoteTags ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/manufacturing',
			element: <Manufacturing ROOT={fakeRoot} {...fakeRoot} />,
		},
		// {
		// 	path: '/mfghome',
		// 	element: <MfgHome ROOT={fakeRoot} {...fakeRoot} />,
		// },
		{
			path: '/notifications',
			element: <Notifications ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/paymentmethods',
			element: <PaymentMethods ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/quoteevents',
			element: <QuoteEvents {...fakeRoot} />,
		},
		{
			path: '/quotereviews',
			element: <QuoteReviews ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/Representative',
			element: <RepresentativeView ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/Representatives',
			element: <RepresentativeList ROOT={fakeRoot} />,
		},
		{
			path: '/RMATypes',
			element: <RMATypes />,
		},
		{
			path: '/UnderContstruction',
			element: <UnderConstruction/>,
		},
		{
			path: '/Users',
			element: <UserList ROOT={fakeRoot} />,
		},
		{
			path: 'User',
			element: <UserView ROOT={fakeRoot} />,
		},
		{
			path: '/ZUpdateTesting',
			element: <ZUpdateTesting ROOT={fakeRoot} />,
		},
		{
			path: '/reports',
			element: <Reports ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/requestedconfigurations',
			element: <RequestedConfigurations ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/resetpassword',
			element: <ResetPassword ROOT={fakeRoot} {...fakeRoot} />,
		},
		{
			path: '/toplevelview',
			element: <TopView />,
		},
		{
			path: '/Territories',
			element: <TerritoryList ROOT={fakeRoot} />,
		},
		{
			path: '/Territory',
			element: <TerritoryView ROOT={fakeRoot} />,
		},
		{
			path: '/shadecardtesting',
			element: <ShadeCardTesting ROOT={fakeRoot} />,
		},
		{
			path: '/QuoteIconTesting',
			element: <QuoteIconTesting ROOT={fakeRoot} />,
		},
		{
			path: '/NewQuote',
			element: <NewQuote ROOT={fakeRoot} />,
			children: [
				{
					path: '/NewQuote/Details',
					element: <QuoteDetails />,
					index: true,
				}
			]

		},
	];

	const noLoginRedirectRoutes = ['/login', '/dealersignup', '/confirmpasswordreset', '/resetpassword'];

	/*
	* This function is used to check if the user is logged in. 
	* It by filtering through the list of routes (above) and comparing the route.path to the paths in 
	* noLoginRedirectRoutes array. If the route.path is not in the noLoginRedirectRoutes array, then
	* the loader is appended to that route. 
	* The loader handles whether or not to redirect the user to login, or return null to allow the
	* user to access the route.
	*/
	
	goods
		.filter((route) => !noLoginRedirectRoutes.some((url) => url?.toLocaleLowerCase().includes(route?.path?.toLocaleLowerCase() ?? "")))
		.forEach((route) => {
			const loaderOrig = route.loader ?? (() => { return null; });

			route.loader = (inp) => {
				const { user } = getState();
				const { authenticated, loading } = user;
				if (authenticated && !loading) {
					return loaderOrig(inp);
				}

				if (!authenticated && !loading) {
					return redirect('/login');
				}
				return loaderOrig(inp);
			};
		});

	goods.forEach(((route) => (route.errorElement = <PageNotFound ROOT={fakeRoot} {...fakeRoot} />)));

	return goods;
};

export default PortalRoutes;
