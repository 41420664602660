function fabricSampleRow({
	worksheet, workbook, row, fabric_sample
}) {
	let col = 1;

	function cell(string) {
		const cell = worksheet.cell(row, col++)
			.string(`${string}`)
			.style({
				fill: {
					type: 'pattern',
					patternType: 'solid',
					fgColor: '#f0f0f0',
				},
				border: {
					left: {
						style: 'thin',
						color: 'black'
					},
					right: {
						style: 'thin',
						color: 'black'
					},
					top: {
						style: 'thin',
						color: 'black'
					},
					bottom: {
						style: 'thin',
						color: 'black'
					},
					diagonal: {
						style: 'thin',
						color: 'black'
					},
					diagonalDown: true,
					outline: true
				}
			});
        
		return cell;
	}

	cell(fabric_sample.name);
	cell(fabric_sample.quantity);
}

function fabricSamplesHeaderRow({
	workbook, worksheet, row
}) {
	 const HEAD_ROW_STYLE = workbook.createStyle({
		alignment: {
			horizontal: 'center',
		},
		fill: {
			type: 'pattern',
			patternType: 'solid',
			fgColor: '#d5d5d5',
		},
		border: {
			left: {
				style: 'thin',
				color: 'black'
			},
			right: {
				style: 'thin',
				color: 'black'
			},
			top: {
				style: 'thin',
				color: 'black'
			},
			bottom: {
				style: 'thin',
				color: 'black'
			},
			diagonal: {
				style: 'thin',
				color: 'black'
			},
			diagonalDown: true,
			outline: true
		}
	});

	let col = 1;

	function cell(string) {
		worksheet.cell(row, col++)
			.string(string)
			.style(HEAD_ROW_STYLE);
	}

	cell`Fabric Name`;
	cell`Qty`;
}

export default function FabricSamplesSection({
	workbook, worksheet, row, quote, quote_data, userMultiplier
}) {
	const HEADER_STYLE = workbook.createStyle({

		font: {
			size: 16,
			bold: true,
		}
	});

	worksheet
		.cell(row++, 1)
      	.string('Fabric Samples')
      	.style(HEADER_STYLE);

	fabricSamplesHeaderRow({
		worksheet, workbook, row: row++
	});

	quote_data.fabric_samples?.forEach((fabric_sample) => fabricSampleRow({
		worksheet, workbook, row: row++, fabric_sample
	}));

	return row;
}
