import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowFrame, faBlindsOpen } from '@fortawesome/pro-regular-svg-icons';

import type { WindowCoveringIconProps } from './types';

const WindowCoveringIcon = ({
	size = '1x',
	bottomColorOverride = '',
	topColorOverride = '',
	className = '',
}: WindowCoveringIconProps) => (
	<span className={`fa-stack ${className}`}>
		<FontAwesomeIcon
			icon={faWindowFrame}
			size={size}
			className={`fa-stack-2x ${bottomColorOverride && `text-${bottomColorOverride}`}`}
		/>
		<FontAwesomeIcon
			icon={faBlindsOpen}
			size={size}
			className={`fa-stack-2x stacked-logo text-${topColorOverride}`}
		/>
	</span>
);

export default WindowCoveringIcon;
