import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

import { useStateList, useStatesLoadStatus } from '../Store/entities/hooks';

import type { StateSelectorTypedProps } from './types';
import { AddressStateStore } from '../Store/entities/addressStates';

const StateSelectorTyped = ({
	currentStateCode,
	onChange,
	disabled = false,
	isValid = true
}: StateSelectorTypedProps) => {
	const statesLoadingStatus = useStatesLoadStatus();
	const states = useStateList();

	const loading = statesLoadingStatus <= 3;

	return (
		<div>
			<label>State</label>
			<Typeahead
				id="state-select"
				clearButton
				paginate
				flip
				placeholder={loading ? "Loading States..." : "Select State..."}
				options={states}
				disabled={disabled}
				isLoading={loading}
				labelKey="name"
				selected={states.filter((state) => state.code === currentStateCode)}
				onChange={(selection) => {
					const selectedState = selection[0];
					if (!selectedState) {
						onChange('');
						return;
					}
					const stateStore = selectedState as AddressStateStore;
					const selectedStateCode = stateStore.code;
					const newState = states.find((state) => state.code === selectedStateCode);
					onChange(newState?.code ?? '');
				}}
				inputProps={{ autoComplete: "new-password" }} // Added to prevent chrome from autofilling the input
				style={!isValid ? { border: "1px solid #d68f83", borderRadius: "6px" } : {}}
			/>
		</div>
	);
};

export default StateSelectorTyped;
