// This index file is for coallessing the organizations. This includes Dealers,
// Distributors, Representatives, Contractors, and Employees. This list may
// increase.

import { createAsyncThunk } from "@reduxjs/toolkit";
import type { AxiosError } from "axios";
import type { AppState } from "../..";

import { extraEntityReducersFunction, loadStatus } from "../entitiesType";
import { dealerBuilder } from "./dealers";
import { distributorBuilder } from "./distributors";
import { representativeBuilder } from "./representatives";

import type { BuyingGroupMeta, Channel, loadStatusType } from "../../../powershadesApiTypes";

import apiCalls from "../../../PowerShadesAPIFunctions";
import { distributorLocationBuilder } from "./distributorLocations";
import { userBuilder } from "../users";
import { manufacturerBuilder } from "./manufacturers";
import { ThunkAPI } from "../types";
import { selectIsPsAdmin } from "../../user/userPermissions";

export type OrganizationStore = {
    /* Loading enum */
    loadStatus: loadStatusType;

    /* Basic Meta Data */
    id: number;
    name : string;
    email : string;
	phone: string;
}

export const loadChannels = createAsyncThunk<Channel[], void, ThunkAPI>(
	'organizations/loadChannels',
	async (_emptyInput, thunkAPI) => {
		let channels: Channel[] = [];
		const { getState, rejectWithValue } = thunkAPI;
		const store = getState() as AppState;
		const userIsPsAdmin = selectIsPsAdmin(store);

		try {
			if (!userIsPsAdmin) return rejectWithValue(null);
			const response = await apiCalls.getChannels();
			channels = response.data.channels;
		} catch (err: any) {
			const error: AxiosError = err;
			console.error(error);

			return rejectWithValue(null);
		}

		return channels;
	}
);

export const loadBuyingGroups = createAsyncThunk<BuyingGroupMeta[], void, ThunkAPI>(
	'organizations/loadBuyingGroups',
	async (_emptyInput, thunkAPI) => {
		let buyingGroups: BuyingGroupMeta[] = [];
		const { getState, rejectWithValue } = thunkAPI;
		const store = getState() as AppState;
		const userIsPsAdmin = selectIsPsAdmin(store);
		try {
			if (!userIsPsAdmin) return rejectWithValue(null);
			const response = await apiCalls.getBuyingGroups();
			buyingGroups = response.data.buying_groups;
		} catch (err: any) {
			const error: AxiosError = err;
			console.error(error);

			return rejectWithValue(null);
		}

		return buyingGroups;
	}
);
		
const organizationBuilder: extraEntityReducersFunction = (builder) => {
	// Dealer Thunks
	dealerBuilder(builder);

	// Distributor Thunks
	distributorBuilder(builder);

	// Representative Thunks
	representativeBuilder(builder);

	// Distributor Lccation Thunks
	distributorLocationBuilder(builder);
	
	// User Thunks
	userBuilder(builder);

	// Manufacturer Thunks
	manufacturerBuilder(builder);

	builder.addCase(loadChannels.pending, (entities) => {
		entities.channels.loadStatus = loadStatus.loading;
	});

	builder.addCase(loadChannels.fulfilled, (entities, action) => {
		const channels = action.payload;
		entities.channels.list = channels;
		entities.channels.loadStatus = loadStatus.fullyLoaded;
	});

	builder.addCase(loadBuyingGroups.pending, (entities) => {
		entities.buyingGroups.loadStatus = loadStatus.loading;
	});

	builder.addCase(loadBuyingGroups.fulfilled, (entities, action) => {
		const buyingGroups = action.payload;
		entities.buyingGroups.list = buyingGroups;
		entities.buyingGroups.loadStatus = loadStatus.fullyLoaded;
	});
};

export const selectChannels = (state: AppState) => state.entity.channels;
export const selectBuyingGroups = (state: AppState) => state.entity.buyingGroups;
export const selectTermsOptions = (state: AppState) => state.entity.termsOptions;

export { organizationBuilder };
