import React, { useEffect } from 'react';
import * as DOMPurify from 'dompurify';
import { Container, Row, Col, Card } from 'react-bootstrap';
import * as showdown from 'showdown';

import changelog from './changelog.md';
import type { fakeRootType } from "../FakeRootType";


type ChangeLogProps = {
	ROOT: fakeRootType;
}
const ChangeLog = ({ ROOT }: ChangeLogProps): JSX.Element => {
	const converter = new showdown.Converter();
	const dirtyHTML = converter.makeHtml(changelog);
	// ! DO NOT IN ANY CIRCUMSTANCE REMOVE THE PURIFICATION OF THIS HTML 
	// ! https://github.com/cure53/DOMPurify
	const purifiedChangelog = DOMPurify.sanitize(dirtyHTML);
	useEffect(() => {
		ROOT.setTitle('Change Log');
		ROOT.loaded();
	}, []);
	/* eslint-disable react/no-danger */
	return (
		<Container className="local-bootstrap">
			<Row>
				<Col>
					<Card body className="ms-4 me-4">
						<div dangerouslySetInnerHTML={{ __html: purifiedChangelog }} />
					</Card>
				</Col>
			</Row>
		</Container>
	);
};
 
export default ChangeLog;
