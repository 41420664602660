import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

type SpinningWheelProps = {
	size?: SizeProp;
	style?: React.CSSProperties;
	className?: string;
};

const SpinningWheel = ({
	size = '1x', style = {}, className = ''
}: SpinningWheelProps) => (
	<FontAwesomeIcon
		icon={faSpinner}
		className={`spinning-wheel ${className} ${size ? '' : 'spinning-wheel-default'}`}
		size={size}
		style={style}
	/>
);

export default SpinningWheel;
