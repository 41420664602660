import { loadMotors, loadTubes } from ".";
import store, { AppState, UsePortalSelector } from "../..";
import { loadStatus } from "../entitiesType";


const selectMotors = (state: AppState) => {
	if(state.entity.parts.motors.loadStatus === loadStatus.needsLoaded
		|| state.entity.parts.motors.loadStatus === loadStatus.notLoaded){
		store.dispatch(loadMotors());
	}
	
	return state.entity.parts.motors.list;
};

const useMotors = () => UsePortalSelector(selectMotors);

const selectTubes = (state: AppState) => {
	if(state.entity.parts.tubes.loadStatus === loadStatus.needsLoaded
		|| state.entity.parts.tubes.loadStatus === loadStatus.notLoaded){
		store.dispatch(loadTubes());
	}
	
	return state.entity.parts.tubes.list;
}

const useTubes = () => UsePortalSelector(selectTubes);

export {
	useMotors,
	useTubes,
	selectMotors,
	selectTubes
}

