/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @stylistic/max-len */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import IsOutdoorShadeSelector from '../../../Quote/ShadeForm/IsOutdoorShadeSelector';

import Button from '../../../Parts/Button';
import Money from '../../../Parts/Money';
import { useNewPSOutdoorShadeOptions } from '../../../Store/entities/assemblies/PSOutdoorHooks';
import { addAssembly, InitialAssemblyState, InitialShadeState } from '../../../Store/entities/assemblies';
import { useQuoteById, useQuoteTypeNameByQuoteId } from '../../../Store/entities/quotes/hooks';
import { AssemblyStore, AssemblyViewModelFromAssemblyStore } from '../../../Store/entities/assemblies/types';
import { useNewIndoorShadeOptions } from '../../../Store/entities/assemblies/IndoorHooks.ts';
import apiCalls from '../../../PowerShadesAPIFunctions';
import { UsePortalDispatch, UsePortalSelector } from '../../../Store';
import { useIndoorFabrics, usePSOutdoorFabrics } from '../../../Store/entities/hooks';
import { useUser } from '../../../Store/user/hooks';
import { useAssemblyOptions } from '../../../Store/entities/assemblyOptions/hooks';
import EditIndoorShadeModalInner from '../EditShade/EditIndoorShade/EditIndoorShadeInner';
import EditPSOutdoorShadeModalInner from '../EditShade/EditPSOutdoorShade/EditPSOutdoorShadeInner';

// import { FabricViewModel, loadStatusType} from "../../../powershadesApiTypes";

type NewQuoteItemFormProps = {
	quoteId: number;
	onCancel: () => void;
};

type AddOptions = '' | 'psoutdoor' | 'indoor';

const NewQuoteItemForm = ({
	quoteId,
	onCancel
}: NewQuoteItemFormProps) => {
	const [currentShadeType, setCurrentShadeType] = useState<AddOptions>('');

	const [currentAssembly, setCurrentAssembly] = useState<AssemblyStore>({ ...InitialAssemblyState(), shades: [InitialShadeState()] });

	const indoorFabrics = useIndoorFabrics(quoteId);

    //console.log('🔹🔹🔹🔹🔹🔹 NewQuoteItem', quoteId);
	const psOutdoorFabrics = usePSOutdoorFabrics(quoteId);

	// const [allowedIndoorFabrics, setAllowedIndoorFabrics] = useState<FabricViewModel[]>([]);
	// const [allowedPsOutdoorFabrics, setAllowedPsOutdoorFabrics] = useState<FabricViewModel[]>([]);

	// Fetch allowed indoor fabrics from the backend
	// useEffect(() => {
	//    const fetchAllowedIndoorFabrics = async () => {
	//        try {
	//            const response = await apiCalls.getFabricIndoorAllowedList(quoteId);
	//            setAllowedIndoorFabrics(response.data.fabrics); // Store the fetched fabrics in state
	//        } catch (error) {
	//            console.error("Error fetching allowed indoor fabrics:", error);
	//        }
	//    };

	//    fetchAllowedIndoorFabrics();
	// }, [quoteId]);

	// Combine fetched allowed fabrics with the indoor fabrics
	// Mapping to FabricStore when is FabricViewModel
	// const combinedIndoorFabrics = [
	//	...indoorFabrics,
	//	...allowedIndoorFabrics.map(fabric => ({
	//		name: fabric.name,
	//		imageUrl: fabric.image_url || "",
	//		imageLoaded: 1 as loadStatusType,
	//		isNextLoad: false,
	//		fabric_color: "",
	//		fabric_pattern: "",
	//		is_indoor: fabric.is_indoor,
	//		is_outdoor: fabric.is_outdoor,
	//		is_psoutdoor: fabric.is_psoutdoor,
	//		is_railroadable: fabric.is_railroadable,
	//		is_seamable: fabric.is_seamable,
	//		is_discontinued: fabric.is_discontinued,
	//		is_gone: fabric.is_gone
	//	}))
	// ];
	
	// // Fetch allowed indoor fabrics from the backend
	// useEffect(() => {
	//     const fetchAllowedPsOutdoorFabrics = async () => {
	//         try {
	//             const response = await apiCalls.getFabricPsOutdoorAllowedList(quoteId);
	//             setAllowedPsOutdoorFabrics(response.data.fabrics); // Store the fetched fabrics in state
	//         } catch (error) {
	//             console.error("Error fetching allowed outdoor fabrics:", error);
	//         }
	//     };

	//     fetchAllowedPsOutdoorFabrics();
	// }, [quoteId]);

	// // Combine fetched allowed fabrics with the indoor fabrics
	// // Mapping to FabricStore when is FabricViewModel
	// const combinedPsOutdoorFabrics = [
	// 	...psOutdoorFabrics,
	// 	...allowedPsOutdoorFabrics.map(fabric => ({
	// 		name: fabric.name,
	// 		imageUrl: fabric.image_url || "",
	// 		imageLoaded: 1 as loadStatusType,
	// 		isNextLoad: false,
	// 		fabric_color: "",
	// 		fabric_pattern: "",
	// 		is_indoor: fabric.is_indoor,
	// 		is_outdoor: fabric.is_outdoor,
	// 		is_psoutdoor: fabric.is_psoutdoor,
	// 		is_railroadable: fabric.is_railroadable,
	// 		is_seamable: fabric.is_seamable,
	// 		is_discontinued: fabric.is_discontinued,
	// 		is_gone: fabric.is_gone
	// 	}))
	// ];

	// // Gio Test Add
	// useEffect(() => {
	// 	//console.log("***** FabricsIndoorAllowed:", allowedIndoorFabrics)
	// 	console.log("***** FabricsOutdoorAllowed:", allowedPsOutdoorFabrics)
	// 	console.log("***** Indoor Fabrics:", indoorFabrics);
	// 	//console.log("***** CombinedIndoorFabrics:", combinedIndoorFabrics);
	// 	console.log("***** CombinedOutdoorFabrics:", combinedPsOutdoorFabrics);
	// 	console.log("***** PS Outdoor Fabrics:", psOutdoorFabrics);
	// }, [indoorFabrics, psOutdoorFabrics]);

	const user = useUser();
	const quote = useQuoteById(quoteId);

	const assemblyOptions = UsePortalSelector(useAssemblyOptions(quoteId));

	const psOutdoorOptions = useNewPSOutdoorShadeOptions(currentAssembly, setCurrentAssembly, { fabrics: psOutdoorFabrics, user, quote, assemblyOptions });
	const indoorOptions = useNewIndoorShadeOptions(currentAssembly, setCurrentAssembly, { fabrics: indoorFabrics, user, quote, assemblyOptions });

	const dispatch = UsePortalDispatch();

	const [{
		errors,
		price
	}, setAssemblyViability] = useState({
		errors: "",
		price: 0
	});

	const formRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setAssemblyViability({
			errors: "",
			price: 0
		});
		apiCalls.calculateAssemblyPriceNew(AssemblyViewModelFromAssemblyStore(currentAssembly), quoteId, currentShadeType).then((res) => {
			const errorMessage = res?.data?.errors.map(e => e.message);
			
			const errorMessageListAsOneString = errorMessage?.join(", ") ?? "";
			setAssemblyViability({
				price: res?.data?.msrp ?? 0,
				errors: errorMessageListAsOneString
			});
		});
	}, [currentAssembly]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (formRef.current && !formRef.current.contains(event.target as Node)) {
				onCancel();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [onCancel]);

	const msrp = price;

	const selectIndoor = () => {
		setCurrentShadeType("indoor");
	};

	const selectOutdoor = () => {
		setCurrentShadeType("psoutdoor");
	};

	const attemptAdd = () => {
		// TODO Check if the assembly is valid

		dispatch(addAssembly({
			quoteId,
			newAssembly: currentAssembly,
			shadeType: currentShadeType
		}));
		onCancel();
	};

	// const done = (isCanceled = true) => {

	// 	if (isCanceled && newSequenceId != 0) {
	// 		dispatch(deleteAssemblies({
	// 			quoteId,
	// 			sequenceIds: [newSequenceId]
	// 		}))
	// 	}
	// 	try {
	// 		// this.props.newQuote.UI.reRenderTable();
	// 		onCancel();
	// 	} catch (e) {
	// 		// ROOT.alert({
	// 		// 	quickFormat: 'error',
	// 		// 	message: 'Something went wrong. Please try again.'
	// 		// });
	// 		// throw new Error(`Error closing new shade form and re-rendering quote table: ${e}`);
	// 	}
	// }

	// const {
	// 	isOutdoorShade, userMultiplier, fetchingPrice, shadeErrors, doneGettingId
	// } = this.state;

	const quoteType = useQuoteTypeNameByQuoteId(quoteId);
	
	// const add_button_disabled = fetchingPrice || isOutdoorShade === null || shadeErrors.length;

	const doneGettingId = true;

	const fetchingPrice = false;
	
	const add_button_disabled = fetchingPrice || !!currentAssembly.error_messages || currentShadeType === "";

	const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

	const assyOptions = UsePortalSelector(useAssemblyOptions(quoteId));

	const hasOutdoorHeaders = assyOptions.headers.some((header) => header?.series_attatched_to_header?.includes("outdoor_roller_shade") ?? false);

	return (
		<div>
			<h3 className={`text-${quoteType}`}>
				{currentShadeType === ""
					? 'New Shade'
					: currentShadeType === "psoutdoor"
						? 'New Outdoor Shade'
						: 'New Indoor Shade'}
			</h3>
			{!isColorPickerOpen && currentShadeType !== "" && doneGettingId ? (
				<h4 className={`text-${quoteType}`}>All Fields Required</h4>
			) : null}

			{currentShadeType === "" ? (
				<IsOutdoorShadeSelector
					quoteType={quoteType}
					selectOutdoor={selectOutdoor}
					selectIndoor={selectIndoor}
					selectLighting={() => {
						window.open('https://powershades.com/interior-roller-shades', '_blank');
					}}
					noOutdoor={!hasOutdoorHeaders}
				/>
			) : (
				<Col className=''>
					<Row />
					{
						currentShadeType === "psoutdoor" ? <EditPSOutdoorShadeModalInner
							// options, quoteType, isNotAdd = true, unlocked = true, massEdit
							options={psOutdoorOptions}
							quoteType={quoteType}
							isNotAdd={false}
							unlocked
							massEdit={false}
							pickerState={isColorPickerOpen}
							setPickerState={setIsColorPickerOpen}
						/>
							: <EditIndoorShadeModalInner
								options={indoorOptions}
								quoteType={quoteType}
								isNotAdd={false}
								unlocked
								massEdit={false}
								pickerState={isColorPickerOpen}
								setPickerState={setIsColorPickerOpen}
							/>
					}
					<Row />

				</Col>

			)}

			{!isColorPickerOpen && currentShadeType !== "" && (
				price !== 0 ? (
					<div className={`shade-price text-${quoteType}`}>
						<Money value={msrp} />
					</div>
				) : <text>
					{errors}
				</text>
			)}

			<Container className="local-bootstrap">
				<Row className="mt-4 mb-2">
					<Col sm={6} xl={4}>
						<Button
							fullWidth
							color="gray"
							onClick={
								isColorPickerOpen
									? () => setIsColorPickerOpen(false)
									: onCancel
							}
						>
							{isColorPickerOpen ? 'Done' : 'Cancel'}
						</Button>
					</Col>
					<Col xl={4} className="d-none d-xxl-block d-xl-block" />
					{!isColorPickerOpen
					&& <Col sm={6} xl={4} className="text-end">
						<Button
							fullWidth
							disabled={add_button_disabled}
							color={quoteType}
							onClick={attemptAdd}
						>
							{fetchingPrice ? 'Calculating...' : 'Add Shade'}
						</Button>
					</Col>}
				</Row>
			</Container>
		</div>
	);
};

export default NewQuoteItemForm;
