/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { Accordion, Col, Form, Row } from 'react-bootstrap';
import styles from "./ShadeSummaryNew.module.scss";

export type SummaryAccordionItem = {
	eventKey: string;
	title: string;
	content: Array<{ key: string, value: any, highlight?: string }>;
};

type SummaryAccordionListProps = {
	accordions: SummaryAccordionItem[];
	quoteType: string;
};

const SummaryAccordionList = ({ accordions, quoteType }: SummaryAccordionListProps) => {
	const { detailsAccordion, detailsAccordionGroup, assemblyErrorsAccordion, accordionHeaderSticky } = styles;
	const [searchTerm, setSearchTerm] = useState('');
	const [currentEventKeys, setCurrentEventKeys] = useState<string[]>(["Assembly Errors"]);

	const filteredAccordions = accordions.map((accordion) => {
		const filteredContent = accordion.content.filter((item) =>
			item.key.toLowerCase().includes(searchTerm.toLowerCase())
			|| item.value.toString().toLowerCase().includes(searchTerm.toLowerCase()));
		
		return filteredContent.length > 0 ? { ...accordion, content: filteredContent } : null;
	}).filter(Boolean) as SummaryAccordionItem[];

	useEffect(() => {
		const matchingEventKeys = filteredAccordions.map((acc) => acc.eventKey);
		setCurrentEventKeys(searchTerm ? matchingEventKeys : ["Assembly Errors"]);
	}, [searchTerm, accordions]);

	const toggleAccordion = (eventKey: string) => {
		setCurrentEventKeys((prev) =>
			(prev.includes(eventKey) ? prev.filter((key) => key !== eventKey) : [...prev, eventKey]));
	};

	const highlightText = (text: string, highlight: string) => {
		if (!highlight.trim()) return <span>{text}</span>;
		const regex = new RegExp(`(${highlight})`, 'gi');
		const parts = text.split(regex);
		return (
			<span>
				{parts.map((part, index) =>
					(regex.test(part) ? <mark key={index}>{part}</mark> : <span key={index}>{part}</span>))}
			</span>
		);
	};

	return (
		<div className={detailsAccordionGroup}>
			<Form.Control
				type="text"
				placeholder="Search..."
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				className="mb-3 rounded"
			/>
			<Accordion activeKey={currentEventKeys}>
				{filteredAccordions.map((accordion) => {
					const isError = accordion.eventKey === "Assembly Errors";
					return (
						<Accordion.Item
							key={accordion.eventKey}
							eventKey={accordion.eventKey}
							className={detailsAccordion}
							id={isError ? assemblyErrorsAccordion : ""}
						>
							<Accordion.Header
								className={`${quoteType} ${
									currentEventKeys.includes(accordion.eventKey) && accordionHeaderSticky
								}`}
								onClick={() => toggleAccordion(accordion.eventKey)}
							>
								{accordion.title}
							</Accordion.Header>
							<Accordion.Body>
								{accordion.content.map((item, index) => (
									<Row
										key={item.key}
										className={`mb-1 p-1 border border-1 rounded ${
											index % 2 !== 0 ? "bg-body" : (
												isError ? "bg-danger-subtle" : "bg-body-tertiary"
											)
										}`}
									>
										<Col className="border-end-1 border-end border-light-subtle" hidden={isError}>
											{highlightText(item.key, searchTerm)}
										</Col>
										<Col>
											{highlightText(item.value.toString(), searchTerm)}
										</Col>
									</Row>
								))}
							</Accordion.Body>
						</Accordion.Item>
					);
				})}
			</Accordion>
		</div>
	);
};

export default SummaryAccordionList;
