import React, { Component } from 'react';
import { isFunction } from 'lodash';

import InfoBox from '../../ShadeForm/InfoBox';
import OptionField from '../OptionField';
import Button from '../../../Parts/Button';

class MassShadeFormOptions extends Component {
	constructor(props) {
		super(props);
		this.state = {

		};

		this.onChange = this.onChange.bind(this);
	}

	onChange(...items) {
		this.props.onChange(...items);
	}

	render() {
		const {
			options,
			sequenceIds,
			isLightGapEnabled
		} = this.props;

		const fields = [];

		let current_group = [];
		let current_group_name = '';
		let last_opt;
		const i = 0;

		let form_group_z_index = 80;

		let index = 0;
		options
			.filter((o) => {
				const { varName } = o;

				if (varName == 'balanced_lightgaps') {
					return o.massEdit && isLightGapEnabled;
				}
				return o.massEdit;
			})
			.forEach((option) => {
				const opt_group_name = isFunction(option.groupName) ? option.groupName(shade) : option.groupName;
				option.value = null;
				index++;

				if (!opt_group_name) {
					if (current_group.length > 0) {
						fields.push(

							<div key={`form-group-${current_group_name}-${index}`} className={`${formy} no-bottom${last_opt.inlineGroup ? 'inline-group' : ''}`} style={{ zIndex: form_group_z_index-- }}>
								<span className="form-group-title">{current_group_name}</span>
								{current_group}
							</div>
						);
					}

					current_group = [];
					current_group_name = '';

					fields.push(

						<OptionField
							disabled={this.editable}
							key={`mass-edit-${option.varName}-form` + `-${index}`}
							option={option}
							value={null}
							shade={{}}
							onChange={this.onChange}
							id={sequenceIds}
							frontPage={false}
							global
						/>
					);
				} else if (opt_group_name != current_group_name) {
					if (current_group.length > 0) {
						let formy = "form-group";
						if (current_group_name == "Coupled Shades") {
							formy = "";
						}
						fields.push(

							<div
								key={`form-group-${current_group_name}-${index}`}
								className={`${formy} no-bottom ${last_opt.inlineGroup ? 'inline-group' : ''}`}
								style={{ zIndex: form_group_z_index-- }}
							>
								<span className="form-group-title">{current_group_name}</span>
								{current_group}
							</div>
						);
					}

					current_group = [];
					current_group_name = '';

					current_group_name = opt_group_name;

					current_group.push(

						<OptionField
							disabled={this.editable}
							key={`mass-edit-${option.varName}-form` + `-${index}`}
							option={option}
							value={null}
							shade={null}
							onChange={this.onChange}
							id={sequenceIds}
							frontPage={false}
							global

						/>
					);
				} else {
					current_group_name = opt_group_name;

					current_group.push(

						<OptionField
							disabled={this.editable}
							key={`mass-edit-${option.varName}-form` + `-${index}`}
							option={option}
							value={null}
							shade={null}
							onChange={this.onChange}
							id={sequenceIds}
							frontPage={false}
							global

						/>
					);
				}

				last_opt = option;
			});

		return (
			<div key="1d" id="product-editor">
				<div key="2d" className="product-form">

					<h2>Edit Shades</h2>
					{fields}
					<div key="3d" className="button-holder add-shade">

						<Button
							color="green"
							onClick={this.props.close}
						>
                            Done
						</Button>
					</div>
				</div>

			</div>
		);
	}
}

export default MassShadeFormOptions;
