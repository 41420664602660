// import wsClient from 'WebsocketClient';

import userApiCalls from './UserApi';
import HookImplementation from './Parts/HookImplementation';
import { selectCurrentUserData } from "./Store/user/userPermissions";

const wsClient = require('websocket').client;

const TOKEN_UPDATE_FREQUENCY = 60 * 1000;// Every 60 seconds.

let refreshing = false;
const refreshCallBacks = [];

const tokenPayload = (token) => {
	if ((token?.length ?? 0) < 1) {
		return null;
	}

	const split = token.split('.')[1];

	return split ? JSON.parse(atob(split ?? "")) : null;
};

const User = new (function () {
	const user = this;

	let user_data_queued = false;
	this.user_data_callbacks = [];

	this.hooks = new HookImplementation();

	this.temporary_multiplier = null;
	this.territories = [];

	this.getTerritoriesCallbackQueue = [];
	this.getTerritoriesInitiated = false;

	this.clearCache = async function () {
		user_data_queued = false;
		user.user_data = null;

		return true;
	};

	this.getDistributor = async function () {
		const distributors = await user.getEntities('distributors');

		return distributors[0];
	};

	this.getDistributorID = async function () {
		const dist = await user.getDistributor();

		return dist ? dist.id : null;
	};

	this.getEntities = async function (entities_name) {
		const data = await user.userData();

		const entities = [];

		data.user_roles.forEach((r) => {
			if (r[entities_name]) {
				r[entities_name].forEach((ent) => entities.push(ent));
			}
		});

		return entities;
	};

	this.getManufacturer = async function () {
		const manufacturers = await user.getEntities('manufacturers');

		return manufacturers[0];
	};

	this.getManufacturerID = async function () {
		const mfg = await user.getManufacturer();

		return mfg ? mfg.id : null;
	};

	this.getRepresentative = async function () {
		const representatives = await user.getEntities('representatives');

		return representatives[0];
	};

	this.getRepresentativeID = async function () {
		const rep = await user.getRepresentative();

		return rep ? rep.id : null;
	};

	this.getDistributorsDealersIds = async function () {
		const terrs = await user.getTerritories();

		const dealer_ids = [...new Set(terrs.reduce((acc, terr) => acc.concat(terr.dealers), []))];

		return dealer_ids;
	};

	this.logout = async function () {
		// localStorage.removeItem('user_jwt');
		// localStorage.removeItem('user_refresh');
		// localStorage.removeItem('user_data');

		// direct_to_login && window.ROOT && window.ROOT.loadContent("#Login");

		// direct_to_login && window.ROOT.refreshContent();

		// user.hooks.execute('login_status_changed');

		// user.setToken('');

		return true;
	};

	this.getToken = () => {
		if (this.token && (`${this.token}`).length > 0) {
			return this.token;
		}

		const goods = localStorage.getItem("user_data");
		const goodsObj = goods == "" ? {} : JSON.parse(goods);

		const jwt = goodsObj?.jwt ?? "";

		return jwt;
	};
	// eslint-disable-next-line no-unused-expressions
	this.updateToken = (newToken) => {
		const user = User;

		return new Promise((resolve) => {
			const rFunc = () => {
				refreshing = true;
				const claimedRefresh = localStorage.getItem("user_refresh");
				const refreshCall = userApiCalls.refreshToken;
				refreshCall && refreshCall(claimedRefresh)
					.then((resp) => {
						if (resp.data?.success !== true) {
							resolve(false);
							refreshCallBacks.forEach((r) => r(false));
							return;
						}
						localStorage.setItem('user_jwt', resp.data.access);
						localStorage.setItem("user_refresh", resp.data.refresh);
						refreshCallBacks.forEach((r) => r(true));
						resolve(true);
					})
					.catch((err) => {
						console.log(err);
						user.logout().then(() => {
							refreshing = false;

							resolve(false);
						});
					});
			};

			if (localStorage.getItem("user_refresh") == null) {
				resolve(false);
			} else if (refreshing) {
				refreshCallBacks.push((bool) => {
					resolve(bool);
				});

				const currentRCallbackLength = refreshCallBacks.length;

				if (currentRCallbackLength > 4) {
					rFunc();
				}

				if (currentRCallbackLength > 100) {
					location.reload();
				}
			} else {
				refreshing = true;
				rFunc();
			}
		});
	},

	this.setToken2 = function (token) {
		user.token = token;

		localStorage.setItem('user_jwt', token);
	};

	this.setToken = async function (token) {
		user.token = token;

		localStorage.setItem('user_jwt', token);

		return new Promise((resolve) => {
			wsClient.onLoad.add(async () => {
				resolve(await wsClient.updateJWT(user.token));
			});
		});
	};

	// this.isLoggedIn = async () => {
	// 	const time = Math.floor(new Date().getTime() / 1000);

	// 	const logged_in =
	// 		localStorage.getItem("user_jwt") !== null &&
	// 		tokenPayload(this.getToken()) != null &&
	// 		tokenPayload(this.getToken()).exp - time > 0;

	// 	if (logged_in) {
	// 		return true;
	// 	}

	// 	if (localStorage.getItem("user_refresh") != null) {
	// 		const token_updated = await this.updateToken();

	// 		if (!token_updated) {
	// 			localStorage.removeItem("user_jwt");
	// 		}

	// 		return token_updated;
	// 	}

	// 	return false;
	// },
	this.isLoggedIn = (dispatch, getState) => {
		const self = this;
		return new Promise((resolve, reject) => {
			this.dispatch = this.dispatch ?? dispatch;
			this.getState = this.getState ?? getState;

			const resolveFalse = () => { resolve(false); };
			const resolveTrue = () => { resolve(true); };

			if (this.dispatch == null || this.getState == null) {
				resolveFalse();
				return;
			}
			
			const state = this.getState();
			const userData = selectCurrentUserData(state);

			resolve(userData.authenticated && userData.email != "");

			// 
			// console.log(state);

			// if (userData.authenticated && userData.email != "") {
			// 	resolveTrue();
			// 	return;
			// }

			// let savedData = localStorage.getItem('user_data') ?? "";

			// if (savedData.userJwt == "") {
			// 	resolveFalse();
			// 	return;
			// 	//Login needed
			// }

			// const payload = tokenPayload(savedData.jswt);

			// const time = Math.floor((new Date()).getTime() / 1000);

			// const expired = payload.exp - time < 0;

			// if (expired) {
			// 	//Try refresh
			// 	const refreshToken = localStorage.getItem('user_refresh') ?? "";

			// 	if (refreshToken != "") {
			// 		return new Promise((resolve) => {
			// 			self.dispatch(attemptRefresh({
			// 				refreshJwt: refreshToken
			// 			})).then(resp => {
			// 				resolve(true)
			// 			}).catch(resp => {
			// 				resolve(false)
			// 			});
			// 		});
			// 		return;
			// 	}
			// 	else {
			// 		//Login Needed
			// 		return new Promise((resolve) => resolve(false));
			// 	}
			// } else {
			// 	userService.ensureUserData()
			// 	return new Promise((resolve) => resolve(false));
			// }
		});
	};

	async function tokenData() {
		const logged_in = await user.isLoggedIn();

		if (logged_in) {
			return tokenPayload(user.getToken());
		}

		if (
			!window.location.href.includes('#Login')
				&& !window.location.href.includes('#DealerSignup')
				&& !window.location.href.includes('#ConfirmPasswordReset')
				&& !window.location.href.includes('#CreatePassword')
		) {
			// eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwidXNlcl9pZCI6MTIwMCwiZXhwIjoxNjA1Njg4ODQsIm5hbWUiOiJHYXZpbiBEZVBhdWwiLCJqdGkiOiJiMDIwOTJhYzFkNDM0M2I5OTAxNmU2NWIxNjcxNmYwZiIsImVtYWlsIjoiZ2F2aW4uZGVwYXVsQHBvd2Vyc2hhZGVzLmNvbSJ9.WKQBM9aofAFeWeXocjwXmDFA-ro95VfROOMHD7ZuMMI
			if (window.ROOT) {
				// window.ROOT.loadContent("#Login");
			} else {
				const checkItAgain = function () {
					if (window.ROOT) {
						console.error("Error Caught");
						// window.ROOT.loadContent("#Login");
					} else {
						setTimeout(checkItAgain, 1000);
					}
				};

				checkItAgain();
			}
		}

		return null;
	}
	this.isLoggedIn().then((bool) => {
		if (!bool) {
			user.updateToken();
		}
	});

	// Updated the jwt token at the interval specified by "tokenUpdateFrequency".
	setInterval(async () => {
		const logged_in = await user.isLoggedIn();

		if (logged_in) {
			user.updateToken();
		}
	}, TOKEN_UPDATE_FREQUENCY);

	async function init() {

	}

	window.addEventListener('load', init);
	this.rCallbacksLengthOpe = 0;

	return this;
})();

// window.User = User;

export default User;
