import { faCheck, faInfoCircle, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { Row, Col, Form, Placeholder } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import Button from '../Parts/Button';
import { useBuyingGroups, useChannels } from '../Store/entities/hooks';
import { useIsPsAdmin } from '../hooks';
import apiCalls from '../PowerShadesAPIFunctions';
import { Portfolio } from '../powershadesApiTypes';

export type EntityPaymentOptionsChangeables = {
	allowCreditCardPayments: boolean | undefined;
	hasCreditTerms: boolean | undefined;
	termsId: string | undefined;
	isUsingPricingOverride: boolean;
	multiplier: number | undefined;
	buyingGroupIds: number[] | undefined;
	channelId: number;
	isAllowingCreditCardPayments: boolean | undefined;
	hasFabricBookOverride: boolean | undefined;
	currentPortfolio: string | undefined;
}

type EntityPaymentOptionsProps = EntityPaymentOptionsChangeables & {
	disabled?: boolean;
	onChange: (goods: EntityPaymentOptionsChangeables) => void;
	loading: boolean | undefined;
};

const EntityPaymentOptions: FC<EntityPaymentOptionsProps> = (props) => {
	const {
		allowCreditCardPayments,
		hasCreditTerms,
		termsId,
		isUsingPricingOverride,
		multiplier,
		onChange,
		disabled = false,
		buyingGroupIds,
		channelId,
		loading,
		isAllowingCreditCardPayments,
		hasFabricBookOverride,
		currentPortfolio
	} = props;

	const channels = useChannels().list;
	const channelOptions = channels?.map((channel) => (
		<option id={`${channel.id}`} value={`${channel.id}`} key={`${channel.id}`}>
			{`${channel.name}`}
		</option>
	));

	channelOptions?.unshift(<option selected disabled key="no-channel">Select a Channel Option</option>);
	const [editableMultiplier, setEditableMultiplier] = React.useState(`${multiplier}`);

	const [portfolios, setPortfolios] = React.useState<Portfolio[]>([]);

	const portfolioOptions = portfolios.map((portfolio) => (
		<option id={`${portfolio.code}`} value={`${portfolio.code}`} key={`${portfolio}`}>
			{`${portfolio.name}`}
		</option>
	));

	useEffect(() => {

		apiCalls.getPortfolios().then((response) => {
			setPortfolios(response.data);
		});
		

	}, [])

	useEffect(() => {
		setEditableMultiplier(`${multiplier}`);
	}, [multiplier])



	const buyingGroups = useBuyingGroups();
	const buyingGroupOptions = buyingGroups?.map((group) => ({
		value: group.id,
		label: group.name,
		id: group.id
	}));
	const workingBuyingGroups = buyingGroups
		?.filter((group) => buyingGroupIds?.includes(group.id))
		?.map((group) => ({ value: group.id, label: group.name }));

	const termOptions: { id: number, name: string }[] = [];
	const termOptionFields = termOptions.map((option) => (
		<option id={`${option.id}`} value={`${option.id}`} key={`${option.id}`}>
			{option.name}
		</option>
	));



	const isPsAdmin = useIsPsAdmin();

	const showPaymentMethods = allowCreditCardPayments !== undefined;
	const showTerms = termsId !== undefined;
	const showChannelsAndGroups = isPsAdmin;
	const showMultiplier = multiplier !== undefined;
	const showPricingOverride = isPsAdmin;

	const onChangeInternal = (
		goods: { [key in keyof EntityPaymentOptionsChangeables]?: EntityPaymentOptionsChangeables[key] }
	) => {
		const newItem = {
			allowCreditCardPayments,
			hasCreditTerms,
			termsId,
			isUsingPricingOverride,
			multiplier,
			buyingGroupIds,
			channelId,
			isAllowingCreditCardPayments,
			hasFabricBookOverride,
			currentPortfolio,
			...goods
		};
		onChange(newItem);
	};

	if (loading) {
		return (
			<Placeholder animation="glow" className="mb-4 ms-0 me-0">
				<Row>
					<Col xs={12} lg={4}>
						<Placeholder.Button xs={12} variant="green" />
					</Col>
					<Col xs={12} lg={4}>
						<Placeholder.Button xs={12} variant="green" />
					</Col>
					<Col xs={12} lg={4}>
						<Placeholder.Button xs={12} variant="green" />
					</Col>
				</Row>
			</Placeholder>
		);
	}

	return (
		<>
			<Row>
				<Col xs={12}>
					<h4>Payment Options</h4>
				</Col>
				{showPaymentMethods && (
					<>
						<Col sm={4}>
							<Button
								fluid
								fullWidth
								disabled={disabled}
								color={allowCreditCardPayments
									? 'green'
									: 'gray'}
								onClick={() =>
									onChangeInternal({ allowCreditCardPayments: !allowCreditCardPayments })
								}
								radioButton
							>
								Credit Card Payment&nbsp;
								<FontAwesomeIcon icon={allowCreditCardPayments ? faCheck : faTimes} />
							</Button>
						</Col>
						<Col sm={4}>
							<Button
								fluid
								fullWidth
								disabled={disabled}
								color={
									hasCreditTerms ? 'green' : 'gray'
								}
								onClick={() =>
									onChangeInternal({ hasCreditTerms: !hasCreditTerms })
								}
								radioButton
							>
								Terms Payment&nbsp;
								<FontAwesomeIcon icon={hasCreditTerms ? faCheck : faTimes} />
							</Button>
						</Col>
						<Col sm={4}>
							<Button
								fluid
								fullWidth
								disabled={disabled}
								color={
									hasFabricBookOverride ? 'green' : 'gray'
								}
								onClick={() =>
									onChangeInternal({ hasFabricBookOverride: !hasFabricBookOverride })
								}
								radioButton
							>
								Has Fabric Book Override&nbsp;
								<FontAwesomeIcon icon={hasFabricBookOverride ? faCheck : faTimes} />
							</Button>
						</Col>

					</>
				)}

				{showTerms && (
					<Row>

						<Col sm={4}>
							<select
								className="form-select"
								disabled={disabled}
								id="terms_options"
								value={termsId}
								onChange={(e) => onChangeInternal({ termsId: e.target.value })}
							>
								<option selected disabled>Select a Terms Option</option>
								{termOptionFields}
							</select>
						</Col>
						<Col sm={4}>
							<select
								className="form-select"
								disabled={disabled}
								id="current_portfolio"
								value={currentPortfolio}
								onChange={(e) => onChangeInternal({ currentPortfolio: e.target.value })}
							>
								<option selected disabled>Select a Portfolio</option>
								{portfolioOptions}
							</select>
						</Col>
					</Row>

				)
				}
				{showChannelsAndGroups && (
					<>
						<Col sm={12} md={6} className="mb-2 mt-2">
							<h6>Channel</h6>
							<select
								className="form-select"
								disabled={disabled}
								id="channel-selection"
								value={channelId}
								onChange={(e) => onChangeInternal({ channelId: parseInt(e.target.value, 10) })}
							>
								{channelOptions}
							</select>
						</Col>
						<Col sm={12} md={6} className="mb-2 mt-2">
							<h6>Buying Group</h6>
							<Typeahead
								disabled={disabled}
								id="buying-group-selection"
								multiple
								clearButton
								selected={workingBuyingGroups}
								options={buyingGroupOptions}
								onChange={(e) => {
									const buyingGroupsGiven = e as {
										value: string;
									}[];
									// This is because Typeahead 
									// typing is bad.
									const buyingGroupIdsGiven = buyingGroupsGiven.map((group) => group.value as unknown as number);

									onChangeInternal({
										buyingGroupIds: buyingGroupIdsGiven
									});
								}}
							/>
						</Col>
					</>
				)}
				{showMultiplier && (
					<Col sm={12} md={6} className="mb-2 mt-2">
						<h6>
							<Row>
								<Col xs={11}>
									Multiplier
								</Col>
								<Col xs={1}>
									<Popup
										trigger={(
											<FontAwesomeIcon
												icon={faInfoCircle}
												className="info-circle"
											/>
										)}
										on="hover"
										position="bottom center"

									>
										This field is expecting a decimal between 0 and 1.
									</Popup>
								</Col>
							</Row>
						</h6>
						<Form.Control
							disabled={disabled}
							type="text"
							value={editableMultiplier}
							onChange={(e) => {
								setEditableMultiplier(e.target.value);
								// const { value } = e.target;
								// const floatVal = parseFloat(value);
								// onChangeInternal({ multiplier: floatVal });
							}}
							onBlur={() => {
								const floatVal = parseFloat(editableMultiplier);
								onChangeInternal({ multiplier: floatVal });
							}}
						/>
					</Col>
				)}
				{showPricingOverride && (
					<Col sm={12} md={6} className="mb-2 mt-2">
						<h6>Pricing Override</h6>
						<select
							className="form-select"
							value={isUsingPricingOverride ? "true" : "false"}
							disabled={disabled}
						>
							<option disabled selected>Select a Pricing Override Option</option>
							<option value="true">Pricing Grid</option>
							<option value="false">Portal Pricing</option>
						</select>
					</Col>
				)}
			</Row>
			<hr />
		</>
	);
};

export default EntityPaymentOptions;
