import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { startCase } from 'lodash';
import { toast } from 'react-toastify';

import { UsePortalDispatch } from '../Store';
import {
	useDealerById,
	useTerritories,
	useBuyingGroups,
	useDistributors,
	useRepresentatives,
	useUsersList,
} from '../Store/entities/hooks';

import { DealerFilters, QuotePrefixById } from '../powershadesApiTypeExtensions';
import { loadStatus } from '../Store/entities/entitiesType';
import { initialDealerState, updateDealerFull } from '../Store/entities/organizations/dealers';

// import apiCalls from '../PowerShadesAPIFunctions';

import TabPaneWithSearchFilter from '../Parts/TabPaneWithSearchFilter';
import EntityPageHeader from '../Parts/EntityPageHeader';
import BillingInformationHandler from '../ManageEntities/BillingInformationHandler';
import EntityTaxInfo from '../ManageEntities/EntityTaxInfo';
import EntityPaymentOptions from '../ManageEntities/EntityPaymentOptions';
import AddTerritory from '../Modals/AddTerritory';
import AddEmailDistribution from '../Modals/AddEmailDistribution';
// import EntityHistoryModal from '../ManageEntities/EntityHistoryModal';
import EditDistributorTerritories from '../Modals/EditDistributorTerritories';

import type {
	ChannelId,
	DealerStore,
	DistributorLocationTerritory,
	// EntityHistoryView,
	QuoteTypeIds,
	RepresentativeTerritory,
} from '../powershadesApiTypes';
import type { DealerViewProps, DistributionEmailType } from './types';
import type { TabPaneSelections } from '../Parts/types';
import { useIsPowerShadesAdmin } from '../hooks';
import DealerDistributorView from './DealerDistributorView';
import { useQuoteLoadStatus, useQuoteMetaDispatch, useQuotes } from '../Store/entities/quotes/hooks';
import { getRepresentativeLink, getTerritoryLink, getUserLink } from '../psUtil';
import { useModal } from '../Store/ui/hooks';
import { usePrompt } from '../Store/hooks';
import AddRepresentative from '../Modals/AddRepresentative';
import { FlatRep } from '../Modals/types';
import AddUserModal from '../User/AddUserModal';
import UserLookup from '../Modals/UserLookup';
import DealerApproval from '../Parts/DealerApproval';

const DealerView = ({ ROOT }: DealerViewProps) => {
	const [activeFilter, setActiveFilter] = useState<DealerFilters>('all');
	const [activeSearch, setActiveSearch] = useState<string>('');
	const [activeKey, setActiveKey] = useState(0);
	const [searchParams, setSearchParms] = useSearchParams();
	const [editable, setEditable] = useState(false);
	// const [historyList, setHistoryList] = useState<EntityHistoryView[]>([]);
	// const [showHistoryModal, setShowHistoryModal] = useState(false);
	const [showAddTerritoryModal, setShowAddTerritoryModal] = useState(false);
	const [showAddDistributorModal, setShowAddDistributorModal] = useState(false);
	const [showAddEmailDistributionModal, setShowAddEmailDistributionModal] = useState(false);
	const [showAddRepresentativeModal, setShowAddRepresentativeModal] = useState(false);
	const [showAddUserModal, setShowAddUserModal] = useState(false);
	const [showLookupUserModal, setShowLookupUserModal] = useState(false);

	const entityIdStr = searchParams.get('dealer_id') ?? '0';
	const entityId = parseInt(entityIdStr, 10);
	const filter = searchParams.get('filter') ?? 'approved';

	const { navigate, setTitle, loadContent } = ROOT;

	const dispatch = UsePortalDispatch();
	const distributors = useDistributors();
	console.debug(distributors);
	const representatives = useRepresentatives();
	const selectedDealer = useDealerById(entityId) ?? initialDealerState();
	const modal = useModal();
	const territories = useTerritories();
	const buyingGroupList = useBuyingGroups();
	const userIsPsAdmin = useIsPowerShadesAdmin();
	const dispatchQuotesMetaData = useQuoteMetaDispatch();
	const quoteMetaDataLoadStatus = useQuoteLoadStatus();
	const quotes = useQuotes();
	const users = useUsersList();
	const [workingDealer, setWorkingDealer] = useState(selectedDealer ?? initialDealerState());

	const changesHaveBeenMade = useMemo(() =>
		JSON.stringify(workingDealer) !== JSON.stringify(selectedDealer),
		[workingDealer, selectedDealer]);

	usePrompt(modal, changesHaveBeenMade && editable);

	useEffect(() => {
		if (selectedDealer) {
			setWorkingDealer(selectedDealer);
		}
	}, [selectedDealer.id, selectedDealer.loadStatus]);

	// const entityName = selectedDealer?.name ?? 'Dealer';

	const openAddTerritoryModal = () => {
		setShowAddTerritoryModal(true);
	};
	const _openAddDistributorModel = () => {
		setShowAddDistributorModal(true);
	};
	console.debug(_openAddDistributorModel);

	/**
	 * Function that handles the change of the dealer filter and updates the search parameters.
	 * @param workingFilter - The new filter to be applied.
	 */
	const handleFilterChange = (workingFilter: string): void => {
		setActiveFilter(workingFilter as DealerFilters);

		setSearchParms((searchParamsCurr) => {
			searchParamsCurr.set('filter', workingFilter);
			return new URLSearchParams(searchParams);
		});
	};

	/**
	 * Function that sets the working dealer to the selected dealer and toggles the editable state
	 * @param newValue - The new value for the editable state
	 */
	const setIsEditable = (newValue: boolean) => {
		setWorkingDealer(selectedDealer ?? initialDealerState());
		setEditable(newValue);
	};

	/**
	 * Function that saves the working dealer by dispatching an update action.
	 * @returns void
	 */
	const saveWorkingEntity = async () => {
		try {
			const updateResponse = await dispatch(updateDealerFull(workingDealer));
			if (updateDealerFull.fulfilled.match(updateResponse)) {
				toast.success('Dealer updated successfully');
				setWorkingDealer(updateResponse.payload as DealerStore);
			}
			if (updateDealerFull.rejected.match(updateResponse)) {
				const errorMessage = updateResponse.payload
					?? 'Something went wrong trying to save the dealer and no error message was provided';
				setWorkingDealer(selectedDealer ?? initialDealerState());
				throw new Error(errorMessage);
			}
		} catch (e: any) {
			toast.error(`Dealer Update Failed! PowerShades engineering has been notified`);
			setWorkingDealer(selectedDealer ?? initialDealerState());
			throw new Error(e);
		}
	};

	/**
	 * Function that deletes a distribution email from the working dealer
	 * @param email - The email to be deleted
	 * @param deleteVal - The type of email to delete
	 */
	const deleteDistributionEmail = (email: string, deleteVal: DistributionEmailType = 'all') => {
		const distributionEmails = [...workingDealer.distribution_emails];
		switch (deleteVal) {
			case 'all':
				return setWorkingDealer({
					...workingDealer,
					distribution_emails: distributionEmails.filter((de) => de.email !== email),
				});
			case 'order':
				return setWorkingDealer({
					...workingDealer,
					distribution_emails: distributionEmails.map((de) =>
						(de.email === email ? { ...de, is_receiving_order_placed_emails: false } : de)
					),
				});
			case 'invoice':
				return setWorkingDealer({
					...workingDealer,
					distribution_emails: distributionEmails.map((de) =>
						(de.email === email ? { ...de, is_receiving_invoice_emails: false } : de)
					),
				});
			default:
		}
	};

	/**
	 * Function that converts the working dealer entity into a tab pane selection entity.
	 * @notes Uses territories, distributor territories, rep territories, users, and
	 * distribution emails to create the selection.
	 * @returns TabPaneSelections
	 */
	const getTabSelectionsEntity = (): TabPaneSelections[] => {
		const workingTabPanes: TabPaneSelections[] = [];

		const territoryTabPane: TabPaneSelections = {
			id: 0,
			title: 'Territories',
			key: 'territory_pane',
			filters: [],
			items: [],
		};

		workingDealer?.territory_ids?.forEach((territoryId) => {
			const territory = territories.find((wt) => wt.id === territoryId);
			if (!territory) return;
			const territoryLink = getTerritoryLink(territoryId);
			territoryTabPane.items.push({
				id: `${territory.id}`,
				type: "territory",
				name: territory.name,
				title: territory.name,
				onClick: () => {
					navigate(territoryLink);
				},
				onDelete: () => {
					modal({
						title: `Remove ${territory.name}?`,
						text: `Are you sure you want to remove ${territory.name} from ${workingDealer.name}?`,
						confirmButtonText: 'Remove',
						onConfirm: () => {
							try {
								setWorkingDealer({
									...workingDealer,
									territory_ids: workingDealer.territory_ids?.filter(
										(t) => t !== territory.id
									),
									distributor_location_territories: workingDealer.distributor_location_territories?.filter(
										(dlt) => dlt.territory_id !== territory.id
									),
									representative_territories: workingDealer.representative_territories?.filter(
										(rt) => rt.territory_id !== territory.id
									),
								});
								toast.success(
									`${territory.name} removed from ${workingDealer.name}`
								);
							} catch (err) {
								toast.error('Something went wrong trying to remove the territory');
								throw err;
							}
						},
						onCancel: () => {
							toast.info('Territory removal canceled');
						},
					});
				},
			});
		});

		if (editable) {
			territoryTabPane.items.unshift({
				id: 'Add',
				type: "territory",
				name: 'Add Territory...',
				title: 'Add Territory...',
				onClick: () => {
					openAddTerritoryModal();
				},
			});
		}

		workingTabPanes.push(territoryTabPane);

		const representativesTabPane: TabPaneSelections = {
			id: 3,
			title: 'Representatives',
			key: 'representative_pane',
			filters: [],
			items: [],
		};

		workingDealer.representative_territories.forEach((repTerritory) => {
			const representative = representatives.find(
				(rep) => rep.id === repTerritory.representative_id
			);

			if (representatives?.length === 0) {
				representativesTabPane.items.push({
					id: `${representative?.id ?? 0}`,
					type: "representative",
					name: `${representative?.name ?? 'Loading...'}`,
					title: 'Loading',
					onClick: () => {
						/* Do Nothing */
					},
				});
			} else {
				if (!representative?.id || !representative?.name) return;
				representativesTabPane.items.push({
					id: `${representative.id}`,
					type: "representative",
					name: `${representative.name}`,
					title: 'None',
					onClick: () => {
						navigate(getRepresentativeLink(representative?.id ?? 0));
					},
					onDelete: () => {
						modal({
							title: `Remove ${representative?.name ?? 'Representative'}?`,
							text: `Are you sure you want to remove ${representative?.name ?? 'this representative'
								} from ${workingDealer.name}?`,
							confirmButtonText: 'Remove',
							onConfirm: () => {
								try {
									setWorkingDealer({
										...workingDealer,
										representative_territories:
											workingDealer.representative_territories?.filter(
												(rt) =>
													rt.representative_id !== representative?.id
											),
									});
									toast.success(
										`${representative?.name ?? 'Representative'
										} removed from ${workingDealer.name}`
									);
								} catch (err) {
									toast.error(
										'Something went wrong trying to remove the representative'
									);
									throw err;
								}
							},
							onCancel: () => {
								toast.info('Representative removal canceled');
							},
						});
					},
				});
			}
		});
		if (editable) {
			representativesTabPane.items.unshift({
				id: 'Add',
				type: "representative",
				name: 'Add Representative...',
				title: 'Add Representative...',
				onClick: () => {
					setShowAddRepresentativeModal(true);
				},
			});
		}

		workingTabPanes.push(representativesTabPane);

		const quotesTabPane: TabPaneSelections = {
			id: 4,
			key: 'quote_pane',
			title: 'Quotes',
			filters: ['all', 'active', 'placed', 'RMA', 'rework', 'demo', 'archived'],
			items: [],
			editable: false,
		};

		if (workingDealer.quote_ids.length > 0 && quoteMetaDataLoadStatus === loadStatus.needsLoaded) {
			dispatchQuotesMetaData(workingDealer.quote_ids);
		}

		workingDealer.quote_ids.forEach((quoteId) => {
			const quote = quotes[quoteId];

			if (!quote) {
				return;
			}

			const quoteTypeId: QuoteTypeIds = quote?.quote_type_id ?? 5;
			const quotePrefix = QuotePrefixById[quoteTypeId ?? 5] ?? 'PS';

			quotesTabPane.items.push({
				id: `${quote.id}`,
				type: "quote",
				name: `${quotePrefix}${quote.id} - 
					${quote.title === '' || quote.title === null ? 'No Title' : quote.title}`,
				title: 'Quote ',
				onClick: () => {
					navigate(`/Quote?quoteID=${quote.id}`);
				},
			});
		});
		workingTabPanes.push(quotesTabPane);

		const usersTabPane: TabPaneSelections = {
			id: 5,
			key: 'user_pane',
			title: 'Users',
			items: [],
			editable: true,
		};

		workingDealer.user_ids.forEach((userId) => {
			const user = users[userId];
			if (!user) return;

			usersTabPane.items.push({
				id: `${user.id}`,
				type: "user",
				name: `${user.name}`,
				title: `${user.name}`,
				onClick: () => {
					loadContent(getUserLink(user.id));
				},
				onDelete: () => {
					modal({
						title: `Remove ${user.name}?`,
						text: `Are you sure you want to remove ${user.name} from ${workingDealer.name}?`,
						confirmButtonText: 'Remove',
						onConfirm: () => {
							try {
								setWorkingDealer({
									...workingDealer,
									user_ids: workingDealer.user_ids?.filter(
										(u) => u !== user.id
									),
								});
								toast.success(`${user.name} removed from ${workingDealer.name}`);
							} catch (err) {
								toast.error('Something went wrong trying to remove the user');
								throw err;
							}
						},
						onCancel: () => {
							toast.info('User removal canceled');
						},
					});
				}
			});
		});

		if (editable) {
			usersTabPane.items.unshift({
				id: 'Add',
				type: "user",
				name: 'Add User...',
				title: 'Add User...',
				onClick: () => {
					modal({
						title: 'Add or Lookup User?',
						text: 'Would you like to add a new user or lookup an existing user?',
						confirmButtonText: 'Add',
						cancelButtonText: 'Lookup',
						onConfirm: () => {
							setShowAddUserModal(true);
						},
						onCancel: () => {
							setShowLookupUserModal(true);
						},
					});
				},
			});
		}
		workingTabPanes.push(usersTabPane);

		const distributionEmailsTabPane: TabPaneSelections = {
			id: 6,
			title: 'Distribution Emails',
			key: 'distribution_emails_pane',
			filters: ['all', 'order placed access', 'invoice access'],
			items: [],
		};

		workingDealer.distribution_emails.forEach((distributionEmail) => {
			if (distributionEmail === undefined) return;

			const hasInvoiceAccess = distributionEmail.is_receiving_invoice_emails;
			const hasOrderPlacedAccess = distributionEmail.is_receiving_order_placed_emails;
			if (hasInvoiceAccess) {
				distributionEmailsTabPane.items.push({
					id: `inv-${distributionEmail.email}`,
					type: "distributionEmail",
					name: `${distributionEmail.email} - Invoice Access`,
					title: distributionEmail.email,
					onClick: () => null,
					onDelete: () => {
						modal({
							title: `Remove ${distributionEmail.email} invoice access?`,
							text: `Are you sure you want to remove ${distributionEmail.email} invoice access from ${workingDealer.name}?`,
							confirmButtonText: 'Remove',
							onConfirm: () => {
								try {
									deleteDistributionEmail(distributionEmail.email, 'invoice');
									toast.success(`${distributionEmail.email} invoice access removed`);
								} catch (err) {
									toast.error('Something went wrong trying to remove invoice access');
									throw err;
								}
							},
							onCancel: () => {
								toast.info('Invoice access removal canceled');
							}
						});
					},
				});
			}
			if (hasOrderPlacedAccess) {
				distributionEmailsTabPane.items.push({
					id: `ord-${distributionEmail.email}`,
					type: "distributionEmail",
					name: `${distributionEmail.email} - Order Access`,
					title: distributionEmail.email,
					onClick: () => null,
					onDelete: () => {
						modal({
							title: `Remove ${distributionEmail.email} order access?`,
							text: `Are you sure you want to remove ${distributionEmail.email} order access from ${workingDealer.name}?`,
							confirmButtonText: 'Remove',
							onConfirm: () => {
								try {
									deleteDistributionEmail(distributionEmail.email, 'order');
									toast.success(`${distributionEmail.email} order access removed`);
								} catch (err) {
									toast.error('Something went wrong trying to remove order access');
									throw err;
								}
							},
							onCancel: () => {
								toast.info('Order access removal canceled');
							}
						});
					},
				});
			}

			if (!hasInvoiceAccess && !hasOrderPlacedAccess) {
				distributionEmailsTabPane.items.push({
					id: `non-${distributionEmail.email}`,
					type: "distributionEmail",
					name: `${distributionEmail.email} - No Access`,
					title: distributionEmail.email,
					onClick: () => null,
					onDelete: () => {
						modal({
							title: `Remove ${distributionEmail.email} from distribution emails?`,
							text: `Are you sure you want to remove ${distributionEmail.email} from ${workingDealer.name}? This will remove the 
							email entirely.`,
							confirmButtonText: 'Remove',
							onConfirm: () => {
								try {
									deleteDistributionEmail(distributionEmail.email);
									toast.success(`${distributionEmail.email} removed`);
								} catch (err) {
									toast.error('Something went wrong trying to remove the email');
									throw err;
								}
							},
							onCancel: () => {
								toast.info('Email removal canceled');
							}
						});
					},
				});
			}
		});

		if (editable) {
			distributionEmailsTabPane.items.unshift({
				id: 'Add',
				type: "distributionEmail",
				name: 'Add or Edit Email Distribution Rule...',
				title: 'Add or Edit Email Distribution Rule...',
				onClick: () => {
					setShowAddEmailDistributionModal(true);
				},
			});
		}

		workingTabPanes.push(distributionEmailsTabPane);
		return workingTabPanes;
	};

	useEffect(() => {
		if (selectedDealer) {
			setTitle(`View ${startCase(selectedDealer.status)} Dealer | ${selectedDealer?.name}`);
			setWorkingDealer(selectedDealer);
		}
	}, [setTitle, selectedDealer]);

	useEffect(() => {
		if (activeFilter !== filter) {
			setActiveFilter(filter as DealerFilters);
		}
	}, [filter]);

	// useEffect(() => {
	// 	const loadDealerHistory = async () => {
	// 		if (!userIsPsAdmin) return;
	// 		if (selectedDealer && selectedDealer.id !== 0) {
	// 			const dealerHistoryList = await apiCalls.getDealerHistory(selectedDealer.id);

	// 			setHistoryList(dealerHistoryList.data.history);
	// 		}
	// 	};

	// 	loadDealerHistory();
	// }, [entityId]);

	// If the component is set to not editable, and there is a selected dealer with a
	// non-zero ID and a non-zero entity ID (from URL params),
	// set the working dealer to the selected dealer.
	useEffect(() => {
		if (!editable && entityId !== 0 && selectedDealer?.id !== 0 && selectedDealer) {
			setWorkingDealer(selectedDealer);
		}
	}, [selectedDealer, editable]);

	if (entityId !== 0) {
		const workingEntity = workingDealer;

		const editing = editable;
		const setEditing = setIsEditable;

		const loading = (selectedDealer?.loadStatus ?? 0) < loadStatus.fullyLoaded;
		const tabSelectionCurrent = getTabSelectionsEntity();

		return (
			<Container className="local-bootstrap">
				<Card body className="entity-card">
					<Row>
						<Col>
							<EntityPageHeader
								name={workingEntity.name ?? ''}
								email={workingEntity.email ?? ''}
								phone={workingEntity.phone ?? ''}
								loading={loading}
								editable={editing}
								saveEnabled={changesHaveBeenMade}
								setEditable={(e) => setEditing(e)}
								onChange={(fields) => {
									const {
										name,
										email,
										phone
									} = fields;

									setWorkingDealer({
										...workingDealer,
										name,
										email: email ?? '',
										phone: phone ?? '',
									});
								}}
								onSave={() => {
									saveWorkingEntity();
									setEditing(false);
								}}
								selectedDealer={selectedDealer}
								showZohoConnection={userIsPsAdmin}
								id={entityId}
								entityType="dealer"
							/>
							<DealerApproval
								loading={loading}
								dealerId={entityId}
							/>
							<hr />
							<BillingInformationHandler
								disabled={!editing}
								address={workingEntity.billing_address}
								onChange={(address) => {
									/* TODO: Update Address */ console.log(address);

									setWorkingDealer({
										...workingDealer,
										billing_address: { ...address },
									});
								}}
								loading={loading}
							/>
							<EntityTaxInfo
								disabled={!editing}
								stateTaxCode={
									workingEntity.sales_tax_exempt_certificate_number ?? ''
								}
								stateTaxStatus={workingEntity.sales_tax_exempt_reason ?? ''}
								stateTaxLink={
									workingEntity.sales_tax_exempt_certificate_location ?? ''
								}
								isTaxable={workingEntity.sales_tax_exempt_status !== 'tax_exempt'}
								federalTaxLink={
									workingEntity.federal_tax_certificate_location ?? ''
								}
								federalTaxCode={workingEntity.federal_tax_id ?? ''}
								onChange={(fields) => {
									const {
										stateTaxCode,
										stateTaxLink,
										isTaxable,
										federalTaxLink,
										federalTaxCode,
										stateTaxStatus
									} = fields;

									/* idk yet */
									setWorkingDealer({
										...workingDealer,
										sales_tax_exempt_certificate_number: stateTaxCode,
										sales_tax_exempt_status: !isTaxable
											? 'tax_exempt'
											: 'non_exempt',
										sales_tax_exempt_certificate_location: stateTaxLink,
										federal_tax_certificate_location: federalTaxLink,
										federal_tax_id: federalTaxCode,
										sales_tax_exempt_reason: stateTaxStatus
									});
								}}
								entityType="dealer"
								entityId={workingEntity.id.toString()}
								loading={loading}
							/>
							<DealerDistributorView
								disabled={!editing}
								loading={loading}
								dealerId={entityId}
								workingDealer={workingDealer}
								onSubmit={(newDistributorLocationId, territoryIds) => {
									if (newDistributorLocationId === 0) {
										setWorkingDealer({
											...workingDealer,
											distributor_location_territories: [],
										});
									} else {
										setWorkingDealer({
											...workingDealer,
											distributor_location_territories: territoryIds.map(
												(id) => ({
													distributor_location_id:
														newDistributorLocationId,
													territory_id: id,
												})
											),
										});
									}
								}}
							/>
							<EntityPaymentOptions
								disabled={!editing}
								allowCreditCardPayments={
									workingEntity.allow_credit_card_payments ?? false
								}
								hasCreditTerms={workingEntity.has_credit_terms ?? false}
								channelId={workingEntity.channel_id ?? 0}
								multiplier={workingEntity.multiplier ?? 1}
								buyingGroupIds={
									workingEntity?.buying_group_ids ?? []
								}
								isUsingPricingOverride={false}
								onChange={(fields) => {
									const {
										allowCreditCardPayments,
										hasCreditTerms,
										channelId,
										multiplier,
										buyingGroupIds = [],
										hasFabricBookOverride,
										currentPortfolio
									} = fields;


									const buyingGroups = buyingGroupIds?.map(
										(bgId) => {
											const buyingGroup = buyingGroupList.find(
												(bg) => `${bg.id}` === `${bgId}`
											)

											if (!buyingGroup) throw new Error(`Buying Group ${bgId} not found`);

											return buyingGroup;
										}
									) ?? [];

									setWorkingDealer({
										...workingDealer,
										multiplier: multiplier ?? 0.5,
										allow_credit_card_payments:
											allowCreditCardPayments ?? false,
										has_credit_terms: hasCreditTerms ?? false,
										channel_id: channelId as ChannelId,
										buying_groups: buyingGroups,
										buying_group_ids: buyingGroupIds,
										has_fabric_book_override: hasFabricBookOverride ?? false,
										current_portfolio: currentPortfolio ?? ''
									});
								}}
								loading={loading}
								isAllowingCreditCardPayments={undefined}
								termsId={""}
								hasFabricBookOverride={workingDealer.has_fabric_book_override}
								currentPortfolio={workingDealer.current_portfolio}
							/>

							<TabPaneWithSearchFilter
								key="dealer-view"
								disabled={!editing}
								loading={loading}
								currentFilter={activeFilter}
								setCurrentFilter={handleFilterChange}
								selections={tabSelectionCurrent}
								setActiveKey={setActiveKey}
								activeKey={activeKey}
								searchFilter={activeSearch}
								setSearchFilter={setActiveSearch}
								filterOptions={[]}
							/>

							{/* Modals here bc */}
							<AddTerritory
								key="addTerritoryDealerView"
								open={showAddTerritoryModal}
								selectedTerritoryIds={workingDealer.territory_ids ?? []}
								onClose={() => setShowAddTerritoryModal(false)}
								handleAdd={(territoryIds: number[]) => {
									const repVal = selectedDealer.representative_territories.find(r => r)?.representative_id ?? 0;
									const distVal = selectedDealer.distributor_location_territories.find(d => d)?.distributor_location_id ?? 0;

									const currentTerrIds = [...(workingDealer.territory_ids ?? []), ...territoryIds];

									// Get newDistributor Territories
									const newDisTerrs = currentTerrIds.reduce((prev, dlt) => {
										if(distVal === 0) return prev;
										return [...prev, { distributor_location_id: distVal, territory_id: dlt }];
									}, [] as DistributorLocationTerritory[]);

									// Get newRepresentative territories
									const newRepTerrs = currentTerrIds.reduce((prev, rt) => {
										if(repVal === 0) return prev;
										return [...prev, { representative_id: repVal, territory_id: rt }];
									}, [] as RepresentativeTerritory[]);
									

									setWorkingDealer({
										...workingDealer,
										territory_ids: [
											...workingDealer.territory_ids,
											...territoryIds,

										],
										distributor_location_territories: newDisTerrs,
										representative_territories: newRepTerrs
									});
								}}
							/>

							<AddUserModal
								open={showAddUserModal}
								onClose={() => setShowAddUserModal(false)}
								callBack={(userId) => {
									setWorkingDealer({
										...workingDealer,
										user_ids: [...workingDealer.user_ids, userId],
									});
								}}
								allowEntitySelection={false}
								entityType="dealer"
								inheritedEntityId={workingDealer.id}
								inheritedEntityName={workingDealer.name}
							/>

							<UserLookup
								open={showLookupUserModal}
								onClose={() => setShowLookupUserModal(false)}
								handleAdd={(user) => {
									setWorkingDealer({
										...workingDealer,
										user_ids: [...workingDealer.user_ids, user.id],
									});
								}}
							/>

							<AddRepresentative
								key="addRepresentativeDealerView"
								open={showAddRepresentativeModal}
								selectedRepresentativeIds={workingDealer.representative_territories?.map(
									(rt) => rt.representative_id
								)}
								onClose={() => setShowAddRepresentativeModal(false)}
								handleAdd={(repsToAdd: FlatRep[]) => {
									setWorkingDealer({
										...workingDealer,
										representative_territories: [
											...workingDealer.representative_territories,
											...repsToAdd.map((rep) => ({
												representative_id: rep.id,
												territory_id: rep.territoryId,
											})),
										],
									});
								}}
								territoriesToFilterFrom={workingDealer.territory_ids ?? []}
							/>

							<AddEmailDistribution
								key="addEmailDealerView"
								open={showAddEmailDistributionModal}
								onClose={() => setShowAddEmailDistributionModal(false)}
								handleAdd={(distributionEmails) => {
									setWorkingDealer({
										...workingDealer,
										distribution_emails: [...distributionEmails],
									});
								}}
								existingEmailDistributions={workingDealer.distribution_emails ?? []}
							/>

							{/* <EntityHistoryModal
								items={historyList}
								entityName={entityName}
								show={showHistoryModal}
								setShow={(show: boolean): void => {
									setShowHistoryModal(show);
								}}
							/> */}

							<EditDistributorTerritories
								show={showAddDistributorModal}
								setShow={(show) => {
									show
										? setShowAddDistributorModal(true)
										: setShowAddDistributorModal(false);
								}}
								onSave={(_territoryIds) => {
									// setWorkingDealer({
									// 	...workingDealer,
									// 	distributor_territories: territoryIds,
									// });
								}}
								currentDistributorTerritories={[]}
								allowedDistributorTerritories={[]}
								distributors={[]}
								territories={[]}
								initialDistributorId={0}
							/>
						</Col>
					</Row>
				</Card>
			</Container>
		);
	}
	// TODO: Add error modal probably
	return <span />;
};

export default DealerView;
