/* eslint-disable arrow-body-style */
/* eslint-disable dot-notation */
// Need assumed values here. This is a report, not a production app.

import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import { Card, Col, Container, Row, Table } from 'react-bootstrap';
import apiCalls from '../PowerShadesAPIFunctions';

type VauluesExpected = {
	SalesOrderAmountOrdered: number;
	InvoiceOrdered: number;
	ProjectedCostOrdered: number;
	ProjectedInvoiceOrdered: number;
	SalesOrderAmountShipped: number;
	InvoiceShipped: number;
	ProjectedCostShipped: number;
	ProjectedInvoiceShipped: number;
	ShadesShipped: number;
	ShadesOrdered: number;
}

type DateRange = {
	Begin: Date;
	End: Date;
	Data: VauluesExpected | null;
};

type TopViewState = {
	dateRanges: DateRange[];
}

const TopView: FC = () => {
	// const isPsAdmin = useIsPowerShadesAdmin();

	const [dataVals, setDataVals] = React.useState<TopViewState>({
		dateRanges: [],
	});

	const countRef = useRef(0);

	const getDataValsRef = useRef(() => {
		return dataVals;
	});

	getDataValsRef.current = () => {
		return dataVals;
	};

	const setDataValsRef = useRef((newData: TopViewState) => {
		setDataVals(newData);
	});

	setDataValsRef.current = (newData: TopViewState) => {
		setDataVals(newData);
	};

	const endOfMonth = (date) => {
		const year = date.getFullYear();
		const month = date.getMonth();

		return new Date(year, month + 1, 0);
	};

	const generateDates: () => DateRange[] = () => {
		const dates: DateRange[] = [];

		// Set up initial values
		const currentYear = new Date().getFullYear();
		const lastYear = currentYear - 1;

		// const cleanTimezone = (date) => {
		// 	const userTimezoneOffset = date.getTimezoneOffset() * 60000;
		// 	const idk = new Date(date.getTime() - userTimezoneOffset);
		// 	return idk;
		// };

		// Set up the date ranges
		for (let i = 1; i <= 12; i++) {
			const lastBegin = new Date(lastYear, i - 1, 1);

			const lastEnd = endOfMonth(lastBegin);
			dates.push({
				Begin: lastBegin, End: lastEnd, Data: null
			});

			const currentBegin = new Date(currentYear, i - 1, 1);
			const currentEnd = endOfMonth(currentBegin);
			dates.push({
				Begin: currentBegin, End: currentEnd, Data: null
			});
		}

		return dates;
	};

	const addDateRangeData = (month: number, year: number, data: VauluesExpected) => {
		const dates = generateDates();
		const dateRange = dates.find((date) => {
			return date.Begin.getMonth() === month && date.Begin.getFullYear() === year;
		}) ?? {
			Begin: new Date(), End: new Date(), Data: null
		};

		if (dateRange) {
			dateRange.Data = data;
		}

		const datavals = getDataValsRef.current();

		const newDataVals = datavals.dateRanges.map((date) => {
			if (date.Begin.getMonth() === month && date.Begin.getFullYear() === year) {
				return dateRange;
			}
			return date;
		});

		countRef.current += 1;

		setDataValsRef.current({
			dateRanges: [
				...newDataVals
			]
		});
	};

	const dateConverter = (date, startOrEnd) => {
		// 2012-04-23T18:25:43.511Z
		const dd1 = String(date.getDate()).padStart(2, "0");
		const mm2 = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
		const yyyy3 = date.getFullYear();
		// T23:59:59
		return startOrEnd === "start" ? `${yyyy3}-${mm2}-${dd1}T00:00:00` : `${yyyy3}-${mm2}-${dd1}T23:59:59`;
	};

	const generateData = async () => {
		const dates = generateDates();

		// Shipped Ordered
		/* {
			"start_date": "2022-07-25T20:02:07.724Z",
			"end_date": "2022-07-25T20:02:07.724Z",
			"sales_person_id": 0,
			"date_location": "string",
			"power_type": "string"
			} */
		dates.forEach(async (dateRange) => {
			if (dateRange.Data !== null) return;

			const goodsOrdered = await apiCalls.RunReport("sales_report", {
				start_date: dateConverter(dateRange.Begin, "start") ?? "",
				end_date: dateConverter(dateRange.End, "end") ?? "",
				sales_person_id: 0,
				date_location: "Ordered"
			});

			const goodsShipped = await apiCalls.RunReport("sales_report", {
				start_date: dateConverter(dateRange.Begin, "start") ?? "",
				end_date: dateConverter(dateRange.End, "end") ?? "",
				sales_person_id: 0,
				date_location: "Shipped"
			});

			const orderedExtras = goodsOrdered?.data?.extras ?? {};
			const shippedExtras = goodsShipped?.data?.extras ?? {};

			const data = {
				SalesOrderAmountOrdered: parseFloat(orderedExtras['Total Recorded Salesorder/Cost Amount'] ?? "0"),
				InvoiceOrdered: parseFloat(orderedExtras['Total Recorded Invoice Amount'] ?? "0"),
				ProjectedCostOrdered: parseFloat(orderedExtras['Total Projected Salesorder/Cost Amount'] ?? "0"),
				ProjectedInvoiceOrdered: parseFloat(orderedExtras['Total Projected Invoice Amount'] ?? "0"),
				SalesOrderAmountShipped: parseFloat(shippedExtras['Total Recorded Salesorder/Cost Amount'] ?? "0"),
				InvoiceShipped: parseFloat(shippedExtras['Total Recorded Invoice Amount'] ?? "0"),
				ProjectedCostShipped: parseFloat(shippedExtras['Total Projected Salesorder/Cost Amount'] ?? "0"),
				ProjectedInvoiceShipped: parseFloat(shippedExtras['Total Projected Invoice Amount'] ?? "0"),
				ShadesShipped: parseFloat(shippedExtras['Number of Shades'] ?? "0"),
				ShadesOrdered: parseFloat(orderedExtras['Number of Shades'] ?? "0")
			};

			addDateRangeData(dateRange.Begin.getMonth(), dateRange.Begin.getFullYear(), data);
		});
		setDataVals({ dateRanges: dates });
	};

	useEffect(() => {
		generateData();
		countRef.current = 0;
	}, []);

	const { dateRanges } = dataVals;

	const earliestDateRange = dateRanges.reduce((dr, curr) => {
		if (curr.Begin > dr.Begin) return dr;
		return curr;
	}, {
		Begin: new Date(),
		End: new Date(),
		Data: null
	});

	const firstYear = earliestDateRange.Begin.getFullYear();
	const secondYear = firstYear + 1;

	const getDataByMonthYear = (month: number, year: number) => {
		const dateRange = dateRanges
			.find((dr) => {
				const monthVal = dr.Begin.getMonth();
				const yearVal = dr.Begin.getFullYear();

				return monthVal === month && yearVal === year;
			});
		return dateRange?.Data ?? {
			SalesOrderAmountOrdered: 0,
			InvoiceOrdered: 0,
			ProjectedCostOrdered: 0,
			ProjectedInvoiceOrdered: 0,
			SalesOrderAmountShipped: 0,
			InvoiceShipped: 0,
			ProjectedCostShipped: 0,
			ProjectedInvoiceShipped: 0,
			ShadesShipped: 0,
			ShadesOrdered: 0
		};
	};

	const rows: string[][][] = [];

	for (let year = firstYear; year <= secondYear; year++) {
		const row: string[][] = [];
		const yearRowItem: string[] = [];
		yearRowItem.push(`${year}`);
		row.push(yearRowItem); 
		for (let month = 1; month <= 12; month++) {
			const dateRange = getDataByMonthYear(month - 1, year);

			const rowItem: string[] = [];

			rowItem.push(`Shades Ordered: ${dateRange.ShadesOrdered} `);
			rowItem.push(`Recorded Salesorder/Cost Amount Ordered: ${dateRange.SalesOrderAmountOrdered} `);
			rowItem.push(`Recorded Invoice Amount Ordered: ${dateRange.InvoiceOrdered} `);
			rowItem.push(`Projected Salesorder/Cost Amount Ordered: ${dateRange.ProjectedCostOrdered} `);
			rowItem.push(`Projected Invoice Amount Ordered: ${dateRange.ProjectedInvoiceOrdered} `);

			rowItem.push(`Shades Shipped: ${dateRange.ShadesShipped} `);
			rowItem.push(`Recorded Salesorder/Cost Amount Shipped: ${dateRange.SalesOrderAmountShipped} `);
			rowItem.push(`Recorded Invoice Amount Shipped: ${dateRange.InvoiceShipped} `);
			rowItem.push(`Projected Salesorder/Cost Amount Shipped: ${dateRange.ProjectedCostShipped} `);
			rowItem.push(`Projected Invoice Amount Shipped: ${dateRange.ProjectedInvoiceShipped} `);

			row.push(rowItem);
		}
		rows.push(row);
	}

	const percentDone = (100 * (countRef.current / 24)).toFixed(2);

	return (
		<Container className="local-bootstrap">
			<Card body>
				<h1>{`Top Level Report ${percentDone}%`}</h1>

				<Table striped bordered responsive>
					<thead>
						<tr>
							<th colSpan={1}>Year</th>
							<th colSpan={1}>January</th>
							<th colSpan={1}>February</th>
							<th colSpan={1}>March</th>
							<th colSpan={1}>April</th>
							<th colSpan={1}>May</th>
							<th colSpan={1}>June</th>
							<th colSpan={1}>July</th>
							<th colSpan={1}>August</th>
							<th colSpan={1}>September</th>
							<th colSpan={1}>October</th>
							<th colSpan={1}>November</th>
							<th colSpan={1}>December</th>
						</tr>
					</thead>
					<tbody>
						{rows.map((row, index1) => {
							const rowIndex = `row-${index1}`;

							return (
								<tr key={rowIndex}>
									{row.map((cell, index2) => {
										const cellIndex = `cell-${index1}-${index2}`;

										return (
											<td key={cellIndex}>
												<Container>
													{cell.map((item, index3) => {
														const itemIndex = `item-${index1}-${index2}-${index3}`;

														const [label = "", val] = item.split(":");
														const valNum = parseFloat(val ?? '0');
														const valFormatted = valNum.toFixed(2);

														const isMoney = !label.includes("Shades");

														return (
															<Row key={itemIndex} className="mb-2">
																<Row className="text-nowrap mb-1">
																	<Col xs={12}>
																		{label}
																	</Col>
																</Row>
																<Row>
																	{val
																		&& (
																			<Col xs={12} className="text-muted">
																				{isMoney ? `$${valFormatted}` : val}
																			</Col>
																		)}
																</Row>
															</Row>
														)
													})}
												</Container>
												{/* <ul>
												{cell.map((item, index3) => {
													const itemIndex = `item-${index1}-${index2}-${index3}`;
													return (
														<li key={itemIndex}>{item}</li>
													);
												})}
											</ul> */}
												{/* {cell} */}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</Table>
			</Card>
		</Container>
	);
};

export default TopView;
