import React, { FC, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Popup from "reactjs-popup";
import apiCalls from "../PowerShadesAPIFunctions";
import Button from "../Parts/Button";
import { useIsEngineering } from "../hooks";
import { useQuoteTypeNameByQuoteId } from "../Store/entities/quotes/hooks";

type ShadeImportProps = {
	quoteId: number;
};

type ShadeImportAllowedFileTypes = "xlsx" | "csv";

const ShadeImport: FC<ShadeImportProps> = ({ quoteId }) => {
	const [shadeImportOpen, setShadeImportOpen] = useState(false);

	const [currentFile, setCurrentFile] = useState<File>();
	const [fileType, setFileType] = useState<ShadeImportAllowedFileTypes>("csv");

	// Temporary gate until the feature has been vetted.
	const isEngineer = useIsEngineering();


	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		const selectedFiles = files as FileList;
		setCurrentFile(selectedFiles?.[0]);
	};

	const handleFileImport = () => {
		if (currentFile) {
			apiCalls.importQuoteShades(quoteId, currentFile).then((_response) => {
				setShadeImportOpen(false);
			});
		}
	};

	const handleFileExport = () => {
		// Call the API to export the shades in current file type.
		apiCalls.getExportedQuoteShades(quoteId, fileType).then((_response) => {
			setShadeImportOpen(false);
		});
	};

	const quoteType = useQuoteTypeNameByQuoteId(quoteId);

	return (
		<>
			{
				isEngineer
				&& (
					<Button
						fullWidth
						onClick={() => setShadeImportOpen(true)}
						color={quoteType}
					>
						Shade Import
					</Button>
				)}
			<Popup
				open={shadeImportOpen}
				onClose={() => setShadeImportOpen(false)}
				modal
				className="mobile-modal"
			>
				<Row>
					<Col>
						<Row>
							<Col>
								<Row>
									<label>File Type</label>
								</Row>
								<Row>
									<Form.Select
										value={fileType}
										onChange={(e) => setFileType(e.target.value as ShadeImportAllowedFileTypes)}
									>
										<option value="xlsx">Excel</option>
										<option value="csv">CSV</option>
									</Form.Select>
								</Row>

							</Col>
						</Row>
						<Row>
							<h2>Shade Import</h2>
							<Button onClick={() => handleFileImport()}>
								Import
							</Button>

						</Row>
						<Row>
							<Col>
								<Row>
									<h2>Shade Export</h2>

								</Row>
								<Row>
									<Button onClick={() => handleFileExport()}>
										Export
									</Button>
								</Row>
							</Col>

							<Col>
								<Row>
									<label className="btn btn-default p-0">
										<input type="file" onChange={handleFileUpload} />
									</label>
								</Row>

							</Col>
						</Row>
					</Col>
				</Row>
			</Popup>
		</>

	);
};

export default ShadeImport;
