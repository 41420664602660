/* eslint-disable react/jsx-no-useless-fragment */
import React, { useMemo } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

import type { TerritoryFilterProps } from './types';
import { loadStatus } from '../../Store/entities/entitiesType';

import { useTerritories, useTerritoriesLoadStatus } from '../../Store/entities/hooks';

const TerritoryFilter = ({ setNewTerritoryIds, selectedTerritoryIds }: TerritoryFilterProps) => {
	const territories = useTerritories();
	const territoriesLoading = useTerritoriesLoadStatus();
	const loading = territoriesLoading <= loadStatus.loading;

	const formattedTerritories = useMemo(
		() => territories
			.filter(({ id }) => !selectedTerritoryIds.includes(id))
			.slice()
			.map(({ id, name }) => ({ id, label: name })),
		[territories, selectedTerritoryIds]
	);

	const setNewTerritoryId = (newDealerId: number) => {
		setNewTerritoryIds([...selectedTerritoryIds, newDealerId]);
	};

	return (
		<Typeahead
			id="territory-filter"
			paginate
			multiple
			positionFixed
			labelKey="label"
			flip
			renderToken={() => <></>}
			placeholder={loading ? "Loading Territories..." : "Select Territories..."}
			options={formattedTerritories}
			isLoading={loading}
			disabled={loading}
			onChange={(selected) => setNewTerritoryId((selected[0] as { id: number }).id)}
			selected={[]}
		/>
	);
};

export default TerritoryFilter;
