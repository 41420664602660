import jsPDF from 'jspdf';
import powershadesLogo from '../../assets/img/logos/primary/Powershades_Logo_400.png';
import api from '../../PowerShadesAPI';

async function downloadInstallerCopy(quote, user, quoteAccessories, quoteAssemblies) {
    // initialize jsPDF
    const doc = new jsPDF(); // eslint-disable-line new-cap

    doc.setFontSize(10);

    // doc.setFontStyle = function () {};

    doc.text('Installer Report', 15, 10);

    const dealerId = quote.dealer_id;

    let dealer = null;

    if (dealerId > 0) {
        dealer = (await api.getDealerMeta(dealerId))?.data?.dealer ?? false;
    }

    const logoImage = new Image();
    logoImage.src = powershadesLogo;

    if (dealer) {
        // TODO: Define getName before use
        let identityHeader = getName(quote, user, dealer);
        // TODO: Define addInformation before use
        identityHeader += addInformation(quote, user, dealer);
        doc.text(identityHeader, 15, 25);
    }

    const headStyles = { fillColor: '#8DC73E' };

    doc.autoTable({
        columns: [{
            dataKey: 'id', header: 'Sold To'
        }],
        body: [['']],
        startY: 20,
        showHead: 'firstPage',
        margin: { left: 107 },
        styles: { overflow: 'hidden' },
        headStyles,
    });
    doc.autoTable({
        columns: [{
            dataKey: 'id', header: 'Install Location'
        }],
        body: [['']],
        startY: doc.lastAutoTable.finalY,
        showHead: 'firstPage',
        margin: { left: 107 },
        styles: { overflow: 'hidden' },
        headStyles,
    });

    // Shades Table
    let tableColumn = [
        ['Name', 'Room', 'Type', 'Motorized', 'Mount', 'Width', 'Height', 'Product'],
    ];
    let tableRows = [];

    const shades = quoteAssemblies;
    // const columnStyles = {
    // 	0: { cellWidth: 100 },
    // 	1: { cellWidth: 80 },
    // 	2: { cellWidth: 80 },
    // 	// etc
    // };

    doc.autoTable({
        columns: [
            { header: 'Date' },
            { header: 'Quote Title' },
            { header: 'Reference Number' },
            { header: 'Quote Number' },
            { header: 'Salesperson' },
        ],
        body: [
            [
                // TODO: Define getCurrentDateString before use
                [getCurrentDateString()],
                [quote.title],
                [quote.reference_number],
                [`${quote.display_id}`],
                [''],
            ],
        ],
        startY: doc.lastAutoTable.finalY + 20,
        showHead: 'firstPage',
        styles: { overflow: 'hidden' },
        headStyles,
        pageBreak: 'avoid',
    });

    if (shades.length > 0) {
        const indoorShades = shades.filter(
            (s) => s.shade_type_id === 1
        );
        const outdoorShades = shades.filter(
            (s) => s.shade_type_id !== 1
        );
        // for each ticket pass all its data into an array
        if (indoorShades) {
            indoorShades.forEach((shade) => {
                let productInfo = shade.header_line;
                productInfo += `-${shade.shades[0].fabric_name}`;
                const isDual = shade.is_dual;
                if (isDual) {
                    const backFab = shade.shades.find(s => s.column_cooridnate == 1)?.fabric_name ?? "";
                    productInfo += `-${backFab}`;
                }
                const isCoupledShade = shade.is_coupled;
                const shadeTotalWidth = shade.total_width;

                const shadeActualWidth = shade.total_width;
                const height = shade.shades[0].height;

                const singleDual = isDual && isCoupledShade ? "Dual Coupled" : 
                                    isDual ? "Dual" :
                                    isCoupledShade ? "Single Coupled" : "Single";

                const isMotorized = shade.shades.some(s => s.shade_type === "motorized");

                const shadeData = [
                    shade.shade_name,
                    shade.room_name,
                    singleDual,
                    isMotorized ? 'Y' : 'N',
                    shade.mount_type,
                    shadeActualWidth,
                    height,
                    productInfo,
                ];
                // push each ticket's info into a row
                tableRows.push(shadeData);
            });
            if (tableRows.length > 0) {
                doc.autoTable({
                    head: [['Indoor Shades']],
                    body: [],
                    startY: doc.lastAutoTable.finalY + 20,
                    headStyles,
                    didDrawPage: (data) => {
                        // ! Do not change these variables to const or let. It'll break. :(
                        // Header
                        doc.setFontSize(20);
                        doc.setTextColor(40);

                        var pageSize = doc.internal.pageSize; // eslint-disable-line
                        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight(); // eslint-disable-line

                        if (logoImage) {
                            doc.addImage(
                                logoImage,
                                'JPEG',
                                (data.settings.margin.left + data.settings.margin.right) / 2
                                + 20
                                + 50,
                                pageHeight - 25,
                                35,
                                18
                            );
                        }

                        // Footer
                        var str = 'Page ' + doc.internal.getNumberOfPages(); // eslint-disable-line
                        // Total page number plugin only available in jspdf v1.0+
                        if (typeof doc.putTotalPages === 'function') {
                            str = str + ' of ' + totalPagesExp; // eslint-disable-line
                        }
                        doc.setFontSize(10);

                        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                        var pageSize = doc.internal.pageSize; // eslint-disable-line
                        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight(); // eslint-disable-line
                    },
                });
            }
        }
        if (outdoorShades) {
            doc.autoTable({
                head: tableColumn,
                body: tableRows,
                startY: doc.lastAutoTable.finalY,
                headStyles,
            });

            tableRows = [];

            outdoorShades.forEach((shade) => {
                let productInfo = shade.header_line;
                productInfo += `-${shade.shades[0].fabric_name}`;

                const shadeData = [
                    shade.shade_name,
                    shade.room_name,
                    "Single",
                    shade.mount_style,
                    shade.shades[0].width,
                    shade.shades[0].height,
                    productInfo,
                ];
                // push each ticket's info into a row
                tableRows.push(shadeData);
            });

            tableColumn = [['Name', 'Room', 'Type', 'Mount', 'Width', 'Height', 'Product']];
            if (tableRows.length > 0) {
                doc.autoTable({
                    head: [['Outdoor Shades']],
                    body: [],
                    startY: doc.lastAutoTable.finalY + 20,
                    headStyles,
                });
                doc.autoTable({
                    head: tableColumn,
                    body: tableRows,
                    startY: doc.lastAutoTable.finalY,
                    headStyles,
                });
            }
            tableRows = [];
            tableColumn = [['Id', 'Quantity', 'Name', 'Description']];
        }
    }

    // page break for accessories
    doc.setPage(doc.lastAutoTable.pageNumber + 1);

    const accessories = quoteAccessories.filter((acc) => acc.quantity !== 0);

    if (accessories) {
        accessories.forEach((acc) => {
            const accessoryData = [acc.id, acc.quantity, acc.name, acc.description];
            // push each ticket's info into a row
            tableRows.push(accessoryData);
        });
        if (tableRows.length !== 0) {
            doc.autoTable({
                head: [['Accessories']], body: [], headStyles
            });
            doc.autoTable({
                head: tableColumn,
                body: tableRows,
                startY: doc.lastAutoTable.finalY,
                pageBreak: 'avoid',
                headStyles,
            });
        }
    }

    const totalPagesExp = '{total_pages_count_string}';

    doc.autoTable({
        columns: [{ header: 'Notes' }],
        body: [[''], ['']],
        startY: doc.lastAutoTable.finalY + 20,
        showHead: 'firstPage',
        styles: { overflow: 'hidden' },
        headStyles,
        pageBreak: 'avoid',
    });

    doc.autoTable({
        columns: [{ header: 'Installed By' }],
        body: [['']],
        startY: doc.lastAutoTable.finalY,
        showHead: 'firstPage',
        margin: { right: 107 },
        styles: { overflow: 'hidden' },
        headStyles
    });
    doc.autoTable({
        columns: [{ header: 'Approved By' }],
        body: [['']],
        startY: doc.lastAutoTable.finalY,
        showHead: 'firstPage',
        margin: { right: 107 },
        styles: { overflow: 'hidden' },
        headStyles,
        didDrawPage: () => {
            // ! Do not change these variables to const or let. It'll break. :(
            // Header
            doc.setFontSize(20);
            doc.setTextColor(40);

            var pageSize = doc.internal.pageSize; // eslint-disable-line
            var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight(); // eslint-disable-line

            // Footer
            var str = 'Page ' + doc.internal.getNumberOfPages(); // eslint-disable-line
            // Total page number plugin only available in jspdf v1.0+
            if (typeof doc.putTotalPages === 'function') {
                str = str + ' of ' + totalPagesExp; // eslint-disable-line
            }
            doc.setFontSize(10);

            // jsPDF 1.4+ uses getWidth, <1.4 uses .width
            var pageSize = doc.internal.pageSize; // eslint-disable-line
            var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight(); // eslint-disable-line
        },
    });

    doc.save(`${quote.display_id} InstallerReport.pdf`);
}

function getCurrentDateString() {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();

    today = `${mm}/${dd}/${yyyy}`;
    return today;
}

function getName( dealer) {
    return dealer.name;
}

function addInformation( dealer) {
    let baseInfo = '';
    if (dealer.address) {
        baseInfo = `\n${dealer.address}`;
        baseInfo += `\n${dealer.city}, ${dealer.state} ${dealer.zip}`;
        baseInfo += `\nPhone: ${dealer.phone}`;
        baseInfo += `\nEmail: ${dealer.email}`;
    }
    return baseInfo;
}

export default downloadInstallerCopy;