import type {
	AssemblyFieldCutBooleans,
	PropertyDisplayCategoryNames,
	PropertyDisplayNames,
	ShadePart,
} from "../../../powershadesApiTypeExtensions";
import type {  PreviousShadeIdObject, QuoteTypeNames } from "../../../powershadesApiTypes";
import type { AssemblyShadePayloadShade } from "../../../powershadesApiTypeExtensions";

export type ShadeParts = {
	[category: string]: ShadePart[];
};

export type ServiceAssemblyProps = {
	sequenceId: number;
	quoteId: number;
	SetRecipeSku: (sku: string) => void;
	quoteType: QuoteTypeNames;
	previousQuoteId?: number;
};

export type NameToFunctionMapType = {
	[key in PropertyDisplayNames]: (value: boolean) => void;
};

type ExcludedCategories = "Service" | "Crowns and Drives";

type DisplayCategoryNamesToUse = Exclude<PropertyDisplayCategoryNames, ExcludedCategories>;

export type NameToIconMapType = {
	[key in DisplayCategoryNamesToUse]: JSX.Element;
};

export type NameToStateMapType = {
	[key in PropertyDisplayNames]: boolean;
};

export type PartsToDisplayCategoriesType = {
	[key in DisplayCategoryNamesToUse]: ShadePart[];
};

export type CutNameProperties = "is_header_extrusion_to_be_cut"
	| "is_tube_to_be_cut"
	| "is_hembar_to_be_cut"
	| "is_sill_channels_to_be_cut"
	| "is_side_channels_to_be_cut"
	| "is_fabric_to_be_cut";
	
export type CutOptionDisplayNames =
	| "Tube"
	| "Header Extrusion"
	| "Side Channels"
	| "Sill Channel"
	| "Hembar"
	| "Fabric";

export const cutNamesToBackEndNamesMap: {
	[key in CutOptionDisplayNames]: AssemblyFieldCutBooleans;
} = {
	Tube: "IsTubeToBeCut",
	"Header Extrusion": "IsHeaderExtrusionToBeCut",
	"Side Channels": "IsSideToBeCut",
	"Sill Channel": "IsSillToBeCut",
	Hembar: "IsHembarToBeCut",
	Fabric: "IsFabricToBeCut",
} as const;

export const cutNamesToPropertiesNameMap: {
	[key in CutOptionDisplayNames]: CutNameProperties;
} = {
	Tube: "is_tube_to_be_cut",
	"Header Extrusion": "is_header_extrusion_to_be_cut",
	"Side Channels": "is_side_channels_to_be_cut",
	"Sill Channel": "is_sill_channels_to_be_cut",
	Hembar: "is_hembar_to_be_cut",
	Fabric: "is_fabric_to_be_cut",
} as const;

export const cutPropertiesToDisplayNamesMap: {
	[key in CutNameProperties]: CutOptionDisplayNames;
} = {
	is_tube_to_be_cut: "Tube",
	is_header_extrusion_to_be_cut: "Header Extrusion",
	is_side_channels_to_be_cut: "Side Channels",
	is_sill_channels_to_be_cut: "Sill Channel",
	is_hembar_to_be_cut: "Hembar",
	is_fabric_to_be_cut: "Fabric",
} as const;

export const cutPropertiesToBackEndNamesMap: {
	[key in CutNameProperties]: AssemblyFieldCutBooleans;
} = {
	is_tube_to_be_cut: "IsTubeToBeCut",
	is_header_extrusion_to_be_cut: "IsHeaderExtrusionToBeCut",
	is_side_channels_to_be_cut: "IsSideToBeCut",
	is_sill_channels_to_be_cut: "IsSillToBeCut",
	is_hembar_to_be_cut: "IsHembarToBeCut",
	is_fabric_to_be_cut: "IsFabricToBeCut",
} as const;

export type DisplayNameToCutIconType = {
	[key in CutOptionDisplayNames]: JSX.Element;
};

export type CutDisplayNameToStateType = {
	[key in CutOptionDisplayNames]: boolean;
};

export type CutOption = {
	displayName: CutOptionDisplayNames;
	backEndName: AssemblyFieldCutBooleans;
	propertyName: CutNameProperties;
	state: boolean;
};

export type ShadeAssemblyRowData = {
	shades: AssemblyShadePayloadShade[];
	cutOptions: CutOption[];
	rowCoordinate: number;
	singleOrDual: string;
};

export type CutDisplayNameToFunctionType = {
	[key in CutOptionDisplayNames]: (value: boolean) => void;
};

export type PreviousShadeIdObjectWithEditBoolean = PreviousShadeIdObject & {
	editing: boolean;
};
