import { faHome, faSun } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Vr from '../../Parts/VerticalRule';
import { Col, Row } from 'react-bootstrap';

const IsOutdoorShadeSelector = ({ selectOutdoor, selectIndoor, quoteType, selectLighting, noOutdoor = false }) => (
	<Col>
		<Row>
			<div id="indoor-outdoor-selector">
				<button type="button" className={`indoor-outdoor-selector ${quoteType}`} onClick={selectIndoor}>
					<FontAwesomeIcon icon={faHome} size="2x" />
					<span>Indoor Shade</span>
				</button>
{!noOutdoor &&[(
				<Vr style={{ backgroundColor: 'gray', height: '100px' }} />
			),(
				<button type="button" className={`indoor-outdoor-selector ${quoteType}`} onClick={selectOutdoor}>
					<FontAwesomeIcon icon={faSun} size="2x" />
					<span>Outdoor Shade</span>
				</button>)]
				}
			</div>
		</Row>
		{/* <Row>
			<button onClick={selectLighting} type="button" className={`indoor-outdoor-selector ${quoteType}`}  >
				PS Lighting
			</button>
		</Row> */}
	</Col>
);

export default IsOutdoorShadeSelector;
