import React, { useEffect, useState } from "react";

import { Typeahead } from "react-bootstrap-typeahead";

import { DistributorStore, DistributorTerritory, Territory } from "../powershadesApiTypes";
import Modal from "../Parts/Modal";

type EditDistributorTerritoriesProps = {
	currentDistributorTerritories: DistributorTerritory[];
	show: boolean;
	setShow: (show: boolean) => void;
	allowedDistributorTerritories: DistributorTerritory[];
	distributors: DistributorStore[];
	territories: Territory[];
	onSave: (distributorTerritories: DistributorTerritory[]) => void;
	initialDistributorId: number;
};

const EditDistributorTerritories = ({
	show,
	setShow,
	allowedDistributorTerritories,
	currentDistributorTerritories,
	distributors,
	territories,
	initialDistributorId
}: EditDistributorTerritoriesProps) => {
	const [, setDistInputValue] = useState("");
	const [, setTerrInputValue] = useState("");
	const [currentDistributorId, setCurrentDistributorId] = useState(initialDistributorId);
	const [currentTerritoryIds, setCurrentTerritoryIds] = useState<number[]>([]);

	useEffect(() => {
		setCurrentDistributorId(initialDistributorId);
	}, [initialDistributorId]);

	useEffect(() => {
		setCurrentTerritoryIds(
			currentDistributorTerritories
				.filter((t) => t.distributor_id === currentDistributorId)
				.map((t) => t.territory_id)
		);
	}, [currentDistributorId, currentDistributorTerritories]);

	const allowedDistributorOptions = distributors
		.filter((di) => allowedDistributorTerritories.some((dt) => dt.distributor_id === di.id))
		.map((group) => ({
			value: group.id,
			label: group.name,
			id: group.id
		}));

	const allowedTerritoryOptions = currentDistributorTerritories
		.filter((t) => t.distributor_id === currentDistributorId)
		.map((t) => {
			const terr = territories.find((te) => te.id === t.territory_id);

			return {
				value: terr?.id ?? 0,
				label: terr?.name ?? "Unknown",
				id: terr?.id ?? 0
			};
		});

	// If there is more than one territory the distributor could be part of when attached to this dealer,
	// then we need to show the territory select.
	const needsTerritorySelected =
		currentDistributorId !== 0 &&
		allowedDistributorTerritories.filter((t) => t.distributor_id === currentDistributorId).length >
			1;

	const currentTerritoryOptions = currentTerritoryIds.map((t) => {
		const terr = territories.find((te) => te.id === t);

		return {
			value: terr?.id ?? 0,
			label: terr?.name ?? "Unknown",
			id: terr?.id ?? 0
		};
	});

	return (
		<Modal
			show={show}
			setShow={(e) => {
				setShow(e);
			}}
		>
			<h1>Edit Distributor Territories</h1>
			<Typeahead
				id="ediy-distributor-territories"
				options={allowedDistributorOptions}
				selected={allowedDistributorOptions.filter(option => option.id === currentDistributorId)}
				onInputChange={(newValue: string) => {
					setDistInputValue(newValue);
				}}
				// value={{ id: currentDistributorId }}
			/>

			{needsTerritorySelected && (
				<Typeahead
					id="edit-distributor-territories"
					multiple
					options={allowedTerritoryOptions}
					onChange={(e) => console.log(e)}
					selected={currentTerritoryOptions}
					onInputChange={(newValue: string) =>{
						setTerrInputValue(newValue);
					}}
				/>
			)}
		</Modal>
	);
};

export default EditDistributorTerritories;
