//import React, { useEffect, Dispatch, ReactNode, SetStateAction, useState } from "react";
import React, { Dispatch, ReactNode, SetStateAction } from "react";

import Popup from "reactjs-popup";
import PowerShadesTypeahead from "../Parts/PowerShadesTypeahead";
import { selectIndoorAssembliesByQuoteId } from "../Store/entities/assemblies/index";
import { IndoorShadeConfiguration, IndoorShadeOptionsDualDict, IndoorShadeOptionsSingleDict } from "../Store/entities/assemblies/types";
import FractionField from "./NewOptionFields/FractionField";
import QuoteAssemblyLockSelect from "./QuoteAssemblyLockSelect";
import { UseAssembly } from "../Store/entities/assemblies/hooks";
import { UsePortalSelector } from "../Store/index";
import FabricSelectField from "./NewOptionFields/FabricSelectField.js";
import { useQuoteById } from "../Store/entities/quotes/hooks";
import ShadeActions from "./NewOptionFields/ShadeActions";
import QuoteData from "./NewItems/QuoteData.js";
import TextField from "./NewOptionFields/TextField.js";
import { useCurrentIndoorShadeOptions } from "../Store/entities/assemblies/IndoorHooks.ts/index";
import { useIsPsAdmin } from "../hooks.js";
import { useErrorsBySeqAndQuoteId } from "../Store/entities/assemblies/errors/hooks";
import PricingUI from "../PricingUI";
import { useQuoteCombination } from "../Store/entities/combinations/hooks";

//import { FabricViewModel} from "../powershadesApiTypes";
//import apiCalls from "../PowerShadesAPIFunctions";


type IndoorRowProps = {
	quoteId: number;
	sequenceId: number;
	unlockShadeBySequenceId: (sequenceId: number) => void;
	quoteData: QuoteData;
	FCState: [any[], Dispatch<SetStateAction<any[]>>];
};

const IndoorTableRow = ({ quoteId, sequenceId, unlockShadeBySequenceId, quoteData }: IndoorRowProps) => {
	
	// Gio Test Add
	// console.log("************** Rendering IndoorTableRow", {
    //     quoteId,
    //     sequenceId,
    //     quoteData,
    // });
	
	const assembly = UseAssembly(quoteId, sequenceId);
	const psIndoorShadeOptions = useCurrentIndoorShadeOptions(quoteId)(sequenceId);

	const indoorAssemblies = UsePortalSelector(selectIndoorAssembliesByQuoteId(quoteId));
	const isAnyIndoorDual = indoorAssemblies.some((a) => a.is_dual);

	const errors = useErrorsBySeqAndQuoteId(quoteId, sequenceId);

	if (errors?.length > 0) {

	}

	const { editable } = useQuoteById(quoteId) ?? { editable: false };
	const {
		TotalWidth,
		Height,
		RoomName,
		ShadeName,
		ShadeConfiguration
	} = psIndoorShadeOptions;

	const isPsAdmin = useIsPsAdmin();
	if (!assembly) return null;

	const setNewHeight = (newHeight) => {
		Height.setValue(newHeight);
	};

	const setNewWidth = (newWidth) => {
		TotalWidth.setValue(newWidth);
	};

	const setNewShadeName = (newShadeName) => {
		ShadeName.setValue(newShadeName);
	};

	const setNewRoomName = (newRoomName) => {
		RoomName.setValue(newRoomName);
	};

	const assemblyErrorMessages = `${assembly.error_messages ?? ""}${errors.reduce((acc, error) => `${acc} ${error.message}`, "")}`;

	const isErrored = (errors.length > 0) || (assembly.error_messages !== null && assembly.error_messages?.match(/^ *$/) === null && assembly.msrp === 0);
	const errored = `${isErrored ? "errored" : ""}`;

	const fabricCells: ReactNode[] = [];

	//const [allowedIndoorFabrics, setAllowedIndoorFabrics] = useState<FabricViewModel[]>([]);

	// Fetch allowed indoor fabrics from the backend
	// useEffect(() => {
	//  const fetchAllowedIndoorFabrics = async () => {
	// 	 try {
	// 		 const response = await apiCalls.getFabricIndoorAllowedList(quoteId);
		
	//  setAllowedIndoorFabrics(response.data.fabrics); // Store the fetched fabrics in state
	// 	 } catch (error) {
	// 		 console.error("Error fetching allowed indoor fabrics:", error);
	// 	 }
	//  };

	//  fetchAllowedIndoorFabrics(); // Llama a la función aquí
	// }, [quoteId]);

	if (assembly.is_dual) {
		const {
			FrontFabric,
			BackFabric
		} = psIndoorShadeOptions as IndoorShadeOptionsDualDict;


		
			// Combine fetched allowed fabrics with the indoor fabrics
			// Mapping to FabricStore when is FabricViewModel
			// const combinedFrontIndoorFabrics = [
			// 	...FrontFabric.options ?? [],
			// 	...allowedIndoorFabrics.map(fabric => ({
			// 		isActive: true,
			// 		label: fabric.name,
			// 		title: fabric.name,
			// 		value: fabric.name
			// 	}))
			// ].filter((fabric, index, self) =>
			// 	index === self.findIndex(f => f.value === fabric.value) // Filtra duplicados por el campo "value"
			// );

			// Combine fetched allowed fabrics with the indoor fabrics
			// Mapping to FabricStore when is FabricViewModel
			// const combinedBackIndoorFabrics = [
			// 	...BackFabric.options ?? [],
			// 	...allowedIndoorFabrics.map(fabric => ({
			// 		isActive: true,
			// 		label: fabric.name,
			// 		title: fabric.name,
			// 		value: fabric.name
			// 	}))
			// ].filter((fabric, index, self) =>
			// 	index === self.findIndex(f => f.value === fabric.value) // Filtra duplicados por el campo "value"
			// );


		fabricCells.push(<td colSpan={1}>
			<FabricSelectField
				className="-cell"
				style={{ width: "15em", minWidth: "100%" }}
				disabled={!editable || !assembly.unlocked}
				value={FrontFabric.getValue() ?? ""}
				choices={FrontFabric.options}
				//choices={combinedFrontIndoorFabrics}
				canceled={FrontFabric.clearValue}
				onChange={(newFabricName) => {
					newFabricName
						? FrontFabric.setValue(newFabricName)
						: FrontFabric.clearValue();
				}}
				onBlur={() => {
					console.log("blur");
				}}
			/>
		</td>);

		fabricCells.push(<td colSpan={1}>
			<FabricSelectField
				className="-cell"
				style={{ width: "15em", minWidth: "100%" }}
				disabled={!editable || !assembly.unlocked}
				value={BackFabric.getValue() ?? ""}
				choices={BackFabric.options}
				//choices={combinedBackIndoorFabrics}
				canceled={BackFabric.clearValue}
				onChange={(newFabricName) => {
					BackFabric.setValue(newFabricName);
				}}
				onBlur={() => {
					console.log("blur");
				}}
			/>
		</td>);


		// // Gio Test Add
		// console.log("+++++++++ Dual Current QuoteID:", quoteId);
		// console.log("+++++++++ Dual IIndoorTableROW ***** QuoteID:", quoteId);
		// console.log("+++++++++ Dual IIndoorTableROW ***** allowedIndoorFabrics:", allowedIndoorFabrics);
		// console.log("+++++++++ Dual IIndoorTableROW ***** FrontFabric.options:", FrontFabric.options);
		// console.log("+++++++++ Dual IIndoorTableROW ***** BackFabric.options:", BackFabric.options);
		// console.log("+++++++++ Dual IIndoorTableROW ***** CombinedFrontIndoorFabrics:", combineFrontdIndoorFabrics);
		// console.log("+++++++++ Dual IIndoorTableROW ***** CombinedBackIndoorFabrics:", combineBackdIndoorFabrics);



	} else {
		const { Fabric } = psIndoorShadeOptions as IndoorShadeOptionsSingleDict;

			// Combine fetched allowed fabrics with the indoor fabrics
			// Mapping to FabricStore when is FabricViewModel
			// const combineIndoorFabrics = [
			// 	...Fabric.options ?? [],
			// 	...allowedIndoorFabrics.map(fabric => ({
			// 		isActive: true,
			// 		label: fabric.name,
			// 		title: fabric.name,
			// 		value: fabric.name
			// 	}))
			// ].filter((fabric, index, self) =>
			// 	index === self.findIndex(f => f.value === fabric.value) // Filtra duplicados por el campo "value"
			// );


		// // Gio Test Add
		// 	console.log("+++++++++ Single Current QuoteID:", quoteId);
		// 	console.log("+++++++++ Single IIndoorTableROW ***** QuoteID:", quoteId);
		// 	console.log("+++++++++ Single IIndoorTableROW ***** allowedIndoorFabrics:", allowedIndoorFabrics);
		// 	console.log("+++++++++ Single IIndoorTableROW ***** FrontFabric.options:", Fabric.options);
		// 	console.log("+++++++++ Single IIndoorTableROW ***** CombinedIndoorFabrics:", combineIndoorFabrics);


		fabricCells.push(<td colSpan={1}>
			<FabricSelectField
				className="-cell"
				style={{ width: "15em", minWidth: "100%" }}
				disabled={!editable || !assembly.unlocked}
				value={Fabric.getValue()}
				choices={Fabric.options}
				//choices={combineIndoorFabrics}
				canceled={Fabric.clearValue}
				onChange={(newFabricName) => {
					Fabric.setValue(newFabricName);
				}}
				onBlur={() => {
					console.log("blur");
				}}
			/>
		</td>);

		if (isAnyIndoorDual) {
			fabricCells.push(<td colSpan={1}>
				N/A
			</td>);
		}
	}

	let fasciaClass = '';

	const FCState = useQuoteCombination(quoteId)?.Fascias ?? [];

	const fasciaSet = FCState.find((fasciaSet) => fasciaSet.assemblies.some((a) => a.sequence_id === sequenceId));
	let first = true;

	if (fasciaSet?.assemblies) {
		const max = fasciaSet.assemblies.length;
		let increment = 1;

		for (const innerIndex in fasciaSet.assemblies) {
			const assembly = fasciaSet.assemblies[innerIndex];
	
			if (assembly?.sequence_id == sequenceId) {
				if (first && increment == max) {
					fasciaClass = 'fascia-top fascia-bottom';
				} else if (first) {
					fasciaClass = 'fascia-top';
				} else if (increment == max) {
					fasciaClass = 'fascia-bottom';
				} else {
					fasciaClass = 'fascia-middle';
				}
			}
			first = false;
			increment += 1;
		}

	};

	

	

	return (
		<tr className={`${errored} ${fasciaClass} `} key={assembly.sequence_id}>
			<td colSpan={1} className={`${fasciaClass ? `${fasciaClass} left-most-cell` : ""}`}>
				<QuoteAssemblyLockSelect quoteId={quoteId} sequenceId={sequenceId} unlockShadeBySequenceId={unlockShadeBySequenceId} />
			</td>
			<td colSpan={1}>
				<TextField
					disabled={!editable || !assembly.unlocked}

					value={RoomName.getValue() ?? ""}
					onBlur={setNewRoomName}
					handleEnter={setNewRoomName}
				/>
			</td>
			<td colSpan={1}>
				<TextField
					disabled={!editable || !assembly.unlocked}
					value={ShadeName.getValue() ?? ""}
					onBlur={setNewShadeName}
					handleEnter={setNewShadeName}
				/>
			</td>

			<td colSpan={1}>
				<PowerShadesTypeahead
					disabled={!editable || !assembly.unlocked}
					id="shade-configuration"
					options={ShadeConfiguration.options ?? []}
					selected={[ShadeConfiguration.getSelectedOption()]}
					positionFixed
					onChange={(selected) => {
						if (selected.length === 0) {
							ShadeConfiguration.clearValue();
						}
						const selectedObj = selected && selected[0] ? selected[0] as {
							value: IndoorShadeConfiguration;
						} : { value: "" as IndoorShadeConfiguration };
						ShadeConfiguration.setValue(selectedObj.value);
					}}
					className="text-center "
					style={{ width: "10em", minWidth: "100%" }}
				/>
			</td>
			{fabricCells}
			<td colSpan={1}>
				<FractionField
					key={`width-${assembly.sequence_id}`}
					disabled={!editable || !assembly.unlocked}
					value={TotalWidth.getValue() ?? 0.0}
					onBlur={setNewWidth}
					minValue={TotalWidth.minValue ?? 0.0}
					maxValue={TotalWidth.maxValue ?? 0.0}
					varName="width"
					width={10}
				/>
			</td>
			<td colSpan={1}>
				<FractionField
					key={`height-${assembly.sequence_id}`}
					disabled={!editable || !assembly.unlocked}
					value={Height.getValue() ?? 0.0}
					onBlur={setNewHeight}
					minValue={Height.minValue ?? 0.0}
					maxValue={Height.maxValue ?? 0.0}
					varName="height"
					width={10}
				/>
			</td>
			<td colSpan={1}  >
				{
					isErrored ? (
						<Popup
							trigger={<span>Error</span>}
							position="right center"
							on="hover"
						>
							<div>
								<h6>{assemblyErrorMessages}</h6>
							</div>
						</Popup>
					) : <PricingUI msrp={assembly.msrp} quoteId={quoteId} assembly={assembly} />
				}

				{/*
                <td colSpan={1}>
				{
					isErrored ? (
						<Popup
							trigger={<span>Error</span>}
							position="right center"
							on="hover"
						>
							<div>
								<h6>{assembly.error_messages}</h6>
							</div>
						</Popup>
					) :
				}
                
			</td>
                */}

			</td>
			{
				isPsAdmin && <td colSpan={1}>
					{assembly.shipping}
				</td>
			}
			<td colSpan={1} className={`${fasciaClass ? `${fasciaClass} right-most-cell` : ""}`}>
				<ShadeActions
					quoteId={quoteId}
					sequenceId={assembly.sequence_id}
					quoteData={quoteData}
				/>
			</td>

		</tr>);
};

export default IndoorTableRow;
