import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenRuler } from '@fortawesome/free-solid-svg-icons';

import type { ReworkIconProps } from './types';

const ReworkIcon = ({
	size = '1x', colorOverride = '', className = ''
}: ReworkIconProps) => (
	<FontAwesomeIcon
		icon={faPenRuler}
		size={size}
		className={`${className} ${colorOverride && `text-${colorOverride}`}`}
	/>
);

export default ReworkIcon;
