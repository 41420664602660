import getSymbolFromCurrency from 'currency-symbol-map';
import Money from './Money.js';
import { selectQuoteAccessories } from '../../Store/entities/accessories/hooks';
import { selectQuoteFromId } from '../../Store/entities/quotes/hooks';
import { selectTerritoryByIdAndDispatch, useTerritoryById } from '../../Store/entities/hooks';

class QuoteMoney {
	constructor({ quoteLevelVariables, userData }) {
		this.Accessories = [];
		this.AccessoryTotals = [];
		this.AccessoryShipping = [];
		this.FabricSamples = [];
		this.ShadeMSRP = [];
		this.ShadeShipping = [];

		// This is for state changes outside the quote items
		this.moneyFunctions = {};



		this.quoteLevelVariables = quoteLevelVariables;
		const isPurchasing = userData?.roles.some(r => r.role_name.includes("purchasing")) ?? false;

		const msrpMultiplier = this.multipliers?.find((m) => m.name === 'MSRP');
		const buyMultiplier = this.multipliers?.find((m) => m.name === 'buy');

		if (userData) {
			this.ApplyMSRP(isPurchasing ? buyMultiplier : msrpMultiplier);
		}


		this.currentMultiplierName = 'MSRP';

		this.RemoveSequenceIds = this.RemoveSequenceIds.bind(this);
		this.ApplyMSRPByName = this.ApplyMSRPByName.bind(this);

		this.UpdateIso = [];

		this.Reprice = this.Reprice.bind(this);
	}

	Load({
		money, shipping, msrp, currencies
	}) {
		this.multipliers = money.multipliers;
		this.shipping = shipping;
		this.msrp = msrp;
		this.currencies = currencies;
		this.coupons = money.coupons;

		const quoteVars = this.quoteLevelVariables();

		const { userData } = quoteVars;

		const isPurchasing = userData.roles.some(r => r.role_name.includes("purchasing"));

		const msrpMultiplier = money.multipliers.find((m) => m.name === 'MSRP');
		const buyMultiplier = money.multipliers.find((m) => m.name === 'buy');

		this.ApplyMSRP(isPurchasing ? buyMultiplier : msrpMultiplier);
	}

	ApplyMSRPByName(name, applyChanges = false) {
		let multiplier = this.multipliers.find((m) => m.name == name);

		if (!multiplier) {
			multiplier = this.multipliers.find((m) => name?.toLowerCase().includes(m.name?.toLowerCase()));
		}

		if (!multiplier) {
			multiplier = this.multipliers.find((m) => name?.toLowerCase().includes(m.label?.toLowerCase()))
				?? this.multipliers.find((m) => m.label.toLowerCase() == name.toLowerCase());
		}

		const isNameMsrpDash = name.includes('MSRP-');

		if (!multiplier && isNameMsrpDash) {
			const qlVar = this.quoteLevelVariables();
			const quote = selectQuoteFromId(qlVar.quoteSub.ID)(qlVar.store.getState())
			const territory = selectTerritoryByIdAndDispatch(quote?.territory_id ?? 0)(qlVar.store.getState())

			multiplier = {
				name: name,
				multiplier: 1,
				currency_multiplier: territory.msrp_multiplier,
				currency: territory.iso_4217_currency_code_sell
			};

			this.multipliers = [...this.multipliers, multiplier];
		}

		this.ApplyMSRP(multiplier, applyChanges);
	}

	ApplyMSRP(currentMultiplier, applyChanges = false) {
		const self = this;

		if (currentMultiplier) {
			this.currentMultiplierName = currentMultiplier.name;
		}

		this.currentMultiplier = currentMultiplier ? currentMultiplier.multiplier : 1;
		this.currencyMultiplier = currentMultiplier ? currentMultiplier.currency_multiplier : 1;
		this.symbol = currentMultiplier && currentMultiplier.currency ? getSymbolFromCurrency(currentMultiplier.currency) : '$';
		this.iso = currentMultiplier ? currentMultiplier.currency : 'USD';
		this.UpdateIso && this.UpdateIso.forEach((u) => u(this.iso));

		if (self.moneyFunctions.UpdateIso) {
			self.moneyFunctions.UpdateIso.forEach((u) => u(this.iso));
		}

		if (applyChanges) {
			const { multiplier, currency_multiplier } = currentMultiplier;

			this.AllItems.forEach((money) => {
				money.UpdateMultipliers(multiplier, currency_multiplier);
				money.UpdateSymbol(this.symbol);
			});

			const finalItem = self.quoteLevelMSRP(currentMultiplier.name);
			const printItem = self.quoteLevelMSRP('MSRP');

			if (self.moneyFunctions.setQuoteTotals) {
				self.moneyFunctions.setQuoteTotals.forEach((f) => f(finalItem));
			}

			if (self.moneyFunctions.setPrintTotals) {
				self.moneyFunctions.setPrintTotals.forEach((f) => f(printItem));
			}
		}

		this.UI && this.UI.reRenderTable();
	}

	get Symbol() {
		return this.symbol ?? '$';
	}

	get ISO() {
		return this.iso ?? 'USD';
	}

	Set({ UI, Data }) {
		this.UI = UI;
		this.Data = Data;
	}

	Reprice(data, refreshFrontPage) {
		const self = this;
		if (!data) return;

		const { quote } = data;
		const { pricing } = data;
		const taxMessage = data.taxes;

		if (quote?.MSRP ?? quote?.msrp) {
			this.msrp = quote?.MSRP ?? quote?.msrp;
		}

		// Update Assemblies
		pricing?.forEach((p) => {
			const assembly = self.Data.shadeAssemblies.Items.find((sa) => sa.sequence_id == p.sequenceId);
			const newAssembly = quote.shadeAssemblies.Items.find((sa) => sa.sequence_id == p.sequenceId);

			const lastTimePriced = quote.shadeAssemblies.Items.find((sa) => sa.sequence_id == p.sequenceId)?.last_time_priced;

			const pricingShades = p.shades;

			if (!assembly) return;

			if (p.errors) {
				assembly.errors = p.errors;
				assembly.error_messages = p.errors;
			} else {
				assembly.errors = null;
				assembly.error_messages = null;
			}

			const { msrp } = p;
			const { shipping } = p;
			const { cost } = p;

			assembly.msrp = msrp;
			assembly.shipping_price = shipping;
			assembly.cost = cost;
			assembly.will_be_seamed = p.will_be_seamed;
			assembly.has_sag_warning = p.has_sag_warning;
			assembly.hidden_shipping = newAssembly.hidden_shipping;
			assembly.quote_id = newAssembly.quote_id;
			assembly.RecipeSku = newAssembly.RecipeSku;

			if (assembly.shades) {
				assembly.shades.forEach((s) => {
					s.quote_id = assembly.quote_id;
					if (!pricingShades || !Array.isArray(pricingShades)) return;

					const pricingShade = pricingShades.find((ps) => ps.row_coordinate == s.row_coordinate && ps.column_coordinate == s.column_coordinate);

					if (pricingShade) {
						s.bearing_pin_type = pricingShade.bearing_pin_type;
						s.motor = pricingShade.motor;
						s.tube_sku = pricingShade.tube_sku;
						s.roll_diameter = pricingShade.roll_diameter;
						s.clearance_diameter = pricingShade.clearance_diameter;
						s.deflection = pricingShade.deflection;
						s.torque_needed = pricingShade.torque_needed;
						s.tube = pricingShade.tube;
						s.tube_name = pricingShade.tube_name;
					}
				});
			}

			// Bearing Pin
			// motor
			// tube sku
			// roll diameter
			// clearance diameter
			// deflection
			// torque needed
			// tube

			if (lastTimePriced) {
				assembly.last_time_priced = lastTimePriced;
			}

			const sequenceId = assembly.sequence_id;

			self.ShadeMSRP.find((s) => s.sequenceId == sequenceId).UpdateAmount(msrp);
			self.ShadeShipping.find((s) => s.sequenceId == sequenceId).UpdateAmount(shipping);

			if (refreshFrontPage) {
				this.Data.FrontPageUpdate
					&& this.Data.FrontPageUpdate[sequenceId]
					&& this.Data.FrontPageUpdate[sequenceId].row
					&& this.Data.FrontPageUpdate[sequenceId].row(msrp <= 0);
			}

			self.Data.UpdateAssemblyMoney(sequenceId, msrp, shipping);
		});

		// Update Accessories
		try {
			// quote?.accessories?.Items?.forEach((a) => {
			// 	const acccShip = self.AccessoryShipping.find((as) => a.accessory_id == as.sequenceId);
			// 	acccShip && acccShip.UpdateAmount(a.shipping);

			// 	const acccMsrp = self.AccessoryMoney.find((as) => a.accessory_id == as.Id);
			// 	acccMsrp && acccMsrp.cost && acccMsrp.cost.UpdateAmount(a.msrp);

			// 	const accTotal = self.AccessoryTotals.find((as) => a.accessory_id == as.sequenceId);
			// 	accTotal && accTotal.UpdateAmount(a.msrp * a.quantity);
			// });
		} catch {
			ROOT.alert({
				quickFormat: 'error',
				message: 'There was an error updating the accessories. Please refresh the page and try again.',
			});
			throw new Error('Error updating accessories');
		}

		// Update HWParts
		try {
			quote?.hwParts?.forEach((a, index) => {
				const oldHwReqs = self.Data.hwRequestItems;

				const oldHwReq = oldHwReqs.find(hw => hw.line_number == a.line_number);

				if (oldHwReq) {
					const newHwReq = { ...oldHwReq, ...a };
					oldHwReqs[index] = newHwReq;
				}
			});
		} catch {
			ROOT.alert({
				quickFormat: 'error',
				message: 'There was an error updating the accessories. Please refresh the page and try again.',
			});
			throw new Error('Error updating accessories');
		}

		self.msrp = quote?.MSRP;
		self.money = quote?.money;
		self.coupons = quote?.money.coupons;

		const finalItem = self.quoteLevelMSRP(this.currentMultiplierName, taxMessage);
		const printItem = self.quoteLevelMSRP('MSRP', taxMessage);

		if (self.moneyFunctions.setQuoteTotals) {
			self.moneyFunctions.setQuoteTotals.forEach((f) => f(finalItem));
		}

		if (self.moneyFunctions.setPrintTotals) {
			self.moneyFunctions.setPrintTotals.forEach((f) => f(printItem));
		}
		quote?.shadeAssemblies?.Items?.forEach(this.Data.checkMotorChange);
		// Loop through shades, any with succesful prices need to be set to no errors

		this.quoteLevelVariables().checkExpiration();
		this.UI.reRenderTable();
	}

	quoteLevelMSRP(current = 'MSRP', taxMessage = null) {
		const { currentMultiplier, currencyMultiplier } = this;

		const msrpMultiplier = currentMultiplier ?? 1;
		const territoryMultiplier = currencyMultiplier ?? 1;

		const self = this;

		const totalss = self.msrp?.totals?.find((m) => m.name == current);

		const items = [
			{
				msrpMultiplier: true,
				territoryMultiplier: true,
				name: "accessoryList",
				originalNumber: totalss?.accessoryItems

			},
			{
				msrpMultiplier: true,
				territoryMultiplier: true,
				name: "shadeList",
				originalNumber: totalss?.assemblyItems

			},
			{
				msrpMultiplier: true,
				territoryMultiplier: true,
				name: "accessoryDisplay",
				originalNumber: totalss?.accessories

			},
			{
				msrpMultiplier: false,
				territoryMultiplier: true,
				name: "accessoryShipping",
				originalNumber: totalss?.shipping.accessories

			},
			{
				msrpMultiplier: true,
				territoryMultiplier: true,
				name: "shadePrice",
				originalNumber: totalss?.assemblies
			},
			{
				msrpMultiplier: false,
				territoryMultiplier: true,
				name: "indoorShadeShipping",
				originalNumber: totalss?.shipping.indoorShades
			},
			{
				msrpMultiplier: false,
				territoryMultiplier: true,
				name: "hardwareParts",
				originalNumber: totalss?.hardware_parts
			},
			{
				msrpMultiplier: false,
				territoryMultiplier: true,
				name: "hardwarePartsShipping",
				originalNumber: totalss?.shipping.hwParts
			},
			{
				msrpMultiplier: false,
				territoryMultiplier: true,
				name: "overSizedShipping",
				originalNumber: totalss?.shipping.oversized
			},
			{
				msrpMultiplier: false,
				territoryMultiplier: true,
				name: "outdoorOutsideContinental",
				originalNumber: totalss?.shipping.outdoorOutsideContinental
			},
			{
				msrpMultiplier: false,
				territoryMultiplier: true,
				name: "total",
				originalNumber: totalss?.totalPrice
			},

			{
				msrpMultiplier: false,
				territoryMultiplier: true,
				name: "hasOutdoorShade",
				originalNumber: totalss?.assemblyItems.some((sa) => sa.type == 'outdoor')
			},
			{
				msrpMultiplier: false,
				territoryMultiplier: true,
				name: "outdoorShadeShipping",
				originalNumber: totalss?.shipping.outdoorShades
			},
			{
				msrpMultiplier: false,
				territoryMultiplier: true,
				name: "taxes",
				originalNumber: totalss?.taxes
			},
			{
				msrpMultiplier: false,
				territoryMultiplier: true,
				name: "subtotal",
				originalNumber: totalss?.subtotal
			},
			{
				msrpMultiplier: false,
				territoryMultiplier: true,
				name: "preCoupon",
				originalNumber: (totalss?.pre_shipping + totalss?.shipping.total_shipping) ?? 0
			},
			{
				msrpMultiplier: false,
				territoryMultiplier: false,
				name: "hasError",
				originalNumber: (function () {
					const hasErrors = self.Data?.shadeAssemblies?.Items.some((sa) => self.Data.shadeSubstitute(sa.sequence_id).getErrorFields().length > 0) ?? false;

					const hasAZeroCostShade = self.Data?.shadeAssemblies?.Items?.some((sa) => sa.msrp == 0) ?? false;

					return hasErrors || hasAZeroCostShade;
				}())
			},

		];

		const finalItem = {};

		items.forEach((it) => finalItem[it.name] = it.originalNumber);

		this.taxMessage = taxMessage ?? this.taxMessage;

		finalItem.taxMessage = this.taxMessage;
		finalItem.ISO = totalss?.multiplier?.currency ?? 'USD';
		finalItem.symbol = getSymbolFromCurrency(finalItem.ISO);
		finalItem.name = current;

		if (current = 'paid') {
			finalItem.paidPrice = totalss?.paidPrice ?? 0;
			finalItem.couponTotal = totalss?.couponTotal ?? 0;
			//    finalItem.preCoupon = totalss.preCoupon;
		}

		return finalItem;
	}

	LoadMoney() {
		this.Data.shadeAssemblies.Items.forEach((s) => {
			const type = s.indoor_outdoor;

			const Money = this.MoneyItem(s.sequence_id, s.msrp ?? 0, `shade-msrp-${type}`);
			const Shipping = this.MoneyItem(s.sequence_id, s.shipping_price, `shade-shipping-${type}`, false);

			this.ShadeMSRP.push(Money);
			this.ShadeShipping.push(Shipping);
		});

		const { store, quoteSub } = this.quoteLevelVariables();
		if (store) {
			const qas = selectQuoteAccessories(quoteSub.ID)(store.getState());
			qas.forEach((acc) => {
				const accessoryPrice = this.MoneyItem(
					acc.line_number,
					acc.msrp,
					"acc-msrp"
				);
				this.Accessories.push(accessoryPrice);

				const totalMoney = this.MoneyItem(
					acc.line_number,
					acc.msrp * (acc.quantity ?? acc.quantity),
					"acc-total"
				);

				this.AccessoryTotals.push(totalMoney);

				const accessoryShipping = this.MoneyItem(
					acc.line_number,
					acc.shipping,
					"acc-shipping",
					false
				);

				this.AccessoryShipping.push(accessoryShipping);
			});

		}

		this.Data.fabricSamples.Items.forEach((f) => {
			const fabricPrice = this.MoneyItem(
				f.id,
				0,
				"fabric"
			);

			this.FabricSamples.push(fabricPrice);
		});
	}

	get FabricMoney() {
		return this.FabricSamples;
	}

	AddAndGetMoney(acc) {
		const accessoryPrice = this.MoneyItem(
			acc.id,
			Number((acc.msrp / acc.quantity).toFixed(2)),
			"acc-msrp"
		);
		this.Accessories.push(accessoryPrice);

		const totalMoney = this.MoneyItem(
			acc.id,
			acc.msrp,
			"acc-total"
		);

		this.AccessoryTotals.push(totalMoney);

		const accessoryShipping = this.MoneyItem(
			acc.id,
			acc.shipping_price,
			"acc-shipping"
		);

		this.AccessoryShipping.push(accessoryShipping);

		return {
			cost: accessoryPrice,
			shipping: accessoryShipping,
			total: totalMoney
		};
	}

	AddAssemblyMSRP(assemblyId, amount, type) {
		const Money = this.MoneyItem(assemblyId, amount ?? 0, `shade-msrp-${type}`);

		this.ShadeMSRP.push(Money);
	}

	AddAssemblyShipping(assemblyId, amount, type) {
		const Shipping = this.MoneyItem(assemblyId, amount, `shade-shipping-${type}`, false);

		this.ShadeShipping.push(Shipping);
	}

	MoneyItem(sequenceId, amount, type, affectedByMultiplier = true) {
		const moneyItem = new MoneyId({
			sequenceId,
			amount,
			symbol: this.symbol,
			multiplier: this.currentMultiplier,
			currencyMultiplier: this.currencyMultiplier,
			type,
			affectedByMultiplier
		});

		return moneyItem;
	}

	RemoveSequenceIds(sequenceIds) {
		this.ShadeMSRP = this.ShadeMSRP.filter((m) => !sequenceIds.includes(m.sequenceId));
		this.ShadeShipping = this.ShadeShipping.filter((m) => !sequenceIds.includes(m.sequenceId));
	}

	GetMoneyById(sequenceId, type) {
		const item = this.AllItems.find((item) => item.sequenceId === sequenceId && item.Type === type);
		return item;
	}

	GetMsrp(sequenceId, ...typeConstraints) {
		const item = this.AllItems.find((money) =>
			money.sequenceId === sequenceId
			&& money.Type.toLowerCase().includes('msrp')
			&& (typeConstraints ? typeConstraints.every((tc) => money.Type.includes(tc)) : true));
		return item;
	}

	GetShipping(sequenceId, ...typeConstraints) {
		const item = this.AllItems.find((money) =>
			money.sequenceId === sequenceId
			&& money.Type.toLowerCase().includes('shipping')
			&& (typeConstraints ? typeConstraints.every((tc) => money.Type.includes(tc)) : true));
		return item;
	}

	get AllItems() {
		return [
			...this.Accessories,
			...this.AccessoryTotals,
			...this.AccessoryShipping,
			...this.FabricSamples,
			...this.ShadeMSRP,
			...this.ShadeShipping
		];
	}

	get AccessoryMoney() {
		const moneys = [];

		const { store, quoteSub } = this.quoteLevelVariables();

		const qas = selectQuoteAccessories(quoteSub.ID)(store.getState());

		qas.forEach((acc) => {
			const id = acc.line_number;

			moneys.push({
				Id: acc.line_number,
				cost: acc,
				total: this.AccessoryTotals.find((at) => at.sequenceId == id),
				shipping: this.AccessoryShipping.find((as) => as.sequenceId == id)
			});
		});

		return moneys;
	}

	get OutdoorMoney() {
		return this.AllItems.filter((m) => m.Type.includes('outdoor'));
	}

	get IndoorMoney() {
		return this.AllItems.filter((m) => m.Type.includes('indoor'));
	}

	get TotalMSRP() {
		let total = 0;

		total += this.ShadeMSRP.reduce(((s, total) => s.msrp + total), 0);
		total += this.AccessoryTotals.reduce(((s, total) => s.msrp + total), 0);

		return total;
	}
}

class MoneyId {
	constructor({
		sequenceId, amount, symbol, multiplier, type, currencyMultiplier, affectedByMultiplier
	}) {
		this.amount = amount;
		this.symbol = symbol;
		this.currencyMultiplier = currencyMultiplier;
		this.multiplier = multiplier;
		this.sequenceId = sequenceId;
		this.Type = type;
		this.affectedByMultiplier = affectedByMultiplier;

		this.UpdateMoney = this.UpdateMoney.bind(this);
		this.UpdateMultipliers = this.UpdateMultipliers.bind(this);
		this.UpdateAmount = this.UpdateAmount.bind(this);
		this.functions = {};
	}

	UpdateMoney() {
		return this.functions;
	}

	get Component() {
		const {
			sequenceId, Type, amount, symbol, multiplier, currencyMultiplier, affectedByMultiplier
		} = this;

		return <Money key={`${sequenceId}-${Type}-${amount}-${symbol}-${currencyMultiplier}-${multiplier}`} amount={amount} symbol={symbol} multiplier={!affectedByMultiplier ? 1 : multiplier} update={this.UpdateMoney} currencyMultiplier={currencyMultiplier} />;
	}

	// These mutators won't work. Need to add lower level functions;
	UpdateMultipliers(multiplier, currencyMultiplier) {
		const updateObj = {};

		if (this.multiplier != multiplier && this.affectedByMultiplier) {
			this.multiplier = multiplier;
			updateObj.multiplier = multiplier;
		}

		if (this.currencyMultiplier != currencyMultiplier) {
			this.currencyMultiplier = currencyMultiplier;
			updateObj.currencyMultiplier = currencyMultiplier;
		}

		if (this.functions && this.functions.Update && Object.keys(updateObj).length !== 0) {
			this.functions.Update(updateObj);
		}
	}

	UpdateSymbol(symbol) {
		const updateObj = {};

		if (this.symbol != symbol) {
			this.symbol = symbol;
			updateObj.symbol = symbol;
		}

		if (this.functions && this.functions.Update && Object.keys(updateObj).length !== 0) {
			this.functions.Update(updateObj);
		}
	}

	UpdateAmount(amount) {
		// if (this.amount != amount) {
		this.amount = amount;

		if (this.functions && this.functions.Update) {
			this.functions.Update({
				amount
			});
		}
		// }
	}

	get value() {
		return this.Component.value;
	}
}

export default QuoteMoney;
