import { createAsyncThunk } from "@reduxjs/toolkit";
import { HardwareRequestItem, HardwareRequestItemFromOptions, QuoteHardwareRequestItemStore } from "../../../powershadesApiTypes";
import { ThunkAPI } from "../types";
import apiCalls from "../../../PowerShadesAPIFunctions";
import { AxiosError } from "axios";
import { extraEntityReducersFunction, loadStatus } from "../entitiesType";
import { wentToQuote } from "../quotes";


const loadHwItemsByQuoteId = createAsyncThunk<HardwareRequestItem[], number, ThunkAPI>(
    'entities/GetHwItems',
    async (quoteId, { rejectWithValue }) => {
        let hwItems: HardwareRequestItem[] = [];

        try {
            const resp = await apiCalls.getHwParts(quoteId);

            hwItems = resp.data.hardware_request_items;
        } catch (err: any) {
            const error: AxiosError = err;

            console.error(error);

            return rejectWithValue(false);
        }

        return hwItems;
    }
);

const addQuoteHwItem = createAsyncThunk<
    HardwareRequestItem, { quoteId: number, sku: string, lineItemNumber: number }, ThunkAPI
>(
    'entities/AddHwItem',
    async ({ quoteId, sku, lineItemNumber }, { rejectWithValue, dispatch }) => {
        let hwItem;

        try {
            dispatch(wentToQuote(quoteId));

            const resp = await apiCalls.createHWItem(quoteId, sku, lineItemNumber);

            hwItem = resp.data.hardware_request_items.find((item: HardwareRequestItem) => item.line_number === lineItemNumber);
        } catch (err: any) {
            const error: AxiosError = err;

            console.error(error);

            return rejectWithValue(false);
        }

        return hwItem;
    }
);

const deleteQuoteHwItem = createAsyncThunk<
    { quoteId: number, lineNumber: number }, { quoteId: number, lineNumber: number }, ThunkAPI
>(
    'entities/DeleteHwItem',
    async ({ quoteId, lineNumber }, { rejectWithValue, dispatch }) => {
        try {
            dispatch(wentToQuote(quoteId));
            await apiCalls.deleteHWItem(quoteId, lineNumber);
        } catch (err: any) {
            const error: AxiosError = err;

            console.error(error);

            return rejectWithValue(false);
        }

        return { quoteId, lineNumber };
    }
);

const updateQuoteHwItem = createAsyncThunk<
    HardwareRequestItem | null, { quoteId: number, lineNumber: number, quantity: number }, ThunkAPI
>(
    'entities/UpdateHwItem',
    async ({ quoteId, lineNumber, quantity }, { rejectWithValue, dispatch }) => {
        let hwItem: HardwareRequestItem | null;

        dispatch(wentToQuote(quoteId));

        try {
            const resp = await apiCalls.updateHWItem(quoteId, lineNumber, quantity);

            hwItem = resp?.data?.hardware_request_items?.find((item: HardwareRequestItem) => item.line_number === lineNumber) ?? null;
        } catch (err: any) {
            const error: AxiosError = err;

            console.error(error);

            return rejectWithValue(false);
        }

        return hwItem;
    }
);

const getFullListOfParts = createAsyncThunk<
    HardwareRequestItemFromOptions[], {quoteId: number}, ThunkAPI
>(
    'entities/GetFullListOfParts',
    async ({quoteId}, {
        rejectWithValue
    }) => {
        let hwItems: HardwareRequestItemFromOptions[] = [];

        try {
            const resp = await apiCalls.getAllowedHwParts(quoteId);

            hwItems = resp.data.parts;
        } catch (err: any) {
            const error: AxiosError = err;

            console.error(error);

            return rejectWithValue(false);
        }

        return hwItems;
    }
);



const quoteHWItemsBuilder: extraEntityReducersFunction = (builder) => {



    builder.addCase(getFullListOfParts.pending, (state, action) => {
        const currentState = state.hardwareRequestItems[action.meta.arg.quoteId]
        if(currentState)
            currentState.fullLoadStatus = loadStatus.loading;
        state.hardwareRequestItems[action.meta.arg.quoteId] = currentState ?? {
            Items: {},
            loadStatus: loadStatus.loading,
            TotalItems: {},
            fullLoadStatus: loadStatus.needsLoaded
        };
    });

    builder.addCase(getFullListOfParts.fulfilled, (state, action) => {
        let record = state.hardwareRequestItems[action.meta.arg.quoteId];
        
     
        const itemsStore: HardwareRequestItemFromOptions[] = action.payload.map(hwi => ({
            name: hwi.name ?? "",
            partNumber: hwi.partNumber ?? "",
            msrp: hwi.msrp ?? 0,
            uom: hwi.uom ?? "",
            cost: hwi.cost ?? 0,
        }));
            
        const itemsRecord = itemsStore.reduce((acc, item) => {
            acc[item.partNumber] = item;
            return acc;
        }, {} as Record<string, HardwareRequestItemFromOptions>);

        if (!record) {
            record = {
                Items: {},
                loadStatus: loadStatus.fullyLoaded,
                TotalItems: {},
                fullLoadStatus: loadStatus.needsLoaded
            };
        }

        record.fullLoadStatus = loadStatus.fullyLoaded;
        record.TotalItems = { ...itemsRecord };
    
    });


    builder.addCase(loadHwItemsByQuoteId.pending, (state, action) => {
        const currentState = state.hardwareRequestItems[action.meta.arg]
        if(currentState)
            currentState.loadStatus = loadStatus.loading;
        state.hardwareRequestItems[action.meta.arg] = currentState ?? {
            Items: {},
            loadStatus: loadStatus.loading,
            TotalItems: {},
            fullLoadStatus: loadStatus.needsLoaded
        };
    });

    builder.addCase(loadHwItemsByQuoteId.fulfilled, (state, action) => {
        let record = state.hardwareRequestItems[action.meta.arg];

        const itemsStore: QuoteHardwareRequestItemStore[] = action.payload.map(hwi => ({
            loadStatus: loadStatus.fullyLoaded,
            Id: hwi.line_number ?? 0,
            name: hwi.sku ?? "",
            mfg_status: "",
            quantity: hwi.quantity ?? 0,
            last_time_priced: 0,
            part_number: hwi.sku ?? "",
            is_pricing: false,
            quote_id: hwi.quote_id ?? 0,
            msrp: hwi.msrp ?? 0,
            cost: hwi.cost ?? 0,
            line_number: hwi.line_number ?? 0,
            shipping: hwi.shipping ?? ((hwi?.msrp ?? 0) * 0.35),
            sku: hwi.sku ?? ""
        }))

        const itemsRecord = itemsStore.reduce((acc, item) => {
            acc[item.line_number] = item;
            return acc;
        }, {} as Record<number, QuoteHardwareRequestItemStore>);
        if (!record) {
            record = {
                Items: {},
                loadStatus: loadStatus.fullyLoaded,
                TotalItems: {},
                fullLoadStatus: loadStatus.needsLoaded
            };
        }

        record.Items = { ...itemsRecord };
    });

    builder.addCase(loadHwItemsByQuoteId.rejected, (state, action) => {
        state.hardwareRequestItems[action.meta.arg] = {
            Items: {},
            loadStatus: loadStatus.needsLoaded,
            TotalItems: {},
            fullLoadStatus: loadStatus.needsLoaded
        };
    });

    builder.addCase(addQuoteHwItem.pending, (state, action) => {
        const quoteId = action.meta.arg.quoteId;
        const line_number =action.meta.arg.lineItemNumber;
        const sku = action.meta.arg.sku;

        let record = state.hardwareRequestItems[quoteId];

        if(!record) {
            record = {
                Items: {},
                loadStatus: loadStatus.fullyLoaded,
                TotalItems: {},
                fullLoadStatus: loadStatus.needsLoaded
            };
            state.hardwareRequestItems[quoteId] = record;
        }

        record.Items[line_number] = {
            Id: line_number,
            mfg_status: "",
            last_time_priced: 0,
            is_pricing: false,
            quote_id: quoteId,
            part_number: sku,
            shipping: 0,
            quantity:  1,
            name: "",
            msrp: 0,
            cost: 0,
            line_number
        };

    });
    builder.addCase(addQuoteHwItem.fulfilled, (state, action) => {
        const quoteId = action.meta.arg.quoteId;
        const line_number =action.meta.arg.lineItemNumber;
        const sku = action.meta.arg.sku;
        let record = state.hardwareRequestItems[quoteId];

        if(!record) {
            record = {
                Items: {},
                loadStatus: loadStatus.needsLoaded,
                TotalItems: {},
                fullLoadStatus: loadStatus.fullyLoaded
            };
            state.hardwareRequestItems[quoteId] = record;
        }

        record.Items[line_number] = {
            Id: line_number,
            mfg_status: "",
            last_time_priced: 0,
            is_pricing: false,
            quote_id: quoteId,
            part_number: action.payload?.sku ?? sku,
            shipping: action.payload.shipping ?? ((action?.payload?.msrp ?? 0) * 0.035),
            quantity: action.payload.quantity ?? 0,
            name: action.payload?.sku,
            msrp: action.payload.msrp ?? 0,
            cost: 0,
            line_number
        };
    });

    builder.addCase(deleteQuoteHwItem.pending, (state, action) => {
        const record = state.hardwareRequestItems[action.meta.arg.quoteId];

        if (record) {
            delete record.Items[action.meta.arg.lineNumber];
        }
    });

    builder.addCase(deleteQuoteHwItem.fulfilled, (state, action) => {
        const record = state.hardwareRequestItems[action.payload.quoteId];

        if (record) {
            delete record.Items[action.payload.lineNumber];
        }
    });

    builder.addCase(updateQuoteHwItem.fulfilled, (state, action) => {
        const {
            quote_id = action.meta.arg.quoteId,
            line_number,
            quantity
        } = action.payload ?? {
            quote_id: action.meta.arg.quoteId,
            line_number: action.meta.arg.lineNumber,
            quantity: 0
        };
        const record = state.hardwareRequestItems[quote_id];

        if (record) {
            var hwItemRecord = record.Items[line_number];
            if (hwItemRecord)
                hwItemRecord.quantity = quantity ?? 0;

            if((hwItemRecord?.quantity ?? 0) === 0) {
               delete  record.Items[line_number];
            }
        }
    });
}


export {
    quoteHWItemsBuilder,
    loadHwItemsByQuoteId,
    addQuoteHwItem,
    deleteQuoteHwItem,
    updateQuoteHwItem,
    getFullListOfParts
}