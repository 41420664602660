import { createAsyncThunk, createSelector } from "@reduxjs/toolkit";

import { ManufacturerStore, extraEntityReducersFunction, loadStatus } from "../entitiesType";
import apiCalls from "../../../PowerShadesAPIFunctions";
import type { AppState } from "../..";
import { captureSentryError, extractErrorMessage, isFailedApiCall } from "../../../psUtil";

export const initialManufacturerState: () => ManufacturerStore = () => ({
	manufacturer_id: 0,
	name: "",
	loadStatus: loadStatus.notLoaded
});

const loadManufacturersMeta = createAsyncThunk<ManufacturerStore[], void, { rejectValue: string }>(
	'entities/getManufacturersMeta',
	async (_emptyInput, { rejectWithValue }) => {
		let manufacturers: ManufacturerStore[];

		const initDis = initialManufacturerState();
		try {
			const resp = await apiCalls.getManufacturers();

			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			const workingManufs = resp.data.manufacturers.map((rep) => ({
				...initDis,
				...rep,
			}));

			manufacturers = workingManufs;
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: 'redux'
				},
				extra: {
					redux_action: 'getManufacturersMeta',
					file_origin: 'src/Store/entities/organizations/manufacturers.ts'
				}
			});

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}

		return manufacturers;
	}
);

const selectManufacturerList = createSelector(
	(state: AppState) => state.entity.manufacturers,
	(manufacturers) => {
		const updatedDealers = Object.entries(manufacturers)
			.reduce((list, [key, value]) => {
				if (key === 'loadStatus') return list;
				list.push(value as ManufacturerStore);
				return list;
			}, [] as ManufacturerStore[])
			.filter((d) => d?.loadStatus !== loadStatus.notLoaded);

		return updatedDealers;
	}
);

const manufacturerBuilder: extraEntityReducersFunction = (_builder) => {
	_builder.addCase(loadManufacturersMeta.pending, (state) => {
		state.manufacturers.loadStatus = loadStatus.loading;
	});

	_builder.addCase(loadManufacturersMeta.fulfilled, (state, action) => {
		const manufacturers = action.payload.map((m) => ({
			...m,
			loadStatus: loadStatus.fullyLoaded
		}));

		state.manufacturers.list = manufacturers;
		state.manufacturers.loadStatus = loadStatus.fullyLoaded;
	});
};

export {
	manufacturerBuilder,
	loadManufacturersMeta,
	selectManufacturerList
};
