// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatusCards-module__status-card___qRwvQ{height:100%;text-align:center;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;user-select:none}.StatusCards-module__status-card___qRwvQ:hover{background-color:#f2f2f2}.StatusCards-module__active___pZh3Q{border-color:#8dc63f !important;border-width:2px !important}.StatusCards-module__active___pZh3Q{background-color:#f2f2f2}`, "",{"version":3,"sources":["webpack://./src/Home/StatusCards/StatusCards.module.scss","webpack://./src/assets/css/_variables-light.scss"],"names":[],"mappings":"AAEA,yCACC,WAAA,CACA,iBAAA,CACA,cAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,gBAAA,CACA,+CACC,wBCFiB,CDMnB,oCACC,+BAAA,CACA,2BCRkB,CDMnB,oCAGC,wBCTkB","sourcesContent":["@import \"../../assets/css/variables-light.scss\";\n\n.status-card {\n\theight: 100%;\n\ttext-align: center;\n\tcursor: pointer;\n\tuser-select: none;\n\t&:hover {\n\t\tbackground-color: $ps-lightest-gray;\n\t}\n}\n\n.active {\n\tborder-color: $ps-green !important;\n\tborder-width: 2px !important;\n\tbackground-color: $ps-lightest-gray;\n}","$ps-green: #8dc63f;\n$ps-dark-green: #7fb239;\n$ps-darker-green: #72A033;\n$ps-gray: #6d6e71;\n$ps-light-gray: #a7a9ac;\n$ps-lighter-gray: #dedede;\n$ps-lightest-gray: #F2F2F2;\n$ps-dark-gray: #57585a;\n$mobile-threshold: 993px;\n$error-color: #e30000;\n$error-row-background-color: #ff00006e;\n$delete-red: #e60d0d;\n$pending-orange: #e37204;\n$ps-black: #020202;\n$ps-rma: #bb4430;\n$ps-bid: #9611d0;\n$ps-rework: #469e95;\n$ps-light-green: #ddeec5;\n$ps-light-blue: #b3e6f5;\n$ps-light-yellow: #fbe7c6;\n$ps-light-red: #ffb7b6;\n$ps-dark-red: #822f21;\n\n$seam-color: #f8dc3f;\n$sag-color: #f39422;\n$seam-sag-color: #e289dd;\n$large-outdoor-color: #5589e7;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status-card": `StatusCards-module__status-card___qRwvQ`,
	"statusCard": `StatusCards-module__status-card___qRwvQ`,
	"active": `StatusCards-module__active___pZh3Q`
};
export default ___CSS_LOADER_EXPORT___;
