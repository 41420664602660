import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import type { QuoteHeaderProps } from "./types";
import { QuoteTypeNamesById } from "../../powershadesApiTypeExtensions";
import { useDealersByTerritory } from "../../Store/entities/hooks";
import TerritorySelect from "../../Parts/TerritorySelect";
import DealerSelect from "../../Parts/DealerSelect";
import Button from "../../Parts/Button";
import { useSetTabPaneStatus } from "../../Store/ui/hooks";

const QuoteHeader = ({
	isPsAdmin,
	quoteId,
	quoteTypeId = 5,
	quoteTitle,
	quoteReference,
	quoteTerritoryId = 0,
	quoteDealerId
}: QuoteHeaderProps) => {
	const placeholderTitle = "New Quote - No Title";
	const placeholderReference = "No Reference Number";
	const openTabPane = useSetTabPaneStatus();

	const dealersInTerritory = useDealersByTerritory(quoteTerritoryId);

	const quoteType = QuoteTypeNamesById[quoteTypeId]?.toLowerCase();

	console.log({
		quoteId,
		quoteTypeId,
		quoteTitle,
		quoteReference,
		quoteTerritoryId,
		quoteDealerId
	});

	return (
		<Row className="quote-header">
			<Col xs={12} sm={6} lg={isPsAdmin ? 3 : 6} xl={isPsAdmin ? 2 : 4} className="mb-2">
				<Form.Control
					id="quote-title"
					placeholder={placeholderTitle}
					value={quoteTitle ?? ""}
					disabled
				/>
			</Col>
			<Col xs={12} sm={6} lg={isPsAdmin ? 3 : 6} xl={isPsAdmin ? 2 : 4} className="mb-2">
				<Form.Control placeholder={placeholderReference} value={quoteReference ?? ""} disabled />
			</Col>
			{isPsAdmin && (
				<>
					<Col xs={12} sm={6} lg={3} xl={2} className="mb-2">
						<TerritorySelect
							placeholderText="No Territory Selected"
							disableOverride
							selectedTerritoryIds={quoteTerritoryId ? [quoteTerritoryId] : []}
							handleChange={(territory) => {
								console.log("territory", territory);
							}}
						/>
					</Col>
					<Col xs={12} sm={6} lg={3} xl={2} className="mb-2">
						<DealerSelect
							placeholderText="No Dealer Selected"
							disableOverride
							optionsOverride={dealersInTerritory}
							selectedDealerId={quoteDealerId ?? 0}
							handleChange={(dealer) => {
								console.log("dealer", dealer);
							}}
						/>
					</Col>
				</>
			)}
			<Col xl={3} className="d-none d-xl-block">
				<Form.Control placeholder="Search Quote..." disabled />
			</Col>
			<Col xl={1} className="text-end d-none d-xl-block desktop-button">
				<Button
					fullWidth
					color={quoteType ?? "green"}
					onClick={() => {
						console.log("opening tab pane!");
						openTabPane(true);
					}}
				>
					<FontAwesomeIcon icon={faBars} />
				</Button>
			</Col>
		</Row>
	);
};

export default QuoteHeader;
