import React, { useEffect, useState } from 'react';

export default function TextArea(props) {
	const [value, setValue] = useState(props.defaultValue || null);

	function valueChanged(new_value) {
		setValue(new_value);
		props.onChange && props.onChange(new_value);
	}

	const quoteId = props.quoteId || null;

	useEffect(() => {
		if (quoteId) {
			setValue(props.defaultValue);
		}
	}, [quoteId]);


	const filtered_props = { ...props };

	['wrapperStyle', 'labelStyle', 'defaultValue']
		.forEach((key) => delete filtered_props[key]);

	return (

		<div style={props.wrapperStyle}>

			{
				props.label

					? (
						<>
							<label htmlFor={props.label} style={props.labelStyle}>
								{props.label}
							</label>
							<br />
						</>
					)
					: null
			}

			<textarea
				name={props.label}
				{...filtered_props}
				value={value || ''}
				onChange={(e) => valueChanged(e.target.value)}

			/>

		</div>
	);
}
