import process from "process";
import { HttpTransportType, HubConnectionBuilder, HubConnectionState, LogLevel } from "redux-signalr";

const hubUrl = `${process.env.BACKEND_ROOT}/hub`;

const connection = new HubConnectionBuilder()
	.configureLogging(LogLevel.Debug)
	.withUrl(hubUrl, {
		skipNegotiation: true,
		transport: HttpTransportType.WebSockets,
		accessTokenFactory: (() => {
			const jwtItem = localStorage.getItem("user_jwt");

			return jwtItem ?? "";
		}),
		
	})
	.withAutomaticReconnect()
	.build();

connection.onclose((error) => {
	console.error("SignalR connection closed. Error:", error);
	console.log("Attempting to reconnect in 5 seconds...");
	setTimeout(() => EnsureConnectionMade(), 5000);
});
	
connection.onreconnecting((error) => {
	console.warn("SignalR reconnecting. Error:", error);
});
	
connection.onreconnected((connectionId) => {
	console.log("SignalR reconnected. Connection ID:", connectionId);
});

connection.on("WentToQuote", (quoteId) => {
	console.log("Went to quote: ", quoteId);
});

connection.on("RequestPageReload", (quoteId) => {
    console.log(`Received request to reload page for quote: ${quoteId}`);
    window.location.reload();
});

const EnsureConnectionMade = async (retryCount = 0) => {
	if (connection.state === HubConnectionState.Disconnected || connection.state === HubConnectionState.Reconnecting) {
		try {
			await connection.start();
			console.log("✅ SignalR connected");
		} catch (e) {
			console.error(`❌ SignalR connection failed (Attempt ${retryCount + 1}):`, e);
			if (retryCount < 5) { // Retry up to 5 times
				setTimeout(() => EnsureConnectionMade(retryCount + 1), 5000);
			} else {
				console.error("❌ Unable to reconnect after 5 attempts.");
			}
		}
	} else {
		console.log("SignalR is already connected.");
	}
};

// // store: any, callback: (() => void)
// export function signalRStart() {
// 	const _hub = connection;

// 	// _hub.invoke .firstClientFunction = (p1: any) => {
// 	// 	store.dispatch({ type: "SERVER_CALLED_ME", a: p1 });
// 	// }

// 	// _hub.start(() => callback());
// }

export {
	EnsureConnectionMade
};

export default connection;
