// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FilterContainer-module__filter-container___ae9qp{-webkit-user-select:none;-moz-user-select:none;user-select:none;margin-bottom:1em}.FilterContainer-module__filter-container___ae9qp .FilterContainer-module__dropdown-toggle___eEEKQ::after{display:none !important}`, "",{"version":3,"sources":["webpack://./src/Home/Filters/FilterContainer.module.scss"],"names":[],"mappings":"AAAA,kDACC,wBAAA,CAAA,qBAAA,CAAA,gBAAA,CAMA,iBAAA,CAJC,0GACC,uBAAA","sourcesContent":[".filter-container {\n\tuser-select: none;\n\t.dropdown-toggle {\n\t\t&::after {\n\t\t\tdisplay: none !important;\n\t\t}\n\t}\n\tmargin-bottom: 1em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter-container": `FilterContainer-module__filter-container___ae9qp`,
	"filterContainer": `FilterContainer-module__filter-container___ae9qp`,
	"dropdown-toggle": `FilterContainer-module__dropdown-toggle___eEEKQ`,
	"dropdownToggle": `FilterContainer-module__dropdown-toggle___eEEKQ`
};
export default ___CSS_LOADER_EXPORT___;
