import React, { useMemo } from 'react';

import { Typeahead } from 'react-bootstrap-typeahead';
import { useQuoteTypes, useQuoteTypesLoadStatus } from '../../Store/entities/quotes/hooks';

import type { QuoteTypeFilterProps } from './types';
import { loadStatus } from '../../Store/entities/entitiesType';
import { QuoteTypeIds } from '../../powershadesApiTypes';

const QuoteTypeFilter = ({ setNewQuoteTypeIds, selectedQuoteTypeIds }: QuoteTypeFilterProps) => {
	const quoteTypes = useQuoteTypes();
	const quoteTypesLoading = useQuoteTypesLoadStatus();
	const loading = quoteTypesLoading <= loadStatus.loading;

	const formattedQuoteTypes = useMemo(
		() => quoteTypes
			.filter(({ id }) => !selectedQuoteTypeIds.includes(id))
			.slice()
			.map(({ id, name }) => ({ id, label: name })),
		[quoteTypes, selectedQuoteTypeIds]
	);

	const setNewQuoteTypeId = (newDealerId: QuoteTypeIds) => {
		setNewQuoteTypeIds([...selectedQuoteTypeIds, newDealerId]);
	};


	return (
		<Typeahead
			id="quote-type-filter"
			multiple
			positionFixed
			labelKey="label"
			flip
			renderToken={() => (
				<>
					{ /* EMPTY */}
					{ /* EMPTY */}
				</>
			)}
			placeholder={loading ? "Loading Quote Types..." : "Select Quote Types..."}
			options={formattedQuoteTypes}
			isLoading={loading}
			disabled={loading}
			onChange={
				(selected) => setNewQuoteTypeId((selected[0] as { id: QuoteTypeIds }).id)
			}
			selected={[]}
		/>
	);
};

export default QuoteTypeFilter;
