import React, { useState, useEffect, useCallback } from "react";
import Popup from "reactjs-popup";
import { Container, Row, Col, Card, Table, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTimesCircle,
	faExclamationCircle,
	faCheckCircle,
	faPlus,
	faTrashAlt,
	faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";

import { toast } from "react-toastify";
import EntityEmailsListing from "../Home/EntityEmailsListing";
import apiCalls from "../PowerShadesAPIFunctions";
import { captureSentryError, isFailedApiCall } from "../psUtil";

import type { BankAccount, BankCard, GetPaymentMethods2Response, PowerShadesAPIResponse } from "../powershadesApiTypes";
import type { PaymentMethodProps } from "./types";
import { useUsersLoadStatus } from "../Store/entities/hooks";
import Button from "../Parts/Button";

const PaymentMethods = ({ ROOT }: PaymentMethodProps) => {
	const { setTitle, loadContent, loaded, alert, loading } = ROOT;
	const [cards, setCards] = useState<BankCard[]>([]);
	const [oldCards, setOldCards] = useState<BankCard[]>([]);

	const [accounts, setAccounts] = useState<BankAccount[]>([]);
	const [oldAccounts, setOldAccounts] = useState<BankAccount[]>([]);

	const [itemZid, setItemZid] = useState("");

	const [isDeleting, setIsDeleting] = useState(false);
	const [isVerifying, setIsVerifying] = useState(false);
	const [depositOne, setDepositOne] = useState("0.00");
	const [depositTwo, setDepositTwo] = useState("0.00");
	const [isError, setIsError] = useState(false);
	const [errorTitle, setErrorTitle] = useState("Generic Error Message");
	const defaultErrorMessage = "Please reach out to PowerShades if you've received this message.";
	const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);
	const [fetchingLink, setFetchingLink] = useState(false);
	const [initFetchRan, setInitFetchRan] = useState(false);
	const [fetchingMethods, setFetchingMethods] = useState(false);
	const [hasCards, setHasCards] = useState(false);
	const [hasAccounts, setHasAccounts] = useState(false);
	const [tabLink, setTabLink] = useState("");

	const [searchParms] = useSearchParams();

	const clearZId = () => setItemZid("");
	const clearError = () => {
		setErrorTitle("Generic Error Message");
		setErrorMessage("Please reach out to PowerShades if you've received this message.");
	};

	const [accountBeingVerified, setAccountBeingVerified] = useState(false);
	const [accountID, setAccountID] = useState("");

	const clearAccountID = () => setAccountID("");

	const needsTab = tabLink !== "";
	const clearTab = () => setTabLink("");

	const isPopupOpen = itemZid !== "";

	const entityType = searchParms.get("entityType") ?? "";
	const entityId = Number(searchParms.get("entityId") ?? "0");
	const [selectedEntityName, setSelectedEntityName] = useState("");

	const isEntityLoaded = entityType !== null && entityId !== 0;

	const [showEntityList, setShowEntityList] = useState<any>(false);

	/**
	 * Clears all state variables
	 * @returns void
	 */
	const clearState = () => {
		setCards([]);
		setOldCards([]);
		setAccounts([]);
		setOldAccounts([]);
		setItemZid("");
		setIsDeleting(false);
		setIsVerifying(false);
		setDepositOne("0.00");
		setDepositTwo("0.00");
		setIsError(false);
		setErrorTitle("Generic Error Message");
		setErrorMessage("Please reach out to PowerShades if you've received this message.");
		setFetchingLink(false);
		setInitFetchRan(false);
		setHasCards(false);
		setHasAccounts(false);
		setTabLink("");
	};

	/**
	 * Navigates to the payment methods page for the selected entity
	 * @param newEntityId The id of the entity to navigate to
	 * @param newEntityType The type of entity to navigate to
	 * @returns void
	 */
	const navigateToEntitiesPaymentMethod = useCallback((newEntityId: number, newEntityType: string) => {
		loading();
		clearState();
		const url = `/#/PaymentMethods?entityId=${newEntityId}&entityType=${newEntityType}`;
		loadContent(url);
	}, [loadContent, loading]);

	const userLoadingStatus = useUsersLoadStatus();

	useEffect(() => {
		apiCalls
			.getAccessableEntities()
			.then((resp) => {
				if (isFailedApiCall(resp)) throw resp;

				const entities = resp.data;

				const { dealers = [], distributors = [], representatives = [] } = entities;

				const totalAmount = dealers.length + distributors.length + representatives.length;

				if (totalAmount === 1) {
					// Redirect
					let workingEntityType = "";
					let id = 0;

					if (dealers?.length > 0) {
						workingEntityType = "Dealers";
						id = dealers[0]?.id ?? 0;
					} else if (distributors?.length > 0) {
						workingEntityType = "Distributors";
						id = distributors[0]?.id ?? 0;
					} else if (representatives?.length > 0) {
						workingEntityType = "Representatives";
						id = representatives[0]?.id ?? 0;
					}
					navigateToEntitiesPaymentMethod(id, workingEntityType);
				} else {
					setShowEntityList(entities);
					loaded();
				}
			})
			.catch((resp) => {
				console.error({ resp });
				const error = resp?.error
					?? `Something went wrong loading your payment methods.
					Please reach out to PowerShades customer service.`;
				alert({
					quickFormat: "error",
					text: error,
				});
				throw new Error("Something went wrong loading entities");
			});
	}, [loaded, userLoadingStatus, navigateToEntitiesPaymentMethod, setTitle, alert]);

	useEffect(() => {
		if (entityType || entityId) return;
		setTitle("Payment Methods");
		clearState();
	}, [
		entityType,
		entityId,
		setTitle,
	]);

	useEffect(() => {
		if ((!entityType || entityId <= 0) && !initFetchRan) return;
		if (!entityType || entityId <= 0) return;
		setTitle("Loading Payment Methods...");
		setFetchingMethods(true);
		!initFetchRan && setInitFetchRan(true);
		apiCalls.getPaymentMethods2(entityType, entityId).then((resp) => {
			if (isFailedApiCall(resp)) {
				console.error(resp);
				const error = resp?.error
					?? `Something went wrong loading your payment methods. 
					Please reach out to PowerShades customer service.`;
				alert({
					quickFormat: "error",
					text: error,
				});
				throw new Error("Something went wrong loading payment methods");
			}
			const acc = [...resp.data.accounts];
			const tempCards = [...resp.data.cards];
			setCards(tempCards);
			setAccounts(acc);
			setOldCards(tempCards);
			setOldAccounts(acc);
			tempCards.length >= 1 && setHasCards(true);
			acc.length >= 1 && setHasAccounts(true);
			setFetchingMethods(false);
		});
	}, [entityType, entityId, userLoadingStatus, initFetchRan, loaded, setTitle, selectedEntityName, alert]);

	useEffect(() => {
		if (!initFetchRan) return;
		if (fetchingMethods) return;
		setTitle(`${selectedEntityName}${selectedEntityName && " - "} Payment Methods`);
		loaded();
	}, [setTitle, initFetchRan, loaded, fetchingMethods, selectedEntityName]);

	const deleteCard = (selectedCardId) => {
		setItemZid(selectedCardId.card_id);
	};

	/**
	 * Sets the itemZid state variable to the selected account's id
	 * @param selectedAccountId The id of the account to be deleted
	 */
	const deleteAccount = (selectedAccount) => {
		setItemZid(selectedAccount.account_id);
	};

	/**
	 * Attempts to refresh the payment methods. If the refresh fails, it will try again
	 * @param maxAttempts The maximum number of attempts to refresh payment methods
	 * defaults to 5
	 * @param interval The interval between attempts in milliseconds
	 * defaults to 5000
	 * @returns void
	 */
	const refreshPaymentMethods = (maxAttempts = 5, interval = 5000) => {
		let fetchAttempts = 0;
		const fetchErrors: PowerShadesAPIResponse<GetPaymentMethods2Response>[] = [];
		const refreshFunc = setInterval(() => {
			fetchAttempts += 1;

			if (fetchAttempts >= maxAttempts) {
				console.error("Max fetch attempts reached for refreshing payment methods!");
				fetchErrors.forEach((error) => {
					console.error({ error });
				});
				captureSentryError(new Error("Max fetch attempts reached for refreshing payment methods!"));
				clearInterval(refreshFunc);
			}
			
			apiCalls.getPaymentMethods2(entityType, entityId).then((resp) => {
				if (isFailedApiCall(resp)) fetchErrors.push(resp);
				const acc = [...resp.data.accounts];
				const tempCards = [...resp.data.cards];
				setCards(tempCards);
				setAccounts(acc);
				if (oldCards !== tempCards || oldAccounts !== acc) {
					clearInterval(refreshFunc);
				}
			});
		}, interval);
	};

	/**
	 * Deletes a card or account from the database
	 * @remarks Takes no params, but uses the itemZid state variable
	 * @returns void
	 */
	const deleteItem = () => {
		const itemToDelete = itemZid;
		setIsDeleting(true);

		apiCalls.deletePaymentMethod(entityType, entityId, itemToDelete).then((resp) => {
			if (isFailedApiCall(resp)) throw resp;
			const newAccountList = accounts.filter((object) => object.account_id !== itemZid);
			setAccounts(newAccountList);
			const newCardList = cards.filter((object) => object.card_id !== itemZid);
			setCards(newCardList);
			setIsDeleting(false);
			clearZId();
			toast.success("Payment method deleted successfully");
		}).catch((deletePaymentError) => {
			console.error({ resp: deletePaymentError });
			const formattedDeletePaymentError = deletePaymentError?.error
				?? `Something went wrong deleting your payment method. 
				Please reach out to PowerShades customer service.`;
			alert({
				quickFormat: "error",
				text: formattedDeletePaymentError,
			});
			setIsDeleting(false);
			throw new Error("Something went wrong deleting payment method");
		});
	};

	/**
	 * Gets the link to add a new payment method and opens it in a new tab
	 * @remarks Sets the tabLink state variable to the link if the tab fails to open
	 * @returns void
	 */
	const addPaymentMethod = () => {
		apiCalls.getAddCardLink(entityType, entityId).then((resp) => {
			setFetchingLink(false);
			const { link } = resp.data;
			const openLink = window.open(link, "_blank");
			!openLink && setTabLink(link);
			refreshPaymentMethods();
		});
		setFetchingLink(true);
	};

	/**
	 * Verifies the account by sending the two deposits to the backend
	 * @param firstDeposit First deposit entered by the user
	 * @param secondDeposit Second deposit entered by the user
	 * @returns void
	 * @remarks Handles the error message and title (by setting state)
	 * if applicable
	 */
	const verifyAccount = (firstDeposit: number, secondDeposit: number) => {
		setIsVerifying(true);
		apiCalls.updateACHVerification(firstDeposit, secondDeposit, accountID, entityType, entityId).then((resp) => {
			if (!isFailedApiCall(resp)) {
				setIsVerifying(false);
				return;
			}
			setErrorTitle("Verification Failed");
			setErrorMessage(resp.data.message);
			setIsVerifying(false);
			setIsError(true);
			refreshPaymentMethods();
		});
	};

	const handleSubmit = () => {
		const checkValidString = (str) => {
			const validString = (/^\d+\.\d+$/).test(str);
			if (validString) {
				return true;
			}
			return false;
		};
		if (depositOne === "0.00" || depositTwo === "0.00") {
			setErrorTitle("Error: Values haven't been changed");
			setErrorMessage("Please make sure you update the values before resubmitting the form.");
			setIsError(true);
			return;
		}
		if (!checkValidString(depositOne) || !checkValidString(depositTwo)) {
			setErrorTitle("Error: Invalid Character Entered");
			setErrorMessage(`Please make sure you enter the number(s) without a dollar sign 
			and a leading zero. (i.e. 0.20)`);
			setIsError(true);
			return;
		}
		if (
			!depositOne.includes(".")
			|| !depositTwo.includes(".")
			|| depositOne.indexOf(".") !== 1
			|| depositTwo.indexOf(".") !== 1
		) {
			setErrorTitle("Error: Incorrect Decimal Point");
			setErrorMessage(`Please make sure you enter the number(s) with a leading zero 
			and a decimal point. (i.e. 0.20)`);
			setIsError(true);
			return;
		}
		if (depositOne.indexOf("0.") !== 0 || depositTwo.indexOf("0.") !== 0) {
			setErrorTitle("Error: No Leading Zero");
			setErrorMessage(`Please make sure you enter the number(s) with a leading zero 
			and a decimal point. (i.e. 0.20)`);
			setIsError(true);
			return;
		}
		// console.log(accountID);
		// Grabbing the value entered by the customer and getting the numbers after the decimal point,
		// then trimming a zero if it's there.

		const trimZero = (number) => parseInt(number, 10);

		const depositOneShort = depositOne.substring(2);
		const depositTwoShort = depositTwo.substring(2);
		const depositOneCents = trimZero(depositOneShort);
		const depositTwoCents = trimZero(depositTwoShort);

		verifyAccount(depositOneCents, depositTwoCents);
	};

	const cardRows = cards.map((c) => {
		const cardType = c.card_type;
		const last4Digits = c.last_four_digits;
		const expired = c.is_expired;

		return (
			<tr key={last4Digits + cardType}>
				<td>{cardType}</td>
				<td>{last4Digits}</td>
				<td>
					{expired ? (
						<FontAwesomeIcon
							title="This card has expired"
							color="#e60d0d"
							transform="grow-5"
							icon={faTimesCircle}
						/>
					) : (
						<FontAwesomeIcon
							title="This card is valid and has not expired"
							color="#8dc63f"
							transform="grow-5"
							icon={faCheckCircle}
						/>
					)}
				</td>
				<td>
					<Row>
						<Col>
							<Button fullWidth color="light" onClick={() => deleteCard(c)}>
								<FontAwesomeIcon icon={faTrashAlt} />
							</Button>
						</Col>
					</Row>
				</td>
			</tr>
		);
	});

	const accountRows = accounts.map((a) => {
		const bankName = a.bank_name;
		const last4Digits = a.last_four_digits;
		let pending = false;
		let failed = false;

		if (a.status.toLowerCase().includes("pending")) {
			pending = true;
		}
		if (a.status.toLowerCase().includes("verification failed")) {
			failed = true;
		}
		return (
			<tr key={last4Digits + bankName}>
				<td>{bankName}</td>

				<td>{last4Digits}</td>
				<td>
					{pending && (
						<OverlayTrigger
							overlay={<Tooltip id="tooltip-disabled">This account needs to be verified</Tooltip>}
						>
							<FontAwesomeIcon className="text-warning" transform="grow-5" icon={faExclamationCircle} />
						</OverlayTrigger>
					)}
					{failed && (
						<OverlayTrigger
							overlay={<Tooltip id="tooltip-disabled">This account has failed verification</Tooltip>}
						>
							<FontAwesomeIcon className="text-danger" transform="grow-5" icon={faTimesCircle} />
						</OverlayTrigger>
					)}
					{!pending && !failed && (
						<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">This account is verified</Tooltip>}>
							<FontAwesomeIcon color="text-green" transform="grow-5" icon={faCheckCircle} />
						</OverlayTrigger>
					)}
				</td>
				<td>
					<Row>
						{pending && (
							<Col>
								<Button
									fullWidth
									color="light"
									title="Verify Account"
									onClick={() => {
										setAccountBeingVerified(true);
										setAccountID(a.account_id);
									}}
									type="button"
								>
									<FontAwesomeIcon icon={faExclamationTriangle} />
								</Button>
							</Col>
						)}
						<Col>
							<Button
								fullWidth
								title="Delete"
								color="light"
								onClick={() => deleteAccount(a)}
							>
								<FontAwesomeIcon icon={faTrashAlt} />
							</Button>
						</Col>
					</Row>
				</td>
			</tr>
		);
	});

	let currentItem = cards.find((c) => c.card_id === itemZid) ?? accounts.find((a) => a.account_id === itemZid);
	let currentLast4Digits = currentItem?.last_four_digits ?? "";

	return (
		// eslint-disable-next-line
		<>
			{!isEntityLoaded ? (
				<Container className="local-bootstrap">
					<Card>
						<Card.Body>
							{showEntityList !== false ? (
								<>
									<h4 className="text-green mb-4">Select Entity to View</h4>
									<EntityEmailsListing
										noHeaders
										bootstrap
										entities={showEntityList}
										fullEntityValues
										redirect={(type, entity) => {
											console.log({ entity });
											const id = entity.value;
											setSelectedEntityName(entity.label);
											navigateToEntitiesPaymentMethod(id, type);
										}}
										close={() => { /* Empty */ }}
									/>
								</>
							) : (
								<>Loading...</>
							)}
						</Card.Body>
					</Card>
				</Container>
			) : (
				<>
					<Popup
						open={isError}
						onClose={() => {
							setIsError(false);
							clearError();
							clearAccountID();
						}}
						className="mobile-modal"
					>
						<h1>{errorTitle}</h1>
						<h4>{errorMessage}</h4>
						<Container
							style={{
								position: "absolute",
								bottom: 0,
								left: 0,
								width: "100%",
								marginBottom: "1em",
								paddingLeft: "0.5em",
								paddingRight: "0.5em",
							}}
						>
							<Row xs={12}>
								<Col className="text-start">
									<Button
										color="gray"
										onClick={() => {
											clearZId();
											setIsError(false);
										}}
									>
										Cancel
									</Button>
								</Col>
							</Row>
						</Container>
					</Popup>
					<Popup
						open={accountBeingVerified}
						onClose={() => {
							clearZId();
							setAccountBeingVerified(false);
						}}
						className="mobile-modal"
					>
						<>
							<h1>Account verification</h1>
							<p>
								When adding an ACH, you were notified you would receive two micro deposits into your
								bank account. Please enter them in the order in which you received them.
							</p>
							<Container>
								<Row>
									<label htmlFor="deposit-1">Deposit #1</label>
								</Row>
								<Row>
									<Form.Control
										id="deposit-1"
										value={depositOne}
										maxLength={4}
										onChange={(e) => setDepositOne(e.target.value)}
									/>
								</Row>
								<Row>
									<label htmlFor="deposit-2">Deposit #2</label>
								</Row>
								<Row>
									<Form.Control
										id="deposit-2"
										value={depositTwo}
										maxLength={4}
										onChange={(e) => setDepositTwo(e.target.value)}
									/>
								</Row>
								<Row xs={12}>
									<Col
										className="text-start"
									>
										<Button
											color="gray"
											onClick={() => {
												clearZId();
												setAccountBeingVerified(false);
											}}
										>
											Cancel
										</Button>
									</Col>
									<Col className="text-end">
										<Button
											onClick={() => {
												handleSubmit();
											}}
											color="green"
											loading={isVerifying}
										>
											Submit
										</Button>
									</Col>
								</Row>
							</Container>
							<Container
								style={{
									position: "absolute",
									bottom: 0,
									left: 0,
									width: "100%",
									marginBottom: "1em",
									paddingLeft: "0.5em",
									paddingRight: "0.5em",
								}}
							>
								
							</Container>
						</>
					</Popup>
					<Popup open={isPopupOpen} onClose={clearZId} className="mobile-modal">
						<>
							<h1>Deleting Payment Method</h1>
							<h4>
								Are you sure you would like to delete the payment method ending in
								&nbsp;
								{currentLast4Digits}
								?
							</h4>
							<br />
							<br />
							<Container
								style={{
									position: "absolute",
									bottom: 0,
									left: 0,
									width: "100%",
									marginBottom: "1em",
									paddingLeft: "0.5em",
									paddingRight: "0.5em",
								}}
							>
								<Row xs={12}>
									<Col
										className="text-start"
									>
										<Button color="gray" onClick={clearZId}>
											Cancel
										</Button>
									</Col>
									<Col className="text-end">
										<Button
											type="button"
											className="red-button"
											onClick={deleteItem}
											loading={isDeleting}
										>
											Delete
										</Button>
									</Col>
								</Row>
							</Container>
						</>
					</Popup>

					{initFetchRan && !fetchingMethods && (
						<>
							<Container
								style={{
									margin: "auto",
									maxWidth: "75%",
									textAlign: "right",
								}}
							>
								<Row>
									<Popup className="mobile-modal" open={needsTab} onClose={clearTab}>
										<h2>Oops!</h2>
										<p>
											We tried to open a new tab and direct you to add a new payment method - that
											seemed to have failed.
										</p>
										<p>
											You can access your link by clicking
											{' '}
											<a href={tabLink}>here</a>
										</p>
										<p>If that still doesn&lsquo;t work, you can copy and paste the URL:</p>
										<input style={{ width: "60%" }} disabled value={tabLink} />
										<p>
											If for some reason this doesn&lsquo;t work either, please reach out to
											PowerShades Support.
										</p>
										<Container
											style={{
												position: "absolute",
												bottom: 0,
												left: 0,
												width: "100%",
												marginBottom: "1em",
												paddingLeft: "0.5em",
												paddingRight: "0.5em",
											}}
										>
											<Row>
												<Col className="text-start">
													<Button color="gray" onClick={clearTab}>
														Close
													</Button>
												</Col>
											</Row>
										</Container>
									</Popup>
									<Button
										color="green"
										loading={fetchingLink}
										onClick={addPaymentMethod}
										disabled={fetchingLink}
										type="button"
									>
										<FontAwesomeIcon icon={faPlus} />
										&nbsp; Add New Payment Method
									</Button>
								</Row>
							</Container>
							<br />
							<Table
								striped
								bordered
								hover
								style={{ margin: "auto", maxWidth: "75%" }}
								className="bg-white table table-striped"
							>
								<tr className="bold-row">
									{hasCards ? (
										<th colSpan={4}>Credit/Debit Card List</th>
									) : (
										<th colSpan={1}>Credit/Debit Card List</th>
									)}
								</tr>
								{hasCards ? (
									<tr>
										<th>Card Type</th>
										<th>Last Four Digits</th>
										<th>Valid</th>
										<th>Actions</th>
									</tr>
								) : (
									<tr>
										<th>No Cards Found</th>
									</tr>
								)}
								{cardRows}
							</Table>
							<br />
							<Table
								style={{ margin: "auto", maxWidth: "75%" }}
								className=" bg-white table table-striped"
							>
								<tr className="bold-row">
									{hasAccounts ? (
										<th colSpan={4}>Bank Account List</th>
									) : (
										<th colSpan={1}>Back Account List</th>
									)}
								</tr>
								{hasAccounts ? (
									<tr>
										<th>Bank Name</th>
										<th>Last Four Digits</th>
										<th>Verification Status</th>
										<th>Actions</th>
									</tr>
								) : (
									<tr>
										<th>No Bank Accounts Found</th>
									</tr>
								)}

								{accountRows}
							</Table>
						</>
					)}
				</>
			)}
		</>
	);
};

export default PaymentMethods;
