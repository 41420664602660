import React, { Component } from 'react';

class HeadRow extends Component {
	constructor(props) {
		super(props);

		this.state = {
			ISO: props.ISO
		};

		if (props.UpdateIso) {
			props.UpdateIso.push((ISO) => this.setState({ ISO }));
		}
	}

	render() {
		const ROWS = [
			...this.props.columns.map((col) => <th key={col.name}>{(() => ((typeof col.display === "function") ? col.display(this.state) : col.display))()}</th>)
		];

		return (
			<tr>
				{ROWS}
			</tr>
		);
	}
}

export default HeadRow;
