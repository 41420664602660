/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @stylistic/max-len */
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Popup from "reactjs-popup";
import { debounce } from "lodash";
import COROutdoorHooks from "../../../../Store/entities/assemblies/COROutdoorHooks";
import { AssemblyStore, CORDriveType } from "../../../../Store/entities/assemblies/types";
import { InitialAssemblyState } from "../../../../Store/entities/assemblies";
import { useQuoteById, useQuoteTypeNameByQuoteId } from "../../../../Store/entities/quotes/hooks";
import Button from "../../../../Parts/Button";
import PowerShadesTypeahead from "../../../../Parts/PowerShadesTypeahead";
import { UsePortalSelector } from "../../../../Store";
import FractionField from "../../../../Quote/NewOptionFields/FractionField";
import FabricSelectField from "../../../../Quote/NewOptionFields/FabricSelectField";
import { useOutdoorFabrics } from "../../../../Store/entities/hooks";
import { useUser } from "../../../../Store/user/hooks";
import { useAssemblyOptions } from "../../../../Store/entities/assemblyOptions/hooks";
import { COROptionLabel } from "../../../../Parts/FormParts";

type EditCOROutdoorShadeModalBaseProps = {
	isOpen: boolean;
	onClose: () => void;
	quoteId: number;
	assembly: AssemblyStore;
	setAssembly: (assembly: AssemblyStore) => void;
	showErrorsInitially?: boolean;
	headerTitleOverride?: string;
};

const EditCOROutdoorShadeModalBase = ({ isOpen, onClose, quoteId, assembly: incomingAssembly, setAssembly: incomingSetAssembly, showErrorsInitially, headerTitleOverride = "Edit Shade" }: EditCOROutdoorShadeModalBaseProps) => {
	const [assembly, setAssembly] = React.useState(incomingAssembly);

	const quoteType = useQuoteTypeNameByQuoteId(quoteId);
	const quote = useQuoteById(quoteId);

	useEffect(() => {
		setAssembly(incomingAssembly);
	}, [isOpen]);

	const submitShade = () => {
		incomingSetAssembly(assembly);
		onClose();
	};

	const corOutdoorFabrics = useOutdoorFabrics(quoteId);
	const user = useUser();

	const assemblyOptions = UsePortalSelector(useAssemblyOptions(quoteId));
	const options = COROutdoorHooks({ ...InitialAssemblyState(), ...assembly }, setAssembly, { fabrics: corOutdoorFabrics, user, quote, assemblyOptions });

	const {
		DriveType,
		HeaderMountType,
		Width,
		Height,
		HeaderType,
		Color,
		ControlSide,
		Fabric,
		// HembarType,
	
		SideGuides,
		DirectionFacing,
		Floor,
		RoomName,
		ShadeName
	} = options;

	const groupHeader = (groupName: string) => (
		<Row className="w-100 p-2" style={{ margin: "0 auto" }}>
			<div
				className={`form-group-title text-center text-green border-success border-bottom text-${quoteType}`}
			>
				<Col>
					{groupName}
				</Col>
			</div>
		</Row>
	);

	const [roomNameValue, setRoomNameValue] = useState(RoomName.getValue());
	const [shadeNameValue, setShadeNameValue] = useState(ShadeName.getValue());

	const debouncedSetRoomName = useCallback(
		debounce((value) => RoomName.setValue(value), 500),
		[options]
	);

	const debouncedSetShadeName = useCallback(
		debounce((value) => ShadeName.setValue(value), 500),
		[options]
	);

	return (
		<Popup open={isOpen} onClose={onClose} className="mobile-modal edit-modal">
			<Row>
				<h2>{headerTitleOverride}</h2>
			</Row>
			<Row>
				<Col className=" d-grid row-gap-2">
					<Row>
						<Col xs={6} className=" form-group no-bottom header  text-center">
							<COROptionLabel
								option={DriveType}
								quoteType={quoteType}
								showErrorsInitially={showErrorsInitially}
							/>
							<PowerShadesTypeahead
								id="drive-type"
								options={DriveType.options ?? []}
								onChange={(selected) => {
									if (selected.length === 0) {
										DriveType.clearValue();
										return;
									}
									const selectedObj = selected[0] as {
										value: CORDriveType;
									};

									DriveType.setValue(selectedObj.value);
								}}
								selected={[DriveType.getSelectedOption()]}
							/>
						</Col>
						<Col xs={6} className="form-group no-bottom header   text-center">
							<COROptionLabel
								option={HeaderMountType}
								quoteType={quoteType}
								showErrorsInitially={showErrorsInitially}
							/>
							<PowerShadesTypeahead
								id="header-mount-type"
								options={HeaderMountType.options ?? []}
								onChange={(selected) => {
									if (selected.length === 0) {
										HeaderMountType.clearValue();
										return;
									}
									const selectedObj = selected[0] as {
										value;
									};

									HeaderMountType.setValue(selectedObj.value);
								}}
								selected={[HeaderMountType.getSelectedOption()]}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={6} className="form-group no-bottom header" >
							<COROptionLabel
								option={Width}
								quoteType={quoteType}
								showErrorsInitially={showErrorsInitially}
							/>
							<Row>
								<FractionField
									key={`width-${assembly.sequence_id}`}
									disabled={false}
									varName="width"
									minValue={Width.minValue ?? 0}
									maxValue={Width.maxValue ?? 0}
									onBlur={(e) => Width.setValue(e)}
									value={Width.getValue()}
								/>
							</Row>
						</Col>
						<Col xs={6} className="form-group no-bottom header" >
							<COROptionLabel
								option={Height}
								quoteType={quoteType}
								showErrorsInitially={showErrorsInitially}
							/>
							<Row>
								<FractionField
									key={`height-${assembly.sequence_id}`}
									disabled={false}
									value={Height.getValue()}
									onBlur={(e) => Height.setValue(Number(e))}
									minValue={Height.minValue ?? 0}
									maxValue={Height.maxValue ?? 0}
									varName="height"
								/>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col xs={6} className="form-group no-bottom header" >
							<COROptionLabel
								option={HeaderType}
								quoteType={quoteType}
								showErrorsInitially={showErrorsInitially}
							/>
							<PowerShadesTypeahead
								id="header-type"
								options={HeaderType.options ?? []}
								onChange={(selected) => {
									if (selected.length === 0) {
										HeaderType.clearValue();
										return;
									}
									const selectedObj = selected[0] as {
										value;
									};

									HeaderType.setValue(selectedObj.value);
								}}
								selected={[HeaderType.getSelectedOption()]}
							/>
						</Col>
						<Col xs={6} className="form-group no-bottom header">
							<COROptionLabel
								option={Color}
								quoteType={quoteType}
								showErrorsInitially={showErrorsInitially}
							/>
							<PowerShadesTypeahead
								id="header-type"
								options={Color.options ?? []}
								onChange={(selected) => {
									if (selected.length === 0) {
										Color.clearValue();
										return;
									}
									const selectedObj = selected[0] as {
										value;
									};

									Color.setValue(selectedObj.value);
								}}
								selected={[Color.getSelectedOption()]}
							/>
						</Col>
						<Col xs={6} className="form-group no-bottom header pt-2">
							<COROptionLabel
								option={ControlSide}
								quoteType={quoteType}
								showErrorsInitially={showErrorsInitially}
							/>
							<PowerShadesTypeahead
								id="control-side"
								options={ControlSide.options ?? []}
								onChange={(selected) => {
									if (selected.length === 0) {
										ControlSide.clearValue();
										return;
									}
									const selectedObj = selected[0] as {
										value;
									};

									ControlSide.setValue(selectedObj.value);
								}}
								selected={[ControlSide.getSelectedOption()]}
							/>
						</Col>
					</Row>
					<Row>
						{groupHeader("Options")}
						{/* <Col xs={6} className="form-group no-bottom header">
							<COROptionLabel
								option={HembarType}
								quoteType={quoteType}
								showErrorsInitially={showErrorsInitially}
							/>
							<PowerShadesTypeahead
								id="hembar-type"
								options={HembarType.options ?? []}
								onChange={(selected) => {
									if (selected.length === 0) {
										HembarType.clearValue();
										return;
									}
									const selectedObj = selected[0] as {
										value;
									};

									HembarType.setValue(selectedObj.value);
								}}
								selected={[HembarType.getSelectedOption()]}
							/>
						</Col> */}
						<Col xs={6} className="form-group no-bottom header">
							<COROptionLabel
								option={SideGuides}
								quoteType={quoteType}
								showErrorsInitially={showErrorsInitially}
							/>
							<PowerShadesTypeahead
								id="side-guides"
								options={SideGuides.options ?? []}
								onChange={(selected) => {
									if (selected.length === 0) {
										SideGuides.clearValue();
										return;
									}
									const selectedObj = selected[0] as {
										value;
									};

									SideGuides.setValue(selectedObj.value);
								}}
								selected={[SideGuides.getSelectedOption()]}
							/>
						</Col>
					</Row>
					<Row>
						{groupHeader("Fabric")}
						<COROptionLabel
							option={Fabric}
							quoteType={quoteType}
							showErrorsInitially={showErrorsInitially}
						/>
						<FabricSelectField
							id="dropdown-full"
							clearButton
							disabled={!assembly.unlocked}
							value={Fabric.getValue() ?? ""}
							choices={Fabric.options}
							canceled={Fabric.clearValue}
							onChange={(newFabricName) => {
								newFabricName
									? Fabric.setValue(newFabricName)
									: Fabric.clearValue();
							}}
							onBlur={() => {
								console.log("blur");
							}}
						/>
					</Row>
					<Row>
						{groupHeader("Attributes")}
						<Col xs={6} className="form-group no-bottom header">
							<COROptionLabel
								option={DirectionFacing}
								quoteType={quoteType}
								showErrorsInitially={showErrorsInitially}
							/>
							<PowerShadesTypeahead
								id="direction-facing"
								options={DirectionFacing.options ?? []}
								onChange={(selected) => {
									if (selected.length === 0) {
										DirectionFacing.clearValue();
										return;
									}
									const selectedObj = selected[0] as {
										value;
									};

									DirectionFacing.setValue(selectedObj.value);
								}}
								selected={[DirectionFacing.getSelectedOption()]}
							/>
						</Col>
						<Col xs={6} className="form-group no-bottom header">
							<COROptionLabel
								option={Floor}
								quoteType={quoteType}
								showErrorsInitially={showErrorsInitially}
							/>
							<input
								className="form-control"
								type="number"
								value={Floor.getValue()}
								onChange={(e) => Floor.setValue(Number(e.target.value))}
							/>
						</Col>

						<Col xs={6} className="form-group no-bottom header pt-2">
							<COROptionLabel
								option={RoomName}
								quoteType={quoteType}
								showErrorsInitially={showErrorsInitially}
							/>
							<input
								className="form-control rounded"
								style={{ padding: "0.4rem" }}
								type="text"
								value={roomNameValue}
								onChange={(e) => {
									setRoomNameValue(e.target.value);
									debouncedSetRoomName(e.target.value);
								}}
							/>
						</Col>
						<Col xs={6} className="form-group no-bottom header pt-2">
							<COROptionLabel
								option={ShadeName}
								quoteType={quoteType}
								showErrorsInitially={showErrorsInitially}
							/>
							<input
								className="form-control rounded"
								style={{ padding: "0.4rem" }}
								type="text"
								value={shadeNameValue}
								onChange={(e) => {
									setShadeNameValue(e.target.value);
									debouncedSetShadeName(e.target.value);
								}}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
			{/* <Row> */}
			{/* <PriceErrors
                    error_messages={error_messages ?? ""}
                    msrp={msrp}
                />
                </Row> */}
			<Row className="d-flex justify-content-between p-4 w-100">
				<Col>
					<Button onClick={onClose}>Cancel</Button>
				</Col>
				<Col>
					<Button onClick={submitShade} className=" float-end">Save</Button>
				</Col>
			</Row>
		</Popup>
	);
};

export default EditCOROutdoorShadeModalBase;
