import { createAsyncThunk, createSelector } from "@reduxjs/toolkit";

import { loadStatus } from "../entitiesType";
import type { AppState } from "../..";
import type { extraEntityReducersFunction } from "../entitiesType";

import apiCalls from "../../../PowerShadesAPIFunctions";
import {
	emptyAddress,
	emptyBuyingGroup,
	emptyDistributionEmail,
	emptyDistributorMeta,
	emptyRepresentativeMeta
} from "../../../powershadesApiTypeExtensions";

import type { Dealer, DealerMeta, DealerStore, DistributorLocationTerritory, TermsOptions } from "../../../powershadesApiTypes";
import { captureSentryError, extractErrorMessage, isFailedApiCall } from "../../../psUtil";

export const initialDealerState: () => DealerStore = () => ({
	loadStatus: loadStatus.notLoaded,
	id: 0,
	name: "",
	email: "",
	phone: "",
	// billing_address_id: 0,
	pricing_multiplier: 0,
	status: "approved",
	allow_credit_card_payments: false,
	has_credit_terms: false,
	owner_distributor_id: 0,
	owner_representative_id: 0,
	sign_up_representative_id: 0,
	pricing_override: false,
	sign_up_user_id: 0,
	channel_id: 1,
	sales_tax_exempt_status: "non_exempt",
	sales_tax_exempt_reason: "",
	sales_tax_exempt_certificate_location: "",
	sales_tax_exempt_certificate_number: "",
	federal_tax_id: "",
	federal_tax_certificate_location: "",
	territory_ids: [],
	distributor: emptyDistributorMeta,
	representatives: [emptyRepresentativeMeta],
	distributor_location_territories: [] as DistributorLocationTerritory[],
	representative_territories: [
		{
			representative_id: 0,
			territory_id: 0,
		}
	],
	distribution_emails: [emptyDistributionEmail],
	buying_groups: [emptyBuyingGroup],
	buying_group_ids: [],
	user_ids: [],
	multiplier: 0.5,
	quote_ids: [],
	mailing_addresses: [],
	billing_address: emptyAddress,
	has_fabric_book_override: false,
	current_portfolio: "",
	date_application_sent: "",
});

const ensureDealerIds = createAsyncThunk<number[], void, { rejectValue: string }>(
	'entities/getDealerIds',
	async (_emptyInput, { rejectWithValue }) => {
		let ids: number[];

		try {
			const resp = await apiCalls.getDealerIds();

			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			ids = resp.data.ids;
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: 'redux'
				},
				extra: {
					redux_action: 'getDealerIds',
					file_origin: 'src/Store/entities/index.ts'
				}
			});

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}

		return ids;
	}
);

const loadDealersMeta = createAsyncThunk<{
	dealers: DealerMeta[];
	territoryPendingDealers: number;
	totalPendingDealers: number;
}, void, { rejectValue: string }>(
	'entities/getDealersMeta',
	async (_emptyInput, { rejectWithValue }) => {
		let dealers: DealerMeta[];
		let territoryPendingDealers = 0;
		let totalPendingDealers = 0;

		try {
			const resp = await apiCalls.getDealersMeta();

			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			dealers = resp.data.dealers;
			territoryPendingDealers = resp.data.territory_pending_dealer_count;
			totalPendingDealers = resp.data.pending_dealer_count;
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: 'redux'
				},
				extra: {
					redux_action: 'getDealersMeta',
					file_origin: 'src/Store/entities/index.ts'
				}
			});

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}

		return { dealers, territoryPendingDealers, totalPendingDealers };
	}
);

const loadDealerFull = createAsyncThunk<Dealer, number, { rejectValue: string }>(
	'entities/getDealerFull',
	async (dealerId, { rejectWithValue }) => {
		let dealer: Dealer;

		try {
			const resp = await apiCalls.getDealer(dealerId);

			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			dealer = resp.data.dealer;
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: 'redux'
				},
				extra: {
					redux_action: 'getDealerFull',
					file_origin: 'src/Store/entities/index.ts'
				}
			});

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}

		return dealer;
	}
);

const loadDealerTermOptions = createAsyncThunk<TermsOptions[], void, { rejectValue: string }>(
	'entities/getDealerTermOptions',
	async (_emptyInput, { rejectWithValue }) => {
		let termOptions: TermsOptions[];

		try {
			const resp = await apiCalls.getDealerTermOptions();
			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			termOptions = resp.data.terms;
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: 'redux'
				},
				extra: {
					redux_action: 'getDealerTermOptions',
					file_origin: 'src/Store/entities/index.ts'
				}
			});

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}

		return termOptions;
	}
);

// Null if bad. Otherwise, returns the updated dealer
const updateDealerFull = createAsyncThunk<Dealer | null, Dealer, {
	state: AppState;
	rejectValue: string;
}>(
	'entities/updateDealer',
	async (dealerNew, { rejectWithValue }) => {
		let dealer: Dealer;

		try {
			const resp = await apiCalls.updateDealer(dealerNew.id, dealerNew);

			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			dealer = resp.data.dealer;
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: 'redux'
				},
				extra: {
					redux_action: 'updateDealerFull',
					file_origin: 'src/Store/entities/index.ts'
				}
			});

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}

		return dealer;
	}
);

const updateDealerTaxInfo = createAsyncThunk<Dealer | null, Dealer, {
	state: AppState;
	rejectValue: string;
}>(
	'entities/updateDealerTaxInfo',
	async (dealerNew, { rejectWithValue }) => {
		let dealer: Dealer;

		try {
			const taxInfo: Partial<Dealer> = {
				sales_tax_exempt_status: dealerNew.sales_tax_exempt_status,
				sales_tax_exempt_reason: dealerNew.sales_tax_exempt_reason,
				sales_tax_exempt_certificate_number: dealerNew.sales_tax_exempt_certificate_number,
				sales_tax_exempt_certificate_location: dealerNew.sales_tax_exempt_certificate_location,
			};

			if (dealerNew.federal_tax_id) {
				taxInfo.federal_tax_id = dealerNew.federal_tax_id;
			}

			if (dealerNew.federal_tax_certificate_location) {
				taxInfo.federal_tax_certificate_location = dealerNew.federal_tax_certificate_location;
			}

			const resp = await apiCalls.updateDealerTaxInfo(dealerNew.id, taxInfo);

			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			dealer = { ...dealerNew, ...resp.data.dealer };
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: 'redux'
				},
				extra: {
					redux_action: 'updateDealerTaxInfo',
					file_origin: 'src/Store/entities/index.ts'
				}
			});

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}

		return dealer;
	}
);

const approveDealer = createAsyncThunk<boolean, number, { rejectValue: string }>(
	'entities/approveDealer',
	async (dealerId, { rejectWithValue }) => {
		try {
			const resp = await apiCalls.activateDealer(dealerId);

			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			return resp.data.success;
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: 'redux'
				},
				extra: {
					redux_action: 'approveDealer',
					file_origin: 'src/Store/entities/index.ts'
				}
			});

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}
	}
);

const declineDealer = createAsyncThunk<boolean, number, { rejectValue: string }>(
	'entities/declineDealer',
	async (dealerId, { rejectWithValue }) => {
		try {
			const resp = await apiCalls.deactivateDealer(dealerId);

			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			return resp.data.success;
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: 'redux'
				},
				extra: {
					redux_action: 'declineDealer',
					file_origin: 'src/Store/entities/index.ts'
				}
			});

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}
	}
);

const deactivateDealer = createAsyncThunk<boolean, number, { rejectValue: string }>(
	'entities/deactivateDealer',
	async (dealerId, { rejectWithValue }) => {
		try {
			const resp = await apiCalls.deactivateDealer(dealerId);

			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			return resp.data.success;
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: 'redux'
				},
				extra: {
					redux_action: 'deactivateDealer',
					file_origin: 'src/Store/entities/index.ts'
				}
			});

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}
	}
);

const dealerBuilder: extraEntityReducersFunction = (builder) => {
	builder.addCase(ensureDealerIds.pending, (entities) => {
		entities.dealers.loadStatus = loadStatus.loading;
	});

	builder.addCase(ensureDealerIds.fulfilled, (entities, action) => {
		const ids = action.payload;
		const initialDealerStateObj = initialDealerState();
		ids.forEach((id) =>
			entities.dealers[id] = ({
				...initialDealerStateObj,
				id,
				loadStatus: loadStatus.needsLoaded
			}));
	});

	builder.addCase(loadDealersMeta.fulfilled, (entitiesState, action) => {
		const dealersObj = action.payload;

		const { dealers } = dealersObj;

		dealers.forEach((dealer) => {
			const workingDealer = entitiesState.dealers[dealer.id];
			if (!workingDealer) {
				entitiesState.dealers[dealer.id] = ({
					...initialDealerState(),
					...dealer,
					loadStatus: loadStatus.metaLoaded
				});
			} else if (workingDealer.loadStatus <= 4) {
				entitiesState.dealers[dealer.id] = ({
					...workingDealer,
					...dealer,
					loadStatus: loadStatus.metaLoaded
				});
			}
		});

		entitiesState.dealers.totalPendingDealers = dealersObj.totalPendingDealers;
		entitiesState.dealers.territoryPendingDealers = dealersObj.territoryPendingDealers;

		entitiesState.dealers.loadStatus = loadStatus.metaLoaded;
		entitiesState.dealers.lastMetaLoadedTime = Date.now();
	});

	builder.addCase(loadDealersMeta.pending, (entitiesState, _) => {
		entitiesState.dealers.loadStatus = loadStatus.loading;
	});

	builder.addCase(loadDealerFull.pending, (entitiesState, action) => {
		const id = action.meta.arg;
		const workingDealer = ({
			...initialDealerState(),
			...entitiesState.dealers[id],
			loadStatus: loadStatus.loading,
			id
		});

		const workingDict = { ...entitiesState.dealers, [id]: workingDealer };

		entitiesState.dealers = workingDict;
	});

	builder.addCase(loadDealerFull.fulfilled, (entitiesState, action) => {
		const dealer = action.payload;

		entitiesState.dealers[dealer.id] = ({
			...dealer, loadStatus: loadStatus.fullyLoaded
		});
	});

	builder.addCase(loadDealerTermOptions.fulfilled, (entitiesState, action) => {
		const termOptions = action.payload;

		entitiesState.termsOptions.list = termOptions;
		entitiesState.termsOptions.loadStatus = loadStatus.fullyLoaded;
	});

	builder.addCase(updateDealerFull.pending, (entitiesState, action) => {
		const { arg: dealer } = action.meta;

		if (dealer) {
			entitiesState.dealers[dealer.id] = ({
				...dealer, loadStatus: loadStatus.fullyLoaded
			});
		}

		return entitiesState;
	});

	builder.addCase(updateDealerFull.fulfilled, (entitiesState, action) => {
		const dealer = action.payload;

		if (dealer) {
			entitiesState.dealers[dealer.id] = ({
				...dealer, loadStatus: loadStatus.fullyLoaded
			});
		}

		return entitiesState;
	});

	builder.addCase(updateDealerTaxInfo.fulfilled, (entitiesState, action) => {
		const updatedDealer = action.payload;
		
		if (updatedDealer) {
			entitiesState.dealers[updatedDealer.id] = {
				...entitiesState.dealers[updatedDealer.id],
				...updatedDealer,
				loadStatus: loadStatus.fullyLoaded,
			};
		}
	});
	
	builder.addCase(updateDealerTaxInfo.pending, (entitiesState, action) => {
		const { id } = action.meta.arg;
		const workingDealer = entitiesState.dealers[id];
	
		if (workingDealer) {
			entitiesState.dealers[id] = {
				...workingDealer,
				loadStatus: loadStatus.loading,
			};
		}
	});
	
	builder.addCase(updateDealerTaxInfo.rejected, (entitiesState, action) => {
		const { id } = action.meta.arg;
	
		const workingDealer = entitiesState.dealers[id];
	
		if (workingDealer) {
			entitiesState.dealers[id] = {
				...workingDealer,
				loadStatus: loadStatus.notLoaded
			};
		}
	});
	
	builder.addCase(approveDealer.pending, (entitiesState, action) => {
		const dealerId = action.meta.arg;

		const workingDealer = entitiesState.dealers[dealerId];

		if (!workingDealer) return;

		entitiesState.dealers[dealerId] = ({
			...workingDealer,
			loadStatus: loadStatus.loading
		});
	});
	builder.addCase(approveDealer.fulfilled, (entitiesState, action) => {
		const success = action.payload;

		if (!success) return;

		const dealerId = action.meta.arg;

		const workingDealer = entitiesState.dealers[dealerId];

		if (!workingDealer) return;

		entitiesState.dealers[dealerId] = ({
			...workingDealer,
			status: "approved",
			loadStatus: loadStatus.fullyLoaded
		});
	});
	builder.addCase(declineDealer.pending, (entitiesState, action) => {
		const dealerId = action.meta.arg;

		const workingDealer = entitiesState.dealers[dealerId];

		if (!workingDealer) return;

		entitiesState.dealers[dealerId] = ({
			...workingDealer,
			loadStatus: loadStatus.loading
		});
	});
	builder.addCase(declineDealer.fulfilled, (entitiesState, action) => {
		const success = action.payload;

		if (!success) return;

		const dealerId = action.meta.arg;

		const workingDealer = entitiesState.dealers[dealerId];

		if (!workingDealer) return;

		entitiesState.dealers[dealerId] = ({
			...workingDealer,
			status: "declined",
			loadStatus: loadStatus.fullyLoaded
		});
	});
	builder.addCase(deactivateDealer.pending, (entitiesState, action) => {
		const dealerId = action.meta.arg;

		const workingDealer = entitiesState.dealers[dealerId];

		if (!workingDealer) return;

		entitiesState.dealers[dealerId] = ({
			...workingDealer,
			loadStatus: loadStatus.loading
		});
	});
	builder.addCase(deactivateDealer.fulfilled, (entitiesState, action) => {
		const success = action.payload;

		if (!success) return;

		const dealerId = action.meta.arg;

		const workingDealer = entitiesState.dealers[dealerId];

		if (!workingDealer) return;

		entitiesState.dealers[dealerId] = ({
			...workingDealer,
			status: "declined",
			loadStatus: loadStatus.fullyLoaded
		});
	});
};

const selectDealerById = (dealerId: number) => (state: AppState) => {
	const { dealers } = state.entity;

	const workingDealer = dealers[dealerId];

	return workingDealer;
};

const selectDealerTerms = (state: AppState) => {
	const workingTerms = state.entity.termsOptions.list ?? [];

	return workingTerms;
};

const selectDealerList = createSelector(
	(state: AppState) => state.entity.dealers,
	(dealers) => Object.values(dealers).filter((d: any) => d && d.loadStatus !== loadStatus.notLoaded && typeof d === "object" // 5 minutes * 60 seconds per mintute * 1000 milliseconds per second
	) as DealerStore[]
);

const selectPendingDealerCount = createSelector(
	(state: AppState) => state.entity.dealers,
	(dealers) => ({
		total: dealers.totalPendingDealers,
		territory: dealers.territoryPendingDealers
	})
);

const selectDealerByTerritory = (territoryId: number) =>
	createSelector(selectDealerList, (dealers) => {
		const filteredDealers = dealers.filter((d) => (d?.loadStatus !== loadStatus.notLoaded) && d?.territory_ids?.includes(territoryId));
		return filteredDealers;
	});

export {
	dealerBuilder,
	ensureDealerIds,
	loadDealersMeta,
	loadDealerFull,
	loadDealerTermOptions,
	selectDealerById,
	selectDealerTerms,
	selectDealerList,
	selectDealerByTerritory,
	approveDealer,
	declineDealer,
	deactivateDealer,
	updateDealerFull,
	updateDealerTaxInfo,
	selectPendingDealerCount
};
