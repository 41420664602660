import Cassette_3_FW_Single from '../../assets/img/Cassette_3_FW_Single.png';
import Fascia_3_Single from '../../assets/img/Fascia_3_Single.png';
import Fascia_5_Dual from '../../assets/img/Fascia_5_Dual.png';
import Fascia_5_Single from '../../assets/img/Fascia_5_Single.png';
import Open_Roll_Large_Dual from '../../assets/img/Open_Roll_Large_Dual.png';
import Open_Roll_Large_Single from '../../assets/img/Open_Roll_Large_Single.png';
import Open_Roll_Medium_Dual from '../../assets/img/Open_Roll_Medium_Dual.png';
import Open_Roll_Medium_Single from '../../assets/img/Open_Roll_Medium_Single.png';
import Open_Roll_Small_Single from '../../assets/img/Open_Roll_Small_Single.png';
import Fascia_7_Dual from '../../assets/img/Fascia_7_Dual.png';

const HeaderData = () => {
	const bothOpen = [
		{
			name: 'Open Roll Medium', value: 'OR_1234', conditional: (shade) => true
		},
	];

	bothOpen.forEach((h) => {
		h.image_name = 'Open_Roll_Medium_Single.png';
		h.dual_image_name = 'Open_Roll_Medium_Dual.png';
	});

	const threeInchCassette = [
		{
			optGroup: 'Round Fabric Insert Cassette',
			value: 'RC3051-A',
			name: 'Round Fabric Insert Cassette Anodized',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Round Fabric Insert Cassette',
			value: 'RC3051-BK',
			name: 'Round Fabric Insert Cassette Black',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Round Fabric Insert Cassette',
			value: 'RC3051-DBR',
			name: 'Round Fabric Insert Cassette Bronze',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Round Fabric Insert Cassette',
			value: 'RC3051-LB',
			name: 'Round Fabric Insert Cassette Off-White',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Round Fabric Insert Cassette',
			value: 'RC3051-W',
			name: 'Round Fabric Insert Cassette White',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Round Fabric Insert Cassette',
			value: 'RC3051-AN',
			name: 'Round Fabric Insert Cassette Anthracite',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Square Cassette',
			value: 'RC3029-A',
			name: 'Square Cassette 3 in. Anodized',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Square Cassette',
			value: 'RC3029-BK',
			name: 'Square Cassette 3 in. Black',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Square Cassette',
			value: 'RC3029-W',
			name: 'Square Cassette 3 in. White',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Square Cassette',
			value: 'RC3029-LB',
			name: 'Square Cassette 3 in. Off-White',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Flat Fabric Wrapped Cassette',
			value: 'RB88-0150-050490',
			name: 'Flat Fabric Wrapped Cassette Black',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Flat Fabric Wrapped Cassette',
			value: 'RB88-0150-069490',
			name: 'Flat Fabric Wrapped Cassette White',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Flat Fabric Wrapped Cassette',
			value: 'RB88-0150-020490',
			name: 'Flat Fabric Wrapped Cassette Anodized',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Flat Fabric Wrapped Cassette 3 in.',
			value: 'RB88-0150-080490',
			name: 'Flat Fabric Wrapped Cassette Bronze',
			conditional: (shade) => !shade.isDualShade(),
		},
		{
			optGroup: 'Flat Fabric Wrapped Cassette 3 in.',
			value: 'RB88-0150-414490',
			name: 'Flat Fabric Wrapped Cassette Off-White',
			conditional: (shade) => !shade.isDualShade(),
		},
	];

	threeInchCassette.forEach((h) => {
		switch (h.optGroup) {
			case "Round Fabric Insert Cassette":
				h.image_name = 'Cassette_3_FW_Single.png';
				break;
			case "Square Cassette":
				h.image_name = 'Cassette_3_Square_Single.png';
				break;
			case "Flat Fabric Wrapped Cassette":
				h.image_name = 'Cassette_3_FFW_Single.png';
				break;
			default:
				h.image_name = 'Cassette_3_FW_Single.png';
				break;
		}
	});

	const threeInchFascia = [
		{
			optGroup: 'Fascia',
			value: 'RC3080-A',
			name: 'Fascia 3 in. Anodized',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC3080-AN',
			name: 'Fascia 3 in. Anthracite',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC3080-BK',
			name: 'Fascia 3 in. Black',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC3080-DBR',
			name: 'Fascia 3 in. Bronze',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC3080-LB',
			name: 'Fascia 3 in. Off-White',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC3080-W',
			name: 'Fascia 3 in. White',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
	];

	const fourInchFasciaCou = [
		{
			optGroup: 'Fascia',
			value: 'RC3158-A',
			name: 'Fascia 4 in. Anodized',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC3158-AN',
			name: 'Fascia 4 in. Anthracite',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC3158-BK',
			name: 'Fascia 4 in. Black',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC3158-DBR',
			name: 'Fascia 4 in. Bronze',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC3158-LB',
			name: 'Fascia 4 in. Off-White',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC3158-W',
			name: 'Fascia 4 in. White',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
	];

	threeInchFascia.forEach((h) => {
		h.image_name = 'Fascia_3_Single.png';
	});

	const fiveInchFascia = [

		{
			optGroup: 'Fascia',
			value: 'RC4039-A',
			name: 'Fascia 5 in. Anodized',
			// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC4039-AN',
			name: 'Fascia 5 in. Anthracite',
			// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC4039-BK',
			name: 'Fascia 5 in. Black',
			// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC4039-DBR',
			name: 'Fascia 5 in. Bronze',
			// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC4039-LB',
			name: 'Fascia 5 in. Off-White',
			// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC4039-W',
			name: 'Fascia 5 in. White',
			// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
		},
	];

	fiveInchFascia.forEach((h) => {
		h.image_name = 'Fascia_5_Single.png';
		h.dual_image_name = 'Fascia_5_Dual.png';
	});

	const standardOpen = [
		{
			name: 'Open Roll Small',
			value: 'OR_0002',
			conditional: (shade) => shade.valMatch('shade_type', 'Manual') && !shade.isDualShade()
		},
	];

	standardOpen.forEach((h) => {
		h.image_name = 'Open_Roll_Small_Single.png';
	});

	const fourInchCassette = [
		{
			optGroup: 'Cassette (Fabric Insert Included)',
			value: 'RS-SU1',
			name: 'Cassette 4 in. White',
			conditional: (shade) => false
		},
		{
			optGroup: 'Cassette (Fabric Insert Included)',
			value: 'RS-SU2',
			name: 'Cassette 4 in. Bronze',
			conditional: (shade) => false
		},
		{
			optGroup: 'Cassette (Fabric Insert Included)',
			value: 'RS-SU3',
			name: 'Cassette 4 in. Alabaster',
			conditional: (shade) => false
		},
		{
			optGroup: 'Cassette (Fabric Insert Included)',
			value: 'RS-SU4',
			name: 'Cassette 4 in. Anodized',
			conditional: (shade) => false
		},
		{
			optGroup: 'Cassette (Fabric Insert Included)',
			value: 'RS-SU5',
			name: 'Cassette 4 in. Black',
			conditional: (shade) => false
		}
	];

	// fourInchCassette.forEach(h => {
	//     h.image_name = 'Cassette_3_FW_Single.png'
	// })
	const mediumLargeOpen = [
		{
			name: 'Open Roll Large (Single)',
			value: 'OR_COULLISE_LARGE_BALANCED',
			conditional: (shade) => {
				if (!shade) {
					return true;
				}

				return !shade.isDualShade();
			},
			onDeselect: (quote, shade) => { emptyHardwareColor(shade); }
		}];

	const heavyOpen = [
		{
			name: 'Open Roll Large', value: 'OR_5678', conditional: (shade) => shade.valMatch('shade_type', 'Motorized')
		},
		{
			name: 'Open Roll Extra Large', value: 'OR_9876', conditional: (shade) => shade.valMatch('shade_type', 'Motorized')
		}
	];

	heavyOpen.forEach((h) => {
		if (h.name === "Open Roll Large") {
			h.image_name = 'Open_Roll_Large_Single.png';
			h.dual_image_name = 'Open_Roll_Large_Dual.png';
		} else if (h.name === "Open Roll Extra Large") {
			h.image_name = "Open_Roll_Extra_Large_Single.png";
			h.dual_image_name = "Open_Roll_Extra_Large_Dual.png";
		}
	});

	mediumLargeOpen.forEach((h) => {
		h.image_name = 'Open_Roll_Medium_Large_Single';
	});

	const fourInchFascia = [
		{
			optGroup: 'Fascia',
			value: 'RS-SC1',
			name: 'Fascia 4 in. White',
			conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
		},
		{
			optGroup: 'Fascia',
			value: 'RS-SC2',
			name: 'Fascia 4 in. Bronze',
			conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
		},
		{
			optGroup: 'Fascia',
			value: 'RS-SC3',
			name: 'Fascia 4 in. Alabaster',
			conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
		},
		{
			optGroup: 'Fascia',
			value: 'RS-SC4',
			name: 'Fascia 4 in. Anodized',
			conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
		},
		{
			optGroup: 'Fascia',
			value: 'RS-SC5',
			name: 'Fascia 4 in. Black',
			conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
		},
	];

	// threeInchCassette.forEach(h => {
	//     h.image_name = 'Cassette_3_FW_Single.png'
	// })

	const fiveAndAHalfInchFascia = [
		{
			optGroup: 'Fascia',
			value: 'RS-SV4',
			name: 'Fascia 5.5 in. Anodized',
			conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
		},
		{
			optGroup: 'Fascia',
			value: 'RS-SV2',
			name: 'Fascia 5.5 in. Bronze',
			conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
		},
		{
			optGroup: 'Fascia',
			value: 'RS-SV1',
			name: 'Fascia 5.5 in. White',
			conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
		},
	];

	// threeInchCassette.forEach(h => {
	//     h.image_name = 'Cassette_3_FW_Single.png'
	// })

	const sevenInchFascia = [

		{
			optGroup: 'Fascia',
			value: 'RS-SK4',
			name: 'Fascia 7 in. Anodized',
			conditional: (shade) => shade.isDualShade() && shade.valMatch('shade_type', 'Motorized')
		},
		{
			optGroup: 'Fascia',
			value: 'RS-SK5',
			name: 'Fascia 7 in. Black',
			conditional: (shade) => shade.isDualShade() && shade.valMatch('shade_type', 'Motorized')
		},
		{
			optGroup: 'Fascia',
			value: 'RS-SK2',
			name: 'Fascia 7 in. Bronze',
			conditional: (shade) => shade.isDualShade() && shade.valMatch('shade_type', 'Motorized')
		},
		{
			optGroup: 'Fascia',
			value: 'RS-SK1',
			name: 'Fascia 7 in. White',
			conditional: (shade) => shade.isDualShade() && shade.valMatch('shade_type', 'Motorized')
		},
	];

	sevenInchFascia.forEach((h) => {
		h.dual_image_name = 'Fascia_7_Dual.png';
	});

	const items = [
		...standardOpen, // No Dual
		...bothOpen, // Dual
		...heavyOpen, // Dual
		...threeInchFascia, // No Dual
		...fourInchFasciaCou, // No Dual
		...fourInchFascia, // No Dual
		...fiveInchFascia, // Dual
		...fiveAndAHalfInchFascia, // Dual
		...sevenInchFascia, // Dual
		...threeInchCassette, // No Dual
		...fourInchCassette // No dual
	];

	return items;
};

export default HeaderData;
