import React, { useState } from "react";
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { RMAItems, RMAParts } from "./types";
import { useActiveRMATypes, useRMADepartmentList, useRMARootCauseList } from "../../../../Store/entities/rmaTypes/hooks";
import alert from "../../../../Store/ui/alert";
import { AssemblyStore } from "../../../../Store/entities/assemblies/types";
import AssembliesPartsDropdown from "./AssembliesPartsDropdown";

type RMAListItemProps = {
	rma?: RMAItems;
	primaryRmas?: RMAItems[] | undefined;
	assemblies: AssemblyStore[];
	quoteId: number;
	activateItem: (item: string) => void;
	saveRma: (rmaState: RMAItems) => void;
	deleteRma?: (rmaId: number) => void;
};

const RMAListItem = ({ rma, primaryRmas, quoteId, activateItem, saveRma, deleteRma, assemblies }: RMAListItemProps) => {
	const initialRmaState: RMAItems = rma || {
		quoteId,
		title: "",
		rmaTypeId: "",
		rootCauseId: "",
		departmentId: "",
		sequenceId: +"",
		parts: [] as RMAParts[]
	};
  
	const [editing, setEditing] = useState(!rma);
	const [rmaState, setRmaState] = useState(initialRmaState);

	const activeRmaTypeOptions = useActiveRMATypes();
	const activeDepartmentOptions = useRMADepartmentList();
	const activeRootCauseOptions = useRMARootCauseList();

	const selectedRmaType = activeRmaTypeOptions.filter((to) => to.id === +rmaState.rmaTypeId)[0]?.name;
	const selectedDepartment = activeDepartmentOptions.filter((dp) => dp.id === +rmaState.departmentId)[0]?.name;
	const selectedRootCause = activeRootCauseOptions.filter((rc) => rc.id === +rmaState.rootCauseId)[0]?.name;

	const updateRmaState = (e) => {
		const { name, value } = e.target;
		if (name === "parentRmaId") {
			const parentRmaId = +value;
			const parentRmaTitle = (primaryRmas && primaryRmas.find((prma) => prma.id === parentRmaId)?.title) || '';
			setRmaState({ ...rmaState, parentRmaId, parentRmaTitle });
		} else {
			setRmaState({ ...rmaState, [name]: value });
		}
	};
	
	const handleAccordion = () => {
		activateItem(`RMA-${rma?.id || "add"}`);
		if (!rma) {
			setTimeout(() => setRmaState(initialRmaState), 200);
		}
	};

	const handleCancel = () => {
		setRmaState(initialRmaState);
		setEditing(false);
	};

	const handleSubmit = () => {
		saveRma(rmaState);
		setEditing(!rma);
		!rma && setRmaState(initialRmaState);
	};

	const handleDelete = () => {
		activateItem(`RMA-${rma?.id || "add"}`);
		deleteRma && deleteRma(rma?.id as number);
	};

	const parentRmaOptionsRows = primaryRmas && primaryRmas
		.sort((a, b) => a.title.localeCompare(b.title))
		.map((prma) => (
			<option key={prma.id} value={prma.id}>
				{prma.title}
			</option>
		));

	const rmaTypeOptionsRows = activeRmaTypeOptions
		.sort((a, b) => a.name.localeCompare(b.name))
		.map((rmaTypeOption) => (
			<option key={rmaTypeOption.name} value={rmaTypeOption.id}>
				{rmaTypeOption.name}
			</option>
		));

	const departmentOptionsRows = activeDepartmentOptions
		.sort((a, b) => a.name.localeCompare(b.name))
		.map((department) => (
			<option key={department.name} value={department.id}>
				{department.name}
			</option>
		));

	const rootCauseOptionsRows = activeRootCauseOptions
		.sort((a, b) => a.name.localeCompare(b.name))
		.map((rootCause) => (
			<option key={rootCause.name} value={rootCause.id}>
				{rootCause.name}
			</option>
		));

	return (
		<Accordion.Item eventKey={`RMA-${rma?.id || "add"}`}>
			<Accordion.Header onClick={handleAccordion} id={rma ? "" : "add-rma-btn"} className="rma">
				<span>{rma ? rmaState.title : "Add RMA"}</span>
			</Accordion.Header>
			<Accordion.Body className="d-grid row-gap-2">
				{primaryRmas && (
					<Row>
						{editing ? (
							<Form.Label>
								Parent RMA
								<Form.Select
									name="parentRmaId"
									value={rmaState.parentRmaId ? rmaState.parentRmaId : ""}
									onChange={updateRmaState}
								>
									<option value="">Select Parent RMA</option>
									{parentRmaOptionsRows}
								</Form.Select>
							</Form.Label>

						) : (
							<h4>{`Sub RMA of ${rmaState.parentRmaTitle}`}</h4>
						)}
					</Row>
				)}
				<Row>
					{editing ? (
						<Form.Label>
							RMA Type
							<Form.Select name="rmaTypeId" value={rmaState.rmaTypeId} onChange={updateRmaState}>
								<option value="">Select RMA Type</option>
								{rmaTypeOptionsRows}
							</Form.Select>
						</Form.Label>
					) : (
						<h4>{selectedRmaType || ""}</h4>
					)}
				</Row>
				<Row>
					<Form.Label>
						Department:&nbsp;
						{editing ? (
							<Form.Select name="departmentId" value={rmaState.departmentId} onChange={updateRmaState}>
								<option value="">Select Department</option>
								{departmentOptionsRows}
							</Form.Select>
						) : (
							<span>{selectedDepartment || ""}</span>
						)}
					</Form.Label>
				</Row>
				<Row>
					<Form.Label>
						Root Cause:&nbsp;
						{editing ? (
							<Form.Select name="rootCauseId" value={rmaState.rootCauseId} onChange={updateRmaState}>
								<option value="">Select Root Cause</option>
								{rootCauseOptionsRows}
							</Form.Select>
						) : (
							<span>{selectedRootCause || ""}</span>
						)}
					</Form.Label>
				</Row>
				<AssembliesPartsDropdown
					rma={rmaState}
					setRma={setRmaState}
					editing={rma ? editing : true}
					assemblies={assemblies}
				/>
				{editing ? (
					<Row className="pt-2">
						{rma && (
							<Col>
								<Button className="float-start text-white" onClick={handleCancel}>
									Cancel
								</Button>
							</Col>
						)}
						<Col>
							<Button className={`float-end text-white ${!rma && "w-100"}`} onClick={handleSubmit}>
								Submit
							</Button>
						</Col>
					</Row>
				) : (
					<Row>
						<Col>
							<Button className="p-1 px-2 text-white" onClick={() => setEditing(true)}>
								<FontAwesomeIcon icon={faEdit} size="sm" />
							</Button>
						</Col>
						<Col>
							<Button
								className="p-1 px-2 float-end border-secondary bg-body-secondary text-body-secondary"
								onClick={() =>
									alert({
										title: `Delete ${rma?.parts.map((part) => part.sku).join(", ") || "RMA"}`,
										text: "Are you sure you want to remove this RMA?",
										icon: "warning",
										onConfirm: handleDelete,
										confirmButtonText: "Delete",
										cancelButtonText: "Cancel",
									})}
							>
								<FontAwesomeIcon icon={faTrash} size="sm" />
							</Button>
						</Col>
					</Row>
				)}
			</Accordion.Body>
		</Accordion.Item>
	);
};

export default RMAListItem;
