// import { getBaseENV } from "./GlobalTypesAccess.js";

type WindowPS = {
    ROOT: {
        loadContent: (path: string) => void;
    };
    toast: (toastOptions: { text?: string, type?: string, duration?: number, id?: number }) => void;

    process: {

    };
}

const getWindowPS: () => WindowPS = () => window as unknown as WindowPS;

// class ProcessPS {
//     env : {[key: string] : string};
// }

// let env = getBaseENV();

// declare global {
//     interface process {
//         GITHUB_AUTH_TOKEN: string;
//         NODE_ENV: 'development' | 'production';
//         PORT?: string;
//         PWD: string;
//         env: { [key: string]: string };
//     }
// }

// let getProcessPS: () => ProcessPS = () => {
//     return (process as ProcessPS);
// }

export { getWindowPS };
