import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { PortalShadeOption } from "../Store/entities/assemblies/types";

type ImageTooltipProps = {
	option: PortalShadeOption<any>;
	label: string;
};

const ImageToolTip = ({ option, label }: ImageTooltipProps) => {
	// const importAllImages = (r: __WebpackModuleApi.RequireContext) => r.keys().map((key: any) => r(key).default);
	const [open, setOpen] = useState(false);
	// const [image, setImage] = useState("");

	// const images = {
	// 	header:
    //     importAllImages(require.context('../assets/img/outdoor_diagrams/header', false, /\.(png|jpe?g|svg)$/)),
	// 	headerMount:
    //     importAllImages(require.context('../assets/img/outdoor_diagrams/header_mount', false, /\.(png|jpe?g|svg)$/)),
	// 	sideGuide:
    //     importAllImages(require.context('../assets/img/outdoor_diagrams/side_guide', false, /\.(png|jpe?g|svg)$/)),
	// 	sideGuideMount:
    //     importAllImages(require.context('../assets/img/outdoor_diagrams/side_guide_mount', false, /\.(png|jpe?g|svg)$/))
	// };
	const pictureOrText = option?.valuePopup ?? null;
    
	// useEffect(() => {
	// 	const headerMountValue = HeaderMountType?.getSelectedOption()?.value || "";
	// 	const headerValue = HeaderType?.getSelectedOption()?.value || "";
	// 	const sideGuideMountValue = SideGuideMountTypes?.getSelectedOption()?.value || "";
	// 	const sideGuidesValue = SideGuides?.getSelectedOption()?.value || "";


	// 	switch (label) {
	// 		case "Header Mount":
	// 			// Set header mount popup
	// 			switch (headerMountValue) {
	// 				case "Face":
	// 					setImage(headerValue.includes("Box")
	// 						? images.headerMount.find((img) => img.includes("box-face"))
	// 						: images.headerMount.find((img) => img.includes("open-roll-face")));
	// 					break;
	// 				case "Side":
	// 					setImage(headerValue.includes("Box")
	// 						? images.headerMount.find((img) => img.includes("box-side"))
	// 						: images.headerMount.find((img) => img.includes("open-roll-side")));
	// 					break;
	// 				case "Top":
	// 					setImage(headerValue.includes("Box")
	// 						? images.headerMount.find((img) => img.includes("box-top"))
	// 						: images.headerMount.find((img) => img.includes("open-roll-top")));
	// 					break;
	// 				default:
	// 					setImage("");
	// 					break;
	// 			}
	// 			break;
	// 		case "Header":
	// 			// Set header popup
	// 			switch (headerValue) {
	// 				case "Box 190":
	// 					setImage(images.header.find((img) => img.includes("box-extreme")));
	// 					break;
	// 				case "Box 120":
	// 					setImage(images.header.find((img) => img.includes("box-header")));
	// 					break;
	// 				case "Open Roll Extreme":
	// 					setImage(images.header.find((img) => img.includes("open-roll-extreme")));
	// 					break;
	// 				case "Open Roll 120":
	// 					setImage(images.header.find((img) => img.includes("open-roll-header")));
	// 					break;
	// 				default:
	// 					setImage("");
	// 					break;
	// 			}
	// 			break;
	// 		case "Side Guide Mount Types":
	// 			// Set side guide mount popup
	// 			switch (sideGuideMountValue) {
	// 				case "Floor":
	// 					setImage(images.sideGuideMount.find((img) => img.includes("floor-fix")));
	// 					break;
	// 				case "Side":
	// 					setImage(images.sideGuideMount.find((img) => img.includes("side-fix")));
	// 					break;
	// 				case "Face Fixed":
	// 					setImage(sideGuidesValue.includes("Zip")
	// 						? images.sideGuideMount.find((img) => img.includes("zipper-track-face-fix"))
	// 						: images.sideGuideMount.find((img) => img.includes("face-fix")));
	// 					break;
	// 				default:
	// 					setImage("");
	// 					break;
	// 			}
	// 			break;
	// 		case "Side Guides":
	// 			// Set side guides popup
	// 			switch (sideGuidesValue) {
	// 				case "Wire":
	// 					setImage(images.sideGuide.find((img) => img.includes("wire")));
	// 					break;
	// 				case "Zip":
	// 					setImage(images.sideGuide.find((img) => img.includes("ziptrack")));
	// 					break;
	// 				case "Channel":
	// 					setImage(images.sideGuide.find((img) => img.includes("channel")));
	// 					break;
	// 				default:
	// 					setImage("");
	// 					break;
	// 			}
	// 			break;
	// 		default:
	// 			setImage("");
	// 			break;
	// 	}
	// }, [open]);

	return (
		<>
			<FontAwesomeIcon
				icon={faInfoCircle}
				onMouseOver={() => setOpen(true)}
				onMouseLeave={() => setOpen(false)}
				className="position-absolute end-0"
			/>
			<div
				className={open && pictureOrText ? "h-auto position-absolute border-gray end-0" : "d-none"}
				style={{ border: "1px solid", zIndex: "99999", width: "15em" }}
			>
				<img
					className="w-100 border-3 border-solid border-light position-relative z-3"
					style={{ border: "20px  solid" }}
					src={pictureOrText}
					alt={label}
					onMouseOver={() => setOpen(true)}
					onFocus={() => setOpen(true)}
					onMouseLeave={() => setOpen(false)}
				/>
			</div>
		</>
	);
};

export default ImageToolTip;
