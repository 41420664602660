const SavingWheel = (props) => {
	if (props.status < 1 || props.status > 3) return null;

	const cases = {

		1: {
			text: 'Saving',
			classes: 'saving green-text'
		},
		
		2: {
			text: 'Saved',
			classes: 'saved green-text'
		},

		3: {
			text: 'Error Saving',
			classes: 'error'
		}
	
	};

	return (

		<div style={{
			justifyContent: 'flex-end', flexDirection: 'row', justifyContent: 'flex-end'
		}}
		>

			<span
				className={`saving-notifier ${cases[props.status].classes}`}
				style={
					{
						alignSelf: 'flex-start',
						marginRight: 'unset',
						transform: 'unset',
						fontSize: '20px'
					}
				}
			/>
			&nbsp;
			<span className={cases[props.status].classes} style={{ fontSize: '20px' }}>
				{ 	props.customText
					
					? props.customText
					: cases[props.status].text}
			</span>

		</div>
	);
};

export default SavingWheel;
