import { useState } from 'react';

export default function TextField(props) {
	const {
		option, noPlaceholder, placeholderLabel, disabled
	} = props;
	const [value, setValue] = useState(option.value);

	function blurred(current_val) {
		option.value = current_val;

		props.onChange();
		props.onBlur();
	}

	function changed(new_val) {
		setValue(new_val);
	}

	const placeholder = noPlaceholder ? '' : (placeholderLabel ? (typeof option.label === 'function' ? option.label() : option.label) : '-');

	return (

		<input
			name={option.field_name}
			disabled={disabled}
			type="text"
			onBlur={(e) => blurred(e.target.value)}
			onFocus={props.onFocus}
			placeholder={placeholder}
			onChange={(e) => changed(e.target.value)}
			value={value || ''}
		/>
	);
}
