import React, { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";

const emailValid = (email) =>
	`${email}`.match(/[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)/);

const MultiEmailInput = ({ onChange, emails }) => {
	const [localEmails, setEmails] = useState(emails);
	const [menuOpen, setMenuOpen] = useState(false);
	const emailToOption = localEmails.filter((email) => localEmails.includes(email)) ?? [];
	const typeaheadRef = React.createRef();

	return (
		<Typeahead
			ref={typeaheadRef}
			allowNew
			clearButton
			multiple
			open={menuOpen}
			id="multi-email-input"
			selected={emailToOption}
			options={localEmails}
			onChange={(selectedEmails) => {
				setEmails(selectedEmails);
				if (selectedEmails?.length <= 0) return;
				onChange(selectedEmails);
			}}
			onInputChange={(input) => {
				if (input === '') {
					setMenuOpen(true);
				};
			}}
			onKeyDown={(e) => {
				if (e.key === 'Enter' || e.key === 'Tab') {
					const input = e.target.value;
					setEmails([...localEmails, input]);
					onChange([...localEmails, input]);
					const workingState = typeaheadRef.current.state;
					workingState.text = '';
					typeaheadRef.current.hideMenu();
				};
			}}
			onFocus={() => setMenuOpen(true)}
			onBlur={() => setMenuOpen(false)}
		/>
	)
};

export default MultiEmailInput;
