import React, {  useState } from 'react';
import apiCalls from '../PowerShadesAPIFunctions';
import { Col, Row } from 'react-bootstrap';
import EntitySelectFull from '../Parts/EntitySelectFull';
import type { fakeRootType } from "../FakeRootType";

type ZUpdateTestingAccountType = {
    ROOT: fakeRootType;
};

const ZUpdateTestingAccount: React.FC<ZUpdateTestingAccountType> = (props : {ROOT : fakeRootType}) => {

    const {ROOT} = props;

    const [entityName, setEntityName] = useState("");

    const [entitySentRevieved, setEntitySentRevieved] = useState<{
        zBooksSent: any,
        zCrmSent: any,
        zBooksRecieved: any,
        zCrmRecieved: any
    }>({
        zBooksSent: null,
        zCrmSent: null,
        zBooksRecieved: null,
        zCrmRecieved: null
    });



    const updateCheck = (entityType: string, entityId: number) => {
        console.log("UpdateCheck");

        apiCalls.GetZproperties(entityType, entityId)
            .then(resp => {
                setEntitySentRevieved({
                    zBooksSent: resp.data.books_sent,
                    zCrmSent: resp.data.crm_sent,
                    zBooksRecieved: resp.data.books_received,
                    zCrmRecieved: resp.data.crm_received
                });
            });
    };

    const tabOptions = ["CRM", "Books"];
    const [currentTab, setCurrentTab] = useState<string | null>(null);

    const currentSent = currentTab == "Books" ? entitySentRevieved.zBooksSent : entitySentRevieved.zCrmSent;
    const currentRecieved = currentTab == "Books" ? entitySentRevieved.zBooksRecieved : entitySentRevieved.zCrmRecieved;

    const sentKeys = Object.keys(currentSent ?? {});
    const recievedKeys = Object.keys(currentRecieved ?? {});

    const commonKeys = sentKeys.filter(key => recievedKeys.includes(key));

    // const sentKeysNotReceieved = sentKeys.filter(key => !recievedKeys.includes(key));
    // const recievedKeysNotSent = recievedKeys.filter(key => !sentKeys.includes(key));

    const sentTable = currentSent != null && currentRecieved !== null ? (
        <table>
            <thead>
                <tr>
                    <th>Property</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
                {commonKeys.map((key, index) => {
                    const val = `${currentSent[key] ?? ""}`
                    const otherVal = `${currentRecieved[key] ?? ""}`
                    return (
                        <tr className={val == otherVal ? "" : "bg-danger" }  key={index}>
                            <td>{key}</td>
                            <td>{`${currentSent[key] ?? ""}`}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    ) : null;

    const recievedTable = currentSent != null && currentRecieved !== null ? (
        <table>
            <thead>
                <tr>
                    <th>Property</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
                {commonKeys.map((key, index) => {
                    const val = `${currentRecieved[key] ?? ""}`
                    const otherVal = `${currentSent[key] ?? ""}`
                    return (
                        <tr className={val == otherVal ? "" : "bg-danger" }  key={index}>
                            <td>{key}</td>
                            <td>{val}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    ) : null;

    return (
        <div className="admin-panel-buttons">
            <EntitySelectFull 
                ROOT={ROOT} 
                placeholder={entityName}
                onSubmit={ (entityId: number, entityType: string) => {
                    updateCheck(entityType, entityId)
                    setEntityName(entityName)
                }}            
                />
            <Row className='m-4'>
                <Col>
                    <Row className='border'>
                        {tabOptions.map((tab, index) => {
                            return (
                                <Col className='border' onClick={
                                    () => setCurrentTab(tab)
                                } key={index}>
                                    {currentTab === tab ? <h3>{tab}</h3> : <h4>{tab}</h4>}
                                </Col>
                            );
                        })}
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <h3>Sent</h3>
                            </Row>
                            <Row>
                                {sentTable}
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <h3>Received</h3>
                            </Row>
                            <Row>
                                {recievedTable}
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row >
        </div >
    );
};

export default ZUpdateTestingAccount;