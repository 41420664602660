import React from "react";
import store from "../../../../Store";
import { updateAssembly } from "../../../../Store/entities/assemblies";
import { UseAssembly } from "../../../../Store/entities/assemblies/hooks";
import { AssemblyStore } from "../../../../Store/entities/assemblies/types";
import EditCOROutdoorShadeModalBase from "./EditCOROutdoorShadeBase";

type EditCOROutdoorShadeModalProps = {
	isOpen: boolean;
	onClose: () => void;
	quoteId: number;
	sequenceId: number;
};

const EditCOROutdoorShadeModal = ({ isOpen, onClose, quoteId, sequenceId }: EditCOROutdoorShadeModalProps) => {
	const assy = UseAssembly(quoteId, sequenceId);

	const setAssembly = (assembly: AssemblyStore) => {
		store.dispatch(updateAssembly({ quoteId, assembly, sequenceId }));
	};
	if (!assy) return null;
	return (
		<EditCOROutdoorShadeModalBase
			isOpen={isOpen}
			onClose={onClose}
			quoteId={quoteId}
			assembly={assy}
			setAssembly={setAssembly}
		/>
	);
};

export default EditCOROutdoorShadeModal;
