import React, { useEffect, useState } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popup from 'reactjs-popup';
import { Container, Col, Row } from 'react-bootstrap';
import balancedLightgapExample from '../assets/img/Balanced_Lightgap_Example.jpg';

import { useDealers, useDealersByTerritory } from '../Store/entities/hooks';
import QuoteField from './QuoteField';
import DealerAssign from './DealerAssign';
import TerritoryAssign from './TerritoryAssign';
import AddressSummaryBlock from './AddressSummaryBlock';
import ContractorAssign from './ContractorAssign';
import { getQuoteType } from './QuoteObjectExtensions';
import { useIsDealer } from '../hooks';
import { useQuoteById } from '../Store/entities/quotes/hooks';
import { useDispatch } from 'react-redux';
import { updateQuoteDealerId, updateQuoteMeta, updateQuoteTerritoryId } from '../Store/entities/quotes';
import { usePortfolios } from '../Store/entities/portfolios/hooks';

/* eslint-disable camelcase */

const QuoteFields = (props) => {
	const {
		newQuote,
		onChange,
		quote,
		territories,
		editPropertyAddress,
		editShippingAddress,
		quoteId
	} = props;

	const dispatch = useDispatch();

	

	const {
		editable, 
		territory_id : territoryId, 
		dealer_id,
		title,
		reference_number,
		property_address,
		shipping_address
	} = useQuoteById(Number(quoteId));

	const [newTitle, setNewTitle] = useState(null);
	const [newRef, setNewRef] = useState(null);
	
	const quoteType = getQuoteType(newQuote);
	const [dealerSet] = useState({});
	const dealersInQuoteTerritory = useDealersByTerritory(territoryId);

	const setTerritory = (newTerritoryId) => {
		dispatch(updateQuoteTerritoryId({
			quoteId,
			territoryId: newTerritoryId
		}))
	};

	const dealers = useDealers();
	const portfolios = usePortfolios();

	const currentQuotePortfolio = portfolios.find(p => p.id === newQuote.Data.quote?.portfolio_id);

	const setDealerId = (newDealerId) => {
		const dealer = dealers.find(d => d.id === dealer_id);
		dispatch(updateQuoteDealerId({
			quoteId,
			dealerId: newDealerId.value
		})).then((resp) => {
			const newDealer = dealers.find(d => d.id === resp.payload.dealer_id);
			if(newDealer && currentQuotePortfolio.code !== newDealer?.current_portfolio)
			{
				window.location.reload();
			}
		})
	}


	const isManufacturer = false; // useIsManufacturer();

	const repriceQuote = newQuote.Data.RePriceWholeQuote;

	const isDealer = useIsDealer();

	const changeTitle = (newValue) => {
		setNewTitle(newValue);
		dispatch(updateQuoteMeta({
			quoteId,
			changeQuote: (quote) => ({
				...quote,
				title: newValue
			})
		// onChange('title', newValue);
	}))};

	const changeReferenceNumber = (newValue) => {
		setNewRef(newValue);
		dispatch(updateQuoteMeta({
			quoteId,
			changeQuote: (quote) => ({
				...quote,
				reference_number : newValue,
				
			})
		// onChange('reference_number', newValue);
	}))};

	useEffect(() => {
		setNewTitle(title);
		setNewRef(reference_number);
	}, [title,reference_number] )

	const { setDealers } = newQuote;

	const isLightgapChoiceActive = newQuote.Data.quote?.IsLightgapChoiceActive === true;

	const showShippingEditable = editable;

	return (
		<Row className="g-0">
			<Col xs={12} lg={3} className="inputs">
				<QuoteField
					disabled={isManufacturer || !editable}
					quoteOption={quote.quote_options.title}
					onChange={changeTitle}
					value={newTitle}
				/>
				<QuoteField
					disabled={isManufacturer || !editable}
					quoteOption={quote.quote_options.reference_number}
					onChange={changeReferenceNumber}
					value={newRef}
				/>

				{territories && newQuote.QuoteType !== 'BidSpec' ? (
					<TerritoryAssign
						quote={quote}
						editable={!isDealer && editable && !newQuote.QuoteSubstitution.is_order}
						territoryId={territoryId}
						setTerritory={setTerritory}
						territories={territories}
						repriceQuote={repriceQuote}
						dealerSet={dealerSet}
						setDealers={setDealers}
						setDealerId={() => {}}
						extraMargin
					/>
				) : null}

				{newQuote.QuoteType === 'BidSpec'
					|| isDealer
					|| !quoteId
					|| !territoryId
					|| !(dealersInQuoteTerritory && territories)
					? null
					: (
						<DealerAssign
							key={quote.territory_id}
							className="quote-field"
							quote={quote}
							dealerId={dealer_id}
							setDealerId={(id) => setDealerId(id)}
							dealers={dealersInQuoteTerritory}
							editable={editable && !newQuote.QuoteSubstitution.is_order}
							repriceQuote={repriceQuote}
							dealerSet={dealerSet}
							setDealers={setDealers}
							extraMargin
						/>
					)}
				{newQuote.QuoteType === 'BidSpec' ? (
					<ContractorAssign
						key="contractor-assign"
						contractorId={newQuote.Data.quote?.contractor_id}
						setContractorId={newQuote.setContractorId}
						editable={editable && !newQuote.QuoteSubstitution.is_order}
						extraMargin
					/>
				) : null}
				{!isLightgapChoiceActive ? (
					<Row
						key="balanced-light-quote"
						className="mt-2"
					>
						<Col
							key="balanced-light-sentence"
							xs={10}
						>
							<p>Light Gaps Default: Balanced</p>
						</Col>
						<Col
							className="text-end"
							key="balanced-light-icon"
							xs={2}
						>
							<p>
								<Popup
									trigger={
										(
											<span>
												<FontAwesomeIcon
													color="black"
													icon={faInfoCircle}
												/>
											</span>
										)
									}
									position="right center"
									keepTooltipInside
									on={['hover', 'focus']}
									arrow
								>
									<Container className="local-bootstrap">
										<Row>
											<Col>
												To enable lightgap manipulation, please go to
												Advanced Settings
											</Col>
											<br />
											<img
												alt="balanced-light-gap-example"
												className="hideonmobile"
												width={450}
												height={450}
												src={balancedLightgapExample}
											/>
										</Row>
									</Container>
								</Popup>
							</p>
						</Col>
					</Row>
				) : null}
			</Col>
			<Col sm={12} lg={4} className="address-summary-block">
				<AddressSummaryBlock
					title="Installation Address"
					address={property_address}
					showEdit={editable}
					onEdit={editPropertyAddress}
					style={{ maxWidth: 'unset' }}
					quoteType={quoteType}
					isPropertyAddress
				/>
			</Col>
			<Col sm={12} lg={4} className="address-summary-block">
				<AddressSummaryBlock
					title="Shipping Address"
					address={shipping_address}
					showEdit={showShippingEditable}
					onEdit={editShippingAddress}
					style={{ maxWidth: 'unset' }}
					quoteType={quoteType}
				/>
			</Col>
		</Row>
	);
};

export default QuoteFields;
