import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faUserTie } from '@fortawesome/free-solid-svg-icons';

import type { MarketingIconProps } from './types';

const AwaitingApprovalIcon = ({
	size = '1x',
	bottomColorOverride = '',
	topColorOverride = '',
	className = '',
}: MarketingIconProps) => (
	<span className={`fa-stack ${className}`}>
		<FontAwesomeIcon
			icon={faUserTie}
			size={size}
			className={`fa-stack-2x ${bottomColorOverride && `text-${bottomColorOverride}`}`}
		/>
		<FontAwesomeIcon
			icon={faEllipsis}
			size={size}
			className={`fa-stack-2x stacked-logo text-${topColorOverride} ps-3 pb-3`}
		/>
	</span>
);

export default AwaitingApprovalIcon;
