import React, { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";

import Button from "../Parts/Button";

const EntityEmailsListing = (props) => {
	const [entityTypeSelected, setEntityTypeSelected] = useState("");
	const [entitySelected, setEntitySelected] = useState("");

	const {
		entities,
		redirect,
		noHeaders,
		noButton,
		maxMenuHeight,
		styling,
		isClearable,
		modal,
		closeFunction,
		entitySelectButtonText,
		rightAlignButton,
		bootstrap,
		noEntitySelectButton,
		fullEntityValues
	} = props;

	const {
		dealers, distributors, representatives
	} = entities;

	let itemTypeSelected = entityTypeSelected;

	let multiplerEntityTypes = false;

	const typeItems = [];

	if (dealers.length > 0) {
		typeItems.push("Dealers");
	}

	if (distributors.length > 0) {
		typeItems.push("Distributors");
	}

	if (representatives.length > 0) {
		typeItems.push("Representatives");
	}

	if (typeItems.length > 1) {
		multiplerEntityTypes = true;
	} else {
		itemTypeSelected =			dealers.length > 0
			? "Dealers"
			: distributors.length > 0
				? "Distributors"
				: "Representatives";
	}

	let selectedEntities = [];

	if (itemTypeSelected == "Distributors") {
		selectedEntities = distributors;
	} else if (itemTypeSelected == "Representatives") {
		selectedEntities = representatives;
	} else if (itemTypeSelected == "Dealers") {
		selectedEntities = dealers;
	}

	const options = selectedEntities
		.map((bg) => ({
			label: bg.name,
			value: bg.id,
			title: bg.name,
		}))
		.sort((a, b) => {
			const nameA = a.label.toUpperCase(); // ignore upper and lowercase
			const nameB = b.label.toUpperCase(); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});

	const emailDisabled = entityTypeSelected == "" || entitySelected == "";

	useEffect(() => {
		if (noButton) {
			redirect(itemTypeSelected, entitySelected.value);
		}
	}, [entitySelected]);

	useEffect(() => {
		if (noEntitySelectButton && itemTypeSelected && entitySelected.value) {
			redirect(itemTypeSelected, entitySelected.value);
		}
	}, [itemTypeSelected, entitySelected.value]);
	
	return (
		<div>
			{!noHeaders ? <h2>Select Entity to View</h2> : null}
			<div>
				<div className={bootstrap ? "mb-4" : null}>
					{!noHeaders ? <h3>Entity Type</h3> : null}
					<select
						className={bootstrap ? "form-select" : null}
						style={styling}
						value={entityTypeSelected}
						placeholder="Please Select"
						onChange={(e) => setEntityTypeSelected(e.target.value)}
					>
						<option value="">Select...</option>
						<option value="Dealers">Dealers</option>
						<option value="Distributors">Distributors</option>
						<option value="Representatives">Representatives</option>
					</select>
				</div>
				<div>
					{!noHeaders ? <h3>Entity Name</h3> : null}
					<Typeahead
						id="entity-emails-listing"
						menuId="entity-emails-listing"
						isClearable={isClearable}
						style={styling}
						options={options}
						value={entitySelected}
						maxMenuHeight={maxMenuHeight}
						className="buying-groups-selector"
						onChange={(e) => {
							console.log(e);
							e !== null ? setEntitySelected(e) : setEntitySelected("");
						}}
					/>
					{/* <Select
						menuPortalTarget={document.body}
						isClearable={isClearable}
						style={styling}
						styles={{
							menuPortal: (base) => ({ ...base, zIndex: 99999999999 }),
						}}
						options={options}
						value={entitySelected}
						maxMenuHeight={maxMenuHeight}
						className="buying-groups-selector"
						onChange={(e) => {
							e !== null ? setEntitySelected(e) : setEntitySelected('');
						}}
					/> */}
				</div>
				{!noButton ? (
					<div
						style={{
							marginTop: noEntitySelectButton ? null : "2em",
							display: "block",
							width: "100%",
						}}
					>
						{modal ? (
							<div style={{ width: "50%", display: "inline-block" }}>
								<Button color="gray" onClick={() => closeFunction()}>
									Close
								</Button>
							</div>
						) : null}
						<div
							style={{
								width: rightAlignButton ? "100%" : "50%",
								display: "inline-block",
								textAlign: "right",
							}}
						>
							{noEntitySelectButton ? null : (
								<Button
									color="green"
									disabled={emailDisabled}
									onClick={() =>
										redirect(
											itemTypeSelected,
											fullEntityValues
												? entitySelected[0]
												: entitySelected?.value ?? entitySelected[0]?.value
										)
									}
								>
									{entitySelectButtonText ?? "Go To Entity"}
								</Button>
							)}
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default EntityEmailsListing;
