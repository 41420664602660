import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Card } from 'react-bootstrap';
import type { fakeRootType } from "../FakeRootType";
import Button from '../Parts/Button';

import apiCalls from '../PowerShadesAPIFunctions';

/* eslint-disable dot-notation */
type CreatePasswordProps = {
	ROOT: fakeRootType;
	setTitle: (title: string) => void;
	loaded: () => void;
};

const CreatePassword = ({
	ROOT, setTitle, loaded
}: CreatePasswordProps) => {
	const [email, setEmail] = useState<string>(ROOT.GET['preset'] || '');
	const [success, setSuccess] = useState(false);
	const [error] = useState(false);

	useEffect(() => {
		setTitle('Create Password');
		loaded();
	}, [loaded, setTitle]);

	const attemptReset = () => {
		apiCalls.sendResetPasswordEmail(email).then(() => setSuccess(true));
	};

	return (
		<Container className="request-password-reset local-bootstrap">
			<Card body className="quarter-card">
				{success ? null : (
					<div>
						<Row>
							<Col>
								<h4>Create Password</h4>
							</Col>
						</Row>

						<Row>
							<Col className="text-muted">Please enter your email address below.</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								An email will be sent to you with instructions on how to set your
								password.
							</Col>
						</Row>

						<Row>
							<Col>
								<label className="d-block" htmlFor="login-password">
									Email Address
								</label>
								<input
									className="form-control mb-4"
									id="login-password"
									type="text"
									autoComplete="current-email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</Col>
						</Row>

						<Row className={`error ${!error ? 'hide' : ''}`}>
							<Col>{error}</Col>
						</Row>
						<Row>
							<Col className="text-right">
								<Button color="green" onClick={attemptReset}>
									Submit
								</Button>
							</Col>
						</Row>
					</div>
				)}

				{success ? (
					<>
						<Row>
							<Col>
								<h3>Thank you</h3>
							</Col>
						</Row>
						<Row>
							<Col>
								<span>
									You will be receiving an email shortly with a link to set your
									new password.
								</span>
							</Col>
						</Row>
					</>
				) : null}
			</Card>
		</Container>
	);
};

export default CreatePassword;
