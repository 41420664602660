/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { AccordionEventKey } from "react-bootstrap/esm/AccordionContext";
import ColorSwatch from "./ColorSwatch";
import "./ColorPaletteModal.scss";
import { PortalShadeOption, PortalShadeOptionItem } from "../../Store/entities/assemblies/types";

type ColorOptionsAccordionProps = {
	title: string;
	defaultColor?: string;
	options: PortalShadeOptionItem<void>[] | PortalShadeOptionItem<string>[] | { value: string }[];
	onSelect: (value: string) => void;
	colorPalettes: PortalShadeOption<string>;
	selectedOption?: PortalShadeOptionItem<string>
	className?: string;
};

const ColorOptionsAccordion = ({
	title,
	defaultColor,
	options,
	onSelect,
	colorPalettes,
	selectedOption,
	className = "",
}: ColorOptionsAccordionProps) => {
	const [accordionOpen, setAccordionOpen] = useState(false);

	const handleAccordionToggle = (eventKey: AccordionEventKey) => {
		setAccordionOpen(eventKey === title);
	};

	return (
		<Accordion className="color-options-accordion mb-2" onSelect={handleAccordionToggle}>
			<Accordion.Item as={Card} eventKey={title} className={className}>
				<Accordion.Header className="m-0">
					<span className={`${className} d-flex align-items-center`}>
						{selectedOption?.label || "Select a Color"}
						<ColorSwatch
							colorPalettes={colorPalettes}
							value={selectedOption?.value ?? ""}
							hexCode={selectedOption?.hexCode ?? ""}
							isOpen={accordionOpen}
							size="small"
							defaultColor={defaultColor}
							hidden={selectedOption?.value === "Custom Palette"}
						/>

					</span>
				</Accordion.Header>
				<Accordion.Body>
					<div
						className="d-flex flex-wrap w-100"
						style={{
							rowGap: "32px",
							justifyContent: "flex-start",
							gap: "16px",
						}}
					>
						{options.map((option) => (
							<div
								className="color-option d-flex flex-column align-items-center text-center"
								key={option.value}
								style={{
									flex: "0 1 calc(25% - 16px)",
									maxWidth: "calc(25% - 16px)",
								}}
								onClick={() => onSelect(option.value)}
							>
								<ColorSwatch
									colorPalettes={colorPalettes}
									value={option.value}
									selectedValue={selectedOption?.value ?? ""}
									hexCode={option.hexCode}
									size="large"
								/>
								<span className={option.value === selectedOption?.value ? "text-primary" : ""}>
									{option.label}
								</span>
							</div>
						))}
						{options.length % 4 !== 0
							&& Array.from({ length: (4 - (options.length % 4)) % 4 }).map((_, idx) => (
								<div
									key={`spacer-${+idx}`}
									style={{ flex: "0 1 calc(25% - 16px)", maxWidth: "calc(25% - 16px)" }}
								/>
							))}
					</div>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	);
};

export default ColorOptionsAccordion;
