import React from "react";
import { Col, Row } from "react-bootstrap";

import StatusBlock from "./StatusBlock";
import { useQuoteById, useQuoteShipmentsById, useQuoteStatusesById } from "../../Store/entities/quotes/hooks";
import type { StatusContainerProps } from "./types";
import { initialQuote } from "../../Store/entities/quotes";

import style from "./StatusContainer.module.scss";

const StatusContainer = ({ id }: StatusContainerProps) => {
	const quoteStatuses = useQuoteStatusesById(id);
	const quoteShipments = useQuoteShipmentsById(id);
	const quote = useQuoteById(id) ?? initialQuote(id);

	const formatDate = (date: string) => new Date(date).toLocaleDateString(undefined, {
		day: "numeric",
		month: "short",
		year: "numeric",
	}).replace(/^0+/, "");
	const trimEstimatedShipDate = (date: string | undefined) => {
		const prefix = "Estimated Ship Date: ";
		if (!date) return '';
		if (date.startsWith(prefix)) {
			return date.substring(prefix.length);
		}
		return date;
	};
	
	const orderPlacedStatus = quoteStatuses.find((status) => status.name.includes("Orderprocessing"));
	const formattedOrderPlaced = formatDate(orderPlacedStatus?.date_time ?? "");
	const formattedOrderPlacedYear = new Date(orderPlacedStatus?.ship_date ?? "").getFullYear();

	const orderApprovedEpoch = quote.order_approved_time ?? 0;
	const orderApprovedDate = new Date(orderApprovedEpoch * 1000).toISOString();
	const formattedApprovedDate = formatDate(orderApprovedDate);

	const mfgStatus = quoteStatuses.find((status) => status.name.includes("Katlin"));
	const formattedMfgYear = new Date(mfgStatus?.ship_date ?? "").getFullYear();

	const submittedToMfg = quote.mfg_assigned_time ?? 0;
	const submittedToMfgDate = new Date(submittedToMfg * 1000).toISOString();
	const formattedSubmittedToMfgDate = formatDate(submittedToMfgDate);

	const inProductionTime = quote.sent_to_production_time ?? 0;
	const inProductionDate = new Date(inProductionTime * 1000).toISOString();
	const formattedInProductionDate = formatDate(inProductionDate);

	const displayOrderPlaced = orderPlacedStatus;
	const displayOrderApproved = orderApprovedEpoch > 0;
	const displaySubmittedToMfg = mfgStatus && submittedToMfg > 0;
	const displayInProduction = mfgStatus && inProductionTime > 0;

	return (
		<Row className={style.statusContainer}>
			{displayOrderPlaced && (
				<Col xs={12} sm={6} md={4} xl={3} className="mb-2">
					<StatusBlock
						title="Order Placed"
						subtitle={formattedOrderPlaced}
						body={`${trimEstimatedShipDate(orderPlacedStatus?.ship_date_string)}, ${formattedOrderPlacedYear} `}
					/>
				</Col>
			)}
			{displayOrderApproved && (
				<Col xs={12} sm={6} md={4} xl={3} className="mb-2">
					<StatusBlock
						title="Order Approved"
						subtitle={formattedApprovedDate}
						body={`${trimEstimatedShipDate(orderPlacedStatus?.ship_date_string)}, ${formattedOrderPlacedYear} `}
					/>
				</Col>
			)}
			{displaySubmittedToMfg && (
				<Col xs={12} sm={6} md={4} xl={3} className="mb-2">
					<StatusBlock
						title="Submitted to Mfg"
						subtitle={formattedSubmittedToMfgDate}
						body={`${trimEstimatedShipDate(mfgStatus?.ship_date_string)}, ${formattedMfgYear} `}
					/>
				</Col>
			)}
			{displayInProduction && (
				<Col xs={12} sm={6} xl={3} className="mb-2">
					<StatusBlock
						title="In Production"
						subtitle={formattedInProductionDate}
						body={`${trimEstimatedShipDate(mfgStatus?.ship_date_string)}, ${formattedMfgYear} `}
					/>
				</Col>
			)}
			{quoteShipments.map((shipment, index) => (
				<Col xs={12} sm={6} xl={3} className="mb-2" key={shipment.tracking_number}>
					<StatusBlock
						title={`Shipment ${index + 1}`}
						subtitle={formatDate(new Date(shipment.time * 1000).toISOString())}
						body={shipment}
					/>
				</Col>
			))}
		</Row>
	);
};

export default StatusContainer;