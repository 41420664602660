import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { startCase } from "lodash";

import { useDisplayPrices, useLockedStatus, useViewingNewQuoteAsPsAdmin, useViewingQuoteId } from "../Store/ui/hooks";
import { useQuoteById } from "../Store/entities/quotes/hooks";
import { useSalesPeople } from "../Store/entities/hooks";

import { initialQuote } from "../Store/entities/quotes";
import AddressBlock from "./AddressBlock";
import StatusContainer from "./Status/StatusContainer";
import Button from "../Parts/Button";
import notImplementedModal from "./NotImplemented";
import PowerShadesTypeahead from "../Parts/PowerShadesTypeahead";

const QuoteDetails = () => {
	const quoteLocked = useLockedStatus();
	const quoteId = useViewingQuoteId();
	const quote = useQuoteById(quoteId) ?? initialQuote(quoteId);
	const isPsAdmin = useViewingNewQuoteAsPsAdmin();
	const displayPrices = useDisplayPrices();
	const salesPeople = useSalesPeople();

	console.log({
		shipping_address: quote.shipping_address,
		install_address: quote.property_address
	});

	const bidSpecQuoteTypeId = 3;
	const quoteHasBeenPaid = false;

	const allowedDisplayPrices = displayPrices.filter((price) => {
		if (quote.quote_type_id === bidSpecQuoteTypeId && price === "bidspec") return false;
		if (!quoteHasBeenPaid && price === "invoice") return false;
		return price;
	});

	const displayPriceOptions = allowedDisplayPrices.map((price) => ({
		label: price === "msrp" ? "MSRP" : startCase(price),
		value: price,
	}));

	const displaySalesPeople = salesPeople.map((salesPerson) => ({
		label: salesPerson.name,
		value: salesPerson.id
	}));

	const salesPersonOnQuote = displaySalesPeople.find((salesPerson) => salesPerson.value === quote.salesperson_id);
	const displayPriceOnQuote = displayPriceOptions.filter((price) => price.value === "dealer");

	const selectedSalesPerson = salesPersonOnQuote ? [salesPersonOnQuote] : [];
	console.log({
		salesPeople,
		displaySalesPeople,
		salesPersonOnQuote,
		quoteSalesPersonId: quote.salesperson_id,
		selectedSalesPerson
	});

	const psAdminView = (
		<>
			<Col>
				<h5>
					<label htmlFor="territory-manager">Territory Manager</label>
				</h5>
				<PowerShadesTypeahead
					id="territory-manager"
					selected={selectedSalesPerson}
					clearButton
					options={displaySalesPeople}
					placeholder="No Territory Manager Selected"
					onChange={() => notImplementedModal()}
				/>
			</Col>
			<Col xs={6}>
				<h5>
					<label htmlFor="previous-quote-id">Previous Quote ID</label>
				</h5>
				<Form.Control id="previous-quote-id" value={quote.previous_quote_id?.toString() ?? ''} disabled={quoteLocked} />
			</Col>
			<Row className="mt-4 mb-4">
				<Col xs={3}>
					<Button
						fullWidth
						disabled={quoteLocked}
						color={quote.isEvolutionShippingForced ? "green" : "gray"}
						onClick={() => notImplementedModal()}
						radioButton
					>
						Ship To Evolution&nbsp;
						<FontAwesomeIcon icon={quote.isEvolutionShippingForced ? faCheck : faTimes} />
					</Button>
				</Col>
				<Col xs={3}>
					<Button
						fullWidth
						disabled={quoteLocked}
						color={quote.overrideLightGap ? "green" : "gray"}
						onClick={() => notImplementedModal()}
						radioButton
					>
						Override Light Gap&nbsp;
						<FontAwesomeIcon icon={quote.overrideLightGap ? faCheck : faTimes} />
					</Button>
				</Col>
				<Col xs={3}>
					<Button
						fullWidth
						disabled={quoteLocked}
						color={quote.overrideRailRoad ? "green" : "gray"}
						onClick={() => notImplementedModal()}
						radioButton
					>
						Override Railroad&nbsp;
						<FontAwesomeIcon icon={quote.overrideRailRoad ? faCheck : faTimes} />
					</Button>
				</Col>
			</Row>
		</>
	);
	return (
		<>
			<Row className="mb-4">
				<Col xs={12} md={6} className="mb-2">
					<AddressBlock
						locked={quoteLocked}
						preShipWhips={quote.pre_ship_whips}
						shipOrder={quote.ship_order}
						shippingAddress
						address={quote.shipping_address}
					/>
				</Col>
				<Col xs={12} md={6}>
					<AddressBlock locked={quoteLocked} address={quote.property_address} />
				</Col>
			</Row>
			<StatusContainer id={quoteId} />
			<Row className="mb-4 mt-4">
				<Col className="mb-4" xs={6}>
					<h5>
						<label htmlFor="display-price">Display Price</label>
					</h5>
					<PowerShadesTypeahead
						id="display-price"
						selected={displayPriceOnQuote}
						clearButton
						options={displayPriceOptions}
						onChange={() => notImplementedModal()}
					/>
				</Col>
				{isPsAdmin && psAdminView}
			</Row>
			<Row>
				<Col>
					<h5>
						<label htmlFor="notes">Notes</label>
					</h5>
					<Form.Control as="textarea" id="notes" value={quote.notes ?? ""} disabled={quoteLocked} />
				</Col>
			</Row>
		</>
	);
};

export default QuoteDetails;
