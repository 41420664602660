import React, { useEffect, useState } from "react";
import apiCalls from "../PowerShadesAPIFunctions";
import { isFailedApiCall } from "../psUtil";
import { EntityListFullResp } from "../powershadesApiTypes";
import PowerShadesTypeahead from "./PowerShadesTypeahead";
import { fakeRootType } from "../FakeRootType";


type EntitySelectFullProps = {
    placeholder?: string;
    disabled?: boolean;
    onSubmit: (entityId: number, entityType: string) => void;
    ROOT: fakeRootType;
    disableAutoSubmit?: boolean;
};

const EntitySelectFull = (props: EntitySelectFullProps) => {

    const { onSubmit, ROOT, disableAutoSubmit = false } = props;

    const { setTitle, loaded, alert } = ROOT;


    // const roleOption: {
    //     value: string;
    //     label: string;
    // }[] = [
    //         { value: "dealer", label: "Dealer" },
    //         { value: "distributor", label: "Distributor" },
    //         { value: "representative", label: "Representative" }
    //     ];

    const [entityType, setEntityType] = useState("");
    const [entityList, setEntityList] = useState<EntityListFullResp>();
    const [selectedEntityName, setSelectedEntityName] = useState("");

    useEffect(() => {
        apiCalls
            .getAccessableEntities()
            .then((resp) => {
                if (isFailedApiCall(resp)) throw resp;

                const entities = resp.data;

                const { dealers = [], distributors = [], representatives = [] } = entities;

                const totalAmount = dealers?.length ?? 0 + distributors?.length ?? 0 + representatives?.length ?? 0;

                if (totalAmount === 1) {
                    // Redirect
                    let workingEntityType = "";
                    let id = 0;

                    if (dealers?.length > 0) {
                        workingEntityType = "dealers";
                        id = dealers[0]?.id ?? 0;
                    } else if (distributors?.length > 0) {
                        workingEntityType = "distributors";
                        id = distributors[0]?.id ?? 0;
                    } else if (representatives?.length > 0) {
                        workingEntityType = "representatives";
                        id = representatives[0]?.id ?? 0;
                    }
                    !disableAutoSubmit && onSubmit(id, workingEntityType);
                } else {
                    setEntityList(entities);
                    loaded();
                }
            })
            .catch((resp) => {
                console.error({ resp });
                const error = resp?.error
                    ?? `Something went wrong loading your payment methods.
					Please reach out to PowerShades customer service.`;
                alert({
                    quickFormat: "error",
                    text: error,
                });
                throw new Error("Something went wrong loading entities");
            });
    }, [loaded, setTitle, alert]);

    const selectedEntitiesFromType = entityList ? entityList[entityType] ?? [] : [];
    const selectedEntity = selectedEntitiesFromType.find(e => e.name === selectedEntityName);

    console.log({ selectedEntity, selectedEntitiesFromType })

    const currentEntityListOptions = selectedEntitiesFromType.map(e => ({ label: e.name, value: e.name }));

    const currentEntityOption = currentEntityListOptions.find(e => e.value === selectedEntityName);
    const selectedOptionList = currentEntityOption ? [currentEntityOption] : [];

    return (
        <>
            <div className={"mb-4"}>
                <h3>Entity Type</h3>
                <select
                    className={"form-select"}
                    value={entityType}
                    // placeholder="Please Select"
                    onChange={(e) => setEntityType(e.target.value)}
                >
                    <option value="">Select...</option>
                    <option value="dealers">Dealers</option>
                    <option value="distributors">Distributors</option>
                    <option value="representatives">Representatives</option>
                </select>
            </div>
            <div>
                <h3>Entity Name</h3>
                <PowerShadesTypeahead
                    id="entity-emails-listing"
                    options={currentEntityListOptions}
                    className="buying-groups-selector"
                    labelKey={"label"}
                    placeholder={"Please Select"}
                    selected={selectedOptionList}
                    onChange={(e) => {

                        const item = e[0] ?? "";

                        const name = typeof item == "string" ? item : item.value

                        console.log(e);
                        setSelectedEntityName(name);
                        // e !== null ? setSelectedEntityName(e) : setEntitySelected("");
                    }}
                />
            </div>
            <div>
                <button
                    onClick={() => {
                        if (selectedEntity) {
                            onSubmit(selectedEntity.id, entityType);
                        }
                    }}
                >
                    Submit
                </button>
            </div>

        </>
    )

}

export default EntitySelectFull;