// Shade fields that will be pulled from the top level
// of the shade assembly object received from the server.
const PULL_FROM_SHADE_ASSEMBLY = [
	'bracket_color',
	'control_side',
	'direction_facing',
	'end_caps',
	'fascia_color',
	'floor',
	'header_type',
	'mount_type',
	'msrp',
	'room_name',
	'shade_name',
	'side_channels',
	'side_channels_color',
	'single_dual',
	'window_jamb_depth',
	'open_roll_end_caps_color',
	'indoor_outdoor',
	'crank_length'
];

// Shade fields that will be pulled from the first object in 
// the shades array contained in the shade assembly object.
// These fields should be the same in each object in the shades array, 
// because the portal currently does not let the end user specify their values individually.
const SHADE_CONVERGING_FIELDS = [
	'height',
	'hembar_color',
	'hembar_type',
	'lv_power_source',
	'manual_chain',
	'manual_chain_color',
	'motor',
	'motor_type',
	'shade_type',
	'tube',
	'tube_name',
	'width',
	'bearing_pin_type',
	'shade_assembly_id',
	'tube_sku'
];

// Shade fields that will be pulled from the shade at
// the specified index.
// These fields are not the same between the shade objects,
// but are differentiated to account for dual shades presently.
const SHADE_INDEX_VARIABLE_MAPPING = {
	0: (shade_0_options) => ({
		fabric_name: shade_0_options.fabric_name,
		roll_direction: shade_0_options.roll_direction,
		shade_1_id: shade_0_options.id,
	}),
	1: (shade_1_options) => ({
		fabric2_name: shade_1_options.fabric_name,
		roll_direction2: shade_1_options.roll_direction,
		shade_2_id: shade_1_options.id,
	})
};

// Function to check whether fields that should be the same between 
// shade objects are the same.
// Log an error to the console if the fields do not match.
function checkConvergingFields(shade_assembly) {
	const convergance_check_shade = { ...shade_assembly.shades[0] };

	delete convergance_check_shade.id;

	SHADE_CONVERGING_FIELDS
		.forEach((key) => {
			shade_assembly.shades.forEach((shade) => {
				if (shade[key] !== convergance_check_shade[key]) {

					//				console.error(`Different values in converging fields: ${shade[key]} !== ${convergance_check_shade[key]}`);
				}
			});
		});
}

// Function to transform shades from the new shade assembly format to the old single-shade
// format that the front end quote system uses.
export default function transformShadeAssembly(shade_assembly) {
	// Check whether fields that should be the same 
	// on all shades match, but continue with the function regardless.
	// checkConvergingFields(shade_assembly);

	const transformed_shade_data = {};
	const { shades } = shade_assembly;

	SHADE_CONVERGING_FIELDS
		.forEach((key) => {
			transformed_shade_data[key] = shades[0][key];
		});

	PULL_FROM_SHADE_ASSEMBLY
		.forEach((key) => {
			transformed_shade_data[key] = shade_assembly[key];
		});

	shade_assembly
		.shades.forEach((shade, ind) => {
			const variable_map_function = SHADE_INDEX_VARIABLE_MAPPING[ind];

			if (variable_map_function) {
				const mapped_variables = variable_map_function(shade);

				Object
					.keys(mapped_variables)
					.forEach((key) => {
						transformed_shade_data[key] = mapped_variables[key];
					});
			}
		});

	// Give the generated shade the shade assembly's id
	// so that we can update the corresponding object on the server later.
	transformed_shade_data.id = shade_assembly.id;

	return transformed_shade_data;
}
