import Popup from 'reactjs-popup';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import MassShadeFormOptions from './ShadeActions/MassShadeFormOptions';
import Button from '../../Parts/Button';

const MassShadeActions = ({
	deleteAssemblies,
	sequenceIds,
	onChange,
	quoteType,
	options,
	isLightGapEnabled,
	selectedSequenceIds
}) => {
	const massEdit = (...items) => {
		onChange(...items);
	};

	const localDeleteAssemblies = () => {
		const shadeAmount = selectedSequenceIds.length;
		if (shadeAmount > 0) {
			ROOT.alert({
				text: `Are you sure you want to delete these ${shadeAmount} shades?`,
				icon: 'warning',
				title: 'Mass Delete',
			})
				.then(() => {
					deleteAssemblies(selectedSequenceIds);
					ROOT.toast(`${shadeAmount} shades deleted`, {
						type: 'success',
						duration: 8000,
					});
				})
				.catch(() => {
					ROOT.toast(`Deletion of ${shadeAmount} shades canceled`, {
						type: 'info',
						duration: 3000,
					});
				});
		} else {
			ROOT.alert({
				title: 'No Shades Selected',
				text: 'Please select at least one shade to delete',
				icon: 'warning',
				oneButton: true
			});
		}
	};
	const actions = [];
	actions.push(
		<Popup
			key="a1"
			trigger={(
				<Col>
					<Button
						title="Edit"
						size="sm"
						hoverColor={quoteType}
						color="light"
						fullWidth
					>
						<FontAwesomeIcon icon={faEdit} />
					</Button>
				</Col>
			)}
			modal
			nested
		>
			{(close) => (
				<MassShadeFormOptions
					close={close}
					onChange={massEdit}
					options={options}
					sequenceIds={sequenceIds}
					isLightGapEnabled={isLightGapEnabled}
				/>
			)}
		</Popup>
	);
	actions.push(
		<Col key="a2">
			<Button
				title="Delete"
				size="sm"
				hoverColor={quoteType}
				fullWidth
				color="light"
				onClick={localDeleteAssemblies}
			>
				<FontAwesomeIcon icon={faTrashAlt} />
			</Button>
		</Col>
	);

	return (
		<Container className="local-bootstrap">
			<Row className="g-0">{actions}</Row>
		</Container>
	);
};

export default MassShadeActions;
