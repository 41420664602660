import React, { useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

import type { QuoteIconTestingProps } from './types';
import CediaIcon from '../assets/icons/cedia';
import RMAIcon from '../assets/icons/rma';
import ReworkIcon from '../assets/icons/rework';
import BidSpecIcon from '../assets/icons/bidspec';
import EmployeeQuoteIcon from '../assets/icons/employeeQuote';
import WindowCoveringIcon from '../assets/icons/windowCovering';
import CustomQuoteIcon from '../assets/icons/customQuote';
import InternalQuoteIcon from '../assets/icons/internal';
import MarketingIcon from '../assets/icons/marketing';
import DemoQuoteIcon from '../assets/icons/demo';
import NotPlacedIcon from '../assets/icons/notPlaced';
import AwaitingApprovalIcon from '../assets/icons/awaitingApproval';
import InProductionIcon from '../assets/icons/inProduction';
import ShippedIcon from '../assets/icons/shipped';

const QuoteIconTesting = ({ ROOT }: QuoteIconTestingProps) => {
	const { setTitle } = ROOT;

	useEffect(() => {
		setTitle('Quote Icon Testing');
	}, [setTitle]);

	return (
		<Container className="local-bootstrap">
			<Card body>
				<Row>
					<Col>
						<Row>
							<Col>
								<h5>Quote View Items</h5>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								CEDIA Quote
							</Col>
							<Col>
								<CediaIcon />
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								RMA Quote
							</Col>
							<Col className="ms-3">
								<RMAIcon className="quote-icon" />
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								Rework Quote
							</Col>
							<Col className="ms-3">
								<ReworkIcon className="quote-icon" />
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								BidSpec Quote
							</Col>
							<Col className="ms-3">
								<BidSpecIcon className="quote-icon" />
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								Employee Quote
							</Col>
							<Col className="ms-3">
								<EmployeeQuoteIcon className="quote-icon" />
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								Window Covering Quote
							</Col>
							<Col>
								<WindowCoveringIcon />
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								Custom Quote
							</Col>
							<Col className="ms-3">
								<CustomQuoteIcon className="quote-icon" />
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								Marketing Quote
							</Col>
							<Col className="ms-3">
								<MarketingIcon />
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								Internal Quote
							</Col>
							<Col className="ms-3">
								<InternalQuoteIcon className="quote-icon" />
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								Demo Quote
							</Col>
							<Col className="ms-3">
								<DemoQuoteIcon className="quote-icon" />
							</Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col>
								<h5>Status View Items</h5>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								Not Placed
							</Col>
							<Col className="ms-3">
								<NotPlacedIcon />
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								Awaiting Approval(s)
							</Col>
							<Col className="ms-3">
								<AwaitingApprovalIcon />
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								In Production
							</Col>
							<Col className="ms-3">
								<InProductionIcon />
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								Shipped
							</Col>
							<Col className="ms-3">
								<ShippedIcon />
							</Col>
						</Row>
					</Col>
				</Row>
			</Card>
		</Container>
	);
};

export default QuoteIconTesting;
