const PaymentStep = (props) => {
	const style = {

		animation: 'fadeInFromRight 0.45s',
		animationFillDirection: 'forwards',
		marginBottom: '2em'
	};

	return (
		<div style={style}>
			{ props.children }
		</div>
	);
};

export default PaymentStep;
