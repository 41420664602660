import React from 'react';

import { Col, Modal as ReactBootstrapModal, Row } from 'react-bootstrap';
import Button from './Button';

type ModalProps = {
	show: boolean;
	setShow: (show: boolean) => void;
	children?: React.ReactNode;
	header?: string;
	closeOnDocumentClick?: boolean;
	verticallyCentered?: boolean;
	onAccept?: () => void;
	onCancel?: () => void;
	acceptTitle?: string;
	cancelTitle?: string;
};
/**
 * A custom modal component built using react-bootstrap modal.
 *
 * Renders a modal that can be used for displaying content or prompting the user for input.
 *
 * @interface ModalProps
 * @property {boolean} show - Whether or not the modal is currently displayed.
 * @property {function} setShow - A function to update the `show` prop.
 * @property {React.ReactNode} [children] - Children to be rendered inside the modal.
 * @property {string} [header] - Header text to be displayed at the top of the modal.
 * @property {boolean} [closeOnDocumentClick=false] - 
 * Whether or not the modal should be closed when the user clicks outside of it.
 * @property {boolean} [verticallyCentered=false] - 
 * Whether or not the modal should be vertically centered on the page.
 * @property {boolean} [verticallyCentered=false] - 
 * Whether or not the modal should be vertically centered on the page.
 * @property {function} onAccept - Function to run on the accept button click. 
 * @property {function} onCancel - Function to run on the cancel button click. 
 * @property {string} [acceptTitle] - Function to run on the cancel button click. 
 * @property {string} [cancelTitle] - Function to run on the cancel button click. 
 */
const Modal = ({
	show,
	children,
	header,
	closeOnDocumentClick = false,
	verticallyCentered = false,
	onAccept,
	onCancel = () => { /* Empty Default Function */ },
	acceptTitle = 'Accept',
	cancelTitle = 'Cancel'
}: ModalProps) => (
	// need to do some modal sizing for full screen on mobile devices
	<ReactBootstrapModal
		show={show}
		backdrop={closeOnDocumentClick ? 'static' : true}
		centered={verticallyCentered}
	>
		{header && (
			<ReactBootstrapModal.Header>
				<ReactBootstrapModal.Title>{header}</ReactBootstrapModal.Title>
			</ReactBootstrapModal.Header>
		)}
		<ReactBootstrapModal.Body>{children && children}</ReactBootstrapModal.Body>
		<ReactBootstrapModal.Footer>
			<Row>
				{onCancel && <Col
					className="text-start"
				>
					<Button
						onClick={onCancel}
						color="gray"
					>
						{cancelTitle}

					</Button>
				</Col>}
				{onAccept && <Col
					className='text-end'
				>
					<Button
						onClick={onAccept}
						color='green'
					>
						{acceptTitle}
					</Button>
				</Col>}
			</Row>
		</ReactBootstrapModal.Footer>

	</ReactBootstrapModal>
);
export default Modal;
