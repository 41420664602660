import { withCallbacks } from 'redux-signalr';
import type { AppDispatch, AppState } from '../..';
import quoteCallbacks from './quoteCallbacks';
import { type QuotePricingInput } from '../../entities/assemblies/types';
// import { updateQuoteMeta } from '../../entities/quotes';
// import { updateAssembly } from '../../entities/assemblies';
import CallbackOptions from './callbackOptions';
import { RefreshQuotePricing } from '../../entities/quotes';
import { EnsureConnectionMade } from '../connection';
import { loadDealersMeta } from '../../entities/organizations/dealers';

// These are where callbacks from the portal backend are defined.

const callbacks = withCallbacks<AppDispatch, AppState>();

callbacks
	.add('messageReceived', (_msg: string) => async (_dispatch, _getState, invoke) => {
		try {
			await EnsureConnectionMade();
			await invoke('SendMessage', "I'm a message from the client!");
			console.log(_msg);
		} catch (error) {
			console.error('Error invoking SendMessage:', error);
		}
	})
	.add('ReceiveRandomNumber', (_num: number) => async (_dispatch, _getState, invoke) => {
		try {
			await EnsureConnectionMade();
			await invoke('SendMessage', "I'm a message from the client!");
		} catch (error) {
			console.error('Error invoking SendMessage:', error);
		}
	})
	.add(CallbackOptions.onConnected, () => async (_dispatch, _getState, invoke) => {
		try {
			await EnsureConnectionMade();
			await invoke('SendMessage', "I'm a message from the client!");
		} catch (error) {
			console.error('Error invoking SendMessage:', error);
		}
	})
	.add(CallbackOptions.hello, () => async (_dispatch, _getState, invoke) => {
		try {
			await EnsureConnectionMade();
			await invoke('SendMessage', "I'm a message from the client!");
		} catch (error) {
			console.error('Error invoking SendMessage:', error);
		}
	})
	.add(
		CallbackOptions.assemblyUpdate,
		(assemblyUpdateJson: string) => async (_dispatch, _getState, _invoke) => {
			try {
				const pricingUpdate = JSON.parse(assemblyUpdateJson) as QuotePricingInput;
				console.log(pricingUpdate);
				await _dispatch(RefreshQuotePricing({ quoteId: pricingUpdate.quote_id }));
			} catch (error) {
				console.error('Error processing assembly update:', error);
			}
		}
	)
	.add(CallbackOptions.dealerSignup, () => async (_dispatch, _getState, _invoke) => {
		try {
				console.log("Dealer Signup");
				await _dispatch(loadDealersMeta());
		} catch (error) {
			console.error('Error processing dealer signup:', error);
		}
	})
	.add("ReloadPage", (quoteId: number) => async (_dispatch, _getState, _invoke) => {
        try {
            console.log(`Reload request received for quote ID: ${quoteId}`);
            
            window.location.reload();
        } catch (error) {
            console.error("Error handling ReloadPage event:", error);
        }
    });



	
// Add Quote callbacks here
quoteCallbacks(callbacks);

export default callbacks;
