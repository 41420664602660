import { useState, useEffect } from 'react';
import { isFunction } from 'lodash';

import HookImplementation from './Parts/HookImplementation';

let LOCAL_SHADE_COUNT = 0;

export default function Shade(options, quote = null) {
	// BEGIN INIT
	const self = this;
	const local_id = ++LOCAL_SHADE_COUNT;
	const variables = options.reduce(
		(acc, opt) => ({ ...acc, [opt.varName]: { ...opt, shade: this } }),
		{}
	);
	const temporary_calculation_errors = [];

	let selected = false;

	this.hooks = new HookImplementation();

	// END INIT

	this.additionalShippingCost = async function () {
		let shipping_cost = 0;

		if (quote && quote.override_shade_shipping_cost != null) {
			shipping_cost = quote.override_shade_shipping_cost;
		} else if (quote && quote.territory_id == null) {
			shipping_cost = 1;
		} else {
			const per_Shade_cost = parseFloat(
				(await api.getTerritoryInfo(quote.territory_id)).shade_shipping_cost
			);

			shipping_cost = per_Shade_cost;
		}

		return shipping_cost * (self.isDualShade() ? 2 : 1);
	};

	this.isOutdoorShade = () => this.valMatch('indoor_outdoor', 'outdoor');

	this.firstId = () => this.val('shade_1_id');
	this.secondId = () => this.val('shade_2_id');

	this.getDuplicate = () => {
		const duplicate_options = this.options()
			.filter((s) => !(s.varName.includes('shade_1_id') || s.varName.includes('shade_2_id')))
			.map((opt) => ({ ...opt }));

		const shade = new Shade(duplicate_options, quote);

		const value = this.val('lv_power_source');
		if (value !== '') {
			this.changeAccessoryCharging(true, value === 'solar_panel');
		}

		return shade;
	};

	this.getLocalID = () => local_id;

	this.hasError = (error_code) =>
		this.getErrors().some((err) => err.code && err.code == error_code);

	this.hasRequiredPricingFields = () =>
		!self
			.options()
			.some(
				(opt) =>
					opt.causesReprice
					&& (opt.conditional == null || opt.conditional(self))
					&& opt.value == null
			);

	this.incrementName = () => {
		// Here we increment the ending number in a shade name if there is
		// only one number in it.
		let regmatch = this.val('shade_name').match(/ ([0-9]+)/);

		if (regmatch == null) {
			this.setVal('shade_name', `${this.val('shade_name')} 2`);
		}

		regmatch = this.val('shade_name').match(/ ([0-9]+)/);

		if (regmatch != null) {
			let rg = this.val('shade_name').match(/ ([0-9]+)/);
			let curr_int = rg[1];
			const increment = !/[0-9]+/.test(this.val('shade_name').replace(curr_int, ''));

			if (increment) {
				while (!this.isNameUnique()) {
					rg = this.val('shade_name').match(/ ([0-9]+)/);
					curr_int = rg[1];

					this.setVal(
						'shade_name',
						this.val('shade_name').replace(curr_int, `${parseInt(curr_int) + 1}`)
					);
				}
			}
		}
	};

	this.isDualShade = () => this.valMatch('single_dual', 'dual');

	this.isMotorized = () => this.valMatch('shade_type', 'motorized');

	this.isNameUnique = () => {
		if (!quote) return true;

		const this_name = this.val('shade_name').toLowerCase().trim(); // .replace(/ /g, '');

		// return !quote.shades.some( shade=> local_id != shade.getLocalID() && shade.val('shade_name').toLowerCase().replace(/ /g, '') === this_name);
		return !quote.shades.some(
			(shade) =>
				local_id != shade.getLocalID()
				&& shade.val('shade_name').toLowerCase().trim() === this_name
		);
	};

	this.isSelected = () => selected;

	this.getQuote = () => quote;

	this.getOption = (option_name) => variables[option_name];

	this.options = () =>
		Object.keys(variables)
			.filter((key) => variables[key] && variables[key].varName != null)
			.map((key) => variables[key]);

	this.savableObject = () => {
		const obj = { quote_id: quote.ID, shade_id: this.id };

		this.options()
			.filter(
				(opt) =>
					!Array.isArray(opt.value) & (opt.conditional == null || opt.conditional(this))
			)
			.forEach((opt) => (obj[opt.varName] = opt.value));

		obj.bearing_pin_type = this.isMotorized()
			? 'heavy_duty_ball_bearing'
			: 'standard_non_ball_bearing_pin';

		return obj;
	};

	this.setFetchingPrice = (bool) =>
		this.hooks.execute('fetching_price_changed', !!bool);

	this.setSelected = (bool) => {
		selected = bool;

		this.hooks.execute('selected_changed');
	};

	this.updateRow = () => {};

	this.setVal = (varName, new_value) => {
		variables[varName] && (variables[varName].value = new_value);
		this.valueChanged();
	};

	this.valueChanged = () => this.hooks.execute('updated');

	this.val = (varName) => {
		let val =			variables[varName] != null && variables[varName].value ? variables[varName].value : '';

		if (variables[varName] && variables[varName].type === 'text') {
			val = `${val}`;
		}

		if (
			variables[varName]
			&& (variables[varName].type === 'fraction') | (variables[varName].type === 'int')
		) {
			val = parseFloat(val);
		}

		return val;
	};

	this.changeAccessoryCharging = (isIncrease, isSolar) => {
		if (
			!'motor_type' in variables
			|| !'lv_power_source' in variables
			|| !variables.motor_type === 'low_voltage'
		) {
			return;
		}

		/*
      For high voltage, include AC whip 1 per MOTOR
      For low voltage hardwired, include DC whip 1 per MOTOR
      For solar panels 1 per MOTOR
      For wallcharger 1 per ASSEMBLY

    */

		let accessoryName = '';

		const isMicroUsb = this.val('motor') == '18 mm Li-Ion RF Motor';

		if (isMicroUsb) {
			if (isSolar) {
				accessoryName = 'Solar Panel - USB Output (3 Watts)';
			} else {
				accessoryName = 'USB Power Supply';
				// Also the 3 meter usb charching cable?
			}
		} else if (isSolar) {
			accessoryName = 'Solar Panel - 3.0 Watt';
		} else {
			accessoryName = 'Wall Plug Recharger Removable Prongs';
		}

		const accChange = isIncrease ? quote.incAccessory : quote.decAccessory;
		accChange(accessoryName);
	};

	this.fieldHasErrors = (field_name) => this.getErrorFields().includes(field_name);

	this.getErrorFields = () => {
		const error_var_names = [];

		this.options()
			.filter((opt) => !opt.conditional || opt.conditional(this))
			.filter((opt) => opt.type === 'select' && opt.value != null)
			.filter((opt) => {
				const opt_choices = isFunction(opt.choices) ? opt.choices(this) : opt.choices;

				const found_choice = opt_choices.find(
					(c) =>
						(c.value === opt.value || c.name === opt.value)
						&& (!c.conditional || c.conditional(this))
				);

				return !found_choice;
			})
			.forEach((opt) => error_var_names.push(opt.varName));

		this.options()
			.filter((opt) => opt.error && opt.error(this))
			.forEach((opt) => error_var_names.push(opt.varName));

		return [...new Set(error_var_names)];
	};

	this.setTemporaryCalculationErrors = (new_errors = []) => {
		this.clearTemporaryCalculationErrors();

		new_errors.forEach((err) => temporary_calculation_errors.push(err));

		this.valueChanged();
	};

	this.clearTemporaryCalculationErrors = () => {
		temporary_calculation_errors.splice(0, temporary_calculation_errors.length);
	};

	this.getErrors = () => {
		const errs = [];

		if (quote) {
			const shade_pricing_data = quote.pricing.getShadeAssemblyPricingData(this);

			if (shade_pricing_data && shade_pricing_data.errors) {
				shade_pricing_data.errors.forEach((e) => errs.push(e));
			}
		}

		this.options()
			.filter((opt) => opt.error && opt.error(this))
			.forEach((opt) => errs.push(opt.error(this)));

		this.options()
			.filter((opt) => !opt.conditional || opt.conditional(this))
			.filter((opt) => opt.type === 'select' && opt.value != null)
			.filter((opt) => {
				const opt_choices = isFunction(opt.choices) ? opt.choices(this) : opt.choices;

				const found_choice = opt_choices.find(
					(c) =>
						(c.value === opt.value || c.name === opt.value)
						&& (!c.conditional || c.conditional(this))
				);

				return !found_choice;
			})
			.forEach((opt) =>
				errs.push(
					`Please select a valid option for "${
						isFunction(opt.label) ? opt.label(this) : opt.label
					}".`
				));

		temporary_calculation_errors.forEach((err) => errs.push(err));

		return [...new Set(errs)];
	};

	this.pricingUpdated = () => {
		this.hooks.execute('pricing_updated');

		this.updateRow();
	};
	this.valMatch = (varName, ...accepted_values) => accepted_values.some(
		(acceptable_value) =>
			(`${this.val(varName)}`).toLowerCase() === (`${acceptable_value}`).toLowerCase()
	);

	this.valIncludes = (varName, ...accepted_values) => accepted_values.some((acceptable_value) =>
		(`${this.val(varName)}`).toLowerCase().includes((`${acceptable_value}`).toLowerCase()));

	return this;
}

export function useShadeErrors(shade) {
	const [errors, setErrors] = useState(shade ? shade.getErrors() : []);

	function refetch() {
		if (!shade) {
			return;
		}

		setErrors(shade.getErrors());
	}

	useEffect(() => {
		if (shade) {
			const h_id = shade.hooks.add('updated', refetch);
			const h2_id = shade.hooks.add('pricing_updated', refetch);

			return () => shade.hooks.remove(h_id, h2_id);
		}
	}, []);

	if (!shade) {
		return [];
	}

	return errors;
}
