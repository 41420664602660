/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/*
! These eslint rules are disabled because there's no reason for us to have to
! type out every single prop that we want to pass down to the Typeahead component.
*/
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */

import React, { ForwardRefRenderFunction, useRef, useState } from "react";
import { Hint, Menu, MenuItem, Typeahead, TypeaheadRef } from "react-bootstrap-typeahead";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import type TypeaheadType from "react-bootstrap-typeahead/types/core/Typeahead";

import type { MenuRenderParameters, PowerShadesTypeaheadProps } from "./types";

import "./PowerShadesTypeahead.scss";
import { hasCustomOptionAndLabelProperty, isOptionObject } from "../psUtil";

const PowerShadesTypeahead: ForwardRefRenderFunction<TypeaheadType, PowerShadesTypeaheadProps> = (props) => {
	const [open, setOpen] = useState(false);
	const shouldBeDisabled = (props.isLoading || props.disabled) ?? false;
	const ref = useRef<TypeaheadRef>(null);

	const { clearButton = false } = ref.current?.props as any || {};

	const inputRender = ({ inputRef, referenceElementRef, ...inputProps }) => {
		const hasValue = inputProps?.value?.length > 0;
		const newClassName = `${inputProps.className} start-0  rounded ${props.errored && "border-danger"}`;
		return (
			<Hint >
				<InputGroup className={`position-relative ${clearButton && "typeahead-editing"}`}>
					<Form.Control
						{...inputProps}
						ref={(node) => {
							inputRef(node);
							referenceElementRef(node);
						}}
						className={newClassName}
					/>
					{!hasValue && (
						<InputGroup.Text
							className={`${props.errored && "text-danger"}
								position-absolute end-0 h-100 custom-group-text
								${shouldBeDisabled ? "disabled" : ""}`}
							onClick={(e) => {
								e.preventDefault();
								if (shouldBeDisabled) return;
								setOpen(true);
								const element = e.currentTarget.previousElementSibling as HTMLInputElement;
								element?.focus();
							}}
						>
							<FontAwesomeIcon icon={faAngleDown} />
						</InputGroup.Text>
					)}
				</InputGroup>
			</Hint>
		);
	};

	let realSelected = props?.selected ?? [];
	if (realSelected) {
		if (realSelected.length === 1 && !realSelected[0]) {
			realSelected = [];
		}
	}

	const menuRender = ({ results, menuProps, state }: MenuRenderParameters) => {
		console.debug(state);
		console.debug(menuProps);
		const widthOfInputBar = parseInt(menuProps.style?.width?.toString()?.slice(0, -2) ?? "", 10);
		const customInputText = document.querySelector(".custom-group-text");
		const customInputTextWidth = customInputText?.clientWidth ?? 0;
		const updatedWidth = widthOfInputBar + customInputTextWidth;
		const updatedWidthString = `${updatedWidth}px`;
		const updatedMenuStyle = { ...menuProps.style, width: updatedWidthString };
		const updatedMenuProps = { ...menuProps, style: updatedMenuStyle, className: `${menuProps.className} z-3` };
		const addSelectionText = menuProps.newSelectionPrefix ?? "Add Item:";
	
		const inputValue = ref.current?.getInput()?.value?.trim().toLowerCase();
		const isInputMatchingSelected
			= realSelected.length === 1 && inputValue === realSelected[0]?.toString().toLowerCase();
		const displayResult = isInputMatchingSelected ? props.options : results;
	
		return (
			<Menu {...updatedMenuProps} className="typeahead-menu w-100">
				{displayResult.map((result, index) => (
					<MenuItem key={index} option={result} position={index}>
						{isOptionObject(result) && !result.customOption
							? result.label
							: hasCustomOptionAndLabelProperty(result)
								? `${addSelectionText} ${result.label}`
								: result}
					</MenuItem>
				))}
			</Menu>
		);
	};

	const handleFocus = (event: React.SyntheticEvent<HTMLInputElement>) => {
		const focusEvent = event as React.FocusEvent<HTMLInputElement>;
		const relatedTarget = focusEvent.relatedTarget as HTMLElement | null;
	
		if (!relatedTarget?.className.includes("btn")) {
			setOpen(true);
		}
	};

	const handleContainerClick = () => {
		if (shouldBeDisabled) return;
		
		const inputElement = ref.current?.getInput();
		if (inputElement) {
			inputElement.focus();
		}
		
		setOpen(true);
	};

	return (
		<div onClick={handleContainerClick} className="flex-grow-1">
			<Typeahead
				ref={ref}
				clearButton={props.clearButton ?? true}
				className={`custom-typeahead ${clearButton && "typeahead-editing"}`}
				renderInput={inputRender}
				renderMenu={(results, menuProps, state) => menuRender({ results, menuProps, state })}
				{...props}
				selected={realSelected}
				open={open}
				onInputChange={() => setOpen(true)}
				disabled={shouldBeDisabled}
				onKeyDown={(e) => {
					if (e.key === "Enter" || e.key === "Escape" || e.key === "Tab") {
						setOpen(false);
					}
				}}
				onChange={(selected) => {
					handleContainerClick();
					if (selected.length === 0 || props.multiple) {
						!props.multiple && props.onChange && props.onChange([]);
						return;
					}
					setOpen(false);
					props.onChange && props.onChange(selected);
				}}
				onFocus={handleFocus}
				onBlur={() => setOpen(false)}
			/>
		</div>
	);
};

export default React.forwardRef(PowerShadesTypeahead);
