import { createAsyncThunk } from "@reduxjs/toolkit";
import { AssemblyOptionsViewModel } from "../../../powershadesApiTypes";
import { ThunkAPI } from "../types";
import apiCalls from "../../../PowerShadesAPIFunctions";
import { extraEntityReducersFunction, loadStatus } from "../entitiesType";

const GetAssemblyOptions = createAsyncThunk<
	AssemblyOptionsViewModel, number, ThunkAPI
>(
	'entities/assemblyOptions',
	async (quoteId: number) => {
		try {
			const goods = await apiCalls.getAssemblyOptions(quoteId);
			if (goods.error) {
				throw new Error(goods.error);
			}
			return goods.data;
		} catch (error) {
			console.error("Error fetching assembly options:", error);
			throw error;
		}
	}
	
);

const assemblyOptionsBuilder: extraEntityReducersFunction = (builder) => {
	builder.addCase(GetAssemblyOptions.fulfilled, (state, action) => {
		state.assemblyOptions[action.meta.arg] = {
			Items: action.payload,
			loadStatus: loadStatus.fullyLoaded
		};
	});

	builder.addCase(GetAssemblyOptions.pending, (state, action) => {
		state.assemblyOptions[action.meta.arg] = {
			Items: {
				headers: [],
				side_channels: [],
				hembars: [],
				mount_types: [],
				control_sides: [],
				shade_types: [],
				drive_types: [],
				direction_facing: [],
				light_gaps: [],
				end_caps: [],
				lv_power_supply: [],
				fabric_alignments: [],
				full_palette_list: [],
				color_details: []
			},
			loadStatus: loadStatus.loading
		};
	});
};

export {
	GetAssemblyOptions,
	assemblyOptionsBuilder
};
