import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import ShadeCard from '../Parts/ShadeCard';

import { DualCoupledFascia, DualRollFascia, SingleCoupledOpenRoll, SingleRollFascia } from './DummyShades';
import type { ShadeCardTestingProps } from './types';
import type { TestingOverrides } from '../Parts/types';
import Button from '../Parts/Button';

const ShadeCardTesting = ({ ROOT }: ShadeCardTestingProps) => {
	const { setTitle } = ROOT;
	const [viewingAsPsAdmin, setViewingAsPsAdmin] = useState(true);
	const [viewingAllShadesUnlocked, setViewingAllShadesUnlocked] = useState(false);
	const [viewingWithDropDowns, setViewingWithDropDowns] = useState(true);

	const testingOverrides: TestingOverrides = {
		isPowerShadesAdmin: viewingAsPsAdmin,
		unlocked: viewingAllShadesUnlocked,
		viewingWithDropDowns
	};

	useEffect(() => {
		setTitle('Shade Card Testing');
	}, [ROOT]);

	const isPowerShadesAdminIcon = viewingAsPsAdmin ? faCheck : faTimes;
	const isShadeUnlockedIcon = viewingAllShadesUnlocked ? faCheck : faTimes;
	const isViewingWithDropDownsIcon = viewingWithDropDowns ? faCheck : faTimes;

	return (
		<Container>
			<Card body className="mb-2">
				<Row>
					<Col xs={4}>
						<Button
							fluid
							fullWidth
							color={viewingAsPsAdmin ? 'green' : 'gray'}
							radioButton
							onClick={() => setViewingAsPsAdmin(!viewingAsPsAdmin)}
						>
							Viewing as Power Shades Admin&nbsp;
							<FontAwesomeIcon icon={isPowerShadesAdminIcon} />
						</Button>
					</Col>
					<Col xs={4}>
						<Button
							fluid
							fullWidth
							color={viewingAllShadesUnlocked ? 'green' : 'gray'}
							radioButton
							onClick={() => setViewingAllShadesUnlocked(!viewingAllShadesUnlocked)}
						>
							Viewing All Shades Unlocked
							&nbsp;
							<FontAwesomeIcon icon={isShadeUnlockedIcon} />
						</Button>
					</Col>
					<Col xs={4}>
						<Button
							fluid
							fullWidth
							color={viewingWithDropDowns ? 'green' : 'gray'}
							radioButton
							onClick={() => setViewingWithDropDowns(!viewingWithDropDowns)}
						>
							Viewing With Drop Downs
							&nbsp;
							<FontAwesomeIcon icon={isViewingWithDropDownsIcon} />
						</Button>
					</Col>
				</Row>
			</Card>
			<Row>
				<Col>
					<ShadeCard shade={SingleRollFascia} testingOverrides={testingOverrides} />
				</Col>
				<Col>
					<ShadeCard shade={DualRollFascia} testingOverrides={testingOverrides} />
				</Col>
			</Row>
			<Row>
				<Col>
					<ShadeCard shade={SingleCoupledOpenRoll} testingOverrides={testingOverrides} />
				</Col>
				<Col>
					<ShadeCard shade={DualCoupledFascia} testingOverrides={testingOverrides} />
				</Col>
			</Row>
		</Container>
	);
};

export default ShadeCardTesting;
