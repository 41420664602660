import { createAsyncThunk } from "@reduxjs/toolkit";
import apiCalls from "../../../PowerShadesAPIFunctions";
import { QuoteFabricSampleStore, QuoteFabricSampleVM } from "../../../powershadesApiTypes";
import { extraEntityReducersFunction, loadStatus } from "../entitiesType";
import { ThunkAPI } from "../types";
import { AxiosError } from "axios";


const loadQuoteFabricSamples = createAsyncThunk<
QuoteFabricSampleVM[], number, ThunkAPI
>(
    'entities/GetFabricSamples',
    async (quoteId, { rejectWithValue }) => {
        let fabricSamples: QuoteFabricSampleVM[] = [];

        try {
            const resp = await apiCalls.getQuoteFabricSamples(quoteId);

            fabricSamples = resp.data.fabric_samples;
        } catch (err: any) {
            const error: AxiosError = err;

            console.error(error);

            return rejectWithValue(false);
        }

        return fabricSamples;
    }
);  

const addQuoteFabricSample = createAsyncThunk<
QuoteFabricSampleVM, { quoteId: number, name: string, quantity: number }, ThunkAPI
>(
    'entities/AddFabricSample',
    async ({ quoteId, name, quantity }, { rejectWithValue }) => {
        let fabricSample;

        try {
            const resp = await apiCalls.addQuoteFabricSample(quoteId, {
                name,
                id: 0,
                quantity : quantity               
            });

            fabricSample = resp.data;
        } catch (err: any) {
            const error: AxiosError = err;

            console.error(error);

            return rejectWithValue(false);
        }

        return fabricSample;
    }
);

const updateQuoteFabricSample = createAsyncThunk<
QuoteFabricSampleVM, { quoteId: number, name: string, quantity: number }, ThunkAPI
>(
    'entities/UpdateFabricSample',
    async ({ quoteId, name, quantity }, { rejectWithValue }) => {
        let fabricSample;

        try {
            const resp = await apiCalls.updateQuoteFabricSample(quoteId, name,quantity);

            fabricSample = resp.data.fabric_sample;
        } catch (err: any) {
            const error: AxiosError = err;

            console.error(error);

            return rejectWithValue(false);
        }

        return fabricSample;
    }
);

const deleteQuoteFabricSample = createAsyncThunk<
boolean, { quoteId: number, name: string }, ThunkAPI
>(
    'entities/DeleteFabricSample',
    async ({ quoteId, name }, { rejectWithValue }) => {
        let success = false;

        try {
            const resp = await apiCalls.deleteQuoteFabricSample(quoteId, name);

            success = resp.data.success;
        } catch (err: any) {
            const error: AxiosError = err;

            console.error(error);

            return rejectWithValue(false);
        }

        return success;
    }
);


const quoteFabricSamplesBuilder: extraEntityReducersFunction = (builder) => {
    builder.addCase(loadQuoteFabricSamples.pending, (state, action) => {
        state.quoteFabricSamples[action.meta.arg] = {
            loadStatus:loadStatus.loading,
            Items: {}
        };
    });

    builder.addCase(loadQuoteFabricSamples.fulfilled, (state, action) => {
        state.quoteFabricSamples[action.meta.arg] = {
            loadStatus: loadStatus.fullyLoaded,
            Items: action.payload?.reduce((acc, item) => {
                acc[item.name] = {
                    Quantity: item.quantity,
                    Name: item.name,
                    Id: item.id
                };

                return acc;
            }, {} as {[key: string]: QuoteFabricSampleStore})
        };
    });
        
    builder.addCase(loadQuoteFabricSamples.rejected, (state, action) => {
        state.quoteFabricSamples[action.meta.arg] = {
            loadStatus: loadStatus.needsLoaded,
            Items: {}
        };
    });

    builder.addCase(addQuoteFabricSample.pending, (state, action) => {
        const qfRecord = state.quoteFabricSamples[action.meta.arg.quoteId];

        if(!qfRecord) {
            state.quoteFabricSamples[action.meta.arg.quoteId] = {
                loadStatus: loadStatus.loading,
                Items: {}
            };
        } else {
            qfRecord.loadStatus = loadStatus.loading;
        }

    });

    builder.addCase(addQuoteFabricSample.fulfilled, (state, action) => {
        const qfRecord = state.quoteFabricSamples[action.meta.arg.quoteId];

        const itemAdded = {
            Quantity: action.payload.quantity,
            Name: action.payload.name,
            Id: action.payload.id
        };

        if(qfRecord)
            {
                state.quoteFabricSamples[action.meta.arg.quoteId] = {
                    loadStatus: loadStatus.needsLoaded,
                    Items: {...qfRecord.Items, [action.payload.name]: itemAdded}
                };
            }
        
    });

    builder.addCase(addQuoteFabricSample.rejected, (state, action) => {
        const qfRecord = state.quoteFabricSamples[action.meta.arg.quoteId];

        if(qfRecord)
            {
                qfRecord.loadStatus = loadStatus.needsLoaded;
            }
    });

    builder.addCase(updateQuoteFabricSample.pending, (state, action) => {
        const qfRecord = state.quoteFabricSamples[action.meta.arg.quoteId];

        if(!qfRecord) {
            state.quoteFabricSamples[action.meta.arg.quoteId] = {
                loadStatus: loadStatus.loading,
                Items: {}
            };
        } else {
            qfRecord.loadStatus = loadStatus.loading;
        }

    });

    builder.addCase(updateQuoteFabricSample.fulfilled, (state, action) => {
        const qfRecord = state.quoteFabricSamples[action.meta.arg.quoteId];
        const itemUpdated = {
            Quantity: action.meta.arg.quantity,
            Name: action.payload.name,
            Id: action.payload.id
        };

        if(qfRecord)
            {
                state.quoteFabricSamples[action.meta.arg.quoteId] = {
                    loadStatus: loadStatus.needsLoaded,
                    Items: {...qfRecord.Items, [action.payload.name]: itemUpdated}
                };
            }
        
    });

    builder.addCase(updateQuoteFabricSample.rejected, (state, action) => {
        const qfRecord = state.quoteFabricSamples[action.meta.arg.quoteId];

        if(qfRecord)
            {
                qfRecord.loadStatus = loadStatus.needsLoaded;
            }
    });

    builder.addCase(deleteQuoteFabricSample.pending, (state, action) => {
        const qfRecord = state.quoteFabricSamples[action.meta.arg.quoteId];

        if(!qfRecord) {
            state.quoteFabricSamples[action.meta.arg.quoteId] = {
                loadStatus: loadStatus.loading,
                Items: {}
            };
        } else {
            qfRecord.loadStatus = loadStatus.loading;
        }

    });

    builder.addCase(deleteQuoteFabricSample.fulfilled, (state, action) => {
        const qfRecord = state.quoteFabricSamples[action.meta.arg.quoteId];

        if(qfRecord)
            {
                delete qfRecord.Items[action.meta.arg.name];
                qfRecord.loadStatus = loadStatus.needsLoaded;
            }
        
    });

    builder.addCase(deleteQuoteFabricSample.rejected, (state, action) => {
        const qfRecord = state.quoteFabricSamples[action.meta.arg.quoteId];

        if(qfRecord)
            {
                qfRecord.loadStatus = loadStatus.needsLoaded;
            }
    });

    
    
    return builder;


};

export {
    quoteFabricSamplesBuilder,
    addQuoteFabricSample,
    loadQuoteFabricSamples,
    updateQuoteFabricSample,
    deleteQuoteFabricSample
}