import { createAsyncThunk } from "@reduxjs/toolkit";
import { QuoteWarningStore } from "../../../powershadesApiTypes";
import { ThunkAPI } from "../types";
import { AxiosError } from "axios";
import apiCalls from "../../../PowerShadesAPIFunctions";
import { extraEntityReducersFunction, loadStatus } from "../entitiesType";
import { AppState } from "../..";


const loadQuoteWarnings = createAsyncThunk<QuoteWarningStore[], number, ThunkAPI>(
    'entities/GetQuoteWarnings',
    async (quoteId, { rejectWithValue }) => {
        let quoteWarnings: QuoteWarningStore[] = [];

        try {
            const resp = await apiCalls.quoteWarningsHistory(quoteId);

            quoteWarnings = resp.data.quoteWarnings;
        } catch (err: any) {
            const error: AxiosError = err;

            console.error(error);

            return rejectWithValue(false);
        }

        return quoteWarnings;
    }
);

const addQuoteWarning = createAsyncThunk<QuoteWarningStore[], { quoteId: number, code: string, message: string }, ThunkAPI>(
    'entities/AddQuoteWarning',
    async ({ quoteId, code, message }, { rejectWithValue, getState }) => {
        let state = getState() as AppState;
        let currentQuoteWarnings = state.entity.quoteWarnings[quoteId]?.Items;

        if (!currentQuoteWarnings) {
            currentQuoteWarnings = {};
        }

        const currentWarnList = Object.values(currentQuoteWarnings);

        const newQuoteWarnings: QuoteWarningStore[] = currentWarnList.map((qw) => {
            if (qw.code === code) {
                return {
                    quote_id: quoteId,
                    code,
                    message
                }
            }
            return {
              ...qw
            }
        });

        if(!currentWarnList.some(qwl => qwl.code === code)) {
            newQuoteWarnings.push({
                code,
                message: message,
                quote_id: quoteId
            });
        }


        try {
            await apiCalls.saveQuoteVerifications(quoteId, newQuoteWarnings.map(qw => ({...qw, message: qw.message})));

        } catch (err: any) {
            const error: AxiosError = err;

            console.error(error);

            return rejectWithValue(false);
        }

        return newQuoteWarnings;
    }
);




const quoteWarningsBuilder: extraEntityReducersFunction = (builder) => {
    builder.addCase(loadQuoteWarnings.fulfilled, (state, action) => {
        let record = state.quoteWarnings[action.meta.arg];

        if (!record) {
            let qwStore = action.payload?.reduce((acc, qw) => {
                acc[qw.code] = qw;
                return acc;
            }, {} as Record<string, QuoteWarningStore>) ?? {};
            state.quoteWarnings[action.meta.arg] = {
                loadStatus: loadStatus.fullyLoaded,
                Items: qwStore
            }
        } else {
            record.Items = action.payload?.reduce((acc, qw) => {
                acc[qw.code] = qw;
                return acc;
            }, {} as Record<string, QuoteWarningStore>) ?? {};
        }
    });

    builder.addCase(loadQuoteWarnings.pending, (state, action) => {
        state.quoteWarnings[action.meta.arg] = {
            loadStatus: loadStatus.loading,
            Items: {}
        }
    });

    builder.addCase(loadQuoteWarnings.rejected, (state, action) => {
        state.quoteWarnings[action.meta.arg] = {
            loadStatus: loadStatus.needsLoaded,
            Items: {}
        }
    });

    builder.addCase(addQuoteWarning.fulfilled, (state, action) => {
        let record = state.quoteWarnings[action.meta.arg.quoteId];

        if (!record) {
            let qwStore = action.payload.reduce((acc, qw) => {
                acc[qw.code] = qw;
                return acc;
            }, {} as Record<string, QuoteWarningStore>);
            state.quoteWarnings[action.meta.arg.quoteId] = {
                loadStatus: loadStatus.fullyLoaded,
                Items: qwStore
            }
        } else {
            record.Items = action.payload.reduce((acc, qw) => {
                acc[qw.code] = qw;
                return acc;
            }, {} as Record<string, QuoteWarningStore>);
        }
    });

    builder.addCase(addQuoteWarning.pending, (state, action) => {
        let record = state.quoteWarnings[action.meta.arg.quoteId];

        if (!record) {
            state.quoteWarnings[action.meta.arg.quoteId] = {
                loadStatus: loadStatus.loading,
                Items: {}
            }
        } else {
            record.loadStatus = loadStatus.loading;
        }
    });


}


export {
    quoteWarningsBuilder,
    loadQuoteWarnings,
    addQuoteWarning
} 