import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Styles from '../Styles';
import ExistingAddresses from './ExistingAddresses';
import AddressSummary from './AddressSummary';
import PropertyAddressEditor from './PropertyAddressEditor';
import AddressEditor from './AddressEditor';
import Button from '../Parts/Button';

const AddressModal = ({
	ROOT,
	addressLabel,
	address,
	quote,
	onDone,
	getAddressesFunction,
	setAddress,
	isPropertyAddress,
	quoteType,
}) => {
	const [showingAddressEditor, setShowingAddressEditor] = useState(false);
	const [usingAddress, setUsingAddress] = useState(null);

	const set = (newAddress) => {
		const addr = { ...newAddress };

		newAddress.country_subdivision_id = addr.country_subdivision_id;

		setAddress(newAddress);

		onDone();
	}

	const setUsing = (newAddress) => {
		const addr = {
			...newAddress,
			id: null,
			ID: null,
		};

		setUsingAddress({ ...addr });
		setShowingAddressEditor(true);
	}

	return (
		<div id="address-modal-wrapper">
			{/* Start of First Modal (select address from previous addresses or button to add a new one) */}
			<Container className="local-bootstrap" fluid style={{ height: '100vh', overflowY: 'auto' }}>
				<div
					className="quote-address address-modal address-modal-one"
					style={{ width: '100vw' }}
				>
					<Row>
						<Col>
							<h4 className={`text-${quoteType}`}>{addressLabel}</h4>
						</Col>
						<Col className="text-end">
							<Button
								color={quoteType ?? 'green'}
								onClick={() => {
									setUsingAddress(null);
									setShowingAddressEditor(true);
								}}
							>
								+ New Address
							</Button>
						</Col>
					</Row>
					{isPropertyAddress != undefined ? (
						<Row style={{ color: Styles.psGray, margin: '7px 0' }}>
							<Col>
								This is not the shipping address. This address will be used as the
								property in the PowerShades dashboard.
							</Col>
						</Row>
					) : null}

					<div className="mt-2">
						<h5
							className={`text-${quoteType}`}
						>
							Current Address
						</h5>
						<Row>
							<Col>
								<AddressSummary address={address} style={{ boxShadow: 'none' }} />
							</Col>
						</Row>
						<Row>
							<Col className="text-end">
								<Button
									color={quoteType ?? 'green'}
									onClick={() => {
										setUsingAddress(address);
										setShowingAddressEditor(true);
									}}
								>
									Edit
								</Button>
							</Col>
						</Row>
					</div>
					
					<ExistingAddresses
						quoteType={quoteType}
						getAddressesFunction={getAddressesFunction}
						createNewAddress={() => setShowingAddressEditor(true)}
						useAddress={(a) => setUsing(a)}
					/>

					<Row>
						<Col className="text-end">
							<Button
								color={quoteType ?? 'green'}
								onClick={onDone}
							>
								Done
							</Button>
						</Col>
					</Row>
				</div>

				{showingAddressEditor ? (
					isPropertyAddress !== undefined ? (
						<PropertyAddressEditor
							quoteType={quoteType}
							quote={quote}
							label={addressLabel}
							initialAddress={usingAddress}
							onCancel={() => setShowingAddressEditor(false)}
							onSet={set}
							ROOT={ROOT}
							isPropertyAddress={isPropertyAddress != undefined}
						/>
					) : (
						<AddressEditor
							quoteType={quoteType}
							quote={quote}
							label={addressLabel}
							initialAddress={usingAddress}
							onCancel={() => setShowingAddressEditor(false)}
							onSet={set}
							ROOT={ROOT}
							isPropertyAddress
						/>
					)
				) : null}
			</Container>
		</div>
	);
};

export default AddressModal;
