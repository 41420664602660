/* eslint-disable react/no-array-index-key */
import React from "react";
import { Table } from "react-bootstrap";

import type { AccordionTableProps } from "./types";

import "./accordionTable.scss";

const AccordionTable = ({ children }: AccordionTableProps) => (
	<Table borderless className="accordion-table" size="sm">
		{children}
	</Table>
);

export default AccordionTable;
