import React, { useState } from "react";
import type { fakeRootType } from "../FakeRootType";
import { Row, Col } from "react-bootstrap";
import apiCalls from "../PowerShadesAPIFunctions";

type ZUpdateTestingAccountType = {
    ROOT: fakeRootType;
};

const ZUpdateTestingDeal: React.FC<ZUpdateTestingAccountType> = (props : {ROOT : fakeRootType}) => {

    const {ROOT} = props;

    const [quoteId, setQuoteId] = useState<number | null>(null);

    const [entitySentRevieved, setEntitySentRevieved] = useState<{
        zBooksSent: any,
        zCrmSent: any,
        zBooksRecieved: any,
        zCrmRecieved: any
    }>({
        zBooksSent: null,
        zCrmSent: null,
        zBooksRecieved: null,
        zCrmRecieved: null
    });

    const dealUpdateCheck = (quoteNumber: number) => {
        ROOT.setTitle("Information for PS Quote: " + quoteNumber);


        apiCalls.GetZQuoteproperties(quoteNumber)
            .then(resp => {
                setEntitySentRevieved({
                    zBooksSent: resp.data.books_sent,
                    zCrmSent: resp.data.crm_sent,
                    zBooksRecieved: resp.data.books_received,
                    zCrmRecieved: resp.data.crm_received
                });
            });
    };

    
    const tabOptions = ["CRM", "Books"];
    const [currentTab, setCurrentTab] = useState<string | null>(null);

    const currentSent = currentTab == "Books" ? entitySentRevieved.zBooksSent : entitySentRevieved.zCrmSent;
    const currentRecieved = currentTab == "Books" ? entitySentRevieved.zBooksRecieved : entitySentRevieved.zCrmRecieved;

    const sentKeys = Object.keys(currentSent ?? {});
    const recievedKeys = Object.keys(currentRecieved ?? {});

    const commonKeys = sentKeys.filter(key => recievedKeys.includes(key));

    const sentTable = currentSent != null && currentRecieved !== null ? (
        <table>
            <thead>
                <tr>
                    <th>Property</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
                {commonKeys.map((key, index) => {
                    const val = `${currentSent[key] ?? ""}`
                    const otherVal = `${currentRecieved[key] ?? ""}`
                    return (
                        <tr className={val == otherVal ? "" : "bg-danger" }  key={index}>
                            <td>{key}</td>
                            <td>{`${currentSent[key] ?? ""}`}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    ) : null;

    const recievedTable = currentSent != null && currentRecieved !== null ? (
        <table>
            <thead>
                <tr>
                    <th>Property</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
                {commonKeys.map((key, index) => {
                    const val = `${currentRecieved[key] ?? ""}`
                    const otherVal = `${currentSent[key] ?? ""}`
                    return (
                        <tr className={val == otherVal ? "" : "bg-danger" }  key={index}>
                            <td>{key}</td>
                            <td>{val}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    ) : null;

    return (
        <Row className='m-4'>
                <Col>
                <Row>
                    <Col>
                    <h2>Deal Update Check</h2>
                    <input value={`${quoteId ?? 0}`} type="number" onChange={(e) => setQuoteId(parseInt(e.target.value))} />
                    </Col>
                    <Col>
                        <button
                            disabled={quoteId == null}
                            onClick={() => dealUpdateCheck(quoteId ?? 0)}
                        >Update</button>
                    </Col>
                </Row>
                    <Row className='border'>
                        {tabOptions.map((tab, index) => {
                            return (
                                <Col className='border' onClick={
                                    () => setCurrentTab(tab)
                                } key={index}>
                                    {currentTab === tab ? <h3>{tab}</h3> : <h4>{tab}</h4>}
                                </Col>
                            );
                        })}
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <h3>Sent</h3>
                            </Row>
                            <Row>
                                {sentTable}
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <h3>Received</h3>
                            </Row>
                            <Row>
                                {recievedTable}
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row >
    );

}

export default ZUpdateTestingDeal;