import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChartBar, faCog, faEnvelope, faFlask, faHammer, faList, faPenSquare, faPercentage, faWarehouse
} from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { Container, Row, Col } from "react-bootstrap";

import InternalLink from "../Parts/InternalLink";
import HomeRow from "../Parts/HomeRow";

import api from "../PowerShadesAPI";

import UsersOnline from "./UsersOnline";
import User from "../User";
import { useIsEngineering, useIsService } from "../hooks";
import Styles from "../Styles";

const AdminPanel = (props) => {
	useEffect(() => {
		props.setTitle("Admin Panel");
		props.loaded();
	}, []);

	const user = User;
	const isManufacturing = user?.user_data?.data?.is_manufacturing ?? false;
	const isEngineer = useIsEngineering();
	const isService = useIsService();

	const links = [
		{
			label: "Create Notifications",
			content: "#CreateNotifications",
			icon: <FontAwesomeIcon icon={faBell} className="choice-icon" />,
		},

		// {
		// 	label: "Manage Downloads",
		// 	content: "#ManageDownloads",
		// 	icon: <FaFileDownload className="choice-icon" />,
		// },

		{
			label: "Manage Coupons",
			content: "#ManageCoupons",
			icon: <FontAwesomeIcon icon={faPercentage} className="choice-icon" />,
		},

		{
			label: "Manufacturing",
			content: "#Manufacturing",
			icon: <FontAwesomeIcon icon={faWarehouse} className="choice-icon" />,
		},

		{
			label: "Reports",
			content: "#Reports",
			icon: <FontAwesomeIcon icon={faChartBar} className="choice-icon" />,
		},

		{
			label: "Email Settings",
			content: "#AdminEmails",
			icon: (
				<span className="fa-stack fa-1x">
					<FontAwesomeIcon icon={faEnvelope} className="fa-stack-2x" />
					<FontAwesomeIcon
						icon={faCog}
						className="fa-stack-1x fa-inverse admin-panel-secondary-icon"
					/>
				</span>
			),
		},

		{
			label: "Email Logs",
			content: "#EmailLogs",
			icon: (
				<span className="fa-stack fa-1x">
					<FontAwesomeIcon icon={faEnvelope} className="fa-stack-2x" />
					<FontAwesomeIcon
						icon={faList}
						className="fa-stack-1x fa-inverse admin-panel-secondary-icon"
					/>
				</span>
			),
		},
		{
			label: "Requested Configurations",
			content: "#RequestedConfigurations",
			icon: (
				<span className="fa-stack fa-1x">
					<FontAwesomeIcon icon={faPenSquare} className="fa-stack-2x" />
				</span>
			),
		},
		{
			label: "Quote Events",
			content: "#QuoteEvents",
			icon: (
				<span className="fa-stack fa-1x">
					<FontAwesomeIcon icon={faPenSquare} className="fa-stack-2x" />
					<FontAwesomeIcon
						icon={faList}
						className="fa-stack-1x fa-inverse  admin-panel-secondary-icon"
					/>
				</span>
			),
		},
		{
			label: "Change Log",
			content: "#ChangeLog",
			icon: (
				<span className="fa-stack fa-1x">
					<FontAwesomeIcon icon={faPenSquare} className="fa-stack-2x" />
					<FontAwesomeIcon
						icon={faList}
						className="fa-stack-1x fa-inverse  admin-panel-secondary-icon"
					/>
				</span>
			)
		},
		{
			label: "Top Level View",
			content: "#TopLevelView",
			icon: (
				<span className="fa-stack fa-1x">
					<FontAwesomeIcon icon={faPenSquare} className="fa-stack-2x" />
					<FontAwesomeIcon
						icon={faList}
						className="fa-stack-1x fa-inverse"
						style={{
							transform: "translate(0.75em, 1em)",
							color: Styles.psGreen,
						}}
					/>
				</span>
			)
		},
		{
			label: "Shade Card Testing",
			content: "#ShadeCardTesting",
			icon: (
				<span className="fa-stack fa-1x">
					<FontAwesomeIcon icon={faFlask} className="fa-stack-2x" />
					<FontAwesomeIcon
						icon={faHammer}
						className="fa-stack-1x fa-inverse"
						style={{
							transform: "translate(0.75em, 1em)",
							color: Styles.psGreen,
						}}
					/>
				</span>
			)
		},
		{
			label: "Quote Icon Testing",
			content: "#QuoteIconTesting",
			icon: (
				<span className="fa-stack fa-1x">
					<FontAwesomeIcon icon={faFlask} className="fa-stack-2x" />
					<FontAwesomeIcon
						icon={faHammer}
						className="fa-stack-1x fa-inverse"
						style={{
							transform: "translate(0.75em, 1em)",
							color: Styles.psGreen,
						}}
					/>
				</span>
			)
		},
		{
			label: "New Quote Page Testing",
			content: "#NewQuote/Details",
			icon: (
				<span className="fa-stack fa-1x">
					<FontAwesomeIcon icon={faFlask} className="fa-stack-2x" />
					<FontAwesomeIcon
						icon={faHammer}
						className="fa-stack-1x fa-inverse"
						style={{
							transform: "translate(0.75em, 1em)",
							color: Styles.psGreen,
						}}
					/>
				</span>
			)
		},
	];

	if (isEngineer || isService) {
		links.push({
			label: "Quotes Under Review",
			content: "#QuoteReviews",
			icon: (
				<span className="fa-stack fa-1x">
					<FontAwesomeIcon icon={faPenSquare} className="fa-stack-2x" />
				</span>
			),
		});

		links.push({
			label: "RMA Types",
			content: "#RMATypes",
			icon: (
				<span className="fa-stack fa-1x">
					<FontAwesomeIcon icon={faPenSquare} className="fa-stack-2x" />
				</span>
			),
		});

		links.push({
			label: "Accessory Screen",
			content: "#Accessories",
			icon: (
				<span className="fa-stack fa-1x">
					<FontAwesomeIcon icon={faPenSquare} className="fa-stack-2x" />
				</span>
			),
		});
		links.push({
			label: "ZTesting",
			content: "#ZUpdateTesting",
			icon: (
				<span className="fa-stack fa-1x">
					<FontAwesomeIcon icon={faPenSquare} className="fa-stack-2x" />
				</span>
			),
		});
	}

	if (isManufacturing) {
		links.push({
			label: "Fabric Lead Times",
			content: "#ZohoLeadTimes",
			icon: (
				<span className="fa-stack fa-1x">
					<FontAwesomeIcon icon={faPenSquare} className="fa-stack-2x" />
					<FontAwesomeIcon
						icon={faList}
						className="fa-stack-1x fa-inverse  admin-panel-secondary-icon"
					/>
				</span>
			),
		});
	}

	// ! Do not delete this function call. It's used to rerun the zoho accessories update. 
	api.updateAcessories();
	// TODO: Move this to a more meaningful place

	const linkRows = links.map((link) => (
		<Col xs={6} sm={4} lg={3} xl={2}>
			<InternalLink key={link.label} content={link.content}>
				<span className="content-choice admin-panel-content-choice">
					<Row>
						<Col xs={12} className="text-center">{link.icon}</Col>
						<Col xs={12} className="text-center">{link.label}</Col>
					</Row>
				</span>
			</InternalLink>
		</Col>
	));

	return (
		<>
			<Container className="local-bootstrap" style={{ marginLeft: "1em", marginRight: "1em" }}>
				<Row>{linkRows}</Row>
			</Container>
			<br />
			<br />
			<HomeRow>
				<UsersOnline />
			</HomeRow>
		</>
	);
};

export default AdminPanel;
