import React, { ReactNode } from 'react';

/**
 * Formats a number as a currency string with a specified number of decimal places and separators.
 *
 * @param inputAmount The number to format as a currency string.
 * @param inputDecimalCount The number of decimal places to include in the formatted string. 
 * Defaults to 2.
 * @param decimalSeparator The character to use as the decimal separator. Defaults to '.'.
 * @param thousandSeparator The character to use as the thousands separator. Defaults to ','.
 * @returns The input number formatted as a currency string.
 *
 * @example
 * formatMoney(1234.5678); // "1,234.57"
 *
 * @example
 * formatMoney(-1234.5678, 3, ',', '.'); // "-1.234,568"
 */
export const formatMoney = (
	inputAmount: number,
	inputDecimalCount = 2,
	decimalSeparator = '.',
	thousandSeparator = ','
): string => {
	// Get the absolute value of the input amount
	const absoluteAmount = Math.abs(Number(inputAmount) || 0);

	// Ensure that the decimal count is a non-negative number, default to 2 if not
	const decimalCount = Number.isNaN(inputDecimalCount) ? 2 : Math.abs(inputDecimalCount);

	// Determine if the original amount was negative
	const negativeSign = inputAmount < 0 ? '-' : '';

	// Separate the input into integer and decimal parts
	const integerPart = parseInt(absoluteAmount.toFixed(decimalCount), 10).toString();
	const decimalPart = Math.abs(absoluteAmount - parseInt(integerPart, 10))
		.toFixed(decimalCount)
		.slice(2);

	// If the integer part of the amount is greater than 3 digits, format it with separators
	const hasSeparator = integerPart.length > 3;

	// Compute the start and rest of the integer part
	const integerPartStart = hasSeparator
		? integerPart.slice(0, integerPart.length % 3 || 3)
		: integerPart;
	const separator = integerPart.length > 3 ? thousandSeparator : '';
	const integerPartRest = hasSeparator
		? integerPart
			.slice(integerPart.length % 3 || 3)
			.replace(/(\d{3})(?=\d)/g, `$1${thousandSeparator}`)
		: '';

	// Combine the start and rest of the integer part with separators
	const formattedInteger = integerPartStart + separator + integerPartRest;

	return negativeSign + formattedInteger + (decimalCount ? decimalSeparator + decimalPart : '');
};

type MoneyProps = {
	symbol?: string;
	value?: number;
	decimalCount?: number;
	children?: ReactNode;
};

const Money = ({
	symbol = '$', value, children, decimalCount = 2
}: MoneyProps) => (
	<>
		{symbol}
		{formatMoney(value || Number(children), decimalCount || 2)}
	</>
);

export default Money;
