import { createSelector } from "reselect";
import { AppState, UsePortalSelector } from "../..";

const selectCurrentMultiplierUnsafe = (quoteId: number) => (state: AppState) => {
    return state.entity.quoteMulitpliers[quoteId];
}

const selectCurrentMultiplier = (quoteId: number) => createSelector(
    selectCurrentMultiplierUnsafe(quoteId),
    (multiplier) => {
        return multiplier?.list.find((m) => m.name === multiplier.currentMultiplier) ?? {
            name: "MSRP",
            multiplier: 1,
            label: "MSRP",
            currency: "USD",
            currency_multiplier: 1,
            useCoupons: false
        };
    }
);

const useCurrentMultiplier = (quoteId: number) => {
    return UsePortalSelector(selectCurrentMultiplier(quoteId));
}

export {
    useCurrentMultiplier
}