import React from 'react';

class AccessoryRow extends React.Component {
	constructor(props) {
		super(props);

		this.props = props;
	}

	render() {
		return (
			<tr>{this.props.cells}</tr>
		);
	}
}

export default AccessoryRow;
