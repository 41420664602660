const BoolInput = ({
	value, onChange, style = '', nullable = false, plain = false
}) => {
	const no_checked = nullable ? (!value && value !== null && value !== undefined) : !value;
	const yes_checked = !!value;

	if (plain == false) {
		return (
			<div style={{ ...{ display: 'flex', flexDirection: 'row' }, ...style }}>

				<span style={{
					display: 'flex', flexDirection: 'row', alignItems: 'center'
				}}
				>
					<label>No</label>
					<input type="radio" checked={no_checked} onChange={(e) => { onChange && onChange(false); }} />
				</span>
				&nbsp;&nbsp;
				<span style={{
					display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '10px'
				}}
				>
					<label>Yes</label>
					<input type="radio" checked={yes_checked} onChange={(e) => onChange && onChange(true)} />
				</span>
				
			</div>
		);
	}
	
	return (
		<div>
			<div className="inline-group">
				<div half-width="true"><label>No</label></div>
				<div half-width="true"><input type="radio" checked={no_checked} onChange={(e) => { onChange && onChange(false); }} /></div>
			</div>
			<div className="inline-group">
					
				<div half-width="true"><label>Yes</label></div>
				<div half-width="true"><input type="radio" checked={yes_checked} onChange={(e) => onChange && onChange(true)} /></div>
			</div>
		</div>
	);
};

export default BoolInput;
