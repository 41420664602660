import React, { useEffect } from "react";
import { Col, Container, Offcanvas, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronRight,
	faClone,
	faDownload,
	faPenToSquare,
	faX
} from "@fortawesome/free-solid-svg-icons";

import type { QuoteTabPaneProps } from "./types";

import { useScrollBlock, useSetTabPaneStatus, useTabPaneStatus } from "../../Store/ui/hooks";
import Button from "../../Parts/Button";
import { QuoteTypeNamesById } from "../../powershadesApiTypeExtensions";
import notImplementedModal from "../NotImplemented";

const QuoteTabPane = ({ quoteTypeId }: QuoteTabPaneProps) => {
	const [blockScroll, allowScroll] = useScrollBlock();
	const openStatus = useTabPaneStatus();
	const setStatus = useSetTabPaneStatus();
	const quoteTypeName = QuoteTypeNamesById[quoteTypeId ?? 5]?.toLowerCase() ?? "cedia";

	const handleClose = () => {
		console.log("allowing scroll!");
		allowScroll && allowScroll();
		setStatus(false);
	};

	useEffect(() => {
		if (openStatus && blockScroll) {
			console.log("blocking scroll!");
			blockScroll();
		}
	}, [openStatus]);

	return (
		<Offcanvas
			scroll
			className="tab-pane"
			backdrop="static"
			show={openStatus}
			onHide={() => handleClose()}
			placement="end"
		>
			<Container className="mt-2">
				<Row className="justify-content-start g-1 g-xl-3">
					<Col xs={2} xl={1}>
						<Button fullWidth color={quoteTypeName} onClick={() => handleClose()}>
							<FontAwesomeIcon className="d-none d-xl-inline" icon={faChevronRight} />
							<FontAwesomeIcon className="d-xl-none" icon={faX} />
						</Button>
					</Col>
					<Col xs={2}>
						<Button fullWidth color={quoteTypeName} onClick={() => notImplementedModal()}>
							Edit
							<span className="d-none d-xl-block">
								&nbsp;
								<FontAwesomeIcon icon={faPenToSquare} />
							</span>
						</Button>
					</Col>
					<Col xs={4}>
						<Button fullWidth color={quoteTypeName} onClick={() => notImplementedModal()}>
							Duplicate
							<span className="d-none d-xl-block">
								&nbsp;
								<FontAwesomeIcon icon={faClone} />
							</span>
						</Button>
					</Col>
					<Col xs={4}>
						<Button fullWidth color={quoteTypeName} onClick={() => notImplementedModal()}>
							Download
							<span className="d-none d-xl-block">
								&nbsp;
								<FontAwesomeIcon icon={faDownload} />
							</span>
						</Button>
					</Col>
				</Row>
			</Container>
		</Offcanvas>
	);
};

export default QuoteTabPane;
