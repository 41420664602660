import { useState, useEffect } from 'react';
import hash from 'object-hash';

import HookImplementation from '../Parts/HookImplementation';
import transformShadeAssembly from './transformShadeAssembly';
import QUOTE_GLOBALS from './QuoteGlobals';
import QuotePricing from '../QuotePricing';
import IndoorShadeFormFields from './IndoorShadeFormFields';
import OutdoorShadeFormFields from './OutdoorShadeFormFields';
import api from '../PowerShadesAPI';
import Shade from '../Shade';

const MAX_SAMPLE_QUANTITY = 10;

const addressGen = () => ({
	first_name: null,
	last_name: null,
	company_name: null,
	address: null,
	address2: null,
	state: null,
	city: null,
	zip: null,
	email: null,
	phone: null,
	country_subdivision_id: null
});

function QuoteTemplate() {
	const self = this;

	const shades_reprice_queued = false;
	const running_shades_reprice = false;

	const save_hashes = {
		shades: null,
		accessories: null,
	};

	this.hooks = new HookImplementation();

	this.pricing = new QuotePricing(this);

	const INIT_DEFAULTS = {

		ID: () => null,
		coupons: () => [],
		populate_dasboard_tasks: () => [],
		quote_loaded: () => false,
		dealer_id: () => null,
		dealer_name: () => '',
		forceSave: () => false,
		history: () => [],
		itemIndex: () => 0,
		is_order: () => false,
		shades: () => [],
		accessories: () => QUOTE_GLOBALS.ACCESSORIES.map((acc) => ({ ...acc, order_quantity: 0 })),
		editable: () => true,
		read_only_mode: () => false,
		editingShade: () => null,
		pricing_as: () => 'MSRP',
		order_status: () => 0,
		is_shipped: () => false,
		date_shipped: () => '',
		fabric_samples: () => [],
		additional_motors: () => [],
		shipping_address: () => addressGen(this, 'shipping_address'),
		billing_address: () => addressGen(this, 'billing_address'),
		property_address: () => addressGen(this, 'property_address'),
		quote_options: () => ({
			title: {
				varName: 'title', type: 'text', label: () => (this.is_order ? 'Order Title' : 'Quote Title'), value: ''
			},
			reference_number: {
				varName: 'reference_number', type: 'text', label: 'Reference #', value: ''
			},
			notes: {
				varName: 'notes', type: 'text', label: 'Notes', value: ''
			}
		}),
		territory_id: () => null
	};

	this.last_hash = '';

	// START GETTERS

	this.getFabricSampleById = (id) => this.fabric_samples.find((f) => f.id == id);

	this.getFabricSamplesQuantity = () => this.fabric_samples.reduce((acc, fabric) => (acc + fabric.quantity), 0);

	this.setAdditionalMotorsQuantity = (api_motor_id, new_quantity) => {
		api_motor_id = parseInt(api_motor_id);
		new_quantity = parseInt(new_quantity);

		const motor_ind = this.additional_motors.findIndex((motor) => motor.api_motor_id === api_motor_id);

		if (new_quantity < 1) {
			if (motor_ind != null) {
				this.additional_motors.splice(motor_ind, 1);
			}
		} else if (motor_ind > -1) {
			this.additional_motors[motor_ind].quantity = new_quantity;
		} else {
			const motor = QUOTE_GLOBALS.MOTORS.find((m) => m.id === api_motor_id);

			if (motor == null) {
				console.error(`Motor ${api_motor_id} not found.`);

				return;
			}

			this.additional_motors.push({

				api_motor_id: motor.id,
				msrp: motor.msrp,
				cost: motor.cost,
				motor_name: motor.name,
				quantity: new_quantity
			});
		}

		this.hooks.execute('additional_motors_updated');
	};

	this.saveShadeHash = () => {
		this.last_hash = this.currentHash();
	};

	this.getMSRPMultiplier = async function () {
		if (this.territory_multiplier) {
			return parseFloat(this.territory_multiplier);
		} else if (this.territory_id) {
			const territory_info = await api.getTerritoryInfo(parseInt(this.territory_id));

			return parseFloat(territory_info.msrp_multiplier);
		}

		return 1;
	};

	this.getShadeCount = () => this.shades.reduce((acc, s) => acc + (s.isDualShade() ? 2 : 1), 0);

	this.getTotalAmountCoupon = () => this.coupons.find(({ discount_type }) => discount_type == 'percentage' | discount_type == 'fixed_amount');

	this.getTotalShippingFees = () => 0;

	this.getSelectedShades = () => this.shades.filter((s) => s.isSelected());

	this.hasAccessories = () => this.accessories.some((acc) => parseInt(acc.quantity) > 0);

	this.hasOutdoorShades = () => this.shades.some((s) => s.isOutdoorShade());

	this.hasSaveError = () => this.save_status == 3;

	this.isSaving = () => this.save_status == 1;

	this.totalPrice = async function () {
		return 0;
	};

	this.totalShippingFees = () => 0;

	this.totalAccessories = () => this.accessories.reduce((acc, a) => acc + acc.quantity, 0);

	this.shadesTotalMSRP = () => 0;

	this.shadesTotalCost = () => 0;

	this.accessoriesTotalMSRP = () => 0;

	this.additionalMotorsTotalMSRP = () => 0;

	this.getTotalAccessoriesCost = () => 0;

	this.setTerritoryId = (new_terr_id) => {
		api.getUserTerritoriesID()
			.then((terr_ids) => {
				if (terr_ids.includes(new_terr_id)) {
					this.territory_id = new_terr_id;

					this.hooks.execute('territory_updated');
				} else {
					ROOT.alert({ text: 'You do not have access to this territory.', quickFormat: "error" });
				}
			});
	};

	// END GETTERS

	// START MUTATIONS

	this.addCoupon = (coupon_obj) => {
		const coupon_already_applied = this.coupons.some((c) => c.code == coupon_obj.code);
		const total_value_coupon_ind = this.coupons.findIndex((c) => c.code);

		if (coupon_already_applied) {
      
		} else {
			coupon_obj.disabled = parseInt(coupon_obj.disabled) !== 0;
			coupon_obj.discount_amount = parseFloat(coupon_obj.discount_amount);

			if (total_value_coupon_ind > -1 && coupon_obj.discount_type == 'percentage' | coupon_obj.discount_type == 'fixed_amount') {
				this.coupons[total_value_coupon_ind] = coupon_obj;
			} else {
				this.coupons.push(coupon_obj);
			}

			this.updated('addCoupon');
		}
	};

	this.isMetaEditable = () => !this.is_order || this.forceSave;
	this.areItemsEditable = () => this.editable;

	this.removeCouponByCode = (coupon_code) => {
		this.coupons = this.coupons.filter((c) => c.code != coupon_code);

		this.updated('addCoupon');
	};

	this.removeAllCoupons = () => {
		this.coupons = [];

		this.updated('addCoupon');
	};

	this.selectedShadesChanged = () => this.hooks.execute('selected_shades_changed');

	this.setPricingAs = (label) => {
		this.pricing_as = label;

		this.hooks.execute('pricing_as_changed');
	};

	this.setFabricSampleQuantity = (fabric1, quantity) => {
		return;
		const unchanged = [...this.fabric_samples];

		quantity = parseInt(quantity);

		if (fabric1 == null) return;

		const fabric = this.fabric_samples.find((f) => f.name == fabric1.name);

		if (quantity > 0) {
			if (!fabric) {
				this.fabric_samples.push({ ...fabric1, quantity });
			} else {
				fabric.quantity = quantity;
			}
		} else {
			this.fabric_samples = this.fabric_samples.filter((f) => f.id != fabric1.id);
		}

		this.fabric_samples = [...new Set(this.fabric_samples)];

		if (this.getFabricSamplesQuantity() > MAX_SAMPLE_QUANTITY) {
			this.fabric_samples = unchanged;
		}

		this.hooks.execute('fabricSamplesUpdated');
	};

	this.addAccessoryQuantity = (name, relative_add) => {
		const acc = this.accessories.find((acc) => acc.name.toLowerCase() == name.toLowerCase());

		if (acc) {
			if (!acc.quantity || parseInt(acc.quantity) < 0) {
				acc.quantity = 0;
			}

			this.setAccessoryQuantity(acc.name, acc.quantity + relative_add);
		} else {
			console.error('Failed to find accessory: ', name);
		}

		this.hooks.execute('accessories_updated');
		this.hooks.execute('msrp_updated');
	};

	this.incAccessory = (name) => this.addAccessoryQuantity(name, 1);

	this.decAccessory = (name) => this.addAccessoryQuantity(name, -1);

	this.setAccessoryQuantity = (name, new_quantity) => {
		const acc = this.accessories.find((acc) => acc.name.toLowerCase() == (`${name}`).toLowerCase());

		acc.quantity = new_quantity;

		this.hooks.execute('accessories_updated');
		this.hooks.execute('msrp_updated');
	};

	this.setSelectedShadesOption = (option) => {
		this.getSelectedShades().forEach((shade) => {
			if (option.value.trim() == '') return;

			shade.setVal(option.varName, option.value);

			if (option.display_val) {
				shade[option.varName].display_val = option.display_val;
			}
		});

		this.hooks.execute('shades_updated');
		this.save();

		this.updated('quote: set_selected_shades_option');
	};

	this.updatedAddress = () => this.hooks.execute('address_updated');

	this.convertToOrder = () => {
		api.convertToOrder(this.ID, (resp) => {
			if (resp.data.success) {
				this.onOrderConfirmed && this.onOrderConfirmed();
				this.load(this.ID, true);
			} else {
				ROOT.alert({ text: "Error converting quote to order. \n Please try again later.", quickFormat: "error" });
				console.error("Error Converting Quote to Order.");
			}
		});
	};

	this.duplicateShade = (shade) => {
		const new_shade = shade.getDuplicate();

		// getAssociatedAccessories(new_shade)
		//         .forEach( acc_name=> this.incAccessory(acc_name))

		new_shade.incrementName();

		this.addShade(new_shade);

		this.updated();

		// let id = new_shade.options();

		// let sendBackTheId = this.pricing.duplicateShadeAssembly(shade.id, new_shade.getLocalID());

		// api.duplicateShade({
		//   id: shade.id,
		//   new_name : new_shade.getOption("shade_name").value
		// })
		//   .then( resp => {
		//     new_shade.id = resp.data.shade_id;
		//     sendBackTheId(new_shade.id);
		//     this.saveShadeHash();
		// });
		// this.saveShadeHash();
	};

	this.addShade = (shade, skip_updated = false) => {
		shade.clearTemporaryCalculationErrors();

		const hook_id = shade.hooks.add('updated', this.shadesUpdated);

		this.shades.push(shade);

		this.shadesUpdated();

		if (!skip_updated) {

			// this.hooks.execute('shades_updated');
		}
	};

	this.deleteShade = (shade) => {
		// getAssociatedAccessories(shade)
		//     .forEach( acc_name=> this.decAccessory(acc_name))

		const value = shade.val('lv_power_source');
		if (value !== '') {
			shade.changeAccessoryCharging(false, value === 'solar_panel');
		}

		this.shades.splice(this.shades.indexOf(shade), 1);

		this.hooks.execute('shades_updated');
	};

	this.shadesUpdated = () => { };

	// END MUTATIONS

	// START INTERNAL FUNCTIONS 
	const setSaveStatus = (new_status) => {
		this.save_status = new_status;

		this.hooks.execute('save_status_changed');
	};

	// END INTERNAL FUNCTIONS

	this.unlock = (force_save = true) => {
		this.editable = true;
		this.read_only_mode = false;
		this.forceSave = force_save;

		this.shadesUpdated();
		this.updated();
	};

	this.changedState = (address_key) => {
		const state = QUOTE_GLOBALS.STATES.find((state) => state.name == this[address_key].state.value);

		this[address_key].country = { varName: 'country', value: state ? state.country_name : '' };
	};

	this.areAllIndoorShadesSelected = () => !this.shades.some((s) => !s.isOutdoorShade() && !s.isSelected());

	this.areAllOutdoorShadesSelected = () => !this.shades.some((s) => s.isOutdoorShade() && !s.isSelected());

	this.toggleAllIndoorSelected = () => this.setAllIndoorSelected(!this.areAllIndoorShadesSelected());

	this.toggleAllOutdoorSelected = () => this.setAllOutdoorSelected(!this.areAllOutdoorShadesSelected());

	this.setAllOutdoorSelected = (bool) => {
		this.shades.filter((s) => s.isOutdoorShade()).forEach((shade) => shade.setSelected(bool));

		this.selectedShadesChanged();
	};

	this.setAllIndoorSelected = (bool) => {
		this.shades.filter((s) => !s.isOutdoorShade()).forEach((shade) => shade.setSelected(bool));

		this.selectedShadesChanged();
	};

	this.loadFromData = (data) => {
		const pull_from_data = [
			{ key: 'ID', type: 'int' },
			{ key: 'order_status_index', type: 'int' },
			{ key: 'manufacturer_id', type: 'int' },
			{ key: 'territory_id', type: 'int' },
			{ key: 'territory_multiplier', type: 'float' },
			{ key: 'created_by', type: 'string' },
			{ key: 'is_paid', type: 'bool' },
			{ key: 'created', type: 'string' },
			{ key: 'dealer_id', type: 'int' },
			{ key: 'distributor_id', type: 'int' },
			{ key: 'representative_id', type: 'int' },
			{ key: 'require_payment', type: 'bool' },
			{ key: 'is_order', type: 'bool' },
			{ key: 'is_shipped', type: 'bool' },
			{ key: 'date_shipped', type: 'string' },
			{ key: 'territory_multiplier', type: 'float' },
			{ key: 'override_shade_shipping_cost', type: 'float' },
			{ key: 'coupons', type: 'arr' },
			{ key: 'populate_dashboard_tasks', type: 'arr' },
		];

		const typeTransform = {

			int: (val) => parseInt(val),
			float: (val) => parseFloat(val),
			bool: (val) => (!!val),
			string: (val) => `${val}`,
			arr: (arr) => arr,
		};

		pull_from_data
			.filter((pull) => data[pull.key] != null)
			.map((pull) => ({ key: pull.key, value: data[pull.key] === null ? null : typeTransform[pull.type](data[pull.key]) }))
			.forEach((item) => this[item.key] = item.value);

		if (!this.IsHeldByAdmin) this.IsHeldByAdmin = false;
    
		api.getTerritory(this.territory_id).then((tResp) =>
			this.IsHeldByAdmin = tResp.data.territory.name === "Internal");

		this.editable = this.read_only_mode ? false : (data.editable != null ? parseInt(data.editable) == 1 : true);

		const shades = [];

		const INDOOR_SHADE_OPTIONS = new IndoorShadeFormFields();
		const OUTDOOR_SHADE_OPTIONS = new OutdoorShadeFormFields();

		data
			.shade_assemblies
			.map(transformShadeAssembly)
			.forEach((s, ind) => {
				const options = (s.indoor_outdoor === 'outdoor' ? OUTDOOR_SHADE_OPTIONS : INDOOR_SHADE_OPTIONS).map((opt) => {
					if (s[opt.varName] !== undefined) {
						opt.value = s[opt.varName];
					}

					return opt;
				});

				options.push({ varName: 'motor', value: s.motor });

				const new_shade = new Shade(options, this);

				new_shade.id = parseInt(s.id);

				this.addShade(new_shade, true);
			});

		this.hooks.execute('shades_updated');

		QUOTE_GLOBALS.onLoad(() => {
			data.accessories
				.forEach((accdata) => {
					let acc = this.accessories.find((a) => parseInt(a.id) === parseInt(accdata.accessory_id));

					if (!acc) {
						acc = this.accessories.find((a) => a.name === accdata.name);
					}

					acc.quantity = parseInt(accdata.quantity);
					acc.msrp = accdata.msrp;
				});

			this.shadesUpdated();
		});

		data.fabric_samples
			.forEach((fabric) => this.setFabricSampleQuantity(fabric, fabric.quantity, false));

		if (data.msrp) {
			this.pricing.setPricingData(data.msrp);
		}

		if (this.is_order) {
			this.additional_motors = data.additional_motors;
		} else {
			data.additional_motors.forEach((motor) => this.setAdditionalMotorsQuantity(motor.api_motor_id, motor.quantity));
		}

		Object.keys(data)
			.filter((key) => this.quote_options[key] != null && data[key] != null)
			.forEach((key) => this.quote_options[key].value = data[key]);

		this.property_address = data.property_address || addressGen();
		this.billing_address = data.billing_address || addressGen();
		this.shipping_address = data.shipping_address || addressGen();

		this.last_hash = this.currentHash();

		this.updated();

		this.startSaveListener();
	};

	this.resetAll = (options = {}) => {
		const optLoad = (optionsObj) => {
			Object.keys(optionsObj)
				.forEach((key) => this[key] = optionsObj[key](this));
		};

		QUOTE_GLOBALS.onLoad(() => {
			optLoad(INIT_DEFAULTS);

			optLoad(options);
		});
	};

	this.setLoaded = (bool) => {
		this.quote_loaded = bool;
		this.hooks.execute('load_state_changed');
	};

	this.loadFromID = (quote_id) => {
		api.onLoad.add(() => {
			QUOTE_GLOBALS.onLoad(() => {
				api.loadQuote(quote_id)
					.then((resp) => {
						if (resp.data.error) {
							console.error(resp.data.error);

							this.hooks.execute('load_error', resp.data.error);
						} else {
							this.loadFromData(resp.data);

							this.setLoaded(true);

							this.checkLoaded();
						}
					});
			});
		});
	};

	this.currentSaveData = function (allSaveData = false) {
		const shades = this.shades.map((s, ind) => ({

			...s.savableObject(),
			...(s.shade_id ? { shade_id: s.shade_id } : null),
			last_local_id: s.getLocalID()
		}));

		const accessories = this.accessories
			.filter((acc) => !(acc.quantity == undefined || acc.quantity < 1))
			.map((acc) => ({ id: acc.id, quantity: acc.quantity }));

		const fabric_samples = this.fabric_samples.map((fs) => ({
			id: fs.id, name: fs.name, quantity: fs.quantity
		}));

		const data = {
			ID: this.ID, territory_id: this.territory_id, populate_dasboard_task_ids: this.populate_dasboard_task_ids
		};

		if (allSaveData || this.areItemsEditable()) {
			data.accessories = accessories;
			data.fabric_samples = fabric_samples;
			data.shades = shades;
		}

		if (allSaveData || this.isMetaEditable()) {
			data.coupons = this.coupons;

			// Load Quote Options into the Quote object to be passed to the server.
			Object.keys(this.quote_options)
				.filter((key) => this.quote_options[key].value != null)
				.forEach((key) => data[this.quote_options[key].varName] = this.quote_options[key].value);

			data.shipping_address = { ...this.shipping_address };
			data.billing_address = { ...this.billing_address };
			data.property_address = { ...this.property_address };
		}

		return data;
	};

	this.save = () => {
		// Don't attempt to save this quote if it isn't editable or is still loading.
		if (this.loading || (!this.isMetaEditable() & !this.areItemsEditable())) return;

		run_save();
	};

	const run_save = (callback) => {
		if (!this.isMetaEditable() & !this.areItemsEditable()) {
			// Don't save the quote if it's being viewed in read-only mode.
			return;
		}

		setSaveStatus(1);

		const save_data = this.currentSaveData();

		const current_hash = this.currentHash();

		if (current_hash === this.last_hash) {
			setSaveStatus(0);

			return;
		}
		this.last_hash = current_hash;

		if (this.areItemsEditable()) {
			const current_shade_hash = hash(save_data.shades);
			const current_acc_hash = hash(save_data.accessories);

			if (current_shade_hash === save_hashes.shades) {
				delete save_data.shades;
			} else {
				save_hashes.shades = current_shade_hash;
			}

			if (current_acc_hash === save_hashes.accessories) {
				delete save_data.accessories;
			} else {
				save_hashes.accessories = current_acc_hash;
			}
		}

		setSaveStatus(1);

		api[this.forceSave ? 'forceSaveQuote' : 'saveQuote'](this.ID, save_data).then((resp) => {
			let { data } = resp;

			if (resp.data.constructor === "".constructor) {
				const spotIndexA = resp.data.indexOf('{"success"');
				const spotIndexB = resp.data.indexOf('{"msrp"');

				let spotIndex = spotIndexA > spotIndexB ? spotIndexB : spotIndexA;
				if (spotIndexA > spotIndexB) {
					spotIndex = spotIndexB;
					spotIndex = spotIndexB === -1 ? spotIndexA : spotIndex;
				} else {
					spotIndex = spotIndexA;
					spotIndex = spotIndexA === -1 ? spotIndexB : spotIndex;
				}
				const cleanData = resp.data.substring(spotIndex);
				data = JSON.parse(cleanData);
			}

			setSaveStatus(data.success ? 2 : 3);

			if (data.msrp) {
				this.pricing.setPricingData(data.msrp);
			}

			if (data.quote_id && data.quote_id > -1) {
				this.ID = data.quote_id;
				ROOT.setContent(`#Quote?quoteID=${this.ID}`);

				setSaveStatus(2);
			}

			if (data.error) {
				ROOT.alert({ text: data.error, quickFormat: "error" });
			}

			callback && callback();
		});
	};

	this.currentHash = function () {
		const obj = { ...self.currentSaveData(), editable: self.editable };

		return hash(obj);
	};

	this.updated = (called_by) => this.hooks.execute('updated');

	this.checkLoaded = async function () {
		if (this.quote_loaded && QUOTE_GLOBALS.isLoaded()) {
			this.onLoaded && this.onLoaded();
			this.hooks.execute('loaded');
			this.hooks.execute('load_state_changed');
		}
	};

	this.init = (loadQuoteID = null, options = {}) => {
		QUOTE_GLOBALS.onLoad(() => {
			this.resetAll(options);

			this.onLoading && this.onLoading();

			if (loadQuoteID != null) {
				api.onLoad.add(() => this.loadFromID(loadQuoteID));
			} else {
				this.startSaveListener();

				this.setLoaded(true);

				this.checkLoaded();
			}
		});
	};

	this.startSaveListener = () => {
		// Since the "run_save" method already checks save-data hashes before making a call to the backend,
		// all we need to do here is regularly call the save method.
		this.saveListenerInterval = setInterval(this.save, 3000);
	};

	this.stopSaveListener = () => clearInterval(this.saveListenerInterval);

	return this;
}

export function useHasUncalculatedShades(quote) {
	return false;
}

export function useHasValidPropertyAddress(quote) {
	const [has, setHas] = useState(false);

	function refresh() {
		const required_fields = [
			'company_name',
			'address',
			'country_subdivision_id',
			'city',
			'zip'
		];

		setHas(
			quote?.property_address && !required_fields.some((key) => quote.property_address[key] == null || (`${quote.property_address[key]}`).length < 1)
		);
	}

	useEffect(() => {
		const hook_id = quote?.hooks.add('updated', refresh);
		const hook_id2 = quote?.hooks.add('address_updated', refresh);

		refresh();

		return () => quote?.hooks.remove(hook_id, hook_id2);
	}, []);

	return has;
}

export function useHasValidShippingAddress(quote) {
	const [has, setHas] = useState(false);

	function refresh() {
		const required_fields = [
			'company_name',
			'first_name',
			'last_name',
			'address',
			'country_subdivision_id',
			'city',
			'zip'
		];

		setHas(
			quote.shipping_address && !required_fields.some((key) => quote.shipping_address[key] == null || (`${quote.shipping_address[key]}`).length < 1)
		);
	}

	useEffect(() => {
		const hook_id = quote.hooks.add('updated', refresh);
		const hook_id2 = quote.hooks.add('address_updated', refresh);

		refresh();

		return () => quote.hooks.remove(hook_id, hook_id2);
	}, []);

	return has;
}

export {
	QuoteTemplate,
	MAX_SAMPLE_QUANTITY
};

export default QuoteTemplate;
