import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "lodash";

import type { AppState } from "..";
import { UiState } from "./types";
import { loadStatus } from "../entities/entitiesType";

export const initialUIState: UiState = {
	createQuoteOpen: false,
	pageLoading: false,
	modalsOpen: {
	},
	home: {
		filterSettings: {
			general_filter: '',
			dealer_ids: [],
			territory_ids: [],
			quote_type_ids: [],
			page_number: 1,
			results_per_page: 20,
			filter_names: ['all'],
			sort_names: ['-updated']
		},
		selectedQuoteStatus: 'all',
		visibleQuoteIds: {
			list: [],
			loadStatus: loadStatus.notLoaded,
			fetching: false
		},
	},
	headerInjection: null,
	quote: {
		viewingQuoteId: 0,
		locked: true,
		viewingAsPsAdmin: false,
		displayPrices: [],
		tabPaneOpen: false,
	}
};

const uiSlice = createSlice({
	name: 'ui',
	initialState: initialUIState,
	reducers: {
		openCreateQuote: (ui) => ({ ...ui, createQuoteOpen: true }),
		closeCreateQuote: (ui) => ({ ...ui, createQuoteOpen: false }),
		pageLoaded: (ui) => ({ ...ui, pageLoading: false }),
		pageLoading: (ui) => ({ ...ui, pageLoading: true }),
		openModal: (ui, action) => ({ ...ui, modalsOpen: { ...ui.modalsOpen, [action.payload]: true } }),
		closeModal: (ui, action) => ({ ...ui, modalsOpen: { ...ui.modalsOpen, [action.payload]: false } }),
		setFilterSettings: (ui, action) => {
			if (isEqual(ui.home.filterSettings, action.payload)) {
				return;
			}
			return { ...ui, home: { ...ui.home, filterSettings: action.payload } };
		},
		setVisibleQuoteIdsLoadingStatus: (state, action) => {
			if (state.home.visibleQuoteIds.loadStatus === action.payload) {
				return;
			}
			return { ...state, home: { ...state.home, visibleQuoteIds: { ...state.home.visibleQuoteIds, loadStatus: action.payload } } };
		},
		setVisibleQuoteIds: (state, action) => {
			if (isEqual(state.home.visibleQuoteIds.list, action.payload) && action.payload.length !== 0) {
				return;
			}
			return { ...state, home: { ...state.home, visibleQuoteIds: {
				...state.home.visibleQuoteIds,
				list: action.payload,
				loadStatus: loadStatus.fullyLoaded,
			} } };
		},
		setFetchingVisibleQuoteIds: (state, action) => ({ ...state, home: { ...state.home, visibleQuoteIds: { ...state.home.visibleQuoteIds, fetching: action.payload } } }),
		setHeaderInjection: (state, action) => ({ ...state, headerInjection: action.payload }),
		setViewingQuoteId: (state, action) => ({ ...state, quote: { ...state.quote, viewingQuoteId: action.payload } }),
		setLocked: (state, action) => ({ ...state, quote: { ...state.quote, locked: action.payload } }),
		setViewingNewQuoteAsPsAdmin: (state, action) => ({ ...state, quote: { ...state.quote, viewingAsPsAdmin: action.payload } }),
		setDisplayPrices: (state, action) => ({ ...state, quote: { ...state.quote, displayPrices: action.payload } }),
		setShowingTabPane: (state, action) => ({ ...state, quote: { ...state.quote, tabPaneOpen: action.payload }})
	},
	// extraReducers: (builder) => {
	// }
});

export const SelectCreateQuoteModalOpen = () => (state: AppState) => state.ui.createQuoteOpen;

export const SelectModelOpen = (modalKey: string) => (state: AppState) => state.ui.modalsOpen[modalKey] ?? false;

export default uiSlice.reducer;

const {
	openCreateQuote,
	closeCreateQuote,
	pageLoaded,
	pageLoading,
	openModal,
	closeModal,
	setFilterSettings,
	setVisibleQuoteIds,
	setVisibleQuoteIdsLoadingStatus,
	setFetchingVisibleQuoteIds,
	setHeaderInjection,
	setViewingQuoteId,
	setLocked,
	setViewingNewQuoteAsPsAdmin,
	setDisplayPrices,
	setShowingTabPane
} = uiSlice.actions;

export {
	openCreateQuote,
	closeCreateQuote,
	pageLoaded,
	pageLoading,
	openModal,
	closeModal,
	setFilterSettings,
	setVisibleQuoteIds,
	setFetchingVisibleQuoteIds,
	setVisibleQuoteIdsLoadingStatus,
	setHeaderInjection,
	setViewingQuoteId,
	setLocked,
	setViewingNewQuoteAsPsAdmin,
	setDisplayPrices,
	setShowingTabPane
};
