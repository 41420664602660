import { useState, useEffect } from 'react';
import api from '../PowerShadesAPI';

import NewOrderPriceSummaryTable from '../Quote/NewItems/NewOrderPriceSummaryTable';
import Button from '../Parts/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const ConfirmApproval = (props) => {
	const [approvingOrder, setApprovingOrder] = useState(false);
	const [loadedQuote, setLoadedQuote] = useState(props.preLoadedQuote ? props.quote : null);
	const [quoteTerritory, setQuoteTerritory] = useState({});
	const [approved, setApproved] = useState(false);

	useEffect(() => {
		if (props.quote.territory_id) {
			api.getTerritoryInfo(props.quote.territory_id)
				.then((terr) => setQuoteTerritory(terr || {}));
		}
	}, []);

	const hide = () => props.close && props.close();

	const submit = () => {
		const response = api.populateDashboard(props.quote.ID).then((response) => {
			if (response.data.non_field_errors) {
				ROOT.alert({ text: `Failed to populate dashboard.\n${response.data.non_field_errors[0]}`, quickFormat: "error" });
			} else {
				api.approveOrder(props.quote.ID)
					.then((resp) => {
						if (resp.data.success) {
							setApproved(true);
							ROOT.alert({ quickFormat: "success", text: "Order approved!", }).then(() => {
								props.onComplete && props.onComplete();
							});
						} else {
							ROOT.alert({ text: "Error approving order.\nPlease contact us if this problem persists.", quickFormat: "error" });
							setApprovingOrder(false);
						}
					});
				setApprovingOrder(true);
			}
		});
	};

	const quote_title = props.preLoadedQuote ? props.quote.quote_options.title.value : (props.quote.title || '');

	return (

		<>
			{!approved ? (
				<div className="confirmation-modal-holder">
					<div className="order-approval-modal">

						<h2>Review Order</h2>

						<div>
							<table className="order-confirmation-table order-overview-table" border="0" cellSpacing="0" cellPadding="0">
								<tbody>
									<tr>
										<th>Order Title</th>
										<td>{quote_title}</td>
									</tr>
									<tr>
										<th>Distributor/Dealer Name</th>
										<td>{props.quote.dealer_name}</td>
									</tr>
									{
										props.quote.territory_id

											? (
												<tr>
													<th>Territory</th>
													<td>{quoteTerritory.name}</td>
												</tr>
											)
											: null
									}
									<tr>
										<th>Date Created</th>
										<td>{props.quote.created}</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div className="order-summary-holder">
							{loadedQuote ? null : <FontAwesomeIcon icon={faSpinner} className="loading-wheel" />}
							{loadedQuote ? <NewOrderPriceSummaryTable quote={props.quote} newQuote={props.newQuote} priceLabel={props.quote.pricing_as} quote={loadedQuote} /> : null}
						</div>

						<div className="button-holder">

							{
								approvingOrder

									? <FontAwesomeIcon icon={faSpinner} className="loading-wheel" /> : (
										<>

											<Button color="gray" onClick={hide}>Cancel</Button>
											<Button color="green" onClick={submit}>Approve Order</Button>
										</>
									)
							}

						</div>

					</div>
				</div>
			) : null}
		</>
	);
};

export default ConfirmApproval;
