import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import {
	Container,
	Row,
	Col,
	FormSelect,
	InputGroup,
	Form,
	Accordion,
	Offcanvas,
	Card,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import {
	faCog,
	faXmark,
	faFloppyDisk,
	faPenToSquare,
	faAngleLeft,
	faAngleRight,
	faCheck,
	faBars,
	faAngleDown,
	faAngleUp,
	faExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import Button from "../../../Parts/Button";
import { useMotors, useTubes } from "../../../Store/entities/parts/hooks";
import apiCalls from "../../../PowerShadesAPIFunctions";
import { ServiceScreenShadeVariables, ServiceScreenVariables, ShadeAssembliesAndCoordinates } from "../../../powershadesApiTypes";

import "./ServiceAssembly.scss";
import RadioButton from "../../../Parts/RadioButton";
import { PartCategories, propertyToDisplayNameMap, type ShadePart } from "../../../powershadesApiTypeExtensions";

import motorIcon from "../../../assets/icons/motor.svg";
import channelIcon from "../../../assets/icons/sidechannel.svg";
import shadeIcon from "../../../assets/icons/singleband.svg";
import hardwareIcon from "../../../assets/icons/hardware.svg";
import headerIcon from "../../../assets/icons/header-extrusion.svg";
import tubeIcon from "../../../assets/icons/tube.svg";
import sillChannelIcon from "../../../assets/icons/sillchannel.svg";
import hembarIcon from "../../../assets/icons/hembar.svg";
import fabricIcon from "../../../assets/icons/fabric.svg";

import motorIconDark from "../../../assets/icons/motor-dark.svg";
import channelIconDark from "../../../assets/icons/sidechannel-dark.svg";
import shadeIconDark from "../../../assets/icons/singleband-dark.svg";
import hardwareIconDark from "../../../assets/icons/hardware-dark.svg";
import headerIconDark from "../../../assets/icons/header-extrusion-dark.svg";
import tubeIconDark from "../../../assets/icons/tube-dark.svg";
import sillChannelIconDark from "../../../assets/icons/sillchannel-dark.svg";
import hembarIconDark from "../../../assets/icons/hembar-dark.svg";
import fabricIconDark from "../../../assets/icons/fabric-dark.svg";

import {
	CutDisplayNameToFunctionType,
	CutDisplayNameToStateType,
	PreviousShadeIdObjectWithEditBoolean,
	type DisplayNameToCutIconType,
	type NameToFunctionMapType,
	type NameToIconMapType,
	type NameToStateMapType,
	type ServiceAssemblyProps,
} from "./types";
import AccordionTable from "../../../Parts/AccordionTable";
import Money from "../../../Parts/Money";
import { partsListInDisplayCategories, transformCutProperties } from "./utils";
import { isFailedApiCall } from "../../../psUtil";
import SpinningWheel from "../../../Parts/SpinningWheel";
import { useUserTheme } from "../../../Store/user/hooks";
import { UseAssembly } from "../../../Store/entities/assemblies/hooks";
import { UsePortalDispatch } from "../../../Store";
import { updateAssemblyServiceScreen } from "../../../Store/entities/assemblies";

const ServiceAssembly = ({
	quoteId,
	sequenceId,
	SetRecipeSku,
	quoteType,
	previousQuoteId = 0,

}: ServiceAssemblyProps) => {
	const [open, setOpen] = useState(false);
	const [awaitingAPICall, setAwaitingAPICall] = useState(false);
	const [apiError, setApiError] = useState(false);
	const [displayPartsList, setDisplayPartsList] = useState(true);
	const [loadingParts, setLoadingParts] = useState(false);
	const [partFetchError, setPartFetchError] = useState("");
	const [activeAccordionKey, setActiveAccordionKey] = useState<string>("");
	const [partCategories, setPartCategories] = useState<PartCategories>({
		is_motor_only: [],
		is_tube_and_fabric_only: [],
		is_header_only: [],
		is_hardware_kit_only: [],
		is_channels_only: [],
		is_crown_and_drive_only: [],
	});
	const [fullParts, setFullParts] = useState<ShadePart[]>([]);
	const [actualParts, setActualParts] = useState<ShadePart[]>([]);
	const [originalAssemblyDetails, setOriginalAssemblyDetails] = useState<
		ShadeAssembliesAndCoordinates | null
	>(null);

	const motors = useMotors();
	const tubes = useTubes();
	const theme = useUserTheme();

	const dispatch = UsePortalDispatch();

	const [endButtonName, setEndButtonName] = useState<
		"Close" | "Saving..." | "Save & Close"
	>("Close");

	const assemblyStore = UseAssembly(quoteId, sequenceId);

	if (!assemblyStore) return null;
	const assembly = assemblyStore;

	// const [{
	// 	is_motor_only: motorOnly = false,
	// 	is_tube_and_fabric_only: tubeAndFabricOnly = false,
	// 	is_header_extrusion_only: headerOnly = false,
	// 	is_hardware_only: hardwareOnly = false,
	// 	is_channels_only: channelsOnly = false,
	// 	is_shade_service: serviceShade = false,
	// 	is_crown_and_drive_only: crownAndDriveOnly = false,
	// 	is_header_extrusion_to_be_cut: headerExtrusionToBeCut = false,
	// 	is_tube_to_be_cut: tubeToBeCut = false,
	// 	is_hembar_to_be_cut: hembarToBeCut = false,
	// 	is_sill_channels_to_be_cut: sillChannelsToBeCut = false,
	// 	is_side_channels_to_be_cut: sideChannelsToBeCut = false,
	// 	is_fabric_to_be_cut: fabricToBeCut = false,
	// 	recipe_sku: recipeSkuUnformatted,
	// 	unlocked,
	// 	shades,
	// 	last_time_priced: lastTimePriced,
	// }, setTempAssembly] = useState(assemblyStore);

	const [
		serviceChanges,
		setServiceChanges
	] = useState<ServiceScreenVariables>({
		shades: assembly.shades.map((shade) => {

			return {
				make_this_shade: shade.make_this_shade ?? true,
				col: shade.column_coordinate,
				row: shade.row_coordinate,
			} as ServiceScreenShadeVariables;
		}),
		is_channels_only: assembly?.is_channels_only,
		is_crowns_and_drives_only: assembly?.is_crown_and_drive_only,
		is_hardware_only: assembly?.is_hardware_only,
		is_header_extrusion_only: assembly?.is_header_extrusion_only,
		is_motor_only: assembly?.is_motor_only,
		is_tube_and_fabric_only: assembly?.is_tube_and_fabric_only,
		is_header_extrusion_to_be_cut: assembly?.is_header_extrusion_to_be_cut,
		is_tube_to_be_cut: assembly?.is_tube_to_be_cut,
		is_hembar_to_be_cut: assembly?.is_hembar_to_be_cut,
		is_sill_channels_to_be_cut: assembly?.is_sill_channels_to_be_cut,
		is_side_channels_to_be_cut: assembly?.is_side_channels_to_be_cut,
		is_fabric_to_be_cut: assembly?.is_fabric_to_be_cut,
		is_shade_service: assembly?.is_shade_service,
		motor_override: assembly?.motor_pricing_override,
		tube_override: assembly?.tube_pricing_override,
	});

	const unlocked = assembly?.unlocked ?? false;

	const setServiceChangesPartial = (newChanges: Partial<ServiceScreenVariables>) => {
		setServiceChanges({
			...serviceChanges,
			...newChanges,
		});
		setEndButtonName("Save & Close");
	}

	useEffect(() => {
		if(!assembly) return;

		if(assembly?.motor_pricing_override != serviceChanges.motor_override) {
			setServiceChangesPartial({motor_override: assembly.motor_pricing_override});
		}

	}, [assembly?.motor_pricing_override])

	const formattedRecipeSku = (assembly?.recipe_sku) ?? "";
	const [workingRecipeSku, setWorkingRecipeSku] = useState(formattedRecipeSku);
	const [editingRecipeSku, setEditingRecipeSku] = useState(false);
	const [workingPrevAssemblyId, setWorkingPrevAssemblyId] = useState(originalAssemblyDetails
		?.previous_shade_assembly_id ?? 0);
	const [editingPrevAssemblyId, setEditingPrevAssemblyId] = useState(false);

	const awaitingPricing = !assembly?.last_time_priced;

	const [previousShades, setPreviousShades] = useState<PreviousShadeIdObjectWithEditBoolean[]>([]);

	const failedPartsCall = partFetchError;

	const refetchParts = () => {
		setPartFetchError("");
	};

	useEffect(() => {
		if (!open) return;
		if (workingRecipeSku !== formattedRecipeSku && !editingRecipeSku) {
			refetchParts();
			setWorkingRecipeSku(formattedRecipeSku);
		}
	}, [editingRecipeSku, formattedRecipeSku, open, workingRecipeSku]);

	useEffect(() => {
		if (awaitingPricing && open) setLoadingParts(true);
	}, [awaitingPricing, open]);

	useEffect(() => {
		if (!open || failedPartsCall || !quoteId || awaitingPricing) return;
		setLoadingParts(true);
		apiCalls.getShadePartsList(quoteId, sequenceId).then((resp) => {
			console.log(resp);
			if (isFailedApiCall(resp)) {
				if (resp?.data?.success === undefined || resp.data.message === undefined) {
					console.error({
						resp,
						data: resp?.data,
						error: resp?.error,
						quoteId,
						sequenceId,
						assembly,
					});
					throw new Error("Failed to get shade parts list and no error message was returned");
				}
				setPartFetchError(resp.data.message);
				return;
			}
			const { data } = resp;

			setPartCategories(data.part_categories);
			setFullParts(data.full_parts);
			setActualParts(data.actual_parts);
			setLoadingParts(false);
		});
	}, [
		quoteId,
		sequenceId,
		open,
		assembly,
		failedPartsCall,
		awaitingPricing,
	]);

	useEffect(() => {
		if (awaitingAPICall || apiError || originalAssemblyDetails || !open || !previousQuoteId || !quoteId) return;

		const getPreviousShadeAssemblyIds = async () => {
			setAwaitingAPICall(true);
			apiCalls.getAssemblyIdsAndCoordinates(quoteId, sequenceId).then((resp) => {
				if (isFailedApiCall(resp, "previous_shade_assembly")) {
					setApiError(true);
					console.log("Error getting previous shade assembly ids");
					return;
				}
				const currentAssembly = resp.data.previous_shade_assembly;

				setOriginalAssemblyDetails(currentAssembly);
				setWorkingPrevAssemblyId(currentAssembly?.previous_shade_assembly_id ?? 0);

				const transformedShadeIds = currentAssembly?.previous_shade_ids.map((prevShade) => ({
					editing: false,
					...prevShade,
				}));

				setPreviousShades(transformedShadeIds);

				setAwaitingAPICall(false);
			});
		};
		getPreviousShadeAssemblyIds();
	}, [
		open,
		apiError,
		assembly,
		awaitingAPICall,
		sequenceId,
		previousQuoteId,
		originalAssemblyDetails,
		quoteId,
	]);

	const partsToDisplayCategories = partsListInDisplayCategories(fullParts ?? [], partCategories);
	const includedPartsToDisplayCategories = partsListInDisplayCategories(actualParts ?? [], partCategories);

	const onSetOpen = (openHere: boolean) => {
		if (openHere) {
			setOpen(true);
			return;
		}

		if (endButtonName === "Close") {
			setOpen(false);
			return;
		};

		if (endButtonName === "Saving...") return;

		if (endButtonName === "Save & Close") {
			setEndButtonName("Saving...");
			dispatch(updateAssemblyServiceScreen({ quoteId, sequenceId, serviceScreen: serviceChanges })).then((_resp) => {
				setEndButtonName("Close");
				toast.success("Service Screen Changes Saved");
				setOpen(false);
			});
		}
	};



	const motorTypes = {
		"PoE (Power over Ethernet)": "poe",
		"DC (Battery)": "low_voltage",
		"AC (Hardwired)": "high_voltage",
		"DC (Hardwired)": "low_voltage_hw",
	};

	// const motorTypeOptions = Object.values(motorTypes);

	const motorInShade = assembly?.shades?.find((shade) => shade.motor_type)?.motor_type;

	const saveRecipeSku = () => {
		SetRecipeSku(workingRecipeSku);
		setEditingRecipeSku(false);
		toast.success("Recipe SKU Saved");
	};

	const resetRecipeSku = () => {
		SetRecipeSku(formattedRecipeSku);
		setWorkingRecipeSku(formattedRecipeSku);
		setEditingRecipeSku(false);
		toast.info("Recipe SKU Edit Cancelled and Reset");
	};

	const tubeOptions = tubes?.map((t) => (
		<option title={t.name} key={t.sku} value={t.sku}>
			{t.sku}
		</option>
	));

	const motorOptions = motors?.map((t) => {
		let disabled = false;
		let title = t.name;

		const correctCompare = motorTypes[t.power_type];

		if (correctCompare !== motorInShade) {
			disabled = true;
			title = `This is a ${correctCompare} motor, when the assembly has ${motorInShade} selected.`;
		}

		return (
			<option disabled={disabled} key={t.sku} title={title} value={t.sku}>
				{t.sku}
			</option>
		);
	});

	tubeOptions.unshift(
		<option key="default-tube" title="No Override" value="">
			Default
		</option>
	);
	motorOptions.unshift(
		<option key="default-motor" title="No Override" value="">
			Default
		</option>
	);

	const assemblyUnlocked = unlocked;

	const expectedMotor = motors?.find((mo) => mo.sku === assembly?.motor_pricing_override);

	if (!expectedMotor) {
		motorOptions.push(
			<option
				disabled
				key={assembly?.motor_pricing_override}
				title={assembly?.motor_pricing_override}
				value={assembly?.motor_pricing_override}
			>
				INACTIVE
			</option>
		);
	}

	const resetPrevAssemblyId = () => {
		setWorkingPrevAssemblyId(originalAssemblyDetails?.previous_shade_assembly_id ?? 0);
		setEditingPrevAssemblyId(false);
	};

	const updatePreviousAssemblyId = () => {
		if (workingPrevAssemblyId === originalAssemblyDetails?.previous_shade_assembly_id) {
			toast.info("Previous Assembly ID is already set to this value");
			return;
		}
		apiCalls.setPreviousAssemblyId(quoteId, sequenceId, workingPrevAssemblyId).then((resp) => {
			if (isFailedApiCall(resp, "success")) {
				toast.error("Error saving previous assembly id");
				console.error({
					error: resp?.error,
					status: resp.status,
					data: resp?.data,
					sequenceId,
					workingPrevAssemblyId
				});
				resetPrevAssemblyId();
				throw new Error("Error saving previous assembly id");
			}
			toast.success("Previous Assembly ID Saved");
			setEditingPrevAssemblyId(false);
		});
	};

	const numberOfPreviousShades = originalAssemblyDetails?.previous_shade_ids?.length ?? 1;

	const previousShadeIdDisplay = previousShades.map((shadeIdPrev) => {
		const {
			previous_shade_id: previousShadeId,
			column_coordinate: columnCoordinate,
			row_coordinate: rowCoordinate,
		} = shadeIdPrev;

		const originalShade = originalAssemblyDetails?.previous_shade_ids?.find(
			(prevShade) => prevShade.previous_shade_id === previousShadeId
		);

		if (!originalShade) return null;

		const setEditing = () => {
			const updatedShades = previousShades.map((prevShade) => {
				if (prevShade.previous_shade_id !== previousShadeId) return prevShade;
				return {
					...prevShade,
					editing: true,
				};
			});
			setPreviousShades(updatedShades);
		};
		const handleSave = () => {
			const updatedShadeId = previousShades.find((prevShade) =>
				prevShade.previous_shade_id === previousShadeId)?.previous_shade_id;
			if (!updatedShadeId) {
				toast.error("Error saving previous shade id");
				console.error({
					previousShades,
					previousShadeId,
					quoteId,
					sequenceId,
					rowCoordinate,
					columnCoordinate,
				});
				throw new Error("Error saving previous shade id");
			}

			apiCalls.setPreviousShadeId(quoteId, sequenceId, updatedShadeId, rowCoordinate, columnCoordinate)
				.then((resp) => {
					if (isFailedApiCall(resp, "success")) {
						toast.error("Error saving previous shade id");
						return;
					}
					toast.success("Previous Shade ID Saved");
					setPreviousShades(
						previousShades.map((prevShade) => {
							if (prevShade.previous_shade_id !== updatedShadeId) return prevShade;
							return {
								...prevShade,
								editing: false,
							};
						})
					);
				});
		};
		const resetToOriginal = () => {
			setPreviousShades(
				previousShades.map((prevShade) => {
					if (prevShade.previous_shade_id !== previousShadeId) return prevShade;
					return {
						...prevShade,
						editing: false,
						previous_shade_id: originalShade.previous_shade_id,
					};
				})
			);
		};

		const rowAndColumnDisplay = (
			<InputGroup>
				<Form.Control
					className="form-control"
					disabled={!shadeIdPrev.editing || !unlocked}
					value={shadeIdPrev.previous_shade_id}
					onChange={(e) => {
						const newShadeString = e.target.value;
						const newShadeId = Number(newShadeString);
						if (Number.isNaN(newShadeId)) return;
						setPreviousShades(
							previousShades.map((prevShade) => {
								if (prevShade.previous_shade_id !== previousShadeId) return prevShade;
								return {
									...prevShade,
									previous_shade_id: newShadeId,
								};
							})
						);
					}}
				/>
				{shadeIdPrev.editing ? (
					<>
						<Button
							title="cancel"
							id={`cancel-previous-shade-edit-${rowCoordinate}-${columnCoordinate}`}
							color="gray"
							onClick={resetToOriginal}
						>
							<FontAwesomeIcon icon={faXmark} />
						</Button>
						<Button
							title="save"
							id={`save-previous-shade-${rowCoordinate}-${columnCoordinate}`}
							color={quoteType ?? "green"}
							onClick={handleSave}
						>
							<FontAwesomeIcon icon={faFloppyDisk} />
						</Button>
					</>
				) : (
					<Button disabled={!unlocked} color={quoteType ?? "green"} onClick={setEditing}>
						<FontAwesomeIcon icon={faPenToSquare} />
					</Button>
				)}
			</InputGroup>
		);
		return (
			<Col
				key={rowCoordinate + columnCoordinate}
				xs={12}
				sm={numberOfPreviousShades <= 1 ? 12 : 6}
				className="mb-2"
			>
				<h6>
					Row &nbsp;
					{rowCoordinate}
					&nbsp; - Col &nbsp;
					{columnCoordinate}
				</h6>
				{rowAndColumnDisplay}
			</Col>
		);
	});

	const namesToFunctionMap: NameToFunctionMapType = {
		"Tube and Fabric": () => setServiceChangesPartial({ is_tube_and_fabric_only: !serviceChanges.is_tube_and_fabric_only }),
		"Header Extrusion": () => setServiceChangesPartial({ is_header_extrusion_only: !serviceChanges.is_header_extrusion_only }),
		"Side/Sill Channels": () => setServiceChangesPartial({ is_channels_only: !serviceChanges.is_channels_only }),
		Motor: () => setServiceChangesPartial({ is_motor_only: !serviceChanges.is_motor_only }),
		Hardware: () => setServiceChangesPartial({ is_hardware_only: !serviceChanges.is_hardware_only }),
		Service: () => setServiceChangesPartial({ is_shade_service: !serviceChanges.is_shade_service }),
		"Crowns and Drives": () => setServiceChangesPartial({ is_crowns_and_drives_only: !serviceChanges.is_crowns_and_drives_only }),
	};

	const namesToStateMap: NameToStateMapType = {
		"Tube and Fabric": serviceChanges.is_tube_and_fabric_only,
		"Header Extrusion": serviceChanges.is_header_extrusion_only,
		"Side/Sill Channels": serviceChanges.is_channels_only,
		Motor: serviceChanges.is_motor_only,
		Hardware: serviceChanges.is_hardware_only,
		Service: serviceChanges.is_shade_service,
		"Crowns and Drives": serviceChanges.is_crowns_and_drives_only
	};

	const namesToIconMap: NameToIconMapType = {
		"Tube and Fabric": (
			<img src={theme === "light" ? tubeIcon : tubeIconDark} className="custom-icon" alt="Icon of a shade" />
		),
		"Header Extrusion": (
			<img
				src={theme === "light" ? headerIcon : headerIconDark}
				className="custom-icon"
				alt="Icon of a header extrusion"
			/>
		),
		"Side/Sill Channels": (
			<img
				src={theme === "light" ? channelIcon : channelIconDark}
				className="custom-icon"
				alt="Icon of a side channel"
			/>
		),
		Motor: (
			<img src={theme === "light" ? motorIcon : motorIconDark} className="custom-icon" alt="Icon of a motor" />
		),
		Hardware: (
			<img
				src={theme === "light" ? hardwareIcon : hardwareIconDark}
				className="custom-icon"
				alt="Icon of hardware"
			/>
		),
	};

	const buttonCategories = Object.values(propertyToDisplayNameMap);

	const displayButtons = buttonCategories.map((category) => {
		const relevantFunction = namesToFunctionMap[category];
		const relevantState = namesToStateMap[category];

		return (
			<Col key={category} xs={12} sm={6} className="mb-2">
				<RadioButton
					disabled={!unlocked}
					fullWidth
					displayText={category}
					state={relevantState}
					onClick={() => relevantFunction(!relevantState)}
					color={quoteType}
				/>
			</Col>
		);
	});

	const enabledPartCategories = Object.entries(partsToDisplayCategories).filter((category) => {
		const [categoryName, _] = category;
		const categoryIncludedInActualParts = includedPartsToDisplayCategories[categoryName];
		if (!categoryIncludedInActualParts) return false;
		const partsInActualParts = categoryIncludedInActualParts.length > 0;
		return partsInActualParts;
	});

	const enabledPricePartTotal = enabledPartCategories.reduce((acc, category) => {
		const [categoryName, partsInCategory] = category;
		const actualPartsInCategory: ShadePart[] = includedPartsToDisplayCategories[categoryName];

		if (!partsInCategory || !actualPartsInCategory) return acc;
		const categoryPartTotal = actualPartsInCategory.reduce((acc2, part) => {
			const partTotal = part.cost * Number(part.quantity);
			return acc2 + partTotal;
		}, 0);
		return acc + categoryPartTotal;
	}, 0);

	const pricingDisplay = (
		<Row className="mt-auto">
			<Col>
				<h5 className={`text-${quoteType}`}>Total</h5>
			</Col>
			<Col className="text-end">
				<h5 className="text-muted"><Money value={enabledPricePartTotal} /></h5>
			</Col>
		</Row>
	);

	const partsAccordions = enabledPartCategories.map((category) => {
		const [categoryName, partsInCategory] = category;
		const actualPartsInCategory: ShadePart[] = includedPartsToDisplayCategories[categoryName];

		if (!partsInCategory || !actualPartsInCategory) return null;
		return (
			<Row key={categoryName} className="mb-2">
				<Col xs={12} className="p-0">
					<Accordion aria-label={`${categoryName.toLowerCase()}-parts-accordion`}>
						<Accordion.Item eventKey={categoryName}>
							<Accordion.Header className={quoteType}>
								{namesToIconMap[categoryName]}
								&nbsp;
								{categoryName}
								&nbsp;Parts
							</Accordion.Header>
							<Accordion.Body>
								<Row>
									<AccordionTable>
										<thead>
											<tr>
												<th />
												<th>Part Name</th>
												<th>UoM</th>
												<th>QTY</th>
												<th>Cost</th>
												{/* <th>Shipping</th> */}
												<th>Total</th>
											</tr>
										</thead>
										<tbody>
											{partsInCategory.map((part: ShadePart) => (
												<OverlayTrigger
													key={part.sku}
													overlay={
														!part.is_active ? (
															<Tooltip>
																This part is no long active in Zoho Inventory
															</Tooltip>
														) : (
															<></>
														)
													}
												>
													<tr
														key={part.sku}
														className={`${part.is_active ? "active" : "inactive"} ${actualPartsInCategory.find((ap) => ap.sku === part.sku)
															? "enabled"
															: "disabled"
															}`}
													>
														<td>
															{actualPartsInCategory.find((ap) => ap.sku === part.sku) ? (
																<FontAwesomeIcon icon={faCheck} />
															) : (
																<FontAwesomeIcon icon={faXmark} />
															)}
															{!part?.is_active && (
																<FontAwesomeIcon icon={faExclamation} />
															)}
														</td>
														<td>{part.name}</td>
														<td>{part.uom}</td>
														<td>{Number(part.quantity)}</td>
														<td>{Money({ value: part.cost })}</td>
														{/* <td>{part.shipping}</td> */}
														<td>
															{Money({
																value: part.cost * Number(part.quantity),
															})}
														</td>
													</tr>
												</OverlayTrigger>
											))}
										</tbody>
									</AccordionTable>
								</Row>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</Col>
			</Row>
		);
	});

	const partsDisplay = (
		<Container className="local-bootstrap d-flex flex-column parts-list">
			{loadingParts && !failedPartsCall ? (
				<Row className="mt-4">
					<Col className="text-center mt-4">
						<SpinningWheel className={`text-${quoteType}`} size="3x" />
					</Col>
				</Row>
			) : (
				<>
					{partsAccordions}
					<hr />
				</>
			)}
		</Container>
	);

	const groupByRows = (data: ServiceScreenShadeVariables[]): { row: ServiceScreenShadeVariables[] }[] => {
		const grouped = data.reduce((acc, obj) => {
			// Check if the row already exists in the accumulator
			if (!acc.has(obj.row)) {
				acc.set(obj.row, []);
			}

			// Get the current array for the row and add the object
			acc.get(obj.row)?.push(obj);

			return acc;
		}, new Map<number, ServiceScreenShadeVariables[]>());

		// Convert the Map into an array of the desired format
		return Array.from(grouped, ([_, value]) => ({ row: value }));
	};

	const shadeBandsByRow = groupByRows(serviceChanges.shades);

	const cutDisplayNamesToIconMap: DisplayNameToCutIconType = {
		Tube: <img className="custom-icon" src={theme === "light" ? tubeIcon : tubeIconDark} alt="tube icon" />,
		"Header Extrusion": (
			<img className="custom-icon" src={theme === "light" ? headerIcon : headerIconDark} alt="header icon" />
		),
		"Side Channels": (
			<img
				className="custom-icon"
				src={theme === "light" ? channelIcon : channelIconDark}
				alt="side channel icon"
			/>
		),
		"Sill Channel": (
			<img
				className="custom-icon"
				src={theme === "light" ? sillChannelIcon : sillChannelIconDark}
				alt="sill channel icon"
			/>
		),
		Fabric: <img className="custom-icon" src={theme === "light" ? fabricIcon : fabricIconDark} alt="fabric icon" />,
		Hembar: <img className="custom-icon" src={theme === "light" ? hembarIcon : hembarIconDark} alt="hembar icon" />,
	};

	const cutDisplayNamesToStateMap: CutDisplayNameToStateType = {
		Tube: serviceChanges?.is_tube_to_be_cut,
		"Header Extrusion": serviceChanges?.is_header_extrusion_to_be_cut,
		"Side Channels": serviceChanges?.is_side_channels_to_be_cut,
		"Sill Channel": serviceChanges?.is_sill_channels_to_be_cut,
		Fabric: serviceChanges?.is_fabric_to_be_cut,
		Hembar: serviceChanges?.is_hembar_to_be_cut,
	};

	const cutDisplayNamesToFunctions: CutDisplayNameToFunctionType = {
		Tube: (state: boolean) => setServiceChangesPartial({ is_tube_to_be_cut: state }),
		"Header Extrusion": (state: boolean) => setServiceChangesPartial({ is_header_extrusion_to_be_cut: state }),
		"Side Channels": (state: boolean) => setServiceChangesPartial({ is_side_channels_to_be_cut: state }),
		"Sill Channel": (state: boolean) => setServiceChangesPartial({ is_sill_channels_to_be_cut: state }),
		Fabric: (state: boolean) => setServiceChangesPartial({ is_fabric_to_be_cut: state }),
		Hembar: (state: boolean) => setServiceChangesPartial({ is_hembar_to_be_cut: state }),
	};

	const shadeBandRowTransformer = (rowObject: { row: ServiceScreenShadeVariables[] }) => {
		const { row } = rowObject;

		const rowId = row[0]?.row ?? 0;

		const shadeBandData = row.map((shadeBand) => ({
			...shadeBand,
			make_this_shade: shadeBand.make_this_shade ?? true,
		}));
		const transformedCutOptions = transformCutProperties(assembly);
		return {
			singleOrDual: assembly?.single_dual ?? "Single",
			shades: shadeBandData,
			rowCoordinate: rowId,
			cutOptions: transformedCutOptions,
		};
	};

	const transformedRows = shadeBandsByRow.map(shadeBandRowTransformer);

	const isDual = assembly?.single_dual === "Dual";
	const isCoupled = assembly?.is_coupled ?? false;
	const isDualOrCoupled = isDual || isCoupled;

	const assemblyCutOptionButtons = Object.entries(cutDisplayNamesToIconMap).map((cutOption) => {
		const [displayName, icon] = cutOption;
		const propertyState = Boolean(cutDisplayNamesToStateMap[displayName]) ?? false;
		const propertyFunction = cutDisplayNamesToFunctions[displayName];

		const stateVerb = propertyState ? "enabled" : "disabled";

		return (
			<Col xs={2} key={displayName}>
				<OverlayTrigger
					overlay={
						<Tooltip id={displayName}>
							{!unlocked && (
								<strong className="d-block">Please unlock this assembly to make changes</strong>
							)}
							{displayName}
							&nbsp;remake is currently&nbsp;
							{stateVerb}
						</Tooltip>
					}
				>
					<button
						aria-label={`remake-${displayName.toLowerCase()}`}
						type="button"
						disabled={!unlocked}
						className={`no-style ${unlocked ? "" : "disabled"}`}
						onClick={() => propertyFunction(!propertyState)}
					>
						<span className="fa-stack">
							{icon}
							<FontAwesomeIcon
								className={`fa-stack-1x state-icon ${unlocked ? "" : "disabled"} ${propertyState
									?.toString()
									?.toLowerCase()}`}
								icon={propertyState ? faCheck : faXmark}
							/>
						</span>
					</button>
				</OverlayTrigger>
			</Col>
		);
	});

	const shadeBandRowDisplay = transformedRows.map((assemblyData) => {
		const { shades } = assemblyData;
		const displayName =
			assemblyData.rowCoordinate === 0
				? assemblyData.singleOrDual === "Dual"
					? "Front Shade"
					: "Single Shade"
				: "Back Shade";
		const includeColumn = assemblyData.shades.length > 1;
		const shadeBandDisplay = shades.map((shadeBand) => {
			const { make_this_shade: makeThisShade } = shadeBand;
			const remakeState = Boolean(makeThisShade) ?? false;
			const stateVerb = remakeState ? "enabled" : "disabled";

			return (
				<Col xs={3} key={shadeBand + displayName}>
					<OverlayTrigger

						overlay={
							<Tooltip id={displayName}>
								{!unlocked && (
									<strong className="d-block">Please unlock this assembly to make changes</strong>
								)}
								{displayName}
								{includeColumn && (
									<>
										&nbsp;- Col&nbsp;
										{shadeBand.col}
									</>
								)}
								&nbsp;remake is currently&nbsp;
								{stateVerb}
							</Tooltip>
						}
					>
						<button
							// eslint-disable-next-line @stylistic/max-len
							aria-label={`remake-${displayName.toLowerCase()}${includeColumn ? `-${shadeBand.col}` : ""
								}`}
							disabled={!unlocked}
							type="button"
							className={`no-style ${unlocked ? "" : "disabled"}`}
							onClick={() => {
								setServiceChangesPartial({
									shades: serviceChanges.shades.map((shade) => {
										if (shade.row === assemblyData.rowCoordinate && shade.col === shadeBand.col) {
											return {
												...shade,
												make_this_shade: !shade.make_this_shade,
											};
										}
										return shade;
									}),
								});
							}
							}
						>
							<span className="fa-stack">
								<img
									src={theme === "light" ? shadeIcon : shadeIconDark}
									className="custom-icon"
									alt="Icon of a shade"
								/>
								<FontAwesomeIcon
									className={`fa-stack-1x state-icon ${unlocked ? "" : "disabled"} ${remakeState}`}
									icon={remakeState ? faCheck : faXmark}
								/>
							</span>
						</button>
					</OverlayTrigger>
				</Col>
			);
		});

		const allowExpansion = assemblyData.shades.length > 1;

		const shadeBandDisplayRows = (
			<Card key={assemblyData.rowCoordinate}>
				<Card.Header className="accordion-card-header">
					<Row className="g-0">
						<Col xs={6}>
							{allowExpansion ? (
								<button
									type="button"
									onClick={() => {
										if (activeAccordionKey === assemblyData.rowCoordinate.toString()) {
											setActiveAccordionKey("");
											return;
										}
										setActiveAccordionKey(assemblyData.rowCoordinate.toString());
									}}
									className="no-style"
								>
									<Row>
										<Col xs={10}>{displayName}</Col>
										<Col xs={2}>
											{activeAccordionKey === assemblyData.rowCoordinate.toString() ? (
												<FontAwesomeIcon icon={faAngleUp} />
											) : (
												<FontAwesomeIcon icon={faAngleDown} />
											)}
										</Col>
									</Row>
								</button>
							) : (
								<Row>
									<Col>{displayName}</Col>
									{!allowExpansion && shadeBandDisplay}
								</Row>
							)}
						</Col>
						{Boolean(serviceChanges.is_shade_service) && (
							<Col xs={6}>
								<Row className="justify-content-end me-4">{assemblyCutOptionButtons}</Row>
							</Col>
						)}
					</Row>
				</Card.Header>
				{(isDualOrCoupled) && (
					<Accordion.Collapse eventKey={assemblyData.rowCoordinate.toString()}>
						<Card.Body>
							<Row>{shadeBandDisplay}</Row>
						</Card.Body>
					</Accordion.Collapse>
				)}
			</Card>
		);
		if (isDualOrCoupled) {
			return (
				<Accordion
					key={`${assemblyData.rowCoordinate}-accordion`}
					activeKey={activeAccordionKey}
					className="mb-2"
				>
					{shadeBandDisplayRows}
				</Accordion>
			);
		}
		return shadeBandDisplayRows;
	});

	const serviceOptionsDisplay = (
		<>
			<Row>
				<Col>
					<h5 className={`text-${quoteType}`}>Shade Service Options</h5>
				</Col>
			</Row>
			<Row className="mb-4">
				<Col>{shadeBandRowDisplay}</Col>
			</Row>
		</>
	);

	return (
		<>
			<Popup
				open={open}
				data-test-id="service-assembly-modal"
				key="service-assembly-modal"
				onClose={() => onSetOpen(false)}
				className={`mobile-modal service-assembly-modal ${displayPartsList ? "parts" : ""}`}
				closeOnDocumentClick={false}
			>
				<Row className="user-select-none content-container">
					<Col className="service-assembly-content">
						<Container className="local-bootstrap h-100 assembly-container">
							<Row>
								<Col xs={10}>
									<h4 className={`text-${quoteType}`}>Assembly Service Screen</h4>
								</Col>
								<Col lg={2} className="d-none d-lg-inline">
									<Button
										color="outline-secondary"
										fullWidth
										onClick={() => setDisplayPartsList(!displayPartsList)}
									>
										{displayPartsList ? (
											<FontAwesomeIcon icon={faAngleLeft} />
										) : (
											<FontAwesomeIcon icon={faAngleRight} />
										)}
									</Button>
								</Col>
								<Col xs={2} className="p-0 d-lg-none">
									<Button
										ariaLabel="Show Service Selection"
										color={quoteType}
										fullWidth
										onClick={() => setDisplayPartsList(!displayPartsList)}
									>
										<FontAwesomeIcon icon={faBars} />
									</Button>
								</Col>
							</Row>
							<Row>
								<Col>
									<Row>
										<Col>
											<h5 className={`text-${quoteType}`}>Shade Customization</h5>
										</Col>
									</Row>
									<Row>
										<Col>
											<p className="text-muted">
												Select the bits and pieces of the shade you want made - or if it&apos;s
												a cut down.
											</p>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row>{displayButtons}</Row>
							<hr />
							<Row className="mb-2">
								<Col xs={12}>
									<Row>
										<Col>
											<h5 className={`text-${quoteType}`}>Recipe SKU</h5>
										</Col>
									</Row>
									<Row className="mb-2">
										<Col>
											<InputGroup>
												<Form.Control
													className="form-control"
													disabled={!editingRecipeSku}
													value={workingRecipeSku}
													onChange={(e) => setWorkingRecipeSku(e.target.value)}
												/>
												{editingRecipeSku ? (
													<>
														<Button
															title="cancel"
															id="cancel-recipe-sku"
															color="gray"
															onClick={resetRecipeSku}
														>
															<FontAwesomeIcon icon={faXmark} />
														</Button>
														<Button
															title="save"
															id="save-recipe-sku"
															color={quoteType ?? "green"}
															onClick={saveRecipeSku}
														>
															<FontAwesomeIcon icon={faFloppyDisk} />
														</Button>
													</>
												) : (
													<Button
														color={quoteType ?? "green"}
														onClick={() => setEditingRecipeSku(true)}
													>
														<FontAwesomeIcon icon={faPenToSquare} />
													</Button>
												)}
											</InputGroup>
										</Col>
									</Row>
								</Col>
							</Row>
							<hr />
							<Row className="mb-3">
								<Col xs={12} md={6}>
									<Row>
										<Col>
											<h5 className={`text-${quoteType}`}>Tube SKU</h5>
										</Col>
									</Row>
									<Row>
										<Col>
											<FormSelect
												disabled={!assemblyUnlocked}
												value={serviceChanges.tube_override}
												onChange={(e) => {
													setServiceChangesPartial({ tube_override: e.target.value });
												}}
											>
												{tubeOptions}
											</FormSelect>
										</Col>
									</Row>
								</Col>
								<hr className="d-md-none mt-4 mb-4" />
								<Col xs={12} md={6}>
									<Row>
										<Col>
											<h5 className={`text-${quoteType}`}>Motor Override</h5>
										</Col>
									</Row>
									<Row>
										<Col>
											<FormSelect
												disabled={!assemblyUnlocked}
												value={serviceChanges.motor_override}
												onChange={(e) => {
													setServiceChangesPartial({ motor_override: e.target.value });
												}}
												title={serviceChanges.motor_override}
											>
												{motorOptions}
											</FormSelect>
										</Col>
									</Row>
								</Col>
							</Row>
							{previousQuoteId && (
								<>
									<hr />
									<Row className="mb-4">
										<Col>
											<Row>
												<Col xs={12} md={5}>
													<h5 className={`text-${quoteType}`}>Previous Shade Assembly ID</h5>
												</Col>
												<Col xs={12} md={7}>
													<h5 className={`text-${quoteType}`}>Previous Shade Id(s)</h5>
												</Col>
											</Row>
											<Row>
												<Col xs={12} md={5}>
													<InputGroup>
														<Form.Control
															className="form-control"
															disabled={!editingPrevAssemblyId || !unlocked}
															value={workingPrevAssemblyId}
															onChange={(e) => {
																const numberFromString = Number(e.target.value);
																if (Number.isNaN(numberFromString)) return;
																setWorkingPrevAssemblyId(numberFromString);
															}}
														/>
														{editingPrevAssemblyId ? (
															<>
																<Button
																	title="cancel"
																	id="cancel-previous-assembly-edit"
																	color="gray"
																	onClick={resetPrevAssemblyId}
																>
																	<FontAwesomeIcon icon={faXmark} />
																</Button>
																<Button
																	title="save"
																	id="save-previous-assembly"
																	color={quoteType ?? "green"}
																	onClick={updatePreviousAssemblyId}
																>
																	<FontAwesomeIcon icon={faFloppyDisk} />
																</Button>
															</>
														) : (
															<Button
																disabled={!unlocked}
																color={quoteType}
																onClick={() =>
																	setEditingPrevAssemblyId(!editingPrevAssemblyId)}
															>
																<FontAwesomeIcon icon={faPenToSquare} />
															</Button>
														)}
													</InputGroup>
												</Col>
												<hr className="d-md-none mt-4 mb-4" />
												<Col xs={12} md={7}>
													<Row>
														{numberOfPreviousShades <= 2 && previousShadeIdDisplay}
														{numberOfPreviousShades > 2 && (
															<Accordion>
																<Accordion.Header>
																	{numberOfPreviousShades}
																	&nbsp; Previous Shade Ids
																</Accordion.Header>
																<Accordion.Body>
																	{previousShadeIdDisplay}
																</Accordion.Body>
															</Accordion>
														)}
													</Row>
												</Col>
											</Row>
										</Col>
									</Row>
								</>
							)}
							{!displayPartsList ? (
								<Row className="justify-content-end">
									<Col className="text-end" xs={4}>
										<Button fullWidth color={quoteType ?? "green"} onClick={() => onSetOpen(false)}>
											{endButtonName}
										</Button>
									</Col>
								</Row>
							) : (
								<Row className="justify-content-end d-lg-none">
									<Col className="text-end mb-4" xs={6}>
										<Button fullWidth color={quoteType ?? "green"} onClick={() => onSetOpen(false)}>
											{endButtonName}
										</Button>
									</Col>
								</Row>
							)}
						</Container>
					</Col>
					<Col className={`parts-content d-none d-lg-inline ${!displayPartsList ? "over-ride-block" : ""}`}>
						<Container className="local-bootstrap h-100 d-flex flex-column">
							<Row>
								<Col>
									<h4 className={`text-${quoteType}`}>Service Selection</h4>
								</Col>
							</Row>
							{serviceOptionsDisplay}
							{failedPartsCall ? (
								<Row className="mt-4 mb-4">
									<Col className="text-center mt-4">
										<h5 className="text-danger">{partFetchError}</h5>
									</Col>
								</Row>
							) : (
								<Row>
									<Col>
										<h5 className={`text-${quoteType}`}>Parts List</h5>
										{partsDisplay}
									</Col>
								</Row>
							)}
							{!failedPartsCall && pricingDisplay}
							<Row className={`${failedPartsCall ? "mt-auto" : ""} justify-content-end`}>
								<Col xs={4} className="text-end">
									<Button fullWidth color={quoteType ?? "green"} onClick={() => onSetOpen(false)}>
										{endButtonName}
									</Button>
								</Col>
							</Row>
						</Container>
					</Col>
					<Offcanvas
						show={!displayPartsList}
						onHide={() => setDisplayPartsList(true)}
						responsive="lg"
						className="d-lg-none"
					>
						<Offcanvas.Header closeButton>
							<Offcanvas.Title>
								<h4 className={`text-${quoteType}`}>Service Selection</h4>
							</Offcanvas.Title>
						</Offcanvas.Header>
						<Offcanvas.Body>
							{serviceOptionsDisplay}
							{failedPartsCall ? (
								<Row className="mt-4 mb-4">
									<Col className="text-center mt-4">
										<h5 className="text-danger">Failed to load parts</h5>
									</Col>
								</Row>
							) : (
								<Row>
									<Col>
										<h5 className={`text-${quoteType}`}>Parts List</h5>
										{partsDisplay}
									</Col>
								</Row>
							)}
							{pricingDisplay}
						</Offcanvas.Body>
					</Offcanvas>
				</Row>
			</Popup>
			<Col>
				<Button
					ariaLabel="Open Service Assembly Modal"
					fullWidth
					key="service-assembly-button"
					size="sm"
					color="light"
					hoverColor={quoteType}
					onClick={() => onSetOpen(true)}
				>
					<FontAwesomeIcon icon={faCog} />
				</Button>
			</Col>
		</>
	);
};

export default ServiceAssembly;
