import Money from '../Parts/Money';
import { useCalculateShadeMSRP } from '../QuotePricing';
import Button from '../Parts/Button';

const ACTUAL_COST_MULTIPLIER = 0.2133;

const CostBreakdownTable = (props) => {
	const { shade } = props;

	const pricing_data = useCalculateShadeMSRP(props.quote, shade);

	function addShadeSubProperty(sub_item, property_name) {
		if (!pricing_data) {
			return 0;
		}

		return (pricing_data.shades || [])
			.filter((s) => s[sub_item] != undefined)
			.reduce(
				(acc, s) =>
					acc + (s[sub_item][property_name] ? parseFloat(s[sub_item][property_name]) : 0),
				0
			);
	}

	function addShadeProperty(property_name) {
		if (!pricing_data) {
			return 0;
		}

		return (pricing_data.shades || []).reduce(
			(acc, s) => acc + (s[property_name] ? parseFloat(s[property_name]) : 0),
			0
		);
	}

	const shipping_cost = addShadeProperty('shipping_price');

	const side_channels_msrp = shade.isOutdoorShade()
		? addShadeProperty('side_channels_msrp')
		: pricing_data
		? pricing_data.side_channels_msrp
		: 0;

	const hembar_msrp = addShadeProperty('hembar_msrp');

	const { sill_channel_msrp, end_caps_msrp, total_msrp } = pricing_data || {};

	console.log({ pricing_data });

	return (
		<table cellSpacing="0">
			<thead>
				<tr>
					<th />
					<th>Cost</th>
					<th>Distributor Price</th>
					<th>Dealer Price</th>
					<th>MSRP</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Fabric</td>
					<td>
						<Money value={addShadeSubProperty('fabric', 'cost')} />
					</td>
					<td>
						<Money value={addShadeSubProperty('fabric', 'dist_sale_price')} />
					</td>
					<td>
						<Money value={addShadeSubProperty('fabric', 'dealer_sale_price')} />
					</td>
					<td>
						<Money value={addShadeSubProperty('fabric', 'msrp')} />
					</td>
				</tr>

				<tr>
					<td>Hembar</td>
					<td>
						<Money value={addShadeSubProperty('hembar', 'cost')} />
					</td>
					<td>
						<Money value={addShadeSubProperty('hembar', 'dist_sale_price')} />
					</td>
					<td>
						<Money value={addShadeSubProperty('hembar', 'dealer_sale_price')} />
					</td>
					<td>
						<Money value={addShadeSubProperty('hembar', 'msrp')} />
					</td>
				</tr>

				<tr>
					<td>Motor</td>
					<td>
						<Money value={addShadeSubProperty('motor', 'cost')} />
					</td>
					<td>
						<Money value={addShadeSubProperty('motor', 'dist_sale_price')} />
					</td>
					<td>
						<Money value={addShadeSubProperty('motor', 'dealer_sale_price')} />
					</td>
					<td>
						<Money value={addShadeSubProperty('motor', 'msrp')} />
					</td>
				</tr>

				<tr>
					<td>Header</td>
					<td>
						<Money value={addShadeSubProperty('header', 'cost')} />
					</td>
					<td>
						<Money value={addShadeSubProperty('header', 'dist_sale_price')} />
					</td>
					<td>
						<Money value={addShadeSubProperty('header', 'dealer_sale_price')} />
					</td>
					<td>
						<Money value={addShadeSubProperty('header', 'msrp')} />
					</td>
				</tr>

				<tr>
					<td>Side Channels</td>
					<td>
						<Money value={addShadeSubProperty('channels', 'side_cost')} />
					</td>
					<td>
						<Money value={addShadeSubProperty('channels', 'side_dist_sale_price')} />
					</td>
					<td>
						<Money value={addShadeSubProperty('channels', 'side_dealer_sale_price')} />
					</td>
					<td>
						<Money value={addShadeSubProperty('channels', 'side_msrp')} />
					</td>
				</tr>

				{shade.isOutdoorShade() ? null : (
					<>
						<tr>
							<td>Sill Channel</td>
							<td>
								<Money value={addShadeSubProperty('channels', 'sill_cost')} />
							</td>
							<td>
								<Money
									value={addShadeSubProperty('channels', 'sill_dist_sale_price')}
								/>
							</td>
							<td>
								<Money
									value={addShadeSubProperty(
										'channels',
										'sill_dealer_sale_price'
									)}
								/>
							</td>
							<td>
								<Money value={addShadeSubProperty('channels', 'sill_msrp')} />
							</td>
						</tr>

						<tr>
							<td>Tube</td>
							<td>
								<Money value={addShadeSubProperty('tube', 'cost')} />
							</td>
							<td>
								<Money value={addShadeSubProperty('tube', 'dist_sale_price')} />
							</td>

							<td>
								<Money value={addShadeSubProperty('tube', 'dealer_sale_price')} />
							</td>
							<td>
								<Money value={addShadeSubProperty('tube', 'msrp')} />
							</td>
						</tr>

						<tr>
							<td>Shade Adds</td>
							<td>
								<Money value={addShadeSubProperty('shade_adds', 'cost')} />
							</td>
							<td>
								<Money
									value={addShadeSubProperty('shade_adds', 'dist_sale_price')}
								/>
							</td>
							<td>
								<Money
									value={addShadeSubProperty('shade_adds', 'dealer_sale_price')}
								/>
							</td>
							<td>
								<Money value={addShadeSubProperty('shade_adds', 'msrp')} />
							</td>
						</tr>
						<tr>
							<td>Hardware Kit</td>
							<td>
								<Money
									value={
										pricing_data && pricing_data.hardware_kit
											? pricing_data.hardware_kit.cost
											: 0
									}
								/>
							</td>
							<td>
								<Money
									value={
										pricing_data && pricing_data.hardware_kit
											? pricing_data.hardware_kit.dist_sale_price
											: 0
									}
								/>
							</td>
							<td>
								<Money
									value={
										pricing_data && pricing_data.hardware_kit
											? pricing_data.hardware_kit.dealer_sale_price
											: 0
									}
								/>
							</td>
							<td>
								<Money
									value={
										pricing_data && pricing_data.hardware_kit
											? pricing_data.hardware_kit.msrp
											: 0
									}
								/>
							</td>
						</tr>
					</>
				)}

				<tr style={{ background: 'transparent' }}>
					<td>Total</td>
					<td>
						<Money value={pricing_data ? pricing_data.total_cost : 0} />
					</td>
					<td>
						<Money value={pricing_data ? pricing_data.total_dist_sale_price : 0} />
					</td>
					<td>
						<Money value={pricing_data ? pricing_data.total_dealer_sale_price : 0} />
					</td>
					<td>
						<Money value={pricing_data ? pricing_data.total_msrp : 0} />
					</td>
				</tr>
			</tbody>
		</table>
	);
};

const ShadeCostBreakdown = ({ shade, quote, onClose }) => (
	<div className="standard-modal-holder">
		<div className="standard-modal shade-price-breakdown" style={{ width: '25em' }}>
			<h2 style={{ textAlign: 'center' }}>Internal Cost Breakdown</h2>

			<h3>
				{shade.val('room_name')}
				-
				{shade.val('shade_name')}
			</h3>

			<CostBreakdownTable quote={quote} shade={shade} />

			<div className="button-holder align-center">
				<Button color="green" onClick={onClose}>
					Done
				</Button>
			</div>
		</div>
	</div>
);

export default ShadeCostBreakdown;
