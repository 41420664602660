import React from 'react';

import type { ChipProps } from './types';
import Button from '../../Parts/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const Chip = ({ className, id, label, deleteButton, handleDelete }: ChipProps) => {
	return <Button 
		className={className}
		color="light-gray"
		size="sm"
		onClick={() => handleDelete(id)}
		>
			{label}
			{deleteButton && <FontAwesomeIcon icon={faXmark} className="ms-2" />}
		</Button>
};

export default Chip;
