/* eslint-disable no-constant-condition */
/* eslint-disable @stylistic/no-mixed-operators */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @stylistic/max-len */
import React, { Dispatch, Fragment, ReactNode, SetStateAction, useState } from 'react';
import Popup from 'reactjs-popup';
import { applyPlugin } from 'jspdf-autotable';
import { Container, Row, Col, FormSelect } from 'react-bootstrap';

import jsPDF from 'jspdf';

import SpinningWheel from '../../Parts/SpinningWheel';
// import QuotePricingLockedNew from './QuotePricingLockedNew';
import UnlockQuoteModal from './UnlockQuoteModal';
import PlaceOrder from '../OrderModal/PlaceOrder';
import { exportQuote } from '../../ExcelExport/Export';
import { exportOutdoorOrderForm } from '../../OutdoorShadesExport/Export';
import ForceEditModal from '../ForceEditModal';
import QuoteLevelVariables from './QuoteLevelVariables';
import InvoiceStatus from '../InvoiceStatus';
import AdvancedShadeManipulation from './AdvancedShadeManipulation';
import BidSpecControl from './BidSpecControl';
import DemoReview from './DemoReviewComponent';
import PrintQuoteNew from './PrintQuoteNew';
import Button from '../../Parts/Button';
import CorradiOnly from './CorradiOnly';
import ShadeImport from '../ShadeImport';
import ApproveOrderButton from './ApproveOrderButton';
import { repriceAssemblies, setAssembliesUnlocked } from '../../Store/entities/assemblies';
import { UseMultipliersByQuoteId, useQuoteIdAndDispatch, useQuoteTypeNameByQuoteId, useQuoteTypes } from '../../Store/entities/quotes/hooks';
import { useAssembliesByQuoteId } from '../../Store/entities/assemblies/hooks';
import apiCalls from '../../PowerShadesAPIFunctions';
import { useIsDealer, useIsDistributor, useIsPsAdmin, useIsRepresentative } from '../../hooks';
import downloadInstallerCopy from './InstallerCopy';
import { UsePortalDispatch } from '../../Store';
import { useQuoteFabricSamplesByQuoteId } from '../../Store/entities/fabricSamples/hooks';
import { fakeRootType } from '../../FakeRootType';
import { useIsEngineer, useIsService, useUser } from '../../Store/user/hooks';
import updateBidData from '../../Store/entities/quoteBid';
import { useDealers, useTerritories } from '../../Store/entities/hooks';
import { UseUnAcknowledgedWarnings } from './QuoteWarningVerificationsFromStore';
import { useQuoteAccessories } from '../../Store/entities/accessories/hooks';
import { useErrorsByQuoteId } from '../../Store/entities/assemblies/errors/hooks';
import { QuoteStore } from '../../Store/entities/entitiesType';
import { useCurrentMultiplier } from '../../Store/entities/multipliers/hooks';

applyPlugin(jsPDF);

type QuoteActionsInput = {
	quoteId: number;
	ROOT: fakeRootType;
	showEditQuoteDisclaimer: number;
	setShowEditQuoteDisclaimer: (show: number) => void;
	newQuote: any; // Passthrough for legacy components
	demoReviewFailed: boolean;
	setShowConfigurations: (open: boolean) => void;
	fcState: [any[], Dispatch<SetStateAction<any[]>>];
};

const QuoteActions = ({ quoteId, ROOT, showEditQuoteDisclaimer, setShowEditQuoteDisclaimer, newQuote, demoReviewFailed, setShowConfigurations }: QuoteActionsInput) => {
	const quote = useQuoteIdAndDispatch(quoteId) ?? {
		order_status: 'active_quote',
		order_status_index: 0,
		dealer_id: 0,
		distributor_id: 0,
		manufacturer_id: 0,
		representative_id: 0,
		editable: false,
		property_address: {
			company_name: ''
		},
		property_address_id: 0,
		territory_id: 0,
		previous_quote_id: 0,
		notes: '',
	} as unknown as QuoteStore;

	const quoteAccessories = useQuoteAccessories(quoteId);

	const [open, setIsPlacedOrderOpen] = useState(false);

	const {
		editable,
		notes
	} = quote;

	const unsavedConfigurations = UseUnAcknowledgedWarnings(quoteId);

	const userData = useUser();

	const isPsAdmin = useIsPsAdmin();

	const [duplicateLoading, setDuplicateLoading] = useState(false);
	const [duplicateOpen, setDuplicateOpen] = useState(false);
	const [duplicateQuoteType, setDuplicateQuoteType] = useState('');
	const quoteTypesMain = useQuoteTypes();

	const quoteTypesUnfiltered = [
		{
			name: 'Please Select',
			zoho_reference: '',
		},
		...quoteTypesMain,
	];

	const quoteFabricSamples = useQuoteFabricSamplesByQuoteId(quoteId);

	const dispatch = UsePortalDispatch();

	const isRepresentative = useIsRepresentative();
	const isDistributor = useIsDistributor();
	const isDealer = useIsDealer();
	// const isSalesperson = useIsSales();

	const adds: ReactNode[] = [];

	const assemblies = useAssembliesByQuoteId(quoteId);
	const installerCopyQuote = { ...quote, shades: assemblies };

	const hasOutdoorShades = assemblies.some((a) => a.shade_type_id === 2);

	const isOrder = quote.order_status !== "active_quote";
	const manufacturerId = quote.manufacturer_id ?? 0;

	const quoteAssemblies = useAssembliesByQuoteId(quoteId);

	const isService = useIsService();
	const isEngineer = useIsEngineer();

	const isQuoteStatusBeforeAccountingApproval = quote.order_status === "order_pending_approval" || quote.order_status === "under_review";

	const quoteType = useQuoteTypeNameByQuoteId(quoteId);

	const showPlaceOrder = () => {
		setIsPlacedOrderOpen(true);
	};

	const [taxModalOpen, setTaxModalOpen] = useState(false);

	const closePlaceOrder = () => {
		setTaxModalOpen(false);
		setIsPlacedOrderOpen(false);
	};

	let multiplier = useCurrentMultiplier(quoteId);
    
	const multipliers = UseMultipliersByQuoteId(quoteId);

	if (multipliers?.list.some((m) => m.name?.toLocaleLowerCase()?.includes("dealer"))
        || multipliers?.list.find((m) => m.name?.toLocaleLowerCase()?.includes("my price"))) {
		multiplier = multipliers.list.find((m) => m.name?.toLocaleLowerCase()?.includes("dealer"))
                    ?? multipliers.list.find((m) => m.name?.toLocaleLowerCase()?.includes("my price"))
                    ?? multiplier;
	}

	const hasDealer = (quote.dealer_id !== null && quote.dealer_id !== undefined && quote.dealer_id !== 0);
	const hasDistributor = (quote.distributor_id !== null && quote.distributor_id !== undefined && quote.distributor_id !== 0);
	const hasRepresentative = (quote.representative_id !== null && quote.representative_id !== undefined && quote.representative_id !== 0);

	const hasEntity = hasDealer || hasDistributor || hasRepresentative;

	const [isInvoiceStatusOpen, setIsInvoiceStatusOpen] = useState(false);
	const [isForceEditModalOpen, setIsForcedEditModelOpen] = useState(false);
	// TODO, make it off of 90 days and make a hook.
	const quoteAccessoriesNeedingUnlocked = useQuoteAccessories(quoteId).filter((a) => a.last_time_priced > 0);

	const dealers = useDealers();
	const currentDealer = dealers.find((d) => d.id === quote.dealer_id);
	const territories = useTerritories();

	const quoteErrors = useErrorsByQuoteId(quoteId);

    const exportQuoteItem = {
        ...quote,
        accessories : quoteAccessories,
        assemblies : quoteAssemblies,
    }

    const submit = async () => {
        apiCalls.placeOrderPressed(quoteId).then(() => {
            console.log('placeOrderPressed');
        });

		const hasShadeError = quoteErrors.length > 0;

		const hasValidPropertyAddress = quote.property_address !== null && (quote.property_address?.company_name ?? "") !== '';

		// Magic Number, 90 days in seconds
		const shadesNeedingUnlocked = quoteAssemblies.filter((s) => s.msrp === 0 || s.last_time_priced > (Date.now() / 1000 + (7776000)));

		const isRMAAndHasNoPreviousQuoteId = (quoteType === "Rework" || quoteType === "RMA")
            && quoteAssemblies.length > 0
            && !((quote?.previous_quote_id ?? 0) > 0);

		if (hasShadeError) {
			ROOT.alert({
				text: 'Please click Edit Quote and address all shade errors before placing an order.',
				title: 'Shade Errors',
				quoteType,
				icon: 'error',
				oneButton: true,
			})
				.then(() => { })
				.catch(() => { });
		} else if (!hasValidPropertyAddress) {
			ROOT.alert({
				text: 'Please enter a valid property address before placing an order.',
				title: 'Missing Property Address',
				quoteType,
				icon: 'error',
				oneButton: true,
			})
				.then(() => { })
				.catch(() => { });
		} else if (unsavedConfigurations.length > 0) {
			ROOT.alert({
				text: 'Please verify the quote configuration.',
				title: 'Quote Configuration Verification',
				noCancelButton: false,
				cancelButtonText: 'Cancel',
				acceptButtonText: 'Show Configuration',
				quoteType,
				icon: 'warning',
				onAccept: () => {
					setShowConfigurations(true);
					console.log('Show Configurations');
				},
			})
				.then(() => { })
				.catch(() => { });
		} else if (
			shadesNeedingUnlocked.length > 0
            || quoteAccessoriesNeedingUnlocked.length > 0
		) {
			const repriceOldItems = () => {
				quoteAccessoriesNeedingUnlocked.forEach(() => {
					// newQuote.Data.setAccessoryQuantity(a.line_number, a.quantity);
				});

				// newQuote.unlock();
				// setEditable(true);

				const shadeSeqIds = shadesNeedingUnlocked.map((s) => s.sequence_id);

				dispatch(repriceAssemblies({
					quoteId,
					sequenceIds: shadeSeqIds,
					acc_line_numbers: quoteAccessoriesNeedingUnlocked.map((a) => a.line_number),
				}));

				const shadesNeedingUnlockedSequenceIds = shadesNeedingUnlocked.map((s) => s.sequence_id);
				dispatch(setAssembliesUnlocked({
					quoteId,
					sequenceIds: shadesNeedingUnlockedSequenceIds,
					unlocked: true,
				}));
				// newQuote.Data.RePriceWholeQuote();
			};
			ROOT.alert({
				text: 'This quote has expired. Quotes are valid for 90 days. To place this order, please reprice these shades/accessories via the button below.',
				title: 'Old Shade Pricing',
				icon: 'warning',
				cancelButtonText: 'Cancel',
				acceptButtonText: 'Reprice Shades',
				onAccept: repriceOldItems,
			})
				.then(() => { })
				.catch(() => { });
		} else if (quoteFabricSamples.length > 10) {
			ROOT.alert({
				text: 'Please limit fabric samples order quantity to 10 or less.',
				title: 'Fabric Samples Limit',
				quickFormat: 'error',
			})
				.then(() => { })
				.catch(() => { });
		} else if (!quote.territory_id || quote.territory_id === 0) {
			ROOT.alert({
				icon: "error",
				text: "Please enter a valid territory before placing an order.",
				title: "Territory Required",
				quickFormat: "error"

			})
				.then(() => { })
				.catch(() => { });
		} else if (!hasEntity && quoteType !== 'bidspec') {
			ROOT.alert({
				icon: "error",
				text: "Please enter a valid dealer before placing an order.",
				title: "Dealer Required",
				quickFormat: "error"

			})
				.then(() => { })
				.catch(() => { });
		} else if (isRMAAndHasNoPreviousQuoteId) {
			ROOT.alert({
				text: 'Please enter the previous quote id (The PS#) in Advanced Settings to place this RMA/Rework',
				title: 'No Previous Quote Id',
				quickFormat: 'error',
			})
				.then(() => { })
				.catch(() => { });
		} else {
			const propertyName = quote?.property_address?.company_name ?? "";

			const addressSnapshot = (
				await apiCalls.getQuoteAddressSnapshot(propertyName ?? "", quote?.dealer_id ?? 0)
			).data.address_snapshot;

			if (addressSnapshot !== null) {
				ROOT.alert({
					title: 'Existing Property Name',
					icon: 'warning',
					text: isDealer ? (
						<span>
							There is already an installation address with the property name & quot;
							{propertyName}
							& quot;.
							<br />
							<br />
							Continuing with this property name will add shades to the existing
							property in the dashboard.
							<span
								style={{
									display: 'block', marginBottom: '10px'
								}}
							/>
						</span>
					) : (
						<span>
							There is already an installation address with the property name & quot;
							{propertyName}
							& quot; for this dealer.
							<br />
							<br />
							Continuing with this property name will add shades to the existing
							property in the dashboard.
							<span
								style={{
									display: 'block', marginBottom: '10px'
								}}
							/>
						</span>
					),
					cancelButtonText: 'Edit Property Name',
					acceptButtonText: 'Continue',
				})
					.then(showPlaceOrder)
					.catch(() => { });
			} else {
				const isBlank = (str) => (!str || (/^\s*$/).test(str));

				if (isBlank(notes)) {
					showPlaceOrder();
				} else {
					ROOT.alert({
						title: 'Place an Order',
						icon: 'warning',
						text: 'Please acknowledge any notes that specify amendments to this order may be subject to price adjustment. We will do our best to accommodate all requests that promote our standard of quality.',
						cancelButtonText: 'Cancel',
						acceptButtonText: 'I understand, thanks!',
					})
						.then(showPlaceOrder)
						.catch(() => { });
				}
			}
		}
	};

	const buttonAdds = `btn-${quoteType}`;

	const placeOrderButton = (
		<Col key="placeOrder" xl="auto" lg={3} md={3} sm={4} xs={6} className="mt-2 mt-xl-0" >
			<Button
				fullWidth
				color={quoteType ?? 'green'}
				title="Place Order"
				onClick={submit}
			>
				Place Order
			</Button>
		</Col>
	);

	// if (!isSalesperson) {
	adds.push(<Popup open={open} key="placeOrderP" onClose={closePlaceOrder} closeOnDocumentClick={false} >
		<PlaceOrder
			quoteType={quoteType}
			key="place-order-component"
			ROOT={ROOT}
			isPsAdmin={isPsAdmin}
			isRep={isRepresentative}
			isDistributor={isDistributor}
			territoryId={quote.territory_id}
			quote={quote}
			close={closePlaceOrder}
			done={ROOT.refreshContent}
			submit={submit}
			dealers={dealers}
			territories={territories}
			currentDealer={currentDealer}
			newQuote={newQuote}
			taxModalOpen={taxModalOpen}
			setTaxModalOpen={setTaxModalOpen}
		/>
	</Popup>);
	// }

	if (!isOrder && !(quoteType === "demo")) {
		adds.push(placeOrderButton);
	} else if ((quoteType === "demo")) {
		adds.push(<Col key="demo-review" xl="auto" lg={3} md={3} sm={4} xs={6} className="mt-2 mt-xl-0" >
			<DemoReview
				quoteType={quoteType}
				demoReviewFailed={demoReviewFailed}
				isPsAdmin={isPsAdmin}
				placeOrderButton={placeOrderButton}
				isOrder={isOrder}
				newQuote={newQuote}
			/>
		</Col>);
	}

	const territoryId = quote.territory_id;

	if (isOrder && quote.order_status === "order_pending_approval" && isPsAdmin) {
		adds.push(<ApproveOrderButton
			quoteType={quoteType}
			key="approve-order"
			quoteId={quoteId}
		/>);
	}

	adds.push(<Col key="export-to-excel" xl="auto" lg={3} md={3} sm={4} xs={6} className="mt-2 mt-xl-0" >
		<Button
			title="Export Quote to Excel"
			color={quoteType ?? 'green'}
			fluid
			fullWidth
			onClick={() => exportQuote(quote, userData, newQuote, assemblies, multiplier)}
		>
			Export Quote
		</Button>
	</Col>);

	if (isPsAdmin && hasOutdoorShades) {
		adds.push(<Col key="export-outdoor" xl="auto" lg={3} md={3} sm={4} xs={6} className="mt-2 mt-xl-0" >
			<Button
				title="Export Outdoor Shades"
				color={quoteType ?? 'green'}
				fluid
				fullWidth
				onClick={() => {
					exportOutdoorOrderForm(installerCopyQuote);
				}}
			>
				Export Outdoor Shades
			</Button>
		</Col>);
	}
	adds.push(<Col xl="auto" key="print-quote-new-button" lg={3} md={3} sm={4} xs={6} className="mt-2 mt-xl-0" >
		<PrintQuoteNew
			color={buttonAdds}
			newQuote={newQuote}
			territories={territories}
			territory_id={territoryId}
			has_errors={quoteErrors.length > 0}
		/>
	</Col>);

    adds.push(
        <Col key="export-to-excel" xl="auto" lg={3} md={3} sm={4} xs={6} className="mt-2 mt-xl-0" >
            <Button
                title="Export Quote to Excel"
                color={quoteType ?? 'green'}
                fluid
                fullWidth
                onClick={() => exportQuote(exportQuoteItem, userData, newQuote, assemblies,multiplier)}
            >
                Export Quote
            </Button>
        </Col>
    );
	const duplicateFunction = () => {
		if (isPsAdmin) {
			setDuplicateOpen(true);
		} else {
			setDuplicateLoading(true);
			const duplicateQT = duplicateQuoteType === "" ? "CEDIA" : duplicateQuoteType;
			apiCalls.duplicateQuote(
				quoteId,
				duplicateQT
			).then(() => ROOT.loadContent('#Home'));
		}
	};

	// Has this quote shipped
	const hasShipped = quote.order_status === "mfg_shipped_order";

	// Has this quote been ordered
	const hasOrdered = quote.order_status !== "active_quote";

	const quoteTypes = quoteTypesUnfiltered
		.filter((qt) => {
			if (!hasShipped && qt.zoho_reference === "rma") return false;
			if (!hasOrdered && qt.zoho_reference === "rework") return false;
			return true;
		});

	adds.push(<Fragment key="duplicate-quote-fragment" >
		<Popup
			className="mobile-modal"
			key="duplicatequote-modal"
			open={duplicateOpen}
			closeOnDocumentClick={false}
			onClose={() => {
				setDuplicateOpen(false);
			}}
			contentStyle={{
				minHeight: '30vh',
				overflowX: 'hidden',
			}}
		>
			<Container style={{ minHeight: '30vh' }} className="local-bootstrap" >
				<Row className="mb-4" >
					<Col>
						<h4 className={`text-${quoteType}`}> Select Quote Type </h4>
					</Col>
				</Row>
				<Row >
					<Col>
						<FormSelect
							value={duplicateQuoteType}
							onChange={(e) => {
								setDuplicateQuoteType(e.target.value);
							}}
						>
							{
								quoteTypes.length > 0 ? (
									quoteTypes.map((qt) => (
										<option key={qt.name} value={qt.name} >
											{qt.name}
										</option>
									))
								) : (
									<option key="loading" value="loading" >
										Loading...
									</option>
								)
							}
						</FormSelect>
					</Col>
				</Row>
				<Row className="bottom-row" >
					<Col className="text-start mb-2" >
						<Button
							color="gray"
							onClick={() => {
								setDuplicateOpen(false);
							}}
						>
							Close
						</Button>
					</Col>
					{
						!duplicateLoading ? (
							<Col className="text-end mb-2" >
								<Button
									color={quoteType ?? 'green'}
									disabled={duplicateLoading}
									onClick={() => {
										setDuplicateLoading(true);
										apiCalls.duplicateQuote(
											quoteId,
											duplicateQuoteType
										).then(() => ROOT.loadContent('#Home'));
									}}
								>
									Duplicate
								</Button>
							</Col>
						) : (
							<Col className="mb-2 text-center" >
								<SpinningWheel
									style={{ fontSize: '2em' }}
									className={`text-${quoteType}`}
								/>
							</Col>
						)
					}
				</Row>
			</Container>
		</Popup>
		<Col xl="auto" lg={3} md={3} sm={4} xs={6} className="mt-2 mt-xl-0" >
			<Button
				fullWidth
				disabled={duplicateLoading}
				color={quoteType ?? 'green'}
				title="Duplicate the Current Quote"
				onClick={duplicateFunction}
			>
				{!duplicateLoading ? (
					'Duplicate Quote'
				) : (
					<SpinningWheel
						style={{
							fontSize: '1.5em',
							marginTop: '0.25em',
							marginBottom: '-0.5em',
						}}
						className={`text-${quoteType}`}
					/>
				)}
			</Button>
		</Col>
	</Fragment>);

	// if (isPsAdmin && !isPaid) {
	//     //Mark as Paid
	//     adds.push(
	//         <ButtonFunction
	//             title={'Mark Quote as Paid'}
	//             key={'mark-paid'}
	//             keyX={'mark-paid'}
	//             className={`green-button ${buttonAdds}`}
	//             action={() => {

	//                 if (!confirm('Are you sure you want to mark this order as paid?')) return;

	//                 api.markAsPaid(quote.ID)
	//                     .then(() => ROOT.refreshContent());
	//             }}
	//             buttonLabel={"Mark Paid"}
	//         ></ButtonFunction>
	//     )
	// }

	// if (
	//     isOrder
	//     && orderStatusIndex < 2
	//     && !isPaid
	//     && (isDistributor || isRepresentative || isPsAdmin)
	// ) {
	//     adds.push(
	//         <Col key="require-payment" xl = "auto" lg = { 3} md = { 3} sm = { 4} xs = { 6} className = "mt-2 mt-xl-0" >
	//         <Button
	//                     color={ quoteType ?? 'green'}
	// title = "Mark order as requiring payment"
	// onClick = {() => {
	//     if (
	//         // TODO: Replace this with root.alert
	//         !confirm(
	//             'Are you sure you want to decline credit and require payment for this order?'
	//         )
	//     ) { return; }

	//     api.markRequirePayment(quote.ID).then(() => ROOT.refreshContent());
	// }}
	//                 >
	//     Require Payment
	//         </Button>
	//         </Col>
	//         );
	//     }

	if (isPsAdmin && !editable && isOrder && manufacturerId <= 0 && (isEngineer || isService || isQuoteStatusBeforeAccountingApproval)) {
		adds.push(<>
			<Popup
				key="forceEdit"
				open={isForceEditModalOpen}
			>
				<ForceEditModal
					key="force-edit-modal"
					setEditable={() => {
						console.log("Force Edit Set");
						// newQuote.unlock(() => {
						//     setEditable(true);
						// });
					}}
					quote={quote}
					close={() => { }}
				/>
			</Popup>
			<Col xl="auto" lg={3} md={3} sm={4} xs={6} className="mt-2 mt-xl-0" >
				<Button
					key="forceEdit"
					color={quoteType ?? 'green'}
					title="Force Unlock Order"
					onClick={() => setIsForcedEditModelOpen(true)}
				>
					Force Edit
				</Button>
			</Col>
		</>);
	}

	const downloadInstallerCopyLocal = () => {
		downloadInstallerCopy(quote, userData, quoteAccessories, quoteAssemblies);
	};

	adds.push(<Col key="installer-copy" xl="auto" lg={3} md={3} sm={4} xs={6} className="mt-2 mt-xl-0" >
		<Button
			fullWidth
			title="Download the Installer Copy"
			color={quoteType ?? 'green'}
			// TODO: Define this function before using it
			onClick={downloadInstallerCopyLocal}
		>
			Installer Copy
		</Button>
	</Col>);

	if (isOrder && isPsAdmin) {
		adds.push(<>
			<Col xl="auto" lg={3} md={3} sm={4} xs={6} className="mt-2 mt-xl-0" >
				<Button
					color={quoteType ?? 'green'}
					title="Check Invoice Status"
					onClick={() => setIsInvoiceStatusOpen(true)}
				>
					Invoice Status
				</Button>
			</Col>

			<Popup
				key="invoiceStatus"
				modal
				open={isInvoiceStatusOpen}
				onClose={() => setIsInvoiceStatusOpen(false)}

			>
				<InvoiceStatus
					alert={ROOT.alert}
					isPsAdmin={isPsAdmin}
					quoteId={quoteId}
					close={() => {
						setIsInvoiceStatusOpen(false);
					}}
				/>
			</Popup>
		</>);
	}

	// if (isOrder) {
	// 	adds.push(
	// 		<Col key="order-status" xl="auto" lg={3} md={3} sm={4} xs={6} className="mt-2 mt-xl-0">
	// 			<OrderStatus quoteType={quoteType} key={quote.ID} quoteId={quote.ID} />
	// 		</Col>
	// 	);
	// }

	if (
		!editable
        && !isOrder
        && (isPsAdmin || quoteType === "cedia" || quoteType === "demo")
	) {
		adds.push(<Col key="unlock-quote" xl="auto" lg={3} md={3} sm={4} xs={6} className="mt-2 mt-xl-0" >
			{/* <QuotePricingLockedNew
                    keyX="unlock-quote"
                    setEditable={setEditable}
                    unlockQuote={editQuote}
                    showEditQuoteDisclaimer={showEditQuoteDisclaimer}
                    setShowEditQuoteDisclaimer={setShowEditQuoteDisclaimer}
                    lockAllShades={lockAllShades}
                    unlockShade={newQuote.Data.unlockShade}
                    variant={quoteType}
                    newQuote={newQuote}
                /> */}
			<UnlockQuoteModal
				quoteId={quoteId}
				sequenceId={showEditQuoteDisclaimer}
				clearSequenceId={() => {
					setShowEditQuoteDisclaimer(-1);
				}}
			/>
		</Col>);
	}

	if (
		!editable
        && (!isOrder || (isOrder && (quote?.manufacturer_id ?? 0) <= 0))
        && isPsAdmin
        && false
	) {
		adds.push(<Col key="secret-edit-o.o" xl="auto" lg={3} md={3} sm={4} xs={6} className="mt-2 mt-xl-0" >
			<Button
				color={quoteType ?? 'green'}
				onClick={() => {
					// editQuote((quote) => {
					//     // TODO: Change this close() to a non-restricted global
					//     close();
					//     setEditable(true);
					//     quote.noPricing = true;
					// });
				}}
			>
				No Pricing Edit
			</Button>
		</Col>);
	}

	adds.push(<Col key="quote-variables" xl="auto" lg={3} md={3} sm={4} xs={6} className="mt-2 mt-xl-0" >
		<QuoteLevelVariables
			newQuote={newQuote}
			isPsAdmin={isPsAdmin}
			isRepresentative={isRepresentative}
			onChange={newQuote.Data.QuoteFieldChange}
			// onChange={(item) => console.log(`Change this ${item}`)}
		/>
	</Col>);

	adds.push(<Col key="advancedshademanipulation" xl="auto" lg={3} md={3} sm={4} xs={6} className="mt-2 mt-xl-0" >
		<AdvancedShadeManipulation newQuote={newQuote} />
	</Col>);

	if (quoteType === "Bidspec") {
		adds.push(<Col key="bidspec-control" xl="auto" lg={3} md={3} sm={4} xs={6} className="mt-2 mt-xl-0" >
			<BidSpecControl
				quoteColor={quoteType}
				shadeAssemblies={quoteAssemblies}
				setShadeBidData={(bidData) => {
					dispatch(updateBidData(bidData));
				}}
			/>
		</Col>);
	}

	// if (newQuote.IsDemo) {
	//     adds.push(<span key="isDemo" />);
	// }

	if (isOrder
        && quote.order_status !== 'mfg_shipped_order'
        && isPsAdmin) {
		adds.push(<Col key="outdoor-only" xl="auto" lg={3} md={3} sm={4} xs={6} className="mt-2 mt-xl-0" >
			<CorradiOnly QuoteId={quoteId} />
		</Col>);
	}

	if (isPsAdmin && !isOrder) {
		adds.push(<Col key="shade-import" xl="auto" lg={3} md={3} sm={4} xs={6} className="mt-2 mt-xl-0">
			<ShadeImport
				quoteId={quoteId}
			/>
		</Col>);
	}

	return (
		<Container
			className="local-bootstrap mb-2 p-0"
			fluid
			// style={{
			// 	marginRight: '1em', marginLeft: '1em', marginBottom: '1em'
			// }}
		>
			<Row className="justify-content-end" style={{ paddingLeft: "0.5em", paddingRight: "0.5em" }}>
				<Col xl="auto" lg={12} >
					<Row>
						{adds.reverse()}
						{' '}
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default QuoteActions;

