export default {
	
	psGreen: '#8dc63f',
	psGray: '#6d6e71',
	psLightGray: '#a7a9ac',
	mobileThreshold: ' 1010px',
	errorColor: '#e30000',
	errorRowBackgroundColor: '#ff00006e',
	deleteRed: '#e60d0d',
	disabledButtonBackground: '#d1d1d1',
	pendingOrange: '#e37204',
};
