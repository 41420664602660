/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Popup } from "reactjs-popup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Button from "../../Parts/Button";
import { GroupHeader } from "../../Parts/FormParts";
import { Contractor } from "../../Contractors/contractors";
import FileUpload from "../../Parts/FileUploader";
import { DealerStore, DistributorStore } from "../../powershadesApiTypes";
import { RepresentativeStore } from "../../Store/entities/entitiesType";
import SpinningWheel from "../../Parts/SpinningWheel";

type TaxJustificationProps = {
	org: DealerStore | Contractor | DistributorStore | RepresentativeStore;
	orgType: string;
	onSave: (workingOrg: DealerStore | Contractor | DistributorStore | RepresentativeStore) => Promise<any>;
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	loading?: boolean;
	hideButton?: boolean;
};

const TaxJustification = ({ org, orgType, onSave, setOpen, loading, hideButton }: TaxJustificationProps) => {
	const [workingOrg, setWorkingOrg] = useState<
	DealerStore
	| Contractor
	| DistributorStore
	| RepresentativeStore
	>(org);

	const hasFed = "federal_tax_id" in workingOrg && "federal_tax_certificate_location" in workingOrg;

	const disabled = workingOrg.sales_tax_exempt_status === "tax_exempt" && [
		workingOrg.sales_tax_exempt_reason,
		workingOrg.sales_tax_exempt_certificate_number,
		workingOrg.sales_tax_exempt_certificate_location,
	].some((v) => v === '' || v === null);

	const setOrg = (newWorkingOrg) => {
		setWorkingOrg((prev) => ({ ...prev, ...newWorkingOrg }));
	};

	const cancel = () => {
		setWorkingOrg(org);
		setOpen(false);
};

	const save = () => {
		onSave(workingOrg).then(() => {
			setOpen(false);
		});
	};

	useEffect(() => setWorkingOrg(org), [org]);
	
	return (
		<div id="tax-justification-modal">
			{
				hideButton
					? null
					: <Button
						color="gray"
						onClick={() => setOpen(true)}
						style={{ width: "fit-content", marginLeft: "auto", display: "none" }}
					>
						<FontAwesomeIcon icon={faEdit} />
						&nbsp;Tax Details
					</Button>
			}
			
			<Popup
				// open={open}
				open={false}
				onClose={() => setOpen(false)}
				className="mobile-modal"
				modal
				closeOnDocumentClick={false}
			>
				<h2 className="m-0 w-auto">Tax Justification</h2>
				{GroupHeader("Tax Jurisdiction Details")}
				<Row className="p-2">
					<Row>
						<Form.Label>
							Shipping Address:&nbsp;
							<span className="text-secondary">{org.billing_address.address}</span>
						</Form.Label>
					</Row>
					<Row>
						<Form.Label className="d-flex flex-row">
							Tax Data:&nbsp;
							<ul className="list-unstyled text-secondary">
								<li>
									Country:
									{org.billing_address.country}
								</li>
								<li>
									State:
									{org.billing_address.state}
								</li>
								<li>
									City:
									{org.billing_address.city}
								</li>
								<li className="fw-bold">Combined Rate: 5.74%</li>
							</ul>
						</Form.Label>
					</Row>
				</Row>
				{GroupHeader("Tax Exemption Details")}
				<Row className="p-2">
					<Form>
						<Row className="py-2">
							<Col xs={6}>
								<Form.Label>Is Exempt?</Form.Label>
								<Form.Check
									type="checkbox"
									checked={workingOrg.sales_tax_exempt_status === "tax_exempt"}
									onChange={(e) => setOrg({
										sales_tax_exempt_status: e.target.checked
											? "tax_exempt"
											: "non_exempt"
									})}
								/>
							</Col>
							<Col xs={6}>
								<Form.Label>Exemption Type</Form.Label>
								<Form.Select
									disabled={workingOrg.sales_tax_exempt_status === "non_exempt"}
									as="select"
									onChange={(e) => setOrg({ sales_tax_exempt_reason: e.target.value })}
									value={workingOrg.sales_tax_exempt_reason}
								>
									<option value="">Select Reason</option>
									<option value="government">Government</option>
									<option value="nonprofit">Non-Profit</option>
									<option value="wholesale">Wholesale</option>
								</Form.Select>
							</Col>
						</Row>
						<Row className="mt-4">
							<Col>
								<Form.Label className="w-100">
									State Tax Form
									<Form.Control
										disabled={workingOrg.sales_tax_exempt_status === "non_exempt"}
										className="mb-2"
										placeholder="Enter Code..."
										value={workingOrg.sales_tax_exempt_certificate_number || ""}
										onChange={(e) =>
											setOrg({ sales_tax_exempt_certificate_number: e.target.value })}
									/>
									<FileUpload
										disabled={workingOrg.sales_tax_exempt_status === "non_exempt"}
										certType="state"
										entityType={orgType}
										entityID={JSON.stringify(org.id)}
										inputKey={`state-${org.id}`}
										currentFile={workingOrg.sales_tax_exempt_certificate_location || null}
										onSuccess={(link) => {
											setOrg({ sales_tax_exempt_certificate_location: link });
										}}
										onClear={() => setOrg({ sales_tax_exempt_certificate_location: null })}
									/>
								</Form.Label>
							</Col>
							{
								hasFed
								&& <Col>
									<Form.Label className="w-100">
										Federal Tax Form
										<Form.Control
											disabled={workingOrg.sales_tax_exempt_status === "non_exempt"}
											className="mb-2"
											placeholder="Enter Code..."
											value={hasFed
												? workingOrg.federal_tax_id || "" : ""}
											onChange={(e) => {
												hasFed
													? setOrg({ federal_tax_id: e.target.value })
													: console.log("Cannot save federal tax code.");
											}}
										/>
										<FileUpload
											disabled={workingOrg.sales_tax_exempt_status === "non_exempt"}
											certType="federal"
											entityType={orgType}
											entityID={JSON.stringify(org.id)}
											inputKey={`federal-${org.id}`}
											currentFile={workingOrg.federal_tax_certificate_location}
											onSuccess={(link) => {
												hasFed
													? setOrg({ federal_tax_certificate_location: link })
													: console.log("Cannot save federal tax certificate.");
											}}
											onClear={() => setOrg({ federal_tax_certificate_location: null })}
										/>
									</Form.Label>
								</Col>
							}
						</Row>
					</Form>
				</Row>
				<Row className="d-flex justify-content-between p-2 pt-4">
					<Col>
						<Button className="btn-gray border-0" onClick={cancel}>
							Cancel
						</Button>
					</Col>
					<Col className="d-flex justify-content-end">
						{
							loading
								? <SpinningWheel size="2x" className="mt-4" />
								: <Button className="border-0" onClick={save} disabled={disabled}>
									Submit
								</Button>
						}
					</Col>
				</Row>
			</Popup>
		</div>
	);
};

export default TaxJustification;
