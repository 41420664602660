// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResetPassword-module__reset-card___oUMtD{min-width:40vw !important}@media(min-width: var(--bs-breakpoint-xxl)){.ResetPassword-module__reset-card___oUMtD .ResetPassword-module__reset-card___oUMtD{max-width:30vw !important}}.ResetPassword-module__reset-card-body___kd9BQ{padding:1em !important}`, "",{"version":3,"sources":["webpack://./src/ResetPassword/ResetPassword.module.scss"],"names":[],"mappings":"AAAA,0CAMC,yBAAA,CALA,4CACC,oFACC,yBAAA,CAAA,CAMH,+CACC,sBAAA","sourcesContent":[".reset-card {\n\t@media (min-width: var(--bs-breakpoint-xxl)) {\n\t\t.reset-card {\n\t\t\tmax-width: 30vw !important;\n\t\t}\n\t}\n\tmin-width: 40vw !important;\n}\n\n.reset-card-body {\n\tpadding: 1em !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reset-card": `ResetPassword-module__reset-card___oUMtD`,
	"resetCard": `ResetPassword-module__reset-card___oUMtD`,
	"reset-card-body": `ResetPassword-module__reset-card-body___kd9BQ`,
	"resetCardBody": `ResetPassword-module__reset-card-body___kd9BQ`
};
export default ___CSS_LOADER_EXPORT___;
