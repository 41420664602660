// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatusContainer-module__status-container___dQQHE{-webkit-user-select:none;-moz-user-select:none;user-select:none}`, "",{"version":3,"sources":["webpack://./src/NewQuote/Status/StatusContainer.module.scss"],"names":[],"mappings":"AAAA,kDACC,wBAAA,CAAA,qBAAA,CAAA,gBAAA","sourcesContent":[".status-container {\n\tuser-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status-container": `StatusContainer-module__status-container___dQQHE`,
	"statusContainer": `StatusContainer-module__status-container___dQQHE`
};
export default ___CSS_LOADER_EXPORT___;
