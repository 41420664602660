import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from "@sentry/react";
import { startCase } from 'lodash';
import axios from 'axios';

import { WritableDraft } from 'immer/dist/internal';
import type { AppDispatch, AppState } from '..';
import type {  UserState } from '../../powershadesApiTypeExtensions';
import { apiPost } from '../../psUtil';
import { AuthResponse } from '../../User/types';
import extractJwtData from '../../extractJwtData';
import { loadQuoteIds, loadQuoteTypes } from '../entities/quotes';
import { loadTerritoriesMeta } from '../entities/territories';
import type { UserPreferences } from './types';
import userApiCalls from '../../UserApi';
import { selectIsDealer, selectIsPsAdmin, selectIsRepresentative } from './userPermissions';
import { setDisplayPrices } from '../ui';
import { DisplayPriceOptions } from '../ui/types';
import { loadSalesPeople } from '../entities';
import { AuthUserRole } from '../../powershadesApiTypeExtensions';
import { loadStatus } from '../entities/entitiesType';

// ! Back end calls return camel cases. Deal with it.
/* eslint-disable @typescript-eslint/no-explicit-any, no-param-reassign, camelcase */

const backendRoot = process.env.BACKEND_ROOT;



export const initialUserState: UserState = {
	id: 0,
	loading: true,
	name: '',
	email: '',
	roles: [],
	authenticated: false,
	error: '',
	jwt: '',
	refreshJwt: '',
	is_sales_person: false,
	is_disallowed_force_edit: false,
	is_engineering: false,
	is_service: false,
	is_manufacturing: false,
	is_using_old_homepage: false,
	is_allowed_parts_on_cedia: false,
	theme: 'light',
	links: {
		loadStatus: loadStatus.needsLoaded,
		list: []
	}
};

interface loginUserPayload {
	email: string;
	password: string;
}

export interface UserDataRoles {
	id?: number;
	email: string;
	name: string;
	roles: AuthUserRole[];
	is_sales_person: boolean;
	is_disallowed_force_edit: boolean;
	is_engineering: boolean;
	is_service: boolean;
	is_manufacturing: boolean;
	is_using_old_homepage: boolean;
	is_allowed_parts_on_cedia: boolean;
	theme: 'light' | 'dark';
};

export interface UserDataJwt {
	user_data?: UserDataRoles;
	email: string;
	name: string;
	jwt: string;
	refreshJwt: string;
	id: number;
}

interface refreshToken {
	refreshJwt: string;
}

interface loginFailurePayload {
	error: string;
}

const setSentryUser = (userData: UserDataRoles) => {
	const entityName = userData.roles.filter((role) => role.entity_name !== null)[0]?.entity_name;
	const snakeCaseRole = userData.roles.filter((role) => role.entity_name !== null)[0]
		?.role_name;
	const role = startCase(snakeCaseRole);

	Sentry.setUser({
		entity: entityName,
		role,
		email: userData.email,
		username: userData.name
	});
};

const initialHydration = createAsyncThunk<void, void, { dispatch: AppDispatch, state: AppState }>(
	'user/initialHydration',
	async (_, { dispatch, getState }) => {
		const state = getState();
		const currentUser = state.user;
		setSentryUser(currentUser);
		const quoteIdPayload = loadQuoteIds();
		const quoteTypesPayload = loadQuoteTypes();
		await dispatch(quoteTypesPayload);
		await dispatch(quoteIdPayload);
		await dispatch(loadTerritoriesMeta());
		const psAdminDisplayPrices: DisplayPriceOptions[] = ["dealer", "distributor", "msrp", "bidspec", "invoice"];
		const dealerAndRepPrices: DisplayPriceOptions[] = ["dealer", "msrp", "invoice"];
		const distributorDisplayPrices: DisplayPriceOptions[] = ["dealer", "distributor", "msrp", "invoice"];

		const isPsAdmin = selectIsPsAdmin(state);
		const isDealer = selectIsDealer(state);
		const isRep = selectIsRepresentative(state);
		const isDistributor = selectIsRepresentative(state);

		if (isPsAdmin) dispatch(setDisplayPrices(psAdminDisplayPrices));
		if (isDealer) dispatch(setDisplayPrices(dealerAndRepPrices));
		if (isRep) dispatch(setDisplayPrices(dealerAndRepPrices));
		if (isDistributor) dispatch(setDisplayPrices(distributorDisplayPrices));

		if (isPsAdmin) await dispatch(loadSalesPeople());
	}
);

const attemptLogin = createAsyncThunk<
UserDataJwt,
loginUserPayload,
{
	rejectValue: loginFailurePayload;
	dispatch: AppDispatch;
}
>('user/attemptLogin', async (loginInfo, { rejectWithValue, dispatch }) => {
	const { email, password } = loginInfo;

	if (email === '' || password === '') {
		return rejectWithValue({
			error: 'Please enter a valid email and password',
		});
	}

	if (email === '' || password === '') {
		return rejectWithValue({
			error: 'Please enter a valid email and password',
		});
	}
	let userData: UserDataJwt;

	try {
		const loginResponse = await apiPost<AuthResponse>("auth/login", {
			email,
			password
		});

		const extractedResponse = extractJwtData(loginResponse.data.access ?? '');

		const possibleError = (loginResponse.data as any).message as string ?? '';

		const isVerboseLoginFailure = possibleError.includes('The provided authorization grant (e.g., authorization');

		const failedCredentialsMessage = 'No active account found with the given credentials';

		const finalError = possibleError === '' || isVerboseLoginFailure ? failedCredentialsMessage : possibleError;

		if (!extractedResponse) return rejectWithValue({ error: finalError });

		userData = {
			jwt: loginResponse.data.access ?? '',
			refreshJwt: loginResponse.data.refresh ?? '',
			email: extractedResponse.email,
			name: extractedResponse.name,
			id: extractedResponse.id,
			user_data: loginResponse.data.user_data
		};

		localStorage.setItem('user_data', JSON.stringify(userData));
		localStorage.setItem('user_jwt', userData.jwt);
		localStorage.setItem('user_refresh', userData.refreshJwt);

		userData.user_data && setSentryUser(userData.user_data);
		dispatch(initialHydration());

	} catch (err: any) {
		if (typeof err === typeof "") {
			const errMessage = err as string;

			return rejectWithValue({
				error: errMessage,
			});
		}

		const errMessage = err.toJSON().message as string;

		return rejectWithValue({
			error: errMessage,
		});
	} finally {
		// dispatch(detractUser()) For the inevitable spot for UI integration with redux
	}

	return userData;
});

const attemptRefresh = createAsyncThunk<UserDataJwt, refreshToken>(
	'user/attemptRefresh',
	async (loginInfo, { rejectWithValue }) => {
		const { refreshJwt } = loginInfo;

		if (!refreshJwt) {
			return rejectWithValue({ error: 'No refresh token' });
		}

		let userData: UserDataJwt;

		try {
			const responseData: AuthResponse = await axios.post(`${backendRoot}/api/auth/Refresh`, { token: refreshJwt });
			const extractedResponse = extractJwtData(responseData.access ?? '');
			if (!responseData.success || !extractedResponse || !responseData.access || !responseData.refresh) {
				console.error(responseData);
				return rejectWithValue({ error: 'Refresh failed' });
			}

			userData = {
				jwt: responseData.access ?? '',
				refreshJwt: responseData.refresh,
				email: extractedResponse.email,
				name: extractedResponse.name,
				id: extractedResponse.id,
			};

			localStorage.setItem('user_data', JSON.stringify(userData));
			localStorage.setItem('user_jwt', userData.jwt);
			localStorage.setItem('user_refresh', userData.refreshJwt);

			userData.user_data && setSentryUser(userData.user_data);
		} catch (err: any) {
			// let error: AxiosError = err;

			return rejectWithValue(false);
		}

		return userData;
	}
);

const setPrefersOldPage = (userId: number) => createAsyncThunk<void, boolean>(
	'user/setPrefersOldPage',
	async (prefersOldPage) => {
		await userApiCalls.setHomePage(userId, prefersOldPage);
	}
);

const setThemePreference = createAsyncThunk<void, 'light' | 'dark'>(
	'user/setThemePreference',
	async (theme, thunkAPI) => {
		const storeState = thunkAPI.getState() as AppState;
		const userId = storeState.user.id;
		await userApiCalls.setTheme(userId, theme);
	}
);

const loadUserLinks = createAsyncThunk<{
	display_name: string;
	link: string;
}[], void, { dispatch: AppDispatch }>(
	'user/loadUserLinks',
	async () => {
		const userLinks = await userApiCalls.getLinks();
		return userLinks.data.support_links;
	}
);

const loadUserDataThunk = createAsyncThunk<
	UserDataJwt,
	UserDataJwt,
	{
		rejectValue: UserDataJwt;
	}
>('user/loadData/thunk', async (_loginInfo, { rejectWithValue }) => {
	const userDataObj = _loginInfo;
	if (userDataObj) {
		setSentryUser(userDataObj.user_data ?? {} as UserDataRoles);

	} else {
		return rejectWithValue(userDataObj ?? {} as UserDataJwt);
	}
	return userDataObj ?? {} as UserDataJwt;
});

const userSlice = createSlice({
	name: 'user',
	initialState: initialUserState,
	reducers: {
		loadUser: (user, userData: PayloadAction<UserDataJwt>) => {
			const {
				email, name, jwt, refreshJwt, id
			} = userData.payload;

			user.loading = false;
			user.email = email;
			user.name = name;
			user.jwt = jwt;
			user.id = id;
			user.refreshJwt = refreshJwt;
			if (user.jwt.length > 10) {
				user.authenticated = true;
			}
		},
		loadUserData: (user, action: PayloadAction<UserDataRoles>) => {
			const userData = action.payload;

			const newRole = userData.roles.find(() => true)?.role_name;
			const oldRole = user.roles.find(() => true)?.role_name;

			if (
				!user.roles.every((r, index) => user.roles[index] === r)
				|| user.name !== userData.name
				|| user.email !== userData.email
				|| user.roles.length !== userData.roles.length
				|| newRole !== oldRole
			) {
				user.roles = userData.roles;
				user.name = userData.name;
				user.email = userData.email;
			}

			user.is_sales_person = userData.is_sales_person;
			user.is_disallowed_force_edit = userData.is_disallowed_force_edit;
			user.is_engineering = userData.is_engineering;
			user.is_service = userData.is_service;
			user.is_manufacturing = userData.is_manufacturing;
			user.is_using_old_homepage = userData.is_using_old_homepage;
			user.is_allowed_parts_on_cedia = userData.is_allowed_parts_on_cedia;
			user.id = userData.id ?? 0;

			user.authenticated = true;

			return user;
		},
		userNotLoading: (user) => {
			user.loading = false;
		},
		userLoading: (user) => {
			user.loading = true;
		},
		setTheme: (user, action: PayloadAction<'light' | 'dark'>) => {
			user.theme = action.payload;
		},
		updateUserPreferences: (user, action: PayloadAction<UserPreferences>) => {
			user.theme = action.payload.theme;
			user.is_using_old_homepage = action.payload.usingOldHomePage;
		}
	},
	extraReducers: (builder) => {
		const successfulAuth = (
			user: WritableDraft<UserState>,
			userData: PayloadAction<
				UserDataJwt,
				string,
				{
					arg: loginUserPayload | refreshToken;
					requestId: string;
					requestStatus: 'fulfilled';
				},
				never
			>
		) => {
			const {
				email, name, jwt, refreshJwt, user_data, id
			} = userData.payload;

			// const jwtData = extractJwtData(jwt);

			user.loading = false;
			user.email = email;
			user.id = user_data?.id ?? id;
			user.name = name;
			user.jwt = jwt;
			user.refreshJwt = refreshJwt;
			user.authenticated = true;
			if (user_data !== undefined) {
				if (
					!user.roles?.every((r, index) => user.roles[index] === r)
					|| user.name !== user_data.name
					|| user.email !== user_data.email
					|| user.roles?.length !== user_data?.roles.length
				) {
					user.roles = user_data.roles;
					user.name = user_data.name;
					user.email = user_data.email;
				}

				user.is_sales_person = user_data.is_sales_person;
				user.is_disallowed_force_edit = user_data.is_disallowed_force_edit;
				user.is_engineering = user_data.is_engineering;
				user.is_service = user_data.is_service;
				user.is_manufacturing = user_data.is_manufacturing;
				user.is_using_old_homepage = user_data.is_using_old_homepage;
				user.is_allowed_parts_on_cedia = user_data.is_allowed_parts_on_cedia;
				user.theme = user_data.theme;
			}
		};

		const loading = (user: WritableDraft<UserState>) => {
			user.loading = true;
		};

		builder.addCase(attemptLogin.fulfilled, successfulAuth);
		builder.addCase(attemptRefresh.fulfilled, successfulAuth);
		builder.addCase(loadUserDataThunk.fulfilled, successfulAuth);

		builder.addCase(attemptRefresh.pending, loading);
		// builder.addCase(attemptLogin.pending, loading);

		builder.addCase(setPrefersOldPage(0).pending, (user, action) => {
			user.is_using_old_homepage = action.meta.arg;
		});

		builder.addCase(attemptLogin.rejected, (user, action) => {
			user.error = action.payload?.error ?? '';
		});

		builder.addCase(loadUserLinks.pending, (user) => {
			user.links = {
				loadStatus: loadStatus.loading,
				list: []
			}
		});

		builder.addCase(loadUserLinks.fulfilled, (user, action) => {
			user.links = {
				loadStatus: loadStatus.fullyLoaded,
				list: action.payload
			};
		});
	},
});


export const selectIsLoaded = (state: AppState) => !state.user.loading;

export default userSlice.reducer;

export { attemptLogin, attemptRefresh, /* logout */ setPrefersOldPage, setThemePreference , loadUserLinks};

export const {
	setTheme,
	loadUser,
	userNotLoading,
	userLoading,
	updateUserPreferences
} = userSlice.actions;

export { loadUserDataThunk as loadUserData, initialHydration };
