import Popup from 'reactjs-popup';
import { useState } from 'react';
import { isFunction } from 'lodash';
import HeaderData from '../../NewItems/HeaderData';
import ImageBubble from '../../../Parts/ImageBubble';

import StandardRoll from "../../../assets/img/illus/standard_roll.png";
import ReverseRoll from "../../../assets/img/illus/reverse_roll.png";
import MotorizedShade from "../../../assets/img/illus/motorized_shade.png";
import SpringPull from "../../../assets/img/illus/spring_pull.png";
import ManualShade from "../../../assets/img/illus/manual_shade.png";
import FabricWidth from "../../../assets/img/illus/fabric_width.png";
import InsideMount from "../../../assets/img/illus/inside_mount.png";
import OutsideMount from "../../../assets/img/illus/outside_mount.png";
import RightControl from "../../../assets/img/illus/right_control.png";
import LeftControl from "../../../assets/img/illus/left_control.png";
import InternalFlatHembar from "../../../assets/img/internal_flat_hembar.png";
import InternalRoundedHembar from "../../../assets/img/internal_rounded_hembar.png";
import ExternalHembar from "../../../assets/img/external_hembar.png";
import HembarWithMagnet from "../../../assets/img/illus/magnet_hembar.png";
import SideChannels from "../../../assets/img/illus/side_channels.png";
import SillPlate from "../../../assets/img/illus/sill_plate.png";
import SideAndSill from "../../../assets/img/illus/side_and_sill_channels.png";
import InsideFabric from "../../../assets/img/illus/inside_fabric.png";
import OutsideFabric from "../../../assets/img/illus/outside_fabric.png";
import PoE from "../../../assets/img/illus/poe-icon.png";
import AC from "../../../assets/img/illus/ac-icon.png";
import DC from "../../../assets/img/illus/dc-icon.png";
import Battery from "../../../assets/img/illus/battery-icon.png";
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { SelectFabrics, SelectIndoorFabrics } from '../../../Store/entities/fabrics';

export default function IndoorShadeFormFields(UI, injectedMethods) {
	/*
		indoor_outdoor
		shade_assembly_id
		shade_1_id
		shade_2_id
		tube_wku
	*/

	const {
		getInsideFabricState,
		getOutsideFabricState,
		getInsideRollState,
		getOutsideRollState,
		toggleInsideFabricState,
		toggleOutsideFabricState,
		toggleInsideRollState,
		toggleOutsideRollState
	} = (injectedMethods ?? {});

	const shadeToSplit = (assembly) => {
		try {
			const sequenceId = assembly.sequence_id;

			return assembly.shades.map((s) => ({
				sequenceId,
				row: s.row_coordinate,
				column: s.column_coordinate
			}));
		} catch {
			ROOT.alert({
				quickFormat: 'error',
				message: 'Something went wrong. Please try again.'
			});
			throw new Error('Something went wrong. Please try again.');
		}
	};

	const shadeSubstitute = (dataOrShade) => (!dataOrShade ? null : (isFunction(dataOrShade.options) ? dataOrShade : UI.Data.shadeSubstitute(dataOrShade.sequence_id)));

	const metalEndcapColorConditional = (shade) => {
		if (!shade) return true;

		const shadeObj = shadeSubstitute(shade);

		const isMetalEndcaps = shadeObj?.valMatch('end_caps', 'Metal End Caps');
		const isMetalHembar = shadeObj?.valMatch('hembar_type', 'external_metal');

		return isMetalEndcaps !== isMetalHembar;
	};

	function unique(a) {
		return a?.sort().filter((value, index, array) => (index === 0) || (value !== array[index - 1])) ??[];
	}

	const getAssemblyId = (assembly) => assembly.sequence_id;
	const getAllShadeIds = (assembly) => shadeToSplit(assembly);
	const getFrontIds = (assembly) => shadeToSplit(assembly).filter((sId) => sId.row == 0);
	const getBackIds = (assembly) => shadeToSplit(assembly).filter((sId) => sId.row == 1);

	const isPlastic = (shade) => shade?.valMatch('manual_chain', 'plastic') ?? true;
	const isMetal = (shade) => shade?.valMatch('manual_chain', 'metal')?? true;

	const isPlasticOrMetal = (shade) => isPlastic(shade) || isMetal(shade);

	const emptyHardwareColor = (shade) => {
		const shadeData = shade?.data();

		try {
			if (shadeData.hardware_color) {
				shadeData.hardware_color = null;
			}
		} catch (err) {
			ROOT.alert({ quickFormat: 'error', text: 'Something went wrong. Please try again.' });
			console.error(err);
			throw new Error('Something went wrong. Please check console for details');
		}
	};

	const has_chains_banned = UI.hasChainsBanned();

	const fasciaBaseSkus = [
		"RS-SK",
		"RC3080",
		"RC4039"
	];

	const Items = [
		{
			varName: 'indoor_outdoor',
			value: 'indoor',
			getId: getAssemblyId,
			showOnForm: false,
		},
		{
			varName: 'shade_assembly_id',
			getId: getAssemblyId,
			showOnForm: false,
		},
		{
			varName: 'shade_1_id',
			getId: getAssemblyId,
			showOnForm: false,
		},
		{
			varName: 'shade_2_id',
			getId: getAssemblyId,
			showOnForm: false,
		},
		{
			varName: 'tube_sku',
			getId: getAssemblyId,
			showOnForm: false,
		},
		{
			varName: 'shade_type',
			label: 'Shade Type',
			type: 'select',
			description: (shade) => {
				const selectedShadeIsMotorized = shade?.isMotorized();
				const shadeType = shade?.val('shade_type');
				return (
					<>
						{shadeType ? (
							<img src={selectedShadeIsMotorized ? MotorizedShade : ManualShade} />
						) : (
							<>Please select a shade type</>
						)}
					</>
				);
			},
			choices: [
				{ name: 'Manual', value: 'manual' },
				{
					name: 'Motorized',
					value: 'motorized',
					onSelect: (quote, shade) => {
						if (!shade) return;
						const value = shade?.val('lv_power_source');
						if (value && value !== '') {
							shade && shade?.changeAccessoryCharging(true, value === 'solar_panel');
						}
					},
					onDeselect: (quote, shade) => {
						if (!shade) return;

						const value = shade?.val('lv_power_source');
						if (value && value !== '') {
							shade && shade?.changeAccessoryCharging(false, value === 'solar_panel');
						}
						const data = shade?.data();

						data.shades.forEach((s) => (s.motor_type = ''));
						data.shades.forEach((s) => (s.lv_power_source = ''));
						data.shades.forEach((s) => (s.manual_chain_color = ''));
					},
				},
			],
			causesReprice: true,
			groupName: 'Motor',
			inlineGroup: true,
			saveType: UI.ShadeNum,
			getId: getAllShadeIds,
			massEdit: true,
		},

		{
			varName: 'manual_chain',
			label: 'Drive Type',
			type: 'select',
			groupName: 'Motor',
			inlineGroup: true,
			conditional: (shade) => shade?.valMatch('shade_type', 'Manual'),
			choices: [
				{
					name: 'Metal Chain',
					value: 'metal',
					conditional: () => !has_chains_banned,
					
				},
				{
					name: 'Plastic Chain',
					value: 'plastic',
					conditional: () => !has_chains_banned,
				},
				{ name: 'Spring Pull', value: 'spring' },
			],
			saveType: UI.ShadeNum,
			getId: getAllShadeIds,
			massEdit: true,
			description: (shade) => {
				const driveType = shade?.val('manual_chain');
				const pullChain = driveType === 'stainless_steel' || driveType === 'plastic';
				return (
					<>
						{driveType ? (
							<img src={pullChain ? ManualShade : SpringPull} />
						) : (
							<>Please select a drive type</>
						)}
					</>
				);
			},
		},

		{
			varName: 'motor_type',
			label: 'Motor Type',
			type: 'select',
			groupName: 'Motor',
			value: null,
			conditional: (shade) => shade?.valMatch('shade_type', 'Motorized'),
			error: (shade) => {},
			choices: [
				{ name: 'PoE', value: 'poe' },
				{
					name: 'High-Voltage',
					value: 'high_voltage',
					onSelect: (quote, shade) => {
						if (!shade) return;
						shade && shade?.changeAccessoryCharging(true, true, true);
					},
					onDeselect: (quote, shade) => {
						if (!shade) return;
						shade && shade?.changeAccessoryCharging(false, true, true);
					},
				},
				{
					name: 'Low-Voltage (Hardwired)',
					value: 'low_voltage_hw',
					onSelect: (quote, shade) => {
						if (!shade) return;
						shade && shade?.changeAccessoryCharging(true, false, true);
					},
					onDeselect: (quote, shade) => {
						if (!shade) return;
						shade && shade?.changeAccessoryCharging(false, false, true);
					},
				},
				{
					name: 'Low-Voltage (Internal Li-Ion Battery)',
					value: 'low_voltage',
					onSelect: (quote, shade) => {
						if (!shade) return;

						const value = shade?.val('lv_power_source');
						if (value && value !== '') {
							shade && shade?.changeAccessoryCharging(true, value === 'solar_panel');
						}
					},
					onDeselect: (quote, shade) => {
						if (!shade) return;

						const value = shade?.val('lv_power_source');
						if (value && value !== '') {
							shade && shade?.changeAccessoryCharging(false, value === 'solar_panel');
						}
					},
				},
				{ name: 'PoE Gen 2', value: 'poe 2' },

			],
			description: (shade) => {
				const motorType = shade?.val('motor_type');

				const selectionToComponent = (motorType) => {
					switch (motorType) {
						case 'poe':
							return PoE;
						case 'low_voltage_hw':
							return DC;
						case 'low_voltage':
							return Battery;
						case 'high_voltage':
							return AC;
					}
				};
				return (
					<>
						{motorType ? (
							<img src={selectionToComponent(motorType)} />
						) : (
							<>Please select a motor type</>
						)}
					</>
				);
			},
			causesReprice: true,
			saveType: UI.ShadeNum,
			getId: getAllShadeIds,
			massEdit: true,
		},

		{
			varName: 'width',
			label: (shade) => (shade && shade?.data().is_coupled ? 'Total Width' : 'Width'),
			groupName: 'Motor',
			inlineGroup: true,
			type: 'fraction',
			error: (shade, quote) => {
				const shadeObj = shadeSubstitute(shade);
				
				const fabrics = SelectFabrics(shadeObj.quote_id)(UI.quoteLevelVariables().store.getState()) ?? [];

				const fab1 = fabrics.find(
					(f) => f.name === shade?.val('fabric_name')
				);
				const fab2 = fabrics.find(
					(f) => f.name === shade?.val('fabric2_name')
				);

				shade = shadeObj.data ? shadeObj.data() : shade;

				const column_coordinates = shade?.shades.map((s) => s.column_coordinate);

				const columnsy = unique(column_coordinates);

				const overrideRailroad = shadeObj.overrideRailRoad();

				for (let i = 0; i < columnsy.length; i++) {
					const width = shade?.shades.find(
						(s) => s.column_coordinate == i && s.row_coordinate == 0
					)?.width;

					if (fab1 && !fab1.is_railroadable && width > 144 && !overrideRailroad) {
						return `${
							shadeObj.isDualShade() ? 'Inside fabric ' : 'Fabric'
						} is not railroadable. Please enter a width of 144" or less.`;
					} else if (fab2 && !fab2.is_railroadable && width > 144) {
						return `${
							shadeObj.isDualShade() ? 'Outside fabric ' : 'Fabric'
						} is not railroadable. Please enter a width of 144" or less.`;
					}
				}

				if (shade?.is_coupled) {
					const totalWidthDoesntAddUp = ((shade) => {
						const totalWidth = shade?.total_width;

						const addedWidth = shade?.shades
							.filter((s) => s.row_coordinate == 0)
							.reduce((total, newShade) => total + (newShade ?? {})?.width ?? 0, 0);

						const diff = Math.abs(addedWidth - totalWidth);

						return diff > 0.005;
					})(shade);

					if (totalWidthDoesntAddUp) {
						return 'The current band widths do not add up to the entered total width.';
					}
				}
			},
			info: (shade) => {
				shade.valMatch('shade_type', 'Manual') &&
				shade.val('width') &&
				shade.val('width') < 18
					? 'Shades less than 18 inches wide are not upgradeable to motorized shades.'
					: null;
				if (
					shade.valMatch('shade_type', 'Manual') &&
					shade.val('width') &&
					shade.val('width') < 18
				) {
					return 'Shades less than 18 inches wide are not upgradeable to motorized shades.';
				}

				const shadeDataObj = shade?.data();

				const band_count = shadeDataObj.band_count ?? 1;

				if (shade?.valMatch('mount_type', 'Inside', 'Outside', 'Fabric Width')) {
					const variableAmount = band_count % 8;
					const bigNumberAmount = band_count / 8;

					let totalWidth = shadeDataObj.total_width;

					if (totalWidth == 0 || !totalWidth) {
						totalWidth = shadeDataObj.shades.find(
							(s) => s.row_coordinate == 0 && s.column_coordinate == 0
						).width;
					}

					const bigNumberString = bigNumberAmount > 0 ? `${bigNumberAmount}` : '';
					const variableString = variableAmount > 0 ? `${variableAmount}/8` : '';

					let variableEigths = '';

					if (bigNumberAmount > 0) {
						variableEigths += bigNumberString;
					}

					if (bigNumberAmount > 0 && variableAmount > 0) {
						variableEigths += ' ';
					}

					if (variableAmount > 0) {
						variableEigths += variableString;
					}

					let widthString = `${totalWidth - bigNumberAmount} - ${totalWidth}`;

					if (shade?.valMatch('mount_type', 'Outside', 'Fabric Width')) {
						variableEigths = `${variableEigths}`;
						widthString = `${totalWidth} - ${totalWidth + bigNumberAmount}`;
					}

					let bracketString = `Actual Width (Bracket to Bracket):`;

					if (shade?.valMatch('mount_type', 'Fabric Width')) {
						bracketString = `Actual Width (Fabric edge to Fabric edge):`;
					}

					return [bracketString, `${widthString}`];
				}
			},
			minValue: 0,
			maxValue: 228,
			complexMaxValueOverride: (shade) => {
					return 240;
			},
			causesReprice: true,
			saveType: UI.IsWidth,
			getId: getAssemblyId,
			getValue: (shade) => {
				const assemblyData = shade?.data ? shade?.data() : shade;
				const value = assemblyData.is_coupled
					? assemblyData.total_width
					: assemblyData.shades[0].width;
				return value;
			},
			massEdit: true,
		},

		{
			varName: 'height',
			label: 'Height',
			groupName: 'Motor',
			inlineGroup: true,
			type: 'fraction',
			info: (shade) =>
				shade?.val('width') &&
				shade?.val('height') &&
				shade?.val('height') >= shade?.val('width') * 3
					? 'For Optimal Tracking, a width-to-length ratio of less than 3:1 is recommended.'
					: null,
			minValue: 1,
			maxValue: 384,
			causesReprice: true,
			error: (shade) => {
				shade = shadeSubstitute(shade);

				if (shade?.val('height') > 280) {
					return 'Please contact PowerShades for shades greater than 280" in length.';
				}
			},
			saveType: UI.ShadeNum,
			getId: getAllShadeIds,
			massEdit: true,
		},

		{
			varName: 'mount_type',
			label: 'Mount Type',
			type: 'select',
			choices: [{ name: 'Inside' }, { name: 'Outside' }, { name: 'Fabric Width' }],
			groupName: 'Motor',
			inlineGroup: true,
			saveType: UI.AssemblyNum,
			getId: getAssemblyId,
			massEdit: true,
			description: (shade) => {
				const mountTypeSelected = shade?.data().mount_type;
				const mountToImage = (mountType) => {
					mountType = mountType.toLowerCase();
					switch (mountType) {
						case 'inside':
							return InsideMount;
						case 'outside':
							return OutsideMount;
						case 'fabric width':
							return FabricWidth;
					}
				};

				return (
					<>
						{mountTypeSelected ? (
							<img src={mountToImage(mountTypeSelected)} />
						) : (
							<>Please select a mount type</>
						)}
					</>
				);
			},
			info: (shade) => {
				if (!shade) return null;

				shade = shadeSubstitute(shade);

				const mountType = shade.val('mount_type');
				if (mountType === 'Inside') {
					return 'PowerShades advises measuring down to a 1/16” of an inch and subtracting 1/8” of an inch off the measurement from the top of the window for an inside mount. Please note that measurements may vary between the front and back of the window jamb. The 1/8” of an inch is to be taken from the narrowest measurement obtained.';
				}

				return null;
			},
		},

		{
			varName: 'single_dual',
			label: 'Shade Configuration',
			type: 'select',
			choices: [
				{
					name: 'Single',
				},
				{
					name: 'Dual',
				},
				{
					name: 'Single Coupled',
					conditional: (shade) => {
						if (!shade) return true;

						const mountType = shade?.val('mount_type');

						if (mountType == 'Fabric Width') return false;

						if (
							shade?.val('manual_chain') == 'spring' &&
							shade?.val('shade_type') == 'manual'
						) {
							return false;
						}
						return true;
					},
				},
				{
					name: 'Dual Coupled',
					conditional: (shade) => {
						if (!shade) return true;

						const mountType = shade.val('mount_type');

						if (mountType == 'Fabric Width') return false;

						if (
							shade.val('manual_chain') == 'spring' &&
							shade.val('shade_type') == 'manual'
						) {
							return false;
						}
						return true;
					},
				},
			],
			causesReprice: true,
			groupName: 'Motor',
			inlineGroup: true,
			saveType: UI.IsDualOrCoupled,
			getId: getAssemblyId,
			getValue: (shade) => {
				const assemblyData = shade.data ? shade.data() : shade;
				const isDual = assemblyData.single_dual.toLowerCase().includes("dual");
				const isCoupled = assemblyData.is_coupled;
				let val = isDual ? "Dual" : "Single";
				if (isCoupled) {
					val += " Coupled";
				}
				return val;
			},
		},

		{
			varName: 'coupledShades',
			label: 'Coupled or Uncoupled Shade',
			causesReprice: true,
			groupName: 'Coupled Shades',
			saveType: UI.IsCoupled,
			getId: getAssemblyId,
			conditional: (shade) => shade?.val('is_coupled') == true,
		},

		{
			varName: 'roll_direction',
			label: (shade) =>
				!shade || shadeSubstitute(shade).isDualShade()
					? 'Inside Roll Direction'
					: 'Roll Direction',
			groupName: 'Roll Direction',
			type: 'select',
			description: (shade) => {
				const selectedRollDirection = shade?.val('roll_direction');
				const isStandardRoll = selectedRollDirection === 'standard';

				return (
					<>
						{selectedRollDirection ? (
							<img src={isStandardRoll ? StandardRoll : ReverseRoll} />
						) : (
							<>Please select a roll direction</>
						)}
					</>
				);
			},
			choices: [
				{
					name: 'Standard Roll',
					value: 'standard',
					conditional: (shade) => {
						if (!shade) return true;

						shade = shadeSubstitute(shade);

						if (shade.isDualShade() && shade.val('header_type') == 'OR_1234') {
							return false;
						}

						return true;
					},
				},
				{
					name: 'Reverse Roll',
					value: 'reverse',
					conditional: (shade) => {
						if (!shade) return true;

						shade = shadeSubstitute(shade);

						const headerType = shade?.val('header_type') ?? '';

						return !(
							headerType.includes('RC3080') ||
							headerType.includes('RC3051') ||
							headerType.includes('RC3029')
						);
						return !shade || !(shade.val('header_type') ?? '').includes('FASCIA');
					},
				},
			],
			saveType: UI.ShadeNum,
			getId: getFrontIds,
			skipReprice: true,
			massEdit: true,
		},

		{
			varName: 'roll_direction2',
			realVarName: 'roll_direction',
			label: 'Outside Roll Direction',
			groupName: 'Roll Direction',
			type: 'select',
			conditional: (shade) => shadeSubstitute(shade).isDualShade(),
			description: (shade) => {
				const shadeData = shade?.data();
				const selectedRollDirection = shadeData?.shades[1].roll_direction ?? '';
				const isStandardRoll = selectedRollDirection === 'standard';

				return (
					<>
						{selectedRollDirection ? (
							<img src={isStandardRoll ? StandardRoll : ReverseRoll} />
						) : (
							<>Please select a roll direction</>
						)}
					</>
				);
			},
			choices: [
				{
					name: 'Standard Roll',
					value: 'standard',
					conditional: (shade) => {
						if (!shade) return true;

						return true;
					},
				},
				{
					name: 'Reverse Roll',
					value: 'reverse',
					conditional: (shade) => {
						if (!shade) return true;

						const shadeObj = shadeSubstitute(shade);

						return !shadeObj || !(shadeObj.val('header_type') ?? '').includes('FASCIA');
					},
				},
			],
			saveType: UI.ShadeNum,
			getId: getBackIds,
			massEdit: true,
			skipReprice: true,
			getValue: (shade) => {
				const assemblyData = shade.data ? shade.data() : shade;

				if (assemblyData.single_dual == 'single') {
					return null;
				}

				const shadeItem = assemblyData.shades.find((s) => s.row_coordinate == 1);

				const value = shadeItem && shadeItem.roll_direction;
				return value;
			},
		},

		{
			varName: 'header_type',
			label: 'Header Type',
			groupName: 'Header',
			type: 'select',
			error: (shade) =>
				shade.isDualShade() & `${shade.val('header_type')}`.includes('Cassette')
					? 'Cassette headers are not compatible with dual shades.'
					: null ||
					  shade.isDualShade() &
							`${shade.val('header_type')}`.includes('Open Roll Small')
					? 'Small Open Roll is not compatible with dual shades.'
					: null,
			causesReprice: true,
			choices: (shade) => {
				const { quoteType } = UI.quoteLevelVariables();

				const isBidSpec = quoteType == 'BidSpec' || quoteType == 'RMA' || quoteType == 'Rework';

				const bothOpen = [
					{
						name: 'Open Roll Medium',
						value: 'OR_1234',
						conditional: (shade) => {
							if (shade?.isDualShade() && shade?.val('is_coupled') == true) {
								return false;
							}

							return true;
						},
						onDeselect: (quote, shade) => {
							emptyHardwareColor(shade);
						},
					},
				];

				const threeInchCassette = [
					{
						optGroup: 'Round Fabric Insert Cassette 3 in.',
						value: 'RC3051-A',
						name: 'Round Fabric Insert Cassette Anodized',
						conditional: (shade) => !shade.isDualShade(),
					},
					{
						optGroup: 'Round Fabric Insert Cassette 3 in.',
						value: 'RC3051-BK',
						name: 'Round Fabric Insert Cassette Black',
						conditional: (shade) => !shade.isDualShade(),
					},
					{
						optGroup: 'Round Fabric Insert Cassette 3 in.',
						value: 'RC3051-DBR',
						name: 'Round Fabric Insert Cassette Bronze',
						conditional: (shade) => !shade.isDualShade(),
					},
					{
						optGroup: 'Round Fabric Insert Cassette 3 in.',
						value: 'RC3051-LB',
						name: 'Round Fabric Insert Cassette Off-White',
						conditional: (shade) => !shade.isDualShade(),
					},
					{
						optGroup: 'Round Fabric Insert Cassette 3 in.',
						value: 'RC3051-W',
						name: 'Round Fabric Insert Cassette White',
						conditional: (shade) => !shade.isDualShade(),
					},
					{
						optGroup: 'Round Fabric Insert Cassette 3 in.',
						value: 'RC3051-AN',
						name: 'Round Fabric Insert Cassette Anthracite',
						conditional: (shade) => !shade.isDualShade(),
					},

					{
						optGroup: 'Square Cassette 3 in.',
						value: 'RC3029-A',
						name: 'Square Cassette Anodized',
						conditional: (shade) => !shade.isDualShade(),
					},
					{
						optGroup: 'Square Cassette 3 in.',
						value: 'RC3029-BK',
						name: 'Square Cassette Black',
						conditional: (shade, quote) =>
							!shade.isDualShade() 
					},
					{
						optGroup: 'Square Cassette 3 in.',
						value: 'RC3029-W',
						name: 'Square Cassette White',
						conditional: (shade) => !shade.isDualShade(),
					},
					{
						optGroup: 'Square Cassette 3 in.',
						value: 'RC3029-LB',
						name: 'Square Cassette Off-White',
						conditional: (shade, quote) =>
							!shade.isDualShade() &&
							(false || shade.val('header_type') == 'RC3029-LB'),
					},
					{
						optGroup: 'Flat Fabric Wrapped Cassette 3 in.',
						value: 'RB88-0150-050490',
						name: 'Flat Fabric Wrapped Cassette Black',
						conditional: (shade) => !shade.isDualShade(),
					},
					{
						optGroup: 'Flat Fabric Wrapped Cassette 3 in.',
						value: 'RB88-0150-069490',
						name: 'Flat Fabric Wrapped Cassette White',
						conditional: (shade) => !shade.isDualShade(),
					},
					{
						optGroup: 'Flat Fabric Wrapped Cassette 3 in.',
						value: 'RB88-0150-020490',
						name: 'Flat Fabric Wrapped Cassette Anodized',
						conditional: (shade) => !shade.isDualShade(),
					},
					{
						optGroup: 'Flat Fabric Wrapped Cassette 3 in.',
						value: 'RB88-0150-080490',
						name: 'Flat Fabric Wrapped Cassette Bronze',
						conditional: (shade) => !shade.isDualShade(),
					},
					{
						optGroup: 'Flat Fabric Wrapped Cassette 3 in.',
						value: 'RB88-0150-414490',
						name: 'Flat Fabric Wrapped Cassette Off-White',
						conditional: (shade) => !shade.isDualShade(),
					},
				];

				const threeInchFascia = [
					{
						optGroup: 'Fascia',
						value: 'RC3080-A',
						name: 'Fascia 3 in. Anodized',
						conditional: (shade) => !shade.isDualShade(), // && !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC3080-AN',
						name: 'Fascia 3 in. Anthracite',
						conditional: (shade) => !shade.isDualShade(), // && !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC3080-BK',
						name: 'Fascia 3 in. Black',
						conditional: (shade) => !shade.isDualShade(), // && !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC3080-DBR',
						name: 'Fascia 3 in. Bronze',
						conditional: (shade) => !shade.isDualShade(), // && !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC3080-LB',
						name: 'Fascia 3 in. Off-White',
						conditional: (shade) => !shade.isDualShade(), // && !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC3080-W',
						name: 'Fascia 3 in. White',
						conditional: (shade) => !shade.isDualShade(), // && !shade.valIncludes('motor_type', 'high_voltage')
					},
				];

				const fourInchFasciaCou = [
					{
						optGroup: 'Fascia',
						value: 'RC3158-A',
						name: 'Fascia 4 in. Anodized',
						conditional: (shade) => !shade.isDualShade(), // && !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC3158-AN',
						name: 'Fascia 4 in. Anthracite',
						conditional: (shade) => !shade.isDualShade(), // && !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC3158-BK',
						name: 'Fascia 4 in. Black',
						conditional: (shade) => !shade.isDualShade(), // && !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC3158-DBR',
						name: 'Fascia 4 in. Bronze',
						conditional: (shade) => !shade.isDualShade(), // && !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC3158-LB',
						name: 'Fascia 4 in. Off-White',
						conditional: (shade) => !shade.isDualShade(), // && !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC3158-W',
						name: 'Fascia 4 in. White',
						conditional: (shade) => !shade.isDualShade(), // && !shade.valIncludes('motor_type', 'high_voltage')
					},
				];

				const fiveInchFascia = [
					{
						optGroup: 'Fascia',
						value: 'RC4039-A',
						name: 'Fascia 5 in. Anodized',
						// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC4039-AN',
						name: 'Fascia 5 in. Anthracite',
						// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC4039-BK',
						name: 'Fascia 5 in. Black',
						// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC4039-DBR',
						name: 'Fascia 5 in. Bronze',
						// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC4039-LB',
						name: 'Fascia 5 in. Off-White',
						// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC4039-W',
						name: 'Fascia 5 in. White',
						// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
					},
				];

				const standardOpen = [
					{
						name: 'Open Roll Small',
						value: 'OR_0002',
						conditional: (shade) =>
							shade.valMatch('shade_type', 'Manual') &&
							!shade.isDualShade() &&
							!shade.isCoupledShade(),
						onDeselect: (quote, shade) => {
							emptyHardwareColor(shade);
						},
					},
				];

				const extraLargeOpen = [
					{
						name: 'Open Roll Extra Large',
						value: 'OR_9876',
						conditional: (shade) => {
							// Needs to check if either the shade has xl or if PS admin
							const userData = UI.Data.getUserData();

							const isPsAdmin =
								userData &&
								userData.roles &&
								userData.roles.some((ur) => ur.role_name == 'powershades_admin');

							if (!shade) return false;

							const isXl = shade.valMatch('header_type', 'OR_9876');

							return isXl || isPsAdmin;
						},
						onDeselect: (quote, shade) => {
							emptyHardwareColor(shade);
						},
					},
				];

				const fourInchCassette = !isBidSpec
					? []
					: [
							{
								optGroup: 'Cassette (Fabric Insert Included)',
								value: 'RS-SU1',
								name: 'Cassette 4 in. White',
								conditional: (shade) => false
							},
							{
								optGroup: 'Cassette (Fabric Insert Included)',
								value: 'RS-SU2',
								name: 'Cassette 4 in. Bronze',
								conditional: (shade) =>false
							},
							{
								optGroup: 'Cassette (Fabric Insert Included)',
								value: 'RS-SU3',
								name: 'Cassette 4 in. Alabaster',
								conditional: (shade) => false
							},
							{
								optGroup: 'Cassette (Fabric Insert Included)',
								value: 'RS-SU4',
								name: 'Cassette 4 in. Anodized',
								conditional: (shade) => false
							},
							{
								optGroup: 'Cassette (Fabric Insert Included)',
								value: 'RS-SU5',
								name: 'Cassette 4 in. Black',
								conditional: (shade) => false
							},
					  ];

				const mediumLargeOpen = [
					{
						name: 'Open Roll Large (Single)',
						value: 'OR_COULLISE_LARGE_BALANCED',
						conditional: (shade) => {
							if (!shade) {
								return true;
							}

							return (
								 !shade.isDualShade()
							);
						},
						onDeselect: (quote, shade) => {
							emptyHardwareColor(shade);
						},
					},
				];

				const heavyOpen = [
					{
						name: 'Open Roll Large (Dual)',
						value: 'OR_5678',
						conditional: (shade) => {
							if (!shade) {
								return true;
							}

							return (
								!(
									shade.valMatch(
										'motor_type',
										'low_voltage_hw',
										'low_voltage',
										'poe'
									) && shade.isDualShade()
								) && shade.valMatch('shade_type', 'Motorized', 'motorized')
							);
						},
						onDeselect: (quote, shade) => {
							emptyHardwareColor(shade);
						},
					},
				];

				const fourInchFascia = !isBidSpec
					? []
					: [
							{
								optGroup: 'Fascia',
								value: 'RS-SC1',
								name: 'Fascia 4 in. White',
								conditional: (shade) =>
									!shade.isDualShade() &&
									!shade.valIncludes(
										'motor_type',
										'low_voltage',
										'low_voltage_hw'
									),
							},
							{
								optGroup: 'Fascia',
								value: 'RS-SC2',
								name: 'Fascia 4 in. Bronze',
								conditional: (shade) =>
									!shade.isDualShade() &&
									!shade.valIncludes(
										'motor_type',
										'low_voltage',
										'low_voltage_hw'
									),
							},
							{
								optGroup: 'Fascia',
								value: 'RS-SC3',
								name: 'Fascia 4 in. Alabaster',
								conditional: (shade) =>
									!shade.isDualShade() &&
									!shade.valIncludes(
										'motor_type',
										'low_voltage',
										'low_voltage_hw'
									),
							},
							{
								optGroup: 'Fascia',
								value: 'RS-SC4',
								name: 'Fascia 4 in. Anodized',
								conditional: (shade) =>
									!shade.isDualShade() &&
									!shade.valIncludes(
										'motor_type',
										'low_voltage',
										'low_voltage_hw'
									),
							},
							{
								optGroup: 'Fascia',
								value: 'RS-SC5',
								name: 'Fascia 4 in. Black',
								conditional: (shade) =>
									!shade.isDualShade() &&
									!shade.valIncludes(
										'motor_type',
										'low_voltage',
										'low_voltage_hw'
									),
							},
					  ];

				const fiveAndAHalfInchFascia = !isBidSpec
					? []
					: [
							{
								optGroup: 'Fascia',
								value: 'RS-SV4',
								name: 'Fascia 5.5 in. Anodized',
								conditional: (shade) =>
									!shade.isDualShade() &&
									!shade.valIncludes(
										'motor_type',
										'low_voltage',
										'low_voltage_hw'
									),
							},
							{
								optGroup: 'Fascia',
								value: 'RS-SV2',
								name: 'Fascia 5.5 in. Bronze',
								conditional: (shade) =>
									!shade.isDualShade() &&
									!shade.valIncludes(
										'motor_type',
										'low_voltage',
										'low_voltage_hw'
									),
							},
							{
								optGroup: 'Fascia',
								value: 'RS-SV1',
								name: 'Fascia 5.5 in. White',
								conditional: (shade) =>
									!shade.isDualShade() &&
									!shade.valIncludes(
										'motor_type',
										'low_voltage',
										'low_voltage_hw'
									),
							},
					  ];

				const sevenInchFascia = [
					{
						optGroup: 'Fascia',
						value: 'RS-SK4A',
						name: 'Fascia 7 in. Anodized',
						conditional: (shade) =>
							shade.isDualShade() && shade.valMatch('shade_type', 'Motorized'),
					},
					{
						optGroup: 'Fascia',
						value: 'RS-SK5A',
						name: 'Fascia 7 in. Black',
						conditional: (shade) =>
							shade.isDualShade() && shade.valMatch('shade_type', 'Motorized'),
					},
					{
						optGroup: 'Fascia',
						value: 'RS-SK2A',
						name: 'Fascia 7 in. Bronze',
						conditional: (shade) =>
							shade.isDualShade() && shade.valMatch('shade_type', 'Motorized'),
					},
					{
						optGroup: 'Fascia',
						value: 'RS-SK1A',
						name: 'Fascia 7 in. White',
						conditional: (shade) =>
							shade.isDualShade() && shade.valMatch('shade_type', 'Motorized'),
					},
				];

				const largeZmc = !isBidSpec
					? []
					: [
							{
								optGroup: 'ZMC - Extended',
								value: 'OR_ZMC_LARGE',
								name: '(ZMC) Open Roll Large',
								// conditional: (shade) => shade.valMatch('motor_type', 'high_voltage') && shade.valMatch('shade_type', 'Motorized')
							},
					  ];

				let items = null;

				if (shade) {
					if (!shade.valMatch('motor_type', 'high_voltage') && !shade.isDualShade()) {
						fourInchCassette.forEach((h) => (h.conditional = () => false));
						heavyOpen.forEach((h) => (h.conditional = () => false));
						[...fourInchFascia, ...fiveAndAHalfInchFascia, ...sevenInchFascia].forEach(
							(h) => (h.conditional = () => false)
						);
					} else {
						standardOpen.forEach((h) => (h.conditional = () => false));
					}

					items = [
						...standardOpen, // No Dual
						...bothOpen, // Dual
						...mediumLargeOpen, // No dual,
						...heavyOpen, // Dual
						...extraLargeOpen, // XL open
						...threeInchFascia, // No Dual
						...fourInchFasciaCou, // No Dual
						...fourInchFascia, // No Dual
						...fiveInchFascia, // Dual
						...fiveAndAHalfInchFascia, // Dual
						...sevenInchFascia, // Dual
						...threeInchCassette, // No Dual
						...fourInchCassette, // No dual,
						...largeZmc, // No dual,
					];

					const isSpring = shade.valIncludes('manual_chain', 'spring');

					if (isSpring) {
						standardOpen.forEach((h) => (h.conditional = () => false));
					}
				} else {
					items = [
						...standardOpen, // No Dual
						...bothOpen, // Dual
						...mediumLargeOpen, // No dual
						...heavyOpen, // Dual
						...threeInchFascia, // No Dual
						...fourInchFasciaCou, // No Dual
						...fourInchFascia, // No Dual
						...fiveInchFascia, // Dual
						...fiveAndAHalfInchFascia, // Dual
						...sevenInchFascia, // Dual
						...threeInchCassette, // No Dual
						...fourInchCassette, // No dual
						...largeZmc, // No dual
					];

					items.forEach((i) => (i.conditional = () => true));
				}

				return items;
			},
			value: null,
			saveType: UI.AssemblyNum,
			getId: getAssemblyId,
			massEdit: true,
			description: (shade, shade2) => {
				if (!shade) return null;

				const headerType = shade?.val('header_type') ?? '';
				const { quoteType } = UI.quoteLevelVariables();

				if (!headerType) {
					return <ImageBubble quoteType={quoteType} generic />;
				}

				const headerData = HeaderData();

				const headerItem = headerData.find((h) => headerType.includes(h.value));

				if (!headerItem) {
					return <ImageBubble quoteType={quoteType} generic />;
				}

				const imageName = shade?.isDualShade()
					? headerItem.dual_image_name
					: headerItem.image_name;

				return <ImageBubble quoteType={quoteType} fileName={imageName} />;
			},
		},

		{
			varName: 'end_caps',
			label: 'End Caps',
			groupName: 'Header',
			type: 'select',
			conditional: (shade) =>
				!shade?.valIncludes(
					'header_type',
					'RC3051',
					'RC3029',
					'OR_9876',
					'RB88-0150',
					'OR_ZMC_LARGE',
					'OR_5678'
				), // End caps not available for cassettes or ZMC.
			choices: [
				{
					name: 'None',
					conditional: (shade) => {
						if (shade?.valMatch('end_caps', 'None') ?? false) {
							return true;
						}
						return false;

						// if(shade.valIncludes('header_type', 'RC3080', 'RC4039', 'RS-SC', 'RS-SK', 'RS-SV')) {

						// }

						// if (shade && (shade.valMatch('mount_type', 'Outside') | shade.valMatch('mount_type', 'Fabric Width'))) {

						//     return false;
						// }

						// return true;
					},
				},
				{
					optGroup: 'Fascia',
					name: 'Fascia End Caps',
					conditional: (shade) =>
						!shade ||
						shade.valIncludes(
							'header_type',
							'RC3080',
							'RC3158',
							'RC4039',
							'RS-SC',
							'RS-SK',
							'RS-SV'
						),
				},
				{
					optGroup: 'Open Roll',
					name: 'Metal End Caps',
					conditional: (shade) => {
						if (shade && shade?.isDualShade()) {
							return false;
						}

						return (
							!shade ||
							shade?.valIncludes('header_type', 'OR_0002', 'OR_1234', 'OR_5678', 'OR_COULLISE_LARGE_BALANCED')
						);
					},
				},
				{
					optGroup: 'Open Roll',
					name: 'Plastic End Caps',
					conditional: (shade) =>
						!shade ||
						shade?.valIncludes(
							'header_type',
							'OR_0002',
							'OR_1234',
							'OR_5678',
							'OR_9876',
							'OR_COULLISE_LARGE_BALANCED'
						),
				},
			],
			saveType: UI.AssemblyNum,
			getId: getAssemblyId,
			massEdit: true,
		},
		{
			varName: 'fascia_split',
			label: (shade) => 'Fascia Style',
			getId: getAssemblyId,
			saveType: UI.AssemblyNum,
			groupName: 'Header',
			type: 'select',
			choices: () => {
				const split_items = [
					{
						name: 'Match Mullion',
						value: 'match_mullion',
						conditional: (shade) => {
							const shadeObj = shadeSubstitute(shade);

							if (shadeObj?.val('header_type')?.includes('RC3080') ?? false) {
								return false;
							}

							return true;
						},
					},
					{ name: 'One Piece', value: 'single_piece' },
					{ name: 'No Fascia', value: 'no_fascia' },
				];

				return split_items;
			},
			error: (shade) => {
				if (!shade) return false;

				const shadeObj = shadeSubstitute(shade);

				const splitVal = shadeObj?.val('fascia_split') ?? 'empty';

				const isFascia = fasciaBaseSkus.some(
					(fsHeader) => shadeObj?.val('header_type')?.includes(fsHeader) ?? false
				);

				const isCoupled = shadeObj.isCoupledShade();

				if (splitVal == 'empty' && isFascia && isCoupled) {
					return 'Must select Fascia Style.';
				}

				return false;
			},
			conditional: (shade) => {
				if (!shade) {
					return false;
				}

				const shadeObj = shadeSubstitute(shade);

				const isCoupled = shadeObj.isCoupledShade();

				const isFascia = fasciaBaseSkus.some(
					(fsHeader) => shadeObj?.val('header_type')?.includes(fsHeader) ?? false
				);

				return isFascia && isCoupled;
			},
		},
		{
			varName: 'hardware_color',
			label: (shade) =>
				shade
					? shade?.valMatch('end_caps', 'None')
						? 'Bracket Color'
						: 'Open Roll Hardware Color'
					: 'Hardware Color',
			groupName: 'Header',
			type: 'select',
			choices: (shade) => {
				if (shade) {
					const shadeIsXl = (shade) => shade?.valMatch('header_type', 'OR_9876');

					if (shadeIsXl(shade)) {
						return [
						
							{
								conditional: () => true,
								name: 'Black',
								value: 'Black',
							},
							{
								conditional: () => true,
								name: 'White',
								value: 'White',
							},
						];
					}

					const shadeIsZmcOpenRoll = (shade) => shade?.valMatch('header_type', 'OR_5678');

					if(shadeIsZmcOpenRoll(shade))
					{
						return [
							{
								name: 'White',
								value: 'White',
							},
						];

					}

					return [
						{
							conditional: (shade) => shade?.valMatch('end_caps', 'None'),
							name: 'Anthracite',
							value: 'Anthracite',
						},
						{
							conditional: (shade) => shade?.valMatch('end_caps', 'None'),
							name: 'Black',
							value: 'Black',
						},
						{
							conditional: (shade) => shade?.valMatch('end_caps', 'None'),
							name: 'Bronze',
							value: 'Bronze',
						},
						{
							conditional: (shade) => shade?.valMatch('end_caps', 'None'),
							name: 'Gray',
							value: 'Gray',
						},
						{
							conditional: (shade) => shade?.valMatch('end_caps', 'None'),
							name: 'Off-White',
							value: 'Off-White',
						},
						{
							conditional: (shade) => shade?.valMatch('end_caps', 'None'),
							name: 'White',
							value: 'White',
						},

						{
							conditional: (shade) => shade?.valMatch('end_caps', 'Plastic End Caps'),
							name: 'Anthracite',
							value: 'Anthracite',
						},
						{
							conditional: (shade) => shade?.valMatch('end_caps', 'Plastic End Caps'),
							name: 'Black',
							value: 'Black',
						},
						{
							conditional: (shade) => shade?.valMatch('end_caps', 'Plastic End Caps'),
							name: 'Bronze',
							value: 'Bronze',
						},
						{
							conditional: (shade) => shade?.valMatch('end_caps', 'Plastic End Caps'),
							name: 'Gray',
							value: 'Gray',
						},
						{
							conditional: (shade) => shade?.valMatch('end_caps', 'Plastic End Caps'),
							name: 'Off-White',
							value: 'Off-White',
						},
						{
							conditional: (shade) => shade?.valMatch('end_caps', 'Plastic End Caps'),
							name: 'White',
							value: 'White',
						},

						{
							conditional: (shade) => shade?.valMatch('end_caps', 'Metal End Caps'),
							name: 'Black Nickel',
							value: 'Black Nickel',
						},
						{
							conditional: metalEndcapColorConditional,
							name: 'Chrome',
							value: 'Chrome',
						},
						{
							conditional: metalEndcapColorConditional,
							name: 'Natural Aluminum',
							value: 'Natural Aluminum',
						},
						{
							conditional: metalEndcapColorConditional,
							name: 'Old Brass',
							value: 'Old Brass',
						},
						{
							conditional: metalEndcapColorConditional,
							name: 'Stainless Steel',
							value: 'Stainless Steel',
						},
						{
							conditional: metalEndcapColorConditional,
							name: 'White',
							value: 'White',
						},
					];
				}
				return [
					{ name: 'Black', value: 'Black' },
					{ name: 'Gray', value: 'Gray' },
					{ name: 'Anthracite', value: 'Anthracite' },
					{ name: 'Bronze', value: 'Bronze' },
					{ name: 'Gray', value: 'Gray' },
					{ name: 'Off-White', value: 'Off-White' },
					{ name: 'Black Nickel', value: 'Black Nickel' },
					{ name: 'Chrome', value: 'Chrome' },
					{ name: 'Natural Aluminum', value: 'Natural Aluminum' },
					{ name: 'White', value: 'White' },
					{ name: 'Old Brass', value: 'Old Brass' },
					{ name: 'Stainless Steel', value: 'Stainless Steel' },
				];
			},

			conditional: (shade) =>
				shade?.valMatch('header_type', 'OR_1234', 'OR_9876') ||
				shade?.valMatch('header_type', 'OR_5678', 'OR_COULLISE_LARGE_BALANCED') ||
				shade?.valMatch('header_type', 'OR_0002'),
			skipReprice: true,
			saveType: UI.AssemblyNum,
			getId: getAssemblyId,
			massEdit: true,
		},

		{
			value: null,
			varName: 'fabric_name',
			label: (shade) => {
				const toggleHover = (override) => {
					toggleInsideFabricState(override);
					console.log('Uhhh');
					console.log(getInsideFabricState());
				};
				const open = (getInsideFabricState && getInsideFabricState()) ?? false;
				if (shade && shade?.isDualShade()) {
					return (
						<div>
							<Popup
								position="right center"
								on={['hover']}
								trigger={() => <info className="button">Inside</info>}
							>
								<div>Hey There</div>
							</Popup>{' '}
							Fabric
						</div>
					);
				}
				return 'Fabric';
			},
			fullWidth: true,
			causesReprice: true,
			groupName: 'Fabric Select',
			type: 'fabricSelect',
			error: (shade) => {},
			choices: () => {
				const goods = SelectIndoorFabrics(UI.quoteLevelVariables().quoteSub.ID)(UI.quoteLevelVariables().store.getState())
				return  goods ?? [];
			},
			saveType: UI.ShadeNum,
			getId: getFrontIds,
			massEdit: true,
			description: (shade) => {
				if (!shade) return;
				const isDual = shade?.isDualShade();
				if (!isDual) return false;
				return <img src={InsideFabric} />;
			},
			getValue: (shade) => {
				const assemblyData = shade?.data ? shade?.data() : shade;

				const shadeItem = assemblyData.shades.find((s) => s.row_coordinate == 0);

				const value = shadeItem && shadeItem.fabric_name;
				return value ?? "";
			},
		},

		{
			value: null,
			varName: 'fabric2_name',
			realVarName: 'fabric_name',
			label: 'Outside Fabric',
			causesReprice: true,
			fullWidth: true,
			groupName: 'Fabric Select',
			type: 'fabricSelect',
			conditional: (shade) => shade?.isDualShade(),
			error: (shade) => {
				if (shade?.isDualShade() && shade?.valMatch('fabric2_name', '')) {
					return 'Please select an outside fabric.';
				}
			},
			choices: () => SelectIndoorFabrics(UI.quoteLevelVariables().quoteSub.ID)(UI.quoteLevelVariables().store.getState()) ?? [],
			saveType: UI.ShadeNum,
			getId: getBackIds,
			massEdit: true,
			getValue: (shade) => {
				const assemblyData = shade?.data ? shade?.data() : shade;

				if (assemblyData.single_dual == 'single') {
					return null;
				}

				const shadeItem = assemblyData.shades.find((s) => s.row_coordinate == 1);

				const value = shadeItem && shadeItem.fabric_name;
				return value ?? "";
			},
			description: (shade) => {
				if (!shade) return;
				const isDual = shade.isDualShade();
				if (!isDual) return;

				return <img src={OutsideFabric} />;
			},
		},

		{
			varName: 'control_side',
			label: 'Control Side',
			skipReprice: true,
			type: 'select',
			choices: [{ name: 'Right' }, { name: 'Left' }],
			groupName: 'Motor Options',
			inlineGroup: true,
			saveType: UI.AssemblyNum,
			getId: getAssemblyId,
			description: (shade) => {
				const controlSideSelected = shade?.data().control_side;

				return (
					<>
						{controlSideSelected ? (
							<img
								src={
									controlSideSelected.toLowerCase() === 'right'
										? RightControl
										: LeftControl
								}
							/>
						) : (
							<>Please select a control side</>
						)}
					</>
				);
			},
			massEdit: true,
		},

		{
			varName: 'hembar_type',
			groupName: 'Motor Options',
			label: 'Hembar Type',
			type: 'select',
			choices: [
				{ name: 'Internal / Sealed Hembar with Sealed Ends', value: 'internal_sealed' },

				{ name: 'Internal Oval', value: 'internal_oval' },
				// {name: 'External / Fabric Wrapped', value: 'external_fabric_wrapped'},
				{
					name: 'External / Metal',
					value: 'external_metal',
					onSelect: (quote, shade) => {
						const data = shade?.data() ?? {};

						data.side_channels = 'None';
						data.side_channels_color = '';
					},
					conditional: () => true,
				}, // TODO: IF METAL HEMBAR match color options to fascia color choices.
				{
					name: 'External Cable',
					value: 'external_cable',
					conditional: (shade) => {
						// Needs to check if either the shade has xl or if PS admin
						const userData = UI.Data.getUserData();

						const isPsAdmin =
							userData &&
							userData.user_roles &&
							userData.user_roles.some((ur) => ur.role_name == 'PowerShades Admin');

						if (!shade) return false;

						const isXl = shade.valMatch('hembar_type', 'external_cable');

						return isXl || isPsAdmin;
					},
				},
				{
					name: 'External Hembar with Grip',
					value: 'external_handle',
					conditional: (shade) => {
						// Needs to check if either the shade has xl or if PS admin
						// let userData = UI.Data.getUserData();

						// let isPsAdmin = userData && userData.user_roles && userData.user_roles.some(ur => ur.role_name == 'PowerShades Admin');

						if (!shade) return false;
						const hasMetalEndcaps = shade.valMatch('end_caps', 'Metal End Caps');
						const isSpring = shade.valIncludes('manual_chain', 'spring');
						const isManual = !shade.valMatch('shade_type', 'Motorized');

						return isManual && isSpring && !hasMetalEndcaps;
					},
				},
				{
					name: 'Internal Fabric Wrapped',
					value: 'internal_oval_fabric_wrapped',
					conditional: (shade) => {
						// Needs to check if either the shade has xl or if PS admin
						// let userData = UI.Data.getUserData();
						if (!shade) return false;

						const isXl = shade.valMatch('hembar_type', 'internal_oval_fabric_wrapped');

						return isXl; // || isPsAdmin;
					},
				},

				{
					name: 'Internal / Sealed Hembar with Magnetic Holddown',
					value: 'internal_magnetic',
				},
				{ name: 'Internal Oval Hembar with Magnetic Holddown', value: 'oval_magnetic' },
				{
					name: 'External / Metal Hembar with Magnetic Holddown',
					value: 'external_magnetic',
				},
			],
			description: (shade) => {
				const hembarSelected = shade?.val('hembar_type') ?? undefined;
				const isMagnetic = hembarSelected?.includes('magnetic') ?? false;

				const hembarImage = (hembar) => {
					switch (hembar) {
						case ('internal_sealed', 'internal_magnetic'):
							return InternalFlatHembar;
						case ('internal_oval', 'oval_magnetic'):
							return InternalRoundedHembar;
						case ('external_metal', 'external_magnetic'):
							return ExternalHembar;
						default:
							return InternalFlatHembar;
					}
				};

				if (isMagnetic && hembarSelected) {
					const selectedHembar = hembarImage(hembarSelected);
					return (
						<Row>
							<Col>
								<img src={HembarWithMagnet} alt="magnetic hembar" />
								<img src={selectedHembar} alt="Selected hembar type" />
							</Col>
						</Row>
					);
				}

				return (
					<>
						{hembarSelected ? (
							<img src={hembarImage(hembarSelected)} />
						) : (
							<>Please select a hembar type</>
						)}
					</>
				);
			},
			saveType: UI.ShadeNum,
			getId: getAssemblyId,
			massEdit: true,
		},
		{
			varName: 'balanced_lightgaps',
			label: 'Balanced Lightgaps',
			type: 'select',
			skipReprice: false,
			groupName: 'Motor Options',
			inlineGroup: true,
			ignoreIfOrder: true,
			conditional: (shade, quote) => shade?.showLightGap(),

			saveType: UI.AssemblyNum,
			choices: [
				{ name: 'Balanced', value: 'balanced' },
				{ name: 'Unbalanced', value: 'unbalanced' },
			],
			getId: getAssemblyId,
			massEdit: true,
		},

		{
			varName: 'window_jamb_depth',
			label: 'Window Jamb Depth',
			type: 'fraction',
			skipReprice: true,
			groupName: 'Motor Options',
			inlineGroup: true,
			minValue: 0,
			maxValue: 384,
			conditional: (shade) => shade?.valMatch('mount_type', 'Inside'),
			saveType: UI.AssemblyNum,
			getId: getAssemblyId,
			massEdit: true,
		},

		{
			varName: 'hembar_color',
			groupName: 'Motor Options',
			skipReprice: true,
			label: 'Hembar Color',
			type: 'select',
			error: (shade) =>
				(shade?.val('hembar_type') ?? '').includes('External') &&
				`${shade?.val('hembar_color')}` == ''
					? 'Please select a hembar color.'
					: null,
			conditional: (shade) =>
				shade?.valMatch('hembar_type', 'External / Fabric Wrapped', 'External / Metal'),
			choices: (shade) =>
				shade && shade.valMatch('hembar_type', 'External / Metal')
					? shade.fascia_color.choices
					: [{ name: 'White' }, { name: 'Black' }, { name: 'Anodized' }],
			saveType: UI.ShadeNum,
			getId: getAllShadeIds,
			massEdit: true,
		},

		{
			varName: 'side_channels',
			groupName: 'Options',
			label: 'Side Channels',
			type: 'select',
			conditional: (shade) =>
				!(
					(shade?.val('hembar_type') ?? '').includes('external') ||
					(shade?.val('hembar_type') ?? '').includes('oval') || 
					(shade?.val('header_type') ?? '').includes('RC3051')
				),
			value: 'None',
			causesReprice: true,
			choices: [
				{ name: 'None' },
				{ name: 'Side Channels' },
				{ name: 'Sill Channel' },
				{ name: 'Both' },
			],
			saveType: UI.AssemblyNum,
			getId: getAssemblyId,
			massEdit: true,
			description: (shade) => {
				const channelSelection = shade?.data().side_channels;

				const selectionToImage = (selection) => {
					selection = selection.toLowerCase();
					switch (selection) {
						case 'none':
							return InsideMount;
						case 'side channels':
							return SideChannels;
						case 'sill channel':
							return SillPlate;
						case 'both':
							return SideAndSill;
						default:
							return SideChannels;
					}
				};
				return (
					<>
						{channelSelection ? (
							<img src={selectionToImage(channelSelection)} />
						) : (
							<>Please select channels</>
						)}
					</>
				);
			},
		},

		{
			varName: 'side_channels_color',
			groupName: 'Options',
			label: 'Side Channels Color',
			skipReprice: true,
			type: 'select',
			conditional(shade) {
				const hembarType = shade?.val('hembar_type') ?? '';

				const noSideChannels = shade?.valMatch('side_channels', 'None', '');

				const sideChannels = shade?.val('side_channels');

				if (
					hembarType.includes('external') ||
					hembarType.includes('oval') ||
					noSideChannels ||
					!sideChannels
				) {
					return false;
				}

				return true;
			},
			choices: [{ name: 'White' }, { name: 'Black' }, { name: 'Anodized' }],
			saveType: UI.AssemblyNum,
			getId: getAssemblyId,
			massEdit: true,
		},

		{
			varName: 'manual_chain_color',
			label: 'Chain Color',
			type: 'select',
			skipReprice: true,
			groupName: 'Options',
			inlineGroup: true,
			conditional: (shade) =>
				shade.valMatch('shade_type', 'Manual') && (shade.valMatch('manual_chain', 'plastic') || shade.valMatch('manual_chain', 'metal')),
			choices: [
				{ name: 'Off-White', value: 'offwhite', conditional: isPlastic },
				{ name: 'Gray', value: 'gray', conditional: isPlastic },
				{ name: 'Brown', value: 'Brown', conditional: isPlastic },

				{ name: 'White', value: 'white', conditional: isPlasticOrMetal },
				{ name: 'Black', value: 'black', conditional: isPlasticOrMetal },
				
				{ name: 'Stainless Steel', value: 'stainless_steel', conditional: isMetal },
				//  Plastic Off-white and white, gray black and brown,
				// Stainless Steel, white, black
			],
			saveType: UI.ShadeNum,
			getId: getAllShadeIds,
			massEdit: true,
		},

		{
			varName: 'lv_power_source',
			label: 'How will you power this shade?',
			type: 'select',
			groupName: 'Charger',
			value: null,
			error: (shade) => {},
			choices: [
				// {name: 'Hardwired', value: 'hardwired'},
				{
					name: 'Charge w/ Wall Charger',
					value: 'wall_charger',
					onSelect: (quote, shade) => {
						if (!shade) return;
						shade?.changeAccessoryCharging(true, false);
					},
					onDeselect: (quote, shade) => {
						if (!shade) return;
						shade?.changeAccessoryCharging(false, false);
					},
				},
				{
					name: 'DC Whip',
					value: 'dc_whip',
					onSelect: (quote, shade) => {},
					onDeselect: (quote, shade) => {},
				},
			],
			causesReprice: true,
			conditional: (shade) =>
				shade?.valMatch('shade_type', 'Motorized') &&
				shade?.valMatch('motor_type', 'low_voltage'),
			saveType: UI.ShadeNum,
			getId: getAllShadeIds,
			massEdit: true,
		},

		{
			varName: 'floor',
			label: 'Floor',
			groupName: 'Attributes',
			type: 'int',
			minValue: -50,
			maxValue: 200,
			negative_prefix: 'B',
			nullable: () => false,
			saveType: UI.AssemblyNum,
			getId: getAssemblyId,
			skipReprice: true,
			massEdit: true,
		},

		{
			varName: 'direction_facing',
			label: 'Direction Facing',
			groupName: 'Attributes',
			type: 'select',
			choices: [
				{ name: 'Unknown' },
				{ name: 'North' },
				{ name: 'Northeast' },
				{ name: 'East' },
				{ name: 'Southeast' },
				{ name: 'South' },
				{ name: 'Southwest' },
				{ name: 'West' },
				{ name: 'Northwest' },
			],
			saveType: UI.AssemblyNum,
			getId: getAssemblyId,
			skipReprice: true,
			massEdit: true,
		},

		{
			varName: 'room_name',
			label: 'Room Name',
			groupName: 'Attributes',
			type: 'text',
			error: (shade) => {
				const room_name = shade?.val('room_name');

				if (room_name && room_name.toString().replace(' ', '') != '') {
					return false;
				}

				return 'Please enter a room name';
			},
			nullable: () => false,
			saveType: UI.AssemblyNum,
			skipReprice: true,
			getId: getAssemblyId,
		},

		{
			varName: 'shade_name',
			label: 'Shade Name',
			groupName: 'Attributes',
			type: 'text',
			error: (shade) => {
				const shade_name = shade?.val('shade_name');

				if (!(shade_name && shade_name.toString().replace(' ', '') != '')) {
					return 'Please enter a shade name';
				}

				if (!shade?.isNameUnique()) {
					return 'Please create a unique shade name.';
				}

				return false;
			},
			nullable: () => false,
			saveType: UI.AssemblyNum,
			getId: getAssemblyId,
			skipReprice: true,
		},
	];

	Items.forEach((i) => {
		i.ValueChanged = UI.ValueChanged;
	});

	Items.forEach((i) => {
		i.GetIds = function () {

		};
	});

	return Items;
}
