import { createSelector } from "reselect";
import { AppState, UsePortalSelector } from "../..";

const selectQuoteAssemblyCombinationUnsafe = (quoteId: number) => (state: AppState) => {
    return state.entity.quoteAssemblyCombinations[quoteId];
}

const selectQuoteAssemblyCombination = (quoteId: number) => createSelector(
    selectQuoteAssemblyCombinationUnsafe(quoteId),
    (multiplier) => {
        return multiplier
    }
);

const useQuoteCombination = (quoteId: number) => {
    return UsePortalSelector(selectQuoteAssemblyCombination(quoteId));
}

export {
    useQuoteCombination,
    selectQuoteAssemblyCombination
}