import React, { FC, useState } from "react";
import { toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import Popup from "reactjs-popup";

import apiCalls from "../../PowerShadesAPIFunctions";
import Button from "../../Parts/Button";
import { GetCreditLimitCheck } from "../../powershadesApiTypes";

type ApproveOrderButtonProps = {
	quoteId: number;
	quoteType: string;
};

const ApproveOrderButton: FC<ApproveOrderButtonProps> = ({ quoteId, quoteType }) => {
	const [approveOrderOpen, setApproveOrderOpen] = useState(false);
	const [approveOrderLoading, setApproveOrderLoading] = useState(false);

	const [creditLimitWarning, setCreditLimitWarning] = useState(false);

	// Initialize with defaults
	const [creditLimitData, setCreditLimitData] = useState<GetCreditLimitCheck>(() => ({
		quote_id: 0,
		current_outstanding_amount: 0,
		credit_limit: 0,
		customer_name: "",
		expected_invoice_total: 0,
		credit_check_passed: true,
		contact_url: ""
	}));

	const {
		credit_limit: creditLimit,
		customer_name: buyerName,
		expected_invoice_total: requestedInvoiceAmount,
		current_outstanding_amount: currenCredit,
		contact_url: contactUrl
	} = creditLimitData;

	const updatedCredit = currenCredit + requestedInvoiceAmount;
	const remainingCredit = creditLimit - updatedCredit;

	const handleApproveOrderButtonClicked = () => {
		let creditLimitOk = false;
		setApproveOrderLoading(true);


		apiCalls.getCreditLimit(quoteId).then((resp) => {
			const creditLimitDataResp = resp.data.credit_check;

			if (creditLimitDataResp.credit_check_passed) {
				creditLimitOk = true;
			}

			if (creditLimitOk) {
				setApproveOrderOpen(true);
			} else {
				setCreditLimitData(creditLimitDataResp);
				setCreditLimitWarning(true);
			}

			setApproveOrderLoading(false);
		});
	};

	const handleApproveOrder = () => {
		setApproveOrderLoading(true);
		apiCalls.approveOrder(quoteId).then((_response) => {
			setApproveOrderLoading(false);
			setApproveOrderOpen(false);

			if (_response.status === 200) {
				window.location.reload();
			} else {
				toast.error("Error approving order. Please Contact Engineering.");
			}
		});
	};

	const handleEmailTerritoryManager = () => {
		apiCalls.sendAccountingHoldEmail(quoteId).then((_response) => {
			if (_response.data.success) {
				toast.success("Email sent to Territory Manager");
			} else {
				toast.error("Error sending email. Please Contact Engineering.");
			}
		});
	};

	return (
		<>
			<Col xl="auto" lg={3} md={3} sm={4} xs={6} className="mt-2 mt-xl-0">
				<Button
					title="Export Outdoor Shades"
					color={quoteType}
					fluid
					onClick={handleApproveOrderButtonClicked}
					loading={approveOrderLoading}
				>
					Approve Order
				</Button>
			</Col>
			<Popup
				open={approveOrderOpen}
				onClose={() => setApproveOrderOpen(false)}
				modal
				className="mobile-modal"
			>
				<Row>
					<Col>
						<Row>
							<Col>
								<Row>
									<Col>
										<h3>Approve Order</h3>
									</Col>
								</Row>
								<Row>
									<Col>
										<p>
											Are you sure you want to approve this order?
										</p>
									</Col>
								</Row>
								<Row>
									<Col>
										<Button
											onClick={handleApproveOrder}
											loading={approveOrderLoading}
										>
											Approve Order
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Popup>
			<Popup
				open={creditLimitWarning}
				onClose={() => setCreditLimitWarning(false)}
				modal
				className="mobile-modal"
			>
				<Row>
					<Col>
						<Row>
							<Col>
								<h4>
									<a
										href={contactUrl}
									>

										{buyerName} -
										Credit Limit Verification
									</a>
								</h4>

							</Col>
						</Row>
						<Row>
							<Col>
								<h6>Account Credit Limit</h6>

							</Col>
							<Col>
								{creditLimit}
							</Col>
						</Row>
						<Row>
							<Col>
								<h6>Current Credit</h6>
							</Col>
							<Col>
								{currenCredit}
							</Col>
						</Row>
						<Row>
							<Col>
								<h6>Updated Credit</h6>
							</Col>
							<Col>
								{updatedCredit}
							</Col>
						</Row>
						<Row>
							<Col>
								<h6>Remaining Credit</h6>
							</Col>
							<Col>
								{remainingCredit}
							</Col>
						</Row>
						<Row>
							<Col>
								<Button
									color="gray"
									onClick={() => setCreditLimitWarning(false)}
								>
									Cancel
								</Button>
							</Col>
							<Col>
								<Button
									onClick={handleEmailTerritoryManager}
									color="gray">
									Email Territory Manager
								</Button>
							</Col>
							<Col >
								<Button>
									Confirm and Approve
								</Button>
							</Col>

						</Row>

					</Col>
				</Row>

			</Popup>

		</>
	);
};

export default ApproveOrderButton;

