import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import store from '../../../Store';
import { selectIsPsAdmin } from '../../../Store/user/userPermissions';
import { selectAccessories } from '../../../Store/entities/accessories/hooks';
import Button from '../../../Parts/Button';

class NewAccessoryForm extends Component {
	constructor(props) {
		super(props);
		const storeState = store.getState();

		const accessories = [...selectAccessories(props.newQuote.Id)(storeState)];
		this.state = {
			accessories,
			isPsAdmin: false,
		};

		this.numberChanged = this.numberChanged.bind(this);
	}

	componentDidMount() {
		const quoteId = this.props.newQuote.Id;
		this.unsubscribe = store.subscribe(() => {
			const storeState = store.getState();

			const isPsAdmin = selectIsPsAdmin(storeState);

			const accessories = [...selectAccessories(quoteId)(storeState)];

			const {
				isPsAdmin: isPsAdminState,
				accessories: accessoriesState
			} = this.state;

			if (isPsAdmin !== isPsAdminState
				|| accessories !== accessoriesState) {
				this.setState({ isPsAdmin });
				this.setState({ accessories: [...accessories] });
			}
		});
	}

	componentWillUnmount() {
		this.setState = () => { };
		this.unsubscribe && this.unsubscribe();
	}

	setAccessories(accessories) {
		this.setState({
			quoteAccessories: accessories,
		});
	}

	numberChanged(newVal, accessory) {
		const { setAccessoryQuantity } = this.props;
		let workingValue = newVal;

		if (newVal.toString() === parseInt(newVal, 10).toString()) {
			workingValue = Math.floor(newVal);
		} else {
			workingValue = 0;
		}

		// Check if the new value of a number input
		// is greater than the minimum value and less than the maximum value.
		// If not, bring it back into range.
		if (workingValue > 99999) {
			workingValue = 99999;
		} else if (workingValue < 0) {
			workingValue = 0;
		}

		const quoteAccessories = setAccessoryQuantity(accessory, workingValue);

		this.setAccessories(quoteAccessories);
	}

	render() {
		const {
			accessories, isPsAdmin,
		} = this.state;

		// Add Accessory

		const { quoteType, onDone } = this.props;

		const isAdmin = isPsAdmin;

		const rows = accessories?.sort((a, b) =>
			(a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : -1)
		)
			.filter((a) => a.is_active)
			.filter((a) => !a.is_internal_only || isAdmin)
			.map((acc) => (
				<tr key={acc.id}>
					<td>
						<label title={acc.name} htmlFor={acc.name}>
							{acc.name}
						</label>
					</td>
					<td>
						<Button
							onClick={() => {
								this.numberChanged(1, acc.part_number);
							}}
						>
							Add +
						</Button>
					</td>
				</tr>
			)) ?? [];

		return (
			<>
				<div id="accessories-holder">
					<h2 className={`text-${quoteType}`}>Add Accessories</h2>
					<table>
						<tbody>{rows}</tbody>
					</table>
				</div>
				<Container className="local-bootstrap">
					<Row className="mt-4 mb-2 justify-content-end">
						<Col xl={4} className="text-end">
							<Button fullWidth color={quoteType ?? 'green'} onClick={() => onDone()}>
								Done
							</Button>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default NewAccessoryForm;
