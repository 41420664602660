import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEngineWarning } from '@fortawesome/pro-solid-svg-icons';

import type { CustomQuoteIconProps } from './types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const CustomQuoteIcon = ({
	size = '1x', colorOverride = '', className = ''
}: CustomQuoteIconProps) => (
	<FontAwesomeIcon
		icon={faEngineWarning as IconProp}
		size={size}
		className={`${className} ${colorOverride && `text-${colorOverride}`}`}
	/>
);

export default CustomQuoteIcon;
