import { useState, useEffect } from 'react';

import api from '../PowerShadesAPI';
import MfgStatusSelect from '../Parts/MfgStatusSelect';
import QUOTE_GLOBALS from '../Quote/QuoteGlobals';
import { ShipmentModalRow, ShipmentModalHeadRow } from './ShipmentModalRows';
import Button from '../Parts/Button';

const AccessoryStatusRow = ({
	accessory, quote, onStatusChange
}) => {
	const [mfgStatus, setMfgStatus] = useState(accessory.mfg_status);
		  const [accessoryName, setAccessoryName] = useState(null);

	useEffect(() => {
		QUOTE_GLOBALS.onLoad(() => {
			const acc = QUOTE_GLOBALS.getAccessory(accessory.id);

			if (acc) {
				setAccessoryName(acc.name);
			}
		});
	}, []);

	function statusChanged(new_status) {
		setMfgStatus(new_status);
		onStatusChange(accessory, new_status);
	}

	return (

		<tr>
				
			<td>{ accessoryName }</td>
			<td>
				{
					accessory.quantity > 1
						? <b style={{ fontSize: '1.1em' }}>{ accessory.quantity }</b>
						:						accessory.quantity
				}
			</td>
			<td>
				<MfgStatusSelect
					initialStatus={mfgStatus}
					onChange={statusChanged}
				/>
			</td>
			
		</tr>
	);
};

export default function AccessoriesManufacturing(props) {
	const [quote, setQuote] = useState(props.quote);
		  const [markAllStatus, setMarkAllStatus] = useState('');
	
	const accessory_rows = quote.accessories.map((accessory) => (

		<AccessoryStatusRow
			key={accessory.id + accessory.mfg_status}
			quote={quote}
			accessory={accessory}
			onStatusChange={updateStatus}
		/>
	));

	function updateStatus(accessory, new_status) {
		accessory.mfg_status = new_status;

		api.setAccessoryMfgStatus(quote.ID, accessory.part_number, new_status);

		setQuote(props.quote);

		props.onUpdate && props.onUpdate();
	}

	function markAll(new_status) {
		if (new_status != '') {
			quote.accessories.forEach((acc) => updateStatus(acc, new_status));
			setMarkAllStatus(new_status);
		}
	}

	return (
			
		<div className="modal-holder">

			<div className="shipment-modal">
				
				<h1>Accessory Manufacturing</h1>
				<h2>
Order #
					{ quote.ID }
				</h2>

				<table className="order-confirmation-table" cellSpacing="0">
					<tbody>

						<ShipmentModalHeadRow quote={quote} />
						<ShipmentModalRow quote={quote} />

					</tbody>
				</table>

				<div style={{ marginTop: '0.5em', textAlign: 'right' }}>
					<MfgStatusSelect
						initialStatus={markAllStatus}
						emptyValueLabel="- Mark All -"
						onChange={markAll}
					/>
				</div>

				<table className="order-confirmation-table" cellSpacing="0">
					<tbody>
						
						<tr className="bold-row">
							<th>Accessory Name</th>
							<th>Quantity</th>
							<th>Mfg. Status</th>
						</tr>

						{ accessory_rows }
					</tbody>
				</table>

				<br />

				<div className="align-right">

					<Button color="green" onClick={props.onClose}>Done</Button>
					
				</div>

				<br />

			</div>

		</div>
	);
}
