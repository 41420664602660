import React, { FC, useEffect, useState } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import type { fakeRootType } from "../FakeRootType";
import EntityPageHeader from "../Parts/EntityPageHeader";
import { useDistributorById, useDistributorLocationById, useTerritories } from "../Store/entities/hooks";
import BillingInformationHandler from "../ManageEntities/BillingInformationHandler";
import { TabPaneSelections } from "../Parts/types";
import TabPaneWithSearchFilter from "../Parts/TabPaneWithSearchFilter";
import { initialDistributorLocationState, updateDistributorLocationFull } from "../Store/entities/organizations/distributorLocations";
import { UsePortalDispatch } from "../Store";
import AddTerritory from "../Modals/AddTerritory";

type DistributorLocationViewProps = {
	ROOT: fakeRootType;
}

const DistributorLocationView: FC<DistributorLocationViewProps> = (props) => {
	const {
		ROOT

	} = props;

	const {
		GET,
		navigate
	} = ROOT;

	const [editable, setEditing] = useState(false);
	const [activeSearch, setActiveSearch] = useState<string>('');
	const [activeKey, setActiveKey] = useState(0);
	const [showAddTerritoryModal, setShowAddTerritoryModal] = useState(false);

	const dispatch = UsePortalDispatch();

	const distributorLocationId = parseInt(GET.distributor_location_id ?? "0", 10) ?? 0;

	// TODO, MAKE A SELECTOR
	const selectedDistributorLocation = useDistributorLocationById(distributorLocationId);
	const [workingDistributorLocation, setWorkingDistributor] = useState(
		selectedDistributorLocation ?? initialDistributorLocationState()
	);
	const distributor = useDistributorById(selectedDistributorLocation?.distributor_id ?? 0);
	const distributorTerritories = distributor.territory_ids;

	const territories = useTerritories();

	const saveWorkingEntity = () => {
		dispatch(updateDistributorLocationFull(workingDistributorLocation));
	};

	const openAddTerritoryModal = () => {
		setShowAddTerritoryModal(true);
	};

	useEffect(() => {
		if (
			!editable
			&& distributorLocationId !== 0
			&& selectedDistributorLocation?.id !== 0
			&& selectedDistributorLocation
		) {
			setWorkingDistributor(selectedDistributorLocation);
		}
	}, [selectedDistributorLocation, editable]);

	if (!selectedDistributorLocation) {
		return (
			<>
				ope
			</>
		);
	}
	// Quotes, Dealers, Territories
	const loading = !selectedDistributorLocation;

	const getTabSelectionsEntity = (): TabPaneSelections[] => {
		const workingTabPanes: TabPaneSelections[] = [];

		const territoryTabPane: TabPaneSelections = {
			id: 0,
			title: 'Territories',
			key: 'territory_pane',
			filters: [],
			items: [],
		};

		workingDistributorLocation?.territory_ids?.forEach((territoryId) => {
			const territory = territories.find((wt) => wt.id === territoryId);
			if (territory !== undefined) {
				territoryTabPane.items.push({
					id: `${territory.id}`,
					type: "territory",
					name: territory.name,
					title: territory.name,
					onClick: () => {
						navigate(`territories?territory_id=${territory.id}`);
					},
					onDelete: () => {
						const newTerritories = workingDistributorLocation.territory_ids?.filter(
							(t) => t !== territory.id
						);
						setWorkingDistributor({
							...workingDistributorLocation,
							territory_ids: newTerritories,
						});
					},
				});
			}
		});

		if (editable) {
			territoryTabPane.items.push({
				id: '0',
				type: "territory",
				name: 'Add Territory +',
				title: 'Add Territory',
				onDelete: () => {
					/* Do Nothing */
				},
				onClick: () => {
					openAddTerritoryModal();
				},
			});
		}

		workingTabPanes.push(territoryTabPane);

		if (workingDistributorLocation.users) {
			const usersTabPane: TabPaneSelections = {
				id: 5,
				title: 'Users',
				key: 'user_pane',
				filters: ['all', 'admin', 'user'],
				items: [],
			};

			workingDistributorLocation.users.forEach((user) => {
				if (user === undefined) return;
				usersTabPane.items.push({
					id: `${user.id}`,
					type: "user",
					name: `${user.name}`,
					title: `${user.email}`,
					onClick: () => {
						/* Do Nothing */
					},
				});
			});

			workingTabPanes.push(usersTabPane);
		}

		const distributionEmailsTabPane: TabPaneSelections = {
			id: 6,
			title: 'Distribution Emails',
			key: 'distribution_emails_pane',
			filters: ['all', 'order placed access', 'invoice access'],
			items: [],
		};

		workingDistributorLocation.distribution_emails.forEach((distributionEmail) => {
			if (distributionEmail === undefined) return;

			const hasInvoiceAccess = distributionEmail.is_receiving_invoice_emails;
			const hasOrderPlacedAccess = distributionEmail.is_receiving_order_placed_emails;
			if (hasInvoiceAccess) {
				distributionEmailsTabPane.items.push({
					id: `inv-${distributionEmail.email}`,
					type: "distributionEmail",
					name: `${distributionEmail.email} - Invoice Email Access`,
					title: distributionEmail.email,
					onClick: () => {
						// editDistributionEmail(distributionEmail.email);
					},
					onDelete: () => {
						// deleteDistributionEmail(distributionEmail.email, 'invoice');
					},
				});
			}
			if (hasOrderPlacedAccess) {
				distributionEmailsTabPane.items.push({
					id: `ord-${distributionEmail.email}`,
					type: "distributionEmail",
					name: `${distributionEmail.email} - Order Placed Email Access`,
					title: distributionEmail.email,
					onClick: () => {
						// editDistributionEmail(distributionEmail.email);
					},
					onDelete: () => {
						// deleteDistributionEmail(distributionEmail.email, 'order');
					},
				});
			}

			if (!hasInvoiceAccess && !hasOrderPlacedAccess) {
				distributionEmailsTabPane.items.push({
					id: `non-${distributionEmail.email}`,
					type: "distributionEmail",
					name: `${distributionEmail.email} - No Email Access`,
					title: distributionEmail.email,
					onClick: () => {
						// editDistributionEmail(distributionEmail.email);
					},
					onDelete: () => {
						// deleteDistributionEmail(distributionEmail.email);
					},
				});
			}
		});

		workingTabPanes.push(distributionEmailsTabPane);

		return workingTabPanes;
	};

	const tabSelections = getTabSelectionsEntity();

	return (
		<Container className="local-bootstrap">
			<Card body className="entity-card">
				<Row>
					<Col>
						<span>
							<EntityPageHeader
								name={workingDistributorLocation.name ?? ""}
								email={workingDistributorLocation.email ?? ""}
								phone={workingDistributorLocation.phone ?? ""}
								loading={loading}
								editable={editable}
								setEditable={(editable) => setEditing(editable)}
								onChange={(goods) => {
									const {
										name,
										email,
										phone
									} = goods;

									setWorkingDistributor({
										...workingDistributorLocation,
										name,
										email: email ?? "",
										phone: phone ?? ""
									});
								}}
								onSave={() => {
									saveWorkingEntity();
									setEditing(false);
								}}
							/>

							<BillingInformationHandler
								disabled={!editable}
								address={workingDistributorLocation.billing_address}
								onChange={(address) => {
									setWorkingDistributor({
										...workingDistributorLocation,
										billing_address: { ...address }
									});
								}}
								loading={loading}
							/>

							<TabPaneWithSearchFilter
								key="distributor-view"
								disabled={!editable}
								loading={loading}
								selections={tabSelections}
								setActiveKey={setActiveKey}
								activeKey={activeKey}
								searchFilter={activeSearch}
								setSearchFilter={setActiveSearch}
								filterOptions={[]}
							/>

						</span>
					</Col>
				</Row>
				<AddTerritory
					key="addTerritoryDistributorLocationView"
					open={showAddTerritoryModal}
					selectedTerritoryIds={distributorTerritories}
					onClose={() => setShowAddTerritoryModal(false)}
					handleAdd={(territoryIds: number[]) => {
						const newTerritories = [
							...(workingDistributorLocation.territory_ids ?? []), ...territoryIds
						];
						setWorkingDistributor({
							...workingDistributorLocation,
							territory_ids: newTerritories,
						});
					}}
				/>
			</Card>
		</Container>

	);
};

export default DistributorLocationView;
