import QUOTE_GLOBALS from './QuoteGlobals';

export default function OutdoorShadeFormFields() {
	return [

		{
			varName: 'indoor_outdoor',
			value: 'outdoor',
		},

		{
			varName: 'single_dual',
			value: 'single',
		},
		{
			varName: 'shade_1_id'
		},
		{
			varName: 'shade_2_id'
		},
		{
			varName: 'roll_direction',
			value: 'standard',
		},
    
		{
			varName: 'fascia_color',
			value: null,
			nullable: () => true,
		},

		{
			varName: 'manual_chain',
			value: null,
			nullable: () => true,
		},

		{
			varName: 'manual_chain_color',
			value: null,
			nullable: () => true,
		},

		{
			varName: 'hembar_color',
			value: null,
			nullable: () => true,
		},
    
		{
			varName: 'shade_type',
			label: 'Shade Type',
			type: 'select',
			choices: [
				{ name: 'Manual', value: 'manual' },
				{ name: 'Motorized', value: 'motorized' }
			],
			causesReprice: true,
			groupName: 'shade_type_mount_type',
			inlineGroup: true,
			error: (shade) => {
				if (shade.val('width') > 192 && shade.valMatch('shade_type', 'manual')) {
					return 'Shades greater than 192" must be motorized.';
				}
			}
		},

		{
			varName: 'mount_type',
			label: 'Mount Type',
			type: 'select',
			choices: [
				{ name: 'Ceiling', value: 'outdoor_ceiling_mount' },
				{ name: 'Wall', value: 'outdoor_wall_mount' },
			],
			groupName: 'shade_type_mount_type',
			inlineGroup: true
		},
    
		{
			varName: 'width',
			label: 'Width',
			groupName: 'WH',
			inlineGroup: true,
			type: 'fraction',
			info: (shade) => (shade.valMatch('shade_type', 'Manual') && shade.val('width') && shade.val('width') < 18 ? 'Shades less than 18 inches wide are not upgradeable to motorized shades.' : null),
			minValue: 0,
			maxValue: 300,
			causesReprice: true,
        
		},

		{
			varName: 'height',
    	label: 'Height',
    	groupName: 'WH',
    	inlineGroup: true,
    	type: 'fraction',
    	info: (shade) => (shade.val('width') && shade.val('height') && shade.val('height') >= shade.val('width') * 3
    					? 'For Optimal Tracking, a width-to-length ratio of less than 3:1 is recommended.'
    					: null
    				  ),
    	minValue: 1,
    	causesReprice: true,
			error: (shade) => {
				if (shade.valMatch('shade_type', 'Manual') && shade.val('height') > 192) {
					return 'Manual shades must be 192" or less in length.';
				}

				if (shade.valMatch('shade_type', 'Motorized') && shade.val('height') > 240) {
					return 'Please contact PowerShades for shades greater than 240" in length.';
				}
			}
		},

		{
			varName: 'crank_length',
			label: 'Crank Length',
			groupName: 'WH',
			inlineGroup: true,
			type: 'fraction',
			conditional: (shade) => shade.valMatch('shade_type', 'Manual'),
			minValue: 0,
			causesReprice: false,
		},

		{
			varName: 'header_type',
    	label: 'Header Type',
    	groupName: 'Header & Frame',
			inlineGroup: true,
    	type: 'select',
    	error: (shade) => {
				if (shade.valMatch('header_type', 'outdoor_harmony_standard_round_front', 'outdoor_harmony_premium_square_front') && shade.val('width') >= 240) {
					return 'Harmony headers are not available for shades 240" or more in width.';
				}
			},
    	causesReprice: true,
    	choices: [
	    	{
					optGroup: 'Harmony', name: 'Standard Round Front', value: 'outdoor_harmony_standard_round_front'
				},
	        {
					optGroup: 'Harmony', name: 'Premium Square Front', value: 'outdoor_harmony_premium_square_front'
				},
				{
					optGroup: 'Apex', name: 'Apex Cassette', value: 'outdoor_apex'
				},
				{
					optGroup: 'Meridian', name: 'Meridian Open Roll', value: 'outdoor_meridian'
				}
			],
			value: null
		},

		{
			varName: 'bracket_color',
			label: 'Bracket/Frame Color',
			groupName: 'Header & Frame',
			inlineGroup: true,
			type: 'select',
			choices: [
	        { name: 'Anthracite', value: 'outdoor_anthracite' },
				{ name: 'Beach Sand', value: 'outdoor_beach_sand' },
				{ name: 'Carbon', value: 'outdoor_carbon' },
				{ name: 'Corten', value: 'outdoor_corten' },
				{ name: 'Dune', value: 'outdoor_dune' },
				{ name: 'Glacier', value: 'outdoor_glacier' },
				{ name: 'Granite', value: 'outdoor_granite' },
				{ name: 'Urban Bronze', value: 'outdoor_urban_bronze' },
				{ name: 'White Quartz', value: 'outdoor_white_quartz' },
			]
		},

		{
			varName: 'control_side',
			label: 'Control Side',
			type: 'select',
			choices: [
				{ name: 'Right' },
				{ name: 'Left' }
			],
			groupName: 'control_side',
			inlineGroup: true,
		},

		{
			value: null,
			varName: 'fabric_name',
			label: 'Fabric',
			causesReprice: true,
			fullWidth: true,
			groupName: 'Fabric',
			type: 'fabricSelect',
			error: (shade) => {},
			choices: () => QUOTE_GLOBALS.OUTDOOR_FABRICS,
		},

		{
			varName: 'hembar_type',
    	groupName: 'Options',
    	label: 'Hembar Type',
    	type: 'select',
    	choices: [
				{ name: 'Heavy Duty Bottom Bar', value: 'outdoor_heavy_duty_bottom_bar' },
    	]
		},
      
		{
			varName: 'side_channels',
    	groupName: 'Options',
    	label: 'Side Channels',
    	type: 'select',
			causesReprice: true,
    	choices: [
	    	{ name: 'Lightning Track', value: 'outdoor_lightning_track' },
	    	{ name: 'Cable - Wall Mount', value: 'outdoor_wall_mount_cable' },
	    	{ name: 'Cable - Floor Mount', value: 'outdoor_floor_mount_cable' },
    	]
		},
            
		{
			varName: 'direction_facing',
    	label: 'Direction Facing',
    	groupName: 'Attributes',
    	type: 'select',
    	nullable: () => true,
    	choices: [
				{ name: 'Unknown' },
    		{ name: 'North' },
    		{ name: 'Northeast' },
    		{ name: 'East' },
    		{ name: 'Southeast' },
    		{ name: 'South' },
    		{ name: 'Southwest' },
    		{ name: 'West' },
    		{ name: 'Northwest' }
    	]
		},

		{
			varName: 'floor',
			label: 'Floor',
			groupName: 'Attributes',
			type: 'int',
			minValue: -50,
			maxValue: 200,
			negative_prefix: 'B'
		},

		{
			varName: 'room_name',
			label: 'Area Name',
			groupName: 'Attributes',
			type: 'text',
		},

		{
			varName: 'shade_name',
    	label: 'Shade Name',
    	groupName: 'Attributes',
    	type: 'text',
    	error: (shade) => (shade.isNameUnique() ? null : 'Please create a unique shade name.'),
		}

	];
}
