import { useState, useEffect } from 'react';

import api from '../PowerShadesAPI';
import Styles from '../Styles';
import Button from '../Parts/Button';

const ManufacturerSelect = (props) => {
	const styles = {

		h2: {
			marginBottom: '15px',
			textAlign: 'center',
			color: Styles.psGreen
		},

		h3: {
			marginTop: '0',
			textAlign: 'center'
		},

		modal: {
			width: '23em'
		},

		selectHolder: {
			textAlign: 'center',
			marginTop: '2em'
		}
	};

	const [mfgs, setMfgs] = useState([]);
	const [selectedMfg, setSelectedMfg] = useState('');

	const mfg_options = [<option key="mfg-select" value="">- Select Manufacturer -</option>];
	
	useEffect(() => {
		api.getManufacturers()
			.then((resp) => setMfgs(resp.data.manufacturers));
	}, []);

	function getMfgNameById(id) {
		const mfg = mfgs.find((m) => parseInt(m.id, 10) === parseInt(id, 10));
		
		return mfg ? mfg.name : null;
	}

	mfgs?.sort((m1, m2) => m1.name.localeCompare(m2.name))
		.forEach((mfg) =>
			mfg_options.push(
				<option key={mfg.id} value={mfg.id}>
					{mfg.name}
				</option>
			));

	return (

		<div className="confirmation-modal-holder">
				
			<div className="order-approval-modal" style={styles.modal}>
					
				<h2 style={styles.h2}>Select Manufacturer</h2>

				<div style={styles.selectHolder}>
					<select value={selectedMfg} style={{ background: 'white', pointerEvents: 'all' }} onChange={(e) => setSelectedMfg(e.target.value)}>
						{mfg_options}
					</select>
				</div>

				<div className="button-holder">
					<Button color="gray" onClick={props.onCancel}>Cancel</Button>
					<Button color="green" disabled={(`${selectedMfg}`).length < 1} onClick={() => props.onSubmit && props.onSubmit(selectedMfg, getMfgNameById(selectedMfg))}>Submit</Button>
				</div>

			</div>
		</div>

	);
};

export default ManufacturerSelect;
