import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as Sentry from "@sentry/react";
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Router, RouterNavigateOptions, To } from '@remix-run/router';
import { isEqual } from 'lodash';

import FakeRoot from './FakeRoot';
import CreateQuoteTerritoryModal from './Modals/CreateQuoteTerritoryModal';
import AlertModal from './Parts/AlertModal';
// import ConnectionStatus from './Parts/ConnectionStatus';
import Header from './Parts/Header/Header';
import PortalRoutes from './PortalRoutes';
import apiCalls from './PowerShadesAPIFunctions';
import store, { UsePortalDispatch } from './Store';
import { closeCreateQuote, openCreateQuote } from './Store/ui';
import UserTimer from './userTimer';
import SpinningWheel from './Parts/SpinningWheel';
import { useQuoteTypes } from './Store/entities/quotes/hooks';
import { useTerritories } from './Store/entities/hooks';
import { useCreateQuoteOpen, usePageLoading } from './Store/ui/hooks';
import { useUser, useUserTheme } from './Store/user/hooks';
import { initialHydration } from './Store/user';
import { AlertProps } from './FakeRootType';
// import { useStatePortalState } from './Store/hooks';
import QuoteWelcome from './Quote/QuoteWelcome';
import { wentToAdmin } from './Store/entities/quotes';
// import UnderConstruction from './UnderConstruction/Content';

const Layout = () => {
	const dispatch = UsePortalDispatch();

	const theme = useUserTheme();

	useEffect(() => {
		document.documentElement.setAttribute('data-bs-theme', theme);
	}, [theme]);

	const isCreateQuoteOpen = useCreateQuoteOpen();
	const isPageLoading = usePageLoading();
	const quoteTypes = useQuoteTypes();
	const territories = useTerritories();
	const user = useUser();

	const windowDoc = window.document;

	const [title, setTitle] = useState(windowDoc.title);

	const defaultAlertOptions = useMemo(() => ({
		title: '',
		text: '',
		noCancelButton: false,
		acceptButtonText: '',
		cancelButtonText: '',
		onAccept: () => { /* Empty Default Function */ },
		onCancel: () => { /* Empty Default Function */ },
		quoteType: 'cedia',
		icon: '',
		smallIcon: true,
		closeOnDocumentClick: false,
		oneButton: false,
		quickFormat: '',
		active: false,
	}), []);

	useEffect(() => {
		user.authenticated === true &&
			dispatch(initialHydration());
	}, [dispatch, user.authenticated]);
	
	const [alertOptions, setAlertOptions] = useState<AlertProps & { active: boolean }>(
		defaultAlertOptions
	);

	const handleTitleChange = useCallback((workingTitle: string) => {
		const currentTitle = windowDoc.title;
		if (currentTitle === workingTitle) return;
		windowDoc.title = workingTitle;
		setTitle(workingTitle);

	}, [setTitle, windowDoc]);
	
	const alertAccept = React.useRef<() => void>(() => { /* Empty Default Function */ });
	const alertCancel = React.useRef<() => void>(() => { /* Empty Default Function */ });

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const fakeRoot = useMemo(() => FakeRoot(store), [store]);

	const { withAlert } = fakeRoot;

	const linkStoreToAlert = useCallback(() => {
		withAlert((props: AlertProps) => new Promise((resolve, reject) => {
			if (isEqual(props, defaultAlertOptions)) return;
			setAlertOptions({ ...props, active: true });
			alertAccept.current = () => {
				props.onAccept && props.onAccept();
				setAlertOptions({ ...defaultAlertOptions });
				resolve();
			};
			alertCancel.current = () => {
				props.onCancel && props.onCancel();
				setAlertOptions({ ...defaultAlertOptions });
				reject();
			};
		}));
	}, [defaultAlertOptions, withAlert]);
 
	useEffect(() => {
		linkStoreToAlert();
	}, [linkStoreToAlert]);

	const state = store.getState;
	const router = useMemo(() => createHashRouter(PortalRoutes(fakeRoot, state), {}), [fakeRoot, state]);

	useEffect(() => {
		const userTimer = new UserTimer(dispatch, state, (router as unknown) as Router );
		userTimer.start();

		return () => userTimer.stop();
	}, [dispatch, state, router]);

	const closeCreateQuoteModal = () => dispatch(closeCreateQuote());

	const navigate = useCallback((path: string) => {
		router.navigate(path);
	}, [router]);

	useEffect(() => {
		fakeRoot.withNavigate(navigate as (to: To, opts?: RouterNavigateOptions | undefined) => void);
		fakeRoot.withSetTitle(handleTitleChange as (workingTitle: string) => void);
	}, [navigate, handleTitleChange, fakeRoot]);

	/* This sets the ROOT object to window.ROOT in order to support legacy code
	  It has been quarantined with an IIFE */
	(() => {
		const anyWindow = window as any;

		anyWindow.ROOT = fakeRoot;
	})();

	const createQuote = (callback: () => void) => {
		const currentTerritories = territories;

		if (!currentTerritories) {
			fakeRoot.alert({
				quickFormat: 'error',
				text: 'Something went wrong loading your territories. Please try again.',
			});
			callback && typeof callback === 'function' && callback();
			return;
		}
		if (currentTerritories.length > 1) {
			callback && typeof callback === 'function' && callback();
			dispatch(openCreateQuote());
			return;
		}

		if (quoteTypes) {
			const cediaQuoteType = quoteTypes.find((qt) => qt.name === 'CEDIA');

			apiCalls.createQuote(currentTerritories[0]?.id ?? 0, cediaQuoteType?.id ?? 5).then((resp) => {
				const { success } = resp.data;

				if (success) {
					const quoteId = resp.data.quote_id;
					fakeRoot.loadContent(`#Quote?quoteID=${quoteId}&initial=${true}`);
					if (callback && typeof callback === 'function') {
						callback();
					}
				} else {
					// Alert Them
				}
			});
		}
	};

	fakeRoot.withCreateQuote(createQuote);

	const [showWelcome, setShowWelcome] = useState(false);

	useEffect(() => {
		if (user.authenticated && localStorage.getItem('hasSeenWelcome') !== 'true') {
			setShowWelcome(true);
		}
	}, [user.authenticated]);

	const handleCloseWelcome = () => {
		setShowWelcome(false);
		localStorage.setItem('hasSeenWelcome', 'true');
	};

	useEffect(() => {
		dispatch(wentToAdmin());
	}, [title])

	useEffect(() => {
		if (!user.authenticated) {
			localStorage.removeItem('hasSeenWelcome');
		}
	}, [user.authenticated]);

	return (
		<div
			className={`${isPageLoading ? 'loading' : ''
			} ${`${process.env.ENV}`.toLowerCase()}-env`}
		>
			<ToastContainer position="bottom-right" />
			<div className="no-print">
				<div id="header-wrapper">
					<Header
						ROOT={fakeRoot}
						title={title}
					/>
				</div>

				<QuoteWelcome open={showWelcome} onClose={handleCloseWelcome} />

				{isCreateQuoteOpen ? (
					<CreateQuoteTerritoryModal
						territories={territories}
						quoteTypes={quoteTypes}
						loadContent={fakeRoot.loadContent}
						onClose={closeCreateQuoteModal}
					/>
				) : null}

				{/* {process.env.API_ROOT.match(/(dev|test)/) ? <ConnectionStatus /> : null} */}
			</div>
			{isPageLoading ? (
				<div className="local-bootstrap">
					<div className="w-100">
						<div className="text-center" style={{ marginTop: '15%' }}>
							<SpinningWheel size="5x" />
						</div>
					</div>
				</div>
			) : null}
			<div className="main-content">
				{user.loading 
				? <div /> 
				: (
					// (!(user.email?.includes("powershades.com")) && (user.email?.includes("@")))  ? <UnderConstruction/> : 
					// Commenting this out so people can use the Portal.
				<RouterProvider router={router} />
				)}
			</div>

			{alertOptions.active ? (
				<AlertModal
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...alertOptions}
					onCancel={() => alertCancel.current()}
					onAccept={() => alertAccept.current()}
				/>
			) : null}
		</div>
	);
};

Layout.whyDidYouRender = {
	logOnDifferentValues: true,
	trackAllPureComponents: true,
};

export default Sentry.withProfiler(Layout);
