import React from 'react';

class UnChangeableCell
	extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<td className={this.props.className ?? ""}>{this.props.value}</td>
		);
	}
}

export default UnChangeableCell;
