import { useState, useEffect, Fragment } from 'react';
import HomeRow from '../Parts/HomeRow';
import Styles from '../Styles';

const Notifications = (props) => {
	const [notifications, setNotifications] = useState([]);

	useEffect(() => {
		props.setTitle('Notifications');

		api.getAllNotifications().then(({ data }) => {
			setNotifications(data.notifications);

			props.loaded();
		});
	}, []);

	function linkClicked(link) {
		if (`${link}`.length < 1) {
			// notta
		} else if (link[0] === '#') {
			window.open(`${process.env.ROOT_URL}/${link}`, '_tab');
		} else {
			window.open(link);
		}
	}

	const elements = notifications.map((n) => {
		const date = new Date(n.timestamp * 1000);
		const hours_str = date.getHours() > 12 ? date.getHours() % 12 : date.getHours();
		const am_pm_str = date.getHours() > 12 ? 'pm' : 'am';
		const date_str = `${
			date.getMonth() + 1
		}/${date.getDate()}/${date.getFullYear()} ${hours_str}:${date.getMinutes()} ${am_pm_str}`;

		return (
			<Fragment key={n.id}>
				<div
					className={`notification-entry ${
						n.link.length & (n.link != '#Notifications') ? 'has-link' : ''
					}`}
					title={n.link}
					onClick={() => linkClicked(n.link)}
				>
					<span
						style={{
							display: 'inline-block',
							marginBottom: '0.5em',
							color: 'black',
						}}
					>
						<b>{n.title}</b>
					</span>
					<br />

					<span>{n.description}</span>

					<span
						style={{
							position: 'absolute',
							top: '2px',
							right: '2px',
							color: 'black',
						}}
					>
						{date_str}
					</span>
				</div>
				<hr
					style={{
						border: 'none',
						height: '1px',
						backgroundColor: Styles.psGreen,
						marginTop: '1em',
						transform: 'translateY(1px)',
					}}
				/>
			</Fragment>
		);
	});

	return (
		<HomeRow>
			<div
				style={{
					margin: 'auto',
					fontSize: '13px',
					backgroundColor: 'white',
					marginTop: '3em',
					width: '40em',
					borderRadius: '3px',
					border: '1px solid gray',
					padding: '3em',
				}}
			>
				<h2
					style={{
						margin: 0,
						marginBottom: '1em',
						textAlign: 'center',
						fontSize: '1.75em',
					}}
				>
					Notifications
				</h2>
				{elements}
			</div>
		</HomeRow>
	);
};

export default Notifications;
