import React, { useEffect, useRef } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import type { NewQuoteProps } from "./types";
import QuoteNavigation from "./Navigation";
import { useQuoteIdAndDispatch } from "../Store/entities/quotes/hooks";
import QuoteHeader from "./Header";

import { initialQuote } from "../Store/entities/quotes";
import { QuotePrefixById, QuoteTypeNamesById } from "../powershadesApiTypeExtensions";
import {
	useSetHeaderInjection, useSetViewingNewQuoteAsPsAdmin, useSetViewingQuoteId, useViewingNewQuoteAsPsAdmin
} from "../Store/ui/hooks";
import Button from "../Parts/Button";

import "./NewQuote.scss";
import QuoteTabPane from "./TabPane";
import HomeRow from "../Parts/HomeRow";

const NewQuote = ({ ROOT }: NewQuoteProps) => {
	const { setTitle } = ROOT;
	const navigate = useNavigate();
	const navigatingToOldPage = useRef(false);

	const [urlParams] = useSearchParams();
	const viewingAsPsAdmin = useViewingNewQuoteAsPsAdmin();
	const setViewingAsPsAdmin = useSetViewingNewQuoteAsPsAdmin();
	const setHeaderInjection = useSetHeaderInjection();
	const setViewingQuoteId = useSetViewingQuoteId();
	const quoteIdFromParams = urlParams.get("quoteID");
	const quoteId = quoteIdFromParams ? parseInt(quoteIdFromParams, 10) : 0;

	const quote = useQuoteIdAndDispatch(quoteId) ?? initialQuote(quoteId);
	
	const quotePrefix = QuotePrefixById[quote.quote_type_id ?? 5];
	const quoteType = QuoteTypeNamesById[quote.quote_type_id ?? 5]?.toLowerCase() ?? "cedia";

	const handleOldQuoteView = () => {
		navigatingToOldPage.current = true;
		setHeaderInjection(
			<Row className="justify-content-end">
				<Col xs={12} className="mb-2 mt-2">
					<Button
						fullWidth
						style={{ height: "100%", marginTop: "0.25em" }}
						onClick={() => {
							navigatingToOldPage.current = false;
							navigate(`/NewQuote/Details?quoteID=${quoteId}`);
						}}
					>
						New Quote View (Beta)
					</Button>
				</Col>
			</Row>
		);
		navigate(`/Quote?quoteID=${quoteId}`);
	};

	useEffect(() => {
		if (navigatingToOldPage.current === true) return;
		if (!quoteId) return;
		setHeaderInjection(
			<Row>
				<Col xs={12} sm={6} className="mt-2">
					<Button
						fullWidth
						radioButton
						onClick={() => {
							setViewingAsPsAdmin(!viewingAsPsAdmin);
						}}
						color={viewingAsPsAdmin ? "green" : "gray"}
					>
						View as PSAdmin&nbsp;
						<FontAwesomeIcon icon={viewingAsPsAdmin ? faCheck : faTimes} />
					</Button>
				</Col>
				<Col xs={12} sm={6} className="mt-2">
					<Button
						radioButton
						fullWidth
						onClick={() => handleOldQuoteView()}
					>
						Old Quote View
					</Button>
				</Col>
			</Row>
		);
		return () => {
			if (navigatingToOldPage.current) return;
			setHeaderInjection(null);
		};
	}, [quoteId, handleOldQuoteView, setHeaderInjection, setViewingAsPsAdmin, viewingAsPsAdmin]);

	useEffect(() => {
		quoteId
			? setTitle(`New Quote (Beta) - ${quotePrefix}${quote.id}`)
			: setTitle("New Quote (Beta)");
		setViewingQuoteId(quote.id);
	}, [quoteId, quote.id, quotePrefix, setTitle, setViewingQuoteId]);

	// if (quote.loadStatus <= 3) return <div>Loading...</div>;

	if (!quoteId) return (
		<HomeRow>
			<div className="four-zero-four-card">
				<h1>No Quote Found</h1>
				<span>
					Please enter a quote # in the field above.
					<br/>
					Select the checkbox to view the quote in the new quote view.
				</span>
			</div>
		</HomeRow>
	);

	return (
		<>
			<QuoteTabPane quoteTypeId={quote.quote_type_id} />
			<Container fluid className={`quote-${quoteType}`}>
				<Card className="new-quote-card">
					<Card.Body>
						<QuoteHeader
							isPsAdmin={viewingAsPsAdmin}
							quoteId={quote.id}
							quoteTypeId={quote.quote_type_id}
							quoteTitle={quote.title}
							quoteReference={quote.reference_number}
							quoteDealerId={quote.dealer_id}
							quoteTerritoryId={quote.territory_id}
						/>
					</Card.Body>
					<hr className="m-0" />
					<Card.Body>
						<Row>
							<QuoteNavigation isPsAdmin={viewingAsPsAdmin} quoteTypeId={quote.quote_type_id} />
							<Col>
								<Outlet />
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</Container>
		</>
	);
};

export default NewQuote;
