import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import PowerShadesTypeahead from "../Parts/PowerShadesTypeahead";
import { updateAssembly } from "../Store/entities/assemblies";
import { useCurrentPSOutdoorShadeOptions } from "../Store/entities/assemblies/PSOutdoorHooks";
import { PSOutdoorHeaderTypeSku } from "../Store/entities/assemblies/types";
import FractionField from "./NewOptionFields/FractionField";
import QuoteAssemblyLockSelect from "./QuoteAssemblyLockSelect";
import { UseAssembly } from "../Store/entities/assemblies/hooks";
import { UsePortalDispatch } from "../Store";
import FabricSelectField from "./NewOptionFields/FabricSelectField";
import { useQuoteById } from "../Store/entities/quotes/hooks";
import ShadeActions from "./NewOptionFields/ShadeActions";
import QuoteData from "./NewItems/QuoteData";
import TextField from "./NewOptionFields/TextField";
import PricingUI from "../PricingUI";
import { useErrorsBySeqAndQuoteId } from "../Store/entities/assemblies/errors/hooks";

import { FabricViewModel} from "../powershadesApiTypes";
import apiCalls from "../PowerShadesAPIFunctions";

type PSOutdoorTableRowProps = {
	quoteId: number;
	sequenceId: number;
	unlockShadeBySequenceId: (sequenceId: number) => void;
	quoteData: QuoteData;
};

const PSOutdoorTableRow = ({ quoteId, sequenceId, unlockShadeBySequenceId, quoteData }: PSOutdoorTableRowProps) => {
	const assembly = UseAssembly(quoteId, sequenceId);
	const psOutdoorOptions = useCurrentPSOutdoorShadeOptions(quoteId)(sequenceId);

	const errors = useErrorsBySeqAndQuoteId(quoteId, sequenceId);

	const { editable } = useQuoteById(quoteId) ?? { editable: false };
	const {
		Width,
		Height,
		Fabric,
		HeaderType,
		RoomName,
		ShadeName
	} = psOutdoorOptions;

	const dispatch = UsePortalDispatch();

	if (!assembly) return null;

	const setNewHeight = (newHeight) => {
		dispatch(updateAssembly({
			quoteId,
			sequenceId: assembly.sequence_id,
			assembly: {
				...assembly,
				shades: assembly.shades.map((s) => ({
					...s,
					height: newHeight
				}))
			}
		}));
	};

	const setNewWidth = (newWidth) => {
		dispatch(updateAssembly({
			quoteId,
			sequenceId: assembly.sequence_id,
			assembly: {
				...assembly,
				shades: assembly.shades.map((s) => ({
					...s,
					width: newWidth
				}))
			}
		}));
	};

	const setNewShadeName = (newShadeName) => {
		dispatch(updateAssembly({
			quoteId,
			sequenceId: assembly.sequence_id,
			assembly: {
				...assembly,
				shade_name: newShadeName
			}
		}));
	};

	const setNewRoomName = (newRoomName) => {
		dispatch(updateAssembly({
			quoteId,
			sequenceId: assembly.sequence_id,
			assembly: {
				...assembly,
				room_name: newRoomName
			}
		}));
	};
	
	const assemblyErrorMessages = `${assembly.error_messages}${errors.reduce((acc, error) => `${acc} ${error.message}`, "")}`;

	const isErrored = (errors.length > 0) || (assembly.error_messages !== null && assembly.error_messages?.match(/^ *$/) === null && assembly.msrp === 0);
	const errored = `${isErrored ? "errored" : ""}`;

	const [allowedPsOutdoorFabrics, setAllowedPsOutdoorFabrics] = useState<FabricViewModel[]>([]);


	// Fetch allowed PsOutdoor fabrics from the backend
	useEffect(() => {
		const fetchAllowedPsOutdoorFabrics = async () => {
			try {
				const response = await apiCalls.getFabricPsOutdoorAllowedList(quoteId);
				setAllowedPsOutdoorFabrics(response.data.fabrics); // Store the fetched fabrics in state
			} catch (error) {
				console.error("Error fetching allowed PsOutdoor fabrics:", error);
			}
		};
   
		fetchAllowedPsOutdoorFabrics(); 
	   }, [quoteId]);
   

			// Combine fetched allowed fabrics with the PsOutdoor fabrics
			// Mapping to FabricStore when is FabricViewModel
			const combinedPsOutdoorFabrics = [
				...Fabric.options ?? [],
				...allowedPsOutdoorFabrics.map(fabric => ({
					isActive: true,
					label: fabric.name,
					title: fabric.name,
					value: fabric.name
				}))
			].filter((fabric, index, self) =>
				index === self.findIndex(f => f.value === fabric.value) // Filtra duplicados por el campo "value"
			);




	return (
		<tr className={errored} key={assembly.sequence_id}>
			<td>
				<QuoteAssemblyLockSelect
					quoteId={quoteId}
					sequenceId={sequenceId}
					unlockShadeBySequenceId={(seqId) => unlockShadeBySequenceId(seqId)}
				/>
			</td>
			<td>
				<TextField
					disabled={!editable || !assembly.unlocked}
					value={RoomName.getValue() ?? ""}
					onBlur={setNewRoomName}
					handleEnter={setNewRoomName}
				/>
			</td>
			<td>
				<TextField
					disabled={!editable || !assembly.unlocked}
					value={ShadeName.getValue() ?? ""}
					onBlur={setNewShadeName}
					handleEnter={setNewShadeName}
				/>
			</td>
			<td>
				<PowerShadesTypeahead
					disabled={!editable || !assembly.unlocked}
					id="header-type"
					options={HeaderType.options ?? []}
					selected={[HeaderType.getSelectedOption()]}
					positionFixed
					onChange={(selected) => {
						if (selected.length === 0) {
							HeaderType.clearValue();
						}
						const selectedObj = selected && selected[0] ? selected[0] as {
							value: PSOutdoorHeaderTypeSku;
						} : { value: "" as PSOutdoorHeaderTypeSku };
						dispatch(updateAssembly({
							quoteId,
							sequenceId: assembly.sequence_id,
							assembly: {
								...assembly,
								header_line: selectedObj.value
							}
						}));
					}}
					className="text-center"
				/>
			</td>
			<td>
				<FabricSelectField
					className="-cell"
					style={{ width: "15em", minWidth: "100%" }}
					disabled={!editable || !assembly.unlocked}
					value={assembly.shades[0]?.fabric_name ?? ""}
					// choices={Fabric.options}
					choices={combinedPsOutdoorFabrics}
					canceled={Fabric.clearValue}
					onChange={(newFabricName) => {
						dispatch(updateAssembly({
							quoteId,
							sequenceId: assembly.sequence_id,
							assembly: {
								...assembly,
								shades: assembly.shades.map((s) => ({
									...s,
									fabric_name: newFabricName
								}))
							}
						}));
					}}
					onBlur={() => {
						console.log("blur");
					}}
				/>
			</td>
			<td>
				<FractionField
					key={`width-${sequenceId}`}
					disabled={!editable || !assembly.unlocked}
					value={assembly?.shades[0]?.width ?? 0.0}
					onBlur={setNewWidth}
					minValue={Width.minValue ?? 0.0}
					maxValue={Width.maxValue ?? 0.0}
					varName="width"
					width={10}
				/>
			</td>
			<td>
				<FractionField
					key={`height-${sequenceId}`}
					disabled={!editable || !assembly.unlocked}
					value={assembly?.shades[0]?.height ?? 0.0}
					onBlur={setNewHeight}
					minValue={Height.minValue ?? 0.0}
					maxValue={Height.maxValue ?? 0.0}
					varName="height"
					width={10}
				/>
			</td>
			<td>
				{
					isErrored ? (
						<Popup
							trigger={<span>Error</span>}
							position="right center"
							on="hover"
						>
							<div>
								<h6>{assemblyErrorMessages}</h6>
							</div>
						</Popup>
					) : <PricingUI msrp={assembly.msrp} quoteId={quoteId} assembly={assembly} />
				}
                
			</td>
			<td className="  right-most-cell">
				<ShadeActions
					quoteId={quoteId}
					sequenceId={assembly.sequence_id}
					quoteData={quoteData}
				/>
			</td>

		</tr>);
};

export default PSOutdoorTableRow;
