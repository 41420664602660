import { createSelector } from "reselect";
import type { AppState } from "../index";
import { createDeepEqualSelector } from "../../psUtil";

/** 
 * Selects the current user data (non-memoized).
 * @param state - The application state.
 * @remark This is not memoized.
 * @returns The current user data.
 */
const selectCurrentUserDataUnsafe = (state: AppState) => state.user;

/**
 * Selects the current user data.
 * @returns The current user data.
 * @remarks This is memoized.
 * @returns The current user data.
 */
export const selectCurrentUserData = createDeepEqualSelector(selectCurrentUserDataUnsafe, (user) => user);

export const selectCurrentUserName = createSelector([
	selectCurrentUserData
], (user) => user.name);

export const selectHasRoles = (...roles: string[]) => createSelector([
	(state: AppState) => selectCurrentUserData(state),
], (userData) => userData?.roles?.some((r1) => roles.some((r2) => r2 == r1.role_name)) ?? false);

const selectHasRoles2 = (...roles: string[]) =>
	(state: AppState) =>
		state.user?.roles?.some((r1) => roles.some((r2) => r2 == r1.role_name)) ?? false;

export const selectIsPsAdmin = selectHasRoles("powershades_admin");

export const selectIsRepAdmin = selectHasRoles("rep_admin");
export const selectIsRepUser = selectHasRoles("rep_user");

export const selectIsRepresentative = (state: AppState) => selectIsRepAdmin(state) || selectIsRepUser(state);

export const selectIsDistributorAdmin = selectHasRoles("distributor_admin");
export const selectIsDistributorUser = selectHasRoles("distributor_user");

export const selectIsDistributor = (state: AppState) => selectIsDistributorAdmin(state) || selectIsDistributorUser(state);

export const selectIsDealerAdmin = selectHasRoles("dealer_admin");
export const selectIsDealerUser = selectHasRoles("dealer_user");
export const selectIsDealerPurchasing = selectHasRoles("dealer_purchasing");

export const selectIsDealer = (state: AppState) => selectIsDealerAdmin(state) || selectIsDealerUser(state) || selectIsDealerPurchasing(state);

export const selectIsManufacturerAdmin = selectHasRoles("manufacturer_admin");
export const selectIsManufacturerUser = selectHasRoles("manufacturer_user");

export const selectIsAdmin = (state: AppState) => selectIsPsAdmin(state) || selectIsManufacturerAdmin(state) || selectIsDealerAdmin(state) || selectIsDistributorAdmin(state) || selectIsRepAdmin(state);
export const selectIsSalesperson = selectHasRoles2("dealer_salesperson");
export const selectIsPurchasing = selectHasRoles2("dealer_purchasing");

export const selectIsEngineer = (state: AppState) => selectCurrentUserData(state).is_engineering;
export const selectIsManufacturer = (state: AppState) => selectCurrentUserData(state).is_manufacturing;
export const selectIsSalesPerson = (state: AppState) => selectCurrentUserData(state).is_sales_person;
export const selectIsService = (state: AppState) => selectCurrentUserData(state).is_service;
export const selectIsDisallowedForceEdit = (state: AppState) => selectCurrentUserData(state).is_disallowed_force_edit;

export const selectIsLoggedIn = (state: AppState) => (selectCurrentUserData(state)?.name ?? "") !== "";
