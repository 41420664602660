import { useState, useEffect, useRef } from 'react';

export default function QuantityInput(props) {
	const [value, setValue] = useState(props.initialValue);
	const ref = useRef(null);

	function blurred() {
		props.onChange && props.onChange(parseInt(value));
	}

	function causeFocus() {
		// This is a workaround for browsers like firefox that don't focus properly
		// the input field when a user clicks on the native toggle up or down buttons for numbers.
		if (ref.current) {
			ref.current.focus();
		}
	}

	function changed(e) {
		let int_val = parseInt(e.target.value);
		const min_val = props.minValue != null ? parseInt(props.minValue) : 0;

		if (`${e.target.value}` === `${int_val}`) {
			``;
			if (int_val < min_val) {
				int_val = min_val;
        
				props.onChange && props.onChange(int_val);

				setValue(int_val);
				return;
			}
		}

		setValue(e.target.value);
	}

	return (

		<input
			ref={ref}
			disabled={props.disabled}
			className={props.className}
			type="number"
			onChange={changed}
			onBlur={blurred}
			onMouseDown={causeFocus}
			onKeyDown={causeFocus}
			value={value}
		/>
	);
}
