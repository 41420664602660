import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import Chip from './Chip';

import { useDealerMap, useTerritoriesMap } from '../../Store/entities/hooks';
import { useFilterSettings } from '../../Store/ui/hooks';

import type { ChipContainerProps, ChipTypes } from './types';

import styles from './ChipContainer.module.scss';
import { useQuoteTypesMap, useSetFilterSettings } from '../../Store/entities/quotes/hooks';
import { QuoteTypeIds } from '../../powershadesApiTypes';
import Button from '../../Parts/Button';

const ChipContainer = ({
	selectedTerritoryIds,
	selectedDealerIds,
	selectedQuoteTypeIds,
}: ChipContainerProps) => {
	const filterSettings = useFilterSettings();
	const setFilterSettings = useSetFilterSettings();

	const dealerMap = useDealerMap();
	const quoteTypeMap = useQuoteTypesMap();
	const territoriesMap = useTerritoriesMap();

	const formattedSelectedDealers = selectedDealerIds.map((id) => ({
		id: id,
		label: dealerMap.get(id) ?? '',
	}));

	const formattedSelectedQuoteTypes = selectedQuoteTypeIds.map((id) => ({
		id: id,
		label: quoteTypeMap.get(id as QuoteTypeIds) ?? '',
	}));

	const formattedTerritories = selectedTerritoryIds.map((id) => ({
		id: id,
		label: territoriesMap.get(id) ?? '',
	}));

	const removeChip = (id: number, type: ChipTypes) => {
		const currentFilterSettings = filterSettings[type];
		const newSettings = currentFilterSettings.filter((filterId) => filterId !== id);
		setFilterSettings({ ...filterSettings, [type]: newSettings });
	};

	const removeAllChips = (type: ChipTypes) => {
		setFilterSettings({ ...filterSettings, [type]: [] });
	};

	const clearFilters = () => {
		setFilterSettings({
			...filterSettings,
			territory_ids: [],
			dealer_ids: [],
			quote_type_ids: [],
		});
	}

	const nonEmptyArrayCount = [
		selectedTerritoryIds,
		selectedDealerIds,
		selectedQuoteTypeIds,
	].filter((arr) => arr.length > 0).length;
	const multipleContainersShowing = nonEmptyArrayCount >= 2;

	return (
		<Row className={`mt-2 ${styles.chipContainer}`}>
			<Col
				className={`territory-chip-container ${
					selectedTerritoryIds.length <= 0 && `d-none`
				}`}
			>
				<Row className={`text-muted ${styles.chipHeader}`}>
					<Col>
						Selected Territories
						<FontAwesomeIcon
							className={styles?.chipRemover ?? ''}
							icon={faXmark}
							onClick={() => removeAllChips('territory_ids')}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						{formattedTerritories.map((territory) => (
							<Chip
								className={styles?.chip ?? ''}
								key={territory.id}
								id={territory.id}
								label={territory.label}
								deleteButton
								handleDelete={() => removeChip(territory.id, 'territory_ids')}
							/>
						))}
					</Col>
				</Row>
			</Col>
			<Col className={`dealer-chip-container ${selectedDealerIds.length <= 0 && `d-none`}`}>
				<Row className={`text-muted ${styles.chipHeader}`}>
					<Col>
						Selected Dealers
						<FontAwesomeIcon
							className={styles?.chipRemover ?? ''}
							icon={faXmark}
							onClick={() => removeAllChips('dealer_ids')}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						{formattedSelectedDealers.map((dealer) => (
							<Chip
								className={styles?.chip ?? ''}
								key={dealer.id}
								id={dealer.id}
								label={dealer.label}
								deleteButton
								handleDelete={() => removeChip(dealer.id, 'dealer_ids')}
							/>
						))}
					</Col>
				</Row>
			</Col>
			<Col
				className={`quote-type-chip-container ${
					selectedQuoteTypeIds.length <= 0 && `d-none`
				}`}
			>
				<Row className={`text-muted ${styles.chipHeader}`}>
					<Col>
						Selected Quote Types
						<FontAwesomeIcon
							className={styles?.chipRemover ?? ''}
							icon={faXmark}
							onClick={() => removeAllChips('quote_type_ids')}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						{formattedSelectedQuoteTypes.map((quoteType) => (
							<Chip
								className={styles?.chip ?? ''}
								key={quoteType.id}
								id={quoteType.id}
								label={quoteType.label}
								deleteButton
								handleDelete={() => removeChip(quoteType.id, 'quote_type_ids')}
							/>
						))}
					</Col>
				</Row>
			</Col>
			<Col xs={2} className={`clear-all-container ${!multipleContainersShowing && `d-none`}`}>
				<Row>
					<Col className="text-end">
						<Button
							size="sm"
							color="light"
							hoverColor="red"
							onClick={() => clearFilters()}
						>
							Clear All Filters
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default ChipContainer;
