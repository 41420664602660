import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import TextInput from './TextInput';

const SearchBar = (props) => (
	<div
		style={{
			display: props.noFlex ? 'unset' : 'flex',
			flexAlign: props.bootstrap ? '' : 'center',
			alignSelf: props.bootstrap ? '' : 'center',
			boxShadow: props.noboxshadow ? '' : '0 0 0 1px gray',
			borderRadius: '3px',
		}}
	>
		{!props.noicon ? (
			<FontAwesomeIcon
				icon={faSearch}
				className={`text-${props.quoteType}`}
				style={{
					marginTop: '0.1em',
					padding: '0.25em 0.45em',
					fontSize: '0.8em',
					lineHeight: '1.3em',
					borderRadius: '3px',
				}}
			/>
		) : null}
		<TextInput
			{...props}
			style={{ ...{ boxShadow: 'none' }, ...props.style }}
			placeholder={props.placeholder || 'Search'}
			bootstrap={props.bootstrap}
		/>
	</div>
);

export default SearchBar;
