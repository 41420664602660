import React, { useEffect, useState } from "react";
import { debounce, upperFirst } from "lodash";
import { Col, Form, Row } from "react-bootstrap";

import Button from "./Button";
import TabPaneInner from "./TabPaneInner";

import type { TabPaneSelections, TabPaneWithSearchFilterProps } from "./types";

const TabPaneWithSearchFilter = ({
	setCurrentFilter,
	disabled,
	loading,
	currentFilter,
	filterOptions,
	setSearchFilter,
	setActiveKey,
	searchFilter,
	selections,
	activeKey,
}: TabPaneWithSearchFilterProps) => {
	const [localInput, setLocalInput] = useState("");
	const activeSelection = selections[activeKey] ?? { key: "empty", filters: [] };

	const amountOfFilters = filterOptions?.length ?? 0;
	const xsButtonSize = Math.floor(24 / amountOfFilters);
	const smButtonSize = Math.floor(12 / amountOfFilters);

	const filterButtons =
		filterOptions?.map((option) => (
			<Col xs={xsButtonSize} sm={smButtonSize} className="mb-1 mb-sm-0" key={option}>
				<Button
					disabled={activeSelection.key === "all"}
					fluid
					fullWidth
					onClick={() => {
						if (currentFilter !== option) {
							setCurrentFilter && setCurrentFilter(option);
							setSearchFilter("");
						}
					}}
					color={option === currentFilter || (currentFilter === "" && option === "all") ? "green" : "gray"}
				>
					{upperFirst(option)}
				</Button>
			</Col>
		)) ?? [];

	const debouncedSearch = debounce((value) => {
		setSearchFilter(value.trim());
	}, 500);

	useEffect(() => {
		debouncedSearch(localInput);
	}, [localInput]);

	const filterSelections = (workingSearchFilter: string, selectionsToFilter: TabPaneSelections[]) => {
		const lowerCaseSearchFilter = workingSearchFilter.toLowerCase();

		const filteredSelections = selectionsToFilter.filter((selection) => {
			const titleMatch = selection.title.toLowerCase().includes(lowerCaseSearchFilter);
			const itemsMatch = selection.items.some((item) => item.name?.toLowerCase().includes(lowerCaseSearchFilter));
			const emailsMatch = selection.items.some((item) =>
				item.title?.toLowerCase().includes(lowerCaseSearchFilter)
			);

			return titleMatch || itemsMatch || emailsMatch;
		});

		return filteredSelections;
	};

	return (
		<Row className="entity-container">
			<Col xs={12}>
				<Row className="mb-2 pe-0">
					{filterButtons.length > 0 && (
						<Col xs={12} lg={7} md={7} className="mb-2">
							<Row className="g-1">{filterButtons}</Row>
						</Col>
					)}
					<Col xs={12} lg={filterButtons.length > 0 ? 5 : 12} md={filterButtons.length > 0 ? 5 : 12}>
						<Form.Control
							disabled={loading}
							id="tab-pane-search-filter"
							className="form-control"
							placeholder="Search..."
							onChange={(e) => setLocalInput(e.target.value)}
							value={localInput}
						/>
					</Col>
				</Row>
				<Row>
					<TabPaneInner
						selections={filterSelections(searchFilter, selections)}
						activeKey={activeKey}
						setActiveKey={setActiveKey}
						loading={loading}
						disabled={disabled}
					/>
				</Row>
			</Col>
		</Row>
	);
};

export default TabPaneWithSearchFilter;
