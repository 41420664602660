const style = {

	maxWidth: '95vw',
	width: '130em',
	margin: 'auto',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
};

export default function HomeRow(props) {
	return (

		<div className="home-row" style={{ ...style, ...props.style }}>
			{ props.children }
		</div>
	);
}
