/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-constant-condition */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';

// import { toast } from 'react-toastify';
import Money from '../Parts/Money';

import { useAssembliesByQuoteId } from '../Store/entities/assemblies/hooks';
import { useQuoteAccessories } from '../Store/entities/accessories/hooks';
import { useHWRItemsByQuoteId } from '../Store/entities/hardwareRequestItems/hooks';
import { UseMultipliersByQuoteId, useQuoteById } from '../Store/entities/quotes/hooks';
import { QuotePricingShadeAssemblyVM, QuotePricingVM } from '../powershadesApiTypes';
import apiCalls from '../PowerShadesAPIFunctions';
// import TaxJustification from '../Modals/TaxJustification/TaxJustification';
// import { useDealers } from '../Store/entities/hooks';
// import { UsePortalDispatch } from '../Store';
// import { updateDealerTaxInfo } from '../Store/entities/organizations/dealers';

// TODO: Delete unused values

/* eslint-disable camelcase */
type TotalPriceProps = {
	quoteId: number;
	className: string;
};

const TotalPrice = ({
	quoteId,
	className
}: TotalPriceProps) => {
	const assemblies = useAssembliesByQuoteId(quoteId);
	const qas = useQuoteAccessories(quoteId);
	const hwrItems = useHWRItemsByQuoteId(quoteId);
	const shadeCount = assemblies.length;

	const mutlipliers = UseMultipliersByQuoteId(quoteId);
	const show_my_price = mutlipliers?.list?.find((m) => m.name === mutlipliers?.currentMultiplier) ?? {
		name: "MSRP",
		multiplier: 1,
		label: "MSRP",
		currency: "",
		currency_multiplier: 1,
		useCoupons: false
	};
	const quote = useQuoteById(quoteId);
	// const dealers = useDealers();
	// const currentDealer = dealers.find((d) => d.id === quote.dealer_id);
	// const [taxModalOpen, setTaxModalOpen] = useState(false);
	// const [taxEditLoading, setTaxEditLoading] = useState(false);
	// const dispatch = UsePortalDispatch();

	// const saveTaxDetails = async (newDealer) => {
	// 	setTaxEditLoading(true);
	// 	try {
	// 		const updateResponse = await dispatch(updateDealerTaxInfo(newDealer));
	// 		if (updateDealerTaxInfo.fulfilled.match(updateResponse)) {
	// 			toast.success('Tax details updated successfully');
	// 			setTaxEditLoading(false);
	// 			return updateResponse.payload;
	// 		}
	// 		if (updateDealerTaxInfo.rejected.match(updateResponse)) {
	// 			toast.error(updateResponse.payload
	// 				?? 'Something went wrong trying to save the dealer and no error message was provided');
	// 			setTaxEditLoading(false);
	// 			return currentDealer;
	// 		}
	// 		return currentDealer;
	// 	} catch (e) {
	// 		toast.error("Tax Details Update Failed! PowerShades engineering has been notified");
	// 		setTaxEditLoading(false);
	// 		return currentDealer;
	// 	}
	// };

	// const territory = useTerritoryById(quote.territory_id ?? 0)

	const [localQuoteTotals, setLocalQuoteTotals] = useState<QuotePricingVM>();

	useEffect(() => {
		apiCalls.getQuotePricing(quoteId).then((response) => {
			response?.data?.pricing && setLocalQuoteTotals(response.data.pricing);
		});
	}, [quote]);

	const hasError = assemblies.some((a) => (a.msrp ?? 0) < 0.1)
	|| qas.some((a) => (a.msrp ?? 0) < 0.1)
	|| hwrItems.some((a) => (a.msrp ?? 0) < 0.1);

	const PriceRow = ({ children, no_hr = false }) => (

		<>
			<span
				style={{
					textAlign: 'right', display: 'inline-block', width: '100%'
				}}
			>
				{children}
			</span>
			{no_hr ? null : <hr />}
		</>
	);

	const {
		multiplier,
		currency_multiplier,
		currency
		
	} = show_my_price;

	/*
type QuotePricingVM = {
	subtotal: number;
	oversized_shipping: number;
	parcel_shipping: number;
	tax_rate: number;
	total_taxes: number;
	is_shipping_taxed: boolean;
	assemblies: QuotePricingShadeAssemblyVM[];
	accessories: QuotePricingAccessoryVM[];
	hardware_request_items: QuotePricingAccessoryVM[];
}
	*/

	const {
		subtotal: subtotalUnclean = 0,
		oversized_shipping: overSizedShipping = 0,
		parcel_shipping: parcelShipping = 0,
		tax_rate: taxRate = 0,
		total_taxes: taxesUnclean = 0,
		accessories: quoteAccessoryPricings = [],
		hardware_request_items: hardwareItemPricings = [],
		assemblies: assemblyPricings = [],
		coupon_total: couponTotal = 0,

	} = localQuoteTotals ?? {
		subtotal: 0,
		oversized_shipping: 0,
		parcel_shipping: 0,
		tax_rate: 0,
		total_taxes: 0,
		is_shipping_taxed: false,
		assemblies: [],
		accessories: [],
		hardware_request_items: [],
		coupon_total: 0
	};

	const isOutdoor = (shade: QuotePricingShadeAssemblyVM) => {
		const assy = assemblies.find((a) => a.sequence_id === shade.sequence_id);
		return assy?.shade_type_id === 2 || assy?.shade_type_id === 3;
	};

	const isIndoor = (shade: QuotePricingShadeAssemblyVM) => !isOutdoor(shade);

	const subtotal = (
		subtotalUnclean * multiplier * currency_multiplier
	) + (
		(parcelShipping + overSizedShipping) * currency_multiplier
	);
	const taxes = taxesUnclean * multiplier * currency_multiplier;

	const accessoryShipping = (
		quoteAccessoryPricings?.reduce((acc, accPricing) => acc + accPricing.shipping, 0) ?? 0
	) * currency_multiplier;
	const indoorShadeShipping = (
		assemblyPricings?.filter(isIndoor).reduce((acc, accPricing) => acc + accPricing.shipping, 0) ?? 0
	) * currency_multiplier;
	const outdoorShadeShipping = (
		assemblyPricings?.filter(isOutdoor).reduce((acc, accPricing) => acc + accPricing.shipping, 0) ?? 0
	) * currency_multiplier;
	const hardwarePartsShipping = (
		hardwareItemPricings?.reduce((acc, accPricing) => {
			const hwItem = hwrItems.find((hwi) => hwi.line_number === accPricing.line_number);
			return acc + (accPricing.shipping * (hwItem?.quantity ?? 0));
		}, 0) ?? 0) * currency_multiplier;

	const shadePrice = (
		assemblyPricings?.reduce((acc, accPricing) => acc + accPricing.msrp, 0) ?? 0
	) * multiplier * currency_multiplier;
	const hardwareParts = (hardwareItemPricings?.reduce((acc, accPricing) => {
		const hwItem = hwrItems.find((hwi) => hwi.line_number === accPricing.line_number);
		return acc + (accPricing.msrp * (hwItem?.quantity ?? 0));
	}, 0) ?? 0) * multiplier * currency_multiplier;

	const accessoryDisplay = (
		quoteAccessoryPricings?.reduce((acc, accPricing) => acc + accPricing.msrp, 0) ?? 0
	) * multiplier * currency_multiplier;

	const hasOutdoorShade = assemblyPricings.some(isOutdoor);
	const outdoorOutsideContinental = false;

	const preCoupon = subtotal + taxes;
	const total = preCoupon - couponTotal;

	if (hasError) {
		return (
			<div style={{ textAlign: 'right' }}>
				Please correct all shade errors.
			</div>
		);
	}

	const shipping = indoorShadeShipping + accessoryShipping + outdoorShadeShipping + hardwarePartsShipping;

	const shippingTitle = `Shipping Total`;

	const taxDisplay = [];
	// let index = 0;
	// if (taxMessage) {
	// 	taxMessage.split('<br>').forEach((element) => {
	// 		index++;
	// 		taxDisplay.push(<Fragment key={`k-${index}`}>{element}</Fragment>);
	// 		index++;
	// 		taxDisplay.push(<br key={`k-${index}`} />);
	// 	});
	// }

	const symbol = "$";
	const taxMessage = "";

	return (

		<>
			<div key="01" className={className} style={{ width: '100%' }}>

				{accessoryDisplay > 0

					? (
						<PriceRow key="PR1">
							Accessories:
							{' '}
							<Money symbol={symbol}>{accessoryDisplay}</Money>
						</PriceRow>
					)
					: null}

				{false

					? (
						<PriceRow key="PR2">
							Additional Motors:
							{' '}
							{/* <Money symbol={symbol}>{total_additional_motors_price}</Money> */}
						</PriceRow>
					)
					: null}

				{shadePrice > 0

					? (
						<PriceRow key="PR3">
							{shadeCount}
							{' '}
							{shadeCount === 1 ? 'Shade' : 'Shades'}
							:
							{' '}
							<Money symbol={symbol}>{shadePrice}</Money>
						</PriceRow>
					)
					: null}
				{hardwareParts > 0

					? (
						<PriceRow key="HW">
							Hardware Parts
							{' '}
							<Money symbol={symbol}>{hardwareParts}</Money>
						</PriceRow>
					)
					: null}

				{shipping > 0

					? (
						<PriceRow key="PR4">
							{shippingTitle}
							:
							<Money symbol={symbol}>{shipping}</Money>
						</PriceRow>
					)
					: null}

				{overSizedShipping > 0

					? (
						<PriceRow key="PR5">
							**Oversized Freight:
							{' '}
							<Money symbol={symbol}>{overSizedShipping * currency_multiplier}</Money>
						</PriceRow>
					)
					: null}

				{outdoorOutsideContinental && hasOutdoorShade
					? (
						<PriceRow key="PR6">
							*Outdoor Shades Freight:
						</PriceRow>
					)
					: null}

				{taxes > 0
					? (
						<>
							<PriceRow key="PR7">
								Subtotal:
								{' '}
								<Money symbol={symbol}>{subtotal}</Money>
							</PriceRow>
							<PriceRow key="PR8">
								{/* {
									currentDealer
										&& <PriceRow>
											<TaxJustification
												org={currentDealer}
												orgType='Dealer'
												onSave={saveTaxDetails}
												open={taxModalOpen}
												setOpen={setTaxModalOpen}
												loading={taxEditLoading}
											/>
										</PriceRow>
								} */}
								***Taxes:
								{' '}
								<Money symbol={symbol}>{taxes}</Money>
							</PriceRow>
						</>
					)
					: null}

				{ (couponTotal ?? 0) > 0
					? (
						<>
							<PriceRow key="PR7">
								Before Coupon:
								{' '}
								<Money symbol={symbol}>{preCoupon}</Money>
							</PriceRow>
							<PriceRow key="PR8">
								Coupon: -
								<Money symbol={symbol}>{couponTotal}</Money>
							</PriceRow>
						</>
					)
					: null}

				<PriceRow no_hr key="PR9">
					{`(${currency})`}
					{' '}
					Total:
					<Money symbol={symbol}>{total || null}</Money>
				</PriceRow>

			</div>
			<div key="02" style={{ width: '100%' }}>
				{
					outdoorOutsideContinental && hasOutdoorShade

						? (
							<div key="PN1">
								<span style={{ fontSize: '13px', marginTop: '1em' }}>
									* Shipping charges for outdoor shades will be invoiced
									<br />
&nbsp;&nbsp;separately for orders outside the continental U.S.
									<br />
									<br />
								</span>
							</div>
						)
						: null
				}

				{
					overSizedShipping > 0
						? (
							<div key="PN2">
								<span
									style={{
										fontSize: '13px',
										display: 'inline-block',
										marginTop: '1em',
										alignContent: 'right'
									}}
								>
									**All freight prices are standard Ground shipping.
									<br />
									<br />
									Requested Expedited shipping or additional services
									{' '}
									<br />
									(Lift-gate, Residential Delivery, White glove delivery, etc…)
									{' '}
									<br />
									will be charged actual invoice, and will be charged
									{' '}
									<br />
									before shipment if not selected during the
									{' '}
									<br />
									{' '}
									"Place Order" order process..
									<br />
									<br />
									If you suspect Freight Damage, REFUSE the shipment.
									{' '}
									<br />
									The product will be returned to PowerShades
									{' '}
									<br />
									{' '}
									for expedited repair or replacement.
									<br />
									<br />
									Freight Damage must be reported within 10 days of
									{' '}
									<br />
									receipt of goods. Contact Customer service immediately,
									<br />
									{' '}
									and retain all packaging.
									{' '}
									<br />
									The product will be returned to PowerShades for expedited
									<br />
									{' '}
									repair or replacement.
									<br />
									<br />
								</span>
							</div>
						)
						: null
				}
				{
					taxes
						? taxMessage ? (
							<div key="1k">
								<span
									style={{
										fontSize: '13px',
										display: 'inline-block',
										marginTop: '1em',
										alignContent: 'right'
									}}
								>
									***
									{taxDisplay}
								</span>
							</div>
						)
							: (
								<div key="2k">
									<span
										style={{
											fontSize: '13px',
											display: 'inline-block',
											marginTop: '1em',
											alignContent: 'right'
										}}
									>
										***
										{`This quote was taxed at ${taxRate * 100}%. `}
									</span>
								</div>
							) : null
				}

			</div>
		</>
	);
};

export default TotalPrice;
