
import { createSelector } from "@reduxjs/toolkit";
import { loadQuoteFabricSamples } from ".";
import store, { AppDispatch, AppState, UsePortalSelector } from "../..";
import { QuoteFabricSampleStore } from "../../../powershadesApiTypes";
import { loadStatus as loadStatusEnum} from "../entitiesType";
import { RefreshQuotePricing } from "../quotes";

const selectQuoteFabricSamplesByQuoteId = (QuoteId: number) => (state: AppState) => {
    const assyList = state.entity.quoteFabricSamples[QuoteId] ?? {
        loadStatus: loadStatusEnum.needsLoaded,
        Items: {}
    };
    const {loadStatus, ...assyListTwo} = assyList ?? {};

    if( loadStatus !== loadStatusEnum.loading && loadStatus !== loadStatusEnum.fullyLoaded) {
        // I don't know why the type system is not catching this as a AppDispatch
        const dispatch = store.dispatch as AppDispatch;
        dispatch(loadQuoteFabricSamples(QuoteId)).then(() => {
            dispatch(RefreshQuotePricing({quoteId: QuoteId}));
        });
        return {} as Record<number,QuoteFabricSampleStore>;
    }

    return assyListTwo.Items as Record<number,QuoteFabricSampleStore>;
} 

const selectQuoteFabricSampleListByQuoteId = (quoteId: number) =>createSelector(selectQuoteFabricSamplesByQuoteId(quoteId), (assyList) => {
    return Object.values(assyList).sort((a, b) => (a.Name ?? "").localeCompare(b.Name ?? ""));
});

const useQuoteFabricSamplesByQuoteId = (QuoteId: number) => UsePortalSelector(selectQuoteFabricSampleListByQuoteId(QuoteId));

export {
    useQuoteFabricSamplesByQuoteId,
    selectQuoteFabricSampleListByQuoteId
};