import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck } from '@fortawesome/free-solid-svg-icons';

import type { ShippedIconProps } from './types';
import { faPowerShades } from './powershades-icon';

const ShippedIcon = ({
	size = '1x',
	bottomColorOverride = '',
	topColorOverride = 'green',
	className = '',
}: ShippedIconProps) => (
	<span className={`fa-stack ${className}`}>
		<FontAwesomeIcon
			icon={faTruck}
			size={size}
			className={`fa-stack-2x ${bottomColorOverride && `text-${bottomColorOverride}`}`}
		/>
		<FontAwesomeIcon
			icon={faPowerShades}
			size={size}
			className={`fa-stack-1x stacked-logo pe-3 pb-2 text-${topColorOverride}`}
		/>
	</span>
);

export default ShippedIcon;
