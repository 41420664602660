import React from "react";
import PricingUI from "../PricingUI";
import { AssemblyStore } from "../Store/entities/assemblies/types";

type PriceErrorsProps = {
	error_messages: string;
	msrp: number;
	quoteId: number;
	assembly: AssemblyStore;
	noLoading?: boolean;
};

const PriceErrors = ({ error_messages, msrp, quoteId, assembly, noLoading = false }: PriceErrorsProps) => {
	if ((msrp ?? 0) === 0) {
		return (
			<div className="price-error text-center">
				<h6 className=" text-danger">{error_messages ?? "Something went wrong"}</h6>
			</div>
		);
	}
	return (
		<div className="price-success text-center">
			<h4 title="">
				<PricingUI
					quoteId={quoteId}
					msrp={msrp}
					assembly={assembly}
					noLoading={noLoading}
				/>
			</h4>
		</div>
	);
};

export default PriceErrors;
