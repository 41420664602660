import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

import { useDistributorLoadStatus, useDistributors } from '../Store/entities/hooks';

import type { DistributorSelectProps } from './types';
import { DistributorStore } from '../powershadesApiTypes';

const DistributorSelect = ({
	optionsOverride,
	handleChange,
	disableOverride = false,
}: DistributorSelectProps) => {
	const distributors = useDistributors();
	const distributorsLoadingStatus = useDistributorLoadStatus();
	const distributorsLoading = distributorsLoadingStatus <= 3;

	const shouldBeDisabled = distributorsLoading || disableOverride;
	return (
		<Typeahead
			id="dist-selector"
			paginate
			clearButton
			positionFixed
			labelKey="name"
			placeholder={
				distributorsLoading ? 'Loading Distributors...' : 'Select a Distributor...'
			}
			options={optionsOverride ?? distributors}
			isLoading={distributorsLoading}
			disabled={shouldBeDisabled}
			onChange={(selected) => {
				if (!selected) return;
				const distributor = selected as unknown as DistributorStore;
				handleChange(distributor[0]?.id ?? 0);
			}}
		/>
	);
};

export default DistributorSelect;
