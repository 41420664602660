import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { Container, Col, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { trim } from 'lodash';

import Button from '../../Parts/Button';
import { useAssembliesByQuoteId } from '../../Store/entities/assemblies/hooks';
import { useQuoteById, useQuoteTypeNameByQuoteId } from '../../Store/entities/quotes/hooks';
import { useQuoteCombination } from '../../Store/entities/combinations/hooks';
import QuoteObject from './QuoteObject';
import { UsePortalDispatch, UsePortalSelector } from '../../Store';
import { combineFascia, deleteCombinedFascia, updateCombinedFascia } from '../../Store/entities/combinations';
import { useAssemblyOptions } from '../../Store/entities/assemblyOptions/hooks';

type AdvancedShadeManipulationProps = {
	newQuote: QuoteObject;
}

const AdvancedShadeManipulation = (props: AdvancedShadeManipulationProps) => {
	const [open, setOpen] = useState(false);
	const [errors, setErrors] = useState("");
	const [editingStatus, setEditingStatus] = useState(0);
	const [currentStateString, setCurrentStateString] = useState("");

	const {  newQuote} = props;

	const quoteId = newQuote.Id;

	const quote = useQuoteById(quoteId);

	const quoteType = useQuoteTypeNameByQuoteId(quoteId);

	const shades = useAssembliesByQuoteId(quoteId);
	const selectedShades = shades.filter((a) => a.selected);

	const selectedCount = selectedShades.length;

	const dispatch = UsePortalDispatch();

	const quoteCombinations = useQuoteCombination(quoteId) ?? { Fascias: [] };
	const fasciaCombinations = quoteCombinations?.Fascias ?? [];

	const [initialFcsLoaded, setInitialFcsLoaded] = useState(false);

	useEffect(
		() => {
			if (!initialFcsLoaded) {
				setInitialFcsLoaded(true);
			}
		}
		, [fasciaCombinations]
	);

	// Length based on Fascia Type
	// RC3080 (3 inch) 228
	// RC3080 (4 inch) 228
	// RC4039 (5 inch) 228
	// RS-SK (7 inch) 196
	// Two inches less each for the bits cut off on both sides.
	const assemblyOptions = UsePortalSelector( useAssemblyOptions(quoteId));

	const getMaxExtrusionLength = (headerName: string) => {
		const fasciaItem = assemblyOptions.headers?.find((h) => h.name == headerName);

		return fasciaItem?.max_extrusion_width ?? 0;
	}

	const fasciaTypesAllowedToBeCombined = [
		"Fascia 4 Inch",
		"Fascia 3 Inch",
		"Fascia 5 Inch",
		"Fascia 7 Inch"
	]

	const fasciaItems = fasciaTypesAllowedToBeCombined.map((ft) => {
		return {
			sku: ft,
			maxLength: getMaxExtrusionLength(ft),
			name: ft
		};
	});

	const oneFascia = () => {
		setErrors('');

		if (selectedCount < 1) {
			// Error Message
			setErrors('Please Select a shade');

			return;
		}

		const headerTypes = selectedShades.map((s) => s.header_line);

		const headerType = headerTypes.find(() => true) ?? "";

		const allSameHeaderType = (function (headerTypes, headerType) {
			return !headerTypes.some((h) => h != headerType);
		})(headerTypes, headerType);

		if (!allSameHeaderType) {
			// Error Message
			setErrors('All shades must have the same header type and color.');

			return;
		}

		// Differing rooms

		const roomNames = selectedShades.map((s) => s.room_name);

		const allSameRoom = (function (roomNames) {
			const roomName = roomNames.find(() => true);

			return !roomNames.some((h) => trim(h) != trim(roomName));
		})(roomNames);

		if (!allSameRoom) {
			setErrors('All shades must be in the same room.');

			// Error Message
			return;
		}

		const mountTypes = selectedShades.map((s) => s.mount_style);

		const allSameMountTypes = (function (mountTypes) {
			const mountType = mountTypes.find(() => true);

			return !mountTypes.some((h) => h != mountType);
		})(mountTypes);

		if (!allSameMountTypes) {
			setErrors('All shades must have the same mount type.');
			// Error Message
			return;
		}

		const fasciaType = fasciaItems.find((fi) => headerType?.includes(fi.sku));

		if (fasciaType == null) {
			setErrors('The shades must be fascia in order to have one fascia.');

			return;
		}

		// This function adds up all of the shade widths for each assembly into one value.
		const totalWidth = selectedShades
			.reduce(
				(prev, shade) =>
				(prev += shade.shades
					.filter((s) => s.row_coordinate === 0)
					.reduce((prev, shade) => (prev += shade.width), 0)),
				0
			);

		if (totalWidth > fasciaType.maxLength) {
			setErrors('These assemblies combined for one piece of fascia is too wide.');

			return;
		}

		// Can't already be in a fascia combination
		const currentSeqIds = fasciaCombinations.reduce(
			(prev, fc) => [...prev, ...fc.assemblies.map((fa) => fa.sequence_id)],
			[] as number[]
		);

		const seqIds = selectedShades.map((s) => s.sequence_id);

		const hasSeqIdsAlreadyCombined = currentSeqIds.some((c) => seqIds.includes(c));

		if (hasSeqIdsAlreadyCombined) {
			setErrors('Shades selected must not already be combined.');

			return;
		}

		// Shades must all be facing the same window.

		const directions = selectedShades.map((s) => s.direction_facing);

		const direction = directions.find(() => true);

		const hasMoreThanOneDirection = directions.some((d) => d != direction);

		if (hasMoreThanOneDirection) {
			setErrors('Shades must all face the same direction.');

			return;
		}

		// If it passes all the checks we then call the method that combines/created the Fascia combo

		dispatch(combineFascia({
			quote_id: quoteId,
			sequence_ids: seqIds,
		})).then(() => {
			// Refresh Screen

			newQuote.rerender();

			newQuote.Data.RePriceWholeQuote();
		});

	};

	const deleteOneFascia = (fc_id) => {
		// deleteCombinedFascia
		dispatch(deleteCombinedFascia({ quote_id: quoteId, fascia_combination_id: fc_id }));

		newQuote.deleteFasciaCombination(fc_id);
	};

	const editOneFascia = (fc_id) => {
		setErrors('');

		const fcs = newQuote.getFasciaCombinations();

		const fc = fcs?.find((f) => f.id == fc_id) ?? { assemblies: [] };

		const sequenceId = fc.assemblies.find(() => true)?.sequence_id ?? 0;

		const shade = shades.find((s) => s.sequence_id == sequenceId);

		const headerType = shade?.header_line ?? "";

		const fasciaType = fasciaItems.find((fi) => headerType?.includes(fi.sku)) ?? {
			maxLength: 0,
			name: 'Unknown Fascia',
		};

		const number = parseFloat(currentStateString);

		if (number > fasciaType.maxLength) {
			setErrors(`Max length for ${fasciaType.name} is ${fasciaType.maxLength} inches. Please enter a number less than or equal to ${fasciaType.maxLength}.`)
			return;
		}

		if (number.toString() != currentStateString) {
			setErrors('Invalid Number');
			return;
		}

		dispatch(updateCombinedFascia({
			quote_id: quoteId,
			fascia_combination_id: fc_id,
			total_width: number,
		})).then(() => {
			// Refresh Screen
			newQuote.rerender();

			newQuote.Data.RePriceWholeQuote();
			// Fix state
			setEditingStatus(0);
			setCurrentStateString("");

		});
	};

	const turnOnEditing = (fc_id) => {
		const fasciaCombination = fasciaCombinations.find((fc) => fc.id == fc_id);

		const width = fasciaCombination?.total_width ?? "";

		const widthStr = width.toString();

		setCurrentStateString(widthStr);
		setEditingStatus(fc_id);
	};

	const shadeDisabled = selectedCount < 1;

	const fasciaButtonName = selectedCount == 1 ? 'Custom Fascia Length' : 'Fascia Span';

	const quoteIsEditable = quote.editable;

	return (
		<div>
			<Popup
				modal
				open={open}
				onClose={() => {
					setOpen(false);
					setErrors('');
				}}
				className="edit mobile-modal"
			>
				<Container className="local-bootstrap">
					<Row>
						<Col>
							<h4 className={`text-${quoteType}`}>Combined Fascia</h4>
						</Col>
					</Row>
					<h6 className="error">{errors}</h6>
					<Row>
						<Col>
							<h5 className={`text-${quoteType}`}>Current Combined Shades </h5>
						</Col>
					</Row>
					<Row>
						<Col>
							<Table size="sm" responsive hover bordered className={quoteType}>
								<thead>
									<tr className="bold-row">
										<th>Type</th>
										<th>Shade Names</th>
										<th>Total Width</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{fasciaCombinations.map((fc) => {
										const isBeingEdited = fc.id == editingStatus;

										const seqIds = fc.assemblies.map((fa) => fa.sequence_id);

										const shadeNames = shades
											.filter((sa) => seqIds.includes(sa.sequence_id))
											.reduce(
												(csv, shade) =>
													csv
														? `${csv}, ${shade.shade_name}`
														: shade.shade_name
											, "");

										const deleteButton = (
											<Col>
												<Button
													title={
														!quoteIsEditable
															? 'Please Unlock Quote Before Editing Fascia'
															: 'Delete'
													}
													color="light"
													size="sm"
													hoverColor={quoteType}
													fullWidth
													onClick={() => deleteOneFascia(fc.id)}
													disabled={!quoteIsEditable}
												>
													<FontAwesomeIcon icon={faTrashAlt} />
												</Button>
											</Col>
										);

										const editButton = (
											<Col>
												<Button
													title={
														!quoteIsEditable
															? 'Please Unlock Quote Before Editing Fascia'
															: 'Edit'
													}
													size="sm"
													color="light"
													hoverColor={quoteType}
													onClick={() => {
														turnOnEditing(fc.id);
													}}
													disabled={!quoteIsEditable}
												>
													<FontAwesomeIcon icon={faEdit} />
												</Button>
											</Col>
										);

										const saveButton = (
											<Col>
												<Button
													color="light"
													size="sm"
													hoverColor={quoteType}
													title={
														!quoteIsEditable
															? 'Please Unlock Quote Before Editing Fascia'
															: 'Save'
													}
													onClick={() => {
														editOneFascia(fc.id);
													}}
													disabled={!quoteIsEditable}
												>
													<FontAwesomeIcon icon={faSave} />
												</Button>
											</Col>
										);

										const widthSpot = !isBeingEdited ? (
											<div>{fc.total_width}</div>
										) : (
											<input
												value={currentStateString}
												onChange={(e) => {
													setCurrentStateString(e.target.value);
												}}
											/>
										);
										return (
											<tr>
												<td>{fasciaButtonName}</td>
												<td>{shadeNames}</td>
												<td>{widthSpot}</td>
												<td>
													<Row className="justify-content-between g-0">
														{isBeingEdited ? saveButton : editButton}
														{deleteButton}
													</Row>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</Col>
					</Row>
					<Row>
						<Col>
							<h5 className={`text-${quoteType}`}>Shades Selected</h5>
						</Col>
					</Row>
					<Row>
						<Col>
							<Table size="sm" responsive hover bordered className={quoteType}>
								<thead>
									<tr className="bold-row">
										<th>Shade Name</th>
										<th>Header Type</th>
									</tr>
								</thead>
								<tbody>
									{selectedShades.map((ss) => {
										const headerName = ss.header_line;

										return (
											<tr>
												<td>{ss.shade_name}</td>
												<td>{headerName}</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</Col>
					</Row>
					<Row>
						<Col className="text-start">
							<Button color="gray" onClick={() => setOpen(false)}>
								Close
							</Button>
						</Col>
						<Col className="text-end">
							<Button
								color={quoteType ?? 'green'}
								onClick={oneFascia}
								disabled={shadeDisabled}
								title={
									shadeDisabled
										? 'Please Unlock Shade(s) Before Attempting to Combine Fascia'
										: ""
								}
							>
								Combine Fascia
							</Button>
						</Col>
					</Row>
				</Container>
			</Popup>
			<Button fullWidth color={quoteType ?? 'green'} onClick={() => setOpen(true)}>
				Combine Fascia
			</Button>
		</div>
	);
};

export default AdvancedShadeManipulation;
