import React, { useMemo, useState, useEffect } from "react";
import { Col, Row, Container, Card } from "react-bootstrap";
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { useDealers, useDealersLastLoadedTime, useDealersLoadStatus, usePendingDealerCount, useTerritories, useTerritoriesLoadStatus } from '../Store/entities/hooks';

import TabPaneWithSearchFilter from "../Parts/TabPaneWithSearchFilter";
import { DealerFilterOptions, DealerFilters } from '../powershadesApiTypeExtensions';
import { DealerStore } from '../powershadesApiTypes';

import type { DealerListProps } from './types';
import type { TabPaneSelections } from "../Parts/types";
import { isSearchMatch } from "../psUtil";
import { loadDealersMeta } from "../Store/entities/organizations/dealers";
import Button from "../Parts/Button";
import { UsePortalDispatch } from "../Store";
import { useIsSales } from "../Store/user/hooks";

const DealerList = ({ ROOT }: DealerListProps) => {
	const [activeFilter, setActiveFilter] = useState<DealerFilters>('all');
	const [activeSearch, setActiveSearch] = useState<string>('');
	const [activeKey, setActiveKey] = useState(0);

	const [searchParams, setSearchParams] = useSearchParams();
	const territories = useTerritories();
	const dealers = useDealers();
	const dispatch = UsePortalDispatch();
	const dealersLoadingStatus = useDealersLoadStatus();
	const dealerLastLoadingTime = useDealersLastLoadedTime();
	const isSalesperson = useIsSales();
	const territoriesLoadingStatus = useTerritoriesLoadStatus();

	const { navigate, setTitle } = ROOT;

	useEffect(() => {
		setTitle('View Dealers');
	}, [setTitle]);

	const fiveMinutesAgo = Date.now() - 5 * 60 * 1000;

	useEffect(() => {
		if (dealerLastLoadingTime < fiveMinutesAgo) {
			dispatch(loadDealersMeta());
		}
	}, [dealerLastLoadingTime, fiveMinutesAgo]);

	/**
	 * Handles the change of the active filter.
	 * @param filter - The new active filter.
	 */
	const handleFilterChange = (filter: string): void => {
		setActiveFilter(filter as DealerFilters);

		setSearchParams((searchParamsCurr) => {
			searchParamsCurr.set('filter', filter);
			return new URLSearchParams(searchParams);
		});
	};

	const compareFilter = activeFilter.split(" ")[0];

	const workingDealers = useMemo(
		() =>
			dealers
				.filter((d: DealerStore) => d)
				.filter((d: DealerStore) => isSearchMatch(d.name, activeSearch))
				.filter((d: DealerStore) => activeFilter === 'all' || d.status === compareFilter),
		[dealers, activeFilter, activeSearch]
	);

	const dealerCounts = usePendingDealerCount();

	const pending = isSalesperson ? dealerCounts.territory : dealerCounts.total;

	const options = DealerFilterOptions.map((v) => {
		if (pending > 0) {
			if (isSalesperson) {
				return  v === 'pending' ? `pending (${pending})` : v;

			} else {

				return v === 'pending' ? `pending (${pending})` : v;
			}
		} else {
			return v;
		}
	});
	/** 
	 * Function that turns the list of dealers into a list of tab panes.
	 * @notes Uses the territories list to create the tabs.
	 * @returns TabPaneSelections
	 */
	const getTabSelectionsList = (): TabPaneSelections[] => {
		const workingTabPanes: TabPaneSelections[] = [];
		const workingTerritories = territories
			.sort((a, b) => a.name.localeCompare(b.name))
			.map((territory) => territory.id);
		// Add an "All" tab with ID: 0
		workingTerritories.unshift(0);
		workingTerritories.forEach((id) => {
			workingTabPanes.push({
				id,
				title: territories.find((territory) => territory.id === id)?.name || 'All',
				key: id.toString(),
				items: [],
				onClick: (itemClicked) => {
					navigate(`/Dealer?dealer_id=${itemClicked.id}`);
				}
			});
		});

		workingDealers
			.forEach((dealer) => {
				const territoryIdList = dealer?.territory_ids ?? [];
				if (territoryIdList.length === 0) return;

				dealer.territory_ids?.forEach((dealerTerritory) => {
					const tabPaneToAddTo = workingTabPanes.find((tabPane) => tabPane.id === dealerTerritory);
					tabPaneToAddTo?.items.push({
						id: `${dealer.id}`,
						type: "dealer",
						name: `${dealer.name} | ${dealer.email}`,
						title: `${dealer.name} | ${dealer.email}`,
						onClick: () => {
							navigate(`/Dealer?dealer_id=${dealer.id}`);
						}
					});
				});
			});
		// Check if all tab exists, if so, push the dealer to it
		if (workingTabPanes[0] !== undefined) {
			workingTabPanes[0].items = workingDealers.map((dealer) => ({
				id: `${dealer.id}`,
				type: "dealer",
				name: `${dealer.name} | ${dealer.email}`,
				group: dealer.territory_ids,
				filter: dealer.status,
				title: `${dealer.name} | ${dealer.email}`,
				onClick: () => {
					navigate(`/Dealer?dealer_id=${dealer.id}`);
				}
			}));
		}
		return workingTabPanes;
	};

	const tabSelectionsCurrent = getTabSelectionsList();
	const loading = dealersLoadingStatus <= 3 && territoriesLoadingStatus <= 3;

	return (
		<Container className="local-bootstrap">
			<Card body className="entity-card">
				<Row className="justify-content-end mb-2">
					<Col className="text-end">
						<Button
							onClick={() => {
								navigate('/Dealer/Add');
							}}
						>
							<FontAwesomeIcon icon={faPlus} />
							&nbsp;Add New Dealer
						</Button>

					</Col>

				</Row>
				<Row>
					<Col>
						<TabPaneWithSearchFilter
							key="dealer-list"
							selections={tabSelectionsCurrent}
							loading={loading}
							disabled={false}
							setActiveKey={setActiveKey}
							activeKey={activeKey}
							searchFilter={activeSearch}
							setSearchFilter={setActiveSearch}
							currentFilter={activeFilter}
							setCurrentFilter={handleFilterChange}
							filterOptions={options}
						/>
					</Col>
				</Row>
			</Card>
		</Container>

	);
};

export default DealerList;
