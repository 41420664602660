import NON_MOTORIZED_IMG from "../assets/img/illus/non_motorized_shade_icon.png";
import MOTORIZED_IMG from "../assets/img/illus/motorized_shade_icon.png";
import STANDARD_ROLL_IMG from "../assets/img/illus/standard_roll.png";
import REVERSE_ROLL_IMG from "../assets/img/illus/reverse_roll.png";
import FASCIA_IMG from "../assets/img/illus/fascia.png";
import CASSETTE_IMG from "../assets/img/illus/cassette.png";
import SEALED_HEMBAR_IMG from "../assets/img/illus/sealed_hembar.png";
import WRAPPED_HEMBAR_IMG from "../assets/img/illus/fabric_wrapped_hembar.png";
import INSIDE_MOUNT_IMG from "../assets/img/illus/inside_mount.png";
import OUTSIDE_MOUNT_IMG from "../assets/img/illus/outside_mount.png";
import FABRIC_WIDTH_IMG from "../assets/img/illus/fabric_width.png";

const DescDiv = (props) => (

	<div {...props} style={{ cursor: 'pointer' }}>
		{ props.children }
	</div>
);

const DescriptionSlides = {

	shade_type: (opt) => {
		const is_manual = opt.value == 'Manual';
						  const is_motorized = opt.value == 'Motorized';

		return	(
			<>
								
				<DescDiv onClick={() => opt.shade.setVal('shade_type', 'Manual')}>
			 						<h2>Manual</h2>
			 						<img className={is_manual ? 'highlight' : ''} src={NON_MOTORIZED_IMG} />
				</DescDiv>
				<DescDiv onClick={() => opt.shade.setVal('shade_type', 'Motorized')}>
					<h2>Motorized</h2>
					<img className={is_motorized ? 'highlight' : ''} src={MOTORIZED_IMG} />
				</DescDiv>
			 					
			</>
		);
	},

	roll_direction: (opt) => {
		const is_standard = opt.value == 'standard';
						  const is_reverse = opt.value == 'reverse';

		return	(
			<>
						
				<DescDiv onClick={() => opt.shade.setVal('roll_direction', 'standard')}>
					<h2>Standard Roll</h2>
					<img className={is_standard ? 'highlight' : ''} src={STANDARD_ROLL_IMG} />
				</DescDiv>

			 					<DescDiv onClick={() => opt.shade.setVal('roll_direction', 'reverse')}>
			 						<h2>Reverse Roll</h2>
			 						<img className={is_reverse ? 'highlight' : ''} src={REVERSE_ROLL_IMG} />
			 					</DescDiv>
			 			
			</>
		);
	},

	header_type: (opt) => {
		const is_no_header = opt.value == '';
				  const is_cassette = (opt.value && opt.value.toLowerCase().includes('cassette'));
				  const is_fascia = (opt.value && opt.value.toLowerCase().includes('fascia'));
	
		return (

			<>
				  			
				<DescDiv onClick={() => opt.shade.setVal('header_type', '')}>
					<h2>Open Roll</h2>
					<img className={is_no_header ? 'highlight' : ''} src={STANDARD_ROLL_IMG} />
				</DescDiv>

				<DescDiv onClick={() => alert('Please select a fascia type from the "Header Type" dropdown to the right.')}>
					<h2>Fascia</h2>
					<img className={is_fascia ? 'highlight' : ''} src={FASCIA_IMG} />
				</DescDiv>

				<DescDiv onClick={() => alert('Please select a cassette type from the "Header Type" dropdown to the right.')}>
					<h2>Cassette</h2>
					<img className={is_cassette ? 'highlight' : ''} src={CASSETTE_IMG} />
				</DescDiv>

			</>
		);
	},

	hembar_type: (opt) => {
		const is_sealed = (`${opt.value}`).includes('sealed');
						  const is_fabric_wrapped = (`${opt.value}`).includes('wrapped');

		return	(
			<>
						
				<DescDiv onClick={() => opt.shade.setVal('hembar_type', 'internal_sealed')}>
					<h2>Sealed</h2>
					<img className={is_sealed ? 'highlight' : ''} src={SEALED_HEMBAR_IMG} />
				</DescDiv>

			 					<DescDiv onClick={() => opt.shade.setVal('hembar_type', 'external_fabric_wrapped')}>
			 						<h2>Fabric Wrapped</h2>
			 						<img className={is_fabric_wrapped ? 'highlight' : ''} src={WRAPPED_HEMBAR_IMG} />
			 					</DescDiv>
			 					
			</>
		);
	},

	mount_type: (opt) => {
		const is_inside = opt.value == 'Inside';
						  const is_outside = opt.value == 'Outside';
						  const is_fabric_width = opt.value == 'Fabric Width';

		return	(
			<>
						
				<DescDiv onClick={() => opt.shade.setVal('mount_type', 'Inside')}>
					<h2>Inside</h2>
					<img className={is_inside ? 'highlight' : ''} src={INSIDE_MOUNT_IMG} />
				</DescDiv>

			 					<DescDiv onClick={() => opt.shade.setVal('mount_type', 'Outside')}>
			 						<h2>Outside</h2>
			 						<img className={is_outside ? 'highlight' : ''} src={OUTSIDE_MOUNT_IMG} />
			 					</DescDiv>

			 					<DescDiv onClick={() => opt.shade.setVal('mount_type', 'Fabric Width')}>
			 						<h2>Fabric Width</h2>
			 						<img className={is_fabric_width ? 'highlight' : ''} src={FABRIC_WIDTH_IMG} />
			 					</DescDiv>
			 			
			</>
		);
	},

};

export default DescriptionSlides;
