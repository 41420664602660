import React from 'react';
import { isFunction } from 'lodash';

class IntField extends React.Component {
	constructor(props) {
		super(props);
    
		this.state = {
			value: props.value
		};

		this.blurred = this.blurred.bind(this);
		this.changed = this.changed.bind(this);
	}

	blurred(e) {
		this.props.onChange(e.target.value);
		this.props.onBlur();
	}

	changed(new_val) {
		const { option } = this.props;

		const maxValue = option.complexMaxValueOverride ? option.complexMaxValueOverride(option.shade) : option.maxValue;

		option.value = Math.round(new_val);

		const min = option.minValue ? (isFunction(option.minValue) ? option.minValue(option.shade) : option.minValue) : null;
		
		if (maxValue && option.value > maxValue) {
			option.value = maxValue;
		} else if (min && option.value < min) {
			option.value = option.minValue;
		}

		this.setState({ value: new_val });
	}
    
	render() {
		const {
			option: {
				value,
				label,
				field_name
			},
			disabled,
			onFocus,
			handleEnter,
			keepOldStyle
		} = this.props;

		return (

			<input
				className={keepOldStyle ? '' : 'form-control'}
				onKeyDown={(e) => handleEnter(e)}
				name={field_name}
				disabled={disabled}
				type="number"
				onBlur={this.blurred}
				value={value != null ? value : ''}
				onChange={(e) => this.changed(e.target.value)}
				placeholder="-"
			/>
		);
	}
}

export default IntField;
