import * as Sentry from "@sentry/react";

import extractJwtData from "./extractJwtData";
import store from "./Store";
import type { UserDataJwt, UserDataRoles } from "./Store/user";
import { loadUserData } from "./Store/user";
import userApiCalls, { getUserData, login } from "./UserApi";
import { logout } from "./Store/upperLevelReducers";

const EnsureUserData = () => {
	let userDataFetchAttempts = 0;
	const maxUserDataFetchAttempts = 6;
	const retryInterval = 30000;
	const getUserDataWithRetry = () => {
		getUserData().then((resp) => {
			const userData = resp.data.user_data;
			const { user } = store.getState();
			const { jwt, refreshJwt } = user;
	
			const safeJwt = jwt ?? localStorage.getItem("user_jwt");
			const safeRefreshJwt = refreshJwt ?? localStorage.getItem("user_refresh");
	
			const transformedUserData: UserDataRoles = {
				name: userData.name,
				email: userData.email,
				roles: userData.roles.map((r) => ({
					entity_name: r.entity_name,
					entity_id: r.entity_id,
					role_name: r.role_name
				})),
				is_sales_person: userData.is_sales_person,
				is_disallowed_force_edit: userData.is_disallowed_force_edit,
				is_engineering: userData.is_engineering,
				is_service: userData.is_service,
				is_manufacturing: userData.is_manufacturing,
				is_using_old_homepage: userData.is_using_old_homepage,
				is_allowed_parts_on_cedia: userData.is_allowed_parts_on_cedia,
				theme: userData.theme,
				id: userData.id
			};
	
			store.dispatch(loadUserData({
				user_data: transformedUserData,
				email: userData.email,
				name: userData.name,
				jwt: safeJwt,
				refreshJwt: safeRefreshJwt,
				id: userData.id
			}));
		}).catch((err) => {
			if (userDataFetchAttempts < maxUserDataFetchAttempts) {
				console.log(`userData fetch failed, retrying in 30 seconds - ${maxUserDataFetchAttempts - userDataFetchAttempts} attempts remaining}`);
				userDataFetchAttempts++;
				setTimeout(getUserDataWithRetry, retryInterval);  // retry after a delay
			} else {
				console.log("userData fetch failed, logging out");
				// log the error and log the user out after all attempts fail
				Sentry.captureMessage("Failed to get user data - this is typically because the back end went down", {
					tags: {
						section: 'user_service'
					},
					extra: {
						response: err,
						unhandled: false,
					},
					level: 'info'
				});
				store.dispatch(logout());
			}
		});
	}
};

const userService = {
	getToken: () => {
		const token = localStorage.getItem("user_jwt") ?? "";

		return token;
	},
	login: (email: string, password: string) => new Promise((resolve, reject) => {
		login(email, password).then((resp) => {
			const respData = resp.data;

			const goods = extractJwtData(respData?.access ?? '');

			const name = goods?.name;

			if (goods === null) {
				reject(new Error("No active account found with the given credentials"));
			}

			const userData = {
				jwt: respData.access,
				refreshJwt: respData.refresh,
				email,
				name,
				user_data: respData.user_data
			};
		

			resolve(userData);
		}).catch((err: unknown) => {
			if (err instanceof Error) {
				reject(err);
			} else {
				reject(new Error("An unknown error occurred"));
			}
		});
	}),
	UserData(): UserDataJwt {
		const userData = localStorage.getItem("user_data") ?? "{}";
		const userDataJson = JSON.parse(userData);

		return userDataJson;
	},
	refresh: userApiCalls.refreshToken,
	ensureUserData: EnsureUserData,
};

export default userService;
