import { saveAs } from 'file-saver';
import { startCase } from 'lodash';
import QUOTE_GLOBALS from '../Quote/QuoteGlobals';
import { useAssembliesByQuoteId } from '../Store/entities/assemblies/hooks';
 
const csvCOROutdoorExport = (quote) => {
	const shade_rows = quote.shades.filter((s) => s.shade_type_id == 2).map((s) => {
		function val(var_name) {
			const unaltered = s[var_name] ?? s.shades[0][var_name] ?? "";

			return unaltered ? shadeValueTransform(unaltered) : 'N/A';
		}

		const side_channels = s.side_channels == 'outdoor_lightning_track' ? 'ShyZip' : val('side_channels');

		const header_map = {
			outdoor_harmony_standard_round_front: 'Summit Standard Round Front',
			outdoor_harmony_premium_square_front: 'Summit Premium Square Front',
			outdoor_apex: 'Harbor',
			outdoor_meridian: 'Vista',
		};

		const header = header_map[s.header_type] || '';
		const isMotorized = s.shades.some(sh => sh.shade_type == "motorized");
		return [
			1,
			val(`shade_type`),
			val(`mount_type`),
			val(`width`),
			val(`height`),
			header,
			val(`hardware_color`),
			val(`control_side`),
			val(`fabric_name`),
			val(`hembar_type`),
			side_channels,
			isMotorized ? 'N/A' : val(`crank_length`),
			isMotorized ? 'PowerShades Outdoor 45mm AC Motor' : 'N/A',
		];
	});

	const shade_row_headers = [
		'Qty',
		'Manual/Motorized',
		'Mount Type',
		'Width (in.)',
		'Drop (in.)',
		'Header Type',
		'Bracket Color',
		'Control Side',
		'Fabric Name',
		'Bottom Bar Type',
		'Side Guides',
		'Crank Length (in.)',
		'Motor',
	];

	const date = new Date();

	const addr = quote.shipping_address;

	const state = addr.state;

	return [
		['Dealer Name', 'PowerShades'],
		['Job Sidemark / PO', `PS${quote.ID}`],
		['Date', `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`],
		['Special Notes', ''],
		[],
		[],
		['Shipping Address'],
		['Address Line 1', addr.address || ''],
		['Address Line 2', addr.address2 || ''],
		['Country', addr.country],
		['State / Province', state],
		['City', addr.city || ''],
		['Zip / Postal Code', addr.zip || ''],
		['First Name', addr.first_name || ''],
		['Last Name', addr.last_name || ''],
		['Phone', addr.phone || ''],
		[],
		[],
		[],
		shade_row_headers,
		...shade_rows
	];
}

function shadeValueTransform(val) {
	return startCase((`${val}`).replace('outdoor_', '').replace(/\_/g, ' '));
}

export const exportOutdoorOrderForm = (quote) => {
	const csv_str = csvCOROutdoorExport(quote).reduce((csv, row) => {
		const encapsulated_cells = row.map((cell) => `"${cell}"`);

		return `${csv + encapsulated_cells.join(',')}\n`;
	}, '');

	const blob = new Blob([csv_str], { type: "text/plain;charset=utf-8" });
  
	saveAs(blob, `OutdoorOrderPS${quote.ID}.csv`);
}
