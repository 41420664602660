import Axios from 'axios';
import React, { Component, useEffect, useState } from 'react';
import api from '../PowerShadesAPI';

function FileViewer(props) {

    let [src, setSrc] = useState(false);
    let [data, setData] = useState(false);
    let pdficon = 'https://powershades-tax-certificates.s3.amazonaws.com/pdf-icon.png';

    let {
        certType,
        entityId,
        entityType,
        location
    } = props;


    useEffect(() => {

        if (certType && entityId && entityType) {
            //Do API Call
            api.getTaxCertificate(certType,entityId,entityType).then(resp => {
                setSrc(resp.data)
            }).catch(idk => console.log(idk))
        };

    }, [certType,entityId,entityType,location])

    let url = props.srcOverride ?? `data:image/png;base64,${src}`;
    
    return (
        <>
            {(!src && !props.srcOverride) ? null :

                <a href={url} download>
                    {props.title}<br></br>
                    {props.isPdf ?
                        <>
                            <img
                                src={pdficon}
                                alt="new"
                                width="45"
                                height="60"
                            />
                        </>
                        ://federal tax
                        <img
                            src={url}
                            alt="new"
                            width="45"
                            height="60"
                        />
                    }
                </a>
            }
        </>

    );
}

export default FileViewer;