import { faFolder, faHammer, faPaintRoller, faPenRuler, faToolbox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Row, Col, Container, Card } from "react-bootstrap";



const UnderConstruction = () => {
    return (
        <div className="local-bootstrap">

            <Container fluid>
                <Card className="w-100 h-100 p-4">

                    <div>
                        <Row className="h-25">
                            <Col>
                                <h4 className="text-center">
                                    Under Construction
                                </h4>
                            </Col>
                        </Row>
                        <Row>
                            The PowerShades Dealer Portal is currently under construction. Please check back later.
                        </Row>
                        <Row className="p-2 b-2s">
                            <Col>
                                <FontAwesomeIcon color="#8dc63f" icon={faPaintRoller} size="5x" className="text-center" />
                            </Col>
                            <Col>
                                <FontAwesomeIcon color="#8dc63f" icon={faHammer} size="5x" className="text-center" />
                            </Col>
                            <Col>
                                <FontAwesomeIcon color="#8dc63f" icon={faPenRuler} size="5x" className="text-center" />
                            </Col>
                            <Col>
                                <FontAwesomeIcon color="#8dc63f" icon={faToolbox} size="5x" className="text-center" />
                            </Col>
                            <Col>
                                <FontAwesomeIcon color="#8dc63f" icon={faFolder} size="5x" className="text-center" />
                            </Col>
                        </Row>
                    </div>
                </Card>
            </Container>
        </div>
    )

};

export default UnderConstruction;