import { Card, Col, Placeholder, Row } from 'react-bootstrap';
import React from 'react';

import styles from './QuoteCards.module.scss';

const SkeletonQuoteCard = () => (
	<Col xs={12} md={6}>
		<Card className="mb-1">
			<Card.Body className={styles.cardBody}>
				<Placeholder as={Card.Title} animation="glow">
					<Row>
						<Col xs={10} lg={10} className={styles.skeletonTitle}>
							<Placeholder bg="green" xs={9} sm={6} />
						</Col>
						<Col className="d-none d-sm-block" xs={1} />
						<Col xs={1} className="text-end">
							<Placeholder.Button variant="green" />
						</Col>
					</Row>
				</Placeholder>
				{/* <Card.Subtitle className="mb-2 text-muted">
				</Card.Subtitle> */}
				<Placeholder as={Card.Text} animation="glow" className={styles.cardText}>
					<Row>
						<Col className={styles.createdBy} xs={12}>
							<Placeholder xs={3} sm={2} />
							&nbsp;
							<Placeholder xs={3} sm={2} />
						</Col>
					</Row>
					<Row>
						<Col xs={12}>
							<Placeholder xs={6} sm={4} />
						</Col>
					</Row>
					<Row>
						<Col xs={12}>
							<Placeholder xs={3} sm={2} />
							&nbsp;
							<Placeholder xs={3} sm={2} />
						</Col>
					</Row>
					<Row>
						<Col xs={12}>
							<Placeholder xs={3} sm={2} />
							&nbsp;
							<Placeholder xs={3} sm={2} />
						</Col>
					</Row>
					<Row>
						<Col xs={12}>
							<Placeholder xs={3} sm={2} />
							&nbsp;
							<Placeholder xs={3} sm={2} />
						</Col>
					</Row>
					<Row>
						<Col xs={12}>
							<Placeholder xs={3} sm={2} />
							&nbsp;
							<Placeholder xs={3} sm={2} />
						</Col>
					</Row>
				</Placeholder>
			</Card.Body>
		</Card>
	</Col>
);

export default SkeletonQuoteCard;
