import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import IntField from './OptionFields/IntField';
import FabricSelectField from './OptionFields/FabricSelectField';
import FractionField from './OptionFields/FractionField';
import SelectField from './OptionFields/SelectField';
import TextField from './OptionFields/TextField';

export default function OptionField(props) {
	const {
		disabled,
		placeholderLabel,
		noPlaceholder,
		editable,
		option,
		quote
	} = props;

	const props_to_pass = {
		disabled, placeholderLabel, noPlaceholder, editable, option, noPlaceholder, quote
	};

	const [showDescription, setShowDescription] = useState(false);

	useEffect(() => {
		if (option.type == 'fraction') {
			option.display_val = Math.floor(option.value);
		}
	}, []);

	function onChange(new_val) {
		if (option.onChange != undefined) {
			option.onChange();
		}

		if (props.onChange) {
			props.onChange(option);
		}
		
		option.shade.valueChanged();
	}

	function onFocus(e) {
		props.onFocus && props.onFocus(option, e);
	}

	function onBlur(e) {
		props.onBlur && props.onBlur(option, e);
	}
	
	const OPTION_TYPES = {
		text: () => (
			<TextField
				{...props_to_pass}
				key={`${option.varName}-${option.value}`}
				onBlur={onBlur}
				onFocus={onFocus}
				onChange={onChange}
			/>
		),

		select: () => (
			<SelectField
				{...props_to_pass}
				key={`${option.varName}-${option.value}`}
				onBlur={onBlur}
				onFocus={onFocus}
				onChange={onChange}
			/>
		),

		int: () => (
			<IntField
				{...props_to_pass}
				key={`${option.varName}-${option.value}`}
				onBlur={onBlur}
				onFocus={onFocus}
				onChange={onChange}
			/>
		),

		fabricSelect: () => (
			<FabricSelectField
				{...props_to_pass}
				key={`${option.varName}-${option.value}`}
				onBlur={onBlur}
				onFocus={onFocus}
				onChange={onChange}
			/>
		),

		fraction: () => (
			<FractionField
				{...props_to_pass}
				key={`${option.varName}-${option.value}`}
				onBlur={onBlur}
				onFocus={onFocus}
				onChange={onChange}
			/>
		),

	};

	const label = (typeof option.label === 'function' ? option.label(option.shade) : option.label);

	const contents = option.type ? OPTION_TYPES[option.type]() : null;

	let additionalClasses = option.type === 'fraction' ? 'number-holder fraction-holder' : '';

	if ((option.nullable == undefined || !option.nullable()) && option.value == null) {
		additionalClasses += ' empty-value';
	}

	if (option.fullWidth) {
		additionalClasses += ' full-width';
	}

	const has_error = (option.error && option.error(option.shade)) || (option.shade && option.shade.fieldHasErrors(option.varName));

	return (

		<div className={`${has_error ? 'error' : ''} ${additionalClasses}`}>

			{
				option.description
					
					? (
						<>
							<FontAwesomeIcon icon={faInfoCircle} description="true" onMouseOver={() => setShowDescription(true)} onMouseLeave={() => setShowDescription(false)} />
							<div className={`option-info ${showDescription ? 'show' : ''}`}>
								{ option.description }
							</div>
						</>
					)
					: null
			}

			<label style={{ width: "100%" }} htmlFor={option.varName}>{ label }</label>
					
			{ contents }
				
		</div>
	);

	return null;
}
