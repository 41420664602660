import type { JwtData } from "./User/types";

const extractJwtData = (jwt: string): JwtData | undefined => {
	if (!jwt) {
		return undefined;
	}

	const jwtBits = jwt.split(".");

	const mainBit = jwtBits[1];

	if (!mainBit) {
		return undefined;
	}

	const jwtObjStr : string = atob(mainBit);
    
	const jwtData = JSON.parse(jwtObjStr);

	return jwtData;
};

export default extractJwtData;
