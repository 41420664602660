// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home-module__home-container___ZpCJB{max-width:95vw;margin-left:auto}.Home-module__home-wrapper___pq1_p{min-height:"890px"}.Home-module__status-container___E4dzJ{margin-bottom:1em}.Home-module__navigator-container___TDLLP{margin-bottom:1.5em}.Home-module__quotes-container___UF6l0{min-height:80vh}`, "",{"version":3,"sources":["webpack://./src/Home/Home.module.scss"],"names":[],"mappings":"AAAA,qCACC,cAAA,CACA,gBAAA,CAGD,mCACC,kBAAA,CAGD,uCACC,iBAAA,CAGD,0CACC,mBAAA,CAGD,uCACC,eAAA","sourcesContent":[".home-container {\n\tmax-width: 95vw;\n\tmargin-left: auto;\n}\n\n.home-wrapper {\n\tmin-height: '890px';\n}\n\n.status-container {\n\tmargin-bottom: 1em;\n}\n\n.navigator-container {\n\tmargin-bottom: 1.5em;\n}\n\n.quotes-container {\n\tmin-height: 80vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home-container": `Home-module__home-container___ZpCJB`,
	"homeContainer": `Home-module__home-container___ZpCJB`,
	"home-wrapper": `Home-module__home-wrapper___pq1_p`,
	"homeWrapper": `Home-module__home-wrapper___pq1_p`,
	"status-container": `Home-module__status-container___E4dzJ`,
	"statusContainer": `Home-module__status-container___E4dzJ`,
	"navigator-container": `Home-module__navigator-container___TDLLP`,
	"navigatorContainer": `Home-module__navigator-container___TDLLP`,
	"quotes-container": `Home-module__quotes-container___UF6l0`,
	"quotesContainer": `Home-module__quotes-container___UF6l0`
};
export default ___CSS_LOADER_EXPORT___;
