import { signalMiddleware } from "redux-signalr";
import callbacks from "./callbacks";
import connection from "./connection";



const signalR = signalMiddleware({
	connection,
	callbacks,
	shouldConnectionStartImmediately: false
});



export default signalR;
