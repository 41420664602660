/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
import * as Sentry from "@sentry/react";
import {
    createAsyncThunk,
    createReducer,
} from '@reduxjs/toolkit';
import { uncoupledReducer } from "./configureReducer";
import { initialEntitiesState } from './entities';
import { initialUIState } from './ui';
import { initialUserState } from './user';
import { archiveQuoteThunk } from "./entities/quotes";
import { persistor } from ".";

import { initialFabricsAllowedState } from "./entities/fabricsAllowedSlice"; 


const logout = createAsyncThunk<void, void>(`user/logout`, async () => { // eslint-disable-line
    localStorage.setItem('user_data', '');
    localStorage.setItem('user_jwt', '');
    localStorage.setItem('user_refresh', '');
    localStorage.removeItem('persist:root');

    Sentry.setUser(null);
    persistor.purge();
});

const upperLevelReducer = createReducer<(ReturnType<typeof uncoupledReducer>)>(({
    user: initialUserState,
    ui: initialUIState,
    entity: initialEntitiesState(),
    fabricsAllowed: initialFabricsAllowedState,
}), (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
        const preferredUserTheme = state.user.theme;
        const initialStateWithThemePreference = {
            ...initialUserState,
            theme: preferredUserTheme,
        };
        state.user = initialStateWithThemePreference;
        const initialUiStateWithLoading = {
            ...initialUIState,
            pageLoading: true,
        };
        state.ui = initialUiStateWithLoading;
        state.entity = initialEntitiesState();
        return state;
    });

    builder.addCase(archiveQuoteThunk.fulfilled, (state, action) => {
        const quoteId = action.meta.arg;
        const quote = state.entity.quotes[quoteId];

        state.ui.home.visibleQuoteIds.list = state.ui.home.visibleQuoteIds.list
            .filter((id) => id !== quoteId);

        if (quote) {
            quote.archived = true;
        }
    });
});
export { logout };
export default upperLevelReducer;
