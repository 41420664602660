import { getWindowPS } from './GlobalTypes';

export type typedRootType = {
	loadContent: (path: string) => void;
	toast: (toastOptions: { text?: string, type?: string, duration?: number, id?: number }) => void;
	loaded: () => void;
	setTitle: (title: string) => void;
	alert: (alertOptions) => void;
};

const typedWindow = getWindowPS();

const typedRoot: typedRootType = (typedWindow.ROOT as typedRootType);

export default typedRoot;
