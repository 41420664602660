/* eslint-disable camelcase */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { HubConnectionState } from 'redux-signalr';
import { ThunkQuoteResponse } from "./types";
import { QuoteState, QuoteStore, TableStore, extraEntityReducersFunction, loadStatus } from '../entitiesType';
import type { Address, FilterOptions, HomeTableDatum, HomeTableSet, QuotePricingVM, QuoteShipment, QuoteVM, TableCounts } from "../../../powershadesApiTypes";

import apiCalls from '../../../PowerShadesAPIFunctions';
import { QuoteTypeNamesById } from '../../../powershadesApiTypeExtensions';
import SignalRMessageOptions from '../../signalR/callbacks/SignalRMessageOptions';

import type { AppState } from "../..";
import { ThunkAPI } from "../types";
import { captureSentryError, extractErrorMessage, isFailedApiCall } from "../../../psUtil";
import { selectQuoteFromId } from './hooks';
import { setFetchingVisibleQuoteIds } from "../../ui";
import { SelectAssembly } from '../assemblies';
import { selectQuoteAccessories } from '../accessories/hooks';
import { QuoteCombinations, QuoteFasciaCombinations, QuoteMultiplier } from '../multipliers/types';
import connection, { EnsureConnectionMade } from '../../signalR/connection';
import { selectHWRItemsListByQuoteId } from '../hardwareRequestItems/hooks';

const initDatum: () => HomeTableDatum = () => {
	const newDatum: HomeTableDatum = {
		count: 0,
		invoice: 0,
		msrp: 0
	};

	return newDatum;
};

export const initialCountsList = {
	all: initDatum(),
	active_quotes: initDatum(),
	awaiting_po: initDatum(),
	ready_for_manufacturing: initDatum(),
	submitted_to_manufacturing: initDatum(),
	in_production: initDatum(),
	credit_card_declined: initDatum(),
	shipped_orders: initDatum()
};

export const initialQuotesState: QuoteState = {
	list: {},
	counts: {
		list: {
			...initialCountsList
		},
		loadStatus: loadStatus.notLoaded
	},
	types: {
		list: [],
		loadStatus: loadStatus.notLoaded
	},
	loadStatus: loadStatus.notLoaded
};

export const initialQuote = (id = 0): QuoteStore => ({
	loadStatus: loadStatus.notLoaded,
	id,
	ship_order: true,
	msrp_snapshot: 0,
	order_status: "active_quote",
	installation_address_id: 0,
	shipping_address_id: 0,
	pre_ship_whips: false,
	created: 0,
	created_by: "",
	created_by_email: "",
	notes: "",
	quote_type_id: 3,
	reference_number: "",
	title: "",
	updated: 0,
	user_id: 0,
	archived: false,
	display_id: "",
	entity_name: "",
	freight_costs: 0,
	shipping_snapshot: 0,
	sales_tax_snapshot: 0,
	sales_tax_percentage_snapshot: 0,
	sales_tax_on_shipping_snapshot: false,
	editable: false,
	msrp_sale_price_snapshot: 0,
	portfolio_code: "",
});

export const loadQuoteCounts = createAsyncThunk<TableStore, FilterOptions, ThunkAPI>(
	"entity/quotes/loadQuoteCounts",
	async (filterOptions: FilterOptions, thunkAPI) => {
		try {
			const { getAccessibleQuoteCounts } = apiCalls;
			const quoteCountsResp = await getAccessibleQuoteCounts(filterOptions);

			if (isFailedApiCall(quoteCountsResp)) {
				throw quoteCountsResp.error;
			}
			const quoteCounts: TableCounts = quoteCountsResp.data.home_table_count;

			const currentItem = (thunkAPI.getState() as AppState).entity.quotes.counts.list;

			const formattedCounts: HomeTableSet = {
				all: {
					...currentItem.all,
					count: quoteCounts.all
				},
				active_quotes: {
					...currentItem.active_quotes,
					count: quoteCounts.active_quotes
				},
				awaiting_po: {
					...currentItem.awaiting_po,
					count: quoteCounts.awaiting_po
				},
				ready_for_manufacturing: {
					...currentItem.ready_for_manufacturing,
					count: quoteCounts.ready_for_manufacturing
				},
				submitted_to_manufacturing: {
					...currentItem.submitted_to_manufacturing,

					count: quoteCounts.submitted_to_manufacturing
				},
				in_production: {
					...currentItem.in_production,
					count: quoteCounts.in_production
				},
				credit_card_declined: {
					...currentItem.credit_card_declined,
					count: quoteCounts.credit_card_declined
				},
				shipped_orders: {
					...currentItem.shipped_orders,
					count: quoteCounts.shipped_orders
				}
			};

			const formattedTableStore = {
				list: formattedCounts,
				loadStatus: loadStatus.fullyLoaded
			};

			return formattedTableStore;
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: "redux"
				},
				extra: {
					redux_action: "loadQuoteCounts",
					file_origin: "src/Store/entities/quotes/index.ts"
				}
			});
			const errorMessage = extractErrorMessage(err);
			return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const loadQuoteIds = createAsyncThunk<Record<number, QuoteStore>, void, { rejectValue: string }>(
	"entity/quotes/loadQuoteIds",
	async (_, { rejectWithValue }) => {
		try {
			const { getAccessibleQuoteIds } = apiCalls;
			const quoteIdResp = await getAccessibleQuoteIds();

			if (isFailedApiCall(quoteIdResp)) {
				throw quoteIdResp.error;
			}

			const quoteIds = quoteIdResp.data.quote_ids;

			const formattedQuotes: Record<number, QuoteStore> = {};
			quoteIds.forEach((id) => {
				formattedQuotes[id] = initialQuote(id);
			});

			return formattedQuotes;
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: "redux"
				},
				extra: {
					redux_action: "loadQuoteIds",
					file_origin: "src/Store/entities/quotes/index.ts"
				}
			});
			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}
	}
);

export const loadFilteredQuoteIds = createAsyncThunk<number[], FilterOptions, { rejectValue: string }>(
	"entity/quotes/loadFilteredQuoteIds",
	async ({
		general_filter,
		dealer_ids,
		territory_ids,
		quote_type_ids,
		page_number,
		results_per_page,
		filter_names,
		sort_names
	}, { rejectWithValue }) => {
		try {
			const { getQuotesFiltered } = apiCalls;

			const quoteIdResp = await getQuotesFiltered(
				general_filter,
				dealer_ids,
				territory_ids,
				quote_type_ids,
				page_number,
				results_per_page,
				filter_names,
				sort_names
			);

			if (isFailedApiCall(quoteIdResp)) {
				throw quoteIdResp.error;
			}

			const quoteIds = quoteIdResp.data.quote_ids;

			return quoteIds;
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: "redux"
				},
				extra: {
					redux_action: "loadFilteredQuoteIds",
					file_origin: "src/Store/entities/quotes/index.ts"
				}
			});
			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}
	}
);

export const loadFilteredHomePageData = createAsyncThunk<
	ThunkQuoteResponse,
	FilterOptions,
	{ rejectValue: string }
>(
	"entity/quotes/loadFilteredHomePageData",
	async (
		{
			general_filter,
			dealer_ids,
			territory_ids,
			quote_type_ids,
			page_number,
			results_per_page,
			filter_names,
			sort_names,
			manufacturer_id
		},
		{ rejectWithValue, dispatch }
	) => {
		try {
			dispatch(setFetchingVisibleQuoteIds(true));
			const quoteIdResp = await apiCalls.getQuotesFiltered(
				general_filter,
				dealer_ids,
				territory_ids,
				quote_type_ids,
				page_number,
				results_per_page,
				filter_names,
				sort_names,
				manufacturer_id
			);

			if (isFailedApiCall(quoteIdResp)) {
				throw quoteIdResp.error;
			}

			const { quote_ids: quoteIds, home_items } = quoteIdResp.data;

			dispatch(setFetchingVisibleQuoteIds(false));

			return {
				quoteIds,
				counts: home_items
			};
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: "redux"
				},
				extra: {
					redux_action: "loadFilteredHomePageData",
					file_origin: "src/Store/entities/quotes/index.ts"
				}
			});
			dispatch(setFetchingVisibleQuoteIds(false));
			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}

	}
);

export const loadQuotesMetaData = createAsyncThunk<QuoteStore[], number[], { rejectValue: string }>(
	"entity/quotes/loadQuotesMetaData",
	async (quoteIds: number[], { rejectWithValue }) => {
		try {
			const { loadQuoteMetaData } = apiCalls;

			const quoteMetaDataResp = await loadQuoteMetaData(quoteIds);

			if (isFailedApiCall(quoteMetaDataResp)) {
				throw quoteMetaDataResp.error;
			}

			const quoteMetaData = quoteMetaDataResp.data.quotes;
			const formattedQuotes = quoteMetaData.map((quote) => ({
				...quote,
				loadStatus: loadStatus.metaLoaded,
				editable: false
			}));

			return formattedQuotes;
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: "redux"
				},
				extra: {
					redux_action: "loadQuotesMetaData",
					file_origin: "src/Store/entities/quotes/index.ts"
				}
			});
			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}
	}
);

export const loadQuoteTypes = createAsyncThunk("entity/quotes/loadQuoteTypes", async (_, { rejectWithValue }) => {
	try {
		const { getQuoteTypes } = apiCalls;

		const quoteTypesResp = await getQuoteTypes();

		if (isFailedApiCall(quoteTypesResp, "quote_types")) {
			console.error("Quote Types API Call Failed");
			console.error({ quoteTypesResp });
			throw quoteTypesResp.error;
		}

		const quoteTypesMap = quoteTypesResp.data.quote_types.map((qt) => ({
			id: qt.id,
			name: qt.name,
			zoho_reference: qt.zoho_reference,
			description: qt.description
		}));

		return quoteTypesMap;
	} catch (err: any) {
		captureSentryError(err, {
			tags: {
				section: "redux"
			},
			extra: {
				redux_action: "loadQuoteTypes",
				file_origin: "src/Store/entities/quotes/index.ts"
			}
		});
		const errorMessage = extractErrorMessage(err);
		return rejectWithValue(errorMessage);
	}
});

const waitForConnection = async (maxRetries = 10, delay = 1000) => {
	let retries = 0;
	while (connection.state !== HubConnectionState.Connected && retries < maxRetries) {
		try {
			await EnsureConnectionMade();
		} catch (e) {
			console.error(e);
		}
		await new Promise((resolve) => setTimeout(resolve, delay));
		retries++;
	}

	if (connection.state !== HubConnectionState.Connected) {
		throw new Error("Unable to establish a connection after multiple attempts");
	}
};

export const wentToAdmin = () => async (_dispatch, _getState, invoke) => {
	try {
		await waitForConnection();
		await invoke(SignalRMessageOptions.WentToAdmin);
	} catch (e) {
		if (e instanceof Error && e.message.includes("unauthorized")) {
			return;
		}
		await connection.stop();
		await EnsureConnectionMade();
	}
};


export const wentToQuote = (quoteId: number) => async (_dispatch, _getState, invoke) => {
	

	try {
		await waitForConnection();
		await invoke(SignalRMessageOptions.WentToQuote, `${quoteId}`);
	} catch (e) {
		await connection.stop();
		await EnsureConnectionMade();
		console.error(e);
	}
};

export const archiveQuoteThunk = createAsyncThunk(
	"entity/quotes/archive",
	async (quoteId: number, { rejectWithValue }) => {
		try {
			const { archiveQuote } = apiCalls;

			const archiveQuoteResp = await archiveQuote(quoteId);

			if (isFailedApiCall(archiveQuoteResp)) {
				throw archiveQuoteResp.error;
			}

			return archiveQuoteResp.data.success;
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: "redux"
				},
				extra: {
					redux_action: "quotes/archive",
					file_origin: "src/Store/entities/quotes/index.ts"
				}
			});
			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}
	}
);

export const duplicateQuote = createAsyncThunk<number, QuoteStore, ThunkAPI>(
	"entity/quotes/duplicate",
	async (quote, { rejectWithValue }) => {
		try {
			const quoteTypeId = quote.quote_type_id ?? 5;
			const quoteType = QuoteTypeNamesById[quoteTypeId] ?? "CEDIA";

			const resp = await apiCalls.duplicateQuote(quote.id, quoteType);

			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			return resp.data.new_quote_id;
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: "redux"
				},
				extra: {
					redux_action: "quotes/duplicate",
					file_origin: "src/Store/entities/quotes/index.ts"
				}
			});
			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}

	}
);

export const loadShipments = createAsyncThunk<QuoteShipment[], number, { rejectValue: string }>(
	"entity/quotes/loadShipments",
	async (quoteId: number, { rejectWithValue }) => {
		let shipments: QuoteShipment[] = [];

		try {
			const resp = await apiCalls.getQuoteShipments(quoteId);

			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			shipments = resp.data.quote_shipments;
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: "redux"
				},
				extra: {
					redux_action: "quotes/loadShipments",
					file_origin: "src/Store/entities/quotes/index.ts"
				}
			});
			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}

		return shipments;
	}
);

export const loadQuoteById = createAsyncThunk<[QuoteStore, QuoteMultiplier[], QuoteCombinations], number, ThunkAPI>(
	"entity/quotes/loadQuoteById",
	async (quoteId: number, { rejectWithValue, dispatch, getState }) => {
		try {
			if (!quoteId) return rejectWithValue("No Quote ID Provided");
			// Let Hub Know We're Going to Quote
			dispatch(wentToQuote(quoteId));

			// Grab Quote Data
			const { getOrderStatus, getQuoteShipments, getQuoteMeta, getQuoteFasciaCombinations, getQuoteAddresses, getQuoteMulipliers } = apiCalls;

			const quoteMetaResponse = await getQuoteMeta(quoteId);
			const orderStatusResponse = await getOrderStatus(quoteId);
			const shipmentsResponse = await getQuoteShipments(quoteId);
			const fasciaCombinationsResponse = await getQuoteFasciaCombinations(quoteId);
			const quoteAddresses = await getQuoteAddresses(quoteId);
			const quoteMultipliers = await getQuoteMulipliers(quoteId);


			if (isFailedApiCall(quoteMetaResponse)) {
				throw quoteMetaResponse.error;
			}
			if (isFailedApiCall(fasciaCombinationsResponse)) {
				throw fasciaCombinationsResponse.error;
			}
			if (isFailedApiCall(quoteAddresses)) {
				throw quoteAddresses.error;
			}
			if (isFailedApiCall(quoteMultipliers)) {
				throw quoteMultipliers.error;
			}
			if (isFailedApiCall(orderStatusResponse)) {
				throw orderStatusResponse.error;
			}
			if (isFailedApiCall(shipmentsResponse)) {
				throw shipmentsResponse.error;
			}

			const orderStatus = orderStatusResponse.data.quote_status;
			const shipments = shipmentsResponse.data.quote_shipments;

			const quote = selectQuoteFromId(quoteId)(getState() as AppState);
			const quoteIsAlreadyEditable = quote?.editable ?? false;
			const currentDisplayId = quote?.display_id;
			const createdDate = 0;

			const quoteMeta = quoteMetaResponse.data.quote;

			const formattedQuote: QuoteStore = {
				...quoteMeta,
				shipments,
				quote_statuses: orderStatus,
				id: quoteId,
				display_id: currentDisplayId,
				entity_name: '',
				ship_order: Boolean(quoteMeta.ship_order),
				pre_ship_whips: Boolean(quoteMeta.pre_ship_whips),
				loadStatus: loadStatus.fullyLoaded,
				archived: Boolean(quoteMeta.archived),
				isEvolutionShippingForced: Boolean(quoteMeta.is_evolution_shipping_forced),
				overrideLightGap: Boolean(quoteMeta.is_lightgap_choice_active),
				overrideRailRoad: Boolean(quoteMeta.is_railroad_prevention_deactivated),
				shipping_snapshot: 0,
				freight_costs: 0,
				sales_tax_on_shipping_snapshot: false,
				sales_tax_percentage_snapshot: 0,
				sales_tax_snapshot:  0,
				editable: quoteIsAlreadyEditable,
				created: createdDate,
				property_address: quoteAddresses.data.billing_address,
				shipping_address: quoteAddresses.data.shipping_address,
				msrp_snapshot: 0,
				msrp_sale_price_snapshot: 0,
				updated: 0,
				created_by: "",
				created_by_email: "",
				order_status: "active_quote"

			};

			const extraMultiplier = quoteMultipliers.data.multipliers.find((iso) => iso.currency !== "USD");
			const msrpIsAlreadyNotUSD = extraMultiplier?.currency !== "USD";
			const formatedMultipliers: QuoteMultiplier[] = quoteMultipliers.data.multipliers.map((m) => ({
				...m,
				multiplier: m.multiplier ?? 1,
				currency_multiplier: m.currency_multiplier ?? 1,
				useCoupons: Boolean(m.use_coupons)
			}));

			if (extraMultiplier && msrpIsAlreadyNotUSD) {
				// formatedMultipliers.push({
				// 	multiplier: 1,
				// 	currency_multiplier: extraMultiplier.multiplier ?? 1,
				// 	useCoupons: false,
				// 	currency: extraMultiplier.currency,
				// 	name: `MSRP-${extraMultiplier.currency}`.toUpperCase(),
				// 	label: `MSRP (${extraMultiplier.currency})`.toUpperCase()
				// });

			}

			const quoteFasciaCombinations: QuoteFasciaCombinations[] = fasciaCombinationsResponse?.data?.fascia_combinations ?? ([] as QuoteFasciaCombinations[]);

			const quoteCombinations: QuoteCombinations = {
				Fascias: quoteFasciaCombinations
			};

			return [formattedQuote, formatedMultipliers, quoteCombinations];
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: "redux"
				},
				extra: {
					redux_action: "quotes/loadQuoteById",
					file_origin: "src/Store/entities/quotes/index.ts"
				}
			});
			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}
	}
);

export const updateQuoteNotes = createAsyncThunk<boolean, { quoteId: number, notes: string }, ThunkAPI>(
	"entity/quotes/updateQuoteNotes",
	async ({ quoteId, notes }, { rejectWithValue, getState }) => {
		if (!quoteId) return rejectWithValue("No Quote ID Provided");

		try {
			const quote = selectQuoteFromId(quoteId)(getState() as AppState);

			if (!quote) {
				throw new Error(`Quote ${quoteId} not found`);
			}

			const resp = await apiCalls.updateQuoteNotes(quoteId, notes);

			return resp?.data?.success ?? false;
		} catch (err: any) {
			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}
	});

// Make reducer for updating the quote record
/*
const updateQuoteStr = "entity/quotes/updateQuote";

export const UpdateQuoteStore: (quote: QuoteStore) => AnyAction = (quote) => ({
	type: updateQuoteStr,
	payload: quote
});
*/

/*
 createAsyncThunk<TableStore, FilterOptions, ThunkAPI>(
	"entity/quotes/loadQuoteCounts",
	async (filterOptions: FilterOptions, thunkAPI) => {
*/

type UpdateQuoteMetaInput = {
	quoteId: number,
	changeQuote: (quote: QuoteStore) => QuoteStore,
};

export const updateQuoteMeta = createAsyncThunk<QuoteStore, UpdateQuoteMetaInput, ThunkAPI>(
	"entity/quotes/updateQuoteMeta",
	async (quoteItems: UpdateQuoteMetaInput, { getState, rejectWithValue }) => {
		const {
			quoteId,
			changeQuote
		} = quoteItems;

		if (!quoteId) return rejectWithValue("No Quote ID Provided");

		try {
			const quote = selectQuoteFromId(quoteId)(getState() as AppState);

			if (!quote) {
				throw new Error(`Quote ${quoteId} not found`);
			}
			const newQuote = changeQuote({ ...quote });

			const newQuoteVM: QuoteVM = {
				is_railroad_prevention_deactivated: newQuote.overrideRailRoad ?? false,
				title: newQuote.title ?? "",
				reference_number: newQuote.reference_number ?? "",
				ship_order: newQuote?.ship_order ?? false,
				pre_ship_whips: newQuote.pre_ship_whips ?? false,
				previous_quote_id: newQuote.previous_quote_id ?? 0,
				distributor_id: newQuote.distributor_id ?? 0,
				dealer_id: newQuote?.dealer_id ?? 0,
				representative_id: newQuote?.representative_id ?? 0,
				territory_id: newQuote?.territory_id ?? 0,
				distributor_location_id: newQuote?.distributor_location_id ?? 0,
				sales_person_id: newQuote?.salesperson_id ?? 0,
				rma_type_id: newQuote?.rma_type_id ?? 0,
				is_evolution_shipping_forced: false,
				is_lightgap_choice_active: newQuote.overrideLightGap ?? false,
				archived: newQuote.archived ?? false,
				order_status: newQuote.order_status ?? "active_quote",
				portfolio_code: newQuote.portfolio_code ?? "",
			};

			await apiCalls.updateQuoteVM(quoteId, newQuoteVM);
			return newQuote;
		} catch (err: any) {

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}
	});

export const repriceQuote = createAsyncThunk<
	void,
	{ quoteId: number }
>("entities/repriceQuote", async ({ quoteId }, { rejectWithValue }) => {
	try {
		const resp = await apiCalls.repriceWholeQuote(quoteId);

		if (isFailedApiCall(resp)) {
			throw resp.error;
		}
	}
	catch (err: any) {
		captureSentryError(err, {
			tags: {
				section: "redux"
			},
			extra: {
				redux_action: "repriceQuote",
				file_origin: "src/Store/entities/quote/index.ts"
			}
		});

		const errorMessage = extractErrorMessage(err);
		rejectWithValue(errorMessage);
	}
});


export const repriceQuoteMeta = createAsyncThunk<
	void,
	{ quoteId: number }
>("entities/repriceQuote", async ({ quoteId }, { rejectWithValue }) => {
	try {
		const resp = await apiCalls.repriceQuote(quoteId);

		if (isFailedApiCall(resp)) {
			throw resp.error;
		}
	}
	catch (err: any) {
		captureSentryError(err, {
			tags: {
				section: "redux"
			},
			extra: {
				redux_action: "repriceQuoteMeta",
				file_origin: "src/Store/entities/quote/index.ts"
			}
		});

		const errorMessage = extractErrorMessage(err);
		rejectWithValue(errorMessage);
	}
});


type UpdateQuoteTerritoryIdInput = {
	quoteId: number,
	territoryId: number,
};

export const updateQuoteTerritoryId = createAsyncThunk<QuoteStore, UpdateQuoteTerritoryIdInput, ThunkAPI>(
	"entity/quotes/updateQuoteTerritoryId",
	async (quoteItems: UpdateQuoteTerritoryIdInput, { getState, rejectWithValue }) => {
		const {
			quoteId,
			territoryId
		} = quoteItems;

		if (!quoteId) return rejectWithValue("No Quote ID Provided");

		try {
			const quote = selectQuoteFromId(quoteId)(getState() as AppState);

			if (!quote) {
				throw new Error(`Quote ${quoteId} not found`);
			}

			await apiCalls.assignTerritory(territoryId, quoteId);

			const newQuote = { ...quote, territory_id: territoryId, dealer_id: 0 };
			return newQuote;
		} catch (err: any) {

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}
	});
type UpdateQuoteDealerIdInput = {
	quoteId: number,
	dealerId: number,
};

export const updateQuoteDealerId = createAsyncThunk<QuoteStore, UpdateQuoteDealerIdInput, ThunkAPI>(
	"entity/quotes/updateQuoteDealerId",
	async (quoteItems: UpdateQuoteDealerIdInput, { getState, rejectWithValue }) => {
		const {
			quoteId,
			dealerId
		} = quoteItems;

		if (!quoteId) return rejectWithValue("No Quote ID Provided");

		try {
			const quote = selectQuoteFromId(quoteId)(getState() as AppState);

			if (!quote) {
				throw new Error(`Quote ${quoteId} not found`);
			}

			await apiCalls.assignDealer(dealerId, quoteId);

			const newQuote = { ...quote, dealer_id: dealerId };
			return newQuote;
		} catch (err: any) {

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}
	});
export const RefreshQuotePricing = createAsyncThunk<QuotePricingVM | undefined, {
	quoteId: number,
}, ThunkAPI
>(
	"entity/quotes/refreshQuotePricing",
	async (quoteItems, { rejectWithValue }) => {
		const {
			quoteId
		} = quoteItems;

		if (!quoteId) return rejectWithValue("No Quote ID Provided");
		try {
			const goods = await apiCalls.getQuotePricing(quoteId);

			return goods.data.pricing;
		} catch (err: any) {

			const errorMessage = extractErrorMessage(err);
			rejectWithValue(errorMessage);
			return void 0;
		}
	});

export const setQuoteToEditable = createAction<number>("entity/quotes/setEditable");
export const setQuoteToUnEditable = createAction<number>("entity/quotes/setUnEditable");
export const reloadQuote = createAction<number>("entity/quotes/reloadQuote");

export const updateQuoteAddress = createAsyncThunk<QuoteStore, { quoteId: number, address: Address, addressType: "property" | "shipping" }, ThunkAPI>(
	"entity/quotes/updateQuoteAddress",
	async ({ quoteId, address, addressType }, { getState, rejectWithValue }) => {
		if (!quoteId) return rejectWithValue("No Quote ID Provided");

		try {
			const quote = selectQuoteFromId(quoteId)(getState() as AppState);

			if (!quote) {
				throw new Error(`Quote ${quoteId} not found`);
			}

			/*
Address = {
	id?: number;
	first_name?: string;
	last_name?: string;
	company_name?: string;
	address: string;
	address2?: string;
	phone?: string;
	email?: string;
	city?: string;
	state_code: string;
	state: string;
	zip: string;
	country: string;
	country_subdivision_id?: number;
	// ? The call I checked had a null for this, but this might be a 1 / 0 response too.
	is_residential?: boolean;
	lift_gate?: number;
	address_id?: number;
	snapshot_id?: number;
			*/

			const addressId = address.id ?? 0;
			const cleanAddress: Address = {
				id: addressId,
				address_id: addressId,
				address: address.address ?? "",
				address2: address.address2 ?? "",
				phone: address.phone ?? "",
				email: address.email ?? "",
				city: address.city ?? "",
				state_code: address.state_code ?? "",
				state: address.state ?? "",
				zip: address.zip ?? "",
				country: address.country ?? "",
				country_subdivision_id: address.country_subdivision_id ?? 0,
				is_residential: address.is_residential ?? false,
				lift_gate: address.lift_gate ?? 0,
				snapshot_id: address.snapshot_id ?? 0,
				company_name: address.company_name ?? "",
				first_name: address.first_name ?? "",
				last_name: address.last_name ?? "",

			};


			const resp = await apiCalls.updateQuoteAddress(quoteId, cleanAddress, addressType);

			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			const newQuote = { ...quote, [`${addressType}_address_id`]: addressId, [`${addressType}_address`]: resp.data.address };
			return newQuote;
		} catch (err: any) {
			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}
	});



export const quoteBuilder: extraEntityReducersFunction = (builder) => {
	// * Start Fulfilled Promises
	builder.addCase(loadQuoteIds.fulfilled, (state, action) => {
		state.quotes.list = { ...state.quotes.list, ...action.payload };
		state.quotes.loadStatus = loadStatus.needsLoaded;
	});

	builder.addCase(loadQuotesMetaData.fulfilled, (state, action) => {
		action.payload.forEach((quote) => {
			if (state.quotes.list[quote.id]?.loadStatus !== loadStatus.metaLoaded) {
				const currentQuote = state.quotes.list[quote.id];
				// If the current quote is has been loaded before, keep the highest quote load status between current and new.
				const loadLevel = (currentQuote?.loadStatus ?? loadStatus.notLoaded) > (quote?.loadStatus ?? loadStatus.notLoaded) ? currentQuote?.loadStatus : quote?.loadStatus;
				state.quotes.list[quote.id] = { ...currentQuote, ...quote, loadStatus: (loadLevel ?? loadStatus.notLoaded), created: quote.created, updated: quote.updated};
			}
		});
		state.quotes.loadStatus = loadStatus.metaLoaded;
	});

	builder.addCase(loadQuoteCounts.fulfilled, (state, action) => {
		state.quotes.counts.list = action.payload.list;
		state.quotes.counts.loadStatus = action.payload.loadStatus;
	});

	builder.addCase(loadQuoteTypes.fulfilled, (state, action) => {
		state.quotes.types.list = action.payload;
		state.quotes.types.loadStatus = loadStatus.fullyLoaded;
	});

	builder.addCase(loadShipments.fulfilled, (state, action) => {
		const quote = state.quotes.list[action.meta.arg];
		const shipments = [...action.payload];

		if (quote) {
			quote.shipments = shipments;
		}
		// state.quotes.list[action.meta.arg].shipments = action.payload;
	});

	builder.addCase(loadQuoteById.fulfilled, (state, action) => {
		const [quote, multipliers, combinations] = action.payload;
		const currentQuote = state.quotes.list[quote.id];
		const currentQuoteDates = {
			order_approved_time: currentQuote?.order_approved_time,
			created: currentQuote?.created,
			updated: currentQuote?.updated,
			mfg_assigned_time: currentQuote?.mfg_assigned_time,
			ordered_time: currentQuote?.ordered_time,
			order_shipped_time: currentQuote?.order_shipped_time,
			order_status: currentQuote?.order_status ?? "active_quote"
		}
		state.quotes.list[quote.id] = { ...currentQuote, ...quote, ...currentQuoteDates };

		state.quoteMulitpliers[quote.id] = {
			currentMultiplier: "MSRP",
			list: multipliers,
			loadStatus: loadStatus.metaLoaded
		};

		state.quoteAssemblyCombinations[quote.id] = combinations;


	});

	// builder.addCase(handleQuoteArchive.fulfilled, (state, action) => {
	// 	if (!action.payload) return;
	// 	const workingQuote = state.quotes.list[action.meta.arg];
	// 	if (!workingQuote) return;
	// 	delete state.quotes.list[action.meta.arg];
	// });

	// * Start Pending Promises
	builder.addCase(loadQuotesMetaData.pending, (state, _) => {
		state.quotes.loadStatus = loadStatus.loading;

		const quotes = state. quotes.list;

		_.meta.arg.forEach((quoteId) => {
			let quote = quotes[quoteId];
			if (!quote) {
				quote = initialQuote(quoteId);
			}
			quote.loadStatus = loadStatus.loading;

			quotes[quoteId] = quote;
		});

	});

	builder.addCase(repriceQuote.pending, (state, action) => {
		const quoteAssembliesToChangeList = state.assemblies[action.meta.arg.quoteId];
		if (!quoteAssembliesToChangeList) return;
		const quoteAssembliesToChange = Object.values(quoteAssembliesToChangeList.Items);
		quoteAssembliesToChange.forEach((assembly) => {
			assembly.last_time_priced_value_saved = (assembly?.last_time_priced ?? 0) + 3;
		});
	});

	// builder.addCase(loadQuoteCounts.pending, (state, _) => {
	// 	state.quotes.counts.loadStatus = loadStatus.loading;
	// });

	builder.addCase(loadQuoteTypes.pending, (state, _) => {
		state.quotes.types.loadStatus = loadStatus.loading;
	});

	builder.addCase(loadQuoteById.pending, (state, action) => {
		let workingQuote = state.quotes.list[action.meta.arg];
		if (!workingQuote) {
			workingQuote = initialQuote(action.meta.arg)
			state.quotes.list[action.meta.arg] = workingQuote;
		};
		workingQuote.loadStatus = loadStatus.loading;
	});

	builder.addCase(updateQuoteMeta.fulfilled, (state, action) => {
		const quote = action.payload;
		quote.loadStatus = loadStatus.needsLoaded;
		const workingQuote = state.quotes.list[quote.id];
		if (!workingQuote) return;
		state.quotes.list[quote.id] = quote;
	});
	builder.addCase(RefreshQuotePricing.fulfilled, (state, action) => {
		const quotePricing = action.payload;
		const quoteId = action.meta.arg.quoteId;

		if (!quotePricing) return;
		const workingQuote = state.quotes.list[quoteId];
		if (!workingQuote) return;

		workingQuote.freight_costs = quotePricing.oversized_shipping;
		workingQuote.shipping_snapshot = quotePricing.parcel_shipping;
		workingQuote.msrp_snapshot = quotePricing.subtotal;
		workingQuote.sales_tax_snapshot = quotePricing.total_taxes;
		workingQuote.sales_tax_percentage_snapshot = quotePricing.tax_rate;
		workingQuote.sales_tax_on_shipping_snapshot = quotePricing.is_shipping_taxed;

		// It's a real app store, but missing uneeded properties.
		const fakeAppStore = { entity: state } as AppState;

		quotePricing.assemblies.forEach((assembly) => {
			const workingAssembly = SelectAssembly(quoteId, assembly.sequence_id)(fakeAppStore);
			if (!workingAssembly) return;

			workingAssembly.msrp = assembly.msrp;
			workingAssembly.cost = assembly.cost;
			workingAssembly.shipping = assembly.shipping;
			workingAssembly.recipe_sku = assembly.recipe_sku;
			workingAssembly.will_be_seamed = assembly.has_seam;
			workingAssembly.has_sag_warning = assembly.has_sag;
			workingAssembly.fabric_will_rub = assembly.has_fabric_will_rub;
			workingAssembly.last_time_priced = assembly.time_priced;
			workingAssembly.error_messages = assembly.error_messages;
			workingAssembly.last_time_priced_value_saved = assembly.time_price_value_changed
			workingAssembly.front_row_torque = assembly.front_row_torque;
			workingAssembly.back_row_torque = assembly.back_row_torque;
			workingAssembly.front_row_weight = assembly.front_row_weight;
			workingAssembly.back_row_weight = assembly.back_row_weight;

			assembly.shades.forEach((shade) => {
				const workingShade = workingAssembly.shades.find((s) => s.row_coordinate === shade.row && s.column_coordinate === shade.col);
				if (!workingShade) return;

				workingShade.torque_needed = shade.torque;
				workingShade.roll_diameter = shade.roll_diameter;
				workingShade.deflection = shade.deflection;
				workingShade.clearence_diameter = shade.clearence_diameter;
				workingShade.weight = shade.weight;
				workingShade.tube_name = shade.tube_name;
				workingShade.tube_sku = shade.tube_sku;
				workingShade.weight = shade.weight
				workingShade.bearing_pin_sku = shade.bearing_pin;
				workingShade.motor_type = shade.drive_type;
				workingShade.motor = shade.drive;
			});


		});

		quotePricing.accessories.forEach((accessory) => {
			const workingAccessory = selectQuoteAccessories(quoteId)(fakeAppStore).find((a) => a.line_number === accessory.line_number);
			if (!workingAccessory) return;

			workingAccessory.cost = accessory.cost;
			workingAccessory.msrp = accessory.msrp;
			workingAccessory.shipping = accessory.shipping;
		});

		quotePricing?.hardware_request_items?.forEach((hardware) => {
			const workingHardware = selectHWRItemsListByQuoteId(quoteId)(fakeAppStore).find((a) => a.line_number === hardware.line_number);
			if (!workingHardware) return;

			workingHardware.cost = hardware.cost;
			workingHardware.msrp = hardware.msrp;
			workingHardware.shipping = hardware.shipping;
		});

	});

	builder.addCase(setQuoteToEditable, (state, action) => {
		const workingQuote = state.quotes.list[action.payload];
		if (!workingQuote) return;
		workingQuote.editable = true;
	});

	builder.addCase(setQuoteToUnEditable, (state, action) => {
		const workingQuote = state.quotes.list[action.payload];
		if (!workingQuote) return;
		workingQuote.editable = false;
	});

	builder.addCase(updateQuoteTerritoryId.fulfilled, (state, action) => {
		const quote = action.payload;
		const workingQuote = state.quotes.list[quote.id];
		if (!workingQuote) return;
		state.quotes.list[quote.id] = quote;
	});

	builder.addCase(updateQuoteDealerId.fulfilled, (state, action) => {
		const quote = action.payload;
		const workingQuote = state.quotes.list[quote.id];
		if (!workingQuote) return;
		state.quotes.list[quote.id] = quote;
	});

	builder.addCase(reloadQuote, (state, action) => {
		const workingQuote = state.quotes.list[action.payload];
		if (!workingQuote) return;
		if (workingQuote.loadStatus === loadStatus.fullyLoaded) {
			workingQuote.loadStatus = loadStatus.needsLoaded;
		}
	});

	builder.addCase(updateQuoteAddress.pending, (state, action) => {
		const workingQuote = state.quotes.list[action.meta.arg.quoteId];
		if (!workingQuote) return;
		workingQuote[`${action.meta.arg.addressType}_address`] = action.meta.arg.address[`${action.meta.arg.addressType}_address`];

	});
	builder.addCase(updateQuoteAddress.fulfilled, (state, action) => {
		const quote = action.payload;
		const workingQuote = state.quotes.list[quote.id];
		if (!workingQuote) return;
		workingQuote[`${action.meta.arg.addressType}_address`] = action.payload[`${action.meta.arg.addressType}_address`];
		workingQuote[`${action.meta.arg.addressType}_address_id`] = action.payload[`${action.meta.arg.addressType}_address_id`];
		state.quotes.list[quote.id] = quote;
	});


};

