import React, { useEffect, Dispatch, SetStateAction, useState } from "react";
// import { useAssembliesByQuoteId } from "../Store/entities/assemblies/hooks";
import Table from '../Parts/Table';
import { useQuoteIdAndDispatch, useQuoteTypeNameByQuoteId, useQuoteTypes } from "../Store/entities/quotes/hooks";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../Parts/Button";
import { faEdit, faLock, faTrashAlt, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { QuoteTypeNames } from "../powershadesApiTypes";
import type { QuoteData } from "./NewItems/QuoteData";
import { UsePortalDispatch, UsePortalSelector } from "../Store";
import { deleteAssemblies, selectIndoorAssembliesByQuoteId, setAssembliesSelected, setAssembliesUnlocked, updateAssemblies } from "../Store/entities/assemblies";
import { fakeRootType } from "../FakeRootType";
import { useIsPsAdmin } from "../hooks";
import IndoorTableRow from "./IndoorTableRow";
import { useCurrentMultiplier } from "../Store/entities/multipliers/hooks";
import FabricSelectField from "./NewOptionFields/FabricSelectField";
import { useIndoorFabrics } from "../Store/entities/hooks";
import FractionField from "./NewOptionFields/FractionField";
import SelectBox from "./NewItems/SelectBox";
import MassEditIndoorModal from "../Modals/ShadeModals/EditShade/EditIndoorShade/MassEditIndoor";

// import { FabricViewModel, loadStatusType} from "../powershadesApiTypes";
// import apiCalls from "../PowerShadesAPIFunctions";

type IndoorTableProps = {
    quoteId: number;
    unlockShadeBySequenceId: (sequenceId: number) => void;
    quoteData: QuoteData;
    fcState: [any[], Dispatch<SetStateAction<any[]>>];
}


const IndoorTable = (props: IndoorTableProps) => {
    const {
        quoteId,
        unlockShadeBySequenceId,
        quoteData,
        fcState
    } = props;

    const isPsAdmin = useIsPsAdmin();

    const indoorAssemblies = UsePortalSelector(selectIndoorAssembliesByQuoteId(quoteId));
    const selectedIndoorAssemblies = indoorAssemblies.filter((assembly) => assembly.selected);

    const indoorFabrics = useIndoorFabrics(quoteId);

    // const [allowedIndoorFabrics, setAllowedIndoorFabrics] = useState<FabricViewModel[]>([]);

//    // Fetch allowed indoor fabrics from the backend
//    useEffect(() => {
//     const fetchAllowedIndoorFabrics = async () => {
//         try {
//             const response = await apiCalls.getFabricIndoorAllowedList(quoteId);
//             setAllowedIndoorFabrics(response.data.fabrics); // Store the fetched fabrics in state
//         } catch (error) {
//             console.error("Error fetching allowed indoor fabrics:", error);
//         }
//     };

//     fetchAllowedIndoorFabrics();
//     }, [quoteId]);


//     // Combine fetched allowed fabrics with the indoor fabrics
//     // Mapping to FabricStore when is FabricViewModel
//     const combinedIndoorFabrics = [
//         ...indoorFabrics,
//         ...allowedIndoorFabrics.map(fabric => ({
//             name: fabric.name,
//             imageUrl: fabric.image_url || "", 
//             imageLoaded: 1 as loadStatusType, 
//             isNextLoad: false, 
//             fabric_color: "", 
//             fabric_pattern: "", 
//             is_indoor: fabric.is_indoor,
//             is_outdoor: fabric.is_outdoor,
//             is_psoutdoor: fabric.is_psoutdoor,
//             is_railroadable: fabric.is_railroadable,
//             is_seamable: fabric.is_seamable,
//             is_discontinued: fabric.is_discontinued,
//             is_gone: fabric.is_gone
//         }))
//     ];


    // Gio Test Add
    useEffect(() => {
        //console.log("IndoorTable ***** QuoteID:", quoteId);
        // console.log("IndoorTable ***** FabricsIndoorAllowed:", allowedIndoorFabrics);
       // console.log("IndoorTable ***** Indoor Fabrics:", indoorFabrics);
        // console.log("IndoorTable ***** CombinedIndoorFabrics:", combinedIndoorFabrics);
    }, [indoorFabrics]); 

    const quoteMeta = useQuoteIdAndDispatch(quoteId);
    const quoteTypeId = quoteMeta?.quote_type_id;
    const quoteTypes = useQuoteTypes();
    const currentQuoteType = (quoteTypes.find((qt) => qt.id === quoteTypeId)?.name ?? "CEDIA") as QuoteTypeNames;
    // const selectedAssemblies = 
    const isEditable = quoteMeta?.editable ?? false;

    const dispatch = UsePortalDispatch();
    const quoteType = useQuoteTypeNameByQuoteId(quoteId);
    const isAnyIndoorDual = indoorAssemblies.some((a) => a.is_dual);

    const currentMultiplier = useCurrentMultiplier(quoteId);
    const { label, currency } = currentMultiplier;

    const [massEditOpen, setMassEditOpen] = useState(false);

    const columns: {
        displayName: string;
    }[] = [
            {
                displayName: "Unlock/Select",
            },
            {
                displayName: "Room Name",
            },
            {
                displayName: "Shade Name",
            },
            {
                displayName: "Shade Type",

            },
            ...(isAnyIndoorDual ? [{ displayName: "Inside Fabric" }, { displayName: "Outside Fabric" }] : [{ displayName: "Fabric" }]),
            {
                displayName: "Width",

            },
            {
                displayName: "Height",

            },
            {
                displayName: `${label} (${currency})`
            },
            ...(isPsAdmin ? [{ displayName: "Shipping" }] : []),
            {
                displayName: "Actions",
            }
        ];

    const {
        alert,
        toast
    } = quoteData.quoteLevelVariables().ROOT as fakeRootType;

    const selectedAssemblySeqIds = selectedIndoorAssemblies.map((a) => a.sequence_id);
    const onDelete = () => {
        const deletingShadeCount = selectedIndoorAssemblies.length;
        alert({
            title: 'Are you sure you want to delete this shade?',
            text: `You are about to delete ${deletingShadeCount} shades! There's no way to undo this.`,
            icon: 'warning',
            quoteType: quoteType,
        })
            .then(() => {
                dispatch(deleteAssemblies({
                    quoteId: quoteId,
                    sequenceIds: selectedAssemblySeqIds,
                })).then(() => {
                    toast(`${deletingShadeCount} shades deleted`, {
                        type: 'success',
                    });
                }).catch(() => {
                    toast(`Deletion of ${deletingShadeCount} shades failed`, {
                        type: 'info',
                    });
                });

            })
            .catch(() => {
                toast(`Deletion of ${deletingShadeCount} shades canceled`, {
                    type: 'info',
                });
            });
    }

    const massTableAction = (
        <Container className="local-bootstrap">
            <Row className="g-0">
                <Col key="a1">
                    <Button
                        title="Mass Edit"
                        size="sm"
                        hoverColor={currentQuoteType}
                        fullWidth
                        color="light"
                        onClick={() => setMassEditOpen(true)}
                    >
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                </Col>
                <Col key="a2">
                    <Button
                        title="Delete"
                        size="sm"
                        hoverColor={currentQuoteType}
                        fullWidth
                        color="light"
                        onClick={() => onDelete()}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Col>
            </Row>

        </Container>
    )

    const isAllIndoorShadesUnlocked = indoorAssemblies.every((a) => a.unlocked);
    const unlockAllShades = () => {
        dispatch(setAssembliesUnlocked({
            quoteId: quoteId,
            sequenceIds: indoorAssemblies.map((a) => a.sequence_id),
            unlocked: true,
        }));
    }

    const optionInitState = {
        front_fabric_name: "",
        back_fabric_name: "",
        room_name: "",
        shade_type: "",
        width: 0,
        height: 0
    };

    const [option, setOption] = useState(optionInitState);
    const setOptionPartial = (newOption?: Partial<typeof option>) => {
        newOption && setOption({ ...option, ...newOption });
    };

    const handleMassEditSubmit = () => {
        if (JSON.stringify(option) !== JSON.stringify(optionInitState)) {
            // const optionInitState = { ...InitialAssemblyState(), unlocked: true, shades: [InitialShadeState()], quote_id: quoteId };
            let selectedAssemblies = selectedIndoorAssemblies

            Object.entries(optionInitState).forEach(([key, value]) => {
                if (option[key] === value) {
                    return;
                }
                if(key === "front_fabric_name"){
                    selectedAssemblies = selectedAssemblies.map((selectedAssy) => ({ ...selectedAssy, shades: selectedAssy.shades.map((shade) => shade.row_coordinate === 0 ?  ({ ...shade, ["fabric_name"]: option[key] }) : shade) }));
                } else if (key === "back_fabric_name") {
                    selectedAssemblies = selectedAssemblies.map((selectedAssy) => ({ ...selectedAssy, shades: selectedAssy.shades.map((shade) => shade.row_coordinate === 1 ?  ({ ...shade, ["fabric_name"]: option[key] }) : shade) }));
                } else if(key === "width" || key === "height"){
                    selectedAssemblies = selectedAssemblies.map((selectedAssy) => ({ ...selectedAssy, shades: selectedAssy.shades.map((shade) => ({ ...shade, [key]: option[key] }) )}));
                } else{
                    selectedAssemblies = selectedAssemblies.map((selectedAssy) => ({ ...selectedAssy, [key]: option[key] }));
                }
                
            });
            dispatch(updateAssemblies({
                quoteId,
                assemblies: selectedAssemblies
            }));

            setOption(optionInitState);
        }
    };

    if (indoorAssemblies.length === 0) {
        return null;
    }


    return (<div
        className="quote-table "
        style={{
            overflowX: 'auto', overflowY: 'hidden', marginBottom: '0.25em'
        }}
    >
        {isEditable && <Table
            quoteType={currentQuoteType}
            className="quote-mass-table-indoor"
            noTBody
            style={{
                width: '100%', zIndex: '22', marginBottom: '0.5em'
            }}
        >
            <tbody style={{ zIndex: 900 }}>

                <tr className="bold-row">
                    <th className={currentQuoteType} colSpan={12}>
                        {/* {buttonAdd ? <div className="table-to-right">{buttonAdd}</div> : null} */}
                        <div className={`table-header ${currentQuoteType}`}>{"Indoor Shades"}</div>
                    </th>
                </tr>
                <tr>
                    <th colSpan={1}>
                        Select
                    </th>
                    <th colSpan={1}>
                        Room Name
                    </th>
                    <th colSpan={1}>
                        Shade Name
                    </th>
                    <th colSpan={1}>
                        Shade Type
                    </th>
                    {isAnyIndoorDual ? <th colSpan={1}>
                        Inside Fabric
                    </th> : <th colSpan={1}>
                        Fabric
                    </th>}
                    {isAnyIndoorDual && <th colSpan={1}>
                        Outside Fabric
                    </th>}
                    <th colSpan={1}>
                        Width
                    </th>
                    <th colSpan={1}>
                        Height
                    </th>
                    <th colSpan={1}>
                        Actions
                    </th>
                    <th colSpan={1}>
                        Apply
                    </th>
                </tr>
                <tr>
                    <th colSpan={1}>
                        <SelectBox
                            onChange={(e) => {
                                const sequenceIds: number[] = [];
                                (indoorAssemblies.filter((a) => a.unlocked)
                                    .map((fa) => sequenceIds.push(fa.sequence_id)));
                                dispatch(setAssembliesSelected({
                                    quoteId, sequenceIds, selected: e.target.checked
                                }));
                            }}
                        />

                    </th>
                    <th colSpan={1}>
                        <input
                            type="text"
                            value={option.room_name}
                            onChange={(e) => setOptionPartial({ room_name: e.target.value })}
                        />
                    </th>
                    <th colSpan={1}></th>
                    <th colSpan={1}>
                        <select
                            value={option.shade_type}
                            onChange={(e) => setOptionPartial({ shade_type: e.target.value })}
                        >
                            <option value=""> </option>
                            <option value="single">Single</option>
                            <option value="dual">Dual</option>
                            <option value="coupled">Coupled</option>
                            <option value="dual_coupled">Dual Coupled</option>
                        </select>
                    </th>
                    <th colSpan={1}>
                        <FabricSelectField
                            value={option.front_fabric_name}
                            choices={indoorFabrics}
                            onChange={(newFabricName) => setOptionPartial({ front_fabric_name: newFabricName })}
                        />
                    </th>
                    {isAnyIndoorDual && <th colSpan={1}>
                        <FabricSelectField
                            value={option.back_fabric_name}
                            choices={indoorFabrics}
                            onChange={(newFabricName) => setOptionPartial({ back_fabric_name: newFabricName })}
                        />
                    </th>}
                    <th colSpan={1}>
                        <FractionField
                            key={`width-mass-edit`}
                            value={option.width}
                            onBlur={(newWidth) => setOptionPartial({ width: newWidth })}
                            varName="width"
                            disabled={false}
                            minValue={0}
                            maxValue={240}
                        />
                    </th>
                    <th colSpan={1}>
                        <FractionField
                            key={`height-mass-edit`}
                            value={option.height}
                            onBlur={(newHeight) => setOptionPartial({ height: newHeight })}
                            varName="height"
                            disabled={false}
                            minValue={0}
                            maxValue={240}
                        />
                    </th>
                    <th colSpan={1}>{massTableAction}</th>
                    <th colSpan={1}>
                        <Button
                            title="Submit"
                            size="sm"
                            hoverColor={currentQuoteType}
                            fullWidth
                            color="light"
                            onClick={handleMassEditSubmit}
                            disabled={JSON.stringify(option) === JSON.stringify(optionInitState)}
                        >
                            Apply
                        </Button>
                    </th>
                </tr>
            </tbody>
        </Table>}
        <Table
            quoteType={currentQuoteType}
            className="quote-rows-table-indoor"
            noTBody
            style={{ width: '100%', zIndex: '22' }}>
            <tbody>
                <tr className="bold-row">
                    <th className={currentQuoteType} colSpan={12}>
                        <div className="table-to-right">
                            {isEditable && (
                                <span title={`unlock`} onClick={() => (isAllIndoorShadesUnlocked ? () => { } : unlockAllShades())} className=" fa-fw">
                                    <FontAwesomeIcon className="icon-unlock unlock-all" transform="x-12 grow-6" icon={
                                        isAllIndoorShadesUnlocked ? faUnlock : faLock
                                    } />
                                </span>
                            )}
                        </div>
                        <div className={`table-header ${quoteType}`}>{"Indoor Shades"}</div>
                    </th>
                </tr>
                <tr>
                    {columns.map((column) => (
                        <th key={column.displayName}>
                            {column.displayName}
                        </th>
                    ))}
                </tr>
                {indoorAssemblies.map((assembly) => (
                    <IndoorTableRow
                        quoteData={quoteData}
                        unlockShadeBySequenceId={unlockShadeBySequenceId}
                        quoteId={quoteId}
                        sequenceId={assembly.sequence_id}
                        FCState={fcState}
                    />

                ))}
            </tbody>
        </Table>
        <MassEditIndoorModal
            quoteId={quoteId}
            isOpen={massEditOpen}
            onClose={() => setMassEditOpen(false)}
        />
    </div>)

}

export default IndoorTable;


