/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-use-before-define */

import { InitialAssemblyState, InitialShadeState } from ".";
import {
	AssemblyStore,
	CurrentPSOutdoorShadeOptionsFunc,
	PSOutdoorControlSide,
	PSOutdoorHeaderMountType,
	PSOutdoorHeaderTypeSku,
	PortalShadeOptionItem,
	PSOutdoorShadeOptionsDict,
	PSOutdoorShadeOptionsFunc,
	PSOutdoorSideGuideMountTypes,
	PSOutdoorSideGuides,
	CardinalDirectionType,
	PortalShadeOption
} from "./types";
import { UseAssembly } from "./hooks";

import TopBoxHeaderMount from "../../../assets/img/outdoor_diagrams/header_mount/box-top-fix.jpg";
import SideBoxHeaderMount from "../../../assets/img/outdoor_diagrams/header_mount/box-side-fix.jpg";
import FaceBoxHeaderMount from "../../../assets/img/outdoor_diagrams/header_mount/box-face-fix.jpg";
import TopOpenRollHeaderMount from "../../../assets/img/outdoor_diagrams/header_mount/open-roll-top-fix.jpg";
import SideOpenRollHeaderMount from "../../../assets/img/outdoor_diagrams/header_mount/open-roll-side-fix.jpg";
import FaceOpenRollHeaderMount from "../../../assets/img/outdoor_diagrams/header_mount/open-roll-face-fix.jpg";
import { usePSOutdoorFabrics } from "../hooks";
import { useUser } from "../../user/hooks";
import { useQuoteById } from "../quotes/hooks";
import { UsePortalSelector } from "../..";
import { useAssemblyOptions, useGetColorOptions, useGetFullPaletteList } from "../assemblyOptions/hooks";

const useCurrentPSOutdoorShadeOptions: CurrentPSOutdoorShadeOptionsFunc = (quoteId) => (sequenceId) => {
	const assembly = UseAssembly(quoteId, sequenceId) ?? InitialAssemblyState();

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const setAssembly = (assembly: AssemblyStore) => {
		console.log(assembly);
	};

	// console.log('🔹🔹🔹🔹🔹🔹 PsOutdoorHooks', quoteId);
	const fabrics = usePSOutdoorFabrics(quoteId);
	const userData = useUser();
	const quote = useQuoteById(quoteId);
	const assemblyOptions = UsePortalSelector(useAssemblyOptions(quoteId));

	return useNewPSOutdoorShadeOptions(assembly, setAssembly, { fabrics, user: userData, quote, assemblyOptions });
};

// Generic Factory Function to make one list of options to rule them all.
const useNewPSOutdoorShadeOptions: PSOutdoorShadeOptionsFunc = (assembly, setAssemblyInit, externalData) => {
	const headers = externalData?.assemblyOptions?.headers?.filter((h) =>
		h?.series_attatched_to_header?.includes("outdoor_roller_shade") ?? false) ?? [];
	const fullPaletteList = externalData?.assemblyOptions?.full_palette_list ?? [];
	const colorDetails = externalData?.assemblyOptions?.color_details ?? [];

	const headerTypeOptions = headers.map((header) => ({
		label: header.name,
		value: header.name as PSOutdoorHeaderTypeSku,
		title: header.name
	}));

	const getColorOptions = useGetColorOptions(assembly.quote_id);

	const getPaletteList = useGetFullPaletteList(assembly.quote_id);

	const currentHeader = headers.find((header) => header.name === assembly.header_line);

	const controlSides = currentHeader?.control_sides ?? [];
	const controlSideOptions = controlSides.map((controlSide) => ({
		label: controlSide.name,
		value: controlSide.name as PSOutdoorControlSide,
		title: controlSide.name
	}));

	const firstShade = assembly.shades[0] ?? InitialShadeState(); // Should be the only shade.

	const setAssembly = (editableNewAssemblyInit: Partial<AssemblyStore>) => {
		const editableNewAssembly = { ...assembly, ...editableNewAssemblyInit };

		if (!editableNewAssemblyInit?.colors) {
			const palettesUsed = Object.entries(assembly.colors).map((c) => ({ name: c[0], value: c[1] }));
			const fullColorsListExpected = getPaletteList(assembly);

			const colorsToRemove = palettesUsed.filter((pu) => !fullColorsListExpected.some((fcl) =>
				fcl.name === pu.name));
			const colorsToAdd = fullColorsListExpected.filter((fcl) => !palettesUsed.some((pu) =>
				pu.name === fcl.name));
			
			const isCustomPalette = assembly.palette_color === "Custom Palette";
			const defaultColors = currentHeader?.default_color_palettes?.find((dcp) =>
				dcp.name === assembly.palette_color)?.defaultColors ?? {};
			
			colorsToAdd.forEach((c) => {
				const defaultColor = defaultColors[c.name] ?? headers?.find((hc) =>
					hc.default_color_palettes.some((dfc) => dfc.name === c.name))?.[0] ?? "";
				editableNewAssembly.colors = {
					...editableNewAssembly.colors,
					[c.name]: isCustomPalette ? "" : defaultColor
				};
			});

			const newColorObject = {
				...assembly.colors,
				...editableNewAssembly.colors
			};

			colorsToRemove.forEach((c) => {
				const { colors } = assembly;
				if (!colors) return;
				delete newColorObject[c.name];
			});

			editableNewAssembly.colors = newColorObject;
		}

		setAssemblyInit(editableNewAssembly);
	};

	const fabricList = externalData?.fabrics ?? [];

	const fabricOptions: PortalShadeOptionItem<string>[] = fabricList.map((fabric) => ({
		label: fabric.name,
		value: fabric.name,
		title: fabric.name
	}));

	// Header Specific Options
	const isBoxHeader = assembly.header_line?.includes("Box");

	const headerMountTypes = currentHeader?.mount_types ?? [];
	const mountTypeOptions = headerMountTypes.map((mountType) => ({
		label: mountType.name,
		value: mountType.name as PSOutdoorHeaderMountType,
		title: mountType.name
	}));

	const sideChannels = currentHeader?.channels ?? [];
	const sideGuideOptions = sideChannels.map((sideChannel) => ({
		label: sideChannel.name,
		value: sideChannel.value as PSOutdoorSideGuides,
		title: sideChannel.name
	}));

	const currentChannel = sideChannels.find((sideChannel) => sideChannel.value === assembly.side_channels);

	const sideChannelMountTypes = currentChannel?.mount_types ?? [];
	const sideMountTypeOptions = sideChannelMountTypes.map((mountType) => ({
		label: mountType.name,
		value: mountType.name as PSOutdoorSideGuideMountTypes,
		title: mountType.name
	}));

	const directionFacings = externalData?.assemblyOptions?.direction_facing ?? [];
	const directionFacingOptions = directionFacings.map((directionFacing) => ({
		label: directionFacing.name,
		value: directionFacing.name,
		title: directionFacing.name
	}));

	const colorPalettes = getColorOptions(assembly);

	const colorPaletteOptions = colorPalettes.map((cp) => ({
		label: cp.name,
		value: cp.name,
		title: cp.name,
		default_colors: cp.defaultColors,
		hexCode: colorDetails.find((color) =>
			color.name.toLocaleLowerCase() === cp.name.toLocaleLowerCase())?.hexcode || "transparent"
	})) ?? [];

	const colorSelections = getPaletteList(assembly).map((colPal) => {
		const colorChoices = colPal.choices.map((cc) => ({
			label: cc,
			value: cc,
			title: cc,
			hexCode: colorDetails.find((color) =>
				color.name.toLocaleLowerCase() === cc.toLocaleLowerCase())?.hexcode
            || "transparent"
        
		}));

		const colorOption: PortalShadeOption<string> = {
			label: colPal.name,
			options: colorChoices,
			clearValue: () => {
				setAssembly({
					[colPal.name]: ""
				});
			},
			setValue: (value) => {
				const colors = { ...assembly.colors };
				colors[colPal.name] = value;
				setAssembly({
					colors
				});
			},
			getSelectedOption: () => colorChoices.find((opt) => (
				opt.value?.toLowerCase() === assembly.colors[colPal.name]?.toLowerCase()
			)) as PortalShadeOptionItem<string>,
			varName: "colors",
			error: [],
			getValue: () => assembly.colors[colPal.name] ?? "",
			valuePopup: colorChoices,
			info: [],
		};

		return colorOption;
	}) ?? [];

	const currentPalette = colorPaletteOptions?.find((colPal) => colPal.value === assembly.palette_color);

	const hasPaletteBeenAltered = Object.keys(currentPalette?.default_colors ?? {}).some((key) => {
		if (!assembly.colors[key]) return false;

		const currentColor = assembly.colors[key];
		const paletteColor = currentPalette?.default_colors[key];

		if (paletteColor === "" || paletteColor === "Missing Default Color") return false;

		return currentColor !== paletteColor;
	});

	const colorPaletteOptionAll = [
		...colorPaletteOptions,
		{
			label: "Custom Palette",
			value: "Custom Palette",
			title: "Custom Palette",
			default_colors: {},
		}
	];

	let options: PSOutdoorShadeOptionsDict | null = null;

	options = {
		Width: {
			varName: "Width",
			label: "Width",
			error: [],
			info: [],
			minValue: 27,
			maxValue: 238,
			getValue(): number {
				return firstShade.width;
			},
			setValue(newVal: number): void {
				let newWidth = newVal;
				const minVal = options?.Width.minValue ?? 0;
				const maxVal = options?.Width.maxValue ?? 0;
				if (newWidth < minVal) newWidth = minVal;
				if (newWidth > maxVal) newWidth = maxVal;

				setAssembly({
					shades: [
						{
							...firstShade,
							width: newWidth
						}
					]
				});
			},
			getSelectedOption: () => ({
				label: firstShade.width.toString(),
				value: firstShade.width,
				title: firstShade.width.toString()
			}),
			clearValue: () => {
				setAssembly({
					shades: [
						{
							...firstShade,
							width: 0
						}
					]
				});
			},

		},
		Height: {
			varName: "height",
			label: "Height",
			error: [],
			info: [],
			minValue: 12,
			maxValue: 240,
			getValue(): number {
				return firstShade.height;
			},
			setValue(newVal: number): void {
				let newHeight = newVal;
				const minVal = options?.Height.minValue ?? 0;
				const maxVal = options?.Height.maxValue ?? 0;
				if (newHeight < minVal) newHeight = minVal;
				if (newHeight > maxVal) newHeight = maxVal;

				setAssembly({
					shades: [
						{
							...firstShade,
							height: newHeight
						}
					]
				});
			},
			getSelectedOption: () => ({
				label: firstShade.height.toString(),
				value: firstShade.height,
				title: firstShade.height.toString()
			}),
			clearValue: () => {
				setAssembly({
					shades: [
						{
							...firstShade,
							height: 0
						}
					]
				});
			}
		},
		HeaderMountType: {
			varName: "header_mount_type",
			label: "Header Mount",
			error: [],
			info: [],
			getValue(): PSOutdoorHeaderMountType {
				return assembly.mount_style as PSOutdoorHeaderMountType;
			},
			setValue: (newVal) => {
				setAssembly({
					mount_style: newVal
				});
			},
			options: mountTypeOptions,
			getSelectedOption: () => mountTypeOptions.find((option) =>
				option.value === assembly.mount_style) as PortalShadeOptionItem<PSOutdoorHeaderMountType>,
			clearValue: () => {
				setAssembly({
					mount_style: ""
				});
			},
			valuePopup: assembly.mount_style === "Top" && isBoxHeader ? TopBoxHeaderMount
				: assembly.mount_style === "Face" && isBoxHeader ? FaceBoxHeaderMount
					: assembly.mount_style === "Side" && isBoxHeader ? SideBoxHeaderMount
						: assembly.mount_style === "Top" && !isBoxHeader ? TopOpenRollHeaderMount
							: assembly.mount_style === "Face" && !isBoxHeader ? FaceOpenRollHeaderMount
								: assembly.mount_style === "Side" && !isBoxHeader ? SideOpenRollHeaderMount : ""

		},
		HeaderType: {
			varName: "header_line",
			label: "Header",
			error: [],
			info: [],
			getValue(): PSOutdoorHeaderTypeSku {
				return assembly.header_line as PSOutdoorHeaderTypeSku;
			},
			setValue(newVal): void {
				const selectedHeader = headers.find((header) => header.name === newVal);
				let hembarLine = "";
				let hembarStyle = "";
				let endcap = "";
				let driveType = "";
				if (selectedHeader?.hembars?.length === 1) 
					hembarLine = selectedHeader?.hembars[0]?.value ?? "";
				if (selectedHeader?.hembars?.length === 1)
					hembarStyle = selectedHeader?.hembars[0]?.hembar_styles[0]?.value ?? "";
				if (selectedHeader?.end_caps?.length === 1)
					endcap = selectedHeader?.end_caps[0]?.value ?? "";
				if (selectedHeader?.drive_types?.length === 1)
					driveType = selectedHeader?.drive_types[0]?.value ?? "";

				setAssembly({
					header_line: newVal,
					end_caps: endcap,
					shades: assembly.shades.map((shade) => ({
						...shade,
						hembar_line: hembarLine,
						hembar_style: hembarStyle,
						motor_type: driveType,
						roll_direction: "standard"
					}))

				});
			},
			options: headerTypeOptions,
			getSelectedOption: () => headerTypeOptions.find((option) =>
				option.value === assembly.header_line) as PortalShadeOptionItem<PSOutdoorHeaderTypeSku>,
			clearValue: () => {
				setAssembly({
					header_line: ""
				});
			}
		},
		// Color: {
		//     varName: "hardware_color",
		//     label: "Color",
		//     error: [],
		//     info: [],
		//     getValue: function (): PSOutdoorColor {
		//         return assembly.hardware_color as PSOutdoorColor;
		//     },
		//     setValue: function (newVal: PSOutdoorColor): void {
		//         setAssembly({
		//             hardware_color: newVal
		//         })
		//     },
		//     options: colorOptions,
		//     getSelectedOption: () => {
		//         return colorOptions.find((option) =
		//  option.value === assembly.hardware_color) as PortalShadeOptionItem<PSOutdoorColor>;
		//     },
		//     clearValue: () => {
		//         setAssembly({
		//             hardware_color: ""
		//         })
		//     }
		// },
		Fabric: {
			varName: "fabric_name",
			label: "Fabric",
			error: [],
			info: [],
			getValue(): string {
				return firstShade.fabric_name as string;
			},
			setValue(newVal: string): void {
				setAssembly({
					shades: [
						{
							...firstShade,
							fabric_name: newVal
						}
					]
				});
			},

			options: fabricOptions,
			getSelectedOption: () => fabricOptions.find((option) =>
				option.value === firstShade.fabric_name) as PortalShadeOptionItem<string>,
			clearValue: () => {
				setAssembly({
					shades: [
						{
							...firstShade,
							fabric_name: ""
						}
					]
				});
			}
		},
		ControlSide: {
			varName: "control_side",
			label: "Control Side",
			error: [],
			info: [],
			getValue(): PSOutdoorControlSide {
				return assembly.control_side as PSOutdoorControlSide;
			},
			setValue(newVal: PSOutdoorControlSide): void {
				setAssembly({
					control_side: newVal
				});
			},
			options: controlSideOptions,
			getSelectedOption: () => controlSideOptions.find((option) =>
				option.value === assembly.control_side) as PortalShadeOptionItem<PSOutdoorControlSide>,
			clearValue: () => {
				setAssembly({
					control_side: ""
				});
			}

		},
		SideGuides: {
			varName: "side_channels",
			label: "Side Guides",
			error: [],
			info: [],
			getValue(): PSOutdoorSideGuides {
				return assembly.side_channels as PSOutdoorSideGuides;
			},
			setValue(newVal: PSOutdoorSideGuides): void {
				setAssembly({
					side_channels: newVal
				});
			},
			options: sideGuideOptions,
			getSelectedOption: () => sideGuideOptions.find((option) =>
				option.value === assembly.side_channels) as PortalShadeOptionItem<PSOutdoorSideGuides>,
			clearValue: () => {
				setAssembly({
					side_channels: ""
				});
			}
		},
		SideGuideMountTypes: {
			varName: "side_channel_mount_type",
			label: "Side Guide Mount Types",
			error: [],
			info: [],
			getValue(): PSOutdoorSideGuideMountTypes {
				return assembly.side_channels_mount_type as PSOutdoorSideGuideMountTypes;
			},
			setValue(newVal: PSOutdoorSideGuideMountTypes): void {
				setAssembly({
					side_channels_mount_type: newVal
				});
			},
			options: sideMountTypeOptions,
			getSelectedOption: () => sideMountTypeOptions.find((option) => (
				option.value === assembly.side_channels_mount_type
			)) as PortalShadeOptionItem<PSOutdoorSideGuideMountTypes>,
			clearValue: () => {
				setAssembly({
					side_channels_mount_type: ""
				});
			}
		},
		RoomName: {
			varName: "room_name",
			label: "Room/Area Name",
			error: [],
			info: [],
			getValue(): string {
				return assembly.room_name as string;
			},
			setValue(newVal: string): void {
				setAssembly({
					room_name: newVal
				});
			},
			getSelectedOption: () => ({
				label: assembly.room_name,
				value: assembly.room_name,
				title: assembly.room_name
			}),
			options: [],
			clearValue: () => {
				setAssembly({
					room_name: ""
				});
			}
		},
		ShadeName: {
			varName: "shade_name",
			label: "Shade Name",
			error: [],
			info: [],
			getValue(): string {
				return assembly.shade_name as string;
			},
			setValue(newVal: string): void {
				setAssembly({
					shade_name: newVal
				});
			},
			options: [],
			getSelectedOption: () => ({
				label: assembly.shade_name,
				value: assembly.shade_name,
				title: assembly.shade_name
			}),
			clearValue: () => {
				setAssembly({
					shade_name: ""
				});
			}
		},
		DirectionFacing: {
			varName: "direction_facing",
			label: "Direction Facing",
			error: [],
			info: [],
			getValue(): string {
				return assembly.direction_facing as string;
			},
			setValue(newVal: string): void {
				setAssembly({
					direction_facing: newVal
				});
			},
			options: directionFacingOptions,
			getSelectedOption: () => directionFacingOptions.find((direction) =>
				direction.value === assembly.direction_facing)
            ?? { label: "", value: "" as CardinalDirectionType, title: "" },
			clearValue: () => {
				setAssembly({
					direction_facing: ""
				});
			}
		},
		Floor: {
			varName: "fllor",
			label: "Floor",
			error: [],
			info: [],
			minValue: 0,
			maxValue: 100,
			getValue(): number {
				return assembly.floor as number;
			},
			setValue(newVal: number): void {
				setAssembly({
					floor: newVal
				});
			},
			getSelectedOption: () => ({
				label: assembly.floor.toString(),
				value: assembly.floor,
				title: assembly.floor.toString()
			}),
			clearValue: () => {
				setAssembly({
					floor: 0
				});
			}
		},
		ColorPalettes: {
			varName: "color_palette",
			label: "Color Palette",
			error: [],
			info: [],
			getValue: () => (hasPaletteBeenAltered ? "Custom Palette" : assembly.palette_color as string),
			setValue: (value) => {
				const selectedPalette = colorPaletteOptions.find((colPal) => colPal.value === value);

				const defAcc = selectedPalette?.default_colors ?? {};

				const selectedDefaultColors = getPaletteList(assembly).reduce((acc, curr) => {
					let newColorValue = defAcc[curr.name] ?? "";

					if (newColorValue === "Missing Default Color") {
						newColorValue = fullPaletteList?.find((fp) => fp.name === curr.name)?.choices[0] ?? "";
					}

					acc[curr.name] = newColorValue;
					return acc;
				}, {} as Record<string, string>) ?? {};

				setAssembly({
					hardware_color: value,
					palette_color: value,
					colors: selectedDefaultColors
				});
			},
       
			options: colorPaletteOptionAll,
			getSelectedOption: () => (hasPaletteBeenAltered
				? colorPaletteOptionAll?.find((colPal) =>
					colPal.value === "Custom Palette")
				: (colorPaletteOptionAll?.find((colPal) =>
					colPal.value === assembly.palette_color))
				?? colorPaletteOptionAll?.find((colPal) =>
					colPal.value === "Custom Palette")) as PortalShadeOptionItem<string>,
			clearValue: () => {
				setAssembly({
					hardware_color: "Custom Palette",
					palette_color: "Custom Palette",
					colors: colorPaletteOptions.find((colPal) =>
						colPal.value === "Custom Palette")?.default_colors ?? {}
				});
			},
		},
		Colors: colorSelections
	};

	// Generic Option Validation
	Object.values(options).forEach((dict) => {
		if (Array.isArray(dict)) {
			dict.forEach((opt) => {
				opt.active = opt.active ?? true;
				// eslint-disable-next-line @typescript-eslint/no-shadow
				opt.options?.forEach((opt) => {
					opt.isActive = opt.isActive ?? true;
				});
			});
		} else {
			dict.active = dict.active ?? true;
			dict.options?.forEach((opt) => {
				opt.isActive = opt.isActive ?? true;
			});
		}
	});

	Object.values(options).forEach((option) => {
		if (Array.isArray(option)) {
			option.forEach((opt) => {
				opt.active = opt.active ?? true;
				// eslint-disable-next-line @typescript-eslint/no-shadow
				opt.options?.forEach((opt) => {
					opt.isActive = opt.isActive ?? true;
				});
			});
		} else {
			const optionVal = option.getValue();
			if (optionVal === undefined || optionVal === null || optionVal === "") {
				option.error.push("This field is required");
			}

			if (typeof optionVal === "number") {
				if (optionVal < (option?.minValue ?? 0) || optionVal > (option.maxValue ?? 0)) {
					option.error.push(`Value must be between ${option.minValue} and ${option.maxValue}`);
				}
			// eslint-disable-next-line @typescript-eslint/no-shadow
			} else if ((option.options?.length ?? 0) > 0 && !option.options?.find((option) =>
				option.value === optionVal)) {
				option.error.push("Invalid Option");
			}
		}
	});

	return options;
};

export {
	useCurrentPSOutdoorShadeOptions,
	useNewPSOutdoorShadeOptions
};

