import React from 'react';
import { Row, Col, Form, Placeholder } from 'react-bootstrap';
import Popup from 'reactjs-popup';
import { Typeahead } from 'react-bootstrap-typeahead';

import Button from '../Parts/Button';

import { useDealers, useDistributorLocations, useDistributors, useTerritories } from '../Store/entities/hooks';

import type { DealerDistributorViewProps } from './types';
import type { Distributor, DistributorLocation, DistributorStore } from '../powershadesApiTypes';
import type { fakeRootType } from "../FakeRootType";
import type { TerritoryStore } from '../Store/entities/entitiesType';

const DealerDistributorView= ({
	dealerId, disabled = false, workingDealer, onSubmit, loading
}: DealerDistributorViewProps) => {
	const distributors = useDistributors();
	const distributorLocations = useDistributorLocations();
	const dealers = useDealers();
	const territories = useTerritories();

	const dealer = dealers.find((d) => d.id === dealerId);
	const [modalOpen, setModalOpen] = React.useState(false);

	const [selectedDistId, setSelectedDistId] = React.useState(0);
	const selectedDist = distributors.find((d) => d.id === selectedDistId);
	const [selectedDistLocationId, setSelectedDistLocationId] = React.useState(0);

	const ROOT = (window as any).ROOT as fakeRootType;

	if (!dealer) {
		return null;
	}

	const currentDistributorLocation = distributorLocations.find(
		(dl) => workingDealer.distributor_location_territories
			.some((dlt) => dlt.distributor_location_id === dl.id)
	);

	const currentDistributor = distributors.find(
		(d) => d.id === currentDistributorLocation?.distributor_id
	);

	const allowedDistributorLocations = distributorLocations.filter(
		(dl) => dl.distributor_id === selectedDist?.id
	).sort((a, b) => a.name.localeCompare(b.name));

	const selectedDistLocation = allowedDistributorLocations.find(
		(dl) => dl.id === selectedDistLocationId
	);

	const sortedDistList = distributors.sort((a, b) => a.name.localeCompare(b.name));

	const dealerTerritories = workingDealer
		?.territory_ids
		?.map((tId) => territories.find((t) => t.id === tId) ?? { id: 0, name: '' })
		.filter((t) => t.id !== 0)
		.sort((a, b) => a.name.localeCompare(b.name)) ?? [] as TerritoryStore[];

	const commonDealerDistLocationTerritoryIds = dealerTerritories
		.filter((dt) => selectedDistLocation?.territory_ids.includes(dt.id))
		.map((dt) => dt.id);

	const submit = () => {
		if (selectedDistLocation) {
			onSubmit(selectedDistLocationId, [...commonDealerDistLocationTerritoryIds]);
			setModalOpen(false);
		}
	};

	const submitDisabled = !(selectedDistLocationId !== 0
		&& selectedDistId !== 0);

	if (loading) {
		return (
			<Placeholder animation="glow" className="row mb-4 ms-0 me-0">
				<Col xs={12} lg={6} className="mt-2">
					<Placeholder xs={8} size="lg" className="mb-1" />
					<Placeholder xs={12} size="lg" />
				</Col>
				<Col xs={12} lg={6} className="mt-2">
					<Placeholder xs={8} size="lg" className="mb-1" />
					<Placeholder xs={12} size="lg" />
				</Col>
			</Placeholder>
		);
	}
	return (
		<>
			<Row>
				<Col xls={6}>
					<h4>Distributor Information</h4>
				</Col>
				{currentDistributorLocation && (
					<Col xs={6} className="text-end">
						{/* Button to remove distributor from dealer. */}
						<Button
							disabled={disabled}
							onClick={() => {
								ROOT.alert({
									title: "Remove Distributor",
									text: "Are you sure you want to remove this distributor from this dealer?",
									onAccept: () => {
										onSubmit(0, []);
									},
								});
							}}
						>
							Remove Distributor
						</Button>
					</Col>
				)}
			</Row>
			<Row className="mb-2">
				<Col sm={12} md={6}>
					<h6>Distributor</h6>
					<Form.Control
						id="open-dist-modal"
						disabled={disabled}
						type="text"
						value={currentDistributor?.name ?? ""}
						onClick={() => setModalOpen(!disabled)}
					/>
				</Col>
				<Col sm={12} md={6}>
					<h6>Distributor Location</h6>
					<Form.Control
						id="open-dist-modal-location"
						disabled={disabled}
						type="text"
						value={currentDistributorLocation?.name ?? ''}
						onClick={() => setModalOpen(!disabled)}
					/>
				</Col>
			</Row>
			<Popup
				open={modalOpen}
				onClose={() => setModalOpen(false)}
				closeOnDocumentClick={false}
				closeOnEscape
				className="mobile-modal "
			>
				<Row>
					<h3>Change Distributor</h3>
				</Row>
				<Row>
					<h4>
						Current Distributor:
						{currentDistributor?.name}
					</h4>
					<h4>
						Current Distributor Location:
						{currentDistributorLocation?.name}
					</h4>
				</Row>
				<hr />
				<Row>
					<Col>
						<Row>
							<h4>Select New Distributor</h4>
						</Row>
						<Row>
							<Typeahead
								id="dist-select"
								placeholder="Select New Distributor"
								options={sortedDistList}
								isLoading={false}
								labelKey="name"
								autoFocus={false}
								defaultOpen={false}
								selected={selectedDist ? [selectedDist] : []}
								onChange={(selection) => {
									const selectedCountry = selection[0];
									if (!selectedCountry) {
										setSelectedDistId(0);
										return;
									}

									const dist = selectedCountry as DistributorStore;

									if (!dealerTerritories.some((dt) => dist.territory_ids.includes(dt.id))) {
										ROOT.alert({
											title: "Missing Territory ",
											text: "This distributor doesn't have any territories this dealer can be assigned to. Either select a different distributor or add the territory to this dealer.",
											noCancelButton: true,
										});
										return;
									}

									const distLoc = selectedCountry as Distributor;

									setSelectedDistId(distLoc.id);
								}}
							/>
						</Row>
						<hr />
					</Col>

				</Row>
				<Row>
					<Col>
						<Row>
							<h4>Select New Distributor Location</h4>
						</Row>
						<Row>
							<Typeahead
								id="distloc-select"
								placeholder="Select New Distributor Location"
								options={allowedDistributorLocations}
								isLoading={false}
								labelKey="name"
								selected={
									selectedDistLocation ? [selectedDistLocation] : []
								}
								onChange={(selection) => {
									const selectedCountry = selection[0];
									if (!selectedCountry) {
										setSelectedDistLocationId(0);
										return;
									}

									const distLoc = selectedCountry as DistributorLocation;

									setSelectedDistLocationId(distLoc.id);
								}}
							/>
						</Row>
						<hr />
					</Col>
				</Row>

				<Row>
					<Button disabled={submitDisabled} onClick={submit}>
						Save
					</Button>
				</Row>
			</Popup>
			<hr />
		</>
	);
};

export default DealerDistributorView;
