import { createAsyncThunk, createSelector } from '@reduxjs/toolkit';

import { loadStatus } from '../entitiesType';
import apiCalls from '../../../PowerShadesAPIFunctions';

import type { AppState } from '../..';
import type { RepresentativeStore, extraEntityReducersFunction } from '../entitiesType';

import { emptyAddress } from '../../../powershadesApiTypeExtensions';
import { captureSentryError, extractErrorMessage, isFailedApiCall } from '../../../psUtil';
import { Representative } from '../../../powershadesApiTypes';

export const initialRepresentativeState: () => RepresentativeStore = () => ({
	loadStatus: loadStatus.needsLoaded,
	id: 0,
	name: "",
	email: "",
	phone: "",
	invite_code: "",
	territory_ids: [],
	sales_tax_exempt_status: "non_exempt",
	sales_tax_exempt_reason: "",
	sales_tax_exempt_certificate_location: "",
	sales_tax_exempt_certificate_number: "",
	federal_tax_id: "",
	federal_tax_certificate_location: "",
	channel_id: 1,
	user_ids: [],
	quote_ids: [],
	distribution_emails: [],
	billing_address: emptyAddress,
	current_portfolio: ""
});

const loadRepresentativesMeta = createAsyncThunk<RepresentativeStore[], void, { rejectValue: string }>(
	'entities/getRepresentativesMeta',
	async (_emptyInput, { rejectWithValue }) => {
		let representatives: RepresentativeStore[];

		const initDis = initialRepresentativeState();
		try {
			const resp = await apiCalls.getRepresentatives();

			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			const workingReps = resp.data.representatives.map((rep) => ({
				...initDis,
				...rep,
				billing_address: emptyAddress
			}));

			representatives = workingReps;
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: 'redux'
				},
				extra: {
					redux_action: 'getRepresentativesMeta',
					file_origin: 'src/Store/entities/organizations/representatives.ts'
				}
			});

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}

		return representatives;
	}
);

const loadRepresentativesFull = createAsyncThunk<Representative, number, { rejectValue: string }>(
	'entities/getRepresentativeFull',
	async (representativeId, { rejectWithValue }) => {
		let representative: Representative;

		if (representativeId === 0) return rejectWithValue("rejected: default id provided.");

		try {
			const resp = await apiCalls.getRepresentative(representativeId);

			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			representative = resp.data.representative;
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: 'redux'
				},
				extra: {
					redux_action: 'getRepresentativeFull',
					file_origin: 'src/Store/entities/organizations/representatives.ts'
				}
			});

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}

		return representative;
	}
);

const selectRepresentativeList = createSelector(
	(state: AppState) => state.entity.representatives,
	(representatives) => {
		const updatedDealers = Object.entries(representatives)
			.reduce((list, [key, value]) => {
				if (key === 'loadStatus') return list;
				list.push(value as RepresentativeStore);
				return list;
			}, [] as RepresentativeStore[])
			.filter((d) => d?.loadStatus !== loadStatus.notLoaded);
  
		return updatedDealers;
	}
);

const updateRepresentativeFull = createAsyncThunk<
	Representative | null,
	Representative,
	{
		state: AppState;
		rejectValue: string;
	}
>('entities/updateRepresentativeFull', async (representativeNew, { rejectWithValue }) => {
	let representative: Representative;

	try {
		const resp = await apiCalls.updateRepresentative(representativeNew.id, representativeNew);

		if (isFailedApiCall(resp)) {
			throw resp.error;
		}

		representative = resp.data.representative;
	} catch (err: any) {
		captureSentryError(err, {
			tags: {
				section: 'redux'
			},
			extra: {
				redux_action: 'updateRepresentativeFull',
				file_origin: 'src/Store/entities/organizations/representatives.ts'
			}
		});

		const errorMessage = extractErrorMessage(err);
		return rejectWithValue(errorMessage);
	}

	return representative;
});

const representativeBuilder: extraEntityReducersFunction = (_builder) => {
	_builder.addCase(loadRepresentativesMeta.pending, (state) => {
		state.representatives.loadStatus = loadStatus.loading;
	});

	_builder.addCase(loadRepresentativesMeta.fulfilled, (state, action) => {
		action.payload.forEach((rep) => {
			const currentStoreRep = state.representatives[rep.id];
			const newStoreRep = { ...initialRepresentativeState(), ...rep };
			state.representatives[rep.id] = currentStoreRep?.loadStatus === loadStatus.fullyLoaded ? { ...currentStoreRep } : newStoreRep;
		});

		state.representatives.loadStatus = loadStatus.metaLoaded;
	});

	_builder.addCase(loadRepresentativesFull.pending, (state, action) => {
		state.representatives[action.meta.arg] = {
			...initialRepresentativeState(),
			...state.representatives[action.meta.arg],
			loadStatus: loadStatus.loading,
			id: action.meta.arg,
		};
		return state;
	});

	_builder.addCase(loadRepresentativesFull.fulfilled, (state, action) => {
		const rep = action.payload;
		
		state.representatives[rep.id] = {
			...rep,
			loadStatus: loadStatus.fullyLoaded,
		};
		return state;
	});

	_builder.addCase(updateRepresentativeFull.fulfilled, (state, action) => {
		if (action.payload === null) return state;

		state.representatives[action.payload.id] = {
			...action.payload,
			loadStatus: loadStatus.fullyLoaded,
		};
		return state;
	});
};

export {
	representativeBuilder,
	selectRepresentativeList,
	loadRepresentativesFull,
	loadRepresentativesMeta,
	updateRepresentativeFull,
};
