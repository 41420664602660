import React, { useEffect, useState } from 'react';
import {
	Card, Col, Container, Form, Row, Table
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import { toast } from 'react-toastify';
import FileViewer from '../Parts/FileViewer';
import Button from '../Parts/Button';

import store from '../Store';

import apiCalls from '../PowerShadesAPIFunctions';
import AddressEditorModal from '../Quote/AddressEditorModal';
import ContractorEdit from './Modals/ContractorEdit';

import { Contractor } from "./contractors";
import TaxJustification from '../Modals/TaxJustification/TaxJustification';

const ContractorRead = ({ contractorId, loaded, channels, ROOT }) => {
	const [contractor, setContractor] = useState<Contractor>({
		name: '',
		email: '',
		phone: '',
		billing_address: {
			first_name: '',
			last_name: '',
			email: '',
			phone: '',
			address: '',
			address2: '',
			city: '',
			state: '',
			zip: '',
			state_code: '',
			country: ''
		},
		sales_tax_exempt_status: ''
	});

	const billingAddress = contractor.billing_address;

	const { user } = store.getState();

	const saveBillingAddress = (data) => {
		apiCalls.saveOrganizationAddress(contractorId, data);
		setContractor({ ...contractor, billing_address: data });
	};

	const [taxEditLoading, setTaxEditLoading] = useState(false);

	const saveTaxItems = async (data) => {
		try {
			setTaxEditLoading(true);
			const transformedData = {
				sales_tax_exempt_status: data.sales_tax_exempt_status,
				sales_tax_exempt_reason: data.sales_tax_exempt_reason,
				sales_tax_exempt_certificate_location: data.sales_tax_exempt_certificate_location,
				sales_tax_exempt_certificate_number: data.sales_tax_exempt_certificate_number,
			};
			setTaxEditLoading(true);
			const newContractor = { ...contractor, ...transformedData };
		
			setContractor(newContractor);
			await apiCalls.updateOrganization(contractorId, newContractor).then(() => {
				setTaxEditLoading(false);
				toast.success("Tax details updated successfully.");
			});
		} catch {
			toast.error("Failed to update tax details.");
		}
	};

	const saveBaseItems = (data) => {
		const newContractor = { contractor, ...data };

		apiCalls.updateOrganization(contractorId, newContractor);
		setContractor(newContractor);
	};

	const [taxModalOpen, setTaxModalOpen] = useState(false);

	useEffect(() => {
		apiCalls.readOrganization(contractorId).then((resp) => {
			const { data } = resp;

			const { organization } = data;

			setContractor(organization);

			loaded();
		});
	}, [contractorId, loaded]);

	return (
		// eslint-disable-next-line
		<>
			{!contractor ? null : (
				<Container className="local-bootstrap">
					<Card>
						<Card.Body>
							<Row>
								<Col>
									<h4 className="text-green">{contractor.name}</h4>
								</Col>
							</Row>
							<hr />
							<Row>
								<Col>
									<h5 className="text-green">Contact Information</h5>
								</Col>
							</Row>
							<Row>
								<Col>
									<Row>
										<Col>
											<Form.Label>Email:</Form.Label>
										</Col>
									</Row>
									<Row>
										<Col>{contractor.email}</Col>
									</Row>
								</Col>

								<Col>
									<Row>
										<Col>Phone:</Col>
									</Row>
									<Row>
										<Col>{contractor.phone}</Col>
									</Row>
								</Col>
							</Row>
							<Row>
								<Col className="text-end">
									<ContractorEdit
										channels={channels}
										contractor={contractor}
										update={saveBaseItems}
									/>
								</Col>
							</Row>
							<hr />
							<Row>
								<Col>
									<Row>
										<Col>
											<h5 className="text-green">Billing Information</h5>
										</Col>
									</Row>
									<Row className="mt-2 mb-2">
										<Col>
											{billingAddress ? (
												<Table striped hover responsive size="sm">
													<thead>
														<tr>
															<th>Primary Contact First Name</th>
															<th>Primary Contact Last Name</th>
															<th>Billing Email</th>
															<th>Billing Phone</th>
															<th>Address Line 1</th>
															<th>Address Line 2</th>
															<th>City</th>
															<th>Country and State/Territory</th>
															<th>ZIP/Postal Code</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>{billingAddress.first_name}</td>
															<td>{billingAddress.last_name}</td>
															<td>{billingAddress.email}</td>
															<td>{billingAddress.phone}</td>
															<td>{billingAddress.address}</td>
															<td>{billingAddress.address2}</td>
															<td>{billingAddress.city}</td>
															<td>{billingAddress.state}</td>
															<td>{billingAddress.zip}</td>
														</tr>
													</tbody>
												</Table>
											) : (
												<Col className="text-center">
													<h6 className="text-green">No Data Found</h6>
												</Col>
											)}
										</Col>
									</Row>
									<Row>
										<Col className="text-end">
											<AddressEditorModal
												bootstrap
												ROOT={ROOT}
												onCancel={() => {}} // eslint-disable-line
												onSet={(data) => {
													saveBillingAddress(data);
												}}
												label="Billing Address"
												initialAddress={contractor.billing_address}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
							<hr />
							<Row>
								<Col>
									<h5 className="text-green">Sales Tax Exemption</h5>
								</Col>
							</Row>
							<Row>
								<Col>
									<Row>
										<Col>
											<Button
												title="Please click edit to make any changes to this contractor"
												className={`btn fluid-button ${
													contractor.sales_tax_exempt_status
														!== 'tax_exempt'
													|| contractor.sales_tax_exempt_status === undefined
														? 'gray-button text-white'
														: 'btn-green'
												}`}
											>
												{contractor.sales_tax_exempt_status === 'tax_exempt'
													? 'Tax Exempt '
													: 'No Tax Exemption '}
												{contractor.sales_tax_exempt_status
													!== 'tax exempt'
												|| contractor.sales_tax_exempt_status === undefined
													? (<FontAwesomeIcon icon={faTimes} />)
													: (<FontAwesomeIcon icon={faCheck} />)}
											</Button>
										</Col>
									</Row>
								</Col>
								<Col>
									<Row>
										<Col>
											<Form.Label>Exemption Type</Form.Label>
										</Col>
									</Row>
									<Row>
										<Col>
											{contractor.sales_tax_exempt_reason
												? contractor.sales_tax_exempt_reason
												: 'N/A'}
										</Col>
									</Row>
								</Col>
								<Col>
									<Row>
										<Col>
											<Form.Label>Tax Exemption Number</Form.Label>
										</Col>
									</Row>
									<Row>
										<Col>
											{contractor.sales_tax_exempt_certificate_number
												? contractor.sales_tax_exempt_certificate_number
												: 'N/A'}
										</Col>
									</Row>
								</Col>
								<Col>
									<FileViewer
										apiCall={`${
											process.env.BACKEND_ROOT
											+ (process.env.BACKEND_ROOT.endsWith('/') ? '' : '/')
										}src/reports/downloadFileFromS3.php`}
										data={{
											certType:
												process.env.ENV === 'dev'
												|| process.env.ENV === 'dev-alex'
												|| process.env.ENV === 'dev-lucy'
												|| process.env.ENV === 'test'
													? 'state-test'
													: 'state',
											user_jwt: user?.jwt,
											entityID: contractorId,
											baseFileName: 'StateExemptionForm-',
											entityType: 'organization',
										}}
										title="State Tax Certificate"
										isPdf={
											contractor.sales_tax_exempt_certificate_location
											&& contractor.sales_tax_exempt_certificate_location.endsWith('.pdf')
										}
									/>
								</Col>
							</Row>
							<Row>
								<Col className="text-end">
									<TaxJustification
										org={contractor}
										orgType='Contractor'
										onSave={saveTaxItems}
										open={taxModalOpen}
										setOpen={setTaxModalOpen}
										loading={taxEditLoading}
									/>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Container>
			)}
		</>
	);
};

export default ContractorRead;
