import React, { useMemo } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import type { Option } from 'react-bootstrap-typeahead/types/types';

import { useUsersList, useUsersLoadStatus } from '../Store/entities/hooks';

import type { UserSelectProps } from './types';
import { initialUser } from '../Store/entities/users';
import { UserStore } from '../powershadesApiTypeExtensions';

const UserSelect = ({
	handleChange,
	disableOverride = false,
	optionsOverride,
	selectedUserId = undefined
}: UserSelectProps) => {
	const users = useUsersList();
	const usersLoadStatus = useUsersLoadStatus();
	const usersLoading = usersLoadStatus <= 3;
	const usersArray = useMemo(() => Object.values(users), [users]);

	const shouldBeDisabled = usersLoading || disableOverride;
	
	const selectedFromOptions = useMemo(() => {
		if (!selectedUserId) return [];
		if (optionsOverride) {
			return optionsOverride.filter((user) => user.id === selectedUserId);
		}
		return usersArray.filter((user) => user.id === selectedUserId);
	}, [selectedUserId, usersArray, optionsOverride]);

	return (
		<Typeahead
			id="user-select"
			clearButton
			paginate
			positionFixed
			selected={selectedFromOptions as unknown as Option[]}
			labelKey="name"
			placeholder={usersLoading ? 'Loading Users...' : 'Select a User...'}
			options={optionsOverride ?? usersArray}
			isLoading={usersLoading}
			disabled={shouldBeDisabled}
			onChange={(selected) => {
				if (!selected) return;
				const user = selected as unknown as UserStore;
				handleChange(user[0] ?? initialUser());
			}}
		/>
	);
};

export default UserSelect;
