/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @stylistic/max-len */
import Popup from "reactjs-popup";
import React, { useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { AssemblyStore, AssemblyViewModelFromAssemblyStore } from "../../../../Store/entities/assemblies/types";
import { useUser } from "../../../../Store/user/hooks";
import { useQuoteById, useQuoteTypeNameByQuoteId } from "../../../../Store/entities/quotes/hooks";
import { usePSOutdoorFabrics } from "../../../../Store/entities/hooks";
import apiCalls from "../../../../PowerShadesAPIFunctions";
import PriceErrors from "../../../../Quote/PriceErrors";
import { UsePortalSelector } from "../../../../Store";
import { InitialAssemblyState } from "../../../../Store/entities/assemblies";
import { useNewPSOutdoorShadeOptions } from "../../../../Store/entities/assemblies/PSOutdoorHooks";
import { useAssemblyOptions } from "../../../../Store/entities/assemblyOptions/hooks";
import EditPSOutdoorShadeModalInner from "./EditPSOutdoorShadeInner";
// import { FabricViewModel, loadStatusType } from "../../../../powershadesApiTypes";

type EditPSOutdoorShadeModalBaseProps = {
	isOpen: boolean;
	onClose: () => void;
	quoteId: number;
	assembly: AssemblyStore;
	setAssembly: (assembly: AssemblyStore) => void;
	isNotAdd?: boolean;
	massEdit?: boolean | undefined;
};
const EditPSOutdoorShadeModalBase = ({ isOpen, onClose, assembly: incomingAssembly, setAssembly: setAssemblyIncoming, isNotAdd = true, massEdit }: EditPSOutdoorShadeModalBaseProps) => {
	const [assembly, setAssembly] = React.useState(incomingAssembly);
	const quoteId = assembly.quote_id ?? 0;

	//console.log('🔹🔹🔹🔹🔹🔹 EditPsOutdoorShadeBase', quoteId);
	const psOutdoorFabrics = usePSOutdoorFabrics(quoteId);
	const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

	const toggleColorPicker = () => setIsColorPickerOpen(!isColorPickerOpen);

	const user = useUser();
	const quote = useQuoteById(quoteId);

	const assemblyOptions = UsePortalSelector(useAssemblyOptions(quoteId));

	const shadeOptions = useNewPSOutdoorShadeOptions({ ...InitialAssemblyState(), ...assembly }, setAssembly, { fabrics: psOutdoorFabrics, user, quote, assemblyOptions });

	const quoteType = useQuoteTypeNameByQuoteId(quoteId);

	useEffect(() => {
		setAssembly(incomingAssembly);
	}, [isOpen]);

	const submitShade = () => {
		setAssemblyIncoming(assembly);
		onClose();
	};

	const [msrp, setMsrp] = React.useState(assembly.msrp);
	const [errors, setErrors] = React.useState(assembly.error_messages);

	useEffect(() => {
		if (quoteId === 0 || !isOpen) return;
		setMsrp(0);
		setErrors("");
		apiCalls.calculateAssemblyPriceNew(AssemblyViewModelFromAssemblyStore(assembly), quoteId, "psoutdoor").then((res) => {
			setMsrp(res?.data?.msrp ?? 0);
			const errorMessage = res?.data?.errors.map(e => e.message);
			const errorMessageListAsOneString = errorMessage?.join(", ") ?? "";
			setErrors(errorMessageListAsOneString);
		});
	}, [assembly]);

	if (!isOpen) return null;

	return (
		<Popup open={isOpen} onClose={onClose} className="mobile-modal p-2 m-2">
			<Row>
				{
					isNotAdd
						? <h2>Edit Shade</h2>
						: <h2>Add Shade</h2>
				}
			</Row>
			<EditPSOutdoorShadeModalInner
				options={shadeOptions}
				quoteType={quoteType}
				isNotAdd={isNotAdd}
				massEdit={massEdit}
				pickerState={isColorPickerOpen}
				setPickerState={setIsColorPickerOpen}
				unlocked={assembly.unlocked}
			/>
			{
				isNotAdd && !isColorPickerOpen && (
					<PriceErrors quoteId={quoteId} msrp={msrp} error_messages={errors ?? ""} assembly={assembly} />
				)
			}
			<Row className=" justify-content-center w-100 mb-1">
				<Button
					className="col-lg-4"
					onClick={(assembly.unlocked || !isNotAdd) ? (isColorPickerOpen ? toggleColorPicker : submitShade) : onClose}
				>
					Done
				</Button>
			</Row>
			{
				(!assembly.unlocked && isNotAdd)
					? <Row className="justify-content-center w-100">
						<Button className="col-lg-4" onClick={() => setAssembly({ ...assembly, unlocked: true })}>
							<FontAwesomeIcon icon={faLock} />
						</Button>
					</Row>
					: null
			}
		</Popup>
	);
};

export default EditPSOutdoorShadeModalBase;
