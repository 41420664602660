import React from 'react';
import { useQuoteAccessory } from '../../Store/entities/accessories/hooks';

type AccessoryQuantityProps = {
	onChange: (lineNumber: number, quantity: number) => void;
	quoteId: number;
	lineNumber: number;
}

const AccessoryQuantity = ({ quoteId, lineNumber, onChange}: AccessoryQuantityProps) => {
	const quoteAccessory = useQuoteAccessory(quoteId, lineNumber);

	const value = quoteAccessory ? quoteAccessory.quantity : 0;

	return (
		<td>
			<input
				className="form-control"
				type="number"
				onChange={(e) => {
					const intVal = parseInt(e.target.value, 10);
					const minVal = 0;

					let newValue = intVal;

					if (`${e.target.value}` === `${intVal}`) {
						
						if (intVal < minVal) {
							newValue = minVal;
						}
					}

					onChange(lineNumber, newValue);
				}}
				value={value}
			/>
		</td>
	);
}

export default AccessoryQuantity;
