// All exports from this file will be available from the root of the store

import type { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import type { extraEntityReducersFunction } from "../entitiesType";
import { loadStatus } from "../entitiesType";
import type { Motor, Tube } from "../../../powershadesApiTypes";
import apiCalls from "../../../PowerShadesAPIFunctions";



const loadMotors = createAsyncThunk<Motor[], void, {
	rejectValue: boolean;
}>(
	'entities/GetMotors',
	async (_inputs, { rejectWithValue }) => {
		let motors: Motor[] = [];

		try {
			const resp = await apiCalls.getMotors();

			motors = resp.data.motors;
		} catch (err: any) {
			const error: AxiosError = err;

			console.error(error);

			return rejectWithValue(false);
		}

		return motors;
	}
);

const loadTubes = createAsyncThunk<Tube[], void, {
	rejectValue: boolean;
}>(
	'entities/GetTubes',
	async (_inputs, { rejectWithValue }) => {
		let tubes: Tube[] = [];

		try {
			const resp = await apiCalls.getTubes();

			tubes = resp.data.tubes;
		} catch (err: any) {
			const error: AxiosError = err;

			console.error(error);

			return rejectWithValue(false);
		}

		return tubes;
	}
);


const partsBuilder: extraEntityReducersFunction = (builder) => {

	builder.addCase(loadMotors.pending, (entities) => {
		entities.parts.motors.loadStatus = loadStatus.loading;
	});

	builder.addCase(loadMotors.fulfilled, (entities, action) => {
		entities.parts.motors.list = action.payload;
		entities.parts.motors.loadStatus = loadStatus.fullyLoaded;
	});

	builder.addCase(loadMotors.rejected, (entities) => {
		entities.parts.motors.loadStatus = loadStatus.needsLoaded;
	});

	builder.addCase(loadTubes.pending, (entities) => {
		entities.parts.tubes.loadStatus = loadStatus.loading;
	});

	builder.addCase(loadTubes.fulfilled, (entities, action) => {
		entities.parts.tubes.list = action.payload;
		entities.parts.tubes.loadStatus = loadStatus.fullyLoaded;
	});

	builder.addCase(loadTubes.rejected, (entities) => {
		entities.parts.tubes.loadStatus = loadStatus.needsLoaded;
	});

};

export {
	loadMotors,
	partsBuilder,
	loadTubes
};