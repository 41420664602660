const Table = ({
	className, quoteType, style, noTBody, children
}) => (
	<table
		className={`custom-table ${className} ${quoteType}`}
		border="0"
		cellSpacing="0"
		cellPadding="0"
		style={style}
	>
		{noTBody ? children : <tbody>{children}</tbody>}
	</table>
);

export default Table;
