import { useEffect, useCallback, useRef } from "react";
import { createSelector } from "reselect";

import store, { AppState, UsePortalSelector } from "..";
import {
	setFilterSettings,
	setHeaderInjection,
	setLocked,
	setShowingTabPane,
	setViewingNewQuoteAsPsAdmin,
	setViewingQuoteId,
	setVisibleQuoteIds,
	setVisibleQuoteIdsLoadingStatus
} from ".";


import { setCountsLoadStatus } from "../entities";
import { loadStatus } from "../entities/entitiesType";

import modal from "./alert";
import { FilterOptions, StatusOptions } from "../../powershadesApiTypes";

export const useScrollBlock = () => {
	const scrollBlocked = useRef(false);
	
	const blockScroll = () => {
		if (typeof document === "undefined") return;

		const html = document.documentElement;
		const { body } = document;

		if (!body || !body.style || scrollBlocked.current) return;

		const scrollBarWidth = window.innerWidth - html.clientWidth;
		const bodyPaddingRight =
			parseInt(body.getAttribute("padding-right") ?? "0", 10) || 0;

		/**
		 * 1. Fixes a bug in iOS and desktop Safari whereby setting
		 *    `overflow: hidden` on the html/body does not prevent scrolling.
		 * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
		 *    scroll if an `overflow-x` style is also applied to the body.
		 */
		html.style.position = "relative"; /* [1] */
		body.style.position = "relative"; /* [1] */
		html.style.overflow = "hidden"; /* [2] */
		body.style.overflow = "hidden"; /* [2] */
		body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

		scrollBlocked.current = true;
	};

	const allowScroll = () => {
		if (typeof document === "undefined") return;

		const html = document.documentElement;
		const { body } = document;

		if (!body || !body.style || !scrollBlocked.current) return;

		html.style.position = "";
		html.style.overflow = "";
		body.style.position = "";
		body.style.overflow = "";
		body.style.paddingRight = "";
		body.style.removeProperty("padding-right");

		const forceRerender = body.offsetHeight;
		console.debug(forceRerender);
		scrollBlocked.current = false;
	};

	return [blockScroll, allowScroll];
};

/**
 * A hook that returns a boolean indicating whether the user has scrolled past a certain threshold.
 * @param threshold - The number of pixels from the top of the page at which the hook should trigger.
 * @returns A boolean indicating whether the user has scrolled past the threshold.
 */
export const useScrollPosition = (threshold: number) => {
	const isMinified = useRef<boolean>(window.scrollY >= threshold);
	const prevScrollY = useRef<number>(window.scrollY);

	const handleScroll = useCallback(() => {
		const currentScrollY = window.scrollY;
		if (Math.abs(currentScrollY - prevScrollY.current) > 10) {
			isMinified.current = currentScrollY > threshold;
			prevScrollY.current = currentScrollY;
		}
	}, [threshold]);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [handleScroll]);

	return isMinified.current;
};

/**
 * A selector that returns the list of visible quote IDs.
 * @param state - The current state of the application.
 * @returns The list of visible quote IDs.
 */
export const selectVisibleQuoteIds = createSelector(
	(state: AppState) => state.ui.home.visibleQuoteIds,
	(visibleQuoteIds) => visibleQuoteIds.list
);

/**
 * A hook that returns the list of visible quote IDs.
 * @returns The list of visible quote IDs.
 */
export const useVisibleQuoteIds = () => UsePortalSelector(selectVisibleQuoteIds);

/**
 * A selector that returns the filter settings for the home page.
 * @param state - The current state of the application.
 * @returns The filter settings for the home page.
 */
export const selectFilterSettings = (state: AppState) => state.ui.home.filterSettings;

/**
 * A hook that returns the filter settings for the home page.
 * @returns The filter settings for the home page.
 */
export const useFilterSettings = () => UsePortalSelector(selectFilterSettings);

/**
 * A selector that returns the selected quote status.
 * @param state - The current state of the application.
 * @returns The selected quote status.
 */
export const selectSelectedQuoteStatus = createSelector(
	(state: AppState) => state.ui.home.selectedQuoteStatus,
	(selectedQuoteStatus) => selectedQuoteStatus
);

/**
 * A hook that returns the selected quote status.
 * @returns The selected quote status.
 */
export const useSelectedQuoteStatus = () => UsePortalSelector(selectSelectedQuoteStatus);

/**
 * A selector that returns the count of quotes with the selected status.
 * @param state - The current state of the application.
 * @param selectedQuoteStatus - The status of the quotes to count.
 * @returns The count of quotes with the selected status.
 */
export const selectQuoteCountOfSelectedStatus = createSelector(
	(state: AppState, selectedQuoteStatus: StatusOptions) => ({
		quoteCounts: state.entity.quotes.counts.list,
		selectedQuoteStatus
	}),
	({ quoteCounts, selectedQuoteStatus }) => {
		const currentSelectedCounts = quoteCounts[selectedQuoteStatus]?.count ?? 0;
		return currentSelectedCounts;
	}
);

/**
 * A hook that returns the count of quotes with the selected status.
 * @param selectedQuoteStatus - The status of the quotes to count.
 * @returns The count of quotes with the selected status.
 */
export const useSelectedQuoteCountOfSelectedStatus = (selectedQuoteStatus: StatusOptions) =>
	UsePortalSelector((state) => selectQuoteCountOfSelectedStatus(state, selectedQuoteStatus));

/**
 * A hook that returns a function to set the filter settings for the home page.
 * @param setLoadingCounts - A boolean indicating whether to set the loading status for the quote counts.
 * @param setLoadingVisibleQuoteIds - A boolean indicating whether to set the loading status for the visible quote IDs.
 * @returns A function to set the filter settings for the home page.
 */
export const useSetFilterSettings = (
	setLoadingCounts?: boolean,
	setLoadingVisibleQuoteIds?: boolean
) => {
	const { dispatch } = store;

	return (filterSettings: FilterOptions) => {
		if (setLoadingCounts) {
			dispatch(setCountsLoadStatus(loadStatus.loading));
		}
		if (setLoadingVisibleQuoteIds) {
			dispatch(setVisibleQuoteIdsLoadingStatus(loadStatus.loading));
		}
		dispatch(setFilterSettings(filterSettings));
	};
};

/**
 * A hook that returns a function to set the list of visible quote IDs.
 * @returns A function to set the list of visible quote IDs.
 */
export const useSetVisibleQuoteIds = () => {
	const { dispatch } = store;

	return (visibleQuoteIds: number[]) => {
		dispatch(setVisibleQuoteIds(visibleQuoteIds));
	};
};

/**
 * A selector that returns the load status of the visible quote IDs.
 * @param state - The current state of the application.
 * @returns The load status of the visible quote IDs.
 */
export const selectVisibleQuoteIdsLoadStatus = createSelector(
	(state: AppState) => state.ui.home.visibleQuoteIds,
	(visibleQuoteIds) => visibleQuoteIds.loadStatus
);

/**
 * A hook that returns the load status of the visible quote IDs.
 * @returns The load status of the visible quote IDs.
 */
export const useVisibleQuoteIdsLoadStatus = () =>
	UsePortalSelector(selectVisibleQuoteIdsLoadStatus);

/**
 * A hook that returns the alert object (SWAL2).
 * @returns The alert object (SWAL2).
 */
export const useModal = () => modal;

const selectPageLoadingUnsafe = (state: AppState) => state.ui.pageLoading;

/**
 * A selector that returns the page loading status.
 * @param state - The current state of the application.
 * @returns The page loading status.
 */
const selectPageLoading = createSelector(selectPageLoadingUnsafe, (pageLoading) => pageLoading);

/**
 * A hook that returns the page loading status.
 * @returns The page loading status.
 */
export const usePageLoading = () => UsePortalSelector(selectPageLoading);

const selectCreateQuoteOpenUnsafe = (state: AppState) => state.ui.createQuoteOpen;

/**
 * A selector that returns the create quote modal open status.
 * @param state - The current state of the application.
 * @returns The create quote modal open status.
 */
const selectCreateQuoteOpen = createSelector(
	selectCreateQuoteOpenUnsafe,
	(createQuoteOpen) => createQuoteOpen
);

/**
 * A hook that returns the create quote modal open status.
 * @returns The create quote modal open status.
 */
export const useCreateQuoteOpen = () => UsePortalSelector(selectCreateQuoteOpen);

export const selectHeaderInjection = (state: AppState) => state.ui.headerInjection;

export const useHeaderInjection = () => UsePortalSelector(selectHeaderInjection);

export const useSetHeaderInjection = () => {
	const { dispatch } = store;

	return (headerInjection: React.ReactNode) => {
		dispatch(setHeaderInjection(headerInjection));
	};
};

export const selectViewingQuoteId = (state: AppState) => state.ui.quote.viewingQuoteId;

export const useViewingQuoteId = () => UsePortalSelector(selectViewingQuoteId);

export const useSetViewingQuoteId = () => {
	const { dispatch } = store;

	return (viewingQuoteId: number) => {
		dispatch(setViewingQuoteId(viewingQuoteId));
	};
};

export const selectLocked = (state: AppState) => state.ui.quote.locked;

export const useLockedStatus = () => UsePortalSelector(selectLocked);

export const useSetLocked = () => {
	const { dispatch } = store;

	return (locked: boolean) => {
		dispatch(setLocked(locked));
	};
};

export const selectViewingAsPsAdmin = (state: AppState) => state.ui.quote.viewingAsPsAdmin;

export const useViewingNewQuoteAsPsAdmin = () => UsePortalSelector(selectViewingAsPsAdmin);

export const useSetViewingNewQuoteAsPsAdmin = () => {
	const { dispatch } = store;

	return (viewingAsPsAdmin: boolean) => {
		dispatch(setViewingNewQuoteAsPsAdmin(viewingAsPsAdmin));
	};
};

export const selectDisplayPrices = (state: AppState) => state.ui.quote.displayPrices;

export const useDisplayPrices = () => UsePortalSelector(selectDisplayPrices);

export const selectTabPaneStatus = (state: AppState) => state.ui.quote.tabPaneOpen;

export const useTabPaneStatus = () => UsePortalSelector(selectTabPaneStatus);

export const useSetTabPaneStatus = () => {
	const { dispatch } = store;

	return (tabPaneStatus: boolean) => {
		dispatch(setShowingTabPane(tabPaneStatus));
	};
};
