import React, { Component } from 'react';

class CheckBox extends Component {
	constructor(props) {
		super(props);

		let { value } = props;

		if (value === 0) {
			value = false;
		}

		if (value === 1 || value === '1') {
			value = true;
		}

		this.state = {
			isChecked: value
		};
	}

	changed(newValue) {
		this.setState({
			isChecked: newValue
		});

		this.props.onChange(newValue);

		this.props && this.props.onBlur();
	}

	render() {
		const { props } = this;
		const value = this.state.isChecked;

		return (
			<input
				type="checkbox"
				disabled={props.disabled}
				onChange={(e) => this.changed(e.target.checked)}
				value={value || ''}
				checked={value}
			/>
		);
	}
}
 
export default CheckBox;
