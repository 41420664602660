// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QuoteCards-module__created-by___JeH8P{font-size:.9em}.QuoteCards-module__card-text___Qb9cd{font-size:.8em}.QuoteCards-module__card-title___tTXGA{display:flex;align-items:flex-end}.QuoteCards-module__card-body___DJJFl{padding-top:.5em !important}.QuoteCards-module__skeleton-title___tdUmY{display:flex;align-items:flex-end;font-size:1.8em}.QuoteCards-module__card___ybLmu:hover{background-color:#f2f2f2}`, "",{"version":3,"sources":["webpack://./src/Home/Quotes/QuoteCards.module.scss","webpack://./src/assets/css/_variables-light.scss"],"names":[],"mappings":"AAKA,uCACC,cAAA,CAGD,sCACC,cAAA,CAGD,uCACC,YAAA,CACA,oBAAA,CAGD,sCACC,2BAAA,CAGD,2CACC,YAAA,CACA,oBAAA,CACA,eAAA,CAQA,uCACC,wBC5BiB","sourcesContent":["@import \"../../assets/css/variables-light.scss\";\n\n.company-name {\n\t\n}\n.created-by {\n\tfont-size: 0.9em;\n}\n\n.card-text {\n\tfont-size: 0.8em;\n}\n\n.card-title {\n\tdisplay: flex;\n\talign-items: flex-end;\n}\n\n.card-body {\n\tpadding-top: 0.5em !important;\n}\n\n.skeleton-title {\n\tdisplay: flex;\n\talign-items: flex-end;\n\tfont-size: 1.8em;\n}\n\n// .skeleton-title-placeholder {\n// \twidth: 16%;\n// }\n\n.card {\n\t&:hover {\n\t\tbackground-color: $ps-lightest-gray;\n\t}\n}","$ps-green: #8dc63f;\n$ps-dark-green: #7fb239;\n$ps-darker-green: #72A033;\n$ps-gray: #6d6e71;\n$ps-light-gray: #a7a9ac;\n$ps-lighter-gray: #dedede;\n$ps-lightest-gray: #F2F2F2;\n$ps-dark-gray: #57585a;\n$mobile-threshold: 993px;\n$error-color: #e30000;\n$error-row-background-color: #ff00006e;\n$delete-red: #e60d0d;\n$pending-orange: #e37204;\n$ps-black: #020202;\n$ps-rma: #bb4430;\n$ps-bid: #9611d0;\n$ps-rework: #469e95;\n$ps-light-green: #ddeec5;\n$ps-light-blue: #b3e6f5;\n$ps-light-yellow: #fbe7c6;\n$ps-light-red: #ffb7b6;\n$ps-dark-red: #822f21;\n\n$seam-color: #f8dc3f;\n$sag-color: #f39422;\n$seam-sag-color: #e289dd;\n$large-outdoor-color: #5589e7;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"created-by": `QuoteCards-module__created-by___JeH8P`,
	"createdBy": `QuoteCards-module__created-by___JeH8P`,
	"card-text": `QuoteCards-module__card-text___Qb9cd`,
	"cardText": `QuoteCards-module__card-text___Qb9cd`,
	"card-title": `QuoteCards-module__card-title___tTXGA`,
	"cardTitle": `QuoteCards-module__card-title___tTXGA`,
	"card-body": `QuoteCards-module__card-body___DJJFl`,
	"cardBody": `QuoteCards-module__card-body___DJJFl`,
	"skeleton-title": `QuoteCards-module__skeleton-title___tdUmY`,
	"skeletonTitle": `QuoteCards-module__skeleton-title___tdUmY`,
	"card": `QuoteCards-module__card___ybLmu`
};
export default ___CSS_LOADER_EXPORT___;
