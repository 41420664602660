import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import api from '../PowerShadesAPI';

import HomeRow from '../Parts/HomeRow';
import Table from '../Parts/Table';
import Money from '../Parts/Money';
import Button from '../Parts/Button';

import AddCoupon from './AddCoupon';

const ManageCoupons = (props) => {
	const [coupons, setCoupons] = useState([]);
	const [showingAddCoupon, setShowingAddCoupon] = useState(false);
	const [editingCoupon, setEditingCoupon] = useState(null);

	const refetchCoupons = () => {
		api.onLoad.add(() => {
			api.getCoupons().then((resp) => setCoupons(resp.data.coupons || []));
		});
	};

	function deleteCoupon(coupon) {
		props.ROOT.alert({
			title: 'Delete Coupon',
			icon: 'warning',
			text: (
				<span>
					Are you sure you want to delete the coupon code: "
					{coupon.code}
					"?
					<br />
					<br />
					This will remove it from all active quotes.
					<br />
				</span>
			),
		})
			.then(() => {
				api.deleteCoupon(coupon.ID)
					.then(() => {
						ROOT.toast(`Coupon ${coupon.code} deleted`, { type: 'success' });
					})
					.finally(refetchCoupons);
			})
			.catch(() => {
				ROOT.toast('Coupon deletion canceled', { type: 'info' });
			});
	}

	function editCoupon(coupon) {
		setEditingCoupon(coupon);
		setShowingAddCoupon(true);
	}

	useEffect(() => {
		props.ROOT.loaded();
		props.setTitle('Manage Coupons');

		refetchCoupons();
	}, []);

	const rows = coupons.map((coupon) => {
		const couponNameNumberName = (function (couponDiscountType) {
			if (couponDiscountType == 'percentage') {
				return 'Discount Percentage';
			} else if (couponDiscountType == 'multiplier_override') {
				return 'Percent off Multiplier Override';
			} else if (couponDiscountType == 'invoice_percentage') {
				return 'Percent off Invoice';
			} else if (couponDiscountType == 'fixed_amount') {
				return 'Fixed Discount Value';
			}
		}(coupon.discount_type));

		return (
			<tr key={coupon.code}>
				<td
					style={{
						fontFamily: 'monospace',
						whiteSpace: 'nowrap',
						fontSize: '17px',
					}}
				>
					{coupon.code}
				</td>
				<td>{coupon.note}</td>
				<td>{couponNameNumberName}</td>
				<td>
					{coupon.discount_type !== 'fixed_amount' ? (
						`${parseFloat(coupon.discount_amount)}%`
					) : (
						<Money>{coupon.discount_amount}</Money>
					)}
				</td>
				<td>{coupon.expires ? coupon.expires.split(' ')[0] : 'N/A'}</td>
				<td>{coupon.max_uses_per_dealer || 'N/A'}</td>
				<td>{coupon.max_total_uses || 'N/A'}</td>
				<td>{coupon.times_used}</td>
				<td>
					<Row className="no-gutters">
						<Col xs={6}>
							<Button
								color="light"
								size="sm"
								title="Edit Coupon"
								onClick={() => editCoupon(coupon)}
							>
								<FontAwesomeIcon icon={faEdit} />
							</Button>
						</Col>
						<Col xs={6}>
							<Button
								color="light"
								size="sm"
								title="Delete Coupon"
								onClick={() => deleteCoupon(coupon)}
							>
								<FontAwesomeIcon icon={faTrashAlt} />
							</Button>
						</Col>
					</Row>
				</td>
			</tr>
		);
	});

	return (
		<>
			<HomeRow
				style={{
					display: 'block',
					marginBottom: '5em',
					width: '1090px',
					maxWidth: '90%',
					overflow: 'auto hidden',
					paddingBottom: '1em',
				}}
			>
				<div style={{ textAlign: 'right', margin: '3em auto 0.5em auto' }}>
					<Button color="green" onClick={() => setShowingAddCoupon(true)}>
						<FontAwesomeIcon icon={faPlus} />
						&nbsp;New Coupon
					</Button>
				</div>

				<Table
					className="local-bootstrap coupon-table"
					style={{ margin: 'auto', maxWidth: '100%' }}
				>
					<tr className="bold-row">
						<th colSpan={9}>Coupons</th>
					</tr>

					<tr>
						<th>Code</th>
						<th>Note</th>
						<th>Discount Type</th>
						<th>Discount Amount</th>
						<th>Expires</th>
						<th>Max Uses Per Dealer</th>
						<th>Max Total Uses</th>
						<th>Times Used</th>
						<th>Actions</th>
					</tr>

					{rows}
				</Table>
			</HomeRow>

			{showingAddCoupon ? (
				<div className="modal-holder">
					<div className="standard-modal">
						<AddCoupon
							initialCoupon={editingCoupon}
							onClose={() => {
								refetchCoupons();
								setEditingCoupon(null);
								setShowingAddCoupon(false);
							}}
						/>
					</div>
				</div>
			) : null}
		</>
	);
};

export default ManageCoupons;
