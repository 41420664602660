import { createSelector } from "reselect";
import store, { AppState, UsePortalSelector } from "../..";
import { loadQuoteWarnings } from ".";
import { loadStatus } from "../entitiesType";

const selectQuoteWarningsUnsafe = (quoteId: number) => (state: AppState) => {

    const quoteWarnings = state.entity.quoteWarnings[quoteId];

    if(!quoteWarnings || quoteWarnings.loadStatus === loadStatus.needsLoaded || quoteWarnings.loadStatus === loadStatus.notLoaded) {
        store.dispatch(loadQuoteWarnings(quoteId));
    }

    return  state.entity.quoteWarnings[quoteId]?.Items ?? {};
};


const selectQuoteWarnings = (quoteId: number) => createSelector(
    selectQuoteWarningsUnsafe(quoteId),
    (quoteWarnings) => {
        return Object.values(quoteWarnings);
    }
);

const useSavedQuoteWarnings = (quoteId: number) => {
    return UsePortalSelector(selectQuoteWarnings(quoteId));
}

export {
    useSavedQuoteWarnings
};
