import React, { useEffect } from 'react';

import HomeRow from "../Parts/HomeRow";

import { useIsPsAdmin } from "../hooks.js";
import CreateNotification from "./CreateNotification";

const CreateNotifications = ({
	ROOT, loaded, setTitle
}) => {
	const isPsAdmin = useIsPsAdmin();
		
	useEffect(() => {
		loaded();
		setTitle('Create Notification');
	}, [loaded, setTitle]);

	if (!isPsAdmin) {
		return null;
	}

	return (
		<HomeRow>
			<CreateNotification ROOT={ROOT} />
		</HomeRow>
	);
};

export default CreateNotifications;
