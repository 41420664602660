import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import apiCalls from "../../PowerShadesAPIFunctions";

import { emptyDealerMeta, emptyDistributorMeta, type UserRole, Role, UserMeta, User, UserStore  } from "../../powershadesApiTypeExtensions";
import { extraEntityReducersFunction, loadStatus } from "./entitiesType";
import type {     UpdateUserAttributeInput } from "./types";
import type { AppState } from "..";
import {  DeleteUserRoleInput, DeleteUserInput, AddUserRoleInput, AddUserInput} from "../../powershadesApiTypes";
import { hasErrorInAPIResponse } from "../../psUtil";

export const initialUser = (id = 0): UserStore => ({
	id,
	loadStatus: loadStatus.notLoaded,
	name: '',
	email: '',
	phone: '',
	roles: [],
	default_currency: 'USD',
	is_sales_person: false,
	is_engineering: false,
	is_service: false,
	is_manufacturing: false,
	is_allowed_parts_on_cedia: false,
	is_using_old_homepage: false,
	has_acknowledged_new_homepage: false,
	last_accessed_version: '0.0.0',
	quote_ids: [],
	dealer: emptyDealerMeta,
	distributor: emptyDistributorMeta,
	theme: "light"
});

export const loadUsersMeta = createAsyncThunk<UserMeta[], void>(
	'entities/getUsersMeta',
	async (_emptyInput, { rejectWithValue }) => {
		let users: UserMeta[];

		try {
			const resp = await apiCalls.getUsers();

			if (!resp?.data?.users) {
				const message = hasErrorInAPIResponse(resp)
					? resp.error
					: "No users returned from user meta load and no error message returned";
				return rejectWithValue(message);
			}
			users = resp.data.users;
		} catch (err: any) {
			const errorItem = err as { error: AxiosError };
			const { error } = errorItem;
	
			console.error(error);
			const data = error.response?.data as { message: string };
	
			return rejectWithValue(data.message);
		}

		return users;
	}
);

export const loadUserFull = createAsyncThunk<User, number>(
	'entities/getUserFull',
	async (userId, { rejectWithValue }) => {
		let user: User;

		try {
			const resp = await apiCalls.getUser(userId);

			user = resp.data.user;
		} catch (err: any) {
			const error: AxiosError = err;

			console.error(error);

			return rejectWithValue(null);
		}

		return user;
	}
);

// export const updateUserData = createAsyncThunk<
// 	UpdateUserInput | null,
// 	UpdateUserInput,
// 	{
// 		state: AppState;
// 		rejectValue: string;
// 	}
// >('entities/updateUserData', async (updateUserInput, { rejectWithValue }) => {
// 	let valuesToUpdate: UpdateUserInput;

// 	try {
// 		const resp = await apiCalls.updateUser(updateUserInput);

// 		if (!resp.data.success) return rejectWithValue(resp.data.message);
// 		valuesToUpdate = updateUserInput;
// 	} catch (err: any) {
// 		const errorItem = err as { error: AxiosError };
// 		const { error } = errorItem;

// 		console.error(error);
// 		const data = error.response?.data as { message: string };

// 		return rejectWithValue(data.message);
// 	}

// 	return valuesToUpdate;
// });

export const createUser = createAsyncThunk<
	number | null,
	AddUserInput,
	{
		state: AppState;
		rejectValue: string;
	}
>('entities/createUser', async (newUser: AddUserInput, { rejectWithValue }) => {
	let newUserId: number;
	try {
		const resp = await apiCalls.createUser(newUser);

		if (!resp.data?.new_user_id) return rejectWithValue(resp.data.message ?? "No user id returned from user creation and no error message returned");
		newUserId = resp.data.new_user_id;
	} catch (err: any) {
		const errorItem = err as { error: AxiosError };
		const { error } = errorItem;

		console.error(error);
		const data = error.response?.data as { message: string };

		return rejectWithValue(data.message);
	}

	return newUserId;
});

export const deleteUser = createAsyncThunk<boolean, DeleteUserInput>(
	'entities/deleteUser',
	async (userDeletion, { rejectWithValue }) => {
		try {
			const resp = await apiCalls.deleteUser(userDeletion);

			if (!resp.data?.success) return rejectWithValue(resp.data.message ?? "No success message returned from user deletion and no error message returned");
			return resp.data.success;
		} catch (err: any) {
			const error: AxiosError = err;

			console.error(error);

			return rejectWithValue(null);
		}
	}
);

export const addUserRole = createAsyncThunk<
	UserRole,
	AddUserRoleInput
>('entities/addUserRole', async (addUserRoleInput, { rejectWithValue }) => {
	let newRole: UserRole;
	try {
		const resp = await apiCalls.addUserRole(addUserRoleInput);

		if (!resp.data?.success || !resp.data?.added_role) return rejectWithValue(resp.data.message ?? "No success message or added role returned from user role addition and no error message returned");
		newRole = resp.data.added_role;
	} catch (err: any) {
		const errorItem = err as {error: AxiosError};
		const { error } = errorItem;
		
		console.error(error);
		const data = error.response?.data as { message: string };
		return rejectWithValue(data.message);
	}
	return newRole;
});

export const deleteUserRole = createAsyncThunk<
	boolean,
	DeleteUserRoleInput,
	{ state: AppState, rejectValue: string }
>('entities/deleteUserRole', async (deleteUserRoleInput, { rejectWithValue }) => {
	try {
		const resp = await apiCalls.deleteUserRole(deleteUserRoleInput);

		if (!resp.data?.success) return rejectWithValue(resp.data.message ?? "No success message returned from user role deletion and no error message returned");
		return resp.data.success;
	} catch (err: any) {
		const errorItem = err as { error: AxiosError };
		const { error } = errorItem;

		console.error(error);
		const data = error.response?.data as { message: string };
		return rejectWithValue(data.message);
	}
});

export const getAccessibleRoles = createAsyncThunk<
	Role[],
	void,
	{ state: AppState, rejectWithValue: string }
>('entities/getAccessibleRoles', async (_emptyInput, { rejectWithValue }) => {
	try {
		const resp = await apiCalls.getRoles();

		return resp.data?.roles;
	} catch (err: any) {
		const errorItem = err as { error: AxiosError };
		const { error } = errorItem;

		console.error(error);
		const data = error.response?.data as { message: string };
		return rejectWithValue(data.message);
	}
});

export const updateUserAttribute = createAsyncThunk<
	boolean,
	UpdateUserAttributeInput,
	{ state: AppState, rejectValue: string }
>('entities/updateUserAttribute', async (updateUserDto, { rejectWithValue }) => {
	try {
		const resp = await apiCalls.setUserAttribute(updateUserDto.attribute_name, updateUserDto.attribute_value, updateUserDto.user_id);
		if (resp.status !== 200) return rejectWithValue("No success message returned from user attribute update and no error message returned");
		return resp.data?.success;
	} catch (err: any) {
		const errorItem = err as { error: AxiosError };
		const { error } = errorItem;

		console.error(error);
		const data = error.response?.data as { message: string };
		return rejectWithValue(data.message);
	}
});

export const userBuilder: extraEntityReducersFunction = (builder) => {
	builder.addCase(loadUsersMeta.pending, (state) => {
		state.users.loadStatus = loadStatus.loading;
	});

	builder.addCase(loadUsersMeta.fulfilled, (entitiesState, action) => {
		const users = action.payload;

		users.forEach((user) => {
			if (entitiesState.users.list[user.id]?.loadStatus !== loadStatus.metaLoaded) {
				const workingUser = initialUser(user.id);
				const newUser = {
					...workingUser,
					...user,
					loadStatus: loadStatus.metaLoaded
				};
				entitiesState.users.list[user.id] = newUser;
			}
		});
		entitiesState.users.loadStatus = loadStatus.metaLoaded;
	});

	builder.addCase(loadUserFull.pending, (state, action) => {
		const id = action.meta.arg;
		const user = state.users.list[id];

		if (!user) return;

		state.users.list[id] = ({
			...user,
			loadStatus: loadStatus.loading
		});
	});

	builder.addCase(loadUserFull.fulfilled, (state, action) => {
		const user = action.payload;

		if (!user) return;

		state.users.list[user.id] = ({
			...state.users.list[user.id],
			...user,
			loadStatus: loadStatus.fullyLoaded
		});
	});

	// builder.addCase(updateUserData.fulfilled, (state, action) => {
	// 	const userId = action.payload?.user_id;
	// 	if (!userId) return;
	// 	const user = state.users.list[userId];
	// 	if (!user) return;

	// 	const newUserValues = action.meta.arg;
	// 	const newUser = {
	// 		...user,
	// 		...newUserValues,
	// 	};

	// 	state.users.list[userId] = newUser;
	// });

	builder.addCase(createUser.fulfilled, (state, action) => {
		const userId = action.payload;
		const userInformation = action.meta.arg;

		if (!userId) return;

		const initUser = initialUser(userId);
		const userInputToUser = {
			name: userInformation.user_name,
			email: userInformation.email,
		};
		const user: UserStore = {
			...initUser,
			...userInputToUser,
		};

		state.users.list[userId] = user;
	});

	builder.addCase(deleteUser.fulfilled, (state, action) => {
		const userDeletion = action.meta.arg;

		if (!userDeletion) return;

		delete state.users.list[userDeletion.user_id];
	});

	builder.addCase(addUserRole.fulfilled, (state, action) => {
		const addUserRoleInput = action.meta.arg;

		const user = state.users.list[addUserRoleInput.user_id];

		if (!user) return;
		const newRole = action.payload;

		const newUser = {
			...user,
			roles: [...user.roles, newRole]
		};

		state.users.list[addUserRoleInput.user_id] = newUser;
	});
	builder.addCase(deleteUserRole.fulfilled, (state, action) => {
		const deleteUserRoleInput = action.meta.arg;

		const user = state.users.list[deleteUserRoleInput.user_id];

		if (!user) return;

		const newUser = {
			...user,
			roles: user.roles.filter((role) => role.id !== deleteUserRoleInput.role_id)
		};

		state.users.list[deleteUserRoleInput.user_id] = newUser;
	});
	builder.addCase(getAccessibleRoles.pending, (state) => {
		state.users.roles.loadStatus = loadStatus.loading;
	});
	builder.addCase(getAccessibleRoles.fulfilled, (state, action) => {
		const roles = action.payload;

		state.users.roles.list = roles;
		state.users.roles.loadStatus = loadStatus.fullyLoaded;
	});
	builder.addCase(loadUsersMeta.rejected, (state) => {
		const previousUsers = state.users.list;

		state.users.list = previousUsers;
	});

	builder.addCase(updateUserAttribute.fulfilled, (state, action) => {
		const updateUserAttributeInput = action.meta.arg;

		const user = state.users.list[updateUserAttributeInput.user_id];

		if (!user) return;

		const newUser = {
			...user,
			[updateUserAttributeInput.attribute_name]: updateUserAttributeInput.attribute_value
		};

		state.users.list[updateUserAttributeInput.user_id] = newUser;
	});
};
