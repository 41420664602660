/* eslint-disable arrow-body-style */
import React, { FC, useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { Card, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useDealers, useDistributorLocationById, useDistributorLocationsByDistributorId } from '../Store/entities/hooks';
import Button from '../Parts/Button';
import { UsePortalDispatch } from '../Store';
import { swapDealers } from '../Store/entities/organizations/distributorLocations';

type SwapDealerBetweenDistributorsModalType = {
	initialDistributorLocationId: number | undefined;
	distributorId: number;
	open: boolean;
	setOpen: (open: boolean) => void;
}

const SwapDealerBetweenDistributorsModal: FC<SwapDealerBetweenDistributorsModalType> = (props) => {
	const {
		initialDistributorLocationId,
		distributorId,
		open,
		setOpen
	} = props;

	const distributorLocations = useDistributorLocationsByDistributorId(distributorId);

	const [fromDistLocId, setFromDistLocId] = useState(initialDistributorLocationId ?? 0);

	const [toDistLocId, setToDistLocId] = useState(0);

	const dealers = useDealers();

	const fromDistLoc = useDistributorLocationById(fromDistLocId);

	const toDistLoc = useDistributorLocationById(toDistLocId);

	const dispatch = UsePortalDispatch();

	const setFromDistIdWhole = (distributorLocationId: number) => {
		if (distributorLocationId === toDistLocId) {
			setToDistLocId(0);
		}

		setFromDistLocId(distributorLocationId);
	};

	const fromDistributorOptions = distributorLocations.map((distributorLocation) => {
		return (
			<option
				key={distributorLocation?.id}
				value={distributorLocation?.id}
			>
				{distributorLocation?.name}
			</option>
		);
	});

	fromDistributorOptions.unshift(<option key={0} value={0}>Select Distributor Location</option>);

	const toDistributorOptions = distributorLocations
		.filter((distributorLocation) => distributorLocation?.id !== fromDistLocId)
		.map((distributorLocation) => {
			return (
				<option
					key={distributorLocation?.id}
					value={distributorLocation?.id}
				>
					{distributorLocation?.name}
				</option>
			);
		});

	toDistributorOptions.unshift(<option key={0} value={0}>Select Distributor Location</option>);

	const [workingFromDealerList, setWorkingFromDealerList] = useState<number[]>([]);
	const [workingToDealerList, setWorkingToDealerList] = useState<number[]>([]);

	const swapToTo = (dealerid: number) => {
		const fromDealer = workingFromDealerList.find((dt) => dt === dealerid);

		if (fromDealer) {
			const newFromDealerList = workingFromDealerList.filter((dt) => dt !== dealerid);

			const newToDealerList = [...workingToDealerList, fromDealer];

			setWorkingFromDealerList(newFromDealerList);
			setWorkingToDealerList(newToDealerList);
		}
	};

	const swapToFrom = (dealerid: number) => {
		const toDealer = workingToDealerList.find((dt) => dt === dealerid);

		if (toDealer) {
			const newToDealerList = workingToDealerList.filter((dt) => dt !== dealerid);

			const newFromDealerList = [...workingFromDealerList, toDealer];

			setWorkingFromDealerList(newFromDealerList);
			setWorkingToDealerList(newToDealerList);
		}
	};

	const fromDealerItems = workingFromDealerList
		// Filter for common dealer_ids by removing duplicate dealer_ids.
		.map((dt) => {
			const dealer = dealers.find((d) => d.id === dt);

			return (
				<Card id={`from-${dealer?.name}-${dealer?.id}`} onClick={() => swapToTo(dt)}>
					<Card.Header>
						{dealer?.name}
					</Card.Header>
					{/* <Card.Body>
					<Row>
						{dealer?.billing_address?.city ?? ""}
						,
						{dealer?.billing_address?.state_code ?? ""}
					</Row>
				</Card.Body> */}
				</Card>
			);
		}) ?? [];

	const toDealerItems = workingToDealerList.map((dt) => {
		const dealer = dealers.find((d) => d.id === dt);

		return (
			<Card id={`to-${dealer?.name}-${dealer?.id}`} onClick={() => swapToFrom(dt)}>
				<Card.Header>
					{dealer?.name}
				</Card.Header>
				{/* <Card.Body>
					<Row>
						{dealer?.billing_address?.city ?? ""}
						,
						{dealer?.billing_address?.state_code ?? ""}
					</Row>
				</Card.Body> */}
			</Card>
		);
	}) ?? [];

	useEffect(() => {
		// Set working list

		const newToList = [
			...new Set(toDistLoc?.dealer_territories?.map((dt) => dt.dealer_id) ?? [])
		];
		const newFromList = [
			...new Set(fromDistLoc?.dealer_territories?.map((dt) => dt.dealer_id) ?? [])
		];

		setWorkingFromDealerList(newFromList);
		setWorkingToDealerList(newToList);
	}, [fromDistLoc, toDistLoc, fromDistLocId, toDistLocId, dealers]);

	const swap = () => {
		// Swap
		const allDealerTerritories = [
			...(toDistLoc?.dealer_territories ?? []), ...(fromDistLoc?.dealer_territories ?? [])
		];
		const finalToDealerTerritoryList = allDealerTerritories.filter(
			(dt) => workingToDealerList.includes(dt.dealer_id)
		);
		const finalFromDealerTerritoryList = allDealerTerritories.filter(
			(dt) => workingFromDealerList.includes(dt.dealer_id)
		);

		if (fromDistLoc && toDistLoc) {
			dispatch(swapDealers({
				from: { ...fromDistLoc, dealer_territories: finalFromDealerTerritoryList },
				to: { ...toDistLoc, dealer_territories: finalToDealerTerritoryList }
			})).then((resp) => {
				if (resp.meta.requestStatus === 'fulfilled') {
					setOpen(false);
					toast.success('Dealers Swapped');
				} else {
					toast.error(`Failed to swap dealers: ${resp.payload}`);
				}
			});
		}
	};

	const clearAndCancel = () => {
		setOpen(false);
		setFromDistLocId(0);
		setToDistLocId(0);
		
		setWorkingFromDealerList([]);
		setWorkingToDealerList([]);


	};

	return (
		<Popup
			open={open}
			onClose={() => { setOpen(false); }}
			closeOnDocumentClick
			closeOnEscape
			className="mobile-modal"
		>
			<Row>
				<h4>
					Distributor Location Dealer Swap
				</h4>
				<hr />
			</Row>
			<Row>
				<Col>
					<Row>
						<h6>
							From Distributor Location
						</h6>
					</Row>
					<Row>
						<Col>
							<select
								value={fromDistLocId}
								// placeholder="Please Select Distributor Location"
								onChange={(e) => setFromDistIdWhole(parseInt(e.target.value, 10))}
							>
								{fromDistributorOptions}
							</select>
							<hr />
						</Col>

					</Row>
					<Row>
						<Col>
							{fromDealerItems}
						</Col>
					</Row>
				</Col>
				<Col>
					<Row>
						<h6>
							To Distributor Location
						</h6>
					</Row>
					<Row>
						<Col>
							<select
								value={toDistLocId}
								// placeholder="Please Select Distributor Location"
								onChange={(e) => setToDistLocId(parseInt(e.target.value, 10))}
							>
								{toDistributorOptions}
							</select>
							<hr />
						</Col>
					</Row>
					<Row>
						<Col>
							{toDealerItems}
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Col width="50%">
					<Button className='mt-4' onClick={() => clearAndCancel()}>
						Cancel
					</Button>
					<Button className='mt-4 align-end' onClick={() => swap()}>
						Swap Dealers
					</Button>

				</Col>
			</Row>
		</Popup>
	);
};

export default SwapDealerBetweenDistributorsModal;
