import React, {  useState } from "react";
import { UseAssembly } from "../../Store/entities/assemblies/hooks";
import Popup from "reactjs-popup";
import { useQuoteTypeNameByQuoteId } from "../../Store/entities/quotes/hooks";
import { Col, Container, Row } from "react-bootstrap";
import Button from "../../Parts/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/free-solid-svg-icons";
import { duplicateAssemblies } from "../../Store/entities/assemblies";
import { UsePortalDispatch } from "../../Store";

type ShadeDuplicateProps = {
    quoteId: number;
    sequenceId: number;
}

const ShadeDuplicate = ({ quoteId, sequenceId }: ShadeDuplicateProps) => {
    const shadeData = UseAssembly(quoteId, sequenceId);
    const [isDuplicateOpen, setIsDuplicateOpen] = useState(false);
    const quoteType = useQuoteTypeNameByQuoteId(quoteId);
    const [duplicateCount, setDuplicateCount] = useState(1);
    const dispatch = UsePortalDispatch();
    const [isCurrentlyDuplicating, setIsCurrentlyDuplicating] = useState(false);

    if (!shadeData) return null;

    const onDuplicate = () => {
        setIsCurrentlyDuplicating(true);
        dispatch(duplicateAssemblies({ quoteId, sequenceIds: [sequenceId], duplicateCount })).then(() => {
            setIsDuplicateOpen(false);
            setDuplicateCount(1);
            setIsCurrentlyDuplicating(false);
        });
    }

    const {
        shade_name: shadeName
    } = shadeData;

    return (
        <>
            <Popup
                open={isDuplicateOpen}
                onClose={() => {
                    setIsDuplicateOpen(false);
                }}
                className="mobile-modal"
            >
                <div id="product-editor">
                    <div className="product-form">
                        <div style={{ overflowX: 'auto' }}>
                            <h2 className={`text-${quoteType}`}>
                                Duplicate Shade: {shadeName}
                            </h2>
                            <Container className="local-bootstrap">
                                <Row>
                                    <Col>
                                        How many shades need to be made from this shade?
                                    </Col>
                                    <Col>
                                        <input value={duplicateCount} type="number" onChange={(e) => setDuplicateCount(Number(e.target.value))} />
                                    </Col>
                                </Row>
                            </Container>
                            <Container className="local-bootstrap">
                                <Row className="justify-content-center mt-2">
                                    <Col lg={4}>
                                        <Button
                                            color={quoteType ?? 'green'}
                                            fullWidth
                                            disabled={isCurrentlyDuplicating}
                                            onClick={() => {
                                                setIsDuplicateOpen(false);
                                            }}
                                        >
                                            {isCurrentlyDuplicating ? "..." : "Cancel"}
                                        </Button>
                                    </Col>
                                    <Col lg={4}>
                                        <Button
                                            color={quoteType ?? 'green'}
                                            fullWidth
                                            disabled={isCurrentlyDuplicating}
                                            onClick={() => {
                                                onDuplicate();
                                            }}
                                        >
                                            {isCurrentlyDuplicating ? "Duplicating..." : "Duplicate"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </Popup>
            <Button
                fullWidth
                color="light"
                size="sm"
                title="Duplicate Shade"
                hoverColor={quoteType}
                onClick={() => setIsDuplicateOpen(true)}
            >
                <FontAwesomeIcon
                    transform="grow-5"
                    icon={faClone}
                />
            </Button>
        </>
    )
};

export default ShadeDuplicate;