import React, { ChangeEvent, useEffect, useState } from 'react';
import {
	Card, Row, Col, Container, Form
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import * as formik from 'formik';
import * as Sentry from "@sentry/react";
import {
	object, ObjectSchema, string, boolean, ref, number
} from 'yup';
import { debounce } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import type { DealerSignupProps } from './types';
import FileUpload from '../Parts/FileUploader';

import { initialDealerSignUpForm } from './utilities';
import StateSelectorTyped from '../Parts/StateSelectorTyped';
import CountrySelectorTyped from '../Parts/CountrySelectorTyped';
// import { useStateByCode } from '../Store/entities/hooks';
import Button from '../Parts/Button';
import { useStateList } from '../Store/entities/hooks';
import apiCalls from '../PowerShadesAPIFunctions';
import { DealerSignUpForm } from '../powershadesApiTypes';
import { AddressStateStore } from '../Store/entities/addressStates';

// import { UsePortalDispatch } from '../Store';

const debouncedDealerNameExists = debounce(
	(
		val: string,
		setFieldError: (field: string, message: string) => void,
	) => {
		apiCalls.dealerNameExists(val, 0).then((resp) => {
			if (resp.data.name_exists) {
				console.debug('Company name already exists');
				setFieldError('company_name', 'This company name is already being used');
			}
		});
	},
	500
);

const dealerSignUpSchema: ObjectSchema<DealerSignUpForm> = object({
	company_name: string()
		.required('Company name is required'),
	contact_first_name: string().required('First name of primary contact is required'),
	contact_last_name: string().required('Last name of primary contact is required'),
	contact_email: string().email().required('Email of primary contact is required'),
	contact_phone: string().required('Phone of primary contact is required'),
	billing_first_name: string().required('First name of billing contact is required'),
	billing_last_name: string().required('Last name of billing contact is required'),
	billing_email: string().email().required('Email of billing contact is required'),
	billing_phone: string().required('Phone of billing contact is required'),
	billing_address1: string().required('Billing address is required'),
	billing_address2: string().default('').optional().defined(),
	billing_address_city: string().required('Billing city is required'),
	billing_address_zip: string().required('Billing postal code is required'),
	billing_address_state_code: string().required('Billing state is required'),
	billing_address_country_subdivision_id: number().required('Billing country subdivision is required'),
	billing_address_country_code: string().required('Billing Country Required'),
	mailing_same_as_billing: boolean().default(false).required('Mailing address is required'),
	mailing_first_name: string().required('First name of mailing contact is required'),
	mailing_last_name: string().required('Last name of mailing contact is required'),
	mailing_address1: string().required('Mailing address is required'),
	mailing_address2: string().default('').optional().defined(),
	mailing_address_city: string().required('Mailing city is required'),
	mailing_address_zip: string().required('Mailing postal code is required'),
	mailing_address_country_code: string().required('Mailing country is required'),
	mailing_address_country_subdivision_id: number().required('Mailing country subdivision is required'),
	is_sales_tax_exempt: boolean()
		.default(false)
		.required('Sales tax exemption status is required'),
	sales_tax_exemption_type: string()
		.required()
		.when('is_sales_tax_exempt', (isExempt) =>
			(!isExempt ? string().required('Exemption type is required') : string().optional())
		),
	sales_tax_exempt_number: string()
		.required()
		.when('is_sales_tax_exempt', (isExempt) =>
		(!isExempt
			? string().required('Sales tax exemption number is required')
			: string().optional())
		),
	sales_tax_file_location: string()
		.required()
		.when('is_sales_tax_exempt', (isExempt) =>
		(!isExempt
			? string().required('Sales tax exemption file is required')
			: string().optional())
		),
	federal_tax_id: string()
		.required()
		.when('is_sales_tax_exempt', (isExempt) =>
			(!isExempt ? string().required('Federal tax number is required') : string().optional())
		),
	federal_tax_file_location: string()
		.required()
		.when('is_sales_tax_exempt', (isExempt) =>
			(!isExempt ? string().required('Federal tax file is required') : string().optional())
		),
	password: string().min(8).required('Password is required'),
	confirm_password: string()
		.min(8)
		.required('Password confirmation is required')
		.oneOf([ref('password'), ''], 'Passwords must match'),
	success: boolean().default(false),
	invite_code: string().default('').optional().defined(),
	territory_id: number().default(0).optional().defined(),
	user_signup_id: number().default(0).optional().defined(),
	error: string().default('').optional().defined(),

});

const DealerSignup = ({ ROOT }: DealerSignupProps) => {
	const { Formik } = formik;
	const states = useStateList();
	// const dispatch = UsePortalDispatch();

	const { setTitle } = ROOT;

	const [searchParams] = useSearchParams();
	const inviteCode = searchParams.get('invite');
	const signUpUserId = parseInt(searchParams.get('u') ?? "0", 10);
	const territoryId = parseInt(searchParams.get('t') ?? "0", 10);

	useEffect(() => {
		setTitle('Dealer Signup');
	}, [setTitle]);

	type addressPartial = {
		country: {
			value: string;
			valid: boolean;
		};
		state: {
			value: AddressStateStore | string;
			valid: boolean;
		};
	};

	const [mailAddress, setMailAddress] = useState<addressPartial>({
		country: {
			value: "",
			valid: true
		}, state: {
			value: "",
			valid: true
		}
	});

	const [billAddress, setBillAddress] = useState<addressPartial>({
		country: {
			value: "",
			valid: true
		}, state: {
			value: "",
			valid: true
		}
	});

	const updateAddress = (setAddress, updates) => {
		setAddress((prev) => {
			const updatedState = { ...prev };
			Object.entries(updates).forEach(([keyPath, value]) => {
				const keys = keyPath.split('.');
				keys.reduce((acc, key, index) => {
					if (index === keys.length - 1) {
						acc[key] = value;
					} else {
						acc[key] = { ...acc[key] };
					}
					return acc[key];}, updatedState);
			});
			return updatedState;
		});
	};

	const submitForm = (
		values: DealerSignUpForm,
		setSubmitting: (isSubmitting: boolean) => void
	) => {
		console.log(values);
		console.log(setSubmitting);
		// Submit the form
		// dispatch();
		apiCalls
			.dealerSignup(values)
			.then((resp) => {
				console.log(resp);
				setSubmitting(false);

				if (resp.status === 200) {
					ROOT.toast('Dealer Signup Successful', { type: 'success' });
				} else {
					const dealerValuesDehydrated = {
						...values,
						password: undefined
					};
					const message = `Sign Up Failed! ${resp.data.message}`;
					const messageProvided = !resp.data.message;
					toast.error(message);
					if (!messageProvided) {
						console.log(resp.data);
						Sentry.captureException("Dealer Signup Failed (Unhandled)", {
							tags: {
								section: 'dealer_signup'
							},
							extra: {
								response: resp.data,
								error: resp?.error,
								unhandled: true,
								dealerInfo: dealerValuesDehydrated
							},
							level: 'error',
						});
					} else {
						console.log(resp.data);
						Sentry.captureMessage("Dealer Signup Failed (Handled)", {
							tags: {
								section: 'dealer_signup'
							},
							extra: {
								response: resp.data,
								error: resp?.error,
								unhandled: false,
								dealerInfo: dealerValuesDehydrated,
								message
							},
							level: 'info'
						});
					}
					setSubmitting(false);
				}
			})
			.catch((err) => {
				console.error({ err });
				if (err?.data?.message) {
					ROOT.toast(`Dealer Signup Failed: ${err.data.message}`, { type: 'error' });
				} else {
					ROOT.toast('Dealer Signup Failed', { type: 'error' });
				}
				setSubmitting(false);
				throw new Error(err);
			});
	};

	const dealerSignupAttempt = (handleSubmit: () => any, valuesGiven:DealerSignUpForm ) => {
		updateAddress(setMailAddress, {
			"state.valid": !!mailAddress.state.value,
			"country.valid": !!mailAddress.country.value
		});
		updateAddress(setBillAddress, {
			"state.valid": !!billAddress.state.value,
			"country.valid": !!billAddress.country.value
		});
		const cleanValues = {
			...valuesGiven,
			password: "",
			confirm_password: "",
		}
		apiCalls.dealerSignupButtonClick(cleanValues).then((resp) => {
			console.log(resp);
			handleSubmit();
		});
	}

	return (
		<Container>
			<Card>
				<Card.Body>
					<Card.Title>
						<Row>
							<Col>
								<h3>Dealer Signup</h3>
							</Col>
						</Row>
					</Card.Title>
					<Card.Text>
						<Formik
							validationSchema={dealerSignUpSchema}
							onSubmit={(values, { setSubmitting }) => {
								submitForm(values, setSubmitting);
							}}
							initialValues={initialDealerSignUpForm(inviteCode ?? "", territoryId ?? 0, signUpUserId ?? 0)}
						>
							{({
								handleSubmit,
								handleChange,
								values,
								errors,
								touched,
								isSubmitting,
								setFieldValue,
								setFieldError,
							}) => {
								const handleChangeWrapper: typeof handleChange = (e) => {
									if (
										e.target.name === 'mailing_same_as_billing'
										&& e.target.value
									) {
										setFieldValue(
											'mailing_first_name',
											values.billing_first_name
										);
										setFieldValue(
											'mailing_last_name',
											values.billing_last_name
										);
										setFieldValue('mailing_email', values.billing_email);
										setFieldValue('mailing_phone', values.billing_phone);
										setFieldValue('mailing_address1', values.billing_address1);
										setFieldValue('mailing_address2', values.billing_address2);
										setFieldValue('mailing_address_city', values.billing_address_city);
										setFieldValue('mailing_address_zip', values.billing_address_zip);
										setFieldValue(
											'mailing_address_country_code',
											values.billing_address_country_code
										);
										setFieldValue(
											'mailing_address_country_subdivision_id',
											values.billing_address_country_subdivision_id
										);
									}

									if (
										values.mailing_same_as_billing
										&& e.target.name.startsWith('billing_')
									) {
										const eventName = e.target.name.replace(
											'billing_',
											'mailing_'
										);
										handleChange({
											target: {
												name: eventName,
												value: e.target.value,
											},
										});
									}

									handleChange(e);
								};

								const handleCompanyNameChange = (e: ChangeEvent<HTMLInputElement>) => {
									debouncedDealerNameExists(e.target.value, setFieldError);
									handleChange(e);
								};

								return (
									<Form
										noValidate
										onSubmit={(e) => {
											e.preventDefault();
									//		const idk = handleSubmit();
											// console.log(idk);
										}}
										autoComplete="off"
										id="Maybe?"
									>
										<Row className="mb-4">
											<Form.Group as={Col} id="company-name">
												<label>Company Name</label>
												<Form.Control
													value={values.company_name}
													name="company_name"
													onChange={handleCompanyNameChange}
													isValid={
														!!(
															touched.company_name
															&& !errors.company_name
														)
													}
													isInvalid={
														!!(
															touched.company_name
															&& !!errors.company_name
														)
													}
												// onChange={(e) =>
												// 	mutateDealerSignUpForm({
												// 		company_name: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.company_name}
													{/* {companyNameTaken === "taken" 
														? `Dealer Name ${values.company_name} has been taken.` 
														: errors.company_name} */}
												</Form.Control.Feedback>
											</Form.Group>
										</Row>
										<Row className="mb-2">
											<Col>
												<h4>Primary Contact Info</h4>
											</Col>
										</Row>
										<Row className="mb-2">
											<Form.Group as={Col} id="contact_first_name">
												<label>First Name</label>
												<Form.Control
													value={values.contact_first_name}
													name="contact_first_name"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.contact_first_name
															&& !errors.contact_first_name
														)
													}
													isInvalid={
														!!(
															touched.contact_first_name
															&& !!errors.contact_first_name
														)
													}
												// value={dealerSignUpForm.contact_first_name}
												// onChange={(e) =>
												// 	mutateDealerSignUpForm({
												// 		contact_first_name: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.contact_first_name}
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group as={Col} id="contact_last_name">
												<label>Last Name</label>
												<Form.Control
													value={values.contact_last_name}
													name="contact_last_name"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.contact_last_name
															&& !errors.contact_last_name
														)
													}
													isInvalid={
														!!(
															touched.contact_last_name
															&& !!errors.contact_last_name
														)
													}
												// value={dealerSignUpForm.contact_last_name}
												// onChange={(e) =>
												// 	mutateDealerSignUpForm({
												// 		contact_last_name: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.contact_last_name}
												</Form.Control.Feedback>
											</Form.Group>
										</Row>
										<Row className="mb-4">
											<Form.Group as={Col} id="contact_phone">
												<label>Phone</label>
												<Form.Control
													type="tel"
													value={values.contact_phone}
													name="contact_phone"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.contact_phone
															&& !errors.contact_phone
														)
													}
													isInvalid={
														!!(
															touched.contact_phone
															&& !!errors.contact_phone
														)
													}
												// value={dealerSignUpForm.contact_phone}
												// onChange={(e) =>
												// 	mutateDealerSignUpForm({
												// 		contact_phone: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.contact_phone}
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group as={Col} id="contact_email">
												<label>Email</label>
												<Form.Control
													value={values.contact_email}
													name="contact_email"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.contact_email
															&& !errors.contact_email
														)
													}
													isInvalid={
														!!(
															touched.contact_email
															&& !!errors.contact_email
														)
													}
												// value={dealerSignUpForm.contact_email}
												// onChange={(e) =>
												// 	mutateDealerSignUpForm({
												// 		contact_email: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.contact_email}
												</Form.Control.Feedback>
											</Form.Group>
										</Row>
										<Row className="mb-2">
											<Col>
												<h4>Billing Info</h4>
											</Col>
										</Row>
										<Row className="mb-2">
											<Form.Group as={Col} id="billing_first_name">
												<label>First Name</label>
												<Form.Control
													value={values.billing_first_name}
													name="billing_first_name"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.billing_first_name
															&& !errors.billing_first_name
														)
													}
													isInvalid={
														!!(
															touched.billing_first_name
															&& !!errors.billing_first_name
														)
													}
												// value={dealerSignUpForm.billing_first_name}
												// onChange={(e) =>
												// 	updateBillingAddress({
												// 		billing_first_name: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.billing_first_name}
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group as={Col} id="billing_last_name">
												<label>Last Name</label>
												<Form.Control
													value={values.billing_last_name}
													name="billing_last_name"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.billing_last_name
															&& !errors.billing_last_name
														)
													}
												// value={dealerSignUpForm.billing_last_name}
												// onChange={(e) =>
												// 	updateBillingAddress({
												// 		billing_last_name: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.billing_last_name}
												</Form.Control.Feedback>
											</Form.Group>
										</Row>
										<Row className="mb-2">
											<Form.Group as={Col} id="billing_phone">
												<label>Phone</label>
												<Form.Control
													type="tel"
													value={values.billing_phone}
													name="billing_phone"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.billing_phone
															&& !errors.billing_phone
														)
													}
													isInvalid={
														!!(
															touched.billing_phone
															&& !!errors.billing_phone
														)
													}
												// value={dealerSignUpForm.billing_phone}
												// onChange={(e) =>
												// 	updateBillingAddress({
												// 		billing_phone: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.billing_phone}
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group as={Col} id="billing_email">
												<label>Email</label>
												<Form.Control
													value={values.billing_email}
													name="billing_email"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.billing_email
															&& !errors.billing_email
														)
													}
													isInvalid={
														!!(
															touched.billing_email
															&& !!errors.billing_email
														)
													}
												// value={dealerSignUpForm.billing_email}
												// onChange={(e) =>
												// 	updateBillingAddress({
												// 		billing_email: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.billing_email}
												</Form.Control.Feedback>
											</Form.Group>
										</Row>
										<Row className="mb-2">
											<Form.Group as={Col} id="billing_address1">
												<label>Address 1</label>
												<Form.Control
													value={values.billing_address1}
													name="billing_address1"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.billing_address1
															&& !errors.billing_address1
														)
													}
													isInvalid={
														!!(
															touched.billing_address1
															&& !!errors.billing_address1
														)
													}
												// value={dealerSignUpForm.billing_address1}
												// onChange={(e) =>
												// 	updateBillingAddress({
												// 		billing_address1: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.billing_address1}
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group as={Col} id="billing_address2">
												<label>Address 2</label>
												<Form.Control
													value={values.billing_address2}
													name="billing_address2"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.billing_address2
															&& !errors.billing_address2
														)
													}
													isInvalid={
														!!(
															touched.billing_address2
															&& !!errors.billing_address2
														)
													}
												// value={dealerSignUpForm.billing_address2}
												// onChange={(e) =>
												// 	updateBillingAddress({
												// 		billing_address2: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.billing_address2}
												</Form.Control.Feedback>
											</Form.Group>
										</Row>
										<Row className="mb-2">
											<Form.Group as={Col} id="billing_city">
												<label>City</label>
												<Form.Control
													value={values.billing_address_city}
													name="billing_address_city"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.billing_address_city
															&& !errors.billing_address_city
														)
													}
													isInvalid={
														!!(
															touched.billing_address_city
															&& !!errors.billing_address_city
														)
													}
												// value={dealerSignUpForm.billing_city}
												// onChange={(e) =>
												// 	updateBillingAddress({
												// 		billing_city: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.billing_address_city}
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group as={Col} id="billing_zip">
												<label>Postal Code</label>
												<Form.Control
													value={values.billing_address_zip}
													name="billing_address_zip"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.billing_address_zip
															&& !errors.billing_address_zip
														)
													}
													isInvalid={
														!!(
															touched.billing_address_zip
															&& !!errors.billing_address_zip
														)
													}
												// value={dealerSignUpForm.billing_zip}
												// onChange={(e) =>
												// 	updateBillingAddress({
												// 		billing_zip: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.billing_address_zip}
												</Form.Control.Feedback>
											</Form.Group>
										</Row>
										<Row className="mb-4">
											<Form.Group as={Col} id="billing_state_code">
												<StateSelectorTyped
													currentStateCode={states.find(s => s.id === values.billing_address_country_subdivision_id)?.code ?? ''}
													onChange={(newStateCode) => {
														const newState = states.find(
															(state) => state.code === newStateCode
														);
														handleChangeWrapper({
															target: {
																name: 'billing_address_state_code',
																value: newStateCode,
															},
														});
														handleChangeWrapper({
															target: {
																name: 'billing_address_country_subdivision_id',
																value: newState?.id,
															},
														});
														handleChangeWrapper({
															target: {
																name: 'billing_address_country_code',
																value: newState?.country_code,
															},
														});
														updateAddress(setBillAddress, {
															"state.value": newState || "",
															"state.valid": !!newState,
															"country.value": newState?.country_code
															|| billAddress.country.value,
															"country.valid": !!newState?.country_code
														});
													}}
													isValid={billAddress.state.valid}
												/>
											</Form.Group>
											<Form.Group as={Col} id="billing_address_country_code">
												<CountrySelectorTyped
													currentCountryCode={values.billing_address_country_code}
													onChange={(newCountryCode) => {
														updateAddress(setBillAddress, {
															"country.value": newCountryCode || "",
															"country.valid": !!newCountryCode
														});
														handleChangeWrapper({
															target: {
																name: 'billing_address_country_code',
																value: newCountryCode,
															},
														});
													}}
													isValid={billAddress.country.valid}
												/>
											</Form.Group>
										</Row>
										<Row className="mb-2">
											<Col>
												<h4>Mailing Info</h4>
											</Col>
											<Col xs={3}>
												<Button
													fluid
													fullWidth
													color={
														values.mailing_same_as_billing
															? 'green'
															: 'gray'
													}
													onClick={() =>
														handleChangeWrapper({
															target: {
																name: 'mailing_same_as_billing',
																value: !values.mailing_same_as_billing,
															},
														})
													}
												>
													Same as Billing&nbsp;
													<FontAwesomeIcon
														icon={
															values.mailing_same_as_billing
																? faCheck
																: faTimes
														}
													/>
												</Button>
											</Col>
											{/* <input
									type="checkbox"
									checked={dealerSignUpForm.mailing_same_as_billing}
									onChange={(e) =>
										mutateDealerSignUpForm({
											mailing_same_as_billing: e.target.checked,
										})
									}
								/> */}
										</Row>
										<Row className="mb-2">
											<Form.Group as={Col} id="mailing_first_name">
												<label>First Name</label>
												<Form.Control
													value={values.mailing_first_name}
													name="mailing_first_name"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.mailing_first_name
															&& !errors.mailing_first_name
														)
													}
													isInvalid={
														!!(
															touched.mailing_first_name
															&& !!errors.mailing_first_name
														)
													}
													disabled={values.mailing_same_as_billing}
												// value={dealerSignUpForm.mailing_first_name}
												// disabled={dealerSignUpForm.mailing_same_as_billing}
												// onChange={(e) =>
												// 	mutateDealerSignUpForm({
												// 		mailing_first_name: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.mailing_first_name}
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group as={Col} id="mailing_last_name">
												<label>Last Name</label>
												<Form.Control
													value={values.mailing_last_name}
													name="mailing_last_name"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.mailing_last_name
															&& !errors.mailing_last_name
														)
													}
													isInvalid={
														!!(
															touched.mailing_last_name
															&& !!errors.mailing_last_name
														)
													}
													disabled={values.mailing_same_as_billing}
												// value={dealerSignUpForm.mailing_last_name}
												// disabled={dealerSignUpForm.mailing_same_as_billing}
												// onChange={(e) =>
												// 	mutateDealerSignUpForm({
												// 		mailing_last_name: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.mailing_last_name}
												</Form.Control.Feedback>
											</Form.Group>
										</Row>
										<Row className="mb-2">
											<Form.Group as={Col} id="mailing_address1">
												<label>Address 1</label>
												<Form.Control
													value={values.mailing_address1}
													name="mailing_address1"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.mailing_address1
															&& !errors.mailing_address1
														)
													}
													isInvalid={
														!!(
															touched.mailing_address1
															&& !!errors.mailing_address1
														)
													}
													disabled={values.mailing_same_as_billing}
												// value={dealerSignUpForm.mailing_address1}
												// disabled={dealerSignUpForm.mailing_same_as_billing}
												// onChange={(e) =>
												// 	mutateDealerSignUpForm({
												// 		mailing_address1: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.mailing_address1}
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group as={Col} id="mailing_address2">
												<label>Address 2</label>
												<Form.Control
													value={values.mailing_address2}
													name="mailing_address2"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.mailing_address2
															&& !errors.mailing_address2
														)
													}
													isInvalid={
														!!(
															touched.mailing_address2
															&& !!errors.mailing_address2
														)
													}
													disabled={values.mailing_same_as_billing}
												// value={dealerSignUpForm.mailing_address2}
												// disabled={dealerSignUpForm.mailing_same_as_billing}
												// onChange={(e) =>
												// 	mutateDealerSignUpForm({
												// 		mailing_address2: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.mailing_address2}
												</Form.Control.Feedback>
											</Form.Group>
										</Row>
										<Row className="mb-2">
											<Form.Group as={Col} id="mailing_city">
												<label>City</label>
												<Form.Control
													value={values.mailing_address_city}
													name="mailing_address_city"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.mailing_address_city
															&& !errors.mailing_address_city
														)
													}
													isInvalid={
														!!(
															touched.mailing_address_city
															&& !!errors.mailing_address_city
														)
													}
													disabled={values.mailing_same_as_billing}
												// value={dealerSignUpForm.mailing_city}
												// disabled={dealerSignUpForm.mailing_same_as_billing}
												// onChange={(e) =>
												// 	mutateDealerSignUpForm({
												// 		mailing_city: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.mailing_address_city}
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group as={Col} id="mailing_zip">
												<label>Postal Code</label>
												<Form.Control
													value={values.mailing_address_zip}
													name="mailing_address_zip"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.mailing_address_zip
															&& !errors.mailing_address_zip
														)
													}
													isInvalid={
														!!(
															touched.mailing_address_zip
															&& !!errors.mailing_address_zip
														)
													}
													disabled={values.mailing_same_as_billing}
												// value={dealerSignUpForm.mailing_zip}
												// disabled={dealerSignUpForm.mailing_same_as_billing}
												// onChange={(e) =>
												// 	mutateDealerSignUpForm({
												// 		mailing_zip: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.mailing_address_zip}
												</Form.Control.Feedback>
											</Form.Group>
										</Row>
										<Row className="mb-4">
											<Form.Group as={Col} id="mailing_address_state_code">
												<StateSelectorTyped
													disabled={values.mailing_same_as_billing}
													currentStateCode={states.find(s => s.id === values.mailing_address_country_subdivision_id)?.code ?? ''}
													onChange={(newStateCode) => {
														const newState = states.find(
															(state) => state.code === newStateCode
														);
														handleChangeWrapper({
															target: {
																name: 'mailing_address_state_code',
																value: newStateCode,
															},
														});
														handleChangeWrapper({
															target: {
																name: 'mailing_address_country_subdivision_id',
																value: newState?.id,
															},
														});
														handleChangeWrapper({
															target: {
																name: 'mailing_address_country_code',
																value: newState?.country_code,
															},
														});
														updateAddress(setMailAddress, {
															"state.value": newState || "",
															"state.valid": !!newState,
															"country.value": newState?.country_code
															|| mailAddress.country.value,
															"country.valid": !!newState?.country_code
														});
													}}
													isValid={
														(!!values.mailing_same_as_billing
															&& !!values.billing_address_country_subdivision_id)
															|| mailAddress.state.valid
													}
												// onChange={(newStateCode) =>
												// 	updateBillingAddress({
												// 		mailing_state_code: newStateCode,
												// 	})
												// }
												/>
											</Form.Group>
											<Form.Group as={Col} id="mailing_country_code">
												<CountrySelectorTyped
													disabled={values.mailing_same_as_billing}
													currentCountryCode={values.mailing_address_country_code}
													onChange={(newCountryCode) => {
														updateAddress(setMailAddress, {
															"country.value": newCountryCode || "",
															"country.valid": !!newCountryCode
														});
														handleChangeWrapper({
															target: {
																name: 'mailing_address_country_code',
																value: newCountryCode,
															},
														});
													}}
													isValid={
														(!!values.mailing_same_as_billing
															&& !!values.billing_address_country_code)
															|| mailAddress.country.valid
													}
												// onChange={(newCountryCode) =>
												// 	updateBillingAddress({
												// 		mailing_country_code: newCountryCode,
												// 	})
												// }
												/>
											</Form.Group>
										</Row>
										<Row className="mb-2">
											<Col>
												<h4>Sales Tax Exemption Info</h4>
											</Col>
											<Col xs={3}>
												<Button
													fluid
													fullWidth
													color={
														values.is_sales_tax_exempt
															? 'green'
															: 'gray'
													}
													onClick={
														() =>
															handleChangeWrapper({
																target: {
																	name: 'is_sales_tax_exempt',
																	value: !values.is_sales_tax_exempt,
																},
															})
														// mutateDealerSignUpForm({
														// 	is_sales_tax_exempt:
														// 		!dealerSignUpForm.is_sales_tax_exempt,
														// })
													}
												>
													Tax Exempt&nbsp;
													<FontAwesomeIcon
														icon={
															values.is_sales_tax_exempt
																? faCheck
																: faTimes
														}
													/>
												</Button>
											</Col>
										</Row>
										<Row className="mb-2">
											<Form.Group as={Col} id="sales_tax_exemption_type">
												<label>Exemption Type</label>
												<Form.Select
													value={values.sales_tax_exemption_type}
													name="sales_tax_exemption_type"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.sales_tax_exemption_type
															&& !errors.sales_tax_exemption_type
														)
													}
													isInvalid={
														!!(
															touched.sales_tax_exemption_type
															&& !!errors.sales_tax_exemption_type
														)
													}
													disabled={!values.is_sales_tax_exempt}
												// disabled={!dealerSignUpForm.is_sales_tax_exempt}
												// value={dealerSignUpForm.exemption_type}
												// onChange={(e) =>
												// 	mutateDealerSignUpForm({
												// 		exemption_type: e.target.value,
												// 	})
												// }
												>
													<option value="non_exempt" selected disabled>
														Select Exemption Type
													</option>
													<option value="government">Government</option>
													<option value="nonprofit">Non-Profit</option>
													<option value="non_exempt">Not Exempt</option>
													<option value="wholesale">Wholesale</option>
												</Form.Select>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.sales_tax_exemption_type}
												</Form.Control.Feedback>
											</Form.Group>
										</Row>
										<Row className="mb-2">
											<Form.Group as={Col} id="sales_tax_exempt_number">
												<label>Sales Tax Exempt Number</label>
												<Form.Control
													value={values.sales_tax_exempt_number}
													name="sales_tax_exempt_number"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.sales_tax_exempt_number
															&& !errors.sales_tax_exempt_number
														)
													}
													isInvalid={
														!!(
															touched.sales_tax_exempt_number
															&& !!errors.sales_tax_exempt_number
														)
													}
													disabled={!values.is_sales_tax_exempt}
												// disabled={!dealerSignUpForm.is_sales_tax_exempt}
												// value={dealerSignUpForm.sales_tax_exempt_number}
												// onChange={(e) =>
												// 	mutateDealerSignUpForm({
												// 		sales_tax_exempt_number: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.sales_tax_exempt_number}
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group as={Col} id="sales_tax_file_location">
												<label>Sales Tax Exemption File</label>
												<FileUpload
													disabled={!values.is_sales_tax_exempt}
													onSuccess={(fileLocation) =>
														handleChangeWrapper({
															target: {
																name: 'sales_tax_file_location',
																value: fileLocation,
															},
														})
													}
													// onSuccess={(fileLocation) =>
													// 	mutateDealerSignUpForm({
													// 		sales_tax_file_location: fileLocation,
													// 	})
													// }
													certType="state"
													entityType="dealer"
													entityID={null}
												/>
											</Form.Group>
										</Row>
										<Row className="mb-4">
											<Form.Group as={Col} id="federal_tax_id">
												<label>Federal Tax Exempt Number</label>
												<Form.Control
													value={values.federal_tax_id}
													name="federal_tax_id"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.federal_tax_id
															&& !errors.federal_tax_id
														)
													}
													isInvalid={
														!!(
															touched.federal_tax_id
															&& !!errors.federal_tax_id
														)
													}
													disabled={!values.is_sales_tax_exempt}
												// disabled={!dealerSignUpForm.is_sales_tax_exempt}
												// value={dealerSignUpForm.federal_tax_exempt_number}
												// onChange={(e) =>
												// 	mutateDealerSignUpForm({
												// 		federal_tax_exempt_number: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.federal_tax_id}
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group as={Col}>
												<label>Federal Tax Exempt File</label>
												<FileUpload
													disabled={!values.is_sales_tax_exempt}
													// onSuccess={(fileLocation) =>
													// 	mutateDealerSignUpForm({
													// 		federal_tax_file_location: fileLocation,
													// 	})
													// }
													onSuccess={(fileLocation) =>
														handleChangeWrapper({
															target: {
																name: 'federal_tax_file_location',
																value: fileLocation,
															},
														})
													}
													certType="federal"
													entityType="dealer"
													entityID={null}
												/>
											</Form.Group>
										</Row>
										<Row className="mb-2">
											<Col>
												<h4>Account Info</h4>
											</Col>
										</Row>
										<Row className="mb-4">
											<Form.Group>
												<label>Password</label>
												<Form.Control
													as="input"
													type="password"
													// value={dealerSignUpForm.password}
													// onChange={(e) =>
													// 	mutateDealerSignUpForm({
													// 		password: e.target.value,
													// 	})
													// }
													value={values.password}
													name="password"
													onChange={handleChangeWrapper}
													isValid={
														!!(touched.password && !errors.password)
													}
													isInvalid={
														!!(touched.password && !!errors.password)
													}
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.password}
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group>
												<label>Confirm Password</label>
												<Form.Control
													as="input"
													type="password"
													value={values.confirm_password}
													name="confirm_password"
													onChange={handleChangeWrapper}
													isValid={
														!!(
															touched.confirm_password
															&& !errors.confirm_password
														)
													}
													isInvalid={
														!!(
															touched.confirm_password
															&& !!errors.confirm_password
														)
													}
												// value={dealerSignUpForm.confirm_password}
												// onChange={(e) =>
												// 	mutateDealerSignUpForm({
												// 		confirm_password: e.target.value,
												// 	})
												// }
												/>
												<Form.Control.Feedback type="valid">
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													{errors.confirm_password}
												</Form.Control.Feedback>
											</Form.Group>
										</Row>
										<Row className="justify-content-end">
											<Col xs={4}>
												<Button
													type="submit"
													fullWidth
													loading={isSubmitting}
													onClick={() => dealerSignupAttempt(handleSubmit, values)}
												>
													Submit
												</Button>
											</Col>
										</Row>
									</Form>
								);
							}}
						</Formik>
					</Card.Text>
				</Card.Body>
			</Card>
		</Container>
	);
};

export default DealerSignup;
