import { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';

import SpinningWheel from '../Parts/SpinningWheel';
import api from '../PowerShadesAPI';

import Button from '../Parts/Button';

// Sample color pallete. Not currently being used.
const TAG_COLORS = [
	'#61bd4f',
	'#f2d600',
	'#ff9f1a',
	'#eb5a46',
	'#c377e0',
	'#0079bf',
	'#00c2e0',
	'#51e898',
	'#ff78cb',
	'#344563',
];

const TagSelector = (props) => {
	const [availableTags, setAvailableTags] = useState(null);
	const [selectedTags, setSelectedTags] = useState([]);
	const [expanded, setExpanded] = useState(false);
	const ref = useRef(null);

	function hideExpanded() {
		window.removeEventListener('click', windowClicked);

		setExpanded(false);
	}

	function windowClicked(e) {
		if (ref && ref.current && e.target) {
			if (!ref.current.contains(e.target)) {
				hideExpanded();
			}
		}
	}

	function showExpanded() {
		setTimeout(() => {
			window.addEventListener('click', windowClicked);
		}, 500);

		setExpanded(true);
	}

	function fetchAvailableTags() {
		// Get a list of available tags from the back-end.
		api.getTags().then(({ data }) => setAvailableTags(data.tags));
	}

	function fetchQuoteTags() {
		// Get a list of tags associated with this quote.
		api.getQuoteTags(props.quote.ID).then(({ data }) => {
			// Update the react state.
			// We only need to store the ids of each selected tag.
			setSelectedTags(data.tags.map((t) => t.id));
		});
	}

	function tagClicked(tag_id) {
		// Search for clicked tag in the "selectedTags" array.
		const found_ind = selectedTags.findIndex((tid) => tag_id === tid);

		// If we find the tag, we remove it at the index we found it at.
		// If we didn't find the tag, we'll add it.
		const new_selected_tags =
			found_ind > -1
				? selectedTags.filter((t, tind) => tind !== found_ind)
				: [...selectedTags, tag_id];

		setSelectedTags(new_selected_tags);

		// Tell the back-end to update the quote tags.
		api.setQuoteTags(props.quote.ID, new_selected_tags);
	}

	useState(() => {
		// We can fetch available tags as soon as the component is ready.
		fetchAvailableTags();

		// Add a hook to wait until the quote loads to fetch associated tags.
		const h_id = props.quote.hooks.add('loaded', fetchQuoteTags);

		// Fetch quote tags now if the quote has already been loaded.
		if (props.quote.quote_loaded) {
			fetchQuoteTags();
		}

		return () => props.quote.hooks.remove(h_id);
	}, []);

	const tag_els = (availableTags || []).map((tag) => (
		<div
			key={tag.id}
			className="quote-tag"
			style={{ backgroundColor: tag.color }}
			onClick={() => tagClicked(tag.id)}
		>
			<span>{tag.text}</span>
			{selectedTags.includes(tag.id) ? <FontAwesomeIcon icon={faCheck} className="selected-check-mark" /> : null}
		</div>
	));

	const summary_tags = (availableTags || [])
		.filter((at) => selectedTags.includes(at.id))
		.map((tag) => (
			<div
				key={tag.id}
				className="summary-quote-tag"
				style={{ backgroundColor: tag.color }}
				onClick={() => setExpanded(true)}
			>
				<span>{tag.text}</span>
			</div>
		));

	return (
		<div ref={ref} className={`quote-tag-selector ${props.fullWidth ? 'full-width' : null}`}>
			{expanded ? (
				<div className="expanded-selector">
					<button className="close-button" onClick={hideExpanded}>
						x
					</button>

					<h3>Tags</h3>

					<div className="tag-list">
						{availableTags ? (
							tag_els
						) : (
							<SpinningWheel
								style={{
									fontSize: '30px',
									top: '45%',
									left: 'calc(50%)',
									position: 'absolute',
								}}
							/>
						)}
					</div>
				</div>
			) : null}

			<div className={`summary-tag-list ${props.fullWidth ? 'full-width' : null}`}>
				{summary_tags}
				{selectedTags.length ? (
					<Button color="light" size="sm" title="Add Quote Tags" onClick={showExpanded}>
						<FontAwesomeIcon icon={faPlus} />
					</Button>
				) : (
					<Button
						color={props.variant}
						title="Add Quote Tags"
						fullWidth
						onClick={showExpanded}
					>
							Add Tags
							&nbsp;
						<FontAwesomeIcon icon={faPlus} />
					</Button>
				)}
			</div>
		</div>
	);
};

export default TagSelector;
