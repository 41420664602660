import type { AnyAction } from "@reduxjs/toolkit";
import type { SignalAction, SignalDispatch } from "redux-signalr";
import type {  TypedUseSelectorHook } from "react-redux";
import {  useDispatch, useSelector as useReduxSelector } from "react-redux";

import { createBrowserHistory } from 'history';
import type { rootReducer } from "./configureReducer";
import { store as notLocalStore } from "./configureStore";
import { persistStore } from "redux-persist";

export type AppState = ReturnType<typeof rootReducer>;

export type Action<ReturnValue = void> = SignalAction<
  ReturnValue,
  AppState,
  AnyAction
>;

export const persistor = persistStore(notLocalStore);

export type AppDispatch<Action2 extends AnyAction = AnyAction> = SignalDispatch<AppState, Action2>;

export const UsePortalSelector: TypedUseSelectorHook<AppState> = useReduxSelector;

export const UsePortalDispatch = () => useDispatch<AppDispatch>();

export const browserHistory = createBrowserHistory();

const store = notLocalStore;

export const UsePortalHistory = () => browserHistory;

export type AppStore = typeof store;

export default store;
