import { useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";

import TextInput from '../Parts/TextInput';
import StateSelector from '../Parts/StateSelector';
import Styles from '../Styles';
import Button from '../Parts/Button';

const InlineGroup = ({ children }) => (

	<div style={{
		display: 'flex', justifyContent: 'space-between', marginBottom: '0.5em'
	}}
	>
			
		{ children }
		
	</div>
);

const Hr = () => (

	<hr style={{
		background: 'gray', border: 'none', height: '1px', margin: '1.4em 1em 1em 1em'
	}}
	/>
);

const PropertyAddressEditor = ({
	quote,
	onCancel,
	onSet,
	label,
	initialAddress,
	quoteType
}) => {
	const [address, setAddress] = useState(
		initialAddress || {
			company_name: null,
			first_name: null,
			last_name: null,
			address: null,
			address2: null,
			state: null,
			city: null,
			zip: null,
			phone: null,
			email: null,
		}
	);

	function addressChanged(options) {
		const new_address = { ...address, ...options };

		setAddress(new_address);
	}

	function submitAddress() {
		const REQUIRED = [
			{ label: "Address", varName: "address" },
			{ label: "State", varName: "country_subdivision_id" },
			{ label: "City", varName: "city" },
			{ label: "Zip / Postal Code", varName: "zip" },
		];

		const missing_fields = REQUIRED.filter(
			(req) =>
				address[req.varName] == null
				|| address[req.varName] == ""
				|| address[req.varName] == undefined
		);

		if (missing_fields.length > 0) {
			ROOT.alert({
				icon: "error",
				title: "Missing Fields",
				text: (
					<span>
						Please fill out all required fields before setting this
						address.
						<br />
						<br />
						Missing Fields:
						<br />
						<ul>
							{missing_fields.map((field) => (
								<li key={field.varName}>{field.label}</li>
							))}
						</ul>
					</span>
				),
				acceptButtonText: "OK",
				noCancelButton: true,
				quoteType
			})?.then(() => { });
		} else {
			onSet && onSet(address);
		}
	}

	return (
		<div id="address-modal-wrapper">
			<div
				className="quote-address address-modal address-modal-two"
				style={{
					width: "30em",
					maxWidth: "95vw",
				}}
			>
				<Container className="local-bootstrap">
					<Row>
						<Col>
							<h4 className={`text-${quoteType}`}>{label || "New Address"}</h4>
						</Col>
					</Row>
					<Row>
						<Col>
							<TextInput
								style={{ width: "100%" }}
								label="Property Name"
								labelStyle={{ color: Styles.psGray }}
								onChange={(company_name) =>
									addressChanged({ company_name })}
								defaultValue={address.company_name}
								required
							/>
						</Col>
					</Row>

					<Hr />
					<Row>
						<Col>
							<TextInput
								label="Address Line 1"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ width: "100%", marginBottom: "0.5em" }}
								style={{ width: "100%" }}
								onChange={(address) => addressChanged({ address })}
								defaultValue={address.address}
								required
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<TextInput
								label="Address Line 2"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ width: "100%", marginBottom: "0.5em" }}
								style={{ width: "100%" }}
								onChange={(address2) => addressChanged({ address2 })}
								defaultValue={address.address2}
							/>
						</Col>
					</Row>

					<Row>
						<Col>
							<StateSelector
								key={address.country_subdivision_id}
								label="State / Province"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ flexGrow: "1", marginBottom: "0.5em" }}
								style={{ width: "100%" }}
								onChange={(country_subdivision_id) =>
									addressChanged({ country_subdivision_id })}
								defaultValue={address.country_subdivision_id}
								required
							/>
						</Col>
					</Row>

					<Row>
						<Col>
							<TextInput
								label="City"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ flexGrow: "1", marginRight: "3%" }}
								style={{ width: "100%" }}
								onChange={(city) => addressChanged({ city })}
								defaultValue={address.city}
								required
							/>
						</Col>
						<Col>
							<TextInput
								label="Zip / Postal Code"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ flexGrow: "1" }}
								style={{ width: "100%" }}
								onChange={(zip) => addressChanged({ zip })}
								defaultValue={address.zip}
								required
							/>
						</Col>
					</Row>

					<Hr />
					<Row>
						<Col>
							<h3
								className={`text-${quoteType}`}
								style={{
									textAlign: "center",
									margin: "0",
									fontSize: "18px",
									fontWeight: "normal",
								}}
							>
								Optional Customer Info
							</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<div
								style={{
									color: Styles.psGray,
									margin: "10px 0",
									marginBottom: "15px",
									textAlign: "center",
									fontSize: "13px",
								}}
							>
								Customer data will NOT be used for marketing purposes.
							</div>
							{" "}
						</Col>
					</Row>
					<Row>
						<Col>
							<TextInput
								label="First Name"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ marginRight: "3%", flexGrow: "1" }}
								style={{ width: "100%" }}
								onChange={(first_name) =>
									addressChanged({ first_name })}
								defaultValue={address.first_name}
							/>
						</Col>
						<Col>
							<TextInput
								label="Last Name"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ flexGrow: "1" }}
								style={{ width: "100%" }}
								onChange={(last_name) => addressChanged({ last_name })}
								defaultValue={address.last_name}
							/>
						</Col>
					</Row>

					<Row>
						<Col>
							<TextInput
								label="Email"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ flexGrow: "1" }}
								style={{ width: "95%" }}
								onChange={(email) => addressChanged({ email })}
								defaultValue={address.email}
							/>
						</Col>
						<Col>
							<TextInput
								label="Phone"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ flexGrow: "1" }}
								style={{ width: "100%" }}
								onChange={(phone) => addressChanged({ phone })}
								defaultValue={address.phone}
							/>
						</Col>
					</Row>
					<Row className="mt-2">
						<Col>
							<Button
								color="gray"
								onClick={onCancel}
							>
								Cancel
							</Button>
						</Col>
						<Col className="text-end">
							<Button
								color={quoteType ?? 'green'}
								onClick={submitAddress}
							>
								Set Address
							</Button>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default PropertyAddressEditor;
