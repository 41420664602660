import React, { Dispatch, SetStateAction } from "react";
import { useQuoteById } from "../Store/entities/quotes/hooks";
import Table from "../Parts/Table";
import QuoteObject from "./NewItems/QuoteObject";
import PartsTable from "./PartsTable";
import { NewFabricSampleForm } from "./NewOptionFields/ShadeActions/NewFabricSampleForm";
import PSOutdoorTable from "./PSOutdoorTable";
import { UsePortalSelector } from "../Store";
import { selectAssemblyListByQuoteId } from "../Store/entities/assemblies/hooks";
import { useQuoteFabricSamplesByQuoteId } from "../Store/entities/fabricSamples/hooks";
import { useHWRItemsByQuoteId } from "../Store/entities/hardwareRequestItems/hooks";
import IndoorTable from "./IndoorTable";
import COROutdoorTable from "./COROutdoorTable";

type InvoiceTableProps = {
    quoteId: number;
    quoteNew: QuoteObject;
    unlockBySequenceId: (sequenceId: number) => void;
    fcState: [any[], Dispatch<SetStateAction<any[]>>];
};

const InvoiceTable = ({ quoteId, quoteNew, unlockBySequenceId, fcState }: InvoiceTableProps) => {


    const [fakeNumForRendering, setFakeNumForRendering] = React.useState(0);

    const rerender = () => {
        setFakeNumForRendering(fakeNumForRendering + 1);
    }

    quoteNew.setRender(rerender);
    quoteNew.setRenderPage(rerender);

    const quote = useQuoteById(quoteId);

    const {
        editable
    } = quote ?? { editable: false };

    // const INDOOR_SHADE_ROWS = quoteNew.ready
    //     ? quoteNew.shadeRows()
    //     : null;

    // const IndoorEditRow = quoteNew.IndoorEditRow();
    // const OutdoorEditRow = quoteNew.OutdoorEditRow();

    const accessoryTable = quoteNew.ready
        ? quoteNew.AccessoryTable()
        : null;

    // const outdoorTable = quoteNew.ready
    //     ? quoteNew.OutdoorShadesTable()
    //     : null;

    const samples = useQuoteFabricSamplesByQuoteId(quoteId);
    const countAssemblyCount = UsePortalSelector(selectAssemblyListByQuoteId(quoteId));
    console.log(countAssemblyCount);

    const hwItems = useHWRItemsByQuoteId(quoteId);

    if (!quoteNew || !quoteNew.Ready || !quoteNew.ready) return null;

    return (
        <>
            <div
                className="quote-table"
                style={{
                    overflowX: 'auto',
                    marginBottom: '0.25em'
                }}
            >
                {/* <Table
                    noTBody
                    style={{
                        width: '100%', zIndex: '22', marginBottom: '0.5em'
                    }}
                    quoteType={quoteNew.QuoteType}
                    className={"IndoorEditTable"}
                >
                    {INDOOR_SHADE_ROWS.length > 0 && editable
                        ? IndoorEditRow
                        : null}
                </Table>
                <Table
                    quoteType={quoteNew.QuoteType}
                    noTBody
                    style={{ width: '100%', zIndex: '23' }}
                    className={"IndoorTable"}
                >
                    {indoorTable}
                </Table> */}
                <IndoorTable
                    quoteData={quoteNew.Data}
                    quoteId={quoteId}
                    unlockShadeBySequenceId={unlockBySequenceId}
                    fcState={fcState}
                />
            </div>
            <br />
            <div
                className="quote-table"
                style={{
                    overflowX: 'auto', overflowY: 'hidden', marginBottom: '0.25em'
                }}
            >
                <COROutdoorTable
                    quoteData={quoteNew.Data}
                    quoteId={quoteId}
                    unlockShadeBySequenceId={unlockBySequenceId}
                />
            </div>
            <PSOutdoorTable
                quoteId={quoteNew.Id}
                unlockShadeBySequenceId={unlockBySequenceId}
                quoteData={quoteNew.Data}
            />
            <br />
            <div
                className="quote-table"
                style={{
                    overflowX: 'auto', overflowY: 'hidden', marginBottom: '0.25em'
                }}
            >
                <Table quoteType={quoteNew.QuoteType} noTBody style={{ width: '100%', zIndex: '21' }} className={"AccesoryTable"}>
                    {accessoryTable}
                </Table>
            </div>
            <br />
            <div
                className="quote-table"
                style={{
                    overflowX: 'auto', overflowY: 'hidden', marginBottom: '0.25em'
                }}
            >
                <Table
                    quoteType={quoteNew.QuoteType}
                    className="fabric-samples"
                    noTBody
                    style={{ width: '100%', zIndex: '20' }}
                >
                    <NewFabricSampleForm
                        key={
                            `fabric-${(samples ?? []).reduce((sum, next) => sum + next.Quantity, 0)}`
                        }
                        quoteType={quoteNew.QuoteType}
                        quoteSamples={samples}
                        onChange={quoteNew.Data.changeFabricSampleQuantity}
                        editable={editable}
                    />
                </Table>
            </div>
            <br />
            <div
                className="quote-table"
                style={{
                    overflowX: 'auto', overflowY: 'hidden', marginBottom: '0.25em'
                }}
            >
                <Table noTBody style={{ width: '100%', zIndex: '19' }}
                    className={"PartsTable"}
                    quoteType={quoteNew.QuoteType}
                >
                    <PartsTable
                        key="hw_request"
                        hwRequestItems={hwItems}
                        deleteItem={quoteNew.deleteHWItem}
                        update={quoteNew.updateHWItem}
                        quoteType={quoteNew.QuoteType}
                        isEditable={editable}
                        quoteId={quoteId}
                    />
                </Table>
            </div>

            {/* {props.priceBreakDownShade ? (
                <ShadeCostBreakdown
                    quote={props.quote}
                    shade={priceBreakDownShade}
                    onClose={() => setPriceBreakdownShade(null)}
                />
            ) : null} */}
        </>
    );
}

export default InvoiceTable;