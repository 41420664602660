import React from 'react';
import { Card } from "react-bootstrap";

import type { StatusBlockProps } from './types';

import style from './StatusBlock.module.scss';
import { checkIfAbbreviatedMonthDayFormat, getTrackingLink, isQuoteShipment } from '../../psUtil';

const StatusBlock = ({ title, subtitle, body }: StatusBlockProps) => {
	console.debug();

	const dateRegex = /\b(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\s+\d{1,2},\s+\d{4}\b/g;

	const beforeToday = (date: string) => {
		const today = new Date();
		const secondDateFromBody = date.match(dateRegex)?.[0] ?? "";
		const dateToCheck = new Date(secondDateFromBody);
		return dateToCheck < today;
	};
	
	const isShipment = isQuoteShipment(body);
	const abbreviatedMonthDayFormat = !isShipment && checkIfAbbreviatedMonthDayFormat(body);
	const trackingLink = isShipment ? getTrackingLink(body.tracking_number, body.carrier) : undefined;

	const formattedTracking = isShipment
		? (<a href={trackingLink} target='_blank' rel='noreferrer'>{body.carrier} - {body.tracking_number}</a>)
		: isShipment ? `${body.carrier} - ${body.tracking_number}` : body;
	
	const formattedBody = abbreviatedMonthDayFormat
		? `Estimated Ship Date ${beforeToday(body) ? "was" : "is"} ${body}`
		: formattedTracking

	return (
		<Card className="h-100">
			<Card.Body>
				<Card.Title>
					{title}
				</Card.Title>
				<Card.Subtitle className={style.subtitle}>
					{subtitle}
				</Card.Subtitle>
				<Card.Text className="text-muted">
					{formattedBody}
				</Card.Text>
			</Card.Body>
		</Card>
	);
};

export default StatusBlock;
