import { useState } from 'react';

import api from '../PowerShadesAPI';
import Table from '../Parts/Table';
import { ShipmentModalRow, ShipmentModalHeadRow } from './ShipmentModalRows';
import ShadeManufacturing from './ShadeManufacturing';
import AccessoriesManufacturing from './AccessoriesManufacturing';
import Button from '../Parts/Button';

const ShipmentModalAddress = (props) => {
	const a = props.quote.shipping_address || {};

	function val(field) {
		return a[field] ? a[field] : null;
	}

	return (
		<div className="shipment-modal-address">
			<h2>Shipping Address</h2>

			<span className="field-label">Name: </span>
			<span>{`${val`first_name`} ${val`last_name`}`}</span>
			<br />
			<span className="field-label">Company: </span>
			<span>{val`company_name`}</span>
			<br />
			<span className="field-label">Phone Number: </span>
			<span>{val`phone`}</span>
			<br />
			<span className="field-label">Email: </span>
			<span>{val`email`}</span>
			<br />
			<br />

			<span className="field-label">Address Line 1: </span>
			<span>{val`address`}</span>
			<br />
			<span className="field-label">Address Line 2</span>
			<span>{val`address2`}</span>
			<br />
			<span className="field-label">State: </span>
			<span>{val`state`}</span>
			<br />
			<span className="field-label">Zip / Postal Code: </span>
			<span>{val`zip`}</span>
			<br />
			<span className="field-label">City: </span>
			<span>{val`city`}</span>
			<br />
			<span className="field-label">Country: </span>
			<span>{val`country`}</span>
			<br />
		</div>
	);
};

const ShadesStatusSummary = ({ quote }) => {
	const shade_assemblies = quote.shade_assemblies || [];

	const completed_count = shade_assemblies.filter(
		(shade) => shade.mfg_status === 'complete'
	).length;

	return (
		<div
			className={completed_count === shade_assemblies.length ? 'green-text' : 'pending-text'}
			style={{ flexGrow: '1' }}
		>
			<h2>Shades</h2>
			{shade_assemblies.length > 0 ? (
				<span>
					{completed_count} of
					{shade_assemblies.length} shade assemblies complete.
				</span>
			) : (
				<span style={{ color: 'black' }}>This order does not have any shades.</span>
			)}
		</div>
	);
};

const AccessoriesStatusSummary = ({ quote }) => {
	const accessories_total = quote.accessories.reduce(
		(acc, accessory) => acc + parseInt(accessory.quantity),
		0
	);
	const completed_count = quote.accessories
		.filter((accessory) => accessory.mfg_status === 'complete')
		.reduce((acc, accessory) => acc + parseInt(accessory.quantity), 0);

	return (
		<div
			className={completed_count === accessories_total ? 'green-text' : 'pending-text'}
			style={{ flexGrow: '1' }}
		>
			<h2>Accessories</h2>
			{accessories_total > 0 ? (
				<span>
					{completed_count} of
					{accessories_total} accessories complete.
				</span>
			) : (
				<span style={{ color: 'black' }}>This order does not have any accessories.</span>
			)}
		</div>
	);
};

const GatewaysSummary = ({ quote }) => (
	<div style={{ flexGrow: '1' }}>
		<h2>Gateways</h2>

		{quote.gateways.length < 1 ? (
			<span>This order does not have any gateways.</span>
		) : (
			<Table style={{ margin: 'unset', fontSize: '12px' }}>
				<tr className="bold-row">
					<th>Gateway ID</th>
					<th>Serial Number</th>
				</tr>

				{quote.gateways.map((gw) => (
					<tr key={gw.id}>
						<td>{gw.id}</td>
						<td>{gw.serial_number ? gw.serial_number : <b>None</b>}</td>
					</tr>
				))}
			</Table>
		)}
	</div>
);

const ShipmentModal = (props) => {
	const [shippingCompanySelect, setShippingCompanySelect] = useState(null);
	const [trackingNumber, setTrackingNumber] = useState('');
	const [otherCarrier, setOtherCarrier] = useState('');
	const [showShadesModal, setShowShadesModal] = useState(false);
	const [showAccessoriesModal, setShowAccessoriesModal] = useState(false);
	const [quote, setQuote] = useState(props.quote);

	function sendShipment() {
		function shades_complete() {
			const completed_count = props.quote.shade_assemblies.filter(
				(shade) => shade.mfg_status === 'complete'
			).length;
			const total_count = props.quote.shade_assemblies.length;

			return completed_count == total_count;
		}

		function accessories_complete() {
			const accessories_total = quote.accessories.reduce(
				(acc, accessory) => acc + accessory.quantity,
				0
			);
			const completed_count = quote.accessories
				.filter((accessory) => accessory.mfg_status === 'complete')
				.reduce((acc, accessory) => acc + accessory.quantity, 0);

			return completed_count == accessories_total;
		}

		function gateways_complete() {
			const completed_count = quote.gateways.filter(
				(accessory) => !!accessory.serial_number
			).length;

			return completed_count == quote.gateways.length;
		}

		if (
			!shippingCompanySelect ||
			(!trackingNumber &&
				!confirm(
					'Are you sure you want to send this shipment without a tracking number?'
				)) ||
			(!shades_complete() &&
				!confirm(
					'Not all shade assemblies are marked as complete.\nAre you sure you want to ship this order?'
				)) ||
			(!accessories_complete() &&
				!confirm(
					'Not all accessories are marked as complete.\nAre you sure you want to ship this order?'
				)) ||
			(!gateways_complete() &&
				!confirm(
					'Not all gateways have a serial number assigned.\nAre you sure you want to ship this order?'
				))
		) {
			return;
		}

		const carrier_name =
			shippingCompanySelect === 'other' ? otherCarrier : shippingCompanySelect;

		api.shipOrder(props.quote.ID, carrier_name, trackingNumber, ROOT.refreshContent).then(
			(resp) => {
				props.onClose && props.onClose();
			}
		);
	}

	return (
		<div className="modal-holder">
			<div className="shipment-modal">
				{props.quote.is_shipped ? <h1>Shipment Overview</h1> : <h1>Ship Order</h1>}

				<table className="order-confirmation-table" cellSpacing="0">
					<tbody>
						<ShipmentModalHeadRow quote={props.quote} />
						<ShipmentModalRow quote={props.quote} />
					</tbody>
				</table>

				<ShipmentModalAddress quote={props.quote} />

				<br />

				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<ShadesStatusSummary quote={props.quote} />

					{quote.shade_assemblies.length > 0 ? (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Button color="green" onClick={(e) => setShowShadesModal(true)}>
								View Shades
							</Button>
						</div>
					) : null}
				</div>

				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<AccessoriesStatusSummary quote={props.quote} />

					{quote.accessories.length > 0 ? (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Button
								color="green"
								style={{ marginTop: '1.5em' }}
								onClick={() => setShowAccessoriesModal(true)}
							>
								View Accessories
							</Button>
						</div>
					) : null}
				</div>

				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<GatewaysSummary quote={props.quote} />
				</div>

				<br />

				<div className={`shipping-details ${props.quote.is_shipped ? 'hide' : ''}`}>
					<h2 style={{ width: '100%' }}>Shipment Details</h2>

					<br />

					<div>
						<label>Carrier</label>
						<br />
						<select
							value={shippingCompanySelect || ''}
							onChange={(e) => setShippingCompanySelect(e.target.value)}
						>
							<option value="">- Select -</option>
							<option value="FedEx">FedEx</option>
							<option value="USPS">USPS</option>
							<option value="UPS">UPS</option>
							<option value="other">Other</option>
						</select>
					</div>

					{shippingCompanySelect === 'other' ? (
						<div>
							<label>Carrier Name</label>
							<br />
							<input
								type="text"
								value={otherCarrier}
								onChange={(e) => setOtherCarrier(e.target.value)}
								placeholder="Carrier Name"
							/>
						</div>
					) : null}

					<div>
						<label>Tracking Number</label>
						<br />
						<input
							type="text"
							value={trackingNumber}
							onChange={(e) => setTrackingNumber(e.target.value)}
							placeholder="Tracking #"
						/>
					</div>
				</div>

				<br />

				<div className="button-holder">
					<Button color="gray" onClick={props.onClose}>
						{props.quote.is_shipped ? 'Close' : 'Cancel'}
					</Button>

					{!props.quote.is_shipped ? (
						<Button
							color="green"
							disabled={!shippingCompanySelect}
							onClick={sendShipment}
						>
							Send Shipment
						</Button>
					) : null}
				</div>
			</div>

			{showShadesModal ? (
				<ShadeManufacturing
					onClose={() => setShowShadesModal(false)}
					onUpdate={() => setQuote(props.quote)}
					quote={props.quote}
				/>
			) : null}

			{showAccessoriesModal ? (
				<AccessoriesManufacturing
					onClose={() => setShowAccessoriesModal(false)}
					onUpdate={() => setQuote(props.quote)}
					quote={props.quote}
				/>
			) : null}
		</div>
	);
};

export default ShipmentModal;
