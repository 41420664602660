import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Popup from "reactjs-popup";

import Button from '../../Parts/Button';
import ChannelSelect from "../../Parts/ChannelSelect";

/* eslint-disable camelcase */
const ContractorEdit = ({
	update, channels, contractor
}) => {
	const {
		name, email, phone, channel_id
	} = contractor;

	const [localObj, setLocalObj] = useState({
		name,
		email,
		phone,
		channel_id,
	});

	const [open, setOpen] = useState(false);

	const saveContractor = () => {
		update(localObj);
	};

	return (
		<>
			<Popup open={open} onClose={() => setOpen(false)} className=" mobile-modal">
				<Container className="local-bootstrap">
					<Row>
						<Col>
							<h4 className="text-green">Edit Contractor</h4>
						</Col>
					</Row>
					<Row className="mb-2">
						<Col>
							<div className="mb-2">Name</div>
							<Form.Control
								size="sm"
								type="text"
								value={localObj.name}
								onChange={(e) => setLocalObj({ ...localObj, name: e.target.value })}
							/>
						</Col>
						<Col>
							<div className="mb-2">Channel</div>
							<ChannelSelect
								noLabel
								bootstrap
								channelsOverride={channels}
								title={false}
								channelId={localObj.channel_id}
								onChange={(new_channel_id) =>
									setLocalObj({
										...localObj,
										channel_id: new_channel_id,
									})}
							/>
						</Col>
					</Row>
					<Row className="mb-2">
						<Col>
							<div className="mb-2">Phone</div>
							<Form.Control
								size="sm"
								type="text"
								value={localObj.phone}
								onChange={(e) =>
									setLocalObj({
										...localObj,
										phone: e.target.value,
									})}
							/>
						</Col>
						<Col>
							<div className="mb-2">Email</div>
							<Form.Control
								size="sm"
								type="text"
								value={localObj.email}
								onChange={(e) =>
									setLocalObj({
										...localObj,
										email: e.target.value,
									})}
							/>
						</Col>
					</Row>

					<Row className="mt-4">
						<Col className="text-right">
							<Button
								color="green"
								onClick={() => {
									saveContractor();
									setOpen(false);
								}}
							>
								Save
							</Button>
						</Col>
					</Row>
				</Container>
			</Popup>
			<Button
				color="green"
				onClick={() => {
					setOpen(true);
				}}
			>
				Edit
			</Button>
		</>
	);
};

export default ContractorEdit;
