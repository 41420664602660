/* eslint-disable react-hooks/rules-of-hooks */
import Popup from "reactjs-popup";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UseAssembly } from "../../Store/entities/assemblies/hooks";
import Button from "../../Parts/Button";
import { useQuoteById, useQuoteTypeNameByQuoteId } from "../../Store/entities/quotes/hooks";
import { GroupHeader } from "../../Parts/FormParts";
import { UsePortalSelector } from "../../Store";
import { useAssemblyOptions } from "../../Store/entities/assemblyOptions/hooks";
import { useNewIndoorShadeOptions } from "../../Store/entities/assemblies/IndoorHooks.ts";
import { InitialAssemblyState } from "../../Store/entities/assemblies";
import { useIndoorFabrics } from "../../Store/entities/hooks";
import { useUser } from "../../Store/user/hooks";
import SummaryAccordionList, { SummaryAccordionItem } from "./SummaryAccordionList";
import { AssemblyStore, AssemblyViewModelFromAssemblyStore } from "../../Store/entities/assemblies/types";
import apiCalls from "../../PowerShadesAPIFunctions";

type ShadeSummaryNewProps = {
	quoteId: number;
	sequenceId: number;
};

const ShadeSummaryNew = ({ quoteId, sequenceId }: ShadeSummaryNewProps) => {
	const assemblyItems: SummaryAccordionItem[] = [];
	const shadeItems: SummaryAccordionItem[] = [];

	const shadeData = UseAssembly(quoteId, sequenceId);
	const [assembly, setAssembly] = useState<AssemblyStore | null>(null);

	useEffect(() => {
		if (shadeData) setAssembly(shadeData);
	}, [shadeData]);

	if (!shadeData) return null;

	const shadeName = shadeData?.shade_name ?? "Unknown Shade";
	const quoteType = useQuoteTypeNameByQuoteId(quoteId) ?? "default";

	const [errors, setErrors] = useState<any[]>([]);

	const [shadeSummaryOpen, setShadeSummaryOpen] = useState(false);

	useEffect(() => {
		if (shadeSummaryOpen && assembly) {
			const assemblyVM = AssemblyViewModelFromAssemblyStore(assembly);
			const type = assemblyVM.shade_type_id === 1
				? "indoor"
				: assemblyVM.shade_type_id === 2
					? "outdoor"
					: "psoutdoor";

			apiCalls
				.calculateAssemblyPriceNew(assemblyVM, quoteId, type)
				.then((resp) => {
					if (resp.data.errors === undefined) throw new Error(`500 Error: ${resp.data}`);
					const respErrors = resp.data.errors;

					let errorDifferential = 0;

					if (errors.length === respErrors.length) {
						errors.forEach((error, index) => {
							if (error.message !== respErrors[index]?.message) errorDifferential++;
						});
					} else {
						errorDifferential++;
					}

					if (errorDifferential > 0) setErrors(resp.data.errors);
				});
		}
	}, [errors, shadeSummaryOpen, assembly]);

	const setAssemblyCallback = useCallback(setAssembly, []);

	const Colors = (useNewIndoorShadeOptions(
		{ ...InitialAssemblyState(), ...assembly },
		setAssemblyCallback,
		{
			quote: useQuoteById(quoteId) ?? {},
			assemblyOptions: UsePortalSelector(useAssemblyOptions(quoteId)) ?? {},
			fabrics: useIndoorFabrics(quoteId) ?? {},
			user: useUser() ?? {},
		}
	).Colors ?? {});

	const ColorPalettes = (useNewIndoorShadeOptions(
		{ ...InitialAssemblyState(), ...assembly },
		setAssemblyCallback,
		{
			quote: useQuoteById(quoteId) ?? {},
			assemblyOptions: UsePortalSelector(useAssemblyOptions(quoteId)) ?? {},
			fabrics: useIndoorFabrics(quoteId) ?? {},
			user: useUser() ?? {},
		}
	).ColorPalettes ?? {});

	const activePalette = ColorPalettes?.getSelectedOption?.()?.label ?? "Default Palette";

	const ignoredFields = new Set([
		"cost",
		"base_sale_price",
		"dealer_sale_price",
		"api_motor_id",
		"hidden_shipping",
		"ShadesCost",
		"ShadesMsrp",
		"tube_name",
		"shades",
		"error_messages",
		"colors",
	]);

	const formatKey = (key: string) =>
		(key === "id" ? "ID" : key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase()));

	if (errors.length !== 0) {
		assemblyItems.push({
			eventKey: "Assembly Errors",
			title: "Errors",
			content: errors.map((error, index) => ({
				key: `Error ${index + 1}`,
				value: error.message || "Something went wrong",
			})),
		});
	}

	assemblyItems.push({
		eventKey: "Assembly Details",
		title: "Assembly Details",
		content: Object.entries(shadeData ?? {})
			.filter(([key]) => !ignoredFields.has(key))
			.sort(([aKey], [bKey]) => (aKey === "id" ? -1 : aKey.localeCompare(bKey)))
			.map(([key, value]) => ({
				key: formatKey(key),
				value: value ?? "-",
			})),
	});

	assemblyItems.push({
		eventKey: "Color Palette",
		title: "Color Palette",
		content: [
			{ key: "Color Palette Preset", value: activePalette },
			...Object.entries(Colors)
				.filter(([_, color]) => color?.label && !ignoredFields.has(color.label))
				.sort(([_a, { label: colorA }], [_b, { label: colorB }]) => colorA.localeCompare(colorB))
				.map(([_, color]) => ({
					key: color.label,
					value: color.getValue() ?? "-",
				})),
		],
	});

	if (Array.isArray(shadeData?.shades)) {
		shadeData.shades.forEach((s) => {
			const detailList: { key: string, value: string | number | boolean }[] = [];

			Object.entries(s ?? {})
				.filter(([sKey]) => !ignoredFields.has(sKey))
				.sort(([aKey], [bKey]) => (aKey === "id" ? -1 : aKey.localeCompare(bKey)))
				.forEach(([sKey, value]) => {
					let displayValue = value ?? "-";

					if (sKey === "motor") {
						displayValue = value === "DM35CEQ/S-2/28" ? "35 mm 12VDC Hardwired Motor"
							: value === "DM35LEQ/S-2/28" ? "35 mm Li-Ion RF Motor"
								: value;
					}

					detailList.push({ key: formatKey(sKey), value: displayValue });
				});

			shadeItems.push({
				eventKey: `${s?.row_coordinate ?? "N/A"}-${s?.column_coordinate ?? "N/A"}`,
				title: `${(shadeData.is_coupled) ? `Column ${s.column_coordinate + 1}` : ""}
						${shadeData.is_dual ? (s?.row_coordinate < 1 ? "Front Row" : "Back Row") : ""}
						${(!shadeData.is_dual && !shadeData.is_coupled) ? "Shade Details" : ""}`,
				content: detailList,
			});
		});
	}

	return (
		<>
			<Popup
				open={shadeSummaryOpen}
				onClose={() => setShadeSummaryOpen(false)}
				className="mobile-modal"
			>
				<div id="detail-view">
					<div>
						<div className="d-flex align-items-end justify-content-start mb-4">
							<h2 className={`text-${quoteType} m-0 w-auto`}>Shade Summary</h2>
							<h3 className="text-body-tertiary my-0 mx-2 w-auto" style={{ fontSize: "18px" }}>
								{shadeName}
							</h3>
						</div>
						{GroupHeader("Assembly Data", quoteType)}
						<SummaryAccordionList accordions={assemblyItems} quoteType={quoteType} />
						{GroupHeader("Shade Data", quoteType)}
						<SummaryAccordionList accordions={shadeItems} quoteType={quoteType} />
						<Container className="local-bootstrap">
							<Row className="justify-content-center mt-2">
								<Col lg={4}>
									<Button color={quoteType} fullWidth onClick={() => setShadeSummaryOpen(false)}>
										Done
									</Button>
								</Col>
							</Row>
						</Container>
					</div>
				</div>
			</Popup>
			<Button
				fullWidth
				color="light"
				size="sm"
				title="Detail View"
				hoverColor={quoteType}
				onClick={() => setShadeSummaryOpen(true)}
			>
				<FontAwesomeIcon transform="grow-5" icon={faEye} />
			</Button>
		</>
	);
};

export default ShadeSummaryNew;
