import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkAPI } from "../types";
import { AssemblyStore } from "../assemblies/types";
import apiCalls from "../../../PowerShadesAPIFunctions";
import { selectAssemblyListByQuoteId } from "../assemblies/hooks";
import { BidDataAssemblyAmounts } from "../../../powershadesApiTypes";
import { AxiosError } from "axios";

type UpdateBidDataThunkInput = {
    QuoteId: number;
    Amount_Sets: BidDataAssemblyAmounts[];
}


const updateBidData = createAsyncThunk<AssemblyStore, UpdateBidDataThunkInput, ThunkAPI>(
    'entities/assemblies/updateBidData',
    async (bidData, { rejectWithValue, getState }: ThunkAPI) => {
        const { QuoteId, Amount_Sets } = bidData;

        const assys = selectAssemblyListByQuoteId(QuoteId)(getState());

        let assemblies;

        const finalAmountSets = Amount_Sets.map((amountSet) => {
            const assembly = assys.find((assy) => assy.sequence_id === amountSet.sequence_id);

            const finalAmountSet = {
                labor: assembly?.labor ?? 0,
                overhead: assembly?.overhead ?? 0,
                markup: assembly?.markup ?? 0,
                ...amountSet,
            }

            return finalAmountSet
        });

        try {
            const resp = await apiCalls.updateBidPricing(QuoteId, finalAmountSets);

            assemblies = resp.data.updated_assemblies;
        } catch (err: any) {
            const error: AxiosError = err;

            console.error(error);

            return rejectWithValue(false);
        }

        return assemblies;
    }
);



/*setShadeLabor(sequenceId, amount) {
    const quoteId = this.quote.ID;
    const assembly = this.AllShadeAssemblies.find((sa) => sa.sequence_id == sequenceId);

    assembly.installation_labor_cost = amount;

    api.setShadeLabor(quoteId, sequenceId, amount).then((resp) => {
        this.UI.reRenderTable();
    });
}

setShadeOverhead(sequenceId, amount) {
    const quoteId = this.quote.ID;
    const assembly = this.AllShadeAssemblies.find((sa) => sa.sequence_id == sequenceId);

    assembly.overhead = amount;

    api.setShadeOverhead(quoteId, sequenceId, amount).then((resp) => {
        this.UI.reRenderTable();
    });
}

setShadeMarkup(sequenceId, amount) {
    const quoteId = this.quote.ID;
    const assembly = this.AllShadeAssemblies.find((sa) => sa.sequence_id == sequenceId);

    assembly.markup = amount;

    api.setShadeMarkup(quoteId, sequenceId, amount).then((resp) => {
        this.UI.reRenderTable();
    });
}*/

export default updateBidData;


