import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { Col, Row } from "react-bootstrap";

import Button from "../../Parts/Button";

import {
	useAccessories,
	useQuoteAccessories,
	useUpdateQuoteAccessory
} from "../../Store/entities/accessories/hooks";

import type { EditAccessoryPopupProps } from "./types";
import type { QuoteAccessoryParameters } from "../../powershadesApiTypes";

const EditAccessoryPopup = ({ quoteId, lineNumber, legacyReprice }: EditAccessoryPopupProps) => {
	const quoteAccessories = useQuoteAccessories(quoteId);

	const quoteAccessory = quoteAccessories.find((accessory) => accessory.line_number === lineNumber);

	const [isPopupOpen, setIsPopupOpen] = useState(false);

	const [editableQuoteAccessory, setEditableQuoteAccessory] = useState(quoteAccessory );

	// This could be where a signalR bug expresses itself.
	useEffect(() => {
		setEditableQuoteAccessory(quoteAccessory);
	}, [quoteAccessory]);

	const accessories = useAccessories(quoteId);
	const updateQuoteAccessory = useUpdateQuoteAccessory();
	const accessory = accessories.find((acc) => acc.part_number === quoteAccessory?.part_number);

	if (!quoteAccessory || !editableQuoteAccessory || !accessory) {
		return null;
	}

	const baseParms =
		editableQuoteAccessory.parameters.length < accessory.parameters.length
			? accessory.parameters
			: editableQuoteAccessory.parameters;

	const baseParmsClean = baseParms.map((parm) => {
		return {...parm, value : "0"};
	});

	const parmsToMap = [
		...baseParmsClean,
		({
			name: "Quantity",
			value: editableQuoteAccessory.quantity.toString(),
			unit_of_measurement: "Count"
		} as QuoteAccessoryParameters)
	];

	const editParam = (parmName: string, value: string) => {
		const newQuoteAccessory = {
			...editableQuoteAccessory,
			parameters: accessory.parameters.map((param) => {
				if (param.name === parmName) {
					return {
						...param,
						value
					};
				}
				const currentParm = editableQuoteAccessory.parameters.find(
					(quoteParam) => quoteParam.name === param.name
				);
				return { ...param, ...currentParm, value: "0" };
			}),
			value: value ?? 0
		};

		if (parmName === "Quantity") {
			value = (!value ? "0" : value);
			newQuoteAccessory.quantity = parseInt(value, 10);
		}

		setEditableQuoteAccessory(newQuoteAccessory);
	};

	const columnRows: {
		headerName: string;
		accessor: (quoteParameter: QuoteAccessoryParameters) => React.ReactNode;
	}[] = [
		{
			headerName: "Name",
			accessor: (quoteParameter) => quoteParameter.name
		},
		{
			headerName: "Description",
			accessor: (quoteParameter) => quoteParameter.description
		},
		{
			headerName: "Unit",
			accessor: (quoteParameter) => quoteParameter.unit_of_measurement
		},
		{
			headerName: "Value",
			accessor: (quoteParameter) => (
				<input
					className="form-control-sm form-control"
					value={quoteParameter.value}
					onChange={(e) => {
						editParam(quoteParameter.name, e.target.value);
					}}
				/>
			)
		}
	];

	const { name: accessoryName } = accessory;

	const saveAcc = () => {
		// save to db
		updateQuoteAccessory({
			priceCallback: legacyReprice,
			quoteId,
			lineNumber,
			quantity: editableQuoteAccessory.quantity,
			parms: editableQuoteAccessory.parameters
		});
	};

	return (
		<>
			{isPopupOpen && (<Popup
				open={isPopupOpen}
				onOpen={() => setIsPopupOpen(true)}
				onClose={() => setIsPopupOpen(false)}
				className="mobile-modal"
				key={`edit-accessory-${lineNumber}-${quoteId}`}
			>
				<Row className="mb-2">
					<Col>
						<Row>
							<Col>
								<h4>Editing Accessory</h4>
							</Col>
							<Col className="mt-2">
								<h6>
									<strong>{accessoryName}</strong>
								</h6>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col>
						<Row>
							{columnRows.map((columnRow) => (
								<Col key={`col-edit-head-${columnRow.headerName}`}>
									<strong>{columnRow.headerName}</strong>
								</Col>
							))}
						</Row>
						{parmsToMap.map((quoteParameter) => (
							<Row className={`col-edit-val-${quoteParameter.name}`}>
								{columnRows.map((columnRow) => (
									<Col>{columnRow.accessor(quoteParameter)}</Col>
								))}
							</Row>
						))}
					</Col>
				</Row>
				<Row className="mt-4">
					<Col>
						<Button color="gray" onClick={() => setIsPopupOpen(false)}>
							Cancel
						</Button>
					</Col>
					<Col className="text-end">
						<Button
							title="Save"
							color="green"
							onClick={() => {
								saveAcc();
								setIsPopupOpen(false);
							}}
						>
							Save
						</Button>
					</Col>
				</Row>
			</Popup>)}
			<Button title="Delete" color="light" size="sm" fullWidth onClick={() => setIsPopupOpen(true)}>
				Edit
			</Button>
		</>
	);
};

export default EditAccessoryPopup;
