import { useState, useEffect } from 'react';
import TextDivider from '../../Parts/TextDivider';
import AddressModal from '../AddressModal';
import AddressSummary from '../AddressSummary';
import PaymentStep from './PaymentStep';
import Button from '../../Parts/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const ShippingConfirm = (props) => {
	const [editingAddress, setEditingAddress] = useState(false);
	const [addr, setAddress] = useState(props.quote.shipping_address);

	const { quote } = props;
	const { quoteType } = props;
	const { setInvalidInstallationAddress } = props;
	const { setInvalidShippingAddress } = props;

	const requiredFieldsShippingAddress = [
		'company_name',
		'first_name',
		'last_name',
		'address',
		'country_subdivision_id',
		'city',
		'zip',
	];

	const requiredFieldsInstallationAddress = [
		'company_name',
		'address',
		'country_subdivision_id',
		'city',
		'zip',
	];

	const shippingAddressValid = () =>
		quote.shipping_address &&
		!requiredFieldsShippingAddress.some(
			(key) =>
				quote.shipping_address[key] == null || `${quote.shipping_address[key]}`.length < 1
		);
	const installationAddressValid = () =>
		quote.property_address &&
		!requiredFieldsInstallationAddress.some(
			(key) =>
				quote.property_address[key] == null || `${quote.property_address[key]}`.length < 1
		);

	const [shipping_address_valid, set_shipping_address_valid] = useState(shippingAddressValid());
	const [installation_address_valid, set_installation_address_valid] = useState(
		installationAddressValid()
	);

	useEffect(() => {
		if (!shipping_address_valid) {
			setInvalidShippingAddress(true);
			props.hideNext();
		}
		if (!installation_address_valid) {
			setInvalidInstallationAddress(true);
			props.hideNext();
		}
		if (shipping_address_valid && installation_address_valid) {
			props.showNext();
		}
	}, [addr]);

	return (
		<PaymentStep>
			<TextDivider>
				<h3 className={`text-${quoteType}`} style={{ fontWeight: 'normal' }}>
					Shipping Info
				</h3>
			</TextDivider>

			<div
				className="address-summary"
				style={{
					boxShadow: 'none',
					border: '1px solid gray',
					borderWidth: '0',
					borderRadius: '0',
					padding: '5px 0',
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				<AddressSummary quoteType={quoteType} address={addr} />
			</div>

			<div
				style={{
					width: '100%',
					textAlign: 'right',
					marginTop: '5px',
				}}
			>
				<Button
					color="gray"
					style={{ fontSize: '15px', marginRight: '8px' }}
					onClick={() => setEditingAddress(true)}
				>
					<FontAwesomeIcon icon={faEdit} />
					&nbsp;Edit
				</Button>
			</div>

			{editingAddress ? (
				<AddressModal
					quoteType={quoteType}
					ROOT={props.ROOT}
					setAddress={(new_address) => {
						props.newQuote.setShippingAddress(new_address).then((addr) => {
							setAddress(addr);
							set_shipping_address_valid(shippingAddressValid());
						});
					}}
					onDone={() => setEditingAddress(false)}
					addressLabel="Shipping Address"
					getAddressesFunction="getMyShippingAddresses"
					address={props.quote.shipping_address}
					quote={props.quote}
				/>
			) : null}
		</PaymentStep>
	);
};

export default ShippingConfirm;
