import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';

import SelectPaymentMethod from './SelectPaymentMethod';
import ShippingConfirm from './ShippingConfirm';
import CardPayment from './PaymentMethods/CardPayment';
import DealerTermsPayment from './PaymentMethods/DealerTermsPayment';
import PowerShadesAdminPayment from './PaymentMethods/PowerShadesAdminPayment';
import { getQuoteColor } from '../QuoteObjectExtensions.ts';
import RMAPaymentOverride from './PaymentMethods/RMAPaymentOverride';

/* eslint-disable camelcase */

const PaymentSteps = ({
	newQuote,
	setProcessing,
	quote,
	hasCard,
	hasTerms,
	setAdminPayment,
	ROOT,
	quoteType,
	setSubmit,
	setShowingSubmit,
}) => {
	const [state, setStateR] = useState({ step: 0 });
	const [hideNext, setHideNext] = useState(false);
	const [actuallyHideNext, setActuallyHideNext] = useState(false);
	const [, setError] = useState(false);
	const [invalidShippingAddress, setInvalidShippingAddress] = useState(false);
	const [, setInvalidInstallationAddress] = useState(false);

	const [willNeedRetainer, setWillNeedRetainer] = useState(false);

	const setRetainer = (paymentType) => {
		api.willNeedRetainer(newQuote.Id, paymentType).then((resp) => {
			setWillNeedRetainer(resp.data.retainer_required);
		}).catch((err) => {
			console.error(err);
			throw new Error('Something went wrong checking if this order needs a retainer. Please check console for resp details.');
		});
	};

	const setState = (new_state) => {
		setStateR({ ...state, ...new_state });
	};

	// * unused function
	// const x = function (payment_method) {
	// 	setState({ payment_method, step: 1 });
	// 	moveToFinal();
	// };
	
	const moveToFinal = (payment_method) => {
		const final_steps = {
			card: 2,
			dealer_terms: 3,
			powershades_admin: 4,
			rma_override: 5
		};

		const localPaymentMethods = payment_method ?? state.payment_method;

		setState({ step: final_steps[localPaymentMethods] });

		setHideNext(true);
		setActuallyHideNext(true);
	};

	const price = newQuote.buy();

	const halfPrice = Number.parseFloat(price.totalPrice / 2).toFixed(2);
	const quoteColor = getQuoteColor(quoteType);

	const steps = {
		0: (
			<ShippingConfirm
				quoteType={quoteType}
				quote={quote}
				setInvalidShippingAddress={setInvalidShippingAddress}
				setInvalidInstallationAddress={setInvalidInstallationAddress}
				newQuote={newQuote}
				hideNext={() => setHideNext(true)}
				showNext={() => setHideNext(false)}
				ROOT={ROOT}
			/>
		),

		1: (
			<SelectPaymentMethod
				quoteColor={quoteColor}
				quoteType={quoteType}
				hasCard={hasCard}
				hasTerms={hasTerms}
				quote={quote}
				hideNext={() => setHideNext(true)}
				showNext={() => setHideNext(false)}
				setAdminPayment={setAdminPayment}
				paymentMethodSelected={(payment_method) => {
					moveToFinal(payment_method);
				}}
			/>
		),

		2: (
			<CardPayment
				quoteType={quoteType}
				halfPrice={halfPrice}
				setRetainer={setRetainer}
				willNeedRetainer={willNeedRetainer}
				setWillNeedRetainer={setWillNeedRetainer}
				setProcessing={setProcessing}
				newQuote={newQuote}
				quote={quote}
				preLoadedQuote={quote}
				setSubmit={setSubmit}
				showSubmit={() => setShowingSubmit(true)}
				hideSubmit={() => setShowingSubmit(false)}
			/>
		),

		3: (
			<DealerTermsPayment
				halfPrice={halfPrice}
				setRetainer={setRetainer}
				willNeedRetainer={willNeedRetainer}
				setWillNeedRetainer={setWillNeedRetainer}
				setProcessing={setProcessing}
				newQuote={newQuote}
				quote={quote}
				preLoadedQuote={quote}
				setSubmit={setSubmit}
				showSubmit={() => setShowingSubmit(true)}
				hideSubmit={() => setShowingSubmit(false)}
			/>
		),

		4: (
			<PowerShadesAdminPayment
				halfPrice={halfPrice}
				setRetainer={setRetainer}
				willNeedRetainer={willNeedRetainer}
				setWillNeedRetainer={setWillNeedRetainer}
				setProcessing={setProcessing}
				quote={quote}
				preLoadedQuote={quote}
				setSubmit={setSubmit}
				showSubmit={() => setShowingSubmit(true)}
				hideSubmit={() => setShowingSubmit(false)}
			/>
		),

		5: (
			<RMAPaymentOverride
			setSubmit={setSubmit}
			quoteId={quote.ID}
			
			/>

		)
	};

	const backStep = () => {
		setShowingSubmit(false);

		if (state.step === 1) {
			setActuallyHideNext(false);
			setState({ payment_method: null, step: 0 });
		}

		if (state.step === 2 || state.step === 3 || state.step === 4) {
			setState({ step: 1 });
		}
	};

	const next = () => {
		if (state.step === 0) {
			api.shippingNextPressed(newQuote.Id).then(() => {
				console.log('shippingNextPressed');
			});

			setState({ step: 1 });
			setActuallyHideNext(true);
		} else {
			moveToFinal();
		}
	};

	return (
		state.step == 5 ? (
			<>
			{steps[state.step]}
			</>
		) : 
		(
			<>
			{steps[state.step]}

			{state.step > -1 ? (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					{state.step > 0 ? (
						<button
							type="button"
							onClick={backStep}
							style={{
								background: 'none',
								marginRight: 'auto',
								width: '6em',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								color: quoteColor,
							}}
						>
							<FontAwesomeIcon icon={faArrowLeftLong} />
							<span
								style={{
									fontSize: '20px',
									fontWeight: 'normal',
									textAlign: 'left',
									paddingLeft: '5px',
								}}
							>
								Back
							</span>
						</button>
					) : null}
					<button
						type="button"
						onClick={() => {
							hideNext
								? ROOT.alert({
									quickFormat: 'error',
									text: `Please enter the ${
										invalidShippingAddress
											? 'Property / Company Name'
											: 'Property Name'
									} for ${
										invalidShippingAddress
											? 'Shipping Address'
											: 'Installation Address'
									}`,
								  })
								: null;
							!hideNext ? next() : setError(true);
						}}
						style={{
							background: 'none',
							marginLeft: 'auto',
							width: '6em',
							alignItems: 'center',
							justifyContent: 'center',
							color: quoteColor,
							display: actuallyHideNext ? 'none' : 'flex',
						}}
					>
						<span
							style={{
								fontSize: '20px',
								fontWeight: 'normal',
								textAlign: 'right',
								paddingRight: '5px',
							}}
						>
							Next
						</span>
						<FontAwesomeIcon icon={faArrowRightLong} />
					</button>
				</div>
			) : null}
		</>)
	);
};

export default PaymentSteps;
