import { SelectOutdoorFabrics } from '../../../Store/entities/fabrics';
import QUOTE_GLOBALS from '../../QuoteGlobals';

export default function OutdoorShadeFormFields(UI) {
	const shadeToSplit = (assembly) => {
		const sequenceId = assembly.sequence_id;

		return assembly.shades.map((s) => ({
			sequenceId,
			row: s.row_coordinate,
			column: s.column_coordinate
		}));
	};

	const getAssemblyId = (assembly) => assembly.sequence_id;
	const getAllShadeIds = (assembly) => shadeToSplit(assembly);
	const getFrontIds = (assembly) => shadeToSplit(assembly);
	const getBackIds = (assembly) => shadeToSplit(assembly);

	const items = [

		{
			varName: 'indoor_outdoor',
			value: 'outdoor',
			getId: getAssemblyId,
			showOnForm: false
		},

		{
			varName: 'single_dual',
			value: 'single',
			getId: getAssemblyId,
			showOnForm: false
		},
		{
			varName: 'shade_1_id',
			getId: getAssemblyId,
			showOnForm: false
		},
		{
			varName: 'shade_2_id',
			getId: getAssemblyId,
			showOnForm: false
		},
		{
			varName: 'roll_direction',
			value: 'standard',
			getId: getAssemblyId,
			showOnForm: false
		},

		{
			varName: 'fascia_color',
			value: null,
			nullable: () => true,
			getId: getAssemblyId,
			showOnForm: false
		},

		{
			varName: 'manual_chain',
			value: null,
			nullable: () => true,
			getId: getAssemblyId,
			showOnForm: false
		},

		{
			varName: 'manual_chain_color',
			value: null,
			nullable: () => true,
			getId: getAssemblyId,
			showOnForm: false
		},

		{
			varName: 'hembar_color',
			value: null,
			nullable: () => true,
			getId: getAssemblyId,
			showOnForm: false
		},

		{
			varName: 'shade_type',
			label: 'Shade Type',
			type: 'select',
			choices: [
				//     { name: 'Manual', value: 'manual' },
				{ name: 'Motorized', value: 'motorized' }
			],
			causesReprice: true,
			groupName: 'shade_type_mount_type',
			inlineGroup: true,
			error: (shade) => {
				if (shade.val('width') > 192 && shade.valMatch('shade_type', 'manual')) {
					return 'Shades greater than 192" must be motorized.';
				}
			},
			saveType: UI.ShadeNum,
			getId: getAssemblyId,
			massEdit: true

		},

		{
			varName: 'mount_type',
			label: 'Mount Type',
			type: 'select',
			choices: [
				{ name: 'Ceiling', value: 'outdoor_ceiling_mount' },
				{ name: 'Wall', value: 'outdoor_wall_mount' },
			],
			groupName: 'shade_type_mount_type',
			inlineGroup: true,
			saveType: UI.AssemblyNum,
			getId: getAssemblyId,
			massEdit: true
		},

		{
			varName: 'width',
			label: 'Width',
			groupName: 'WH',
			inlineGroup: true,
			type: 'fraction',
			info: (shade) => (shade.valMatch('shade_type', 'Manual') && shade.val('width') && shade.val('width') < 18 ? 'Shades less than 18 inches wide are not upgradeable to motorized shades.' : null),
			minValue: 0,
			maxValue: 300,
			causesReprice: true,
			saveType: UI.ShadeNum,
			getId: getAllShadeIds,
			massEdit: true

		},

		{
			varName: 'height',
			label: 'Height',
			groupName: 'WH',
			inlineGroup: true,
			type: 'fraction',
			info: (shade) => (shade.val('width') && shade.val('height') && shade.val('height') >= shade.val('width') * 3
				? 'For Optimal Tracking, a width-to-length ratio of less than 3:1 is recommended.'
				: null
			),
			minValue: 1,
			causesReprice: true,
			error: (shade) => {
				if (shade.val('height') > 192) {
					return 'Oudoor Shades must be 192" or less in length.';
				}

				// if (shade.valMatch('shade_type', 'Manual') && shade.val('height') > 192) {

				//     return 'Manual shades must be 192" or less in length.';
				// }

				if (shade.valMatch('shade_type', 'Motorized') && shade.val('height') > 240) {
					return 'Please contact PowerShades for shades greater than 240" in length.';
				}
			},
			saveType: UI.ShadeNum,
			getId: getAllShadeIds,
			massEdit: true
		},

		{
			varName: 'crank_length',
			label: 'Crank Length',
			groupName: 'WH',
			inlineGroup: true,
			type: 'fraction',
			conditional: (shade) => shade.valMatch('shade_type', 'Manual'),
			minValue: 0,
			causesReprice: false,
			saveType: UI.AssemblyNum,
			getId: getAssemblyId,
			massEdit: true
		},

		{
			varName: 'header_type',
			label: 'Header Type',
			groupName: 'Header & Frame',
			inlineGroup: true,
			type: 'select',
			error: (shade) => {
				if (shade.valMatch('header_type', 'outdoor_harmony_standard_round_front', 'outdoor_harmony_premium_square_front') && shade.val('width') >= 240) {
					return 'Harmony headers are not available for shades 240" or more in width.';
				}
			},
			causesReprice: true,
			choices: [
				{
					optGroup: 'Harmony', name: 'Standard Round Front', value: 'outdoor_harmony_standard_round_front'
				},
				{
					optGroup: 'Harmony', name: 'Premium Square Front', value: 'outdoor_harmony_premium_square_front'
				},
				{
					optGroup: 'Apex', name: 'Apex Cassette', value: 'outdoor_apex'
				},
				{
					optGroup: 'Meridian', name: 'Meridian Open Roll', value: 'outdoor_meridian'
				}
			],
			value: null,
			saveType: UI.AssemblyNum,
			getId: getAssemblyId,
			massEdit: true
		},

		{
			varName: 'hardware_color',
			label: 'Bracket/Frame Color',
			groupName: 'Header & Frame',
			inlineGroup: true,
			type: 'select',
			choices: [
				{ name: 'Anthracite', value: 'outdoor_anthracite' },
				//        { name: 'Beach Sand', value: 'outdoor_beach_sand' }, Commenting out until corradi has it back in stock.
				{ name: 'Carbon', value: 'outdoor_carbon' },
				{ name: 'Corten', value: 'outdoor_corten' },
				{ name: 'Dune', value: 'outdoor_dune' },
				{ name: 'Glacier', value: 'outdoor_glacier' },
				{ name: 'Granite', value: 'outdoor_granite' },
				{ name: 'Urban Bronze', value: 'outdoor_urban_bronze' },
				{ name: 'White Quartz', value: 'outdoor_white_quartz' },
			],
			saveType: UI.AssemblyNum,
			skipReprice: true,
			getId: getAssemblyId,
			massEdit: true
		},

		{
			varName: 'control_side',
			skipReprice: true,
			label: 'Control Side',
			type: 'select',
			choices: [
				{ name: 'Right' },
				{ name: 'Left' }
			],
			groupName: 'control_side',
			inlineGroup: true,
			saveType: UI.AssemblyNum,
			getId: getAssemblyId,
			massEdit: true
		},

		{
			value: null,
			varName: 'fabric_name',
			label: 'Fabric',
			causesReprice: true,
			fullWidth: true,
			groupName: 'Fabric',
			type: 'fabricSelect',
			error: (shade) => { },
			choices: () => {
				const goods = SelectOutdoorFabrics(UI.quoteLevelVariables().quoteSub.ID)(UI.quoteLevelVariables().store.getState())
				return goods;
			},
			saveType: UI.ShadeNum,
			getId: getAllShadeIds,
			massEdit: true
		},

		{
			varName: 'hembar_type',
			groupName: 'Options',
			label: 'Hembar Type',
			type: 'select',
			choices: [
				{ name: 'Heavy Duty Bottom Bar', value: 'outdoor_heavy_duty_bottom_bar' },
			],
			saveType: UI.ShadeNum,
			getId: getAssemblyId,
			massEdit: true
		},

		{
			varName: 'side_channels',
			groupName: 'Options',
			label: 'Side Channels',
			type: 'select',
			causesReprice: true,
			choices: [
				{ name: 'Lightning Track', value: 'outdoor_lightning_track' },
				{ name: 'Cable - Wall Mount', value: 'outdoor_wall_mount_cable' },
				{ name: 'Cable - Floor Mount', value: 'outdoor_floor_mount_cable' },
			],
			saveType: UI.AssemblyNum,
			getId: getAssemblyId,
			massEdit: true
		},

		{
			varName: 'direction_facing',
			label: 'Direction Facing',
			skipReprice: true,
			groupName: 'Attributes',
			type: 'select',
			nullable: () => true,
			choices: [
				{ name: 'Unknown' },
				{ name: 'North' },
				{ name: 'Northeast' },
				{ name: 'East' },
				{ name: 'Southeast' },
				{ name: 'South' },
				{ name: 'Southwest' },
				{ name: 'West' },
				{ name: 'Northwest' }
			],
			saveType: UI.AssemblyNum,
			getId: getAssemblyId,
			massEdit: true
		},

		{
			varName: 'floor',
			label: 'Floor',
			groupName: 'Attributes',
			skipReprice: true,
			type: 'int',
			minValue: -50,
			maxValue: 200,
			negative_prefix: 'B',
			saveType: UI.AssemblyNum,
			getId: getAssemblyId,
			massEdit: true
		},

		{
			varName: 'room_name',
			label: 'Area Name',
			skipReprice: true,
			groupName: 'Attributes',
			type: 'text',
			saveType: UI.AssemblyNum,
			getId: getAssemblyId,
			massEdit: true
		},

		{
			varName: 'shade_name',
			label: 'Shade Name',
			skipReprice: true,
			groupName: 'Attributes',
			type: 'text',
			error: (shade) => (shade.isNameUnique() ? null : 'Please create a unique shade name.'),
			saveType: UI.AssemblyNum,
			getId: getAssemblyId
		}

	];

	items.forEach((i) => {
		i.ValueChanged = UI.ValueChanged;
	});

	return items;
}
