import React from 'react';

import { Typeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Popup from 'reactjs-popup';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class FabricSelectField extends React.Component {
	constructor(props) {
		super(props);

		this.props = props;

		const choices = props.option?.choices
			? props.option.choices()
			: props.choices ? props.choices : [];

		const isCenterable = props.isCenterable ?? false;

		this.state = {
			value: props.value,
			choices,
			zIndex: 30,
			open: false,
			isCenterable,
			tooltipDisabled: true,
		};
		

		this.focused = this.focused.bind(this);
		this.blurred = this.blurred.bind(this);
		this.close = this.close.bind(this);
		this.open = this.open.bind(this);
		this.changed = this.changed.bind(this);
		// this.idk = this.onScrollEvent.bind(this);
	}

	onScrollEvent(event) {
		this.blurred && this.blurred();
	}

	close() {
		this.setState({ open: false });
	}

	open() {
		this.setState({ open: true });
	}

	getChoice(val) {
		return this.state.choices.find((c) => (c.value && c.value === val) || c.name === val);
	}

	loadChoices() {
		this.setState({
			choices: this.props?.options?.choices ?? this.props.choices ?? this.state.choices ?? [],
			zIndex: 50,
		});
	}

	changed(newVal) {
		const { value } = newVal[0] ?? '';
		const currentChoice = this.getChoice(this.state.value);
		const newChoice = this.getChoice(newVal);
		const { props } = this;
	
		currentChoice && currentChoice.onDeselect && currentChoice.onDeselect(props.quote);
		newChoice && newChoice.onSelect && newChoice.onSelect(props.quote);
	
		this.setState({ value, tooltipDisabled: true }, () => {
			this.close();
			setTimeout(() => this.setState({ tooltipDisabled: false }), 500);
		});
	
		props.onChange(value);
		props.onBlur ? props.onBlur(value) : console.log("blur");
	}
	
	

	focused(component) {
		this.open();

		if (this.Choices().length < 1) {
			this.loadChoices();
		}
		const td = component.target.parentElement.parentElement.parentElement.parentElement
			.parentElement.parentElement.parentElement;

		// Scroll element
		const tb = td.parentElement.parentElement.parentElement.parentElement;

		const options = {
			behavior: 'auto',
			block: 'nearest',
			inline: 'center',
		};

		if (this.state.isCenterable) {
			tb.addEventListener('scroll', this.onScrollEvent, { once: true });
			td.scrollIntoView(options);
		}

		// This tunnels to the td element,

		this.setState({ zIndex: 10000 });
	}

	Choices() {
		return this.state.choices;
	}

	blurred() {
		this.setState({ zIndex: 1 });
		this.close();
	}

	opacity(opacity = '') {
		return {
			alignItems: 'center',
			display: 'flex',

			':before': {
				borderRadius: 10,
				content: `"${opacity || ''}"`,
				display: 'block',
				marginRight: 8,
			},
		};
	}

	render() {
		const { value: stateValue } = this.state;

		const value = this.props.value ?? stateValue;

		const options = (this.props.choices ?? this.state.choices).map((c) => ({
			value: c.name ?? c.value,
			label: `${c?.label ?? c.name ?? c.value}`,
			opacity: c.opacity ?? 1,
			lead_time_days: c.lead_time_days,
		}));

		const selectionFromOptions = options.find((o) => o.value === value);

		const selectionDoesNotExist = !selectionFromOptions && value;

		const selectionFromOptionsInArray = selectionFromOptions ? [selectionFromOptions] : (selectionDoesNotExist
			? [{
				value,
				label: `${value}`,
				opacity: 0,
				lead_time_days: 0,
			}] : []);
		// const selectionFromOptionsInArray = selectionFromOptions ? [selectionFromOptions] : [];

		const reference = { current: null };

		const selectedFabric = JSON.stringify(value);

		const tooltip = (
			<Tooltip
				className={(this.state.open || !selectedFabric || selectedFabric === '""' || this.state.tooltipDisabled) && "visually-hidden"}
			>
				{selectedFabric}
			</Tooltip>
		);
		

		const select = (
			<OverlayTrigger
				placement='top'
				overlay={tooltip}
				trigger={(!this.state.open && selectedFabric && selectedFabric !== '""') ? ["hover", "focus"] : []}
				delayShow={300}
				delayHide={150}
			>
				<div className='trigger-wrapper'>
					<Typeahead
						className={`fabric-select-field-input${this.props.className || ""}${!this.props.disabled && "-editing"}`}
						id={`fabric-select-field ${this.props.id}`}
						disabled={this.props.disabled}
						options={options}
						onChange={(option) => ((option.length == 0 && this.props.isClearable) ? () => {} : this.changed(option))}
						clearButton
						selected={selectionFromOptionsInArray}
						open={this.state.open}
						positionFixed
						onBlur={() => this.close()}
						onFocus={() => this.open()}
						style={this.props.style}
					/>
				</div>
			</OverlayTrigger>
		);

		let iconText = '';

		if (this.props.willBeSeamed) {
			iconText += '\n This assembly will have seamed fabric.';
		}

		if (this.props.hasSagWarning) {
			iconText += '\n The fabric on this shade may have a noticeable V shape.';
		}

		const isIconActive = this.props.willBeSeamed || this.props.hasSagWarning;

		return !isIconActive ? (
			select
		) : (
			<span className='z-0' ref={reference}>
				<div className="fabric-select-input z-0">{select}</div>
				<div>
					{
						isIconActive ? (
							<Popup
								contentStyle={{
									width: "fit-content", border: '1px solid #6d6e71',zIndex: 0,
								}}
								arrow
								on={['hover', 'focus']}
								trigger={
									(
										<span>
											<FontAwesomeIcon icon={faInfoCircle} />
										</span>
									)
								}
								position={['bottom center', 'top center', 'right center', 'left center', 'center center']}
								keepTooltipInside
							>
								{iconText}
							</Popup>
						) : null
					}
				</div>
			</span>
		);
	}
}

export default FabricSelectField;
