import React from "react";
import { faPalette } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PortalShadeOption } from "../../Store/entities/assemblies/types";

type ColorSwatchProps = {
	colorPalettes: PortalShadeOption<string>;
	value: string;
	hexCode: string;
	selectedValue?: string;
	isOpen?: boolean;
	size?: "small" | "large";
	defaultColor?: string | undefined;
	hidden?: boolean;
};

const ColorSwatch = ({ colorPalettes, value, hexCode, selectedValue, isOpen = false, size = "large", defaultColor, hidden = false }: ColorSwatchProps) => {
	const colorPaletteValue = colorPalettes.getValue();
	const isCustomPalette = colorPaletteValue === "Custom Palette";
	const missingDefault = defaultColor === "Missing Default Color";

	const styles = {
		small: {
			height: "20px",
			width: "20px",
			border: `solid 2px ${value !== (defaultColor || colorPaletteValue)
				&& !isOpen && !isCustomPalette && !missingDefault ? "#8dc63f" : "gray"}`,
			borderRadius: "100%",
			marginLeft: "6px",
		},
		large: {
			height: "50px",
			width: "50px",
			border: `solid ${value === (selectedValue || colorPaletteValue || defaultColor)
				&& !isOpen ? "#8dc63f 4px" : "gray 2px"}`,
			borderRadius: "6px",
		},
	};

	return (
		<div
			className="color-swatch position-relative"
			style={{
				...styles[size],
				background: hexCode || "transparent",
			}}
			hidden={hidden}
		>
			{hexCode && hexCode === "transparent" && (
				<FontAwesomeIcon
					icon={faPalette}
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						color: "Gray",
						fontSize: size === "small" ? "12px" : "24px",
					}}
				/>
			)}
		</div>
	);
};

export default ColorSwatch;
