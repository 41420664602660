import React, { useMemo, useState } from 'react';
import Popup from 'reactjs-popup';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { isEqual } from 'lodash';
import type { Option } from 'react-bootstrap-typeahead/types/types';

import type { AddEmailDistributionProps } from './types';
import type { DistributionEmail } from '../powershadesApiTypes';
import Button from '../Parts/Button';

const AddEmailDistribution = ({
	open,
	onClose,
	handleAdd,
	existingEmailDistributions,
}: AddEmailDistributionProps) => {
	const [invoiceAccess, setInvoiceAccess] = useState(false);
	const [orderPlacedAccess, setOrderPlacedAccess] = useState(false);
	const [email, setEmail] = useState('');
	const [emailSelectOpen, setEmailSelectOpen] = useState(false);
	const [originalDistributionEmail, setOriginalDistributionEmail] = useState<
		DistributionEmail | null
	>(null);

	const modifyingExistingEmail = originalDistributionEmail !== null;

	const workingDistributionEmail: DistributionEmail = {
		email,
		is_receiving_order_placed_emails: orderPlacedAccess,
		is_receiving_invoice_emails: invoiceAccess,
	};

	const emailSchema = yup
		.string()
		.email('Email must be a valid email')
		.required('Email is required');

	const isEmailValid = useMemo(() => {
		try {
			emailSchema.validateSync(email);
			return true;
		} catch (err) {
			return false;
		}
	}, [email]);

	const readyToSubmit = useMemo(
		() => {
			if (modifyingExistingEmail) {
				const changesHaveBeenMade = !isEqual(originalDistributionEmail, workingDistributionEmail);
				return changesHaveBeenMade && isEmailValid;
			}
			return isEmailValid;
		},
		[isEmailValid, modifyingExistingEmail, originalDistributionEmail, workingDistributionEmail]
	);

	const handleModifyingExistingEmail = (existingEmail: DistributionEmail) => {
		setOriginalDistributionEmail(existingEmail);
		setInvoiceAccess(existingEmail.is_receiving_invoice_emails ?? false);
		setOrderPlacedAccess(existingEmail.is_receiving_order_placed_emails);
	};

	const resetStateValues = () => {
		setOriginalDistributionEmail(null);
		setEmail('');
		setInvoiceAccess(false);
		setOrderPlacedAccess(false);
	};

	const handleClose = () => {
		toast.info('Email Distribution Rule Not Added');
		resetStateValues();
		onClose();
	};

	const handleSubmit = () => {
		try {
			const newDistributionEmails = [...existingEmailDistributions, workingDistributionEmail];
			handleAdd(newDistributionEmails);
			handleClose();
			toast.success('Email Distribution Rule Added');
		} catch (err) {
			toast.error('Error Adding Email Distribution Rule');
			throw err;
		}
	};

	const handleEmailChange = (selected: Option[]) => {
		if (selected.length === 0 || !selected) {
			resetStateValues();
			return;
		}
		const selection = selected[0] as unknown as DistributionEmail;
		if (!selection || selection === undefined) return;
		setEmail(selection.email);
		setEmailSelectOpen(false);
		const emailInExisting = existingEmailDistributions.find(
			(distributionEmail) => distributionEmail.email === selection.email
		);
		if (!emailInExisting) return;
		handleModifyingExistingEmail(emailInExisting);
	};

	return (
		<Popup
			closeOnEscape={false}
			closeOnDocumentClick={false}
			className="mobile-modal"
			open={open}
			onClose={handleClose}
		>
			<Row className="mb-2">
				<Col>
					<h4>
						{modifyingExistingEmail ? 'Modify' : 'Add'}
						&nbsp;Email Distribution Rule
					</h4>
				</Col>
			</Row>
			<Row className="mb-2">
				<Col>
					<input className="d-none" />
					<Typeahead
						id="email-distribution"
						open={emailSelectOpen}
						onFocus={() => setEmailSelectOpen(true)}
						onBlur={() => setEmailSelectOpen(false)}
						positionFixed
						clearButton
						labelKey="email"
						options={existingEmailDistributions}
						allowNew
						newSelectionPrefix="Add a new email: "
						placeholder="Select an email address..."
						onChange={handleEmailChange}
						onInputChange={() => {
							if (!emailSelectOpen) setEmailSelectOpen(true);
						}}
					/>
				</Col>
			</Row>
			<Row className="mb-2">
				<Col>
					<Button
						fluid
						fullWidth
						disabled={!email}
						color={orderPlacedAccess ? 'green' : 'gray'}
						onClick={() =>
							setOrderPlacedAccess((prevOrderPlacedAccess) => !prevOrderPlacedAccess)
						}
						radioButton
					>
						Order Notifications&nbsp;
						<FontAwesomeIcon icon={orderPlacedAccess ? faCheck : faTimes} />
					</Button>
				</Col>
				<Col>
					<Button
						fluid
						fullWidth
						disabled={!email}
						color={invoiceAccess ? 'green' : 'gray'}
						onClick={() => setInvoiceAccess((prevInvoiceAccess) => !prevInvoiceAccess)}
						radioButton
					>
						Invoices&nbsp;
						<FontAwesomeIcon icon={invoiceAccess ? faCheck : faTimes} />
					</Button>
				</Col>
			</Row>
			<Row className="justify-content-end mt-4">
				<Col className="text-start">
					<Button color="gray" onClick={handleClose}>
						Close
					</Button>
				</Col>
				<Col className="text-end">
					<Button color="green" disabled={!readyToSubmit} onClick={handleSubmit}>
						{modifyingExistingEmail ? 'Update' : 'Add'}
					</Button>
				</Col>
			</Row>
		</Popup>
	);
};

export default AddEmailDistribution;
