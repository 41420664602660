import { DealerSignUpForm } from "../powershadesApiTypes";

/* eslint-disable import/prefer-default-export */
export const initialDealerSignUpForm: (inviteCode: string, territoryId: number, sign_up_userId: number) => DealerSignUpForm = (inviteCode = "", territoryId = 0, signUpUserId = 0) => {
	const form: DealerSignUpForm = {
		company_name: '',
		contact_first_name: '',
		contact_last_name: '',
		contact_email: '',
		contact_phone: '',
		billing_first_name: '',
		billing_last_name: '',
		billing_email: '',
		billing_phone: '',
		billing_address1: '',
		billing_address2: '',
		billing_address_city: '',
		billing_address_zip: '',
		billing_address_country_subdivision_id: 0,
		billing_address_country_code: '',
		mailing_same_as_billing: false,
		mailing_first_name: '',
		mailing_last_name: '',
		mailing_address1: '',
		mailing_address2: '',
		mailing_address_city: '',
		mailing_address_zip: '',
		mailing_address_country_code: '',
		mailing_address_country_subdivision_id: 0,
		is_sales_tax_exempt: false,
		sales_tax_exemption_type: 'non_exempt',
		sales_tax_exempt_number: '',
		sales_tax_file_location: '',
		federal_tax_id: '',
		federal_tax_file_location: '',
		password: '',
		confirm_password: '',
		invite_code: inviteCode,
		territory_id: territoryId,
		user_signup_id: signUpUserId,
		success: false,
		error: ""
	};

	return form;
};
