import React from "react";
import store from "../../../../Store";
import { InitialAssemblyState, InitialShadeState, updateAssemblies } from "../../../../Store/entities/assemblies";
import { useAssembliesByQuoteId } from "../../../../Store/entities/assemblies/hooks";
import EditPSOutdoorShadeModalBase from "./EditPSOutdoorShadeBase";

const MassEditPSOutdoorModal = ({ quoteId, isOpen, onClose }) => {
	const assembly = { ...InitialAssemblyState(), unlocked: true, shades: [InitialShadeState()] };
	let selectedAssemblies = useAssembliesByQuoteId(quoteId).filter((assy) => assy.selected);

	const setAssembly = (assy) => {
		Object.entries(assembly).forEach(([key, value]) => {
			if (key === "shades") {
				Object.entries(value[0]).forEach(([shadeKey, shadeVal]) => {
					if (shadeVal !== assy.shades[0][shadeKey]) {
						selectedAssemblies = selectedAssemblies.map((selectedAssy) => (
							{
								...selectedAssy, shades: selectedAssy.shades.map((selectedShade) => (
									{ ...selectedShade, [shadeKey]: assy.shades[0][shadeKey] }
								))
							}
						));
					}
				});
			}
			if (assy[key] !== value && key !== 'shades') {
				selectedAssemblies = selectedAssemblies.map((selectedAssy) => ({ ...selectedAssy, [key]: assy[key] }));
			}
		});

		store.dispatch(updateAssemblies({ quoteId, assemblies: selectedAssemblies }));
	};

	return <EditPSOutdoorShadeModalBase
		isOpen={isOpen}
		onClose={onClose}
		quoteId={quoteId}
		assembly={assembly}
		setAssembly={setAssembly}
		massEdit
	/>;
};

export default MassEditPSOutdoorModal;
