/* eslint-disable react/button-has-type */
import React from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { ButtonProps } from "./types";

import "./button.scss";

const Button = ({
	ariaLabel = "",
	fluid = false,
	type = "button",
	fullWidth = false,
	blockLevel = false,
	disabled = false,
	color = "green",
	radioButton = false,
	size = "standard",
	hoverColor = "green",
	className = "",
	id = "",
	onClick,
	children,
	loading = false,
	title = "",
	style = {},
}: ButtonProps): JSX.Element => (
	<button
		aria-label={ariaLabel || undefined}
		id={id || undefined}
		style={style || undefined}
		title={title || undefined}
		disabled={disabled || loading}
		className={`${size === "standard" ? "btn" : `btn btn-${size}`} ${
			loading ? 'btn-gray' : color === "green" ? "btn-green" : `btn-${color}`
		} ${radioButton ? "radio-button" : ""} ${hoverColor === "green" ? "" : ` btn-light-${hoverColor} `}${
			fluid ? " btn-fluid " : ""
		}${fullWidth ? " full-width " : ""}${blockLevel ? " btn-block " : ""} ${className}`}
		onClick={(idk) => onClick && onClick(idk)}
		type={type as "button" | "submit" | "reset"}
	>
		<span className={loading ? "invisible" : "visible"}>{children}</span>
		{loading && (
			<FontAwesomeIcon
				icon={faSpinner}
				className="fast-spin button-spinner"
			/>
		)}
	</button>
);

export default Button;
