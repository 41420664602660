import jsPDF from 'jspdf';
import autoTable, { applyPlugin } from 'jspdf-autotable';

import powershades_logo from '../../assets/img/logos/primary/Powershades_Logo_400.png';
import powershades_logo_white from '../../assets/img/logos/primary/Powershades_Logo_White.png';
import powershades_logo_white_svg from '../../assets/img/logos/primary/Powershades_Logo_White.svg';
import powershades_logo_black_svg from '../../assets/img/logos/primary/Powershades_Logo_Black.svg';
import * as idk from "../../assets/fonts/Montserrat-Regular-normal";
import * as idk2 from "../../assets/fonts/Montserrat-Bold-bold";
import Styles from '../../Styles';
import warrantyInfo from "../../assets/warranty-terms-conditions.pdf";
import quoteVerbiage from "../../assets/quote-verbiage.pdf";
import PDFMerger from 'pdf-merger-js/browser';
// import { applyPlugin2 } from 'jspdf-svg'
applyPlugin(jsPDF);

const expensiveDraws = {

};

const GetQuotePDFBase64 = async (props) => {
	const {
		type = "msrp",
		shadeAssemblies = [],
		quoteAccessories = [],
		fabricSamples = [],
		quoteData = {
		},
		dealerData = {},
		distributorData = {},
		representativeData = {},
		currencyData = {},
		includeWarranty = true
	} = props;

	const {
		customer_address: installation_address = {},
		id: quoteId,
		title,
		generated_by,
		subtotal = 0,
		shipping = 0,
		couponAmount = 0,
		couponType = "none",
		taxType = "hidden",
		taxPercentage = 0,
		taxShipping = false,
		adjustment = 0,
		adjustmentName = "",
		isShippingTaxed = false,
	} = quoteData;

	const x = idk;
	const x2 = idk2;
	const doc = new jsPDF();

	// doc.addFont("Montserrat","Montserrat","Montserrat","5",'StandardEncoding')

	const isDealerShowing = "dealer|distributor".includes(type);
	const isDistributorShowing = "distributor".includes(type);

	const isCouponEnabled = couponAmount !== 0 && couponType !== 'hidden';
	const isTaxesEnabled = taxPercentage !== 0 && taxType !== 'hidden';

	let base64Img;
	const currentDate = getCurrentDateString();
	// var base64 = getBase64Image(logo_image);

	// console.log(base64);

	let psLogoWhite = null;
	if (!expensiveDraws.psLogoWhite) {
		expensiveDraws.psLogoWhite = await base64SvgToBase64Png(powershades_logo_white_svg, 700);
	}

	psLogoWhite = expensiveDraws.psLogoWhite;

	doc.setFontSize(40);

	/*
		This starts the weird section where we use triangles to make
		a rectangle. 

		There are going to be 4 constants that represent the furthest left, right, up, and down that should be used for 8.5/11.

		A rectangle is 2 triangles.: Demo Dealer Canada 1 Contact: Josh Carrier
Street: 123 De
Company: De
		For more look up the documentation for a triangle on the jspdf site.

	*/

	const left = 16;
	const right = 207;
	const up = 2;
	const down = 200;

	const minLeft = 0;
	const maxRight = 220;
	const minUp = 0;

	const headerLeft = 3;

	const rectangleHeight = 32;

	// These are the points that will go into the rectangle.
	const topLeft = { x: 0, y: minUp + 5 };
	const topRight = { x: maxRight, y: minUp + 5 };
	const bottomLeft = { x: 0, y: minUp + rectangleHeight };
	const bottomRight = { x: maxRight, y: minUp + rectangleHeight };

	//            red, green, blue
	const psGreen = [141, 198, 63];
	// AFD779
	const lightPsGreen1 = [175, 215, 121];
	// DDEEC5
	const lightPsGreen2 = [221, 238, 197];
	const psGreenHex = "#8DC73E";
	const white = [255, 255, 255];
	const black = [0, 0, 0];
	const gray = [80, 80, 80];
	const lightGray = [220, 220, 220];
	const normal = "normal";
	const bold = "bold";
	const Monserrat = "Montserrat-Regular";
	const MonserratBold = "Montserrat-Bold";
	doc.setFillColor(204, 204, 204, 0);
	doc.setFont(Monserrat, normal);

	const addPSImage = () => {
		doc.setLineWidth(1);
		doc.setDrawColor(...lightPsGreen1);
		doc.setFillColor(...lightPsGreen1);

		// x1,y1,x2,y2,x3,y3
		doc.triangle(topLeft.x, topLeft.y, topRight.x, topRight.y, bottomLeft.x, bottomLeft.y, "FD");
		doc.triangle(bottomRight.x, bottomRight.y, topRight.x, topRight.y, bottomLeft.x, bottomLeft.y, "FD");

		doc.addImage(psLogoWhite, 10, 1.5, 114, 32);// .advancedAPI(doc => doc)
	};
	addPSImage();

	const {
		currencyMultiplier = 1.0,
		currencySymbol = "$",
		currencyISO = "USD"
	} = currencyData;

	const dealerMultiplier = dealerData.multiplier;
	const distributorMultiplier = distributorData.multiplier;

	const dealerSubtotal = dealerMultiplier * subtotal;

	const isPercentage = couponType == "percentage";
	const isFixed = couponType == "fixed";

	const dealerCoupon = couponAmount;
	const msrpCoupon = isPercentage ? dealerCoupon / dealerMultiplier : isFixed ? Math.abs(couponAmount) : 0;


	let dealerMSRP = 0 + dealerSubtotal + shipping;
	let msrp = 0 + subtotal + 0 + shipping;

	if (isCouponEnabled) {
		dealerMSRP -= dealerCoupon;
		msrp -= msrpCoupon;
	}

	const dealerTaxes = dealerMSRP * taxPercentage;
	const taxes = isShippingTaxed ? msrp * taxPercentage : (msrp - shipping) * taxPercentage;

	if (isTaxesEnabled) {
		msrp += taxes;
		dealerMSRP += dealerTaxes;
	}

	if (adjustment !== 0 && adjustment !== null) {
		const adjustmentAsNumber = Number(adjustment);
		msrp += adjustmentAsNumber;
	}

	// Styles
	const headerTextsize = 14;
	const regularTextSize = 11;
	const smallText = 8;
	const headerTextRegularSize = 12;
	const sectionPadding = 20;
	const linePadding = 9;

	const alignRight = { align: "right" };
	const alignLeft = { align: "left" };

	// Quote Information
	doc.setDrawColor(...black);
	const quoteHeight = up + rectangleHeight + 14;

	doc.setFontSize(headerTextsize);
	doc.setFont(MonserratBold, bold);

	const quoteColumn = left + 23;
	doc.text("Quote #:", quoteColumn, quoteHeight, alignRight);

	const dateColumn = right - 73.29;

	doc.text("Date:", dateColumn, quoteHeight, alignRight);

	const jobTitleHeight = quoteHeight + linePadding;
	doc.text("Job Title:", quoteColumn, jobTitleHeight, alignRight);

	const generatedByHeight = jobTitleHeight + linePadding;
	doc.text("Generated By:", quoteColumn, generatedByHeight, alignRight);

	const lineSpaceX = 2;
	const lineSpaceY = -0.5;

	doc.setDrawColor(...lightGray);
	doc.setFillColor(...lightGray);

	const quoteLineLength = 80;

	// x1,y1,
	// x2,y2
	const quoteLine = [
		quoteColumn + lineSpaceX, quoteHeight,
		quoteColumn + quoteLineLength, quoteHeight
	];

	doc.setLineWidth(0.3);

	doc.line(...quoteLine);

	const jobOffset = 9;
	const jobLineLength = 140;

	const jobLine = [
		quoteColumn + lineSpaceX, quoteHeight + jobOffset,
		quoteColumn + jobLineLength, quoteHeight + jobOffset
	];

	doc.line(...jobLine);

	const generatedOffset = 18;
	const generatedLineEnd = 140;
	const generatedLine = [
		quoteColumn + lineSpaceX, quoteHeight + generatedOffset,
		quoteColumn + generatedLineEnd, quoteHeight + generatedOffset
	];
	doc.line(...generatedLine);

	const dateLine = [
		dateColumn + lineSpaceX, quoteHeight,
		dateColumn + 45, quoteHeight
	];

	doc.line(...dateLine);

	const cleanNumber = (symbol) => (number) => {
		let isNegative = 0;
		if (!number) return;

		if (number < 0) {
			isNegative = true;
			number = -number;
		}

		const numberStr = number.toString() ?? "";

		const numberFloat = parseFloat(numberStr) * currencyMultiplier;

		const numberStrCut = numberFloat.toFixed(2);

		// Doesn't work but will unless they'er in quebec.
		const numberWithSymbol = `${isNegative ? '-' : ''}${symbol}${numberStrCut}`;

		return numberWithSymbol;
	};

	const cleanDisplay = cleanNumber(currencySymbol);

	doc.setFont(Monserrat, normal);

	doc.setFontSize(headerTextRegularSize);

	doc.text(` ${quoteId}`, quoteColumn + lineSpaceX, quoteHeight + lineSpaceY, alignLeft);
	doc.text(` ${getCurrentDateString()}`, dateColumn + lineSpaceX, quoteHeight + lineSpaceY, alignLeft);
	doc.text(` ${title}`, quoteColumn + lineSpaceX, jobTitleHeight + lineSpaceY, alignLeft);
	doc.text(` ${generated_by}`, quoteColumn + lineSpaceX, generatedByHeight + lineSpaceY, alignLeft);

	// Dealer information

	doc.setFont(MonserratBold, bold);

	doc.setFontSize(headerTextsize);

	const dealerHeaderHeight = generatedByHeight + sectionPadding;
	doc.text("Dealer Info", left, dealerHeaderHeight);

	doc.setFontSize(regularTextSize);

	const companyRightLine = 50;
	const contactRightLine = 140;
	const companyStart = companyRightLine + 3;
	const contactStart = contactRightLine + 3;

	const companyHeight = dealerHeaderHeight + 10;

	doc.text("Company:", companyRightLine, companyHeight, alignRight);
	doc.text("Contact:", contactRightLine, companyHeight, alignRight);

	const streetHeight = companyHeight + linePadding;
	doc.text("Street:", companyRightLine, streetHeight, alignRight);
	doc.text("Email:", contactRightLine, streetHeight, alignRight);

	const cityHeight = streetHeight + linePadding;
	doc.text("City:", companyRightLine, cityHeight, alignRight);
	doc.text("Phone:", contactRightLine, cityHeight, alignRight);

	const stateHeight = cityHeight + linePadding;
	doc.text("State:", companyRightLine, stateHeight, alignRight);

	const zipHeight = stateHeight + linePadding;
	doc.text("Zip:", companyRightLine, zipHeight, alignRight);

	const contryHeight = zipHeight + linePadding;
	doc.text("Country:", companyRightLine, contryHeight, alignRight);

	doc.setFontSize(regularTextSize);

	doc.setFont(Monserrat, normal);

	const {
		company: Dcompany = "",
		contact: Dcontact = "",
		street: Dstreet = "",
		email: Demail = "",
		city: Dcity = "",
		phone: Dphone = "",
		state: Dstate = "",
		zip: Dzip = "",
		country: Dcountry = "",
	} = dealerData;

	doc.text(`${Dcompany}`, companyStart, companyHeight);
	doc.text(`${Dcontact}`, contactStart, companyHeight);
	doc.text(`${Dstreet}`, companyStart, streetHeight);
	doc.text(`${Demail}`, contactStart, streetHeight);
	doc.text(`${Dcity}`, companyStart, cityHeight);
	doc.text(`${Dphone}`, contactStart, cityHeight);
	doc.text(`${Dstate}`, companyStart, stateHeight);
	doc.text(`${Dzip}`, companyStart, zipHeight);
	doc.text(`${Dcountry}`, companyStart, contryHeight, alignLeft);
	//
	// Customer Info
	//

	doc.setFontSize(headerTextsize);

	doc.setFont(MonserratBold, bold);

	const customerHeaderHeight = contryHeight + sectionPadding;
	doc.text("Customer Info", left, customerHeaderHeight);
	doc.setFont(MonserratBold, bold);

	doc.setFontSize(regularTextSize);

	const cCompanyHeight = customerHeaderHeight + 10;
	doc.text("Company:", companyRightLine, cCompanyHeight, alignRight);
	doc.text("Contact:", contactRightLine, cCompanyHeight, alignRight);

	const cStreetHeight = cCompanyHeight + linePadding;
	doc.text("Street:", companyRightLine, cStreetHeight, alignRight);
	doc.text("Email:", contactRightLine, cStreetHeight, alignRight);

	const cCityHeight = cStreetHeight + linePadding;
	doc.text("City:", companyRightLine, cCityHeight, alignRight);
	doc.text("Phone:", contactRightLine, cCityHeight, alignRight);

	const cStateHeight = cCityHeight + linePadding;
	doc.text("State:", companyRightLine, cStateHeight, alignRight);

	const cZipHeight = cStateHeight + linePadding;
	doc.text("Zip:", companyRightLine, cZipHeight, alignRight);

	const cContryHeight = cZipHeight + linePadding;
	doc.text("Country:", companyRightLine, cContryHeight, alignRight);

	doc.setFont(Monserrat, normal);

	const {
		company: Icompany = "",
		contact: Icontact = "",
		street: Istreet = "",
		email: Iemail = "",
		city: Icity = "",
		phone: Iphone = "",
		state: Istate = "",
		zip: Izip = "",
		country: Icountry = "",
	} = installation_address;

	doc.text(`  ${Icompany}`, companyRightLine, cCompanyHeight, alignLeft);
	doc.text(`  ${Icontact}`, contactRightLine, cCompanyHeight, alignLeft);
	doc.text(`  ${Istreet}`, companyRightLine, cStreetHeight, alignLeft);
	doc.text(`  ${Iemail}`, contactRightLine, cStreetHeight, alignLeft);
	doc.text(`  ${Icity}`, companyRightLine, cCityHeight, alignLeft);
	doc.text(`  ${Iphone}`, contactRightLine, cCityHeight, alignLeft);
	doc.text(`  ${Istate}`, companyRightLine, cStateHeight, alignLeft);
	doc.text(`  ${Izip}`, companyRightLine, cZipHeight, alignLeft);
	doc.text(`  ${Icountry}`, companyRightLine, cContryHeight, alignLeft);

	// Main level pricing.
	const headStyles = {
		fillColor: psGreenHex
	};

	const columns = ["Explanation"];
	const subtotalRow = ["Subtotal: "];
	const shippingAndHandlingRow = ["Shipping & Handling"];
	const couponRow = ["Coupon Amount: "];
	const taxRow = [`Tax (${taxPercentage}%) Amount: `];
	const adjustmentRow = [`${adjustmentName}: `];
	const totalRow = ["Total:"];

	if (isDistributorShowing) {

	}

	if (isDealerShowing) {
		columns.push("Dealer");
		subtotalRow.push(cleanDisplay(dealerSubtotal));
		shippingAndHandlingRow.push(cleanDisplay(shipping));
		couponRow.push(cleanDisplay(-dealerCoupon));
		taxRow.push(cleanDisplay(dealerTaxes));
		adjustmentRow.push(0);
		totalRow.push(cleanDisplay(dealerMSRP));
	}

	columns.push("Consumer");
	subtotalRow.push(cleanDisplay(subtotal));
	shippingAndHandlingRow.push(cleanDisplay(shipping));
	couponRow.push(cleanDisplay(-msrpCoupon));
	taxRow.push(cleanDisplay(taxes));
	adjustmentRow.push(cleanDisplay(adjustment));
	totalRow.push(cleanDisplay(msrp));

	const mainTableBody = [
		subtotalRow,
		shippingAndHandlingRow,
	];

	if (isCouponEnabled) {
		mainTableBody.push(couponRow);
	}

	if (isTaxesEnabled) {
		mainTableBody.push(taxRow);
	}

	if (adjustment !== 0 && adjustment !== null) {
		mainTableBody.push(adjustmentRow);
	}

	mainTableBody.push(totalRow);

	let mainTableHeight = cContryHeight + 30;

	if (isCouponEnabled) {
		mainTableHeight -= 10;
	}

	if (isTaxesEnabled) {
		mainTableHeight -= 10;
	}

	if (adjustment !== 0 && adjustment !== null) {
		mainTableHeight -= 10;
	}
	const couponOffset = isCouponEnabled ? 1 : 0;

	doc.autoTable({
		columns,
		body: mainTableBody,
		startY: mainTableHeight,
		// showHead: 'firstPage',
		showHead: false,
		margin: { left },
		styles: { overflow: 'hidden', },
		headStyles,
		theme: 'grid',
		didParseCell: (data) => {
			if (data.row.index === 1) {
				data.cell.styles.fillColor = lightGray;
			}
			if (data.row.index === 2 + couponOffset) {
				data.cell.styles.fillColor = lightPsGreen1;
			}
		},
		didDrawPage: (data) => {
			const x = 1;

			// Margins
			const {
				top,
				bottom,
				left,
				right
			} = data.settings.margin;
			doc.setFont(MonserratBold, bold);
			doc.setFontSize(smallText);

			const headerY = data.settings.startY - 5;

			const { columns } = data.table;

			let msrpStart = left + columns[0].width + 2;

			if (columns.some((c) => c.raw == 'Dealer')) {
				// Add Dealer
				doc.text(`Dealer Price (${currencyISO})`, msrpStart, headerY);
				msrpStart += columns[1].width;
			}

			doc.text(`MSRP (${currencyISO})`, msrpStart, headerY);
		}
	});

	/*
	==============
	Shade Assemblies
	==============
	*/

	// page break for assemblies
	doc.addPage();
	addPSImage();

	// Header
	doc.setFont(MonserratBold, bold);
	doc.setFontSize(headerTextsize);

	doc.text("Shades", left, quoteHeight - 5);
	doc.setFont(Monserrat, normal);
	doc.setFontSize(regularTextSize);

	let lineItem = 1;

	const saRows = shadeAssemblies
		.sort((s1, s2) => {
			if (s1.sequence_id > s2.sequence_id) {
				return 1;
			} else if (s1.sequence_id < s2.sequence_id) {
				return -1;
			}
			return 0;
		})
		.map((shade) => {
			const arr = [
				lineItem,
				shade.room_name,
				shade.shade_name,
				shade.width,
				shade.height,
				shade.fabric

			];

			if (isDistributorShowing) {
				arr.push(cleanDisplay(shade.msrp * distributorMultiplier));
			}

			if (isDealerShowing) {
				arr.push(cleanDisplay(shade.msrp * dealerMultiplier));
			}

			arr.push(cleanDisplay(shade.msrp));

			lineItem += 1;

			return arr;
		});

	const saColumns = [
		{ header: 'Line Item' },
		{ header: 'Room Name' },
		{ header: 'Shade Name' },
		{ header: 'Width' },
		{ header: 'Height' },
		{ header: 'Fabric' },
	];

	if (isDistributorShowing) {
		saColumns.push({ header: `Distributor Price (${currencyISO})` });
	}

	if (isDealerShowing) {
		saColumns.push({ header: `Dealer Price (${currencyISO})` });
	}

	saColumns.push({ header: `MSRP (${currencyISO})` });

	doc.setFontSize(8);

	// doc.lastAutoTable.finalY + 20
	doc.autoTable({
		columns: saColumns,
		body: saRows,
		startY: quoteHeight,
		// showHead: 'firstPage',
		styles: { overflow: 'hidden', },
		headStyles: {
			...headStyles, fillColor: white, textColor: black, fontSize: 8
		},
		bodyStyles: { fontSize: 8 },
		rowPageBreak: 'avoid',
		didParseCell: (data) => {
			data.cell.styles.fillColor = data.row.index != 0 && data.row.index % 2 == 1 ? lightGray : white;
			// data.cell.styles.fillColor = white
			if (data.section == "head" && data.cell.raw == "Total") {
				data.cell.styles.textColor = lightPsGreen2;
			}
		},
		theme: 'grid',

		// pageBreak: '',
	});

	/*
	==============
	Quote Accessories
	==============
	*/

	const makeAccTable = Array.isArray(quoteAccessories) && quoteAccessories.length > 0;

	// Skip a page
	// Header
	doc.setFont(MonserratBold, bold);
	doc.setFontSize(headerTextsize);
	if (makeAccTable) {
		doc.text("Accessories", left, doc.lastAutoTable.finalY + 14);
	}
	doc.setFont(Monserrat, normal);
	doc.setFontSize(regularTextSize);

	// Dynamically figure columns
	const qaColumns = [
		{ header: 'Name' },
		{ header: 'Quantity' },
		{ header: 'MSRP' },
	];

	if (isDistributorShowing) {
		qaColumns.push({ header: `Distributor Price (${currencyISO})` });
	}

	if (isDealerShowing) {
		qaColumns.push({ header: `Total Dealer Price (${currencyISO})` });
	}

	qaColumns.push({ header: `Total MSRP (${currencyISO})` });

	/*
		{
	name : "Remote",
	quantity: 1,
	description: "It remotes",
	msrp: 120
}
	*/
	// Dynamically map the rows

	const qaRows = quoteAccessories.map((qa) => {
		const row = [
			qa.name,
			qa.quantity,
			cleanDisplay(qa.msrp / qa.quantity)
		];

		if (isDealerShowing) {
			row.push(cleanDisplay((qa.msrp * dealerMultiplier)));
		}

		row.push(cleanDisplay(qa.msrp));

		return row;
	});

	// Table

	if (makeAccTable) {
		doc.autoTable({
			columns: qaColumns,
			body: qaRows,
			startY: doc.lastAutoTable.finalY + 20,
			// showHead: 'firstPage',
			styles: { overflow: 'hidden', },
			headStyles: {
				...headStyles, fillColor: white, textColor: black, fontSize: 8
			},
			bodyStyles: { fontSize: 8 },
			rowPageBreak: 'avoid',
			//
			didParseCell: (data) => {
				data.cell.styles.fillColor = data.row.index != 0 && data.row.index % 2 == 1 ? lightGray : white;
			},
			theme: 'grid'
			// pageBreak: '',
		});
	}

	// Final Wrap up
	const pdfBlob = doc.output("blob");
	let objectUrl = window.URL.createObjectURL(pdfBlob);

	if (includeWarranty) {
		const merger = new PDFMerger();
		await merger.add(objectUrl);
		await merger.add(quoteVerbiage);
		await merger.add(warrantyInfo);
		await merger.setMetadata({
			producer: "pdf-merger-js based script"
		});
		const mergedPdf = await merger.saveAsBlob();
		objectUrl = URL.createObjectURL(mergedPdf);
	}

	return objectUrl;
};

function getBase64Image(img) {
	const canvas = document.createElement("canvas");
	canvas.width = img.width;
	canvas.height = img.height;
	const ctx = canvas.getContext("2d");
	ctx.drawImage(img, 0, 0);
	const dataURL = canvas.toDataURL("image/png");
	return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}

function toDataUrl(url, callback) {
	const xhr = new XMLHttpRequest();
	xhr.onload = function () {
		const reader = new FileReader();
		reader.onloadend = function () {
			callback(reader.result);
		};
		reader.readAsDataURL(xhr.response);
	};
	xhr.open('GET', url);
	xhr.responseType = 'blob';
	xhr.send();
}

const toDataUrlPromise = (url) => new Promise((resolve, reject) => {
	toDataUrl(url, (base64) => {
		resolve(base64);
	});
});

function toText(url, callback) {
	const xhr = new XMLHttpRequest();
	xhr.onload = function () {
		const reader = new FileReader();
		reader.onloadend = function () {
			callback(reader.result);
		};
		reader.readAsText(xhr.response);
	};
	xhr.open('GET', url);
	xhr.responseType = 'blob';
	xhr.send();
}

const toTextPromise = (url) => new Promise((resolve, reject) => {
	toText(url, (base64) => {
		resolve(base64);
	});
});

const makeRectangle = (doc, topLeft, topRight, bottomLeft, bottomRight) => {

};
function getCurrentDateString() {
	let today = new Date();
	const dd = String(today.getDate()).padStart(2, '0');
	const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
	const yyyy = today.getFullYear();

	today = `${mm}/${dd}/${yyyy}`;
	return today;
}

function base64SvgToBase64Png(originalBase64, width) {
	return new Promise((resolve) => {
		const img = document.createElement('img');
		img.onload = function () {
			document.body.appendChild(img);
			const canvas = document.createElement("canvas");
			const ratio = (img.clientWidth / img.clientHeight) || 1;
			document.body.removeChild(img);
			canvas.width = width;
			canvas.height = width / ratio;
			const ctx = canvas.getContext("2d");
			ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
			try {
				const data = canvas.toDataURL('image/png');
				resolve(data);
			} catch (e) {
				resolve(null);
			}
		};
		img.src = originalBase64;
	});
}

export default GetQuotePDFBase64;
