import Money from '../../Parts/Money';
import { useUserMultiplier } from '../../hooks';
import Page from './Page';

const ShadeRow = ({
	quote, shade, displayData,key
}) => {
	const msrp = (displayData.shadeList.find((s) => s.sequenceId == shade.sequenceId) ?? { price: 0 }).price;
	const roomName = shade.val('room_name');
	const shadeName = shade.val('shade_name');

	return (
		<tr key={`row-price-${shadeName}-${roomName}`}>
			<td>{roomName}</td>
			<td>{shadeName}</td>
			<td>
				<Money>{msrp}</Money>
			</td>
		</tr>
	);
};

const ShadesTable = ({
	quote, shades, displayData
}) => {
	const shadesPerPage = 25;

	const user_multiplier = useUserMultiplier();

	const rows = shades.map((s) => <ShadeRow key={`price-sr-${s.sequenceId}-${s.val("shade_name")}`} quote={quote} shade={s} displayData={displayData} />);

	const numberOfPages = (Math.floor(shades.length / shadesPerPage)) + 1;

	if (shades.length == 0) return <></>;

	const shadePages = [];

	let index = 0;
	for (let outerCount = 0; outerCount < numberOfPages; outerCount++) {
		let innerPageCount = shadesPerPage;

		if (outerCount == numberOfPages - 1) {
			innerPageCount = shades.length % shadesPerPage;
		}

		const firstIndex = outerCount * shadesPerPage;
		const lastIndex = firstIndex + innerPageCount;

		const currentShades = rows.slice(firstIndex, lastIndex);

		shadePages.push(
			<Page key={`shades-table-print-${index}`}>
				<table className="invoice-table" border="0" cellSpacing="0" cellPadding="0">
					<tbody>
						<tr className="bold-row">
							<th colSpan="42">Shades</th>
						</tr>
						<tr>
							<th>Room</th>
							<th>Shade Name</th>
							<th>Price</th>
						</tr>
						{currentShades}
					</tbody>
				</table>
			</Page>
		);
		index++;
	}

	return shadePages;
};

export default ShadesTable;
