import { AssemblyShadePayload } from "../powershadesApiTypeExtensions";

export const SingleRollFascia: AssemblyShadePayload = {
	id: 866362,
	indoor_outdoor: 'indoor',
	shades: [
		{
			row_coordinate: 0,
			column_coordinate: 0,
			shade_assembly_id: 866362,
			fabric_name: 'Screen Essential 3001 Ebony',
			hembar_type: 'internal_sealed',
			roll_direction: 'standard',
			width: 30,
			height: 50,
			motor_type: 'low_voltage',
			shade_type: 'motorized',
			lv_power_source: 'wall_charger',
			manual_chain: null,
			manual_chain_color: null,
			bearing_pin_type: 'heavy_duty_ball_bearing',
			motor: '15mm Micro USB Li-Ion Motor 2022',
			tube_sku: 'RTU-42',
			roll_diameter: 2.19356,
			clearance_diameter: 2.19474,
			deflection: 0.00118,
			torque_needed: 0.16352,
			tube: null,
			tube_name: 'Roller blind tube with tape aluminium 42mm 580cm',
		},
	],
	parts: {
		actual_parts: [],
		full_parts: [],
		part_categories: {
			is_channels_only: [],
			is_crown_and_drive_only: [],
			is_hardware_kit_only: [],
			is_header_only: [],
			is_motor_only: [],
			is_tube_and_fabric_only: []
		}
	},
	quote_id: 0,
	unlocked: true,
	sequence_id: 1,
	single_dual: 'Single',
	control_side: 'Right',
	direction_facing: 'Unknown',
	mount_type: 'Inside',
	room_name: 'r 1',
	floor: 1,
	window_jamb_depth: 5,
	side_channels: 'Both',
	side_channels_color: 'Black',
	shade_name: 'Shade Card Testing - Single Roll Fascia',
	quick_shade: true,
	header_type: 'RC3080-A',
	end_caps: 'Fascia End Caps',
	hardware_color: null,
	errors: null,
	error_messages: null,
	msrp: 560.78,
	shipping_price: 10,
	cost: 111.51,
	will_be_seamed: 0,
	has_sag_warning: '',
	hidden_shipping: 40,
	last_time_priced: 1694131200,
};

export const DualRollFascia: AssemblyShadePayload = {
	id: 866363,
	indoor_outdoor: 'indoor',
	shades: [
		{
			row_coordinate: 0,
			column_coordinate: 0,
			shade_assembly_id: 866363,
			fabric_name: 'Screen Essential 3001 Ebony',
			hembar_type: 'internal_sealed',
			roll_direction: 'standard',
			width: 30,
			height: 50,
			motor_type: 'low_voltage',
			shade_type: 'motorized',
			lv_power_source: 'wall_charger',
			manual_chain: null,
			manual_chain_color: null,
			single_dual: 'Dual',
			bearing_pin_type: 'heavy_duty_ball_bearing',
			motor: '15mm Micro USB Li-Ion Motor 2022',
			tube_sku: 'RTU-42',
			roll_diameter: 2.19356,
			clearance_diameter: 2.19474,
			deflection: 0.00118,
			torque_needed: 0.16352,
			tube: null,
			tube_name: 'Roller blind tube with tape aluminium 42mm 580cm',
		},
		{
			row_coordinate: 1,
			column_coordinate: 0,
			shade_assembly_id: 866363,
			fabric_name: 'Screen Essential 3001 Ebony',
			hembar_type: 'internal_sealed',
			roll_direction: 'standard',
			width: 30,
			height: 50,
			motor_type: 'low_voltage',
			shade_type: 'motorized',
			lv_power_source: 'wall_charger',
			manual_chain: null,
			manual_chain_color: null,
			bearing_pin_type: 'heavy_duty_ball_bearing',
			motor: '15mm Micro USB Li-Ion Motor 2022',
			tube_sku: 'RTU-42',
			roll_diameter: 2.19356,
			clearance_diameter: 2.19474,
			deflection: 0.00118,
			torque_needed: 0.16352,
			tube: null,
			tube_name: 'Roller blind tube with tape aluminium 42mm 580cm',
		},
	],
	parts: {
		actual_parts: [],
		full_parts: [],
		part_categories: {
			is_channels_only: [],
			is_crown_and_drive_only: [],
			is_hardware_kit_only: [],
			is_header_only: [],
			is_motor_only: [],
			is_tube_and_fabric_only: []
		}
	},
	quote_id: 0,
	unlocked: true,
	sequence_id: 3,
	single_dual: 'Dual',
	control_side: 'Right',
	direction_facing: 'Unknown',
	mount_type: 'Inside',
	room_name: 'r 1',
	floor: 1,
	window_jamb_depth: 5,
	side_channels: 'None',
	shade_name: 'Shade Card Testing - Dual Roll Fascia',
	quick_shade: true,
	header_type: 'RC4039-A',
	end_caps: 'Fascia End Caps',
	hardware_color: null,
	errors: null,
	error_messages: null,
	msrp: 1124.48,
	shipping_price: 20,
	cost: 223.49,
	will_be_seamed: 0,
	has_sag_warning: '',
	hidden_shipping: 80,
	last_time_priced: 1694131200,
};

export const SingleCoupledOpenRoll: AssemblyShadePayload = {
	id: 866364,
	indoor_outdoor: 'indoor',
	shades: [
		{
			row_coordinate: 0,
			column_coordinate: 0,
			shade_assembly_id: 866364,
			fabric_name: 'Screen Essential 3001 Ebony',
			hembar_type: 'internal_sealed',
			roll_direction: 'standard',
			width: 30,
			height: 50,
			motor_type: 'low_voltage',
			shade_type: 'motorized',
			lv_power_source: 'wall_charger',
			manual_chain: null,
			manual_chain_color: null,
			bearing_pin_type: 'heavy_duty_ball_bearing',
			motor: 'coupled',
			tube_sku: 'RTU-42',
			roll_diameter: 2.19356,
			clearance_diameter: 2.19474,
			deflection: 0.00118,
			torque_needed: 0.49056,
			tube: null,
			tube_name: 'Roller blind tube with tape aluminium 42mm 580cm',
		},
		{
			row_coordinate: 0,
			column_coordinate: 1,
			shade_assembly_id: 866364,
			fabric_name: 'Screen Essential 3001 Ebony',
			hembar_type: 'internal_sealed',
			roll_direction: 'standard',
			width: 30,
			height: 50,
			motor_type: 'low_voltage',
			shade_type: 'motorized',
			lv_power_source: 'wall_charger',
			manual_chain: null,
			manual_chain_color: null,
			bearing_pin_type: 'driven_coupler',
			motor: 'coupled',
			tube_sku: 'RTU-42',
			roll_diameter: 2.19356,
			clearance_diameter: 2.19474,
			deflection: 0.00118,
			torque_needed: 0.49056,
			tube: null,
			tube_name: 'Roller blind tube with tape aluminium 42mm 580cm',
		},
		{
			row_coordinate: 0,
			column_coordinate: 2,
			shade_assembly_id: 866364,
			fabric_name: 'Screen Essential 3001 Ebony',
			hembar_type: 'internal_sealed',
			roll_direction: 'standard',
			width: 30,
			height: 50,
			motor_type: 'low_voltage',
			shade_type: 'motorized',
			lv_power_source: 'wall_charger',
			manual_chain: null,
			manual_chain_color: null,
			bearing_pin_type: 'driven_coupler',
			motor: '25mm Micro USB Li-Ion Motor 2022',
			tube_sku: 'RTU-42',
			roll_diameter: 2.19356,
			clearance_diameter: 2.19474,
			deflection: 0.00118,
			torque_needed: 0.49056,
			tube: null,
			tube_name: 'Roller blind tube with tape aluminium 42mm 580cm',
		},
	],
	parts: {
		actual_parts: [],
		full_parts: [],
		part_categories: {
			is_channels_only: [],
			is_crown_and_drive_only: [],
			is_hardware_kit_only: [],
			is_header_only: [],
			is_motor_only: [],
			is_tube_and_fabric_only: []
		}
	},
	quote_id: 0,
	unlocked: true,
	sequence_id: 5,
	single_dual: 'Single',
	control_side: 'Right',
	direction_facing: 'Unknown',
	mount_type: 'Inside',
	room_name: 'r 1',
	floor: 1,
	window_jamb_depth: 5,
	side_channels: 'None',
	shade_name: 'Shade Card Testing - Single Coupled Open Roll',
	quick_shade: true,
	header_type: 'OR_1234',
	end_caps: 'Plastic End Caps',
	hardware_color: 'Black',
	errors: null,
	error_messages: null,
	msrp: 1289.79,
	shipping_price: 30,
	cost: 356.95,
	will_be_seamed: 0,
	has_sag_warning: '',
	hidden_shipping: 120,
	last_time_priced: 1694131200,
	is_coupled: true,
	band_count: 3,
	total_width: 90,
};

export const DualCoupledFascia: AssemblyShadePayload = {
	id: 866364,
	indoor_outdoor: 'indoor',
	shades: [
		{
			row_coordinate: 0,
			column_coordinate: 0,
			shade_assembly_id: 866364,
			fabric_name: 'Screen Essential 3001 Ebony',
			hembar_type: 'internal_sealed',
			roll_direction: 'standard',
			width: 30,
			height: 50,
			motor_type: 'low_voltage',
			shade_type: 'motorized',
			lv_power_source: 'wall_charger',
			manual_chain: null,
			manual_chain_color: null,
			bearing_pin_type: 'heavy_duty_ball_bearing',
			motor: 'coupled',
			tube_sku: 'RTU-42',
			roll_diameter: 2.19356,
			clearance_diameter: 2.19474,
			deflection: 0.00118,
			torque_needed: 0.49056,
			tube: null,
			tube_name: 'Roller blind tube with tape aluminium 42mm 580cm',
			single_dual: 'Dual',
		},
		{
			row_coordinate: 1,
			column_coordinate: 0,
			shade_assembly_id: 866364,
			fabric_name: 'Screen Essential 3001 Ebony',
			hembar_type: 'internal_sealed',
			roll_direction: 'standard',
			width: 30,
			height: 50,
			motor_type: 'low_voltage',
			shade_type: 'motorized',
			lv_power_source: 'wall_charger',
			manual_chain: null,
			manual_chain_color: null,
			bearing_pin_type: 'heavy_duty_ball_bearing',
			motor: 'coupled',
			tube_sku: 'RTU-42',
			roll_diameter: 2.19356,
			clearance_diameter: 2.19474,
			deflection: 0.00118,
			torque_needed: 0.49056,
			tube: null,
			tube_name: 'Roller blind tube with tape aluminium 42mm 580cm',
		},
		{
			row_coordinate: 0,
			column_coordinate: 1,
			shade_assembly_id: 866364,
			fabric_name: 'Screen Essential 3001 Ebony',
			hembar_type: 'internal_sealed',
			roll_direction: 'standard',
			width: 30,
			height: 50,
			motor_type: 'low_voltage',
			shade_type: 'motorized',
			lv_power_source: 'wall_charger',
			manual_chain: null,
			manual_chain_color: null,
			bearing_pin_type: 'driven_coupler',
			motor: 'coupled',
			tube_sku: 'RTU-42',
			roll_diameter: 2.19356,
			clearance_diameter: 2.19474,
			deflection: 0.00118,
			torque_needed: 0.49056,
			tube: null,
			tube_name: 'Roller blind tube with tape aluminium 42mm 580cm',
			single_dual: 'Dual',
		},
		{
			row_coordinate: 1,
			column_coordinate: 1,
			shade_assembly_id: 866364,
			fabric_name: 'Screen Essential 3001 Ebony',
			hembar_type: 'internal_sealed',
			roll_direction: 'standard',
			width: 30,
			height: 50,
			motor_type: 'low_voltage',
			shade_type: 'motorized',
			lv_power_source: 'wall_charger',
			manual_chain: null,
			manual_chain_color: null,
			bearing_pin_type: 'driven_coupler',
			motor: 'coupled',
			tube_sku: 'RTU-42',
			roll_diameter: 2.19356,
			clearance_diameter: 2.19474,
			deflection: 0.00118,
			torque_needed: 0.49056,
			tube: null,
			tube_name: 'Roller blind tube with tape aluminium 42mm 580cm',
		},
		{
			row_coordinate: 0,
			column_coordinate: 2,
			shade_assembly_id: 866364,
			fabric_name: 'Screen Essential 3001 Ebony',
			hembar_type: 'internal_sealed',
			roll_direction: 'standard',
			width: 30,
			height: 50,
			motor_type: 'low_voltage',
			shade_type: 'motorized',
			lv_power_source: 'wall_charger',
			manual_chain: null,
			manual_chain_color: null,
			bearing_pin_type: 'driven_coupler',
			motor: '25mm Micro USB Li-Ion Motor 2022',
			tube_sku: 'RTU-42',
			roll_diameter: 2.19356,
			clearance_diameter: 2.19474,
			deflection: 0.00118,
			torque_needed: 0.49056,
			tube: null,
			tube_name: 'Roller blind tube with tape aluminium 42mm 580cm',
			single_dual: 'Dual',
		},
		{
			row_coordinate: 1,
			column_coordinate: 2,
			shade_assembly_id: 866364,
			fabric_name: 'Screen Essential 3001 Ebony',
			hembar_type: 'internal_sealed',
			roll_direction: 'standard',
			width: 30,
			height: 50,
			motor_type: 'low_voltage',
			shade_type: 'motorized',
			lv_power_source: 'wall_charger',
			manual_chain: null,
			manual_chain_color: null,
			bearing_pin_type: 'driven_coupler',
			motor: '25mm Micro USB Li-Ion Motor 2022',
			tube_sku: 'RTU-42',
			roll_diameter: 2.19356,
			clearance_diameter: 2.19474,
			deflection: 0.00118,
			torque_needed: 0.49056,
			tube: null,
			tube_name: 'Roller blind tube with tape aluminium 42mm 580cm',
		},
	],
	parts: {
		actual_parts: [],
		full_parts: [],
		part_categories: {
			is_channels_only: [],
			is_crown_and_drive_only: [],
			is_hardware_kit_only: [],
			is_header_only: [],
			is_motor_only: [],
			is_tube_and_fabric_only: []
		}
	},
	quote_id: 0,
	unlocked: true,
	sequence_id: 5,
	single_dual: 'Dual',
	control_side: 'Right',
	direction_facing: 'Unknown',
	mount_type: 'Inside',
	room_name: 'r 1',
	floor: 1,
	window_jamb_depth: 5,
	side_channels: 'None',
	shade_name: 'Shade Card Testing - Dual Coupled Fascia',
	quick_shade: true,
	header_type: 'RC4039-A',
	end_caps: 'Plastic End Caps',
	hardware_color: null,
	errors: null,
	error_messages: null,
	msrp: 2905.34,
	shipping_price: 60,
	cost: 768.27,
	will_be_seamed: 0,
	has_sag_warning: '',
	hidden_shipping: 240,
	last_time_priced: 1694131200,
	is_coupled: true,
	band_count: 3,
	total_width: 90,
	fascia_split: 'single_piece',
};

export const DummyIndoorShadeFactory = (
	options: Partial<AssemblyShadePayload>
): AssemblyShadePayload => {
	const randomId = Date.now().toString(36) + Math.random().toString(36).substring(2, 8);
	const defaults: AssemblyShadePayload = {
		id: Number(randomId),
		indoor_outdoor: 'indoor',
		shades: [
			{
				row_coordinate: 0,
				column_coordinate: 0,
				shade_assembly_id: Number(randomId),
				fabric_name: 'Screen Essential 3001 Ebony',
				hembar_type: 'internal_sealed',
				roll_direction: 'standard',
				width: 30,
				height: 50,
				motor_type: 'low_voltage',
				shade_type: 'motorized',
				lv_power_source: 'wall_charger',
				manual_chain: null,
				manual_chain_color: null,
				bearing_pin_type: 'heavy_duty_ball_bearing',
				motor: '15mm Micro USB Li-Ion Motor 2022',
				tube_sku: 'RTU-42',
				roll_diameter: 2.19356,
				clearance_diameter: 2.19474,
				deflection: 0.00118,
				torque_needed: 0.16352,
				tube: null,
				tube_name: 'Roller blind tube with tape aluminium 42mm 580cm',
			}
		],
		parts: {
			actual_parts: [],
			full_parts: [],
			part_categories: {
				is_channels_only: [],
				is_crown_and_drive_only: [],
				is_hardware_kit_only: [],
				is_header_only: [],
				is_motor_only: [],
				is_tube_and_fabric_only: []
			}
		},
		quote_id: 0,
		unlocked: false,
		sequence_id: 1,
		single_dual: 'Single',
		control_side: 'Right',
		direction_facing: 'Unknown',
		mount_type: 'Inside',
		room_name: 'r 1',
		floor: 1,
		window_jamb_depth: 5,
		side_channels: 'None',
		shade_name: `Dummy Indoor Shade ${randomId}`,
		quick_shade: true,
		header_type: 'RC3080-A',
		end_caps: 'Fascia End Caps',
		hardware_color: null,
		errors: null,
		error_messages: null,
		msrp: 0,
		shipping_price: 0,
		cost: 0,
		will_be_seamed: 0,
		has_sag_warning: '',
		hidden_shipping: 0,
		last_time_priced: 0,
	};

	const actualShades = options.shades || defaults.shades;

	const merged = {
		...defaults,
		...options,
		shades: actualShades,
	};

	return merged;
};

export const IndoorShadeDefaults: AssemblyShadePayload = {
	id: 0,
	indoor_outdoor: 'indoor',
	shades: [
		{
			row_coordinate: 0,
			column_coordinate: 0,
			shade_assembly_id: 0,
			fabric_name: '',
			hembar_type: '',
			roll_direction: '',
			width: 0,
			height: 0,
			motor_type: '',
			shade_type: '',
			lv_power_source: '',
			manual_chain: null,
			manual_chain_color: null,
			bearing_pin_type: '',
			motor: '',
			tube_sku: '',
			roll_diameter: 0,
			clearance_diameter: 0,
			deflection: 0,
			torque_needed: 0,
			tube: null,
			tube_name: '',
		}
	],
	parts: {
		full_parts: [],
		actual_parts: [],
		part_categories: {
			is_channels_only: [],
			is_crown_and_drive_only: [],
			is_hardware_kit_only: [],
			is_header_only: [],
			is_motor_only: [],
			is_tube_and_fabric_only: []
		}
	},
	quote_id: 0,
	unlocked: false,
	sequence_id: 0,
	single_dual: '',
	control_side: '',
	direction_facing: 'Unknown',
	mount_type: '',
	room_name: '',
	floor: 0,
	window_jamb_depth: 0,
	side_channels: '',
	shade_name: '',
	header_type: '',
	end_caps: '',
	hardware_color: null,
	errors: null,
	error_messages: null,
	msrp: 0,
	shipping_price: 0,
	cost: 0,
	will_be_seamed: 0,
	has_sag_warning: '',
	hidden_shipping: 0,
	last_time_priced: 0,
};
