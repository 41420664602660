import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import TextDivider from "../../Parts/TextDivider";
import api from "../../PowerShadesAPIFunctions";
import Button from "../../Parts/Button";
import { useTerritories } from "../../Store/entities/hooks";
import { UseMultipliersByQuoteId, useQuoteIdAndDispatch } from "../../Store/entities/quotes/hooks";
import apiCalls from "../../PowerShadesAPIFunctions";
import { useAssembliesByQuoteId } from "../../Store/entities/assemblies/hooks";

const AdminPaymentDetails = (props) => {
	const { newQuote: initialQuote, close, dealers } = props;

	const territories = useTerritories();

	const quoteSub = initialQuote.QuoteSubstitution;
	const quoteMsrp = initialQuote.buy();
	const quoteNoMultipler = initialQuote.msrpTotal();
	const shadeCount = quoteSub.shades.length;
	const [processing, setProcessing] = useState(false);
	const [fetchingMultiplier, setFetchingMultiplier] = useState(true);
	const [defaultMultiplier, _setDefaultMultiplier] = useState(0.5);
	const [success, setSuccess] = useState(false);

	const parentQuoteType = props.quoteType;

	const [adminPaymentDetails, setAdminPaymentDetails] = useState({});
	const [quoteType, setQuoteType] = useState("");

	const defaultObj = useRef({});

	const _salePrice = quoteNoMultipler.pre_shipping * defaultMultiplier;
	
	const newQuote = useQuoteIdAndDispatch(initialQuote.quoteId);
	
	const quoteMult = UseMultipliersByQuoteId(initialQuote.quoteId);
	const currentMultiplier = quoteMult.list.find((mult) => mult.name === "buy")?.multiplier 
						   ?? quoteMult.list.find((mult) => mult.name === "my price")?.multiplier
						   ?? quoteMult.list.find((mult) => mult.name === "Dealer Price")?.multiplier
						   ?? 0.5;
	const [quotePricing, setQuotePricing] = useState();
	
	useEffect(() => {
		const fetchQuotePricing = async () => {
			try {
				const res = await apiCalls.getQuotePricing(initialQuote.quoteId);
				const qp = res.data.pricing;
				setQuotePricing(qp);
			} catch (error) {
				console.error("Error fetching quote pricing: ", error);
			}
		};

		fetchQuotePricing();
	}, [initialQuote]);

	defaultObj.current = {
		quote_id: initialQuote.Id,
		tax_percentage: quoteSub.taxRate == 0 ? 0 : quoteSub.taxRate,
		tax_percentage_string:
			quoteSub.taxRate == 0 ? "0" : (quoteSub.taxRate * 100).toString(),
		sale_price: _salePrice.toFixed(2),
		sale_price_string: _salePrice
			.toFixed(2)
			.toString(),
		shipping_price: quoteMsrp.shipping.total_shipping.toFixed(2),
		shipping_price_string: quoteMsrp.shipping.total_shipping
			.toFixed(2)
			.toString(),
		tax_shipping: quoteSub.taxShipping,
		installation_cost: null,
		installation_cost_string: "0.0",
		installation_single_string: "0.0",
		misc_cost: null,
		misc_cost_string: "0.0",
		final_price: null,
		final_price_string: "0.0",
		credit_card: null,
		credit_card_string: "0.0",
		multiplier: defaultMultiplier !== 0 ? defaultMultiplier : 0.5,
		multiplier_string:
			defaultMultiplier !== 0 ? defaultMultiplier.toString() : "0.5",
	};

	const [defaults, setDefaults] = useState(defaultObj.current);

	const setDefault = () => {
		setAdminPaymentDetails(defaults);
	};

	const setDefaultMultiplier = (multiplier) => {
		_setDefaultMultiplier(multiplier);
		setDefault();
	};

	const quoteTypes = [
		{
			display: "RMA",
			value: "rma",
		},
		{
			display: "Rework",
			value: "rework",
		},
		{
			display: "Regular Quote",
			value: "",
		},
	];

	Number.prototype.toFixedNumber = function (digits, base) {
		const pow = (base || 10) ** digits;
		return Math.round(this * pow) / pow;
	};

	const { dealerId } = initialQuote;

	const dealer = dealerId ? dealers.find((d) => d.id == dealerId) : null;

	const distributorId = dealer?.owner_distributor_id ?? null;

	const territory = territories.find((t) => t.id == initialQuote.territoryId);

	const is_bidspec_default = territory.name == "Internal"
		&& dealer
		&& dealer.name == "PowerShades Home Integration";

	const adminDetRef = useRef({});

	adminDetRef.current = adminPaymentDetails;

	const patchPayment = (items) => {
		setAdminPaymentDetails({ ...adminDetRef.current, ...items });
	};

	const patchTax = (taxString) => {
		const patch = taxString;

		const tax = parseFloat(taxString.tax_percentage_string).toFixedNumber(5);

		if (tax.toString() === taxString.tax_percentage_string) {
			patch.tax_percentage = tax / 100;
		}
		patchPayment(patch);
	};

	const patchRetainerPercentage = (retainerString) => {
		const patch = retainerString;

		const retainerPercentage = parseFloat(retainerString.retainer_string).toFixedNumber(5);

		if (retainerPercentage.toString() === retainerString.retainer_string) {
			patch.retainer_percentage = retainerPercentage / 100;
		}
		patchPayment(patch);
	};

	const patchMultiplier = (multiplierString) => {
		const patch = multiplierString;

		const multiplier = parseFloat(multiplierString.multiplier_string).toFixedNumber(4);

		if (multiplier.toString() === multiplierString.multiplier_string) {
			patch.multiplier = multiplier;
			patch.sale_price = (
				quoteNoMultipler.pre_shipping * multiplier
			).toFixedNumber(2);
			patch.sale_price_string = patch.sale_price.toString();
		}
		patchPayment(patch);
	};

	const patchGeneral = (key, value) => {
		const patch = {};
		if (value === null) {
			patch[`${key}`] = value;
			patchPayment(patch);
			return;
		}
		value = value.replace(",", "");

		const num = parseFloat(value).toFixedNumber(2);

		patch[`${key}_string`] = value;

		if (num.toString() === value) {
			patch[key] = num;
		}

		patchPayment(patch);
	};

	const patchInstallation = (value, isSingle) => {
		const patch = {};

		const num = parseFloat(value).toFixedNumber(2);

		if (isNaN(num)) {
			if (isSingle) {
				patch.installation_single_string = value;
			} else {
				patch.installation_cost_string = value;
			}
		} else if (isSingle) {
			patch.installation_single_string = value;
			patch.installation_cost_string = (num * shadeCount).toString();
			patch.installation_cost = num * shadeCount;
		} else {
			patch.installation_cost_string = value;
			patch.installation_single_string = (num / shadeCount).toString();
			patch.installation_cost = num;
		}

		patchPayment(patch);
	};

	const {
		quote_id,
		tax_percentage,
		tax_percentage_string,
		sale_price,
		sale_price_string,
		shipping_price,
		shipping_price_string,
		tax_shipping,
		installation_cost,
		installation_cost_string,
		installation_single_string,
		misc_cost,
		misc_cost_string,
		credit_card_string,
		credit_card,
		final_price,
		final_price_string,
		multiplier,
		multiplier_string,
		retainer_string,
		retainer_percentage,
		is_bidspec,
	} = adminPaymentDetails;

	const totalPrice = (function () {
		const {
			tax_percentage,
			sale_price,
			shipping_price,
			installation_cost,
			misc_cost,
			tax_shipping,
			multiplier,
			credit_card
		} = adminPaymentDetails;

		let total = 0;

		total += sale_price * (1 + (tax_percentage ?? 0.0));
		total
			+= shipping_price * (1 + (tax_shipping ? tax_percentage ?? 0.0 : 0));
		total += installation_cost + misc_cost + (credit_card ?? 0.0);

		return total;
	})(adminPaymentDetails);

	const patchCC = (needsCreditCard) => {
		const newAmount = Number((totalPrice * 0.03).toFixed(2));

		patchPayment({
			credit_card: needsCreditCard ? newAmount : null,
			credit_card_string: needsCreditCard ? newAmount.toString() : "0.0",
		});
	};
	useEffect(() => {
		setFetchingMultiplier(true);
		if (distributorId !== null) {
			api.getDistributor(parseInt(distributorId)).then((resp) => {
				if (resp.data?.distributor?.sales_type === "direct") {
					defaults.multiplier = resp.data.distributor.pricing_multiplier;
					defaults.multiplier_string = resp.data.distributor.pricing_multiplier.toString();
					setDefaultMultiplier(resp.data.distributor.pricing_multiplier);
					patchMultiplier({
						multiplier_string: resp.data.distributor.pricing_multiplier?.toString() ?? '0.5',
					});
					patchPayment({
						sale_price: (
							quoteNoMultipler.pre_shipping * resp.data.distributor.pricing_multiplier
						).toFixedNumber(2),
						sale_price_string: (
							quoteNoMultipler.pre_shipping * resp.data.distributor.pricing_multiplier
						)
							.toFixedNumber(2)
							.toString()
					});
					setFetchingMultiplier(false);
				} else {
					defaults.multiplier = quoteMsrp.multiplier.multiplier;
					defaults.multiplier_string = quoteMsrp.multiplier.multiplier.toString();
					setDefaultMultiplier(quoteMsrp.multiplier.multiplier);
					patchMultiplier({
						multiplier_string: quoteMsrp.multiplier.multiplier?.toString() ?? '0.5',
					});
					patchPayment({
						sale_price: (
							quoteNoMultipler.pre_shipping * resp.data.distributor.pricing_multiplier
						).toFixedNumber(2),
						sale_price_string: (
							quoteNoMultipler.pre_shipping * resp.data.distributor.pricing_multiplier
						)
							.toFixedNumber(2)
							.toString()
					});
					setFetchingMultiplier(false);
				}
			});
			setDefault();
		} else {
			setDefaultMultiplier(quoteMsrp.multiplier.multiplier);
			defaults.multiplier = quoteMsrp.multiplier.multiplier;
			defaults.multiplier_string = quoteMsrp.multiplier.multiplier.toString();
			defaults.sale_price = quoteNoMultipler.pre_shipping * quoteMsrp.multiplier.multiplier;
			defaults.sale_price_string = defaults.sale_price.toString();
			setDefault();
			setFetchingMultiplier(false);
		}

		api.needsCreditCard(initialQuote.Id).then((resp) => {
			const idk = resp;

			console.log(idk);

			const isInNeedOfCreditCard = resp?.data?.is_in_need_of_cc ?? false;

			if (isInNeedOfCreditCard) {
				patchCC(isInNeedOfCreditCard);
			}
		});
	}, []);

	useEffect(() => {
		if (quotePricing) {
			const patch = {
				tax_percentage: quotePricing.tax_rate,
				tax_percentage_string: JSON.stringify(quotePricing.tax_rate),
				sale_price: quotePricing.subtotal * currentMultiplier,
				sale_price_string: JSON.stringify(quotePricing.subtotal * currentMultiplier),
				shipping_price: quotePricing.parcel_shipping,
				shipping_price_string: JSON.stringify(quotePricing.parcel_shipping),
				tax_shipping: quotePricing.is_shipping_taxed,
				multiplier: currentMultiplier,
				multiplier_string: JSON.stringify(currentMultiplier),
			};
			setDefaults({ ...defaultObj.current, ...patch });
			patchPayment(patch);
		}
	}, [quotePricing]);

	const quoteAssemblies = useAssembliesByQuoteId(newQuote.id);

	const isRMACediaAndHasNoPreviousQuoteId = (quoteType === "rework" || quoteType === "rma" || quoteType === "cedia")
	&& quoteAssemblies.length > 0
	&& !((quote?.previous_quote_id ?? 0) > 0);

	const submitAdminPayment = () => {
		// if (adminPaymentDetails.is_bidspec && adminPaymentDetails.retainer_string.trim() == '') {
		//     props.ROOT.alert({
		//         title: "Enter a retiner percentage.",
		//         text: "Since this is a bidspec order, please enter a retainer percentage so Zoho knows how much to make the retainer invoice."
		//     })

		//     return;
		// }

		const data = { ...adminPaymentDetails };

		data.misc_cost = adminPaymentDetails.misc_cost ?? 0.0;
		data.final_price = adminPaymentDetails.final_price ?? totalPrice;
		data.installation_cost = adminPaymentDetails.installation_cost ?? 0.0;
		data.total_price = totalPrice;
		data.tax_percentage === null ? (data.tax_percentage = 0.0) : null;

		const {
			sale_price: SalePrice,
			shipping_price: ShippingPrice,
			tax_shipping: TaxShipping,
			tax_percentage: TaxPercentage,
			installation_cost: InstallationCost,
			misc_cost: MiscCost,
			total_price: TotalPrice,
			final_price: FinalPrice,
			sale_price: Multiplier,
			credit_card: CreditCardPercentage,
		} = data;

		const quoteId = initialQuote.Id;

		api.submitOrderPressed(quoteId);

		api.submitAdminPayment(
			quoteId,
			SalePrice,
			ShippingPrice,
			TaxShipping,
			TaxPercentage,
			InstallationCost,
			MiscCost,
			TotalPrice,
			FinalPrice,
			Multiplier,
			CreditCardPercentage ?? 0
		).then((resp) => {
			if (isRMACediaAndHasNoPreviousQuoteId) {
				props.ROOT.alert({
					text: 'Please enter the previous quote id (The PS#) in Advanced Settings to place this RMA/Rework/CEDIA',
					title: 'No Previous Quote Id',
					quickFormat: 'error',
				})
					.then(() => { })
					.catch(() => { });
			} else if (resp?.data?.success === true) {
				setSuccess(true);
				props.ROOT.alert({
					title: "Thanks for your order!",
					icon: "confetti",
					smallIcon: false,
					text: "Your order has been placed successfully!",
					noCancelButton: true,
					acceptButtonText: "Okay"
				}).then(() => window.location.reload());
			} else {
				props.ROOT.alert({
					title: "Order Process Failed",
					text: resp?.data?.errorMessage || resp?.data?.error_message || "Something Went Wrong.",
					quickFormat: "error"
				});
			}
		})

		// api.makePaymentNew(newQuote.Id, 'powershades_admin', { ...data, 'quote_type': newQuote.QuoteType }).then(resp => {
		//     if (resp.data.error) {
		//         props.ROOT.alert({
		//             title: "Order placement failed",
		//             text: `Order placement failed. Please contact engineering. ${JSON.stringify(resp)} `
		//         })
		//     } else {
		//         //Success
		//         props.done();
		//     }

		// })

		setProcessing(true);
	};

	return !success ? (
		<div style={{ overflow: "hidden auto" }}>
			<Container>
				<Row>
					<Col>
						<h2 className={`text-${parentQuoteType}`} style={{ marginBottom: "0" }}>
							Admin Payment View
						</h2>
					</Col>
				</Row>
			</Container>
			{fetchingMultiplier === true ? (
				<Container className="local-bootstrap" style={{ marginTop: "2em" }}>
					<Row>
						<Col className="text-center">
							<FontAwesomeIcon
								icon={faSpinner}
								spin
								className={`text-${parentQuoteType}`}
								transform="grow-25"
							/>
						</Col>
					</Row>
				</Container>
			) : (
				<Container>
					<Row
						style={{
							display: "flex",
							marginTop: "1em",
							marginBottom: "0.5em",
						}}
					>
						<Col style={{ minWidth: "50%" }}>
							Multiplier
							<input
								style={{
									maxWidth: "3.5em",
									marginLeft: "0.5em",
								}}
								value={multiplier_string}
								type="text"
								onChange={(e) =>
									patchMultiplier({
										multiplier_string: e.target.value,
									})}
							/>
						</Col>
						<Col style={{ minWidth: "50%", textAlign: "right" }}>
							<span>{`MSRP (USD): $${quoteNoMultipler.pre_shipping}`}</span>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button color="gray" onClick={setDefault}>
								Default Values
							</Button>
						</Col>
					</Row>
					{/* <div>
                <div>
                    <label>Is Bidspec</label>
                    <input onChange={(e) => patchPayment({ is_bidspec: e.target.checked })} checked={is_bidspec} type='checkbox'></input>
                </div>
                <div>

                    <label>Retainer Percentage</label>
                    <input onChange={(e) => patchRetainerPercentage({ retainer_string: e.target.value })} value={retainer_string} type='number'></input>
                </div>

            </div> */}
					{/* <div style={{ zIndex: 1000, position: 'relative' }}>{
                "This is not the GC_RETAINAGE. This is the percentage of the final price that will be used to make the initial payment and the total on the Zoho Retainer Invoice. If left blank, or set to 0, there will be no retainer made."
            }</div> */}

					<TextDivider>
						<h3 className={`text-${parentQuoteType}`} style={{ fontWeight: "normal" }}>{`PS${quote_id}`}</h3>
					</TextDivider>
					<Row style={{ display: "flex", marginBottom: "0.5em" }}>
						<Col style={{ minWidth: "50%" }}>
							Sales Price (USD)
							{" "}
							<i
								title="Base sales price modified by the multiplier - does not include shipping or taxes"
								className="fas fa-info-circle"
							/>
							<input
								style={{ maxWidth: "60%" }}
								value={sale_price_string}
								disabled={sale_price === null}
								type="text"
								onChange={(e) =>
									patchGeneral("sale_price", e.target.value)}
							/>
						</Col>
						<Col style={{ minWidth: "50%", display: "flex" }}>
							<Row style={{ minWidth: "50%" }}>
								<Col style={{ minWidth: "50%" }}>
									<span style={{ verticalAlign: "baseline" }}>
										Taxes
									</span>
									<input
										style={{
											marginLeft: "0.5em",
											marginTop: "0",
											marginBottom: "0",
											marginRight: "0",
											verticalAlign: "baseline",
										}}
										checked={tax_percentage !== null}
										onChange={(e) => {
											patchPayment({
												tax_percentage_string: "0",
												tax_percentage: e.target.checked
													? 0.0
													: null,
											});
										}}
										type="checkbox"
									/>
								</Col>
								<Col style={{ minWidth: "50%" }}>
									<input
										style={{ maxWidth: "50%" }}
										disabled={tax_percentage === null}
										value={tax_percentage_string}
										onChange={(e) =>
											patchTax({
												tax_percentage_string: e.target.value,
											})}
										type="text"
									/>
									{" "}
									%
								</Col>
							</Row>
							<Row style={{ minWidth: "50%" }}>
								<Col style={{ minWidth: "50%" }}>Tax (USD)</Col>
								<Col style={{ minWidth: "50%" }}>
									$
									{(tax_percentage * sale_price).toFixedNumber(2)}
								</Col>
							</Row>
						</Col>
					</Row>
					<Row style={{ display: "flex", marginBottom: "0.5em" }}>
						<Col style={{ minWidth: "50%" }}>
							<label>Shipping (USD)</label>
							<input
								checked={shipping_price != 0}
								style={{
									marginLeft: "0.5em",
									marginTop: "0",
									marginBottom: "0",
									marginRight: "0",
									verticalAlign: "baseline",
								}}
								onChange={(e) => {
									patchGeneral(
										"shipping_price",
										e.target.checked
											? quoteMsrp.shipping.total_shipping
												.toFixedNumber(2)
												.toString()
											: "0"
									);
								}}
								type="checkbox"
							/>
							<input
								disabled={shipping_price === 0}
								style={{ maxWidth: "60%" }}
								value={shipping_price_string}
								onChange={(e) => {
									patchGeneral("shipping_price", e.target.value);
								}}
								type="text"
							/>
						</Col>
						<Col style={{ minWidth: "50%" }}>
							Shipping Tax (USD)
							<input
								checked={tax_shipping}
								style={{
									marginLeft: "0.5em",
									marginTop: "0",
									marginBottom: "0",
									marginRight: "0",
									verticalAlign: "baseline",
								}}
								onChange={(e) => {
									patchPayment({ tax_shipping: e.target.checked });
								}}
								type="checkbox"
							/>
							<input
								disabled
								style={{ maxWidth: "70%" }}
								value={
									tax_shipping
										? (
											shipping_price * (tax_percentage ?? 0)
										).toFixedNumber(2)
										: 0.0
								}
								type="text"
							/>
						</Col>
					</Row>
					<Row style={{ display: "flex", marginBottom: "0.5em" }}>
						<Col style={{ minWidth: "50%", maxWidth: "50%" }}>
							Install Cost Per Shade
							<i
								style={{ marginLeft: "0.5em" }}
								title="If we're charging for installation, enter the cost per individual shade to install, in USD"
								className="fas fa-info-circle"
							/>
							<input
								style={{ maxWidth: "60%" }}
								disabled={installation_cost === null}
								type="text"
								value={installation_single_string}
								onChange={(e) =>
									patchInstallation(e.target.value, true)}
							/>
							<input
								checked={installation_cost !== null}
								onChange={(e) => {
									patchPayment({
										installation_cost: e.target.checked ? 0.0 : null,
									});
								}}
								type="checkbox"
							/>
						</Col>
						<Col style={{ minWidth: "50%" }}>
							<Row style={{ minWidth: "100%" }}>
								Total Installation Cost
								{' '}
								(USD)
							</Row>
							<Row style={{ minWidth: "100%" }}>
								$
								{installation_cost_string}
							</Row>
						</Col>
					</Row>
					<Row style={{ display: "flex", minWidth: "100%" }}>
						<Col style={{ minWidth: "50%" }}>
							Misc Costs (USD)
							<i
								style={{ marginLeft: "0.5em" }}
								title="If there are any additional chargers we need to add to the invoice, enter them here"
								className="fas fa-info-circle"
							/>
						</Col>
						<Col style={{ minWidth: "50%" }}>
							Credit Card 3% (USD)
							<i
								style={{ marginLeft: "0.5em" }}
								title="If there are any additional chargers we need to add to the invoice, enter them here"
								className="fas fa-info-circle"
							/>
						</Col>
					</Row>
					<Row
						style={{
							display: "flex", minWidth: "100%", marginBottom: "0.5em"
						}}
					>
						<Col style={{ minWidth: "50%" }}>
							<input
								style={{ maxWidth: "30%" }}
								type="text"
								disabled={misc_cost === null}
								value={misc_cost_string}
								onChange={(e) =>
									patchGeneral("misc_cost", e.target.value)}
							/>
							<input
								checked={misc_cost !== null}
								onChange={(e) => {
									patchPayment({
										misc_cost: e.target.checked ? 0.0 : null,
									});
								}}
								type="checkbox"
							/>
						</Col>
						<Col style={{ minWidth: "50%" }}>
							<input
								style={{ maxWidth: "30%" }}
								type="text"
								disabled
								value={credit_card_string}
								onChange={(e) =>
									patchGeneral("misc_cost", e.target.value)}
							/>
							<input
								checked={credit_card !== null}
								onChange={(e) => {
									patchCC(e.target.checked);
								}}
								type="checkbox"
							/>
						</Col>

					</Row>
					<Row style={{ display: "flex", minWidth: "100%" }}>
						<Col style={{ minWidth: "50%" }}>
							<Row style={{ minWidth: "100%" }}>Final Price (USD)</Row>
							<Row style={{ minWidth: "100%" }}>
								$
								{final_price === null
									? totalPrice.toFixedNumber(2)
									: final_price_string}
							</Row>
						</Col>
						<Col style={{ minWidth: "50%" }}>
							Override Final Price (USD)
							<i
								style={{ marginLeft: "0.5em" }}
								title="Only use this if you absolutely have to - please explain any extra costs in the notes"
								className="fas fa-info-circle"
							/>
							<input
								disabled={final_price === null}
								value={
									final_price === null ? "0.0" : final_price_string
								}
								style={{ maxWidth: "60%" }}
								onChange={(e) =>
									patchGeneral("final_price", e.target.value)}
								type="text"
							/>
							<input
								type="checkbox"
								checked={final_price !== null}
								onChange={(e) =>
									patchGeneral(
										"final_price",
										e.target.checked ? "0" : null
									)}
							/>
						</Col>
					</Row>

					<Row style={{ minWidth: "100%", display: "flex" }}>
						<Col style={{ minWidth: "50%" }}>
							<Button color="gray" onClick={close}>
								Cancel
							</Button>
						</Col>
						<Col style={{ minWidth: "50%", textAlign: "right" }}>
							<Button
								disabled={processing}
								color={parentQuoteType ?? 'green'}
								onClick={submitAdminPayment}
							>
								{processing ? "Processing" : "Submit"}
							</Button>
						</Col>
					</Row>
				</Container>
			)}
		</div>
	) : null;
};

export default AdminPaymentDetails;
