/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

import type { Option } from 'react-bootstrap-typeahead/types/types';

import {
	useDealers,
	useDistributorLocations,
	useDistributors,
	useManufacturers,
	useRepresentatives,
} from '../Store/entities/hooks';

import type { EntitySelectProps } from './types';
import type { Entities, RoleEntities } from '../User/types';

const EntitySelect = ({
	placeholder = "Select an entity",
	disabled = false,
	selectedRoleCategory,
	selectedEntityId,
	setSelectedEntityId,
	setSelectedEntityName,
}: EntitySelectProps) => {
	const [entitySelectOpen, setEntitySelectOpen] = useState(false);

	const dealers = useDealers();
	const dists = useDistributors();
	const distLocs = useDistributorLocations();
	const reps = useRepresentatives();
	const manufacturers = useManufacturers();

	const roleEntities: Partial<RoleEntities> = {
		Dealer: dealers,
		Rep: reps,
		Distributor: dists,
		'Distributor Location': distLocs,
		Manufacturer: manufacturers,
	};
	
	const entities: Entities = roleEntities[selectedRoleCategory ?? ''] || [];

	const entitiesTransformed = entities.map((entity) => ({
		value: entity.id,
		label: entity.name,
	}));

	const handleEntityChange = (selected: Option[]) => {
		const selectedTyped = selected as { value: number, label: string}[];
		const index = selectedEntityId === 0 ? 0 : 1;

		const selectedEntityIdToSave = selectedTyped[index]?.value ?? 0;
		const selectedEntityNameToSave = selectedTyped[index]?.label ?? '';

		setEntitySelectOpen(false);
		setSelectedEntityId(selectedEntityIdToSave);
		setSelectedEntityName(selectedEntityNameToSave);
	};
	
	return (
		<Typeahead
			placeholder={placeholder}
			disabled={disabled}
			id="entity-selector"
			options={entitiesTransformed}
			clearButton
			open={entitySelectOpen}
			onFocus={() => setEntitySelectOpen(true)}
			onBlur={() => setEntitySelectOpen(false)}
			selected={entitiesTransformed.filter((entity) => entity.value === selectedEntityId)}
			onChange={handleEntityChange}
			onInputChange={() => {
				if (!entitySelectOpen) setEntitySelectOpen(true);
			}}
		/>
	);
};

export default EntitySelect;
