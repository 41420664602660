import {  createSelector } from "@reduxjs/toolkit";
import store, { AppState, UsePortalSelector, AppDispatch } from "../..";
import { loadQuoteAssemblies } from ".";
import { loadStatus as loadStatusEnum} from "../entitiesType";
import  { AssemblyStore } from "./types";
import { RefreshQuotePricing } from "../quotes";

const selectAssembliesByQuoteRecord = (QuoteId: number) => (state: AppState) => {
    const assyList = state.entity.assemblies[QuoteId] ?? {
        loadStatus: loadStatusEnum.needsLoaded,
        Items: {}
    };
    const {loadStatus, ...assyListTwo} = assyList ?? {};

    if( loadStatus !== loadStatusEnum.loading && loadStatus !== loadStatusEnum.fullyLoaded) {
        // I don't know why the type system is not catching this as a AppDispatch
        const dispatch = store.dispatch as AppDispatch;
        dispatch(loadQuoteAssemblies({quoteId: QuoteId, isAdd: false})).then(() => {
            dispatch(RefreshQuotePricing({quoteId: QuoteId}));
        });
        return {} as Record<number,AssemblyStore>;
    }

    return assyListTwo.Items as Record<number,AssemblyStore>;
} 

const selectAssemblyListByQuoteId = (quoteId: number) =>createSelector(selectAssembliesByQuoteRecord(quoteId), (assyList) => {
    return Object.keys(assyList)[0] ? Object.values(assyList).sort((a, b) => a.sequence_id - b.sequence_id) : [];
});

const useAssembliesByQuoteId = (QuoteId: number) => UsePortalSelector(selectAssemblyListByQuoteId(QuoteId));

const SelectAssembly = (QuoteId: number, SequenceId: number) => createSelector((selectAssembliesByQuoteRecord(QuoteId)), (assyList) => {
    return assyList[SequenceId] ?? null;
});

const UseAssembly = (QuoteId: number, SequenceId: number) => {
    const assembly = UsePortalSelector(SelectAssembly(QuoteId, SequenceId));

    return assembly;
}



export {
    UseAssembly,
    SelectAssembly,
    selectAssemblyListByQuoteId,
    useAssembliesByQuoteId
};