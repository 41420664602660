import HeadRow from './HeadRow';

const TableComponent = ({
	buttonAdd, rows, columns, quoteType, zIndex, title, UpdateIso, ISO
}) => (rows.length <= 0 ? null : (
	<tbody style={{ zIndex }}>
		<tr className="bold-row">
			<th className={quoteType} colSpan={columns.length}>
				{buttonAdd ? <div className="table-to-right">{buttonAdd}</div> : null}
				<div className={`table-header ${quoteType}`}>{title}</div>
			</th>
		</tr>
		<HeadRow columns={columns} ISO={ISO} UpdateIso={UpdateIso} />
		{rows}
	</tbody>
));

export default TableComponent;
