import React, { useEffect, useState } from 'react';

type FractionFieldProps = {
	value: number;
	disabled: boolean;
	minValue: number;
	maxValue: number;
	isCoupled?: boolean;
	placeHolderLabel?: string;
	varName: string;
	onBlur: (newNumber: number) => void;
	width?: number;
	extraClasses?: string;
};

const FractionField = ({
	value,
	disabled,
	minValue,
	maxValue,
	isCoupled = false,
	placeHolderLabel = "-",
	varName,
	onBlur,
	width,
	extraClasses = "",

}: FractionFieldProps) => {
	const getSeperatedNumbers = (value) => {
		const values = (value ?? '').toString().split('.');
		let wholeValue = 0;
		let fractionValue = 0;

		if (value !== null) {
			wholeValue = typeof Number(value) === 'number' ? parseInt(values[0], 10) : 0;
			fractionValue = parseFloat(`0.${values.length > 1 ? values[1] : '0'}`);
		} else {
			wholeValue = 0;
			fractionValue = 0;
		}

		return [wholeValue, fractionValue];
	};

	const [displayValue, setDisplayValue] = useState(value);
	const [isEditting, setIsEditting] = useState(false);

	const [incomingWholeValue, displayFractionValue = 0] = getSeperatedNumbers(displayValue);
	const [displayWholeValue, setDisplayWholeValue] = useState(`${incomingWholeValue}`);

	useEffect(() => {
		if (!isEditting) {
			setDisplayValue(value);
			const [whole] = getSeperatedNumbers(value);
			setDisplayWholeValue(`${whole}`);
		}
	}, [isEditting, value]);

	const blurred = (newValue) => {
		onBlur(newValue);
	};

	const textInputBlurred = (new_val) => {
		new_val = parseFloat(new_val);

		const min = minValue ?? Number.MIN_VALUE;

		const max = maxValue ?? Number.MAX_VALUE;

		if (new_val < min) {
			new_val = min;
		} else if (new_val > max) {
			new_val = max;
		}

		const [wholeValue = 0, fractionValue = 0] = getSeperatedNumbers(new_val);

		blurred(wholeValue + fractionValue);

		setDisplayValue(fractionValue + wholeValue);
	};

	const fractionChange = (new_val) => {
		textInputBlurred(Number(displayWholeValue) + new_val);
	};

	const fractionOptionsBase = [
		{ value: 0, displayValue: '0' },
		{ value: 0.0625, displayValue: '1/16' },
		{ value: 0.125, displayValue: '1/8' },
		{ value: 0.1875, displayValue: '3/16' },
		{ value: 0.25, displayValue: '1/4' },
		{ value: 0.3125, displayValue: '5/16' },
		{ value: 0.375, displayValue: '3/8' },
		{ value: 0.4375, displayValue: '7/16' },
		{ value: 0.5, displayValue: '1/2' },
		{ value: 0.5625, displayValue: '9/16' },
		{ value: 0.625, displayValue: '5/8' },
		{ value: 0.6875, displayValue: '11/16' },
		{ value: 0.75, displayValue: '3/4' },
		{ value: 0.8125, displayValue: '13/16' },
		{ value: 0.875, displayValue: '7/8' },
		{ value: 0.9375, displayValue: '15/16' },
	];

	const fractionOptions = fractionOptionsBase.map((fo) => {
		if (Math.abs(fo.value - (displayFractionValue ?? 0)) < 0.0006) {
			return {
				...fo,
				value: displayFractionValue
			};
		}

		return fo;
	});

	const options = fractionOptions.map((fo, index) => (
		<option key={index} value={fo.value}>
			{fo.displayValue}
		</option>
	));

	const roundToNearestFraction = (decimal) => {
		const fractions = [0.0625, 0.125, 0.1875, 0.25, 0.3125, 0.375, 0.4375, 0.5, 0.5625, 0.625, 0.6875, 0.75, 0.8125, 0.875, 0.9375, 1];
		let lowerFraction = 0;
		let higherFraction = 1;

		for (let i = 0; i < fractions.length; i++) {
			if (decimal < (fractions[i] ?? 0)) {
				higherFraction = fractions[i] ?? 0;
				lowerFraction = i > 0 ? (fractions[i - 1] ?? 0) : 0;
				break;
			}
		}
		
		const roundedDown = lowerFraction;
		const roundUpThreshold = higherFraction - 0.0006;
		return decimal > roundUpThreshold ? higherFraction : roundedDown;
	};

	const wholeNumberChange = (newWholeNum) => {
		const [wholeNumber = 0, fractionNumber = 0] = getSeperatedNumbers(newWholeNum);

		if (fractionNumber == 0) {
			blurred(wholeNumber + displayFractionValue);
		} else {
			const newFraction = roundToNearestFraction(fractionNumber);
			blurred(wholeNumber + newFraction);
			setDisplayWholeValue(`${wholeNumber}`);
		}
		setIsEditting(false);
	};
	return (
		<div className= {`${extraClasses} fraction-field d-flex justify-content-between align-items-center`} style={{ width: `${width}em`, minWidth: "100%" }}>
			<input
				className="fraction-inner-whole form-control"
				style={{ flex: 1 }}
				onKeyDown={(e) => e.key === "Enter" && blurred(Number(displayWholeValue))}
				disabled={disabled}
				name={varName}
				type="number"
				value={displayWholeValue === "0" ? 0 : displayWholeValue}
				onBlur={() => wholeNumberChange(Number(displayWholeValue))}
				onChange={(e) => {
					setIsEditting(true);
					setDisplayWholeValue(e.target.value);
				}}
				placeholder={placeHolderLabel}
			/>
			{isCoupled ? <br /> : null}
			<select
				className="fraction-inner-select rounded form-select px-2"
				style={{ flex: 1 }}
				disabled={disabled}
				value={displayFractionValue}
				onChange={(e) => {
					fractionChange(parseFloat(e.target.value));
				}}
			>
				<option disabled hidden selected value={0}>-</option>
				{options}
			</select>
		</div>
	);
};

export default FractionField;
