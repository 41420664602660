import React, { Component, useEffect, useState } from 'react';
import { FormSelect } from "react-bootstrap";

const ChannelSelect = (props) => {
	let [channels, setChannels] = useState([]);

	const { channelsOverride } = props;

	if (channelsOverride) {
		channels = channelsOverride;
	}

	useEffect(() => {
		if (!channelsOverride) {
			api.listChannelTypes().then((resp) => {
				setChannels(resp && resp.data && resp.data.channels);
			});
		}
	}, []);

	const options = channels ? channels.map((c) => <option value={c.id} title={c.description}>{c.name}</option>) : null;

	return (
		<>
			{!options || options.length == 0 ? null : (
				<div style={!props.bootstrap ? { flexGrow: "1", maxWidth: "20%" } : null}>
					{props.noLabel ? null : (
						<>
							<label>
								{props.title ? `${props.title} Channel` : "Channel"}
							</label>
							<br />
						</>
					)}
					{props.bootstrap
						? (
							<FormSelect className="full-width" size="sm">
								{options}
							</FormSelect>
						)
						: (
							<select
								value={props.channelId}
								onChange={(e) => props.onChange(parseInt(e.target.value))}
							>
								{options}
							</select>
						)}
						
				</div>
			)}
		</>
	);
};

export default ChannelSelect;
