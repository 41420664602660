/* eslint-disable max-len */
import { createAsyncThunk, createSelector } from "@reduxjs/toolkit";

import apiCalls from "../../../PowerShadesAPIFunctions";
import type { ChannelId, Distributor, DistributorStore } from "../../../powershadesApiTypes";
import type { extraEntityReducersFunction} from "../entitiesType";
import {  loadStatus } from "../entitiesType";
import type { AppState } from "../..";
import { emptyAddress } from "../../../powershadesApiTypeExtensions";
import { captureSentryError, extractErrorMessage, isFailedApiCall } from "../../../psUtil";



const initialDistributorState: () => DistributorStore = () => ({
	pricing_multiplier: 0,
	invite_code: "",
	sales_tax_exempt_status: "",
	sales_tax_exempt_reason: "",
	sales_tax_exempt_certificate_location: "",
	sales_tax_exempt_certificate_number: "",
	federal_tax_id: "",
	federal_tax_certificate_location: "",
	sales_type: "referral",
	channel_id: 1,
	loadStatus: loadStatus.notLoaded,
	id: 0,
	name: "",
	email: "",
	phone: "",
	territory_ids: [],
	dealer_ids: [],
	billing_address: emptyAddress,
	is_credit_cards_allowed: false,
	is_distributor_locations_active: false,
	distribution_emails: [],
	user_ids: [],
	quote_ids: [],
	current_portfolio: ""
});
export { initialDistributorState };

const loadDistributorsMeta = createAsyncThunk<Distributor[], void, { rejectValue: string }>(
	'entities/getDistributorsMeta',
	async (_emptyInput, { rejectWithValue }) => {
		let distributors: Distributor[];

		try {
			const resp = await apiCalls.getDistributorsMeta();

			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			const initDis = initialDistributorState();
			distributors = resp.data.distributors.map((di) => ({
				...initDis,
				...di,
				loadStatus: loadStatus.metaLoaded
			}));
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: 'redux'
				},
				extra: {
					redux_action: 'getDistributorsMeta',
					file_origin: 'src/Store/entities/organizations/distributors.ts'
				}
			});

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}

		return distributors;
	}
);

const loadDistributorFull = createAsyncThunk<Distributor, number, { rejectValue: string }>(
	'entities/getDistributorFull',
	async (distributorId, { rejectWithValue }) => {
		let distributor: Distributor;

		if (distributorId === 0) return rejectWithValue("rejected: default id provided.");


		try {
			const resp = await apiCalls.getDistributor(distributorId);

			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			const initDis = initialDistributorState();
			distributor = {
				...initDis,
				...resp.data.distributor,
			};
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: 'redux'
				},
				extra: {
					redux_action: 'getDistributorFull',
					file_origin: 'src/Store/entities/organizations/distributors.ts'
				}
			});

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}

		return distributor;
	});

// Null if bad. Otherwise, returns the updated dealer
const updateDistributorFull = createAsyncThunk<Distributor | null, Distributor, {
	state: AppState;
	rejectValue: string;
}>(
	'entities/updateDistributorFull',
	async (distributorNew, { rejectWithValue }) => {
		let distributor: Distributor;

		try {
			const resp = await apiCalls
				.updateDistributor(distributorNew.id,
					{ ...distributorNew, channel_id: distributorNew.channel_id as ChannelId }
				);

			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			distributor = resp.data.distributor;
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: 'redux'
				},
				extra: {
					redux_action: 'updateDistributorFull',
					file_origin: 'src/Store/entities/organizations/distributors.ts'
				}
			});

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}

		const newDist: Distributor = {
			...distributorNew,
			...distributor,
		};

		return newDist;
	}
);

const selectDistributorList = createSelector((state: AppState) => state.entity.distributors,
	(distributors) => {
		const updatedDealers = Object.entries(distributors)
			.reduce((list, [key, value]) => {
				if (key === "loadStatus") return list;
				list.push(value as DistributorStore);
				return list;
			}, [] as DistributorStore[])
			.filter(
				(d) => d?.loadStatus !== loadStatus.notLoaded
			);

		return updatedDealers;
	});

const distributorBuilder: extraEntityReducersFunction = (_builder) => {
	_builder.addCase(loadDistributorsMeta.pending, (state) => {
		state.distributors.loadStatus = loadStatus.loading;
		return state;
	});

	_builder.addCase(loadDistributorsMeta.fulfilled, (state, action) => {
		action.payload.forEach((distributor) => {
			state.distributors[distributor.id] = { ...distributor, loadStatus: loadStatus.metaLoaded };
		});
		state.distributors.loadStatus = loadStatus.metaLoaded;
		return state;
	});

	_builder.addCase(loadDistributorFull.pending, (state, action) => {
		state.distributors[action.meta.arg] = {
			...initialDistributorState(),
			id: action.meta.arg,
			...state.distributors[action.meta.arg],
			loadStatus: loadStatus.loading
		};
		return state;
	});

	_builder.addCase(loadDistributorFull.fulfilled, (state, action) => {
		state.distributors[action.payload.id] = {
			...action.payload,
			loadStatus: loadStatus.fullyLoaded
		};
		return state;
	});

	_builder.addCase(updateDistributorFull.fulfilled, (state, action) => {
		if (action.payload) {
			state.distributors[action.payload.id] = {
				...action.payload,
				loadStatus: loadStatus.fullyLoaded
			};
		}
		return state;
	});
};

export { distributorBuilder, loadDistributorsMeta, loadDistributorFull, selectDistributorList, updateDistributorFull };
