import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonDigging } from '@fortawesome/free-solid-svg-icons';

import type { InternalQuoteIconProps } from './types';

const InternalQuoteIcon = ({
	size = '1x', colorOverride = '', className = ''
}: InternalQuoteIconProps) => (
	<FontAwesomeIcon
		icon={faPersonDigging}
		size={size}
		className={`${className} ${colorOverride && `text-${colorOverride}`}`}
	/>
);

export default InternalQuoteIcon;
