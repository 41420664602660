import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { startCase, pickBy } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Sentry from "@sentry/react";

import { useAddUserRole, useDeleteUserRole, useUpdateUserAttribute, useUserById } from '../Store/entities/hooks';
import { usePrompt } from '../Store/hooks';
import { useIsAdmin, useIsDealer } from '../hooks';
import { useModal } from '../Store/ui/hooks';

import { getEntityLinkFromRole } from '../psUtil';
import EntityPageHeader from '../Parts/EntityPageHeader';
import TabPaneWithSearchFilter from '../Parts/TabPaneWithSearchFilter';
import AddUserRoleModal from './AddUserRoleModal';

import type { RejectedActionFromThunk, UserListProps } from './types';
import { UserChangesMade as UserChanges } from './typeExtensions';
import type { TabPaneItem, TabPaneSelections } from '../Parts/types';
import type { AddUserRoleInput, DeleteUserRoleInput, OperationResult, UserAttributes } from '../powershadesApiTypes';
import { addUserRole, deleteUserRole } from '../Store/entities/users';
import { errorResult, successResult, UserRole, UserStore } from '../powershadesApiTypeExtensions';
import AddUserAttributeModal from './AddUserAttributeModal';

const UserView = ({ ROOT }: UserListProps) => {
	const [searchParams,] = useSearchParams();
	const [editing, setEditing] = useState(false);
	const [activeKey, setActiveKey] = useState(0);
	const [searchFilter, setSearchFilter] = useState('');
	const [addUserRoleOpen, setAddUserRoleOpen] = useState(false);
	const [addUserAttributeOpen, setAddUserAttributeOpen] = useState(false);
	const [changesMade, setChangesMade] = useState<UserChanges[]>([]);
	const changesHaveBeenMade = changesMade.length > 0;

	const userIdStr = searchParams.get('user_id') ?? '0';
	const userId = parseInt(userIdStr, 10);
	const { navigate, setTitle } = ROOT;

	const selectedUser = useUserById(userId);
	const userIsAdmin = useIsAdmin();
	const userIsDealer = useIsDealer();
	const modal = useModal();

	usePrompt(modal, changesHaveBeenMade);

	const userLoadingStatus = selectedUser?.loadStatus ?? 0;
	const loading = userLoadingStatus <= 3;

	const [workingUser, setWorkingUser] = useState(selectedUser);

	// const updateUserInfoDispatch = useUpdateUser();
	const addUserRoleDispatch = useAddUserRole();
	const deleteUserRoleDispatch = useDeleteUserRole();
	const updateUserAttribute = useUpdateUserAttribute();

	// useIsPsAdmin only applies to user auth, not entity/users. 
	const userIsPowerShadesEmployee = workingUser?.roles?.some((r1) => r1.role_name === "powershades_admin") ?? false;

	// TODO: convert this to a BE call
	const internalRoles = {
		salesPerson: workingUser?.is_sales_person,
		engineer: workingUser?.is_engineering,
		service: workingUser?.is_service,
		manufacturing: workingUser?.is_manufacturing,
		admin: workingUser?.roles?.some((role) => role.role_name === "powershades_admin"),
		cediaParts: workingUser?.is_allowed_parts_on_cedia,
	};

	const roleAttributeMapping: Record<string, keyof UserAttributes | null> = {
		salesPerson: 'is_sales_person',
		engineer: 'is_engineering',
		service: 'is_service',
		manufacturing: 'is_manufacturing',
		admin: null,  // admin does not have an attribute, accessed via role,
		cediaParts: 'is_allowed_parts_on_cedia',
	};

	// I'm not happy with this as it's very verbose, but makes typescript happy
	const modifiableAttributes: Record<string, keyof UserAttributes> = Object.entries(
		roleAttributeMapping
	)
		.filter((entry): entry is [string, keyof UserAttributes] => entry[1] !== null)
		.reduce((acc, [role, attributeName]) => {
			acc[role] = attributeName;
			return acc;
		}, {} as Record<string, keyof UserAttributes>);
	
	const filteredModifiableAttributes: Record<string, keyof UserAttributes> = Object.entries(modifiableAttributes)
		.filter((entry): entry is [string, keyof UserAttributes] => {
			const value = entry[1];
			const workingUserAttribute = workingUser?.[value];
			return workingUserAttribute !== true; // Keep attributes that are not set to true
		})
		.reduce((acc, [role, attributeName]) => {
			acc[role] = attributeName;
			return acc;
		}, {} as Record<string, keyof UserAttributes>);
	
	/**
	 * Function for handling adding a user role to the working user
	 * @param localUser - user to perform add operation on
	 * @returns void
	 */
	const handleAddWorkingUserRole = (localUser: UserStore) => {
		setChangesMade([...changesMade, UserChanges.AddedRole]);
		setWorkingUser(localUser);
	};

	/**
	 * Function for handling updating user information on the working user
	 * @param localUser - user to perform update operation on
	 * @returns void
	 */
	const handleUpdateUserInformation = (localUser: UserStore) => {
		setChangesMade([...changesMade, UserChanges.UpdatedUserInformation]);
		setWorkingUser(localUser);
	};

	/**
	 * Function for handling deletion of a user role from the working user
	 * @param localUser - user to perform deletion operation on
	 * @returns void
	 */
	const handleDeleteUserRole = (roleToDelete: UserRole) => {
		if (!workingUser) return;
		const { roles } = workingUser;
		const updatedRoles = roles.filter((role) => role.role_id !== roleToDelete.role_id);

		setWorkingUser({ ...workingUser, roles: updatedRoles });
		setChangesMade([...changesMade, UserChanges.DeletedRole]);
	};

	const handleUpdateUserAttribute = (attribute: keyof UserStore, value: string | boolean) => {
		if (!workingUser) return;
		const updatedUser = { ...workingUser, [attribute]: value };
		
		setWorkingUser(updatedUser);
		setChangesMade([...changesMade, UserChanges.UpdatedUserInformation]);
	};

	// ! This code is commented out because we haven't implemented updates yet.
	// const handleDispatchUpdateUser = async (): Promise<OperationResult> => {
	// 	if (!workingUser) throw new Error("User was undefined when trying to update user info");
	// 	const oldUserData = selectedUser;
	// 	const updateUserDto: UpdateUserInput = {
	// 		user_id: workingUser.id,
	// 		user_name: workingUser.name,
	// 		email: workingUser.email,
	// 	};
	// 	const updateResponse = await updateUserInfoDispatch(updateUserDto);
	// 	if (updateUserData.fulfilled.match(updateResponse)) {
	// 		setWorkingUser({ ...workingUser, ...updateResponse.payload });
	// 		return successResult('User information updated successfully');
	// 	}
	// 	Sentry.captureMessage(updateResponse.payload 3.
	// ?? "There was an error updating user information from the user view");
	// 	console.error(updateResponse.payload);
	// 	setWorkingUser(oldUserData);
	// 	return errorResult("There was an error updating user information");
	// };

	/**
	 * Function for handling the dispatch of the add user role action.
	 * @returns A promise containing the operation result.
	 * @remarks This function is what adds the user role to the 
	 * user in the store (and in turn, the back end).
	 */
	const handleDispatchAddUserRole = async (): Promise<OperationResult> => {
		if (!workingUser) throw new Error("User was undefined when trying to add user role");
		const originalRoles = selectedUser?.roles;
		if (!originalRoles) throw new Error("Original roles were undefined when trying to add user role");
		const newRoles = workingUser.roles.filter((role) => !originalRoles?.includes(role));
		const newRole = newRoles[0];
		if (!newRole) throw new Error("New role was undefined when trying to add user role");
		const addUserRoleDto: AddUserRoleInput = {
			entity_id: newRole.entity_id,
			user_id: workingUser.id,
			role_name: newRole.role_name,
			entity_name: newRole.entity_name,
			role_id: newRole.role_id,
		};
		const addUserRoleResponse = await addUserRoleDispatch(addUserRoleDto);
		if (addUserRole.fulfilled.match(addUserRoleResponse)) {
			const workingUserRolesNoDuplicates = workingUser.roles
				.filter((role) => !originalRoles.includes(role));
			setWorkingUser({ ...workingUser, roles: workingUserRolesNoDuplicates });
			return successResult('User role added successfully');
		}
		Sentry.captureMessage(addUserRoleResponse.payload as string ?? "There was an error adding user role from the user view");
		console.error(addUserRoleResponse.payload);
		setWorkingUser({ ...workingUser, roles: originalRoles });
		return errorResult("There was an error adding user role");
	};

	/**
	 * Function for handling the dispatch of the delete user role action.
	 * @returns A promise containing the operation result.
	 * @remarks This function is what deletes the user role to the 
	 * user in the store (and in turn, the back end).
	 */
	const handleDispatchDeleteUserRole = async (): Promise<OperationResult> => {
		if (!workingUser) throw new Error("User was undefined when trying to delete user role");
		const originalRoles = selectedUser?.roles;
		if (!originalRoles) throw new Error("Original roles were undefined when trying to delete user role");
		const deletedRoles = originalRoles?.filter((role) => !workingUser.roles.includes(role));
		if (!deletedRoles) throw new Error("Deleted roles were undefined when trying to delete user role");
		const deletedRole = deletedRoles[0];
		if (!deletedRole) throw new Error("Deleted role was undefined when trying to delete user role");
		const deleteUserRoleDto: DeleteUserRoleInput = {
			user_id: workingUser.id,
			entity_id: deletedRole.entity_id,
			role_id: deletedRole.role_id,
		};
		const deleteUserRoleResponse = await deleteUserRoleDispatch(deleteUserRoleDto);
		if (deleteUserRole.fulfilled.match(deleteUserRoleResponse)) {
			setWorkingUser({
				...workingUser,
				roles: workingUser.roles.filter((role) => role.role_id !== deletedRole.role_id),
			});
			return successResult("User role deleted successfully");
		}
		Sentry.captureMessage(deleteUserRoleResponse.payload ?? "There was an error deleting user role from the user view");
		console.error(deleteUserRoleResponse.payload);
		setWorkingUser({ ...workingUser, roles: originalRoles });
		return errorResult("There was an error deleting user role");
	};

	const getAttributeDifferences = (oldUser: UserStore, newUser: UserStore): (keyof UserAttributes)[] => {
		const differingKeys = pickBy(oldUser, (value, key) => value !== newUser[key]);
  
		return Object.keys(differingKeys) as (keyof UserAttributes)[];
	}

	const handleDispatchUpdateUserAttribute = async (): Promise<OperationResult> => {
		if (!workingUser) throw new Error("User was undefined when trying to update user attribute");
		if (!selectedUser)
			throw new Error("Selected user was undefined when trying to update user attribute");
		const oldUserData = selectedUser;
		const changedAttributes = getAttributeDifferences(oldUserData, workingUser);
		const newAttributeValues = changedAttributes.map((attribute) => ({
			attribute_name: attribute,
			attribute_value: workingUser[attribute]
		}));
		const results = await Promise.allSettled(
			newAttributeValues.map((attribute) =>
				updateUserAttribute({
					user_id: workingUser.id,
					attribute_name: attribute.attribute_name,
					attribute_value: attribute.attribute_value
				})
			)
		);

		const failedUpdates = results
			.filter((result): result is PromiseFulfilledResult<RejectedActionFromThunk>  => result.status === "fulfilled" && result.value.type === 'entities/updateUserAttribute/rejected')
			.map((result, index) => ({
				attribute: newAttributeValues[index],
				reason: result.value.payload
			}));

		if (failedUpdates.length) {
			// Handle failures. You have each failed attribute and its reason in 'failedUpdates'
			console.error("Failed to update attributes:", failedUpdates);
			Sentry.captureMessage("There was an issue updating user attributes from the user view");
			setWorkingUser(oldUserData);
			return errorResult("There were errors updating some attributes");
		}

		// All attributes were updated successfully
		setWorkingUser({ ...workingUser, ...newAttributeValues });
		return successResult("All attributes updated successfully");
	};

	const handleSave = async () => {
		const dispatchTasks: Promise<OperationResult>[] = [];
		const userInfoUpdated = changesMade.includes(UserChanges.UpdatedUserInformation);
		const userRolesAdded = changesMade.includes(UserChanges.AddedRole);
		const userRolesDeleted = changesMade.includes(UserChanges.DeletedRole);

		if (userInfoUpdated) {
			dispatchTasks.push(handleDispatchUpdateUserAttribute());
		}
		if (userRolesDeleted) {
			dispatchTasks.push(handleDispatchDeleteUserRole());
		}
		if (userRolesAdded) {
			dispatchTasks.push(handleDispatchAddUserRole());
		}
		

		const dispatchResults = await Promise.all(dispatchTasks);

		const successMessages = dispatchResults.filter((result) => result.success)
			.map((result) => result.message);
		const errorMessages = dispatchResults.filter((result) => !result.success)
			.map((result) => result.message);

		if (successMessages.length > 0) {
			toast.success(successMessages.join("&#13;"));
		}
		if (errorMessages.length > 0) {
			toast.error(errorMessages.join("&#13;"));
		}
		setChangesMade([]);
		setEditing(false);
	};

	/** 
	 * Function that converts the working user entity into a tab pane selection entity.
	 * @notes Uses organizations to get entities, roles to get... roles, and quotes to get quotes.
	 * @returns TabPaneSelections
	 */
	const getTabSelections = (): TabPaneSelections[] => {
		const workingTabPanes: TabPaneSelections[] = [];
		const rolesTabPane: TabPaneSelections = {
			id: 0,
			title: 'Roles',
			key: 'roles_pane',
			filters: [],
			items: [],
		};

		const attachedEntities: TabPaneSelections = {
			id: 1,
			title: 'Entities',
			key: 'entities_pane',
			filters: [],
			items: [],
		};
		if (!userIsPowerShadesEmployee) {
			workingUser?.roles.forEach((role, index) => {
				const entityPageLink = getEntityLinkFromRole(role, true);
				rolesTabPane.items.push({
					id: `${role.role_name} - ${role.entity_id} - ${index}`,
					type: "role",
					title: userIsDealer ? `${startCase(role.role_name)}` : `${startCase(role.role_name)}${role?.entity_name && ` | ${role.entity_name}`}`,
					name: userIsDealer ? `${startCase(role.role_name)}` : `${startCase(role.role_name)}${role?.entity_name && ` | ${role.entity_name}`}`,
					onClick: () => {
						navigate(entityPageLink);
					},
					onDelete: () => {
						modal({
							title: `Remove ${startCase(role.role_name)}?`,
							text: `Are you sure you want to remove ${startCase(role.role_name)} from ${workingUser?.name}? 
							This will remove the associated entity!`,
							confirmButtonText: 'Remove',
							onConfirm: () => {
								handleDeleteUserRole(role);
							},
							onCancel: () => {
								toast.info('Role deletion canceled');
							}
						});
					}
				});
				attachedEntities.items.push({
					id: `${role.entity_name + role.entity_id}`,
					type: "role",
					title: role.entity_name,
					name: role.entity_name,
					onClick: () => {
						navigate(entityPageLink);
					},
					onDelete: () => {
						modal({
							title: `Remove ${role.entity_name}?`,
							text: `Are you sure you want to remove ${role.entity_name} from ${workingUser?.name}? 
							This will remove the associated user role!`,
							confirmButtonText: 'Remove',
							cancelButtonText: 'Cancel',
							onConfirm: () => {
								handleDeleteUserRole(role);
							},
							onCancel: () => {
								toast.info('Entity deletion canceled');
							}
						});
					}
				});
			});
			if (editing) {
				rolesTabPane.items.unshift({
					id: 'Add',
					type: "role",
					name: 'Add Role...',
					title: 'Add Role...',
					onClick: () => setAddUserRoleOpen(true)
				});
			}
			if (editing) {
				attachedEntities.items.unshift({
					id: 'Add',
					type: "role",
					name: 'Add Entity...',
					title: 'Add Entity...',
					onClick: () => setAddUserRoleOpen(true)
				});
			}
		}

		if(!userIsPowerShadesEmployee) workingTabPanes.push(rolesTabPane);

		if (userIsPowerShadesEmployee) {
			const internalTabRoles: TabPaneSelections = {
				id: 0,
				title: 'Internal Roles',
				key: 'internal_roles_pane',
				filters: [],
				items: [],
			};
			Object.entries(internalRoles).forEach(([role, value]) => {
				if (!value) return;

				const baseItem: TabPaneItem = {
					id: role,
					type: "role",
					title: startCase(role),
					name: startCase(role),
					onClick: () => console.debug(role),
				}

				const attributeName = roleAttributeMapping[role];

				if (role.toLowerCase() === "admin") {
					const onClickAction = {
						onClick: () => editing && modal({
							title: "Unable to Remove",
							text: "You cannot remove a PowerShades admin role from a user. Please contact engineering to remove this role.",
							icon: "error",
							confirmButtonText: "OK",
							showCancelButton: false
						})
					}
					internalTabRoles.items.push({ ...baseItem, ...onClickAction });
					return;
				}
				if (!attributeName) return;

				const deleteItem = {
					onDelete: () => {
						modal({
							title: `Remove ${startCase(role)}?`,
							text: `Are you sure you want to remove ${startCase(role)} from ${workingUser?.name}?`,
							confirmButtonText: 'Remove',
							onConfirm: () => {
								handleUpdateUserAttribute(attributeName, false);
							},
							onCancel: () => {
								toast.info('Internal Role deletion canceled');
							}
						})
					}
				};
				internalTabRoles.items.push({ ...baseItem, ...deleteItem });
			});
			if (editing) {
				internalTabRoles.items.unshift({
					id: 'Add',
					type: "role",
					name: 'Add Internal Role...',
					title: 'Add Internal Role...',
					onClick: () => setAddUserAttributeOpen(true)
				});
			}
			if (internalTabRoles.items.length > 0) workingTabPanes.push(internalTabRoles);
		}

		if (!userIsDealer && !userIsPowerShadesEmployee) {
			workingTabPanes.push(attachedEntities);
		}

		if (workingUser?.quote_ids && workingUser?.quote_ids.length > 0) {
			const quotesTabPane: TabPaneSelections = {
				id: 3,
				title: 'Quotes',
				key: 'quotes_pane',
				filters: [],
				items: [],
			};
			workingUser?.quote_ids.forEach((quoteId) => {
				quotesTabPane.items.push({
					id: quoteId.toString(),
					type: "quote",
					title: `Quote ${quoteId}`,
					name: `Quote ${quoteId}`,
					onClick: () => navigate(`/quote?quote_id=${quoteId}`),
				});
			});
			workingTabPanes.push(quotesTabPane);
		}

		return workingTabPanes;
	};

	/**
	 * Function for handling the resetting of the local user to the selected user.
	 * @remarks This sets the workingUser to the user that is currently stored in the 
	 * redux store.
	 */
	const handleResetUser = () => {
		setWorkingUser(selectedUser);
		setChangesMade([]);
		toast.info("Changes have been canceled");
	};
	
	/**
	 * Function for handling the updating of the working user when the selected user changes.
	 * @returns void
	 */
	useEffect(() => {
		if (!selectedUser) return;
		setWorkingUser(selectedUser);
	}, [selectedUser]);

	/**
	 * Function for handling the updating of the page title when the selected user changes.
	 * @remarks This function updates the page title to reflect the selected user.
	 * @returns void
	 */
	useEffect(() => {
		if (!selectedUser) return;
		setTitle(`View User | ${selectedUser.name}`);
	}, [setTitle, selectedUser]);

	if (userId !== 0 && workingUser) {
		return (
			<Container>
				<AddUserAttributeModal
					open={addUserAttributeOpen}
					onClose={() => setAddUserAttributeOpen(false)}
					setWorkingUser={handleUpdateUserAttribute}
					attributeOptions={filteredModifiableAttributes}
				/>
				<AddUserRoleModal
					open={addUserRoleOpen}
					onClose={() => setAddUserRoleOpen(false)}
					workingUser={workingUser}
					setWorkingUser={handleAddWorkingUserRole}
				/>
				<Card body className="entity-card">
					<Row>
						<Col>
							<EntityPageHeader
								saveEnabled={changesHaveBeenMade}
								loading={loading}
								name={workingUser?.name ?? ''}
								email={workingUser?.email ?? ''}
								editable={editing}
								entityFieldsEditable={false}
								setEditable={setEditing}
								editPermissionsOverride={userIsAdmin}
								onChange={(fields) => {
									const { name, email } = fields;

									if (workingUser.name !== name) {
										handleUpdateUserInformation({
											...workingUser,
											name,
										});
									}
									if (workingUser.email !== email) {
										handleUpdateUserInformation({
											...workingUser,
											email: email ?? "",
										});
									}
								}}
								showUserControls
								selectedUser={workingUser}
								onSave={handleSave}
								onCancel={handleResetUser}
							/>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<TabPaneWithSearchFilter
								disabled={!editing}
								key="user_tab_pane"
								loading={loading}
								selections={getTabSelections()}
								activeKey={activeKey}
								setActiveKey={setActiveKey}
								searchFilter={searchFilter}
								setSearchFilter={setSearchFilter}
							/>
						</Col>
					</Row>
				</Card>
			</Container>
		);
	}
	if (!loading && !selectedUser) {
		throw new Error("Something went wrong trying to load the user data.");
	}
	return (
		<>
		</>
	);
};

export default UserView;
