import React, { useState } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faUpload, faTimes } from "@fortawesome/free-solid-svg-icons";
import { capitalize } from "lodash";
import apiCalls from "../PowerShadesAPIFunctions";
import User from "../User";
import type { FileUploadProps } from "./types";
import DisplayEntityPdf from "../ManageEntities/DisplayEntityPdf";

const FileUpload = ({ onSuccess, onClear, certType, entityType, entityID, disabled = false, inputKey = "", currentFile }: FileUploadProps) => {
	const [file, setFile] = useState<File | null>(null);
	const [error, setError] = useState<string | null>(null);
	const [isHovered, setIsHovered] = useState(false);
	const [uploaded, setUploaded] = useState(false);
	const [shake, setShake] = useState(false);
	const [showFileName, setShowFileName] = useState(true);
	
	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const selectedFile = e.target.files?.[0] || null;
		setShowFileName(true);
		setFile(selectedFile);
		setError(null);
		setIsHovered(false);
		setUploaded(false);
		setShake(false);
	};

	const uploadFile = async (workingFile: File) => {
		const userJwt = User.getToken();
		if (!userJwt) return setError("Authentication error: Missing user token.");

		const formData = new FormData();
		formData.append("avatar", workingFile);
		formData.append("certType", certType);
		formData.append("entityType", entityType);
		formData.append("entityID", entityID || "0");
		formData.append("user_jwt", userJwt);

		try {
			return await apiCalls.uploadToS3(formData);
		} catch {
			return null;
		}
	};

	const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		if (!file) return setError("No file selected.");

		const res = await uploadFile(file);
		if (!res || res.data.error) {
			setError("Failed to upload file.");
			setShake(true);
			setTimeout(() => setShake(false), 500);
			return;
		}

		onSuccess?.(res.data.url);
		setUploaded(true);
	};

	const clearFile = () => {
		onClear && onClear();
		setFile(null);
		setError(null);
		setIsHovered(false);
		setUploaded(false);
		setShake(false);
	};

	const fileName = capitalize(currentFile?.split('/').pop()
		?.replace(/^(Dealer|Contractor|Distributor|Representative)-\d+-/, '')
			|| file?.name
			|| "");
		
	return (
		<Form.Group key={inputKey} controlId={JSON.stringify(inputKey)} className="local-bootstrap">
			<Row className="g-0 py-2">
				<Col xs={12} className="position-relative">
					{!file && !currentFile ? (
						<Form.Label
							className={`btn text-white w-100 ${
								disabled ? "bg-gray" : "bg-body-secondary hover-bg-green border-light-subtle"
							}`}
						>
							<FontAwesomeIcon icon={faFile} className="mb-1 pe-2" />
							Choose File
							<Form.Control
								type="file"
								accept="image/*,.pdf"
								onChange={onChange}
								disabled={disabled}
								hidden
							/>
						</Form.Label>
					) : (
						<div className="d-flex align-items-center gap-2">
							{uploaded || currentFile ? (
								<span
									className={`text-success flex-grow-1 ${
										isHovered ? "text-danger text-decoration-line-through" : ""
									}`}
								>
									<DisplayEntityPdf
										key={`${inputKey}-display`}
										entityType={entityType}
										entityId={+(entityID as string) as any}
										certType={certType}
										fileName={fileName}
										btnClass="w-100"
										onChange={() => setShowFileName(false)}
									/>
								</span>
							) : (
								<Button
									disabled={disabled}
									onClick={onSubmit}
									className={`text-white flex-grow-1 ${
										disabled ? "bg-gray" : "btn-primary"} ${shake ? "shake" : ""
									}`}
								>
									<FontAwesomeIcon icon={faUpload} className="mb-1 pe-2" />
									Upload File
								</Button>
							)}
							<Button
								variant="danger"
								onClick={clearFile}
								onMouseEnter={() => setIsHovered(true)}
								onMouseLeave={() => setIsHovered(false)}
								disabled={disabled}
							>
								<FontAwesomeIcon icon={faTimes} />
							</Button>
						</div>
					)}
				</Col>
			</Row>
			{((file || currentFile) && showFileName) && (
				<Row>
					<Col>
						<span
							className={`text-secondary ${
								isHovered ? "text-danger text-decoration-line-through" : ""
							}`}
						>
							{fileName}
						</span>
					</Col>
				</Row>
			)}
			{error && (
				<Row>
					<Col>
						<span className="text-danger">{error}</span>
					</Col>
				</Row>
			)}
		</Form.Group>
	);
};

export default FileUpload;
