//import { useEffect, useState } from 'react';
import { useEffect} from 'react';

import { createSelector } from 'reselect';
import { loadAddressStates } from '.';
import store, { AppDispatch, AppState, UsePortalSelector } from '..';
import { loadStatus } from './entitiesType';
import { loadBuyingGroups, loadChannels } from './organizations';
import {
	ensureDealerIds,
	loadDealerFull,
	loadDealerTermOptions,
	loadDealersMeta,
	selectDealerByTerritory,
	selectDealerList,
	selectDealerTerms,
	selectPendingDealerCount,
} from './organizations/dealers';
import { loadTerritoriesMeta, loadTerritoryFull, selectTerritoryDict, selectTerritoryList } from './territories';
import {
	initialDistributorState,
	loadDistributorFull,
	loadDistributorsMeta,
	selectDistributorList,
} from './organizations/distributors';
import {
	loadRepresentativesFull,
	loadRepresentativesMeta,
	selectRepresentativeList,
} from './organizations/representatives';
import type { AddressStateStore } from './addressStates';
import { loadDistributorLocationFull, loadDistributorLocationsMeta, selectDistributorLocationList } from "./organizations/distributorLocations";
import { addUserRole, createUser, deleteUser, deleteUserRole, getAccessibleRoles, loadUserFull, loadUsersMeta, updateUserAttribute } from './users';
import { loadManufacturersMeta, selectManufacturerList } from './organizations/manufacturers';
import type { AddUserInput, AddUserRoleInput, DealerStore, DeleteUserInput, DeleteUserRoleInput, } from './../../powershadesApiTypes';
import { SelectFabrics, SelectIndoorFabrics, SelectOutdoorFabrics, SelectPSOutdoorFabrics } from './fabrics';
import { UpdateUserAttributeInput } from './types';
//import apiCalls from '../../PowerShadesAPIFunctions';
//import { FabricViewModel, loadStatusType} from "../../powershadesApiTypes";

import { useDispatch, useSelector } from "react-redux";
import { loadAllowedIndoorFabrics, loadAllowedPsOutdoorFabrics } from "../../Store/entities/fabricsAllowedSlice"; 
import { loadStatusType} from "../../powershadesApiTypes";



export const selectDealersAndDispatch = createSelector(selectDealerList, (dealers) => {
	const { dispatch, getState } = store;

	const state = getState();
	const dealerLoadStatus = state.entity.dealers.loadStatus;

	const typedDispatch = dispatch as AppDispatch;

	const fiveMinutesAgo = new Date((new Date()).getTime() - (5 * 60 * 1000)); // 5 minutes * 60 seconds per mintute * 1000 milliseconds per second

	if (dealerLoadStatus <= loadStatus.needsLoaded || (state.entity.dealers.lastMetaLoadedTime < fiveMinutesAgo.getTime() && dealerLoadStatus === loadStatus.metaLoaded)) {
		typedDispatch(ensureDealerIds()).then(() => {
			dispatch(loadDealersMeta());
			dispatch(loadDealerTermOptions());
		});
	}
	const sortedDealers = dealers.sort((a, b) => a?.name?.localeCompare(b?.name));
	return sortedDealers;
});

export const selectDealerMap = createSelector(
	selectDealersAndDispatch,
	(dealers) => new Map(dealers.map((dealer) => [dealer.id, dealer.name]))
);

const selectTerritoriesAndDispatch = createSelector(selectTerritoryList, (territories) => {
	const { dispatch, getState } = store;

	const state = getState();
	const territoryLoadStatus = state.entity.territories.loadStatus;

	const typedDispatch = dispatch as AppDispatch;

	if (territoryLoadStatus <= loadStatus.needsLoaded) {
		typedDispatch(loadTerritoriesMeta());
	}

	return territories;
});

export const selectDistributorLocationsAndDispatch = createSelector(selectDistributorLocationList, (distributorLocations) => {
	const { dispatch, getState } = store;

	const state = getState();
	const distributorLoadStatus = state.entity.distributorLocations.loadStatus;
	// const userStatus = state.user.authenticated;

	const typedDispatch = dispatch as AppDispatch;

	if (distributorLoadStatus <= loadStatus.needsLoaded) {
		// if ()
		typedDispatch(loadDistributorLocationsMeta());
	}

	return distributorLocations;
});

export const useDealerMap = () => UsePortalSelector(selectDealerMap);

export const selectDistributorsAndDispatch = createSelector(
	selectDistributorList,
	(distributors) => {
		const { dispatch, getState } = store;

		const state = getState();
		const distributorLoadStatus = state.entity.distributors.loadStatus;

		const typedDispatch = dispatch as AppDispatch;

		if (distributorLoadStatus <= loadStatus.needsLoaded) {
			typedDispatch(loadDistributorsMeta());
		}

		const sortedDistributors = distributors.slice().sort((a, b) => a?.name?.localeCompare(b?.name));

		return sortedDistributors;
	}
);

export const selectRepresentativesAndDispatch = createSelector(
	selectRepresentativeList,
	(representatives) => {
		const { dispatch, getState } = store;

		const state = getState();
		const representativeLoadStatus = state.entity.representatives.loadStatus;

		const typedDispatch = dispatch as AppDispatch;

		if (representativeLoadStatus <= loadStatus.needsLoaded) {
			typedDispatch(loadRepresentativesMeta());
		}

		const sortedReps = representatives.slice().sort((a, b) => a?.name?.localeCompare(b?.name));

		return sortedReps;
	}
);

export const selectManufacturersAndDispatch = createSelector(
	selectManufacturerList,
	(manufacturers) => {
		const { dispatch, getState } = store;

		const state = getState();
		const manufacturerLoadStatus = state.entity.manufacturers.loadStatus;

		const typedDispatch = dispatch as AppDispatch;

		if (manufacturerLoadStatus <= loadStatus.needsLoaded) {
			typedDispatch(loadManufacturersMeta());
		}

		const sortedManufactureres = manufacturers
			.slice()
			.sort((a, b) => a?.name?.localeCompare(b?.name));

		return sortedManufactureres;
	}
);

export const useDealers = () => UsePortalSelector(selectDealersAndDispatch);

export const usePendingDealerCount = () => UsePortalSelector(selectPendingDealerCount)

export const useDealersLoadStatus = () =>
	UsePortalSelector((state) => state.entity.dealers.loadStatus);

export const useDealersLastLoadedTime = () =>
	UsePortalSelector((state) => state.entity.dealers.lastMetaLoadedTime);


export const useTerritoriesLoadStatus = () =>
	UsePortalSelector((state) => state.entity.territories.loadStatus);

export const useManufacturersLoadStatus = () =>
	UsePortalSelector((state) => state.entity.manufacturers.loadStatus);

export const selectChannelsAndDispatch = createSelector(
	(state: AppState) => state.entity.channels,
	(channels) => {
		const { dispatch, getState } = store;

		const state = getState();
		const organizationsLoadStatus = state.entity.channels.loadStatus;

		if (organizationsLoadStatus <= loadStatus.needsLoaded) {
			dispatch(loadChannels());
		}

		return channels;
	}
);

export const useChannels = () => UsePortalSelector(selectChannelsAndDispatch);

export const selectBuyingGroupsAndDispatch = createSelector(
	(state: AppState) => state.entity.buyingGroups,
	(buyingGroups) => {
		const { loadStatus: buyingGroupsLoadStatus, list } = buyingGroups;

		if (buyingGroupsLoadStatus <= loadStatus.needsLoaded) {
			store.dispatch(loadBuyingGroups());
		}

		return list;
	}
);

export const useBuyingGroups = () => UsePortalSelector(selectBuyingGroupsAndDispatch);

export const selectTermsOptionsAndDispatch = createSelector(
	(state: AppState) => state.entity.termsOptions,
	(termObj) => {
		const { list, loadStatus: organizationsLoadStatus } = termObj;

		if (organizationsLoadStatus <= loadStatus.needsLoaded) {
			store.dispatch(loadBuyingGroups());
		}

		return list;
	}
);

export const useTermsOptions = () => UsePortalSelector(selectTermsOptionsAndDispatch);

export const selectDealerByIdAndDispatch = (dealerId: number) =>
	createSelector(selectDealersAndDispatch, (dealers) => {
		const dispatch = store.dispatch as AppDispatch;

		const dealer = dealers.find((d) => d.id === dealerId);

		if (
			dealer?.loadStatus === loadStatus.metaLoaded
			|| (dealer?.loadStatus === loadStatus.needsLoaded && (dealer?.id ?? 0) !== 0)
		) {
			dispatch(loadDealerFull(dealerId));
		}

		return dealer;
	});

export const selectTerritoryByIdAndDispatch = (territoryId: number) =>
	createSelector(selectTerritoriesAndDispatch, (territories) => {
		const dispatch = store.dispatch as AppDispatch;

		const territory = territories.find((t) => t?.id === territoryId);

		if (
			territory?.loadStatus === loadStatus.metaLoaded
			|| (territory?.loadStatus === loadStatus.needsLoaded && (territory?.id ?? 0) !== 0)
		) {
			dispatch(loadTerritoryFull(territoryId));
		}

		return territory;
	});

export const useTerritoryById = (territoryId: number) =>
	UsePortalSelector(selectTerritoryByIdAndDispatch(territoryId));

export const useDealerById = (dealerId: number) =>
	UsePortalSelector(selectDealerByIdAndDispatch(dealerId));

export const selectDistributorByIdAndDispatch = (distributorId: number) =>
	createSelector(selectDistributorList, (distributors) => {
		const dispatch = store.dispatch as AppDispatch;

		const distributor = distributors.find((d) => d.id === distributorId);

		if (
			distributor?.loadStatus === loadStatus.metaLoaded
			|| distributor?.loadStatus === loadStatus.needsLoaded
			|| !distributor
		) {
			dispatch(loadDistributorFull(distributorId));
		}

		return distributor ?? initialDistributorState();
	});

export const useDistributorById = (distributorId: number) =>
	UsePortalSelector(selectDistributorByIdAndDispatch(distributorId));

// export const selectDistributorLocationsByDistIdAndDispatch = (distributorId: number) =>
// 	createSelector(selectDistributorList, (distributors) => {
// 		const dispatch = store.dispatch as AppDispatch;

// 		const distributor = distributors.find(d => d.id == distributorId);

// 		if (distributor?.loadStatus === loadStatus.metaLoaded || distributor?.loadStatus === loadStatus.needsLoaded && (distributor?.id ?? 0) !== 0) {
// 			// dispatch(loadDistributorLocationFull(distributorId));
// 		}

// 		return distributor;
// 	});

// export const useDisLocationsByDistributorId = (distributorId: number) =>
// 	UsePortalSelector(selectDistributorLocationsByDistIdAndDispatch(distributorId));

export const useDistributorLocations = () => UsePortalSelector(selectDistributorLocationsAndDispatch);

export const selectDistributorLocationByIdAndDispatch = (distributorLocationId: number) =>

	createSelector(selectDistributorLocationList, (distLocations) => {
		const dispatch = store.dispatch as AppDispatch;

		const distLocation = distLocations.find((r) => r.id === distributorLocationId);

		if ((distLocation?.loadStatus === loadStatus.metaLoaded
			|| distLocation?.loadStatus === loadStatus.needsLoaded || !distLocation)
			&& distributorLocationId !== 0
			&& distributorLocationId !== undefined) {
			dispatch(loadDistributorLocationFull(distributorLocationId));
		}

		return distLocation;
	});

export const selectDistributorLocationsByDistributorIdAndDispatch = (distributorId: number) =>

	createSelector(selectDistributorLocationsAndDispatch, (distLocations) => distLocations.filter(dl => dl.distributor_id === distributorId));

export const useDistributorLocationsByDistributorId = (distId: number) => UsePortalSelector(selectDistributorLocationsByDistributorIdAndDispatch(distId));



export const useDistributorLocationById = (distLocationId: number) => UsePortalSelector(selectDistributorLocationByIdAndDispatch(distLocationId));
export const useDealerTerms = () => UsePortalSelector(selectDealerTerms);

export const useDistributors = () => UsePortalSelector(selectDistributorsAndDispatch);

export const useManufacturers = () => UsePortalSelector(selectManufacturersAndDispatch);

export const selectRepresentativeByIdAndDispatch = (representativeId: number) =>
	createSelector(selectRepresentativeList, (representatives) => {
		const dispatch = store.dispatch as AppDispatch;

		const representative = representatives.find((r) => r.id === representativeId);

		if (
			(representative?.loadStatus === loadStatus.metaLoaded
				|| representative?.loadStatus === loadStatus.needsLoaded
				|| !representative) && representativeId !== 0
		) {
			dispatch(loadRepresentativesFull(representativeId));
		}

		return representative;
	});

export const useRepresentativeById = (repId: number) =>
	UsePortalSelector(selectRepresentativeByIdAndDispatch(repId));

export const useRepresentatives = () => UsePortalSelector(selectRepresentativesAndDispatch);

export const selectRepresentativeLoadStatus = createSelector(
	(state: AppState) => state.entity.representatives.loadStatus,
	(workingLoadStatus) => workingLoadStatus
);

export const useRepresentativesLoadStatus = () => UsePortalSelector(selectRepresentativeLoadStatus);

export const selectDistributorLoadStatus = createSelector(
	(state: AppState) => state.entity.distributors.loadStatus,
	(workingLoadStatus) => workingLoadStatus
);

export const useDistributorLoadStatus = () => UsePortalSelector(selectDistributorLoadStatus);

const selectStateListUnsafe = (state: AppState) => state.entity.addressStates.list;

const selectStateList = createSelector(selectStateListUnsafe, (states) => states);

export const useStateList = (): AddressStateStore[] => {
	const { dispatch, getState } = store;

	const state = getState();

	if (state.entity.addressStates.loadStatus <= loadStatus.needsLoaded) {
		dispatch(loadAddressStates());
	}

	return UsePortalSelector(selectStateListUnsafe);
};

const selectStateByCode = (stateCode: string) =>
	createSelector(selectStateList, (states) => {
		const state = states.find((s) => s.code === stateCode);

		return state;
	});

export const useStateByCode = (stateCode: string) =>
	UsePortalSelector(selectStateByCode(stateCode));

const selectStatesLoadStatus = createSelector(
	(state: AppState) => state.entity.addressStates.loadStatus,
	(workingLoadStatus) => workingLoadStatus
);

export const useStatesLoadStatus = () => UsePortalSelector(selectStatesLoadStatus);

export const useDealersByTerritory = (territoryId: number) =>
	UsePortalSelector(selectDealerByTerritory(territoryId));

export const selectMetaLoadedTerritories = createSelector(selectTerritoryDict, (territories) => {

	if (territories?.loadStatus <= loadStatus.needsLoaded) {
		store.dispatch(loadTerritoriesMeta());
	}

	const metaLoadedTerritories = territories.list.filter((t) => t.loadStatus >= loadStatus.metaLoaded);

	const sortedTerritories = metaLoadedTerritories.sort((a, b) => a?.name?.localeCompare(b?.name));
	return sortedTerritories;
});

export const selectTerritoriesMap = createSelector(
	selectMetaLoadedTerritories,
	(territories) => new Map(territories.map((territory) => [territory.id, territory.name]))
);

export const useTerritories = () => UsePortalSelector(selectMetaLoadedTerritories);

export const useTerritoriesMap = () => UsePortalSelector(selectTerritoriesMap);

/**
 * Memoized selector that returns the load status of the users object.
 * @returns A loadStatus enum value.
 */
export const selectUsersLoadStatus = createSelector(
	(state: AppState) => state.entity.users.loadStatus,
	(workingLoadStatus) => workingLoadStatus
);

/**
 * Gets the load status of the users object.
 * @returns A loadStatus enum value.
 * @remarks You probably don't need this - you should check the load status 
 * and use the derived status instead.
 */
export const useUsersLoadStatus = () => UsePortalSelector(selectUsersLoadStatus);

/**
 * Memoized selector that returns a list of roles.
 * @returns An array of Role objects.
 */
export const selectRoleList = createSelector(
	(state: AppState) => state.entity.users.roles,
	(roles) => roles
);

/**
 * Memoized selector that returns a list of roles.
 * @returns An array of Role objects.
 * @remarks If the roles aren't loaded and this is fired, the dispatch
 * will be called to load the roles.
 */
export const selectRolesAndDispatch = createSelector(selectRoleList, (roles) => {
	const { dispatch, getState } = store;

	const state = getState();
	const rolesLoadStatus = state.entity.users.roles.loadStatus;

	const typedDispatch = dispatch as AppDispatch;

	if (rolesLoadStatus <= loadStatus.needsLoaded) {
		typedDispatch(getAccessibleRoles());
	}
	return roles.list;
});

/**
 * Custom hook that returns a list of roles.
 * @returns An array of Role objects.
 */
export const useRolesList = () => UsePortalSelector(selectRolesAndDispatch);

/**
 * Memoized selector that returns a list of users.
 * @returns An array of UserStore objects.
 * @remarks If the users aren't loaded and this is used,
 * it will not dispatch the loadUsersMeta action.
 */
export const selectUsersList = createSelector(
	(state: AppState) => state.entity.users,
	(users) => users
);

/**
 * Memoized selector that returns a list of users.
 * @returns An array of UserStore objects.
 * @remarks If the users aren't loaded and this is fired, the dispatch
 * will be called to load the users.
 */
export const selectUsersAndDispatch = createSelector(selectUsersList, (users) => {
	const { dispatch, getState } = store;

	const state = getState();
	const usersLoadStatus = state.entity.users.loadStatus;

	const typedDispatch = dispatch as AppDispatch;

	if (usersLoadStatus <= loadStatus.needsLoaded) {
		typedDispatch(loadUsersMeta());
	}
	return users.list;
});

/**
 * Custom hook that returns a list of users.
 * @returns An array of UserStore objects.
 */
export const useUsersList = () => UsePortalSelector(selectUsersAndDispatch);

/**
 * Memoized selector that returns a user by their id
 * @param userId - The id of the user to find.
 * @returns A UserStore object.
 * @remarks If the user isn't loaded and this is fired, the dispatch 
 * will be called to load the user.
 */
export const selectUserByIdAndDispatch = (userId: number) =>
	createSelector(selectUsersAndDispatch, (users) => {
		const dispatch = store.dispatch as AppDispatch;

		const user = users[userId];

		if (
			user?.loadStatus === loadStatus.metaLoaded
			|| (user?.loadStatus === loadStatus.needsLoaded && (user?.id ?? 0) !== 0)
		) {
			dispatch(loadUserFull(userId));
		}

		return user;
	});

/**
 * Custom hook that returns a user by their id.
 * @param userId - The id of the user to find.
 * @returns A UserStore object.
 */
export const useUserById = (userId: number) =>
	UsePortalSelector(selectUserByIdAndDispatch(userId));

/**
 * Memoized selector that returns a user by their name.
 * @param userName - The name of the user to find.
 * @returns A UserStore object.
 */
export const selectUserByName = (userName: string) =>
	createSelector(selectUsersAndDispatch, (users) => {
		const user = Object.values(users).find((u) => u.name === userName);
		return user;
	});

/**
 * Custom hook that returns a user by their name.
 * @param userName - The name of the user to find.
 * @returns A UserStore object.
 */
export const useUserByName = (userName: string) =>
	UsePortalSelector(selectUserByName(userName));

/**
 * Custom hook that returns a function to add a new user.
 * @returns A function that takes an AddUserInput object and dispatches a createUser action with it.
 */
export const useAddUser = () => (user: AddUserInput) => {
	const { dispatch } = store;
	const typedDispatch = dispatch as AppDispatch;

	return typedDispatch(createUser(user));
};

/**
 * Custom hook that returns a function to update a user.
 * @returns A function that takes an UpdateUserInput object and dispatches an update user
 *  action with it.
 */
// export const useUpdateUser = () => (user: UpdateUserInput) => {
// 	const { dispatch } = store;
// 	const typedDispatch = dispatch as AppDispatch;

// 	return typedDispatch(updateUserData(user));
// };

/**
 * Custom hook that returns a function to delete a user.
 * @returns A function that takes an DeleteUserInput object and dispatches a delete user
 *  action with it.
 */
export const useDeleteUser = () => (user: DeleteUserInput) => {
	const { dispatch } = store;
	const typedDispatch = dispatch as AppDispatch;

	return typedDispatch(deleteUser(user));
};

/**
 * Custom hook that returns a function to add a user role to a user.
 * @returns A function that takes an AddUserRoleInput object and dispatches an add user
 * role action with it.
 */
export const useAddUserRole = () => (user: AddUserRoleInput) => {
	const { dispatch } = store;
	const typedDispatch = dispatch as AppDispatch;

	return typedDispatch(addUserRole(user));
};

/**
 * Custom hook that returns a function to delete a user role to a user.
 * @returns A function that takes an DeleteUserRoleInput object and dispatches a delete user
 * role action with it.
 */
export const useDeleteUserRole = () => (user: DeleteUserRoleInput) => {
	const { dispatch } = store;
	const typedDispatch = dispatch as AppDispatch;

	return typedDispatch(deleteUserRole(user));
};

export const useUpdateUserAttribute = () => (userAttribute: UpdateUserAttributeInput) => {
	const { dispatch } = store;
	const typedDispatch = dispatch as AppDispatch;

	return typedDispatch(updateUserAttribute(userAttribute));
}

/**
 * Custom hook that returns a boolean on whether or not a dealer is in Canada.
 * @param dealer - The dealer to check.
 * @returns boolean
 * @todo Update the logic to determine if a dealer is in Canada.
 */
export const useDealerInCanada = (dealer: DealerStore | undefined) => {
	// ! This is a temporary solution until we have a better way to determine if a dealer is in Canada
	if (!dealer) return false;
	if (dealer.id === 0) return false;
	const canadianTerritoryIds = [
		23,
		24,
		25,
		26,
		27,
		21,
		20
	];

	const locatedInCanada = dealer.territory_ids
		?.some((territoryId) => canadianTerritoryIds.includes(territoryId));

	return locatedInCanada ?? false;
};



const selectSalesPeople = (state: AppState) => state.entity.salespeople.list;

export const useSalesPeople = () => UsePortalSelector(selectSalesPeople);

const selectSalesPeopleFromUserList = createSelector(selectUsersAndDispatch, (usersList) => {
	const salesPeople = Object.values(usersList).filter((user) => user.is_sales_person);
	return salesPeople;
});

export const useSalesPeopleUsers = () => UsePortalSelector(selectSalesPeopleFromUserList);

export const useFabrics = (quoteId: number) => UsePortalSelector(SelectFabrics(quoteId));
//export const useIndoorFabrics = (quoteId) => UsePortalSelector(SelectIndoorFabrics);
export const useOutdoorFabrics = (quoteId: number) => UsePortalSelector(SelectOutdoorFabrics(quoteId));
// export const usePSOutdoorFabrics = (quoteId) => UsePortalSelector(SelectPSOutdoorFabrics);


export const useIndoorFabrics = (quoteId) => {
    const indoorFabrics = UsePortalSelector(SelectIndoorFabrics(quoteId)); // Existing indoor fabrics

    //GIO
	//   console.log("Debugging 1: indoorFabrics");
	//  indoorFabrics.forEach((fabric, index) => {
    //     if (fabric.name == 'Verona Twilight 080000 Orion' || fabric.name == 'Sparta Twilight Emery/White BO')
	//  	console.log(`Fabric ${index + 1}:`, fabric);
	//  });

	const dispatch = useDispatch<AppDispatch<any>>();

		// Get fabrics from Redux state
		const allowedIndoorFabrics = useSelector((state: AppState) => state.fabricsAllowed.allowedIndoorFabrics[quoteId] || []);
		const isLoading = useSelector((state: AppState) => state.fabricsAllowed.loading[quoteId] || false);  
		const hasFetched = useSelector((state: AppState) => state.fabricsAllowed.hasFetched[quoteId] || false);
		
		// Only fetch data if it's not already in Redux
		useEffect(() => {

				// console.log(`🔹 useIndoorFabrics - Checking Redux before submitting for quoteId: ${quoteId}`);
				// console.log("✔️ allowedIndoorFabrics:", allowedIndoorFabrics);
				// console.log("⏳ isLoading:", isLoading);
				// console.log("🔍 hasFetched:", hasFetched);


			if (!isLoading && !hasFetched) {
				console.log("🚀 Despachando loadAllowedIndoorFabrics...");
				dispatch(loadAllowedIndoorFabrics(quoteId));
			}
		}, [quoteId, dispatch, allowedIndoorFabrics, isLoading, hasFetched]);



	    // Debugging allowedIndoorFabrics
		// console.log("Debugging 2: Allowed indoor fabrics");
		// allowedIndoorFabrics.forEach((fabric, index) => {
		// 	console.log(`Allowed Fabric ${index + 1}:`, fabric);
		// });

    //const [allowedIndoorFabrics, setAllowedIndoorFabrics] = useState<FabricViewModel[]>([]);

    // Fetch allowed indoor fabrics from the backend
    // useEffect(() => {
    //     const fetchAllowedIndoorFabrics = async () => {
    //         try {
    //             const response = await apiCalls.getFabricIndoorAllowedList(quoteId);
    //             setAllowedIndoorFabrics(response.data.fabrics); // Store the fetched fabrics in state
    //         } catch (error) {
    //             console.error("Error fetching allowed indoor fabrics:", error);
    //         }
    //     };

    //     if (quoteId) {
    //         fetchAllowedIndoorFabrics();
    //     }
    // }, [quoteId]);

    // Combine fetched allowed fabrics with the indoor fabrics
    const combinedIndoorFabrics = [
        ...indoorFabrics,
        ...allowedIndoorFabrics.map((fabric) => ({
            name: fabric.name,
            imageUrl: fabric.image_url || "",
            imageLoaded: 1 as loadStatusType, 
            isNextLoad: false,
            fabric_color: "",
            fabric_pattern: "",
            is_indoor: fabric.is_indoor,
            is_outdoor: fabric.is_outdoor,
            is_psoutdoor: fabric.is_ps_outdoor,
            is_railroadable: fabric.is_railroadable,
            is_seamable: fabric.is_seamable,
            is_discontinued: fabric.is_discontinued,
            is_gone: fabric.is_gone,
        })),
    ];

    return combinedIndoorFabrics;
	//return indoorFabrics;
	
};


export const usePSOutdoorFabrics = (quoteId) => {
    const PsOutdoorFabrics =UsePortalSelector(SelectPSOutdoorFabrics(quoteId));// Existing PsOutdoorFabrics fabrics
    
	const dispatch = useDispatch<AppDispatch<any>>();

	// Get fabrics from Redux state
	const allowedPsOutdoorFabrics = useSelector((state: AppState) => state.fabricsAllowed.allowedPsOutdoorFabrics[quoteId] || []);
	const isLoading = useSelector((state: AppState) => state.fabricsAllowed.loading[quoteId] || false);  
	const hasFetched = useSelector((state: AppState) => state.fabricsAllowed.hasFetched[quoteId] || false);

	// Only fetch data if it's not already in Redux
	useEffect(() => {

			// console.log(`🔹 usePSOutdoorFabrics - Checking Redux before submitting for quoteId: ${quoteId}`);
			// console.log("✔️ allowedPsOutdoorFabrics:", allowedPsOutdoorFabrics);
			// console.log("⏳ isLoading:", isLoading);
			// console.log("🔍 hasFetched:", hasFetched);


		if (!isLoading && !hasFetched) {
			console.log("🚀 Despachando loadAllowedPsOutdoorFabrics...");
			dispatch(loadAllowedPsOutdoorFabrics(quoteId));
		}
	}, [quoteId, dispatch, allowedPsOutdoorFabrics, isLoading, hasFetched]);

	
	
	//const [allowedPsOutdoorFabrics, setAllowedPsOutdoorFabrics] = useState<FabricViewModel[]>([]);

    // Fetch allowed indoor fabrics from the backend
    // useEffect(() => {
    //     const fetchAllowedPsOutdoorFabrics = async () => {
    //         try {
    //             const response = await apiCalls.getFabricPsOutdoorAllowedList(quoteId);
    //             setAllowedPsOutdoorFabrics(response.data.fabrics); // Store the fetched fabrics in state
    //         } catch (error) {
    //             console.error("Error fetching allowed PsOutdoor fabrics:", error);
    //         }
    //     };

    //     if (quoteId) {
    //         fetchAllowedPsOutdoorFabrics();
    //     }
    // }, [quoteId]);

    // Combine fetched allowed fabrics with the PsOutdoor fabrics
    const combinedPsOutdoorFabrics = [
        ...PsOutdoorFabrics,
        ...allowedPsOutdoorFabrics.map((fabric) => ({
            name: fabric.name,
            imageUrl: fabric.image_url || "",
            imageLoaded: 1 as loadStatusType, 
            isNextLoad: false,
            fabric_color: "",
            fabric_pattern: "",
            is_indoor: fabric.is_indoor,
            is_outdoor: fabric.is_outdoor,
            is_psoutdoor: fabric.is_ps_outdoor,
            is_railroadable: fabric.is_railroadable,
            is_seamable: fabric.is_seamable,
            is_discontinued: fabric.is_discontinued,
            is_gone: fabric.is_gone,
        })),
    ];

    //return PsOutdoorFabrics;
	return combinedPsOutdoorFabrics;
};