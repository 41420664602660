import { formatMoney } from '../Parts/Money';
import HeaderData from '../Quote/NewItems/HeaderData';

const ROOM_COLORS = ['C5D9F1', 'FDE9D9', 'EBF1DE', 'CCC0DA'];

/* eslint-disable camelcase, no-param-reassign, no-shadow */

function shadeFieldDisplayValue(key, value, shade) {
	return shade[key] ?? shade.shades[0][key] ?? value;
	const option = sff.find((opt) => opt.varName === key);

	if (key === 'single_dual') {
		const isSingle = value === 'Single';
		const isCoupled = shade.is_coupled;

		let str = isSingle ? 'Single' : 'Dual';
		if (isCoupled) {
			str += ' Coupled';
		}

		return str;
	}

	if (option && option.type === 'select') {
		const choices = typeof option.choices === 'function' ? option.choices(shade) : option.choices;

		const choice = choices.find((c) =>
			(c.value !== undefined ? c.value === value : c.name === value));

		if (choice && choice.name) {
			return choice.name;
		}
	}

	return value;
}

function shadeHeaderRow({
	workbook, worksheet, row, hidePrice, currencyData
}) {
	const ROW_STYLE = workbook.createStyle({
		alignment: {
			horizontal: 'center',
		},
		fill: {
			type: 'pattern',
			patternType: 'solid',
			fgColor: '#d5d5d5',
		},
		border: {
			left: {
				style: 'thin',
				color: 'black',
			},
			right: {
				style: 'thin',
				color: 'black',
			},
			top: {
				style: 'thin',
				color: 'black',
			},
			bottom: {
				style: 'thin',
				color: 'black',
			},
			diagonal: {
				style: 'thin',
				color: 'black',
			},
			diagonalDown: true,
			outline: true,
		},
	});

	let col = 1;

	function cell(string) {
		worksheet
			.cell(row, col++)
			.string(string)
			.style(ROW_STYLE);
	}

	cell('Qty');

	if (!hidePrice) {
		cell(`MSRP (${currencyData.ISO})`);
		cell(`Dealer Price (${currencyData.ISO})`);
	}

	cell('Type');
	cell('Shade Configuration');
	cell('Inside Fabric');
	cell('Outside Fabric');
	cell('Width');
	cell('Length');
	cell('Window Jamb Depth');
	cell('Tube');
	cell('Floor');
	cell('Room');
	cell('Shade Name');
	cell('Mount Type');
	cell('Header SKU');
	cell('Header Name');
	cell('End Caps');
	cell('Side Channels');
	cell('Side Channel Color');
	cell('Hembar Type');
	cell('Hembar Color');
	cell('Header Color');
	cell('Bracket Color');
	cell('Motor');
	cell('Manual Chain');
	cell('Chain Color');
	cell('Power Source');
	cell('Roll Direction 1');
	cell('Roll Direction 2');
	cell('Direction Facing');
	cell('Control Side');


}

async function shadeRow(items) {
	const {
		workbook,
		worksheet,
		row,
		shade,
		quote,
		shade_ind,
		room_ind,
		userMultiplier,
		hidePrice,
		currencyData,
	} = items;

	const shade_row_style = workbook.createStyle({
		alignment: {
			horizontal: 'center',
		},

		fill: {
			type: 'pattern',
			patternType: 'solid',
			fgColor: `#${ROOM_COLORS[room_ind % 4]}`,
		},

		border: {
			right: {
				style: 'thin',
				color: '#444444',
			},

			bottom: {
				style: 'thin',
				color: '#444444',
			},

			outline: true,
		},
	});

	let col = 1;

	function cell(string) {
		worksheet
			.cell(row, col++)
			.string(`${string}` || 'N/A')
			.style(shade_row_style);
	}

	cell('1');

	if (!hidePrice) {
		if (Number.isNaN(parseFloat(shade.msrp))) {
			cell(`${currencyData.symbol}0.00 (Shade has unresolved errors)`);
		} else {
			cell(
				`${currencyData.symbol}${formatMoney(parseFloat(shade.msrp) * currencyData.multiplier)}`
			);
		}
	}

	const shade_fields = [
		{
			key: 'buy_price',
			value: (shadeToGetBuyPriceOn) => {
				const realMultiplier = userMultiplier.multiplier.multiplier;
				const currencyMultiplier = userMultiplier.multiplier.currency_multiplier;
				const currencySymbol = currencyData.symbol;
				const shadePrice = (parseFloat(shadeToGetBuyPriceOn.msrp) * realMultiplier) * currencyMultiplier;
				const shadePriceWithCurrency = `${currencySymbol}${formatMoney(shadePrice)}`;
				if (Number.isNaN(shadePrice)) return `${currencyData.symbol}0.00 (Shade has unresolved errors)`;
				return shadePriceWithCurrency;
			},
		},
		'shade_type',
		'single_dual',
		'fabric_name',
		'fabric2_name',
		'width',
		'height',
		'window_jamb_depth',
		'tube_name',
		'floor',
		'room_name',
		'shade_name',
		{
			key: 'mount_type',
			value: (shade) => {
				const sku = shade.mount_style;
				return sku;
			},
		},
		{
			key: 'header_sku',
			value: (shade) => {
				return shade.header_line;
			},
		},
		{
			key: 'header_name',
			value: (shade) => {
				const sku = shade.palette_color;
				const headerData = HeaderData().filter((h) => h.value === sku)[0];
				if (headerData?.name) {
					return headerData.name;
				}
				return sku;
			}
		},
		'end_caps',
		'side_channels',
		{
			"key": "side_channel_color",
			value: (shade) => {
				return shade?.colors["Channels"] ?? "N/A";
			}
		},
		{
			"key": "hembar_type",
			value: (shade) => {
				return shade?.shades[0]?.hembar_line
			}
		},
		{
			"key": "hembar_color",
			value: (shade) => {
				return shade?.shades[0]?.hembar_style
			}
		},
		{
			key: "fascia_color",
			value: (shade) => {
				return shade?.palette_color
			}
		},
		{
			key: 'bracket_color',
			value: (shade) => {
				const colors = shade?.colors;
				if (colors) {
					const bracketKey = Object.keys(colors).find((key) => key.toLowerCase().includes('bracket'));
					return colors[bracketKey] ?? 'N/A';
				}
			},
		},
		{
			key: 'motor',
			value: (shade) => {
				let motor = shade?.shades[0]?.motor;
				// Motor Name Substitution.
				if (motor === 'DM35CEQ/S-2/28') {
					motor = '35 mm 12VDC Hardwired Motor';
				} else if (motor === 'DM35LEQ/S-2/28') {
					motor = '35 mm Li-Ion RF Motor';
				}
				return motor;
			},
		},
		"manual_chain",
		{
			"key": "manual_chain_color",
			value: (shade) => {
				const colors = shade?.colors;
				if (colors) {
					const bracketKey = Object.keys(colors).find((key) => key.toLowerCase().includes('chain'));
					return colors[bracketKey] ?? 'N/A';
				}
			}
		},

		'power_source',
		'roll_direction',
		'roll_direction2',
		'direction_facing',
		'control_side',
	];

	shade_fields.forEach((key) => {
		let index = key;
		let value = null;

		if (typeof key === 'object') {
			index = key.key;
			value = key.value(shade);
		} else if (key.includes("2")) {
			const backShade = shade.shades.find(s => s.row_coordinate === 1);
			value = backShade ? backShade[key.replace("2", "")] : "";
		} else if (key === 'single_dual') {
			const isSingle = !shade.is_dual;
			const isCoupled = shade.is_coupled;

			let str = isSingle ? 'Single' : 'Dual';
			if (isCoupled) {
				str += ' Coupled';
			}

			value =  str;

		} else if(key === "power_source") {
			value = shade.shades.find(s => s.row_coordinate === 0).motor_type !== "Battery" ? "Hardwired" :
			(
				shade.shades.find(s => s.row_coordinate === 0).lv_power_source === "wall_charger" ? "Wall Charger" 
				: shade.shades.find(s => s.row_coordinate === 0).lv_power_source === "solar_panel" ? "Solar Panel" 
				: "Hardwired"
			)
		} else {
			value = shade[key] ?? shade.shades[0][key];
		}

		const cellItem = cell(value);
		console.log(key);
		console.log(cellItem);
		return cellItem;
	});
}

export default function ShadesSection({
	workbook,
	worksheet,
	row,
	quote,
	quote_data,
	userMultiplier,
	hidePrice,
	currencyData,
}) {
	const HEADER_STYLE = workbook.createStyle({
		font: {
			size: 16,
			bold: true,
		},
	});

	worksheet
		.cell(row++, 1)
		.string('Shades')
		.style(HEADER_STYLE);

	const currency = currencyData;

	shadeHeaderRow({
		workbook, worksheet, row: row++, hidePrice, currencyData
	});

	quote.shades
		.reduce(
			(acc, s) =>
			(acc.includes(s.room_name.toLowerCase())
				? acc
				: [...acc, s.room_name.toLowerCase()]),
			[]
		)
		.forEach((room_name, room_ind) => {
			const shades = quote.shades.filter(
				(shade) => shade.room_name.toLowerCase() === room_name
			);

			shades.forEach((shade, shade_ind) =>
				shadeRow({
					workbook,
					worksheet,
					row: row++,
					shade,
					quote,
					room_ind: room_ind + 1,
					shade_ind: shade_ind + 1,
					userMultiplier,
					hidePrice,
					currencyData: currency,
				}));
		});

	row++;

	return row;
}
