/* eslint-disable @stylistic/max-len */
import React, { useCallback, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPalette } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";
import { OptionLabel } from "../../../../Parts/FormParts";
import PowerShadesTypeahead from "../../../../Parts/PowerShadesTypeahead";
import FractionField from "../../../../Quote/NewOptionFields/FractionField";
import InfoBox from "../../../../Quote/ShadeForm/InfoBox";
import { PSOutdoorShadeOptionsDict, PortalShadeOption, PSOutdoorHeaderTypeSku, PSOutdoorHeaderMountType, PSOutdoorControlSide, PSOutdoorSideGuides, PSOutdoorSideGuideMountTypes, PortalShadeOptionItem } from "../../../../Store/entities/assemblies/types";
import ColorPaletteModal from "../../../ColorPaletteModal/ColorPaletteModal";
import { ErrorSet } from "../../../../Store/entities/assemblies/errors/hooks";
import { onlyActive } from "../../../../psUtil";
import Button from "../../../../Parts/Button";

type EditPSOutdoorShadeModalBaseInnerProps = {
	options: PSOutdoorShadeOptionsDict;
	quoteType: string;
	isNotAdd?: boolean;
	massEdit?: boolean | undefined;
	errors?: ErrorSet[];
	unlocked?: boolean;
	pickerState?: boolean;
	setPickerState?: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditPSOutdoorShadeModalInner = ({ options, quoteType, unlocked = true, errors = [], setPickerState, pickerState }: EditPSOutdoorShadeModalBaseInnerProps) => {
	const {
		Width,
		Height,
		HeaderMountType,
		Fabric,
		ControlSide,
		HeaderType,
		SideGuides,
		SideGuideMountTypes,
		RoomName,
		ShadeName,
		DirectionFacing,
		Floor,
		
		ColorPalettes,
		Colors
	} = options;

	const disabled = !unlocked;

	const groupHeader = (groupName: string) => (
		<Row className={`form-group-title text-center m-2 mb-3 text-green border-success border-bottom text-${quoteType}`}>
			<Col>
				{groupName}
			</Col>
		</Row>
	);

	const optionFooter = (option: PortalShadeOption<any>) => {
		const { info } = option;

		return (
			<Row className="text-center mb-1" xs={12}>
				{
					info && info.length > 0 && (
						<InfoBox info={info} />
					)
				}
			</Row>
		);
	};

	const erroredClass = "border border-danger";
	const errorClass = (option: PortalShadeOption<any>) => (errors.find((error) => error.varName === option?.varName) ? erroredClass : "");

	let zScoreNum = 1;

	const zScore = () => zScoreNum++;

	const isColorPickerOpen = pickerState ?? false;

	const [colorPaletteState, setColorPaletteState] = useState<string | PortalShadeOptionItem<string>>(ColorPalettes.getValue());

	const toggleColorPicker = () => {
		colorPaletteState === "" && setColorPaletteState("Custom Palette");
		setPickerState && setPickerState(!isColorPickerOpen);
	};

	const activeColorPalettes = onlyActive(ColorPalettes.options?.filter((palette) => palette.value !== "Custom Palette"));

	const [colorBtnDisabled, setColorBtnDisabled] = useState(colorPaletteState === "");

	useEffect(() => {
		const newPalette = ColorPalettes.getValue();
		setColorPaletteState(newPalette === ""
			? "Custom Palette"
			: newPalette);
	}, [pickerState]);

	const [roomNameValue, setRoomNameValue] = useState(RoomName.getValue());
	const [shadeNameValue, setShadeNameValue] = useState(ShadeName.getValue());

	const debouncedSetRoomName = useCallback(
		debounce((value) => RoomName.setValue(value), 500),
		[options]
	);

	const debouncedSetShadeName = useCallback(
		debounce((value) => ShadeName.setValue(value), 500),
		[options]
	);

	return pickerState ? <ColorPaletteModal
		colors={options.Colors}
		colorPalettes={options.ColorPalettes}
		errorClass={errorClass}
		quoteType={quoteType}
	/> : <Row>
		<Col>
			<Row>
				<Col
					key="form-group-header"
					className={` no-bottom header `}
				>
					<Row className="p-2">
						{groupHeader("Header")}
						<Col xs={6} className=" text-center ">
							{OptionLabel(HeaderType)}
							<PowerShadesTypeahead
								disabled={disabled || onlyActive(HeaderType.options).length === 0}
								id="header-type"
								options={HeaderType.options ?? []}
								onChange={(selected) => {
									ColorPalettes.clearValue();
									setColorPaletteState("");
									setColorBtnDisabled(true);
									if (selected.length === 0) {
										HeaderType.clearValue();
										return;
									}
									const selectedObj = selected[0] as {
										value: PSOutdoorHeaderTypeSku;
									};

									HeaderType.setValue(selectedObj.value);
								}}
								selected={[HeaderType.getSelectedOption()]}
								className={`text-center ${zScore()} ${errorClass(HeaderType)}`}
							/>

						</Col>
						<Col>
							{OptionLabel(ColorPalettes)}
							<div className="d-flex flex-col">
								<PowerShadesTypeahead
									disabled={disabled || onlyActive(ColorPalettes.options).length === 0}
									id="color-palettes"
									options={activeColorPalettes}
									onChange={(selected) => {
										setColorPaletteState("");
										if (selected.length === 0) {
											ColorPalettes.clearValue();
											setColorPaletteState("");
											setColorBtnDisabled(true);
											return;
										}
										setColorBtnDisabled(false);
										const selectedObj = selected[0] as {
											value;
										};
										ColorPalettes.setValue(selectedObj.value);
										setColorPaletteState(selectedObj.value);
									}}
									selected={[colorPaletteState]}
									errored={errorClass(ColorPalettes) !== ""}
								/>
								<Button
									color="green"
									disabled={Colors.length === 0 || disabled || !ColorPalettes?.options || colorBtnDisabled}
									onClick={toggleColorPicker}
									style={{ minWidth: "40px", borderRadius: "6px", height: "38px", marginLeft: "5px" }}
								>
									<FontAwesomeIcon icon={faPalette} />
								</Button>
							</div>
						</Col>
								
					</Row>
				</Col>
			</Row>
			<Row className="p-2">
				<Col xs={6} className=" text-center">
					{OptionLabel(HeaderMountType)}
					<PowerShadesTypeahead
						disabled={disabled || onlyActive(HeaderMountType.options).length === 0}
						id="header-mount-type"
						options={HeaderMountType.options ?? []}
						onChange={(selected) => {
							if (selected.length === 0) {
								HeaderMountType.clearValue();
								return;
							}
							const selectedObj = selected[0] as {
								value: PSOutdoorHeaderMountType;
							};

							HeaderMountType.setValue(selectedObj.value);
						}}
						selected={[HeaderMountType.getSelectedOption()]}
						className={`text-center ${zScore()} ${errorClass(HeaderMountType)}`}
					/>

				</Col>
				<Col xs={6}>
					{OptionLabel(Fabric)}
					<PowerShadesTypeahead
						disabled={disabled || onlyActive(Fabric.options).length === 0}
						id="fabric"
						options={Fabric.options ?? []}
						onChange={(selected) => {
							if (selected.length === 0) {
								Fabric.clearValue();
								return;
							}
							const selectedFabric = selected[0] as {
								value: string;
							};
							Fabric.setValue(selectedFabric.value);
						}}
						selected={[Fabric.getSelectedOption()]}
						className={`text-center ${zScore()} ${errorClass(Fabric)}`}
					/>
				</Col>
			</Row>
			<Row>
				<Col
					key="form-group-sizes"
					className={` no-bottom header `}
				>
					<Row className="p-2">
						{groupHeader("Sizes")}
						<Col>
							{OptionLabel(Width)}
							<FractionField
								key={`width-${Width.varName}`}
								disabled={disabled}
								onBlur={(value) => Width.setValue(value)}
								value={Width.getValue()}
								minValue={Width.minValue ?? 0}
								maxValue={Width.maxValue ?? 0}
								varName="width"
								extraClasses={` ${errorClass(Width)}`}
							/>
						</Col>
						<Col>
							{OptionLabel(Height)}
							<FractionField
								key={`height-${Height.varName}`}
								disabled={disabled}
								onBlur={(value) => Height.setValue(value)}
								value={Height.getValue()}
								minValue={Height.minValue ?? 0}
								maxValue={Height.maxValue ?? 0}
								varName="height"
								extraClasses={` ${errorClass(Height)}`}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row className="p-2">
				<Col xs={6}>
					{OptionLabel(ControlSide)}
					<PowerShadesTypeahead
						disabled={disabled || onlyActive(ControlSide.options).length === 0}
						id="control-side"
						options={ControlSide.options ?? []}
						onChange={(selected) => {
							if (selected.length === 0) {
								ControlSide.clearValue();
								return;
							}

							const selectedObj = selected[0] as {
								value: PSOutdoorControlSide;
							};

							ControlSide.setValue(selectedObj.value);
						}}
						selected={[ControlSide.getSelectedOption()]}
						className={`text-center z-2 ${errorClass(ControlSide)}`}
					/>
				</Col>
			</Row>
			<Row className="p-2">
				<Col xs={6}>
					{OptionLabel(SideGuides)}
					<PowerShadesTypeahead
						disabled={disabled || onlyActive(SideGuides.options).length === 0}
						id="side-guides"
						options={SideGuides.options ?? []}
						onChange={(selected) => {
							if (selected.length === 0) {
								SideGuides.clearValue();
								return;
							}
							const selectedObj = selected[0] as {
								value: PSOutdoorSideGuides;
							};
							SideGuides.setValue(selectedObj.value);
						}}
						selected={[SideGuides.getSelectedOption()]}
						className={`text-center ${zScore()} ${errorClass(SideGuides)}`}
					/>
				</Col>
				<Col xs={6}>
					{OptionLabel(SideGuideMountTypes)}
					<PowerShadesTypeahead
						disabled={disabled || onlyActive(SideGuideMountTypes.options).length === 0}
						id="side-guide-mount-types"
						options={SideGuideMountTypes.options ?? []}
						onChange={(selected) => {
							if (selected.length === 0) {
								SideGuideMountTypes.clearValue();
								return;
							}
							const selectedObj = selected[0] as {
								value: PSOutdoorSideGuideMountTypes;
							};

							SideGuideMountTypes.setValue(selectedObj?.value);
						}}
						selected={[SideGuideMountTypes.getSelectedOption()]}
						className={`text-center ${zScore()} ${errorClass(SideGuideMountTypes)}`}
					/>
					{optionFooter(SideGuideMountTypes)}
				</Col>
			</Row>
			<Row className="p-2">
				{groupHeader("Attributes")}
				<Col>
					{OptionLabel(DirectionFacing)}
					<PowerShadesTypeahead
						id="direction-facing"
						clearButton={DirectionFacing.getSelectedOption().label !== ""}
						options={DirectionFacing.options ?? []}
						onChange={(selected) => {
							if (selected.length === 0) {
								DirectionFacing.clearValue();
								return;
							}
							const selectedObj = selected[0] as {
								value;
							};

							DirectionFacing.setValue(selectedObj.value);
						}}
						selected={[DirectionFacing.getSelectedOption()]}
						className={`text-center ${zScore()} ${errorClass(DirectionFacing)}`}
					/>
				</Col>
				<Col>
					{OptionLabel(Floor)}
					<input
						type="number"
						className={`rounded input-group p-2 ${errorClass(Floor)}`}
						value={Floor.getValue() || ""}
						onChange={(e) => Floor.setValue(Number(e.target.value))}
					/>
				</Col>
			</Row>
			<Row className="p-2">
				<Col>
					{OptionLabel(RoomName)}
					<input
						className={`rounded input-group p-2 ${errorClass(RoomName)}`}
						disabled={disabled}
						type="text"
						value={roomNameValue || ""}
						onChange={(e) => {
							setRoomNameValue(e.target.value);
							debouncedSetRoomName(e.target.value);
						}}
					/>
				</Col>
				<Col xs={6}>
					{OptionLabel(ShadeName)}
					<input
						className={`rounded input-group p-2 ${errorClass(ShadeName)}`}
						disabled={disabled}
						type="text"
						value={shadeNameValue}
						onChange={(e) => {
							setShadeNameValue(e.target.value);
							debouncedSetShadeName(e.target.value);
						}}
					/>
				</Col>
			</Row>
		</Col>
	</Row>;
};

export default EditPSOutdoorShadeModalInner;
