import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ValidationError, number, object, string } from 'yup';
import { toast } from 'react-toastify';
import { startCase } from 'lodash';

import { useIsDealer } from '../hooks';

import Button from '../Parts/Button';
import UserRoleSelect from '../Parts/UserRoleSelect';
import EntitySelect from '../Parts/EntitySelect';

import type { AddUserRoleModalProps } from './types';
import type { Role, UserRole } from '../powershadesApiTypeExtensions';

const addUserRoleSchema = object({
	id: number().optional(),
	entity_name: string().required("Entity must be selected."),
	entity_id: number().integer().positive().required("Entity must be selected."),
	role_name: string().required("Role must be selected."),
	role_id: number().integer().positive().required("Role must be selected."),
});

const AddUserRoleModal = ({
	open, onClose, workingUser, setWorkingUser
}: AddUserRoleModalProps) => {
	const [selectedEntityId, setSelectedEntityId] = useState(0);
	const [selectedEntityName, setSelectedEntityName] = useState("");
	const [selectedRoleCategory, setSelectedRoleCategory] = useState("");
	const [selectedRole, setSelectedRole] = useState<Role | undefined>(undefined);
	const [workingUserRole, setWorkingUserRole] = useState<Partial<UserRole>>({});

	const validationChecks = {
		EntitySet: selectedEntityId > 0 && selectedEntityName.length > 0,
		RoleSet: selectedRole !== undefined,
	};
	const readyToSubmit = Object.values(validationChecks).every((check) => check);

	const isDealer = useIsDealer();
	const powerShadesAdminRoleId = 8;
	const displayEntitySelector = !isDealer && selectedRole?.id !== powerShadesAdminRoleId;

	const updateWorkingUserRole = (updateObject: Partial<UserRole>) => {
		setWorkingUserRole({ ...workingUserRole, ...updateObject });
	};

	const addRoleToWorkingUser = async () => {
		try {
			const localRole = {
				...workingUserRole,
				entity_id: selectedEntityId,
				entity_name: selectedEntityName,
			};
			const validatedUserRole = await addUserRoleSchema.validate(localRole) as UserRole;
			setWorkingUser({
				...workingUser,
				roles: [...workingUser.roles, validatedUserRole],
			});
			onClose();
		} catch (error) {
			if (error instanceof ValidationError) {
				toast.error(error.message);
			} else {
				throw error;
			}
		}
	};

	const handleUpdateSelectedEntityId = (newEntityId: number) => {
		updateWorkingUserRole({ entity_id: newEntityId });
		setSelectedEntityId(newEntityId);
	};

	const handleUpdateSelectedEntityName = (newEntityName: string) => {
		updateWorkingUserRole({ entity_name: newEntityName });
		setSelectedEntityName(newEntityName);
	};

	const handleUpdateSelectedRole = (newRole: Role) => {
		updateWorkingUserRole({
			id: 0,
			role_id: newRole.id,
			role_name: newRole.name
		});
		setSelectedRole(newRole);
	};

	const handleClose = () => {
		if (isDealer) {
			const dealerUserRole = workingUser.roles[0];
			if (!dealerUserRole) return;
			setSelectedEntityId(dealerUserRole.entity_id);
			setSelectedEntityName(dealerUserRole.entity_name);
		} else {
			setSelectedEntityId(0);
			setSelectedEntityName("");
		}
		setSelectedRole(undefined);
		setSelectedRoleCategory("");
		onClose();
	};

	useEffect(() => {
		if (!isDealer) return;
		const dealerUserRole = workingUser.roles[0];
		if (!dealerUserRole) return;
		setSelectedEntityId(dealerUserRole.entity_id);
		setSelectedEntityName(dealerUserRole.entity_name);
	}, [isDealer]);
	
	return (
		<Popup
			open={open}
			closeOnDocumentClick={false}
			closeOnEscape={false}
			onClose={handleClose}
			className="mobile-modal"
		>
			<Row className="mb-2">
				<Col>
					<h4>Add User Role</h4>
				</Col>
			</Row>
			<Row className="mb-2">
				<Col>
					<label className="mb-2">Role</label>
					<UserRoleSelect
						setSelectedRole={handleUpdateSelectedRole}
						setSelectedRoleCategory={setSelectedRoleCategory}
					/>
				</Col>
			</Row>
			{displayEntitySelector && (
				<Row className="mb-2">
					<Col>
						<label className="mb-2">
							{selectedRoleCategory
								? startCase(`${selectedRoleCategory}s`)
								: 'Entities'}
						</label>
						<EntitySelect
							placeholder={
								selectedRoleCategory
									? `Select a ${selectedRoleCategory}`
									: 'Select a role first...'
							}
							disabled={selectedRole === undefined}
							selectedEntityId={selectedEntityId}
							setSelectedEntityId={handleUpdateSelectedEntityId}
							setSelectedEntityName={handleUpdateSelectedEntityName}
							selectedRoleCategory={selectedRoleCategory}
						/>
					</Col>
				</Row>
			)}
			<Row className="justify-content-end mt-4">
				<Col className="text-start">
					<Button color="gray" onClick={handleClose}>
						Close
					</Button>
				</Col>
				<Col className="text-end">
					<Button color="green" disabled={!readyToSubmit} onClick={addRoleToWorkingUser}>
						<FontAwesomeIcon icon={faPlus} />
						&nbsp; Add User Role
					</Button>
				</Col>
			</Row>
		</Popup>
	);
};

export default AddUserRoleModal;
