import { useState, useEffect } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import hash from 'object-hash';

import Styles from '../Styles';
import SearchBar from '../Parts/SearchBar';

const ExistingAddressSummary = ({
	address, useAddress, quoteType
}) => {
	const city_string = (address.city || '') + ((address.state || '').length ? `, ${address.state}` : '') + ((address.zip || '').length ? `, ${address.zip}` : '');

	return (
		<Container className="local-bootstrap">
			<Row>
				<Col xs={6}>
					<Row>Property / Company:</Row>
					<Row>Name:</Row>
					<Row>Address:</Row>
					<Row>City:</Row>
					<Row>Country:</Row>
					<Row>Zip:</Row>
				</Col>
				<Col xs={6}>
					<Row>
						<b style={{ display: "inline-block", minHeight: "16%" }}>
							{address.company_name}
						</b>
					</Row>
					<Row>
						<b style={{ display: "inline-block", minHeight: "17%" }}>
							{`${address.first_name || ""
							} ${
								address.last_name || ""}`}
						</b>
					</Row>
					<Row>
						<b
							style={{
								display: "inline-block",
								minHeight: "17%",
								maxWidth: "232px",
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
							}}
						>
							{address.address == null && address.address2 == null ? (
								<br />
							) : (
								(address.address || "")
								+ (address.address2 ? `, ${address.address2}` : "")
							)}
						</b>
					</Row>
					<Row>
						<b style={{ display: "inline-block", minHeight: "17%" }}>
							{city_string}
						</b>
					</Row>
					<Row>
						<b style={{ display: "inline-block", minHeight: "17%" }}>
							{address.country}
						</b>
					</Row>
					<Row>
						<b style={{ display: "inline-block", minHeight: "16%" }}>
							{address.zip}
						</b>
					</Row>
				</Col>
			</Row>
			<Row>
				<Col className="text-end">
					<button
						type="button"
						className={`btn btn-sm green-button btn-${quoteType} text-white`}
						onClick={() => useAddress(address)}
					>
						Use
					</button>
				</Col>
			</Row>
		</Container>
	);
};

const ExistingAddresses = (props) => {
	const [myAddresses, setMyAddresses] = useState([]);
	const [searchQuery, setSearchQuery] = useState(null);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	function refresh(search_query) {
		api.onLoad.add(async () => {
			const resp = await api[props.getAddressesFunction](page, 15, search_query);
			try {
				setMyAddresses(resp?.data?.addresses?.filter((a) => Object.keys(a).some((key) => a[key])));
				setTotalPages(resp.data.page_count);
			} catch (err) {
				ROOT.alert({
					quickFormat: 'error',
					text: 'Error loading addresses',
				});
				throw new Error(`Error loading addresses ${err}`);
			}
		});
	}

	useEffect(() => {
		refresh(searchQuery);
	}, []);

	return (

		<div>

			<div style={{
				width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '5px', marginTop: '20px'
			}}
			>
				
				<h4 className={`text-${props.quoteType}`}>Existing Addresses</h4>
				<SearchBar quoteType={props.quoteType} style={{ fontSize: '14px' }} onChange={(string) => { setSearchQuery(string.toLowerCase()); refresh(string.toLowerCase()); }} />

			</div>
			
			<div style={{
				height: '300px', overflow: 'auto', padding: '0 5px 0 0', marginTop: '8px'
			}}
			>
				{
					(myAddresses?.length && myAddresses?.length > 0)

						? myAddresses
							.map((a) => (

								<div
									key={hash(a)}
									style={{
										border: `1px solid ${Styles.psGray}`, borderRadius: '3px', marginBottom: '5px', padding: '1em', fontSize: '14px'
									}}
								>
								
									<ExistingAddressSummary
										quoteType={props.quoteType}
										address={a}
										useAddress={props.useAddress}
									/>

								</div>
							))
						: (
							<div style={{ color: Styles.psGray, marginTop: '20px' }}>
								<span style={{ fontSize: '20px' }}>No addresses to display.</span>
								<br />
								<span style={{
									color: Styles.psGray, display: 'inline-block', marginTop: '5px'
								}}
								>
									Would you like to
									{' '}
									<span className={`text-${props.quoteType}`} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={props.createNewAddress}>create a new address</span>
									?
								</span>
							</div>
						)
				}
			</div>

		</div>
		
	);
};

export default ExistingAddresses;
