import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import apiCalls from "../../PowerShadesAPIFunctions";
import type { FabricViewModel } from "../../powershadesApiTypes";

// Get fabrics allowed for interiors
export const loadAllowedIndoorFabrics = createAsyncThunk(
    "fabricsAllowed/loadAllowedIndoorFabrics",
    async (quoteId: number, { rejectWithValue }) => {
        try {
          //  console.log(`🔄 Fetching indoor fabrics for quoteId: ${quoteId}`);
            const response = await apiCalls.getFabricIndoorAllowedList(quoteId);
          //  console.log("✅ API Response (Indoor Fabrics):", response.data.fabrics);
            return { quoteId, fabrics: response.data.fabrics }; 
        } catch (err: any) {
          //  console.error("❌ Error fetching indoor fabrics:", err.message);
            return rejectWithValue({ quoteId, error: err.message }); 
        }
    }
);

// Get Permitted Outdoor Fabrics
export const loadAllowedPsOutdoorFabrics = createAsyncThunk(
    "fabricsAllowed/loadAllowedPsOutdoorFabrics",
    async (quoteId: number, { rejectWithValue }) => {
        try {
            //console.log(`🔄 Fetching outdoor fabrics for quoteId: ${quoteId}`);
            const response = await apiCalls.getFabricPsOutdoorAllowedList(quoteId);
           // console.log("✅ API Response (Outdoor Fabrics):", response.data.fabrics);
            return { quoteId, fabrics: response.data.fabrics }; 
        } catch (err: any) {
            //console.error("❌ Error fetching outdoor fabrics:", err.message);
            return rejectWithValue({ quoteId, error: err.message });
        }
    }
);

export interface FabricsAllowedState {
    allowedIndoorFabrics: Record<number, FabricViewModel[]>; 
    allowedPsOutdoorFabrics: Record<number, FabricViewModel[]>;
    loading: Record<number, boolean>; 
    hasFetched: Record<number, boolean>; 
    error: Record<number, string | null>; 
}

export const initialFabricsAllowedState: FabricsAllowedState = {
    allowedIndoorFabrics: {},
    allowedPsOutdoorFabrics: {},
    loading: {},
    hasFetched: {},
    error: {},
};

const fabricsAllowedSlice = createSlice({
    name: "fabricsAllowed",
    initialState: initialFabricsAllowedState,
    reducers: {},
    extraReducers: (builder) => {
        // 🔹 Indoor Fabrics
        builder.addCase(loadAllowedIndoorFabrics.pending, (state, action) => {
            const quoteId = action.meta.arg;
         //   console.log(`🔄 Redux: Loading indoor fabrics for quoteId: ${quoteId}`);
            state.loading[quoteId] = true;
            state.error[quoteId] = null;
        });

        builder.addCase(loadAllowedIndoorFabrics.fulfilled, (state, action: PayloadAction<{ quoteId: number, fabrics: FabricViewModel[] }>) => {
            const { quoteId, fabrics } = action.payload;
         //   console.log(`✅ Redux: Indoor fabrics received for quoteId: ${quoteId}`, fabrics);

            state.allowedIndoorFabrics[quoteId] = fabrics.length > 0 ? fabrics : [];
            state.hasFetched[quoteId] = true;
            state.loading[quoteId] = false;
        });

        builder.addCase(loadAllowedIndoorFabrics.rejected, (state, action) => {
            const { quoteId, error } = action.payload as { quoteId: number, error: string };
        //    console.error(`❌ Redux: Error fetching indoor fabrics for quoteId: ${quoteId}`, error);

            state.loading[quoteId] = false;
            state.error[quoteId] = error;
            state.hasFetched[quoteId] = true;
        });

        // 🔹 Outdoor Fabrics
        builder.addCase(loadAllowedPsOutdoorFabrics.pending, (state, action) => {
            const quoteId = action.meta.arg;
         //   console.log(`🔄 Redux: Loading outdoor fabrics for quoteId: ${quoteId}`);
            state.loading[quoteId] = true;
            state.error[quoteId] = null;
        });

        builder.addCase(loadAllowedPsOutdoorFabrics.fulfilled, (state, action: PayloadAction<{ quoteId: number, fabrics: FabricViewModel[] }>) => {
            const { quoteId, fabrics } = action.payload;
        //    console.log(`✅ Redux: Outdoor fabrics received for quoteId: ${quoteId}`, fabrics);

            state.allowedPsOutdoorFabrics[quoteId] = fabrics.length > 0 ? fabrics : [];
            state.hasFetched[quoteId] = true;
            state.loading[quoteId] = false;
        });

        builder.addCase(loadAllowedPsOutdoorFabrics.rejected, (state, action) => {
            const { quoteId, error } = action.payload as { quoteId: number, error: string };
        //    console.error(`❌ Redux: Error fetching outdoor fabrics for quoteId: ${quoteId}`, error);

            state.loading[quoteId] = false;
            state.error[quoteId] = error;
            state.hasFetched[quoteId] = true;
        });
    },
});

export const fabricsAllowedReducer = fabricsAllowedSlice.reducer;
