import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
	Card, Col, Container, Form, Row
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import { useSalesPeopleUsers, useTerritoryById } from '../Store/entities/hooks';

import EntityPageHeader from '../Parts/EntityPageHeader';
import TabPaneWithSearchFilter from '../Parts/TabPaneWithSearchFilter';

import type { TerritoryViewProps } from './types';
import type { TabPaneSelections } from '../Parts/types';
import Button from '../Parts/Button';
import { initialTerritoryState, updateTerritory } from '../Store/entities/territories';
import { useModal } from '../Store/ui/hooks';
import { usePrompt } from '../Store/hooks';
import UserSelect from '../Parts/UserSelect';
import { UsePortalDispatch } from '../Store';

const TerritoryView = ({ ROOT }: TerritoryViewProps) => {
	const [searchParams] = useSearchParams();
	const [activeKey, setActiveKey] = useState(0);
	const [searchFilter, setSearchFilter] = useState('');
	const [editing, setEditing] = useState(false);

	const modal = useModal();
	const dispatch = UsePortalDispatch();

	const territoryIdString = searchParams.get('territory_id') ?? '0';
	const territoryId = parseInt(territoryIdString, 10);

	const { setTitle, navigate } = ROOT;

	const selectedTerritory = useTerritoryById(territoryId);
	const [workingTerritory, setWorkingTerritory] = useState(
		selectedTerritory ?? initialTerritoryState()
	);
	const changesHaveBeenMade = useMemo(() =>
		(JSON.stringify(workingTerritory) !== JSON.stringify(selectedTerritory)),
	[workingTerritory, selectedTerritory]);
	const salesPeople = useSalesPeopleUsers();
	
	usePrompt(modal, changesHaveBeenMade);

	const loading = (selectedTerritory && selectedTerritory.loadStatus <= 4) ?? true;

	useEffect(() => {
		window.document.title !== `View Territory | ${selectedTerritory?.name}`
		&& setTitle(`View Territory | ${selectedTerritory?.name}`);
	}, [selectedTerritory, setTitle]);

	useEffect(() => {
		if (
			!editing
			&& territoryId !== 0
			&& selectedTerritory?.id !== 0
			&& selectedTerritory
		) {
			setWorkingTerritory(selectedTerritory);
		}
	}, [selectedTerritory, editing]);

	const saveWorkingEntity = async () => {
		try {
			const updateResponse = await dispatch(updateTerritory(workingTerritory));
			if (updateTerritory.fulfilled.match(updateResponse)) {
				toast.success('Territory updated successfully');
			}
			if (updateTerritory.rejected.match(updateResponse)) {
				const errorMessage = updateResponse.payload
					?? 'Something went wrong trying to save the territory and no error message was provided.';
				setWorkingTerritory(selectedTerritory ?? initialTerritoryState());
				throw new Error(errorMessage);
			}
		} catch (e: any) {
			toast.error('Territory update failed! PowerShades engineering has been notified');
			setWorkingTerritory(selectedTerritory ?? initialTerritoryState());
			throw new Error(e);
		}
	};

	const getTabSelections = (): TabPaneSelections[] => {
		const workingTabPanes: TabPaneSelections[] = [];

		const distributorsTabPane: TabPaneSelections = {
			id: 0,
			title: 'Distributors',
			key: 'distributors_pane',
			filters: [],
			items: [],
		};

		selectedTerritory?.distributors.forEach((distributor) => {
			distributorsTabPane.items.push({
				id: `${distributor?.id}`,
				type: "distributor",
				name: distributor?.name,
				title: distributor?.name,
				onClick: () => {
					navigate(`/Distributor?distributor_id=${distributor?.id}`);
				},
			});
		});

		if (distributorsTabPane.items.length > 0) {
			workingTabPanes.push(distributorsTabPane);
		}

		const dealersTabPane: TabPaneSelections = {
			id: 1,
			title: 'Dealers',
			key: 'dealers_pane',
			filters: [],
			items: [],
		};

		selectedTerritory?.dealers.forEach((dealer) => {
			dealersTabPane.items.push({
				id: `${dealer?.id}`,
				type: "dealer",
				name: dealer?.name,
				title: dealer?.name,
				onClick: () => {
					navigate(`/Dealer?dealer_id=${dealer?.id}`);
				},
			});
		});

		if (dealersTabPane.items.length > 0) {
			workingTabPanes.push(dealersTabPane);
		}
		
		const representativesTabPane: TabPaneSelections = {
			id: 2,
			title: 'Representatives',
			key: 'representatives_pane',
			filters: [],
			items: [],
		};

		selectedTerritory?.representatives.forEach((representative) => {
			representativesTabPane.items.push({
				id: `${representative?.id}`,
				type: "representative",
				name: representative?.name,
				title: representative?.name,
				onClick: () => {
					navigate(`/Representative?representative_id=${representative?.id}`);
				},
			});
		});

		if (representativesTabPane.items.length > 0) {
			workingTabPanes.push(representativesTabPane);
		}

		return workingTabPanes;
	};

	const selections = getTabSelections();

	if (territoryId !== 0) {
		return (
			<Container>
				<Card body className="entity-card">
					<Row>
						<Col>
							<EntityPageHeader
								name={workingTerritory.name}
								editable={editing}
								setEditable={(e) => setEditing(e)}
								loading={loading}
								saveEnabled={changesHaveBeenMade}
								onChange={(fields) => {
									setWorkingTerritory({
										...workingTerritory,
										...fields,
									});
								}}
								onSave={() => {
									saveWorkingEntity();
									setEditing(false);
								}}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={6} className="mb-2">
							<Row>
								<Col>
									<h6>Description</h6>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Control
										as="textarea"
										value={workingTerritory.description}
										disabled={!editing}
										onChange={(e) => {
											setWorkingTerritory({
												...workingTerritory,
												description: e.target.value,
											});
										}}
									/>
								</Col>
							</Row>
						</Col>
						<Col xs={12} lg={6} className="mb-2">
							<Row>
								<Col>
									<h6>MSRP Multiplier</h6>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Control
										value={workingTerritory.msrp_multiplier}
										disabled={!editing}
										onChange={(e) => {
											if (e.target.value === '') {
												setWorkingTerritory({
													...workingTerritory,
													msrp_multiplier: 0,
												});
												return;
											}
											const numberFromString = parseFloat(e.target.value);
											setWorkingTerritory({
												...workingTerritory,
												msrp_multiplier: numberFromString,
											});
										}}
									/>
								</Col>
							</Row>
						</Col>
						<Col xs={12} lg={6} className="mb-2">
							<Row>
								<Col>
									<h6>Shipping Multiplier</h6>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Control
										value={workingTerritory.shipping_multiplier}
										disabled={!editing}
										onChange={(e) => {
											if (e.target.value === '') {
												setWorkingTerritory({
													...workingTerritory,
													shipping_multiplier: 0,
												});
												return;
											}
											const numberFromString = parseFloat(e.target.value);
											setWorkingTerritory({
												...workingTerritory,
												shipping_multiplier: numberFromString,
											});
										}}
									/>
								</Col>
							</Row>
						</Col>
						<Col xs={12} lg={6} className="mb-2">
							<Row>
								<Col>
									<h6>Default Sales Person</h6>
								</Col>
							</Row>
							<Row>
								<Col>
									<UserSelect
										optionsOverride={salesPeople}
										disableOverride={!editing}
										selectedUserId={workingTerritory.default_salesperson_id}
										handleChange={(user) => {
											setWorkingTerritory({
												...workingTerritory,
												default_salesperson_id: user.id,
											});
										}}
									/>
								</Col>
							</Row>
						</Col>
						<Col xs={12} lg={6} className="mb-2">
							<Row>
								<Col>
									<h6>Buy Currency</h6>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Control disabled value={workingTerritory.iso_4217_currency_code_buy} />
								</Col>
							</Row>
						</Col>
						<Col xs={12} lg={6} className="mb-2">
							<Row>
								<Col>
									<h6>Sell Currency</h6>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Control disabled value={workingTerritory.iso_4217_currency_code_sell} />
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className="mt-2 mb-4">
						<Col xs={12} lg={4} className="mb-2">
							<Button
								color={
									workingTerritory.allow_credit_card_payments ? 'green' : 'gray'
								}
								fluid
								radioButton
								disabled={!editing}
								fullWidth
								onClick={() => {
									setWorkingTerritory({
										...workingTerritory,
										allow_credit_card_payments:
											!workingTerritory.allow_credit_card_payments,
									});
								}}
							>
								Credit Card Payments Allowed &nbsp;
								{workingTerritory.allow_credit_card_payments ? (
									<FontAwesomeIcon icon={faCheck} />
								) : (
									<FontAwesomeIcon icon={faTimes} />
								)}
							</Button>
						</Col>
						<Col xs={12} lg={4} className="mb-2">
							<Button
								color={workingTerritory.within_continental_us ? 'green' : 'gray'}
								fluid
								radioButton
								disabled={!editing}
								fullWidth
								onClick={() => {
									setWorkingTerritory({
										...workingTerritory,
										within_continental_us:
											!workingTerritory.within_continental_us,
									});
								}}
							>
								Within Continental USA &nbsp;
								{workingTerritory.within_continental_us ? (
									<FontAwesomeIcon icon={faCheck} />
								) : (
									<FontAwesomeIcon icon={faTimes} />
								)}
							</Button>
						</Col>
						<Col xs={12} lg={4} className="mb-2">
							<Button
								color={workingTerritory.has_chains_banned ? 'green' : 'gray'}
								fluid
								radioButton
								disabled={!editing}
								fullWidth
								onClick={() => {
									setWorkingTerritory({
										...workingTerritory,
										has_chains_banned:
											workingTerritory.has_chains_banned === 0 ? 1 : 0,
									});
								}}
							>
								Chains Banned &nbsp;
								{workingTerritory.has_chains_banned ? (
									<FontAwesomeIcon icon={faCheck} />
								) : (
									<FontAwesomeIcon icon={faTimes} />
								)}
							</Button>
						</Col>
					</Row>
					<TabPaneWithSearchFilter
						key="territory-view"
						loading={loading}
						disabled={false}
						selections={selections}
						searchFilter={searchFilter}
						setSearchFilter={setSearchFilter}
						activeKey={activeKey}
						setActiveKey={setActiveKey}
					/>
				</Card>
			</Container>
		);
	}
	throw new Error('Something went wrong.');
};

export default TerritoryView;
