import React from 'react';

class TextField extends React.Component {
	constructor(props) {
		super(props);

		const {
			name,
			value,
			onFocus,
			placeHolder = '-',
			disabled,
			field_name,
			rowInformation,
			update,
			lowerUpdate,
		} = props;

		this.props = props;

		this.state = {
			value,
			dirty : false
		};

		this.onChange = this.onChange.bind(this);
		this.setValue = this.setValue.bind(this);

		update;

		this.props.lowerAdd && this.props.lowerAdd(this.setValue);
	}

	setValue(new_val) {
		this.setState({ value: new_val });
	}

	onChange(newValue) {
		this.setState({ value: newValue, dirty: true});
	}

	blurred(newValue) {
		this.props.onChange && this.props.onChange(newValue);
		this.props.onBlur && this.props.onBlur(newValue);

		this.setState({ dirty: false});


		// this.props.onChange(props.rowInformation, newValue);
		// this.props.onBlur(props.rowInformation, newValue);
	}

	render() {
		const {
			name,
			onFocus,
			placeHolder = '-',
			disabled,
			field_name,
			handleEnter,
			rowInformation,
			keepOldStyle
		} = this.props;

		const { value: stateValue } = this.state;
		const value = this.state.dirty ? this.state.value : this.props.value;
		return (

			<input
				className={keepOldStyle ? '' : 'form-control'}
				name={field_name}
				disabled={disabled}
				type="text"
				onBlur={(e) => this.blurred(e.target.value)}
				onFocus={onFocus}
				placeholder={placeHolder}
				onChange={(e) => this.onChange(e.target.value)}
				onKeyDown={(e) => e.key === "Enter" && handleEnter(e)}
				value={value || ''}
			/>
		);
	}
}

export default TextField;
