import React, { useState } from 'react';
import {
	Container, Card, Row, Col, FormControl
} from 'react-bootstrap';

import SpinningWheel from '../Parts/SpinningWheel';
import Button from '../Parts/Button';
import type { fakeRootType } from "../FakeRootType";

const wsClient = require('websocket').client; // eslint-disable-line

type CreateNotificationProps = {
	ROOT: fakeRootType;
}

const CreateNotification = ({ ROOT }: CreateNotificationProps) => {
	const [notification, setNotification] = useState({
		title: '',
		description: '',
		link: '',
	});
	const [loading, setLoading] = useState(false);

	const isIncomplete = () => notification.title.length < 1 || notification.description.length < 1;

	const create = () => {
		if (isIncomplete()) {
			return;
		}

		wsClient
			.createGlobalNotification(
				notification.title,
				notification.description,
				notification.link
			)
			.then((resp) => {
				if (!resp.data.success) {
					ROOT.alert({
						title: 'Failed to create notification.',
						text: 'Please reach out to PowerShades Engineering.',
						icon: 'error',
						smallIcon: true,
						noCancelButton: true,
						acceptButtonText: 'Dismiss',
					});
				} else {
					setNotification({
						title: '',
						description: '',
						link: '',
					});
					ROOT.toast('Notification created', { type: 'success' });
				}

				setLoading(false);
			});

		setLoading(true);
	};

	return (
		<Container className="local-bootstrap">
			<Card body className="half-card">
				<Row>
					<Col>
						<h3>Create Global Notification</h3>
					</Col>
				</Row>

				<Row>
					<Col className="text-muted">
						<em>Notification will be visible to all users.</em>
					</Col>
				</Row>

				<Row className="mb-2">
					<Col>
						<label className="d-block" htmlFor="notification-title">
							Title
						</label>
						<input
							className="form-control"
							name="notification-title"
							value={notification.title}
							onChange={(e) =>
								setNotification({ ...notification, title: e.target.value })}
						/>
					</Col>
					<Col>
						<label className="d-block" htmlFor="notification-link">
							Link
						</label>
						<input
							className="form-control"
							name="notification-link"
							value={notification.link}
							onChange={(e) =>
								setNotification({ ...notification, link: e.target.value })}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<label className="d-block" htmlFor="notification-desc">
							Description
						</label>
						<FormControl
							as="textarea"
							className="form-control"
							name="notification-desc"
							value={notification.description}
							onChange={(e) =>
								setNotification({
									...notification,
									description: e.target.value,
								})}
						/>
					</Col>
				</Row>
				<Row className="mt-4 text-end">
					<Col>
						{loading ? (
							<SpinningWheel />
						) : (
							<Button disabled={isIncomplete()} color="green" onClick={create}>
								Create
							</Button>
						)}
					</Col>
				</Row>
			</Card>
		</Container>
	);
};

export default CreateNotification;
