import React from "react";
// import { useAssembliesByQuoteId } from "../Store/entities/assemblies/hooks";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faTrashAlt, faUnlock } from "@fortawesome/free-solid-svg-icons";
import Table from '../Parts/Table';
import { useQuoteById, useQuoteTypeNameByQuoteId, useQuoteTypes } from "../Store/entities/quotes/hooks";
import Button from "../Parts/Button";
import { QuoteTypeNames } from "../powershadesApiTypes";
import type { QuoteData } from "./NewItems/QuoteData";
import { UsePortalDispatch, UsePortalSelector } from "../Store";
import { deleteAssemblies, selectOutdoorAssembliesByQuoteId, setAssembliesUnlocked } from "../Store/entities/assemblies";
import { fakeRootType } from "../FakeRootType";
import COROutdoorTableRow from "./COROutdoorTableRow";
import { useCurrentMultiplier } from "../Store/entities/multipliers/hooks";

type COROutdoorTableProps = {
	quoteId: number;
	unlockShadeBySequenceId: (sequenceId: number) => void;
	quoteData: QuoteData;
};

const COROutdoorTable = (props: COROutdoorTableProps) => {
	const {
		quoteId,
		unlockShadeBySequenceId,
		quoteData
	} = props;

	const outdoorAssemblies = UsePortalSelector(selectOutdoorAssembliesByQuoteId(quoteId));
	const selectedCOROutdoorAssemblies = outdoorAssemblies.filter((assembly) => assembly.selected);

	const quoteMeta = useQuoteById(quoteId);
	const quoteTypeId = quoteMeta?.quote_type_id;
	const quoteTypes = useQuoteTypes();
	const currentQuoteType = (quoteTypes.find((qt) => qt.id === quoteTypeId)?.name ?? "CEDIA") as QuoteTypeNames;
	// const selectedAssemblies =
	const isEditable = quoteMeta.editable;

	const dispatch = UsePortalDispatch();
	const quoteType = useQuoteTypeNameByQuoteId(quoteId);

	const currentMultiplier = useCurrentMultiplier(quoteId);
	const { label, currency } = currentMultiplier;

	const columns: {
		displayName: string;
	}[] = [
		{
			displayName: "Unlock/Select",
		},
		{
			displayName: "Room Name",
		},
		{
			displayName: "Shade Name",
		},
		{
			displayName: "Direction",

		},
		{ displayName: "Fabric" },
		{ displayName: "Side Channels" },
		{
			displayName: "Width",
		},
		{
			displayName: "Height",

		},
		{
			displayName: `${label} (${currency})`
		},
		{
			displayName: "Actions",
		}
	];

	const {
		alert,
		toast
	} = quoteData.quoteLevelVariables().ROOT as fakeRootType;

	const selectedAssemblySeqIds = selectedCOROutdoorAssemblies.map((a) => a.sequence_id);
	const onDelete = () => {
		const deletingShadeCount = selectedCOROutdoorAssemblies.length;
		alert({
			title: 'Are you sure you want to delete this shade?',
			text: `You are about to delete ${deletingShadeCount} shades! There's no way to undo this.`,
			icon: 'warning',
			quoteType,
		})
			.then(() => {
				dispatch(deleteAssemblies({
					quoteId,
					sequenceIds: selectedAssemblySeqIds,
				})).then(() => {
					toast(`${deletingShadeCount} shades deleted`, {
						type: 'success',
					});
				}).catch(() => {
					toast(`Deletion of ${deletingShadeCount} shades failed`, {
						type: 'info',
					});
				});
			})
			.catch(() => {
				toast(`Deletion of ${deletingShadeCount} shades canceled`, {
					type: 'info',
				});
			});
	};

	const massTableAction = (
		<Container className="local-bootstrap">
			<Row className="g-0">
				<Col key="a2">
					<Button
						title="Delete"
						size="sm"
						hoverColor={currentQuoteType}
						fullWidth
						color="light"
						onClick={() => onDelete()}
					>
						<FontAwesomeIcon icon={faTrashAlt} />
					</Button>
				</Col>
			</Row>

		</Container>
	);
	if (outdoorAssemblies.length === 0) {
		return null;
	}

	const isAllCOROutdoorShadesUnlocked = outdoorAssemblies.every((a) => a.unlocked);
	const unlockAllShades = () => {
		dispatch(setAssembliesUnlocked({
			quoteId,
			sequenceIds: outdoorAssemblies.map((a) => a.sequence_id),
			unlocked: true,
		}));
	};

	return (<div
		className="quote-table"
		style={{
			overflowX: 'auto', overflowY: 'hidden', marginBottom: '0.25em'
		}}
	>
		<Table
			quoteType={currentQuoteType}
			className="quote-mass-table"
			noTBody
			style={{
				width: '100%', zIndex: '22', marginBottom: '0.5em'
			}}
		>
			<tbody style={{ zIndex: 900 }}>

				<tr className="bold-row">
					<th className={currentQuoteType} colSpan={7}>
						{/* {buttonAdd ? <div className="table-to-right">{buttonAdd}</div> : null} */}
						<div className={`table-header ${currentQuoteType}`}>Outdoor Shades</div>
					</th>
				</tr>
				<tr>
					<th colSpan={1}>
						Room Name
					</th>
					<th colSpan={1}>
						Shade Name
					</th>
					<th colSpan={1}>
						Shade Type
					</th>
					<th colSpan={1}>
						Fabric
					</th>
					<th colSpan={1}>
						Width
					</th>
					<th colSpan={1}>
						Height
					</th>
					<th colSpan={1}>
						Actions
					</th>
				</tr>
				<tr>
					<th colSpan={1} />
					<th colSpan={1} />
					<th colSpan={1} />
					<th colSpan={1} />
					<th colSpan={1} />
					<th colSpan={1} />
					<th colSpan={1}>{massTableAction}</th>
				</tr>
			</tbody>
		</Table>
		<Table
			quoteType={currentQuoteType}
			className="quote-rows-table-coroutdoor"
			noTBody
			style={{ width: '100%', zIndex: '22' }}
		>
			<tbody>
				<tr className="bold-row">
					<th className={currentQuoteType} colSpan={11}>
						<div className="table-to-right">
							{isEditable && (
								<span title="unlock" onClick={() => (isAllCOROutdoorShadesUnlocked ? () => { } : unlockAllShades())} className=" fa-fw">
									<FontAwesomeIcon
										className="icon-unlock unlock-all"
										transform="x-12 grow-6"
										icon={
											isAllCOROutdoorShadesUnlocked ? faUnlock : faLock
										}
									/>
								</span>
							)}
						</div>
						<div className={`table-header ${quoteType}`}>Outdoor Shades</div>
					</th>
				</tr>
				<tr>
					{columns.map((column) => (
						<th key={column.displayName}>
							{column.displayName}
						</th>
					))}
				</tr>
				{outdoorAssemblies.map((assembly) => (
					<COROutdoorTableRow
						quoteData={quoteData}
						unlockShadeBySequenceId={unlockShadeBySequenceId}
						quoteId={quoteId}
						sequenceId={assembly.sequence_id}
					/>

				))}
			</tbody>
		</Table>

	</div>);
};

export default COROutdoorTable;

