import React, { useState } from 'react';
import type { fakeRootType } from "../FakeRootType";
import ZUpdateTestingAccount from './ZUpdateTestingAccount';
import ZUpdateTestingDeal from './ZUpdateTestingDeal';

type ZUpdateTestingType = {
    ROOT: fakeRootType;
};

const ZUpdateTesting: React.FC<ZUpdateTestingType> = (props: { ROOT: fakeRootType }) => {

    const { ROOT } = props;

    const [currentOption, setCurrentOption] = useState<string>("");

    const options = [
        {
            value: "account",
            label: "Account",
            component: (
                <ZUpdateTestingAccount 
                    ROOT={ROOT}
                />
            )
        },
        {
            value: "",
            label: "Select an option",
            component: null
        },
        {
            value: "deal",
            label: "Deal",
            component: (
                <ZUpdateTestingDeal
                    ROOT={ROOT}
                />
            )
        }
        
    ]

    const currentComponent = options.find(option => option.value === currentOption)?.component ?? null;

    return (
        <div className="admin-panel-buttons">
            <select
            value={currentOption}
            onChange={(e) => setCurrentOption(e.target.value)}>
                {options.map((option, index) => {
                    return (
                        <option key={index} value={option.value}>{option.label}</option>
                    )
                })}
            </select>
            <div>
                {currentComponent}
            </div>
        </div >
    );
};

export default ZUpdateTesting;