import { faEdit, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useIsPowerShadesAdmin } from '../hooks';
import Button from '../Parts/Button';
import Money from '../Parts/Money';
import TableComponent from './NewItems/TableComponent';
import QUOTE_GLOBALS from './QuoteGlobals'; // 'Quote/QuoteGlobals';
import { useFullHWRItemsByQuoteId, useHWRItemsByQuoteId } from '../Store/entities/hardwareRequestItems/hooks';

const PartsRow = ({
	deleteItem, update, isEditable, hwItem
}) => {
	let {
		cost = 0.0, msrp = 0.0,  uom
	} = hwItem;
	const {
		part_number, quantity, line_number: lineNumber, description, shipping
	} = hwItem;
	cost = cost ?? 0.0;
	msrp = msrp ?? 0.0;

	const totalShipping = shipping;

	const isPsAdmin = useIsPowerShadesAdmin();

	const fullParts = useFullHWRItemsByQuoteId(hwItem.quote_id);
	const fullPart = fullParts.find((p) => p.partNumber === part_number);
	const name = description;

	const multiplier = 1;
 
	const currenctMultiplier = 1;

	const [isEditing, setIsEditing] = useState(false);
	const displayMsrp = msrp.toString()
	const [displayQuantity, setDisplayQuantity] = useState(quantity.toString());


	const saveHWItem = () => {
		const convertedAmount = parseFloat(displayMsrp);

		let savingAmount = convertedAmount;

		if (convertedAmount !== displayMsrp * 1) {
			savingAmount = msrp;
		}

		const convertedQuantity = parseFloat(displayQuantity);

		let savingQuantity = convertedQuantity;

		if (convertedQuantity !== displayQuantity * 1) {
			savingQuantity = quantity;
		}

		// quoteCall
		update(lineNumber, savingQuantity / (multiplier * currenctMultiplier), cost, savingAmount);

		setDisplayQuantity(savingQuantity.toString());

		setIsEditing(false);
	};

	const editHwItem = () => {
		setIsEditing(true);
	};

	const deleteHWItem = () => {
		deleteItem(lineNumber);
	};

	return (
		<tr key={`${lineNumber}`}>
			<td>
				{isEditing ? (
					<input
						value={displayQuantity}
						onChange={(e) => {
							setDisplayQuantity(e.target.value);
						}}
					/>
				) : (
					displayQuantity
				)}
			</td>
			<td>{uom}</td>
			<td>{part_number}</td>
			<td>{name }</td>
			
			<td>
				<Money value={displayMsrp} />
			</td>
			{isPsAdmin ?<td>
				<Money value={totalShipping * quantity *  currenctMultiplier} />
			</td> : null }
			<td>
				<Money value={quantity * msrp * multiplier * currenctMultiplier} />
			</td>
			{isEditable ? (
				<td>
					<span className="local-bootstrap">
						<Row className="g-0">
							{isEditing ? (
								<Col>
									<Button onClick={saveHWItem} fullWidth color="light" size="sm">
										<FontAwesomeIcon icon={faSave} />
									</Button>
								</Col>
							) : (
								<Col>
									<Button onClick={editHwItem} fullWidth color="light" size="sm">
										<FontAwesomeIcon icon={faEdit} />
									</Button>
								</Col>
							)}
							<Col>
								<Button onClick={deleteHWItem} fullWidth color="light" size="sm">
									<FontAwesomeIcon icon={faTrashAlt} />
								</Button>
							</Col>
						</Row>
					</span>
				</td>
			) : null}
		</tr>
	);
};

const PartsTable = ({
	isEditable, hwRequestItems, deleteItem, update, quoteType,quoteId
}) => {

	const globalParts = useFullHWRItemsByQuoteId(quoteId);

	const isPsAdmin = useIsPowerShadesAdmin();

	const rows = hwRequestItems.map((hw) => {
		let hwItemToUse = { ...hw };
		if (globalParts) {
			const hwPart = globalParts.find((p) => p.partNumber  === hwItemToUse.part_number);

			const uom = hwPart ? hwPart.uom : '';
			const description = hwPart ? hwPart.name : '';
			hwItemToUse.uom = uom;
			hwItemToUse.description = description;
		}

		return (
			<PartsRow
				quoteType={quoteType.toLowerCase()}
				update={update}
				deleteItem={deleteItem}
				key={hwItemToUse.line_number}
				hwItem={hwItemToUse}
				isEditable={isEditable}
			/>
		);
	});

	const columns = [
		{
			display: 'Quantity',
		},
		{
			display: 'Unit of Measurement',
		},
		{
			display: 'SKU',
		},
		{
			display: 'Description'
		},

	];

	columns.push(

		{
			display: 'MSRP',
		}
	);

	
	if (isPsAdmin) {
		columns.push({
			display: 'Total Shipping'
		});
	}

	columns.push(

		{
			display: 'Total',
		},
	);

	if (isEditable) {
		columns.push({
			display: 'Actions',
		});
	}

	return (
		<TableComponent
			quoteType={quoteType}
			rows={rows}
			columns={columns}
			key="ItemsParts"
			ISO={{
				idk: 0,
			}}
			title="Hardware Requests"
		/>
	);
};

export default PartsTable;
