import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faWarning } from "@fortawesome/free-solid-svg-icons";
import { faHouseBuilding, faPersonDolly, faTruckBolt, faTruckRamp } from "@fortawesome/pro-solid-svg-icons";
import { trim } from "lodash";

import type { AddressBlockProps } from "./types";

import style from "./AddressBlock.module.scss";
import AddressBlockIconButton from "./AddressBlockIconButton";
import { IconProp } from "@fortawesome/fontawesome-svg-core";


const AddressBlock = ({ shippingAddress = false, address, locked, preShipWhips, shipOrder }: AddressBlockProps) => {
	const workingAddress = Array.isArray(address) ? undefined : address;
	const missingAddress = !workingAddress;
	const displayShippingButtons = shippingAddress && workingAddress;

	const addressType = shippingAddress ? "Shipping" : "Installation";

	if (preShipWhips === undefined && shippingAddress) throw new Error("preShipWhips needs to be defined if shippingAddress is true");
	if (shipOrder === undefined && shippingAddress) throw new Error("shipOrder needs to be defined if shippingAddress is true");
	
	return (
		<Card
			className={`${style.addressCard} ${missingAddress && style.missing} ${
				locked && style.locked
			} address-block-card`}
		>
			<Card.Body>
				<Row>
					<Col xs={12}>
						<Card.Title className={missingAddress ? "text-danger" : ""}>
							{addressType} Address
						</Card.Title>
						<Card.Text>
							{missingAddress ? (
								!locked && (
									<div className="text-muted fst-italic">
										<FontAwesomeIcon icon={faPlus} />
										&nbsp;Add / Lookup Address...
									</div>
								)
							) : (
								<>
									<Row>
										<Col>
											{workingAddress?.address}{" "}
											{workingAddress?.address2 && `- ${workingAddress.address2}`}
										</Col>
									</Row>
									<Row>
										<Col>
											{trim(workingAddress?.city)}, {workingAddress?.state} {workingAddress?.zip}
										</Col>
									</Row>
								</>
							)}
						</Card.Text>
					</Col>
				</Row>
				{displayShippingButtons && (
					<Row className="icon-row">
						<Col>
							<AddressBlockIconButton
								description="Address is a residential address?"
								icon={faHouseBuilding  as IconProp}
								locked={locked}
								conditional={workingAddress?.is_residential}
							/>
						</Col>
						<Col>
							<AddressBlockIconButton
								description="Lift gate required for delivery?"
								icon={faTruckRamp  as IconProp}
								locked={locked}
								conditional={Boolean(workingAddress?.lift_gate)}
							/>
						</Col>
						<Col>
							<AddressBlockIconButton
								description="Ship whips before entire order is ready?"
								icon={faTruckBolt  as IconProp}
								locked={locked}
								conditional={preShipWhips}
							/>
						</Col>
						<Col>
							<AddressBlockIconButton
								description="Customer will pick up order?"
								icon={faPersonDolly  as IconProp}
								locked={locked}
								conditional={!shipOrder}
							/>
						</Col>
					</Row>
				)}
			</Card.Body>
			{missingAddress && (
				<Card.Footer className={style.footer}>
					<Row>
						<Col xs={1}>
							<FontAwesomeIcon icon={faWarning} />
						</Col>
						<Col>{addressType} address is missing!</Col>
					</Row>
				</Card.Footer>
			)}
		</Card>
	);
};

export default AddressBlock;
