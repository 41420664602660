import { useState, useEffect } from 'react';
import Money from '../../Parts/Money';
import { useShadeErrors } from '../../Shade';
import ShadeFormOptions from './ShadeFormOptions';

export default function ShadeForm(props) {
	const { quote, shade } = props;

	let unmounting = false;

	const [fetchingPrice, setFetchingPrice] = useState(false);
	const [msrp, setMSRP] = useState(null);
	const shadeErrors = useShadeErrors(shade);

	function fetchPrice() {
		if (shade.hasRequiredPricingFields()) {
			api.calculateShadeMSRPForQuote(quote.ID, shade.savableObject())
				.then(({ data }) => {
					if (data.constructor === "".constructor) {
						const spotIndexA = data.indexOf('{"success"');
						const spotIndexB = data.indexOf('{"msrp"');

						let spotIndex = spotIndexA > spotIndexB ? spotIndexB : spotIndexA;

						if (spotIndexA > spotIndexB) {
							spotIndex = spotIndexB;
							spotIndex = spotIndexB === -1 ? spotIndexA : spotIndex;
						} else {
							spotIndex = spotIndexA;
							spotIndex = spotIndexA === -1 ? spotIndexB : spotIndex;
						}
						const cleanData = data.substring(spotIndex);
						data = JSON.parse(cleanData);
					}

					unmounting || setMSRP(data.msrp.total_msrp);

					if (data.msrp.errors) {
						shade.setTemporaryCalculationErrors(data.msrp.errors);
					} else {
						shade.clearTemporaryCalculationErrors();
					}

					unmounting || setFetchingPrice(false);
				});

			setFetchingPrice(true);
		}
	}

	useEffect(() => {
		fetchPrice();

		return () => unmounting = true;
	}, []);

	const errors = shadeErrors.map((err) => (

		<div key={`error-${err}`} className="error" style={{ width: '100%' }}>

			{err}
			<br />

		</div>
	));

	return (

		<>

			{
				shade
					? (
						<ShadeFormOptions
							setFocusedOption={props.setFocusedOption}
							onOptionChange={fetchPrice}
							shade={shade}
							quote={quote}
						/>
					)
					:					null
			}

			{
				errors.length > 0

					? errors
					:					(
						<div className="shade-price">

							{
								msrp
									? <Money>{msrp}</Money>
									:								null
							}

						</div>
					)
			}

		</>
	);
}
