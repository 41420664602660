import { ReactNode } from 'react';
import { RouterNavigateOptions } from '@remix-run/router';
import { NavigateOptions, To } from 'react-router';
import { NavigateFunction } from 'react-router-dom';
import { toast as toastify, ToastOptions } from 'react-toastify';
import { AppStore } from './Store';
// import HandleToast from './Parts/Toast';
import { pageLoaded, pageLoading } from './Store/ui';
import { fakeRootType , AlertProps } from './FakeRootType';
// import { ToastOptions } from './types';



const FakeRoot: (store: AppStore) => fakeRootType = (store) => {
	const { dispatch } = store;

	// This is where the navigate function is stored in the api here.
	let _navigateFunc: ((to: To, opts?: RouterNavigateOptions) => void) | null = null;

	// The method with any null checks already handled
	const navigate : NavigateFunction = (content: To | number, options? :NavigateOptions) =>
		(_navigateFunc && _navigateFunc(content as To, options))
		?? ((_to: To, _opts?: RouterNavigateOptions) => { /* Default Function */ });

	// The function that needs to be set for the ROOT api to navigate.
	const withNavigate = (navigateFunc: (to: To, opts?: RouterNavigateOptions) => void) => {
		_navigateFunc = navigateFunc;
	};

	// const GET: { [key: string]: string } = {};

	let _alertFunc: ((props: AlertProps) => Promise<void>) | null = null;

	const alert = (_props: AlertProps) =>
		(_alertFunc && _alertFunc(_props))
		?? (new Promise((resolve) => { resolve(); }));

	const withAlert = (alertFunc: (props: AlertProps) => Promise<void>) => {
		_alertFunc = alertFunc;
	};

	// Set up like navigate func above but for the method that creates the quote, and routes the page.
	let _createQuoteFunc: ((callback: () => void) => void) | null = null;

	const createQuote = (callback?: () => void) =>
		((_createQuoteFunc && _createQuoteFunc(callback || (() => {})))
		?? ((_callback: () => void) => { /* Default Function */ }));

	const withCreateQuote = (createQuoteFunc: (callback: () => void) => void) => {
		_createQuoteFunc = createQuoteFunc;
	};

	let _setTitleFunc: ((title : string) => void) | null = null;

	const setTitle = (title : string) =>
		(_setTitleFunc && _setTitleFunc(title));

	const withSetTitle = (setTitleFunc: (title : string) => void) => {
		_setTitleFunc = setTitleFunc;
	};

	const loadGetVars = (url) => {
		let cleanUrl = url;

		if (cleanUrl.includes('#')) {
			// Because esLint told me so.
			[, cleanUrl] = cleanUrl.split('#');
		}

		// Function used to parse url GET parameters.
		const $_GET: { [key: string]: string } = {};

		if (!cleanUrl.includes('?')) {
			// If the current url doesn't have a question mark in it,
			// then we know that it doesn't have any GET variables in it. End function.
			return {};
		}

		const split = cleanUrl.split('?')[1].split('&') as string[];

		for (let i = 0; i < split.length; i++) {
			const currentSplit = split[i] ?? '';
			if (!split[i]?.includes('=')) {
				$_GET[currentSplit] = '';
			} else {
				const [key = '', value] = currentSplit.split('=');
				$_GET[key] = value ?? '';
			}
		}

		return $_GET;
	};

	// const GET = loadGetVars(window.location.hash) ?? {};
	// console.log(GET);

	// getParms.forEach((key,value) => {
	//     GET[key] =value;
	// })

	// type getType = { [key: string]: string };
	// const GET: getType = getParms.entries();

	type loadedType = () => void;
	const loaded: loadedType = () => {
		dispatch(pageLoaded());
	};

	type loadingType = () => void;
	const loading: loadingType = () => {
		dispatch(pageLoading());
	};

	const loadContent = (url: string) => {
		// First we must clean the hash
		const cleanUrl = url.replace("/#/", "/").replace('#', '/');
		// Set everything
		navigate(cleanUrl);
	};

	const refreshContent = () => { /* Default  */ };

	const toast = (content: string | ReactNode, toastOptions: ToastOptions) => {
		toastify(content, toastOptions);
	};

	const setContent = loadContent;

	const sendHome = () => {
		navigate('/Home');
	};

	const fakeROOT: fakeRootType = {
		GET: {},
		setTitle,
		loaded,
		loading,
		loadContent,
		alert,
		refreshContent,
		toast,
		setContent,
		createQuote,
		sendHome,
		withNavigate,
		withAlert,
		withCreateQuote,
		withSetTitle,
		navigate
	};

	Object.defineProperty(fakeROOT, 'GET', {
		get() {
			const goods = loadGetVars(window.location.hash);
			// console.log(goods);
			return goods;
		},
	});

	return fakeROOT;
};

export default FakeRoot;