import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import api from '../PowerShadesAPI';
import MfgStatusSelect from '../Parts/MfgStatusSelect';
import { ShipmentModalRow, ShipmentModalHeadRow } from './ShipmentModalRows';
import ShadeSummary from './ShadeSummary';
import Button from '../Parts/Button';

const ShadeStatusRow = ({ shade, viewShade, quote, onStatusChange, defaultStatus }) => {
	const [mfgStatus, setMfgStatus] = useState(shade.mfg_status);
	const [previousDefaultStatus, setPreviousDefaultStatus] = useState(defaultStatus);

	useEffect(() => {
		if (defaultStatus != previousDefaultStatus) {
			setMfgStatus(defaultStatus);
			setPreviousDefaultStatus(defaultStatus);
		}
	});

	function statusChanged(new_status) {
		setMfgStatus(new_status);
		onStatusChange(shade, new_status);
	}

	return (
		<tr>
			<td>{shade.room_name}</td>
			<td>{shade.shade_name}</td>
			<td>
				<MfgStatusSelect
					key={mfgStatus}
					initialStatus={mfgStatus}
					onChange={statusChanged}
				/>
			</td>

			<td>
				<FontAwesomeIcon icon={faEye} onClick={viewShade} />
			</td>
		</tr>
	);
};

const ShadeManufacturing = (props) => {
	const [viewingShade, setViewingShade] = useState(null);
	const [quote, setQuote] = useState(props.quote);
	const [markAllStatus, setMarkAllStatus] = useState('');

	function updateStatus(shade, new_status) {
		shade.mfg_status = new_status;

		api.setShadeAssemblyMfgStatus(quote.ID, shade.id, new_status);

		setQuote(props.quote);

		props.onUpdate && props.onUpdate();
	}

	function markAll(new_status) {
		quote.shade_assemblies.forEach((shade) => updateStatus(shade, new_status));
		setMarkAllStatus(new_status);
	}

	const shade_rows = quote.shade_assemblies.map((shade) => (
		<ShadeStatusRow
			key={shade.id}
			viewShade={() => setViewingShade(shade)}
			quote={quote}
			shade={shade}
			defaultStatus={markAllStatus}
			onStatusChange={(shade, new_status) => updateStatus(shade, new_status)}
		/>
	));

	return (
		<div className="modal-holder">
			<div className="shipment-modal">
				<h1>Shade Manufacturing</h1>
				<h2>Order #{quote.ID}</h2>

				<table className="order-confirmation-table" cellSpacing="0">
					<tbody>
						<ShipmentModalHeadRow quote={quote} />
						<ShipmentModalRow quote={quote} />
					</tbody>
				</table>

				<div style={{ marginTop: '0.5em', textAlign: 'right' }}>
					<select
						value={markAllStatus}
						onChange={(e) => e.target.value.length > 0 && markAll(e.target.value)}
					>
						<option value="">- Mark All -</option>
						<option value="pending">Pending</option>
						<option value="in_progress">In Progress</option>
						<option value="complete">Complete</option>
					</select>
				</div>

				<table className="order-confirmation-table" cellSpacing="0">
					<tbody>
						<tr className="bold-row">
							<th>Room Name</th>
							<th>Shade Name</th>
							<th>Mfg. Status</th>
							<th>View</th>
						</tr>

						{shade_rows}
					</tbody>
				</table>

				<br />

				<div className="align-right">
					<Button color="green" onClick={props.onClose}>
						Done
					</Button>
				</div>

				<br />
			</div>

			{viewingShade ? (
				<ShadeSummary
					shade={viewingShade}
					quote={quote}
					onClose={(e) => setViewingShade(null)}
				/>
			) : null}
		</div>
	);
};

export default ShadeManufacturing;
