import { createSelector } from "reselect";
import { useCallback } from "react";
import store, { AppState, UsePortalDispatch, UsePortalSelector } from "../..";
import { addRma, deleteRma, editRma, loadRmasFull } from ".";
import { selectCurrentUserData } from "../../user/userPermissions";
import { RMAItems } from "../../../Quote/OrderModal/PaymentMethods/RMAPayment/types";

/**
 * Selects RMAs related to a quote
 * @returns RMAs related to a quote
 */
const selectRmaList = createSelector(
	(state: AppState, quoteId: number) => {
		let dispatched = false;
		if (state.entity.rmas.currentQuoteId !== quoteId && !dispatched) {
			dispatched = true;
			store.dispatch(loadRmasFull(quoteId));
		}
		return state.entity.rmas.list;
	},
	(rmas) => rmas
);
  
/**
 * Selects the RMAs and dispatches a load if needed
 * @returns The list of RMAs if user is a ps admin, otherwise an empty array
 */
export const selectRmasAndDispatch = createSelector(
	[(state: AppState, quoteId: number) => selectRmaList(state, quoteId), selectCurrentUserData],
	(rmas, userData) => {
		const userRequestingIsPsAdmin = userData?.roles.some((role) => role.role_name === "powershades_admin");

		if (userRequestingIsPsAdmin) return rmas;

		return rmas;
	}
);

/**
 * Custom hook that returns a list of RMAs if the requesting user is a PSadmin,
 * otherwise returns an empty array.
 * @returns a list of RMAs
 */
export const useRmas = (quoteId: number) => UsePortalSelector((state: AppState) => selectRmasAndDispatch(state, quoteId));

/**
 * Custom hook that checks status of loading RMAS.
 * @returns a rmas loadStatus.
 */
export const useRmaLoadStatus = () => UsePortalSelector((state: AppState) => state.entity.rmas.loadStatus);

export const useAddRma = () => {
	const dispatch = UsePortalDispatch();
	const addRmaLoadStatus = useRmaLoadStatus();

	const handleAddRma = useCallback(
		async (newRma: RMAItems) => {
			const result = await dispatch(addRma(newRma));
			return result.payload;
		},
		[dispatch]
	);

	return {
		addRma: handleAddRma,
		addRmaLoadStatus,
	};
};

export const useEditRma = () => {
	const dispatch = UsePortalDispatch();
	const editRmaLoadStatus = useRmaLoadStatus();
  
	const handleEditRma = useCallback(
		(updatedRma) => {
			dispatch(editRma(updatedRma));
		},
		[dispatch]
	);
  
	return {
		editRma: handleEditRma,
		editRmaLoadStatus,
	};
};

export const useDeleteRma = () => {
	const dispatch = UsePortalDispatch();
	const deleteRmaLoadStatus = useRmaLoadStatus();
  
	const handleDeleteRma = useCallback(
		async (rmaId) => {
			const result = await dispatch(deleteRma(rmaId));
			return result.payload;
		},
		[dispatch]
	);
  
	return {
		deleteRma: handleDeleteRma,
		deleteRmaLoadStatus,
	};
};
