import { useState } from 'react';

import api from '../PowerShadesAPI';
import Button from '../Parts/Button';
import { setQuoteToEditable, wentToQuote } from '../Store/entities/quotes';
import { UsePortalDispatch } from '../Store';

const ForceEditModal = ({ quote, close, setEditable }) => {
	const [reason, setReason] = useState('');

	const dispatch = UsePortalDispatch();

	const unlockQuote = () => {
		if (reason.trim().length < 2) return;
		
		api.unlockedQuote(quote.id, reason);
		dispatch(wentToQuote(quote.id));
		dispatch(setQuoteToEditable(quote.id));
		// props.quote.unlock(props.setEditable ? props.setEditable : () => {});
		setEditable();
		// close();
	};

	return (

		<div className="modal-holder">
					
			<div className="quote-history-modal force-edit-modal">
				
				<h2>
					Edit
					{' '}
					{ quote.is_order ? 'Order' : 'Quote' }
					{' '}
#
					{quote.ID}
				</h2>

				<span className="info-span">
					This quote is locked and should only be edited under special circumstances.
					<br />
				  	If you wish to continue, please provide a reason for editing the quote.
				</span>

				<div className="reason">
					<label name="reason">Reason:</label>
					<textarea
						name="reason"
						placeholder="Customer Request, Invalid Shade, etc.."
						onChange={(e) => setReason(e.target.value)}
						value={reason}
					/>
				</div>

				<div className="button-holder">
					<Button color="gray" onClick={close}>Cancel</Button>
					<Button disabled={reason.trim().length < 2} color="green" onClick={unlockQuote}>Edit Quote</Button>
				</div>

			</div>

		</div>
	);
};

export default ForceEditModal;
