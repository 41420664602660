import React, { useState, useEffect } from 'react';
import {
	Container, Row, Col, Form, InputGroup, Card
} from 'react-bootstrap';
import Popup from 'reactjs-popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import EntityEmailsListing from '../Home/EntityEmailsListing';
import Table from '../Parts/Table';
import Button from '../Parts/Button';
import apiCalls from '../PowerShadesAPIFunctions';
import {  User } from '../powershadesApiTypeExtensions';
import { EntityListFullResp } from '../powershadesApiTypes';
import PowerShadesTypeahead from '../Parts/PowerShadesTypeahead';

type QuoteEventsProps = {
	setTitle: (title: string) => void;
	loading: () => void;
	loaded: () => void;
};

type ContextQuoteEvent = {
    date: string;
    id?: number;
    key?: string;
    user_id?: number;
    user: User;
    quote: any;
    quote_id: number;
    button_pressed: string;
    email: string;
    user_name: string;
};

const QuoteEvents = (props: QuoteEventsProps) => {
	const [selectedStartDate, setSelectedStartDate] = useState('');
	const [selectedEndDate, setSelectedEndDate] = useState('');
	const [selectedUserId, setSelectedUserId] = useState(0);
	const [selectedQuoteId, setSelectedQuoteId] = useState(0);
	const [tempSelectedQuoteId, setTempSelectedQuoteId] = useState('');
	const [selectedKeyId, setSelectedKeyId] = useState('');
	const [settingQuoteId, setSettingQuoteId] = useState(false);
	const [quoteEvents, setQuoteEvents] = useState<ContextQuoteEvent[]>([]);
	const [loadingFilteredData, setLoadingFilteredData] = useState(false);

	const [errorTitle, setErrorTitle] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const [users, setUsers] = useState<{
		label: string;
		value: number;
		key: number;
	}[]>([]);
	const [keys, setKeys] = useState<{
		label: string;
		value: string;
		key: string;
	}[]>([]);

	const [entityType, setEntityType] = useState("");
	const [entityId, setEntityId] = useState(0);

	const selectedUser = users.find((user) => user.value === selectedUserId);


	const [showEntityList, setShowEntityList] = useState<EntityListFullResp | null>(null);

	const clearSelectedId = () => {
		setSettingQuoteId(false);
		setTempSelectedQuoteId('');
	};

	const clearError = () => {
		setErrorTitle('');
		setErrorMessage('');
	};

	const setError = (errorTitle, errorMessage) => {
		setErrorTitle(errorTitle);
		setErrorMessage(errorMessage);
	};
	const handleKeyPress = (e) => {
		if (e.which === 13) {
			handleSubmit();
		}
	};

	const handleSubmit = () => {

		const quoteId = isNaN(parseInt(tempSelectedQuoteId)) ? 0 : parseInt(tempSelectedQuoteId);

		setSelectedQuoteId(quoteId);
		clearSelectedId();
	};

	const handleDate = (attemptedDate, startOrEnd) => {
		// grabbing todays date
		let today = new Date();
		const dd = String(today.getDate()).padStart(2, '0');
		const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
		const yyyy = today.getFullYear();

		let todayStr = `${yyyy}-${mm}-${dd}`;
		let hasError = false;
		if (attemptedDate === 'Invalid Date') {
			switch (startOrEnd) {
				case 'start':
					setSelectedStartDate('');
					break;
				case 'end':
					setSelectedEndDate('');
					break;
			}
			return;
		}
		console.log(`Comparing selected date (${attemptedDate}) to todaysDate (${todayStr})`);
		if (startOrEnd === 'start' && selectedEndDate !== '' && attemptedDate > selectedEndDate) {
			hasError = true;
			setError('Invalid Date', 'The date selected cannot be before selected end date.');
		}

		switch (startOrEnd) {
			case 'start':
				if (selectedEndDate !== '') {
					if (attemptedDate > selectedEndDate) {
						hasError = true;
						setError(
							'Invalid Date',
							'The date selected cannot be after selected end date.'
						);
					}
				}
				break;
			case 'end':
				if (selectedStartDate !== '') {
					if (attemptedDate < selectedStartDate) {
						hasError = true;
						setError(
							'Invalid Date',
							'The date selected cannot be before selected start date.'
						);
					}
				}
				break;
		}
		if (hasError === true) {
			console.log('error found');
			return;
		}
		switch (startOrEnd) {
			case 'start':
				console.log(`setting start date to ${attemptedDate}`);
				setSelectedStartDate(attemptedDate);
				break;

			case 'end':
				console.log(`setting end date to ${attemptedDate}`);
				setSelectedEndDate(attemptedDate);
				break;
		}
	};

	useEffect(() => {
		props.setTitle('Quote Events');

		apiCalls.getAccessableEntities().then((resp) => {
			const entities = resp.data;

			const { dealers, distributors, representatives } = entities;

			const totalAmount = (dealers?.length ?? 0) + (distributors?.length ?? 0) + (representatives?.length ?? 0);

			if (totalAmount == 1) {
				// Redirect
				let entityType = '';
				let id = 0;

				if (dealers && (dealers?.length ?? 0) > 0) {
					entityType = 'Dealers';
					id = dealers[0]?.id ?? 0;
				} else if (distributors && (distributors?.length ?? 0) > 0) {
					entityType = 'Distributors';
					id = distributors[0]?.id ?? 0;
				} else if (representatives && (representatives?.length ?? 0) > 0) {
					entityType = 'Representatives';
					id = representatives[0]?.id ?? 0; 
				}

				setEntityId(id);
				setEntityType(entityType);
				props.loading();
			} else {
				setShowEntityList(entities);
				props.loaded();
			}
		});

		apiCalls.getQuoteEventFilters().then((resp) => {
			const tempResponse = resp.data;
			const tempKeys = tempResponse.keys;
			const tempUsers = tempResponse.users;
			// Thanks Lucy I suck at programming :quote_ids.map(q_id => ({label : q_id, value : q_id, key: q_id}))

			const labeledKeys = tempKeys.map((keyName) => ({
				label: keyName,
				value: keyName,
				key: keyName,
			}));

			const labeledUsers = tempUsers.map((user) => ({
				label: user.user_email,
				value: user.user_id,
				key: user.user_id,
			}));

			setKeys(labeledKeys);
			setUsers(labeledUsers);
		});
	}, []);

	useEffect(() => {
		let formattedStartDate = '';
		let formattedEndDate = '';

		if (
			selectedStartDate !== '' ||
			selectedEndDate !== '' ||
			entityId !== 0 ||
			selectedUserId !== 0 ||
			selectedQuoteId !== 0 ||
			selectedKeyId !== ''
		) {
			console.log('filtering');
		} else {
			// api.getAllQuoteEvents().then((resp) => {
			// 	props.loaded();
			// 	const tempQuotes = resp.data.quote_events;
			// 	const dateConverter = (date) => {
			// 		const formattedDate = new Date(date);
			// 		const newDate = new Date();
			// 		newDate.setTime(formattedDate.getTime()); // A really bad timezone shift that doesn't tak into account daylight savings
			// 		return formattedDate.toLocaleString();
			// 	};

			// 	const quotesWithReadableDates = tempQuotes.map((quotes) => ({
			// 		...quotes,
			// 		date: dateConverter(quotes.date),
			// 	}));
			// 	setQuoteEvents(quotesWithReadableDates);
			// });
		}
		const startDateDirty = new Date(Date.now()).setHours(-29,0,0,0);
		formattedStartDate =
			selectedStartDate === "" ? `${new Date(startDateDirty).toISOString()}` : `${selectedStartDate}T00:00:00`;
		const endDateDirty = new Date(Date.now()).setHours(66,59,59,999);
		formattedEndDate =
			selectedEndDate === ""
				?
				`${new Date(endDateDirty).toISOString()}`
				: `${selectedEndDate}T23:59:59`;

		// if (filtered === true) {
			console.log('filter returned true');
			apiCalls.getQuoteEventsFiltered(
				entityId,
				entityType,
				selectedUserId,
				selectedQuoteId,
				selectedKeyId,
				formattedStartDate,
				formattedEndDate
			).then((resp) => {
				setLoadingFilteredData(false);
				const tempQuotes = resp.data.quote_events;
				const dateConverter = (date) => {
					const formattedDate = new Date(date);
					return formattedDate.toLocaleString();
				};

				const quotesWithReadableDates = tempQuotes.map((quotes) => ({
					...quotes,
					date: dateConverter(quotes.date),
				}));

				console.log(quotesWithReadableDates);

				setQuoteEvents(quotesWithReadableDates);
			});
			setLoadingFilteredData(true);
		// }

		/*
        		apiPost(`QuoteEvents`, {
			entity_id: entity_id,
			entity_type: entity_type,
			user_id: user_id,
			quote_id: quote_id,
			button_pressed: button_pressed,
			start_date: start_date,
			end_date: end_date,
		}),
        */
	}, [
		selectedStartDate,
		selectedEndDate,
		entityId,
		selectedUserId,
		selectedQuoteId,
		selectedKeyId,
	]);
	let key = 0;
	const quoteEventRows = quoteEvents.map((qe) => {
		key += 1;
		return (
			<tr key={key + qe.quote_id + qe.button_pressed + qe.date}>
				<td>{`${qe.date}`}</td>
				<td>{qe.email}</td>
				<td>{qe.user_name}</td>
				<td>{qe.quote_id}</td>
				<td>{qe.button_pressed}</td>
			</tr>
		);
	});

	return (
		<div className="local-bootstrap">
			<Popup
				contentStyle={{ width: '50vh', height: '40vh' }}
				open={errorMessage !== '' || errorTitle !== ''}
				onClose={() => clearError()}
			>
				<Container className="local-bootstrap">
					<Row style={{ marginBottom: '2vh', marginTop: '2vh' }}>
						<Col xs={12} className="text-center">
							<FontAwesomeIcon
								style={{ fontSize: '10vh' }}
								color="#e30000"
								icon={faTimesCircle}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<h5 className="green-text">{errorTitle}</h5>
						</Col>
					</Row>
					<Row>
						<Col>{errorMessage}</Col>
					</Row>
					<Container
						style={{
							position: 'absolute',
							bottom: 0,
							left: 0,
							float: 'right',
						}}
					>
						<Row
							style={{
								marginBottom: '1vh',
							}}
						>
							<Col className="text-end">
								<Button color="gray" onClick={clearError}>
									Close
								</Button>
							</Col>
						</Row>
					</Container>
				</Container>
			</Popup>
			<Popup
				contentStyle={{
					width: '50vh',
					height: '20vh',
					padding: '1em',
				}}
				open={settingQuoteId}
				onClose={() => {
					clearSelectedId();
				}}
				closeOnDocumentClick={false}
			>
				<Container className="local-bootstrap">
					<Row>
						<Col>
							<h5 className="green-text">Enter Quote ID</h5>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Control
								value={tempSelectedQuoteId}
								placeholder="Quote ID"
								type="text"
								onChange={(e) => setTempSelectedQuoteId(e.target.value)}
								onKeyPress={handleKeyPress}
							/>
						</Col>
					</Row>
					<Row
						style={{
							position: 'absolute',
							bottom: 0,
							left: 0,
							width: '100%',
							marginBottom: '0.5em',
							paddingLeft: '0.5em',
							paddingRight: '0.5em',
						}}
					>
						<Col>
							<Button
								style={{
									marginTop: '0.5em',
									paddingLeft: '1em',
									paddingRight: '1em',
									lineHeight: '1em',
								}}
								color="gray"
								onClick={() => setSettingQuoteId(false)}
							>
								Cancel
							</Button>
						</Col>
						<Col className="text-end">
							<Button
								color="green"
								onClick={handleSubmit}
							>
								Submit
							</Button>
						</Col>
					</Row>
				</Container>
			</Popup>
			<Container fluid>
				<Row className="h-25">
					<InputGroup>
						<Col xl={3} lg={6} sm={12} className="mb-1">
							<Card className="h-100">
								<Card.Body>
									<Row className="text-center">
										<h4 className="green-text">Date Range</h4>
									</Row>
									<Row>
										<Col lg={6}>
											<Row>
												<Form.Label
													style={{ fontSize: '0.8em' }}
													className="text-muted"
												>
													Start Date
												</Form.Label>
											</Row>
											<Row>
												<Form.Control
													style={{ width: '95%' }}
													value={selectedStartDate}
													onChange={(e) => {
														handleDate(e.target.value, 'start');
													}}
													placeholder="Start Date"
													type="date"
												/>
											</Row>
										</Col>
										<Col lg={6}>
											<Row>
												<Form.Label
													style={{ fontSize: '0.8em' }}
													className="text-muted"
												>
													End Date
												</Form.Label>
											</Row>
											<Row>
												<Form.Control
													style={{ width: '95%' }}
													value={selectedEndDate}
													onChange={(e) => {
														handleDate(e.target.value, 'end');
													}}
													placeholder="End Date"
													type="date"
												/>
											</Row>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
						<Col xl={3} lg={6} sm={12} className="mb-1">
							<Card className="h-100">
								<Card.Body>
									<Row>
										<h5 className="green-text">Entity</h5>
									</Row>
									<Row>
										<Col>
											{showEntityList !== null ? (
												<EntityEmailsListing
													isClearable
													entities={showEntityList}
													noHeaders
													noButton
													maxMenuHeight={130}
													styling={{
														marginBottom: '2em',
														width: '100%',
													}}
													redirect={(type, id) => {
														setEntityId(id);
														setEntityType(type);
													}}
													close={close}
												/>
											) : (
												<>Loading...</>
											)}
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
						<Col xl={2} lg={6} sm={12} className="mb-1">
							<Card className="h-100">
								<Card.Body>
									<Row>
										<h5 className="green-text">User</h5>
									</Row>
									<Row>
										<PowerShadesTypeahead
											id="quote-events"
											clearButton
											selected={selectedUser ? [selectedUser] : []}
											onChange={(e) => {
												const selected = e[0] as {value: string};

												e !== null && e.length > 0
													? setSelectedUserId(parseInt(selected.value as string ))
													: setSelectedUserId(0);
											}}
											options={users}
											// maxMenuHeight={160}
											// className="w-100"
										/>
									</Row>
								</Card.Body>
							</Card>
						</Col>
						<Col xl={2} lg={6} sm={12} className="mb-1">
							<Card className="h-100">
								<Card.Body>
									<Row>
										<h5 className="green-text">Quote ID</h5>
									</Row>
									<Row>
										<Form.Control
											value={selectedQuoteId}
											placeholder="Enter Quote ID..."
											onClick={() => {
												setSelectedQuoteId(0);
												setSettingQuoteId(true);
											}}
											onChange={() => {}}
										/>
									</Row>
								</Card.Body>
							</Card>
						</Col>
						<Col xl={2} lg={6} sm={12} className="mb-1">
							<Card className="h-100">
								<Card.Body>
									<Row>
										<h5 className="green-text">
											Button Pressed{' '}
											<FontAwesomeIcon
												title='place_order_pressed occurs whenever the first "Place Order" button is pressed on a quote.
													shipping_next_pressed occurs whenever the "Next" button is pressed on the "Ready to Place an Order?" modal.
													ps_admin_pressed, dealer_terms_pressed, cc_ach_pressed all occur on the next dialogue prompt for selecting which payment method will be used.
													card_selected and ach_selected occurs if a user selects cc_ach and then chooses either ACH or card to pay.
													submit_order_pressed is the final button to be pressed, once payment method has been selected and the order is good to go. If the order attached to this event was not placed, there was an error in order placement.'
												icon={faInfoCircle}
											/>
										</h5>
									</Row>
									<Row>
										<PowerShadesTypeahead
											id="quote-key-select"
											selected={[selectedKeyId]}
											onChange={(e) => {
												const selected = (e[0]) as {value: string};
												e !== null && e[0]
													? setSelectedKeyId(selected?.value)
													: setSelectedKeyId('');
											}}
											options={keys}
											maxHeight={`${125}`}
											className="w-100"
										/>
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</InputGroup>
				</Row>
				<Row className="mt-2 ms-2" style={{ overflowX: 'auto' }}>
					<Table className={""} quoteType={""} style={{}} noTBody={false}  >
						<tr className="bold-row">
							<th colSpan={5}>Quote Events</th>
						</tr>
						<tr>
							<th>Date</th>
							<th>Email</th>
							<th>User Name</th>
							<th>Quote ID</th>
							<th>Button Pressed</th>
						</tr><>
						{!loadingFilteredData ? (
							quoteEvents.length >= 1 ? (
								quoteEventRows
							) : (
								<tr>
									<th colSpan={5} style={{ fontSize: '5vh' }}>
										<h5 className="green-text">No Events Returned</h5>
									</th>
								</tr>
							)
						) : (
							<tr>
								<th colSpan={5} style={{ fontSize: '5vh' }}>
									<FontAwesomeIcon icon={faSpinner} spin className="green-text" />
								</th>
							</tr>
						)}</>
					</Table>
				</Row>
			</Container>
		</div>
	);
};

export default QuoteEvents;
