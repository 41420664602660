import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import jsPDF from 'jspdf';
import { applyPlugin } from 'jspdf-autotable';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies

import GetQuotePDFBase64 from './GetQuotePDFBase64';
import User from '../../User';
import { getQuoteType } from '../QuoteObjectExtensions.ts';
import Button from '../../Parts/Button';
import { selectAssemblyListByQuoteId } from '../../Store/entities/assemblies/hooks';
import { selectQuoteData } from '../../Store/entities/quotes/hooks';
import { selectQuoteAccessories } from '../../Store/entities/accessories/hooks';
import apiCalls from '../../PowerShadesAPIFunctions.ts';
import { selectHWRItemsByQuoteId } from '../../Store/entities/hardwareRequestItems/hooks.ts';
import { selectQuoteFabricSampleListByQuoteId } from '../../Store/entities/fabricSamples/hooks.ts';
import { selectErrorsByAllNeededOBjects } from '../../Store/entities/assemblies/errors/hooks';
import { toast } from 'react-toastify';

applyPlugin(jsPDF);

class PrintQuoteNew extends Component {
	constructor(props) {
		super(props);

		this.props = props;

		let currency = props.territories?.find((t) => t.id == props.territory_id)?.iso_4217_currency_code_sell ?? 'USD';

		if(props.territories.length === 1 && currency == "USD")
		{
			currency = props.territories?.find(t => true).iso_4217_currency_code_sell ?? "USD";
		}

		this.state = {
			open: false,
			type: 'MSRP',
			initialBase64: "Loading...",
			currentBase64: "Loading...",
			generated_by_name: '',
			currency: currency,
			couponStatus: 'percentage',
			taxStatus: 'hidden',
			adjustment: null,
			color: 'green-button',
			adjustmentName: "Adjustment",
			includeWarranty: true
		};

		this.setAndReset = this.setAndReset.bind(this);
		this.toggleWarranty = this.toggleWarranty.bind(this);
	}

	componentDidMount() {
		const self = this;

		const user = User;

		const { props, state } = self;
		const { newQuote } = props;
		const { userData } = newQuote.Data;

		const isPurchasing
			= userData?.roles?.some((r) => r?.role_name?.includes('dealer_purchasing')) ?? false;
		if (isPurchasing) {
			this.setState({ type: 'dealer' });
		}

		const asyncWrapper = async (callback) => {
			const setBase64 = (base64) => {
				self.setState({ currentBase64: base64, initialBase64: base64 });
			};

			// apiCalls.getQuotePricing(quoteId).then((response) => {
			// 	setLocalQuoteTotals(response.data.pricing);
			// });

			const pricingResp = await apiCalls.getQuotePricing(newQuote.Id);
			const priceData = pricingResp.data.pricing;

			const taxPercent = newQuote.Data.quote?.sales_tax_percentage_snapshot;

			const { name } = newQuote.Data.userData ?? { name: '' };

			const generated_by_name = `${name}`;

			const msrpTotal = newQuote.msrpTotal();

			api.getUserName().then(() => {
				if (self.state.generated_by_name !== generated_by_name) {
					self.setState({ generated_by_name });
				}
			});

			const { type, currency, couponStatus, taxStatus, adjustment, adjustmentName, includeWarranty } = self.state;

			const subtotal = Number((priceData.subtotal).toFixed(2));

			const { store } = newQuote.QuoteLevelVariables();
			const shadeAssemblies = selectAssemblyListByQuoteId(newQuote.Id)(store.getState());

			const quoteStore = selectQuoteData(newQuote.Id)(store.getState());

			const shipping = Number(quoteStore.quote.shipping_snapshot.toFixed(2)) + Number(quoteStore.quote.freight_costs.toFixed(2));
			const quoteAccessories = selectQuoteAccessories(newQuote.Id)(store.getState());
			const hardwareRequestItems = selectHWRItemsByQuoteId(newQuote.Id)(store.getState());
			const fabricSamples = selectQuoteFabricSampleListByQuoteId(newQuote.Id)(store.getState());

			const quoteAccessoriesToSend = [
				...quoteAccessories,
				...Object.values(hardwareRequestItems)
			];

			const quoteData = newQuote.Data.quote ?? {};

			const propertyAddress = quoteData.property_address;

			const installationAddress = {
				street: `${propertyAddress.address ?? ''} ${propertyAddress.address2 ?? ''}`,
				company: propertyAddress.company_name ?? '',
				city: propertyAddress.city ?? '',
				state: propertyAddress.state ?? '',
				zip: propertyAddress.zip ?? '',
				country: propertyAddress.country ?? '',
				contact: `${propertyAddress.first_name ?? ''} ${propertyAddress.last_name ?? ''}`,
				email: propertyAddress.billing_email ?? '',
				phone: propertyAddress.billing_phone ?? '',
			};

			const dealerId = parseInt(quoteData.dealer_id);
			const representativeId = quoteData.representative_id;
			const { distributorId } = quoteData;

			let dealer = {
				name: 'No Dealer',
				multiplier: 0.5,
			};

			if (dealerId) {
				if (!self.gettingOrGottenDealer) {
					self.gettingOrGottenDealer = true;
					api.getDealerMeta(dealerId).then((dealerResp) => {
						if (self?.state?.dealer?.name !== dealerResp?.data?.dealer?.name) {
							self.setState({ dealer: dealerResp?.data?.dealer ?? {} }, self.reset);
						} else if (!dealerResp?.data?.dealer?.name) {
							self.gettingOrGottenDealer = false;
						}
					}).catch(() => {
						self.gettingOrGottenDealer = false;
					});
				}
				// Check if dealer needs loading.
				if (self.state.dealer === undefined) return;

				// console.log(dealerResp);

				const dealerObj = self.state.dealer;

				dealer = {
					contact: `${dealerObj.name}`,
					multiplier: dealerObj.multiplier,
					company: dealerObj.name,
					street: `${dealerObj.billing_address?.address} ${dealerObj.billing_address?.address2}`,
					state: dealerObj.billing_address?.state ?? '',
					city: dealerObj.billing_address?.city ?? '',
					zip: dealerObj.billing_address?.zip ?? '',
					country: dealerObj.billing_address?.country ?? '',
					email: dealerObj.billing_address?.email ?? '',
					phone: dealerObj.billing_address?.phone ?? '',
					currency: 'USD',
				};
			}

			const demoReviewObj = newQuote.GetDemoInfo();

			let demoAmount = 0;

			if (demoReviewObj) {
				const reviewStatus = demoReviewObj?.demoReviewStatus;

				const reviewType = demoReviewObj?.demoApprovalType?.name;
				const inputValue = demoReviewObj?.value;

				const approvalTypes = {
					CASH_OVERRIDE: 'cash',
					PERCENTAGE_DECREASE: 'percentage',
					REJECT_DEMO: 'reject',
					DEMO_DEFAULT: 'default',
				};

				let dealerPricing = newQuote.dealer();

				if (!dealerPricing) {
					dealerPricing = newQuote.buy();
				}

				const previousMSRP = dealerPricing?.pre_shipping;

				let newMsrp
					= approvalTypes.CASH_OVERRIDE == reviewType
						? inputValue
						: approvalTypes.PERCENTAGE_DECREASE == reviewType
							|| approvalTypes.DEMO_DEFAULT == reviewType
							? previousMSRP * (1 - inputValue / 100)
							: previousMSRP * (1 - 0.4 / 100);

				if (reviewStatus == 'approved') {
					newMsrp
						= demoReviewObj?.demoApprovalType?.name != 'cash'
							? previousMSRP * (1 - demoReviewObj.value / 100)
							: demoReviewObj.value * dealerPricing.multiplier.currency_multiplier;
				}

				demoAmount = previousMSRP - newMsrp;
			}

			const couponAmount
				= quoteData.coupon_snapshot != 0 ? quoteData.coupon_snapshot : demoAmount;

			const quoteDataToSend = {
				id: quoteData.ID,
				title: quoteData?.title ?? "(No Title Entered)",
				generated_by: generated_by_name,
				subtotal,
				shipping,
				taxPercentage: taxPercent,
				taxShipping: quoteData.sales_tax_on_shipping_snapshot,
				customer_address: installationAddress,
				couponAmount,
				couponType: couponStatus,
				taxType: taxStatus,
				adjustment,
				adjustmentName,
				isShippingTaxed: priceData.is_shipping_taxed
			};
			const territories = props?.territories;
			const territoryId = props?.territory_id;

			const territory = territories
				? territories?.find((t) => t.id == territoryId) ?? {}
				: {};

			const testCurrency = currency ?? (territory.msrp_multiplier == '1.5' ? 'CAD' : 'USD');

			const ontario = territories.find((t) => t.name == 'Ontario') ?? territories.find((t) => t.iso_4217_currency_code_sell == 'CAD');
			const MINK = territories.find((t) => t.name == 'MINK') ?? territories.find((t) => t.iso_4217_currency_code_sell == 'USD');

			const currencyData
				= testCurrency === 'CAD'
					? {
						currencyMultiplier: ontario?.msrp_multiplier ?? 1.5,
						currencySymbol: '$',
						currencyISO: ontario?.iso_4217_currency_code_sell ?? "CAD",
					}
					: {
						currencyMultiplier: MINK?.msrp_multiplier ?? 1.0,
						currencySymbol: '$',
						currencyISO: MINK?.iso_4217_currency_code_sell ?? "USD",
					};

			const shadeAssembliesToSend = shadeAssemblies.map((sa) => {
				const firstShade = sa.shades.find((s) => true) ?? {};

				const assembly = {
					room_name: sa.room_name,
					shade_name: sa.shade_name,
					width: sa.is_coupled ? sa.total_width : firstShade.width,
					height: firstShade.height,
				};

				const isDual = sa.single_dual == 'Dual';

				const fabric1 = sa.shades.find((s) => s.row_coordinate == 0).fabric_name;
				const fabric2 = sa.shades.find((s) => s.row_coordinate == 1)?.fabric_name;

				assembly.fabric = isDual ? `${fabric1}-${fabric2}` : `${fabric1}`;
				assembly.msrp = sa.msrp;

				return assembly;
			});

			// Changing data here to correct the display for non-USD Dealers
			quoteDataToSend.adjustment /= currencyData.currencyMultiplier;

			GetQuotePDFBase64({
				shadeAssemblies: shadeAssembliesToSend,
				quoteAccessories: quoteAccessoriesToSend,
				fabricSamples,
				quoteData: quoteDataToSend,
				type,
				dealerData: dealer,
				currencyData,
				includeWarranty
			}).then((data) => {
				setBase64(data);
				callback && callback();
			});
		};

		this.reset = debounce(asyncWrapper, 500);

		this.reset();
	}

	setAndReset(state) {
		this.setState(state, () => {
			this.reset && this.reset();
		});
	}

	async toggleWarranty() {
		const { includeWarranty, currentBase64, initialBase64 } = this.state;

		this.setState((prevState) => ({
			includeWarranty: !prevState.includeWarranty,
		}), () => {
			this.reset();
		});

	}

	render() {
		const { open, currentBase64, type, generated_by_name, currency, couponStatus, taxStatus, className, adjustment, adjustmentName, includeWarranty }
			= this.state;

		const quoteId = this.props.newQuote.Id;

		const isErrored = this.props.has_errors;

		const self = this;

		const openPage = () => {
			if(isErrored)
			{
				toast.error("Please fix the errors before printing the quote.");
				return
			}
			self.setState({ open: true }, self.reset);
		};

		const { newQuote } = this.props;

		const { userData } = newQuote.Data;

		const setBase64 = (base64) => {
			self.setState({ currentBase64: base64 });
		};

		const isSalesPerson
			= userData?.roles?.some((r) => r?.role_name?.includes('dealer_salesperson')) ?? false;

		// /overflow: auto; width: 100%; height: 100%;

		const setType = (option) => {
			this.setState({ type: option }, () => {
				this.reset();
			});
		};

		const currencyOptions = [
			{
				value: 'CAD',
				display: 'CAD',
			},
			{
				value: 'USD',
				display: 'USD',
			},
		];

		const couponOptions = [
			{
				value: 'percentage',
				display: 'Percentage',
				title: 'This gives the MSRP a percentage equvilent of the coupon.',
			},
			{
				value: 'fixed',
				display: 'Fixed Amount',
				title: 'This gives the MSRP the same discount dollar for dollar as the coupon does.',
			},
			{
				value: 'none',
				display: 'None',
				title: "This doesn't apply the coupon to the MSRP.",
			},
			{
				value: 'hidden',
				display: 'Hidden',
				title: 'This hides the coupon.',
			},
		];

		const taxOptions = [
			{
				value: 'hidden',
				display: 'Hidden',
				title: 'This hides the tax value.',
			},
			{
				value: 'visible',
				display: 'Visible',
				title: 'This shows the tax value.',
			},
		];

		const quoteData = this.props.newQuote.Data.quote ?? {};

		const demoReview = this.props.newQuote.GetDemoInfo();

		const showCoupon = quoteData.coupon_snapshot != 0 || demoReview;

		const quoteType = getQuoteType(this.props.newQuote);

		const setCurrency = (option) => {
			this.setState({ currency: option }, () => {
				this.reset();
			});
		};
		const setAdjustment = (number) => {
			if (number === false) {
				this.setState({ adjustment: null, adjustmentName: "Adjustment" }, () => {
					this.reset();
				});
			} else if (number === true) {
				this.setState({ adjustment: 0, adjustmentName: "Adjustment" }, () => {
					this.reset();
				});
			} else {
				this.setState({ adjustment: number }, () => {
					this.reset();
				});
			}
		};

		const setAdjustmentName = (name) => {
			this.setState({ adjustmentName: name }, () => {
				this.reset();
			});
		};

		const setCoupon = (option) => {
			this.setState({ couponStatus: option }, () => {
				this.reset();
			});
		};
		const setTax = (option) => {
			this.setState({ taxStatus: option }, () => {
				this.reset();
			});
		};

		const { props } = this;

		const territories = props.territories ? props.territories : [];
		const territoryId = props.territory_id;

		const territory = territories?.find((t) => t.id == territoryId) ?? {};

		const testCurrency = currency ?? (territory.msrp_multiplier == '1.5' ? 'CAD' : 'USD');

		const showTaxes = (newQuote.Data.quote?.sales_tax_percentage_snapshot ?? 0) > 0;

		return (
			<>
				<Popup
					open={open}
					onClose={() => this.setState({ open: false, includeWarranty: false })}
					className="mobile-modal "
				>
					<Container className="local-bootstrap ">
						<Row>
							<Col className="me-2 ms-2">
								<Row className="mb-4">
									<Col xs={12}>
										<h4 className={`text-${quoteType}`}>Print Type</h4>
									</Col>
									<Col>
										<select
											value={type}
											className="form-select"
											onChange={(e) => setType(e.target.value)}
										>
											<option value="msrp">Customer</option>
											{!isSalesPerson ? <option value="dealer">Dealer</option> : null}
										</select>
									</Col>

								</Row>
								<Row className="mb-4">
									<Col xs={12}>
										<h4 className={`text-${quoteType}`}>Currency</h4>
									</Col>
									<Col>
										<select
											value={currency ?? testCurrency}
											onChange={(e) => setCurrency(e.target.value)}
											className="form-select"
										>
											{currencyOptions.map((o) => (
												<option key={o.value} value={o.value}>
													{o.display}
												</option>
											))}
										</select>
									</Col>
								</Row>
								<Row className="mb-4">
									<Col>
										<Row>
											<Col className="mb-2" xs={12}>
												<Button
													radioButton
													color={adjustment !== null ? quoteType : 'gray'}
													fluid
													fullWidth
													onClick={() => setAdjustment(adjustment === null)}
												>
													Adjustment &nbsp;
													{
														adjustment !== null
															? (<FontAwesomeIcon icon={faCheck} />)
															: (<FontAwesomeIcon icon={faTimes} />)
													}
												</Button>
											</Col>
											<Col>
												<Row>
													<Col xs={6}><span className="text-muted">Description</span></Col>
													<Col xs={6}><span className="text-muted">Amount</span></Col>
												</Row>
												<Row>
													<Col xs={6}>
														<input className="form-control" disabled={adjustment === null} value={adjustmentName} type="text" onChange={(e) => setAdjustmentName(e.target.value)} />
													</Col>
													<Col xs={6}>
														<input className="form-control" disabled={adjustment === null} value={adjustment} type="number" onChange={(e) => setAdjustment(e.target.value)} />
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>

								{showCoupon ? (
									<Row className="mb-4">
										<Col xs={12}>
											<h4 className={`text-${quoteType}`}>Coupon</h4>
										</Col>
										<Col>
											<select
												value={couponStatus}
												onChange={(e) => setCoupon(e.target.value)}
												className="form-select"
											>
												{couponOptions.map((o) => (
													<option key={o.title} title={o.title} value={o.value}>
														{o.display}
													</option>
												))}
											</select>
										</Col>

									</Row>
								) : null}
								{showTaxes ? (
									<Row className="mb-4">
										<Col xs={12}>
											<h4 className={`text-${quoteType}`}>Taxes</h4>
										</Col>
										<Col>
											<select
												value={taxStatus}
												onChange={(e) => setTax(e.target.value)}
												className="form-select"
											>
												{taxOptions.map((o) => (
													<option key={o.value} title={o.title} value={o.value}>
														{o.display}
													</option>
												))}
											</select>
										</Col>

									</Row>
								) : null}
								<Row className="mb-4">
									<Col>
										<Button
											radioButton
											color={includeWarranty ? "green" : "gray"}
											fluid
											fullWidth
											onClick={this.toggleWarranty}
										>
											Include Warranty Info &nbsp;
											{
												includeWarranty
													? (<FontAwesomeIcon icon={faCheck} />)
													: (<FontAwesomeIcon icon={faTimes} />)
											}
										</Button>
									</Col>
								</Row>
							</Col>
							<Col>
								<embed
									style={{
										overflow: 'auto',
										width: '100%',
										height: '100%',
									}}
									className="pdf"
									src={`${currentBase64}#toolbar=0`}
								/>
							</Col>
						</Row>
						<Row className="align-bottom mt-4">
							<Col>
								<Button
									color="gray"
									onClick={() => this.setState({ open: false })}
								>
									Close
								</Button>
							</Col>

							<Col className="text-end">
								<Button
									color={quoteType ?? 'green'}
									onClick={() => downloadURI(currentBase64, `PS${quoteId}.pdf`)}
								>
									Download
								</Button>
							</Col>
						</Row>
					</Container>
				</Popup>
				<Button color={quoteType ?? 'green'} fluid fullWidth onClick={() => openPage()}>
					Print Quote
				</Button>
			</>
		);
	}
}

function downloadURI(uri, name) {
	let link = document.createElement('a');
	link.download = name;
	link.href = uri;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
	link = null;
}

export default PrintQuoteNew;
