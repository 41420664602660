import { useState, useEffect } from 'react';

import api from '../../../PowerShadesAPI';
import PaymentStep from '../PaymentStep';

const DealerTermsPayment = (props) => {
	const [waiting, setWaiting] = useState(false);
		  const [error, setError] = useState(null);
		  const [saveCard, setSaveCard] = useState(false);

	const quote = props.preLoadedQuote;
	
	useEffect(() => {
		props.setSubmit(() => submit());
		props.showSubmit();
		
		props.setRetainer("DT");
	}, []);

	const submit = () => {
		setWaiting(true);
		
		return new Promise((resolve, reject) => {
			api.makePaymentNew(quote.ID, 'powershades_admin', {})
			   .then((resp) => {
					if (resp.data.error) {
						reject(resp.data.error);
						setWaiting(false);
						setError(resp.data.error);
					} else {
						resolve('Payment successful.');
					}
				});
		});
	};

	return (

		<PaymentStep />
	);
};

export default DealerTermsPayment;
