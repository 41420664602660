/* eslint-disable arrow-body-style */ // Disagree with this.
import { createAsyncThunk } from "@reduxjs/toolkit";

import apiCalls from "../../../PowerShadesAPIFunctions";
import { extraEntityReducersFunction, loadStatus } from "../entitiesType";

import type { AppState } from "../../index";
import type { Accessory, QuoteAccessoryStore } from "../../../powershadesApiTypes";
import type {
	AddAccessoryThunkInput,
	DeleteAccessoryThunkInput,
	UpdateAccessoryThunkInput
} from "./types";
import { captureSentryError, extractErrorMessage, isFailedApiCall } from "../../../psUtil";
import { wentToQuote } from "../quotes";

const initialAccessory: () => Accessory = () => {
	const accessory: Accessory = {
		id: 0,
		name: "",
		is_active: 0,
		category_name: "",
		msrp: 0,
		api_accessory_id: 0,
		is_internal_only: 0,
		parameters: []
	};

	return accessory;
};

const initialQuoteAccessory: () => QuoteAccessoryStore = () => {
	const quoteAccessory: QuoteAccessoryStore = {
		Id: 0,
		quote_id: 0,
		accessory_id: 0,
		quantity: 0,
		msrp: 0,
		name: "",
		mfg_status: "",
		last_time_priced: -1,
		part_number: "",
		cost: 0,
		shipping: 0,
		line_number: 0,
		parameters: [],
		is_pricing: false
	};

	return quoteAccessory;
};

const loadAccessoriesFull = createAsyncThunk<
	Accessory[],
	{
		quoteId: number;
	},
	{
		rejectValue: string;
	}
>("entities/getAccessoriesFull", async ({quoteId}, { rejectWithValue }) => {
	let accessories: Accessory[] = [];

	try {
		const resp = await apiCalls.getPortfolioAccessories(quoteId);

		if (isFailedApiCall(resp)) {
			throw resp.error;
		}

		accessories = resp.data.accessories;
	} catch (err: any) {
		captureSentryError(err, {
			tags: {
				section: "redux"
			},
			extra: {
				redux_action: "getAccessoriesFull",
				file_origin: "src/Store/entities/accessories/index.ts"
			}
		});

		const errorMessage = extractErrorMessage(err);
		return rejectWithValue(errorMessage);
	}

	return accessories;
});

const loadQuoteAccessories = createAsyncThunk<
	QuoteAccessoryStore[],
	number,
	{
		rejectValue: string;
	}
>("entities/getQuoteAccessories", async (quoteId, { rejectWithValue, dispatch }) => {
	let quoteAccessories: QuoteAccessoryStore[] = [];
	try {
		dispatch(wentToQuote(quoteId));
		const resp = await apiCalls.getQuoteAccessories(quoteId);

		if (isFailedApiCall(resp)) {
			throw resp.error;
		}

		quoteAccessories = resp.data.quote_accessories;
	} catch (err: any) {
		captureSentryError(err, {
			tags: {
				section: "redux"
			},
			extra: {
				redux_action: "getQuoteAccessories",
				file_origin: "src/Store/entities/accessories/index.ts"
			}
		});

		const errorMessage = extractErrorMessage(err);
		return rejectWithValue(errorMessage);
	}

	return quoteAccessories;
});

// Thunk to add a quote accessory. The fancy promise is to true up the legacy quote object.
const addQuoteAccessory = createAsyncThunk<
	QuoteAccessoryStore,
	AddAccessoryThunkInput,
	{
		rejectValue: string;
	}
>("entities/addQuoteAccessory", async (input, { rejectWithValue, dispatch }) => {
	const { priceCallback, quoteId, sku, quantity, parms, nextLineNumber } = input;

	let respData: QuoteAccessoryStore;
	dispatch(wentToQuote(quoteId));


	try {
		const resp = await apiCalls.addQuoteAccessory({
			quoteId,
			sku,
			newQuantity: quantity,
			parms,
			lineNumber: nextLineNumber
		});

		if (isFailedApiCall(resp)) {
			throw resp.error;
		}

		respData = resp.data.quote_accessory;
		priceCallback && priceCallback(resp.data.legacy_pricing);
	} catch (err: any) {
		captureSentryError(err, {
			tags: {
				section: "redux"
			},
			extra: {
				redux_action: "addQuoteAccessory",
				file_origin: "src/Store/entities/accessories/index.ts"
			}
		});

		const errorMessage = extractErrorMessage(err);
		return rejectWithValue(errorMessage);
	}
	return respData;
});

// Thunk to add a quote accessory. The fancy promise is to true up the legacy quote object.
const deleteQuoteAccessory = createAsyncThunk<
	boolean,
	DeleteAccessoryThunkInput,
	{
		rejectValue: string;
	}
>("entities/deleteQuoteAccessory", async (input, { rejectWithValue, dispatch }) => {
	const { quoteId, lineNumber, priceCallback } = input;

	try {
		dispatch(wentToQuote(quoteId));
		const resp = await apiCalls.deleteQuoteAccessory(quoteId, lineNumber);

		if (isFailedApiCall(resp)) {
			throw resp.error;
		}

		priceCallback && priceCallback(resp.data.legacy_pricing);
	} catch (err: any) {
		captureSentryError(err, {
			tags: {
				section: "redux"
			},
			extra: {
				redux_action: "deleteQuoteAccessory",
				file_origin: "src/Store/entities/accessories/index.ts"
			}
		});

		const errorMessage = extractErrorMessage(err);
		return rejectWithValue(errorMessage);
	}

	return true;
});

// Thunk to add a quote accessory. The callback is for legacy quote object.
const updateQuoteAccessory = createAsyncThunk<
	QuoteAccessoryStore,
	UpdateAccessoryThunkInput,
	{
		rejectValue: string;
		state: AppState;
	}
>("entities/updateQuoteAccessory", async (input, { rejectWithValue, dispatch }) => {
	const { priceCallback, quoteId, lineNumber, quantity, parms } = input;

	let quoteAccessory: QuoteAccessoryStore;

	try {
		dispatch(wentToQuote(quoteId));
		const resp = await apiCalls.updateQuoteAccessory({
			quoteId,
			lineNumber,
			newQuantity: quantity,
			parms
		});

		if (isFailedApiCall(resp)) {
			throw resp.error;
		}

		quoteAccessory = resp.data.quote_accessory;
		priceCallback && priceCallback(resp.data.legacy_pricing);
	} catch (err: any) {
		captureSentryError(err, {
			tags: {
				section: "redux"
			},
			extra: {
				redux_action: "updateQuoteAccessory",
				file_origin: "src/Store/entities/accessories/index.ts"
			}
		});

		const errorMessage = extractErrorMessage(err);
		return rejectWithValue(errorMessage);
	}
	return quoteAccessory;
});

const accessoryBuilder: extraEntityReducersFunction = (builder) => {
	builder.addCase(loadAccessoriesFull.pending, (state,action) => {
		const accState = {
			loadStatus: loadStatus.loading,
			list: []
		}
		state.accessories[action.meta.arg.quoteId] = accState;
	});

	builder.addCase(loadAccessoriesFull.fulfilled, (state, action) => {
		let loadStatusCurrent = state.accessories[action.meta.arg.quoteId]?.loadStatus ?? loadStatus.needsLoaded;
		loadStatusCurrent = !action.payload
			? loadStatus.needsLoaded
			: loadStatus.fullyLoaded;

			const list = action.payload ?? [];

		state.accessories[action.meta.arg.quoteId] = {
			list,
			loadStatus: loadStatusCurrent
		};
	});

	builder.addCase(loadAccessoriesFull.rejected, (state, action) => {

		const accState = state.accessories[action.meta.arg.quoteId] ?? {
			loadStatus: loadStatus.needsLoaded,
			list: []
		};

		accState.loadStatus = loadStatus.needsLoaded;

		state.accessories[action.meta.arg.quoteId] = accState;
	});
	builder.addCase(addQuoteAccessory.pending, (state, action) => {
		const qaList = state.quoteAccessories[action.meta.arg.quoteId];
		const accList = state.accessories[action.meta.arg.quoteId]?.list ?? [];

		const accessory = accList.find((acc) => acc.part_number === action.meta.arg.sku);

		const newQa: QuoteAccessoryStore = {
			...initialQuoteAccessory(),
			...accessory,
			parameters: [],
			quantity: action.meta.arg.quantity,
			msrp: 0,
			quote_id: action.meta.arg.quoteId,
			line_number: action.meta.arg.nextLineNumber
		};

		// Sometimes the FE and the BE disagree on the next line item number. 
		// Using a negative number here will make the front end never use a backend line number id that's already assigned or distributed.

		if (qaList) {
			const lineNumber =
				Object.values(qaList?.Items).reduce((acc, cur) => {
					return Math.max(acc, Math.abs(cur.line_number));
				}, 0) + 1;
			newQa.line_number = action.meta.arg.nextLineNumber;
			qaList.Items[lineNumber] = newQa;
		}
	});

	builder.addCase(addQuoteAccessory.fulfilled, (state, action) => {
		const qaList = state.quoteAccessories[action.meta.arg.quoteId];

		let expected = qaList?.Items[action.payload.line_number];
		let expectedLineNumber = action.payload.line_number;

		if (!qaList) {
			throw new Error("Expected quote not found");
		}

		// If the line item number we've expected doesn't link to the item we've just added, we need to find the correct line number.
		if (!expected) {
			const currentAccessoriesList = Object.entries(qaList.Items);
			let expectedItem = currentAccessoriesList.find(([key, value]) => {
				return value.part_number === action.payload.part_number
					&& value.quantity === action.payload.quantity
					&& value.msrp === 0
					&& parseInt(key) < 0;
				;
			}) ?? currentAccessoriesList.find(([_key, value]) => {
				return value.part_number === action.payload.part_number;
			});

			if (expectedItem === undefined) {
				throw new Error("Expected item not found");
			}

			const [lineNumberKey, _] = expectedItem
			expectedLineNumber = parseInt(lineNumberKey);
		}

		if (qaList) {
			const pricedAcc = action.payload;

			if (pricedAcc) {
				const newQa: QuoteAccessoryStore = {
					...pricedAcc,
					Id: pricedAcc.Id,
					quote_id: pricedAcc.quote_id,
					accessory_id: pricedAcc.accessory_id,
					quantity: pricedAcc.quantity,
					cost: pricedAcc.cost,
					msrp: pricedAcc.msrp,
					shipping: pricedAcc.shipping,
					last_time_priced: pricedAcc.last_time_priced,
					part_number: pricedAcc.part_number,
					line_number: pricedAcc.line_number,
					parameters: pricedAcc.parameters
				};

				delete qaList.Items[expectedLineNumber];

				qaList.Items[pricedAcc.line_number] = newQa;
			}
		}
	});

	builder.addCase(updateQuoteAccessory.pending, (state, action) => {
		const qaList = state.quoteAccessories[action.meta.arg.quoteId];

		if (qaList) {
			const item = qaList.Items[action.meta.arg.lineNumber];

			if (item) {
				item.quantity = action.meta.arg.quantity;
				item.msrp = 0;
				item.cost = 0;
				item.shipping = 0;
			}
		}
	});

	builder.addCase(updateQuoteAccessory.fulfilled, (state, action) => {
		const qaList = state.quoteAccessories[action.meta.arg.quoteId];

		if (qaList) {
			const pricedAcc = action.payload;

			if (pricedAcc) {
				const newQa: QuoteAccessoryStore = {
					...pricedAcc,
					Id: pricedAcc.Id,
					quote_id: pricedAcc.quote_id,
					accessory_id: pricedAcc.accessory_id,
					quantity: pricedAcc.quantity,
					cost: pricedAcc.cost,
					msrp: pricedAcc.msrp,
					shipping: pricedAcc.shipping,
					last_time_priced: pricedAcc.last_time_priced,
					line_number: pricedAcc.line_number,
					parameters: pricedAcc.parameters,
					part_number: pricedAcc.part_number
				};

				qaList.Items = { ...qaList.Items, [pricedAcc.line_number]: newQa };
			}
		}
	});

	builder.addCase(loadQuoteAccessories.fulfilled, (state, action) => {
		try {
			let qaRecord = state.quoteAccessories[action.meta.arg];

			if (!qaRecord) {
				qaRecord = {
					loadStatus: loadStatus.fullyLoaded,
					Items: {}
				};
			}

			qaRecord.Items = action.payload.reduce((acc, cur) => {
				acc[cur.line_number] = { ...cur };
				return acc;
			}, {} as Record<number, QuoteAccessoryStore>);

			state.quoteAccessories[action.meta.arg] = qaRecord;
		} catch (err) {
			const emptyQa = {
				loadStatus: loadStatus.fullyLoaded,
				Items: {}
			};

			state.quoteAccessories[action.meta.arg] = emptyQa;

			console.error(err);
			throw new Error("Error loading quote accessories");
		}
	});

	builder.addCase(loadQuoteAccessories.pending, (state, action) => {
		let qaRecord = state.quoteAccessories[action.meta.arg];

		if (!qaRecord) {
			qaRecord = {
				loadStatus: loadStatus.loading,
				Items: {}
			};
		}

		state.quoteAccessories[action.meta.arg] = qaRecord;
	});
	builder.addCase(deleteQuoteAccessory.pending, (state, action) => {
		// Remove the accessory from the quote.
		const qaList = state.quoteAccessories[action.meta.arg.quoteId];

		if (qaList) {
			const { [action.meta.arg.lineNumber]: omit, ...res } = qaList.Items;
			qaList.Items = res;
		}
	});

	builder.addCase(deleteQuoteAccessory.fulfilled, (state, action) => {
		const qaList = state.quoteAccessories[action.meta.arg.quoteId];

		if (qaList) {
			const { [action.meta.arg.lineNumber]: omit, ...res } = qaList.Items;
			qaList.Items = res;
		}
	});
};

export {
	accessoryBuilder,
	loadAccessoriesFull,
	loadQuoteAccessories,
	initialAccessory,
	addQuoteAccessory,
	deleteQuoteAccessory,
	updateQuoteAccessory
};
