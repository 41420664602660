/* eslint-disable @stylistic/max-len */
import React from "react";
import { AssemblyStore } from "../../../../Store/entities/assemblies/types";
import store from "../../../../Store";
import { updateAssembly } from "../../../../Store/entities/assemblies";
import { UseAssembly } from "../../../../Store/entities/assemblies/hooks";
import EditPSOutdoorShadeModalBase from "./EditPSOutdoorShadeBase";

type EditPSOutdoorShadeModalProps = {
	isOpen: boolean;
	onClose: () => void;
	quoteId: number;
	sequenceId: number;
	sequenceIds?: number[];
	massEdit?: boolean | undefined;
};

const EditPSOutdoorShadeModal = ({ isOpen, onClose, quoteId, sequenceId, sequenceIds }: EditPSOutdoorShadeModalProps) => {
	const assy = UseAssembly(quoteId, sequenceIds?.length ? sequenceIds[0] as number : sequenceId);

	const setAssembly = (assembly: AssemblyStore) => {
		sequenceIds
			? sequenceIds.map((id) => {
				const newAssembly: AssemblyStore = { ...assembly, sequence_id: id };
				return store.dispatch(updateAssembly({ quoteId, assembly: newAssembly, sequenceId: id }));
			})
			: store.dispatch(updateAssembly({ quoteId, assembly, sequenceId }));
	};
	if (!assy) return null;
	return (
		<EditPSOutdoorShadeModalBase
			isOpen={isOpen}
			onClose={onClose}
			quoteId={quoteId}
			assembly={assy}
			setAssembly={setAssembly}
		/>
	);
};

export default EditPSOutdoorShadeModal;
