/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @stylistic/max-len */
/* eslint-disable @stylistic/jsx-indent */
/* eslint-disable @stylistic/indent */
import React, { useCallback, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPalette } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../Parts/Button";
import PowerShadesTypeahead from "../../../../Parts/PowerShadesTypeahead";
import { IndoorShadeOptionsDict, IndoorManualChain, IndoorMotorType, IndoorShadeOptionsDualDict, IndoorShadeOptionsSingleDict, IndoorShadeConfiguration, IndoorEndCaps, IndoorHeaderType, IndoorMountType, IndoorShadeOptionsCoupledDict, PortalShadeOption, IndoorDriveType, PortalShadeOptionItem } from "../../../../Store/entities/assemblies/types";
import FractionField from "../../../../Quote/NewOptionFields/FractionField";
import CoupledShadeTable from "../../../../Quote/NewItems/CoupledShadeTable";
import { ErrorSet } from "../../../../Store/entities/assemblies/errors/hooks";
import FabricSelectField from "../../../../Quote/NewOptionFields/FabricSelectField";
import ColorPaletteModal from "../../../ColorPaletteModal/ColorPaletteModal";
import { onlyActive } from "../../../../psUtil";
import { GroupHeader, OptionFooter, OptionLabel } from "../../../../Parts/FormParts";
import { debounce } from "lodash";

type EditIndoorShadeModalBaseInnerProps = {
    options: IndoorShadeOptionsDict;
    quoteType: string;
    isNotAdd?: boolean;
    massEdit?: boolean | undefined;
    unlocked?: boolean;
    errors?: ErrorSet[];
    pickerState?: boolean;
    setPickerState?: (isOpen: boolean) => void;
};

const EditIndoorShadeModalInner = ({
    options,
    quoteType,
    isNotAdd,
    unlocked = true,
    errors = [],
    pickerState,
    setPickerState
}: EditIndoorShadeModalBaseInnerProps) => {
    const {
        Height,
        HeaderMountType,
        ControlSide,
        HeaderType,
        RoomName,
        ShadeName,
        DirectionFacing,
        Floor,
        ShadeConfiguration,
        DriveType,
        TotalWidth,
        MotorType,
        ManualType,
        EndCaps,
        SideChannels,
        HembarLine,
        HembarStyle,
        WindowJambDepth,
        LVPowerSupply,
        BalancedLightGaps,
        ColorPalettes,
        CustomFabricWidth,
        FabricAlignment,
    } = options;

    const disabled = !unlocked;

    const isColorPickerOpen = pickerState ?? false;
    const setIsColorPickerOpen = setPickerState ?? (() => console.log("Failed to open color picker."));

    const isMotorized = DriveType.getValue() === "motorized";
    const driveOption = isMotorized ? MotorType : ManualType;
    type driveOptionType = (IndoorManualChain | IndoorMotorType);

    const isDual = ShadeConfiguration.getValue() === "dual" || ShadeConfiguration.getValue() === "dual_coupled";
    const isCoupled = ShadeConfiguration.getValue() === "coupled" || ShadeConfiguration.getValue() === "dual_coupled";

    const innerRollDirectionOption = isDual ? (options as IndoorShadeOptionsDualDict).FrontRollDirection : (options as IndoorShadeOptionsSingleDict).RollDirection;
    const outerRollDirectionOption = (options as IndoorShadeOptionsDualDict).BackRollDirection;

    const innerFabric = isDual ? (options as IndoorShadeOptionsDualDict).FrontFabric : (options as IndoorShadeOptionsSingleDict).Fabric;
    const outerFabric = (options as IndoorShadeOptionsDualDict).BackFabric;

    const erroredClass = "border border-danger";

    const errorClass = (option: PortalShadeOption<any>) => (errors.find((error) => error.varName === option?.varName) ? erroredClass : "");

    const fasciaSplit = (options as IndoorShadeOptionsCoupledDict).FasciaSplit;

    const [emphasizing, setEmphasizing] = useState(false);
    const [groupClass, setGroupClass] = useState("p-2 rounded");
    const [optionClass, setOptionClass] = useState("");
    const [driveTypeSelected, setDriveTypeSelected] = useState(false);
    const [shadeConfigState, setShadeConfigState] = useState<string | PortalShadeOptionItem<IndoorShadeConfiguration>>(ShadeConfiguration.getSelectedOption());
    const [sideChannelState, setSideChannelState] = useState<string>(SideChannels.getSelectedOption().value);
    const [colorPaletteState, setColorPaletteState] = useState<string | PortalShadeOptionItem<string>>(ColorPalettes.getValue());
    const toggleColorPicker = () => {
        colorPaletteState === "" && setColorPaletteState("Custom Palette");
        setIsColorPickerOpen(!isColorPickerOpen);
    };

    const emphasizeOptions = () => {
        if (!isNotAdd && !driveTypeSelected) return setDriveTypeSelected(true);
        const initialOption = "rounded";
        const initialGroup = "p-2 rounded";
        const optionEmphasis = "rounded border-warning";
        const groupEmphasis = "p-2 rounded border-warning bg-warning-subtle";

        setEmphasizing(true);
        setGroupClass(groupEmphasis);
        setOptionClass(optionEmphasis);

        setTimeout(() => {
            setGroupClass(initialGroup);
            setOptionClass(initialOption);
        }, 400);
        setTimeout(() => {
            setGroupClass(groupEmphasis);
            setOptionClass(optionEmphasis);
        }, 800);
        setTimeout(() => {
            setGroupClass(initialGroup);
            setOptionClass(initialOption);
        }, 1200);
        setTimeout(() => {
            setGroupClass(groupEmphasis);
            setOptionClass(optionEmphasis);
        }, 1600);
        setTimeout(() => {
            setGroupClass(initialGroup);
            setOptionClass(initialOption);
        }, 2000);
        setTimeout(() => {
            setGroupClass(groupEmphasis);
            setOptionClass(optionEmphasis);
            setEmphasizing(false);
        }, 2400);
    };

    const activeColorPalettes = onlyActive(ColorPalettes.options?.filter((palette) => palette.value !== "Custom Palette"));

    const [colorBtnDisabled, setColorBtnDisabled] = useState(colorPaletteState === "");

    const [roomNameValue, setRoomNameValue] = useState(RoomName.getValue());
    const [shadeNameValue, setShadeNameValue] = useState(ShadeName.getValue());

    const debouncedSetRoomName = useCallback(
        debounce((value) => RoomName.setValue(value), 500),
        [options]
    );

    const debouncedSetShadeName = useCallback(
        debounce((value) => ShadeName.setValue(value), 500),
        [options]
    );

    return (
        <Row>
            {!isColorPickerOpen && <Col>
                <Row>
                    <Col key="form-group-header">
                        <Row className="p-2">
                            <Col>
                                {OptionLabel(DriveType)}
                                <PowerShadesTypeahead
                                    disabled={disabled || onlyActive(DriveType.options).length === 0}
                                    id="header-mount-type"
                                    options={onlyActive(DriveType.options) ?? []}
                                    onChange={(selected) => {
                                        if (selected.length === 0) {
                                            DriveType.clearValue();
                                            return;
                                        }
                                        const selectedObj = selected[0] as {
                                            value: IndoorDriveType;
                                        };

                                        emphasizeOptions();
                                        DriveType.setValueAndClearMotorOptions(selectedObj.value);
                                    }}
                                    selected={[DriveType.getSelectedOption()]}
                                />
                                <OptionFooter option={DriveType} quoteType={quoteType} />
                            </Col>
                            <Col>
                                {OptionLabel(HeaderType)}
                                <div className="d-flex flex-col justify-content-between flex-grow-1">
                                <PowerShadesTypeahead
                                    disabled={disabled || onlyActive(HeaderType.options).length === 0}
                                    id="header-type"
                                    options={onlyActive(HeaderType.options)}
                                    onChange={(selected) => {
                                        ColorPalettes.clearValue();
                                        setColorPaletteState("");
                                        setColorBtnDisabled(true);
                                        if (selected.length === 0) {
                                            HeaderType.clearValue();
                                            return;
                                        }
                                        const selectedObj = selected[0] as {
                                            value: IndoorHeaderType;
                                        };
                                        HeaderType.setValue(selectedObj.value);
                                    }}
                                    selected={[HeaderType.getSelectedOption()]}
                                    errored={errorClass(HeaderType) !== ""}
                                />
                                <OptionFooter option={HeaderType} quoteType={quoteType} />
                                </div>
                            </Col>
                        </Row>
                        <Row className="p-2">
                            <Col className="col-6">
                            {OptionLabel(driveOption)}
                            <PowerShadesTypeahead
                                        className={`${driveOption.getSelectedOption() ? "" : optionClass}`}
                                        style={{ border: "solid transparent 2px", transition: "all .1s" }}
                                        disabled={disabled || onlyActive(driveOption.options).length === 0}
                                        id="header-mount-type"
                                        options={onlyActive(driveOption.options) ?? []}
                                        onChange={(selected) => {
                                            if (selected.length === 0) {
                                                driveOption.clearValue();
                                                return;
                                            }
                                            const selectedObj = selected[0] as {
                                                value: driveOptionType;
                                            };

                                            driveOption.setValue(selectedObj.value as never);
                                        }}
                                        selected={[driveOption.getSelectedOption()]}
                                        errored={!emphasizing && errorClass(driveOption) !== ""}
                            />
                                    <OptionFooter option={driveOption} quoteType={quoteType} />
                            </Col>
                            <Col>
                                {OptionLabel(ColorPalettes)}
                                <div className="d-flex flex-col justify-content-between flex-grow-1">
                                    <PowerShadesTypeahead
                                        disabled={disabled || !ColorPalettes?.options}
                                        id="color-palettes"
                                        options={activeColorPalettes}
                                        onChange={(selected) => {
                                            setColorPaletteState("");
                                            if (selected.length === 0) {
                                                ColorPalettes.clearValue();
                                                setColorPaletteState("");
                                                setColorBtnDisabled(true);
                                                return;
                                            }
                                            setColorBtnDisabled(false);
                                            const selectedObj = selected[0] as {
                                                value;
                                            };
                                            ColorPalettes.setValue(selectedObj.value);
                                            setColorPaletteState(selectedObj.value);
                                        }}
                                        selected={[colorPaletteState]}
                                        errored={errorClass(ColorPalettes) !== ""}
                                        
                                    />
                                    <Button
                                        color="green"
                                        disabled={disabled || !ColorPalettes?.options || onlyActive(ColorPalettes.options).length <= 1 || colorBtnDisabled}
                                        onClick={toggleColorPicker}
                                        style={{ minWidth: "40px", borderRadius: "6px", height: "38px", marginLeft: "5px" }}
                                    >
                                        <FontAwesomeIcon icon={faPalette} />
                                    </Button>
                                </div>
                            </Col>

                        </Row>
                        <Row className="p-2">
                            <Col>
                                {OptionLabel(ShadeConfiguration)}
                                <PowerShadesTypeahead
                                    disabled={disabled || onlyActive(ShadeConfiguration.options).length === 0}
                                    id="shade-configuration"
                                    options={onlyActive(ShadeConfiguration.options) ?? []}
                                    onChange={(selected) => {
                                        if (selected.length === 0) {
                                            ShadeConfiguration.clearValue();
                                            setShadeConfigState("");
                                            return;
                                        }
                                        const selectedObj = selected[0] as {
                                            value: IndoorShadeConfiguration;
                                            label: string;
                                        };

                                        ShadeConfiguration.setValue(selectedObj.value);
                                        setShadeConfigState(selectedObj.label);
                                    }}
                                    selected={[shadeConfigState]}
                                    errored={errorClass(ShadeConfiguration) !== ""}
                                />
                                <OptionFooter option={ShadeConfiguration} quoteType={quoteType} />

                            </Col>
                            <Col>
                                {OptionLabel(HeaderMountType)}
                                <OptionFooter
                                option={HeaderMountType}
                                quoteType={quoteType}
                                optionField={
                                    <PowerShadesTypeahead
                                    disabled={disabled || onlyActive(HeaderMountType.options).length === 0}
                                    id="header-mount-type"
                                    options={onlyActive(HeaderMountType.options)}
                                    onChange={(selected) => {
                                        if (selected.length === 0) {
                                            HeaderMountType.clearValue();
                                            return;
                                        }
                                        const selectedObj = selected[0] as {
                                            value: IndoorMountType;
                                        };

                                        HeaderMountType.setValue(selectedObj.value);
                                    }}
                                    selected={[HeaderMountType.getSelectedOption()]}
                                    errored={errorClass(HeaderMountType) !== ""}
                                    />
                                }
                                />
                            </Col>

                        </Row>
                        <Row className="p-2">
                            <Col xs={6}>
                                {OptionLabel(TotalWidth)}
                                <OptionFooter
                                option={TotalWidth}
                                quoteType={quoteType}
                                optionField={
                                        <FractionField
                                            extraClasses={` ${errorClass(TotalWidth)}`}
                                            key="total-width-edit"
                                            disabled={disabled}
                                            onBlur={(value) => TotalWidth.setValue(value)}
                                            value={TotalWidth.getValue()}
                                            minValue={TotalWidth.minValue ?? 0}
                                            maxValue={TotalWidth.maxValue ?? 0}
                                            varName="total_width"
                                        />
                                }
                                />
                            </Col>
                            <Col>
                                {OptionLabel(Height)}
                                <OptionFooter
                                    option={Height}
                                    quoteType={quoteType}
                                    optionField={
                                    <FractionField
                                        extraClasses={` ${errorClass(Height)}`}
                                        key="height-edit"
                                        disabled={disabled}
                                        onBlur={(value) => Height.setValue(value)}
                                        value={Height.getValue()}
                                        minValue={Height.minValue ?? 0}
                                        maxValue={Height.maxValue ?? 0}
                                        varName="height"
    
                                    />
                                }
                                />
                            </Col>
                        </Row>
                        {
                            isCoupled
                            && <>
                                {GroupHeader("Coupled Shades", quoteType)}
                                <Row className="p-2 pt-0">
                                    <Col
                                        key="form-group-sizes"
                                        className="no-bottom header"
                                    >
                                        <CoupledShadeTable
                                            widthsOption={(options as IndoorShadeOptionsCoupledDict).CoupledWidth}
                                            bandCountOption={(options as IndoorShadeOptionsCoupledDict).BandCount}
                                            disabled={disabled}
                                        />

                                    </Col>
                                </Row>
                            </>
                        }
                        {CustomFabricWidth.active && FabricAlignment.active
                            && <Row className="p-2">
                                <Col xs={6}>
                                    {OptionLabel(CustomFabricWidth)}
                                    <FractionField
                                        extraClasses={` ${errorClass(CustomFabricWidth)}`}
                                        key="total-width-edit"
                                        disabled={disabled}
                                        onBlur={(value) => CustomFabricWidth.setValue(value)}
                                        value={CustomFabricWidth.getValue()}
                                        minValue={CustomFabricWidth.minValue ?? 0}
                                        maxValue={CustomFabricWidth.maxValue ?? 0}
                                        varName="custom_fabric_width"
                                    />
                                    <OptionFooter option={TotalWidth} quoteType={quoteType} />
                                </Col>
                                <Col>
                                    {OptionLabel(FabricAlignment)}
                                    <PowerShadesTypeahead
                                        disabled={disabled || onlyActive(FabricAlignment.options).length === 0}
                                        id="header-mount-type"
                                        options={onlyActive(FabricAlignment.options)}
                                        onChange={(selected) => {
                                            if (selected.length === 0) {
                                                FabricAlignment.clearValue();
                                                return;
                                            }
                                            const selectedObj = selected[0] as {
                                                value: string;
                                            };

                                            FabricAlignment.setValue(selectedObj.value);
                                        }}
                                        selected={[FabricAlignment.getSelectedOption()]}
                                        errored={errorClass(FabricAlignment) !== ""}
                                    />
                                    <OptionFooter option={FabricAlignment} quoteType={quoteType} />
                                </Col>
                            </Row>}
                        <Row className="p-2">
                            <Col className="text-center">
                                {OptionLabel(innerFabric)}
                                <FabricSelectField
                                    id="dropdown-full"
                                    className="text-center"
                                    disabled={disabled || onlyActive(innerFabric.options).length === 0}
                                    value={innerFabric.getValue() ?? ""}
                                    choices={innerFabric.options}
                                    canceled={innerFabric.clearValue}
                                    onChange={(newFabricName) => {
                                        newFabricName
                                            ? innerFabric.setValue(newFabricName)
                                            : innerFabric.clearValue();
                                    }}
                                    onBlur={() => {
                                        console.log("blur");
                                    }}
                                />
                                <OptionFooter option={innerFabric} quoteType={quoteType} />
                            </Col>
                        </Row>
                            {isDual
                            && <Row className="p-2">
                                    <Col>
                                        {OptionLabel(outerFabric)}
                                        <FabricSelectField
                                            id="dropdown-full"
                                            clearButton
                                            disabled={disabled || onlyActive(outerFabric.options).length === 0}
                                            value={outerFabric.getValue() ?? ""}
                                            choices={outerFabric.options}
                                            canceled={outerFabric.clearValue}
                                            onChange={(newFabricName) => {
                                                newFabricName
                                                    ? outerFabric.setValue(newFabricName)
                                                    : outerFabric.clearValue();
                                            }}
                                            onBlur={() => {
                                                console.log("blur");
                                            }}
                                            selected={[outerFabric.getSelectedOption()]}
                                            errored={errorClass(outerFabric) !== ""}
                                        />
                                        <OptionFooter option={outerFabric} quoteType={quoteType} />
                                    </Col>
                            </Row>}
                    </Col>
                </Row>
                <Row className={groupClass} style={{ boxSizing: "border-box", border: "solid transparent 2px", transition: "all .1s" }}>
                    <Col key="form-group-header" className={` no-bottom header `}>
                        {fasciaSplit && fasciaSplit.active && <Row>
                            <Col>
                                {OptionLabel(fasciaSplit)}
                                <PowerShadesTypeahead
                                    disabled={disabled || onlyActive(fasciaSplit.options).length === 0}
                                    id="fascia-split"
                                    options={onlyActive(fasciaSplit.options) ?? []}
                                    onChange={(selected) => {
                                        setGroupClass("p-2 rounded");
                                        if (selected.length === 0) {
                                            fasciaSplit.clearValue();
                                            return;
                                        }
                                        const selectedObj = selected[0] as {
                                            value;
                                        };
                                        fasciaSplit.setValue(selectedObj.value);
                                    }}
                                    selected={[fasciaSplit.getSelectedOption()]}
                                    errored={errorClass(fasciaSplit) !== ""}
                                />
                                <OptionFooter option={fasciaSplit} quoteType={quoteType} />
                            </Col>
                        </Row>}

                        <Row className="pb-2">
                            <Col>
                                {OptionLabel(ControlSide)}
                                <PowerShadesTypeahead
                                    disabled={disabled || onlyActive(ControlSide.options).length === 0}
                                    id="control-side"
                                    options={onlyActive(ControlSide.options) ?? []}
                                    onChange={(selected) => {
                                        setGroupClass("p-2 rounded");
                                        if (selected.length === 0) {
                                            ControlSide.clearValue();
                                            return;
                                        }
                                        const selectedObj = selected[0] as {
                                            value;
                                        };
                                        ControlSide.setValue(selectedObj.value);
                                    }}
                                    selected={[ControlSide.getSelectedOption()]}
                                    errored={errorClass(ControlSide) !== ""}
                                />
                                <OptionFooter option={ControlSide} quoteType={quoteType} />
                            </Col>
                        </Row>
                        <Row className="py-2">
                            <Col
                                key="form-group-sizes"
                                className="no-bottom header"
                            >
                                <Row>
                                    <Col>
                                        {OptionLabel(innerRollDirectionOption)}
                                        <PowerShadesTypeahead
                                            disabled={disabled || onlyActive(innerRollDirectionOption.options).length === 0}
                                            id="inner-roll-direction"
                                            options={onlyActive(innerRollDirectionOption.options) ?? []}
                                            onChange={(selected) => {
                                                if (selected.length === 0) {
                                                    innerRollDirectionOption.clearValue();
                                                    return;
                                                }
                                                const selectedObj = selected[0] as {
                                                    value;
                                                };

                                                innerRollDirectionOption.setValue(selectedObj.value);
                                            }}
                                            selected={[innerRollDirectionOption.getSelectedOption()]}
                                            clearButton={innerRollDirectionOption.getSelectedOption().label !== ""}
                                            errored={errorClass(innerRollDirectionOption) !== ""}
                                        />
                                        <OptionFooter option={innerRollDirectionOption} quoteType={quoteType} />
                                    </Col>
                                    {isDual ? <Col>
                                        {OptionLabel(outerRollDirectionOption)}
                                        <PowerShadesTypeahead
                                            disabled={disabled || onlyActive(outerRollDirectionOption.options).length === 0}
                                            id="outer-roll-direction"
                                            options={onlyActive(outerRollDirectionOption.options)}
                                            onChange={(selected) => {
                                                if (selected.length === 0) {
                                                    outerRollDirectionOption.clearValue();
                                                    return;
                                                }
                                                const selectedObj = selected[0] as {
                                                    value;
                                                };

                                                outerRollDirectionOption.setValue(selectedObj.value);
                                            }}
                                            selected={[outerRollDirectionOption.getSelectedOption()]}
                                            clearButton={outerRollDirectionOption.getSelectedOption().label !== ""}
                                            errored={errorClass(outerRollDirectionOption) !== ""}
                                        />
                                        <OptionFooter option={outerRollDirectionOption} quoteType={quoteType} />

                                    </Col> : null}
                                </Row>
                            </Col>
                        </Row>
                        <Row className="py-2">
                        <Col>
                                {OptionLabel(HembarLine)}
                                <PowerShadesTypeahead
                                    disabled={disabled || onlyActive(HembarLine.options).length === 0}
                                    id="hembar-type"
                                    options={onlyActive(HembarLine.options) ?? []}
                                    onChange={(selected) => {
                                        setGroupClass("p-2 rounded");
                                        if (selected.length === 0) {
                                            HembarLine.clearValue();
                                            return;
                                        }
                                        const selectedObj = selected[0] as {
                                            value;
                                        };
                                        HembarLine.setValue(selectedObj.value);
                                    }}
                                    selected={[HembarLine.getSelectedOption()]}
                                    errored={errorClass(HembarLine) !== ""}
                                />
                                <OptionFooter option={HembarLine} quoteType={quoteType} />
                            </Col>
                            <Col>
                                {OptionLabel(HembarStyle)}
                                    <PowerShadesTypeahead
                                        disabled={disabled || onlyActive(HembarStyle.options).length === 0}
                                        id="hembar-type"
                                        options={onlyActive(HembarStyle.options) ?? []}
                                        onChange={(selected) => {
                                            setGroupClass("p-2 rounded");
                                            if (selected.length === 0) {
                                                HembarStyle.clearValue();
                                                return;
                                            }
                                            const selectedObj = selected[0] as {
                                                value;
                                            };
                                            HembarStyle.setValue(selectedObj.value);
                                        }}
                                        selected={[HembarStyle.getSelectedOption()]}
                                        errored={errorClass(HembarStyle) !== ""}
                                    />
                                <OptionFooter option={HembarStyle} quoteType={quoteType} />
                            </Col>
                        </Row>
                       <Row className="py-2">
                            {SideChannels.active &&   <Col xs={6}>
                                {OptionLabel(SideChannels)}
                                <PowerShadesTypeahead
                                    disabled={disabled || onlyActive(SideChannels.options).length === 0}
                                    id="side-channels"
                                    options={onlyActive(SideChannels.options) ?? []}
                                    onChange={(selected) => {
                                        setGroupClass("p-2 rounded");
                                        if (selected.length === 0) {
                                            SideChannels.clearValue();
                                            setSideChannelState("");
                                            return;
                                        }
                                        const selectedObj = selected[0] as {
                                            value;
                                            label;
                                        };
                                        SideChannels.setValue(selectedObj.value);
                                        setSideChannelState(selectedObj.label);
                                    }}
                                    selected={[sideChannelState]}
                                    errored={errorClass(SideChannels) !== ""}
                                />
                                <OptionFooter option={SideChannels} quoteType={quoteType} />
                            </Col>}
                            {EndCaps.active && <Col xs={6}>
                                {OptionLabel(EndCaps)}
                                <PowerShadesTypeahead
                                    disabled={disabled || onlyActive(EndCaps.options).length === 0}
                                    id="fabric"
                                    options={onlyActive(EndCaps.options) ?? []}
                                    onChange={(selected) => {
                                        if (selected.length === 0) {
                                            EndCaps.clearValue();
                                            return;
                                        }
                                        const selectedFabric = selected[0] as {
                                            value: IndoorEndCaps;
                                        };
                                        EndCaps.setValue(selectedFabric.value);
                                    }}
                                    selected={[EndCaps.getSelectedOption()]}
                                    errored={errorClass(EndCaps) !== ""}
                                />
                                <OptionFooter option={EndCaps} quoteType={quoteType} />
                            </Col>}
                        </Row>
                        <Row className="pt-2" hidden={!LVPowerSupply.active && !fasciaSplit?.active}>
                            {LVPowerSupply.active && <Col>
                                {OptionLabel(LVPowerSupply)}
                                <PowerShadesTypeahead
                                    disabled={disabled || onlyActive(LVPowerSupply.options).length === 0}
                                    id="lv-power-supply"
                                    options={onlyActive(LVPowerSupply.options) ?? []}
                                    onChange={(selected) => {
                                        setGroupClass("p-2 rounded");
                                        if (selected.length === 0) {
                                            LVPowerSupply.clearValue();
                                            return;
                                        }
                                        const selectedObj = selected[0] as {
                                            value;
                                        };
                                        LVPowerSupply.setValue(selectedObj.value);
                                    }}
                                    selected={[LVPowerSupply.getSelectedOption()]}
                                    className="text-center"
                                    errored={errorClass(LVPowerSupply) !== ""}
                                />
                                <OptionFooter option={LVPowerSupply} quoteType={quoteType} />
                            </Col>}
                            {fasciaSplit?.active && <Col>
                                {OptionLabel(fasciaSplit)}
                                <PowerShadesTypeahead
                                    disabled={disabled || onlyActive(fasciaSplit.options).length === 0}
                                    id="fascia-split"
                                    options={onlyActive(fasciaSplit.options) ?? []}
                                    onChange={(selected) => {
                                        setGroupClass("p-2 rounded");
                                        if (selected.length === 0) {
                                            fasciaSplit.clearValue();
                                            return;
                                        }
                                        const selectedObj = selected[0] as {
                                            value;
                                        };
                                        fasciaSplit.setValue(selectedObj.value);
                                    }}
                                    selected={[fasciaSplit.getSelectedOption()]}
                                    errored={errorClass(fasciaSplit) !== ""}
                                />
                                <OptionFooter option={fasciaSplit} quoteType={quoteType} />
                            </Col>}
                        </Row>
                        <Row>
                            {BalancedLightGaps.active && <Col>
                                {OptionLabel(BalancedLightGaps)}
                                <PowerShadesTypeahead
                                    disabled={disabled || onlyActive(BalancedLightGaps.options).length === 0}
                                    id="balanced-lightgaps"
                                    options={onlyActive(BalancedLightGaps.options) ?? []}
                                    onChange={(selected) => {
                                        setGroupClass("p-2 rounded");
                                        if (selected.length === 0) {
                                            BalancedLightGaps.clearValue();
                                            return;
                                        }
                                        const selectedObj = selected[0] as {
                                            value;
                                        };
                                        BalancedLightGaps.setValue(selectedObj.value);
                                    }}
                                    selected={[BalancedLightGaps.getSelectedOption()]}
                                    className="text-center p-2"
                                    errored={errorClass(BalancedLightGaps) !== ""}
                                />
                                <OptionFooter option={BalancedLightGaps} quoteType={quoteType} />
                            </Col>}

                        </Row>
                    </Col>
                </Row>
                {WindowJambDepth.active && <Row className="p-2 pt-0">
                    <Col>
                        {OptionLabel(WindowJambDepth)}
                        <FractionField
                            key="window_jamb_depth"
                            disabled={disabled}
                            onBlur={(value) => {
                                setGroupClass("p-2 rounded");
                                WindowJambDepth.setValue(value);
                            }}
                            value={WindowJambDepth.getValue()}
                            minValue={WindowJambDepth.minValue ?? 0}
                            maxValue={WindowJambDepth.maxValue ?? 0}
                            varName="window_jamb_depth"
                            extraClasses={` ${errorClass(WindowJambDepth)}`}
                        />
                        <OptionFooter option={WindowJambDepth} quoteType={quoteType} />
                    </Col>
                    <Col />
                </Row>}
                <Row className="p-2">
                    <Col>
                        {OptionLabel(DirectionFacing)}
                        <PowerShadesTypeahead
                            id="direction-facing"
                            clearButton={!!DirectionFacing.getSelectedOption()}
                            options={onlyActive(DirectionFacing.options) ?? []}
                            onChange={(selected) => {
                                if (selected.length === 0) {
                                    DirectionFacing.clearValue();
                                    return;
                                }
                                const selectedObj = selected[0] as {
                                    value;
                                };

                                DirectionFacing.setValue(selectedObj.value);
                            }}
                            selected={[DirectionFacing.getSelectedOption()]}
                            errored={errorClass(DirectionFacing) !== ""}
                        />
                        <OptionFooter option={DirectionFacing} quoteType={quoteType} />
                    </Col>
                    <Col>
                        {OptionLabel(Floor)}
                        <input
                            type="number"
                            className={`rounded input-group p-2 ${errorClass(Floor)}`}
                            value={Floor.getValue()}
                            onChange={(e) => Floor.setValue(Number(e.target.value))}
                        />
                        <OptionFooter option={Floor} quoteType={quoteType} />
                    </Col>
                </Row>
                <Row className="p-2">
                    <Col xs={6}>
                        {OptionLabel(RoomName)}
                        <input
                            className={`rounded input-group p-2 ${errorClass(RoomName)}`}
                            type="text"
                            value={roomNameValue}
                            onChange={(e) => {
                                setRoomNameValue(e.target.value);
                                debouncedSetRoomName(e.target.value);
                            }}
                        />
                        <OptionFooter option={RoomName} quoteType={quoteType} />
                    </Col>
                    <Col xs={6}>
                        {OptionLabel(ShadeName)}
                        <input
                            className={`rounded input-group p-2 ${errorClass(ShadeName)}`}
                            disabled={disabled}
                            type="text"
                            value={shadeNameValue}
                            onChange={(e) => {
                                setShadeNameValue(e.target.value);
                                debouncedSetShadeName(e.target.value);
                            }}
                        />
                        <OptionFooter option={ShadeName} quoteType={quoteType} />
                    </Col>
                </Row>

                <Row className="text-center p-2" />
            </Col>}
            {
                isColorPickerOpen
                && <ColorPaletteModal
                    colors={options.Colors}
                    colorPalettes={{ ...ColorPalettes, options: activeColorPalettes }}
                    errorClass={errorClass}
                    quoteType={quoteType}
                />
            }
        </Row>
    );
};

export default EditIndoorShadeModalInner;
