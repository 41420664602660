import React, { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import type { Option } from 'react-bootstrap-typeahead/types/types';

import { useRepresentatives, useRepresentativesLoadStatus } from '../Store/entities/hooks';

import type { RepresentativeSelectProps } from './types';
import { RepresentativeStore } from '../Store/entities/entitiesType';

const RepresentativeSelect = ({
	multiple = false,
	optionsOverride,
	handleChange,
	handleMultiChange,
	disableOverride = false,
}: RepresentativeSelectProps) => {
	const [repSelectOpen, setRepSelectOpen] = useState(false);
	const representatives = useRepresentatives();
	const representativesLoadingStatus = useRepresentativesLoadStatus();
	const representativesLoading = representativesLoadingStatus <= 3;

	const shouldBeDisabled = representativesLoading || disableOverride;

	const handleRepChange = (selected: Option[]) => {
		if (!selected) return;
		if (multiple && handleMultiChange) {
			const selectedReps = selected as unknown as RepresentativeStore[];
			handleMultiChange(selectedReps.map((r) => r.id));
			return;
		}
		if (!multiple && handleChange) {
			const selectedRep = selected as unknown as RepresentativeStore;
			handleChange(selectedRep[0]?.id ?? 0);
			setRepSelectOpen(false);
			return;
		}
		throw new Error('RepresentativeSelect: handleChange or handleMultiChange not defined');
	};

	return (
		<Typeahead
			id="rep-selector"
			paginate
			multiple={multiple}
			positionFixed
			clearButton
			open={repSelectOpen}
			labelKey="name"
			onFocus={() => setRepSelectOpen(true)}
			onBlur={() => setRepSelectOpen(false)}
			placeholder={
				representativesLoading ? 'Loading Representatives...' : 'Select a Representative...'
			}
			options={optionsOverride ?? representatives}
			isLoading={representativesLoading}
			disabled={shouldBeDisabled}
			onChange={handleRepChange}
			onInputChange={() => {
				if (!repSelectOpen) setRepSelectOpen(true);
			}}
		/>
	);
};

export default RepresentativeSelect;
