import { useEffect } from 'react';
import { isRouteErrorResponse } from 'react-router';

import HomeRow from '../Parts/HomeRow';

const PageNotFound = (props) => {
	const { setTitle, loaded } = props;
	const error = ""; // useRouteError();

	useEffect(() => {
		if (isRouteErrorResponse(error) && error.status === 404) {
			console.log(error);
			setTitle('Page Not Found | PowerShades Dealer Portal');
		} else {
			setTitle('Something Went Wrong | PowerShades Dealer Portal');
		}
		loaded();
	}, [error, loaded, setTitle]);

	return (
		<HomeRow>
			<div className="four-zero-four-card">
				{error.status === 404 ? (
					<>
						<h1>Page Not Found</h1>
						<span>
							Please double-check that the url you entered is correct,
							<br />
							and contact PowerShades if your issue persists.
						</span>
					</>
				) : (
					<>
						<h1>Something Went Wrong</h1>
						<span>
							It looks like something went wrong. PowerShades engineering has been
							notified about the issue. Please reach out to us if you have any other
							questions.
						</span>
					</>
				)}
			</div>
		</HomeRow>
	);
};

export default PageNotFound;
