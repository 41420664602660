import React, { useEffect, useState } from 'react';
import Styles from '../Styles';
import Button from '../Parts/Button';

const InvoiceStatus = (props) => {
	const [invoice, setInvoice] = useState(null);
	const [success, setSuccess] = useState(null);
	const [loading, setLoading] = useState(true);

	const { quoteId, close, isPsAdmin, alert } = props;

	useEffect(() => {
		api.getInvoiceData(quoteId).then((resp) => {
			const { data } = resp;

			const { invoice } = data;
			const { success } = data;

			setInvoice(invoice);
			setSuccess(success);
			setLoading(false);
		});
	}, quoteId);

	let display = null;

	if (loading) {
		display = <>Loading....</>;
	} else if (success === false) {
		display = (
			<>
				No invoice found. Invoices after March 12th do not show up on the Dealer Portal.
				Contact PowerShades for assistance.
			</>
		);
	} else if (success === true && invoice == null) {
		display = <>No Invoice was found.</>;
	} else {
		const [status, description, showPay] = (function (invoice) {
			const invoiceStatus = invoice.status;
			if (invoice.balance == 0.0 && invoiceStatus !== 'draft') {
				return ['Paid', 'The invoice for this order has been paid.', true];
			} else if (invoiceStatus == 'draft') {
				return ['Not Sent.', 'The invoice will be sent once the order is shipped.', false];
			} else if (Date.parse(invoice.Date) > Date.parse(invoice.DueDate)) {
				return ['Overdue', 'The invoice for this order is overdue.', false];
			}
			return ['Sent', 'The invoice for this order is sent.', true];
		})(invoice);

		const updateStatus = () => {
			api.updateZohoInvoice(quoteId).then((resp) => {
				const { data } = resp;

				ROOT.alert({ text: 'Command Sent. Data will update.', quickFormat: 'success' });

				setInvoice(data);
			});
		};

		display = (
			<div>
				<h3>
					Status:
					{status}
				</h3>
				<h4>
					Description:
					{description}
				</h4>
				<div>
					Invoice Total:
					{invoice.total}
				</div>
				{showPay ? (
					<>
						<div>
							Amount Paid:
							{invoice.total - invoice.balance}
						</div>
						<div>
							Remaining Balance:
							{invoice.balance}
						</div>
						<div>
							Invoice Sent Date
							{invoice.date}
						</div>
						<div>
							Invoice Due Date
							{invoice.due_date}
						</div>
					</>
				) : null}
				<Button color="green" onClick={() => window.open(invoice.invoice_url)}>
					Pay Here
				</Button>
				{isPsAdmin ? (
					<Button color="green" onClick={updateStatus}>
						Update Invoice
					</Button>
				) : null}
			</div>
		);
	}

	return (
		<div className="admin-payment" style={{ overflow: 'hidden auto' }}>
			<h2 style={{ color: Styles.psGreen, marginBottom: '0' }}>Invoice Status</h2>
			<div>{display}</div>
			<div>
				{' '}
				<Button color="green" onClick={close}>
					Close
				</Button>
			</div>
		</div>
	);
};

export default InvoiceStatus;
