import { useEffect } from 'react';

import HomeRow from '../Parts/HomeRow';
import QuoteTagSelector from '../Quote/TagSelector';

export default function ManageQuoteTags(props) {
	useEffect(() => {
		props.ROOT.loaded();
		props.setTitle('Manage Quote Tags');
	}, []);

	return (

		<HomeRow style={{
			display: 'block', marginBottom: '5em', width: '1090px', maxWidth: '90%', overflow: 'auto hidden', paddingBottom: '1em'
		}}
		>
			
			<QuoteTagSelector />

		</HomeRow>
	);
}
