import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCalls from '../../PowerShadesAPIFunctions';
import type { EntitiesState } from "./entitiesType";
import { loadStatus } from "./entitiesType";
import { organizationBuilder } from "./organizations";
import { territoryBuilder } from "./territories";
import { AddressStateStore } from "./addressStates";
import { initialQuotesState, quoteBuilder } from "./quotes";
import { rmaTypeBuilder } from "./rmaTypes/index";
import { accessoryBuilder } from "./accessories";
import { partsBuilder } from "./parts";
import { captureSentryError, extractErrorMessage, isFailedApiCall } from "../../psUtil";
import { assemblyBuilder } from "./assemblies";
import { fabricBuilder } from "./fabrics";
import { quoteWarningsBuilder } from "./quotewarnings";
import { quoteMultiplierBuilder } from "./multipliers";
import { quoteHWItemsBuilder } from "./hardwareRequestItems";
import { quoteFabricSamplesBuilder } from "./fabricSamples";
import { rmaBuilder } from "./rmas";
import { assemblyOptionsBuilder } from "./assemblyOptions";
import { combinationsExtraReducers } from "./combinations";
import { portfolioBuilder } from "./portfolios";

export const initialEntitiesState: () => EntitiesState = () => ({
	territories: {
		list: [],
		loadStatus: loadStatus.notLoaded
	},
	dealers: {
		loadStatus: loadStatus.notLoaded,
		lastMetaLoadedTime: 0,
		totalPendingDealers: 0,
		territoryPendingDealers: 0,
	},
	distributors: {
		loadStatus: loadStatus.notLoaded,
	},
	distributorLocations: {
		loadStatus: loadStatus.notLoaded,
	},
	representatives: {
		loadStatus: loadStatus.notLoaded,
	},
	organizations: {
		loadStatus: loadStatus.notLoaded,
	},
	salespeople: {
		loadStatus: loadStatus.notLoaded,
		list: []
	},
	addresses: {},
	fabrics: {
		loadStatus: 1,
		list: []
	},
	outdoorFabrics: {
		loadStatus: 1,
		list: []
	},
	addressStates: {
		list: [],
		loadStatus: loadStatus.notLoaded,
	},
	termsOptions: {
		list: [],
		loadStatus: loadStatus.notLoaded,
	},
	channels: {
		list: [],
		loadStatus: loadStatus.notLoaded,
	},
	buyingGroups: {
		list: [],
		loadStatus: loadStatus.notLoaded,
	},
	quotes: initialQuotesState,
	users: {
		list: {},
		roles: {
			list: {},
			loadStatus: loadStatus.notLoaded
		},
		loadStatus: loadStatus.notLoaded
	},
	manufacturers: {
		list: {},
		loadStatus: loadStatus.notLoaded
	},
	assemblies: {},
	rmaTypes: {
		list: [],
		departments: [],
		root_causes: [],
		loadStatus: loadStatus.notLoaded
	},
	rmas: {
		list: [],
		loadStatus: loadStatus.notLoaded
	},
	quoteAccessories: {},
	hardwareRequestItems: {},
	quoteHardwareRequests: {},
	quoteFabricSamples: {},
	quoteWarnings: {},
	accessories: {
		loadStatus: 1,
		list: []
	},
	parts: {
		motors: {
			loadStatus: 1,
			list: []
		},
		tubes: {
			loadStatus: 1,
			list: []
		},
		all: {
			loadStatus: 1,
			list: []
		}
	},
	quoteMulitpliers: {},
	quoteAssemblyCombinations: {},
	assemblyOptions: {
		headers: [],
		side_channels: [],
		hembars: [],
		mount_types: [],
		control_sides: [],
		shade_types: [],
		drive_types: [],
		end_caps: [],
		direction_facing: [],
		light_gaps: [],
		lv_power_supply: [],
		full_palette_list: [],
		color_details: []
	},
	portfolios: {
		loadStatus: loadStatus.notLoaded,
		list: []
	},
});

const loadAddressStates = createAsyncThunk('entity/loadAddressStates', async (_, { rejectWithValue }) => {
	try {
		const { getCountrySubdivisions } = apiCalls;

		const addressStates = await getCountrySubdivisions();

		if (isFailedApiCall(addressStates)) {
			throw addressStates.error; 
		}

		const addressStatesMap: AddressStateStore[] =
			addressStates.data.country_subdivisions.map((as) => ({
				id: as.id ?? 0,
				name: as.name ?? "",
				code: as.code ?? "",
				type: as.type ?? "",
				country_name: as.country_name,
				country_code: as.country_code,
			}));

		return addressStatesMap;
	} catch (err: any) {
		captureSentryError(err, {
			tags: {
				section: 'redux'
			},
			extra: {
				redux_action: 'loadAddressStates',
				file_origin: 'src/Store/entities/index.ts'
			},
		});

		const errorMessage = extractErrorMessage(err);
		return rejectWithValue(errorMessage);
	}

});

export const loadSalesPeople = createAsyncThunk('entity/loadSalesPeople', async (_, { rejectWithValue }) => {
	try {
		const { getSalespeople } = apiCalls;

		const salesPeople = await getSalespeople();

		if (isFailedApiCall(salesPeople)) {
			throw salesPeople.error;
		}

		return salesPeople.data.salespeople;
	} catch (err: any) {
		captureSentryError(err, {
			tags: {
				section: 'redux'
			},
			extra: {
				redux_action: 'loadSalesPeople',
				file_origin: 'src/Store/entities/index.ts'
			},
		});

		const errorMessage = extractErrorMessage(err);
		return rejectWithValue(errorMessage);
	}
});


/*
const updateQuoteStr = "entity/quotes/updateQuote";

export const UpdateQuoteStore: (quote: QuoteStore) => AnyAction = (quote) => ({
	type: updateQuoteStr,
	payload: quote
});
*/

const entitySlice = createSlice({
	name: 'entities',
	initialState: initialEntitiesState,
	reducers: {
		setCountsLoadStatus: (state, action) => {
			state.quotes.counts.loadStatus = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(loadAddressStates.fulfilled, (entities, action) => {
			entities.addressStates = {
				list: action.payload.map((a) => {
					const goods: AddressStateStore = {
						id: a.id,
						name: a.name,
						code: a.code,
						type: a.type,
						country_name: a.country_name,
						country_code: a.country_code,
					};

					return goods;

					/* shh eslint */
				}),
				loadStatus: loadStatus.fullyLoaded
			};
		});
		builder.addCase(loadAddressStates.pending, (entities, _) => {
			entities.addressStates.loadStatus = loadStatus.fullyLoaded;
		});

		builder.addCase(loadSalesPeople.pending, (entities, _) => {
			entities.salespeople.loadStatus = loadStatus.loading;
		});

		builder.addCase(loadSalesPeople.fulfilled, (entities, action) => {
			entities.salespeople.loadStatus = loadStatus.fullyLoaded;
			entities.salespeople.list = action.payload;
		});

		// Add territory thunks
		territoryBuilder(builder);

		// Add Org thunks
		organizationBuilder(builder);

		// Add Quote Thunks
		quoteBuilder(builder);

		// Add RMAType Thunks
		rmaTypeBuilder(builder);
		
		// Add RMA Thunks
		rmaBuilder(builder);

		// Add Accessory Thunks
		accessoryBuilder(builder);

		// Add Parts Thunks
		partsBuilder(builder);

		// Add Assembly Thunks
		assemblyBuilder(builder);

		// Add Quote Warning Thunks
		quoteWarningsBuilder(builder);

		// Add Quote Multiplier Thunks
		quoteMultiplierBuilder(builder);

		// Add HWItems
		quoteHWItemsBuilder(builder);

		// Add FB Samples
		quoteFabricSamplesBuilder(builder)

		// Add Assembly Option Thunks
		assemblyOptionsBuilder(builder);
		// Add Quote Combinations
		combinationsExtraReducers(builder);

		// Add Portfolio Thunks
		portfolioBuilder(builder);
		
		// Add Fabric Thunks - Must be last
		fabricBuilder(builder);

		
		
	},
});

export const { setCountsLoadStatus } = entitySlice.actions;

export const entitySliceReducer = entitySlice.reducer;

export { entitySlice, loadAddressStates };
