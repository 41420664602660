import React, { useState } from "react";
import { InputGroup, FormControl, Tooltip, OverlayTrigger, Form } from "react-bootstrap";

import apiCalls from "../../PowerShadesAPIFunctions";

import Button from "../Button";

import type { GoToQuoteProps } from "./types";

import styles from "./GoToQuote.module.scss";

const GotoQuote = ({ ROOT, isPsAdmin }: GoToQuoteProps) => {
	const [findQuoteID, setFindQuoteID] = useState("");
	const [loading, setLoading] = useState(false);
	const [prefersNewQuoteStyle, setPrefersNewQuoteStyle] = useState(false);

	const validateInput = (input: string) => {
		const formattedInput = input.toUpperCase();

		setFindQuoteID(formattedInput);
	};

	const findQuote = () => {
		setLoading(true);
		const trimmedQuoteID = findQuoteID.trim();

		const cleanQuoteID = trimmedQuoteID.replace(/\D/g, "");

		setFindQuoteID("Loading...");

		if (Number.isNaN(cleanQuoteID)) {
			ROOT.alert({
				title: "Invalid Quote Number",
				text: `"${findQuoteID}" is not a valid quote/order number.`,
				icon: "error",
				smallIcon: true,
				noCancelButton: true,
				acceptButtonText: "Dismiss",
				closeOnDocumentClick: true
			});
			setLoading(false);
			setFindQuoteID("");
			return;
		}

		const quoteId = parseInt(cleanQuoteID.toString().replace(/[a-zA-Z]/g, ""), 10);

		apiCalls.quoteExists(quoteId).then((resp) => {
			setLoading(false);
			setFindQuoteID("");
			if (resp.data.exists && resp.data.exists === true) {
				if (prefersNewQuoteStyle) {
					ROOT.navigate(`/NewQuote/Details?quoteID=${quoteId}`);
				} else {
					ROOT.navigate(`/Quote?quoteID=${quoteId}`);
				}
			} else {
				ROOT.alert({
					text: `Quote/Order ${findQuoteID} could not be found.`,
					title: "Quote Not Found",
					icon: "warning",
					smallIcon: true,
					noCancelButton: true,
					acceptButtonText: "Dismiss",
					closeOnDocumentClick: true
				});
			}
		});
	};

	const buttonShouldBeDisabled = loading || findQuoteID.length === 0;

	return (
		<span className="local-bootstrap">
			<InputGroup>
				{isPsAdmin && (
					<OverlayTrigger
						placement="auto"
						overlay={<Tooltip id="new-quote-view">Enable this to use the (beta) new quote view!</Tooltip>}
					>
						<InputGroup.Text>
							<Form.Check
								aria-label="New Quote Style"
								checked={prefersNewQuoteStyle}
								onChange={() => setPrefersNewQuoteStyle(!prefersNewQuoteStyle)}
							/>
						</InputGroup.Text>
					</OverlayTrigger>
				)}
				<FormControl
					className={`${styles.searchBar}`}
					name="Open-Quote-ID"
					type="text"
					value={findQuoteID}
					disabled={loading}
					placeholder="Quote #"
					onChange={(e) => validateInput(e.target.value)}
					onKeyDown={({ key }) => key.toString().toLowerCase() === "enter" && findQuote()}
				/>
				<Button
					className="me-2"
					disabled={buttonShouldBeDisabled}
					color={buttonShouldBeDisabled ? "gray" : "green"}
					onClick={findQuote}
				>
					Go
				</Button>
			</InputGroup>
		</span>
	);
};

export default GotoQuote;
