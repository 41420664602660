/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { faCircleCheck, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";

import type { AddressBlockIconButtonProps } from "./types";
import notImplementedModal from "../NotImplemented";
import Button from "../../Parts/Button";

const AddressBlockIconButton = ({
	icon,
	locked,
	conditional = false,
	description,
	onChange = () => notImplementedModal()
}: AddressBlockIconButtonProps) => (
	<OverlayTrigger
		placement="bottom"
		overlay={<Tooltip id="address-block-icon-button">{description}</Tooltip>}
	>
		{/* This span is necessary for overlay trigger to attach a ref! */}
		<span>
			<Button
				className="no-style address-block-button"
				disabled={locked}
				onClick={() => onChange()}
				color="clear"
			>
				<span className="fa-stack fa-1x stacked-icons">
					<FontAwesomeIcon icon={icon} className="fa-stack-2x" />
					{conditional ? (
						<FontAwesomeIcon icon={faCircleCheck} className="fa-stack-1x text-success" />
					) : (
						<FontAwesomeIcon icon={faXmarkCircle} className="fa-stack-1x text-danger" />
					)}
				</span>
			</Button>
		</span>
	</OverlayTrigger>
);

export default AddressBlockIconButton;
