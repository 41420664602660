import React from 'react';
import { startCase } from 'lodash';

import { useRolesList } from '../Store/entities/hooks';

import type { UserRoleSelectProps } from './types';
import type { Role } from '../powershadesApiTypeExtensions';
import type { EntityTypeStrings } from '../psUtil';

const UserRoleSelect = ({
	setSelectedUserRoleId,
	setSelectedRoleCategory,
	setSelectedRole,
	categoryOverride = undefined
}: UserRoleSelectProps) => {
	const roles = useRolesList();

	const { roleGroups, roleIdToCategory } = Object.values(roles).reduce<{
		roleGroups: Record<string, Role[]>;
		roleIdToCategory: Record<number, string>;
	}>(
		(acc, role) => {
			// Take care of the special 'distributor_location' case
			const category = role.name.startsWith('distributor_location_')
				? 'distributor_location'
				: role.name.split('_')[0];

			// Format the category name
			const formattedCategory = startCase(category);

			// Update the role ID to category map
			const newRoleIdToCategory = { ...acc.roleIdToCategory, [role.id]: formattedCategory };

			// Update the category to role array map
			const newRoleGroups = {
				...acc.roleGroups,
				[formattedCategory]: [...(acc.roleGroups[formattedCategory] ?? []), role],
			};

			return {
				roleIdToCategory: newRoleIdToCategory,
				roleGroups: newRoleGroups,
			};
		},
		{ roleGroups: {}, roleIdToCategory: {} }
	);

	const roleOptions = Object.entries(roleGroups).map((rg) => {
		const [groupName, workingRoles] = rg;
		if (
			(categoryOverride !== undefined && categoryOverride !== 'unknown')
			&& groupName.toLowerCase() !== categoryOverride?.toLowerCase()
		) {
			return null;
		}
		return (
			<optgroup key={groupName} label={groupName}>
				{workingRoles.map((role) => (
					<option key={role.id} value={role.id}>
						{startCase(role.name)}
					</option>
				))}
			</optgroup>
		);
	});
	
	roleOptions.unshift(<option disabled selected key={0} value={0}>Select a role...</option>);

	const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const roleId = parseInt(e.target.value, 10);
		setSelectedUserRoleId && setSelectedUserRoleId(roleId);
		setSelectedRoleCategory && setSelectedRoleCategory((roleIdToCategory[roleId] ?? 'unknown') as EntityTypeStrings);
		const selectedRole = Object.values(roles).find((role) => role.id === roleId);
		if (selectedRole) {
			setSelectedRole && setSelectedRole(selectedRole);
		}
	};

	return (
		<select className="form-select" onChange={handleRoleChange}>
			{roleOptions}
		</select>
	);
};

export default UserRoleSelect;
