/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable camelcase */
import React from 'react';
import {
	Accordion, Card, Row, Col, Dropdown, ListGroupItem
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faClone, faCog, faLock, faPenToSquare, faTrashCan, faUnlock
} from '@fortawesome/free-solid-svg-icons';
import { startCase, upperFirst } from 'lodash';

import { prettifySideChannels, shortenMotorType, timeAgo } from '../psUtil';
import { IndoorShadeDefaults } from '../ShadeCardTesting/DummyShades';
import Button from './Button';

import type { ShadeCardProps } from './types';

import styles from './ShadeCard.module.scss';
import Money from './Money';

const ShadeCard = ({ shade, testingOverrides }: ShadeCardProps) => {
	const lastUpdatedTime = timeAgo(shade.last_time_priced);

	// TODO: These are used exclusively for testing - remove them when done
	const isPsAdmin_dev = testingOverrides.isPowerShadesAdmin ?? false;
	const isShadeUnlocked_dev = testingOverrides.unlocked ?? false;

	const isPsAdmin = isPsAdmin_dev;
	const isShadeUnlocked = isShadeUnlocked_dev;

	const lockIcon = isShadeUnlocked ? faUnlock : faLock;
	const bandCount = shade?.band_count ?? 0;
	const coupledShade = bandCount > 1;

	const { viewingWithDropDowns } = testingOverrides;

	const notCoupledSingleShade = !coupledShade && shade.single_dual === 'Single';

	const singleShadeAccessor = (shade.shades[0] ?? IndoorShadeDefaults.shades[0])!;

	const width = coupledShade ? shade.total_width : singleShadeAccessor.width;
	const motorizedShade = singleShadeAccessor.motor_type !== '';

	const accordionMultiBandBreakdown = shade.shades.map((shadeAssembly, i) => {
		if (viewingWithDropDowns) {
			return (
				<Accordion.Item
					as={ListGroupItem}
					className={styles.detailedBreakdown}
					eventKey={shadeAssembly.shade_assembly_id.toString() + i.toString()}
				>
					<Accordion.Header className={styles.header}>
						{shade.single_dual === 'Dual' && i % 2 === 0
							? 'Inside Shade '
							: shade.single_dual === 'Dual' && 'Outside Shade '}
						{shade.is_coupled && `Band ${shadeAssembly.column_coordinate + 1} `}
						Details
					</Accordion.Header>
					<Accordion.Body className={styles.body}>
						{shade.is_coupled && (
							<Row>
								<Col>
									{`Width: ${shadeAssembly.width} inches`}
								</Col>
							</Row>
						)}
						<Row>
							<Col>
								Fabric: &nbsp;
								<a>{shadeAssembly.fabric_name}</a>
							</Col>
						</Row>
						<Row>
							<Col>
								{`Roll Direction: ${upperFirst(shadeAssembly.roll_direction)}`}
							</Col>
						</Row>

					</Accordion.Body>
				</Accordion.Item>
			);
		}
		return (
			<>
			</>
		);
	});

	// const multiBandBreakdown = shade.shades.map((shadeAssembly, i) => {
	// 	console.debug();
	// 	return (
	// 		<>
	// 			{shade.is_coupled && (
	// 				<Row>
	// 					<Col>
	// 						{`Band ${shadeAssembly.column_coordinate + 1} | ${shade.single_dual === 'Dual' && i % 2 === 0 ? ` Inside Shade | ${shadeAssembly.fabric_name} |` : ` Outside Shade | ${shadeAssembly.fabric_name} | `} Width: ${shadeAssembly.width
	// 							} inches`}
	// 					</Col>
	// 				</Row>
	// 			)}
	// 			{!shade.is_coupled && (
	// 				<Row>
	// 					<Col>
	// 						{shade.single_dual === 'Dual' && i % 2 === 0
	// 							? 'Inside Shade Fabric: '
	// 							: shade.single_dual === 'Dual' && 'Outside Shade Fabric: '}
	// 						{shade.single_dual === 'Dual' && shadeAssembly.fabric_name}
	// 					</Col>
	// 				</Row>
	// 			)}
	// 		</>
	// 	);
	// });

	const isDual = shade.single_dual === 'Dual';
	const isCoupled = shade.shades
		.filter((shadeAssembly) => shadeAssembly.column_coordinate % 2 === 0).length > 1

	const insideFabric = shade.shades.find((s) => s.column_coordinate % 2 === 0)?.fabric_name ?? "";
	const outsideFabric = shade.shades.find((s) => s.column_coordinate % 2 === 1)?.fabric_name ?? "";

	const fabricChunk = isDual
		? [
			<Row>
				<Col>
					Inside Fabric: &nbsp;
					<a>{insideFabric}</a>
				</Col>
			</Row>,
			<Row>
				<Col>
					Outside Fabric: &nbsp;
					<a>{outsideFabric}</a>
				</Col>
			</Row>
		] : [
			<Row>
				<Col>
					Fabric: &nbsp;
					<a>{insideFabric}</a>
				</Col>
			</Row>
		];

	const manWidthsChunck = isCoupled ? shade.shades
		.filter((shadeAssembly) => shadeAssembly.column_coordinate % 2 === 0)
		.map((shadeAssembly, i) => (
			<Col>
				{`Width (Band ${i + 1}): ${shadeAssembly.width} inches`}
			</Col>
		)) : [];

	const widthRow = [
		<Row>
			Width By Band From the leftmost band.
		</Row>,
		<Row>
			{manWidthsChunck}
		</Row>
	];

	return (
		<Accordion>
			<Accordion.Item as={Card} className={styles.shadeCard} eventKey={shade.id.toString()}>
				<Accordion.Header className={styles.header}>{shade.shade_name}</Accordion.Header>
				<Accordion.Body className={styles.body}>
					<Card.Body className={styles.cardBody}>
						<Row className="mb-2">
							<Col xs={8}>
								<Row className="mb-1">
									<Col>
										{shade.single_dual}
										&nbsp;
										{coupledShade && 'Coupled '}
										{`${upperFirst(singleShadeAccessor.shade_type)} `}
										{motorizedShade
											? `(${shortenMotorType(
												singleShadeAccessor.motor_type ?? ''
											)}) `
											: shade.manual_chain &&
											`(${upperFirst(shade.manual_chain)}) `}
										Shade
										{coupledShade && ` (${bandCount} bands)`}
									</Col>
								</Row>
								<Row className="mb-1">
									<Col>{`Header Name Here | ${shade.end_caps}`}</Col>
								</Row>
								{notCoupledSingleShade && (
									<Row className="mb-1">
										<Col>
											<a>{`${singleShadeAccessor.fabric_name}`}</a>
										</Col>
									</Row>
								)}
								<Row className="mb-1">
									<Col>
										{`Width: ${width} inches | Height: ${singleShadeAccessor.height} inches`}
									</Col>
								</Row>
								<Row className="mb-1">
									<Col>
										{`${shade.mount_type} Mount ${notCoupledSingleShade
											? `| ${upperFirst(
												singleShadeAccessor.roll_direction
											)} Roll`
											: ''
											}`}
									</Col>
								</Row>
								<Row className="mb-1">
									<Col>{shade.side_channels && prettifySideChannels(shade)}</Col>
								</Row>
								{viewingWithDropDowns && (
									<>
										<Accordion>
											<Accordion.Item
												as={ListGroupItem}
												className={styles.detailedBreakdown}
												eventKey={`${shade.id.toString()}_additional_details`}
											>
												<Accordion.Header className={styles.header}>
													Additional Shade Details
												</Accordion.Header>
												<Accordion.Body className={styles.body}>
													<Row>
														<Col>{`Window Jamb Depth: ${shade.window_jamb_depth} inches`}</Col>
													</Row>
													<Row>
														<Col>{`Control Side: ${shade.control_side}`}</Col>
													</Row>
													<Row>
														<Col>{`Direction Facing: ${shade.direction_facing}`}</Col>
													</Row>
													{singleShadeAccessor.motor_type ===
														'low_voltage' && (
															<Row>
																<Col>
																	{`LV Power Source: ${startCase(
																		singleShadeAccessor.lv_power_source
																	)}`}
																</Col>
															</Row>
														)}
													<Row>
														<Col>
															{`Hembar Type: ${startCase(
																singleShadeAccessor.hembar_type
															)}`}
														</Col>
													</Row>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
										<Accordion>
											{!notCoupledSingleShade && accordionMultiBandBreakdown}
										</Accordion>
									</>
								)}
								{!viewingWithDropDowns && (
									<>
										<Row className="mb-1">
											<Col>
												{`Window Jamb Depth: ${shade.window_jamb_depth} inches`}
											</Col>
										</Row>
										<Row className="mb-1">
											<Col>{`Control Side: ${shade.control_side}`}</Col>
										</Row>
										<Row className="mb-1">
											<Col>{`Direction Facing: ${shade.direction_facing}`}</Col>
										</Row>
										{singleShadeAccessor.motor_type === 'low_voltage' && (
											<Row className="mb-1">
												<Col>
													{`LV Power Source: ${startCase(
														singleShadeAccessor.lv_power_source
													)}`}
												</Col>
											</Row>
										)}
										<Row className="mb-1">
											<Col>
												{`Hembar Type: ${startCase(
													singleShadeAccessor.hembar_type
												)}`}
											</Col>
										</Row>
										{fabricChunk}
										{widthRow}
									</>
								)}
							</Col>
							<Col xs={4} className={styles.shadeImage}>
								<Row>
									<Col>
										<img
											alt="filler"
											src="https://dummyimage.com/150x150/F2F2F2/000"
										/>
									</Col>
								</Row>
								<Row>
									<Col className="text-muted">Shade Image Here</Col>
								</Row>
							</Col>
						</Row>
						<Row className="align-bottom justify-content-end">
							<Col className="text-end">
								<Row>
									<Col>Shipping</Col>
								</Row>
								<Row className="text-muted">
									<Col>
										<Money>{shade.shipping_price}</Money>
									</Col>
								</Row>
							</Col>
							<Col xs={3} className="text-end">
								<Row>
									<Col>MSRP (ISO)</Col>
								</Row>
								<Row className="text-muted">
									<Col>
										<Money>{shade.msrp}</Money>
									</Col>
								</Row>
							</Col>
						</Row>
					</Card.Body>
					<Card.Footer className={`${styles.footer} text-muted`}>
						<Row>
							<Col xs={7} className="mt-auto">
								Last Updated &nbsp;
								{lastUpdatedTime}
							</Col>
							<Col xs={5} className="text-end ps-0">
								<Row className="g-1 justify-content-end">
									{isPsAdmin && (
										<Col xs={2}>
											<Dropdown className="no-dropdown-arrow">
												<Dropdown.Toggle
													variant="light"
													className="ps-2 pe-2"
												>
													<FontAwesomeIcon icon={faCog} />
												</Dropdown.Toggle>
												<Dropdown.Menu>
													<Dropdown.Item>Service Assembly</Dropdown.Item>
													<Dropdown.Item>
														Cost Cost Breakdown
													</Dropdown.Item>
													<Dropdown.Item>Detailed View</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</Col>
									)}
									<Col xs={2}>
										<Button color="light" className="ps-2 pe-2">
											<FontAwesomeIcon icon={lockIcon} />
										</Button>
									</Col>
									<Col xs={2}>
										<Button color="light" className="ps-2 pe-2">
											<FontAwesomeIcon icon={faPenToSquare} />
										</Button>
									</Col>
									<Col xs={2}>
										<Button color="light" className="ps-2 pe-2">
											<FontAwesomeIcon icon={faClone} />
										</Button>
									</Col>
									<Col xs={2}>
										<Button color="light" className="ps-2 pe-2">
											<FontAwesomeIcon icon={faTrashCan} />
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
					</Card.Footer>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	);
};

export default ShadeCard;
