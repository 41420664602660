/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @stylistic/max-len */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @stylistic/indent */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-no-useless-fragment */
import { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import api from '../../PowerShadesAPI';
import PaymentStep from './PaymentStep';
import PaymentSteps from './PaymentSteps';
import NewOrderPriceSummaryTable from '../NewItems/NewOrderPriceSummaryTable';
import AdminPaymentDetails from './AdminPaymentDetails';
import Button from '../../Parts/Button';
import { useDealers } from '../../Store/entities/hooks';
import { useAssembliesByQuoteId } from '../../Store/entities/assemblies/hooks';
import TaxJustification from '../../Modals/TaxJustification/TaxJustification';
import { updateDealerTaxInfo } from '../../Store/entities/organizations/dealers';
import { UsePortalDispatch } from '../../Store';
import { repriceQuote } from '../../Store/entities/quotes';

let submit = null;

const PlaceOrder = (props) => {
	const {
		isPsAdmin,
		isRep,
		territoryId,
		quote,
		ROOT,
		close,
		dealers,
		currentDealer,
		isDistributor,
		quoteType,
		newQuote,
		taxModalOpen,
		setTaxModalOpen,
		territories,
		done
	} = props;

	const { dealerId } = newQuote;

	let hasCard = false;
	let hasTerms = false;

	const dealersList = useDealers();
	const currentDealerEntity = dealersList.find((d) => d.id === dealerId) ?? {};

	if (dealerId) {
		if (currentDealerEntity) {
			hasCard = currentDealerEntity.allow_credit_card_payments === true || currentDealerEntity.allow_credit_card_payments === 1;
			hasTerms = currentDealerEntity.has_credit_terms === true || currentDealerEntity.has_credit_terms === 1;
		}
	}

	if (isRep || isDistributor) {
		hasCard = true;
		hasTerms = true;
	}

	const [showingSubmit, setShowingSubmit] = useState(quote.is_paid);
	const [outsideContinentalUS, setOutsideContinentalUS] = useState(false);
	const [adminPayment, setAdminPayment] = useState(false);
	const [success, setSuccess] = useState(null);
	const [adminPaymentDetails, setAdminPaymentDetails] = useState({});
	const [processing, setProcessing] = useState(false);
	const [taxEditLoading, setTaxEditLoading] = useState(false);

	const enableAdminPaymentDetails = async () => {
		await setAdminPayment(true);

		const quoteSub = newQuote.QuoteSubstitution;

		const quoteMsrp = newQuote.buy();

		const defaults = {
			quote_id: newQuote.Id,
			tax_percentage: quoteSub.taxRate,
			sale_price: quoteMsrp.subtotal,
			shipping_price: quoteMsrp.shipping.total_shipping,
			installation_cost: 0.00,
			misc_cost: 0.00,
			final_price: 0.00,
			multiplier: quoteMsrp.multiplier.multiplier

		};

		patchAdminDetails(defaults);
	};

	const patchAdminDetails = (items) => {
		setAdminPaymentDetails({ ...adminPaymentDetails, ...items });
	};

	const quoteAssemblies = useAssembliesByQuoteId(newQuote.id);

	const isRMACediaAndHasNoPreviousQuoteId = (quoteType === "rework" || quoteType === "rma" || quoteType === "cedia")
	&& quoteAssemblies.length > 0
	&& !((quote?.previous_quote_id ?? 0) > 0);

	const onSubmit = () => {
		api.submitOrderPressed(newQuote.Id).then(() => {
			console.log("submitOrderPressed");
		});
		submit()
			.then((resp) => {
				if (resp && resp.data && resp.data.error) {
					ROOT.alert({ text: `Error Placing Order: ${resp.data.error}\nPlease contact PowerShades if this issue persists.`, quickFormat: "error" });
					setSuccess(null);
				} else {
					setSuccess(true);
					setProcessing(false);
					ROOT.alert({
						title: "Thanks for your order!",
						icon: "confetti",
						smallIcon: false,
						text: "Your order has been placed successfully - Your quote will be editable for 24 hours. After which, it'll move to manufacturing whenever approved. If you need to cancel your order or any other assistance, please contact us!",
						noCancelButton: true,
						acceptButtonText: "Okay"
					}).then(() => window.location.reload());
				}
			})
			.catch((resp) => {
				// {title, text, noCancelButton, acceptButtonText, cancelButtonText, onAccept, onCancel, style}
				if (isRMACediaAndHasNoPreviousQuoteId) {
					ROOT.alert({
						text: 'Please enter the previous quote id (The PS#) in Advanced Settings to place this RMA/Rework/CEDIA',
						title: 'No Previous Quote Id',
						quickFormat: 'error',
					})
						.then(() => { })
						.catch(() => { });
				} else {
					ROOT.alert({
						title: "Order Process Failed",
						text: resp.message ? resp.message : resp,
						quickFormat: "error"
					});
				}
			});
	};

	useEffect(() => {
		if (quote.is_paid) {
			submit = () => api.convertToOrder(quote.ID);
		}

		api.getTerritoryInfo(territoryId)
			.then((terr) => {
				if (terr && !terr.within_continental_usa) {
					setOutsideContinentalUS(true);
				}
			});
	}, []);

	const isRepOrAdmin = isPsAdmin || isRep;

	if (!adminPaymentDetails && !adminPayment.quote_id && adminPayment) {
		setAdminPaymentDetails({
			quote_id: newQuote.quoteId,

		});
	}

	const assemblies = useAssembliesByQuoteId(newQuote.Id);
	const hasOutdoorAssembleis = assemblies.some((a) => a.shade_type_id === 3);

	const dispatch = UsePortalDispatch();
	const saveTaxDetails = async (newDealer) => {
		setTaxEditLoading(true);
		try {
			const updateResponse = await dispatch(updateDealerTaxInfo(newDealer));
			if (updateDealerTaxInfo.fulfilled.match(updateResponse)) {
				toast.success('Tax details updated successfully');
				setTaxEditLoading(false);

				dispatch(repriceQuote(newQuote.Id));
				return updateResponse.payload;
			}
			if (updateDealerTaxInfo.rejected.match(updateResponse)) {
				toast.error(updateResponse.payload
					?? 'Something went wrong trying to save the dealer and no error message was provided');
					setTaxEditLoading(false);
					return currentDealer;
				}
			} catch (e) {
				toast.error("Tax Details Update Failed! PowerShades engineering has been notified");
				setTaxEditLoading(false);
			return currentDealer;
		}
	};

	return (
		<>
			<TaxJustification
					org={currentDealer}
					orgType='Dealer'
					onSave={saveTaxDetails}
					open={taxModalOpen}
					setOpen={setTaxModalOpen}
					loading={taxEditLoading}
					hideButton
			/>

			{!success && !taxModalOpen
					? (
						<div id="order-confirmation-holder">
							{adminPayment && adminPaymentDetails.quote_id
							? <AdminPaymentDetails quoteType={quoteType} territories={territories} dealers={dealers} ROOT={ROOT} done={done} close={close} newQuote={newQuote} />
							: (
								<div style={{ overflow: 'hidden auto' }}>
									{
										<>
											<h2 className={`text-${quoteType}`} style={{ marginBottom: '0' }}>
												Ready to Place an Order?
											</h2>

											<NewOrderPriceSummaryTable
												quoteType={quoteType}
												quote={quote}
												priceLabel="My Price"
												couponsEditable
												isRepOrAdmin={isRepOrAdmin}
												newQuote={newQuote}
												quoteId={newQuote.Id}
											/>
											<Button color="gray" onClick={() => setTaxModalOpen(true)} style={{ width: "fit-content", marginLeft: "auto", display: "none" }}>
												<FontAwesomeIcon icon={faEdit} />
												&nbsp;Tax Details
											</Button>
											
											{
												outsideContinentalUS && hasOutdoorAssembleis
													? (
														<span style={{ fontSize: '13px', display: 'inline-block', marginTop: '1em' }}>
															* Shipping charges for outdoor shades will be invoiced
															<br />
															&nbsp;&nbsp;separately for orders outside the continental U.S.
															<br />
														</span>
													)
													: null
											}

											{
												showingSubmit
													? (
														<PaymentStep>
															<div id="order-confirm-description">
																Orders will be editable for 24 hours after submission, after which they will be processed and uneditable.
															</div>
														</PaymentStep>
													)
													: null
											}

											{
												quote.is_paid

													? null
													: <PaymentSteps
														quoteType={quoteType}
														hasCard={hasCard}
														hasTerms={hasTerms}
														setProcessing={setProcessing}
														ROOT={ROOT}
														quote={quote}
														newQuote={newQuote}
														setShowingSubmit={setShowingSubmit}
														setSubmit={(new_submit) => submit = new_submit}
														setAdminPayment={enableAdminPaymentDetails}
													/>
											}

											<div
												className="button-holder"
												style={{
													width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
												}}
											>
												<Button color="gray" onClick={close}>Cancel</Button>
												{
													showingSubmit

														? (
															<Button disabled={processing} color={quoteType ?? 'green'} onClick={onSubmit}>
																{processing ? "Processing" : "Submit"}
																{' '}
															</Button>
														)
														: null
												}

											</div>

										</>
                            
										// <>
										//     <h2 className='green-text' style={{ marginBottom: '3px' }}>Thank You for Your Order</h2>

										//     <div id="order-confirm-description">
										//         Your order has been placed successfully.

										// <br /><br />

										// Your quote will be editable for 24 hours, after which it will move to manufacturing when approved.<br /><br />
										// If you need to cancel your order, or for further support,
										// please contact us.

										// <br />

										//         <div style={{ width: '100%', textAlign: 'right', marginTop: '7px' }}>
										//             <button className='green-button' onClick={props.done}>Done</button>
										//         </div>
										//     </div>
										// </>

									}

								</div>
							)}
						</div>
				) : null}
		</>
	);
};

export default PlaceOrder;
