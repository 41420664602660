import { shallowEqual } from "react-redux";

import { QuoteData } from "./QuoteData";
import QuoteMoney from "./QuoteMoney";
import QuoteUI from "./QuoteUI";
import QUOTE_GLOBALS from "../QuoteGlobals";
import HookImplementation from '../../Parts/HookImplementation';
import api from "../../PowerShadesAPI";
import HeaderNameBySku from "./HeaderNameBySku";
import { selectQuoteFromId } from "../../Store/entities/quotes/hooks";
import { selectAccessories, selectQuoteAccessories } from "../../Store/entities/accessories/hooks";
import { captureSentryError } from "../../psUtil";
import { setQuoteToEditable, updateQuoteAddress } from "../../Store/entities/quotes";
import { selectAssemblyListByQuoteId } from "../../Store/entities/assemblies/hooks";
class QuoteObject {
	constructor(callback, forceUpdateFunction, store, quoteId) {
		const {
			data, user, onDone
		} = {};
		this.Ready = false;
		if (callback) {
			this.callback = callback;
		}

		this.quoteId = quoteId;

		// Quote Level Functions
		const self = this;

		this.QuoteLevelVariables = function () {
			return {
				editable: self.editable,
				editScreen: self.editScreen,
				quoteSub: self.QuoteSubstitution,
				checkExpiration: self.checkExpiration,
				quoteType: self.QuoteType,
				quoteColor: self.QuoteColor,
				noPricing: self.noPricing,
				userData: self.Data?.userData ?? {},
				store: self?.store ?? store,
				unlock: self.unlock,
				ROOT: self.ROOT,
				is_order: self?.Data?.quote?.is_order ?? false,
				quoteId: quoteId
			};
		};

		this.unlock = function (callback) {
			self.Data.RePriceWholeQuote(() => {
				self.editable = true;
				self.Data.editable = true;
				callback && callback(self);
				self.UI.reRenderTable();
				self.store && self.store.dispatch(setQuoteToEditable(self.Id));
			});
		};

		this.initialPage = function (initial, setEditable) {
			const items = [
				...self.Data.shadeAssemblies.Items,
				...(self.Data.accessories?.Items ?? []),
				...self.Data.fabricSamples.Items,
			];

			// If quote is empty and it's initial
			if (items.length == 0 && initial) {
				self.editable = true;
				setEditable(true);

				self.editable = true;
				self.Data.editable = true;
			}
		};

		this.setROOT = (ROOT) => {
			this.ROOT = ROOT;
		};

		// Initial Constructors
		this.Data = new QuoteData({
			quoteLevelVariables: this.QuoteLevelVariables
		});
		this.Money = new QuoteMoney({
			quoteLevelVariables: this.QuoteLevelVariables,
			userData: self.Data.userData
		});
		this.UI = new QuoteUI({
			QuotaActions: null,
			onDone,
			quoteLevelVariables: this.QuoteLevelVariables
		});

		// Setting each of them up for cross connection.
		this.Data.Set({ Money: this.Money, UI: this.UI });
		this.Money.Set({ Data: this.Data, UI: this.UI });
		this.UI.Set({ Data: this.Data, Money: this.Money });

		if (data) {
			this.Data.Load({
				shadeAssemblies: data.shadeAssemblies,
				accessories: data.accessories,
				fabricSamples: data.fabricSamples,
				quote: data.Quote,
				hwRequestItems: data.data.hwParts
			});

			this.Money.Load({
				money: data.money,
				shipping: data.Shipping,
				msrp: data.MSRP,
				currencies: data.money.currency
			});

			this.Money.LoadMoney();
		}

		this.globals = QUOTE_GLOBALS;

		this.editable = false;
		this.noPricing = false;

		this.forceRerenderRef = forceUpdateFunction;

		this.setTerritory = this.setTerritory.bind(this);
		this.setDealerId = this.setDealerId.bind(this);
		this.setUser = this.setUser.bind(this);
		this.checkExpiration = this.checkExpiration.bind(this);
		this.getConfigurationWarnings = this.getConfigurationWarnings.bind(this);
		this.getConfigurationProblems = this.getConfigurationProblems.bind(this);
		this.needsVerification = this.needsVerification.bind(this);
		this.getCurrentConfigurationProblems = this.getCurrentConfigurationProblems.bind(this);
		this.setWarning = this.setWarning.bind(this);
		this.setWarnings = this.setWarnings.bind(this);
		this.reOrderSequences = this.reOrderSequences.bind(this);
		this.setFasciaCombinations = this.setFasciaCombinations.bind(this);
		this.rerender = this.rerender.bind(this);
		this.deleteFasciaCombination = this.deleteFasciaCombination.bind(this);
		this.addHWItem = this.addHWItem.bind(this);
		this.updateHWItem = this.updateHWItem.bind(this);
		this.deleteHWItem = this.deleteHWItem.bind(this);
		this.setContractorId = this.setContractorId.bind(this);
		this.GetDemoInfo = this.GetDemoInfo.bind(this);
		this.SetDemoInfo = this.SetDemoInfo.bind(this);
		this.subscribe = this.subscribe.bind(this);
		this.unlockAndRepriceWholeQuote = this.unlockAndRepriceWholeQuote.bind(this);

		this.buy = () => {
			const msrps = this.Money.msrp;
			try {
				msrps.totals.find((m) => m.name === 'buy');
			} catch (e) {
				throw new Error(`Something went wrong: ${e}`);
			}
			const buy = msrps.totals.find((m) => m.name === 'buy');
			return buy;
		};

		this.display = () => {
			const msrps = this.Money.msrp;
			const buy = msrps.totals.find((m) => m.name === 'display');
			return buy;
		};

		this.MRSPData = () => this.Money.quoteLevelMSRP('MSRP');

		this.msrpTotal = () => {
			const msrps = this.Money.msrp;
			const buy = msrps.totals.find((m) => m.name === 'MSRP');
			return buy;
		};

		this.dealer = () => {
			const msrps = this.Money.msrp;
			const dealer = msrps.totals.find((m) => m.name == 'dealer');
			return dealer;
		};

		this.distributor = () => {
			const msrps = this.Money.msrp;
			const distributor = msrps.totals.find((m) => m.name == 'distributor');
			return distributor;
		};
	}

	setEditScreen(editScreen) {
		this.editScreen = editScreen;
	}

	// subscribe: () => void
	subscribe(subscribeCallback) {
		// Just in case subscribe is called twice, unsubscribe first.
		const self = this;
		let baseUnsubscribe = () => {};

		if ((self?.Data?.accessories?.Items?.length ?? 0) !== 0) {
			self.unsubscribe && self.unsubscribe();

			const quoteId = self.Id;

			baseUnsubscribe = subscribeCallback(() => {
				/* Update Quote Object, currently only accessories */
				if (this.store) {
					const userLoggedIn = this.QuoteLevelVariables().userData.authenticated;
					if (!userLoggedIn) {
						return;
					}

					const state = this.store.getState();
					const oldVal = self?.Data?.accessories?.Items ?? [];
					const newVal = selectQuoteAccessories(quoteId)(state);

					const oldValSku = oldVal.map((a) => a.sku);
					const newValSku = newVal.map((a) => a.sku);

					const anyDifference = (accListOne, accListTwo) =>
						accListOne.some((accOne) => !accListTwo
							.some((accTwo) =>
								accOne.sku === accTwo.sku
								&& accOne.quantity === accTwo.quantity
								&& accOne.msrp === accTwo.msrp
								&& accOne.last_time_priced === accTwo.last_time_priced
							))

					if (oldVal.length !== newVal.length
						|| !shallowEqual(oldValSku, newValSku)
						|| anyDifference(oldVal, newVal)
					) {
						//self.Data.accessories.Items = newVal.map((a) => ({ ...a }));
						self.rerender();
						self.checkExpiration();
						// self.Money.LoadMoney();
					}
				} else {
					self.unsubscribe && self.unsubscribe();
				}

			});
		}

		self.unsubscribe = () => {
			baseUnsubscribe && baseUnsubscribe();
			self.Data.unsubscribe && self.Data.unsubscribe();
		};
	}

	setUser(userData) {
		this.Data.setUserData(userData);
	}

	ensureStore(store) {
		this.store = store;
		// This method makes sure accessories are loaded on page load.
		// selectAccessories(store.getState());
	}

	get multipliers() {
		return this.Money.multipliers;
	}

	get ready() {
		return this.Ready;
	}

	get territoryId() {
		return this.Data?.quote?.territory_id ?? 0;
	}

	setTerritory(value) {
		api.assignTerritory(value, this.Data.quote.ID).then((response) => {
			const { data } = response;
			const { success, is_evolution } = data;

			if (success) {
				this.Data.quote.territory_id = value;
				this.Data.setEvo(is_evolution);
			}

		});
	}

	setTerritories(territories) {
		this.Data.territories_data = territories;
	}

	setCurrencyData(currencyData) {
		this.Money.ApplyMSRP(currencyData.multiplier, true);
		this.Money.ApplyISO(currencyData.ISO, true);
		this.Money.ApplySymbol(currencyData.symbol, true);
	}

	setMultiplier(multiplier) {
		this.Money.ApplyMSRP(multiplier, true);
	}

	get selectedShades() {
		return this.UI && this.UI.hooks ? this.UI.hooks.selectedShades() : [];
	}

	get dealerId() {
		return this.Data?.quote?.dealer_id ?? 0;
	}

	get distributorId() {
		return this.Data.quote.distributor_id;
	}

	get distributorLocationId() {
		return this.Data.quote.distributor_location_id;
	}

	unlockAndRepriceWholeQuote() {
		this.Data.unlockAndRepriceWholeQuote();
	}

	setDealerId(dealer, callback) {
		const self = this;

		api.assignDealer(dealer.value, this.Data.QuoteId).then((resp) => {
			const { data } = resp;
			const { success, is_evolution } = data;

			this.Data.setEvo(is_evolution);

			callback && callback(success);

			self.Data.quote.dealer_id = dealer.value;
		});
	}

	checkExpiration() {
		if (!this?.ROOT?.setTitle || !this.order_status) return;

		const { setTitle } = this.ROOT;

		const oldestDate = this.oldestPricedItemDate();

		const now = new Date();

		const Difference_In_Time = now.getTime() - oldestDate.getTime();

		// To calculate the no. of days between two dates 
		const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

		const numberOfDaysLeft = Math.ceil(90 - Difference_In_Days);

		const quoteType = this.QuoteType;

		const quoteNum = `${this.prefix}${this.Id}`;

		// this.IsDemo ? `DEMO${this.Id}` : `PS${this.Id}`;

		const orderStatus = this.order_status;

		const statusToTitle = ({
			active_quote: "Active Quote",
			archived: "Archived Quote",
			order_pending_approval: "Pending Approval",
			under_review: "Under Review",
			approved_order: "Ready For Manufacturing",
			mfg_accepted_order: "In Production",
			mfg_provisioned_order: "In Production",
			mfg_shipped_order: "Order Shipped",
			submitted_quote: "Submitted and Processing"
		})[orderStatus];

		const statusNeedsModified = orderStatus === "approved_order" && this.Data.quote.manufacturer_id;
		const statusDisplay = statusNeedsModified ? "Submitted To Manufacturing" : statusToTitle;

		let titleMessage = "";
		const quoteShipped = orderStatus === 'mfg_shipped_order';

		if (this.QuoteSubstitution.is_order) {
			const userData = this.Data.getUserData();

			const isPsAdmin = userData ? userData.roles.find((ur) => ur.role_name.includes('powershades_admin')) : false;

			if (quoteType === null || quoteType === '' || !isPsAdmin || quoteType === undefined) {
				titleMessage = `${quoteNum} - ${statusDisplay}`;
			} else {
				titleMessage = `${quoteNum} - ${quoteType} - ${statusDisplay}`;
			}
		} else if (orderStatus === 'active_quote' && numberOfDaysLeft <= -20000) {
			titleMessage = `${quoteNum} - Quote Has Error(s)`;
		} else if (numberOfDaysLeft <= 0) {
			titleMessage = `${quoteNum} - Quote Expired`;
		} else {
			const message = numberOfDaysLeft == 1 ? "day" : "days";
			titleMessage = `${quoteNum} - ${statusDisplay} - Pricing Expires In ${numberOfDaysLeft} ${message}`;
		}

		if (this.MostRecentShippingStatus !== "" && !quoteShipped) {
			titleMessage = `${titleMessage} : ${this.MostRecentShippingStatus}`;
		}

		setTitle(titleMessage);

		this.callback
			&& this.callback();

		this.callback = () => { };


	}

	get Id() {
		return this.QuoteLevelVariables()?.quoteId?? 0;
	}

	get AdvancedItems() {
		return this.Data.shadeAssemblies.Advanced;
	}

	get QuoteSubstitution() {
		const self = this;

		let fakeQuote = null;

		if(this?.Data?.quote == null) {
			return {};
		}

		fakeQuote = {

			pricing: {
				money: {
					sellCurrencly: "USD",
					buyCurrency: "USD"
				}
			},
			pricing_as: "my Price",
			editable: this.Data.editable,
			totalPrice: () => 0,
			quote_options: this.Data?.OldQuote?.quote_options,
			shades: this.Data.shadeAssemblies.Items.map((s) => this.Data.shadeSubstitute(s.sequence_id)),
			property_address: this.Data.quote.property_address,
			hooks: new HookImplementation(),
			hasOutdoorShades: () => this.Data.shadeAssemblies.Items.some((s) => s.indoor_outdoor == 'outdoor'),
			getShadeCount: () => this.Data.shades.length,
			unlock: (callback) => {
				this.unlock(callback);
			},
			is_order: this.Data.quote.is_order,
			is_paid: this.Data.quote.is_paid,
			order_status_index: this.Data.quote.order_status_index,
			territory_id: this.Data.quote.territory_id,
			manufacturer_id: this.Data.quote.manufacturer_id,
			ID: this.Data.quote.ID,
			populate_dashboard_tasks: this.Data.quote.populate_dashboard_tasks,
			getFabricSamplesQuantity: () => self.Data.fabricSamples.Items.length,
			getShadeQuantity: () => self.Data.shadeAssemblies.Items.length,
			currentSaveData: (allSaveData = false) => {
				const quoteAccessories = selectQuoteAccessories(self.Id)(self.store.getState());
				const shades = self.Data.shadeAssemblies.Items.map((s) => self.Data.shadeSubstitute(s.sequence_id)).map((s) => {
					const item = ({
						...s.savableObject(),
						...(s.shade_id ? { shade_id: s.shade_id } : null),
						last_local_id: s.sequenceId
					});

					return item;
				});

				const accessories = quoteAccessories
					.filter((acc) => !(acc.quantity == undefined || acc.quantity < 1))
					.map((acc) => ({ id: acc.id, quantity: acc.quantity }));

				const fabric_samples = self.Data.fabricSamples.Items.map((fs) => ({
					id: fs.id, name: fs.name, quantity: fs.quantity
				}));

				const data = {
					ID: self.Id, territory_id: this.territory_id, populate_dasboard_task_ids: this.populate_dasboard_task_ids
				};

				data.accessories = accessories;
				data.fabric_samples = fabric_samples;
				data.shades = shades;

				data.coupons = self.coupons;

				// Load Quote Options into the Quote object to be passed to the server.
				Object.keys(self.Data.quote)
					.filter((key) => self.Data.quote[key] != null)
					.forEach((key) => data[key] = self.Data.quote[key]);

				data.shipping_address = { ...self.Data.quote.shipping_address } ?? {};
				data.billing_address = { ...self.Data.quote.billing_address } ?? {};
				data.property_address = { ...self.Data.quote.property_address } ?? {};

				return data;
			},
			addCoupon: (couponCode, callback = null) => {
				const couponData = [
					couponCode || '',
					fakeQuote.ID,
					!couponCode
				];

				// If couponCode is null then it's removing the coupon.
				api.addCouponToQuote(...couponData).then((resp) => {
					const { quote, coupons } = resp.data;

					const empty = [];

					this.Money.Reprice({ quote, pricing: [] });

					this.Data.QuoteFieldChange('coupon_snapshot', quote.Quote.coupon_snapshot);

					callback && callback(coupons);
				});
			},
			showLightGap: () => this.Data.quote.IsLightgapChoiceActive == true

		};

		Object.defineProperty(fakeQuote, 'accessories', {
			get() { 
				const quoteAccessories = selectQuoteAccessories(self.Id)(self.store.getState());
				return quoteAccessories; 
			}
		});

		Object.defineProperty(fakeQuote, 'shipping_address', {
			get() { return self.Data.quote.shipping_address; }
		});
		Object.defineProperty(fakeQuote, 'billing_address', {
			get() { return self.Data.quote.billing_address; }
		});
		Object.defineProperty(fakeQuote, 'property_address', {
			get() { return self.Data.quote.property_address; }
		});

		Object.defineProperty(fakeQuote, 'shades', {
			get() { return self.Data.shadeAssemblies.Items.map((sa) => self.Data.shadeSubstitute(sa.sequence_id)); }
		});

		Object.defineProperty(fakeQuote, 'dealer_id', {
			get() { return self.Data.quote.dealer_id; }
		});

		Object.defineProperty(fakeQuote, 'notes', {
			get() { return self.Data.quote.notes; }
		});
		Object.defineProperty(fakeQuote, 'representativeId', {
			get() { return self.Data.quote.representative_id; }
		});
		Object.defineProperty(fakeQuote, 'distributorId', {
			get() { return self.Data.quote.distributor_id; }
		});
		Object.defineProperty(fakeQuote, 'salespersonId', {
			get() { return self.Data.quote.salesperson_id; }
		});
		Object.defineProperty(fakeQuote, 'willShip', {
			get() { return self.Data.quote.ship_order; }
		});
		Object.defineProperty(fakeQuote, 'preShipWhips', {
			get() { return self.Data.quote.pre_ship_whips; }
		});
		Object.defineProperty(fakeQuote, 'taxRate', {
			get() { return self.Data.quote.sales_tax_percentage_snapshot; }
		});
		Object.defineProperty(fakeQuote, 'taxShipping', {
			get() { return self.Data.quote.sales_tax_on_shipping_snapshot; }
		});

		Object.defineProperty(fakeQuote, 'reference_number', {
			get() { return self.Data.quote.reference_number; }
		});

		Object.defineProperty(fakeQuote, 'title', {
			get() { return self.Data.quote.title; }
		});

		Object.defineProperty(fakeQuote, 'warningObject', {
			get() { return self.Data.quote.warnings; }
		});

		Object.defineProperty(fakeQuote, 'warnings', {
			get() { return self.Data.quote.warnings; }
		});

		Object.defineProperty(fakeQuote, 'quoteType', {
			get() { return self.QuoteType; }
		});
		Object.defineProperty(fakeQuote, 'previousQuoteId', {
			get() { return self.Data.quote.previous_quote_id; }
		});

		// this.Data.territories_data = territories;

		Object.defineProperty(fakeQuote, 'territory_data', {
			get() {
				if (!self.Data) {
					return null;
				}
				const territories = self.Data.territories_data;

				if (!territories) {
					return null;
				}

				const territoryId = fakeQuote.territory_id;

				let territory = null;

				for (const territoryData of territories) {
					if (territoryData.id == territoryId) {
						territory = territoryData;
						break;
					}
				}
				return territory;
			}
		});

		return fakeQuote;
	}

	IndoorEditRow() {
		const onChange = this.Data.MassEdit;
		return this.UI.IndoorEditRow(onChange);
	}

	OutdoorEditRow() {
		const onChange = this.Data.MassEdit;
		return this.UI.OutdoorEditRow(onChange);
	}

	oldestPricedItemDate() {
		const storeState = this?.store?.getState();
		if (!storeState) {
			console.error({
				storeState,
				store: this.store
			});
			throw new Error("Store state not found");
		}
		const qas = selectQuoteAccessories(this.Id)(storeState);
		const assemblies = selectAssemblyListByQuoteId(this.Id)(storeState);

		let oldestDate = assemblies?.reduce(
			(min, sa) => {
				const newDate = sa.msrp == 0 ? new Date(0) : new Date(sa.last_time_priced * 1000);

				if (newDate < min) {
					return newDate;
				}
				return min;
			},
			new Date()
		) ?? new Date();

		oldestDate = qas
			.filter((qasy) => qasy.last_time_priced !== -1)
			.reduce(
				(min, sa) => {
					const newDate = new Date(sa.last_time_priced * 1000);

					if (newDate < min) {
						return newDate;
					}
					return min;
				},
				oldestDate
			) ?? new Date();

		return oldestDate;
	}

	setHooks(hooks) {
		this.UI.setHooks(hooks);
	}

	totalMsrp() {
		return this.Money.quoteLevelMSRP();
	}

	buyMsrp() {
		return this.Money.quoteLevelMSRP("buy");
	}

	msrps() {
		return this.Money.msrp;
	}

	addAndGetNewShade(isOutdoor, callback) {
		return this.Data.addAndGetNewShade(isOutdoor, callback);
	}

	setRender(reRender) {
		this.UI.renderTable = reRender;
	}

	setRenderPage(reRender) {
		this.UI.renderPage = reRender;
	}

	rerender() {
		this.UI.renderPage && this.UI.renderPage();
	}

	forceRerender() {
		this.forceRerenderRef && this.forceRerenderRef();
	}

	deleteAssembly(id) {
		return this.Data.deleteAssembly(id);
	}

	quoteSamples() {
		return this.Data.fabricSamples.Items;
	}

	loadData(data, callback) {
		if (data) {
			this.Data.Load({
				shadeAssemblies: data.shadeAssemblies,
				accessories: data.accessories,
				fabricSamples: data.fabricSamples,
				quote: data.Quote,
				hwRequestItems: data.hwParts
			});

			this.Money.Load({
				money: data.money,
				shipping: data.Shipping,
				msrp: data.MSRP,
				currencies: data.money.currency
			});

			this.Money.LoadMoney();

			const self = this;

			this.Data.globals.onLoad(() => {
				self.Ready = true;
				callback();
			});
		}
	}

	shadeRows() {
		const indoorRows = this.UI.IndoorRows(this.Money.IndoorMoney);
		return indoorRows;
	}

	AccessoryTable() {
		const accessoryMoney = this.Money.AccessoryMoney;

		// console.log(accessoryMoney);

		const accessoryTable = this.UI.AccessoryTable(accessoryMoney);

		return accessoryTable;
	}

	IndoorShadesTable() {
		const indoorMoney = this.Money.IndoorMoney;

		const indoorTable = this.UI.IndoorTable(indoorMoney);

		return indoorTable;
	}

	OutdoorShadesTable() {
		const outdoorMoney = this.Money.OutdoorMoney;

		const outdoorTable = this.UI.OutdoorTable(outdoorMoney);

		return outdoorTable;
	}

	getIndoorMoney() {
		const indoorShades = this.Data.IndoorShades;
		const indoorMoney = indoorShades.map((s) => ({
			Id: s.id,
			Money: this.Money.GetMoneyById(s.id, "shade-msrp"),
			Shipping: this.Money.GetMoneyById(s.id, "shade-shipping")
		}));

		return indoorMoney;
	}

	OutDoorTable() {
		const outdoorMoney = this.Data.OutdoorMoney;
	}

	FabricSamplesTable() {
		const fabricMoney = this.Money.FabricMoney;
		const fabricData = this.Data.fabricSamples.Items;
		return this.UI.FabricSamplesTable(fabricMoney, fabricData);
	}

	setShippingAddress(address) {
		const dispatch = this.store.dispatch;
		return dispatch(updateQuoteAddress({
			quoteId: this.Id,
			address,
			addressType: 'shipping'
		}))
	}

	setPropertyAddress(address) {
		const dispatch = this.store.dispatch;
		return dispatch(updateQuoteAddress({
			quoteId: this.Id,
			address,
			addressType: 'property'
		}))
	}

	setQuoteAddress(address, type) {
		this.Data.quote[type] = address;
		return api.saveQuoteAddress(this.Id, address, `${type}_id`).then((resp) => {
			this.Data.RePriceWholeQuote();

			return resp.data.address;
		});
	}

	getConfigurationWarnings() {
		return this.getConfigurationProblems().map((p) => p.warning(this));
	}

	getConfigurationWarningItems() {
		return this.getConfigurationProblems().map((p) => ({
			message: p.warning(this),
			code: p.warning_code(this),
			showing: p.conditional(this)
		}));
	}

	getCurrentWarningObject() {
		const warnings = this.getConfigurationWarnings();

		const warningObj = {
			warnings
		};

		return JSON.stringify(warningObj);
	}

	getCurrentWarningList() {
		const warnings = this.getConfigurationWarningItems();

		const warningsFiltered = warnings.filter((w) => w.showing).map((w) => ({
			message: w.message,
			code: w.code
		}));

		return warningsFiltered;
	}

	hwRequestItems() {
		return this.Data.hwRequestItems ?? [];
	}

	getConfigurationProblems() {
		const checks = [];

		const self = this;

		// Check has an action, a warning, and a conditional

		// Common Methods and values 
		const changeAccessory2 = self.Data.setAccessoryQuantity;

		const changeAccessory = (quoteSkuToSaveOrAdd, quantity) => {
			const sku = quoteSkuToSaveOrAdd.part_number ?? quoteSkuToSaveOrAdd;
			const quoteAccessories = selectQuoteAccessories(self.Id)(self.store.getState());
			const accessory = quoteAccessories.find((a) => a.part_number === sku);

			const itemToSend = accessory?.line_number ?? sku;

			changeAccessory2(itemToSend, quantity);
		}

		const getCount = (accessory) => {
			const quoteAccessories = selectQuoteAccessories(self.Id)(self.store.getState());
			if (!accessory) return 0;
			const sku = accessory.part_number ?? accessory;
			const accessoryItems = quoteAccessories.filter((a) => a.part_number === sku);
			const count = accessoryItems?.reduce((total, next) => total + next.quantity, 0) ?? 0;
			return count;
		};

		// "Recommend Power Supply Brick with the RF Repeater" - "https://trello.com/c/aXH2QigW/515-recommend-power-supply-brick-with-the-rf-repeater"
		const rfRepeaterSku = "PS_RF_REPEATER_2023";
		const rfRepreaters = self.QuoteSubstitution.accessories.find((a) => a.part_number == rfRepeaterSku);
		const rfRepreatersCount = getCount(rfRepreaters);
		const usbPowerBrickSku = "PSUSBCHRGR_2022";
		const usbPowerBricks = self.QuoteSubstitution.accessories.find((a) => a.part_number == usbPowerBrickSku);
		const usbPowerBricksCount = getCount(usbPowerBricks);

		// "Shade Rules Control - Required Items" - https://trello.com/c/8HuMtZQq/306-shade-rules-control-required-items
		// This first check is for Remotes
		const singleChannelHandSku = "PSRMT_SC_2022";
		const singleChannelHand = self.QuoteSubstitution.accessories.find((a) => a.part_number == singleChannelHandSku);
		const singleChannelHandCount = getCount(singleChannelHand);

		const singleChannelWallSku = "PSRMT_SCOW_2022";
		const singleChannelWall = self.QuoteSubstitution.accessories.find((a) => a.part_number == singleChannelWallSku);
		const singleChannelWallCount = getCount(singleChannelWall);

		const multiChannelHandSku = "PSRMT_MC_2022";
		const multiChannelHand = self.QuoteSubstitution.accessories.find((a) => a.part_number == multiChannelHandSku);
		const multiChannelHandCount = getCount(multiChannelHand);

		const multiChannelWallSku = "PSRMT_MCOW_2022";
		const multiChannelWall = self.QuoteSubstitution.accessories.find((a) => a.part_number == multiChannelWallSku);
		const multiChannelWallCount = getCount(multiChannelWall);

		const shades = self.Data.shadeAssemblies.Items.reduce((list, sa) => {
			list.push(...(sa.shades.filter((s) => s.column_coordinate == 0)));
			return list;
		}, []);
		const numberOfRfShades = self.Data.shadeAssemblies.Items.reduce((total, sa) => {
			const isDual = sa.shades.some((s) => s.column_coordinate == 1);

			if (sa.shades.some((s) => s.shade_type == 'motorized') && !sa.shades.some((s) => (s.motor ?? '').toUpperCase().includes('POE'))) {
				return (isDual ? 2 : 1) + total;
			}
			return total;
		}, 0);

		shades.filter((s) => !(s.motor ? s.motor.includes('Poe') : false) && s.shade_type == 'motorized').length;

		const numberOfRemoteSpots = multiChannelWallCount * 16 + multiChannelHandCount * 16 + singleChannelHandCount + singleChannelWallCount;

		checks.push({
			action: (newQuote) => {
				const difference = numberOfRfShades - numberOfRemoteSpots;

				if (difference == 1 && numberOfRemoteSpots == 0) {
					changeAccessory(singleChannelHandSku, 1);
				} else {
					const numberOfMultiChannelsToAdd = Math.floor((difference - 1) / 16) + 1;

					changeAccessory(multiChannelHandSku, multiChannelHandCount + numberOfMultiChannelsToAdd);
				}

				return () => {
					// Callback?
				};
			},
			warning: (newQuote) => `You currently have ${numberOfRfShades} RF shade(s) and ${numberOfRemoteSpots} connections to remotes. We recommend at least one (1) remote connection per RF shade.`,
			conditional: (newQuote) => numberOfRfShades > numberOfRemoteSpots,
			warning_code: (newQuote) => 'remote_count',
			actionLabel: (newQuote) => "Add recommended connection(s)",
			manualLabel: () => "Got it, I’ll update myself",
			ignoreLabel: () => "Ignore and proceed"
		});

		// "Shade Rules Control - Required Items" - https://trello.com/c/8HuMtZQq/306-shade-rules-control-required-items
		// This is the second check, involving RF Gateways
		const rfGatewaySku = 'PSRFGW_2024';
		const rfGateway = self.QuoteSubstitution.accessories.find((a) => a.part_number == rfGatewaySku);
		const rfGatewayCount = getCount(rfGateway);

		// Number of RF shades was already calculated.

		const rfGatewaySpots = rfGatewayCount * 15;

		checks.push({
			action: (newQuote) => {
				const difference = numberOfRfShades - rfGatewaySpots;

				const numberOfGatewaysToAdd = Math.floor((difference - 1) / 15) + 1;

				changeAccessory(rfGatewaySku, rfGatewayCount + numberOfGatewaysToAdd);
			},
			warning: (newQuote) => `You currently have ${numberOfRfShades} RF shade(s) and ${rfGatewaySpots} RF gateway channels. An RF Gateway is required for automation with RF shades.`,
			conditional: () => rfGatewaySpots < numberOfRfShades,
			warning_code: (newQuote) => 'rf_gateway_spot_count',
			actionLabel: (newQuote) => "Add recommended connection(s)",
			ignoreLabel: () => "Ignore and proceed",
			manualLabel: () => "Got it, I’ll update myself",
		});

		// "Seamable Improvements" - https://trello.com/c/6QkTyUa5/525-seamable-improvements
		// This will let a user know that these have seams

		const shadesWithSeams = self.QuoteSubstitution.shades.filter((s) => s.val('will_be_seamed') == 1);
		const shadesWithSeamsString = shadesWithSeams.reduce((prev, next) => (prev ? `${prev},(${next.val('shade_name')})` : `(${next.val('shade_name')})`), null);

		checks.push({
			warning: (newQuote) => `Shade(s) ${shadesWithSeamsString}, contain fabric that will be seamed. If you have a preference between where the seam(s) is placed, please declare your preference in the order notes.`,
			conditional: (newQuote) => {
				if (shadesWithSeams.length > 0) {
					return true;
				}
				return false;
			},
			warning_code: (newQuote) => 'seamed_shades',
			manualLabel: () => "Go to notes",
			ignoreLabel: () => "Ignore and proceed"
		});

		// "Room Validation" - https://trello.com/c/ieeNy3NH/530-room-validation
		// This check will let the users know that a heaer size/color does not match.

		const differentRoomSets = self.QuoteSubstitution.shades.reduce((obj, next) => {

			let roomName = "none";

			const roomNameUnclean = `${next?.val('room_name')}`;

			if (!(roomNameUnclean == null || roomNameUnclean == undefined || roomNameUnclean == "")) {
				roomName = roomNameUnclean.trim().toLowerCase();
			}

			if (!obj[roomName]) {
				obj[roomName] = [];
			}

			obj[roomName].push(next);

			return obj;
		}, {});

		const roomSetsWithHeaderIssues = {};
		const roomSetsWithMotorIssues = {};
		const roomSetsWithTubeIssues = {};
		const roomSetsWithFrontFabricIssues = {};
		const roomSetsWithBackFabricIssues = {};

		Object.entries(differentRoomSets).forEach(([room, shadesy]) => {
			const header = shadesy[0].val('header_type');

			const color = shadesy[0].val('hardware_color');

			const mismatchHWShades = shadesy.filter((s) => s.val('header_type') != header || s.val('hardware_color') != color);

			if (mismatchHWShades.length > 0) {
				roomSetsWithHeaderIssues[room] = shadesy;
			}

			const tube = shadesy[0].val('tube_sku');

			const mismatchTubeShades = shadesy.filter((s) => s.val('tube_sku') != tube);

			if (mismatchTubeShades.length > 0) {
				roomSetsWithTubeIssues[room] = shadesy;
			}

			const motor = shadesy[0].val('motor');

			const mismatchMotorShades = shadesy.filter((s) => s.val('motor') != motor);

			// Commenting this for quick activation whenever someone asks for it, but also it needs to t
			// if ( mismatchMotorShades.length > 0) {
			// 	roomSetsWithMotorIssues[room] = shadesy;
			// }

			const shadeItems = shadesy.map((s) => s.data());

			const frontFabrics = shadeItems.map((s) => s.shades.filter(sh => sh['row_coordinate'] === 0)[0]?.fabric_name).filter(s => s !== undefined && s !== null);
			const backFabrics = shadeItems.map((s) => s.shades.filter(sh => sh['row_coordinate'] === 1)[0]?.fabric_name).filter(s => s !== undefined && s !== null);

			const frontFabric = frontFabrics[0];

			const mismatchFrontFabricShades = frontFabrics.filter((s) => s !== frontFabric);

			if (mismatchFrontFabricShades.length > 0) {
				roomSetsWithFrontFabricIssues[room] = shadesy;
			}


			if (backFabrics.length > 0) {
				const backFabric = backFabrics[0];
				const mismatchBackFabricShades = shadesy.filter((s) => s.val('fabric2_name') !== backFabric);
				if (mismatchBackFabricShades.length > 0) {
					roomSetsWithBackFabricIssues[room] = shadesy;
				}
			}
		});

		checks.push({
			warning: (newQuote) => {
				let respString = "You currently have shades with differing headers/colors within the same room.";

				for (const [room, shades] of Object.entries(roomSetsWithHeaderIssues)) {
					// Getting differing header shades combos
					const headerShades = shades.reduce((obj, shade) => {
						const headerType = HeaderNameBySku(shade.val('header_type'));
						if (!obj[headerType]) {
							obj[headerType] = [];
						}

						obj[headerType].push(shade.val('shade_name'));

						return obj;
					}, {});

					const colorShades = shades.reduce((obj, shade) => {
						const hardwareColor = shade.val('hardware_color');

						if (!hardwareColor) {
							return obj;
						}

						if (!obj[hardwareColor]) {
							obj[hardwareColor] = [];
						}

						obj[hardwareColor].push(shade.val('shade_name'));

						return obj;
					}, {});

					let roomStr = `\n\nRoom: ${room} \n\n `;

					if (Object.keys(headerShades).length > 1) {
						const num = Object.keys(headerShades).length;
						let iterator = 0;

						const isFirstHeader = true;

						for (const [headerType, shadeNames] of Object.entries(headerShades)) {
							const shadeString = shadeNames.reduce((prev, next) => (prev ? `${prev} ${next} :${headerType} \n` : `${next} :${headerType} \n`), null);

							roomStr += `${shadeString} `;
							iterator += 1;
						}
					}

					if (Object.keys(headerShades).length > 1 && Object.keys(colorShades).length > 1) {
						roomStr += `\n\n Room: ${room}\n\n Shades: `;
					}

					if (Object.keys(colorShades).length > 1) {
						const num = Object.keys(colorShades).length;
						let iterator = 0;

						let isFirstColor = true;

						for (const [color, shadeNames] of Object.entries(colorShades)) {
							if (!isFirstColor) {
								if (num - 1 == iterator) {
									roomStr += " and ";
								} else {
									roomStr += ", ";
								}
							} else {
								isFirstColor = false;
							}

							const shadeString = shadeNames.reduce((prev, next) => (prev ? `${prev}  ${next} :${color} \n` : `${next} :${color} \n`), null);
							roomStr += `${shadeString} `;
							iterator += 1;
						}

						roomStr += ".  \n\n";
					}

					respString += roomStr;
				}

				return respString;
			},
			conditional: (newQuote) => Object.keys(roomSetsWithHeaderIssues).length > 0,
			warning_code: (newQuote) => {
				const roomUnclean = Object.keys(roomSetsWithHeaderIssues).find(a => true) ?? "none";
				const simplifiedRoom = roomUnclean.replace(" ", "__").toLocaleLowerCase();

				return `differing_headers_${simplifiedRoom}`
			},
			manualLabel: () => "Got it, I’ll adjust my selection",
			ignoreLabel: () => "Ignore and proceed with differing headers/colors",
			actionLabel: null
		});

		for (const [room, shades] of Object.entries(roomSetsWithTubeIssues)) {
			let respString = `You currently have shades with differing tubes within the same room - ${room}. This might result in a differing shade speed in room ${room}`;
			const simplifiedRoom = room.replace(" ", "__").toLocaleLowerCase();

			// Getting differing header shades combos
			// const tubeShades = shades.reduce((obj, shade) => {
			// 	const tubeType = shade.val('tube_sku');
			// 	if (!obj[tubeType]) {
			// 		obj[tubeType] = [];
			// 	}

			// 	obj[tubeType].push(shade.val('shade_name'));

			// 	return obj;
			// }, {});

			// let roomStr = `\n\nRoom: ${room} \n\n `;

			// if (Object.keys(tubeShades).length > 0) {
			// 	const num = Object.keys(tubeShades).length;
			// 	let iterator = 0;

			// 	const isFirstHeader = true;

			// 	for (const [tubeSku, shadeNames] of Object.entries(tubeShades)) {
			// 		const shadeString = shadeNames.reduce((prev, next) => (prev ? `${prev} ${next} :${tubeSku} \n` : `${next} :${tubeSku} \n`), null);

			// 		roomStr += `${shadeString} `;
			// 		iterator += 1;
			// 	}
			// }

			// respString += roomStr;


			// const largestTube = "";

			checks.push({
				warning: () => respString,
				conditional: () => true,
				warning_code: (newQuote) => `differing_tubes_${simplifiedRoom}`,
				manualLabel: () => "Got it, I'll call PowerShades to adjust my order",
				ignoreLabel: () => "Ignore and proceed with differing tubes",
				// action : () => {

				// },
				// actionLabel : () => `Put all shades in room (${room}) on the largest tube (${largestTube}).`,
			});
		}

		// checks.push({
		// 	warning: (newQuote) => {
		// 		let respString = "You currently have shades with differing headers/colors within the same room.";

		// 		for (const [room, shades] of Object.entries(roomSetsWithHeaderIssues)) {
		// 			// Getting differing header shades combos
		// 			const headerShades = shades.reduce((obj, shade) => {
		// 				const headerType = HeaderNameBySku(shade.val('header_type'));
		// 				if (!obj[headerType]) {
		// 					obj[headerType] = [];
		// 				}

		// 				obj[headerType].push(shade.val('shade_name'));

		// 				return obj;
		// 			}, {});

		// 			const colorShades = shades.reduce((obj, shade) => {
		// 				const hardwareColor = shade.val('hardware_color');

		// 				if (!hardwareColor) {
		// 					return obj;
		// 				}

		// 				if (!obj[hardwareColor]) {
		// 					obj[hardwareColor] = [];
		// 				}

		// 				obj[hardwareColor].push(shade.val('shade_name'));

		// 				return obj;
		// 			}, {});

		// 			let roomStr = `\n\nRoom: ${room} \n\n `;

		// 			if (Object.keys(headerShades).length > 1) {
		// 				const num = Object.keys(headerShades).length;
		// 				let iterator = 0;

		// 				const isFirstHeader = true;

		// 				for (const [headerType, shadeNames] of Object.entries(headerShades)) {
		// 					const shadeString = shadeNames.reduce((prev, next) => (prev ? `${prev} ${next} :${headerType} \n` : `${next} :${headerType} \n`), null);

		// 					roomStr += `${shadeString} `;
		// 					iterator += 1;
		// 				}
		// 			}

		// 			if (Object.keys(headerShades).length > 1 && Object.keys(colorShades).length > 1) {
		// 				roomStr += `\n\n Room: ${room}\n\n Shades: `;
		// 			}

		// 			if (Object.keys(colorShades).length > 1) {
		// 				const num = Object.keys(colorShades).length;
		// 				let iterator = 0;

		// 				let isFirstColor = true;

		// 				for (const [color, shadeNames] of Object.entries(colorShades)) {
		// 					if (!isFirstColor) {
		// 						if (num - 1 == iterator) {
		// 							roomStr += " and ";
		// 						} else {
		// 							roomStr += ", ";
		// 						}
		// 					} else {
		// 						isFirstColor = false;
		// 					}

		// 					const shadeString = shadeNames.reduce((prev, next) => (prev ? `${prev}  ${next} :${color} \n` : `${next} :${color} \n`), null);
		// 					roomStr += `${shadeString} `;
		// 					iterator += 1;
		// 				}

		// 				roomStr += ".  \n\n";
		// 			}

		// 			respString += roomStr;
		// 		}

		// 		return respString;
		// 	},
		// 	conditional: (newQuote) => Object.keys(roomSetsWithHeaderIssues).length > 0,
		// 	warning_code: (newQuote) => `differing_headers-${Object.keys(roomSetsWithHeaderIssues).find(a => true) ?? "none"}`,
		// 	manualLabel: () => "Got it, I’ll adjust my selection",
		// 	ignoreLabel: () => "Ignore and proceed with differing headers/colors",
		// 	actionLabel: null
		// });

		Object.entries(roomSetsWithFrontFabricIssues).forEach(([room, shadesy]) => {
			let respString = `You currently have shades with differing front (inside) fabrics within the same room - ${room}. This might result in a shade color mismatch in room  ${room}.`;
			const simplifiedRoom = room.replace(" ", "__").toLocaleLowerCase();

			// // Getting differing header shades combos
			// const fabricShades = shadesy.reduce((obj, shade) => {
			// 	const fabType = shade.val('fabric_name');
			// 	if (!obj[fabType]) {
			// 		obj[fabType] = [];
			// 	}

			// 	obj[fabType].push(shade.val('shade_name'));

			// 	return obj;
			// }, {});

			// let roomStr = `\n\nRoom: ${room} \n\n `;

			// if (Object.keys(fabricShades).length > 0) {
			// 	const num = Object.keys(fabricShades).length;
			// 	let iterator = 0;

			// 	const isFirstHeader = true;

			// 	for (const [tubeSku, shadeNames] of Object.entries(fabricShades)) {
			// 		const shadeString = shadeNames.reduce((prev, next) => (prev ? `${prev} ${next} :${tubeSku} \n` : `${next} :${tubeSku} \n`), null);

			// 		roomStr += `${shadeString} `;
			// 		iterator += 1;
			// 	}
			// }

			// respString += roomStr;


			checks.push({
				warning: () => respString,
				conditional: () => true,
				warning_code: (newQuote) => `differing_front_fabrics_${simplifiedRoom}`,
				manualLabel: () => "Got it, I'll change my selection",
				ignoreLabel: () => "Ignore and proceed with differing fabrics",
				// action : () => {

				// },
				// actionLabel : () => `Put all shades in room (${room}) on the largest tube (${largestTube}).`,
			});
		})

		Object.entries(roomSetsWithBackFabricIssues).forEach(([room, shadesy]) => {
			const respString = `You currently have shades with differing back (outside) fabrics within the same room - ${room}. This might result in a shade color mismatch.`;
			const simplifiedRoom = room.replace(" ", "__").toLocaleLowerCase();

			// Getting differing header shades combos
			// const fabricShades = shadesy.reduce((obj, shade) => {
			// 	const fabType = shade.val('fabric2_name');
			// 	if (!obj[fabType]) {
			// 		obj[fabType] = [];
			// 	}

			// 	obj[fabType].push(shade.val('shade_name'));

			// 	return obj;
			// }, {});

			// let roomStr = `\n\nRoom: ${room} \n\n `;

			// if (Object.keys(fabricShades).length > 0) {
			// 	const num = Object.keys(fabricShades).length;
			// 	let iterator = 0;

			// 	const isFirstHeader = true;

			// 	for (const [tubeSku, shadeNames] of Object.entries(fabricShades)) {
			// 		const shadeString = shadeNames.reduce((prev, next) => (prev ? `${prev} ${next} :${tubeSku} \n` : `${next} :${tubeSku} \n`), null);

			// 		roomStr += `${shadeString} `;
			// 		iterator += 1;
			// 	}
			// }

			// respString += roomStr;


			checks.push({
				warning: () => respString,
				conditional: () => true,
				warning_code: (newQuote) => `differing_back_fabrics_${simplifiedRoom}`,
				manualLabel: () => "Got it, I'll change my selection",
				ignoreLabel: () => "Ignore and proceed with differing fabrics",
				// action : () => {

				// },
				// actionLabel : () => `Put all shades in room (${room}) on the largest tube (${largestTube}).`,
			});
		})

		// checks.push({
		// 	warning: (newQuote) => {
		// 		let respString = "You currently have shades with differing tubes within the same room.";

		// 		for (const [room, shades] of Object.entries(roomSetsWithTubeIssues)) {
		// 			// Getting differing header shades combos
		// 			const tubeShades = shades.reduce((obj, shade) => {
		// 				const tubeType = shade.val('tube_sku');
		// 				if (!obj[tubeType]) {
		// 					obj[tubeType] = [];
		// 				}

		// 				obj[tubeType].push(shade.val('shade_name'));

		// 				return obj;
		// 			}, {});

		// 			let roomStr = `\n\nRoom: ${room} \n\n `;

		// 			if (Object.keys(tubeShades).length > 0) {
		// 				const num = Object.keys(tubeShades).length;
		// 				let iterator = 0;

		// 				const isFirstHeader = true;

		// 				for (const [tubeSku, shadeNames] of Object.entries(tubeShades)) {
		// 					const shadeString = shadeNames.reduce((prev, next) => (prev ? `${prev} ${next} :${tubeSku} \n` : `${next} :${tubeSku} \n`), null);

		// 					roomStr += `${shadeString} `;
		// 					iterator += 1;
		// 				}
		// 			}

		// 			if (Object.keys(tubeShades).length > 1 ) {
		// 				roomStr += `\n\n Room: ${room}\n\n Shades: `;
		// 			}

		// 			respString += roomStr;
		// 		}

		// 		return respString;
		// 	},
		// 	conditional: (newQuote) => Object.keys(roomSetsWithTubeIssues).length > 0,
		// 	warning_code: (newQuote) => 'differing_tubes',
		// 	manualLabel: () => "Got it, I’ll adjust my selection",
		// 	ignoreLabel: () => "Ignore and proceed with differing tubes"
		// });

		checks.push({
			warning: (newQuote) => {
				let respString = "You currently have shades with differing motors within the same room.";

				for (const [room, shades] of Object.entries(roomSetsWithMotorIssues)) {
					// Getting differing header shades combos
					const motorShades = shades.reduce((obj, shade) => {
						const motor_type = shade.val('motor');
						if (!obj[motor_type]) {
							obj[motor_type] = [];
						}

						obj[motor_type].push(shade.val('shade_name'));

						return obj;
					}, {});

					let roomStr = `\n\nRoom: ${room} \n\n `;


					respString += roomStr;
				}

				return respString;
			},
			conditional: (newQuote) => Object.keys(roomSetsWithMotorIssues).length > 0,
			warning_code: (newQuote) => 'differing_motors',
			manualLabel: () => "Got it, I’ll adjust my selection",
			ignoreLabel: () => "Ignore and proceed with differing motors"
		});

		// Shade Rules: Power - Required Items - https://trello.com/c/uzzpmSGd/305-shade-rules-power-required-items
		// This card has three parts, with sub points.

		// Part one is low voltage hardwired, which includes a whip and PS power supplies depending on the number of shades.
		// This is point a which is the number and size of power supplies
		const lowVolatagDCMotorsCount = self.QuoteSubstitution.shades.reduce((count, currentAssembly) => {
			if (currentAssembly.val('motor_type') == 'low_voltage_hw') {
				return currentAssembly.isDualShade() ? count + 2 : count + 1;
			}

			return count;
		}, 0);

		const powerSupply4Sku = "PC-U0405-PULQ";
		const powerSupply4 = self.QuoteSubstitution.accessories.find((a) => a.part_number == powerSupply4Sku);
		const powerSupply4Count = getCount(powerSupply4Count);

		const powerSupply9Sku = "PC-U0910-PULQ";
		const powerSupply9 = self.QuoteSubstitution.accessories.find((a) => a.part_number == powerSupply9Sku);
		const powerSupply9Count = getCount(powerSupply9);

		const powerSupply18Sku = "PC-U1830-PULQ";
		const powerSupply18 = self.QuoteSubstitution.accessories.find((a) => a.part_number == powerSupply18Sku);
		const powerSupply18Count = getCount(powerSupply18);

		const totalAvailable = powerSupply18Count * 18 + powerSupply9Count * 9 + powerSupply4Count * 4;

		checks.push({
			action: (newQuote) => {
				let total18 = Math.floor(lowVolatagDCMotorsCount / 18);
				let total9 = 0;
				let total4 = 0;

				const leftOver = lowVolatagDCMotorsCount % 18;
				if (leftOver > 0) {

					if (leftOver > 9) {
						total18 += 1;
					} else if (leftOver > 4) {
						total9 += 1;
					} else {
						total4 += 1;
					}
				}

				changeAccessory(powerSupply9Sku, total9);
				changeAccessory(powerSupply18Sku, total18);
				changeAccessory(powerSupply4Sku, total4);

				return () => {
					// Callback?
				};
			},

			warning: (newQuote) => `You currently have ${lowVolatagDCMotorsCount} shade motor(s) that need a low voltage power supply and ${totalAvailable} available low voltage power supply outputs. We recommend one power supply out per low voltage hardwired shade motor.`,
			conditional: (newQuote) => lowVolatagDCMotorsCount > totalAvailable,
			warning_code: (newQuote) => 'lv_dc_hw_power_supplies',
			actionLabel: (newQuote) => `Add recommended power supplies`,
			manualLabel: (newQuote) => `Got it, I’ll update myself`,
			ignoreLabel: (newQuote) => `Ignore and proceed`,

		});

		// Part one point b is about the DC whips. This one is a bit simpler, a direct one to one.
		const DCWhipSku = "PSDCWHIP_2022";
		const DCWhip = self.QuoteSubstitution.accessories.find((a) => a.part_number == DCWhipSku);
		const DCWhipCount = getCount(DCWhip);

		checks.push({
			action: (newQuote) => {
				changeAccessory(DCWhipSku, lowVolatagDCMotorsCount);

				return () => {
					// Callback?
				};
			},
			warning: (newQuote) => `You currently have ${lowVolatagDCMotorsCount} low voltage shade motors and ${DCWhipCount} DC whips. We recommend at least one (1) DC whip per low voltage shade motor.`,
			conditional: (newQuote) => lowVolatagDCMotorsCount > DCWhipCount,
			warning_code: (newQuote) => 'lv_dc_hw_whip_count',
			actionLabel: (newQuote) => `Add recommended whips`,
			manualLabel: (newQuote) => `Got it, I’ll update myself`,
			ignoreLabel: (newQuote) => `Ignore and proceed`,

		});

		// Now for part 2 point a. This involves low_voltage motors. Essentially, for 18mm or a 25mm motor we want one microUSB cable and one USB charger block is there per shade.  .
		const smallLowVolatagDCBatteryMotorsCount = self.QuoteSubstitution.shades.reduce((count, currentAssembly) => {
			if (currentAssembly.val('motor_type') == 'low_voltage' && currentAssembly.val('shade_type') == 'motorized' && currentAssembly.valIncludes('motor', '18', '25', '15')) {
				return currentAssembly.isDualShade() ? count + 2 : count + 1;
			}

			return count;
		}, 0);

		const largeLowVolatagDCBatteryMotorsCount = self.QuoteSubstitution.shades.reduce((count, currentAssembly) => {
			if (currentAssembly.val('motor_type') == 'low_voltage' && currentAssembly.val('shade_type') == 'motorized' && !currentAssembly.valIncludes('motor', '18', '25', '15')) {
				return currentAssembly.isDualShade() ? count + 2 : count + 1;
			}

			return count;
		}, 0);

		const lowVolatagDCBatteryMotorsCount = smallLowVolatagDCBatteryMotorsCount + largeLowVolatagDCBatteryMotorsCount;
		const neededCableAndBricksCount = Math.ceil(lowVolatagDCBatteryMotorsCount / 5);


		const smallLowVolatagDCBatteryMotorsCountSolar = self.QuoteSubstitution.shades.reduce((count, currentAssembly) => {
			if (currentAssembly.val('motor_type') == 'low_voltage'
				&& currentAssembly.val('shade_type') == 'motorized'
				&& currentAssembly.valIncludes('motor', '18', '25', '15')
				&& currentAssembly.valIncludes('lv_power_source', 'solar_panel')) {
				return currentAssembly.isDualShade() ? count + 2 : count + 1;
			}

			return count;
		}, 0);

		const largeLowVolatagDCBatteryMotorsCountSolar = self.QuoteSubstitution.shades.reduce((count, currentAssembly) => {
			if (currentAssembly.val('motor_type') == 'low_voltage'
				&& currentAssembly.val('shade_type') == 'motorized'
				&& !currentAssembly.valIncludes('motor', '18', '25', '15')
				&& currentAssembly.valIncludes('lv_power_source', 'solar_panel')) {
				return currentAssembly.isDualShade() ? count + 2 : count + 1;
			}

			return count;
		}, 0);

		const smallLowVolatagDCBatteryMotorsCountNotSolar = smallLowVolatagDCBatteryMotorsCount - smallLowVolatagDCBatteryMotorsCountSolar;
		const largeLowVolatagDCBatteryMotorsCountNotSolar = largeLowVolatagDCBatteryMotorsCount - largeLowVolatagDCBatteryMotorsCountSolar;

		const microUSBCableSku = 'PSMUSB_CBL_2022';
		const microUSBCable = self.QuoteSubstitution.accessories.find((a) => a.part_number == microUSBCableSku);
		const microUSBCableCount = getCount(microUSBCable);

		const usbPowerSupplySku = 'PSUSBCHRGR_2022';
		const usbPowerSupply = self.QuoteSubstitution.accessories.find((a) => a.part_number == usbPowerSupplySku);
		const usbPowerSupplyCount = getCount(usbPowerSupply);

		const microSolarSku = 'DC1288I';
		const microSolar = self.QuoteSubstitution.accessories.find((a) => a.part_number == microSolarSku);
		const microSolarCount = getCount(microSolar);

		const largeSolarSku = 'DC1289A';
		const largeSolar = self.QuoteSubstitution.accessories.find((a) => a.part_number == largeSolarSku);
		const largeSolarCount = getCount(largeSolar);

		const barrelChargerSku = 'PS1563US';
		const barrelCharger = self.QuoteSubstitution.accessories.find((a) => a.part_number == barrelChargerSku);
		const barrelChargerCount = getCount(barrelCharger);

		const totalSmallChargersWall = microUSBCableCount;
		const totalSmallChargersSolar = microSolarCount;

		// //This bit adds micro-usb chargers for the smaller low_voltage shades.
		// checks.push({
		//     action: (newQuote) => {
		//         let totalItemsNeeded = smallLowVolatagDCBatteryMotorsCountNotSolar - totalSmallChargersWall;

		//         changeAccessory(microUSBCableSku, totalItemsNeeded + microUSBCableCount);
		//         changeAccessory(usbPowerSupplySku, totalItemsNeeded + microUSBCableCount);

		//         return () => {
		//             //Callback?
		//         }
		//     },
		//     warning: (newQuote) => {
		//         return `You currently have ${smallLowVolatagDCBatteryMotorsCountNotSolar} small low voltage shade motors with batteries and ${totalSmallChargersWall} small shade wall charger(s). We recommend at least one (1) charger per low voltage shade motor battery.`;
		//     },
		//     conditional: (newQuote) => smallLowVolatagDCBatteryMotorsCountNotSolar > totalSmallChargersWall,
		//     warning_code: (newQuote) => {
		//         return 'lv_dc_small_charger_count';
		//     },
		//     actionLabel: (newQuote) => {
		//         return `Add recommended chargers`;
		//     },
		//     manualLabel: (newQuote) => {
		//         return `Got it, I’ll update myself`;
		//     },
		//     ignoreLabel: (newQuote) => {
		//         return `Ignore and proceed`;
		//     },

		// });

		// checks.push({
		//     action: (newQuote) => {
		//         let totalItemsNeeded = smallLowVolatagDCBatteryMotorsCountSolar - microSolarCount;

		//         changeAccessory(microSolarSku, totalItemsNeeded + microSolarCount);

		//         return () => {
		//             //Callback?
		//         }
		//     },
		//     warning: (newQuote) => {
		//         return `You currently have ${smallLowVolatagDCBatteryMotorsCountSolar} small low voltage shade motors with batteries and ${totalSmallChargersSolar} small shade solar charger(s). We recommend at least one (1) charger per low voltage shade motor battery.`;
		//     },
		//     conditional: (newQuote) => smallLowVolatagDCBatteryMotorsCountSolar > totalSmallChargersSolar,
		//     warning_code: (newQuote) => {
		//         return 'lv_dc_small_solar_charger_count';
		//     },
		//     actionLabel: (newQuote) => {
		//         return `Add recommended chargers`;
		//     },
		//     manualLabel: (newQuote) => {
		//         return `Got it, I’ll update myself`;
		//     },
		//     ignoreLabel: (newQuote) => {
		//         return `Ignore and proceed`;
		//     },

		// });

		// This one checks to make sure there are enough power blocks per the cord.
		checks.push({
			action: (newQuote) => {
				changeAccessory(usbPowerSupplySku, currentCountMagneticUSBCount + rfRepreatersCount);

				return () => {
					// Callback?
				};
			},
			warning: (newQuote) => `You currently have ${currentCountMagneticUSBCount} micro USB cable(s), ${currentCountMagneticUSBCount} RF repeater(s) and ${usbPowerSupplyCount} power blocks. We recommend at least one (1) power block per micro USB cable and RF repeater.`,
			conditional: (newQuote) => currentCountMagneticUSBCount + rfRepreatersCount > usbPowerSupplyCount,
			warning_code: (newQuote) => 'power_block_count',
			actionLabel: (newQuote) => `Add recommended power blocks`,
			manualLabel: (newQuote) => `Got it, I’ll update myself`,
			ignoreLabel: (newQuote) => `Ignore and proceed`,
		});

		// This bit is for chargers that are for larger motors.
		const totalLargeChargersWall = barrelChargerCount;
		const totalLargeChargersSolar = largeSolarCount;

		// checks.push({
		//     action: (newQuote) => {
		//         changeAccessory(barrelChargerSku, largeLowVolatagDCBatteryMotorsCountNotSolar);

		//     },
		//     warning: (newQuote) => {
		//         return `You currently have ${largeLowVolatagDCBatteryMotorsCountNotSolar} large low voltage shade motors with batteries and ${totalLargeChargersWall} large shade wall chargers. We recommend at least one (1) charger per low voltage shade motor.`;
		//     },
		//     conditional: (newQuote) => largeLowVolatagDCBatteryMotorsCountNotSolar > totalLargeChargersWall,
		//     warning_code: (newQuote) => {
		//         return 'lv_dc_large_charger_count';
		//     },
		//     actionLabel: (newQuote) => {
		//         return `Add recommended chargers`;
		//     },
		//     manualLabel: (newQuote) => {
		//         return `Got it, I’ll update myself`;
		//     },
		//     ignoreLabel: (newQuote) => {
		//         return `Ignore and proceed`;
		//     },
		// });

		// checks.push({
		//     action: (newQuote) => {
		//         changeAccessory(largeSolarSku, largeLowVolatagDCBatteryMotorsCountSolar);

		//     },
		//     warning: (newQuote) => {
		//         return `You currently have ${largeLowVolatagDCBatteryMotorsCountSolar} large low voltage shade motors with batteries and ${totalLargeChargersSolar} large shade solar chargers. We recommend at least one (1) charger per low voltage shade motor.`;
		//     },
		//     conditional: (newQuote) => largeLowVolatagDCBatteryMotorsCountSolar > totalLargeChargersSolar,
		//     warning_code: (newQuote) => {
		//         return 'lv_dc_large_solar_charger_count';
		//     },
		//     actionLabel: (newQuote) => {
		//         return `Add recommended chargers`;
		//     },
		//     manualLabel: (newQuote) => {
		//         return `Got it, I’ll update myself`;
		//     },
		//     ignoreLabel: (newQuote) => {
		//         return `Ignore and proceed`;
		//     },
		// });

		// This is part 3, no subpoints. Simply an AC hardwired set up. 
		const ACWhipSku = "PS_AC_WHIP_2023_W";
		const ACWhip = self.QuoteSubstitution.accessories.find((a) => a.part_number == ACWhipSku);
		const ACWhipCount = getCount(ACWhip);

		const ACMotorsCount = self.QuoteSubstitution.shades.reduce((count, currentAssembly) => {
			if (currentAssembly.val('motor_type') == 'high_voltage' && currentAssembly.val('shade_type') == 'motorized') {
				return currentAssembly.isDualShade() ? count + 2 : count + 1;
			}

			return count;
		}, 0);

		checks.push({
			action: (newQuote) => {
				changeAccessory(ACWhipSku, ACMotorsCount);

				return () => {
					// Callback?
				};
			},
			warning: (newQuote) => `You currently have ${ACMotorsCount} AC shade motors and ${ACWhipCount} AC whips. We recommend at least one (1) AC whip per AC shade motor.`,
			conditional: (newQuote) => ACMotorsCount > ACWhipCount,
			warning_code: (newQuote) => 'ac_whip_count',
			actionLabel: (newQuote) => `Add recommended whips`,
			manualLabel: (newQuote) => `Got it, I’ll update myself`,
			ignoreLabel: (newQuote) => `Ignore and proceed`,
		});

		// This check is for POE Shades and making sure they have a coupler a POE shade. 

		// Inline
		const POEInlineCouplerSku = "PS-CAT6IL";
		const POEInlineCoupler = self.QuoteSubstitution.accessories.find((a) => a.part_number == POEInlineCouplerSku);
		const POEInlineCouplerCount = getCount(POEInlineCoupler);

		// Flush
		const POEFlushCouplerSku = "PS-CAT6FT";
		const POEFlushCoupler = self.QuoteSubstitution.accessories.find((a) => a.part_number == POEFlushCouplerSku);
		const POEFlushCouplerCount = getCount(POEFlushCoupler);

		const POEMotorsCount = self.QuoteSubstitution.shades.reduce((count, currentAssembly) => {
			if ((currentAssembly.val('motor_type') ?? '').toUpperCase().includes('POE')) {
				return currentAssembly.isDualShade() ? count + 2 : count + 1;
			}

			return count;
		}, 0);

		const POECouplerCount = POEFlushCouplerCount + POEInlineCouplerCount;

		checks.push({
			action: (newQuote) => {
				changeAccessory(POEInlineCouplerSku, POEMotorsCount);

				return () => {
					// Callback?
				};
			},
			warning: (newQuote) => `You currently have ${POEMotorsCount} PoE shade motors and ${POECouplerCount} ethernet coupler(s). We recommend at least one (1) in-line ethernet coupler per PoE shade motor.`,
			conditional: (newQuote) => POEMotorsCount > POECouplerCount,
			warning_code: (newQuote) => 'poe_coupler_count',
			actionLabel: (newQuote) => `Add recommended couplers`,
			manualLabel: (newQuote) => `Got it, I’ll update myself`,
			ignoreLabel: (newQuote) => `Continue without order being shipped.`,
		});

		// 
		const shippingOrder = self.Data.quote.ship_order == true;
		// checks.push({
		// 	action: (newQuote) => {
		// 		self.Data.QuoteFieldChange('ship_order', 1);

		// 		return () => {
		// 			// Callback?
		// 		};
		// 	},
		// 	warning: (newQuote) => `You currently have this order set as "Do not ship this quote" The order will need to be picked up at the items respective manufacturers. If a shipment ends up being required, further costs may be incurred.`,
		// 	conditional: (newQuote) => !shippingOrder,
		// 	warning_code: (newQuote) => 'ship_this_quote',
		// 	actionLabel: (newQuote) => `Ship this quote`,
		// 	ignoreLabel: (newQuote) => `Do not ship this quote.`,
		// 	noManual: () => true
		// });

		const whipsWillShip = self.QuoteSubstitution.preShipWhips;

		checks.push({
			action: (newQuote) => {
				self.Data.QuoteFieldChange('pre_ship_whips', 1);

				return () => {
					// Callback?
				};
			},
			warning: (newQuote) => `Would you like to pre ship your whips for your hardwired shades?`,
			conditional: (newQuote) => whipsWillShip != true && (ACWhipCount + DCWhipCount > 0),
			warning_code: (newQuote) => 'pre_ship_whips',
			actionLabel: (newQuote) => `Pre Ship Whips.`,
			ignoreLabel: (newQuote) => `Do not ship the whips.`,
			noManual: () => true
		});

		// Inline
		const WindSensorSku = "MT02-0301-072001";
		const WindSensor = self.QuoteSubstitution.accessories.find((a) => a.part_number == WindSensorSku);
		const WindSensorCount = getCount(WindSensor);

		const rooms = self.QuoteSubstitution.shades.filter((s) => s.isOutdoor() && s.isMotorized()).map((s) => {
			const value = s?.val('room_name') ?? '';

			return value.trim ? value.trim() : value;
		});

		function unique(arr) {
			const u = {}; const
				a = [];
			for (let i = 0, l = arr.length; i < l; ++i) {
				if (!u.hasOwnProperty(arr[i])) {
					a.push(arr[i]);
					u[arr[i]] = 1;
				}
			}
			return a;
		}

		const uniqueRooms = unique(rooms);

		const outdoorAreaCount = uniqueRooms.length;

		checks.push({
			action: (newQuote) => {
				changeAccessory(WindSensorSku, outdoorAreaCount);

				return () => {
					// Callback?
				};
			},
			warning: (newQuote) => `
                You currently have ${outdoorAreaCount} outdoor shade area(s) and ${WindSensorCount} wind sensor(s). We recommend at least one (1) wind sensor per outdoor shade area.`,
			conditional: (newQuote) => outdoorAreaCount > WindSensorCount,
			warning_code: (newQuote) => 'outdoor_wind_sensor_count',
			actionLabel: (newQuote) => `Add recommended wind sensor(s).`,
			ignoreLabel: (newQuote) => `Ignore and proceed`,
			manualLabel: (newQuote) => `Got it, I’ll update myself`,
		});

		const gateWayCount = rfGatewayCount;
		const repeaterCount = rfRepreatersCount;

		checks.push({
			action: (newQuote) => {
				changeAccessory(rfRepeaterSku, rfGatewayCount);

				return () => {
					// Callback?
				};
			},
			warning: (newQuote) => `
                You currently have ${rfGatewayCount} RF Gateway(s). We recommend at least one RF Repeater Dongle per RF Gateway. Motor feedback such as openness and battery voltage require an RF Repeater Dongle to work properly.
                `,
			conditional: (newQuote) => rfGatewayCount > rfRepreatersCount,
			warning_code: (newQuote) => 'gateway_to_repeater',
			actionLabel: (newQuote) => `Add recommended RF Repeater(s).`,
			ignoreLabel: (newQuote) => `Ignore and proceed`,
			manualLabel: (newQuote) => `Got it, I’ll update myself`,
		});

		// Checking is a shade has a sag
		// This will let a user know that these have a sag

		const shadesWithSags = self.QuoteSubstitution.shades.filter((s) => s.val('has_sag_warning') == 1);
		const shadesWithSagsString = shadesWithSags.reduce((prev, next) => (prev ? `${prev},(${next.val('shade_name')})` : `(${next.val('shade_name')})`), null);

		const sagCount = shadesWithSags.length;

		checks.push({
			warning: (newQuote) =>
				`DEFLECTION WARNING: Shade${sagCount == 1 ? '' : 's'} ${shadesWithSagsString} ${sagCount == 1 ? "is" : "are"} over our recommended deflection. The fabric may have a v shape that the customer will not like. Please contact your PowerShades Sales Rep for options.`, // return `Shade(s) ${shadesWithSagsString}, contain fabric that will be seamed. If you have a preference between where the seam(s) is placed, please declare your preference in the order notes.`;

			conditional: (newQuote) => {
				if (sagCount > 0) {
					return true;
				}
				return false;
			},
			warning_code: (newQuote) => 'sagging_shades',
			ignoreLabel: () => "Ignore and proceed",
			noManual: () => true

		});

		// Inline
		const DCWhipToMicroSku = "12VDC-5VDC TRANSFORMER";
		const DCWhipToMicro = self.QuoteSubstitution.accessories.find((a) => a.part_number == DCWhipToMicroSku);
		const DCWhipToMicroCount = getCount(DCWhipToMicro);

		// Inline
		const DCWhipToBarrelSku = "PS_DC_CAB_2022";
		const DCWhipToBarrel = self.QuoteSubstitution.accessories.find((a) => a.part_number == DCWhipToBarrelSku);
		const DCWhipToBarrelCount = getCount(DCWhipToBarrel);

		const lv_battery_shades = self.QuoteSubstitution.shades.filter((s) => s.val('motor_type') === 'low_voltage');

		const solar_needed = lv_battery_shades.filter((s) => s.val("lv_power_source")?.includes('solar_panel', 'wall_charger') && s.isMotorized() && s.val('motor_type') && !s.isDualShade());
		const wall_needed = lv_battery_shades.filter((s) => s.val("lv_power_source")?.includes('wall_charger') && s.isMotorized() && s.val('motor_type') && !s.isDualShade());
		const whip_needed = lv_battery_shades.filter((s) => s.val("lv_power_source")?.includes('dc_whip') && s.isMotorized() && s.val('motor_type') && !s.isDualShade());
		const dual_motor_lv_batter_needs_magnetic = lv_battery_shades.filter((s) => s.isDualShade());

		const isNotLarge = (shade) => !shade.valIncludes('motor', '45 mm Li-Ion RF Motor');

		const small_shade_solar_count = solar_needed.filter(isNotLarge).length; // Needs DC1288I 
		const large_shade_solar_count = solar_needed.length - small_shade_solar_count;// Needs DC1289A

		const small_shade_wall_count = wall_needed.filter(isNotLarge).length; // Needs PS1564-04
		const large_shade_wall_count = wall_needed.length - small_shade_wall_count; // Needs PS1563US

		const small_shade_whip_count = whip_needed.filter(isNotLarge).length; // Needs 12VDC-5VDC TRANSFORMER
		const large_shade_whip_count = whip_needed.length - small_shade_whip_count; // Needs PS_DC_CAB_2022

		const dual_motor_lv_batter_needs_magnetic_count = dual_motor_lv_batter_needs_magnetic.length;

		// Inline
		const currentCountMagneticUSBSku = "PS_MGCBL_MC_3M_BLK";
		const currentCountMagneticUSB = self.QuoteSubstitution.accessories.find((a) => a.part_number == currentCountMagneticUSBSku);
		const currentCountMagneticUSBCount = getCount(currentCountMagneticUSB);
		/*

		let microUSBCableSku = 'PS1564-04';
		let microUSBCable = self.QuoteSubstitution.accessories.find(a => a.part_number == microUSBCableSku);
		let microUSBCableCount = microUSBCable ? microUSBCable.quantity : 0;

		let microSolarSku = 'DC1288I';
		let microSolar = self.QuoteSubstitution.accessories.find(a => a.part_number == microSolarSku);
		let microSolarCount = microSolar ? microSolar.quantity : 0;

		let largeSolarSku = 'DC1289A';
		let largeSolar = self.QuoteSubstitution.accessories.find(a => a.part_number == largeSolarSku);
		let largeSolarCount = largeSolar ? largeSolar.quantity : 0;

		let barrelChargerSku = 'PS1563US';
		let barrelCharger = self.QuoteSubstitution.accessories.find(a => a.part_number == barrelChargerSku);
		let barrelChargerCount = barrelCharger ? barrelCharger.quantity : 0;

		*/

		// Added this to try and simplify this very wide configuration request
		// addCleaner is short for "Add Cleaner way of configuring these differing requests."
		const addCleaner = (code, compare, message, action, actionLabel, ignoreMessage = `Ignore and proceed`, manualMessage = `Got it, I’ll update myself`) => {
			checks.push({
				action: !action ? null : (newQuote) => {
					action();

					return () => {
						// Callback?
					};
				},
				warning: (newQuote) => message,
				conditional: (newQuote) => compare(),
				warning_code: (newQuote) => code,
				actionLabel: (newQuote) => actionLabel,
				ignoreLabel: (newQuote) => ignoreMessage,
				manualLabel: (newQuote) => manualMessage,

			});
		};

		// addCleaner(`lv_li_solar_small`, () => {
		//     return microSolarCount > small_shade_solar_count
		// }, 
		// `You currently have ${small_shade_solar_count} shades designated to have a Micro-USB Solar Charger, and only ${microSolarCount} total Micro USB Solar Charger(s).`,
		// () => {
		//     changeAccessory(microSolarSku, small_shade_solar_count)
		// });

		// addCleaner(`lv_li_solar_large`, () => {
		//     return largeSolarCount > large_shade_solar_count
		// }, 
		// `You currently have ${large_shade_solar_count} shades designated to have a Barrel Ended Solar Charger, and only ${largeSolarCount} total Barrel Ended Solar Charger(s).`,
		// () => {
		//     changeAccessory(largeSolarSku, large_shade_solar_count)
		// });

		const totalWallSolarSmall = small_shade_wall_count + small_shade_solar_count;
		const totalWallSolarLarge = large_shade_wall_count + large_shade_solar_count;

		const fasciaCombinations = self.AdvancedItems.find(item => item.name == 'fascia_combinations')?.sets ?? [];

		fasciaCombinations.forEach((fc) => {
			const assemblySeqIds = fc.assemblies.map(a => a.sequence_id);

			const totalComboWidth = fc.total_width;

			const assemblies = assemblySeqIds.map((seqId) => self.Data.shadeSubstitute(seqId));

			const firstSeqId = assemblies.find(() => true).sequence_id;

			const totalWidth = assemblies
				.reduce(
					(prevValues, newAssembly) => prevValues
						+ newAssembly.data().shades
							.filter((s) => s.row_coordinate === 0)
							.reduce((init, newShade) => init + newShade.width, 0), 0);

			const shadeNamesCSV = assemblies.reduce((prev, a) => {
				const csv = `${prev}, ${a.data().shade_name}`;
				return csv;
			}, "");


			if (totalComboWidth < totalWidth) {
				addCleaner(
					`fascia_combo_small_${firstSeqId}`,
					() => totalComboWidth < totalWidth,
					`Currently, shade(s) with names ${shadeNamesCSV} have a recorded combined Fascia Width of ${totalComboWidth}, which is lower than the Total Shade Width of ${totalWidth}`,
					false,
					false,
					`Got it, I'll update it myself in the 'Combine Fascia' screen.`,
					`I'm fine with this width.`
				);
			}

		});

		// fasciaCombinations.forEach(fc => fc.)

		addCleaner(
			`lv_li_wall_small`,
			() => currentCountMagneticUSBCount < neededCableAndBricksCount,
			`You currently have ${lowVolatagDCBatteryMotorsCount} shade(s) designated to have a Magnetic-USB Charger, and only ${currentCountMagneticUSBCount} total Micro-USB Charger(s). We recommend one (1) charger for every five (5) shades. \n Your battery powered shades will come pre-installed with a magnetic charging dongle. There is no need to add them to your order unless you want extras for your project. `,
			() => {
				changeAccessory(currentCountMagneticUSBSku, neededCableAndBricksCount);
			},
			`Add Recommended Magnetic USB charging cable(s)`
		);

		// addCleaner(
		// 	`lv_li_wall_large`,
		// 	() => barrelChargerCount < totalWallSolarLarge,
		// 	`You currently have ${totalWallSolarLarge} shade(s) designated to have a Barrel Ended Charger, and only ${barrelChargerCount} total Barrel Ended Charger(s).`,
		// 	() => {
		// 		changeAccessory(barrelChargerSku, totalWallSolarLarge);
		// 	},
		// 	`Add recommended Barrel Chargers.`
		// );

		addCleaner(
			`lv_li_whip_small`,
			() => DCWhipToMicroCount < small_shade_whip_count,
			`You currently have ${small_shade_whip_count} shade(s) designated to have a Micro-USB DC Whip, and only ${DCWhipToMicroCount} total Micro-USB DC Whip Charger(s).`,
			() => {
				changeAccessory(DCWhipToMicroSku, small_shade_whip_count);
			},
			`Add recommended Micro USB DC Whips.`
		);
		addCleaner(
			`lv_li_whip_large`,
			() => DCWhipToBarrelCount < large_shade_whip_count,
			`You currently have ${large_shade_whip_count} shade(s) designated to have a Barrel Ended DC Whip, and only ${DCWhipToBarrelCount} total Barrel Ended DC Whip Charger(s).`,
			() => {
				changeAccessory(DCWhipToBarrelSku, large_shade_whip_count);
			},
			`Add recommended Barrel Ended DC Whips.`
		);




		checks.forEach((c) => {
			const oldWarning = c.warning;

			c.warning = (newQuote) => {
				const warning = oldWarning(newQuote);

				const idk = warning.replaceAll("'", "");

				return idk;
			};

			const newMessage = c.message?.replaceAll("'", "") ?? "";
			c.message = newMessage;

			const oldCode = c.warning_code ? c.warning_code() : "";

			const newCode = oldCode.replaceAll("'", "");
			c.warning_code = () => newCode;
		});

		// adds an optional callback parameter for actions
		checks.forEach((c) => {
			const { action } = c;
			const optionalCallback = (callback) => {
				action && action();
				callback && callback();
			};
			c.action = !action ? action : optionalCallback;
		});

		return checks;
	}

	getCurrentConfigurationProblems() {
		const self = this;

		const ignoredWarnings = self.QuoteSubstitution.warnings ?? [];

		return this.getConfigurationProblems().filter((p) => p.conditional(self) && !ignoredWarnings.find((w) => w.code == p.warning_code(self) && w.message == p.warning(self)));
	}

	needsVerification() {
		const warningsList = this.QuoteSubstitution.warnings ?? [];

		const stringSort = function (a, b) {
			return (`${a.attr}`).localeCompare(b.attr);
		};
		const current = this.getCurrentWarningList();
		current.sort(stringSort);

		warningsList.sort(stringSort);

		const first = JSON.stringify(warningsList);
		const second = JSON.stringify(current);

		if (JSON.stringify(warningsList) == JSON.stringify(current)) {
			return false;
		}

		return this.getCurrentConfigurationProblems().filter((p) => p.conditional(this)).length > 0;
	}

	setWarnings(warnings) {
		const self = this;

		api.saveQuoteVerifications(self.Id, warnings).then((resp) => {
			const newWarnings = resp.data.warnings;

			self.Data.quote.warnings = newWarnings;

			self.UI.reRenderTable();
		});
	}

	setWarning(message, code) {
		const { warnings } = this.QuoteSubstitution;

		const replaceMe = warnings.find((w) => w.code == code);

		if (replaceMe) {
			replaceMe.message = message;
			replaceMe.code = code;
		} else {
			warnings.push({
				message,
				code
			});
		}

		this.setWarnings(warnings);
	}

	reOrderSequences(sequenceSet, noRerender = false) {
		this.Data.reOrderSequences(sequenceSet, noRerender);
	}

	setFasciaCombinations(fasciaCombinations) {
		this.Data.setFasciaCombinations(fasciaCombinations);
	}

	getFasciaCombinations() {
		return this.Data.getFasciaCombinations();
	}

	deleteFasciaCombination(fc_id) {
		this.Data.deleteFasciaCombination(fc_id);
	}

	setQuoteTypes(quoteTypes) {
		this.quoteTypes = quoteTypes;
		// console.log(quoteTypes)
		this.rerender();
	}

	loadShippingStatus(statuses) {
		this.ShippingOrderStatuses = statuses;
	}

	get MostRecentShippingStatus() {
		const { ShippingOrderStatuses } = this;

		if (!ShippingOrderStatuses) {
			return "";
		}

		const sortedStatuses = ShippingOrderStatuses
			.sort((sos1, sos2) => (sos1.date_time > sos2.date_time ? 1 : 0));

		const item = sortedStatuses.find(() => true)?.ship_date_string ?? "";

		return item;
	}

	get QuoteType() {
		const currentQuoteTypeId = this?.Data?.quote?.quote_type_id;
		if (this?.quoteTypes?.length > 0) {
			return this.quoteTypes.find((qt) => qt.id == currentQuoteTypeId)?.name?.replace(" ","_");
		}

		return 'CEDIA';
	}

	get QuoteKey() {
		const currentQuoteTypeId = this.Data?.quote?.quote_type_id;
		let quoteTypeName = 'CEDIA';

		if ((this?.quoteTypes?.length ?? 0) > 0) {
			quoteTypeName = this?.quoteTypes?.find((qt) => qt.id == currentQuoteTypeId);
		}

		const current = quoteTypeName?.ref ?? quoteTypeName?.zoho_reference ?? quoteTypeName;

		return current;
	}

	get QuoteColor() {
		const quoteType = this.QuoteType;

		return quoteType == "RMA" || quoteType == "Rework" ? " rma " : (quoteType == "BidSpec" ? " bid " : "");
	}

	get IsBidSpec() {
		return this.QuoteType == 'BidSpec';
	}

	get IsRMA() {
		return this.QuoteType == 'RMA';
	}

	get IsCedia() {
		return this.QuoteType == 'CEDIA';
	}

	get IsRework() {
		return this.QuoteType == 'Rework';
	}

	get IsInternal() {
		return this.QuoteType == 'Internal';
	}

	get IsDemo() {
		return this.QuoteKey == 'demo';
	}

	get IsExternal() {
		return this.QuoteKey == 'window_covering';
	}

	get order_status() {
		if (this.Data.quote.archived === 1) {
			return 'archived';
		}
		return this.Data.quote.order_status;
	}

	get prefix() {
		switch (this.QuoteType) {
			case 'CEDIA':
				return `PS`;
			case 'RMA':
				return `RMA`;
			case 'Rework':
				return `RWK`;
			case 'Demo':
				return `DEMO`;
			case 'BidSpec':
				return `BID`;
			case 'Internal':
				return `INT`;
			default:
				return `PS`;
		}
	}

	addHWItem(sku, quantity) {
		this.Data.addHWItem(sku, quantity);
	}

	updateHWItem(sku, quantity, cost, msrp) {
		this.Data.updateHWItem(sku, quantity, cost, msrp);
	}

	deleteHWItem(sku) {
		this.Data.deleteHWItem(sku);
	}

	setContractorId(contractorId, callback) {
		const self = this;

		api.assignContractor(this.Data.QuoteId, contractorId).then((resp) => {
			const { data } = resp;
			const { success } = data;

			callback && callback(success);

			self.Data.quote.contractor_id = contractorId;

			self.rerender();
		});
	}

	setPreviousQuoteId(previousQuoteId, callback) {
		const self = this;

		api.SetPreviousQuoteId(this.Id, previousQuoteId).then((resp) => {
			const { success } = resp.data;

			if (success) {
				// Set the front end object
				self.Data.quote.previous_quote_id = previousQuoteId;

				// Say it succeed
				callback && callback(true);
			} else {
				// Say it failed
				callback && callback(false, resp.data.error);
			}

			self.UI.reRenderTable();
		});
	}

	GetDemoInfo() {
		const self = this;

		if (!self.IsDemo) return null;

		return self.Data.GetDemoInfo();
	}

	SetDemoInfo(info) {
		const self = this;

		return self.Data.SetDemoInfo(info);
	}

	HasDealer() {
		const self = this;

		return (self.Data.quote.dealer_id ?? 0) > 0;
	}

	refreshContent() {
		this.ROOT.loading();
		this.UI.reRenderTable();
		this.loadData(null, () => {
			this.ROOT.loaded();
		});
		console.log("refresh page called!");
	}
}

export default QuoteObject;
