import type { IconDefinition } from "@fortawesome/free-solid-svg-icons";

export const faPowerShades = {
	prefix: 'fab',
	iconName: 'powershades',
	icon: [
		413.92,
		290.44,
		[],
		'e001',
		`M195.89,138.5c-13.53.03-26.87.06-40.5.09,2.85-7.86,5.6-15.38,8.28-22.95,6.17-17.48,12.25-35.14,18.48-52.86,3.18-9.12,6.69-18.14,9.88-27.32,2.17-6.28,4.23-12.61,6.31-18.95L0,31.68v225.69l141.61,11.31c18.12-42.47,36.16-85.73,54.28-130.19h0Z 
		M265.31,11.37c-8.9,25.15-17.77,49.94-26.73,74.73,11.31-.38,22.4-.75,34.34-1.14-41.85,64.94-83.35,126.19-124.84,184.25l265.83,21.24V0l-148.6,11.37h0Z`,
	],
} as unknown as IconDefinition;