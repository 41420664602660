import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

import { useTerritories, useTerritoriesLoadStatus } from "../Store/entities/hooks";

import { isSearchMatch } from "../psUtil";
import TabPaneWithSearchFilter from "../Parts/TabPaneWithSearchFilter";

import type { TerritoryListProps } from "./types";
import { loadTerritoriesMeta} from "../Store/entities/territories";
import { TerritoryStore } from "../Store/entities/entitiesType";
import type { TabPaneSelections } from "../Parts/types";
import { UsePortalDispatch } from "../Store";

const TerritoryList = ({ ROOT }: TerritoryListProps) => {
	const [activeSearch, setActiveSearch] = useState<string>('');
	const [activeKey, setActiveKey] = useState(0);

	const { navigate } = ROOT;

	const territories = useTerritories();
	const territoriesLoadingStatus = useTerritoriesLoadStatus();

	const workingTerritories = useMemo(
		() =>
			territories
				.filter((t: TerritoryStore) => t)
				.filter((t: TerritoryStore) => isSearchMatch(t.name, activeSearch)),
		[territories, activeSearch]
	);

	const loading = territoriesLoadingStatus <= 3;

	const getTabSelectionsList = (): TabPaneSelections[] => {
		const workingTabPanes: TabPaneSelections[] = [];
		workingTabPanes.push({
			id: 0,
			title: 'Territories',
			key: 'territories',
			items: []
		});

		workingTabPanes.forEach((tabPane) => {
			workingTerritories.forEach((territory) => {
				tabPane.items.push({
					id: `${territory.id}`,
					type: "territory",
					name: territory.name,
					title: territory.name,
					onClick: () => {
						navigate(`/Territory?territory_id=${territory.id}`);
					},
				});
			});
		});

		return workingTabPanes;
	};

	const selections = getTabSelectionsList();

	const dispatch = UsePortalDispatch();

	useEffect(() => {
		dispatch(loadTerritoriesMeta());
	}, []);

	console.debug(setActiveSearch);

	return (
		<Container>
			<Card body className="entity-card">
				<Row>
					<Col>
						<TabPaneWithSearchFilter
							key="territory-list"
							selections={selections}
							loading={loading}
							disabled={false}
							setActiveKey={setActiveKey}
							activeKey={activeKey}
							searchFilter={activeSearch}
							setSearchFilter={setActiveSearch}
						/>
					</Col>
				</Row>
			</Card>
		</Container>
	);
};
 
export default TerritoryList;
