/* eslint-disable dot-notation */
/* eslint-disable camelcase */
import React from "react";

import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import XLSX from "xlsx";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { initialQuote, archiveQuoteThunk } from "../../Store/entities/quotes";
import { useIsPsAdmin } from "../../hooks";
import type { fakeRootType } from "../../FakeRootType";
import { UsePortalDispatch } from "../../Store";
import {
	useQuoteById,
	useQuoteDuplicateDispatch,
	useQuoteTypes
} from "../../Store/entities/quotes/hooks";

import type { QuoteHomeActionOption, QuoteHomeActionsType } from "./types";
import apiCalls from "../../PowerShadesAPIFunctions";
import QuoteShipmentModal from "../../Modals/QuoteShipmentModal";

const QuoteHomeActions = ({ quoteId }: QuoteHomeActionsType) => {
	const options: QuoteHomeActionOption[] = [];

	const fakeWindow = window as unknown as { ROOT: fakeRootType };

	const { ROOT } = fakeWindow;

	const { alert, toast, navigate } = ROOT;

	const dispatch = UsePortalDispatch();

	const quote = useQuoteById(quoteId) ?? initialQuote(quoteId);
	const isPsAdmin = useIsPsAdmin();
	const viewingFromMfg = window.location.hash.includes("#/Manufacturing");
	const dispatchQuoteDuplication = useQuoteDuplicateDispatch();
	const quoteTypes = useQuoteTypes();

	const [displayQuoteShipping, setDisplayQuoteShipping] = React.useState(false);

	const archiveVisible =
		quote.order_status !== "mfg_shipped_order" ||
		(isPsAdmin && quote.order_status === "mfg_shipped_order");

	const cutListVisible = isPsAdmin;
	const changeReferenceNumberVisible =
		(isPsAdmin && quote?.order_status === "under_review") ||
		(viewingFromMfg && quote?.order_status === "approved_order");
	const shippingVisible =
		quote?.order_status === "mfg_shipped_order" || quote?.order_status === "mfg_accepted_order";

	const mfgAcceptOrderVisible = viewingFromMfg && quote?.order_status === "approved_order";

	options.push({
		key: "view",
		label: "View",
		href: `/#/Quote?quoteID=${quoteId}`,
		onClick: () => {
			/* View the quote. */
		}
	});

	if (isPsAdmin) {
		options.push({
			key: "view-new",
			label: "View (Beta)",
			href: `/#/NewQuote/Details?quoteID=${quoteId}`,
			onClick: () => {
				/* View the quote. */
			}
		});
	}

	if (!viewingFromMfg) {
		options.push({
			key: "duplicate",
			label: "Duplicate",
			nestedOptions: isPsAdmin
				? quoteTypes.map((quoteType) => ({
					key: quoteType.id,
					label: quoteType.name,
					onClick: async () => {
						ROOT.loading();
						const duplicationAction = await dispatchQuoteDuplication(quote, quoteType.id);
						if (typeof duplicationAction === "number") {
							navigate(`/Quote?quoteID=${duplicationAction}`);
						}
					}
				}))
				: [],
			onClick: async () => {
				ROOT.loading();
				const duplicationAction = await dispatchQuoteDuplication(quote, 5);
				if (typeof duplicationAction === "number") {
					navigate(`/Quote?quoteID=${duplicationAction}`);
				}
			}
		});
	}

	if (cutListVisible) {
		options.push({
			key: "cutList",
			label: "Cut List",
			onClick: () => {
				apiCalls.getFabricCsv(quoteId).then((resp) => {
					const { dsv } = resp.data;
					// turn api data into xlsx
					const workbook = XLSX.read(dsv, {
						type: "string",
						raw: true
					});

					// output
					XLSX.writeFile(workbook, `${quoteId}-FabricCutList.xlsx`);
				});
			}
		});
	}

	if (changeReferenceNumberVisible) {
		options.push({
			key: "changeReferenceNumber",
			label: "Change Reference Number",
			onClick: () => {
				/* Open the change reference number modal. */
			}
		});
	}

	if (mfgAcceptOrderVisible) {
		options.push({
			key: "mfgAcceptOrder",
			label: "Accept Order",
			onClick: () => {
				alert({
					title: "Begin Production",
					text: "Are you sure you want to begin production on this order?",
					onAccept: () => {
						try {
							apiCalls.manufacturerAcceptOrder(quote.id).then((resp) => {
								if (resp.data.success) {
									toast("Order accepted!", { type: "success" });
								}
							});
						} catch (e) {
							toast("Something went wrong accepting the order", { type: "error" });
							console.error(e);
							throw new Error(
								`Error accepting production on order #${quote.id}. Please check console logs for details`
							);
						}
					},
					onCancel: () => {
						toast("Order Production not started", { type: "info" });
					}
				});
			}
		});
	}

	if (shippingVisible) {
		options.push({
			key: "shipping",
			label: "Shipping",
			onClick: () => {
				setDisplayQuoteShipping(true);
			}
		});
	}

	if (archiveVisible && !viewingFromMfg) {
		options.push({
			key: "archive",
			label: "Archive",
			onClick: () => {
				alert({
					title: `Are you sure you want to archive quote #${quoteId}?`,
					text: "This cannot be undone.",
					icon: "warning"
				})
					.then(() => {
						dispatch(archiveQuoteThunk(quoteId)).then((resp) => {
							if (resp.meta.requestStatus === "fulfilled") {
								toast(`Quote #${quoteId} deleted`, {
									type: "success"
								});
							} else {
								toast(`Quote #${quoteId} could not be deleted, please try again.`, {
									type: "error"
								});
							}
						});
					})
					.catch(() => {
						toast(`Quote deletion canceled`, { type: "info" });
					});
			}
		});
	}

	const dropDownItems = options.map((option) => {
		if (!option.nestedOptions || option.nestedOptions.length === 0) {
			const item = (
				<Dropdown.Item href={option.href ?? ""} key={option.key} onClick={option.onClick}>
					{option.label}
				</Dropdown.Item>
			);
			return item;
		}
		const nestedItem = (
			<Dropdown key={option.key + option.label} drop="end" className="nested-dropdown-container">
				<Dropdown.Toggle className="dropdown-item mt-0 btn-override-hover nested-dropdown">
					{option.label}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{option.nestedOptions.map((nestedOption) => (
						<Dropdown.Item
							href={nestedOption.href ?? ""}
							key={nestedOption.key}
							onClick={nestedOption.onClick}
						>
							{nestedOption.label}
						</Dropdown.Item>
					))}
				</Dropdown.Menu>
			</Dropdown>
		);
		return nestedItem;
	});

	return (
		<>
			{/* This is where the modals are hiding for now. */}
			<QuoteShipmentModal
				quoteId={quoteId}
				open={displayQuoteShipping}
				onClose={() => setDisplayQuoteShipping(false)}
			/>

			<DropdownButton
				flip
				drop="end"
				variant="green"
				className="quote-action"
				title={<FontAwesomeIcon icon={faBars} />}
			>
				{dropDownItems}
			</DropdownButton>
		</>
	);
};

export default QuoteHomeActions;
