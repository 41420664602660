import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import Popup from "reactjs-popup";

type QuoteWelcomeProps = {
    open: boolean;
    onClose: () => void;
};

const QuoteWelcome: React.FC<QuoteWelcomeProps> = ({ open, onClose }) => {
    return (
        <Popup
            open={open}
            onClose={onClose}
            className="quote-welcome mobile-modal"
            modal
            closeOnDocumentClick={false}
            closeOnEscape={false}
        >
            <Row className="p-2 text-center" >
                <h4>Welcome!</h4>
            </Row>
            <Row className="p-2">
                <text>

                    Thank you for considering PowerShades for your shading solutions. We look forward to enhancing your space with our innovative products.
                </text>
            </Row>
            <Row className="p-2">
                <text>
                    If you need any help with a quote at any given time please reach out to your territory manager and/or inside sales (417)-553-0504
                </text>
            </Row>
            <Row    >
                <Col>
                    <Button onClick={onClose}>Close</Button>
                </Col>
            </Row>
        </Popup>
    );
}

export default QuoteWelcome;