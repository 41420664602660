import React from "react";
import store from "../../../Store";
import { addAssembly, InitialAssemblyState, InitialShadeState } from "../../../Store/entities/assemblies";
import { AssemblyStore } from "../../../Store/entities/assemblies/types";
import EditCOROutdoorShadeModalBase from "../EditShade/EditCOROutdoorShade/EditCOROutdoorShadeBase";

type AddCOROutdoorShadeModalProps = {
	isOpen: boolean;
	onClose: () => void;
	quoteId: number;
};

const AddCOROutdoorShadeModal = ({ isOpen, onClose, quoteId }: AddCOROutdoorShadeModalProps) => {
	const submitShade = (newAssembly: AssemblyStore) => {
		store.dispatch(addAssembly({ quoteId, newAssembly, shadeType: "outdoor" }));
		onClose();
	};
    
	return (
		<EditCOROutdoorShadeModalBase
			isOpen={isOpen}
			onClose={onClose}
			quoteId={quoteId}
			assembly={{ ...InitialAssemblyState(), shades: [InitialShadeState()] }}
			setAssembly={submitShade}
			headerTitleOverride="Add Shade"
		/>
	);
};

export default AddCOROutdoorShadeModal;
