/* eslint-disable camelcase */
/* eslint-disable dot-notation */
import React, { useEffect } from 'react';
import {
	Card, Col, Container, Dropdown, Row,
} from 'react-bootstrap';
import {
	faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { faGhost } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startCase } from 'lodash';

import {
	useFilterSettings,
	useVisibleQuoteIds,
	useSelectedQuoteCountOfSelectedStatus,
	useVisibleQuoteIdsLoadStatus
} from '../Store/ui/hooks';
import {
	useQuoteCountStatus,
	useQuoteCounts,
	useQuotes,
	useSetFilterSettings,
} from '../Store/entities/quotes/hooks';
import { useIsPsAdmin, useUserData } from '../hooks';

import { initialQuote } from '../Store/entities/quotes';
import { loadStatus } from '../Store/entities/entitiesType';

import SkeletonQuoteCard from './Quotes/SkeletonCard';
import StatusCard from './StatusCards/Content';

import ManageEntities from './ManageEntities';
import CustomButton from '../Parts/Button';
import QuoteCard from './Quotes/Card';
import QuoteTable from './Quotes/Table';

import type { HomeProps } from './types';
import type { FilterOptions, HomeTableDatum, QuoteTypeIds, SortOptions, StatusOptions } from '../powershadesApiTypes';

import styles from './Home.module.scss';
import FilterContainer from './Filters/FilterContainer';
import InfiniteScroll from '../Parts/InfiniteScroll';

const Home = ({ ROOT }: HomeProps) => {
	const { setTitle } = ROOT;

	const filterSettings = useFilterSettings();
	const visibleQuotes = useVisibleQuoteIds();
	const quoteCounts = useQuoteCounts();
	const setFilterSettings = useSetFilterSettings();
	const quoteMetaData = useQuotes();
	const isPsAdmin = useIsPsAdmin();

	const quoteCountLoadStatus = useQuoteCountStatus();
	const quoteCountLoading = quoteCountLoadStatus <= loadStatus.loading;

	const quoteIdLoadStatus = useVisibleQuoteIdsLoadStatus();
	const quoteIdsLoading = quoteIdLoadStatus <= loadStatus.loading;

	// Disable eslint warning because we know that filter_names is not null
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const selectedFilter = filterSettings.filter_names[0]!;
	const categoryQuoteCount = useSelectedQuoteCountOfSelectedStatus(selectedFilter);

	const userData = useUserData();
	const isUserUsingTable = userData.is_using_old_homepage;

	/**
	 * Parses the URL parameters and returns the filter options object.
	 */
	const parseUrlParameters = (): FilterOptions => {
		// Parse the URL parameters
		const parsedParams = ROOT.GET;

		const initialFilterName = isPsAdmin ? 'all' : 'active_quotes';

		// Extract the page number from the URL parameters - if user is using card view, set page number to 1
		const page_number = !isUserUsingTable ? 1 : parsedParams.page_number && parsedParams.page_number !== 'undefined'
			? Number(parsedParams.page_number)
			: 1;

		// Extract the general filter from the URL parameters
		const general_filter = parsedParams.general_filter && parsedParams.general_filter !== 'undefined'
			? parsedParams.general_filter.replace(/\+/g, ' ')
			: '';

		// Extract the sort names from the URL parameters
		const sort_names: SortOptions[] = parsedParams.sort_names && parsedParams.sort_names !== 'undefined'
			? ([parsedParams.sort_names] as SortOptions[])
			: (['-updated'] as SortOptions[]);

		// Extract the territory IDs from the URL parameters
		const territory_ids = parsedParams.territory_ids && parsedParams.territory_ids !== 'undefined'
			? parsedParams.territory_ids
				.replace(/\+/g, ' ')
				.split(',')
				.filter(Boolean)
				.map(Number)
			: [];

		// Extract the dealer IDs from the URL parameters
		const dealer_ids = parsedParams.dealer_ids && parsedParams.dealer_ids !== 'undefined'
			? parsedParams.dealer_ids.replace(/\+/g, ' ').split(',').filter(Boolean).map(Number)
			: [];

		// Extract the filter names from the URL parameters
		const filter_names = parsedParams.filter_names && parsedParams.filter_names !== 'undefined'
			? ([parsedParams.filter_names] as StatusOptions[])
			: ([initialFilterName] as StatusOptions[]);

		// Extract the quote type IDs from the URL parameters
		const quote_type_ids = parsedParams.quote_type_ids && parsedParams.quote_type_ids !== 'undefined'
			? (parsedParams.quote_type_ids
				.replace(/\+/g, ' ')
				.split(',')
				.filter(Boolean)
				.map((item) => Number(item)) as QuoteTypeIds[])
			: ([] as QuoteTypeIds[]);

		// Set the number of results per page
		const results_per_page = 20;

		// Return the filter options object
		return {
			page_number,
			general_filter,
			sort_names,
			territory_ids,
			dealer_ids,
			filter_names,
			quote_type_ids,
			results_per_page,
		};
	};

	/**
	 * Updates the URL with the current filter settings and navigates to the Home page.
	 * @param workingFilterSettings - The current filter settings.
	 */
	const filterAndNavigate = (workingFilterSettings: FilterOptions): void => {
		// Convert the filter settings object to URL parameters
		const filterSettingsToParams = Object.entries(workingFilterSettings)
			.map(([key, value]) => {
				if (Array.isArray(value)) {
					return `${key}=${value.join(',')}`;
				} else if (typeof value === 'number') {
					return `${key}=${value.toString()}`;
				}
				return `${key}=${value}`;
			})
			.join('&');

		// Navigate to the Home page with the updated filter settings
		ROOT.navigate(`/Home?${filterSettingsToParams}`, { replace: true });
	};

	const handlePageChange = (page: number) => {
		setFilterSettings({ ...filterSettings, page_number: page });
		filterAndNavigate({ ...filterSettings, page_number: page });
	};

	const visibleQuoteData = visibleQuotes.map((id) => quoteMetaData[id] ?? initialQuote(id));

	const filteredQuoteCounts = Object.entries(quoteCounts).filter(
		([title, ]) => {
			// Need to filter out credit_card_declined if count is 0 or below
			// ! or if user is not a psAdmin
			return !(title === 'credit_card_declined');
		});

	const dropDownFilterUpdate = (title: StatusOptions) => {
		setFilterSettings({
			...filterSettings,
			filter_names: [title],
			page_number: 1,
		});
	};

	const dealerIdOrTerritoryIdFilterApplied = filterSettings.dealer_ids.length > 0
		|| filterSettings.territory_ids.length > 0;
	const quoteIdFilterApplied = filterSettings.quote_type_ids.length > 0;
	const generalFilterApplied = filterSettings.general_filter.length > 0;

	const filterApplied = dealerIdOrTerritoryIdFilterApplied
		|| quoteIdFilterApplied
		|| generalFilterApplied;

	const endMessageTag = filterApplied
		? 'No quotes found. '
		: "Boo! You've reached the end of your quotes!";

	// const amountToLoad = categoryQuoteCount;
	// const amountLoaded = visibleQuoteData.length;

	// Math.min(
	// 	amountToLoad - amountLoaded,
	// 	filterSettings.results_per_page
	// );

	useEffect(() => {
		const webParams = parseUrlParameters();

		setFilterSettings(webParams);

		setTitle('Dealer Portal Home');
	}, []);

	const moreQuotesToLoad = visibleQuoteData.length < categoryQuoteCount;
	console.log({
		moreQuotesToLoad,
		visibleQuoteDataLength: visibleQuoteData.length,
		quoteIdLoadStatus
	});
	const quoteCards = (
		<Col>
			{quoteIdsLoading ? (
				<Row className="g-1">
					{
						// grab results_per_page (default of 20)
						// then render the number of skeleton cards based off the max of results_per_page
						// or the number of quotes in the category
						Array.from([[], []],
							(_, i) => (
								<SkeletonQuoteCard key={i} />
							)
						)
					}
				</Row>
			) : (
				<InfiniteScroll
					loading={quoteIdsLoading}
					currentRenderCount={visibleQuoteData.length}
					fetchMoreItems={() => handlePageChange(filterSettings.page_number + 1)}
					hasMore={moreQuotesToLoad}
					loader={(
						<Row className="g-1">
							{Array.from({ length: 6 }, (_, i) => (
								<SkeletonQuoteCard key={i} />
							))}
						</Row>
					)}
					endMessage={(
						<Row className="justify-content-center mt-4">
							<Col xs={12} md={8} lg={6} xl={3}>
								<Card body>
									<Row>
										<Col className="text-center text-muted">
											<div className="user-select-none mb-3">{endMessageTag}</div>
											<FontAwesomeIcon
												className="text-muted mt-3 mb-4"
												bounce
												size="3x"
												icon={faGhost}
											/>
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					)}
				>
					<Row className="g-1">
						{visibleQuotes.map((quoteId) => (
							<QuoteCard key={quoteId} quoteId={quoteId} />
						))}
					</Row>
				</InfiniteScroll>
			)}
		</Col>
	);

	const quoteTable = (
		<QuoteTable />
	);

	const quoteDataStructure = isUserUsingTable ? quoteTable : quoteCards;

	return (
		<Container fluid>
			<Row className={styles.navigatorContainer}>
				<Col xl={2} lg={4} sm={4} className="mb-2">
					<ManageEntities ROOT={ROOT} />
				</Col>
				<Col xl={7} lg={4} className="d-none d-lg-block">
					{/* Spacer Column do not delete plz */}
				</Col>
				<Col className="d-lg-none mb-2">
					<Dropdown>
						<Dropdown.Toggle variant="green" className="w-100">
							{selectedFilter === "all" ? "All Quotes" : startCase(selectedFilter)}
							&nbsp;|&nbsp;
							{quoteCounts[selectedFilter].count ?? 0}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{filteredQuoteCounts
								.filter(([title]) => title !== selectedFilter)
								.map(([title, datum]) => {
									const { count } = datum as HomeTableDatum;
									return (
										<Dropdown.Item
											key={title}
											onClick={() => dropDownFilterUpdate(title as StatusOptions)}
										>
											{startCase(title)}
											&nbsp;|&nbsp;
											{count}
										</Dropdown.Item>
									);
								})}
						</Dropdown.Menu>
					</Dropdown>
				</Col>
				<Col className="text-end" xl={3} lg={4} sm={4}>
					<CustomButton
						color="green"
						fluid
						fullWidth
						blockLevel
						// disabled={creatingQuote}
						onClick={() => {
							// setCreatingQuote(true);
							// ROOT.createQuote(() => setCreatingQuote(false));
							ROOT.createQuote();
						}}
					>
						<FontAwesomeIcon icon={faPlus} className="me-1" />
						New Quote
						{/* {!creatingQuote ? (
							<>
								<FontAwesomeIcon icon={faPlus} />
								&nbsp;New Quote
							</>
						) : (
							<SpinningWheel className="button-spinner" />
						)} */}
					</CustomButton>
					{/* {quotePoEdit === 0 ? null : (
						<QuoteRefNumberChange
							quote_id={quotePoEdit}
							setOpenOverride={() => setQuotePoEdit(0)}
							openOverride={quotePoEdit > 0}
						/>
					)} */}
				</Col>
			</Row>
			<Row className={styles.statusContainer}>
				<Col className="d-none d-lg-block">
					<Row>
						{filteredQuoteCounts.map(([title, datum]) => {
							const { count } = datum as HomeTableDatum;

							return (
								<StatusCard
									loading={quoteCountLoading}
									active={title === selectedFilter}
									key={title}
									title={title as StatusOptions}
									count={count}
								/>
							);
						})}
					</Row>
				</Col>
			</Row>
			<Row>
				<Col>
					<FilterContainer
						filterSettings={filterSettings}
						setFilterSettings={setFilterSettings}
					/>
				</Col>
			</Row>
			<Row className={styles.quoteContainer}>{quoteDataStructure}</Row>
		</Container>
	);
};
// Home.whyDidYouRender = true;

export default Home;
