import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

import type { CountrySelectorTypedProps } from './types';
import type { AddressCountryStore } from '../Store/entities/addressStates';

const CountrySelectorTyped = ({
	currentCountryCode,
	onChange,
	disabled = false,
	isValid = true
}: CountrySelectorTypedProps) => {
	const loading = false;
	// TODO: Convert this to country store (and create country store :])
	const countries: AddressCountryStore[] = [
		{
			id: 1, name: 'United States', code: 'US'
		},
		{
			id: 2, name: 'Canada', code: 'CA'
		},
		{
			id: 3, name: 'New Zealand', code: 'NZ'
		},
		{
			id: 4, name: 'United Kingdom', code: 'UK'
		},
	];

	return (
		<>
			<h6>Country</h6>
			<Typeahead
				id="state-select"
				clearButton
				paginate
				flip
				placeholder={loading ? 'Loading Countries...' : 'Select Country...'}
				options={countries}
				disabled={disabled}
				isLoading={loading}
				labelKey="name"
				selected={countries.filter((country) => country.code === currentCountryCode)}
				onChange={(selection) => {
					const selectedCountry = selection[0];
					if (!selectedCountry) {
						onChange('');
						return;
					}
					const countryStore = selectedCountry as AddressCountryStore;
					const selectedCountryCode = countryStore.code;
					const newCountry = countries.find(
						(country) => country.code === selectedCountryCode
					);
					onChange(newCountry?.code ?? '');
				}}
				inputProps={{ autoComplete: "new-password" }} // Added to prevent chrome from autofilling the input
				style={!isValid ? { border: "1px solid #d68f83", borderRadius: "6px" } : {}}
			/>
		</>
	);
};

export default CountrySelectorTyped;
