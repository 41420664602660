import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import { Col, Row } from 'react-bootstrap';

import { useRepresentatives, useRepresentativesLoadStatus } from '../Store/entities/hooks';

import RepresentativeSelect from '../Parts/RepresentativeSelect';
import Button from '../Parts/Button';

import type { AddRepresentativeProps, FlatRep } from './types';
import { RepresentativeStore } from '../Store/entities/entitiesType';

const AddRepresentative = ({
	open,
	onClose,
	territoriesToFilterFrom,
	handleAdd,
	selectedRepresentativeIds: existingSelectedRepresentativeIds = [],
}: AddRepresentativeProps) => {
	const [selectedRepresentativeIds, setSelectedRepresentativeIds] = useState<number[]>([]);
	const [selectedRepresentatives, setSelectedRepresentatives] = useState<RepresentativeStore[]>([]);

	const representatives = useRepresentatives();
	const representativesLoadStatus = useRepresentativesLoadStatus();
	const representativesLoading = representativesLoadStatus <= 3;

	const repsNotAlreadySelected = representatives.filter(
		(representative) => !existingSelectedRepresentativeIds.includes(representative.id)
	);
	const filteredRepresentatives = repsNotAlreadySelected.filter((representative) => {
		const repTerritories = representative.territory_ids;
		const repTerritoriesFiltered = repTerritories.some(
			(repTerritory) => territoriesToFilterFrom.includes(repTerritory)
		);
		return repTerritoriesFiltered;
	});

	const readyToSubmit = selectedRepresentativeIds.length > 0 && !representativesLoading;

	const handleClose = () => {
		onClose();
	};

	const handleSubmit = () => {
		try {
			const repStoreToFlatReps: FlatRep[] = selectedRepresentatives.map((representative) => ({
				id: representative.id,
				territoryId: representative.territory_ids
					.find((territoryId) => territoriesToFilterFrom.includes(territoryId)) ?? 0,
			}));
			handleAdd(repStoreToFlatReps);
			handleClose();
			toast.success('Representative Added');
		} catch (err) {
			toast.error('Error Adding Representative');
			throw err;
		}
	};

	useEffect(() => {
		const selectedReps = representatives.filter((representative) =>
			selectedRepresentativeIds.includes(representative.id)
		);
		setSelectedRepresentatives(selectedReps);
	}, [selectedRepresentativeIds]);

	return (
		<Popup
			open={open}
			closeOnDocumentClick={false}
			closeOnEscape={false}
			onClose={handleClose}
			className="mobile-modal"
		>
			<Row className="mb-2">
				<Col>
					<h4>Add Representative</h4>
				</Col>
			</Row>
			<Row className="mb-2">
				<Col>
					<input className="d-none" />
					<RepresentativeSelect
						optionsOverride={filteredRepresentatives}
						multiple
						handleMultiChange={(newRepresentativeIds: number[]) => {
							setSelectedRepresentativeIds(newRepresentativeIds);
						}}
					/>
				</Col>
			</Row>
			<Row className="justify-content-end mt-4">
				<Col className="text-start">
					<Button color="gray" onClick={handleClose}>
						Close
					</Button>
				</Col>
				<Col className="text-end">
					<Button color="green" disabled={!readyToSubmit} onClick={handleSubmit}>
						Add
					</Button>
				</Col>
			</Row>
		</Popup>
	);
};

export default AddRepresentative;
