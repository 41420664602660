import React, { useState, useEffect, ReactNode } from 'react';

import type { QuoteTypeNames } from '../../../powershadesApiTypes';

import SpinningWheel from '../../../Parts/SpinningWheel';
import Money from '../../../Parts/Money';
import apiCalls from '../../../PowerShadesAPIFunctions';
import Button from '../../../Parts/Button';
import { GenericAssemblyCostBreakdown, GenericAssemblyCostBreakdownRow, ShadePart, } from '../../../powershadesApiTypeExtensions';
import Popup from 'reactjs-popup';
import { UseAssembly } from '../../../Store/entities/assemblies/hooks';
import { UseMultipliersByQuoteId } from '../../../Store/entities/quotes/hooks';

type ShadePriceBreakdownProps = {
	quoteId: number;
	sequenceId: number;
	quoteType: QuoteTypeNames;
	done: any;
	open: boolean;
	close: () => void;
}

const ShadePriceBreakdown = ({
	quoteId, sequenceId, quoteType, done, open, close
}: ShadePriceBreakdownProps) => {
	const [costBreakdown, setCostBreakdown] = useState<GenericAssemblyCostBreakdown>({
		fabric: { cost: 0, msrp: 0 },
		hembar: { cost: 0, msrp: 0 },
		motor: { cost: 0, msrp: 0 },
		header: { cost: 0, msrp: 0 },
		channels: { cost: 0, msrp: 0 },
		tube: { cost: 0, msrp: 0 },
		labor: { cost: 0, msrp: 0 },
		overhead: { cost: 0, msrp: 0 },
		packing: { cost: 0, msrp: 0 },
		hardware_kit: { cost: 0, msrp: 0 },
		hidden_shipping: { cost: 0, msrp: 0 },
		category_missing: { cost: 0, msrp: 0 },
		total: { cost: 0, msrp: 0 },
		errors: [],
	});

	const [partList, setPartList] = useState<ShadePart[]>([]);

	const loading = costBreakdown?.errors?.length === 0 && (costBreakdown.total?.msrp ?? 0) === 0;

	const shade = UseAssembly(quoteId, sequenceId);

	const [multiplier, setMultiplier] = useState(1);
	const [symbol,] = useState('$');
	const [iso, setIso] = useState('USD');

	const errors = costBreakdown?.errors ?? [];

	const errorMessageList = errors.map((error, index) => {
		return (
			<tr key={`error-${index}`}>
				<td>{error.error_type}</td>
				<td>{error.message}</td>
			</tr>
		);
	});

	useEffect(() => {
		if(open ?? true) {
			apiCalls
			.getAssemblyCostBreakdown(quoteId, sequenceId)
			.then((resp) => {
				if (resp.data.cost_breakdown === undefined) throw new Error(`500 Error: ${resp.data}`);
				setCostBreakdown(resp.data.cost_breakdown);
				setPartList(resp?.data?.part_list ?? []);
			})

			.catch((err) => {
				setPartList(err?.data?.part_list ?? []);
				console.error(err);
				setCostBreakdown({
					errors: [{
						message: 'Error loading cost breakdown',
						error_level: 2,
						error_type: "Error",
						code: "FE_ERROR_1"

					}],
					fabric: { cost: 0, msrp: 0 },
					hembar: { cost: 0, msrp: 0 },
					motor: { cost: 0, msrp: 0 },
					header: { cost: 0, msrp: 0 },
					channels: { cost: 0, msrp: 0 },
					tube: { cost: 0, msrp: 0 },
					labor: { cost: 0, msrp: 0 },
					overhead: { cost: 0, msrp: 0 },
					packing: { cost: 0, msrp: 0 },
					hardware_kit: { cost: 0, msrp: 0 },
					hidden_shipping: { cost: 0, msrp: 0 },
					total: { cost: 0, msrp: 0 },
					category_missing: { cost: 0, msrp: 0 },
				});
			});
		} 

	}, [sequenceId, open]);

	const costToggle = () => {
		let newIso = 'USD';
		let newMultiplier = 1;

		if (iso === 'USD') {
			newIso = 'CAD';
			newMultiplier = 1.5;
		} else {
			newIso = 'USD';
			newMultiplier = 1;
		}
		setMultiplier(newMultiplier);
		setIso(newIso);
	};

	const multipliers = UseMultipliersByQuoteId(quoteId)

	const distributorMultiplier = multipliers?.list?.find((m) => m.name == 'distributor')?.multiplier ?? 0.425;
	const dealerMultiplier = multipliers?.list?.find((m) => m.name == 'dealer')?.multiplier ?? 0.5;

	// ! Needs to be confirmed that this won't throw errors for non admins
	if (distributorMultiplier === undefined || dealerMultiplier === undefined) throw new Error('Multiplier is undefined');

	// const shadeCount = shade.data().shades.length;
	// Corradi ID Magic Number
	const isOutdoor =shade?.shade_type_id == 2;

	const prettify = (numberToFormat: number): number | '-' => {
		if (numberToFormat === 0.0) {
			return '-';
		}
		return numberToFormat;
	}

	const rowItem = (data: GenericAssemblyCostBreakdownRow | undefined, label: string) => {
		const itemSet: (ReactNode | number)[] = [];

		if (data === undefined || data === null) {

			itemSet.push("Labels");
			itemSet.push('Cost');
			itemSet.push('Distributor Price');
			itemSet.push('Dealer Price');
			itemSet.push('MSRP');
			return itemSet;
		} else {
			const { cost } = data;
			const { msrp } = data;

			itemSet.push(label);
			itemSet.push(`${prettify(cost)}`);
			itemSet.push(`${prettify(msrp * distributorMultiplier)}`);
			itemSet.push(`${prettify(msrp * dealerMultiplier)}`);
			itemSet.push(`${prettify(msrp)}`);
		}

		return itemSet;
	}

	const breakdownRows = errors.length > 0 && !costBreakdown.fabric ? [] : [
		rowItem(undefined, ''),
		rowItem(costBreakdown?.fabric, 'Fabric'),
		rowItem(costBreakdown?.hembar, 'Hembar'),
		rowItem(costBreakdown?.motor, 'Motor'),
		rowItem(costBreakdown?.header, 'Header'),
		rowItem(costBreakdown?.channels, 'Channels'),
		rowItem(costBreakdown?.tube, 'Tube'),
		rowItem(costBreakdown?.labor, 'Labor'),
		rowItem(costBreakdown?.overhead, 'Overhead'),
		rowItem(costBreakdown?.packing, 'Packaging'),
		rowItem(costBreakdown?.hardware_kit, 'Hardware Kit'),
		rowItem(costBreakdown?.hidden_shipping, 'Hidden Shipping'),
	];

	if((costBreakdown?.category_missing?.cost ?? 0) > 0) 
		breakdownRows.push(rowItem(costBreakdown?.category_missing, 'Category Missing'));


	breakdownRows.push(rowItem(costBreakdown?.total, 'Total'));


	const itemRows = breakdownRows.map((is, index) => {
		const cells = is.map((i, index2) => {
			const isNumber = Number.isNaN(Number(i)) === false;
			if (!isNumber) {
				if (is[5] && i === is[0]) {
					return (
						<th key={`kh-${index}${index2}`}>
							{i}
							&nbsp;
							{is[5]}
						</th>
					);
				}
				return <th key={`kh-${index}${index2}`}>{i}</th>;
			}
			const num = (i ?? 0) as number;
			return (
				<td key={`m-${index}${index2}`}>
					<Money value={num * multiplier} symbol={symbol} />
				</td>
			);
		});

		return <tr key={`ktr-${index}`}>{cells}</tr>;
	});

	const table = errors.length === 0 ? (
		<table cellSpacing="0">
			<tbody>{itemRows}</tbody>
		</table>
	) : (
		<table cellSpacing="0">
			<tbody>{errorMessageList}</tbody>
		</table>

	);

	const errorStr = isOutdoor
		? "This functionality isn't currently available on Outdoor Shades."
		: errors.length != 0
			? errors
			: '';

	const assembly = UseAssembly(quoteId, sequenceId);

	const title = `Internal Cost Breakdown ${errorStr ? `(${errorStr})` : ''}`;
	return (
		<Popup
			className="mobile-modal"
			key={`price-breakdown-${quoteId}-${sequenceId}`}
			modal
			nested
			// 0 is default, otherwise sequenceId
			open={open}
			onClose={() => close()}
		>
			<div>
				<h2 className={`text-${quoteType}`} style={{ textAlign: 'center' }}>
					{title}
				</h2>
				<h3 className={`text-${quoteType}`}>
					{shade?.room_name}
					&nbsp; -{shade?.shade_name}
				</h3>
				{!loading ? (
					<div>
						{table}
						{/* {pricingErrors} */}
						<div className="row">
							<div className="col-4">
								<Button color={quoteType ?? 'green'} onClick={costToggle}>
									{iso}
								</Button>
							</div>
							<div className="col-4">
								<div className="d-flex justify-content-end">
									<Button disabled={(partList?.length ?? 0) === 0} color={quoteType ?? 'green'} onClick={() => downloadPartList(partList, assembly?.shade_name ?? "unnamed",quoteId)}>
										Download Parts List
									</Button>
								</div>
							</div>
							<div className="col-4">
								<div className="d-flex justify-content-end">
									<Button color={quoteType ?? 'green'} onClick={done}>
										Done
									</Button>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div style={{ textAlign: 'center' }}>
						<SpinningWheel
							style={{
								fontSize: '3em',
								marginLeft: '1em',
								marginTop: '0.5em',
							}}
							className={`text-${quoteType}`}
						/>
					</div>
				)}

			</div>
		</Popup>
	);
};



const downloadPartList = (partList: ShadePart[], shadeName: string, quoteId: number) => {
	const csvWithoutHeaders = partList.map((row) => {
		const valuesDirty = Object.values(row);
		const values = valuesDirty.map((value) => {
			if (typeof value === 'string') {
				return `"${value.replace(",", "")}"`;
			}
			return value;
		}
		);
		return values.join(',');
	}).join('\n');

	const headers = Object.keys(partList[0] ?? {}).join(',');
	const csv = `${headers}\n${csvWithoutHeaders}`;

	const blob = new Blob([csv], { type: 'text/csv' });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = `${quoteId}-${shadeName}-part_list.csv`;
	a.click();
};

export default ShadePriceBreakdown;
