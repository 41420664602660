import Popup from 'reactjs-popup';
import { Container, Row, Col, Form } from "react-bootstrap";

import { useState } from 'react';
import TextInput from '../Parts/TextInput';
import StateSelector from '../Parts/StateSelector';
import Styles from '../Styles';
import BoolInput from '../Parts/BoolInput';
import Button from '../Parts/Button';

const InlineGroup = ({ children }) => (

	<div style={{
		display: 'flex', justifyContent: 'space-between', marginBottom: '0.5em'
	}}
	>

		{ children}

	</div>
);

const Hr = () => (

	<hr style={{
		background: 'gray', border: 'none', height: '1px', margin: '1.4em 1em 1em 1em'
	}}
	/>
);

const AddressEditorModal = ({
	ROOT, onCancel, onSet, label, initialAddress, bootstrap
}) => {
	const [address, setAddress] = useState(initialAddress || {
		company_name: null, first_name: null, last_name: null, address: null, address2: null, state: null, city: null, zip: null, phone: null, email: null, is_residential: false
	});

	const [open, setOpen] = useState(false);

	function addressChanged(options) {
		const new_address = { ...address, ...options };

		setAddress(new_address);
	}

	function submitAddress() {
		const REQUIRED = [{ label: 'First Name', varName: 'first_name' }, { label: 'Last Name', varName: 'last_name' }, { label: 'Address', varName: 'address' }, { label: 'State', varName: 'country_subdivision_id' }, { label: 'City', varName: 'city' }, { label: 'Zip / Postal Code', varName: 'zip' }, { label: 'Is Residential Address', varName: 'is_residential' }];

		const missing_fields = REQUIRED.filter((req) => address[req.varName] === null || address[req.varName] === '' || address[req.varName] === undefined);

		if (missing_fields.length > 0) {
			ROOT.alert({
				title: 'Missing Fields',
				text: <span>
					Please fill out all required fields before setting this address.
					<br />
					<br />
					Missing Fields:
					<br />

					{missing_fields.map((field) => (
						<span key={field.varName}>
							{field.label}
							<br />
						</span>
					))}

				</span>,
				acceptButtonText: 'OK',
				noCancelButton: true,
				quoteType
			})
				.then(() => { });
		} else {
			onSet && onSet(address);

			setOpen(false);
		}
	}

	const cancel = () => {
		onCancel && onCancel();

		setOpen(false);
	};

	return (
		<>
			<Popup
				className={bootstrap ? "mobile-modal" : "email"}
				open={open}
				onClose={() => setOpen(false)}
				closeOnDocumentClick={!!bootstrap}
			>
				{!bootstrap ? (
					<div
						className="quote-address address-modal"
						style={{
							width: "100%",
							maxWidth: "95vw",
							padding: "2em 3em 1em 3em",
						}}
					>
						<h2>{label || "New Address"}</h2>

						<TextInput
							label="Company Name"
							labelStyle={{ color: Styles.psGray }}
							wrapperStyle={{ width: "100%", marginBottom: "0.5em" }}
							style={{ width: "100%" }}
							onChange={(company_name) =>
								addressChanged({ company_name })}
							defaultValue={address.company_name}
							required
						/>

						<InlineGroup>
							<TextInput
								label="First Name"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ marginRight: "3%", flexGrow: "1" }}
								style={{ width: "100%" }}
								onChange={(first_name) =>
									addressChanged({ first_name })}
								defaultValue={address.first_name}
								required
							/>

							<TextInput
								label="Last Name"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ flexGrow: "1" }}
								style={{ width: "100%" }}
								onChange={(last_name) => addressChanged({ last_name })}
								defaultValue={address.last_name}
								required
							/>
						</InlineGroup>

						<Hr />

						<label>Is Residential Address</label>
						<br />
						<BoolInput
							onChange={(new_val) =>
								addressChanged({ is_residential: new_val })}
							label="Is Residential Address"
							value={address.is_residential}
							nullable
						/>

						<Hr />

						<TextInput
							label="Address Line 1"
							labelStyle={{ color: Styles.psGray }}
							wrapperStyle={{ width: "100%", marginBottom: "0.5em" }}
							style={{ width: "100%" }}
							onChange={(address) => addressChanged({ address })}
							defaultValue={address.address}
							required
						/>

						<TextInput
							label="Address Line 2"
							labelStyle={{ color: Styles.psGray }}
							wrapperStyle={{ width: "100%", marginBottom: "0.5em" }}
							style={{ width: "100%" }}
							onChange={(address2) => addressChanged({ address2 })}
							defaultValue={address.address2}
						/>

						<StateSelector
							key={address.country_subdivision_id}
							label="State / Province"
							labelStyle={{ color: Styles.psGray }}
							wrapperStyle={{ flexGrow: "1", marginBottom: "0.5em" }}
							style={{ width: "100%" }}
							onChange={(country_subdivision_id) =>
								addressChanged({ country_subdivision_id })}
							defaultValue={address.country_subdivision_id}
							required
						/>

						<InlineGroup>
							<TextInput
								label="City"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ flexGrow: "1", marginRight: "3%" }}
								style={{ width: "100%" }}
								onChange={(city) => addressChanged({ city })}
								defaultValue={address.city}
								required
							/>

							<TextInput
								label="Zip / Postal Code"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ flexGrow: "1" }}
								style={{ width: "100%" }}
								onChange={(zip) => addressChanged({ zip })}
								defaultValue={address.zip}
								required
							/>
						</InlineGroup>

						<Hr />

						<InlineGroup>
							<TextInput
								label="Email"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ flexGrow: "1" }}
								style={{ width: "95%" }}
								onChange={(email) => addressChanged({ email })}
								defaultValue={address.email}
							/>
							<TextInput
								label="Phone"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ flexGrow: "1" }}
								style={{ width: "100%" }}
								onChange={(phone) => addressChanged({ phone })}
								defaultValue={address.phone}
							/>
						</InlineGroup>

						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-evenly",
								margin: "1.5em auto",
								alignItems: "center",
								width: "100%",
								textAlign: "right",
							}}
						>
							<Button color="gray" onClick={cancel}>
								Cancel
							</Button>
							<Button color="green" onClick={submitAddress}>
								Set Address
							</Button>
						</div>
					</div>
				) : (
					<Container className="local-bootstrap">
						<Row>
							<Col>
								<h4 className="text-green">Edit Address</h4>
							</Col>
						</Row>
						<Row className="mb-1">
							<Col>Company Name</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								<Form.Control
									size="sm"
									type="text"
									onChange={(company_name) =>
										addressChanged({ company_name })}
									defaultValue={address.company_name}
									required
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								<Row className="mb-1">
									<Col>First Name</Col>
								</Row>
								<Row>
									<Col>
										<Form.Control
											size="sm"
											type="text"
											onChange={(first_name) =>
												addressChanged({ first_name })}
											defaultValue={address.first_name}
											required
										/>
									</Col>
								</Row>
							</Col>
							<Col>
								<Row className="mb-1">
									<Col>Last Name</Col>
								</Row>
								<Row>
									<Col>
										<Form.Control
											size="sm"
											type="text"
											onChange={(last_name) =>
												addressChanged({ last_name })}
											defaultValue={address.last_name}
											required
										/>
									</Col>
								</Row>
							</Col>
						</Row>
						<Hr />
						<Row>
							<Col>
								<Row>
									<Col>Residential Address?</Col>
								</Row>
								<Row>
									<Col>
										<BoolInput
											onChange={(new_val) =>
												addressChanged({
													is_residential: new_val,
												})}
											label="Is Residential Address"
											value={address.is_residential}
											nullable
										/>
									</Col>
								</Row>
							</Col>
						</Row>
						<Hr />
						<Row>
							<Col>
								<Row className="mb-2">
									<Col>
										<Row className="mb-1">
											<Col>Address Line 1</Col>
										</Row>
										<Row>
											<Col>
												<Form.Control
													size="sm"
													type="text"
													onChange={(address) =>
														addressChanged({ address })}
													defaultValue={address.address}
													required
												/>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row className="mb-2">
									<Col>
										<Row className="mb-1">
											<Col>Address Line 2</Col>
										</Row>
										<Row>
											<Col>
												<Form.Control
													size="sm"
													type="text"
													onChange={(address2) =>
														addressChanged({ address2 })}
													defaultValue={address.address2}
												/>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row className="mb-2">
									<Col>
										<Row className="mb-1">
											<Col>State / Province</Col>
										</Row>
										<Row>
											<Col>
												<StateSelector
													bootstrap
													key={address.country_subdivision_id}
													label="State / Province"
													labelStyle={{ color: Styles.psGray }}
													wrapperStyle={{
														flexGrow: "1",
														marginBottom: "0.5em",
													}}
													style={{ width: "100%" }}
													onChange={(country_subdivision_id) =>
														addressChanged({
															country_subdivision_id,
														})}
													defaultValue={
														address.country_subdivision_id
													}
													required
												/>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row className="mb-2">
									<Col>
										<Row>
											<Col>
												<Row className="mb-1">
													<Col>City</Col>
												</Row>
												<Row>
													<Col>
														<Form.Control
															size="sm"
															type="text"
															onChange={(city) =>
																addressChanged({ city })}
															defaultValue={address.city}
															required
														/>
													</Col>
												</Row>
											</Col>
											<Col>
												<Row className="mb-1">
													<Col>ZIP / Postal Code</Col>
												</Row>
												<Row>
													<Col>
														<Form.Control
															size="sm"
															type="text"
															onChange={(zip) =>
																addressChanged({ zip })}
															defaultValue={address.zip}
															required
														/>
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
								<Hr />
								<Row className="mb-2">
									<Col>
										<Row>
											<Col>
												<Row className="mb-1">
													<Col>Email</Col>
												</Row>
												<Row>
													<Col>
														<Form.Control
															size="sm"
															type="text"
															onChange={(email) =>
																addressChanged({ email })}
															defaultValue={address.email}
														/>
													</Col>
												</Row>
											</Col>
											<Col>
												<Row className="mb-1">
													<Col>Phone</Col>
												</Row>
												<Row>
													<Col>
														<Form.Control
															size="sm"
															type="text"
															onChange={(phone) =>
																addressChanged({ phone })}
															defaultValue={address.phone}
														/>
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row className="mt-4">
									<Col>
										<Button
											color="gray"
											onClick={cancel}
										>
											Cancel
										</Button>
									</Col>
									<Col className="text-end">
										<Button
											color="green"
											onClick={submitAddress}
										>
											Set Address
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				)}
			</Popup>
			<Button
				color="green"
				onClick={() => setOpen(true)}
			>
				Edit
			</Button>
		</>
	);
};

export default AddressEditorModal;
