const OrderThankYou = (props) => (

	<div className="confirmation-modal-holder">
        
		<div>
                    
			<h2>Thank You for Your Order</h2>

			<div id="order-confirm-description">
                Your order has been placed successfully.
				<br />
				<br />

                Your quote will be editable for 24 hours, after which it will move to manufacturing when approved.
				<br />
				<br />
                If you need to cancel your order, or for further support,
                please contact us.
			</div>

			<div className="button-holder">

				<button className="green-button" type="button" onClick={props.close}>Done</button>
				{// <button className="green-button" type="button" onClick={ ()=>{  ROOT.loadContent('#Quote?quoteID='+props.quote.ID+'&makepayment=1'); setTimeout( ()=> ROOT.refreshContent(true), 250) }}>Make Payment</button>
				}
			</div>

		</div>
	</div>
);

export default OrderThankYou;
