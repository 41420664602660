import React, { useEffect, useState, useMemo, useRef } from 'react';
import {
	Container, Row, Col, ListGroup, Badge, Placeholder
} from 'react-bootstrap';
import { isEmpty, debounce } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGhost } from '@fortawesome/pro-regular-svg-icons';

import QUOTE_GLOBALS from './QuoteGlobals';
import Button from '../Parts/Button';
import { useFullHWRItemsByQuoteId } from '../Store/entities/hardwareRequestItems/hooks';

const PartsForm = ({
	onDone, newQuote, quoteType
}) => {
	const parts = useFullHWRItemsByQuoteId(newQuote.Id);


	const [searchText, setSearchText] = useState('');
	const [filtering, setFiltering] = useState(false);
	const [found, setFound] = useState(true);
	const [loading, setLoading] = useState(true);
	const timesClicked = useRef(0);

	const debouncedSearch = useMemo(() =>
		debounce((value) => {
			setSearchText(value.trim());
		}, 500)
	);

	const addHwPart = (value) => {
		newQuote.addHWItem(value, 1);
	}

	// const debounceAdd = debounce((value) => {
	// 	newQuote.addHWItem(value, 1);
	// 	timesClicked.current = 0;
	// }, 500);

	// useEffect(() => () => {
	// 	debouncedSearch.cancel();
	// });

	let partsPreFiltered = parts.filter((p) => p.partNumber !== '');

	if (searchText !== '' ) {

		const searchTextClean = searchText?.trim()?.toLocaleLowerCase() ?? "";

		partsPreFiltered = partsPreFiltered.filter(
			(p) =>{
				const partNumber = p?.partNumber?.toLowerCase() ?? "";
				const description = p?.description?.toLowerCase() ?? "";


				return partNumber.includes(searchTextClean) ||
				description.includes(searchTextClean);
			}
		);
	}

	const options = partsPreFiltered.map((p) => {
		const listItem = (
			<ListGroup.Item
				key={p.partNumber}
				action
				onClick={() => {
					addHwPart(p.partNumber);
				}}
			>
				<Row>
					<Col xs={2} className="field">
						{p.partNumber }
					</Col>
					<Col xs={9} className="field">
						{p.name}
					</Col>
					<Col xs={1}>
						<Badge bg="green">{p.uom}</Badge>
					</Col>
				</Row>
			</ListGroup.Item>
		);
		return listItem;
	});


	useEffect(() => {
		if (options?.length >= 1) {
			setLoading(false);
		}
	}, [options]);

	return (
		<Container fluid className="local-bootstrap">
			<Row>
				<Col>
					<h5 className={`text-${quoteType}`}>Hardware Parts</h5>
				</Col>
			</Row>
			<Row className="mb-2">
				<Col>
					<input
						className="form-control form-control-sm"
						placeholder="Search..."
						onChange={(e) => debouncedSearch(e.target.value ?? "")}
					/>
				</Col>
			</Row>
			<Row className="parts-container">
				<Col xs={12}>
					{loading ? (
						<div className="placeholder-holder">
							<Placeholder animation="glow" className="row ms-0 me-0 mb-2">
								<Placeholder size="lg" xs={2} />
								<Col xs={1} />
								<Placeholder size="lg" xs={7} />
								<Col xs={1} />
								<Placeholder size="lg" bg="green" xs={1} />
							</Placeholder>
							<Placeholder animation="glow" className="row ms-0 me-0 mb-2">
								<Placeholder size="lg" xs={2} />
								<Col xs={1} />
								<Placeholder size="lg" xs={7} />
								<Col xs={1} />
								<Placeholder size="lg" bg="green" xs={1} />
							</Placeholder>
							<Placeholder animation="glow" className="row ms-0 me-0 mb-2">
								<Placeholder size="lg" xs={2} />
								<Col xs={1} />
								<Placeholder size="lg" xs={7} />
								<Col xs={1} />
								<Placeholder size="lg" bg="green" xs={1} />
							</Placeholder>
							<Placeholder animation="glow" className="row ms-0 me-0 mb-2">
								<Placeholder size="lg" xs={2} />
								<Col xs={1} />
								<Placeholder size="lg" xs={7} />
								<Col xs={1} />
								<Placeholder size="lg" bg="green" xs={1} />
							</Placeholder>
							<Placeholder animation="glow" className="row ms-0 me-0 mb-2">
								<Placeholder size="lg" xs={2} />
								<Col xs={1} />
								<Placeholder size="lg" xs={7} />
								<Col xs={1} />
								<Placeholder size="lg" bg="green" xs={1} />
							</Placeholder>
							<Placeholder animation="glow" className="row ms-0 me-0 mb-2">
								<Placeholder size="lg" xs={2} />
								<Col xs={1} />
								<Placeholder size="lg" xs={7} />
								<Col xs={1} />
								<Placeholder size="lg" bg="green" xs={1} />
							</Placeholder>
						</div>
					) : isEmpty(options) ? (
						<FontAwesomeIcon
							className="text-muted mt-4"
							bounce
							size="3x"
							icon={faGhost}
						/>
					) : <ListGroup>{options}</ListGroup>
				}
				</Col>
			</Row>

			<Row className="mt-4 justify-content-end mb-2">
				<Col lg={4}>
					<Button fullWidth color={quoteType ?? 'green'} onClick={onDone}>
						Done
					</Button>
				</Col>
			</Row>
		</Container>
	);
};

export default PartsForm;
