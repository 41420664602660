import { useState, useEffect, useCallback } from 'react';

import api from '../PowerShadesAPI';
import SpinningWheel from '../Parts/SpinningWheel';
import { useIsPowerShadesAdmin } from '../hooks';
import Button from '../Parts/Button';
import { useTerritoriesLoadStatus } from '../Store/entities/hooks';
import { Col, Container, Row } from 'react-bootstrap';

const CreateQuoteTerritoryModal = ({
	quoteTypes,
	loadContent,
	onClose,
	territories
}) => {
	const [selectedTerritoryId, setSelectedTerritory] = useState(null);
	// const [localTerritories, setLocalTerritories] = useState([]);
	// const [territoriesFetched, setTerritoriesFetched] = useState(false);
	const [creatingQuote, setCreatingQuote] = useState(false);
	const [headerConstructed, setHeaderConstructed] = useState(false);
	const [header, setHeader] = useState('');

	const CediaQuoteType = quoteTypes?.find((qt) => qt.name === 'CEDIA');

	const [selectedQuoteType, setSelectedQuoteType] = useState(CediaQuoteType?.id ?? 5);

	const quoteTypeName = quoteTypes?.find((qt) => qt.id === selectedQuoteType)?.name ?? '';

	const localTerritories = territories;

	const territoriesLoadStatus = useTerritoriesLoadStatus();

	const territoriesFetched = territoriesLoadStatus >= 3;

	// useEffect(() => {
	// 	if (!territories && territoriesFetched === false) {
	// 		api.getUserTerritories().then((resp) => {
	// 			const fetchedTerritories = resp.data.territories;
	// 			setLocalTerritories(fetchedTerritories);
	// 			setTerritoriesFetched(true);
	// 		});
	// 	}
	// }, []);

	// if (territories && territoriesFetched === false) {
	// 	setTerritoriesFetched(true);
	// 	setLocalTerritories(territories);
	// }

	const changedTerritory = (e) => setSelectedTerritory(parseInt(e.target.value, 10));

	const isTerritorySelected = () => {
		if (
			(typeof selectedTerritoryId !== 'number'
			|| selectedTerritoryId === null
			|| selectedQuoteType === null)
			&& quoteTypeName !== 'BidSpec'
		) {
			return false;
		}

		return true;
	};

	const createQuote = (callback) => {
		if (!isTerritorySelected()) return;

		const internalTerritory = localTerritories.length > 0 ? localTerritories.find((t) => t?.name === 'Internal') : null;

		let usedTerritoryId = selectedTerritoryId;

		if (quoteTypeName === 'BidSpec') {
			usedTerritoryId = internalTerritory.id;
		}

		api.createQuote(usedTerritoryId, selectedQuoteType).then((resp) => {
			const { success } = resp.data;

			if (success) {
				const quoteId = resp.data.quote_id;
				if (callback && typeof callback === 'function') {
					callback();
				}
				loadContent(`#Quote?quoteID=${quoteId}&initial=${true}`, onClose());
			} else {
				onClose();
				ROOT.alert({
					quickFormat: 'error',
					text: `There was an error creating the quote.  ${resp.data.error}.`,
				});
				throw new Error(`Failed to create quote. ${resp.data.error}`);
			}
		}).catch(() => {
			onClose();
			ROOT.alert({
				quickFormat: 'error',
				text: `There was an error creating the quote. Please try again.`,
			});
			throw new Error(`Failed to create quote.`);
		});
	};

	const territoryOptions = localTerritories
		.slice()
		.sort((a, b) => a?.name.localeCompare(b?.name))
		.map((terr) => (
			<option key={terr.name} value={terr.id}>
				{terr.name}
			</option>
		));

	const quoteTypeRows = quoteTypes?.map((qt) => (
		<option key={qt.id} value={qt.id}>
			{qt.name}
		</option>
	)) ?? [];

	const isPsAdmin = useIsPowerShadesAdmin();

	const hasAccessToQuoteTypes = (quoteTypes?.length > 0 ?? false) && isPsAdmin;
	const hasAccessToTerritories = (
		localTerritories?.length > 0 && territoriesFetched === true
	) ?? false;
	const hasAccessToBoth = hasAccessToQuoteTypes && hasAccessToTerritories;

	const headerConstruction = useCallback(() => {
		if (hasAccessToQuoteTypes && !hasAccessToTerritories && !hasAccessToBoth) {
			setHeader(' A Quote Type');
		}
		if (!hasAccessToQuoteTypes && hasAccessToTerritories && !hasAccessToBoth) {
			setHeader(' A Territory');
		}
		if (hasAccessToBoth) {
			setHeader(' Quote Type and Territory');
		}
		setHeaderConstructed(true);
	}, [hasAccessToBoth, hasAccessToQuoteTypes, hasAccessToTerritories]);
	
	useEffect(() => {
		header || headerConstruction();
	}, [localTerritories, quoteTypes, headerConstruction, header]);

	return (
		<div className="modal-holder">
			<div className="create-quote-territory-modal">
				{headerConstructed && territoriesFetched ? (
					<>
						<h2>
							Select
							{header}
						</h2>
						<div className="territory-selector">
							{hasAccessToQuoteTypes ? <label>Quote Types</label> : null}
							{hasAccessToQuoteTypes ? (
								<select
									style={{ marginBottom: "0.5em" }}
									value={selectedQuoteType || "CEDIA"}
									onChange={(e) => setSelectedQuoteType(e.target.value)}
								>
									{quoteTypeRows}
								</select>
							) : null}
							<label>Territories</label>
							{quoteTypeName === "BidSpec" ? null : (
								<select value={selectedTerritoryId || ""} onChange={changedTerritory}>
									<option value="">Select</option>
									{territoryOptions}
								</select>
							)}
						</div>
					</>
				) : (
					<div style={{ textAlign: "center", marginTop: "2.5em" }}>
						<SpinningWheel style={{ fontSize: "2em" }} />
					</div>
				)}

				<Container className="local-bootstrap mt-4">
					<Row>
						<Col className="text-start">
							<Button color="gray" className="mt-0" onClick={onClose}>
								Cancel
							</Button>
						</Col>
						<Col className="text-end">
							<Button
								disabled={creatingQuote || !isTerritorySelected()}
								color="green"
								onClick={() => {
									setCreatingQuote(true);
									createQuote(() => setCreatingQuote(false));
								}}
							>
								{!creatingQuote ? (
									"Create Quote"
								) : (
									<SpinningWheel
										style={{
											color: "black",
											marginTop: "0.7em",
											marginLeft: "0.9em"
										}}
									/>
								)}
							</Button>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default CreateQuoteTerritoryModal;
