import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Card } from 'react-bootstrap';

import InternalLink from '../Parts/InternalLink';
import Button from '../Parts/Button';

import { UsePortalDispatch } from '../Store';
import { useUser } from '../Store/user/hooks';
import { attemptLogin as loginDispatch } from '../Store/user';

import type { LoginProps } from './types';

import styles from './Login.module.scss'

const Login = ({ ROOT }: LoginProps) => {
	const { GET, setTitle } = ROOT;

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loggingIn, setLoggingIn] = useState(false);

	useEffect(() => {
		setTitle('Dealer Portal Login');
	}, [setTitle]);

	const dispatch = UsePortalDispatch();

	const user = useUser();

	const { error } = user;

	const triggerRedirect = (callbackFunction: () => void) => {
		if (GET.redirect) {
			let loc = `#${GET.redirect}?`;

			Object.keys(GET).forEach((key) => {
				if (key !== 'redirect') {
					loc += `&${key}=${GET[key]}`;
				}
			});
			ROOT.loadContent(loc);
			callbackFunction();
		} else {
			ROOT.sendHome();
			callbackFunction();
		}
	};

	const attemptLogin = async () => {
		setLoggingIn(true);

		try {
			const resp = await dispatch(loginDispatch({ email, password }));
			if (loginDispatch.fulfilled.match(resp)) {
				triggerRedirect(() => setLoggingIn(false));
			} else {
				setLoggingIn(false);
			}
		} catch (_) {
			setLoggingIn(false);
		}
	};

	useEffect(() => {
		ROOT.loaded();
	}, []);

	return (
		<Container>
			<Row className="justify-content-center">
				<Col sm={10} md={8} lg={6} xl={3} className={styles.loginCard}>
					<Card body border="secondary" className={styles.loginCardBody}>
						<Row>
							<Col className="mt-4 mb-4 text-center">
								<h3>Login</h3>
							</Col>
						</Row>

						{GET.resetsuccess && (
							<Row>
								<Col>
									<div className="green-text">
										Your password has been reset successfully.
									</div>
								</Col>
							</Row>
						)}

						<Row className="mb-2">
							<Col>
								<label htmlFor="login-email" className="mb-1">Email</label>
								<Form.Control
									disabled={loggingIn}
									size="sm"
									id="login-email"
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</Col>
						</Row>

						<Row className="mb-2">
							<Col>
								<label htmlFor="login-password" className="mb-1">Password</label>
								<Form.Control
									disabled={loggingIn}
									size="sm"
									id="login-password"
									type="password"
									autoComplete="current-password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									onKeyDown={(e) => e.key === 'Enter' && attemptLogin()}
								/>
							</Col>
						</Row>

						<Row>
							<Col>
								<span className="forgot-password">
									<InternalLink content="#ResetPassword">
										Forgot Password?
									</InternalLink>
								</span>
							</Col>
						</Row>

						<Row>
							<Col>
								<span className={`error ${error === null ? 'hide' : ''}`}>
									{error}
								</span>
							</Col>
						</Row>

						<Row className="mb-2 mt-4">
							<Col className="text-end">
								<Button color="green" loading={loggingIn} onClick={attemptLogin}>
									Login
								</Button>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

// Login.whyDidYouRender = true;

export default Login;
