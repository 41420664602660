import { useEffect, useState } from "react";

export default function EngineeringScreen(props) {
	const [isReady, setIsReady] = useState(null);
	const [pricingData, setPricingData] = useState({});
	const [quoteData, setQuoteData] = useState({});

	useEffect(() => {
		api.getPricingData().then((resp) => {
			setPricingData(resp.data);

			api.loadQuoteTwo().then((resp) => {
				setQuoteData(resp.data);
              
				setIsReady(true);
			});
		});
	}, props.ROOT.GET.quote_id);

	if (!isReady) {
		return (<>loading</>);
	}

	return (
		<>

		</>

	);
}
