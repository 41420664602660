import axios from 'axios';

import User from './User';
import PowerShadesAPIFunctions from './PowerShadesAPIFunctions';
import GetLegacyEndpoints from './LegacyEndpoints';

const logout = (() => { });

const api = new (function () {
	this.onLoad = new (function () {
		// eslint-disable-next-line no-unused-vars
		let ready = false;

		const callbacks = [];

		const add = (cb) => cb();

		const run = () => {
			ready = true;

			callbacks.forEach((cb) => cb());

			callbacks.splice(0, callbacks.length);
		};

		return {
			add,
			run,
		};
	})();

	this.loadFunctionsFromSpec = (specData) => {
		if (typeof specData === 'string') {
			specData = JSON.parse(specData);
		}

		Object.keys(specData).forEach((key) => {
			this[key] = function (...args) {
				const requestData = { action: key };

				args
					.filter((arg, ind) => specData[key].required[ind] !== null)
					.forEach((arg, ind) => (requestData[specData[key].required[ind]] = args[ind]));
				// eslint-disable-next-line no-async-promise-executor
				return new Promise(async (resolve, reject) => {
					const loggedIn = await User.isLoggedIn();

					const jwtToken = User.getToken();

					requestData.user_jwt = jwtToken;

					const call = `${process.env.BACKEND_ROOT}${process.env.ENDPOINT_URL}?action=${key}`;

					if (loggedIn || key == 'dealerNameExists' || key == 'dealerSignup') {
						axios.post(
							call,
							requestData,
							{
								headers: {
									Authorization: `Bearer ${jwtToken}`
								},
								timeout: 20000
							}
						)
							.then((resp) => {
								const errCheck = JSON.stringify(resp.data ?? "");

								if (errCheck.includes('You must be logged-in to perform this action')) {
									logout();
									reject(resp.data);
								} else {
									resolve(resp);
								}
							})
							.catch((err) => reject(err?.response?.data ?? 'Bad Call'));
					}
				});
			};
		});
		this.onLoad.run();
	};

	const specData = GetLegacyEndpoints();
	this.loadFunctionsFromSpec(specData);

	return this;
})();

window.api = api;

api.onLoad = api?.onLoad ?? ((callBack) => { callBack(); });

// const specData = GetLegacyEndpoints();

// Object.entries(api).forEach(([key, output]) => {
// 	if (PowerShadesAPIFunctions[key] == undefined) {
// 		console.log(`MISSING: ${key}`)

// 		const goods = specData[key];

// 		const items = goods?.required ?? [];

// 		const inputStr = items.reduce((prev, newItem) => {
// 			if(prev == null) {
// 				return newItem;
// 			}
// 			return `${prev},${newItem}`
// 		}, null) ?? ""

// 		console.log(inputStr)
// 	} else {
// 		console.log(`GOT: ${key}`)
// 	}
// })

Object.keys(PowerShadesAPIFunctions).forEach((key) => {
	(api[key] = PowerShadesAPIFunctions[key]);
});

export default api;
