import React, { FC, useState } from 'react';
import Popup from 'reactjs-popup';
import { Col, Form, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import Button from '../Parts/Button';
import { UseTerritories } from '../Store/entities/entitySelector';
import { addDistributorLocationFull, initialDistributorLocationState } from '../Store/entities/organizations/distributorLocations';
import BillingInformationHandler from '../ManageEntities/BillingInformationHandler';
import { UsePortalDispatch } from '../Store';
import { toast } from 'react-toastify';
import { useDistributorById } from '../Store/entities/hooks';
import { TerritoryStore } from '../Store/entities/entitiesType';
import { AddressStore } from '../powershadesApiTypes';

type AddDistributorLocationModalType = {
	allowedTerritoryIds: number[];
	open: boolean;
	setOpen: (open: boolean) => void;
	distributorId: number;
};

const AddDistributorLocationModal: FC<AddDistributorLocationModalType> = (props) => {
	const {
		open,
		setOpen,
		distributorId
	} = props;

	const [
		workingDistributorLocation,
		setWorkingDistributorLocation
	] = useState(initialDistributorLocationState());

	const {
		territory_ids: selectedTerritoryIds,
		name,
		email,
		phone,
		billing_address: billingAddress
	} = workingDistributorLocation;

	const territories = UseTerritories().list;

	const distributor = useDistributorById(distributorId);

	const allowedTerritoryIds = distributor.territory_ids;

	const allowedTerritories = territories.filter((t) => allowedTerritoryIds.includes(t.id));

	const selectedTerritories = territories.filter((t) => selectedTerritoryIds.includes(t.id));

	const dispatch = UsePortalDispatch();

	const addDistLocation = () => {
		// Add it
		dispatch(addDistributorLocationFull({
			...workingDistributorLocation, distributor_id: distributorId
		})).then((resp) => {
			if (resp.meta.requestStatus === 'fulfilled') {
				toast.success('Distributor Location Added');

				// Clear it
				setWorkingDistributorLocation(initialDistributorLocationState());
			} else {
				toast.error('Error Adding Distributor Location');
			}
		});
	};

	return (
		<Popup
			open={open}
			closeOnDocumentClick
			closeOnEscape
			onClose={() => setOpen(false)}
			className="mobile-modal"
		>
			<Row>
				<Col>
					<h3>Add Distributor Location</h3>

					<hr />
				</Col>
			</Row>
			<Row>
				<Col>
					<Row>
						<Col>
							<Row>
								<h4>Choose Territories</h4>

							</Row>
							<Row>
								<Typeahead
									id="territory-typeahead"
									options={allowedTerritories}
									selected={selectedTerritories}
									labelKey="name"
									multiple
									onChange={(selections) => {
										const terrSelect = selections as TerritoryStore[];

										setWorkingDistributorLocation(
											{
												...workingDistributorLocation,
												territory_ids: terrSelect.map((t) => t.id)
											}
										);
									}}
								/>
							</Row>
							<hr />
						</Col>

					</Row>
					<Row>
						<Col>
							<h5>Name</h5>
							<Form.Control
								placeholder="Enter Distributor Location Name"
								type="text"
								value={name}
								onChange={(e) => {
									const distLocName = e.target.value;
									setWorkingDistributorLocation(
										{ ...workingDistributorLocation, name: distLocName }
									);
								}}
							/>
						</Col>
					</Row>
					<br />
					<Row>

						<Col>
							<h6>Email</h6>
							<Form.Control
								type="text"
								value={email}
								onChange={(e) => {
									const newEmail = e.target.value;
									setWorkingDistributorLocation(
										{ ...workingDistributorLocation, email: newEmail }
									);
								}}
							/>
						</Col>
						<Col>
							<h6>Phone</h6>
							<Form.Control
								type="text"
								value={phone}
								onChange={(e) => {
									const newPhone = e.target.value;
									setWorkingDistributorLocation(
										{ ...workingDistributorLocation, phone: newPhone }
									);
								}}
							/>
						</Col>
						<br />

					</Row>
					<hr />

					<Row>
						<BillingInformationHandler
							address={billingAddress}
							disabled={false}
							onChange={(address: AddressStore) => {
								setWorkingDistributorLocation(
									{
										...workingDistributorLocation,
										billing_address: { ...address }
									});
							}}
							loading={false}
						/>
					</Row>
					<Row>
						<Col>
							<Button
								onClick={() => addDistLocation()}
							>
								Submit
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</Popup>
	);
};

export default AddDistributorLocationModal;
