import { InitialShadeState } from "..";
import { AssemblyStore, ExternalShadeData, IndoorFasciaSplit, IndoorRollDirection, IndoorShadeOptionsDualCoupledDict, ShadeStore } from "../types";

import InsideFabric from "../../../../assets/img/illus/inside_fabric.png";
import OutsideFabric from "../../../../assets/img/illus/outside_fabric.png";
import StandardRoll from "../../../../assets/img/illus/standard_roll.png";
import ReverseRoll from "../../../../assets/img/illus/reverse_roll.png";
const IndoorShadeOptionsDualCoupled: (assembly: AssemblyStore, setAssembly: (assembly: AssemblyStore) => void, externalItems: ExternalShadeData) => IndoorShadeOptionsDualCoupledDict = (assembly, setAssembly, externalItems) => {

    const indoorFabrics = externalItems.fabrics;

    const fabOptions = indoorFabrics.map(fab => ({ label: fab.name, value: fab.name, title: fab.name }));


    const isFascia = assembly.header_line?.includes("RC3080")
        || assembly.header_line?.includes("RC3158")
        || assembly.header_line?.includes("RC4039")
        || assembly.header_line?.includes("RS-SK");

    // 2-10 coupled shades
    const coupledAmountOptions = [
        {
            value: 2,
            label: "2",
            title: "2"
        },
        {
            value: 3,
            label: "3",
            title: "3"
        },
        {
            value: 4,
            label: "4",
            title: "4"
        },
        {
            value: 5,
            label: "5",
            title: "5"
        },
        {
            value: 6,
            label: "6",
            title: "6"
        },
        {
            value: 7,
            label: "7",
            title: "7"
        },
        {
            value: 8,
            label: "8",
            title: "8"
        },
        {
            value: 9,
            label: "9",
            title: "9"
        },
        {
            value: 10,
            label: "10",
            title: "10"
        }
    ];

    const setFrontFabric = (newFabricName) => {
        setAssembly({
            ...assembly,
            shades: assembly.shades
                .map(s => s.row_coordinate === 0 ? ({
                    ...s,
                    fabric_name: newFabricName
                }) : s)
        })
    }

    const setBackFabric = (newFabricName) => {
        setAssembly({
            ...assembly,
            shades: assembly.shades
                .map(s => s.row_coordinate === 1 ? ({
                    ...s,
                    fabric_name: newFabricName
                }) : s)
        })
    }

    const setFrontRollDirection = (newRollDirection) => {
        setAssembly({
            ...assembly,
            shades: assembly.shades
                .map(s => s.row_coordinate === 0 ? ({
                    ...s,
                    roll_direction: newRollDirection
                }) : s)
        });
    }

    const setBackRollDirection = (newRollDirection) => {
        setAssembly({
            ...assembly,
            shades: assembly.shades
                .map(s => s.row_coordinate === 1 ? ({
                    ...s,
                    roll_direction: newRollDirection
                }) : s)
        });
    }

    const headers = externalItems.assemblyOptions.headers;
    const currentHeader = headers?.find(header => header.name === assembly.header_line);

    const isStandardFrontRollAllowed = currentHeader?.dual_front_standard_allowed ?? true;
    const isReverseFrontRollAllowed = currentHeader?.dual_front_reverse_allowed ?? true;

    const frontRollDirectionOptions = [
        { label: "Standard", value: "standard" as IndoorRollDirection, title: "Standard", isActive: isStandardFrontRollAllowed },
        { label: "Reverse", value: "reverse" as IndoorRollDirection, title: "Reverse", isActive: isReverseFrontRollAllowed }
    ];

    const isStandardBackRollAllowed = currentHeader?.dual_back_standard_allowed ?? true;
    const isReverseBackRollAllowed = currentHeader?.dual_back_reverse_allowed ?? true;

    const backRollDirectionOptions = [
        { label: "Standard", value: "standard" as IndoorRollDirection, title: "Standard", isActive: isStandardBackRollAllowed },
        { label: "Reverse", value: "reverse" as IndoorRollDirection, title: "Reverse", isActive: isReverseBackRollAllowed }
    ];

    const fasciaSplitOptions = [
        {
            value: "match_mullion" as IndoorFasciaSplit,
            label: "Match Mullion",
            title: "Match Mullion"
        },
        {
            value: "single_piece" as IndoorFasciaSplit,
            label: "Single Piece",
            title: "Single Piece"
        },
        {
            value: "no_fascia" as IndoorFasciaSplit,
            label: "No Fascia",
            title: "No Fascia"
        }
    ];

    const setCoupledWidths = (newWidths: number[]) => {
        const shadesWithNewWidth = assembly.shades.map((shade) => {
            return {
                ...shade,
                width: newWidths[shade.column_coordinate] ?? 0.0
            }
        });

        setAssembly({
            ...assembly,
            shades: shadesWithNewWidth
        });
    };

    const setBandCount = (newBandCount: number) => {
        const currentBandCount = assembly?.band_count ?? 0;
        let newShades: ShadeStore[] = [];
        if (newBandCount > currentBandCount) {
            // Add
            // if(isDual)
            // Dual only file
            const frontShade = assembly?.shades.find(s => s.column_coordinate == 0 && s.row_coordinate == 0) ?? InitialShadeState();
            const backShade = assembly?.shades.find(s => s.column_coordinate == 0 && s.row_coordinate == 1) ?? InitialShadeState();

            newShades = assembly?.shades
                .map(s => ({ ...s }))

            for (let i = 0; i < newBandCount; i++) {
                if (newShades.find(s => s.column_coordinate == i)) {
                    continue;
                }
                newShades.push({ ...frontShade, column_coordinate: i });
                newShades.push({ ...backShade, column_coordinate: i })
            }

        } else {
            // Delete
            newShades = assembly?.shades
                .filter(s => (s.column_coordinate + 1) <= newBandCount)
                .map(s => ({ ...s }))
        }

        newShades.forEach(ns => ns.width = 0);
        setAssembly({
            ...assembly,
            band_count: newBandCount,
            shades: newShades
        });
    }

    const frontShade = (assemblyItem: AssemblyStore) => assemblyItem.shades.find(shade => shade.row_coordinate === 0);
    const backShade = (assemblyItem: AssemblyStore) => assemblyItem.shades.find(shade => shade.row_coordinate === 1);

    const indoorShadeOptionsSingle: IndoorShadeOptionsDualCoupledDict = {
        FrontFabric: {
            label: "Front Fabric",
            options: fabOptions,
            clearValue: () => {
                setFrontFabric("");
            },
            setValue: (value) => {
                setFrontFabric(value);
            },
            getSelectedOption: () => {
                return fabOptions.find(fab => fab.value === frontShade(assembly)?.fabric_name) ?? { label: "", value: "", title: "" };
            },
            varName: "fabric_name",
            error: [],
            info: [],
            getValue: () => frontShade(assembly)?.fabric_name ?? "",
            valuePopup: InsideFabric
        },
        BackFabric: {
            label: "Back Fabric",
            options: fabOptions,
            clearValue: () => {
                setBackFabric("");
            },
            setValue: (value) => {
                setBackFabric(value);
            },
            getSelectedOption: () => {
                return fabOptions.find(fab => fab.value === backShade(assembly)?.fabric_name) ?? { label: "", value: "", title: "" };
            },
            varName: "fabric_name",
            error: [],
            info: [],
            getValue: () => backShade(assembly)?.fabric_name ?? "",
            valuePopup: OutsideFabric
        },
        FrontRollDirection: {
            label: "Front Roll Direction",
            options: frontRollDirectionOptions.map(rd => {
                if(assembly.header_line === "OR_1234") {
                    return {...rd, isActive: false};
                } else {    
                    return rd;
                }
            }),
            clearValue: () => {
                setFrontRollDirection("");
            },
            setValue: (value) => {
                setFrontRollDirection(value);
            },
            getSelectedOption: () => {
                return frontRollDirectionOptions.find(roll => roll.value === frontShade(assembly)?.roll_direction) ?? { label: "", value: "" as IndoorRollDirection, title: "" };
            },
            varName: "roll_direction",
            error: [],
            info: [],
            getValue: () => (frontShade(assembly)?.roll_direction ?? "") as IndoorRollDirection,
            valuePopup: frontShade(assembly)?.roll_direction === "standard" ? StandardRoll : ReverseRoll
        },
        BackRollDirection: {
            label: "Back Roll Direction",
            options: backRollDirectionOptions,
            clearValue: () => {
                setBackRollDirection("");
            },
            setValue: (value) => {
                setBackRollDirection(value);
            },
            getSelectedOption: () => {
                return backRollDirectionOptions.find(roll => roll.value === backShade(assembly)?.roll_direction) ?? { label: "", value: "" as IndoorRollDirection, title: "" };
            },
            varName: "roll_direction",
            error: [],
            info: [],
            getValue: () => (backShade(assembly)?.roll_direction ?? "") as IndoorRollDirection,
            valuePopup: backShade(assembly)?.roll_direction === "standard" ? StandardRoll : ReverseRoll
        },
        CoupledWidth: {
            label: "Coupled Width",
            options: [],
            clearValue: () => {
                setCoupledWidths([]);
            },
            setValue: (value) => {
                setCoupledWidths(value)
            },
            getSelectedOption: () => { return { label: "", value: [] as number[], title: "" } },
            varName: "coupled_width",
            error: [],
            info: [],
            getValue: () => {
                const columnTotal = assembly.shades.reduce((max, shade) => shade.column_coordinate > max ? shade.column_coordinate : max, 0);

                const widths = [] as number[];

                for (let i = 0; i <= columnTotal; i++) {
                    const colShade = assembly.shades.find(s => s.column_coordinate == i);
                    widths.push(colShade?.width ?? 0)
                }
                return widths
            }
        },
        BandCount: {
            label: "Band Count",
            options: coupledAmountOptions,
            clearValue: () => {
                setBandCount(2);
            },
            setValue: (value) => {
                setBandCount(value)
            },
            getSelectedOption: () => { return { label: "", value: assembly.band_count, title: "" } },
            varName: "band_count",
            error: [],
            info: [],
            getValue: () => assembly.band_count
        },
        FasciaSplit: {
            label: "Fascia Split",
            options: fasciaSplitOptions,
            clearValue: () => {
                setAssembly({
                    ...assembly,
                    fascia_split: "" as IndoorFasciaSplit
                });
            },
            setValue: (value) => {
                setAssembly({
                    ...assembly,
                    fascia_split: value
                });
            },
            getSelectedOption: () => {
                return fasciaSplitOptions.find(fascia => fascia.value === assembly.fascia_split) ?? { label: "", value: "" as IndoorFasciaSplit, title: "" };
            },
            varName: "fascia_split",
            error: [],
            info: [],
            getValue: () => assembly.fascia_split as IndoorFasciaSplit,
            active: isFascia
        }


    };

    return indoorShadeOptionsSingle;
};

export default IndoorShadeOptionsDualCoupled;