import React from "react";
import { AssemblyStore } from "../Store/entities/assemblies/types";
import store from "../Store";
import { InitialAssemblyState, InitialShadeState, addAssembly } from "../Store/entities/assemblies";
import EditPSOutdoorShadeModalBase from "../Modals/ShadeModals/EditShade/EditPSOutdoorShade/EditPSOutdoorShadeBase";

type AddPSOutdoorShadeModalProps = {
	isOpen: boolean;
	onClose: () => void;
	quoteId: number;
};

const AddPSOutdoorShadeModal = ({ isOpen, onClose, quoteId }: AddPSOutdoorShadeModalProps) => {
	const submitShade = (newAssembly: AssemblyStore) => {
		store.dispatch(addAssembly({ quoteId, newAssembly, shadeType: "psoutdoor" }));
		onClose();
	};

	const initAssy = { ...InitialAssemblyState(), shades: [InitialShadeState()], unlocked: true };

	return (
		<EditPSOutdoorShadeModalBase
			isOpen={isOpen}
			onClose={onClose}
			quoteId={quoteId}
			assembly={initAssy}
			setAssembly={submitShade}
			isNotAdd={false}
		/>
	);
};

export default AddPSOutdoorShadeModal;
