import React from "react";
import { Link } from "react-router-dom";
import { TabPaneLinkProps } from "./types";

const TabPaneLink = ({ selection }: TabPaneLinkProps) =>
	<Link
		// Only link to entity if url is good, or link home
		to={+selection.id
			? `/${selection.type}?${selection.type}${
				selection.type === "quote" ? "ID" : "_id"
			}=${selection.id}` : "/#"}
		style={{ textDecoration: 'none', color: "#a7a9ac" }}
		onClick={() => console.log(selection)}
	>
		{selection.name}
	</Link>;

export default TabPaneLink;
