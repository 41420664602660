import React, { useState, useEffect } from 'react';
import PowerShadesTypeahead from '../Parts/PowerShadesTypeahead';

const DealerAssign = ({
	dealerId,
	dealers,
	setDealers,
	setDealerId,
	dealerSet,
	repriceQuote,
	className,
	editable,
	extraMargin
}) => {
	const [loadingDealers,] = useState(false);
	const [selectedDealerId, setSelectedDealerId] = useState(dealerId);

	const setDealer = setDealerId;

	useEffect(() => {
		dealerSet.changeDealers = (dealers) => {
			setDealers(dealers);
		};
	}, []);


	useEffect(() => {
		setSelectedDealerId(dealerId);
	}, [dealerId]);

	const changedDealers = (newDealer) => {
		const dealer = newDealer[0] ?? { label: 'Removed', value: 0 };
		const { value } = dealer;

		setDealer(dealer, (success) => {
			if (!success) {
				setSelectedDealerId(value);
				setDealerId(value);
			}
			repriceQuote && repriceQuote();
		});

		setSelectedDealerId(value);
	};

	const options = dealers
		.filter((d) => d.status === 'approved')
		.map((dealer) => ({ label: dealer.name, value: dealer.id }))
		.sort((s1, s2) => s1.label.localeCompare(s2.label));

	const currentDealer = dealers.find((d) => parseInt(d.id, 10) === parseInt(selectedDealerId, 10));

	const transformedDealer = currentDealer
		? { label: currentDealer.name, value: currentDealer.id }
		: { label: '', value: 0 };

	return (
		<div className={`${className} ${extraMargin ? 'mb-1' : null}`}>
			<label className={extraMargin ? 'mb-1' : null}>Dealer</label>
			<br />
			<PowerShadesTypeahead
				clearButton
				id='dealer'
				key={options.length}
				options={options}
				selected={currentDealer ? [transformedDealer] : []}
				onChange={(newDealer) => changedDealers(newDealer)}
				disabled={!editable}
				placeholder={loadingDealers ? 'Loading...' : 'Assign a Dealer'}
			/>
		</div>
	);
};

export default DealerAssign;
