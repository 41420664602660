import OptionField from './NewOptionFields/OptionField';

const QuoteField = ({
	halfWidth,
	quoteOption,
	required,
	disabled,
	noPlaceholder,
	editable,
	onChange,
	onBlur,
	value,
}) => (
	<div
		className={`quote-field ${halfWidth || quoteOption.half_width ? 'half-width' : ''}`}
		style={required !== undefined ? { position: 'relative' } : null}
	>
		<OptionField
			extraMargin
			disabled={disabled}
			placeholderLabel
			noPlaceholder={noPlaceholder == null ? false : noPlaceholder}
			editable={editable}
			option={quoteOption}
			onChange={onChange}
			onBlur={onBlur}
			valueChaned={() => {}}
			value={value}
			quoteLevel
		/>
		{required !== undefined ? (
			<span
				title="Required Field"
				style={{
					cursor: 'default',
					position: 'absolute',
					right: '9px',
					top: '9px',
					display: 'block',
					width: '10px',
					color: 'red',
				}}
			>
				*
			</span>
		) : null}
	</div>
);

export default QuoteField;
