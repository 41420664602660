export default function VerticalRule(props) {
	return (
		<div
			{...props}
			style={{
				...{
					height: '100%', width: '1px', backgroundColor: 'black', display: 'inline-block'
				},
				...props.style
			}}
		/>
	);
}
