import alert from "../Store/ui/alert";

const notImplementedModal = () => {
	alert({
		title: "Not Implemented",
		icon: "error",
		text: "This feature has not yet been implemented in the new quote view. Please use the old quote view and check back later!",
		showCancelButton: false,
	})
};

export default notImplementedModal;
