import React from "react";
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useCurrentMultiplier } from "./Store/entities/multipliers/hooks";
import { AssemblyStore } from "./Store/entities/assemblies/types";

type PricingUIProps = {
	quoteId: number;
	msrp: number;
	assembly: AssemblyStore;
	noLoading?: boolean;
};

const PricingUI = ({ msrp = 0, quoteId, assembly, noLoading = false }: PricingUIProps) => {
	const currentMultiplier = useCurrentMultiplier(quoteId);
	const { multiplier, currency_multiplier, currency } = currentMultiplier;
	const { last_time_priced, last_time_priced_value_saved } = assembly;
	const formattedNumber = (msrp * multiplier * currency_multiplier).toLocaleString('en-US', {
		style: 'currency',
		currency: currency || "USD"
	});

	const isLoading = !noLoading && ( (last_time_priced_value_saved > last_time_priced + 2) || msrp == 0);
	
	return (
		<div>
			<span>
				{
					isLoading
						? <Popup
							arrow={false}
							trigger={
								<div className="d-flex justify-content-center align-items-center position-relative">
									<FontAwesomeIcon
										icon={faSpinner}
										style={{
											position: "absolute",
											left: "50%",
											top: "50%",
											transform: "translate(-50%, -50%)",
											color: "#8dc63f",
											height: "3em",
											animationName: "rotate",
											animationDuration: ".75s",
											animationTimingFunction: "linear",
											animationIterationCount: "infinite",
										}}
									/>
									<span
										className="position-absolute text-primary d-flex align-items-center justify-content-center user-select-none"
									>
										P$
									</span>
								</div>

							}
							position="left center"
							on="hover"
						>
							Loading Price
						</Popup>
						: <span>
							{formattedNumber || "$0"}
						</span>
				}
			</span>
		</div>
	);
};
export default PricingUI;
