import React, { useCallback, useEffect, useRef } from "react";
import type { InfiniteScrollProps } from "./types";

const InfiniteScroll = ({
	loading,
	fetchMoreItems,
	children,
	hasMore = true,
	currentRenderCount,
	loader,
	endMessage,
}: InfiniteScrollProps) => {
	console.log({ loading });
	const observer = useRef<IntersectionObserver | null>(null);
	const lastElementRef = useCallback(
		(node: HTMLDivElement) => {
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[0]?.isIntersecting && hasMore && !loading) {
					fetchMoreItems();
				}
			});
			if (node) observer.current.observe(node);
		},
		[hasMore, loading, fetchMoreItems]
	);
	useEffect(
		() => () => {
			if (observer.current) {
				observer.current.disconnect();
			}
		},
		[]
	);
	return (
		<div>
			{children}
			<div ref={currentRenderCount > 0 && hasMore ? lastElementRef : undefined}>
				{hasMore ? loader : endMessage}
			</div>
		</div>
	);
};

export default InfiniteScroll;
