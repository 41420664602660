/* eslint-disable react/no-array-index-key */
import React from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHomeBlank, faToiletPaperBlankUnder } from "@fortawesome/pro-solid-svg-icons";
import { faBars, faScrewdriverWrench, faShapes } from "@fortawesome/free-solid-svg-icons";

import type { NavigationElement, QuoteNavigationProps } from "./types";
import { useSetTabPaneStatus, useViewingQuoteId } from "../../Store/ui/hooks";
import notImplementedModal from "../NotImplemented";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const QuoteNavigation = ({ isPsAdmin, quoteTypeId = 5 }: QuoteNavigationProps) => {
	const quoteTypesThatDisplayHardware = [1, 2, 3, 4];
	const quoteIsDemo = quoteTypeId === 6;
	const viewingQuoteId = useViewingQuoteId();
	const navigate = useNavigate();
	const setTabPane = useSetTabPaneStatus();

	const displayHardware = quoteTypesThatDisplayHardware.includes(quoteTypeId);
	const displayDemoReview = quoteIsDemo;

	const navigationElements: NavigationElement[] = [
		{
			label: "Details",
			icon: <FontAwesomeIcon icon={faHomeBlank as IconProp} size="2x" />,
			onClick: () => navigate(`?quoteID=${viewingQuoteId}`),
			active: true,
			displayOnDesktop: true,
			displayOnMobile: true,
		},
		{
			label: "Demo Review",
			onClick: () => notImplementedModal(),
			active: false,
			condition: displayDemoReview,
			displayOnDesktop: true,
			displayOnMobile: false,
		},
		{
			label: "Indoor Shades",
			onClick: () => notImplementedModal(),
			active: false,
			displayOnDesktop: true,
			displayOnMobile: false
		},
		{
			label: "Outdoor Shades",
			onClick: () => notImplementedModal(),
			active: false,
			displayOnDesktop: true,
			displayOnMobile: false
		},
		{
			label: "Accessories",
			onClick: () => notImplementedModal(),
			active: false,
			displayOnDesktop: true,
			displayOnMobile: false,
		},
		{
			label: "Fabric Samples",
			onClick: () => notImplementedModal(),
			active: false,
			displayOnDesktop: true,
			displayOnMobile: false
		},
		{
			label: "Hardware",
			onClick: () => notImplementedModal(),
			active: false,
			condition: displayHardware,
			displayOnDesktop: true,
			displayOnMobile: false
		},
		{
			label: "Cost Breakdown",
			onClick: () => notImplementedModal(),
			active: false,
			condition: isPsAdmin,
			displayOnDesktop: true,
			displayOnMobile: false
		},
		{
			label: "History",
			onClick: () => notImplementedModal(),
			active: false,
			condition: isPsAdmin,
			displayOnDesktop: true,
			displayOnMobile: false
		},
		{
			label: "Shades",
			icon: <FontAwesomeIcon icon={faToiletPaperBlankUnder as IconProp} size="2x" />,
			onClick: () => notImplementedModal(),
			active: false,
			displayOnDesktop: false,
			displayOnMobile: true,
		},
		{
			label: "Add-Ons",
			icon: <FontAwesomeIcon icon={faShapes as IconProp} size="2x" />,
			onClick: () => notImplementedModal(),
			active: false,
			displayOnDesktop: false,
			displayOnMobile: true,
		},
		{
			label: "Admin",
			icon: <FontAwesomeIcon icon={faScrewdriverWrench} size="2x" />,
			onClick: () => notImplementedModal(),
			active: false,
			condition: isPsAdmin,
			displayOnDesktop: false,
			displayOnMobile: true
		},
		{
			label: "Actions",
			icon: <FontAwesomeIcon icon={faBars} size="2x" />,
			onClick: () => setTabPane(true),
			active: false,
			displayOnDesktop: false,
			displayOnMobile: true,
		},
	];

	return (
		<>
			<Col xs={2} className="d-none d-xl-block">
				<Nav variant="pills" className="flex-column quote-nav desktop-nav">
					{navigationElements.map((element, index) => {
						if (element.condition === false) return null;
						if (element.displayOnDesktop === false) return null;
						return (
							<Nav.Item key={index}>
								<Nav.Link
									className={element.active ? "active" : ""}
									onClick={element.onClick}
								>
									{element.label}
								</Nav.Link>
							</Nav.Item>
						);
					})}
				</Nav>
			</Col>
			<Navbar fixed="bottom" className="d-xl-none mobile-nav">
				<Container fluid className="m-0 p-0">
					<Row className="w-100 m-0">
						{navigationElements.map((element, index) => {
							if (element.condition === false) return null;
							if (element.displayOnMobile === false) return null;
							if (!element.icon) throw new Error("Icon must be defined if displayOnMobile is true");
							return (
								<Col>
									<Nav.Link
										key={index}
										className={element.active ? "navbar-button active" : "navbar-button"}
										onClick={element.onClick}
									>
										<div className="nav-icon">
											{element.icon}
										</div>
										<div className="nav-text">
											{element.label}
										</div>
									</Nav.Link>
								</Col>
							);
						})}
					</Row>
				</Container>
			</Navbar>
		</>
	);
};

export default QuoteNavigation;
