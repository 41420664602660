import React from 'react';

class ShadeRowNew extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
            
			hasErrors: props.hasErrors
		};

		this.changeError = this.changeError.bind(this);

		if (props.update) {
			if (!props.update[props.sequenceId]) {
				props.update[props.sequenceId] = {};
			}
			props.update[props.sequenceId].row = this.changeError;
		}
	}

	changeError(hasErrors) {
		this.setState({
			hasErrors
		});
	}

	render() {
		const {
			extraClasses,
			columns,
			hasLargeOutdoorMotor,
			hasErrors,
			key
		} = this.props;

		const total = columns.length;

		const displayColumns = columns.map((c, index) => {
			let className = extraClasses;

			if (index == 0) {
				className += ' left-most-cell';
			}

			if (index + 1 == total) {
				className += ' right-most-cell';
			}

			return <td className={className} key={`${key}-${index}`}>{c}</td>;
		});

		let classes = hasLargeOutdoorMotor ? 'large-outdoor' : "";

		classes = `${classes} ${extraClasses}`;

		return (
			<tr key={`row-${key}`} className={classes} error={hasErrors ? 'true' : 'false'}>
				{displayColumns}
			</tr>
		);
	}
}

export default ShadeRowNew;
