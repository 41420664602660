import React, { FC, useEffect } from 'react';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import { Col, Row } from 'react-bootstrap';
import { UsePortalSelector } from '../Store';
import Button from '../Parts/Button';
import { useQuoteShipmentDispatch } from '../Store/entities/quotes/hooks';
import apiCalls from '../PowerShadesAPIFunctions';
import { useIsPsAdmin } from '../hooks';

const QuoteShipmentModal: FC<{
	quoteId: number;
	open: boolean;
	onClose: () => void;
}> = (props) => {
	const {
		quoteId,
		open,
		onClose
	} = props;

	// Using a generic selector here because the shipment data is not stored in the redux store
	// unless we pull the full quote data, which is not necessary for this modal.
	const quote = UsePortalSelector((s) => s.entity.quotes.list[quoteId]);
	const isPsAdmin = useIsPsAdmin();
	const [fixingQuoteShipment, setFixingQuoteShipment] = React.useState(false);

	const isShipped = quote?.order_status === 'mfg_shipped_order';
	const shipments = quote?.shipments ?? [];

	const linkFormats = {

		fedex: (trackingNumber) => `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${trackingNumber}`,
		ups: (trackingNumber) => `https://www.ups.com/track?loc=en_US&tracknum=${trackingNumber}`,
		usps: (trackingNumber) => `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`,
	};

	const dispatchQuoteShipping = useQuoteShipmentDispatch();

	useEffect(() => {
		if (shipments.length === 0 && open) {
			dispatchQuoteShipping(quoteId);
		}
	}, [shipments.length, open]);

	if (!quote) {
		return null;
	}

	const {
		title,
		order_shipped_time: orderShippedTime,
		ordered_time: orderedTime
	} = quote;

	const ordered = new Date((orderedTime ?? 0) * 1000).toLocaleDateString();
	const shipped = new Date((orderShippedTime ?? 0) * 1000).toLocaleDateString();

	const shipmentRows = shipments.map((s) => {
		const carrier = (s.carrier ?? "").toLowerCase().trim();

		const trackingNumber = s.tracking_number.toLowerCase().trim();

		const shippedRow = new Date((s.time ?? 0) * 1000).toLocaleDateString();

		const trackingLink = carrier.includes('fedex') ? linkFormats.fedex(trackingNumber)
			: carrier.includes('ups') ? linkFormats.ups(trackingNumber)
				: carrier.includes('usps') ? linkFormats.usps(trackingNumber)
					: "";

		const link = trackingLink.length > 0 && trackingNumber.length > 0
			? <a href={trackingLink} target="_blank" rel="noreferrer">{trackingNumber}</a>
			: trackingNumber;

		return (
			<tr key={`${s.quote_id}-${trackingNumber}`}>
				<td>{shippedRow}</td>
				<td>{carrier}</td>
				<td>{link}</td>
			</tr>
		);
	});

	const showPullFromZoho = () => {
		setFixingQuoteShipment(true);
		apiCalls.AttemptFixFromZoho(quoteId).then((res) => {
			if (res?.data?.success) {
				dispatchQuoteShipping(quoteId);
				toast.success('Pulling Shipments Succeeded. Any Zoho Shipments should show momentarily.');

			} else {
				toast.error('Failed to pull from Zoho. Please Contact Engineering.');
			}
			setFixingQuoteShipment(false);
		});
	};

	return (
		<Popup
			open={open}
			onClose={onClose}
			closeOnDocumentClick
			className="mobile-modal"
		>
			<Row>
				<Col>
					<Row>
						<h1>Shipments Overview</h1>
					</Row>
					<Row>
						<table className="order-confirmation-table" cellSpacing="0">
							<tbody>

								<tr className="bold-row">

									<th>Order Number</th>
									<th>Order Title</th>
									<th>Date Ordered</th>
									{isShipped ? <th>Date Shipped</th> : null}

								</tr>
								<tr>
									<td>{quoteId}</td>
									<td>{title}</td>
									<td>{ordered}</td>
									{isShipped ? <td>{shipped}</td> : null}
								</tr>

							</tbody>
						</table>

						<table className="order-confirmation-table" cellSpacing="0">
							<tbody>
								<tr className="bold-row">
									<th>Date Shipped</th>
									<th>Carrier</th>
									<th>Tracking Code</th>
								</tr>
								{
									shipmentRows
								}
							</tbody>
						</table>
					</Row>
					{/*
	<Row>
				<Col width="50%">
					<Button className='mt-4' onClick={() => clearAndCancel()}>
						Cancel
					</Button>
					<Button className='mt-4 align-end' onClick={() => swap()}>
						Swap Dealers
					</Button>

				</Col>
			</Row>
*/}
					<Row className=" mt-4">
						<Col className='text-start'>
							<Button color="gray" onClick={onClose}>
								Close
							</Button>
						</Col>
						{isPsAdmin && <Col className='text-end'>
							<Button disabled={fixingQuoteShipment} className='mt-4 align-end' color="green" onClick={showPullFromZoho}>
								Fix From Zoho
							</Button>
						</Col>}
					</Row>
				</Col>
			</Row>
		</Popup>

	);
};

export default QuoteShipmentModal;
