import { useState, useEffect, useCallback, useMemo } from "react";
import jsPDF from "jspdf";
import { applyPlugin } from "jspdf-autotable";
import { Container, Col, Row } from "react-bootstrap";
import Popup from "reactjs-popup";
import * as Sentry from "@sentry/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";

import QuoteHistoryModal from "../Modals/QuoteHistory";
import FabricAllowedModalIndoor from "../Modals/FabricAllowedIndoor";
import FabricAllowedModalOutdoor from "../Modals/FabricAllowedOutdoor";
import PopulateDashboardTasks from "../Modals/QuotePopulateDashboardTasks";
import CostBreakownModal from "../Modals/QuoteCostBreakdown";
import ConfirmApproval from "../Modals/ConfirmApproval";
import QuoteLockedModal from "../Modals/QuoteLocked";
import ShadeSummaryModal from "../Modals/ShadeSummary";

import TextArea from "../Parts/TextArea";
import PlaceOrder from "./OrderModal/PlaceOrder";
import ProductForm from "./NewOptionFields/ShadeActions/ProductForm";
import PrintQuote from "./Print/PrintQuote";

import ForceEditModal from "./ForceEditModal";

import { getQuoteType } from "./QuoteObjectExtensions.ts";
import {
	MAX_SAMPLE_QUANTITY,
	useHasValidPropertyAddress,
} from "./Quote";
import QuoteFields from "./QuoteFields";
import AddPSOutdoorShadeModel from "../Quote/AddPSOutdoorShadeModal";
import QuoteActionsNew from "./NewItems/QuoteActions";
import InvoiceTable from "./InvoiceTable";
import TotalPrice from "./TotalPrice";
import ShadeEditor from "./ShadeForm/ShadeEditor";
import ExportModal from "./ExportModal";
import OrderThankYou from "./OrderThankYou";
import QuoteAdminBar from "./QuoteAdminBar";
import AddressModal from "./AddressModal";

import ShadeCostBreakdown from "./ShadeCostBreakdown";
import QuoteObject from "./NewItems/QuoteObject";

import QuoteVerifications from "./NewItems/QuoteVerification";
import store, { UsePortalDispatch } from "../Store";
import Button from "../Parts/Button";

import api from "../PowerShadesAPI";
import { useDealerById, useDealersByTerritory, useIndoorFabrics, usePSOutdoorFabrics, useTerritories } from "../Store/entities/hooks";
import { RefreshQuotePricing, reloadQuote, setQuoteToEditable, setQuoteToUnEditable, updateQuoteNotes, wentToQuote } from "../Store/entities/quotes";
import { isFailedApiCall } from "../psUtil";
import { selectAssemblyListByQuoteId, useAssembliesByQuoteId } from "../Store/entities/assemblies/hooks";
import { UseMultipliersByQuoteId, useQuoteById, useQuoteTitle, useQuoteTypeNameByQuoteId } from "../Store/entities/quotes/hooks";
import { UseUnAcknowledgedWarnings } from "./NewItems/QuoteWarningVerificationsFromStore";
import { loadQuoteAssemblies, repriceAssemblies, setAssembliesUnlocked } from "../Store/entities/assemblies";
import { debounce } from "lodash";
import { GetAssemblyOptions } from "../Store/entities/assemblyOptions";
import AddCOROutdoorShadeModal from "../Modals/ShadeModals/AddShade/AddCOROutdoorShade";
import { selectAccessories } from "../Store/entities/accessories/hooks";

applyPlugin(jsPDF);

// import { useIsLoggedIn, useIsPsAdmin, useIsManufacturer, useIsDealer } from 'hooks';

const Quote = (props) => {
	const [showingPlaceOrder, setShowingPlaceOrder] = useState(false);
	const [showingApproveModal, setShowingApproveModal] = useState(false);
	const [quoteHash, setQuoteHash] = useState("");
	const [showProductForm, setShowProductForm] = useState(false);
	const [showBidSpecForm, setShowBidSpecForm] = useState(false);
	const [showExportModal, setShowExportModal] = useState(false);
	const [showOrderThankYou, setShowOrderThankYou] = useState(false);
	const [showForceEditModal, setShowForceEditModal] = useState(false);
	const [quoteLockedUser, setQuoteLockedUser] = useState(null);
	const [summaryShade, setSummaryShade] = useState(null);
	const [editingShade, setEditingShade] = useState(null);
	const [showingHistoryModal, setShowingHistoryModal] = useState(false);
	const [ShowingFabricAllowedIndoorModal, setShowingFabricAllowedIndoorModal] = useState(false);
	const [ShowingFabricAllowedOutdoorModal, setShowingFabricAllowedOutdoorModal] = useState(false);
	const [showingPopulateDashboardTasks, setShowingPopulateDashboardTasks] = useState(false);
	const [showingPopulateDashboardModal, setShowingPopulateDashboardModal] = useState(false);
	const [showingCostBreakdown, setShowingCostBreakdown] = useState(false);
	const [showingPricingLocked, setShowingPricingLocked] = useState(false);
	const [showEditQuoteDisclaimer, setShowEditQuoteDisclaimer] = useState(-1); // Allows for sequenceId of 0
	const [costBreakdownShade, setCostBreakdownShade] = useState(null);
	const [showingPropertyAddressEditor, setShowingPropertyAddressEditor] = useState(false);
	const [showingShippingAddressEditor, setShowingShippingAddressEditor] = useState(false);
	const [showConfigurations, setShowConfigurations] = useState(false);
	const [quoteLoaded, setQuoteLoaded] = useState(false);
	const [quoteReady, setQuoteReady] = useState(false);
	const [currentDealer, setCurrentDealer] = useState({});
	const [userCurrencyData, setCurrencyData] = useState(false);
	// [renderCount, setRenderCount] = useState(0),
	const [userData, setUserData] = useState(false);
	const [demoReviewFailed, setDemoReviewFailed] = useState(false);
	const [showAddPSOutdoorShade, setShowAddPSOutdoorShade] = useState(false);
	const [showAddCOROutdoorShade, setShowAddCOROutdoorShade] = useState(false);
	const fcState = useState([]);

	const [newQuote, setNewQuote] = useState(null);

	const [searchParams,] = useSearchParams();
	const quoteID = Number(searchParams.get("quoteID"));
	const quoteStore = useQuoteById(quoteID);

	const changeNotes = useCallback(debounce((new_value) => {
		dispatch(updateQuoteNotes({
			quoteId: quoteID,
			notes: new_value
		}));
	}, 500), [quoteID, newQuote]);

	const quoteTitle = useQuoteTitle(quoteID)
	const multipliers = UseMultipliersByQuoteId(quoteID)?.list ?? [];

	useEffect(() => {
		if(multipliers.length > 0) 
			props.setTitle(quoteTitle);
	}, [quoteTitle, multipliers]);

	useEffect(() => {
		dispatch(GetAssemblyOptions(quoteID));
	}, [])

	const workingTerritoryId = newQuote?.territoryId;
	const dispatch = UsePortalDispatch();

	const indoorFabrics = useIndoorFabrics(quoteID);
	//console.log('🔹🔹🔹🔹🔹🔹 Content', quoteID);
	const psOutdoorFabrics = usePSOutdoorFabrics(quoteID);

	const territories = useTerritories();
	const dealers = useDealersByTerritory(workingTerritoryId);
	const selectedDealer = useDealerById(newQuote?.dealerId ?? 0);

	let isPsAdmin = false;
	let isDealer = false;
	let isManufacturer = false;
	let isPurchaser = false;

	if (userData) {
		isPsAdmin = userData.roles.some((ur) =>
			ur.role_name.includes("powershades_admin"));
		isDealer = userData.roles.some((ur) =>
			ur.role_name.includes("dealer"));
		isManufacturer = userData.roles.some((ur) =>
			ur.role_name.includes("manufacturer"));
		isPurchaser = userData.roles.some((ur) =>
			ur.role_name.includes("purchasing"));
	}

	const renderCountGet = () => renderCount;



	const quoteWarnings = UseUnAcknowledgedWarnings(quoteID);
	const needsVerification = quoteStore?.order_status == 'active_quote' && quoteWarnings.length > 0;

	const {
		editable
	} = quoteStore ?? { editable: false };
	const setEditable = (editableNow) => {
		editableNow ? dispatch(setQuoteToEditable(quoteID)) : dispatch(setQuoteToUnEditable(quoteID));

	};

	const [, updateState] = useState();
	const useForceUpdate = useCallback(() => updateState({}), []);

	const forceRerenderFunction = () => {
		console.log("force render called!");
		ROOT.loading();
		loadQuoteWithID();
		updateState({});
	};


	// function useForceUpdate(){
	// 	let getMe = renderCountGet;

	// 	return () => setRenderCount(getMe() + 1); // update the state to force render
	// };

	const hasValidPropertyAddress = quote ? useHasValidPropertyAddress(quote) : false;

	window.Q = quote;

	const { ROOT } = props;


	function showPlaceOrder() {
		const has_signature_series_fabric = quote.shades.some(
			(s) =>
				s.val("fabric_name").toLowerCase().includes("signature")
				|| s.val("fabric2_name").toLowerCase().includes("signature")
		);

		const isMotorized = (shade) =>
			shade.getOption("shade_type").value === "motorized";

		const getMotorAndTube = function (shade) {
			const tube = shade.getOption("tube_sku").value;

			const motor = shade.getOption("motor_type").value;

			return [motor, tube];
		};

		const hasDifferentSpeeds = ((quote) => {
			let outerMotor = null;
			let outerTube = null;

			const shadesToCheck = quote.shades.filter(
				(s) => s.getOption("indoor_outdoor").value === "indoor"
			);

			for (const shadeIndex in shadesToCheck) {
				const shade = quote.shades[shadeIndex];

				if (!isMotorized(shade)) continue;

				const [motor, tube] = getMotorAndTube(shade);

				outerMotor = outerMotor === null ? motor : outerMotor;
				outerTube = outerTube === null ? tube : outerTube;

				if (outerMotor !== motor || outerTube !== tube) {
					return true;
				}
			}

			return false;
		})(quote);

		const isShadeZMC = (shade) =>
			shade.getOption("header_type").value === "OR_5678"
			|| shade.getOption("header_type").value.startsWith("RS-");

		const hasDifferentLooks = ((quote) => {
			let isZMC = null;
			const differentLook = false;

			for (const shadeIndex in quote.shades) {
				const shade = quote.shades[shadeIndex];

				if (!isMotorized(shade)) continue;

				if (isZMC === null) {
					isZMC = isShadeZMC(shade);
				} else if (isShadeZMC(shade) !== isZMC) {
					return true;
				}
			}
			return false;
		})(quote);

		// Put a put false in here to disable until we want to activate it.
		if (hasDifferentSpeeds || hasDifferentLooks) {
			const beginning = hasDifferentLooks ? "Also, s" : "S";

			const middle = !hasDifferentSpeeds && hasDifferentLooks
				? "look"
				: hasDifferentSpeeds && hasDifferentLooks
					? "look and act"
					: "act";

			ROOT.alert({
				style: { width: "600px" },
				text: (
					<span>
						<b>
							Some of your shades will
							{' '}
							{middle}
							{' '}
							differently
						</b>
						<br />
						<br />
						{hasDifferentLooks ? (
							<>
								Some of your shade headers have a different look, aside
								from possible different colors, due to hardware
								constraints.
								<br />
								<br />
							</>
						) : null}
						{hasDifferentSpeeds ? (
							<>
								{beginning}
								ome of your shades will move at different
								speeds due to size constraints.
								<br />
								<br />
							</>
						) : null}
						If this is an issue please call the PowerShades Sales team at
						417-553-0504 before placing the order.
						<br />
						<br />
						Thanks!
					</span>
				),
				title: "Differing Shades",
				noCancelButton: true,
				acceptButtonText: "OK",
			})
				.then(() => setShowingPlaceOrder(true))
				.catch(() => { });
		} else if (has_signature_series_fabric) {
			ROOT.alert({
				style: { width: "600px" },
				text: (
					<span>
						We are upgrading our Signature Series fabric line.
						<br />
						<br />
						<b>Signature Series</b>
						<ul
							style={{
								maxHeight: "210px",
								overflowY: "auto",
								fontSize: "14px",
								paddingLeft: "30px",
							}}
						>
							<li>
								Beige/Gray
								{" "}
								<i className="fas fa-long-arrow-alt-right" />
								{' '}
								Screen
								Essential Beige Pearl Gray
							</li>
							<li>
								Black/Black
								{" "}
								<i className="fas fa-long-arrow-alt-right" />
								{' '}
								Screen
								Essential Ebony
							</li>
							<li>
								Black/Dark Gray
								{" "}
								<i className="fas fa-long-arrow-alt-right" />
								{' '}
								Screen
								Essential Charcoal Iron Gray
							</li>
							<li>
								Black/Gray
								{" "}
								<i className="fas fa-long-arrow-alt-right" />
								{' '}
								Screen
								Essential Charcoal Iron Gray
							</li>
							<li>
								Bone/Bone
								{" "}
								<i className="fas fa-long-arrow-alt-right" />
								{' '}
								Screen
								Essential Gray Sand
							</li>
							<li>
								Charcoal
								{' '}
								<i className="fas fa-long-arrow-alt-right" />
								{" "}
								Screen Essential Charcoal Iron Gray
							</li>
							<li>
								Charcoal/Bronze
								{" "}
								<i className="fas fa-long-arrow-alt-right" />
								{' '}
								Screen
								Essential Charcoal Dark Bronze
							</li>
							<li>
								Gray/Gray
								{" "}
								<i className="fas fa-long-arrow-alt-right" />
								{' '}
								Screen
								Essential Soft Gray
							</li>
							<li>
								White/Linen
								{" "}
								<i className="fas fa-long-arrow-alt-right" />
								{' '}
								Screen
								Essential Chalk Beige Cream
							</li>
							<li>
								White/Gray
								{" "}
								<i className="fas fa-long-arrow-alt-right" />
								{' '}
								Screen
								Essential Chalk Soft Gray
							</li>
							<li>
								White
								{' '}
								<i className="fas fa-long-arrow-alt-right" />
								{" "}
								Screen Essential Chalk
							</li>
						</ul>
						<br />
						<br />
						<a
							href="https://powershades-dealer-docs.s3-us-west-2.amazonaws.com/Coulisse+Screen+Essential+(coulisse-screen-card-essential-us).pdf"
							target="_blank"
							rel="noreferrer"
						>
							Screen Essentials Digital Fabric Book
						</a>
						<br />
						<br />
						Please contact the PowerShades Sales team with any questions.
					</span>
				),
				title: "Fabric Upgrades",
				noCancelButton: true,
				acceptButtonText: "OK",
			})
				.then(() => setShowingPlaceOrder(true))
				.catch(() => { });
		} else {
			setShowingPlaceOrder(true);
		}
	}

	async function attemptPlaceOrder() {
		const has_missing_msrps = quote.pricing
			.getPricingData()
			.shade_assemblies.some((sa) => parseFloat(sa.total_msrp) < 1);

		const has_shade_errors = quote.shades.some(
			(shade) => shade.getErrors().length
		);

		const hasShadeError = has_missing_msrps || (has_shade_errors > 0 && !quote.is_order);

		if (hasShadeError) {
			ROOT.alert({
				icon: "error",
				text: "Please click Edit Quote and address all shade errors before placing an order.",
				title: "Shade Errors",
				quickFormat: "error"
			})
				.then(() => { })
				.catch(() => { });
		} else if (!hasValidPropertyAddress) {
			ROOT.alert({
				icon: "error",
				text: "Please enter a valid property address before placing an order.",
				title: "Missing Property Address",
				quickFormat: "error"

			})
				.then(() => { })
				.catch(() => { });
		} else if (quote.getFabricSamplesQuantity() > MAX_SAMPLE_QUANTITY) {
			ROOT.alert({
				text:
					`Please limit fabric samples order quantity to ${MAX_SAMPLE_QUANTITY
					} or less.`,
				title: "Fabric Samples Limit",
				oneButton: true,
				icon: "warning"

			})
				.then(() => { })
				.catch(() => { });
		} else {
			const property_name = quote.property_address.company_name;

			const { address_snapshot } = (
				await api.getQuoteAddressSnapshot(property_name, quote.dealer_id)
			).data;

			if (address_snapshot != null) {
				ROOT.alert({
					title: "Existing Property Name",
					icon: "warning",
					text: isDealer ? (
						<span>
							There is already an installation address with the property
							name "
							{property_name}
							".
							<br />
							<br />
							Continuing with this property name will add shades to the
							existing property in the dashboard.
							<span
								style={{ display: "block", marginBottom: "10px" }}
							/>
						</span>
					) : (
						<span>
							There is already an installation address with the property
							name "
							{property_name}
							" for this dealer.
							<br />
							<br />
							Continuing with this property name will add shades to the
							existing property in the dashboard.
							<span
								style={{ display: "block", marginBottom: "10px" }}
							/>
						</span>
					),
					cancelButtonText: "Edit Property Name",
					acceptButtonText: "Continue",
					quoteType
				})
					.then(showPlaceOrder)
					.catch(() => setShowingPropertyAddressEditor(true));
			} else {
				showPlaceOrder();
			}
		}
	}

	/**
	 * Waits until a certain status check is true or until a maximum number of checks is reached.
	 * @param {function} statusCheck - A function that returns a boolean indicating 
	 * whether the desired status has been reached.
	 * @param {string} errorMsg - An error message to be thrown if the 
	 * maximum number of checks is reached before the desired status is reached.
	 * @returns {Promise} - A promise that resolves when the desired 
	 * status is reached or rejects with an error message if the maximum number of checks is reached.
	 * @notes Max checks is set to 15 - so max amount of waiting will be 15 seconds.
	 */
	const waitUntilLoadStatus = (statusCheck, errorMsg) => new Promise((resolve, reject) => {
		let checkCount = 0;
		const maxChecks = 15;

		const checkInterval = setInterval(() => {
			const status = statusCheck();
			if (status) {
				clearInterval(checkInterval);
				resolve();
			} else if (++checkCount >= maxChecks) {
				clearInterval(checkInterval);
				reject(new Error(errorMsg));
			}
		}, 1000); // check every second
	});

	const loadQuoteWithID = async (readOnlyMode = false) => {
		const load_quote_id = parseInt(quoteID);

		props.loading();
		dispatch(reloadQuote(load_quote_id));

		if (!newQuote) {
			setNewQuote(new QuoteObject(_, forceRerenderFunction, store, quoteID))
			return;
		};

		await Promise.all([
			// Wait until the quote types have finished loading
			waitUntilLoadStatus(() => store.getState().entity.quotes.types.loadStatus > 3, 'Timeout waiting for quoteTypeLoadStatus'),
			// Wait until the user data has finished loading
			waitUntilLoadStatus(() => store.getState().user.loading === false, 'Timeout waiting for userDataLoadStatus')
		]).then(() => {
			// Get the quote types from the store and set them on the quote
			const localQuoteTypes = store.getState().entity.quotes.types.list;
			newQuote.setQuoteTypes(localQuoteTypes);
			newQuote.ensureStore(store);

			// Get the user data from the store and set it on the quote
			const localUserData = store.getState().user;
			newQuote.setUser(localUserData);
			setUserData(localUserData);

			updateState({});
		}).catch((err) => {
			console.error(err);
		});

		api.onLoad.add(() => {
			// const usery = userData;

			api.loadQuoteTwo(load_quote_id).then((resp) => {
				ROOT.loading();
				if (resp.data.error) {
					// need to add an error thingy
				} else {
					const liCount = (resp.data.shadeAssemblies.Items?.length ?? 0) + (resp.data.accessories.Items?.length ?? 0) + (resp.data.hwParts.length ?? 0)
					newQuote.loadData(resp.data, () => {
						const initial = props.ROOT.GET.initial === "true";
						const editableHere = initial && liCount == 0;
						newQuote.editable = editableHere;
						setQuoteReady(true);
						newQuote.initialPage(initial, setEditable);
						newQuote.setROOT(props.ROOT);
						// newQuote.setUser(usery);
						newQuote.setRenderPage(useForceUpdate);
						newQuote.setEditScreen(setShowEditQuoteDisclaimer);
						newQuote.checkExpiration();
						newQuote.rerender();
						store.dispatch(wentToQuote(load_quote_id));
						store.dispatch(RefreshQuotePricing(load_quote_id));
						store.dispatch(loadQuoteAssemblies({
							quoteId: load_quote_id,
							isAdd: false
						})).then(() => {
							setQuoteLoaded(true);
						});
						setEditable(editableHere);
					});

					Sentry.setTag({ "Quote ID": resp.data.Quote.ID });
					if (resp.data.Quote.quote_type_id === 6) {
						api.getDemoReview(load_quote_id).then((resp) => {
							if (resp.status !== 200 || !resp?.data || !resp?.data?.demoReviews) {
								// ! If this call fails the quote can still be loaded and edited, 
								// ! but the demo will not be placeable.
								setDemoReviewFailed(true);
								throw new Error('Something went wrong retrieving demo review');
							}

							const { data } = resp;

							const demoReview = data.demoReviews
								? data.demoReviews.find((d) => true)
								: null;

							newQuote.SetDemoInfo(demoReview);
						});
					}

					// api.getDealersByTerritory(newQuote.territoryId).then(
					// 	(resp) => {
					// 		const dealersData = resp.data.dealers;

					// 		setDealers(dealersData);
					// 	}
					// );

					if (
						newQuote.Data.quote?.updated < "2021-04-01 13:31:33"
						|| true
					) {
						// const errors = newQuote.QuoteSubstitution.shades.reduce(
						// 	(prev, shade) => [
						// 		...prev,
						// 		...shade.getErrors().map((err) => ({
						// 			sequenceId: shade.sequenceId,
						// 			error: err,
						// 		})),
						// 	],
						// 	[]
						// );

						// api.checkAndSaveErrors(newQuote.Id, errors).then(
						// 	(resp) => {
						// 		const { shades } = newQuote.QuoteSubstitution;

						// 		const errorMessages = resp?.data?.errorMessages ?? [];

						// 		errorMessages.forEach((em) => {
						// 			const shade = shades.find(
						// 				(s) => s.sequenceId == em.sequenceId
						// 			);

						// 			if (!shade?.data()) {
						// 				Sentry.captureException(
						// 					"Error message returned for shade that does not exist",
						// 					{
						// 						tags: {
						// 							section: "quote"
						// 						},
						// 						extra: {
						// 							quoteId: newQuote.Id,
						// 							sequenceId: em.sequenceId,
						// 							errorMessage: em.message,
						// 							shade
						// 						}
						// 					}
						// 				)
						// 				return;
						// 			}
						// 			const data = shade.data();

						// 			const errorMessage = shade
						// 				.getErrors()
						// 				.reduce(
						// 					(errs, err) => `${errs},${err}`,
						// 					em.message
						// 				);

						// 			function isEmptyOrSpaces(str) {
						// 				return (
						// 					str === null || str.match(/^ *$/) !== null
						// 				);
						// 			}

						// 			data.error_messages = isEmptyOrSpaces(
						// 				data.error_messages
						// 			)
						// 				? errorMessage
						// 				: `${data.error_messages},${errorMessage}`;
						// 		});
						// 	}
						// );
					}

					setCurrentDealer(selectedDealer);

					api.getOrderStatus(load_quote_id).then((resp) => {
						if (isFailedApiCall(resp)) throw resp.error;
						const { quote_status } = resp.data;

						newQuote.loadShippingStatus(quote_status);
						newQuote.setROOT(ROOT);
						newQuote.checkExpiration();
						newQuote.rerender();

						setTimeout(() => {
							newQuote.checkExpiration();
						}, 2000);
					}).catch((err) => {
						console.error({ err });
						throw new Error('Something went wrong retrieving order status. Please check the console for more details.');
					});
				}
			}).then(() => {
				if (quoteReady) ROOT.loaded();
			});

			api.currencyData().then((data) => {
				setCurrencyData(data);
			});
		});
	}

	useEffect(() => {
		if (quoteReady) {
			newQuote.setROOT(ROOT);
			newQuote.checkExpiration();
			store.dispatch(wentToQuote(quoteID));
			ROOT.loaded();
		}
	}, [quoteReady]);

	const keydownHandler = (e) => {
		if ((e.key === 'r' || e.key === 'R') && e.ctrlKey && e.altKey && editable) {
			const erroredAssemblies = selectAssemblyListByQuoteId(quoteID)(store.getState()).filter((a) => a.msrp === 0);
			const erroredAssemblySequenceIds = erroredAssemblies.map((a) => a.sequence_id);
			dispatch(setAssembliesUnlocked({
				quoteId: quoteID,
				unlocked: true,
				sequenceIds: erroredAssemblySequenceIds
			}))
			dispatch(repriceAssemblies({
				quoteId: quoteID,
				sequenceIds: erroredAssemblySequenceIds
			}));
		}
	};
	useEffect(() => {
		document.addEventListener('keydown', keydownHandler);
		return () => {
			document.removeEventListener('keydown', keydownHandler);
		}
	}, []);

	const _accLis = selectAccessories(quoteID)(store.getState())

	const initQuote = () => {
		const storeState = store.getState();

		const quoteTypes = storeState.entity.quotes.types.list;

		newQuote.setQuoteTypes(quoteTypes);
		if (quoteID) {
			const read_only_mode = true;

			loadQuoteWithID(read_only_mode);

			props.setTitle('Loading Quote...');
		} else {
			props.setTitle("Get a Quote");
		}
	};

	useEffect(() => {
		newQuote?.unsubscribe && newQuote.unsubscribe();
		setQuoteLoaded(false);
		setQuoteReady(false);
		setNewQuote(new QuoteObject(_, forceRerenderFunction, store, quoteID))
		return () => () => {
			newQuote?.unsubscribe && newQuote.unsubscribe();
		};
	}, [quoteID]);

	useEffect(() => {
		if (newQuote) {
			initQuote();
		}
	}, [newQuote]);

	// if (!isLoggedIn && userData) {
	// 	props.ROOT.loadContent("#Login");

	// 	return null;
	// }

	if (quoteReady) {
		newQuote.setROOT(props.ROOT);
	}

	function showAddShade() {
		setShowProductForm(true);

		document.body.classList.add("editing");
	}

	function closeAddShade() {
		setShowProductForm(false);

		document.body.classList.remove("editing");
	}

	function unlockAndEditQuote() {
		quote.unlock(false);
		setShowingPricingLocked(false);
	}

	function generateInstallerCopy() { }

	const quoteStoreType = useQuoteTypeNameByQuoteId(quoteID);
	const quoteType = getQuoteType(newQuote);

	if ((quoteType !== quoteStoreType) || !quoteLoaded || dealers === false || territories === false || newQuote.Data.quote === undefined || multipliers.length === 0) {
		return null;
	}

	const columns = {};

	const pricingAs = ""; // quote.pricing_as;

	const shades = []; // quote.shades;
	const selectedShades = []; // quote.getSelectedShades();

	const shadeItems = {
		Shades: shades,
		SelectedShades: selectedShades,
	};

	const invoiceProps = {
		showShadeSummary: (shade) => setSummaryShade(shade),
		editShade: (shade) => setEditingShade(shade),
		columns,
		shadeItems,
		QuoteNew: newQuote,
	};

	// const invoice = React.createElement(InvoiceTableComponent, invoiceProps);
	const invoice = (
		<InvoiceTable
			quoteId={quoteID}
			quoteNew={newQuote}
			unlockBySequenceId={setShowEditQuoteDisclaimer}
			fcState={fcState}
		/>
	)

	let quote = newQuote.QuoteSubstitution;

	const newQuoteMSRP = isPurchaser ? newQuote.buyMsrp() : newQuote.totalMsrp();


	newQuote.ensureStore(store);




	return (
		<>
			{showingPopulateDashboardModal ? (
				<PopulateDashboardModal
					quoteType={quoteType}
					quoteID={quoteID}
					ROOT={props.ROOT}
					onCancel={() => setShowingPopulateDashboardModal(false)}
				/>
			) : null}

			<div
				style={{ zIndex: 29 }}
				className={`local-bootstrap  no-print quote ${newQuote.editable ? "" : "no-edit"}`}
			>
				{quote.is_order & newQuote.editable ? (
					<div id="pending-order-title">Pending Order</div>
				) : null}

				<div id="quote-head-wrapper">
					<div id="quote-options-holder">
						<Container fluid className="local-bootstrap">
							<Row>
								<Col>
									<QuoteFields
										newQuote={newQuote}
										ROOT={props.ROOT}
										quote={quote}
										editPropertyAddress={() =>
											setShowingPropertyAddressEditor(true)}
										editShippingAddress={() =>
											setShowingShippingAddressEditor(true)}
										onChange={newQuote.Data.QuoteFieldChange}
										territories={territories}
										editable={editable}
										dealers={dealers}
										userData={userData}
										quoteId={quoteID}
									/>
								</Col>

								{isPsAdmin ? (
									<Col
										xs={12}
										md={12}
										lg={2}
										className="text-end"
										style={{ marginTop: "1em" }}
									>
										<QuoteAdminBar
											showHistory={() =>
												setShowingHistoryModal(true)}
											showFabricAllowedIndoor={() => {
												//console.log("Allow Disc. Fabrics Indoor clicked");
												setShowingFabricAllowedIndoorModal(true)
											}}
											showFabricAllowedOutdoor={() => {
												//console.log("Allow Disc. Fabrics Outdoor clicked");
												setShowingFabricAllowedOutdoorModal(true)
											}}
											showCostBreakdown={() =>
												setShowingCostBreakdown(true)}
											showPopulateDashboardTasks={() =>
												setShowingPopulateDashboardTasks(true)}
											setIsAddingCOROutdoorShade={() => setShowAddCOROutdoorShade(true)}
											newQuote={newQuote}
											forceRerenderFunction={forceRerenderFunction}
										/>
									</Col>
								) : null}
							</Row>
						</Container>
					</div>
				</div>

				<QuoteActionsNew
					key={`${quote.ID}quoteactionsnew`}
					editable={editable}
					setShowConfigurations={setShowConfigurations}
					newQuote={newQuote}
					quote={quote}
					quoteTypes={newQuote.quoteTypes}
					placeOrder={() => attemptPlaceOrder()}
					showExportModal={() => setShowExportModal(true)}
					showApproveOrder={() => setShowingApproveModal(true)}
					showForceEditModal={() => setShowForceEditModal(true)}
					editQuote={(callback) => newQuote.unlock(callback)}
					downloadTheInstalerCopy={(quote, user) =>
						downloadInstallerCopy(quote, user)}
					setEditable={setEditable}
					showEditQuoteDisclaimer={showEditQuoteDisclaimer}
					setShowEditQuoteDisclaimer={setShowEditQuoteDisclaimer}
					lockAllShades={newQuote.Data.lockAllShades}
					userData={userData}
					ROOT={props.ROOT}
					dealers={dealers}
					territories={territories}
					currentDealer={currentDealer}
					demoReviewFailed={demoReviewFailed}
					needsVerification={needsVerification}
					quoteId={quoteID}
					fcState={fcState}
				/>

				<div style={{ zIndex: 20 }} id="invoice-entries">
					{invoice}
				</div>
				<div
					className="button-holder"
					style={{
						justifyContent: "center",
						display: "flex",
						marginTop: "0",
					}}
				>
					{!newQuote.editable ? null : (
						<>
							<Popup
								open={showProductForm}
								className="mobile-modal"
								modal
								nested
								onClose={() => setShowProductForm(false)}
							>
								{(close) => (
									<div>
										<div id="product-editor">
											<div className="product-form no-additional-background">
													<ProductForm
														quoteType={quoteType}
														newQuote={newQuote}
														quote={quote}
														onCancel={closeAddShade}
														onAdd={closeAddShade}
														close={close}
														openAddPsOutdoorShade={() => {
															setShowAddPSOutdoorShade(true);
															setShowProductForm(false);
														}}
														indoorFabrics={indoorFabrics}
														outdoorFabrics={psOutdoorFabrics}
														quoteId={quoteID}
													/>
											</div>
										</div>
									</div>
								)}
							</Popup>
							<Button
								onClick={() => setShowProductForm(true)}
								color={quoteType ?? 'green'}
								style={{
									fontSize: "17px",
									maxWidth: "unset",
									marginLeft: "20px",
								}}
								icon="fas fa-plus"
							>
								<FontAwesomeIcon icon={faPlus} className="choice-icon" />
								{" Shade / Accessories"}
							</Button>
						</>
					)}
				</div>

				<Container className="local-bootstrap">
					<Row style={{ marginRight: "1em" }}>
						<Col xs={12} md={5} style={{ marginLeft: "1em" }}>
							<TextArea
								key={`notes-${quote.ID}`}
								label="Notes / Special Instructions"
								wrapperStyle={{ alignSelf: "center" }}
								style={{
									resize: "vertical",
									width: "80%",
									height: "10em",
								}}
								quoteId={newQuote.Id}
								disabled={
									isManufacturer
									|| !newQuote.editable & !quote.read_only_mode
								}
								defaultValue={quote.notes}
								onChange={(new_value) => {
									changeNotes(new_value)
								}}
								maxLength={20000}
							/>
						</Col>
						<Col
							xs={12}
							md={6}
							style={{ marginTop: "1em" }}
						>
							{isManufacturer ? null : needsVerification ? (
								<QuoteVerifications
									quoteType={quoteType}
									setShowConfigurations={setShowConfigurations}
									showConfigurations={showConfigurations}
									editable={editable}
									newQuote={newQuote}
									setShowEditQuoteDisclaimer={
										setShowEditQuoteDisclaimer
									}
									quoteId={quoteID}
								/>
							) : (
								<TotalPrice
									key={`totals-${quote.ID}`}
									quoteId={quote.ID}
								/>
							)}
						</Col>
					</Row>
				</Container>
				<AddPSOutdoorShadeModel
					quoteId={quote.ID}
					onClose={() => setShowAddPSOutdoorShade(false)}
					isOpen={showAddPSOutdoorShade}
				/>
				<AddCOROutdoorShadeModal
					quoteId={quote.ID}
					onClose={() => setShowAddCOROutdoorShade(false)}
					isOpen={showAddCOROutdoorShade}

				/>
				{showExportModal ? (
					<ExportModal
						quote={quote}
						close={() => setShowExportModal(false)}
					/>
				) : null}

				{editingShade ? (
					<div id="product-editor-wrapper">
						<ShadeEditor
							quote={quote}
							shade={editingShade}
							onClose={() => setEditingShade(null)}
						/>
					</div>
				) : null}

				{showingApproveModal ? (
					<div>
						<ConfirmApproval
							quote={quote}
							preLoadedQuote
							onComplete={props.ROOT.refreshContent}
							close={() => setShowingApproveModal(false)}
						/>
					</div>
				) : null}
			</div>

			{showingPlaceOrder ? (
				<PlaceOrder
					quoteType={quoteType}
					ROOT={props.ROOT}
					quote={quote}
					done={props.ROOT.refreshContent}
					close={() => setShowingPlaceOrder(false)}
				/>
			) : null}

			{showOrderThankYou ? (
				<OrderThankYou
					quote={quote}
					close={() => setShowOrderThankYou(false)}
				/>
			) : null}

			{summaryShade ? (
				<ShadeSummaryModal
					shade={summaryShade.savableObject()}
					quote={quote}
					onClose={() => setSummaryShade(null)}
				/>
			) : null}

			{showForceEditModal ? (
				<ForceEditModal
					quote={quote}
					close={() => setShowForceEditModal(false)}
				/>
			) : null}

			{/* <PrintQuote
				quote={quote}
				newQuote={newQuote}
				isPrint
				currencyData={userCurrencyData}
			/> */}

			{quoteLockedUser != null ? (
				<QuoteLockedModal
					viewingUser={quoteLockedUser}
					quoteID={quoteID}
					readOnlyMode={() => {
						loadQuoteWithID(true);
						setQuoteLockedUser(null);
					}}
					cancel={() => props.ROOT.loadContent("#Home")}
				/>
			) : null}

			{showingHistoryModal && isPsAdmin ? (
				<QuoteHistoryModal
					newQuote={newQuote}
					quote={newQuote.Id}
					onClose={() => setShowingHistoryModal(false)}
				/>
			) : null}

			{ShowingFabricAllowedIndoorModal && isPsAdmin ? (
				//console.log("FabricAllowedModal Indoor is rendering"),
				<FabricAllowedModalIndoor
					newQuote={newQuote}
					quote={newQuote.Id}
					onClose={() => setShowingFabricAllowedIndoorModal(false)}
				/>
			) : null}


			{ShowingFabricAllowedOutdoorModal && isPsAdmin ? (
				//console.log("FabricAllowedModal Outdoor is rendering"),
				<FabricAllowedModalOutdoor
					newQuote={newQuote}
					quote={newQuote.Id}
					onClose={() => setShowingFabricAllowedOutdoorModal(false)}
				/>
			) : null}



			{showingPopulateDashboardTasks && isPsAdmin ? (
				<PopulateDashboardTasks
					quoteType={quoteType}
					quote={quote}
					onClose={() => setShowingPopulateDashboardTasks(false)}
					showPopulateDashboardModal={() =>
						setShowingPopulateDashboardModal(true)}
					ROOT={ROOT}
				/>
			) : null}

			{showingCostBreakdown && isPsAdmin ? (
				<CostBreakownModal
					newQuote={newQuote}
					quote={quote}
					showShadeBreakdown={(shade) => setCostBreakdownShade(shade)}
					onClose={() => setShowingCostBreakdown(false)}
				/>
			) : null}

			{costBreakdownShade && isPsAdmin ? (
				<ShadeCostBreakdown
					quote={quote}
					shade={costBreakdownShade}
					onClose={() => setCostBreakdownShade(null)}
				/>
			) : null}

			{showingPropertyAddressEditor ? (
				<AddressModal
					quoteType={quoteType}
					setAddress={(address) => newQuote.setPropertyAddress(address)}
					onDone={() => setShowingPropertyAddressEditor(false)}
					addressLabel="Installation Address"
					address={quote.property_address}
					quote={quote}
					getAddressesFunction="getMyPropertyAddresses"
					isPropertyAddress
				/>
			) : null}

			{showingShippingAddressEditor ? (
				<AddressModal
					quoteType={quoteType}
					setAddress={(address) => newQuote.setShippingAddress(address)}
					onDone={() => setShowingShippingAddressEditor(false)}
					addressLabel="Shipping Address"
					address={quote.shipping_address}
					quote={quote}
					getAddressesFunction="getMyShippingAddresses"
					ROOT={props.ROOT}
				/>
			) : null}
		</>
	);
};

export default Quote;
