import { AssemblyStore, ExternalShadeData, IndoorRollDirection, IndoorShadeOptionsSingleDict } from "../types";
import StandardRoll from "../../../../assets/img/illus/standard_roll.png";
import ReverseRoll from "../../../../assets/img/illus/reverse_roll.png";

const IndoorShadeOptionsSingle: (assembly: AssemblyStore, setAssembly: (assembly: AssemblyStore) => void, externalData: ExternalShadeData) =>  IndoorShadeOptionsSingleDict = (assembly, setAssembly, externalData) => {

    const indoorFabrics = externalData.fabrics;
    const headers = externalData.assemblyOptions.headers;

    const fabOptions = indoorFabrics.map(fab => ({ label: fab.name, value: fab.name, title: fab.name}));  

    const setFabric = (newFabricName) => {
        setAssembly({
            ...assembly,
            shades: assembly.shades.map(s => ({
                ...s,
                fabric_name: newFabricName
            }))
        })
    }

    const setRollDirection = (newRollDirection) => {
        setAssembly({
            ...assembly,
            shades: assembly.shades.map(s => ({
                ...s,
                roll_direction: newRollDirection
            }))
        })
    }

    const currentHeader = headers?.find(header => header.name === assembly.header_line);

    const isStandardRollAllowed = currentHeader?.single_standard_allowed ?? true;
    const isReverseRollAllowed = currentHeader?.single_reverse_allowed ?? true;

    const rollDirectionOptions = [
        { label: "Standard", value: "standard" as IndoorRollDirection, title: "Standard", isActive: isStandardRollAllowed },
        { label: "Reverse", value: "reverse" as IndoorRollDirection, title: "Reverse", isActive: isReverseRollAllowed }
    ];

    const indoorShadeOptionsSingle :IndoorShadeOptionsSingleDict = {
        Fabric: {
            label: "Fabric",
            options: fabOptions,
            clearValue: () => {
                setFabric("");
            },
            setValue: (value) => {
                setFabric(value);
            },
            getSelectedOption: () => {
                return fabOptions.find(fab => fab.value === assembly.shades[0]?.fabric_name) ?? { label: "", value: "", title: "" };
            },
            varName: "fabric_name",
            error: [],
            info: [],
            getValue: () => assembly.shades[0]?.fabric_name ?? "",
        },
        RollDirection: {
            label: "Roll Direction",
            options: rollDirectionOptions,
            clearValue: () => {
                setRollDirection("");
            },
            setValue: (value) => {
                setRollDirection(value);
            },
            getSelectedOption: () => {
                return rollDirectionOptions.find(roll => roll.value === assembly.shades[0]?.roll_direction) ?? { label: "", value: "" as IndoorRollDirection, title: "" };
            },
            varName: "roll_direction",
            error: [],
            info: [],
            getValue: () => (assembly.shades[0]?.roll_direction ?? "") as IndoorRollDirection,
            valuePopup: assembly.shades[0]?.roll_direction === "standard" ? StandardRoll : ReverseRoll
        }
    };

    return indoorShadeOptionsSingle;
};

export default IndoorShadeOptionsSingle;