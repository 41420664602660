import * as React from 'react';
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../Parts/Button";
import EditAccessoryPopup from "../NewItems/EditAccessoryPopup";

type AccessoryActionsProps = {
	quoteId: number;
	lineNumber: number;
	setAccessoryQuantity: (lineNumber: number, quantity: number) => void;
	legacyReprice: () => void;
}

const AccessoryAction = ({ quoteId, lineNumber, setAccessoryQuantity, legacyReprice }: AccessoryActionsProps) => (
	<td>
		<Button
			title="Delete"
			color="light"
			size="sm"
			fullWidth
			onClick={() => {
				setAccessoryQuantity(lineNumber, 0);
			}}
		>
			<FontAwesomeIcon icon={faTrashAlt} />
		</Button>
		<EditAccessoryPopup key={`acc-${lineNumber}-${quoteId}`} lineNumber={lineNumber} quoteId={quoteId} legacyReprice={legacyReprice} />
	</td>
);

export default AccessoryAction;
