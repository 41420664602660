import React, { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import type { Option } from 'react-bootstrap-typeahead/types/types';

import { useTerritories, useTerritoriesLoadStatus } from '../Store/entities/hooks';
import type { TerritorySelectProps } from './types';
import { TerritoryStore } from '../Store/entities/entitiesType';

const TerritorySelect = ({
	multiple = false,
	selectedTerritoryIds = [],
	optionsOverride,
	handleChange,
	handleMultiChange,
	disableOverride = false,
	placeholderText = 'Select a Territory...'
}: TerritorySelectProps) => {
	const [territorySelectOpen, setTerritorySelectOpen] = useState(false);
	const territories = useTerritories();
	const territoriesLoadStatus = useTerritoriesLoadStatus();
	const territoriesLoading = territoriesLoadStatus <= 3;

	const handleTerritoryChange = (selected: Option[]) => {
		console.log({ selected });
		if (!selected) return;
		if (multiple && handleMultiChange) {
			const selectedTerritories = selected as unknown as TerritoryStore[];
			handleMultiChange(selectedTerritories.map((t) => t.id));
			return;
		}
		if (!multiple && handleChange) {
			const selectedTerritory = selected as unknown as TerritoryStore;
			handleChange(selectedTerritory[0]?.id ?? 0);
			setTerritorySelectOpen(false);
			return;
		}
		throw new Error('TerritorySelect: handleChange or handleMultiChange not defined');
	};

	const shouldBeDisabled = territoriesLoading || disableOverride;
	const selectedTerritories = territories.filter((t) => selectedTerritoryIds.includes(t.id));

	return (
		<Typeahead
			id="territory-selector"
			clearButton
			paginate
			multiple={multiple}
			open={territorySelectOpen}
			positionFixed
			onFocus={() => setTerritorySelectOpen(true)}
			onBlur={() => setTerritorySelectOpen(false)}
			labelKey="name"
			selected={selectedTerritoryIds
				&& selectedTerritoryIds.length > 0
				? selectedTerritories
				: []}
			placeholder={territoriesLoading ? 'Loading Territories...' : placeholderText}
			options={optionsOverride ?? territories}
			isLoading={territoriesLoading}
			disabled={shouldBeDisabled}
			onChange={handleTerritoryChange}
			onInputChange={() => {
				if (!territorySelectOpen) setTerritorySelectOpen(true);
			}}
		/>
	);
};

export default TerritorySelect;
