import { useState } from 'react';
import { isFunction } from 'lodash';

export default function SelectField(props) {
	const { option } = props;
	const [value, setValue] = useState(option.value);

	function getChoice(val) {
		let choice = null;
		let choices = null;

		if (typeof option.choices === 'function') {
			if (option.shade) {
				choices = option.choices(option.shade);
			} else {
				choices = option.choices();
			}
		} else {
			choices = option.choices;
		}

		choices.forEach((c) => {
			if ((c.value && c.value == val) || (c.name == val)) {
				choice = c;
			}
		});
			
		return choice;
	}

	function changed(new_val) {
		const current_choice = getChoice(option.value);
		const new_choice = getChoice(new_val);

		current_choice && current_choice.onDeselect && current_choice.onDeselect(props.quote, option.shade);
		new_choice && new_choice.onSelect && new_choice.onSelect(props.quote, option.shade);
		
		option.value = new_val;

		// Update the state so the changes are reflected in the view.
		setValue(option.value);

		props.onChange();
	}

	const choices = [];
	let choice_arr = [];

	if (isFunction(option.choices)) {
		if (option.shade) {
			choice_arr = option.choices(option.shade);
		} else {
			choice_arr = option.choices();
		}
	} else {
		choice_arr = option.choices;
	}

	const found_choice = choice_arr.filter((choice) => !(choice.conditional != null && (!choice.conditional(option.shade)))).find((choice) => (choice.value != null ? choice.value : choice.name) == value);

	if (!found_choice && value != null && (`${value}`).length > 0) {
		const found_choice_2 = choice_arr.find((choice) => (choice.value != null ? choice.value : choice.name) == value);

		const o = found_choice_2 || option;

		choices.push(
			<option
				key={`old-val ${o.value}`}
				value={o.value}
			>
				{ o.name || o.value }
			</option>
		);
	}

	if (value == null
		|| (option.varName == 'fabric2_name' && option.value == '')) {
		choices.push(
			<option key="null-val-selector" value="">-</option>
		);
	}

	let choiceGroup = [];
	let choiceGroupName = '';

	choice_arr.filter((choice) => !(choice.conditional != null && (!choice.conditional(option.shade)))).forEach((choice) => {
		const is_disabled = choice.conditional != null && (!choice.conditional(option.shade));

		if ((choiceGroup != null & choice.optGroup == null) | choiceGroupName != choice.optGroup) {
			if (choiceGroup.length > 0) {
				choices.push(
					<optgroup key={`choice-group-${choiceGroupName}`} label={choiceGroupName}>
						{choiceGroup}
					</optgroup>
				);
			}

			choiceGroup = [];
		}

		if (choice.optGroup != null) {
			choiceGroupName = choice.optGroup;

			choiceGroup.push(
				<option key={(choice.id ? choice.id : choice.name)} value={(choice.value != null ? choice.value : choice.name)} disabled={is_disabled}>{choice.name}</option>
			);
		} else {
			choices.push(
				<option key={(choice.id ? choice.id : choice.name)} value={(choice.value != null ? choice.value : choice.name)} disabled={is_disabled}>{choice.name}</option>
			);
		}
	});
					
	if (choiceGroup.length > 0) {
		choices.push(
			<optgroup key={`choice-group-${choiceGroupName}`} label={choiceGroupName}>
				{ choiceGroup }
			</optgroup>
		);
	}

	return (
		
		<select
			disabled={props.disabled}
			onBlur={props.onBlur}
			onFocus={props.onFocus}
			onChange={(e) => changed(e.target.value)}
			value={value || ''}
		>
		
			{ choices }

		</select>
	);
}
