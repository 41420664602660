import React, { Dispatch, SetStateAction, useState } from "react";
import { Accordion } from "react-bootstrap";
import { RMAItems } from "./types";
import RMAListItem from "./RMAListItem";
import { useAddRma, useDeleteRma, useEditRma } from "../../../../Store/entities/rmas/hooks";
import { useAssembliesByQuoteId } from "../../../../Store/entities/assemblies/hooks";

type RMAListProps = {
	primaryRmas?: RMAItems[];
	rmas: RMAItems[];
	setRmas: Dispatch<SetStateAction<RMAItems[]>>;
	quoteId: number;
};

const RMAList = ({ primaryRmas, rmas, setRmas, quoteId }: RMAListProps) => {
	const [activeItems, setActiveItems] = useState<string[]>([]);
	const assemblies = useAssembliesByQuoteId(quoteId);
	
	const { addRma } = useAddRma();
	const { editRma } = useEditRma();
	const { deleteRma } = useDeleteRma();
	
	const activateItem = (item: string) => {
		setActiveItems(activeItems.includes(item)
			? activeItems.filter((i) => i !== item)
			: [...activeItems, item]);
	};

	const handleNewRma = async (rma: RMAItems) => {
		const newRma = await addRma(rma) as RMAItems;
		setRmas([...rmas, newRma as RMAItems]);
		setActiveItems([...activeItems, `RMA-${newRma.id}`].filter((i) => i !== "RMA-add"));
	};

	const handleEditRma = (updatedRma: RMAItems, index) => {
		editRma(updatedRma);
		const updatedRmas = [...rmas];
		updatedRmas.splice(index, 1, updatedRma);
		setRmas(updatedRmas);
	};

	const handleDeleteRma = (rmaId) => {
		const updatedRmas = [...rmas].filter((e) => e.id !== rmaId);
		setRmas(updatedRmas);
		deleteRma(rmaId);
	};

	return (
		<Accordion className="px-0 py-2" activeKey={activeItems.length ? activeItems : undefined}>
			<Accordion.Item eventKey='container'>
				<Accordion.Header onClick={() => activateItem("container")} className='rma'>
					{primaryRmas && "Sub "}
					RMA's
				</Accordion.Header>
				<Accordion.Body>
					{
						rmas.map((rma, index) =>
							<RMAListItem
								key={`RMA-${rma.id}`}
								rma={rma}
								primaryRmas={primaryRmas}
								assemblies={assemblies}
								quoteId={quoteId}
								activateItem={activateItem}
								saveRma={(updatedRma) => handleEditRma(updatedRma, index)}
								deleteRma={handleDeleteRma}
							/>)
					}
					<RMAListItem
						key="Rma-add"
						primaryRmas={primaryRmas}
						assemblies={assemblies}
						quoteId={quoteId}
						activateItem={activateItem}
						saveRma={handleNewRma}
					/>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	);
};

export default RMAList;
