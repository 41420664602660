import { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { Container, Row, Col, Table } from 'react-bootstrap';

import api from '../../PowerShadesAPI';
import { getQuoteType } from '../QuoteObjectExtensions.ts';
import Button from '../../Parts/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const QuoteWarningsHistory = (props) => {
	const { newQuote, close } = props;

	const quoteType = getQuoteType(newQuote);

	const [warningRows, setWarningRows] = useState([]);
	const [finishedLoading, setFinishedLoading] = useState(false);

	useEffect(() => {
		api.quoteWarningsHistory(newQuote.Id).then((resp) => {
			const warnings = resp.data.quoteWarnings;

			setWarningRows(warnings);

			setFinishedLoading(true);
		});
	}, [newQuote.Id]);

	const dateConverter = (date) => {
		const formattedDate = new Date(date);
		const newDate = new Date();
		newDate.setTime(formattedDate.getTime());
		return formattedDate.toLocaleString();
	};

	if (!finishedLoading) {
		return (
			<Container className="local-bootstrap" style={{ height: '30vh' }}>
				<Row className="mt-5">
					<Col className="text-center">
						<FontAwesomeIcon icon={faSpinner} className={`loading-wheel text-${quoteType}`} />
					</Col>
				</Row>
			</Container>
		);
	}
	return (
		<div className="quote-warning-history-modal">
			<Container className="local-bootstrap" fluid style={{ height: '30vh' }}>
				<Row>
					<Col>
						<h4 className={`text-${quoteType}`}>Quote Warnings History</h4>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table striped bordered hover responsive size="sm" className={quoteType}>
							<tr className="bold-row">
								<th>Date/Time</th>
								<th>Warning</th>
								<th>User Name</th>
								<th>User Email</th>
							</tr>
							<tbody>
								{warningRows.map((rowData) => {
									const {
										timestamp, code, message, user_name: userName, user_email: userEmail
									} = rowData;

									const strItems = message.split('\n');

									const display = (
										<>
											{strItems.map((s) =>
												(
													<>
														{s}
														<br />
													</>
												))}
										</>
									);

									return (
										<tr>
											<td>{dateConverter(timestamp)}</td>
											<Popup on="hover" trigger={<td>{code}</td>}>
												{display}
											</Popup>
											<td>{userName}</td>
											<td>{userEmail}</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</Col>
				</Row>
				<Row
					className="mb-2"
					style={{
						position: 'absolute', bottom: 0, width: '95%'
					}}
				>
					<Col className="text-end">
						<Button
							color={quoteType ?? 'green'}
							onClick={() => close()}
						>
							Close
						</Button>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default QuoteWarningsHistory;
