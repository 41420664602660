import Button from '../../Parts/Button';
import ShadeForm from './ShadeForm';

const ProductEditor = ({
	quote, shade, onClose
}) => {
	const done = () => {
		shade.clearTemporaryCalculationErrors();
		onClose && onClose();
	};

	return (
		<div id="product-editor">
			<div className="product-form">
				<h2>Edit Shade</h2>

				<ShadeForm onOptionBlur={quote.shadesUpdated} shade={shade} quote={quote} />

				<div className="button-holder">
					<Button color="green" onClick={done}>
						Done
					</Button>
				</div>
			</div>
		</div>
	);
};

export default ProductEditor;
