import React, { useState } from 'react';

const TextInput = (props) => {
	const [value, setValue] = useState(props.defaultValue || null);

	function valueChanged(new_value) {
		setValue(new_value);
		props.onChange && props.onChange(new_value);
	}

	const filtered_props = { ...props };

	['wrapperStyle', 'labelStyle', 'defaultValue']
		.forEach((key) => delete filtered_props[key]);
		
	return (

		<div style={{ position: 'relative', ...props.wrapperStyle }}>

			{
				props.label

					? (
						<>
							<label htmlFor={props.label} style={props.labelStyle}>
								{props.label}
							</label>
							<br />
						</>
					)
					: null
			}

			<input
				className={props.bootstrap ? "form-control" : null}
				type="text"
				name={props.label}
				{...filtered_props}
				value={value || ''}
				onChange={(e) => valueChanged(e.target.value)}
			/>
			
			{
				props.required != undefined

					? (
						<span style={{
							display: 'inline-block', position: 'absolute', color: 'red', top: '2px', right: '0'
						}}
						>
							*
						</span>
					)
					: null
			}
		</div>
	);
};

export default TextInput;
