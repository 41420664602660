const HeaderNameBySku = (header_sku) => {
	const bothOpen = [
		{
			name: 'Open Roll Medium', value: 'OR_1234', conditional: (shade) => true
		},
	];

	const threeInchCassette = [
		{
			optGroup: 'Cassette (Fabric Insert Included)',
			value: 'RC3051-A',
			name: 'Cassette 3 in. Anodized',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Cassette (Fabric Insert Included)',
			value: 'RC3051-BK',
			name: 'Cassette 3 in. Black',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Cassette (Fabric Insert Included)',
			value: 'RC3051-DBR',
			name: 'Cassette 3 in. Bronze',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Cassette (Fabric Insert Included)',
			value: 'RC3051-LB',
			name: 'Cassette 3 in. Off-White',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Cassette (Fabric Insert Included)',
			value: 'RC3051-W',
			name: 'Cassette 3 in. White',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Cassette (Fabric Insert Included)',
			value: 'RC3051-AN',
			name: 'Cassette 3 in. Anthracite',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Square Cassette',
			value: 'RC3029-A',
			name: 'Square Cassette 3 in. Anodized',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Square Cassette',
			value: 'RC3029-BK',
			name: 'Square Cassette 3 in. Black',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Square Cassette',
			value: 'RC3029-W',
			name: 'Square Cassette 3 in. White',
			conditional: (shade) => !shade.isDualShade()
		},
		{
			optGroup: 'Square Cassette',
			value: 'RC3029-LB',
			name: 'Square Cassette 3 in. Off-White',
			conditional: (shade) => !shade.isDualShade()
		},
	];

	const threeInchFascia = [
		{
			optGroup: 'Fascia',
			value: 'RC3080-A',
			name: 'Fascia 3 in. Anodized',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC3080-AN',
			name: 'Fascia 3 in. Anthracite',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC3080-BK',
			name: 'Fascia 3 in. Black',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC3080-DBR',
			name: 'Fascia 3 in. Bronze',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC3080-LB',
			name: 'Fascia 3 in. Off-White',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC3080-W',
			name: 'Fascia 3 in. White',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
	];

	const fourInchFasciaCou = [
		{
			optGroup: 'Fascia',
			value: 'RC3158-A',
			name: 'Fascia 4 in. Anodized',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC3158-AN',
			name: 'Fascia 4 in. Anthracite',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC3158-BK',
			name: 'Fascia 4 in. Black',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC3158-DBR',
			name: 'Fascia 4 in. Bronze',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC3158-LB',
			name: 'Fascia 4 in. Off-White',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC3158-W',
			name: 'Fascia 4 in. White',
			conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
		},
	];

	const fiveInchFascia = [

		{
			optGroup: 'Fascia',
			value: 'RC4039-A',
			name: 'Fascia 5 in. Anodized',
			// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC4039-AN',
			name: 'Fascia 5 in. Anthracite',
			// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC4039-BK',
			name: 'Fascia 5 in. Black',
			// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC4039-DBR',
			name: 'Fascia 5 in. Bronze',
			// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC4039-LB',
			name: 'Fascia 5 in. Off-White',
			// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
		},
		{
			optGroup: 'Fascia',
			value: 'RC4039-W',
			name: 'Fascia 5 in. White',
			// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
		},
	];

	const standardOpen = [
		{
			name: 'Open Roll Small',
			value: 'OR_0002',
			conditional: (shade) => shade.valMatch('shade_type', 'Manual') && !shade.isDualShade()
		},
	];

	const fourInchCassette = [
		{
			optGroup: 'Cassette (Fabric Insert Included)',
			value: 'RS-SU1',
			name: 'Cassette 4 in. White',
			conditional: (shade) => false
		},
		{
			optGroup: 'Cassette (Fabric Insert Included)',
			value: 'RS-SU2',
			name: 'Cassette 4 in. Bronze',
			conditional: (shade) => false
		},
		{
			optGroup: 'Cassette (Fabric Insert Included)',
			value: 'RS-SU3',
			name: 'Cassette 4 in. Alabaster',
			conditional: (shade) => false
		},
		{
			optGroup: 'Cassette (Fabric Insert Included)',
			value: 'RS-SU4',
			name: 'Cassette 4 in. Anodized',
			conditional: (shade) => false
		},
		{
			optGroup: 'Cassette (Fabric Insert Included)',
			value: 'RS-SU5',
			name: 'Cassette 4 in. Black',
			conditional: (shade) => false
		}
	];

	const heavyOpen = [
		{
			name: 'Open Roll Large', value: 'OR_5678', conditional: (shade) => shade.valMatch('shade_type', 'Motorized')
		},
	];

	const mediumLargeOpen = [
		{
			name: 'Open Roll Large (Single)',
			value: 'OR_COULLISE_LARGE_BALANCED',
			conditional: (shade) => {
				if (!shade) {
					return true;
				}

				return !shade.isDualShade();
			},
		},

	];

	const fourInchFascia = [
		{
			optGroup: 'Fascia',
			value: 'RS-SC1',
			name: 'Fascia 4 in. White',
			conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
		},
		{
			optGroup: 'Fascia',
			value: 'RS-SC2',
			name: 'Fascia 4 in. Bronze',
			conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
		},
		{
			optGroup: 'Fascia',
			value: 'RS-SC3',
			name: 'Fascia 4 in. Alabaster',
			conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
		},
		{
			optGroup: 'Fascia',
			value: 'RS-SC4',
			name: 'Fascia 4 in. Anodized',
			conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
		},
		{
			optGroup: 'Fascia',
			value: 'RS-SC5',
			name: 'Fascia 4 in. Black',
			conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
		},
	];

	const fiveAndAHalfInchFascia = [
		{
			optGroup: 'Fascia',
			value: 'RS-SV4',
			name: 'Fascia 5.5 in. Anodized',
			conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
		},
		{
			optGroup: 'Fascia',
			value: 'RS-SV2',
			name: 'Fascia 5.5 in. Bronze',
			conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
		},
		{
			optGroup: 'Fascia',
			value: 'RS-SV1',
			name: 'Fascia 5.5 in. White',
			conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
		},
	];

	const sevenInchFascia = [

		{
			optGroup: 'Fascia',
			value: 'RS-SK4A',
			name: 'Fascia 7 in. Anodized',
			conditional: (shade) => shade.isDualShade() && shade.valMatch('shade_type', 'Motorized')
		},
		{
			optGroup: 'Fascia',
			value: 'RS-SK5A',
			name: 'Fascia 7 in. Black',
			conditional: (shade) => shade.isDualShade() && shade.valMatch('shade_type', 'Motorized')
		},
		{
			optGroup: 'Fascia',
			value: 'RS-SK2A',
			name: 'Fascia 7 in. Bronze',
			conditional: (shade) => shade.isDualShade() && shade.valMatch('shade_type', 'Motorized')
		},
		{
			optGroup: 'Fascia',
			value: 'RS-SK1A',
			name: 'Fascia 7 in. White',
			conditional: (shade) => shade.isDualShade() && shade.valMatch('shade_type', 'Motorized')
		},
	];

	const items = [
		...standardOpen, // No Dual
		...bothOpen, // Dual
		...heavyOpen, // Dual
		...threeInchFascia, // No Dual
		...fourInchFasciaCou, // No Dual
		...fourInchFascia, // No Dual
		...fiveInchFascia, // Dual
		...fiveAndAHalfInchFascia, // Dual
		...sevenInchFascia, // Dual
		...threeInchCassette, // No Dual
		...fourInchCassette // No dual
	];

	const headerType = items.find((i) => i.value == header_sku);

	return headerType?.name ?? 'None';
};

export default HeaderNameBySku;
