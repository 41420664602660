import { useState, useEffect } from 'react';

import Button from '../Parts/Button';

import api from '../PowerShadesAPI';

const DealerZohoUpsert = ({ setTitle }) => {
	const [sending, setSending] = useState(false);
	const [response, setResponse] = useState(false);
    
	useEffect(() => {
		setTitle('Bulk Dealer Upsert');
	}, [setTitle]);

	const attemptUpsert = () => {
		setSending(true);
		api.bulkDealerUpdate().then((resp) => {
			setResponse(resp);
		});
	};
	const reset = () => {
		setSending(false);
		setResponse(false);
	};

	return (
		<form id="login-form" className="request-password-reset" style={{ width: '24em' }}>
			<h3>Bulk Dealer Upsert</h3>
			{!sending

				? (
					<div>
						<span>
                        Press the button to send all dealers to Zoho CRM and Zoho Books.
							<br />
							<br />
							<br />
							<br />
						</span>

						<div className="button-holder align-right">
							<Button color="green" onClick={attemptUpsert}>Submit</Button>
						</div>

					</div>
				)
				: !response && sending

					? <div>Sending.....</div>
					: (
						<div>
							<h4>Failed</h4>
							<div>{response}</div>
							<div className="button-holder">
								<Button color="green" className="green-button" onClick={reset}>Try Again</Button>
							</div>
						</div>
					)
			}

		</form>
	);
};

export default DealerZohoUpsert;
