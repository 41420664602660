import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonThroughWindow } from '@fortawesome/free-solid-svg-icons';

import type { EmployeeQuoteIconProps } from './types';

const EmployeeQuoteIcon = ({
	size = '1x', colorOverride = '', className = ''
}: EmployeeQuoteIconProps) => (
	<FontAwesomeIcon
		icon={faPersonThroughWindow}
		size={size}
		className={`${className} ${colorOverride && `text-${colorOverride}`}`}
	/>
);

export default EmployeeQuoteIcon;
