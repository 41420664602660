import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';

import api from '../../../PowerShadesAPI';
import SavingWheel from '../../../Parts/SavingWheel';
import TextDivider from '../../../Parts/TextDivider';
import PaymentStep from '../PaymentStep';
import NewCardField from '../../../Parts/NewCardField';

const CardPayment = ({
	newQuote,
	preLoadedQuote,
	setSubmit,
	setWillNeedRetainer,
	willNeedRetainer,
	showSubmit,
	quoteType,
	setProcessing,
	setRetainer,
	done
}) => {
	const [cardErrors] = useState(null);
	const [waiting, setWaiting] = useState(false);
	const [error, setError] = useState(null);
	const [, setSaveCard] = useState(false);

	const [isActuallyAccount, setIsActuallyAccount] = useState(false);

	const quote = preLoadedQuote;

	const quoteId = newQuote.Id;

	const price = newQuote.buy();

	const demo = newQuote.GetDemoInfo();

	const isDemo = newQuote.IsDemo;

	let newDisplayTotalPrice = null;
	const { subtotal } = price;

	let demoDiscount = null;
	if (isDemo) {
		newDisplayTotalPrice =
			demo?.demoApprovalType?.name != 'cash' ? subtotal * (1 - demo.value / 100) : demo.value;
		demoDiscount = newDisplayTotalPrice - price.totalPrice;
	}

	useEffect(() => {}, []);

	const submit = async (card) => {
		setProcessing(true);

		setWaiting(true);

		return new Promise((resolve, reject) => {
			api.submitCreditCardPayment(
				quoteId,
				card.card_id ? card.card_id : card.account_id ? card.account_id : card,
				!!(card.account_id ?? false),
				price.totalPrice / price.multiplier.currency_multiplier +
					demoDiscount / price.multiplier.currency_multiplier
			).then((resp) => {
				if (resp.status !== 200 || resp.data.success === false) {
					reject(Error('There was an error processing your payment.'));
					setWaiting(false);
					setError('There was an error processing your payment.');
					console.error(JSON.stringify(resp));
					Sentry.captureException(
						Error(
							resp?.data?.error_message ?? 'Something went wrong processing a payment - check console for more details'
						)
					);
					showSubmit();
				} else {
					resolve('Payment successful');
					done && done();
				}

				// const errorCase = (message) => {
				// 	reject(errorMessage);
				// 	setWaiting(false);
				// 	setError(errorMessage);
				// 	props.showSubmit();
				// };

				// if (!success) {
				// 	const errorMessage = data.message;
				// 	errorCase(errorMessage);
				// } else {
				// 	resolve('Payment successful.');
				// 	props.done && props.done();
				// }
			});
		});

		// return new Promise((resolve, reject) => {
		// 	api.makePaymentNew(quote.ID, 'card', {card: card, assert_total_price: price.totalPrice/price.multiplier.currency_multiplier, save_card: false, is_actually_account : isActuallyAccount})
		// 	.then(resp => {
		// 		if(resp.data.error) {
		// 			reject(resp.data.error);
		// 			setWaiting(false);
		// 			setError(resp.data.error);
		// 			props.showSubmit();
		// 		} else {
		// 			resolve('Payment successful.');
		// 		}
		// 	})

		// })
	};

	const cardChosen = (card) => {
		setSaveCard(card);
		showSubmit();
		setSubmit(() => submit(card));
	};

	// const ISO = price.multiplier.currency;

	return (
		<PaymentStep>
			<TextDivider>
				<h3 className={`text-${quoteType}`} style={{ fontWeight: 'normal' }}>
					Card/ACH Payment
				</h3>
			</TextDivider>

			<>
				<NewCardField
					quoteType={quoteType}
					setRetainer={setRetainer}
					willNeedRetainer={willNeedRetainer}
					setWillNeedRetainer={setWillNeedRetainer}
					isActuallyAccount={isActuallyAccount}
					setIsActuallyAccount={setIsActuallyAccount}
					half={Number.parseFloat(price.totalPrice / 2).toFixed(2)}
					quoteId={quote.id}
					cardErrors={cardErrors}
					setCard={cardChosen}
				/>

				<br />
			</>

			{error ? (
				<div className="error">
					<br />
					{error}
				</div>
			) : null}

			<div style={{ textAlign: 'right', width: '100%' }}>
				{waiting ? <SavingWheel status={1} customText="Please Wait.." /> : null}
			</div>
		</PaymentStep>
	);
};

export default CardPayment;
