import React from 'react';
import Popup from 'reactjs-popup';
import { isFunction } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { Row, Col } from "react-bootstrap";
import IntField from './IntField';
import FabricSelectField from './FabricSelectField';
import FractionField from './FractionField';
import SelectField from './SelectField';
import TextField from './TextField';
import CheckBox from './CheckBoxField';

class OptionField extends React.Component {
	constructor(props) {
		super(props);

		this.props = props;

		this.state = {
			showDescription: false,
			value: props.value,
			option: this.props.option,
			shade: this.props.shade,
			rowId: this.props.rowId,
			assemblyId: this.props.assemblyId,
			isCoupled: !!this.props.isCoupled,
			quoteLevel: !!this.props.quoteLevel,
			lowerUpdate: {}
		};

		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onChange = this.onChange.bind(this);
		this.update = this.update.bind(this);
		this.setNewValue = this.setNewValue.bind(this);
		this.prettyUpdate = this.prettyUpdate.bind(this);
		this.setLower = this.setLower.bind(this);
		this.handleEnter = this.handleEnter.bind(this);

		if (props.prettyUpdate) {
			props.prettyUpdate[props.shade.sequenceId] = props.prettyUpdate[props.shade.sequenceId] ?? {};
			props.prettyUpdate[props.shade.sequenceId][props.option.varName] = this.prettyUpdate;
		}

		if (props.update) {
			if (!props.update[props.shade.sequenceId]) {
				props.update[props.shade.sequenceId] = {};
			}
			props.update[props.shade.sequenceId][props.option.varName] = this.update;
		}
	}

	get id() {
		return this.state.isCoupled || this.props.global ? (isFunction(this.props.id) ? this.props.id(this.props.shade?.data()) : this.props.id) : this.props.option.getId(this.props.shade.data());
	}

	prettyUpdate(new_val) {
		this.setState({ value: new_val });
		this.state.lowerUpdate.setValue && this.state.lowerUpdate.setValue(new_val);
	}

	onChange(new_val) {
		if (this.props.quoteLevel) {
			this.props.onChange(new_val);
			return;
		}

		if (this.state.option.onChange != undefined) {
			this.state.option.onChange();
		}

		const varName = this.state.option.realVarName ? this.state.option.realVarName : this.state.option.varName;

		if (this.props.onChange) {
			this.props.onChange(
				this.id,
				varName,
				new_val,
				this.props.option
			);
		}

		if (this.props.option.ValueChanged && this.props.onChangeTwo) {
			this.props.option.ValueChanged(
				this.id,
				varName,
				new_val,
				this.props.option,
				true
			);
		}

		// this.state.shade.valueChanged();
	}

	setLower(setFunction) {
		this.setState({ lowerUpdate: { setUpdate: setFunction } });
	}

	onFocus(e) {
		this.props.onFocus && this.props.onFocus(option, e);
	}

	onBlur(e) {
		this.props.onBlur && this.props.onBlur(this.props.option, e);
	}

	setNewValue(newValue) {
		const oldValue = this.state.value;

		if (newValue !== oldValue) {
			this.state.value = newValue;
			this.setState({
				value: newValue
			});
			this.forceUpdate();
		}
	}

	update(shade) {
		this.setState({
			shade
		});
	}

	handleEnter(event) {
		if (event.key === 'Enter') {
			event.target.blur();
		}
	}

	render() {
		const {
			disabled,
			placeholderLabel,
			noPlaceholder,
			editable,
			option,
			update,
			isCoupled,
			global,
			willBeSeamed,
			hasSagWarning,
			extraClassStyles,
			isCenterable = false,
			quoteType = "cedia",
			small,
			isClearable = true
		} = this.props;

		const { value } = this.props;
		const { shade } = this.state;

		const isFabricSelect = option.varName.includes("fabric");

		const smallOverride = isFabricSelect ? false : small;

		this.props_to_pass = {
			disabled,
			placeholderLabel,
			noPlaceholder,
			editable,
			option,
			noPlaceholder,
			value,
			shade,
			onBlur: this.onBlur,
			isCoupled,
			global,
			willBeSeamed,
			hasSagWarning,
			isCenterable,
			handleEnter: this.handleEnter,
			onFocus: this.onFocus,
			onChange: this.onChange,
			key: `${option.varName}-${value}-${editable}`,
			lowerUpdate: this.state.lowerUpdate,
			lowerAdd: this.setLower,
			small: smallOverride,
			isClearable: option.isClearable,
		};

		const { props_to_pass } = this;
		this.OPTION_TYPES = {
			text: () => (
				<TextField
					{...props_to_pass}
				/>
			),

			select: () => (
				<SelectField
					{...props_to_pass}
				/>

			),

			int: () => (
				<IntField
					{...props_to_pass}
				/>
			),

			fabricSelect: () => (
				<FabricSelectField
					{...props_to_pass}
				/>
			),

			fraction: () => (
				<FractionField
					{...props_to_pass}
				/>
			),
			checkbox: () => (
				<CheckBox
					{...props_to_pass}
				/>
			)

		};

		const { OPTION_TYPES } = this;

		const label = (typeof option.label === 'function' ? option.label(option.shade) : this.props.noLabel ? null : option.label);

		this.contents = option.type ? OPTION_TYPES[option.type]() : null;

		let additionalClasses = option.type === 'fraction' ? 'number-holder fraction-holder' : '';

		if ((option.nullable == undefined || !option.nullable()) && option.value == null) {
			additionalClasses += ' empty-value';
		}

		if (option.fullWidth) {
			additionalClasses += ' full-width';
		}

		const has_error = shade && ((option.error && option.error(shade)) || (shade && shade.fieldHasErrors(shade, option.varName)));

		const isDescriptionFunction = typeof option.description === typeof (() => { });
		let displayFunction = true;
		if (isDescriptionFunction) {
			if (option.description(shade) === false) {
				displayFunction = false;
			}
		}

		return (
			<div
				style={{ align: "auto" }}
				className={
					`${has_error ? "error" : ""
					} ${additionalClasses
					} ${extraClassStyles
					} local-bootstrap 
					${this.props.extraMargin ? 'mb-1' : null}`
				}
			>
				{this.props.noLabel ? null : (
					<label style={{ minWidth: "55%" }} htmlFor={option.varName}>
						<Row className="g-0">
							<Col className={option.description ? `text-center` : this.props.extraMargin ? 'mb-1' : null} xs={option.description ? 11 : 12}>
								{label}
							</Col>
							{option.description ? (
								<Col className="text-end" xs={1}>
									{displayFunction ? (
										<Popup
											contentStyle={{ width: "fit-content", border: '1px solid #6d6e71' }}
											arrow={false}
											on="hover"
											trigger={() => (
												<FontAwesomeIcon style={{ marginBottom: '0.5em' }} icon={faInfoCircle} className={`text-${quoteType}`} />
											)}
											position={[
												"left-center",
												"right-center",
												"bottom-center",
											]}
											keepTooltipInside=".product-form"
										>
											{isDescriptionFunction
												? option.description(shade)
												: option.description}
										</Popup>
									) : null}
								</Col>
							) : null}
						</Row>
					</label>
				)}
				{option.infoBubble ? option.infoBubble(shade) : null}
				{this.contents}
			</div>
		);
	}
}

export default OptionField;
