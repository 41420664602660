import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { Col, Container, Row } from "react-bootstrap";

import { useIsPowerShadesAdmin } from "../hooks";
import { useQuoteCountStatus, useQuoteCounts, useSetFilterSettings } from "../Store/entities/quotes/hooks";
import { useFilterSettings } from "../Store/ui/hooks";

import apiCalls from "../PowerShadesAPIFunctions";
import HomeQuoteTable from "../Home/Quotes/Table";
import FilterContainer from "../Home/Filters/FilterContainer";
import ShipmentModal from "../Modals/ShipmentModal";
import ShipmentSummaryModal from "../Modals/ShipmentSummaryModal";
import ManufacturerSelect from "../Modals/ManufacturerSelect";
import StatusCard from "../Home/StatusCards/Content";

import type { HomeTableDatum, QuoteInformation, FilterOptions, StatusOptions } from "../powershadesApiTypes";
import type { ManufacturingProps } from "./types";
import type {  } from "../Store/ui/types";
import { loadStatus, } from "../Store/entities/entitiesType";

const Manufacturing = ({ ROOT }: ManufacturingProps) => {
	const [shipmentModalQuote, setShipmentModalQuote] = useState<QuoteInformation | null>(null);
	const [manufacturerId, setManufacturerId] = useState(0);
	const [selectedFilter, setSelectedFilter] = useState<StatusOptions>("submitted_to_manufacturing");

	const mfgRelevantFilters: StatusOptions[] = [
		"submitted_to_manufacturing",
		"in_production",
		"ready_for_manufacturing"
	];

	const setFilterSettingsHook = useSetFilterSettings();
	const filterSettingsHook = useFilterSettings();
	const quoteCounts = useQuoteCounts();
	
	const quoteCountLoadStatus = useQuoteCountStatus();
	const quoteCountLoading = quoteCountLoadStatus <= loadStatus.loading;

	const { setTitle, alert, loadContent } = ROOT;

	const [defaultFilterSettings, ] = useState<FilterOptions>({
		general_filter: "",
		dealer_ids: [],
		territory_ids: [],
		quote_type_ids: [],
		page_number: 1,
		results_per_page: 20,
		filter_names: ["submitted_to_manufacturing"],
		sort_names: ["-updated"],
		manufacturer_id: manufacturerId
	});

	const filteredQuoteCounts = Object.entries(quoteCounts).filter(([title]) => {
		if (!mfgRelevantFilters.includes(title as StatusOptions)) {
			return false;
		}

		return true;
	});

	useEffect(() => {
		setTitle("Manufacturing | Dealer Portal");
	}, [setTitle]);

	const isPsAdmin = useIsPowerShadesAdmin();

	const showShipmentModal = (quote: QuoteInformation) => {
		apiCalls.loadQuoteTwo(quote.ID).then((resp) => {
			try {
				Sentry.setTag("Quote ID", resp.data.Quote.ID);
				setShipmentModalQuote(resp.data.Quote);
			} catch (err) {
				alert({
					quickFormat: "error",
					text: "Something went wrong loading the quote. Please refresh and try again. If this continues to happen, please contact PowerShades Engineering."
				});
				console.error(err);
				throw new Error(`Error loading quote #${quote.ID} in MFG view. Please check logs.`);
			}
		});
	};

	console.debug(showShipmentModal);

	useEffect(() => {
		const selectedFilterNameFromHook = filterSettingsHook.filter_names[0];
		const selectedFilterNameFromState = selectedFilter;
		if (!selectedFilterNameFromHook) return;
		// default case, if no or incorrect filter is selected
		if (!selectedFilterNameFromState && !(mfgRelevantFilters.includes(selectedFilterNameFromHook))) {
			setSelectedFilter("submitted_to_manufacturing");
			setFilterSettingsHook({ ...defaultFilterSettings, filter_names: ["submitted_to_manufacturing"] });
			return;
		}
		// if the filter selected in the hook is different from the filter selected in state
		if (selectedFilterNameFromHook !== selectedFilterNameFromState) {
			setSelectedFilter(selectedFilterNameFromHook);
		}
	}, [defaultFilterSettings, filterSettingsHook]);

	// ! DEBUGGING use
	useEffect(() => {
		console.log(selectedFilter);
	}, [selectedFilter])

	return isPsAdmin ? (
		<Container fluid className="local-bootstrap">
			{manufacturerId ? (
				<>
					<Row>
						<Col className="d-none d-lg-block mb-4">
							<Row>
								{filteredQuoteCounts.map(([title, datum]) => {
									const { count } = datum as HomeTableDatum;

									return (
										<StatusCard
											loading={quoteCountLoading}
											active={title === selectedFilter}
											key={title}
											title={title as StatusOptions}
											count={count}
										/>
									);
								})}
							</Row>
						</Col>
					</Row>
					<FilterContainer filterSettings={{...defaultFilterSettings, filter_names: [selectedFilter]}} setFilterSettings={setFilterSettingsHook} />
					<HomeQuoteTable />
				</>
			) : (
				<ManufacturerSelect
					onCancel={() => loadContent("#AdminPanel")}
					onSubmit={(mfgId, mfgName) => {
						setManufacturerId(mfgId);
						setTitle(`${mfgName} | Manufacturing | Dealer Portal`);
						setFilterSettingsHook({ ...defaultFilterSettings, manufacturer_id: mfgId });
					}}
				/>
			)}

			{shipmentModalQuote ? (
				shipmentModalQuote.is_shipped ? (
					<ShipmentSummaryModal
						onClose={() => setShipmentModalQuote(null)}
						quote={shipmentModalQuote}
					/>
				) : (
					<ShipmentModal onClose={() => setShipmentModalQuote(null)} quote={shipmentModalQuote} />
				)
			) : null}
		</Container>
	) : null;
};

export default Manufacturing;
