/* eslint-disable arrow-body-style */
import React, { FC } from "react";
import { Placeholder } from "react-bootstrap";

type SkeletonRowProps = {
	columnCount?: number;
}

const SkeletonRow: FC<SkeletonRowProps> = ({ columnCount = 5 }) => {
	const vals = Array.from({ length: columnCount }).map((_, index) => {
		return (
			<td key={`${index * 2} `}>
				<Placeholder xs={3} sm={2} />
			</td>
		);
	});

	return (
		<tr>
			{vals}
		</tr>
	);
};

export default SkeletonRow;