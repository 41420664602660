/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { debounce, startCase, upperCase } from "lodash";
import {
	faArrowDownWideShort,
	faArrowUpShortWide,
	faTable
} from "@fortawesome/free-solid-svg-icons";
import { faCards } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { setPrefersOldPage } from "../../Store/user";
import { useDealers, useTerritories } from "../../Store/entities/hooks";
import { UsePortalDispatch } from "../../Store";
import { useUserData } from "../../hooks";

import ChipContainer from "./ChipContainer";
import DealerFilter from "./DealerFilter";
import QuoteTypeFilter from "./QuoteTypesFilter";
import TerritoryFilter from "./TerritoryFilter";

import type { FilterContainerProps } from "./types";

import styles from "./FilterContainer.module.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { SortOptions } from "../../powershadesApiTypes";

const FilterContainer = ({
	filterSettings,
	setFilterSettings,
	displayTerritoryFilter = true,
	displayDealerFilter = true,
	displayQuoteTypeFilter = true,
	displaySearch = true
}: FilterContainerProps) => {
	const [searchText, setSearchText] = useState("");

	const dispatch = UsePortalDispatch();

	const dealers = useDealers();
	const allowedDealers =
		filterSettings.territory_ids.length > 0
			? dealers.filter((d) =>
				d?.territory_ids?.some((t) => filterSettings.territory_ids.includes(t))
			)
			: dealers;

	const allowedDealerIds = allowedDealers.map((d) => d.id);

	const userData = useUserData();
	const territories = useTerritories();

	const sortOption = filterSettings?.sort_names[0] ?? "-updated";
	const isDesc = sortOption.startsWith("-");
	const lowercaseOption = isDesc ? sortOption.slice(1) : sortOption;

	const allSortOptions = ["created", "updated", "title", "id", "msrp"];

	// Debounce the search input to avoid excessive API calls
	const debouncedSearch = useMemo(
		() =>
			debounce((value) => {
				if (value === "") {
					setFilterSettings({
						...filterSettings,
						general_filter: "",
						page_number: 1
					});
				}
				setSearchText(value.trim());
			}, 500),
		[filterSettings]
	);

	// Update filter settings when search text changes
	useEffect(() => {
		if (searchText.length > 0) {
			setFilterSettings({ ...filterSettings, general_filter: searchText });
		}
	}, [searchText]);

	return (
		<Card className={styles.filterContainer}>
			<Card.Body>
				<Card.Title>
					<Row>
						<Col>
							<h4>Filters</h4>
						</Col>
						<Col className="text-end">
							<Dropdown className="no-dropdown-arrow" as={ButtonGroup}>
								<Dropdown.Toggle split variant="green">
									{lowercaseOption === "id" || lowercaseOption === "msrp"
										? upperCase(lowercaseOption)
										: startCase(lowercaseOption)}
								</Dropdown.Toggle>
								<Dropdown.Menu>
									{allSortOptions
										.filter((option) => option !== lowercaseOption)
										.map((option) => (
											<Dropdown.Item
												key={option}
												onClick={() => {
													const formattedWithDirection = isDesc ? `-${option}` : option;
													setFilterSettings({
														...filterSettings,
														sort_names: [formattedWithDirection as SortOptions]
													});
												}}
											>
												{option === "id" || option === "msrp"
													? upperCase(option)
													: startCase(option)}
											</Dropdown.Item>
										))}
								</Dropdown.Menu>
								<Button
									variant="green"
									onClick={() => {
										const formattedWithDirection = isDesc ? lowercaseOption : `-${lowercaseOption}`;
										setFilterSettings({
											...filterSettings,
											sort_names: [formattedWithDirection as SortOptions]
										});
									}}
								>
									{isDesc ? (
										<FontAwesomeIcon icon={faArrowDownWideShort} />
									) : (
										<FontAwesomeIcon icon={faArrowUpShortWide} />
									)}
								</Button>
								<Button
									variant="green"
									onClick={() => {
										dispatch(setPrefersOldPage(userData.id)(!userData.is_using_old_homepage));
									}}
								>
									{!userData.is_using_old_homepage ? (
										<FontAwesomeIcon icon={faCards as IconProp} />
									) : (
										<FontAwesomeIcon icon={faTable as IconProp} />
									)}
								</Button>
							</Dropdown>
						</Col>
					</Row>
				</Card.Title>
				<Card.Text>
					<Row>
						{territories.length > 1 && displayTerritoryFilter && (
							<Col xs={12} lg={3} className="mb-2">
								<TerritoryFilter
									selectedTerritoryIds={filterSettings.territory_ids}
									setNewTerritoryIds={(terrIds) =>
										setFilterSettings({
											...filterSettings,
											territory_ids: terrIds,
											page_number: 1
										})
									}
								/>
							</Col>
						)}
						{dealers.length > 1 && displayDealerFilter && (
							<Col xs={12} lg={3} className="mb-2">
								<DealerFilter
									selectedDealerIds={filterSettings.dealer_ids}
									setNewDealerIds={(dealerIds) =>
										setFilterSettings({
											...filterSettings,
											dealer_ids: dealerIds,
											page_number: 1
										})
									}
									allowedDealerIds={allowedDealerIds}
								/>
							</Col>
						)}
						{displayQuoteTypeFilter && (
							<Col xs={12} lg={3} className="mb-2">
								<QuoteTypeFilter
									selectedQuoteTypeIds={filterSettings.quote_type_ids}
									setNewQuoteTypeIds={(quoteTypeIds) =>
										setFilterSettings({
											...filterSettings,
											quote_type_ids: quoteTypeIds,
											page_number: 1
										})
									}
								/>
							</Col>
						)}
						{displaySearch && (
							<Col xs={12} lg={3}>
								<Form.Control
									className="form-control form-control sm"
									placeholder="Search..."
									onChange={(e) => debouncedSearch(e.target.value)}
								/>
							</Col>
						)}
					</Row>
					<ChipContainer
						selectedTerritoryIds={filterSettings.territory_ids}
						selectedDealerIds={filterSettings.dealer_ids}
						selectedQuoteTypeIds={filterSettings.quote_type_ids}
					/>
				</Card.Text>
			</Card.Body>
		</Card>
	);
};

export default FilterContainer;
