import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

import { useManufacturers, useManufacturersLoadStatus } from '../Store/entities/hooks';

import type { DealerSelectProps } from './types';
import { DealerStore } from '../powershadesApiTypes';

const ManufacturerSelect = ({
	optionsOverride,
	handleChange,
	disableOverride = false,
}: DealerSelectProps) => {
	const manufacturers = useManufacturers();
	const manufacturerLoadingStatus = useManufacturersLoadStatus();
	const manufacturerLoading = manufacturerLoadingStatus <= 3;

	const shouldBeDisabled = manufacturerLoading || disableOverride;

	return (
		<Typeahead
			id="manufacturer-select"
			clearButton
			paginate
			positionFixed
			labelKey="name"
			placeholder={manufacturerLoading ? 'Loading Manufacturers...' : 'Select a Manufacturer...'}
			options={optionsOverride ?? manufacturers}
			isLoading={manufacturerLoading}
			disabled={shouldBeDisabled}
			onChange={(selected) => {
				if (!selected) return;
				const dealer = selected as unknown as DealerStore;
				handleChange(dealer[0]?.id ?? 0);
			}}
		/>
	);
};

export default ManufacturerSelect;
