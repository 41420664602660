// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatusBlock-module__subtitle___TjVXB{color:#6d6e71 !important}.StatusBlock-module__card-subtitle___W_MKB a{color:#6d6e71;-webkit-text-decoration:none;text-decoration:none}`, "",{"version":3,"sources":["webpack://./src/NewQuote/Status/StatusBlock.module.scss","webpack://./src/assets/css/_variables-light.scss"],"names":[],"mappings":"AAEA,sCACC,wBAAA,CAIA,6CACC,aCLQ,CDMR,4BAAA,CAAA,oBAAA","sourcesContent":["@import \"../../assets/css/variables-light.scss\";\n\n.subtitle {\n\tcolor: $ps-gray !important;\n}\n\n.card-subtitle {\n\ta {\n\t\tcolor: $ps-gray;\n\t\ttext-decoration: none;\n\t}\n}\n","$ps-green: #8dc63f;\n$ps-dark-green: #7fb239;\n$ps-darker-green: #72A033;\n$ps-gray: #6d6e71;\n$ps-light-gray: #a7a9ac;\n$ps-lighter-gray: #dedede;\n$ps-lightest-gray: #F2F2F2;\n$ps-dark-gray: #57585a;\n$mobile-threshold: 993px;\n$error-color: #e30000;\n$error-row-background-color: #ff00006e;\n$delete-red: #e60d0d;\n$pending-orange: #e37204;\n$ps-black: #020202;\n$ps-rma: #bb4430;\n$ps-bid: #9611d0;\n$ps-rework: #469e95;\n$ps-light-green: #ddeec5;\n$ps-light-blue: #b3e6f5;\n$ps-light-yellow: #fbe7c6;\n$ps-light-red: #ffb7b6;\n$ps-dark-red: #822f21;\n\n$seam-color: #f8dc3f;\n$sag-color: #f39422;\n$seam-sag-color: #e289dd;\n$large-outdoor-color: #5589e7;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle": `StatusBlock-module__subtitle___TjVXB`,
	"card-subtitle": `StatusBlock-module__card-subtitle___W_MKB`,
	"cardSubtitle": `StatusBlock-module__card-subtitle___W_MKB`
};
export default ___CSS_LOADER_EXPORT___;
