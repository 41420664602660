import { useState, useEffect } from "react";

import store, { UsePortalSelector } from "../Store";
import api from "../PowerShadesAPI";
import apiCalls from "../PowerShadesAPIFunctions";
import { selectAccessories } from "../Store/entities/accessories/hooks";
import { SelectFabrics, SelectIndoorFabrics, SelectOutdoorFabrics, loadFabrics } from "../Store/entities/fabrics";

let motorLoadAttempts = 0;
let accessoryLoadAttempts = 0;
let indoorFabricLoadAttempts = 0;
let outdoorFabricLoadAttempts = 0;
let countrySubLoadAttempts = 0;
let hardwareLoadAttempts = 0;
let fabricCheckAttempts = 0;

let indoorFabricsLoaded = false;
let outdoorFabricsLoaded = false;
let motorsLoaded = false;
let accessoriesLoaded = false;
let statesLoaded = false;
let hardwarePartsLoaded = false;

const reloadTimeOut = 5000;
const maxAttempts = 5;

let loadTriggered = false;

let userLoggedIn = store.getState().user.authenticated;

let userHasLoggedOut = false;

let globalLoadTriggered = false;

const handleStateChange = () => {
	const userAuthStatus = store.getState().user.authenticated;
	if (userAuthStatus === false && userLoggedIn === true) {
		loadTriggered = false;
		userHasLoggedOut = true;
	}
	if (userAuthStatus === true && userHasLoggedOut === true) {
		userHasLoggedOut = false;
	}
	userLoggedIn = userAuthStatus;
	if (userLoggedIn && !loadTriggered) {
		loadTriggered = true;
		loadAll();
	}
};

const unsubscribe = store.subscribe(handleStateChange);

const QUOTE_GLOBALS = new (function () {
	const areLoaded = {
		accessories: false,
		motors: false,
		fabrics: true,
		states: false
	};
	const failedToLoad = {
		accessories: false,
		motors: false,
		fabrics: false,
		indoor_fabrics: false,
		outdoor_fabrics: false,
		states: false,
		hw_parts: false
	};
	const awaitingResponse = {
		accessories: false,
		motors: false,
		indoor_fabrics: false,
		outdoor_fabrics: false,
		states: false,
		hw_parts: false
	};
	const areLoadedeHardware = { hw_parts: false };
	const load_listeners = [];
	const hw_load_listeners = [];
	
	this.MOTORS = [];
	this.STATES = [];
	this.ACCESSORIES = [];

	const checkLoaded = () => {
		this.isLoaded() && this.loadingComplete();
	};

	const checkHWLoaded = () => {
		areLoadedeHardware.hw_parts && loadingHWComplete();
	};

	this.isLoaded = () => Object.keys(areLoaded).reduce((acc, key) => acc && areLoaded[key], true);

	this.getLoadedStatus = () => areLoaded;

	const checkItems = (num = 0) => {
		if (!this.isLoaded() && !globalLoadTriggered) {
			if (num >= 3) {
				loadAll(true);
			}
			setTimeout(() => checkItems(num + 1), 1000);
		} else {
			this.loadingComplete();
		}
	};

	this.loadingComplete = () => {
		load_listeners.forEach((callback) => callback());
		load_listeners.splice(0, load_listeners.length);
		if(hw_load_listeners && hw_load_listeners.length > 0 && this?.loadingHWComplete) this.loadingHWComplete();
	};

	const loadingHWComplete = () => {
		hw_load_listeners.forEach((callback) => callback());
		hw_load_listeners.splice(0, load_listeners.length);
		if(load_listeners && load_listeners.length > 0) {
			this.loadingComplete();
		}
		
	};

	this.loadAccessories = () => {
		if (areLoaded.accessories || accessoriesLoaded) return;
		if (failedToLoad.accessories) return;
		if (awaitingResponse.accessories) return;
		awaitingResponse.accessories = true;

		if (accessoryLoadAttempts <= maxAttempts) {
			apiCalls.getAccessories().then((resp) => {
				if (!resp?.data?.accessories || resp?.data?.accessories.length <= 0) {
					setTimeout(() => {
						awaitingResponse.accessories = false;
						console.error(resp);
						console.error(`Failed to load accessories, trying again - attempt ${accessoryLoadAttempts}`);
						accessoryLoadAttempts++;
						this.loadAccessories();
					}, reloadTimeOut);
					return;
				}
				this.ACCESSORIES = resp.data.accessories;

				areLoaded.accessories = true;
				accessoriesLoaded = true;
				awaitingResponse.accessories = false;

				checkLoaded();
			});
		} else {
			awaitingResponse.accessories = false;
			failedToLoad.accessories = true;
			throw new Error(`Failed to load accessories after ${maxAttempts} attempts.`);
		}
	};

	this.checkFabricLoadStatus = () => {
		if (areLoaded.fabrics) return;
		if (failedToLoad.fabrics) return;
		if (fabricCheckAttempts <= maxAttempts) {
			setTimeout(() => {
				if (indoorFabricsLoaded && outdoorFabricsLoaded) {
					areLoaded.fabrics = true;
					// this.FABRICS = [...this.OUTDOOR_FABRICS, ...this.INDOOR_FABRICS];
					return;
				}
				fabricCheckAttempts++;
				this.checkFabricLoadStatus();
			}, reloadTimeOut * 2);
		} else {
			failedToLoad.fabrics = true;
			throw new Error(`Failed to load fabrics after ${maxAttempts} attempts.`);
		}
		checkLoaded();
	}

	this.loadIndoorFabrics = () => {
		if (areLoaded.fabrics || indoorFabricsLoaded) return;
		if (failedToLoad.indoor_fabrics) return;
		if (awaitingResponse.indoor_fabrics) return;
		awaitingResponse.indoor_fabrics = true;
		if (indoorFabricLoadAttempts <= maxAttempts) {
			selectIndoorFabrics
		} else if (indoorFabricLoadAttempts > maxAttempts && !failedToLoad.indoor_fabrics) {
			awaitingResponse.indoor_fabrics = false;
			failedToLoad.indoor_fabrics = true;
			throw new Error(`Failed to load indoor fabrics after ${maxAttempts} attempts.`);
		}
	}

	this.loadOutdoorFabrics = () => {
		if (areLoaded.fabrics || outdoorFabricsLoaded) return;
		if (failedToLoad.outdoor_fabrics) return;
		if (awaitingResponse.outdoor_fabrics) return;
		awaitingResponse.outdoor_fabrics = true;
		if (outdoorFabricLoadAttempts <= maxAttempts) {
			api.getOutdoorFabrics().then((response) => {
				if (!response?.data?.fabrics || response?.data?.fabrics?.length <= 0) {
					setTimeout(() => {
						awaitingResponse.outdoor_fabrics = false;
						console.error(response);
						console.error(`Failed to load outdoor fabrics, trying again - attempt ${outdoorFabricLoadAttempts}`);
						outdoorFabricLoadAttempts++;
						this.loadOutdoorFabrics();
					}, reloadTimeOut);
					return;
				}
				awaitingResponse.outdoor_fabrics = false;
				//this.OUTDOOR_FABRICS = response.data.fabrics.map((f) => ({ ...f, value: f.part_number }));
				outdoorFabricsLoaded = true;
			});
		} else if (outdoorFabricLoadAttempts > maxAttempts && !failedToLoad.outdoor_fabrics) {
			failedToLoad.outdoor_fabrics = true;
			awaitingResponse.outdoor_fabrics = false;
			throw new Error(`Failed to load outdoor fabrics after ${maxAttempts} attempts.`);
		}
	}

	this.loadFabrics = () => {
		if (areLoaded.fabrics) return;
		if (failedToLoad.fabrics) return;

		// if (!awaitingResponse.indoor_fabrics && !indoorFabricsLoaded) this.loadIndoorFabrics();

		// if (!awaitingResponse.outdoor_fabrics && !outdoorFabricsLoaded) this.loadOutdoorFabrics();

		// if (!outdoorFabricsLoaded || !indoorFabricsLoaded) this.checkFabricLoadStatus();
		checkLoaded();
	};

	this.loadMotors = () => {
		if (areLoaded.motors || motorsLoaded) return;
		if (failedToLoad.motors) return;
		if (awaitingResponse.motors) return;

		if (motorLoadAttempts <= maxAttempts) {
			awaitingResponse.motors = true;
			api.getMotors().then((response) => {
				if (!response?.data?.motors || response?.data?.motors.length <= 0) {
					setTimeout(() => {
						awaitingResponse.motors = false;
						console.error(response);
						console.error(`Failed to motors, trying again - attempt ${motorLoadAttempts}`);
						motorLoadAttempts++;
						this.loadMotors();
					}, reloadTimeOut);
					return;
				}
				awaitingResponse.motors = false;
				
				response.data.motors.forEach((motor) => this.MOTORS.push(motor));
	
				areLoaded.motors = true;
				motorsLoaded = true;
	
				checkLoaded();
			});
		} else {
			awaitingResponse.motors = false;
			failedToLoad.motors = true;
			throw new Error(`Failed to load motors after ${maxAttempts} attempts.`);
		}
	};

	this.loadStates = () => {
		if (areLoaded.states || statesLoaded) return;
		if (failedToLoad.states) return;
		if (awaitingResponse.states) return;

		if (countrySubLoadAttempts <= maxAttempts) {
			awaitingResponse.states = true;
			api.getCountrySubdivisions().then((response) => {
				if (!response?.data?.country_subdivisions || response?.data?.country_subdivisions.length <= 0) {
					setTimeout(() => {
						awaitingResponse.states = false;
						console.error(response);
						console.error(`Failed to load states, trying again - attempt ${countrySubLoadAttempts}`);
						countrySubLoadAttempts++;
						this.loadStates();
					}, reloadTimeOut);
					return;
				}
				awaitingResponse.states = false;
				const tempStates = [...response.data.country_subdivisions];
				this.STATES = tempStates;

				areLoaded.states = true;
				statesLoaded = true;

				checkLoaded();
			});
		} else {
			awaitingResponse.states = false;
			failedToLoad.states = true;
			throw new Error(`Failed to load states after ${maxAttempts} attempts.`);
		}
	};

	this.loadHWParts = () => {
		if (areLoadedeHardware.hw_parts || hardwarePartsLoaded) return;
		if (failedToLoad.hw_parts) return;
		if (awaitingResponse.hw_parts) return;

		if (hardwareLoadAttempts <= maxAttempts) {
			api.getHWParts().then((resp) => {
				awaitingResponse.hw_parts = true;
				if (!resp?.data?.parts || resp?.data?.parts.length <= 0) {
					setTimeout(() => {
						awaitingResponse.hw_parts = false;
						console.error(resp);
						console.error(`Failed to load hardware parts, trying again - attempt ${hardwareLoadAttempts}`);
						hardwareLoadAttempts++;
						this.loadHWParts();
					}, reloadTimeOut);
					return;
				}
				this.HW_PARTS = resp.data.parts;
				awaitingResponse.hw_parts = false;
				areLoadedeHardware.hw_parts = true;
				hardwarePartsLoaded = true;
				checkHWLoaded();
			});
		} else {
			awaitingResponse.hw_parts = false;
			failedToLoad.hw_parts = true;
			throw new Error(`Failed to load hardware parts after ${maxAttempts} attempts.`);
		}
	};

	this.getCountrySubdivision = (csd_id) =>
		this.STATES.find((s) => parseInt(s.id) === parseInt(csd_id));

	this.getMotor = (motor_id) => this.MOTORS.find((m) => parseInt(m.id) === parseInt(motor_id));

	this.getAccessory = (acc_id) => selectAccessories(store.getState()).find((a) => parseInt(a.id) === parseInt(acc_id));

	this.onLoad = (callback) => {
		if (this.isLoaded()) {
			callback();
		} else {
			load_listeners.push(callback);
			checkItems();
		}
	};

	this.onHWLoad = (callback) => {
		if (areLoadedeHardware.hw_parts) {
			callback();
		} else {
			hw_load_listeners.push(callback);
			checkItems();
		}
	};

	// loadAll();

	return this;
})();

const loadAll = () => {
	globalLoadTriggered = true;
	// ! Need to implement this using redux and react hooks
	// if (load_triggered && !(override)) return;

	if (QUOTE_GLOBALS.isLoaded()) return;
	if (!indoorFabricsLoaded && !outdoorFabricsLoaded) {
		QUOTE_GLOBALS.loadFabrics();
	}
	if (!motorsLoaded) {
		QUOTE_GLOBALS.loadMotors();
	}
	if (!accessoriesLoaded) {
		QUOTE_GLOBALS.loadAccessories();
	}
	if (!statesLoaded) {
		QUOTE_GLOBALS.loadStates();
	}
	if (!hardwarePartsLoaded) {
		QUOTE_GLOBALS.loadHWParts();
	}

	globalLoadTriggered = false;
};

export function useMotors() {
	const [motors, setMotors] = useState([]);

	useEffect(() => {
		QUOTE_GLOBALS.onLoad(() => {
			setMotors(QUOTE_GLOBALS.MOTORS);
		});
	}, []);

	return motors;
}

export function useStates() {
	const [states, setStates] = useState([]);

	useEffect(() => {
		QUOTE_GLOBALS.onLoad(() => {
			setStates(QUOTE_GLOBALS.STATES);
		});
	}, []);

	return states;
}

window.QUOTE_GLOBALS = QUOTE_GLOBALS;

export default QUOTE_GLOBALS;
