// ! These are types from TS. They are unused as this file is a JS file -
// ! By using JSdocs we can provide some type information to the IDE.
// import {
// 	QuoteShade,
// 	QuoteLevelVariables,
// 	QuoteGlobals,
// 	QuoteAccessory,
// 	QuoteShadeAssemblies,
// 	QuoteInformation,
// 	ShallowHardwarePart,
// 	QuoteFabricSamples,
// 	QuoteAccessories,
// 	QuoteSubstitution,
// 	ShadeSubstitutionAttributes,
// 	ShadeSubstitution
// } from "../../powershadesApiTypes";
// /* eslint-enable no-unused-vars */

import { isFunction } from "lodash";
import { toast } from "react-toastify";

import transformShadeAssembly from "../transformShadeAssembly";
import Shade from "../../Shade";
import { QuoteTemplate } from "../Quote";
import IndoorShadeFormFields from "../NewOptionFields/ShadeActions/IndoorShadeOptionsTwo";
import OutdoorShadeFormFields from "../NewOptionFields/ShadeActions/OutdoorShadeOptionsTwo";
import api from "../../PowerShadesAPI";

import { selectCurrentUserData } from "../../Store/user/userPermissions";
import {
	addQuoteAccessory,
	deleteQuoteAccessory,
	updateQuoteAccessory
} from "../../Store/entities/accessories";
import { selectAccessories, selectQuoteAccessories } from "../../Store/entities/accessories/hooks";
import { captureSentryError, isFailedApiCall } from "../../psUtil";
import { SelectAssembly, selectAssemblyListByQuoteId } from "../../Store/entities/assemblies/hooks";
import { loadQuoteById, repriceQuote, wentToQuote } from "../../Store/entities/quotes";
import { InitialAssemblyState, InitialShadeState, addAssembly, deleteAssemblies, loadQuoteAssemblies, reloadAssembliesById, repriceAssemblies, setAssembliesSelected, updateAssemblyNoPrice } from "../../Store/entities/assemblies";
import apiCalls from "../../PowerShadesAPIFunctions";
import { selectQuoteIdAndDispatch } from "../../Store/entities/quotes/hooks";
import { SelectFabrics } from "../../Store/entities/fabrics";
import { selectQuoteFabricSampleListByQuoteId } from "../../Store/entities/fabricSamples/hooks";
import { deleteQuoteFabricSample, updateQuoteFabricSample } from "../../Store/entities/fabricSamples";
import { addQuoteHwItem, deleteQuoteHwItem, updateQuoteHwItem } from "../../Store/entities/hardwareRequestItems";
import { selectHWRItemsListByQuoteId } from "../../Store/entities/hardwareRequestItems/hooks";
import { selectQuoteAssemblyCombination } from "../../Store/entities/combinations/hooks";

import connection, { EnsureConnectionMade } from "../../Store/signalR/connection";

const { default: QUOTE_GLOBALS } = require("../QuoteGlobals");

/* eslint-disable class-methods-use-this */

class QuoteData {
	/**
	 * @constructor
	 * @this {QuoteData}
	 * @param {Object} param
	 * @param {QuoteLevelVariables} param.quoteLevelVariables
	 */
	constructor({ quoteLevelVariables }) {
		/** @type {QuoteGlobals} */
		this.globals = QUOTE_GLOBALS;

		this.shadeSubstitute = this.shadeSubstitute.bind(this);

		this.updateData = this.updateData.bind(this);
		this.RePriceWholeQuote = this.RePriceWholeQuote.bind(this);
		this.DuplicateShade = this.DuplicateShade.bind(this);
		this.setAccessoryQuantity = this.setAccessoryQuantity.bind(this);
		this.changeFabricSampleQuantity = this.changeFabricSampleQuantity.bind(this);
		this.QuoteFieldChange = this.QuoteFieldChange.bind(this);
		this.MassEdit = this.MassEdit.bind(this);
		this.deleteAssembly = this.deleteAssembly.bind(this);
		this.deleteAssemblies = this.deleteAssemblies.bind(this);
		this.selectedShades = this.selectedShades.bind(this);
		this.setUserData = this.setUserData.bind(this);
		this.getUserData = this.getUserData.bind(this);
		this.increaseAccessory = this.increaseAccessory.bind(this);
		this.decreaseAccessory = this.decreaseAccessory.bind(this);
		this.checkMotorChange = this.checkMotorChange.bind(this);
		this.lockAllShades = this.lockAllShades.bind(this);
		this.unlockShade = this.unlockShade.bind(this);
		this.isAccessoryOld = this.isAccessoryOld.bind(this);
		this.reOrderSequences = this.reOrderSequences.bind(this);
		this.setFasciaCombinations = this.setFasciaCombinations.bind(this);
		this.deleteFasciaCombination = this.deleteFasciaCombination.bind(this);
		this.setShadeLabor = this.setShadeLabor.bind(this);
		this.setShadeOverhead = this.setShadeOverhead.bind(this);
		this.setShadeMarkup = this.setShadeMarkup.bind(this);
		this.GetDemoInfo = this.GetDemoInfo.bind(this);
		this.SetDemoInfo = this.SetDemoInfo.bind(this);
		this.SetIsMotorOnly = this.SetIsMotorOnly.bind(this);
		this.SetIsShadeService = this.SetIsShadeService.bind(this);
		this.BalanceAllAssemblies = this.BalanceAllAssemblies.bind(this);
		this.unlockAndRepriceWholeQuote = this.unlockAndRepriceWholeQuote.bind(this);
		this.RePriceSingleRow = this.RePriceSingleRow.bind(this);
		this.setRepresentative = this.setRepresentative.bind(this);
		this.setToEvolution = this.setToEvolution.bind(this);
		this.setDistributor = this.setDistributor.bind(this);

		this.prettyUpdate = {};



		/** @type {QuoteLevelVariables} */
		this.quoteLevelVariables = quoteLevelVariables;

		const qlVars = this.quoteLevelVariables();
		const store = qlVars.store;

		this.FrontPageUpdate = {};

		const self = this;
		let subscCount = 0;

		self.unsubscribe = store.subscribe(() => {
			const quoteId = self?.quote?.ID ?? 0;
			if (quoteId === 0) {
				return;
			}

			const storeState = store.getState();
			const quoteStore = selectQuoteIdAndDispatch(quoteId)(storeState);
			if (!quoteStore) return;

			const userData = selectCurrentUserData(storeState);

			const { editable: isStoreEditable = false } = quoteStore;

			self.setUserData(userData);

			const isQuoteCurrentlyEditable = self.editable;

			if (isStoreEditable !== isQuoteCurrentlyEditable) {
				quoteLevelVariables().unlock();
			}

			// const accessories = selectAccessories(storeState);
			// self.accessories = accessories;

			const assembleis = selectAssemblyListByQuoteId(self.QuoteId)(storeState);

			const newIds = assembleis.filter((a) => a.shade_type_id == 1 || a.shade_type_id == 2).map((a) => a.sequence_id);
			let currentSeqIds = self?.shadeAssemblies?.Items?.map((a) => a.sequence_id) ?? [];

			newIds.sort((seq1, seq2) => Number(seq1) - Number(seq2));
			currentSeqIds.sort((seq1, seq2) => Number(seq1) - Number(seq2));

			if (newIds.length !== currentSeqIds.length) {
				if (self && self.shadeAssemblies && self.shadeAssemblies.Items) {
					self.shadeAssemblies.Items = [];
				}
				currentSeqIds = [];
			}

			if (newIds.join(",") !== currentSeqIds.join(",")) {
				newIds.forEach((id) => {
					if (!currentSeqIds.includes(id)) {
						const assyToAdd = { ...assembleis.find((a) => a.sequence_id === id) };

						if (assyToAdd === undefined || assyToAdd.shades === undefined) return;
						assyToAdd.shades = assyToAdd.shades.map((s) => ({
							...s,
							indoor_outdoor: assyToAdd.shade_type_id === 1 ? "indoor" : assyToAdd.shade_type_id === 3 ? "psoutdoor" : "outdoor",

						}));
						assyToAdd.indoor_outdoor = assyToAdd.shade_type_id === 1 ? "indoor" : assyToAdd.shade_type_id === 3 ? "psoutdoor" : "outdoor";
						assyToAdd.single_dual = assyToAdd.is_coupled && assyToAdd.is_dual ? "Dual Coupled" : assyToAdd.is_dual ? "Dual" : assyToAdd.is_coupled ? "Single Coupled" : "Single";
						self.shadeAssemblies.Items.push(assyToAdd);
					}
				});

				currentSeqIds.forEach((id) => {
					if (!newIds.includes(id)) {
						const index = self.shadeAssemblies.Items.findIndex((a) => a.sequence_id === id);
						self.shadeAssemblies.Items.splice(index, 1);
					}
				});
			}

			self?.shadeAssemblies?.Items?.forEach((a) => {
				const newAssy = assembleis.find((b) => b.sequence_id === a.sequence_id);
				if (!newAssy) return;

				// Update MSRP
				a.msrp = newAssy.msrp;
				a.cost = newAssy.cost;
				a.shipping_price = newAssy.shipping;
				a.last_time_priced = newAssy.last_time_priced;
				a.unlocked = newAssy.unlocked;

				const type = a.indoor_outdoor;
				let shadeMsrpComp = self.Money.ShadeMSRP.find((s) => s.sequenceId == a.sequence_id);

				if (!shadeMsrpComp) {
					const Money = self.Money.MoneyItem(a.sequence_id, a.msrp ?? 0, `shade-msrp-${type}`);
					self.Money.ShadeMSRP.push(Money);
					shadeMsrpComp = Money;
				}

				shadeMsrpComp?.UpdateAmount && shadeMsrpComp.UpdateAmount(newAssy.msrp);

				let shadeShippingComp = self.Money.ShadeShipping.find((s) => s.sequenceId == a.sequence_id);

				if (!shadeShippingComp) {
					const Shipping = self.Money.MoneyItem(a.sequence_id, a.shipping_price, `shade-shipping-${type}`, false);
					self.Money.ShadeShipping.push(Shipping);
					shadeShippingComp = Shipping;
				}

				shadeShippingComp?.UpdateAmount && shadeShippingComp.UpdateAmount(newAssy.shipping);

				a.shades.forEach((s) => {
					const shade = newAssy.shades.find((sh) => sh.row_coordinate === s.row_coordinate && sh.column_coordinate === s.column_coordinate);
					if (!shade) return;

					// s.width = shade.width;
					// s.height = shade.height;
					// s.fabric_name = shade.fabric_name;
				});
			});
			console.log(`Subscribed ${subscCount++}`);
			if (subscCount > 10000) {
				self.unsubscribe && self.unsubscribe();
				window.reload();
			}

			const quoteAccessories = selectQuoteAccessories(quoteId)(storeState);

			// self?.accessories?.Items?.forEach((acc) => {
			// 	const newAcc = quoteAccessories.find((a) => a.line_number === acc.line_number);
			// 	if (!newAcc) return;

			// 	acc.quantity = newAcc.quantity;
			// 	acc.cost = newAcc.cost;
			// 	acc.msrp = newAcc.msrp;
			// 	acc.shipping_price = newAcc.shipping_price;

			// });

			self.UI.reRenderTable();
		});
	}

	/**
	 * Function that returns indoor shades on an order by filtering the items property
	 * on shade assemblies - checking if it's indoor.
	 * @returns {QuoteShade[]}
	 */
	get IndoorShades() {
		return this.shadeAssemblies.Items.filter((sa) => sa.indoor_outdoor === "indoor");
		// return (selectAssemblyListByQuoteId(this?.quote?.ID)( this.quoteLevelVariables().store.getState())
		// 		?? this.shadeAssemblies.Items).filter((sa) => sa.shade_type_id === 1);
	}

	/**
	 * Function that returns outdoor shades on an order by filtering the items property
	 * on shade assemblies - checking if it's outdoor.
	 * @note This may not return a QuoteShade exactly, as it's an outdoor shade.
	 * Don't take this by gospel.
	 * @returns {QuoteShade[]}
	 */
	get OutdoorShades() {
		return this.shadeAssemblies.Items.filter((sa) => sa.indoor_outdoor === "outdoor");
	}

	// /**
	//  * Function that returns an array of QuoteAccessories.
	//  * @returns {QuoteAccessory[]}
	//  */
	// get AccessoryTotals() {
	// 	return this.accessories.Items;
	// }

	/**
	 * Function that returns the quote ID.
	 * @returns {number}
	 */
	get QuoteId() {
		return this.quote.ID;
	}

	/**
	 * @returns {QuoteSubstitution}
	 */
	get OldQuote() {
		return this?.oldObjects?.quote;
	}

	/**
	 * Function that loads the quote data from the "loadQuoteTwo" api call.
	 * @param {Object} param
	 * @param {QuoteShadeAssemblies} param.shadeAssemblies
	 * @param {QuoteAccessories} param.accessories
	 * @param {QuoteFabricSamples} param.fabricSamples
	 * @param {QuoteInformation} param.quote
	 * @param {undefined} param.advanced
	 * @param {ShallowHardwarePart[]} param.hwRequestItems
	 * @returns {void}
	 * @see getQuote
	 * @notes This function doesn't return anything but has side effects. It also
	 * sets "editable" on a quote to false.
	 */
	Load({ shadeAssemblies, accessories, fabricSamples, quote, advanced, hwRequestItems }) {
		/** @type {QuoteShadeAssemblies} */
		this.shadeAssemblies = shadeAssemblies;

		/** @type {QuoteAccessories} */
		this.accessories = null; //accessories;

		/** @type {QuoteFabricSamples} */
		this.fabricSamples = fabricSamples;

		/** @type {ShallowHardwarePart[]} */
		this.hwRequestItems = hwRequestItems;

		/** @type {QuoteInformation} */
		this.quote = quote;

		this.oldObjects = {};

		/** @type {QuoteSubstitution} */
		this.oldObjects.quote = this.getQuote();

		this.editable = false;
	}

	Set({ UI, Money }) {
		this.UI = UI;
		this.Money = Money;
	}

	/**
	 * Function that returns the old quote template. While this returns an unmodified
	 * quote template, this function has side effects because of the "getOldDataStyle"
	 * method it calls. The QuoteSubstitution type takes these side effects into consideration
	 * and includes them in the declaration.
	 * @returns {QuoteSubstitution}
	 * @see getOldDataStyle
	 */
	getQuote() {
		/** @type {QuoteSubstitution} */
		const quote = new QuoteTemplate();

		this.getOldDataStyle(quote);

		return quote;
	}

	/**
	 * @deprecated 1.10.0. This method isn't used anywhere.
	 * @see shadeSubstitute
	 * @returns Error
	 */

	// findShade(comparitor) {
	// 	const {
	// 		id: shade_assembly_id, row, column
	// 	} = comparitor;

	// 	return (shade) => shade_assembly_id == shade.shade_assembly_id
	// 			&& row == shade.row_coordinate
	// 			&& column == shade.column_coordinate;
	// }

	addAndGetNewShade(isOutdoor, callBack) {
		const formFields = isOutdoor
			? new OutdoorShadeFormFields(this.UI)
			: new IndoorShadeFormFields(this.UI);

		const quoteId = this.QuoteId;

		const shadeType = isOutdoor ? "outdoor" : "indoor";

		const assembly = {
			id: 0,
			indoor_outdoor: shadeType,
			shades: [
				{
					row_coordinate: 0,
					column_coordinate: 0
				}
			],
			unlocked: true
		};

		this.shadeAssemblies.Items.push(assembly);

		const { dispatch } = this.quoteLevelVariables().store;

		dispatch(addAssembly({
			quoteId,
			newAssembly: { ...InitialAssemblyState(), single_dual: "Single", shades: [InitialShadeState()] },
			shadeType
		})).then((resp) => {
			const { data } = resp;

			const { sequence_id } = resp.payload.assembly;

			assembly.sequence_id = sequence_id;
			assembly.single_dual = "Single";
			this.Money.AddAssemblyMSRP(sequence_id, 0, shadeType);
			this.Money.AddAssemblyShipping(sequence_id, 5, shadeType);
			callBack({ sequenceId: sequence_id });
		});

		// api.AddAssembly(quoteId, shadeType).then((resp) => {
		// 	const { data } = resp;

		// 	const { sequenceId } = data;

		// 	const { assemblyId } = data;

		// 	assembly.id = assemblyId;
		// 	assembly.sequence_id = sequenceId;
		// 	assembly.single_dual = "Single";
		// 	assembly.shades.forEach((s) => (s.shade_assembly_id = assemblyId));
		// 	this.Money.AddAssemblyMSRP(sequenceId, 0, shadeType);
		// 	this.Money.AddAssemblyShipping(sequenceId, 5, shadeType);
		// 	callBack(data);

		// 	store.dispatch(loadQuoteAssemblies({
		// 		quoteId,
		// 		isAdd: true
		// 	}));
		// });

		return this.shadeSubstitute;
	}

	lockAllShades() {
		const shadeSubstitution = this.shadeSubstitute;

		this.shadeAssemblies.Items.forEach((assembly) => {
			const shadeObj = shadeSubstitution(assembly.sequence_id);

			shadeObj.lock();
		});
	}

	unlockShade(sequenceId) {
		const assembly = this.shadeSubstitute(sequenceId);

		assembly.unlock();
	}

	deleteAssembly(sequenceId) {
		this.deleteAssemblies([sequenceId]);
	}

	deleteAssemblies(sequenceIds) {
		const self = this;

		if ((self.queuedDuplicates ?? 0) !== 0) return;

		sequenceIds.forEach((sId) => {
			const shade = self.shadeSubstitute(sId);

			if (shade.isOutdoorShade()) return;

			const isSolarOrAc
				= shade.val("lv_power_source") === "solar_panel" || shade.val("motor_type") == "high_voltage";
			const isHardwired = shade.val("motor_type") !== "low_voltage";
			shade.changeAccessoryCharging(false, isSolarOrAc, isHardwired);
		});

		sequenceIds.forEach((sId) => {
			const index = this.shadeAssemblies.Items.findIndex((s) => s.sequence_id == sId);
			self.shadeAssemblies.Items.splice(index, 1);
		});

		this.Money.RemoveSequenceIds(sequenceIds);

		const {
			dispatch
		} = this.quoteLevelVariables().store;

		dispatch(deleteAssemblies({ quoteId: this.QuoteId, sequenceIds: sequenceIds })).then(() => {
			const fcs = self.getFasciaCombinations();

			// This gets the fasciaCombination if it exists or returns a null which will
			// be used to condition if a main delete happens.
			const fc_obj = fcs.find((fc) => fc.assemblies.some((a) => sequenceIds.includes(a.sequence_id)));

			if (fc_obj) {
				self.deleteFasciaCombination(fc_obj.id);
			}

			self.UI.reRenderTable();
		});
	}

	setUserData(userData) {
		this.userData = userData;
	}

	getUserData() {
		return this.userData;
	}

	/**
	 * @param {number} sequenceId
	 *
	 */
	shadeSubstitute(sequenceId) {
		const shadeSubstituteReference = this.shadeSubstitute;

		const assemblyFromStore = () => SelectAssembly(this.quoteLevelVariables().quoteSub.ID, sequenceId)(this.quoteLevelVariables().store.getState());
		const shadeData = () => this.shadeAssemblies.Items.find((sa) => sa.sequence_id === sequenceId);

		let shadeObj = null;

		let options = null;

		shadeObj = {

			/**
			 * @param {any} variable
			 * @param {any | any[]} values
			 */
			valMatch: (variable, ...values) => {
				const shadeDataObj = shadeData();

				if (!shadeDataObj) return false;

				const workingVariable = shadeDataObj[variable];
				if (
					workingVariable
					&& values.find((v) => v.toLowerCase() === shadeDataObj[variable].toLowerCase())
				) {
					return true;
				}

				if (
					shadeDataObj.shades.some((shade) => {
						if (
							shade[variable]
							&& values.find((v) => v.toLowerCase() === shade[variable].toLowerCase())
						) {
							return true;
						}
						return false;
					})
				) {
					return true;
				}

				return false;
			},

			val: (variable) => {
				const currentShadeData = shadeData();

				if (!currentShadeData) {
					return null;
				}

				if (currentShadeData[variable]) {
					return currentShadeData[variable];
				}

				if (variable.includes("2") && !variable.startsWith("shade_")) {
					const shade = currentShadeData.shades.find((s) => s.row_coordinate == 1);
					const realVariable = variable.replace("2", "");

					return shade ? shade[realVariable] : null;
				}

				for (const shade of currentShadeData.shades) {
					if (shade[variable]) {
						return shade[variable];
					}
				}

				if (
					variable
					&& variable?.startsWith("shade_")
					&& variable?.endsWith("_id")
					&& !variable?.includes("assembly")
				) {
					const isFront = variable.split("_")[1] === "1";
					if (isFront) return currentShadeData.shades[0] ? currentShadeData.shades[0].id : null;
					if (!isFront) return currentShadeData.shades[1] ? currentShadeData.shades[1].id : null;
					return null;
				}

				return null;
			},

			valIncludes: (variable, ...values) => {
				if (
					shadeData()[variable]
					&& values.some((v) => shadeData()[variable].toLowerCase().includes(v.toLowerCase()))
				) {
					return true;
				}

				if (
					shadeData().shades.some((shade) => {
						if (
							shade[variable]
							&& values.some((v) => shade[variable].toLowerCase(v.toLowerCase()).includes(v))
						) {
							return true;
						}
					})
				) {
					return true;
				}

				return false;
			},
			changeAccessoryCharging: (isIncrease, isSolarOrAC, isWhip) => {
				// TODO fille out with new stuff

				// TODO Just returning here in order to make this easier for a hot fix. I should delete
				// this code next big update
				return;

				if (
					!shadeObj.isMotorized()
					|| (shadeObj.val("motor") == "35 mm Li-Ion PoE" && isIncrease)
					|| shadeObj.isOutdoorShade()
				) return;

				/*
				  For high voltage, include AC whip 1 per MOTOR
				  For low voltage hardwired, include DC whip 1 per MOTOR
				  For solar panels 1 per MOTOR
				  For wallcharger 1 per ASSEMBLY
			
				*/

				let accessorySku = "";

				const shade = shadeObj;

				const isMicroUsb = shade.val("motor") == "18 mm Li-Ion RF Motor";

				if (!isWhip) {
					if (isMicroUsb) {
						if (isSolarOrAC) {
							// Solar Panel - USB Output (3 Watts)
							accessorySku = "DC1288I";
						} else {
							// USB Power Supply and 3 Meter Micro USB Charging Cable
							accessorySku = ["PS2300-14E", "PS1564-04"];
						}
					} else if (isSolarOrAC) {
						// Solar Panel - 3.0 Watt
						accessorySku = "DC1289A";
					} else {
						// Wall Plug Recharger Removable Prongs
						accessorySku = "PS1563US";
					}
				} else if (isSolarOrAC) {
					// Ac Whip
					accessorySku = "45-1-30-04DY-02";
				} else {
					// DC whip
					accessorySku = "DC-1562-01-02";
				}

				(Array.isArray(accessorySku) ? accessorySku : [accessorySku]).forEach((sku) => {
					if (isIncrease) {
						this.increaseAccessory(sku);
					} else {
						this.decreaseAccessory(sku);
					}
				});
			},
			data: () => shadeData(),
			isNameUnique: () => {
				const shadeDatas = shadeData();

				if (!shadeDatas) return true;

				const initialName = shadeDatas.shade_name;

				if (!initialName) return true;

				const name = initialName.toString().trim().toLowerCase();

				const count = this.shadeAssemblies.Items.filter((sa) => (sa.shade_name ? sa.shade_name : "").toString().trim().toLowerCase() === name).length;

				return !(count > 1);
			},
			getErrorFields: (shade) => {
				const errSets = shadeObj.getErrorSets();

				const error_var_names = errSets.map((e) => e.varName);

				return [...new Set(error_var_names)];
			},
			clearErrors: () => {
				const shadeData = shadeObj.data();

				if (shadeData?.errors) {
					shadeData.errors = null;
				}
			},
			getErrors: (idk, ignorePricingErrors = false) => {
				const errSets = shadeObj.getErrorSets();

				const errs = errSets
					.filter((err) => err.varName != "Sorry, something went wrong." || !ignorePricingErrors)
					.map((e) => e.message);

				return [...new Set(errs)];
			},
			getErrorSets: () => {
				const errSets = [];

				let shade = shadeObj;

				if (!shade || !shade.options) {
					shade = shadeSubstituteReference(sequenceId);
				}

				const shadeData = shade.data();

				if (!shadeData) return [];

				const optionValue = (opt) =>
				(opt.getValue
					? opt.getValue(shade)
					: opt.varName in shadeData
						? shadeData[opt.varName]
						: shadeData.shades[0][opt.varName]);

				const types = ["select", "fabricSelect"];

				const shadeOptions = options();

				shadeOptions
					.filter((opt) => !opt.conditional || opt.conditional(shade))
					.filter((opt) => types.some((c) => opt.type === c))
					.filter((opt) => {
						const opt_choices = isFunction(opt.choices) ? opt.choices(shade) : opt.choices;

						let optionValuey
							= (optionValue && optionValue(opt) && optionValue(opt).toLowerCase()) ?? "~P~";

						optionValuey = String(optionValuey).endsWith("_b")
							? optionValuey.substring(0, optionValuey.length - 2)
							: optionValuey;

						optionValuey = optionValuey?.trim() ?? "~F~";

						const found_choice = opt_choices?.find((c) => {
							const cVal = (c.value && c.value.toLowerCase().trim()) ?? "";
							const cName = (c.name && c.name?.toLowerCase().trim()) ?? "";

							const containsVal = cVal.includes(optionValuey) || cName.includes(optionValuey);
							const isAllowed = c.conditional === true || !c.conditional || c.conditional(shade);

							return containsVal && isAllowed;
						});

						let showMe = !found_choice || !optionValuey;

						// This check is because this function likes to run before fabrics are imported into the webpage.
						if (
							(opt.varName?.includes("fabric2_name") || opt.varName?.includes("fabric_name"))
							&& opt_choices?.length == 0
						) {
							showMe = false;
						}

						const qlVariables = this.quoteLevelVariables();

						const hasOrderBeenPlaced = qlVariables.is_order;

						return showMe && !hasOrderBeenPlaced;
					})
					.forEach((opt) =>
						errSets.push({
							varName: opt.varName,
							message: `Please select a valid option for "${isFunction(opt.label) ? opt.label(shadeObj) : opt.label
								}".`
						}));

				shadeOptions.forEach((opt) => {
					const errorItem = opt.error && opt.error(shadeObj);

					if (errorItem) {
						errSets.push({
							varName: opt.varName,
							message: opt.error(shadeObj)
						});
					}
				});

				if (shadeData.msrp == "0" || shadeData.msrp == 0 || !shadeData.msrp) {
					errSets.push({
						varName: "Sorry, something went wrong.",
						message: "Sorry, something went wrong."
					});
				}

				const fasciaCombinations = this.getFasciaCombinations();

				const fasciaCombination = fasciaCombinations.find((fc) =>
					fc.assemblies.map((a) => a.sequence_id).includes(sequenceId));

				if (fasciaCombination) {
					const selectedShadeObjects = fasciaCombination.assemblies.map((a) =>
						shadeSubstituteReference(a.sequence_id));
					const headerTypes = selectedShadeObjects.map((s) => s.val("header_type"));

					const headerType = headerTypes.find((s) => s !== null);

					const allSameHeaderType = (function (headerTypes, headerType) {
						return !headerTypes.some((h) => h != headerType);
					})(headerTypes, headerType);

					if (!allSameHeaderType) {
						// Error Message
						errSets.push({
							varName: "header_fascia.",
							message: "This header must match the others in it's fascia combination."
						});
					}

					// Differing rooms

					const roomNames = selectedShadeObjects.map((s) => s.val("room_name"));

					const allSameRoom = (function (roomNames) {
						const roomName = roomNames.find((s) => true);

						return !roomNames.some((h) => h != roomName);
					})(roomNames);

					if (!allSameRoom) {
						errSets.push({
							varName: "room_fascia.",
							message: "This room must match the others in it's fascia combination."
						});

						// Error Message
					}

					const mountTypes = selectedShadeObjects.map((s) => s.val("mount_type"));

					const allSameMountTypes = (function (mountTypes) {
						const mountType = mountTypes.find((s) => true);

						return !mountTypes.some((h) => h != mountType);
					})(mountTypes);

					if (!allSameMountTypes) {
						errSets.push({
							varName: "mount_fascia.",
							message: "This mount must match the others in it's fascia combination."
						});
					}

					const directions = selectedShadeObjects.map((s) => s.val("direction_facing"));

					const direction = directions.find((s) => true);

					const hasMoreThanOneDirection = directions.some((d) => d != direction);

					if (hasMoreThanOneDirection) {
						errSets.push({
							varName: "direction_fascia.",
							message:
								"This direction must match the direction of other assemblies in the fascia combination."
						});
					}

					// Length based on Fascia Type
					// RC3080 (3 inch) 210
					// RC4039 (5 inch) 210
					// RS-SK (7 inch) 186
					const fasciaItems = [
						// 3 inch
						{
							sku: "RC3080",
							maxLength: 210,
							name: '3" Fascia'
						},
						// 4 inch
						{
							sku: "RC3158",
							maxLength: 210,
							name: '4" Fascia'
						},
						// 5 inch
						{
							sku: "RC4039",
							maxLength: 210,
							name: '5" Fascia'
						},
						// 7 inch
						{
							sku: "RS-SK",
							maxLength: 150,
							name: '7" Fascia'
						}
					];

					const fasciaType = fasciaItems.find((fi) => headerType?.includes(fi.sku));

					if (fasciaType == null) {
						errSets.push({
							varName: "type_fascia.",
							message:
								"This fascia must have the same type of fascia as others in a fascia combination."
						});
					}
				}

				const motorOverride = shadeData.MotorPricingOverride;

				if (motorOverride && motorOverride.length > 2) {
				}

				return errSets;
			},
			isOutdoorShade: () => assemblyFromStore()?.shade_type_id == 2,
			isIndoorShade: () => assemblyFromStore()?.shade_type_id == 1,
			isPSOutdoorShade: () => assemblyFromStore()?.shade_type_id == 3,
			getLocalID: () => sequenceId,
			isUnlocked: () => assemblyFromStore()?.unlocked,
			storeState: () => assemblyFromStore(),
			assemblyFromStore: () => assemblyFromStore(),
			unlock: () => {
				const shadeData = shadeObj.data();
				if (shadeObj.needsRepricing()) {
					this.RePriceSingleRow(sequenceId, true);
				}

				if (shadeData) {
					shadeData.unlocked = true;
				}

				this.UI.reRenderTable(true, false);
			},
			lock: () => {
				const shadeData = shadeObj.data();

				shadeData.unlocked = false;
			},
			needsRepricing: () => {
				const shadeData = shadeObj.data();

				const timestamp = shadeData.last_time_priced;

				const { msrp } = shadeData;

				const msrpNot0OrFalsey = msrp && msrp != "0";

				const lastTimePriced = new Date(timestamp * 1000);

				const currentDate = new Date();

				const dif = currentDate - lastTimePriced;

				const days = dif / (1000 * 60 * 60 * 24);

				if (days > 90 || !msrpNot0OrFalsey) {
					return true;
				}

				return false;
			},

			showLightGap: () => this.quote.IsLightgapChoiceActive == true,
			overrideRailRoad: () => this.quote.IsRailroadPreventionDeactivated == true,
			needsFasciaSplit: () => {
				const shadeData = shadeObj.data();
				// TODO: Make the needed comapre methods

				const isHeaderSplitSpecific = () => { };

				return false;
			}
		};

		shadeObj.isOutdoor = () => {
			const shadeType = shadeObj.val("indoor_outdoor");

			return shadeType == "outdoor";
		};

		shadeObj.isMotorized = () => shadeObj.valMatch("shade_type", "motorized");

		shadeObj.fieldHasErrors = (shade, field_name) => shadeObj.getErrors(shade).includes(field_name);

		shadeObj.savableObject = () => {
			const obj = { quote_id: this.QuoteId, shade_id: shadeData().id };

			shadeObj
				.options()
				.filter((opt) =>
					!Array.isArray(opt.value) & (opt.conditional == null || opt.conditional(shadeObj)))
				.forEach((opt) => (obj[opt.varName] = opt.value));

			obj.bearing_pin_type = shadeObj.isMotorized()
				? "heavy_duty_ball_bearing"
				: "standard_non_ball_bearing_pin";

			return obj;
		};

		shadeObj.isDualShade = () => shadeObj.valIncludes("single_dual", "Dual", "dual");

		shadeObj.isCoupledShade = () => {
			const shadeData = shadeObj.data();

			const isCoupled = shadeData.is_coupled;

			return isCoupled == 1;
		};

		shadeObj.sequenceId = sequenceId;
		shadeObj.self = shadeObj;

		options = (injectedMethods = {}) => {
			if (!shadeData()?.indoor_outdoor) {
				const shadeDataFunc = shadeData();
				console.error({
					shadeObj,
					shadeData: shadeDataFunc
				});
				captureSentryError(new Error("Quote Data shadeData.indoor_outdoor is undefined."));
			}
			const options
				= shadeData()?.indoor_outdoor === "indoor"
					? new IndoorShadeFormFields(this.UI, injectedMethods ?? {})
					: new OutdoorShadeFormFields(this.UI);

			options.forEach((o) => {
				const base = o.value;
				o.value = shadeObj.val(o.varName) ?? base;
			});

			return options;
		};

		shadeObj.options = options;

		return shadeObj;
	}

	increaseAccessory(accessorySku) {
		this.setAccessoryQuantity(accessorySku, this.getAccessoryQuantity(accessorySku) + 1);
	}

	decreaseAccessory(accessorySku) {
		this.setAccessoryQuantity(accessorySku, this.getAccessoryQuantity(accessorySku) - 1);
	}

	getAccessoryQuantity(accessorySku) {
		const quoteAccessories = selectQuoteAccessories(this.QuoteId)(this.quoteLevelVariables().store.getState());
		const quote_accessory = quoteAccessories.find((acc) => acc.part_number == accessorySku);
		return quote_accessory ? quote_accessory.quantity ?? 0 : 0;
	}

	selectedShades(filterIds = null) {
		return [];
		let selectedShades = this.UI.hooks.selectedShades();

		if (filterIds) {
			selectedShades = selectedShades.filter((value) => filterIds.includes(value));
		}
		return selectedShades;
	}

	/* (allIndoor, 'balanced_lightgaps', 'balanced', {
		varname : 'balanced_lightgaps',
		saveType : 2
	}) */
	MassEdit(assemblyIds, dataReference, newValue, option, selectOverride = false) {
		const self = this;
		const {
			store,
			quoteSub
		} = self.quoteLevelVariables();
		const { dispatch } = store;
		const unlockedAssemblies = selectAssemblyListByQuoteId(this.QuoteId)(store.getState())
			.filter((a) => a.unlocked);
		const unlockedAssembliesSequenceIds = unlockedAssemblies.map((a) => a.sequence_id);

		if (dataReference == "select") {
			if (newValue) {
				dispatch(setAssembliesSelected({
					quoteId: this.quote.ID, sequenceIds: unlockedAssembliesSequenceIds, selected: true
				}));
			} else {
				dispatch(setAssembliesSelected({
					quoteId: this.quote.ID, sequenceIds: unlockedAssembliesSequenceIds, selected: false
				}));
			}

			return;
		}
		const storeState = store.getState();
		const assys = selectAssemblyListByQuoteId(quoteSub.ID)(storeState);
		const shadesToChange = assys.filter((a) => a.selected).map((a) => a.sequence_id);

		const quoteJson = {
			id: this.QuoteId,
			shadeAssemblies: []
		};

		shadesToChange.forEach((sId) => {
			if (dataReference == "single_dual") {
				const assembly = this.ChangeDualCoupledStatus(sId, dataReference, newValue, option, true);
				quoteJson.shadeAssemblies.push(assembly);
				return;
			}

			const assembly = self.shadeAssemblies.Items.find((sa) => sa.sequence_id == sId);

			const assemblyJson = {
				sequence_id: assembly.sequence_id
			};

			quoteJson.shadeAssemblies.push(assemblyJson);

			if (dataReference in assembly || option.saveType == self.UI.AssemblyNum) {
				assembly[dataReference] = newValue;
				assemblyJson[dataReference] = newValue;
			} else if (option.varName.includes("2")) {
				// back row
				dataReference = dataReference.replace("2", "");
				assemblyJson.shades = [];
				assembly.shades
					.filter((s) => s.row_coordinate == 1)
					.forEach((s) => {
						s[dataReference] = newValue;
						const shadeJson = {
							row_coordinate: s.row_coordinate,
							column_coordinate: s.column_coordinate
						};
						shadeJson[dataReference] = newValue;
						assemblyJson.shades.push(shadeJson);
					});
			} else if (["roll_direction", "fabric_name"].includes(dataReference)) {
				// forward row
				assemblyJson.shades = [];
				assembly.shades
					.filter((s) => s.row_coordinate == 0)
					.forEach((s) => {
						s[dataReference] = newValue;
						const shadeJson = {
							row_coordinate: s.row_coordinate,
							column_coordinate: s.column_coordinate
						};
						shadeJson[dataReference] = newValue;
						assemblyJson.shades.push(shadeJson);
					});
			} else {
				// Both rows
				if (dataReference === "width") {
					// this is to do the logic just in case there are coupled shades.
					// If the shades aren't coupled its all the rows, if they are coupled it's
					// just the total width field at the assembly level
					if (assembly.is_coupled) {
						assembly.total_width = newValue;
					} else {
						assemblyJson.shades = [];
						assembly.shades.forEach((s) => {
							s[dataReference] = newValue;
							const shadeJson = {
								row_coordinate: s.row_coordinate,
								column_coordinate: s.column_coordinate
							};
							shadeJson[dataReference] = newValue;
							assemblyJson.shades.push(shadeJson);
						});
					}
				} else {
					assemblyJson.shades = [];
					assembly.shades.forEach((s) => {
						s[dataReference] = newValue;
						const shadeJson = {
							row_coordinate: s.row_coordinate,
							column_coordinate: s.column_coordinate
						};
						shadeJson[dataReference] = newValue;
						assemblyJson.shades.push(shadeJson);
					});
				}
			}
		});

		this.RePriceSingleRow(shadesToChange);

		this.save(quoteJson);

		this.UI.reRenderTable();
	}

	isAccessoryOld(partNumber) {
		const quoteAccessories = selectQuoteAccessories(this.QuoteId)(this.quoteLevelVariables().store.getState());
		const accessory = quoteAccessories.find((a) => a.part_number == partNumber);

		const timestamp = accessory?.last_time_priced;

		const lastTimePriced = new Date(timestamp * 1000);

		if (Number.isNaN(lastTimePriced)) return true;

		const currentDate = new Date();

		const dif = currentDate - lastTimePriced;

		const days = dif / (1000 * 60 * 60 * 24);

		if (days > 90) {
			return true;
		}

		return false;
	}

	isAccessoryOld2(lineNumber) {
		const quoteAccessories = selectQuoteAccessories(this.QuoteId)(this.quoteLevelVariables().store.getState());
		const accessory = quoteAccessories.find((a) => a.line_number === lineNumber);

		const timestamp = accessory?.last_time_priced;

		const lastTimePriced = new Date(timestamp);

		if (Number.isNaN(lastTimePriced)) return true;

		const currentDate = new Date();

		const dif = currentDate - lastTimePriced;

		const days = dif / (1000 * 60 * 60 * 24);

		if (days > 90) {
			return true;
		}

		return false;
	}

	setAccessoryQuantity(accessoryToSave, quantity) {
		// Check if we have a quantity of accessories.
		let partNumber = accessoryToSave.part_number ? accessoryToSave.part_number : accessoryToSave;
		const store = this.quoteLevelVariables().store;
		const accessories = selectAccessories(this.QuoteId)(store.getState());
		const accessoriesOnQuote = selectQuoteAccessories(this.QuoteId)(store.getState());

		let isAdd = true;

		const lineNumber = accessoryToSave.line_number ? accessoryToSave.line_number : accessoryToSave;

		if (typeof accessoryToSave === typeof "string") {
			// Check if PartNumber (sku), if so it's an add
		} else if (typeof accessoryToSave === typeof Number(0) || lineNumber > 0) {
			// Check if number, it's an update/delete
			isAdd = false;
			const qa = accessoriesOnQuote.find((acc) => acc.line_number === lineNumber);
			partNumber = qa.part_number;
		}

		const { store: storeLocalRef } = this.quoteLevelVariables();

		const { dispatch } = storeLocalRef;

		const accessory = accessories.find((acc) => acc.part_number === partNumber);

		const reprice = this.Money.Reprice;

		const currentHighestLineNumber = accessoriesOnQuote.reduce((acc, cur) => {
			return cur.line_number > acc ? cur.line_number : acc;
		}, 0);

		const ensureDollarComponents = (newQuoteAccessory) => {
			const { line_number } = newQuoteAccessory;

			let moneys = this.Money.AccessoryMoney.find((accMoney) => accMoney.Id === line_number);
			if (!moneys) {
				moneys = this.Money.AddAndGetMoney({
					...newQuoteAccessory
				});
			}

			const { shipping, total } = moneys;

			const totalMsrp = newQuoteAccessory.msrp;

			// Update the front screen last repriced time

			total.UpdateAmount(totalMsrp);
			shipping.UpdateAmount(newQuoteAccessory.shipping_price);
		};

		const accName = accessory?.name ?? partNumber;

		if (!accName) {
			console.error(accessory);
			console.error(accessories);
			toast.error(`Error adding ${partNumber} to quote. PowerShades engineering has been notified`);
			throw new Error(`Error adding ${partNumber} to quote. Part does not have a name.`);
		}

		if (!isAdd) {
			// If quantity = 0 or non-existent then we need to add the accessory to the quote
			if (quantity === 0) {
				//	this.accessories.Items = accessoriesOnQuote.filter((acc) => acc.line_number !== lineNumber);
				toast(`Removed ${accName}`, {
					type: "success",
					id: `${accName}-delete`,
					duration: 5000
				});
				this.UI.reRenderTable();
				dispatch(deleteQuoteAccessory({
					quoteId: this.QuoteId,
					lineNumber,
					priceCallback: reprice
				})).then((resp) => {
					try {
						if (resp.meta?.requestStatus === "fulfilled") {
							ensureDollarComponents(resp);
						}
					} catch (e) {
						// this.accessories.Items = accessoriesOnQuote;
						toast.error(`Error removing ${accName} from quote. PowerShades engineering has been notified`);
						throw new Error(e);
					}
				});
				// Delete
			} else {
				toast(`Changed # of ${accName} to ${quantity}.`, {
					type: "success",
					id: `added-${accName}-${quantity}`,
					duration: 5000
				});
				// this.accessories.Items = accessoriesOnQuote.map((acc) => {
				// 	if (acc.line_number === lineNumber) {
				// 		acc.quantity = quantity;
				// 	}
				// 	return acc;
				// });
				dispatch(updateQuoteAccessory({
					quoteId: this.QuoteId,
					lineNumber,
					quantity,
					parms: [],
					priceCallback: reprice
				})).then((resp) => {
					try {
						if (resp.meta?.requestStatus === "fulfilled") {
							ensureDollarComponents(resp);
						}
					} catch (e) {
						// this.accessories.Items = accessoriesOnQuote;
						toast.error(`Error changing ${accessory?.name} quantity on quote. PowerShades engineering has been notified`);
						throw new Error(e);
					}
				});
			}
		} else {
			// If quantity > 0 then we need to update the quantity of the accessory
			toast(`Added ${accName} to quote.`, {
				type: "success"
			});
			// this.accessories.Items = [
			// 	...accessoriesOnQuote,
			// 	{
			// 		...accessory,
			// 		order_quantity: quantity,
			// 		line_number: currentHighestLineNumber + 1
			// 	}
			// ];
			dispatch(addQuoteAccessory({
				quoteId: this.QuoteId,
				sku: partNumber,
				quantity,
				parms: [],
				priceCallback: reprice,
				nextLineNumber: currentHighestLineNumber + 1
			})).then((resp) => {
				try {
					if (resp.meta?.requestStatus === "fulfilled") {
						ensureDollarComponents(resp);
					}
				} catch (e) {
					// this.accessories.Items = accessoriesOnQuote;
					toast.error(`Error adding ${accName} to quote. PowerShades engineering has been notified`);
					throw new Error(e);
				}
			});
		}

		this.UI.reRenderTable();
		return selectQuoteAccessories(this.QuoteId)(store.getState());
	}

	setShippingToEvo() {
		const evoAddr = {
			Id: null,
			first_name: "Peter",
			last_name: "Williams",
			company_name: "Evolution Home ",
			address: "266 Applewood Cres.  ",
			address2: null,
			phone: "416-603-9090 X305",
			email: "orders@evolutionhomecorp.com",
			state: "Ontario",
			country: "Canada",
			city: "Concord",
			zip: "L4K 4B4",
			is_residential: 0,
			lift_gate: 0,
			state_code: "ON",
			country_subdivision_id: 72
		};

		this.quote.shipping_address = evoAddr;
	}

	setShippingToNotEvo() {
		// const evoAddr = {
		// 	Id: null,
		// 	first_name: "Peter",
		// 	last_name: "Williams",
		// 	company_name: "Evolution Home ",
		// 	address: "266 Applewood Cres.  ",
		// 	address2: null,
		// 	phone: "416-603-9090 X305",
		// 	email: "orders@evolutionhomecorp.com",
		// 	state: "Ontario",
		// 	country: "Canada",
		// 	city: "Concord",
		// 	zip: "L4K 4B4",
		// 	is_residential: 0,
		// 	lift_gate: 0,
		// 	state_code: "ON",
		// 	country_subdivision_id: 72
		// };
		// this.quote.shipping_address = evoAddr;
	}

	setEvo(newVal) {
		return;
		const currentval = this.quote.IsEvolutionShippingForced;

		if (currentval !== newVal) {
			newVal ? this.setShippingToEvo() : this.setShippingToNotEvo();
		}
		this.quote.IsEvolutionShippingForced = newVal ? 1 : 0;
		this.UI.reRenderTable();
	}

	getEvo() {
		return this.quote.IsEvolutionShippingForced == 1;
	}

	setDistributor(distributorId) {
		api.assignDistributor(distributorId, this.QuoteId).then((resp) => {
			const { success, is_evolution } = resp.data;

			if (success) {
				this.setEvo(is_evolution);
				this.quote.distributor_id = distributorId;
				this.UI.reRenderTable();
			}
		});
	}

	setDistributorLocation(distributorLocationId) {
		api.assignDistributorLocation(distributorLocationId, this.QuoteId).then((resp) => {
			const { success, is_evolution } = resp.data;

			if (success) {
				this.setEvo(is_evolution);
				this.quote.distributor_location_id = distributorLocationId;
				this.UI.reRenderTable();
			}
		});
	}

	setToEvolution(isForceShipped) {
		const self = this;

		// api.assignForcedEvolutionShipping(isForceShipped == "true", self.QuoteId).then((resp) => {
		// 	const { success, is_evolution } = resp.data;

		// 	if (success) {
		// 		self.setEvo(is_evolution);
		// 	}
		// });
	}

	setRepresentative(representativeId) {
		api.assignRepresentative(representativeId ?? 0, this.QuoteId).then((resp) => {
			const { success, is_evolution } = resp.data;

			if (success) {
				this.setEvo(is_evolution);
				this.quote.representative_id = representativeId;
				this.UI.reRenderTable();
			}
		});
	}

	QuoteFieldChange(dataReference, newValue, override_shipping = false) {
		const quoteJson = {
			id: this.QuoteId
		};

		quoteJson[dataReference] = newValue;

		this.quote[dataReference] = newValue;

		const store = this.quoteLevelVariables().store;
		const quoteId = this.QuoteId;

		const ignoredFields = ['title']

		this.save(quoteJson, () => {
			if (ignoredFields.includes(dataReference)) return;
			const assemblySequenceIds = selectAssemblyListByQuoteId(quoteId)(store.getState()).map((a) => a.sequence_id);
			store.dispatch(repriceQuote({
				quoteId: quoteId
			}))
			store.dispatch(loadQuoteById(quoteId));
		});
	}

	/**
	 * @param {QuoteSubstitution} oldQuote
	 * @returns {void}
	 * @notes This method returns void but has many side effects, affecting oldQuote specifically.
	 * oldQuote.success is set to true
	 * oldQuote.shade_assemblies is assigned the value of this.shadeAssemblies.Items
	 * oldQuote.shades is assigned an empty array
	 * oldQuote.quote_options is assigned a new object with the following properties:
	 * 	title: {varName: 'title', type: 'text', label:
	 *     () => (this.is_order ? 'Order Title' : 'Quote Title'), value: ''}
	 * 	reference_number: {varName: 'reference_number', type: 'text', label: 'Reference #', value: ''}
	 * 	notes: {varName: 'notes', type: 'textarea', label: 'Notes', value: ''}
	 * Shades are added to oldQuote using the oldQuote.addShade(new_shade, true) method.
	 * Fabric samples are set using oldQuote.setFabricSamples(fabric, fabric.quantity, false) method.
	 * QuoteSubstitution includes all the modifications.
	 */
	getOldDataStyle(oldQuote) {
		const { quote } = this;

		this.getInitialValues(quote, oldQuote);

		oldQuote.success = true;
		oldQuote.shade_assemblies = this.shadeAssemblies.Items;

		const shades = [];

		const INDOOR_SHADE_OPTIONS = new IndoorShadeFormFields(this.UI);
		const OUTDOOR_SHADE_OPTIONS = new OutdoorShadeFormFields(this.UI);

		oldQuote.shades = [];

		oldQuote.quote_options = {
			title: {
				varName: "title",
				type: "text",
				label: () => (this.is_order ? "Order Title" : "Quote Title"),
				value: ""
			},
			reference_number: {
				varName: "reference_number",
				type: "text",
				label: "Reference #",
				value: ""
			},
			notes: {
				varName: "notes",
				type: "text",
				label: "Notes",
				value: ""
			}
		};

		this.shadeAssemblies.Items.map(transformShadeAssembly).forEach((s, ind) => {
			const options = (
				s.indoor_outdoor === "outdoor" ? OUTDOOR_SHADE_OPTIONS : INDOOR_SHADE_OPTIONS
			).map((opt) => {
				if (s[opt.varName] !== undefined) {
					opt.value = s[opt.varName];
				}

				return opt;
			});

			options.push({ varName: "motor", value: s.motor });

			const new_shade = new Shade(options, this);

			new_shade.id = parseInt(s.id);

			oldQuote.addShade(new_shade, true);
		});

		const quoteAccessories = selectQuoteAccessories(this.QuoteId)(this.quoteLevelVariables().store.getState());

		// this.globals.onLoad(() => {
		// 	quote.accessories?.forEach((accdata) => {
		// 		let acc = quoteAccessories.find((a) => parseInt(a.id) === parseInt(accdata.accessory_id));

		// 		if (!acc) {
		// 			acc = quoteAccessories.find((a) => a.name === accdata.name);
		// 		}

		// 		if (!acc) {
		// 			return;
		// 		}

		// 		// acc.quantity = parseInt(accdata.quantity);
		// 		// acc.msrp = accdata.msrp;
		// 	});
		// });

		const msrp = {};
		msrp.shadeAssemblies = [];

		this.shadeAssemblies.Items.forEach((sa) => { });

		quote.fabric_samples?.forEach((fabric) =>
			oldQuote.setFabricSampleQuantity(fabric, fabric.quantity, false));
	}

	getInitialValues(quote, oldQuote) {
		const pull_from_data = [
			{ key: "ID", type: "int" },
			{ key: "order_status_index", type: "int" },
			{ key: "manufacturer_id", type: "int" },
			{ key: "territory_id", type: "int" },
			{ key: "territory_multiplier", type: "float" },
			{ key: "created_by", type: "string" },
			{ key: "is_paid", type: "bool" },
			{ key: "created", type: "string" },
			{ key: "dealer_id", type: "int" },
			{ key: "distributor_id", type: "int" },
			{ key: "representative_id", type: "int" },
			{ key: "require_payment", type: "bool" },
			{ key: "is_order", type: "bool" },
			{ key: "is_shipped", type: "bool" },
			{ key: "date_shipped", type: "string" },
			{ key: "territory_multiplier", type: "float" },
			{ key: "override_shade_shipping_cost", type: "float" },
			{ key: "coupons", type: "arr" },
			{ key: "populate_dashboard_tasks", type: "arr" }
		];

		const newQuote = {};

		const typeTransform = {
			int: (val) => parseInt(val),
			float: (val) => parseFloat(val),
			bool: (val) => !!val,
			string: (val) => `${val}`,
			arr: (arr) => arr
		};

		if (!pull_from_data) {
			ROOT.alert({
				quickFormat: "error",
				text: "Something went wrong retrieving the quote data. Please refresh and try again.",
				acceptButtonText: "Refresh"
			}).then(() => location.reload());
			throw new Error("Something went wrong retrieving the quote data");
		}

		pull_from_data?.filter((pull) => quote[pull.key] != null)
			.map((pull) => ({
				key: pull.key,
				value: quote[pull.key] === null ? null : typeTransform[pull.type](quote[pull.key])
			}))?.forEach((item) => (newQuote[item.key] = item.value));

		newQuote.quote_options = () => ({
			title: {
				varName: "title",
				type: "text",
				label: () => (this.is_order ? "Order Title" : "Quote Title"),
				value: ""
			},
			reference_number: {
				varName: "reference_number",
				type: "text",
				label: "Reference #",
				value: ""
			},
			notes: {
				varName: "notes",
				type: "text",
				label: "Notes",
				value: ""
			}
		});

		return newQuote;
	}

	changeFabricSampleQuantity(fabric_id, quantity) {
		const store = this.quoteLevelVariables().store
		const dispatch = store.dispatch;
		const storeState = store.getState();
		const fabrics = SelectFabrics(this.QuoteId)(storeState);
		const quoteFabricSamples = selectQuoteFabricSampleListByQuoteId(this.QuoteId)(storeState);
		const { name } = fabrics?.find((f) => f.name == fabric_id) ?? {name: fabric_id}; 

		const currenQuantity = quoteFabricSamples.find((f) => f.Name == name)?.Quantity ?? 0;

		// api.changeFabricSampleQuantity(this.QuoteId, fabric_id, quantity, name);

		if (currenQuantity == quantity) {
			return;
		} else if (quantity == 0) {
			dispatch(deleteQuoteFabricSample({
				quoteId: this.QuoteId,
				name: name,
			}));

		} else if (currenQuantity == 0) {
			dispatch(addQuoteFabricSample({
				quoteId: this.QuoteId,
				name: name,
				quantity: quantity,
			}));

		} else {
			dispatch(updateQuoteFabricSample({
				quoteId: this.QuoteId,
				name: name,
				quantity: quantity,
			}));
		}


		this.UI.reRenderTable();
	}

	ChangeCoupledStatus(sequenceId, dataReference, newValue, column, save = true) {
		if (dataReference == "is_coupled") {
			// console.log(assembly);

			const assembly = this.shadeAssemblies.Items.find((sa) => sa.sequence_id === sequenceId);

			assembly[dataReference] = newValue;

			const assemblyJson = {
				sequence_id: sequenceId
			};
			assemblyJson[dataReference] = newValue;

			if (!newValue) {
				const newShades = assembly.shades.filter((s) => s.column_coordinate === 0);
				assemblyJson.shades = newShades;
				assembly.shades = newShades;
			}

			const quoteJson = {
				id: this.QuoteId,
				shadeAssemblies: [assemblyJson]
			};

			if (save) {
				this.save(quoteJson);
			}

			// console.log(assembly);
		} else if (dataReference == "shades") {
			const assemblies = this.shadeAssemblies.Items;

			const assembly = assemblies.find((sa) => sa.sequence_id === sequenceId);

			assembly[dataReference] = newValue;

			const assemblyJson = { ...assembly };
			assemblyJson[dataReference] = newValue;
			assemblyJson.is_coupled = true;

			assembly.total_width
				= assembly.total_width
				?? assembly.shades.find((s) => s.row_coordinate == 0 && s.column_coordinate == 0).width;

			const newAssemblies = ([...this.shadeAssemblies.Items]).map((sa) => {
				if (sa.sequence_id === sequenceId) {
					return assemblyJson;
				}
				return { ...sa };
			});

			const quoteJson = {
				id: this.QuoteId,
				shadeAssemblies: newAssemblies
			};

			this.save(quoteJson);

			const columns = assembly.shades
				.map((s) => s.column_coordinate)
				.filter((value, index, self) => self.indexOf(value) === index);

			const max = columns.reduce((a, b) => Math.max(a, b));

			for (let i = 0; i < max; i++) {
				if (!columns.includes(i)) {
					// Do thing
					assembly.shades?.forEach((s) => {
						if (s.column_coordinate > i) {
							s.column_coordinate--;
						}
					});

					break;
				}
			}
		}
	}

	ChangeWidth(sequenceId, dataReference, newValue, column) {
		let assembly = this.shadeAssemblies.Items.find((sa) => sa.sequence_id == sequenceId);

		if (!assembly) {
			assembly = this.shadeAssemblies.Items.find((sa) => sa.sequence_id == sequenceId[0].sequenceId);
		}

		const quoteJson = {
			id: this.QuoteId,
			shadeAssemblies: []
		};

		const assemblyToSave = {
			...assembly
		};

		quoteJson.shadeAssemblies.push(assemblyToSave);

		if (Array.isArray(sequenceId)) {
			sequenceId?.forEach((sId) => {
				const shade = assembly.shades.find((s) => s.row_coordinate == sId.row && sId.column == s.column_coordinate);
				shade.width = newValue;
			});
		} else if (assembly.is_coupled) {
			// Change total width
			assembly.total_width = newValue;
			assemblyToSave.total_width = newValue;
		} else {
			// Change column 1 shades

			assemblyToSave.shades = [];

			assembly.shades.filter((s) => s.column_coordinate == 0)
				.forEach((s) => {
					s.width = newValue;
					assemblyToSave.shades.push({ ...s });
				});
		}

		this.save(quoteJson);
	}

	ChangeDualCoupledStatus(sequenceId, dataReference, newValue, column, fromMassEdit = false) {
		const assembly = this.shadeAssemblies.Items.find((sa) => sa.sequence_id == sequenceId);

		const oldIsDual = assembly.single_dual == "Dual";
		const oldIsCoupled = assembly.is_coupled == 1;

		const newIsDual = newValue.includes("Dual");
		const newIsCoupled = newValue.includes("Coupled");

		const quoteJson = {
			id: this.QuoteId
		};

		const assemblySave = {
			...assembly
		};

		quoteJson.shadeAssemblies = [assemblySave];

		let newShades = [];

		if (oldIsDual !== newIsDual) {
			let single_dual = "Dual";

			if (!newIsDual) {
				// Dual to single
				assembly.shades.forEach((s) => {
					if (s.row_coordinate == 0) {
						newShades.push(s);
					}
				});
				single_dual = "Single";
			} else {
				// Single to Dual
				assembly.shades.forEach((s) => {
					if (s.row_coordinate == 0) {
						newShades.push(s);
						const newShade = { ...s };
						newShade.id = null;
						newShade.fabric_name = null;
						newShade.roll_direction = null;
						newShade.row_coordinate = 1;
						newShade.make_this_shade = 1;
						newShades.push(newShade);
					}
				});
			}

			assembly.single_dual = single_dual;
			assembly.shades.forEach((s) => (s.single_dual = single_dual));
			assemblySave.single_dual = single_dual;

			assembly.is_dual = newIsDual;
			assemblySave.is_dual = newIsDual;
		}

		if (oldIsCoupled !== newIsCoupled) {
			let total_width = null;
			let is_coupled = false;
			let band_count = null;

			if (newIsCoupled) {
				is_coupled = true;
				total_width = assembly.shades[0].width;
				band_count = 1;
				newShades = newShades.length === 0 ? assembly.shades : newShades;
				assembly.shades.forEach((s) => (s.width = 0));
			} else {
				// remove shades
				newShades
					= newShades.length === 0
						? assembly.shades.filter((s) => s.column_coordinate == 0)
						: newShades.filter((s) => s.column_coordinate == 0);

				const newWidth = assembly.total_width > 240 ? 240 : assembly.total_width;

				newShades.forEach((s) => (s.width = newWidth));
			}

			assembly.is_coupled = is_coupled;
			assemblySave.is_coupled = is_coupled;

			assembly.band_count = band_count;
			assemblySave.band_count = band_count;

			assembly.total_width = total_width;
			assemblySave.total_width = total_width;
		}

		if (oldIsCoupled == newIsCoupled && oldIsDual == newIsDual) {
			newShades = assembly.shades;
		}

		assembly.shades = newShades;
		assemblySave.shades = newShades;

		if (!fromMassEdit) {
			this.save(quoteJson);
		} else {
			return assemblySave;
		}
	}

	ChangeAssemblyValue(sequenceId, dataReference, newValue, column) {
		const shadeAssembly = this.shadeAssemblies.Items.find((s) => s.sequence_id == sequenceId);

		shadeAssembly[dataReference] = newValue;

		const assembly = {
			...shadeAssembly
		};
		assembly[dataReference] = newValue;

		const quoteJson = {
			id: this.QuoteId,
			shadeAssemblies: [assembly]
		};

		this.save(quoteJson);
	}

	ChangeAccessoryValue(accessoryId, dataReference, value) {
		quoteAccessories.find((s) => s.id == accessoryId)[dataReference] = newValue;

		const oldMSRP = this.Money.TotalMSRP;

		const accessory = {
			id: accessoryId
		};

		accessory[dataReferece] = value;

		const quoteJson = {
			id: this.QuoteId,
			accessories: [id]
		};

		this.save(quoteJson);
	}

	get shades() {
		return this.shadeAssemblies.Items.reduce((list, sa) => list.concat(sa.shades), []);
	}

	TransformIds(shadeIds) {
		const shadeAssemblies = this.shadeAssemblies.Items;

		if (!Array.isArray(shadeIds)) {
			return [shadeIds];
		}

		return shadeIds
			.map((s) => (s.sequenceId || s.sequenceId === 0 ? s.sequenceId : s))
			.filter((value, index, self) => self.indexOf(value) === index);
	}

	RePriceSingleRow(sequenceIds, refreshFrontPage) {
		const ids = this.TransformIds(sequenceIds);

		const self = this;

		const assySeqIds = this.shadeAssemblies.Items.filter((sa) => ids.includes(sa.sequence_id));

		const store = this.quoteLevelVariables().store;

		store.dispatch(wentToQuote(self.QuoteId));
		// const quote = { ...this.quote };
		// quote.shadeAssemblies = this.shadeAssemblies.Items.filter(
		// 	(sa) => ids.includes(sa.sequence_id) || sa.unlocked
		// );

		// const refresh = refreshFrontPage;
		// const shadeReadyForPricing = quote.shadeAssemblies.every((sa) => this.CheckShadeReadyForPricing(sa));
		// if (!shadeReadyForPricing) return;
		// api
		// 	.calculateAndSaveAssemblyPrice(
		// 		quote,
		// 		quote.ID,
		// 		self?.quoteLevelVariables()?.noPricing ?? false
		// 	)
		// 	.then((resp) => {
		// 		const pricing = resp.data;
		// 		this.Money.Reprice(pricing, refresh);
		// 	});
	}

	UpdateAssemblyMoney(sequenceId, msrp, shipping) {
		const assembly = this.shadeAssemblies.Items.find((sa) => sa.sequence_id == sequenceId);

		assembly.msrp = msrp;
		assembly.shipping_price = shipping;

		this.shadeAssemblies.msrp = this.shadeAssemblies.Items.reduce(
			(total, sa) => sa.msrp + total,
			0
		);
	}

	GetPrice(quote, callback) {
		api.calculateAssemblyPrice(quote, this.QuoteId).then((resp) => {
			callback(resp);
		});
	}

	RePriceWholeQuote(callBack, override_shipping = false) {
		const quote = { ...this.quote };
		const self = this;
		quote.shadeAssemblies = this.shadeAssemblies.Items.filter((i) => i.unlocked);
		quote.override_shipping = override_shipping;

		this.isRepricing = this.isRepricing ?? false;

		if (this.isRepricing) {
			this.priceAgain = true;
			return;
		}

		this.isRepricing = true;

		// api
		// 	.calculateAndSaveAssemblyPrice(
		// 		quote,
		// 		quote.ID,
		// 		self?.quoteLevelVariables()?.noPricing ?? false
		// 	)
		// 	.then((resp) => {
		// 		const pricing = resp.data;
		callBack && callBack();
		// 		// console.log(pricing);
		// 		if (pricing.quote) {
		// 			this.Money.Reprice(pricing);
		// 		}
		// 	})
		// 	.finally(() => {
		// 		this.isRepricing = false;
		// 		if (self.priceAgain) {
		// 			self.priceAgain = false;
		// 			self.RePriceWholeQuote();
		// 		}
		// 	});
	}

	checkMotorChange(newAssembly) {
		const oldAssembly = this.shadeAssemblies.Items.find((sa) => sa.sequence_id == newAssembly.sequence_id);

		if (!oldAssembly) return;

		const oldMotor = oldAssembly.shades[0].motor;

		const newMotor = newAssembly.shades[0].motor;

		const isWhip = oldAssembly.shades[0].motor_type != "low_voltage";
		const isSolar = oldAssembly.shades[0].lv_power_source == "solar_panel";

		if (oldMotor != newMotor) {
			const shadeObj = this.shadeSubstitute(oldAssembly.sequence_id);
			shadeObj.changeAccessoryCharging(false, isSolar, isWhip);
			oldAssembly.shades.forEach((s) => (s.motor = newAssembly.shades[0].motor));
			shadeObj.changeAccessoryCharging(true, isSolar, isWhip);
		}
	}

	ChangeShadeValue(shadeIds, dataReference, newValue, col) {
		// if(newValue === undefined) return;
		const shadeAssemblies = this.shadeAssemblies.Items;

		// If shadeIds is not an array it's just the plane assembly Id. Either that's an error,
		// or it means the entire list of shades need to be changed.
		if (!Array.isArray(shadeIds)) {
			shadeIds = shadeAssemblies
				.find((a) => a.sequence_id === shadeIds)
				.shades.map((s) => ({
					sequenceId: shadeIds,
					row: s.row_coordinate,
					column: s.column_coordinate
				}));
		}

		const assemblies = [];

		shadeIds.forEach((sId) => {
			const shadeAssy = shadeAssemblies.find((sa) => sa.sequence_id == sId.sequenceId)
			const shadeObj = shadeAssy
				.shades.find((s) => sId.row == s.row_coordinate && sId.column == s.column_coordinate);

			if (!shadeObj) return;

			const shadeId = shadeObj.id;

			shadeObj[dataReference] = newValue;

			if (newValue === undefined) return;

			let assembly = assemblies.find((a) => a.sequence_id == sId.sequenceId);
			if (!assembly) {
				assembly = {
					...shadeAssemblies.find((sa) => sa.sequence_id == sId.sequenceId)
				};
				assemblies.push(assembly);
			}
			// const shade = {
			// 	id: shadeId,
			// 	row_coordinate: sId.row,
			// 	column_coordinate: sId.column
			// };

			const newShades = shadeAssy.shades.map((s) => {
				if (s.id == shadeId || (s.row_coordinate == sId.row && s.column_coordinate == sId.column)) {
					s[dataReference] = newValue;
				}
				return s;
			})

			shadeAssy.shades = newShades;
			assembly.shades = newShades;
		});

		const quoteJson = {
			id: this.QuoteId,
			shadeAssemblies: assemblies
		};

		this.save(quoteJson);
	}

	// ChangeManyShadesValue(shadeIds, dataReference, newValue, column) {

	//     if (shadeIdList === null && !Array.isArray(assemblyId)) {
	//         shadeIdList = this.shadeAssemblies.Items.find(s => s.id == assemblyId).shades.map(s => s.id);
	//     }

	//     shadeIdList.forEach((id) => {
	//         this.shadeAssemblies.Items.find(s => s.id == assemblyId).shades.find(s => s.id === id)[dataReference] = newValue;
	//     })

	//     let oldMSRP = this.Money.TotalMSRP;

	//     let shades = [];

	//     for (let index = 0; index < shadeIdList.length; index++) {
	//         let shade = {
	//             id: shadeIdList[index]
	//         };
	//         shade[dataReference] = newValue;
	//         shades.push(shade);
	//     }

	//     let quoteJson = {
	//         id: this.QuoteId,
	//         shadeAssemblies: [
	//             {
	//                 id: assemblyId,
	//                 shades: shades
	//             }
	//         ]
	//     }

	//     this.save(quoteJson);

	// }

	ChangeDualStatus(sequenceId, dataReference, newValue, column) {
		const newIsDual = newValue === "Dual";

		const shadeAssembly = this.shadeAssemblies.Items.find((sa) => sa.sequence_id === sequenceId);

		if (newIsDual && shadeAssembly.single_dual === "Dual") return;

		let quoteJson = {};

		const newShades = [];

		if (!newIsDual) {
			// Dual to single

			shadeAssembly.shades.forEach((s) => {
				if (s.row_coordinate == 0) {
					newShades.push(s);
				}
			});
		} else {
			// Single to Dual

			shadeAssembly.shades.forEach((s) => {
				if (s.row_coordinate == 0) {
					newShades.push(s);
					const newShade = { ...s };
					newShade.id = null;
					newShade.fabric_name = null;
					newShade.roll_direction = null;
					newShade.row_coordinate = 1;
					newShades.push(newShade);
				}
			});
		}

		const assembly = this.shadeAssemblies.Items.find((sa) => sa.sequence_id === sequenceId);

		assembly.shades = newShades;

		assembly.shades.forEach((s) => {
			s[dataReference] = newValue;
		});

		assembly[dataReference] = newValue;

		const assemblySave = {
			sequence_id: sequenceId
		};
		assemblySave[dataReference] = newValue;

		quoteJson = {
			id: this.QuoteId,
			shadeAssemblies: [assemblySave]
		};

		this.save(quoteJson);
	}

	save(json, callBack = false) {
		if (this.started === undefined) {
			this.started = false;
		}

		const self = this;
		const jsonSas = json.shadeAssemblies;
		if (jsonSas) {
			json.shadeAssemblies = this.shadeAssemblies.Items
				.filter((sa) => sa.unlocked && (sa.shade_type_id == 1 || sa.shade_type_id == 2))
				.map((sa) => {
					const saShades = sa.shades;
					if (jsonSas.some((saI) => saI.sequence_id == sa.sequence_id)) {
						const jsonSa = jsonSas.find((saI) => saI.sequenceId == sa.sequenceId);
						let jsonShades = jsonSa.shades;

						if (!jsonShades) {
							jsonShades = saShades;
						} else {
							jsonShades = jsonShades.map((s) => {
								const shade = saShades.find((ss) => ss.column_coordinate == s.column_coordinate && ss.row_coordinate == s.row_coordinate);
								if (shade) {
									return { ...s, ...shade };
								}
								return s;
							});
						}

						const finalSa = { ...jsonSa, ...sa, shades: jsonShades };
						return finalSa;
					}
					return sa;
				});
		}

		const item = {
			json
		};

		item.callBack = () => {
			callBack && callBack();
			const ids = json.shadeAssemblies.map((sa) => sa.sequence_id);
			// self.RePriceSingleRow(ids);
		};

		this.start(item);
	}

	DuplicateShade(sequenceId) {
		const oldAssembly = this.shadeAssemblies.Items.find((sa) => sa.sequence_id == sequenceId);

		const newShade = JSON.parse(JSON.stringify(oldAssembly));

		newShade.id = null;

		const nameSet = oldAssembly?.shade_name?.toString()?.split(" ") ?? "";

		let number = nameSet[nameSet.length - 1];

		let newName = oldAssembly.shade_name;

		if (isNaN(number) || number === "") {
			number = "1";
			nameSet.push(number);
		}
		let newNumber = parseInt(number) + 1;

		const shadeNames
			= this.shadeAssemblies.Items.map((sa) => sa?.shade_name?.toString().trim()) ?? "";

		nameSet[nameSet.length - 1] = newNumber;

		newName = nameSet.join(" ");

		while (shadeNames.includes(newName.trim())) {
			nameSet[nameSet.length - 1] = ++newNumber;
			newName = nameSet.join(" ");
		}

		const nextSequenceId
			= this.shadeAssemblies.Items.reduce(
				(seq_id, sa) => (seq_id > sa.sequence_id ? seq_id : sa.sequence_id),
				0
			)
			+ 0
			+ 1;

		const new_shade_info = {
			new_name: newName,
			sequence_id: oldAssembly.sequence_id,
			quote_id: this.QuoteId,
			new_sequence_id: nextSequenceId
		};

		newShade.shade_name = newName;
		newShade.sequence_id = nextSequenceId;
		newShade.series_id = nextSequenceId;
		newShade.sequence_id_duplicate = sequenceId;

		this.shadeAssemblies.Items.push(newShade);

		this.Money.AddAssemblyMSRP(
			nextSequenceId,
			this.Money.GetMsrp(sequenceId, "shade").amount,
			oldAssembly.indoor_outdoor
		);
		this.Money.AddAssemblyShipping(
			nextSequenceId,
			this.Money.GetShipping(sequenceId, "shade").amount,
			oldAssembly.indoor_outdoor
		);

		const self = this;

		const shade = self.shadeSubstitute(nextSequenceId);
		const isSolarOrAc
			= shade.val("lv_power_source") === "solar_panel" || shade.val("motor_type") == "high_voltage";
		const isHardwired = shade.val("motor_type") !== "low_voltage";
		shade.changeAccessoryCharging(true, isSolarOrAc, isHardwired);

		this.UI.reRenderTable();

		self.queuedDuplicates = (self.queuedDuplicates ?? 0) + 1;

		api
			.duplicateShade(new_shade_info, self.QuoteId)
			.then((resp) => {
				const { data } = resp;

				const newId = data.assemblyId;

				if (!newId || newId == 0) {
					const x = 0;

					const y = 0 + x;
				}

				newShade.id = newId;

				newShade.shades.forEach((sa) => (sa.shade_assembly_id = newId));

				self.RePriceWholeQuote(() => { });

				self.UI.reRenderTable();
			})
			.finally(() => {
				self.queuedDuplicates--;
			});
	}

	async start(item) {
		if (!this.updateQueue) {
			this.updateQueue = [];
		}

		this.updateQueue = [item];

		this.check();
	}

	async check(keeGoing = false) {
		if (!(!this.started || keeGoing)) return;

		this.started = true;
		try {
			this.quoteLevelVariables().store.dispatch(wentToQuote(this.QuoteId));
		} catch (e) {
			console.error(e);
		}

		if (this.updateQueue.length > 0) {
			const { json, callBack } = this.updateQueue.shift();

			api
				.saveQuoteTwo(
					{
						quote: json
					},
					json.id
				)
				.then((resp) => {
					this.updateData(resp, json);
					this.check(true);
					callBack && callBack();
				})
				.catch((resp) => {
					this.check(true);
				});
		} else {
			this.started = false;
		}
	}

	updateData(resp, json) {
		if (json.shadeAssemblies && json.shadeAssemblies.length > 0) {
			const { dispatch } = this.quoteLevelVariables().store;
			dispatch(reloadAssembliesById({ quoteId: this.QuoteId }));
		}
		return;
		const { data } = resp;

		const shadeAssemblies = data.shadeAssemblies.Items;

		const oldAssemblies = this.shadeAssemblies.Items;

		shadeAssemblies.forEach((assembly, index, assemblies) => {
			const oldAssembly = oldAssemblies.find((sa) => sa.id == assembly.id);

			oldAssembly.last_time_priced = assembly.last_time_priced;
		});

		// console.log(resp);
		return;

		if (data) {
			this.Load({
				shadeAssemblies: data.shadeAssemblies,
				accessories: data.accessories,
				fabricSamples: data.fabricSamples,
				quote: data.Quote
			});

			this.Money.Load({
				money: data.money,
				shipping: data.Shipping,
				msrp: data.MSRP,
				currencies: data.money.currency
			});

			this.Money.LoadMoney();
		}

		return;

		const accessories = data.accessories.Items;

		const oldAccessories = this.accessories.Items;

		accessories.forEach((accessory) => {
			const oldAccessory = oldAccessories.find((a) => a.id === accessory.id);

			Object.keys(accessory).forEach((key) => {
				if (accessory[key] !== oldAccessory[key]) {
					// update method
					oldAccessory[key] = accessory[key];
				}
			});
		});

		const fabricSamples = data.fabricSamples.Items;

		const oldFabrics = this.fabricSamples.Items;

		fabricSamples.forEach((fabricSample) => {
			const oldFabricSample = oldFabrics.find((fs) => fs.id === fabricSample.id);

			Object.keys(fabricSample).forEach((key) => {
				if (fabricSample[key] !== oldFabricSample[key]) {
					// update method
					oldFabricSample[key] = fabricSamle[key];
				}
			});
		});
	}

	reOrderSequences(sequenceSet, noRerender = false) {
		const items = Object.entries(sequenceSet);

		items.forEach((set) => {
			const [seqId, seriesId] = set;

			const shadeAssembly = this.shadeAssemblies.Items.find((s) => s.sequence_id == seqId);

			shadeAssembly.series_id = seriesId;
		});

		if (!noRerender) {
			this.UI.reRenderTable();
		}
	}

	setFasciaCombinations(fasciaCombinations) {
		this.shadeAssemblies.Advanced.find((a) => a.name == "fascia_combinations").sets
			= fasciaCombinations;
	}

	getFasciaCombinations() {
		const idk  = selectQuoteAssemblyCombination(this.QuoteId)(this.quoteLevelVariables().store.getState()) ?? { Fascias: [] };

		return idk.Fascias;
	}

	deleteFasciaCombination(fc_id) {
		// const fasciaCombinations = this.getFasciaCombinations();

		// const sequenceIds = fasciaCombinations
		// 	.find((f) => f.id == fc_id)
		// 	.assemblies.map((a) => a.sequence_id);

		// sequenceIds.forEach((si) => this.unlockShade(si));

		// api.deleteCombinedFascia(this.QuoteId, fc_id).then((resp) => {
		// 	const { data } = resp;

		// 	if (!data.success) return;

		// 	const fascia_combination = data.fascia_combinations;

		// 	this.setFasciaCombinations(fascia_combination);
		// 	this.RePriceWholeQuote();

			this.UI.reRenderTable();
		// });
	}

	unlockAndRepriceWholeQuote() {
		const sequenceIds = this.shadeAssemblies.Items.map((sa) => sa.sequence_id);

		sequenceIds.forEach((si) => this.unlockShade(si));
		this.RePriceWholeQuote();
	}

	unlockAllShades() {
		const sequenceIds = this.shadeAssemblies.Items.map((sa) => sa.sequence_id);

		sequenceIds.forEach((si) => this.unlockShade(si));

		this.UI.reRenderTable();
	}

	addHWItem(sku, quantity = 1) {
		/*
 if (oldQuantity !== quantity) {
				api.calculateAndSaveAssemblyPrice({ ID: this.quote.ID, shadeAssemblies: []}, this.quote.ID,  self?.quoteLevelVariables()?.noPricing ?? false ).then(resp => {
					let pricing = resp.data;
					this.Money.Reprice(pricing, true);
				});
				if (oldQuantity > quantity)
						ROOT.toast({
							text: `Removed ${oldQuantity - quantity} ${accessory.name}`,
							type: "success",
						});
				if (oldQuantity < quantity && oldQuantity === 0)
						ROOT.toast({
							text: `Added ${quantity} ${accessory.name}`,
							type: "success",
						});
				if (oldQuantity < quantity) {
					ROOT.toast({
						text: `Added ${quantity - oldQuantity} ${accessory.name}`,
						type: "success",
					});
				}
		*/
		const hwItem = QUOTE_GLOBALS.HW_PARTS.find((p) => p.sku == sku);

		const { cost, msrp, description } = hwItem;
		let lineNumber = this.hwRequestItems.reduce(
			(biggest, hw) => (hw.line_number > biggest ? hw.line_number : biggest),
			-1
		);

		lineNumber = lineNumber > 0 ? lineNumber + 1 : 1;

		const newHwItem = {
			sku,
			cost,
			description,
			msrp,
			quote_id: this.quote.ID,
			line_number: lineNumber
		};
		console.log(`requested quantity: ${quantity}`);
		newHwItem.quantity = quantity;

		this.hwRequestItems.push(newHwItem);

		const self = this;

		const store = this.quoteLevelVariables().store;
		const dispatch = store.dispatch;
		const hwItemMain = QUOTE_GLOBALS.HW_PARTS.find((p) => hwItem.sku == p.sku);

		dispatch(addQuoteHwItem({
			quoteId: this.QuoteId,
			sku: sku,
			lineItemNumber: lineNumber
		})).then((item) => {
			self.RePriceWholeQuote();
			console.log("Attempting to fire toast from updateHWItem - using toast");
			toast(`Added ${hwItemMain.sku} to ${quantity} (${hwItemMain.uom})`, { type: "success" });
		});

		// api.createHWItem(this.quote.ID, sku).then((resp) => {
		// 	ROOT.toast(`Added ${quantity} ${newHwItem.uom} ${newHwItem.sku}`, {
		// 		type: "success"
		// 	});

		// 	// If fail alert?
		// 	self.RePriceWholeQuote();

		// 	// LineNumber
		// });

		this.UI.reRenderTable();
	}

	updateHWItem(lineNumber, quantity, cost, msrp) {


		const self = this;
		const store = this.quoteLevelVariables().store;
		const dispatch = store.dispatch;
		const hwItem = selectHWRItemsListByQuoteId(this.QuoteId)(store.getState()).find((hw) => hw.line_number == lineNumber);
		const hwItemMain = QUOTE_GLOBALS.HW_PARTS.find((p) => hwItem.part_number == p.sku);
		dispatch(updateQuoteHwItem({
			quoteId: this.QuoteId,
			quantity,
			lineNumber
		})).then((item) => {
			self.RePriceWholeQuote();
			console.log("Attempting to fire toast from updateHWItem - using toast");
			toast(`Updated ${hwItemMain?.sku} to ${quantity} (${hwItemMain?.uom})`, { type: "success" });
		});
		// api.updateHWItem(this.quote.ID, lineNumber, quantity).then((resp) => {
		// 	console.log("Attempting to fire toast from updateHWItem - using toast");
		// 	toast(`Updated ${currentSet.sku} to ${quantity} (${currentSet.uom})`, { type: "success" });
		// 	// If fail alert?
		// 	self.RePriceWholeQuote();
		// });
	}

	deleteHWItem(lineNumber) {
		const self = this;
		
		const store = self.quoteLevelVariables().store;
		const dispatch = store.dispatch;
		const hwItem = selectHWRItemsListByQuoteId(this.QuoteId)(store.getState()).find((hw) => hw.line_number == lineNumber);
		const hwItemMain = QUOTE_GLOBALS.HW_PARTS.find((p) => p.sku == hwItem.part_number);

		dispatch(deleteQuoteHwItem({
			lineNumber: lineNumber,
			quoteId: this.QuoteId
		})).then((item) => {
			ROOT.toast(`Removed ${hwItem.quantity} ${hwItemMain?.uom ?? ""} of ${hwItem.part_number}`, {
				type: "success"
			});
			self.RePriceWholeQuote();
		});

		this.UI.reRenderTable();
	}

	setShadeLabor(sequenceId, amount) {
		const quoteId = this.quote.ID;
		const assembly = this.shadeAssemblies.Items.find((sa) => sa.sequence_id == sequenceId);

		assembly.installation_labor_cost = amount;

		api.setShadeLabor(quoteId, sequenceId, amount).then((resp) => {
			this.UI.reRenderTable();
		});
	}

	setShadeOverhead(sequenceId, amount) {
		const quoteId = this.quote.ID;
		const assembly = this.shadeAssemblies.Items.find((sa) => sa.sequence_id == sequenceId);

		assembly.overhead = amount;

		api.setShadeOverhead(quoteId, sequenceId, amount).then((resp) => {
			this.UI.reRenderTable();
		});
	}

	setShadeMarkup(sequenceId, amount) {
		const quoteId = this.quote.ID;
		const assembly = this.shadeAssemblies.Items.find((sa) => sa.sequence_id == sequenceId);

		assembly.markup = amount;

		api.setShadeMarkup(quoteId, sequenceId, amount).then((resp) => {
			this.UI.reRenderTable();
		});
	}

	GetDemoInfo() {
		return this.DemoInfo;
	}

	SetDemoInfo(info) {
		this.DemoInfo = info;
		this.UI.reRenderTable();
	}

	GetAssemblyBySequence(sequenceId) {
		return this.shadeAssemblies.Items.find((sa) => sa.sequence_id == sequenceId);
	}

	SetIsMotorOnly(sequenceId, value) {
		const assembly = this.GetAssemblyBySequence(sequenceId);

		assembly.is_motor_only = value;
		const self = this;

		const idArr = [sequenceId];

		api.setIsMotorOnly(this.QuoteId, sequenceId, value).then((resp) => {
			toast.success(`Motor ${value ? "Enabled" : "Disabled"} on ${assembly.shade_name}`, { autoClose: 500 });
			self.RePriceSingleRow(idArr, true);
			self.UI.reRenderTable();
		});
	}

	SetIsTubeAndFabricOnly(sequenceId, value) {
		const assembly = this.GetAssemblyBySequence(sequenceId);

		assembly.is_tube_and_fabric_only = value;
		const self = this;

		const idArr = [sequenceId];

		api.setIsTubeAndFabricOnly(this.QuoteId, sequenceId, value).then((resp) => {
			toast.success(
				`Tube and Fabric ${value ? "Enabled" : "Disabled"} on ${assembly.shade_name}`,
				{ autoClose: 500 }
			);
			self.RePriceSingleRow(idArr, true);
			self.UI.reRenderTable();
		});
	}

	SetIsHeaderExtrusionOnly(sequenceId, value) {
		const assembly = this.GetAssemblyBySequence(sequenceId);

		assembly.is_header_extrusion_only = value;
		const self = this;

		const idArr = [sequenceId];

		api.setIsHeaderExtrusionOnly(this.QuoteId, sequenceId, value).then((resp) => {
			toast.success(`Header ${value ? "Enabled" : "Disabled"} on ${assembly.shade_name}`, { autoClose: 500 });
			self.RePriceSingleRow(idArr, true);
			self.UI.reRenderTable();
		});
	}

	SetIsHardwareOnly(sequenceId, value) {
		const assembly = this.GetAssemblyBySequence(sequenceId);

		assembly.is_hardware_only = value;
		const self = this;

		const idArr = [sequenceId];

		api.setIsHardwareOnly(this.QuoteId, sequenceId, value).then((resp) => {
			toast.success(`Hardware ${value ? "Enabled" : "Disabled"} on ${assembly.shade_name}`, { autoClose: 500 });
			self.RePriceSingleRow(idArr, true);
			self.UI.reRenderTable();
		});
	}

	SetIsChannelsOnly(sequenceId, value) {
		const assembly = this.GetAssemblyBySequence(sequenceId);

		assembly.is_channels_only = value;
		const self = this;

		const idArr = [sequenceId];

		api.setIsChannelsOnly(this.QuoteId, sequenceId, value).then((resp) => {
			toast.success(`Channels ${value ? "Enabled" : "Disabled"} on ${assembly.shade_name}`, { autoClose: 500 });
			self.RePriceSingleRow(idArr, true);
			self.UI.reRenderTable();
		});
	}

	SetIsCrownAndDriveOnly(sequenceId, value) {
		const assembly = this.GetAssemblyBySequence(sequenceId);

		assembly.is_crown_and_drive_only = value;
		const self = this;

		const idArr = [sequenceId];

		api.setIsCrownAndDriveOnly(this.QuoteId, sequenceId, value).then((resp) => {
			toast.success(`Crowns and Drives ${value ? "Enabled" : "Disabled"} on ${assembly.shade_name}`, { autoClose: 500 });
			self.RePriceSingleRow(idArr, true);
			self.UI.reRenderTable();
		});
	}

	SetRecipeSku(sequenceId, value) {
		const assembly = this.GetAssemblyBySequence(sequenceId);

		assembly.RecipeSku = value;
		assembly.recipe_sku = value;
		const self = this;
		self.quoteLevelVariables().store.dispatch(updateAssemblyNoPrice(this.QuoteId, sequenceId, (a) => {
			a.recipe_sku = value;
			return a;
		}))

		api.setRecipeSku(this.QuoteId, sequenceId, value).then((resp) => {
			
			self.UI.reRenderTable();
		});
	}

	SetTubeOverride(sequenceId, value) {
		const assembly = this.GetAssemblyBySequence(sequenceId);

		assembly.TubePricingOverride = value;
		assembly.tube_pricing_override = value;
		const self = this;

		api.setTubeSku(this.QuoteId, sequenceId, value).then((resp) => {
			self.RePriceWholeQuote();
			self.UI.reRenderTable();
		});
	}

	SetMotorOverride(sequenceId, value) {
		const assembly = this.GetAssemblyBySequence(sequenceId);

		assembly.MotorPricingOverride = value;
		assembly.motor_pricing_override = value;
		const self = this;

		api.setMotorSku(this.QuoteId, sequenceId, value).then((resp) => {
			self.RePriceWholeQuote();
			self.UI.reRenderTable();
		});
	}

	SetIsShadeService(sequenceId, value) {
		const assembly = this.GetAssemblyBySequence(sequenceId);

		assembly.is_shade_service = value;
		const self = this;

		const idArr = [sequenceId];

		api.setIsShadeService(this.QuoteId, sequenceId, value).then((resp) => {
			self.RePriceSingleRow(idArr, true);
			self.UI.reRenderTable();
		});
	}

	SetFabricToBeCut(sequenceId, value) {
		const assembly = this.GetAssemblyBySequence(sequenceId);

		assembly.is_fabric_to_be_cut = value;
		const self = this;

		const idArr = [sequenceId];

		api.setFabricToBeCut(this.QuoteId, sequenceId, value).then((resp) => {
			toast.success(
				`Fabric Cutting ${value ? "Enabled" : "Disabled"} on ${assembly.shade_name}`,
				{ autoClose: 500 }
			);
			self.RePriceSingleRow(idArr, true);
			self.UI.reRenderTable();
		});
	}

	SetTubeToBeCut(sequenceId, value) {
		const assembly = this.GetAssemblyBySequence(sequenceId);

		assembly.is_tube_to_be_cut = value;
		const self = this;

		const idArr = [sequenceId];

		api.setTubeToBeCut(this.QuoteId, sequenceId, value).then((resp) => {
			toast.success(`Tube Cutting ${value ? "Enabled" : "Disabled"} on ${assembly.shade_name}`, { autoClose: 500 });
			self.RePriceSingleRow(idArr, true);
			self.UI.reRenderTable();
		});
	}

	SetHeaderExtrusionToBeCut(sequenceId, value) {
		const assembly = this.GetAssemblyBySequence(sequenceId);

		assembly.is_header_extrusion_to_be_cut = value;
		const self = this;

		const idArr = [sequenceId];

		api.setHeaderToBeCut(this.QuoteId, sequenceId, value).then((resp) => {
			toast.success(`Header Cutting ${value ? "Enabled" : "Disabled"} on ${assembly.shade_name}`, { autoClose: 500 });
			self.RePriceSingleRow(idArr, true);
			self.UI.reRenderTable();
		});
	}

	SetSideChannelsToBeCut(sequenceId, value) {
		const assembly = this.GetAssemblyBySequence(sequenceId);

		assembly.is_side_channels_to_be_cut = value;
		const self = this;

		const idArr = [sequenceId];

		api.setSideToBeCut(this.QuoteId, sequenceId, value).then((resp) => {
			toast.success(`Side Channel Cutting ${value ? "Enabled" : "Disabled"} on ${assembly.shade_name}`, { autoClose: 500 });
			self.RePriceSingleRow(idArr, true);
			self.UI.reRenderTable();
		});
	}

	SetSillChannelsToBeCut(sequenceId, value) {
		const assembly = this.GetAssemblyBySequence(sequenceId);

		assembly.is_sill_channels_to_be_cut = value;
		const self = this;

		const idArr = [sequenceId];

		api.setSillToBeCut(this.QuoteId, sequenceId, value).then((resp) => {
			toast.success(`Sill Channel Cutting ${value ? "Enabled" : "Disabled"} on ${assembly.shade_name}`, { autoClose: 500 });
			self.RePriceSingleRow(idArr, true);
			self.UI.reRenderTable();
		});
	}

	SetHembarToBeCut(sequenceId, value) {
		const assembly = this.GetAssemblyBySequence(sequenceId);

		assembly.is_hembar_to_be_cut = value;
		const self = this;

		const idArr = [sequenceId];

		api.setHembarToBeCut(this.QuoteId, sequenceId, value).then((resp) => {
			toast.success(`Hembar Cutting ${value ? "Enabled" : "Disabled"} on ${assembly.shade_name}`, { autoClose: 500 });
			self.RePriceSingleRow(idArr, true);
			self.UI.reRenderTable();
		});
	}

	SetShadeToBeMade(sequenceId, row, column, value) {
		const assembly = this.GetAssemblyBySequence(sequenceId);

		console.log({
			assembly,
			sequenceId
		});

		const shade = assembly.shades.find((s) => s.row_coordinate == row && s.column_coordinate == column);
		shade.make_this_shade = value;

		const self = this;

		const idArr = [sequenceId];

		api.setThisShadeToBeMade(this.QuoteId, sequenceId, row, column, value).then((resp) => {
			toast.success(`Shade Remake ${value ? "Enabled" : "Disabled"} on ${assembly.shade_name}`, { autoClose: 500 });
			self.RePriceSingleRow(idArr, true);
			self.UI.reRenderTable();
		});
	}

	BalanceAllAssemblies() {
		const indoorShadeSequenceIds = this.IndoorShades.map((s) => s.sequence_id);

		this.MassEdit(
			indoorShadeSequenceIds,
			"balanced_lightgaps",
			"balanced",
			{
				varname: "balanced_lightgaps",
				saveType: 2
			},
			true
		);
	}

	CheckShadeReadyForPricing(shadeAssembly) {
		const requiredFields = {
			indoor: {
				assembly: ["header_type", "mount_type", "sequence_id"],
				shades: ["fabric_name", "height", "width", "hembar_type", "roll_direction"]
			},
			outdoor: {
				assembly: ["header_type", "mount_type", "sequence_id"],
				shades: ["fabric_name", "height", "hembar_type", "width"]
			}
		};
		if (shadeAssembly.last_time_priced) return true;
		const requiredFieldsForShade = requiredFields[shadeAssembly.indoor_outdoor];

		const shadeAssemblyFields = Object.keys(shadeAssembly);

		const shadeFields = shadeAssembly.shades.flatMap((s) => Object.keys(s));

		const missingAssemblyFields = requiredFieldsForShade.assembly.filter((f) => !shadeAssemblyFields.includes(f));
		if (missingAssemblyFields.length > 0) return false;

		const missingShadeFields = requiredFieldsForShade.shades.filter((f) => !shadeFields.includes(f));
		if (missingShadeFields.length > 0) return false;

		return true;
	}

	/*
	SetIsMotorOnly: (quoteId,sequenceId,boolVal) => updateAssemblyBoolField("IsMotorOnly",quoteId,sequenceId,boolVal),
	SetIsTubeAndFabricOnly: (quoteId,sequenceId,boolVal) => updateAssemblyBoolField("IsTubeAndFabricOnly",quoteId,sequenceId,boolVal),
	SetIsChannelsOnly: (quoteId,sequenceId,boolVal) => updateAssemblyBoolField("IsChannelsOnly",quoteId,sequenceId,boolVal),
	SetIsHardwareOnly: (quoteId,sequenceId,boolVal) => updateAssemblyBoolField("IsHardwareOnly",quoteId,sequenceId,boolVal),
	SetIsHeaderExtrusionOnly: (quoteId,sequenceId,boolVal) => updateAssemblyBoolField("IsHeaderExtrusionOnly",quoteId,sequenceId,boolVal),

	*/
}

export { QuoteData };

export default QuoteData;
