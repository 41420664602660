// import { useState } from "react";
import { ReactElement } from 'react';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export type ModalProps = {
	icon?: SweetAlertIcon;
	title: string;
	text: string | ReactElement;
	showConfirmButton?: boolean;
	showCancelButton?: boolean;
	confirmButtonText?: string | ReactElement;
	cancelButtonText?: string | ReactElement;
	onCancel?: () => void;
	onConfirm?: (callBack?: any) => void;
};

const MySwal = withReactContent(Swal);

const alert = ({
	icon,
	title,
	text,
	showConfirmButton = true,
	showCancelButton = true,
	confirmButtonText = 'Okay',
	cancelButtonText = 'Cancel',
	onCancel = () => {},
	onConfirm = () => {},
}: ModalProps) => {
	const modalFormula = {
		title,
		html: text,
		showConfirmButton,
		showCancelButton,
		confirmButtonText,
		cancelButtonText,
		cancelButtonColor: '#6d6e71',
		confirmButtonColor: '#8dc63f',
		reverseButtons: true,
	};
	icon
		? MySwal.fire({
			...modalFormula,
			icon,
		}).then((result) => {
			if (result.isConfirmed) {
				onConfirm(result);
				// MySwal.showLoading();
				// console.debug(onConfirm());
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				onCancel();
			}
		})
		: MySwal.fire({
			...modalFormula,
		}).then((result) => {
			if (result.isConfirmed) {
				onConfirm(result);
				// MySwal.showLoading();
				// console.debug(onConfirm());
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				onCancel();
			}
		});
};

export default alert;
