import React from 'react';

import NewQuoteItemForm from "../../../Modals/ShadeModals/AddShade/NewQuoteItem";
import NewAccessoryForm from "./NewAccessoryForm";
import DescriptionSlides from '../../DescriptionSlides';
import { NewFabricForm } from './NewFabricSampleForm';

import PartsForm from '../../PartsForm';
import { useQuoteFabricSamplesByQuoteId } from '../../../Store/entities/fabricSamples/hooks';
import store, { UsePortalDispatch } from '../../../Store';
import { addQuoteFabricSample, updateQuoteFabricSample } from '../../../Store/entities/fabricSamples';
import { useQuoteTypeNameByQuoteId } from '../../../Store/entities/quotes/hooks';
import { selectQuoteAccessories } from '../../../Store/entities/accessories/hooks';
import { useIsPowerShadesAdmin } from '../../../hooks';

type ProductFormProps = {
    quoteId: number;
    quoteType: string;
    newQuote: any;
    quote: any;
    onCancel: () => void;
    onAdd: () => void;
    close: () => void;
    openAddPsOutdoorShade: () => void;
    indoorFabrics: any;
    outdoorFabrics: any;
};

const ProductForm = ({
    indoorFabrics,
    outdoorFabrics,
    newQuote,
    // quote,
    onCancel,
    // onAdd,
    close,
    // openAddPsOutdoorShade,
    quoteId
}: ProductFormProps) => { 


    const [focusedOption, ] = React.useState<any>(null);
    const [tab, setTab] = React.useState(0);
    const [showRequired, ] = React.useState(false);
    const quoteSamples = useQuoteFabricSamplesByQuoteId(quoteId);
    const dispatch = UsePortalDispatch();
    const quoteType = useQuoteTypeNameByQuoteId(quoteId);

    const setFabSampleQuantity = (fabricName: string, quantity: number) => {
        if(quoteSamples.some((sample) => sample.Name === fabricName)) {
            dispatch(updateQuoteFabricSample({
                quoteId,
                quantity,
                name: fabricName
            }))
        } else {
            dispatch(addQuoteFabricSample({
                quoteId,
                quantity,
                name: fabricName
            }))
        }
    }

    const storeState = store.getState();
    
    const quoteAccessories = selectQuoteAccessories(quoteId)(storeState);


    const contents = [
        <NewQuoteItemForm
          quoteId={quoteId}
            onCancel={onCancel}
        />,
        <NewAccessoryForm
            quoteType={quoteType}
            accessories={newQuote.globals.ACCESSORIES}
            onDone={close}
            quoteAccessories={quoteAccessories}
            setAccessoryQuantity={newQuote.Data.setAccessoryQuantity}
            newQuote={newQuote}
        />,
        <NewFabricForm
            quoteType={quoteType}
            invoiceTable={false}
            samples={newQuote.globals.FABRICS}
            quoteSamples={quoteSamples}
            isManuacturer // cSpell:disable-line
            onDone={close}
            onChange={setFabSampleQuantity}
            indoorFabrics={indoorFabrics}
            outdoorFabrics={outdoorFabrics}
        />,
    ];

    const isPsAdmin = useIsPowerShadesAdmin();
    const showParts = isPsAdmin;

    if (showParts) {
        contents.push(<PartsForm
            quoteType={quoteType}
            onDone={close}
            newQuote={newQuote}
        />);
    }

    return (

        <>

            <div className={`option-description ${focusedOption && DescriptionSlides[focusedOption.varName] ? 'show' : ''}`}>
                {
                    focusedOption && DescriptionSlides[focusedOption.varName]
                        ? DescriptionSlides[focusedOption.varName](focusedOption)
                        : null
                }
            </div>

            <div className={`product-form ${showRequired ? 'show-required' : ''} showing-description-modal`}>

                <div className="mode-toggle">

                    <div className={tab == 0 ? `active ${quoteType}` : ''} onClick={() => setTab(0)}>
                        Shades
                    </div>

                    <div className={tab == 1 ? `active ${quoteType}` : ''} onClick={() => setTab(1)}>
                        Accessories
                    </div>

                    <div className={tab == 2 ? `active ${quoteType}` : ''} onClick={() => setTab(2)}>
                        Samples
                    </div>
                    {showParts ? (
                        <div className={tab == 3 ? `active ${quoteType}` : ''} onClick={() => setTab(3)}>
                            Parts
                        </div>
                    ) : null}

                </div>

                {contents[tab]}

            </div>
        </>
    );
}

export default ProductForm;
