import { useState } from 'react';

export default function MfgStatusSelect({
	initialStatus, onChange, emptyValueLabel
}) {
	const [mfgStatus, setMfgStatus] = useState(initialStatus);

	function statusChanged(new_status) {
		setMfgStatus(new_status);
		
		onChange && onChange(new_status);
	}

	return (

		<select value={mfgStatus} onChange={(e) => statusChanged(e.target.value)}>

			<option value="">{ emptyValueLabel || '- Select - ' }</option>
			<option value="pending">
				Mfg Pending
			</option>

			<option value="in_progress">
				Mfg In Progress
			</option>

			<option value="complete">
				Mfg Complete
			</option>

		</select>
	);
}
