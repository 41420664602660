import React from 'react';
import * as ReactRedux from 'react-redux';
import whyDidYouRender from '@welldone-software/why-did-you-render';

const isDevelopment = process.env.ENV.includes("dev");

if (isDevelopment) {
	whyDidYouRender(React, {
		trackExtraHooks: [
			[ReactRedux, 'useSelector']
		],
		logOnDifferentValues: true,
		trackAllPureComponents: true,
		collapseGroups: true,
	});
}
