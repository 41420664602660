import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Button from '../Parts/Button';

import { initialUser } from '../Store/entities/users';
import type { UserLookupProps } from './types';
import UserSelect from '../Parts/UserSelect';
import { UserStore } from '../powershadesApiTypeExtensions';

const UserLookup = ({ open, onClose, handleAdd }: UserLookupProps) => {
	const [selectedUser, setSelectedUser] = useState<UserStore>(initialUser());
	const userHasBeenSelected = selectedUser.id !== 0;
	const handleClose = () => {
		onClose();
	};
	const handleAddUser = () => {
		try {
			handleAdd(selectedUser);
			handleClose();
			toast.success('User Added');
		} catch (err) {
			toast.error('Error Adding User');
			throw err;
		}
	};

	return (
		<Popup
			open={open}
			onClose={handleClose}
			closeOnDocumentClick={false}
			closeOnEscape={false}
			className="mobile-modal"
		>
			<Row className="mb-2">
				<Col>
					<h4>Lookup User</h4>
				</Col>
			</Row>
			<Row className="mb-2">
				<Col>
					<input className="d-none" />
					<UserSelect handleChange={(user) => setSelectedUser(user)} />
				</Col>
			</Row>
			<Row className="justify-content-end mt-4">
				<Col className="text-start">
					<Button color="gray" onClick={handleClose}>
						Close
					</Button>
				</Col>
				<Col className="text-end">
					<Button
						color="green"
						disabled={!userHasBeenSelected}
						onClick={handleAddUser}
					>
						Select User
					</Button>
				</Col>
			</Row>
		</Popup>
	);
};

export default UserLookup;
