import {loadPortfolios} from ".";
import { loadStatus } from "../entitiesType";

import store, { type AppState, UsePortalSelector } from "../..";


const selectPortfolios = (_: AppState) => {
    const dispatch = store.dispatch;
    const currentPortfolioState = store.getState().entity.portfolios;
    const currentUser = store.getState().user;
    if((
        currentPortfolioState.loadStatus === loadStatus.needsLoaded || currentPortfolioState.loadStatus === loadStatus.notLoaded
    ) && currentUser.id !== 0){
        dispatch(loadPortfolios());
    }
    
    return currentPortfolioState.list;
}

const usePortfolios = () => UsePortalSelector(selectPortfolios);

export {
    usePortfolios,
    selectPortfolios
};

