import React, { useEffect, useState } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';

import Button from '../Parts/Button';
import apiCalls from '../PowerShadesAPIFunctions';

const ConfirmPasswordReset = ({ ROOT, loaded }): JSX.Element => {
	const [newPassword, setNewPassword] = useState('');
	const [reNewPassword, setReNewPassword] = useState('');
	const [error, setError] = useState('');

	useEffect(() => {
		loaded();
	}, [loaded]);

	const attemptReset = () => {
		apiCalls
			.confirmResetPassword(ROOT.GET.email, ROOT.GET.token, newPassword, reNewPassword)
			.then(() => {
				ROOT.loadContent('#Login?resetsuccess=1');
			})
			.catch((error) => { // eslint-disable-line
				let errorsMessage = null;

				const { errors } = error.response.data;

				if (errors.password) {
					errorsMessage = errors.password.reduce(
						(prev: string, next: string) => (prev ? `${prev}, ${next}` : `${next}`),
						null
					);
				}

				if (errors.email) {
					errorsMessage = errors.email.reduce(
						(prev: string, next: string) => (prev ? `${prev}, ${next}` : `${next}`),
						errorsMessage
					);
				}
				if (errorsMessage) {
					setError(errorsMessage);
				}
			});
	};

	return (
		<Container className="local-bootstrap">
			<Card body id="login-form">
				<Row>
					<Col>
						<h3>Create New Password</h3>
					</Col>
				</Row>

				<Row>
					<Col>
						<label htmlFor="login-password">New Password</label>
					</Col>
				</Row>
				<Row className="mb-2">
					<Col>
						<input
							className="form-control"
							id="login-password"
							type="password"
							autoComplete="current-password"
							value={newPassword}
							onChange={(e) => setNewPassword(e.target.value)}
						/>
					</Col>
				</Row>

				<Row>
					<Col>
						<label htmlFor="login-password">Confirm New Password</label>
					</Col>
				</Row>
				<Row className="mb-2">
					<Col>
						<input
							className="form-control"
							id="login-password"
							type="password"
							autoComplete="current-password"
							value={reNewPassword}
							onChange={(e) => setReNewPassword(e.target.value)}
						/>
					</Col>
				</Row>

				<Row>
					<Col>
						<div className={`error ${!error ? 'hide' : ''}`}>{error}</div>
					</Col>
				</Row>

				<Row>
					<Col className="text-end mt-2">
						<Button className="green-button" onClick={attemptReset}>
							Submit
						</Button>
					</Col>
				</Row>
			</Card>
		</Container>
	);
};

export default ConfirmPasswordReset;
