import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import Button from '../Parts/Button';

import type { QuotePopulateDashboardTaskProps } from './types';
import apiCalls from '../PowerShadesAPIFunctions';
import alert from '../Store/ui/alert';

const TaskEntryRow = ({ taskId }: { taskId: number }) => {
	const [taskObj, setTaskObj] = useState({
		id: taskId,
		created_at: '',
		executed_at: '',
		error: '',
	});

	useEffect(() => {
		apiCalls.getPopulateDashboardTask(taskId).then((resp) => setTaskObj(resp.data));
	}, [taskId]);

	const dateConverter = (date) => {
		const formattedDate = new Date(date);
		const newDate = new Date();
		newDate.setTime(formattedDate.getTime());
		return formattedDate.toLocaleString();
	};

	return (
		<tr>
			<td>{taskObj.id}</td>
			<td>{dateConverter(taskObj.created_at)}</td>
			<td>{dateConverter(taskObj.executed_at)}</td>

			<td
				style={{
					maxWidth: '100px',
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				}}
			>
				{taskObj.error}
			</td>

			<td>{!taskObj.executed_at ? 'Pending' : taskObj.error ? 'False' : 'True'}</td>
		</tr>
	);
};

const TasksTable = ({ tasks, quoteType }) => {
	const entryRows = tasks.map((task) => <TaskEntryRow key={task.id} taskId={task.id} />);

	return (
		<Table striped hover responsive size="sm" className={quoteType}>
			<tbody>
				<tr className="bold-row">
					<th>ID</th>
					<th>Created Time</th>
					<th>Executed Time</th>
					<th>Error</th>
					<th>Success</th>
				</tr>

				{entryRows}
			</tbody>
		</Table>
	);
};

const QuotePopulateDashboardTasks = ({ ROOT, quote, quoteType, onClose }: QuotePopulateDashboardTaskProps) => {
	const [showingPopulateModal, setShowingPopulateModal] = useState(false);
	console.debug(showingPopulateModal, setShowingPopulateModal);
	
	const run = async () => {
		const response = await apiCalls.populateDashboardV2(quote.ID);
		if (!response.data?.populate_dashboard_output?.success) {
			const responseContent = response.data?.populate_dashboard_output?.response?.responseContent;
			let message = responseContent;
	
			try {
				const parsedContent = typeof responseContent === 'string'
					? JSON.parse(responseContent) : responseContent;
				message = parsedContent.message || responseContent;
			} catch (error) {
				console.log("Failed to parse. error: ", error);
			}
	
			console.error(
				"Failed to populate dashboard. populate_dashboard_output: ",
				response.data?.populate_dashboard_output
			);
			ROOT.alert({
				text: `Failed to populate dashboard. ${message ?? "Look at console for more information"}.`,
				quickFormat: 'error',
			});
		} else {
			ROOT.toast("Successfully populated dashboard!", { type: 'success' });
		}
		
		// onCancel && onCancel();
	};

	const openPopulateDashboard = () => {
		alert({
			title: 'Populate Dashboard',
			text: (
				<span>
					The populate dashboard task is normally ran automatically when a manufacturer is
					assigned.
					<br />
					<br />
					Are you sure you want to populate the dashboard manually?
				</span>
			),
			onConfirm: run,
		});
	};

	return (
		<>
			<div className="modal-holder">
				<div
					className="standard-modal dashboard-tasks-modal"
					style={{
						minHeight: '20em',
						display: 'flex',
						flexDirection: 'column',
						maxWidth: '100vw',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginBottom: '0.5em'
						}}
					>
						<h4 className={`text-${quoteType}`}>Populate Dashboard Tasks</h4>
						<Button
							color={quoteType}
							onClick={openPopulateDashboard}
						>
							Run Task
						</Button>
					</div>

					<div style={{ maxHeight: '18em', height: '18em' }}>
						<div className="overflow-x-wrapper local-bootstrap">
							<TasksTable
								quoteType={quoteType}
								tasks={quote.populate_dashboard_tasks || []}
							/>
						</div>
					</div>

					<div
						className="button-holder"
						style={{
							textAlign: 'right',
							flexGrow: '1',
							display: 'flex',
							alignItems: 'flex-end',
						}}
					>
						<button
							type="button"
							className={`btn-${quoteType}`}
							onClick={onClose}
							style={{ marginLeft: 'auto' }}
						>
							Done
						</button>
					</div>
				</div>
			</div>

			{/* {showingPopulateModal ? (
				<PopulateDashboard
					quoteID={quote.ID}
					onCancel={() => setShowingPopulateModal(false)}
				/>
			) : null} */}
		</>
	);
};

export default QuotePopulateDashboardTasks;
