import Popup from 'reactjs-popup';
import { Container, Row, Col, Table } from 'react-bootstrap';

import Money from '../Parts/Money';
import QUOTE_GLOBALS from '../Quote/QuoteGlobals';
import { getQuoteType } from '../Quote/QuoteObjectExtensions';
import ShadePriceBreakdown from '../Quote/NewOptionFields/ShadeActions/ShadePriceBreakdown';
import Button from '../Parts/Button';
import { selectAssemblyListByQuoteId, useAssembliesByQuoteId } from '../Store/entities/assemblies/hooks';
import { UseMultipliersByQuoteId, selectQuoteFromId, useQuoteById, useQuoteTypeNameByQuoteId } from '../Store/entities/quotes/hooks';
import { useDealerById } from '../Store/entities/hooks';
import { selectAccessories, selectQuoteAccessories } from '../Store/entities/accessories/hooks';
import store from '../Store';
import { useHWRItemsByQuoteId } from '../Store/entities/hardwareRequestItems/hooks';

const findGroup = (groupName, groups) => groups.find((group) => group.name === groupName);

const dealerMultiplier = (groups) => {
	const dealerGroup = findGroup('dealer', groups);
	return dealerGroup.multiplier.multiplier;
};
const distributorMultiplier = (groups) => {
	const distributorMultiplier = findGroup('distributor', groups);
	return distributorMultiplier.multiplier.multiplier;
};

const ShadeCostRow = ({ shade, setShowingSeqId, quoteData }) => {
	const { shade_name, room_name, cost, distributor, dealer, msrp } = shade;
	const quoteId = quoteData.quote.ID;
	const quoteType = useQuoteTypeNameByQuoteId(quoteId);
	const [open, setOpen] = React.useState(false);
	return (
		<tr className={msrp == 0 ? 'error view-cost-row' : 'view-cost-row'}>
			<td>{room_name}</td>
			<td>
				<button onClick={() => {
					setShowingSeqId(shade.sequence_id);
				}}>{shade_name}</button>
			</td>
			<td>
				<Money decimalCount={3}>{cost}</Money>
			</td>
			<td>
				<Money decimalCount={3}>{distributor}</Money>
			</td>
			<td>
				<Money decimalCount={3}>{dealer}</Money>
			</td>
			<td>
				<Money decimalCount={3}>{msrp}</Money>
			</td>
		</tr>
	);
};

const ShadesCostBreakdown = (props) => {
	const pricing_data = null;

	const { quoteMsrps, quoteData, quoteType, quoteId, setShowingSeqId } = props;

	const prices = [
		{
			access: 'dealer',
			label: 'dealer',
		},
		{
			access: 'PSMSRP',
			label: 'msrp',
		},
		{
			access: 'distributor',
			label: 'distributor',
		},
		{
			access: 'cost',
			label: 'cost',
		},
	];

	const assemblies = useAssembliesByQuoteId(quoteId);
	const quote = useQuoteById(quoteId);
	const distributorId = quote.distributor_id;
	const multipliers = UseMultipliersByQuoteId(quoteId)

	const distMultiplier = multipliers?.list?.find((m) => m.name == 'distributor')?.multiplier ?? 0.425;
	const dealerMultiplier = multipliers?.list?.find((m) => m.name == 'dealer')?.multiplier ?? 0.5;


	// This parses through the MSRP's and grabs and allocates the prices
	const shadeSplit = assemblies.map((s) => {
		const shade = {};

		// prices.forEach((price) => {
		// 	let assembly = quoteMsrps.totals
		// 		.find((qm) => qm.name === price.access)
		// 		.assemblyItems.find((ai) => ai.sequenceId == s.sequenceId);

		// 	if (!assembly) {
		// 		assembly = quoteMsrps.totals
		// 			.find((qm) => qm.name === price.access)
		// 			.assemblyItems.find((ai) => ai.sequenceId == s.data().sequence_id_duplicate);
		// 	}

		// 	shade[price.label] = assembly.price;
		// });

		shade.room_name = s.room_name;
		shade.shade_name = s.shade_name;
		shade.sequence_id = s.sequence_id;
		shade.msrp = s.msrp;

		shade.cost = s.cost;

		shade.dealer = s.msrp * dealerMultiplier;
		shade.distributor = s.msrp * distMultiplier;

		return shade;
	});

	const totalAssemblyCost = {};

	prices.forEach(
		(price) =>
		(totalAssemblyCost[price.label] = quoteMsrps.totals.find(
			(qm) => qm.name === price.access
		).assemblies)
	);

	let { cost, distributor, dealer, msrp } = totalAssemblyCost;

	cost = shadeSplit.reduce((previousValue, shade) => previousValue + shade.cost, 0);

	const shade_rows = shadeSplit.map((shade) => (
		<ShadeCostRow
			key={shade.sequence_id}
			shade={shade}
			setShowingSeqId={setShowingSeqId}
			quoteData={quoteData}
		/>
	));

	return (
		<Table bordered hover size="sm" className={quoteType}>
			<tbody>
				<tr className="bold-row">
					<th colSpan={6}>Shades</th>
				</tr>

				<tr>
					<th>Room Name</th>
					<th>Shade Name</th>
					<th>Cost</th>
					<td>
						<strong>
							Dist. Sale Price ({distMultiplier} Multiplier)
						</strong>
					</td>
					<td>
						<strong>
							Dealer Sale Price ({dealerMultiplier} Multiplier)
						</strong>
					</td>
					<td>
						<strong>MSRP</strong>
					</td>
				</tr>

				{shade_rows}

				<tr>
					<td>Total:</td>
					<td />
					<td>
						<strong>
							<Money>{cost}</Money>
						</strong>
					</td>
					<td>
						<strong>
							<Money>{distributor}</Money>
						</strong>
					</td>
					<td>
						<strong>
							<Money>{dealer}</Money>
						</strong>
					</td>
					<td>
						<strong>
							<Money>{msrp}</Money>
						</strong>
					</td>
				</tr>
			</tbody>
		</Table>
	);
};

const AccessoriesCostBreakdown = (props) => {
	const { quoteMsrps, quoteType } = props;

	const quoteAccessories = selectQuoteAccessories(props.quote.ID)(store.getState());
	const accessoriesList = selectAccessories(props.quote.ID)(store.getState());

	const accessories = quoteAccessories.map((acc) => {
		const acc_data = accessoriesList.find(a => acc.part_number === a.part_number);

		if (!acc ||( !acc_data && !acc?.cost && !acc?.name)) {
			return null;
		}

		return {
			...acc,
			name: acc_data?.name ?? acc?.name,
			total_cost: parseFloat(acc_data?.cost ?? acc?.cost) * parseFloat(acc.quantity),
		};
	});

	const prices = [
		{
			access: 'dealer',
			label: 'dealer',
		},
		{
			access: 'PSMSRP',
			label: 'msrp',
		},
		{
			access: 'distributor',
			label: 'distributor',
		},
		{
			access: 'cost',
			label: 'cost',
		},
	];

	let totalCost = 0;
	let totalDistributor = 0;
	let totalDealer = 0;
	let totalMsrp = 0;

	const rows = accessories.map((acc) => {
		if (!acc || acc.quantity == 0 || acc.quantity == 0) {
			return <></>;
		}

		const { cost, distributor, dealer, msrp } = prices.reduce((accessy, price) => {
			accessy[price.label] = quoteMsrps.totals
				.find((qm) => qm.name === price.access)
				.accessoryItems.find((ai) => acc.line_number == ai.line_number).price;

			return accessy;
		}, {});

		totalCost += cost;
		totalDistributor += distributor;
		totalDealer += dealer;
		totalMsrp += msrp;

		return (
			<tr key={acc.id}>
				<td>{acc.name}</td>
				<td>{acc.quantity}</td>
				<td>
					<Money>{cost}</Money>
				</td>
				<td>
					<Money>{distributor}</Money>
				</td>
				<td>
					<Money>{dealer}</Money>
				</td>
				<td>
					<Money>{msrp}</Money>
				</td>
			</tr>
		);
	});

	return (
		<Table bordered hover size="sm" className={quoteType}>
			<tbody>
				<tr className="bold-row">
					<th colSpan={6}>Accessories</th>
				</tr>

				<tr>
					<th>Name</th>
					<th>Quantity</th>
					<th>Cost</th>
					<th>
						Dist. Sale Price ({distributorMultiplier(quoteMsrps.totals)} Multiplier)
					</th>
					<th>Dealer Sale Price ({dealerMultiplier(quoteMsrps.totals)} Multiplier)</th>
					<th>MSRP</th>
				</tr>

				{rows}

				<tr>
					<td>Total:</td>
					<td />
					<td>
						<b>
							<Money>{totalCost}</Money>
						</b>
					</td>
					<td>
						<b>
							<Money>{totalDistributor}</Money>
						</b>
					</td>
					<td>
						<b>
							<Money>{totalDealer}</Money>
						</b>
					</td>
					<td>
						<b>
							<Money>{totalMsrp}</Money>
						</b>
					</td>
				</tr>
			</tbody>
		</Table>
	);
};

const HardwareCostBreakdown = (props) => {
	const { hardwareRequestItems, quoteMsrps, quoteType } = props;

	const { items } = props;

	let totalCost = 0;
	let totalDistributor = 0;
	let totalDealer = 0;
	let totalMsrp = 0;

	const rows = items.map((item) => {
		if (!item || item.quantity == 0) {
			return <></>;
		}

		const { cost } = item;
		const { msrp } = item;

		const dealer = msrp * dealerMultiplier(quoteMsrps.totals);
		const distributor = msrp * distributorMultiplier(quoteMsrps.totals);

		const partTotalCost = cost * item.quantity;
		const partTotalDistributor = distributor * item.quantity;
		const partTotalDealer = dealer * item.quantity;
		const partTotalMsrp = msrp * item.quantity;

		totalCost += partTotalCost;
		totalDistributor += partTotalDistributor;
		totalDealer += partTotalDealer;
		totalMsrp += partTotalMsrp;
		return (
			<tr key={item.id}>
				<td>{item.sku}</td>
				<td>
					{item.quantity} {item.uom}
				</td>
				<td>
					<Money>{partTotalCost}</Money>
				</td>
				<td>
					<Money>{partTotalDistributor}</Money>
				</td>
				<td>
					<Money>{partTotalDealer}</Money>
				</td>
				<td>
					<Money>{partTotalMsrp}</Money>
				</td>
			</tr>
		);
	});

	return (
		<Table bordered hover size="sm" className={quoteType}>
			<tbody>
				<tr className="bold-row">
					<th colSpan={6}>Hardware Requests</th>
				</tr>

				<tr>
					<th>Name</th>
					<th>Quantity</th>
					<th>Cost</th>
					<th>Dist. Price ({distributorMultiplier(quoteMsrps.totals)} Multiplier)</th>
					<th>Dealer Price ({dealerMultiplier(quoteMsrps.totals)} Multiplier)</th>
					<th>MSRP</th>
				</tr>

				{rows}

				<tr>
					<td>Total:</td>
					<td />
					<td>
						<b>
							<Money>{totalCost}</Money>
						</b>
					</td>
					<td>
						<b>
							<Money>{totalDistributor}</Money>
						</b>
					</td>
					<td>
						<b>
							<Money>{totalDealer}</Money>
						</b>
					</td>
					<td>
						<b>
							<Money>{totalMsrp}</Money>
						</b>
					</td>
				</tr>
			</tbody>
		</Table>
	);
};

const TaxCostBreakdown = (props) => {
	const { quoteMsrps, quoteType } = props;

	const prices = {
		dealer: {
			cost: 0,
			multiplier: 0,
		},
		distributor: {
			cost: 0,
			multiplier: 0,
		},
		msrp: {
			cost: 0,
		},
		cost: {
			cost: 0,
		},
	};

	let taxPercentage = 0;
	// wow i wrote this yesterday and i already hate it but it works :(
	const setTaxes = () => {
		Object.entries(prices).forEach((entry) => {
			const [key] = entry;
			quoteMsrps.totals.find((qm) => {
				if (qm.name === key || (qm.name === 'PSMSRP' && key === 'msrp')) {
					prices[key].cost = qm.taxes;
					if (prices[key].hasOwnProperty('multiplier')) {
						prices[key].multiplier = qm.multiplier.multiplier;
					}
				}
				if (taxPercentage === 0 && qm.taxPercentage !== 0) {
					taxPercentage = `${qm.taxPercentage * 100}%`;
				}
			});
		});
	};
	setTaxes();

	return (
		<Table key="tax-table" bordered hover size="sm" className={quoteType}>
			<tbody>
				<tr className="bold-row">
					<th colSpan={6}>Taxes</th>
				</tr>

				<tr>
					<th>Name</th>
					<th>Percentage</th>
					<th>Cost</th>
					<th>Dist. Price ({prices.distributor.multiplier} Multiplier)</th>
					<th>Dealer Price ({prices.dealer.multiplier} Multiplier)</th>
					<th>MSRP</th>
				</tr>

				<tr>
					<td>Quote Taxes</td>
					<td>{taxPercentage ?? taxPercentage}</td>
					<td>
						<strong>
							<Money>{prices.cost.cost}</Money>
						</strong>
					</td>
					<td>
						<strong>
							<Money>{prices.distributor.cost}</Money>
						</strong>
					</td>
					<td>
						<strong>
							<Money>{prices.dealer.cost}</Money>
						</strong>
					</td>
					<td>
						<strong>
							<Money>{prices.msrp.cost}</Money>
						</strong>
					</td>
				</tr>
			</tbody>
		</Table>
	);
};

const ShippingCostBreakdown = (props) => {
	const { shadeAssemblies, quoteMsrps, quoteType } = props;
	const { totals } = quoteMsrps;

	const msrpGroup = findGroup('MSRP', totals);
	const dealerGroup = findGroup('dealer', totals);
	const distributorGroup = findGroup('distributor', totals);
	const costGroup = findGroup('cost', totals);

	const quote = useQuoteById(props.quote.ID);

	const msrpShipping = quote.shipping_snapshot;
	const dealerShipping = msrpShipping;
	const distributorShipping = msrpShipping;
	const costShipping = msrpShipping;

	const numberOfShades = shadeAssemblies.length;

	const getTotalHiddenShipping = () => {
		let hiddenShipping = 0;

		shadeAssemblies.forEach((shade) => {
			hiddenShipping += shade.hidden_shipping;
		});

		return hiddenShipping;
	};

	const totalHiddenShipping = getTotalHiddenShipping();

	const msrpTotal = msrpShipping + totalHiddenShipping;
	const dealerTotal = dealerShipping + totalHiddenShipping;
	const distributorTotal = distributorShipping + totalHiddenShipping;
	const costTotal = costShipping + totalHiddenShipping;

	const oversized = quote?.freight_costs ?? 0;

	const msrpShippingTotal = msrpShipping + oversized;
	const dealerShippingTotal = dealerShipping + oversized;
	const distributorShippingTotal = distributorShipping + oversized;
	const costShippingTotal = costShipping + oversized;

	return (
		<Table bordered hover size="sm" className={quoteType} key="shippingcost-table">
			<tbody>
				<tr className="bold-row">
					<th colSpan={6}>Shipping</th>
				</tr>
				<tr>
					<th>Name</th>
					<th>Cost</th>
					<th>Dist. Price ({distributorMultiplier(totals)} Multiplier)</th>
					<th>Dealer Price ({dealerMultiplier(totals)} Multiplier)</th>
					<th>MSRP</th>
				</tr>
				<tr>
					<td>Parcel Shipping</td>
					<td>
						<Money>{costShipping}</Money>
					</td>
					<td>
						<Money>{distributorShipping}</Money>
					</td>
					<td>
						<Money>{dealerShipping}</Money>
					</td>
					<td>
						<Money>{msrpShipping}</Money>
					</td>
				</tr>
				<tr>
					<td>Freight Shipping</td>
					<td>
						<Money>{oversized}</Money>
					</td>
					<td>
						<Money>{oversized}</Money>
					</td>
					<td>
						<Money>{oversized}</Money>
					</td>
					<td>
						<Money>{oversized}</Money>
					</td>
				</tr>
				{shadeAssemblies.length > 0 ? (
					<>
						<tr>
							<td>Total:</td>
							
							<td>
								<b>
									<Money>{costShippingTotal}</Money>
								</b>
							</td>
							<td>
								<b>
									<Money>{distributorShippingTotal}</Money>
								</b>
							</td>
							<td>
								<b>
									<Money>{dealerShippingTotal}</Money>
								</b>
							</td>
							<td>
								<b>
									<Money>{msrpShippingTotal}</Money>
								</b>
							</td>
						</tr>
					</>
				) : null}
			</tbody>
		</Table>
	);
};

const TotalsCostBreakdown = (props) => {
	const { quoteMsrps, quoteId } = props;
	const distM = distributorMultiplier(quoteMsrps.totals);
	const dealerM = dealerMultiplier(quoteMsrps.totals);

	const assemblies = useAssembliesByQuoteId(quoteId);


	const totalCost = quoteMsrps.totals.find((qm) => qm.name === 'cost').totalPrice ;
	const totalDistributor = quoteMsrps.totals.find((qm) => qm.name === 'distributor').totalPrice ;
	const totalDealer = quoteMsrps.totals.find((qm) => qm.name === 'dealer').totalPrice ;
	const totalMsrp = quoteMsrps.totals.find((qm) => qm.name === 'PSMSRP').totalPrice ;

	const hwItems = useHWRItemsByQuoteId(quoteId);
	const hwLineItems = props.hardwareRequestItems;

	// const hardwareCostTotal = hwLineItems.reduce((acc, item) => acc + item.cost, 0);
	// const hardwareDealerTotal = hwLineItems.reduce((acc, item) => acc + item.msrp * dealerM, 0);
	// const hardwareDistributorTotal = hwLineItems.reduce((acc, item) => acc + item.msrp * distM, 0);
	// const hardwareMsrpTotal = hwLineItems.reduce((acc, item) => acc + item.msrp, 0);

	const hardwareCostTotal = hwLineItems.reduce((acc, item) =>{
		const hwItem = hwItems.find((hw) => hw.line_number === item.line_number);
		return  acc + (item.cost * hwItem.quantity)
	}, 0);

	const hardwareDealerTotal = hwLineItems.reduce((acc, item) =>{
		const hwItem = hwItems.find((hw) => hw.line_number === item.line_number);
		return  acc + (item.msrp * dealerM * hwItem.quantity)
	}, 0);

	const hardwareDistributorTotal = hwLineItems.reduce((acc, item) =>{
		const hwItem = hwItems.find((hw) => hw.line_number === item.line_number);
		return  acc + (item.msrp * distM * hwItem.quantity)
	}, 0);

	const hardwareMsrpTotal = hwLineItems.reduce((acc, item) =>{
		const hwItem = hwItems.find((hw) => hw.line_number === item.line_number);
		return  acc + (item.msrp * hwItem.quantity)
	}, 0);




	const assemblyCostTotal = assemblies.reduce((acc, assembly) => acc + assembly.cost, 0);
	const accessoryCostTotal = quoteMsrps.totals.find((qm) => qm.name === 'cost').accessories;
	const taxCostTotal = quoteMsrps.totals.find((qm) => qm.name === 'cost').taxes;

	const assemblyDealerTotal = quoteMsrps.totals.find((qm) => qm.name === 'dealer').assemblies ;
	const accessoryDealerTotal = quoteMsrps.totals.find((qm) => qm.name === 'dealer').accessories;
	const taxDealerTotal = quoteMsrps.totals.find((qm) => qm.name === 'dealer').taxes;

	const assemblyDistributorTotal = quoteMsrps.totals.find((qm) => qm.name === 'distributor').assemblies;
	const accessoryDistributorTotal = quoteMsrps.totals.find((qm) => qm.name === 'distributor').accessories;
	const taxDistributorTotal = quoteMsrps.totals.find((qm) => qm.name === 'distributor').taxes;

	const assemblyMsrpTotal = quoteMsrps.totals.find((qm) => qm.name === 'PSMSRP').assemblies;
	const accessoryMsrpTotal = quoteMsrps.totals.find((qm) => qm.name === 'PSMSRP').accessories;
	const taxMsrpTotal = quoteMsrps.totals.find((qm) => qm.name === 'PSMSRP').taxes;

	const quoteShipping = quoteMsrps.totals.find((qm) => qm.name === 'PSMSRP');
	const quoteShippingTotal = quoteShipping.shipping.total_shipping;

	

	// Totals, always final toal at end, if there is assemblies, accessories, or hardware requests then their totals will be here aswell.

	return (<Table key="tax-table" bordered hover size="sm" className={props.quoteType}>
		<tbody>
			<tr className="bold-row">
				<th colSpan={6}>Totals</th>
			</tr>

			<tr>
				<th>Name</th>
				<th>Cost</th>
				<th>Dist. Price ({distM} Multiplier)</th>
				<th>Dealer Price ({dealerM} Multiplier)</th>
				<th>MSRP</th>
			</tr>
			{ assemblyCostTotal > 0 && <tr>
				<td>Assemblies</td>
				<td>
					<Money>{assemblyCostTotal}</Money>
				</td>
				<td>
					<Money>{assemblyDistributorTotal}</Money>
				</td>
				<td>
					<Money>{assemblyDealerTotal}</Money>
				</td>
				<td>
					<Money>{assemblyMsrpTotal}</Money>
				</td>
			</tr>}
			{
				accessoryCostTotal > 0 && <tr>
					<td>Accessories</td>
					<td>
						<Money>{accessoryCostTotal}</Money>
					</td>
					<td>
						<Money>{accessoryDistributorTotal}</Money>
					</td>
					<td>
						<Money>{accessoryDealerTotal}</Money>
					</td>
					<td>
						<Money>{accessoryMsrpTotal}</Money>
					</td>
				</tr>
			}
			{
				hardwareCostTotal > 0 && <tr>
					<td>Hardware Requests</td>
					<td>
						<Money>{hardwareCostTotal}</Money>
					</td>
					<td>
						<Money>{hardwareDistributorTotal}</Money>
					</td>
					<td>
						<Money>{hardwareDealerTotal}</Money>
					</td>
					<td>
						<Money>{hardwareMsrpTotal}</Money>
					</td>
				</tr>
			}
			{
				quoteShippingTotal > 0 && <tr>
					<td>Shipping</td>
					<td>
						<Money>{quoteShippingTotal}</Money>
					</td>
					<td>
						<Money>{quoteShippingTotal}</Money>
					</td>
					<td>
						<Money>{quoteShippingTotal}</Money>
					</td>
					<td>
						<Money>{quoteShippingTotal}</Money>
					</td>
				</tr>
			}
			{
				taxCostTotal > 0 && <tr>
					<td>Taxes</td>
					<td>
						<Money>{taxCostTotal}</Money>
					</td>
					<td>
						<Money>{taxDistributorTotal}</Money>
					</td>
					<td>
						<Money>{taxDealerTotal}</Money>
					</td>
					<td>
						<Money>{taxMsrpTotal}</Money>
					</td>
				</tr>
			}
			<tr>
				<td>Quote Totals</td>
				<td>
					<strong>
						<Money>{totalCost}</Money>
					</strong>
				</td>
				<td>
					<strong>
						<Money>{totalDistributor}</Money>
					</strong>
				</td>
				<td>
					<strong>
						<Money>{totalDealer}</Money>
					</strong>
				</td>
				<td>
					<strong>
						<Money>{totalMsrp}</Money>
					</strong>
				</td>
			</tr>
		</tbody>
	</Table>
	);
};

const QuoteCostBreakdown = ({ newQuote, quote, onClose }) => {
	const quoteTotalsData = newQuote.msrps();
	const quoteTotalsCost = findGroup('cost', quoteTotalsData.totals);
	const hasAccessories = quoteTotalsCost.accessoryItems.length > 0;
	const hasShades = quoteTotalsCost.assemblyItems.length > 0;
	const quoteType = getQuoteType(newQuote);
	const hasHardware = !!(quoteTotalsCost.hardware_parts > 0 && quoteType !== 'CEDIA');
	const hasTaxes = quoteTotalsCost.taxes > 0;
	const hasShipping = quoteTotalsCost.shipping.total_shipping > 0;
	const hardwareRequestItems = newQuote.Data.hwRequestItems;
	const shadeAssemblies = selectAssemblyListByQuoteId(newQuote.Id)(newQuote?.store?.getState());

	const [selectedSeqIdForShowqing, setSelectedSeqIdForShowing] = React.useState(0);
	const closeShadeBreakdown = () => {
		setSelectedSeqIdForShowing(0);
	}

	const isOpen = selectedSeqIdForShowqing !== 0;

	return (
		<>
			{
				isOpen ?

					<ShadePriceBreakdown
						sequenceId={selectedSeqIdForShowqing}
						open={isOpen}
						quoteType={quoteType}
						quoteId={newQuote.Id}
						// Old Object
						done={() => { closeShadeBreakdown() }}
						close={() => { closeShadeBreakdown() }}
					/>
					:
					<div className="modal-holder local-bootstrap">
						<div className="quote-history-modal">
							<Container>
								<Row className="mb-2">
									<Col>
										<h4 className={`text-${quoteType}`}>Cost Breakdown</h4>
									</Col>
								</Row>
								{hasShades ? (
									<Row>
										<Col>
											<ShadesCostBreakdown
												quoteType={quoteType}
												quoteMsrps={quoteTotalsData}
												quote={quote}
												setShowingSeqId={setSelectedSeqIdForShowing}
												quoteData={newQuote.Data}
												quoteId={newQuote.Id}

											/>
										</Col>
									</Row>
								) : (
									<h6 className={`text-${quoteType} text-center mt-3 mb-3`}>
										No Shades Found On Quote
									</h6>
								)}
								{hasAccessories ? (
									<Row>
										<Col>
											<AccessoriesCostBreakdown
												quoteType={quoteType}
												quoteMsrps={quoteTotalsData}
												quote={quote}
											/>
										</Col>
									</Row>
								) : (
									<h6 className={`text-${quoteType} text-center mt-3 mb-3`}>
										No Accessories Found On Quote
									</h6>
								)}
								{hasHardware ? (
									<Row>
										<Col>
											<HardwareCostBreakdown
												quoteType={quoteType}
												quoteMsrps={quoteTotalsData}
												items={hardwareRequestItems}
											/>
										</Col>
									</Row>
								) : (
									<h6 className={`text-${quoteType} text-center mt-3 mb-3`}>
										No Hardware Found On Quote
									</h6>
								)}
								{hasTaxes ? (
									<Row>
										<Col>
											<TaxCostBreakdown
												quoteType={quoteType}
												quoteMsrps={quoteTotalsData}
											/>
										</Col>
									</Row>
								) : (
									<h6 className={`text-${quoteType} text-center mt-3 mb-3`}>
										No Taxes Found On Quote
									</h6>
								)}
								{hasShipping ? (
									<Row>
										<Col>
											<ShippingCostBreakdown
												quoteType={quoteType}
												shadeAssemblies={shadeAssemblies}
												quoteMsrps={quoteTotalsData}
												quote={quote}
											/>
										</Col>
									</Row>
								) : (
									<h6 className={`text-${quoteType} text-center mt-3 mb-3`}>
										No Shipping Found On Quote
									</h6>
								)}
								<Row>
									<TotalsCostBreakdown
										quoteMsrps={quoteTotalsData}
										quoteType={quoteType}
										hardwareRequestItems={hardwareRequestItems}
										quoteId= {newQuote.Id}
									/>

								</Row>
								<Row className="pb-4">
									<Col>
										<Button color={quoteType} onClick={onClose}>
											Done
										</Button>
									</Col>
								</Row>
							</Container>
						</div>
					</div>
			}
		</>
	);
};

export default QuoteCostBreakdown;
