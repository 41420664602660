import { useState, useEffect } from 'react';
import hash from 'object-hash';

import api from './PowerShadesAPI';
import User from './User';
import { QuoteTemplate } from './Quote/Quote';
import Shade from './Shade';
import { UsePortalSelector } from './Store';
import {
	selectCurrentUserData,
	selectIsAdmin,
	selectIsDealer,
	selectIsDealerAdmin,
	selectIsDistributor,
	selectIsDistributorAdmin,
	selectIsEngineer,
	selectIsLoggedIn,
	selectIsManufacturer,
	selectIsPsAdmin,
	selectIsRepresentative,
	selectIsService
} from './Store/user/userPermissions';
import { selectTerritoryList } from './Store/entities/territories';
import { selectDealersAndDispatch } from './Store/entities/hooks';

const userData = (functionName) => function () {
	const [dataSet, setData] = useState(false);

	function refresh() {
		const userFunc = User[functionName] ?? api[functionName];

		userFunc()
			.then((dataNew) => {
				if (dataNew !== dataSet) {
					console.log("======");
					console.log(functionName);
					console.log(dataNew);
					console.log("======");
					setData(dataNew);
				}
			});
	}

	useEffect(() => {
		const h_id = User.hooks.add('login_status_changed', refresh);

		refresh();

		return () => User.hooks.remove(h_id);
	}, []);

	return dataSet;
};

// dataAccessFunc needs to be a promise that resolves to the data
// that needs to be cached
const cacheHooke = (dataAccessFunc) => {
	const [cache, setCache] = useState(null);
	if (cache === null) {
		dataAccessFunc().then((resp) => {
			setCache(resp);
		});
	}
	return cache;
};

export const UseRepNew = () => cacheHooke(() => new Promise((resolve) => {
	api.getRepresentatives().then((resp) => {
		const reps = resp.data.representatives;
		resolve(reps);
	});
}));

const userEntity = (entityName) => function () {
	const [entity, setEntity] = useState(null);

	function refresh() {
		api[`get${entityName}`]()
			.then((u_entity) => {
				if (u_entity && u_entity.id) {
					api[`get${entityName}`](u_entity.id)
						.then((resp) => {
							setEntity(resp.data[entityName.toLowerCase()]);
						});
				}
			});
	}

	useEffect(() => {
		const h_id = User.hooks.add('login_status_changed', refresh);

		refresh();

		return () => User.hooks.remove(h_id);
	}, []);

	return entity;
};

const useUserData = () => UsePortalSelector(selectCurrentUserData);

const useIsManufacturer = () => UsePortalSelector(selectIsManufacturer);
const useIsRepresentative = () => UsePortalSelector(selectIsRepresentative);
const useIsDistributor = () => UsePortalSelector(selectIsDistributor);
const useIsDistributorAdmin = () => UsePortalSelector(selectIsDistributorAdmin);
const useIsDealer = () => UsePortalSelector(selectIsDealer);
const useIsEngineering = () => UsePortalSelector(selectIsEngineer);
const useIsService = () => UsePortalSelector(selectIsService);
const useIsPsAdmin = () => UsePortalSelector(selectIsPsAdmin);
const useIsPowerShadesAdmin = () => UsePortalSelector(selectIsPsAdmin);
const useIsAdmin = () => UsePortalSelector(selectIsAdmin);
const useIsDealerAdmin = () => UsePortalSelector(selectIsDealerAdmin);

const useRepresentative = userEntity('Representative');
const useDealer = userEntity('Dealer');
const useDistributor = userEntity('Distributor');

function useUserMultiplier() {
	return 1;
}

function useUserMyMultiplier() {
	const [multiplier, setMultiplier] = useState(1);

	async function refresh() {
		setMultiplier(await User.myMultiplier());
	}

	useEffect(() => {
		const h_id = User.hooks.add('multiplier_changed', refresh);
		const h_id_2 = User.hooks.add('login_status_changed', refresh);

		refresh();

		return () => User.hooks.remove(h_id, h_id_2);
	}, []);

	return multiplier;
}

function useSaveStatus(quote) {
	if (!quote || !quote instanceof QuoteTemplate) {
		console.error('Invalid quote passed to hook "useSaveStatus"');
	}

	const [saveStatus, setSaveStatus] = useState(quote.save_status || 0);

	const refresh = () => setSaveStatus(quote.save_status);

	useEffect(() => {
		const hook_id = quote.hooks.add('save_status_changed', refresh);

		return () => quote.hooks.remove(hook_id);
	}, []);

	return saveStatus;
}

function useQuoteMSRPMultiplier(quote) {
	if (!quote || !quote instanceof QuoteTemplate) {
		console.error('Invalid quote passed to hook "useQuoteMSRPMultiplier"');
	}

	const [msrpMult, setMsrpMult] = useState(1);

	const refresh = () => {
		quote.getMSRPMultiplier()
			.then((msrp_mult) => {
				setMsrpMult(msrp_mult);
			});
	};

	useEffect(() => {
		const hook_id = quote.hooks.add('updated', refresh);

		refresh();

		return () => quote.hooks.remove(hook_id);
	}, []);

	return msrpMult;
}

function useQuoteTerritory(quote) {
	if (!quote || !quote instanceof QuoteTemplate) {
		console.error('Invalid quote passed to hook "useQuoteTerritory"');
	}

	const [territory, setTerritory] = useState({});

	const refresh = () => {
		api.getTerritoryInfo(quote.territory_id)
			.then((terrinf) => {
				terrinf && setTerritory(terrinf);
			});
	};

	useEffect(() => {
		const hook_id = quote.hooks.add('updated', refresh);

		refresh();

		return () => quote.hooks.remove(hook_id);
	}, []);

	return territory;
}

function useQuoteCoupons(quote) {
	if (!quote || !quote instanceof QuoteTemplate) {
		console.error('Invalid quote passed to hook "useQuoteCoupons"');
	}

	const [coupons, setCoupons] = useState([]);

	const refresh = () => {
		setCoupons(quote.coupons);
	};

	useEffect(() => {
		const hook_id = quote.hooks.add('updated', refresh);

		refresh();

		return () => quote.hooks.remove(hook_id);
	}, []);

	return coupons;
}

function useSelectedShades(quote) {
	if (!quote || !quote instanceof QuoteTemplate) {
		console.error('Invalid quote passed to hook "useSelectedShades"');
	}

	const [shades, setShades] = useState(quote.getSelectedShades());

	useEffect(() => {
		const hook_id = quote.hooks.add('selected_shades_changed', () => setShades(quote.getSelectedShades()));

		return () => quote.hooks.remove(hook_id);
	}, []);

	return shades;
}

function useShade(shade) {
	if (!shade || !shade instanceof Shade) {
		console.error('Invalid shade passed to hook "useShade"');
	}

	const [actualShade, setActualShade] = useState(shade);
	const [shadeHash, setShadeHash] = useState(null);

	function refresh() {
		setActualShade(shade);
		setShadeHash(hash(shade));
	}

	useEffect(() => {
		const hook_id = shade.hooks.add('updated', refresh);

		refresh();

		return () => shade.hooks.remove(hook_id);
	}, []);

	return actualShade;
}

function useShadeMSRP(shade) {
	return 0;
}

function useShadeShippingCost(shade) {
	return 0;
}

function useShadeIsSelected(shade) {
	if (!shade || !shade instanceof Shade) {
		console.error('Invalid shade passed to hook "useShadeIsSelected"');
	}

	const [selected, setSelected] = useState(shade.isSelected());

	useEffect(() => {
		const hook_id = shade.hooks.add('selected_changed', () => setSelected(shade.isSelected()));

		return () => shade.hooks.remove(hook_id);
	}, []);

	return selected;
}

function useTotalShadesMSRP(quote) {
	if (!quote || !quote instanceof QuoteTemplate) {
		console.error('Invalid quote passed to hook "useTotalShadesMSRP"');
	}

	const [totalPrice, setTotalPrice] = useState(null);

	const refresh = () => setTotalPrice(quote.shadesTotalMSRP());

	useEffect(() => {
		const hook_id = quote.hooks.add('msrp_updated', refresh);

		refresh();

		return () => quote.hooks.remove(hook_id);
	}, []);

	return totalPrice;
}

function useTotalShippingFees(quote) {
	if (!quote || !quote instanceof QuoteTemplate) {
		console.error('Invalid quote passed to hook "useTotalShippingFees"');
	}

	const [totalPrice, setTotalPrice] = useState(null);

	const refresh = () => setTotalPrice(quote.getTotalShippingFees());

	useEffect(() => {
		const hook_id = quote.hooks.add('msrp_updated', refresh);

		refresh();

		return () => quote.hooks.remove(hook_id);
	}, []);

	return totalPrice;
}

function useTotalAccessoriesMSRP(quote) {
	return 0;
}

function useAdditionalMotors(quote) {
	if (!quote || !quote instanceof QuoteTemplate) {
		console.error('Invalid quote passed to hook "useAdditionalMotors"');
	}

	const [motors, setMotors] = useState(quote.additional_motors || []);
	const [mhash, setMHash] = useState('');

	useEffect(() => {
		function refresh() {
			setMotors(quote.additional_motors);
			setMHash(hash(quote.additional_motors));
		}

		const h_id = quote.hooks.add('additional_motors_updated', refresh);
		const h_id_2 = quote.hooks.add('updated', refresh);

		return () => quote.hooks.remove(h_id, h_id_2);
	}, []);

	return motors;
}

export function usePricingAs(quote) {
	if (!quote || !quote instanceof QuoteTemplate) {
		console.error('Invalid quote passed to hook "usePricingAs"');
	}

	const [pricingAs, setPricingAs] = useState(quote.pricingAs);

	useEffect(() => {
		const hook_id = quote.hooks.add('pricing_as_changed', () => setPricingAs(quote.pricing_as));

		return () => quote.hooks.remove(hook_id);
	}, []);

	return pricingAs;
}

export function useIsShowingMyPrice(quote) {
	if (!quote || !quote instanceof QuoteTemplate) {
		console.error('Invalid quote passed to hook "useIsShowingMyPrice"');
	}

	const [pricingAs, setPricingAs] = useState(quote.pricingAs);

	useEffect(() => {
		const hook_id = quote.hooks.add('pricing_as_changed', () => setPricingAs(quote.pricing_as));

		return () => quote.hooks.remove(hook_id);
	}, []);

	return pricingAs === 'My Price';
}

function useTotalAdditionalMotorsPrice(quote) {
	if (!quote || !quote instanceof QuoteTemplate) {
		console.error('Invalid quote passed to hook "useTotalAdditionalMotorsPrice"');
	}

	const [msrp, setMSRP] = useState(null);
	const userMultiplier = useUserMultiplier();

	const refresh = () => {

		// setMSRP( quote.additionalMotorsTotalMSRP() );
	};

	useEffect(() => {
		const hook_id = quote.hooks.add('additional_motors_updated', refresh);

		refresh();

		return () => quote.hooks.remove(hook_id);
	}, []);

	return msrp * userMultiplier;
}

export function useAccessoryQuantity(quote, accessory_id) {
	if (!quote || !quote instanceof QuoteTemplate) {
		console.error('Invalid quote passed to hook "useAccessoryQuantity"');
	}

	const [state, setState] = useState(0);

	function refresh() {
		const found = quote.accessories.find((acc) => acc.id === parseInt(accessory_id));

		if (found) {
			setState(found.quantity);
		}
	}

	useEffect(() => {
		const h_id = quote.hooks.add('accessories_updated', refresh);

		refresh();

		return () => quote.hooks.remove(h_id);
	}, []);

	return state;
}

export function useShades(quote) {
	if (!quote || !quote instanceof QuoteTemplate) {
		console.error('Invalid quote passed to hook "useShades"');
	}

	const [shades, setShades] = useState(quote.shades);
	const [idArray, setIdArray] = useState([]);

	function refresh() {
		setIdArray(quote.shades.map((s) => s.getLocalID()));
		setShades(quote.shades);
	}

	useEffect(() => {
		const h_id = quote.hooks.add('shades_updated', refresh);

		refresh();

		return () => quote.hooks.remove(h_id);
	}, []);

	return shades;
}

export {
	useIsPsAdmin,
	useIsPowerShadesAdmin,
	useIsDealerAdmin,
	useIsRepresentative,
	useIsDistributor,
	useIsDistributorAdmin,
	useIsDealer,
	useIsAdmin,
	useUserMultiplier,
	useIsManufacturer,
	useSaveStatus,
	useQuoteMSRPMultiplier,
	useQuoteTerritory,
	useAdditionalMotors,
	useQuoteCoupons,
	useUserMyMultiplier,
	useTotalShadesMSRP,
	useTotalAccessoriesMSRP,
	useSelectedShades,
	useShadeIsSelected,
	// useDealersByTerritory,
	useTotalAdditionalMotorsPrice,
	useShadeShippingCost,
	useShade,
	useShadeMSRP,
	useTotalShippingFees,
	useIsEngineering,
	useIsService,
	useUserData
};
