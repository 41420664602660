import { createAsyncThunk, createSelector } from "@reduxjs/toolkit";

import apiCalls from "../../../PowerShadesAPIFunctions";
import { DistributorLocationStore, extraEntityReducersFunction, loadStatus } from "../entitiesType";
import type { AppState } from "../..";
import { AddressStore, ChannelId, SwapDealersInput } from "../../../powershadesApiTypes";
import { captureSentryError, extractErrorMessage, isFailedApiCall } from "../../../psUtil";

const initialDistributorLocationState: () => DistributorLocationStore = () => {
	const goods: DistributorLocationStore = {
		id: 0,
		name: "",
		loadStatus: loadStatus.notLoaded,
		email: "",
		phone: "",
		invite_code: "",
		channel_id: 0,
		territory_ids: [],
		dealer_territories: [],
		distributor_id: 0,
		users: [],
		distribution_emails: [],
		billing_address: {} as AddressStore,
		current_portfolio: ""
	};

	return goods;
};

const addDistributorLocationFull = createAsyncThunk<
	DistributorLocationStore | null, DistributorLocationStore, {
		state: AppState;
		rejectValue: string;
	}>(
		'entities/addDistributorLocation',
		async (distributorLocationNew, { rejectWithValue }) => {
			let distributorLocation: DistributorLocationStore;

			try {
				const resp = await apiCalls.addDistributorLocation({
					...distributorLocationNew, initial_territory_ids: distributorLocationNew.territory_ids
				});

				if (isFailedApiCall(resp)) {
					throw resp.error;
				}

				const initDistributorLocation = resp.data.distributor_location;

				distributorLocation = {
					...initDistributorLocation,
					loadStatus: loadStatus.fullyLoaded,
					users: [], // On initialization no users are loaded
					territory_ids: initDistributorLocation.territory_ids ?? [],
					dealer_territories: initDistributorLocation.dealer_territories ?? [],
					distribution_emails: initDistributorLocation.distribution_emails ?? [],
				};
			} catch (err: any) {
				captureSentryError(err, {
					tags: {
						section: "redux"
					},
					extra: {
						redux_action: "addDistributorLocation",
						file_origin: "src/Store/entities/organizations/distributorLocations.ts"
					}
				});

				const errorMessage = extractErrorMessage(err);
				return rejectWithValue(errorMessage);
			}

			return distributorLocation;
		}
	);

const swapDealers = createAsyncThunk<boolean, {
	from: DistributorLocationStore; to: DistributorLocationStore;
}, { state: AppState; rejectValue: string;
}>(
	'entities/DistributorLocationSwapDealers',
	async (swapDealersInput, { rejectWithValue }) => {
		let success = false;

		try {
			const resp = await apiCalls.SwapDealers({
				from_dealer_ids: swapDealersInput.from.dealer_territories.map((dt) => dt.dealer_id),
				to_dealer_ids: swapDealersInput.to.dealer_territories.map((dt) => dt.dealer_id),
				from_distributor_location_id: swapDealersInput.from.id,
				to_distributor_location_id: swapDealersInput.to.id
			} as SwapDealersInput);

			if (isFailedApiCall(resp)) {
				throw resp.error;
			}
			success = resp.data.success;
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: "redux"
				},
				extra: {
					redux_action: "DistributorLocationSwapDealers",
					file_origin: "src/Store/entities/organizations/distributorLocations.ts"
				}
			});

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}

		return success;
	}
);

const loadDistributorLocationsMeta = createAsyncThunk<DistributorLocationStore[], void, {
	rejectValue: string;
}>(
	'entities/getDistributorLocationsMeta',
	async (_emptyInput, { rejectWithValue }) => {
		let distributorLocations: DistributorLocationStore[];

		const initDis = initialDistributorLocationState();

		try {
			const resp = await apiCalls.getDistributorLocationsMeta();

			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			distributorLocations = resp.data.distributor_locations.map((di) => ({
				...initDis,
				...di,
				loadStatus: loadStatus.metaLoaded
			}));
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: "redux"
				},
				extra: {
					redux_action: "getDistributorLocationsMeta",
					file_origin: "src/Store/entities/organizations/distributorLocations.ts"
				}
			});

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}

		return distributorLocations;
	}
);

const loadDistributorLocationFull = createAsyncThunk<DistributorLocationStore, number, { rejectValue: string }>(
	'entities/getDistributorLocationsFull',
	async (distributorId, { rejectWithValue }) => {
		let distributorLocation: DistributorLocationStore;

		const initDis = initialDistributorLocationState();

		try {
			const resp = await apiCalls.getDistributorLocationFull(distributorId);

			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			distributorLocation = {
				...initDis,
				...resp.data.distributor_location,
			};
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: "redux"
				},
				extra: {
					redux_action: "getDistributorLocationsFull",
					file_origin: "src/Store/entities/organizations/distributorLocations.ts"
				}
			});

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}

		return distributorLocation;
	});

const selectDistributorLocationList = createSelector((state: AppState) =>
	state.entity.distributorLocations,
	(distributorLocations) => {
		const updatedDealers = Object.entries(distributorLocations)
			.reduce((list, [key, value]) => {
				if (key === "loadStatus") return list;
				list.push(value as DistributorLocationStore);
				return list;
			}, [] as DistributorLocationStore[])
			.filter(
				(d) => d?.loadStatus !== loadStatus.notLoaded
			);
		return updatedDealers;
	});

// Null if bad. Otherwise, returns the updated dealer
const updateDistributorLocationFull = createAsyncThunk<
	DistributorLocationStore | null, DistributorLocationStore, {
		state: AppState;
		rejectValue: string;
	}>(
		'entities/updateDistributorLocationFull',
		async (distributorNew, { rejectWithValue }) => {
			let distributor: DistributorLocationStore;

			try {
				const resp = await apiCalls
					.updateDistributorLocation(distributorNew.id,
						{ ...distributorNew, channel_id: distributorNew.channel_id as ChannelId, current_portfolio: distributorNew.current_portfolio }
					);
				
				if (isFailedApiCall(resp)) {
					throw resp.error;
				}

				distributor = {
					...distributorNew,
					...resp.data.distributor_location,
				};
			} catch (err: any) {
				captureSentryError(err, {
					tags: {
						section: "redux"
					},
					extra: {
						redux_action: "updateDistributorLocationFull",
						file_origin: "src/Store/entities/organizations/distributorLocations.ts"
					}
				});

				const errorMessage = extractErrorMessage(err);
				return rejectWithValue(errorMessage);
			}

			const newDistStore: DistributorLocationStore = {
				...distributorNew,
				...distributor,
				loadStatus: loadStatus.fullyLoaded
			};

			return newDistStore;
		}
	);

const distributorLocationBuilder: extraEntityReducersFunction = (_builder) => {
	_builder.addCase(loadDistributorLocationsMeta.pending, (state) => {
		state.distributorLocations.loadStatus = loadStatus.loading;
		return state;
	});

	_builder.addCase(loadDistributorLocationsMeta.fulfilled, (state, action) => {
		action.payload.forEach((distributorLocation) => {
			state.distributorLocations[distributorLocation.id] = {
				...distributorLocation,
				loadStatus: loadStatus.metaLoaded
			};
		});

		state.distributorLocations.loadStatus = loadStatus.metaLoaded;
		return state;
	});

	_builder.addCase(loadDistributorLocationFull.pending, (state, action) => {
		state.distributorLocations[action.meta.arg] = {
			...initialDistributorLocationState(),
			id: action.meta.arg,
			...state.distributorLocations[action.meta.arg],
			loadStatus: loadStatus.loading
		};
		return state;
	}
	);
	_builder.addCase(addDistributorLocationFull.fulfilled, (state, action) => {
		const newDistributorLocation = action.payload;
		if (newDistributorLocation) {
			state.distributorLocations[newDistributorLocation.id] = {
				...initialDistributorLocationState(),
				...state.distributorLocations[newDistributorLocation.id],
				...newDistributorLocation,
				loadStatus: loadStatus.fullyLoaded,
			};

			state.distributorLocations.loadStatus = loadStatus.needsLoaded;
		}

		return state;
	}
	);

	_builder.addCase(loadDistributorLocationFull.fulfilled, (state, action) => {
		state.distributorLocations[action.payload.id] = {
			...action.payload, loadStatus: loadStatus.fullyLoaded, id: action.payload.id
		};
		return state;
	}
	);
	_builder.addCase(swapDealers.fulfilled, (state, action) => {
		const from = state.distributorLocations[action.meta.arg.from.id];
		const to = state.distributorLocations[action.meta.arg.to.id];

		if (from && to) {
			from.dealer_territories = action.meta.arg.from.dealer_territories;
			to.dealer_territories = action.meta.arg.to.dealer_territories;
		}

		return state;
	});

	_builder.addCase(updateDistributorLocationFull.fulfilled, (state, action) => {
		const newDistributorLocation = action.payload;
		if (newDistributorLocation) {
			state.distributorLocations[newDistributorLocation.id] = {
				...state.distributorLocations[newDistributorLocation.id],
				...newDistributorLocation,
				loadStatus: loadStatus.fullyLoaded,
			};
		}

		return state;
	});
};

export {
	distributorLocationBuilder,
	loadDistributorLocationsMeta,
	selectDistributorLocationList,
	loadDistributorLocationFull,
	initialDistributorLocationState,
	addDistributorLocationFull,
	swapDealers,
	updateDistributorLocationFull
};
