import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { AppDispatch, AppState } from "..";
import { extraEntityReducersFunction, loadStatus } from "./entitiesType";
import { FabricStore } from "../../powershadesApiTypes";
import store from "..";
import apiCalls from "../../PowerShadesAPIFunctions";
import { createSelector } from "reselect";

 
// -------------- EXAMPLE OF A NEW STATE SHAPE --------------

export enum LoadStatus {
	notLoaded = "notLoaded",
	metaLoaded = "metaLoaded",
	loading = "loading",
	fullyLoaded = "fullyLoaded",
  }

interface FabricsByQuote {
	[quoteId: number]: {
	  list: FabricStore[];
	  loadStatus: LoadStatus;
	};
  }
  
  export interface FabricsState {
	fabrics: FabricsByQuote;
  }
  
  // The initialState: an object with an empty "fabrics"
//   const initialState: FabricsState = {
// 	fabrics: {}
//   };
  // ----------------------------------------------------------


// Quote Id is needed to get the list of fabrics.
// Thunk to load Fabrics
export const loadFabrics = createAsyncThunk<
  FabricStore[],
  { quoteId: number },
  { rejectValue: boolean }
>(
  "entities/GetFabrics",
  async ({ quoteId }, { rejectWithValue }) => {
    try {
      const resp = await apiCalls.getFabricList(quoteId);
      const fabricsFromApi = resp.data.fabrics;

      return fabricsFromApi.map((fabVM: any) => ({
        name: fabVM.name,
        imageUrl: "",
        // We can set the load status using our loadStatus object:
        imageLoaded: loadStatus.notLoaded,
        isNextLoad: false,
        fabric_color: "",
        fabric_pattern: "",
        is_indoor: fabVM.is_indoor,
        is_outdoor: fabVM.is_outdoor,
        is_psoutdoor: fabVM.is_ps_outdoor,
        is_seamable: fabVM.is_seamable,
        is_railroadable: fabVM.is_railroadable,
        is_discontinued: fabVM.is_discontinued,
        is_gone: fabVM.is_gone
      }));
    } catch (err: any) {
      const error: AxiosError = err;
      console.error(error);
      return rejectWithValue(false);
    }
  }
);

// export const loadFabricImage = (fabricName : string) => createAsyncThunk<string, string, {
//     rejectValue: boolean,
// }>(
//     `entities/LoadFabricImage/${fabricName}`,
//     async (fabricName, { rejectWithValue }) => {

//         let fabricImageUrl : string = "";

//         try {

//             let resp = await api.getFabricImage(fabricName);

//             let dataStr = resp.data;

//             var myblob = new Blob([dataStr], {
//                 type: 'text/plain'
//             });

//             let url = window.URL.createObjectURL(myblob)

//             fabricImageUrl = url;

//         } catch (err: any) {
//             let error: AxiosError = err;

//             console.error(error);

//             return rejectWithValue(false)
//         }

//         return fabricImageUrl;
//     }
// )(fabricName)

// Using currying to pass the quoteId to the function that ensures loading
export const EnsureFabrics = (quoteId: number) => (storeState: AppState, dispatch: AppDispatch) => {
	// Get the quote-specific sub-slice of fabrics
	const fabricsByQuote = storeState.entity.fabrics[quoteId];
  
	// If it doesn’t exist or its loadStatus is not fullyLoaded, dispatch loadFabrics
	if (!fabricsByQuote || fabricsByQuote.loadStatus !== loadStatus.fullyLoaded) {
	  dispatch(loadFabrics({ quoteId }));
	}
  };
  
  export const SelectFabricsUnsorted = (quoteId: number) => (storeState: AppState) => {
	// Get the quote-specific portion
	const fabricsByQuote = storeState.entity.fabrics[quoteId];
  
	// If there’s nothing (or it’s neither metaLoaded nor loading), dispatch load and return []
	if (
	  !fabricsByQuote ||
	  (fabricsByQuote.loadStatus !== loadStatus.metaLoaded &&
		fabricsByQuote.loadStatus !== loadStatus.loading)
	) {
	  store.dispatch(loadFabrics({ quoteId }));
	  return [];
	}
  
	// Return the list of fabrics for this quoteId
	return fabricsByQuote.list;
  };
  
  // SelectFabrics: returns the list sorted by name
  export const SelectFabrics = (quoteId: number) =>
	createSelector(
	  SelectFabricsUnsorted(quoteId),
	  (fabrics) => fabrics.slice().sort((f1, f2) => f1.name.localeCompare(f2.name))
	);
  
  // Filter PSOutdoor fabrics
  export const SelectPSOutdoorFabrics = (quoteId: number) =>
	createSelector(
	  SelectFabrics(quoteId),
	  (fabrics) =>
		fabrics.filter((f) => f.is_psoutdoor && !f.is_gone && !f.is_discontinued)
	);
  
  // Filter outdoor fabrics
  export const SelectOutdoorFabrics = (quoteId: number) =>
	createSelector(
	  SelectFabrics(quoteId),
	  (fabrics) =>
		fabrics.filter((f) => f.is_outdoor && !f.is_gone && !f.is_discontinued)
	);
  
  // Filter indoor fabrics
  export const SelectIndoorFabrics = (quoteId: number) =>
	createSelector(
	  SelectFabrics(quoteId),
	  (fabrics) =>
		fabrics.filter((f) => f.is_indoor && !f.is_gone && !f.is_discontinued)
	);
  
  // Selector to get the image URL of a specific fabric
  // Note: now we also pass quoteId to find the correct list
  export const SelectFabricImageUrl = (quoteId: number, fabricName: string) => (store: AppState) => {
	// Retrieve the list for this quoteId, or an empty array if none
	const fabrics = store.entity.fabrics[quoteId]?.list ?? [];
	return fabrics.find((f) => f.name === fabricName)?.imageUrl ?? null;
  };


// Building the reducer reactions to the actions
export const fabricBuilder: extraEntityReducersFunction = (builder) => {
	// loadFabrics.pending:
	builder.addCase(loadFabrics.pending, (state, action) => {
	  const { quoteId } = action.meta.arg;
	  // If there's no “sub-section” for this quoteId yet, create it
	  if (!state.fabrics[quoteId]) {
		state.fabrics[quoteId] = { list: [], loadStatus: loadStatus.loading};
	  }
	  // Set it to "loading"
	  state.fabrics[quoteId].loadStatus = loadStatus.loading;
	});
  
	// loadFabrics.fulfilled:
	builder.addCase(loadFabrics.fulfilled, (state, action) => {
	  const { quoteId } = action.meta.arg;
	  const fabrics = action.payload; // The FabricStore array
  
	  // Save the array under [quoteId]
	  state.fabrics[quoteId] = {
		list: fabrics,
		loadStatus: loadStatus.metaLoaded
	  };
	});

	builder.addMatcher((action) => {
		const type = action.type as string;
		return type.includes("entities/LoadFabricImage/");
	}, (state, action: PayloadAction<string, string, {
        arg: null;
        requestId: string;
        requestStatus: "fulfilled";
    }, never>) => {
		const imageUrl = action.payload;

		const items = action.type.split("/");
		const fabricName = items[2];

		const fabric = state.fabrics.list.find((f) => f.name == fabricName);

		if (fabric == null) {
			return state;
		}
		const newFabric: FabricStore = {
			...fabric,
			imageUrl,
			imageLoaded: loadStatus.fullyLoaded
		};

		state.fabrics.list = state.fabrics.list.map((l) => (l.name == fabricName ? newFabric : l));

		return state;
	});
};
