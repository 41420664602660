import React, { useEffect, useState } from 'react';
import { Alert, Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Popup from 'reactjs-popup';
import { Typeahead } from 'react-bootstrap-typeahead';

import { useIsPsAdmin, useIsRepresentative, useIsDistributor, useUserData } from '../hooks';
import TextDivider from '../Parts/TextDivider';
import MultiEmailInput from '../Parts/MultiEmailInput';
import SpinningWheel from '../Parts/SpinningWheel';
import Button from '../Parts/Button';

import { useDistributorLocationsByDistributorId, useDistributors, useRepresentativeById, useRepresentatives, useTerritories } from '../Store/entities/hooks';
import apiCalls from '../PowerShadesAPIFunctions'; 
import { AddDealerProps } from './types';
import TerritorySelect from '../Parts/TerritorySelect';
import RepresentativeSelect from '../Parts/RepresentativeSelect';
import DistributorSelect from '../Parts/DistributorSelect';
import { DistributorLocationStore } from '../Store/entities/entitiesType';

const AddDealer = ({ ROOT }: AddDealerProps) => {
	const [currentTerritoryId, setCurrentTerritoryId] = useState(0);
	// const [repOrDist, setRepOrDist] = useState<SignUpType>('select');
	const userData = useUserData();

	const defaultDistId = userData?.roles.find((r) => r.role_name.includes("distributor"))?.entity_id ?? 0;

	const [currentDistributorId, setCurrentDistributorId] = useState(defaultDistId);
	const [currentDistributorLocationId, setCurrentDistributorLocationId] = useState(0);
	const [currentRepId, setCurrentRepId] = useState(0);
	const [sendingEmails, setSendingEmails] = useState(false);
	const [emailsSent, setEmailsSent] = useState(false);
	const [emails, setEmails] = useState([]);
	const [showInviteModal, setShowInviteModal] = useState(false);
	const {
		alert, toast, setTitle
	} = ROOT;

	const isPsAdmin = useIsPsAdmin();
	const isRepresentative = useIsRepresentative();
	const isDistributor = useIsDistributor();

	const distributorsLoading = useDistributors().length === 0;


	const userId = userData.id;

	const territories = useTerritories();

	const distributors = useDistributors();

	const representatives = useRepresentatives();

	const userDistributor = distributors.find((d) => d.id === userData?.roles[0]?.entity_id && userData?.roles[0].role_name.includes("distributor"));
	const currentDistributor = distributors.find((di) => di.id === currentDistributorId) ?? userDistributor;
	const userRep = representatives
	.find((d) => d.id === userData?.roles[0]?.entity_id);
	const currentRep =  useRepresentativeById(currentRepId) ?? userRep;
	const distLocations = useDistributorLocationsByDistributorId(
		currentDistributor?.id ?? currentDistributorId
	);

	useEffect(() => {
		setTitle('Add Dealer');
	}, [setTitle]);

	/**
	 * Returns the current invite code based on the selected distributor and representative.
	 * If no representative is selected, the distributor's invite code is returned.
	 * If no distributor is selected, the representative's invite code is returned.
	 * If both a distributor and a representative 
	 * are selected, their invite codes are combined with a hyphen.
	 * @returns The current invite code.
	 */
	const getCurrentInviteCode = () => {
		const currentRepresentativeCode = currentRep?.invite_code;

		const currentDistributorLocationCode = distLocations
			.find((dil) => dil.id === currentDistributorLocationId)?.invite_code
			?? distLocations[0]?.invite_code
			?? '';

		if ((currentRep?.id ?? 0) == 0 && currentDistributor?.id !== 0) {
			return currentDistributorLocationCode;
		} else if (currentRep?.id !== 0 && (currentDistributor?.id ?? 0) === 0) {
			return currentRepresentativeCode;
		}
		return `${currentDistributorLocationCode}-${currentRepresentativeCode}`;
	};


	/** 
	 * Returns the query string for the invite link.
	 * @returns The query string for the invite link.
	 */
	const getInviteLinkQuery = () =>
		`invite=${getCurrentInviteCode()}&t=${currentTerritoryId}&u=${userId ?? 0}`;

	/**
	 * Returns the invite link.
	 * @returns The invite link.
	 * @see getInviteLinkQuery
	 */
	const getInviteLink = () =>
		`${process.env.ROOT_URL || 'https://portal.powershades.com'
		}/#/DealerSignup?${getInviteLinkQuery()}`;

	/**
	 * Method for extracting just the strings out of the array of emails.
	 * If the user manually clicks the "add selection" it transforms into
	 * an object instead of a string. I have no idea why.
	 * @param array - The array of emails to extract the labels from.
	 * @returns The array of labels (emails).
	 */
	const extractLabels = (array: Array<any>): string[] => array.map((item) => {
		// Check if item is an object and has a 'label' property
		if (typeof item === "object" && item !== null && "label" in item) {
			return item.label;
		}
		// If item is not an object or doesn't have a 'label' property, return the item itself
		return item;
	});

	/**
	 * Sends dealer invite emails to the specified email addresses.
	 * If there are no email addresses, the function returns without doing anything.
	 * If the email sending is successful, the emails state is reset and a success toast is displayed.
	 * If the email sending fails, an error alert is displayed.
	 * @see getInviteLinkQuery
	 */
	const sendEmails = () => {
		if (emails.length < 1) return;
		const validatedEmails = extractLabels(emails);

		apiCalls.sendDealerInvite(getInviteLinkQuery(), validatedEmails).then((resp) => {
			if (resp.data.success) {
				setEmails([]);
				toast('Email sent', { type: 'success' });
			} else {
				alert({
					text: 'Error sending emails.\n Please try again later.',
					title: 'Error',
					icon: 'error',
					noCancelButton: true,
					acceptButtonText: 'Dismiss',
				});
			}

			setEmailsSent(true);
			setSendingEmails(false);
		});

		setSendingEmails(true);
	};

	/* Filters the territories based on whether the current representative and 
	distributor have access to them. 
	If either the current representative or distributor has access to a territory, 
	it is included in the filtered list. */
	const filteredTerritories = territories.filter((terr) => {
		const passRep = currentRep?.territory_ids?.some((t) => t === terr.id) ?? true;
		const passDist = currentDistributor?.territory_ids?.some((t) => t === terr.id) ?? true;
		return passRep || passDist;
	});

	// This is a lot of UI logic that should boil down to a few simple cases
	// 1. If the user is a PS Admin, they can select a rep or distributor
	// 2. If the user is a rep, they can select a distributor or choose not to
	// 3. If the user is a distributor, they can select a rep or choose not to

	const showRepSelect = isPsAdmin || isDistributor;

	const showDistSelect = isPsAdmin || isRepresentative;

	const showTerritorySelector = isPsAdmin || filteredTerritories.length > 0;

	// console.log(showDistributorLocationSelector);

	// Filters the representatives based on the selected territory.
	const filteredReps = representatives.filter((rep) => {
		if (currentTerritoryId === 0 || !currentTerritoryId) return true;
		const repHasTerritory = rep.territory_ids.includes(currentTerritoryId);
		return repHasTerritory;
	});

	// Filters the distributors based on the selected territory.
	const filteredDistributors = distributors.filter((dist) => {
		if (currentTerritoryId === 0 || !currentTerritoryId) return true;
		const distHasTerritory = dist.territory_ids.includes(currentTerritoryId);
		return distHasTerritory;
	});

	const distributorLocations = useDistributorLocationsByDistributorId(currentDistributorId);

	const showDistributorLocationSelector = distLocations.length > 1;

	/**
	 * Validates the current territory, representative, and distributor selections
	 * and shows the invite modal if they are valid.
	 * If the current territory is not selected and the territory selector is shown, 
	 * an error alert is displayed.
	 * If both the representative and distributor selectors are shown and neither 
	 * is selected, an error alert is displayed.
	 * Otherwise, the invite modal is shown.
	 */
	const validateAndShowModal = () => {
		if (!currentTerritoryId && showTerritorySelector) {
			ROOT.alert({ text: 'Please select a territory', quickFormat: 'error' });
			return;
		}
		if ((showRepSelect && currentRepId === 0) && (showDistSelect && currentDistributorId === 0)) {
			ROOT.alert({ text: 'Please select a representative or distributor', quickFormat: 'error' });
			return;
		}

		if (showDistributorLocationSelector && (currentDistributorLocationId === 0)) {
			ROOT.alert({ text: 'Please select a distributor location.', quickFormat: 'error' });
			return;
		}

		setShowInviteModal(true);
	};

	/**
	 * Closes the invite modal and resets the email state and flags.
	 */
	const handleCloseModal = () => {
		setEmails([]);
		setShowInviteModal(false);
		setEmailsSent(false);
	};

	return (
		<Container>
			<Popup open={showInviteModal} onClose={() => handleCloseModal} className="mobile-modal">
				<Row className="mb-2">
					{emailsSent ? (
						<Col className="text-center">
							<h4>Email(s) sent!</h4>
						</Col>
					) : (
						<Col>
							<Row>
								<Col className="text-center">
									<h4>Send Invite Emails</h4>
								</Col>
							</Row>

							<Row>
								<Col>
									{/* necessary to fix auto focus? */}
									<input className="d-none" />
									<MultiEmailInput
										emails={emails}
										onChange={(arr) => setEmails(arr)}
									/>
								</Col>
							</Row>

							<Row className="justify-content-end mt-2">
								<Col className="text-end">
									<Button
										color={emails.length ? 'green' : 'gray'}
										disabled={!emails.length}
										onClick={sendEmails}
									>
										{sendingEmails ? <SpinningWheel /> : 'Send'}
									</Button>
								</Col>
							</Row>
						</Col>
					)}
				</Row>

				<TextDivider>or</TextDivider>

				<Row>
					<Col>
						<Col className="text-center">
							<h4>Copy Signup Link</h4>
						</Col>

						<Col className="mb-2">
							<label className="mb-1 text-muted" htmlFor="signup-link">
								Signup Link
							</label>
							<InputGroup>
								<Form.Control value={getInviteLink()} readOnly />
								<Button
									onClick={() => {
										navigator.clipboard
											.writeText(getInviteLink())
											.then(() => {
												toast('Invite link copied to clipboard', {
													type: 'success',
												});
											})
											.catch(() => {
												toast('Failed to copy to clipboard', {
													type: 'error',
												});
											});
									}}
								>
									Copy
								</Button>
							</InputGroup>
						</Col>
						<Alert variant="info">
							<FontAwesomeIcon icon={faInfoCircle} className="me-1" />
							This link is not unique and can be reused for multiple dealers.
						</Alert>
					</Col>
				</Row>
				<Row className="justify-content-end">
					<Col className="text-end">
						<Button color="gray" onClick={handleCloseModal}>
							Close
						</Button>
					</Col>
				</Row>
			</Popup>
			<Card body className="entity-card">
				<Row>
					{showTerritorySelector && (
						<Col>
							<TerritorySelect
								optionsOverride={filteredTerritories}
								handleChange={(tId) => setCurrentTerritoryId(tId)}
								selectedTerritoryIds={[currentTerritoryId]}
							/>
						</Col>
					)}
					{showRepSelect && (
						<Col>
							<RepresentativeSelect optionsOverride={filteredReps} handleChange={setCurrentRepId} />
						</Col>
					)}
					{showDistSelect && (
						<Col>
							<DistributorSelect
								optionsOverride={filteredDistributors}
								handleChange={setCurrentDistributorId}
							/>
						</Col>
					)}
				</Row>
				<Row className="justify-content-end">
					{
						showDistributorLocationSelector && (
							<Col xs={4} className="text-end">
								<Typeahead
									clearButton
									id="dist-loc-selector"
									paginate
									positionFixed
									labelKey="name"
									placeholder={
										distributorsLoading
											? 'Loading Distributor Locations...'
											: 'Select a Distributor Location...'
									}
									options={distributorLocations}
									isLoading={distributorsLoading}
									disabled={distributorsLoading}
									onChange={(selected) => {
										if (!selected) return;
										const distributor = selected as unknown as DistributorLocationStore;
										setCurrentDistributorLocationId(distributor[0]?.id ?? 0);
									}}
								/>
							</Col>
						)
					}
				</Row>
				<Row className="mt-4">
					<Col className="text-muted">
						<Alert variant="info">
							<FontAwesomeIcon icon={faInfoCircle} className="me-1" />
							Once a dealer has filled out the sign-up form, they will need to be
							approved or denied before using the Dealer Portal.
						</Alert>
					</Col>
				</Row>
				<Row className="justify-content-end">
					<Col className="text-end">
						<Button color="green" onClick={validateAndShowModal}>
							Generate Invite
						</Button>
					</Col>
				</Row>
			</Card>
		</Container>
	);
};

export default AddDealer;
