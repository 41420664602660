import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import Popup from 'reactjs-popup';
import { Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ValidationError, number, object, string } from 'yup';
import { startCase } from 'lodash';

import Button from '../Parts/Button';
import { EntityTypeStrings, randomString } from '../psUtil';

import { useAddUser } from '../Store/entities/hooks';

import { createUser } from '../Store/entities/users';
import EntitySelect from '../Parts/EntitySelect';

import type { AddUserModalProps, DirtyAddUserStore, ValidatedAddUserStore } from './types';

import UserRoleSelect from '../Parts/UserRoleSelect';

const createUserSchema = object({
	name: string().max(70, 'Name cannot be longer than 70 characters').required('Name is required'),
	email: string().email('Email must be a valid email').required('Email is required'),
	phone: string().max(20, 'Phone cannot be longer than 20 characters'),
	password: string()
		.max(70, 'Password cannot be longer than 70 characters')
		.required('Password is required'),
	role_id: number()
		.moreThan(0, 'Role is required')
		.positive()
		.integer()
		.required('Role is required'),
});

const AddUserModal = ({
	onClose,
	callBack,
	open,
	inheritedEntityId = 0,
	inheritedEntityName = '',
	entityType = undefined,
	allowEntitySelection = true,
}: AddUserModalProps) => {
	const [user, setUser] = useState<DirtyAddUserStore>({
		name: '',
		email: '',
		phone: '',
		password: randomString(15),
		role_id: 0,
	});
	const [autoPass, setAutoPass] = useState(true);
	const [confirmPassword, setConfirmPassword] = useState('');
	const [selectedEntityId, setSelectedEntityId] = useState(inheritedEntityId);
	const [selectedEntityName, setSelectedEntityName] = useState(inheritedEntityName);
	const [sendEmailWithReset, setSendEmailWithReset] = useState(false);
	const [selectedRoleCategory, setSelectedRoleCategory] = useState<EntityTypeStrings>('unknown');
	const [creatingUser, setCreatingUser] = useState(false);

	const handleCreateUser = useAddUser();

	const powerShadesAdminRoleId = 8;
	const selectedRoleIsPsAdmin = user.role_id === powerShadesAdminRoleId;

	const displayEntitySelector = allowEntitySelection && !selectedRoleIsPsAdmin;

	const updateUser = (updateObject: Partial<DirtyAddUserStore>) => {
		setUser({ ...user, ...updateObject });
	};

	const handleSetAutoPass = () => {
		setAutoPass(!autoPass);
		setConfirmPassword('');
		setUser({ ...user, password: '' });
		if (!autoPass) {
			const newPassword = randomString(15);
			setConfirmPassword(newPassword);
			setUser({ ...user, password: newPassword });
		}
	}

	const handleClose = () => {
		setUser({
			name: '',
			id: 0,
			email: '',
			phone: '',
			password: randomString(15),
			role_id: 0,
		});
		setAutoPass(true);
		setConfirmPassword('');
		setSelectedEntityId(0);
		setSelectedEntityName('');
		setSendEmailWithReset(false);
		setSelectedRoleCategory('unknown');
		setCreatingUser(false);
		onClose();
	};

	const createNewUser = async () => {
		setCreatingUser(true);
		try {
			const validatedUser = (await createUserSchema.validate(user)) as ValidatedAddUserStore;
			if (!autoPass && validatedUser.password !== confirmPassword) {
				throw new ValidationError('Passwords do not match', user, 'password');
			}
			const createUserResponse = await handleCreateUser({
				email: validatedUser.email,
				role_id: validatedUser.role_id,
				entity_id: selectedEntityId,
				user_name: validatedUser.name,
				entity_name: selectedEntityName,
				password: validatedUser.password,
				confirm_password: autoPass ? validatedUser.password : confirmPassword,
				send_email_with_reset: sendEmailWithReset,
			});
			if (createUserResponse.type === createUser.fulfilled.type) {
				toast.success('User Created');
				const newUserId = Number(createUserResponse.payload) ?? 0;
				callBack && callBack(newUserId);
				handleClose();
			} else {
				toast.error(createUserResponse.payload);
				setCreatingUser(false);
				throw new Error(`Error creating user: ${createUserResponse.payload}`);
			}
		} catch (error) {
			if (error instanceof ValidationError) {
				setCreatingUser(false);
				toast.error(error.message);
			} else {
				throw error;
			}
		}
	};

	const handleUserRoleIdChange = (roleId: number) => {
		updateUser({ role_id: roleId });
	};

	useEffect(() => {
		if (selectedRoleCategory !== 'unknown') return;
		const entityPassedThrough = inheritedEntityId !== 0 && inheritedEntityName !== '';
		if (entityPassedThrough && entityType) {
			setSelectedEntityId(inheritedEntityId);
			setSelectedEntityName(inheritedEntityName);
			setSelectedRoleCategory(entityType);
		}
	}, [open, inheritedEntityId, inheritedEntityName, entityType]);

	const entitySelector = (
		<Row className="mb-2">
			<Col>
				<label className="mb-2">
					{selectedRoleCategory !== 'unknown' ? startCase(`${selectedRoleCategory}s`) : 'Entities'}
				</label>
				<EntitySelect
					placeholder={
						selectedRoleCategory !== 'unknown'
							? `Select a ${selectedRoleCategory}...`
							: 'Select a role first...'
					}
					disabled={user.role_id === 0}
					selectedEntityId={selectedEntityId}
					setSelectedEntityId={setSelectedEntityId}
					setSelectedEntityName={setSelectedEntityName}
					selectedRoleCategory={selectedRoleCategory}
				/>
			</Col>
		</Row>
	);

	return (
		<Popup closeOnDocumentClick={false} open={open} onClose={handleClose} className="mobile-modal">
			<Row className="mb-2">
				<Col>
					<h4>Add User</h4>
				</Col>
			</Row>
			<Row className="mb-2">
				<Col>
					<label className="mb-2">Name</label>
					<Form.Control
						value={user.name ?? ''}
						onChange={(e) => updateUser({ name: e.target.value })}
					/>
				</Col>
				<Col>
					<label className="mb-2">Email</label>
					<Form.Control
						value={user.email ?? ''}
						onChange={(e) => updateUser({ email: e.target.value })}
					/>
				</Col>
			</Row>
			<Row className="mb-2">
				<Col>
					<label className="mb-2">Role</label>
					<UserRoleSelect
						selectedUserRoleId={user.role_id}
						setSelectedUserRoleId={handleUserRoleIdChange}
						setSelectedRoleCategory={setSelectedRoleCategory}
						categoryOverride={selectedRoleCategory}
					/>
				</Col>
			</Row>

			{displayEntitySelector && entitySelector}

			<Row className="auto-select-password mb-2 mt-2">
				<Col>
					<Button
						fluid
						fullWidth
						color={autoPass ? 'green' : 'gray'}
						onClick={() => handleSetAutoPass()}
						radioButton
					>
						Auto Select Password&nbsp;
						<FontAwesomeIcon icon={autoPass ? faCheck : faTimes} />
					</Button>
				</Col>
				<Col>
					<Button
						fluid
						fullWidth
						color={sendEmailWithReset ? 'green' : 'gray'}
						onClick={() => setSendEmailWithReset(!sendEmailWithReset)}
						radioButton
					>
						Send Email With Reset&nbsp;
						<FontAwesomeIcon icon={sendEmailWithReset ? faCheck : faTimes} />
					</Button>
				</Col>
			</Row>

			<Row className="full-width">
				<Col>
					<label className="mb-2">Password</label>
					<Form.Control
						type={autoPass ? 'text' : 'password'}
						readOnly={autoPass}
						disabled={autoPass}
						value={user.password}
						onChange={(e) => updateUser({ password: e.target.value })}
					/>
				</Col>

				{autoPass ? null : (
					<div>
						<label className="mb-2">Confirm Password</label>
						<Form.Control
							type="password"
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
						/>
					</div>
				)}
			</Row>

			<Row className="justify-content-end mt-4">
				<Col className="text-start">
					<Button color="gray" onClick={handleClose}>
						Close
					</Button>
				</Col>
				<Col className="text-end">
					<Button color="green" onClick={createNewUser} loading={creatingUser}>
						<FontAwesomeIcon icon={faPlus} />
						&nbsp; Create User
					</Button>
				</Col>
			</Row>
		</Popup>
	);
};

export default AddUserModal;
