import React, { Component, useEffect, useState } from 'react';

import getSymbolFromCurrency from 'currency-symbol-map';
import api from '../PowerShadesAPI';
import Button from './Button';
import apiCalls from '../PowerShadesAPIFunctions';
import { useQuoteById, useQuoteIdAndDispatch } from '../Store/entities/quotes/hooks';
import { useDealerById } from '../Store/entities/hooks';

const NewCardField = (props) => {
	const {
		total,
		half,
		payHalf,
		payTotal,
		currency,
		setCard,
		quoteId,
		setIsActuallyAccount,
		quoteType,
		isActuallyAccount,
	} = props;

	const symbol = getSymbolFromCurrency(currency);

	const [paymentMethods, setPayments] = useState(null);
	const [gettingPayments, setGettingPayments] = useState(false);
	const [cardBeingUsed, setCardBeingUsed] = useState(null);
	const [zohoLink, setZohoLink] = useState(null);

	const [willNeedRetainer, setWillNeedRetainer] = useState(null);

	const { cards, accounts } = paymentMethods ?? {};

	const quote = useQuoteIdAndDispatch(quoteId);
	const dealer = useDealerById(quote.dealer_id);

	useEffect(() => {
		if(dealer) 
		{
			apiCalls.getPaymentMethods2("Dealers",dealer.id).then((resp) => {
				const paymentItems = resp.data;
	
				setPayments(paymentItems);
			});
		}
		
	}, [dealer]);

	const useCard = (card) => {
		setCard(card);
		setCardBeingUsed(card);
	};

	const addACard = () => {
		apiCalls.getAddCardLink("Dealers", dealer.id).then((resp) => {
			const { link } = resp.data;
			const openLink = window.open(link, "_blank");
			setZohoLink(link);
		});
		
		setGettingPayments(true);

		const intervalObj = {};

		intervalObj.oldCards = JSON.stringify(paymentMethods);

		intervalObj.expire = 48;
		intervalObj.count = 0;
		intervalObj.gettingCard = true;

		intervalObj.id = setInterval(() => {
			if (!intervalObj.gettingCard) {
				clearInterval(intervalObj.id);
			}

			apiCalls.getPaymentMethods2("Dealers",dealer.id).then((resp) => {
				if (intervalObj.expire <= intervalObj.count) {
					clearInterval(intervalObj.id);
					setGettingPayments(false);
					intervalObj.gettingCard = false;
				}

				const newCards = resp.data;

				if (JSON.stringify(newCards) != intervalObj.oldCards) {
					setPayments(newCards);
					setGettingPayments(false);
					clearInterval(intervalObj.id);
					intervalObj.gettingCard = false;
				}
				intervalObj.count += 1;
			});
		}, 5000);

		// Add Card state
		// Must contain an explanation
		// On return we add instructions and open a window
		// Current state must have a cancel button and a done button.
		// Open a new window with the information
	};

	const cardChosen = (item, isAccount) => {
		if (!isAccount) {
			api.cardSelected(quoteId).then((resp) => {
				console.log('cardSelected');
			});
		} else {
			api.achSelected(quoteId).then((resp) => {
				console.log('achSelected');
			});
		}

		setIsActuallyAccount(isAccount);

		useCard(item);

		const paymentType = isAccount ? 'ACH' : 'CC';

		const retainerFunc = isAccount ? api.achRetainerNeeded : api.cardRetainerNeeded;

		retainerFunc(quoteId).then((resp) => {
			if (resp.status !== 200) {
				console.error(resp.data);
				throw new Error('Something went wrong checking if this order needs a retainer. Check console logs for more details.');
			} else {
				setWillNeedRetainer(resp.data.is_retainer_required);
			}
		});
	};

	const addCard = (
		<Button color={quoteType ?? 'green'} onClick={addACard}>
			Add Payment
		</Button>
	);

	let content = '.....Loading';

	if (!paymentMethods) {
	} else if (cardBeingUsed) {
		const hasPendingAccount = cardBeingUsed.status == 'pending';
		const isAccount = isActuallyAccount;

		const paymentTypeDisplay = isActuallyAccount ? 'Bank account' : 'Card';

		const moredisplay = isActuallyAccount ? 'account' : 'card';

		content = (
			<div>
				{`${paymentTypeDisplay} with the last four digits ${cardBeingUsed.last_four_digits} will be used to pay for this order whenever the order is shipped. `}
				{willNeedRetainer ? (
					<div>
						<br />
						<div>
							{`This ${moredisplay} will be charged 50% of the invoice (${half}) because this order requires a retainer.`}
						</div>
						<br />
					</div>
				) : null}

				{hasPendingAccount ? (
					<div>
						<br />
						<div>
							This account is currently pending. Shades will not start production or
							be shipped until the verification process is complete.
						</div>
						<br />
					</div>
				) : null}
				{isAccount ? (
					<>If that is ok please press submit.</>
				) : (
					<>
						{`There will be a 3% credit card charge added onto the invoice. Paying via ACH will avoid this charge. Click submit to continue with card payment. `}
					</>
				)}
			</div>
		);
	} else if (gettingPayments) {
		content = (
			<div>
				<div>Waiting for a new payment method to be entered</div>
				<div>
					<Button color="gray" onClick={() => setGettingPayments(false)}>
						Cancel
					</Button>
				</div>
				{zohoLink ? (
					<div>
						<br />
						<a target="_blank" rel="noopener noreferrer" href={zohoLink}>
							If another window/tab doesn't open click here to add a card.
							{' '}
						</a>
					</div>
				) : null}
			</div>
		);
	} else if (!gettingPayments) {
		const cardRows = !paymentMethods
			? []
			: cards.map((c) => (
				<tr>
					<td>{c.last_four_digits}</td>
					<td>{c.card_type}</td>
					<td>{c.created_by_name}</td>
					<td>
						<Button
							color={quoteType ?? 'green'}
							onClick={() => {
								cardChosen(c, false);
							}}
						>
								Use This Card
						</Button>
					</td>
				</tr>
			  ));

		const accountRows = !paymentMethods
			? []
			: accounts.map((a) => (
				<tr>
					<td>{a.last_four_digits}</td>
					<td>{a.bank_name}</td>
					<td>{a.status}</td>
					<td>
						<Button
							color={quoteType ?? 'green'}
							onClick={() => {
								cardChosen(a, true);
							}}
						>
								Use This Account
						</Button>
					</td>
				</tr>
			  ));

		content = (
			<div>
				{cardRows.length == 0 ? null : (
					<table>
						<tbody>
							<tr>
								<th>Card Number</th>
								<th>Card Type</th>
								<th>Card Name</th>
								<th>Card Select</th>
							</tr>
							{cardRows}
						</tbody>
					</table>
				)}
				{accountRows.length == 0 ? null : (
					<table>
						<tbody>
							<tr>
								<th>Account Number</th>
								<th>Bank Name</th>
								<th>Account Status</th>
								<th>Account Select</th>
							</tr>
							{accountRows}
						</tbody>
					</table>
				)}
				{addCard}
			</div>
		);
	}

	return <div style={{ display: 'flex', flexDirection: 'row' }}>{content}</div>;
};

export default NewCardField;
