import { Container, Row, Col } from 'react-bootstrap';
import { useState } from 'react';

import TextInput from '../Parts/TextInput';
import StateSelector from '../Parts/StateSelector';
import Styles from '../Styles';
import BoolInput from '../Parts/BoolInput';
import Button from '../Parts/Button';

const InlineGroup = ({ children }) => (
	<div
		style={{
			display: 'flex',
			justifyContent: 'space-between',
			marginBottom: '0.5em',
		}}
	>
		{children}
	</div>
);

const Hr = () => (
	<hr
		style={{
			background: 'gray',
			border: 'none',
			height: '1px',
			margin: '1.4em 1em 1em 1em',
		}}
	/>
);

const AddressEditor = ({ ROOT, quote, onCancel, onSet, label, initialAddress, quoteType }) => {
	const [address, setAddress] = useState(initialAddress || {
		company_name: null,
		first_name: null,
		last_name: null,
		address: null,
		address2: null,
		state: null,
		city: null,
		zip: null,
		phone: null,
		email: null,
		is_residential: null,
	});

	const [optionStyle, setOptionStyle] = useState({ border: "1px solid #FFEED7", borderRadius: "4px", transition: "all .2s" });
	const [emphasizing, setEmphasizing] = useState(false);

	const getTextStyle = (value) => (((value === null || value === undefined || value === "") && emphasizing)
		? { ...optionStyle, width: "100%" }
		: { width: "100%", transition: "all .2s" });

	const getBoolStyle = (value) => (((value === null || value === undefined) && emphasizing)
		? optionStyle
		: { border: "1px solid transparent", borderRadius: "4px", transition: "all .2s" });

	const emphasizeOptions = () => {
		const initialOption = { border: "1px solid #FFEED7", borderRadius: "4px", transition: "all .2s" };
		const optionEmphasis = { border: "solid 1px #ffae47", borderRadius: "4px", backgroundColor: "#FFEED7", transition: "all .2s" };

		setEmphasizing(true);
		setOptionStyle(optionEmphasis);

		setTimeout(() => {
			setOptionStyle(initialOption);
		}, 400);
		setTimeout(() => {
			setOptionStyle(optionEmphasis);
		}, 800);
		setTimeout(() => {
			setOptionStyle(initialOption);
		}, 1200);
		setTimeout(() => {
			setOptionStyle(optionEmphasis);
		}, 1600);
		setTimeout(() => {
			setOptionStyle(initialOption);
		}, 2000);
		setTimeout(() => {
			setOptionStyle(optionEmphasis);
		}, 2400);
		setTimeout(() => {
			setOptionStyle(initialOption);
			setEmphasizing(false);
		}, 2800);
	};

	function addressChanged(options) {
		const new_address = { ...address, ...options };

		setAddress(new_address);
	}

	function submitAddress() {
		const REQUIRED = [
			{ label: 'Company Name', varName: 'company_name' },
			{ label: 'First Name', varName: 'first_name' },
			{ label: 'Last Name', varName: 'last_name' },
			{ label: 'Address', varName: 'address' },
			{ label: 'State', varName: 'country_subdivision_id' },
			{ label: 'City', varName: 'city' },
			{ label: 'Zip / Postal Code', varName: 'zip' },
			{ label: 'Is Residential Address', varName: 'is_residential' },
			{ label: 'Address Has Lift Gate', varName: 'lift_gate' },
		];

		const missing_fields = REQUIRED.filter((req) =>
			address[req.varName] === null
				|| address[req.varName] === ''
				|| address[req.varName] === undefined);

		if (missing_fields.length > 0) {
			emphasizeOptions();
		} else {
			onSet && onSet(address);
		}
	}

	return (
		<div id="address-modal-wrapper">
			<div
				className="quote-address address-modal address-modal-two"
				style={{
					width: '30em',
					maxWidth: '95vw',
					maxHeight: '100vh',
				}}
			>
				<Container className="local-bootstrap">
					<Row>
						<Col>
							<h4 className={`text-${quoteType}`}>{label || 'New Address'}</h4>
						</Col>
					</Row>
					<Row>
						<Col>
							<TextInput
								style={getTextStyle(address.company_name)}
								label="Company Name"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ width: '100%', marginBottom: '0.5em' }}
								onChange={(company_name) => addressChanged({ company_name })}
								defaultValue={address.company_name}
								required
							/>
						</Col>
					</Row>

					<Row>
						<Col>
							<TextInput
								label="First Name"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ marginRight: '3%', flexGrow: '1' }}
								style={getTextStyle(address.first_name)}
								onChange={(first_name) => addressChanged({ first_name })}
								defaultValue={address.first_name}
								required
							/>
						</Col>
						<Col>
							<TextInput
								label="Last Name"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ flexGrow: '1' }}
								style={getTextStyle(address.last_name)}
								onChange={(last_name) => addressChanged({ last_name })}
								defaultValue={address.last_name}
								required
							/>
						</Col>
					</Row>

					<Hr />
					<Row>
						<Col>
							<label>Is Residential Address</label>
							<br />
							<BoolInput
								style={getBoolStyle(address.is_residential)}
								onChange={(new_val) => addressChanged({ is_residential: new_val })}
								label="Is Residential Address"
								value={address.is_residential}
								nullable
							/>
						</Col>
					</Row>

					<Row>
						<Col>
							<label>Lift Gate Required</label>
							<br />
							<BoolInput
								style={getBoolStyle(address.lift_gate)}
								onChange={(new_val) => addressChanged({ lift_gate: new_val })}
								label="Lift Gate Required"
								value={address.lift_gate}
								nullable
							/>
						</Col>
					</Row>

					<Hr />
					<Row>
						<Col>
							<TextInput
								label="Address Line 1"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ width: '100%', marginBottom: '0.5em' }}
								style={getTextStyle(address.address)}
								onChange={(address) => addressChanged({ address })}
								defaultValue={address.address}
								required
							/>
						</Col>
					</Row>

					<Row>
						<Col>
							<TextInput
								label="Address Line 2"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ width: '100%', marginBottom: '0.5em' }}
								style={{ width: '100%' }}
								onChange={(address2) => addressChanged({ address2 })}
								defaultValue={address.address2}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<StateSelector
								key={address.country_subdivision_id}
								label="State / Province"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ flexGrow: '1', marginBottom: '0.5em' }}
								style={getTextStyle(address.country_subdivision_id)}
								onChange={(country_subdivision_id) =>
									addressChanged({ country_subdivision_id })}
								defaultValue={address.country_subdivision_id}
								required
							/>
						</Col>
					</Row>

					<Row>
						<Col>
							<TextInput
								label="City"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ flexGrow: '1', marginRight: '3%' }}
								style={getTextStyle(address.city)}
								onChange={(city) => addressChanged({ city })}
								defaultValue={address.city}
								required
							/>
						</Col>
						<Col>
							<TextInput
								label="Zip / Postal Code"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ flexGrow: '1' }}
								style={getTextStyle(address.zip)}
								onChange={(zip) => addressChanged({ zip })}
								defaultValue={address.zip}
								required
							/>
						</Col>
					</Row>

					<Hr />

					<Row>
						<Col>
							<TextInput
								label="Email"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ flexGrow: '1' }}
								style={{ width: '95%' }}
								onChange={(email) => addressChanged({ email })}
								defaultValue={address.email}
							/>
						</Col>
						<Col>
							<TextInput
								label="Phone"
								labelStyle={{ color: Styles.psGray }}
								wrapperStyle={{ flexGrow: '1' }}
								style={{ width: '100%' }}
								onChange={(phone) => addressChanged({ phone })}
								defaultValue={address.phone}
							/>
						</Col>
					</Row>

					<Row className="mt-2">
						<Col>
							<Button
								color="gray"
								onClick={onCancel}
							>
								Cancel
							</Button>
						</Col>
						<Col className="text-end">
							<Button
								color={quoteType ?? 'green'}
								onClick={submitAddress}
							>
								Set Address
							</Button>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default AddressEditor;
