/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import { configureStore } from '@reduxjs/toolkit';

import * as Sentry from "@sentry/react";

import signalR from './signalR/index';
// const isDevelopment = process.env.NODE_ENV?.includes('dev');
import { persistedReducer } from './configureReducer';




const sentryReduxEnhancer = Sentry.createReduxEnhancer({
	actionTransformer: (action) => {
		if (action.type === "user/attemptLogin") return null;
		if (action.type === "user/attemptRefresh") return null;
		if (action.type === "user/loadData/thunk") {
			const workingLoadUserDataAction = {
				...action,
				payload: {
					...action.payload,
					jwt: null,
					refreshJwt: null
				}
			};
			return workingLoadUserDataAction;
		}
		return action;
	},
	stateTransformer: (state) => {
		const transformedState = {
			...state,
			user: {
				jwt: null,
				refreshJwt: null
			}
		}
		return transformedState;
	}
});

// Configuration step.
const configurePowerShadesStore = () => {
	const store = configureStore({
		reducer: persistedReducer,
		middleware: (getDefaultMiddleware) =>
			[
				signalR,
				...getDefaultMiddleware({
					serializableCheck: true,
				}),
				// isDevelopment ? require('redux-immutable-state-invariant').default() : null
			]
		,
		enhancers: [sentryReduxEnhancer]
	});

	return store;
};

const store = configurePowerShadesStore();



export {  store };
