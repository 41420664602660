import React, { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

import apiCalls from '../PowerShadesAPIFunctions';

const ContractorAssign = ({
	contractorId, setContractorId, editable
}) => {
	const [contractors, setContractors] = useState([]);
	const [loadingContractors, setLoadingContractors] = useState(true);

	useEffect(() => {
		apiCalls.listOrganizations("contractor").then((resp) => {
			setContractors(resp.data.contractors);
			setLoadingContractors(false);
		});
	}, []);

	const contractorOptions = contractors?.map((c) => ({
		label: c.name,
		value: c.id
	})) ?? [];

	const currentContractor = contractors?.find((c) => c.id == contractorId) ?? {};

	const changedContractor = (newContractor) => {
		setContractorId(newContractor.value);
	};

	return (
		<div className="quote-field">
			<label>Contractor</label>
			<br />
			<Typeahead
				id="contractor"
				key={contractorOptions.length}
				options={[{ label: "- None -", value: -1 }, ...contractorOptions]}
				value={
					currentContractor ? { label: currentContractor.name, value: currentContractor.id } : null
				}
				onChange={(newDealer) => changedContractor(newDealer)}
				disabled={!editable}
				placeholder={loadingContractors ? "Loading..." : "Assign a Contractor"}
			/>
		</div>
	);
};

export default ContractorAssign;
