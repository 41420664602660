import React from 'react';

import Cassette_3_FW_Single from '../assets/img/Cassette_3_FW_Single.png';
import Cassette_3_FFW_Single from '../assets/img/Cassette_3_FFW_Single.png';
import Cassette_3_Square_Single from '../assets/img/Cassette_3_Square_Single.png';
import Fascia_3_Single from '../assets/img/Fascia_3_Single.png';
import Fascia_5_Dual from '../assets/img/Fascia_5_Dual.png';
import Fascia_5_Single from '../assets/img/Fascia_5_Single.png';
import Open_Roll_Large_Dual from '../assets/img/Open_Roll_Large_Dual.png';
import Open_Roll_Large_Single from '../assets/img/Open_Roll_Large_Single.png';
import Open_Roll_Extra_Large_Single from '../assets/img/Open_Roll_Extra_Large_Single.png';
import Open_Roll_Extra_Large_Dual from '../assets/img/Open_Roll_Extra_Large_Dual.png';
import Open_Roll_Medium_Dual from '../assets/img/Open_Roll_Medium_Dual.png';
import Open_Roll_Medium_Single from '../assets/img/Open_Roll_Medium_Single.png';
import Open_Roll_Medium_Large_Single from '../assets/img/Open_Roll_Medium_Large_Single.png';
import Open_Roll_Small_Single from '../assets/img/Open_Roll_Small_Single.png';
import Fascia_7_Dual from '../assets/img/Fascia_7_Dual.png';
import Balanced_Lightgap_Example from '../assets/img/Balanced_Lightgap_Example.jpg';

export default function ImageBubble({
	fileName, quoteType, generic
}) {
	let images;
	let imgItem;

	if (quoteType) {
		quoteType = quoteType.toLowerCase();
	}
	if (!generic) {
		images = {
			'Cassette_3_FW_Single.png': Cassette_3_FW_Single,
			'Cassette_3_FFW_Single.png': Cassette_3_FFW_Single,
			'Cassette_3_Square_Single.png': Cassette_3_Square_Single,
			'Fascia_3_Single.png': Fascia_3_Single,
			'Fascia_5_Dual.png': Fascia_5_Dual,
			'Fascia_5_Single.png': Fascia_5_Single,
			'Open_Roll_Extra_Large_Dual.png': Open_Roll_Extra_Large_Dual,
			Open_Roll_Extra_Large_Single,
			'Open_Roll_Large_Dual.png': Open_Roll_Large_Dual,
			'Open_Roll_Large_Single.png': Open_Roll_Large_Single,
			'Open_Roll_Medium_Dual.png': Open_Roll_Medium_Dual,
			'Open_Roll_Medium_Single.png': Open_Roll_Medium_Single,
			'Open_Roll_Small_Single.png': Open_Roll_Small_Single,
			'Fascia_7_Dual.png': Fascia_7_Dual,
			'Balanced_Lightgap_Example.jpg': Balanced_Lightgap_Example,
			'Open_Roll_Medium_Large_Single.png': Open_Roll_Medium_Large_Single,
            
		};

		imgItem = images[fileName];
	}

	return (
		<>
			{!generic ? <img className="header-image" src={imgItem} /> : <span>Please select a header</span>}
		</>
	);
}
