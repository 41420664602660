import { Col, Row, Card } from 'react-bootstrap';

const AddressSummary = ({ address, isPropertyAddress }) => {
	const cityString = (address?.city || '')
		+ ((address?.state || '').length ? `, ${address?.state}` : '')
		+ ((address?.zip || '').length ? `, ${address?.zip}` : '');

	// ! I'm not sure if this value is coming from the back end as a 1 / 0 or a true or false...
	// So we're keeping this a double eq. 
	const hasLiftGate = address?.lift_gate == 1; // eslint-disable-line eqeqeq
	const isResidential = address?.is_residential == 1; // eslint-disable-line eqeqeq

	return (
		<Card.Body className="m-2 local-bootstrap h-100 ">
			<Row>
				<Col>
					<b>
						{isPropertyAddress ? (
							<>
								Property Name
								<br />
								(How it will appear
								<br />
								in the Dashboard)
							</>
						) : (
							'Property / Company:'
						)}
					</b>
					<br />
					{address?.company_name}
				</Col>

				{isPropertyAddress ? null : (
					<Col>
						<Row>
							<Col>
								<b>Name: </b>
							</Col>
						</Row>
						<Row>
							<Col>
								{`${address?.first_name || ''} ${address?.last_name || ''}`}
							</Col>
						</Row>
					</Col>
				)}
			</Row>

			<Row>
				<Col>
					<b>Address: </b>
					<br />
					{address?.address == null && address?.address2 == null
						? null
						: (address?.address || '')
						+ (address?.address2 ? `, ${address?.address2}` : '')}
				</Col>

				<Col>
					<b>Country: </b>
					<br />
					{address?.country}
				</Col>
			</Row>

			<Row>
				<Col>
					<b>City: </b>
					<br />
					{cityString}
				</Col>

				<Col>
					<b>Zip: </b>
					<br />
					{address?.zip}
				</Col>
			</Row>
			{!isPropertyAddress ? (
				<Row>
					<Col>
						<b>Is Residential: </b>
						<br />
						{isResidential ? 'Yes' : 'No'}
					</Col>
					<Col>
						<b>Liftgate Needed: </b>
						<br />
						{hasLiftGate ? 'Yes' : 'No'}
					</Col>
				</Row>
			) : null}
		</Card.Body>
	);
};

export default AddressSummary;
