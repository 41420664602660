import { GetAssemblyOptions } from ".";
import store, { AppState } from "../..";
import {  AssemblyOptionsViewModel } from "../../../powershadesApiTypes";
import { AssemblyStore } from "../assemblies/types";
import { loadStatus } from "../entitiesType";


const useAssemblyOptions = (quoteId: number) =>   (state: AppState) => {
    const assyOptions = state.entity.assemblyOptions[quoteId];

    const defaultOptions: AssemblyOptionsViewModel = {
        headers: [],
        side_channels: [],
        hembars: [],
        mount_types: [],
        control_sides: [],
        shade_types: [],
        drive_types: [],
        direction_facing: [],
        light_gaps: [],
        end_caps: [],
        lv_power_supply: [],
        fabric_alignments: [],
        full_palette_list: [],
        color_details: []
    }

    if(!assyOptions || assyOptions.loadStatus === loadStatus.needsLoaded)
    {
        store.dispatch(GetAssemblyOptions(quoteId));

        return defaultOptions;
    }

    if(assyOptions.loadStatus === loadStatus.loading)
    {
        return defaultOptions;
    }


    // TODO: Add dispatch and loading status
    


    return assyOptions?.Items ?? defaultOptions;
}


export const useGetFullPaletteList = (quoteId: number) => {
    const state = store.getState();

    const assemblyOptions = useAssemblyOptions(quoteId)(state);

    const {
        hembars = [],
        full_palette_list = [],
        headers = [],
    } = assemblyOptions;


    const fullColorSelections = (assemblyInner: AssemblyStore) => {
        const selectedHeader = headers?.find((h) => h.name === assemblyInner.header_line);
        const headerColors = selectedHeader?.color_palettes ?? [];
        const isMotorized = assemblyInner.shades[0]?.shade_type === "motorized";
        const channels = selectedHeader?.channels ?? [];
        const endCaps = selectedHeader?.end_caps ?? [];

		const selectedEndCapAddedPalettes = endCaps?.find((ec) => ec.value === assemblyInner.end_caps)?.extra_palettes ?? [];
		const selectedDriveTypeAddedPalettes = selectedHeader?.drive_types?.find((dt) => dt.value === (isMotorized ? assemblyInner.shades[0]?.motor_type : assemblyInner.shades[0]?.manual_chain))?.extra_palettes ?? [];
		const selectedChannelTypeAddedPalettes = channels?.find((sc) => sc.value === assemblyInner.side_channels)?.extra_palettes ?? [];
		const selectedHembarTypeAddedPalettes = hembars?.find((ht) => ht.value === assemblyInner.shades[0]?.hembar_line)?.extra_palettes ?? [];


		const extraColors = full_palette_list?.filter((fpl) => {
			// If the color is already in the header colors, don't add it to this list. They will be added later.
			if (headerColors?.some((hc) => hc.choices.includes(fpl.name))) return false;

			// If the color is in the endcap options, drive type options, channel type options, or hembar type options then add them to the list
			if (selectedEndCapAddedPalettes.some((ec) => ec === fpl.name)) return true;
			if (selectedDriveTypeAddedPalettes.some((dt) => dt === fpl.name)) return true;
			if (selectedChannelTypeAddedPalettes.some((sc) => sc === fpl.name)) return true;
			if (selectedHembarTypeAddedPalettes.some((ht) => ht === fpl.name)) return true;

			// Otherwise don't add it to the list.
			return false;
		}) ?? [];

		return [...extraColors, ...headerColors];
	};
    return fullColorSelections;

}

export const useGetColorOptions = (quoteId: number) => {
    const state = store.getState();

    const assemblyOptions = useAssemblyOptions(quoteId)(state);

    const {
        headers = [],
    } = assemblyOptions;

    const colorOptions = (assemblyInner: AssemblyStore) => {
        const selectedHeader = headers?.find((h) => h.name === assemblyInner.header_line);
        const headerColors = selectedHeader?.default_color_palettes ?? [];

        return headerColors;
    };

    return colorOptions;
}



export {useAssemblyOptions};