import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMegaphone, faSparkles } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import type { MarketingIconProps } from './types';

const MarketingIcon = ({
	size = '1x',
	bottomColorOverride = '',
	topColorOverride = '',
	className = '',
}: MarketingIconProps) => (
	<span className={`fa-stack ${className}`}>
		<FontAwesomeIcon
			icon={faMegaphone as IconProp}
			size={size}
			className={`fa-stack-2x ${bottomColorOverride && `text-${bottomColorOverride}`}`}
		/>
		<FontAwesomeIcon
			icon={faSparkles as IconProp}
			size={size}
			className={`fa-stack-2x stacked-logo text-${topColorOverride} pe-2`}
		/>
	</span>
);

export default MarketingIcon;
