import { createSelector } from "reselect";
import { loadAccessoriesFull, loadQuoteAccessories, updateQuoteAccessory } from ".";
import store, { type AppDispatch, type AppState, UsePortalSelector } from "../..";
import type { QuoteAccessoryStore } from "../../../powershadesApiTypes";
import { loadStatus } from "../entitiesType";
import type { UpdateAccessoryThunkInput } from "./types";

export const selectAccessories = (quoteId: number) => (state: AppState) => {
	const { dispatch } = store;

	const typedDispatch = dispatch as AppDispatch;

	const loadStatusCurrent = state.entity.accessories[quoteId]?.loadStatus ?? loadStatus.notLoaded;

	if (loadStatusCurrent === loadStatus.notLoaded
		|| loadStatusCurrent === loadStatus.needsLoaded) {
		typedDispatch(loadAccessoriesFull({
			quoteId
		}));
	}
	return state.entity.accessories[quoteId]?.list ?? [];
};

export const selectQuoteAccessoriesUnsafe = (state: AppState) => state.entity.quoteAccessories;

export const selectQuoteAccessories = (quoteId: number) => createSelector(
	selectQuoteAccessoriesUnsafe, (quoteAccessories) => {
		const qaItem = quoteAccessories[quoteId];

		if (!qaItem
			|| qaItem?.loadStatus === loadStatus.notLoaded
			|| qaItem?.loadStatus === loadStatus.needsLoaded) {
			store.dispatch(loadQuoteAccessories(quoteId));
		}

		let qaList: QuoteAccessoryStore[] = [];

		const qaItems = quoteAccessories[quoteId];

		if (qaItems) {
			qaList = Object.values(qaItems.Items);
		}

		return qaList;
	}
);

export const selectQuoteAccessory = (quoteId: number, lineNumber: number) => createSelector(
	selectQuoteAccessories(quoteId), (qaList) => qaList.find((qa) => qa.line_number === lineNumber)
);

/**
 * Hook for updating a quote accessory
 * @returns A function that takes a UpdateAccessoryThunkInput and dispatches the updateQuoteAccessory action
 */
export const useUpdateQuoteAccessory = () => (updateQuoteAccessoryInput: UpdateAccessoryThunkInput ) => {
	const { dispatch } = store;

	const typedDispatch = dispatch as AppDispatch;

	typedDispatch(
		updateQuoteAccessory({... updateQuoteAccessoryInput })
	);
};

export const useAccessories = (quoteId: number) => UsePortalSelector(selectAccessories(quoteId));

export const useQuoteAccessories = (quoteId: number) => UsePortalSelector(selectQuoteAccessories(quoteId));
export const useQuoteAccessory = (quoteId: number, lineNumber: number) => UsePortalSelector(selectQuoteAccessory(quoteId, lineNumber));