import React, { FC, useMemo, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

import { useTerritories, useDistributorLocations } from '../Store/entities/hooks';
import TabPaneWithSearchFilter from '../Parts/TabPaneWithSearchFilter';

import type { TabPaneSelections } from '../Parts/types';
import type { fakeRootType } from "../FakeRootType";

type DistributorLocatinoListProps = {
	ROOT: fakeRootType;
};

const DistributorLocationList: FC<DistributorLocatinoListProps> = (props) => {
	const [activeSearch, setActiveSearch] = useState<string>('');
	const [activeKey, setActiveKey] = useState(0);

	const { ROOT } = props;
	const { navigate } = ROOT;

	const territories = useTerritories();

	const distributorLocations = useDistributorLocations();

	const searchMe = (search: string) => {
		if (activeSearch.length !== 0) {
			return (
				search?.toLocaleLowerCase().includes(activeSearch?.toLocaleLowerCase() ?? '')
				?? false
			);
		}
		return true;
	};

	const workingDistributors = useMemo(
		() =>
			distributorLocations
				.filter((d) => d)
				.filter((d) => searchMe(d.name))
				.sort((d1, d2) =>
					d1.name.toLocaleLowerCase().localeCompare(d2.name.toLocaleLowerCase())
				),
		[distributorLocations, activeSearch]
	);

	const getTabSelectionsList = (): TabPaneSelections[] => {
		const workingTabPanes: TabPaneSelections[] = [];
		// Add an "All" tab with ID: 0
		workingTabPanes[0] = {
			id: 0,
			title: "All Distributor Locations",
			key: "distributorList",
			items: []
		};

		// Check if all tab exists, if so, push the dealer to it
		if (workingTabPanes[0] !== undefined) {
			workingTabPanes[0].items = workingDistributors.map((distributorLocation) => ({
				id: `${distributorLocation.id}`,
				type: "distributorLocation",
				name: distributorLocation.name,
				group: distributorLocation.territory_ids,
				title: distributorLocation.name,
				onClick: () => {
					navigate(`distributorLocation?distributor_location_id=${distributorLocation.id}`);
				}
			}));
		}

		territories.forEach((wt) => {
			workingTabPanes.push({
				id: wt.id,
				type: "territory",
				title: wt.name,
				key: wt.name,
				items: workingDistributors
					.filter((wr) => wr.territory_ids.includes(wt.id))
					.map((wr) => ({
						id: `${wr.id}`,
						type: "distributor",
						name: wr.name,
						title: wr.name,
						onClick: () => {
							navigate(`distributorLocation?distributor_location_id=${wr.id}`);
						},
						
					}))
			});
		});

		return workingTabPanes;
	};

	const tableSelectionsCurrent = getTabSelectionsList();

	const loading = tableSelectionsCurrent.length < 1;

	return (
		<Container className="local-bootstrap">
			<Card body className="entity-card">
				<Row>
					<Col>
						<TabPaneWithSearchFilter
							key="distributor-list"
							selections={tableSelectionsCurrent}
							loading={loading}
							disabled={false}
							setActiveKey={setActiveKey}
							activeKey={activeKey}
							searchFilter={activeSearch}
							setSearchFilter={setActiveSearch}
						/>
					</Col>
				</Row>
			</Card>
		</Container>
	);
};

export default DistributorLocationList;
