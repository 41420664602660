
import { createSelector } from "@reduxjs/toolkit";
import store, { AppDispatch, AppState, UsePortalSelector } from "../..";
import { loadStatus as loadStatusEnum} from "../entitiesType";
import { RefreshQuotePricing } from "../quotes";
import { getFullListOfParts, loadHwItemsByQuoteId } from ".";
import { HardwareRequestItemFromOptions, QuoteHardwareRequestItemStore } from "../../../powershadesApiTypes";



const selectHWRItemsByQuoteId = (QuoteId: number) => (state: AppState) => {
    const hwItems = state.entity.hardwareRequestItems[QuoteId] ?? {
        loadStatus: loadStatusEnum.needsLoaded,
        Items: {}
    };
    const {loadStatus, ...hwItemsTwo} = hwItems ?? {};

    if( loadStatus !== loadStatusEnum.loading && loadStatus !== loadStatusEnum.fullyLoaded) {
        // I don't know why the type system is not catching this as a AppDispatch
        const dispatch = store.dispatch as AppDispatch;
        dispatch(loadHwItemsByQuoteId(QuoteId)).then(() => {
            dispatch(RefreshQuotePricing({quoteId: QuoteId}));
        });
        return {} as Record<number,QuoteHardwareRequestItemStore>;
    }

    return hwItemsTwo.Items as Record<number,QuoteHardwareRequestItemStore>;
}

const selectHWRItemsListByQuoteId = (quoteId: number) =>createSelector(selectHWRItemsByQuoteId(quoteId), (hwItems) => {
    return Object.values(hwItems).sort((a, b) => (a.name ?? "").localeCompare(b.name ?? ""));
});

const useHWRItemsByQuoteId = (QuoteId: number) => UsePortalSelector(selectHWRItemsListByQuoteId(QuoteId));

const selectFullHWRItemsByQuoteId = (QuoteId: number) => (state: AppState) => {
    const hwItems = state.entity.hardwareRequestItems[QuoteId] ?? {
        loadStatus: loadStatusEnum.needsLoaded,
        fullLoadStatus: loadStatusEnum.needsLoaded,
        Items: {},
        TotalItems: {}
    };
    const {fullLoadStatus, ...hwItemsTwo} = hwItems ?? {};

    const fullKeyCount = Object.keys(hwItemsTwo.TotalItems ?? {}).length;

    if( (fullLoadStatus !== loadStatusEnum.loading && fullLoadStatus !== loadStatusEnum.fullyLoaded ) || (fullKeyCount === 0 && fullLoadStatus !== loadStatusEnum.loading)) {
        // I don't know why the type system is not catching this as a AppDispatch
        const dispatch = store.dispatch as AppDispatch;
        dispatch(getFullListOfParts({
            quoteId: QuoteId
        }))
        return {} as Record<number,HardwareRequestItemFromOptions>;
    }

    return hwItemsTwo.TotalItems as Record<number,HardwareRequestItemFromOptions>;
}

const selectFullHWRItemsListByQuoteId = (quoteId: number) =>createSelector(selectFullHWRItemsByQuoteId(quoteId), (hwItems) => {
    return Object.values(hwItems).sort((a, b) => (a.name ?? "").localeCompare(b.name ?? ""));
}
);

const useFullHWRItemsByQuoteId = (QuoteId: number) => UsePortalSelector(selectFullHWRItemsListByQuoteId(QuoteId));


export {
    useHWRItemsByQuoteId,
    selectHWRItemsByQuoteId,
    selectHWRItemsListByQuoteId,
    selectFullHWRItemsByQuoteId,
    useFullHWRItemsByQuoteId
};