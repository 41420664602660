import React, { useEffect, useMemo, useState } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

import { initialDistributorState, updateDistributorFull } from "../Store/entities/organizations/distributors";
import BillingInformationHandler from "../ManageEntities/BillingInformationHandler";
import EntityPaymentOptions from "../ManageEntities/EntityPaymentOptions";
import EntityTaxInfo from "../ManageEntities/EntityTaxInfo";
import TabPaneWithSearchFilter from "../Parts/TabPaneWithSearchFilter";
import DistributorDistributorLocationsView from "./DistributorDistributorLocationsView";
import Button from "../Parts/Button";
import EntityPageHeader from "../Parts/EntityPageHeader";

import { UsePortalDispatch } from "../Store";
import { useQuoteMetaDispatch, useQuotes } from "../Store/entities/quotes/hooks";
import { useDistributorById, useDistributorLocationsByDistributorId, useTerritories, useUsersList } from "../Store/entities/hooks";

import { QuotePrefixById } from "../powershadesApiTypeExtensions";
import type { ChannelId, QuoteTypeIds } from "../powershadesApiTypes";
import type { DistributorViewProps } from "./types";
import type { TabPaneSelections } from "../Parts/types";
import { getTerritoryLink, getUserLink } from "../psUtil";
import AddTerritory from "../Modals/AddTerritory";
import { useModal } from "../Store/ui/hooks";
import { usePrompt } from "../Store/hooks";
import AddUserModal from "../User/AddUserModal";
import UserLookup from "../Modals/UserLookup";
import { DistributionEmailType } from "../Dealer/types";
import AddEmailDistribution from "../Modals/AddEmailDistribution";

const DistributorView = ({ ROOT }: DistributorViewProps) => {
	const {
		GET, navigate, setTitle
	} = ROOT;

	const [editable, setEditing] = useState(false);
	const [activeSearch, setActiveSearch] = useState<string>('');
	const [activeKey, setActiveKey] = useState(0);
	const [showAddTerritoryModal, setShowAddTerritoryModal] = useState(false);
	const [showAddUserModal, setShowAddUserModal] = useState(false);
	const [showAddEmailDistributionModal, setShowAddEmailDistributionModal] = useState(false);
	const [showLookupUserModal, setShowLookupUserModal] = useState(false);

	const distributorId = parseInt(GET.distributor_id ?? "0", 10) ?? 0;

	const dispatch = UsePortalDispatch();
	const dispatchQuotesMetaData = useQuoteMetaDispatch();
	const quotes = useQuotes();
	const users = useUsersList();
	const modal = useModal();
	const distributorLocations = useDistributorLocationsByDistributorId(distributorId);
	const territories = useTerritories();

	const selectedDistributor = useDistributorById(distributorId);
	const [workingDistributor, setWorkingDistributor] = useState(
		selectedDistributor ?? initialDistributorState()
	);

	const changesHaveBeenMade = useMemo(
		() => JSON.stringify(workingDistributor) !== JSON.stringify(selectedDistributor),
		[selectedDistributor, workingDistributor]
	);

	usePrompt(modal, changesHaveBeenMade);

	const saveWorkingEntity = async () => {
		try {
			const updateResponse = await dispatch(updateDistributorFull(workingDistributor));
			if (updateDistributorFull.fulfilled.match(updateResponse)) {
				toast.success(`Distributor Updated `);
			}
			if (updateDistributorFull.rejected.match(updateResponse)) {
				toast.error(`Distributor Update Failed: ${updateResponse.payload} `);
				setWorkingDistributor(selectedDistributor ?? initialDistributorState());
			}
		} catch (e: any) {
			toast.error(`Distributor Update Failed! PowerShades engineering has been notified.`);
			setWorkingDistributor(selectedDistributor ?? initialDistributorState());
			throw new Error(e);
		}
	};

	/**
	 * Function that deletes a distribution email from the working Distributor
	 * @param email - The email to be deleted
	 * @param deleteVal - The type of email to delete
	 */
	const deleteDistributionEmail = (
		email: string,
		deleteVal: DistributionEmailType = 'all'
	) => {
		const distributionEmails = [...workingDistributor.distribution_emails];
		switch (deleteVal) {
			case 'all':
				return setWorkingDistributor({
					...workingDistributor,
					distribution_emails: distributionEmails.filter((de) => de.email !== email),
				});
			case 'order':
				return setWorkingDistributor({
					...workingDistributor,
					distribution_emails: distributionEmails.map((de) =>
						(de.email === email
							? { ...de, is_receiving_order_placed_emails: false }
							: de)
					),
				});
			case 'invoice':
				return setWorkingDistributor({
					...workingDistributor,
					distribution_emails: distributionEmails.map((de) =>
						(de.email === email ? { ...de, is_receiving_invoice_emails: false } : de)
					),
				});
			default:
		}
	};

	useEffect(() => {
		if (!editable && distributorId !== 0 && selectedDistributor?.id !== 0 && selectedDistributor) {
			setWorkingDistributor(selectedDistributor);
		}
	}, [selectedDistributor, editable]);

	useEffect(() => {
		if (selectedDistributor) {
			setTitle(`View Distributor | ${selectedDistributor.name}`);
		}
	}, [setTitle, selectedDistributor]);

	useEffect(() => {
		if (selectedDistributor.quote_ids.length > 0) {
			dispatchQuotesMetaData(selectedDistributor.quote_ids);
		}
	}, [selectedDistributor]);

	if (!selectedDistributor) {
		return (
			<>
				ope
			</>
		);
	}
	const isDistLocationsActive = selectedDistributor.is_distributor_locations_active
		|| workingDistributor.is_distributor_locations_active;
	// Quotes, Dealers, Territories

	const loading = selectedDistributor.loadStatus <= 3;

	const getTabSelectionsEntity = (): TabPaneSelections[] => {
		const workingTabPanes: TabPaneSelections[] = [];

		const territoryTabPane: TabPaneSelections = {
			id: 0,
			title: 'Territories',
			key: 'territory_pane',
			filters: [],
			items: [],
		};

		workingDistributor?.territory_ids?.forEach((territoryId) => {
			const territory = territories.find((wt) => wt.id === territoryId);
			if (!territory) return;
			const territoryLink = getTerritoryLink(territoryId);
			territoryTabPane.items.push({
				id: `${territory.name}`,
				type: "territory",
				name: territory.name,
				title: territory.name,
				onClick: () => {
					navigate(territoryLink);
				},
				onDelete: () => {
					modal({
						title: `Remove ${territory.name}?`,
						text: `Are you sure you want to remove ${territory.name} from ${workingDistributor.name}?`,
						confirmButtonText: 'Remove',
						onConfirm: () => {
							try {
								setWorkingDistributor({
									...workingDistributor,
									territory_ids: workingDistributor.territory_ids?.filter(
										(t) => t !== territory.id
									),
								});
								toast.success(
									`${territory.name} removed from ${workingDistributor.name}`
								);
							} catch (err) {
								toast.error('Something went wrong trying to remove the territory');
								throw err;
							}
						},
						onCancel: () => {
							toast.info('Territory removal canceled');
						},
					});
				}
			});
		});

		if (editable) {
			territoryTabPane.items.unshift({
				id: 'Add',
				type: "territory",
				name: 'Add Territory...',
				title: 'Add Territory...',
				onClick: () => {
					setShowAddTerritoryModal(true);
				},
			});
		}

		workingTabPanes.push(territoryTabPane);

		if (workingDistributor.quote_ids) {
			const quotesTabPane: TabPaneSelections = {
				id: 4,
				key: 'quote_pane',
				title: 'Quotes',
				filters: ['all', 'active', 'placed', 'RMA', 'rework', 'demo', 'archived'],
				items: [],
				editable: false,
			};

			workingDistributor.quote_ids.forEach((quoteId) => {
				const quote = quotes[quoteId];

				if (!quote) {
					return;
				}

				const quoteTypeId: QuoteTypeIds = quote?.quote_type_id ?? 5;
				const quotePrefix = QuotePrefixById[quoteTypeId ?? 5] ?? 'PS';

				quotesTabPane.items.push({
					id: `${quote.id}`,
					type: "quote",
					name: `${quotePrefix}${quote.id} - 
						${quote.title === '' || quote.title === null ? 'No Title' : quote.title}`,
					title: "Quote ",
					onClick: () => {
						navigate(`/Quote?quoteID=${quote.id}`);
					},
				});
			});
			workingTabPanes.push(quotesTabPane);
		}
		const usersTabPane: TabPaneSelections = {
			id: 1,
			title: 'Users',
			key: 'user_pane',
			filters: ['all', 'admin', 'user'],
			items: [],
		};

		workingDistributor.user_ids.forEach((userId) => {
			const user = users[userId];
			if (!user) return;

			usersTabPane.items.push({
				id: `${user.id}`,
				type: "user",
				name: `${user.name}`,
				title: `${user.name}`,
				onClick: () => {
					navigate(getUserLink(user.id));
				},
				onDelete: () => {
					modal({
						title: `Remove ${user.name}?`,
						text: `Are you sure you want to remove ${user.name} from ${workingDistributor.name}?`,
						confirmButtonText: 'Remove',
						onConfirm: () => {
							try {
								setWorkingDistributor({
									...workingDistributor,
									user_ids: workingDistributor.user_ids?.filter(
										(u) => u !== user.id
									),
								});
								toast.success(`${user.name} removed from ${workingDistributor.name}`);
							} catch (err) {
								toast.error('Something went wrong trying to remove the user');
								throw err;
							}
						},
						onCancel: () => {
							toast.info('User removal canceled');
						},
					});
				}
			});
		});

		if (editable) {
			usersTabPane.items.unshift({
				id: 'Add',
				type: "user",
				name: 'Add User...',
				title: 'Add User...',
				onClick: () => {
					modal({
						title: 'Add or Lookup User?',
						text: 'Would you like to add a new user or lookup an existing user?',
						confirmButtonText: 'Add',
						cancelButtonText: 'Lookup',
						onConfirm: () => {
							setShowAddUserModal(true);
						},
						onCancel: () => {
							setShowLookupUserModal(true);
						},
					});
				},
			});
		}

		workingTabPanes.push(usersTabPane);

		const distributionEmailsTabPane: TabPaneSelections = {
			id: 2,
			title: 'Distribution Emails',
			key: 'distribution_emails_pane',
			filters: ['all', 'order placed access', 'invoice access'],
			items: [],
		};

		workingDistributor.distribution_emails.forEach((distributionEmail) => {
			if (distributionEmail === undefined) return;

			const hasInvoiceAccess = distributionEmail.is_receiving_invoice_emails;
			const hasOrderPlacedAccess = distributionEmail.is_receiving_order_placed_emails;
			if (hasInvoiceAccess) {
				distributionEmailsTabPane.items.push({
					id: `inv-${distributionEmail.email}`,
					type: "distributor",
					name: `${distributionEmail.email} - Invoice Access`,
					title: distributionEmail.email,
					onClick: () => null,
					onDelete: () => {
						modal({
							title: `Remove ${distributionEmail.email} invoice access?`,
							text: `Are you sure you want to remove ${distributionEmail.email} invoice access from ${workingDistributor.name}?`,
							confirmButtonText: 'Remove',
							onConfirm: () => {
								try {
									deleteDistributionEmail(distributionEmail.email, 'invoice');
									toast.success(`${distributionEmail.email} invoice access removed`);
								} catch (err) {
									toast.error('Something went wrong trying to remove invoice access');
									throw err;
								}
							},
							onCancel: () => {
								toast.info('Invoice access removal canceled');
							}
						});
					},
				});
			}
			if (hasOrderPlacedAccess) {
				distributionEmailsTabPane.items.push({
					id: `ord-${distributionEmail.email}`,
					type: "distributor",
					name: `${distributionEmail.email} - Order Access`,
					title: distributionEmail.email,
					onClick: () => null,
					onDelete: () => {
						modal({
							title: `Remove ${distributionEmail.email} order access?`,
							text: `Are you sure you want to remove ${distributionEmail.email} order access from ${workingDistributor.name}?`,
							confirmButtonText: 'Remove',
							onConfirm: () => {
								try {
									deleteDistributionEmail(distributionEmail.email, 'order');
									toast.success(`${distributionEmail.email} order access removed`);
								} catch (err) {
									toast.error('Something went wrong trying to remove order access');
									throw err;
								}
							},
							onCancel: () => {
								toast.info('Order access removal canceled');
							}
						});
					},
				});
			}

			if (!hasInvoiceAccess && !hasOrderPlacedAccess) {
				distributionEmailsTabPane.items.push({
					id: `non-${distributionEmail.email}`,
					type: "distributor",
					name: `${distributionEmail.email} - No Access`,
					title: distributionEmail.email,
					onClick: () => null,
					onDelete: () => {
						modal({
							title: `Remove ${distributionEmail.email} from distribution emails?`,
							text: `Are you sure you want to remove ${distributionEmail.email} from ${workingDistributor.name}? This will remove the 
							email entirely.`,
							confirmButtonText: 'Remove',
							onConfirm: () => {
								try {
									deleteDistributionEmail(distributionEmail.email);
									toast.success(`${distributionEmail.email} removed`);
								} catch (err) {
									toast.error('Something went wrong trying to remove the email');
									throw err;
								}
							},
							onCancel: () => {
								toast.info('Email removal canceled');
							}
						});
					},
				});
			}
		});

		if (editable) {
			distributionEmailsTabPane.items.unshift({
				id: 'Add',
				type: "distributor",
				name: 'Add or Edit Email Distribution Rule...',
				title: 'Add or Edit Email Distribution Rule...',
				onClick: () => {
					setShowAddEmailDistributionModal(true);
				},
			});
		}

		workingTabPanes.push(distributionEmailsTabPane);

		if (isDistLocationsActive) {
			const distributorLocationTabPane: TabPaneSelections = {
				id: 3,
				title: 'Distributor Location',
				key: 'distributor_locations_pane',
				items: [],
			};

			distributorLocations.forEach((dl) => {
				distributorLocationTabPane.items.push({
					id: `dist_loc_${dl.name}`,
					type: "distributorLocation",
					title: dl.name,
					name: dl.name,
					onClick: () => {
						// Fill
					}

				});
			});

			workingTabPanes.push(distributorLocationTabPane);
		}

		return workingTabPanes;
	};

	const tabSelections = getTabSelectionsEntity();

	return (
		<Container className="local-bootstrap">
			<Card body className="entity-card">
				<Row>
					<Col>
						<span>
							<EntityPageHeader
								name={workingDistributor.name ?? ""}
								email={workingDistributor.email ?? ""}
								phone={workingDistributor.phone ?? ""}
								salesType={workingDistributor.sales_type ?? ""}
								loading={loading}
								editable={editable}
								setEditable={(e) => setEditing(e)}
								saveEnabled={changesHaveBeenMade}
								onChange={(fields) => {
									const {
										name,
										email,
										phone
									} = fields;

									setWorkingDistributor({
										...workingDistributor,
										name,
										email: email ?? '',
										phone: phone ?? ''
									});
								}}
								onSave={() => {
									saveWorkingEntity();
									setEditing(false);
								}}
							/>

							<BillingInformationHandler
								disabled={!editable}
								address={workingDistributor.billing_address}
								onChange={(address) => {
									setWorkingDistributor({
										...workingDistributor,
										billing_address: { ...address }
									});
								}}
								loading={loading}
							/>

							<EntityTaxInfo
								disabled={!editable}
								stateTaxCode={workingDistributor.sales_tax_exempt_certificate_number ?? ""}
								stateTaxStatus={workingDistributor.sales_tax_exempt_status ?? ""}
								stateTaxLink={workingDistributor.sales_tax_exempt_certificate_location ?? ""}
								isTaxable={workingDistributor.sales_tax_exempt_status !== 'tax_exempt'}
								federalTaxLink={workingDistributor.federal_tax_certificate_location ?? ""}
								federalTaxCode={workingDistributor.federal_tax_id ?? ""}
								onChange={(goods) => {
									const {
										stateTaxCode,
										stateTaxLink,
										isTaxable,
										federalTaxLink,
										federalTaxCode
									} = goods;

									/* idk yet */
									setWorkingDistributor({
										...workingDistributor,
										sales_tax_exempt_certificate_number: stateTaxCode,
										sales_tax_exempt_status: !isTaxable ? 'tax_exempt' : 'non_exempt',
										sales_tax_exempt_certificate_location: stateTaxLink,
										federal_tax_certificate_location: federalTaxLink,
										federal_tax_id: federalTaxCode,
									});
								}}
								entityType="dealer"
								entityId={workingDistributor.id.toString()}
								loading={loading}
							/>

							<EntityPaymentOptions
								disabled={!editable}
								channelId={workingDistributor.channel_id ?? 0}
								multiplier={workingDistributor.pricing_multiplier ?? 1}
								isUsingPricingOverride={false}
								onChange={(goods) => {
									const {
										allowCreditCardPayments, channelId, multiplier,
									} = goods;

									setWorkingDistributor({
										...workingDistributor,
										pricing_multiplier: multiplier ?? 0.425,
										is_credit_cards_allowed: allowCreditCardPayments ?? false,
										channel_id: channelId as ChannelId,
									});
								}}
								loading={loading}
								isAllowingCreditCardPayments={workingDistributor.is_credit_cards_allowed ?? false}
								allowCreditCardPayments={undefined}
								hasCreditTerms={undefined}
								termsId={undefined}
								buyingGroupIds={undefined}
								hasFabricBookOverride={undefined}
								currentPortfolio={workingDistributor.current_portfolio}
							/>

							<TabPaneWithSearchFilter
								key="distributor-view"
								disabled={!editable}
								loading={loading}
								selections={tabSelections}
								setActiveKey={setActiveKey}
								activeKey={activeKey}
								searchFilter={activeSearch}
								setSearchFilter={setActiveSearch}
								filterOptions={[]}
							/>
							{isDistLocationsActive ? (
								<>
									<hr />

									<DistributorDistributorLocationsView
										distributorId={distributorId}
									/>
								</>
							) : (
								<>
									<hr />
									<Button
										color="green"
										disabled={!editable}
										onClick={() => {
											setWorkingDistributor({
												...workingDistributor,
												is_distributor_locations_active: true
											});
										}}
									>
										Activate Distributor Locations
									</Button>
								</>
							)}
						</span>
					</Col>
				</Row>
				<AddTerritory
					key="addTerritoryDistributorView"
					open={showAddTerritoryModal}
					selectedTerritoryIds={workingDistributor.territory_ids ?? []}
					onClose={() => setShowAddTerritoryModal(false)}
					handleAdd={(territoryIds) => {
						setWorkingDistributor({
							...workingDistributor,
							territory_ids: [...workingDistributor.territory_ids ?? [], ...territoryIds]
						});
					}}
				/>
				<AddUserModal
					open={showAddUserModal}
					onClose={() => setShowAddUserModal(false)}
					callBack={(userId) => {
						setWorkingDistributor({
							...workingDistributor,
							user_ids: [...workingDistributor.user_ids ?? [], userId]
						});
					}}
					allowEntitySelection={false}
					entityType="distributor"
					inheritedEntityId={workingDistributor.id}
					inheritedEntityName={workingDistributor.name}
				/>

				<UserLookup
					open={showLookupUserModal}
					onClose={() => setShowLookupUserModal(false)}
					handleAdd={(user) => {
						setWorkingDistributor({
							...workingDistributor,
							user_ids: [...workingDistributor.user_ids ?? [], user.id]
						});
					}}
				/>

				<AddEmailDistribution
					key="addEmailDistributionDistributorView"
					open={showAddEmailDistributionModal}
					onClose={() => setShowAddEmailDistributionModal(false)}
					handleAdd={(distributionEmails) => {
						setWorkingDistributor({
							...workingDistributor,
							distribution_emails: [...distributionEmails],
						});
					}}
					existingEmailDistributions={workingDistributor.distribution_emails ?? []}
				/>
			</Card>
		</Container>

	);
};

export default DistributorView;
