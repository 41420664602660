import { createAsyncThunk } from "@reduxjs/toolkit";
import apiCalls from "../../../PowerShadesAPIFunctions";
import { AxiosError } from "axios";
import { extraEntityReducersFunction, loadStatus } from "../entitiesType";
import { Portfolio } from "../../../powershadesApiTypes";


const loadPortfolios = createAsyncThunk<Portfolio[], void, {
    rejectValue: boolean;
}
>(
    'entities/GetPortfolios',
    async (_inputs, { rejectWithValue }) => {
        let portfolios: Portfolio[] = [];

        try {
            const resp = await apiCalls.getPortfolios();

            portfolios = resp.data;
        } catch (err: any) {
            const error: AxiosError = err;

            console.error(error);

            return rejectWithValue(false);
        }

        return portfolios;
    }
);

const updateQuotePortfolioCode = createAsyncThunk<
    boolean, { quoteId: number, portfolioCode: string }, {
        rejectValue: boolean;
    }
>(
    'entities/UpdateQuotePortfolio',
    async ({ quoteId, portfolioCode }, { rejectWithValue }) => {
        try {
            const resp = await apiCalls.updateQuotePortfolio(quoteId, portfolioCode);

            return resp.data.success;
        } catch (err: any) {
            const error: AxiosError = err;

            console.error(error);

            return rejectWithValue(false);
        }
    }
);


export const portfolioBuilder: extraEntityReducersFunction = (builder) => {
    // * Start Fulfilled Promises
    builder.addCase(loadPortfolios.fulfilled, (state, action) => {
        state.portfolios.list = action.payload;
        state.portfolios.loadStatus = loadStatus.fullyLoaded;
    });

    builder.addCase(loadPortfolios.pending, (state) => {
        state.portfolios.loadStatus = loadStatus.loading;
    });



    builder.addCase(updateQuotePortfolioCode.fulfilled, (state, _) => {
        state.portfolios.loadStatus = loadStatus.needsLoaded;
    });

    builder.addCase(updateQuotePortfolioCode.pending, (state, action) => {
        const quote = Object.values(state.quotes.list).find((q) => q.id === action.meta.arg.quoteId);
        if (quote) {
            quote.portfolio_code = action.meta.arg.portfolioCode;

        }

        state.portfolios.loadStatus = loadStatus.loading;
    });

}

export {
    loadPortfolios,
    updateQuotePortfolioCode

};
