import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import type { AppState } from '..';
import type { Territory, TerritoryMeta } from '../../powershadesApiTypes';
import apiCalls from '../../PowerShadesAPIFunctions';

import type { TerritoryStore, extraEntityReducersFunction } from './entitiesType';
import { loadStatus } from './entitiesType';



export const initialTerritoryState: () => TerritoryStore = () => {
	const initTerr: TerritoryStore = {
		id: 0,
		name: '',
		description: '',
		msrp_multiplier: 0,
		iso_4217_currency_code_buy: '',
		iso_4217_currency_code_sell: '',
		shipping_multiplier: 0,
		has_chains_banned: 0,
		default_salesperson_id: 0,
		loadStatus: loadStatus.notLoaded,
		dealers: [],
		distributors: [],
		representatives: [],
		allow_credit_card_payments: false,
		within_continental_us: false
	};

	return initTerr;
};

export const loadTerritoriesMeta = createAsyncThunk<
	TerritoryMeta[],
	void,
	{
		rejectValue: boolean;
	}
>('entities/GetTerritoriesMeta', async (_inputs, { rejectWithValue }) => {
	let territories: TerritoryMeta[] = [];

	try {
		const resp = await apiCalls.getTerritoriesMeta();

		territories = resp.data.territories.map((t) => ({
			...t, name: t.name ?? ''
		}));
	} catch (err: any) {
		// let _error: AxiosError = err;

		return rejectWithValue(false);
	}

	return territories;
});

export const loadTerritories = createAsyncThunk<Territory[], void, { rejectValue: boolean }>(
	'entities/GetTerritories',
	async (_inputs, { rejectWithValue }) => {
		let territories: Territory[] = [];

		try {
			const resp = await apiCalls.getTerritories();

			territories = resp.data.territories;
		} catch (err: any) {
			// let _error: AxiosError = err;

			return rejectWithValue(false);
		}

		return territories;
	}
);

export const loadTerritoryFull = createAsyncThunk<
	Territory,
	number,
	{
		rejectValue: boolean;
	}
>('entities/GetTerritoryFull', async (territoryId, { rejectWithValue }) => {
	let territory: Territory = initialTerritoryState();

	try {
		const resp = await apiCalls.getTerritory(territoryId);

		territory = resp.data.territory;
	} catch (err: any) {
		// let _error: AxiosError = err;

		return rejectWithValue(false);
	}

	return territory;
});

export const updateTerritory = createAsyncThunk<Territory | null, Territory, {
	state: AppState;
	rejectValue: string;
}>(
	'entities/UpdateTerritory',
	async (territory, { rejectWithValue }) => {
		let updatedTerritory: Territory;

		try {
			if (typeof territory.has_chains_banned === 'boolean') {
				territory.has_chains_banned = territory.has_chains_banned ? 1 : 0;
			}
			const resp = await apiCalls.updateTerritory(territory.id, territory);
			if (!resp.data.success) {
				throw resp;
			}
			updatedTerritory = territory;
		} catch (err: any) {
			const errorItem = err as { error: AxiosError };
			const { error } = errorItem;

			console.error(error);
			if (err?.data) {
				const data = err?.data as { error: string };

				return rejectWithValue(data.error);
			}
			if (error.response?.data) {
				const data = error.response?.data as { error: string };

				return rejectWithValue(data.error);
			}
			return rejectWithValue('Something went wrong trying to update the territory and no error message was provided.');
		}

		return updatedTerritory;
	}
);

export const territoryBuilder: extraEntityReducersFunction = (builder) => {
	builder.addCase(loadTerritoriesMeta.fulfilled, (entities, action) => {
		const territories = action.payload;

		territories.forEach((territory) => {
			entities.territories.list[territory.id] = {
				...initialTerritoryState(),
				...territory,
				loadStatus: loadStatus.metaLoaded,
			};
		});

		entities.territories.loadStatus = loadStatus.metaLoaded;

		// If the territory has already been loaded we do an update. Of it hasn't been loaded we give it
		// the initial state, the meta data, and set it to meta loaded.
		// territories.forEach((territory) => {
		// 	if (
		// 		!state.territories[territory.id] ||
		// 		!(state.territories[territory.id]?.loadStatus === loadStatus.fullyLoaded)
		// 	) {
		// 		state.territories[territory.id] = {
		// 			...initialTerritoryState(),
		// 			...territory,
		// 			loadStatus: loadStatus.metaLoaded,
		// 		};
		// 	} else {
		// 		state.territories[territory.id] = {
		// 			...initialTerritoryState(),
		// 			...state.territories[territory.id],
		// 			...territory,
		// 			loadStatus: loadStatus.metaLoaded,
		// 		};
		// 	}
		// });
	});
	builder.addCase(loadTerritoriesMeta.pending, (entities, _action) => {
		entities.territories.loadStatus = loadStatus.loading;
	});

	builder.addCase(loadTerritories.fulfilled, (entities, action) => {
		const territories = action.payload;

		territories.forEach((territory) => {
			entities.territories.list[territory.id] = {
				...territory,
				loadStatus: loadStatus.fullyLoaded,
			};
		});

		entities.territories.loadStatus = loadStatus.fullyLoaded;

		// territories.forEach((territory) => {
		// 	if (
		// 		!state.territories[territory.id] ||
		// 		!(state.territories[territory.id]?.loadStatus === loadStatus.fullyLoaded)
		// 	) {
		// 		state.territories[territory.id] = {
		// 			...initialTerritoryState(),
		// 			...territory,
		// 			loadStatus: loadStatus.fullyLoaded,
		// 		};
		// 	} else {
		// 		state.territories[territory.id] = {
		// 			...initialTerritoryState(),
		// 			...state.territories[territory.id],
		// 			...territory,
		// 			loadStatus: loadStatus.fullyLoaded,
		// 		};
		// 	}
		// });
	});

	builder.addCase(loadTerritoryFull.pending, (entities, action) => {
		const territoryId = action.meta.arg;

		entities.territories.list[territoryId] = {
			...initialTerritoryState(),
			...entities.territories.list[territoryId], 
			id: territoryId,
			loadStatus: loadStatus.loading,
		};
	});

	builder.addCase(loadTerritoryFull.fulfilled, (entities, action) => {
		const territory = action.payload;

		entities.territories.list[territory.id] = {
			...territory,
			loadStatus: loadStatus.fullyLoaded,
		};
	});
	builder.addCase(updateTerritory.pending, (entities, action) => {
		const territory = action.meta.arg;

		entities.territories.list[territory.id] = {
			...territory,
			loadStatus: loadStatus.loading,
		};
	});
	builder.addCase(updateTerritory.fulfilled, (entities, action) => {
		const territory = action.payload;
		if (!territory) return;
		if (!entities.territories.list[territory.id]) return;
		entities.territories.list[territory.id] = {
			...territory,
			loadStatus: loadStatus.fullyLoaded,
		};

		return entities;
	});
	builder.addCase(updateTerritory.rejected, (entities, action) => {
		const territory = action.meta.arg;

		entities.territories.list[territory.id] = {
			...territory,
			loadStatus: loadStatus.fullyLoaded,
		};

		return entities;
	});
};

export const selectTerritoryList = (state: AppState) => state.entity.territories.list;
export const selectTerritoryDict = (state: AppState) => state.entity.territories;
