// This extensions file is to act as a collection of helper functions and the functions must be pure
// They cannot call anything outside of this file without receiving them as inputs.

import { KeyboardEvent } from "react";

import typedApi from "../powershadesApiType";
import typedRoot from "../rootType";
import { IndoorShade } from "../indoorShadeTypes";

// Expecting NewQuote Object
export const getQuoteType = (newQuote): string => (newQuote?.QuoteType?.toLowerCase() ?? 'CEDIA');

const getToast = (() => {
	const anyWindow = window as any;
	const anyROOT = anyWindow.ROOT;
	const { toast } = anyROOT;

	return toast as (content, options) => void;
});

const toast = (content: any, options: any) => getToast()(content, options);

// Expecting Quote Type from NewQuote.QuoteType
export const getQuoteColor = (quoteType): string => {
	switch (quoteType) {
		case 'rma':
			return '#BB4430';
		case 'rework':
			return '#469e95';
		case 'cedia':
		case 'demo':
		case 'internal':
		case 'window_covering':
			return '#8dc63f';
		case 'bid':
		case 'bidspec':
			return '#9611D0';
		default:
			return '#8dc63f';
	}
};

// Expecting NewQuote / ShadeAssemblies / Items
export const checkIfQuoteHasAllSingleShades = (shadesObject) => {
	const hasAllSingles = shadesObject.find((shadeObject) => shadeObject.single_dual !== "Single");
	if (!hasAllSingles) {
		return true;
	}

	return false;
};

/** 
 * @desc For dev/admin specifically, expecting event, shade assemblies.item, 
rerender function, reprice function, Quote ID.
 * @param event - event object, typically a keyboard press
 * @param shade - shade object, type def can be found in indoorShadeTypes.d.ts.
 * @param render - rerender function, found in quote UI render page.
 * @param reprice - reprice function, found in new quote object (reprice single row)
 * @param quoteId - quote ID, found in new quote object.
 */
/* eslint no-param-reassign: "off" */
// TODO: type render and reprice function
export const quickShadeFunction = (
	event: KeyboardEvent,
	shade: IndoorShade,
	render,
	reprice,
	quoteID: number
) => {
	const controlShift = (event.metaKey || event.ctrlKey) && event.shiftKey;
	const quickShadeBaseCommand = controlShift && event.code === 'KeyQ';
	const getShadeObject = controlShift && event.code === 'KeyZ';
	const quickMotorized = controlShift && event.code === 'KeyM';
	const quickManual = controlShift && event.code === 'KeyI';
	const quickFascia = controlShift && event.code === 'KeyF';
	const quickCassette = controlShift && event.code === 'KeyX';
	const quickFabCassette = controlShift && event.code === 'KeyC';
	const quickSquareCassette = controlShift && event.code === 'KeyS';
	const quickOpen = controlShift && event.code === 'KeyO';

	const bodyCreation = () => ({
		id: quoteID,
		shadeAssemblies: [{ ...shade }],
	});

	const saveQuote = () => {
		const saveQuoteTwo = typedApi['saveQuoteTwo']
			?? (() => new Promise((resolve) => {
				resolve(false);
			}));
		saveQuoteTwo({ quote: bodyCreation() }, quoteID)
			.then(() => {
				const idk = typedRoot;
				console.log(idk);
				toast('Shade saved to quote', {
					type: 'success',
				});
			})
			.catch(() => {
				toast('Shade failed to save to quote', {
					type: 'error',
				});
			});
	};

	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const prettyHeaderName = (header) => {
		switch (header) {
			case 'RC3080-A':
				return 'Fascia';
			case 'OR_1234':
				return 'Open Roll';
			case 'RC3051-A':
				return 'OG Cassette';
			case 'RC3029-A':
				return 'Square Cassette';
			case 'RB88-0150-050490':
				return 'Fabric Wrapped Cassette';
			default:
				return 'Generic Header';
		}
	};

	const shadeNameConstructor = () => {
		if (shade.header_type) {
			return `Quick ${capitalizeFirstLetter(shade.shades[0].shade_type)} ${prettyHeaderName(
				shade.header_type
			)} Shade ${shade.id}`;
		}
		if (!shade.header_type && shade.shades[0].shade_type) {
			return `Quick ${capitalizeFirstLetter(shade.shades[0].shade_type)} Shade ${shade.id}`;
		}
		if (!shade.shades[0].shade_type || !shade.header_type) {
			return `Quick Shade ${shade.id}`;
		}
		return 'Quick Shade';
	};

	const quickShadePreReqs = () => {
		shade.control_side = shade.control_side ?? 'Right';
		shade.direction_facing = shade.direction_facing ?? 'Unknown';
		shade.mount_type = shade.mount_type ?? 'Inside';
		shade.room_name = 'r 1';
		shade.floor = 1;
		shade.single_dual = shade.single_dual ?? 'Single';
		shade.window_jamb_depth = shade.window_jamb_depth ?? 5;
		shade.side_channels = shade.side_channels ?? 'None';
		shade.shades[0].fabric_name = shade.shades[0].fabric_name ?? 'Screen Essential 3001 Ebony';
		shade.shades[0].hembar_type = shade.shades[0].hembar_type ?? 'internal_sealed';
		shade.shades[0].roll_direction = shade.shades[0].roll_direction ?? 'standard';
		shade.shades[0].width = shade.shades[0].width ?? 30;
		shade.shades[0].height = shade.shades[0].height ?? 50;
		shade.shade_name = shadeNameConstructor();
		shade.quick_shade = true;
		shade.quote_id = quoteID;
	};

	const quickMotorizedCommand = () => {
		shade.shades[0].motor_type = 'low_voltage';
		shade.shades[0].shade_type = 'motorized';
		shade.shades[0].lv_power_source = 'wall_charger';
		shade.shades[0].manual_chain = null;
		shade.shades[0].manual_chain_color = null;
		shade.shade_name = shadeNameConstructor();
	};

	if (quickShadeBaseCommand) {
		event.preventDefault();
		quickShadePreReqs();
		render();
		toast('Base quick shade command ran', {
			type: 'success',
		});
	}

	if (getShadeObject) {
		console.log('getshadeobject hit!');
		console.log('shade is:');
		console.log(shade);
	}

	if (quickMotorized) {
		event.preventDefault();
		if (!shade.quick_shade) {
			quickShadePreReqs();
		}
		quickMotorizedCommand();
		render();
		toast('Quick motorized shade command ran', {
			type: 'success',
		});
	}

	if (quickManual) {
		event.preventDefault();
		if (!shade.quick_shade) {
			quickShadePreReqs();
		}
		shade.shades[0].manual_chain = 'spring';
		shade.shades[0].shade_type = 'manual';
		shade.shades[0].motor_type = null;
		shade.shades[0].lv_power_source = null;
		shade.shade_name = shadeNameConstructor();
		render();
		toast('Quick manual shade command ran', {
			type: 'success',
		});
	}

	if (quickFascia) {
		event.preventDefault();
		if (!shade.quick_shade) {
			quickShadePreReqs();
		}
		if (!shade.shades[0].shade_type) {
			quickMotorizedCommand();
		}
		shade.header_type = 'RC3080-A';
		shade.end_caps = 'Fascia End Caps';
		shade.hardware_color = null;
		shade.shade_name = shadeNameConstructor();
		reprice(shade.sequence_id);
		saveQuote();
		render();
		toast('Quick fascia shade command ran', {
			type: 'success',
		});
	}

	if (quickOpen) {
		event.preventDefault();
		if (!shade.quick_shade) {
			quickShadePreReqs();
		}
		if (!shade.shades[0].shade_type) {
			quickMotorizedCommand();
		}
		shade.header_type = 'OR_1234';
		shade.end_caps = 'Plastic End Caps';
		shade.hardware_color = 'Black';
		shade.shade_name = shadeNameConstructor();
		reprice(shade.sequence_id);
		saveQuote();
		render();
		toast('Quick open roll shade command ran', {
			type: 'success',
		});
	}

	if (quickCassette) {
		event.preventDefault();
		if (!shade.quick_shade) {
			quickShadePreReqs();
		}
		if (!shade.shades[0].shade_type) {
			quickMotorizedCommand();
		}
		shade.header_type = 'RC3051-A';
		shade.hardware_color = null;
		shade.end_caps = null;
		shade.shade_name = shadeNameConstructor();
		reprice(shade.sequence_id);
		saveQuote();
		render();
		toast('Quick OG cassette shade command ran', {
			type: 'success',
		});
	}
	if (quickSquareCassette) {
		event.preventDefault();
		if (!shade.quick_shade) {
			quickShadePreReqs();
		}
		if (!shade.shades[0].shade_type) {
			quickMotorizedCommand();
		}
		shade.header_type = 'RC3029-A';
		shade.hardware_color = null;
		shade.end_caps = null;
		shade.shade_name = shadeNameConstructor();
		reprice(shade.sequence_id);
		saveQuote();
		render();
		toast('Quick square cassette shade command ran', {
			type: 'success',
		});
	}
	if (quickFabCassette) {
		event.preventDefault();
		if (!shade.quick_shade) {
			quickShadePreReqs();
		}
		if (!shade.shades[0].shade_type) {
			quickMotorizedCommand();
		}
		shade.header_type = 'RB88-0150-050490';
		shade.hardware_color = null;
		shade.end_caps = null;
		shade.shade_name = shadeNameConstructor();
		reprice(shade.sequence_id);
		saveQuote();
		render();
		toast('Quick fabric wrapped cassette shade command ran', {
			type: 'success',
		});
	}
};
