import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "../../Parts/Button";
import { Col, Row } from "react-bootstrap";

const PaymentMethodButton = ({
	quoteColor, onClick, style, icon, text, disabled
}) => {
	const styles = {
		button: {
			display: 'flex',
			flexDirection: 'column',
			padding: '0.8em 1.4em',
			fontSize: '12px',
			alignItems: 'center',
			justifyContent: 'space-between',
			boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.25)',
			backgroundColor: quoteColor,
			flexGrow: '1',
			maxWidth: '132px',
		},

		icon: {
			fontSize: '2.4em',
			marginBottom: '0.25em',
			color: 'white',
		},

		span: {
			fontSize: '1.25em',
			color: 'white',
			fontWeight: 'normal',
			whiteSpace: 'normal',
			lineHeight: '1.25em'
		},
	};

	return (
		<Button color="green" onClick={onClick} style={{ ...styles.button, ...style }} disabled={disabled}>
			<Row>
				<Col>
					<FontAwesomeIcon icon={icon} style={styles.icon} />
				</Col>
				<Col>
					<span style={styles.span}>{text}</span>
				</Col>
			</Row>

		</Button>
	);
};

export default PaymentMethodButton;
