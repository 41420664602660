import QUOTE_GLOBALS from '../Quote/QuoteGlobals';
import store from '../Store';
import { selectAccessories } from '../Store/entities/accessories/hooks';
import { captureSentryError } from '../psUtil';

function accessoriesRow({
	worksheet, workbook, row, accessoryItem, quote, userMultiplier, hidePrice, currencyData
}) {
	const accessory_pricing_object = quote.accessories.find((acc) => acc && parseInt(acc.accessory_id ) === parseInt(accessoryItem.accessory_id ));

	const quote_acc = accessoryItem;

	const state = store.getState();

	const accessorries = selectAccessories(quote.id)(state);

	const accessory = accessorries.find((acc) => acc.part_number === quote_acc.part_number);

	// accessory = QUOTE_GLOBALS.getAccessory(accessory.accessory_id) || {};

	let col = 1;

	const currency_style = workbook.createStyle({

		numberFormat: '$#,##0.00; ($#,##0.00); -',
		fill: {
			type: 'pattern',
			patternType: 'solid',
			fgColor: '#f0f0f0',
		},
		border: {
			left: {
				style: 'thin',
				color: 'black'
			},
			right: {
				style: 'thin',
				color: 'black'
			},
			top: {
				style: 'thin',
				color: 'black'
			},
			bottom: {
				style: 'thin',
				color: 'black'
			},
			diagonal: {
				style: 'thin',
				color: 'black'
			},
			diagonalDown: true,
			outline: true
		}
	});

	function moneyCell(amount) {
		amount = amount || 0;

		worksheet
			.cell(row, col++)
			.number(amount)
			.style(currency_style);
        
		return cell;
	}

	function cell(string) {
		const cell = worksheet.cell(row, col++)
			.string(`${string}`)
			.style({
				alignment: {
					horizontal: 'center',
				},
				fill: {
					type: 'pattern',
					patternType: 'solid',
					fgColor: '#f0f0f0',
				},
				border: {
					left: {
						style: 'thin',
						color: 'black'
					},
					right: {
						style: 'thin',
						color: 'black'
					},
					top: {
						style: 'thin',
						color: 'black'
					},
					bottom: {
						style: 'thin',
						color: 'black'
					},
					diagonal: {
						style: 'thin',
						color: 'black'
					},
					diagonalDown: true,
					outline: true
				}
			});
        
		return cell;
	}

	cell(quote_acc.quantity);

	const accName = accessory?.name || quote_acc?.name || '';

	cell(accName);
	cell(accessory?.part_number ?? quote_acc?.part_number ?? "");
	
	if (!hidePrice) {
		const accessoryMsrp = accessory_pricing_object?.msrp ?? 0;
		if (
			accessoryMsrp <= 0
			|| !currencyData?.multiplier
			|| !userMultiplier?.multiplier?.multiplier
			|| accessory_pricing_object?.quantity === undefined
			|| accessory_pricing_object?.quantity <= 0
		) {
			console.error({ accessory, accessory_pricing_object });
			captureSentryError(new Error(`Accessory ${accName} has no MSRP`));
			cell(`${currencyData.ISO}0.00 (Something went wrong fetching pricing)`);
			cell(`${currencyData.ISO}0.00 (Something went wrong fetching pricing)`);
			cell(`${currencyData.ISO}0.00 (Something went wrong fetching pricing)`);
			cell(`${currencyData.ISO}0.00 (Something went wrong fetching pricing)`);
			return;
		}
		const accessoryMsrpWithQty = accessoryMsrp * currencyData.multiplier;
		const realMultiplier = userMultiplier.multiplier.multiplier;
		moneyCell(accessoryMsrpWithQty);
		moneyCell(accessoryMsrpWithQty * accessory_pricing_object.quantity);
		moneyCell(accessoryMsrpWithQty * realMultiplier);
		moneyCell((accessoryMsrpWithQty * accessory_pricing_object.quantity) * realMultiplier);
	}
}

function accessoriesHeaderRow({
	worksheet, style, row, hidePrice, currencyData
}) {
	let col = 1;

	function cell(string) {
		worksheet.cell(row, col++)
			.string(string)
			.style(style);
	}

	cell(`Qty`);
	cell(`Name`);
	cell(`Part #`);

	if (!hidePrice) {
		cell(`MSRP (${currencyData.ISO})`);
		cell(`Total MSRP (${currencyData.ISO})`);
		cell(`Dealer Price (${currencyData.ISO})`);
		cell(`Total Dealer Price (${currencyData.ISO})`);
	}
}

export default function ({
	workbook, worksheet, quote, quote_data, userMultiplier, row, hidePrice, currencyData
}) {
	const HEAD_ROW_STYLE = workbook.createStyle({
		alignment: {
			horizontal: 'center',
		},
		fill: {
			type: 'pattern',
			patternType: 'solid',
			fgColor: '#d5d5d5',
		},
		border: {
			left: {
				style: 'thin',
				color: 'black'
			},
			right: {
				style: 'thin',
				color: 'black'
			},
			top: {
				style: 'thin',
				color: 'black'
			},
			bottom: {
				style: 'thin',
				color: 'black'
			},
			diagonal: {
				style: 'thin',
				color: 'black'
			},
			diagonalDown: true,
			outline: true
		}
	});

	const HEADER_STYLE = workbook.createStyle({

		font: {
			size: 16,
			bold: true,
		}
	});

	worksheet
		.cell(row++, 1)
      	.string('Accessories')
      	.style(HEADER_STYLE);

	accessoriesHeaderRow({
		worksheet, style: HEAD_ROW_STYLE, row: row++, hidePrice, currencyData
	});

	quote.accessories?.forEach((accessory) => accessoriesRow({
		worksheet, workbook, accessoryItem: accessory, quote, userMultiplier, row: row++, hidePrice, currencyData
	}));
    
	row += (quote.accessories || []).length;

	return row;
}
