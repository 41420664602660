import { useState, useEffect, useMemo } from "react";
import { useTable, useSortBy, useFilters } from "react-table";
import { Container, Row, Col, Table } from "react-bootstrap";
import Money from "../Parts/Money";
import { FaArrowUp, FaArrowDown, FaPlusCircle, FaSyncAlt, FaMinusCircle, FaTimes, FaExclamationCircle, FaTicketAlt, FaBarcode, FaTruck } from 'react-icons/fa';



// Simulated function to get quote type
function getQuoteType(quote) { 
  if (!quote) return 'default';
  return quote.type === 'premium' ? 'premium' : 'standard';
}
const QuoteHistoryHeadRow = (props) => (
	<tr className="bold-row">
		<th>Date / Time</th>
		<th>Description</th>
		<th>User Name</th>
		<th>User Email</th> 
		<th>MSRP Snapshot</th>
	</tr>
);

// Define a default column filter for text filtering
function DefaultColumnFilter({
  column: { filterValue, setFilter },
}) {
  return (
    <input
      value={filterValue || ""}
      onClick={(e) => e.stopPropagation()} // Evitar que se active el ordenamiento al hacer clic en el campo de búsqueda
      onChange={(e) => setFilter(e.target.value || undefined)}
      placeholder={`Search...`}
      style={{ width: '100%' }}
    />
  );
}

// Function to format the description field (with icons and colors)
const formatDescription = (description) => {
  if (typeof description !== "string") {
    console.warn("Warning: formatDescription received a non-string value:", description);
    description = String(description || ""); 
  }

  if (description.includes("AddAssemblyLog")) {
    return <div>{description}</div>;
  }

  let formattedDescription = description
    .split(/, |; /)
    .map((line, index) => {
      line = line
        .replace("SequenceId", "Sequence Id")
        .replace("FieldName", "Field Name")
        .replace("OldValue", "Previous Value")
        .replace("NewValue", "Current Value")
        .replace("RowId", "Row Id")
        .replace("ColumnId", "Column Id");

      if (line.includes("Row Id:") && (line.includes("Row Id: ,") || line.trim().endsWith("Row Id:"))) {
        return null;
      }
      if (line.includes("Column Id:") && (line.includes("Column Id: ,") || line.trim().endsWith("Column Id:"))) {
        return null;
      }
    //  if (line.includes("Old Value:") && (line.includes("Old Value: ,") || line.trim().endsWith("Old Value:"))) {
    //    return null;
    //  }
    //  if (line.includes("New Value:") && (line.includes("New Value: ,") || line.trim().endsWith("New Value:"))) {
    //    return null;
    //  }

      const icon = line.includes("Added") ? <FaPlusCircle style={{ color: "blue" }} /> :
                   line.includes("Created") && !line.includes("Created Shipment") ? <FaPlusCircle style={{ color: "green" }} /> :
                   line.includes("Error") ? <FaExclamationCircle style={{ color: "red" }} /> : 
                   line.includes("error") ? <FaExclamationCircle style={{ color: "red" }} /> : 
                   line.includes("Coupon") ? <FaTicketAlt style={{ color: "green" }} /> : 
                   line.includes("Recipe SKU") ? <FaBarcode /> : 
                   line.includes("Created Shipment") ? <FaTruck /> : 
                   line.includes("PowerShades Admin unlocked") ? <FaExclamationCircle style={{ color: "orange" }} /> :
                   line.includes("Duplicated Quote") ? <FaPlusCircle style={{ color: "green" }} /> :
                   line.includes("Updated") ? <FaSyncAlt style={{ color: "green" }} /> :
                   line.includes("Removed") ? <FaMinusCircle style={{ color: "red" }} /> : null;

                   

      const parts = line.split(": ");
      const style = index === 0 ? {} : { marginLeft: "20px" };

      if (parts.length > 1) {
        return (
          <div key={index} style={style}>
            {icon} <strong style={{ color: "darkgreen" }}>{parts[0]}:</strong> {parts[1]}
          </div>
        );
      } else {
        return (
          <div key={index} style={style}>
            {icon} {line} 
          </div>
        );
      }
    })
    .filter(line => line !== null);

  return formattedDescription;
};

// Define the columns and the first table (Quote History)
const QuoteHistoryTable = ({ entries, quoteType }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Date / Time",
        accessor: "time",
        Cell: ({ value }) => {
          const date = new Date(value * 1000);
          return (
            <>
              {date.toLocaleDateString()} <br /> {date.toLocaleTimeString()}
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: "Seq Id",
        accessor: "sequence_id",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Shade Name",
        accessor: "shade_name",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Shade Type",
        accessor: "shade_type",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Indoor / Outdoor",
        accessor: "indoor_outdoor",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ value }) => formatDescription(value),
        Filter: DefaultColumnFilter,
      },
      {
        Header: "MSRP Snapshot",
        accessor: "msrp_snapshot",
        Cell: ({ value }) => <Money>{value}</Money>,
        disableFilters: true,
      },
      {
        Header: "User Name",
        accessor: "user_name",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "User Email",
        accessor: "user_email",
        Filter: DefaultColumnFilter,
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: entries,
      defaultColumn: { Filter: DefaultColumnFilter },
    },
    useFilters,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <>
      <Row>
        <Col>
          <div className="sorting-info" style={{ marginBottom: '10px', backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px' }}>
            <p style={{ margin: 0 }}>
              Click on the column headers to sort the data. You can toggle between ascending and descending order by clicking the arrows.
              <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                <FaArrowUp /> <FaArrowDown />
              </span>
            </p>
          </div>
        </Col>
      </Row>
      <Table {...getTableProps()} responsive size="sm" bordered hover className={`table-striped-rows ${quoteType}`}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{ backgroundColor: '#8dc640', color: 'white', fontWeight: 'bold' }}
                >
                  {column.render("Header")}
                  <span style={{ fontSize: "12px", color: 'darkgreen' }}>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FaArrowDown />
                      ) : (
                        <FaArrowUp />
                      )
                    ) : ""}
                  </span>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

// Define the columns and the second table (Accessories)
const AccessoriesTable = ({ accessories }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Date / Time",
        accessor: "time",
        Cell: ({ value }) => {
          const date = new Date(value);
          date.setHours(date.getHours() - 6);
          return (
            <>
              {date.toLocaleDateString()} <br /> {date.toLocaleTimeString()}
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: "Action",
        accessor: "action",
        Filter: DefaultColumnFilter,
        Cell: ({ value }) => formatDescription(value)
      },
      {
        Header: "Part Number",
        accessor: "part_number",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Name",
        accessor: "name",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Reference",
        accessor: "field_name",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Current Value",
        accessor: "new_value",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Previous Value",
        accessor: "old_value",
        Filter: DefaultColumnFilter,
      },  
      {
        Header: "User Name",
        accessor: "user_name",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "User Email",
        accessor: "user_email",
        Filter: DefaultColumnFilter,
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: accessories,
      defaultColumn: { Filter: DefaultColumnFilter },
    },
    useFilters,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <>
      <Row>
        <Col>
          <div className="sorting-info" style={{ marginBottom: '10px', backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px' }}>
            <p style={{ margin: 0 }}>
              Click on the column headers to sort the data. You can toggle between ascending and descending order by clicking the arrows.
              <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                <FaArrowUp /> <FaArrowDown />
              </span>
            </p>
          </div>
        </Col>
      </Row>
      <Table {...getTableProps()} responsive size="sm" bordered hover className={`table-striped-rows`}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{ backgroundColor: '#8dc640', color: 'white', fontWeight: 'bold' }}
                >
                  {column.render("Header")}
                  <span style={{ fontSize: "12px", color: 'darkgreen' }}>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FaArrowDown />
                      ) : (
                        <FaArrowUp />
                      )
                    ) : ""}
                  </span>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

// Define the columns and the third table (Hardware Requests)
const HardwareRequestsTable = ({ hardwareRequests }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Date / Time",
        accessor: "time",
        Cell: ({ value }) => {
          const date = new Date(value);
          date.setHours(date.getHours() - 6);
          return (
            <>
              {date.toLocaleDateString()} <br /> {date.toLocaleTimeString()}
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: "Action",
        accessor: "action",
        Filter: DefaultColumnFilter,
        Cell: ({ value }) => formatDescription(value)
      },
      {
        Header: "SKU",
        accessor: "sku",
        Filter: DefaultColumnFilter,
      },

      {
        Header: "Description",
        accessor: "description",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Unit Measurement",
        accessor: "unit_measurement",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Current Value",
        accessor: "new_value",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Previous Value",
        accessor: "old_value",
        Filter: DefaultColumnFilter,
      },  
      {
        Header: "User Name",
        accessor: "user_name",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "User Email",
        accessor: "user_email",
        Filter: DefaultColumnFilter,
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: hardwareRequests,
      defaultColumn: { Filter: DefaultColumnFilter },
    },
    useFilters,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <>
      <Row>
        <Col>
          {/* Message to inform user about sorting functionality */}
          <div className="sorting-info" style={{ marginBottom: '10px', backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px' }}>
            <p style={{ margin: 0 }}>
              Click on the column headers to sort the data. You can toggle between ascending and descending order by clicking the arrows.
              <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                <FaArrowUp /> <FaArrowDown />
              </span>
            </p>
          </div>
        </Col>
      </Row>
      <Table {...getTableProps()} responsive size="sm" bordered hover className={`table-striped-rows`}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{ backgroundColor: '#8dc640', color: 'white', fontWeight: 'bold' }}
                >
                  {column.render("Header")}
                  <span style={{ fontSize: "12px", color: 'darkgreen' }}>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FaArrowDown />
                      ) : (
                        <FaArrowUp />
                      )
                    ) : ""}
                  </span>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

// Define the columns and the fourth table (General Quotes Actions)
const GeneralQuotesActionsTable = ({ generalActions }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Date / Time",
        accessor: "time",
        Cell: ({ value }) => {
          const date = new Date(value);
          date.setHours(date.getHours() - 6);
          return (
            <>
              {date.toLocaleDateString()}
              <br />
              {date.toLocaleTimeString()}
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: "Action",
        accessor: "action",
        Filter: DefaultColumnFilter,
        Cell: ({ value }) => formatDescription(value)
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ value }) => formatDescription(value),
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Current Value",
        accessor: "new_value",
        Cell: ({ value }) => formatDescription(value),
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Previous Value",
        accessor: "old_value",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "User Name",
        accessor: "user_name",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "User Email",
        accessor: "user_email",
        Filter: DefaultColumnFilter,
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: generalActions,
      defaultColumn: { Filter: DefaultColumnFilter },
    },
    useFilters,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <>
      <Row>
        <Col>
          {/* Message to inform user about sorting functionality */}
          <div className="sorting-info" style={{ marginBottom: '10px', backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px' }}>
            <p style={{ margin: 0 }}>
              Click on the column headers to sort the data. You can toggle between ascending and descending order by clicking the arrows.
              <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                <FaArrowUp /> <FaArrowDown />
              </span>
            </p>
          </div>
        </Col>
      </Row>
      <Table {...getTableProps()} responsive size="sm" bordered hover className={`table-striped-rows`}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{ backgroundColor: '#8dc640', color: 'white', fontWeight: 'bold' }}
                >
                  {column.render("Header")}
                  <span style={{ fontSize: "12px", color: 'darkgreen' }}>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FaArrowDown />
                      ) : (
                        <FaArrowUp />
                      )
                    ) : ""}
                  </span>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

const excludedDescriptions = [
  "This order was set to ship",
  "The salesperson was set to",
  "This order was set to pre ship whips",
  "This order was set to not ship",
  "The title was set to",
  "The reference number was set to",
  "The notes were set to",
  "This order was set to pre ship whip",
  "This order was set to not pre ship whips",
  "This order was not set to have constant balanced lightgaps",
  "This order was set to have constant balanced lightgaps",
  "The rma type was set to",
  "Notes Updated",
  "previous_quote_id",
  "updated was changed",
  "Coupon named",
  "This quote has been submitted",
  "Assigned Manufacturer",
  "Dashboard Populated Succesfully",
  "Dashboard Populated Failed", 
  "Recipe SKU",
  "Retainer Invoice Creation Succeded",
  "Created Shipment"
];


const QuoteHistory = (props) => {
  const [entries, setEntries] = useState([]);
  const [accessories, setAccessories] = useState([]);
  const [hardwareRequests, setHardwareRequests] = useState([]);
  const [generalActions, setGeneralActions] = useState([]);
  const quoteType = getQuoteType(props.newQuote);

  useEffect(() => {
    api.getQuoteHistory(props.quote).then((resp) => {
      if (resp.data && resp.data.entries) {
        // Filtra las filas de "Shades" que no contengan las frases excluidas en "Description"
        const filteredEntries = resp.data.entries.filter(
          (entry) => 
            !excludedDescriptions.some((phrase) => entry.description && entry.description.includes(phrase))
        );
        setEntries(filteredEntries);
      }
    });
  }, []);

  // Load accessories data history
  useEffect(() => {
    api.getQuoteHistory_accessories(props.quote).then((resp) => {
      if (resp.data && resp.data.entries) {
        setAccessories(resp.data.entries);
      }
    });
  }, []);

  // Load hardware requests data history
  useEffect(() => {
    api.getQuoteHistory_hardware(props.quote).then((resp) => {
      if (resp.data && resp.data.entries) {
        setHardwareRequests(resp.data.entries);
      }
    });
  }, []);

  // Load general quote actions data
  useEffect(() => {
    api.getGeneralQuoteActionsHistory(props.quote).then((resp) => {
      if (resp.data && resp.data.entries) {
        setGeneralActions(resp.data.entries);
      }
    });
  }, []);

  return (
    <div className="modal-holder">
      <div className="quote-history-modal">
        <Container className="local-bootstrap">
          {/* Botón de cierre en la esquina superior derecha */}
          <button 
            onClick={props.onClose} 
            style={{ 
              position: 'absolute', 
              top: '10px', 
              right: '10px', 
              background: 'none', 
              border: 'none', 
              fontSize: '20px', 
              cursor: 'pointer',
              color: '#000'
            }}
            aria-label="Close"
          >
            <FaTimes />
          </button>
          
          <Row>
            <Col>
              <h4 className={`text-${quoteType}`}>Quote History</h4>
            </Col>
          </Row>
  
          {/* Conditionally render General Quotes Actions section */}
          {generalActions.length > 0 && (
            <>
              <Row>
                <Col>
                  <h5 className={`text-${quoteType}`} style={{ marginTop: '30px' }}>General Quote Actions</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <GeneralQuotesActionsTable generalActions={generalActions} />
                </Col>
              </Row>
            </>
          )}

            <Row>
            <Col>
              <h5 className={`text-${quoteType}`} style={{ marginTop: '30px' }}>Shades</h5>
            </Col>
          </Row>

          <Row>
            <Col>
              <QuoteHistoryTable quoteType={quoteType} entries={entries} />
            </Col>
          </Row>


          {/* Conditionally render Accessories section */}
          {accessories.length > 0 && (
            <>
              <Row>
                <Col>
                  <h5 className={`text-${quoteType}`} style={{ marginTop: '30px' }}>Accessories</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <AccessoriesTable accessories={accessories} />
                </Col>
              </Row>
            </>
          )}

          {/* Conditionally render Hardware Requests section */}
          {hardwareRequests.length > 0 && (
            <>
              <Row>
                <Col>
                  <h5 className={`text-${quoteType}`} style={{ marginTop: '30px' }}>Hardware Requests</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <HardwareRequestsTable hardwareRequests={hardwareRequests} />
                </Col>
              </Row>
            </>
          )}

          <div className="button-holder">
            <button
              style={{ paddingLeft: "16px", paddingRight: "16px" }}
              type="button"
              className={`btn btn-green pt-0 pb-0 btn-${quoteType}`}
              onClick={props.onClose}
            >
              Done
            </button>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default QuoteHistory;
