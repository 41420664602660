import React, { useRef, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Lottie from 'lottie-react';
import error from '../assets/img/animations/error.json';
import success from '../assets/img/animations/success.json';
import warning from '../assets/img/animations/warning.json';
import confetti from '../assets/img/animations/confetti.json';
import info from '../assets/img/animations/info.json';
import Button from './Button';
import { AlertProps } from '../FakeRootType';

/* eslint no-nested-ternary: "off", no-unused-expressions: "off", 
no-param-reassign: "off", consistent-return: "off",
default-case: "off", no-console: "off" */



const AlertModal = ({
	title = '',
	text = '',
	noCancelButton = false,
	acceptButtonText,
	cancelButtonText,
	onAccept = () => {},
	onCancel = () => {},
	quoteType = 'cedia',
	icon,
	smallIcon = true,
	closeOnDocumentClick = false,
	oneButton = false,
	quickFormat = '',
}: AlertProps) => {
	const useOutsideAlerter = (ref) => {
		useEffect(() => {
			/**
			 * Alert if clicked on outside of element
			 */
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					!noCancelButton ? (onCancel && onCancel()) : onAccept ? onAccept() : null;
				}
			}
			// Bind the event listener
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, [ref]);
	};

	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef);

	const convertIconToComponent = () => {
		if (!icon) return;
		if (typeof icon === 'string') {
			icon = icon.toLowerCase();
		} else {
			return icon;
		}
		switch (icon) {
			case 'error':
				return error;
			case 'success':
				return success;
			case 'warning':
				return warning;
			case 'confetti':
				return confetti;
			case 'info':
				return info;
			default:
				return Error();
		}
	};

	if (quickFormat) {
		// used for quick format modals, small boi's that we use for resp.data.errors or whatever
		quickFormat = quickFormat.toLowerCase();

		switch (quickFormat) {
			case 'error':
				icon = error;
				title = title || 'Error';
				oneButton = oneButton || true;
				text = text || 'error';
				if (text === 'error') {
					console.error('Text was not passed through in the props for the alert call.');
				}
				break;
			case 'success':
				icon = icon || success;
				title = title || 'Success';
				oneButton = oneButton || true;
				text = text || 'success';
				if (text === 'success') {
					console.error('Text was not passed through in the props for the alert call.');
				}
				break;
			case 'info':
				icon = icon || info;
				title = title || 'Information';
				oneButton = oneButton || true;
				text = text || 'info';
				if (text === 'info') {
					console.error('Text was not passed through in the props for the alert call.');
				}
				break;
			default:
				throw Error('You have entered an invalid quick format type.');
		}
	}

	return (
		<div className="new-modal-holder">
			<div ref={closeOnDocumentClick ? wrapperRef : null} className="new-modal">
				<div className="local-bootstrap">
					<Container>
						{icon ? (
							<Row>
								<Col className="text-center">
									<Lottie
										animationData={convertIconToComponent()}
										loop={false}
										className={`modal-icon ${smallIcon ? ` small` : null}`}
									/>
								</Col>
							</Row>
						) : null}
						<Row>
							<Col className="text-center">
								<h4 className={quoteType ? `text-${quoteType}` : `text-green`}>{title}</h4>
							</Col>
						</Row>
						<Row>
							<Col>
								<span>{text}</span>
							</Col>
						</Row>
						<Row className="mt-4">
							{!oneButton && (
								<Col className="text-start">
									{noCancelButton ? null : (
										<Button
											fluid
											color="gray"
											onClick={onCancel}
										>
											{cancelButtonText || 'Cancel'}
										</Button>
									)}
								</Col>
							)}
							<Col className="text-end">
								<Button
									color={quoteType ?? 'green'}
									onClick={onAccept}
								>
									{oneButton && !acceptButtonText ? 'Dismiss' : acceptButtonText || 'Accept'}
								</Button>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		</div>
	);
};

export default AlertModal;
