import { AppDispatch, UsePortalDispatch } from "../../Store";
import { addQuoteAccessory, deleteQuoteAccessory, updateQuoteAccessory } from "../../Store/entities/accessories";
import {useQuoteAccessories } from "../../Store/entities/accessories/hooks";
import { useAssembliesByQuoteId } from "../../Store/entities/assemblies/hooks";
import { AssemblyStore, ShadeStore } from "../../Store/entities/assemblies/types";
import { QuoteStore } from "../../Store/entities/entitiesType";
import { updateQuoteMeta } from "../../Store/entities/quotes";
import { useQuoteById, useQuoteTypeNameByQuoteId } from "../../Store/entities/quotes/hooks";
import { useSavedQuoteWarnings } from "../../Store/entities/quotewarnings/hooks";
import { useIsPowerShadesAdmin } from "../../hooks";
import { QuoteAccessoryStore } from "../../powershadesApiTypes";
import HeaderNameBySku from "./HeaderNameBySku";

type QuoteWarningVerification = {
	action: () => void;
	warning: () => string;
	conditional: () => boolean;
	warning_code: () => string;
	actionLabel: (() => string) | false;
	manualLabel: () => string;
	ignoreLabel: () => string;
	noManual?: () => boolean;
};

type QuoteWarningVerificationWithCallback = {
	action: (callback: () => {}) => void;
	warning: () => string;
	conditional: () => boolean;
	warning_code: () => string;
	actionLabel: (() => string) | false;
	manualLabel: () => string;
	ignoreLabel: () => string;
	noManual?: () => boolean;
}; 

const QuoteWarningVerificationsFromStore = (allAssemblies: AssemblyStore[], accessories: QuoteAccessoryStore[], quote: QuoteStore, dispatch: AppDispatch) => {
	const checks: QuoteWarningVerification[] = [];

	// Common Methods and values
	const getCount = (accessory) => {
		if (!accessory) return 0;
		const sku = accessory.part_number ?? accessory;
		const accessoryItems = accessories.filter((a) => a.part_number === sku);
		const count = accessoryItems?.reduce((total, next) => total + next.quantity, 0) ?? 0;
		return count;
	};

	const matterAssemblies = allAssemblies.filter((a) => a.shades.some(s => s.motor_type === "low_voltage_matter"));
	const assemblies = allAssemblies.filter((a) => !a.shades.some(s => s.motor_type === "low_voltage_matter"));

	const matterAssembllyCount = matterAssemblies.reduce((next,agg) => {
		return next +( agg.is_dual ? 2 : 1);
	}, 0);

	const matterMotorRemoteSpotCount = Math.floor((matterAssembllyCount-1) / 6) + 1;

	const matterMotorRemoteSku = "PSRMT_MAT_2025";
	const matterMotorRemoteCount = getCount(matterMotorRemoteSku);

	const matterMotorCableSku = "PS_MGCBL_USBC_3M_BLK";
	const matterMotorCableCount = getCount(matterMotorCableSku);

	//Matter Remote
	if(matterMotorRemoteSpotCount > matterMotorRemoteCount) {
		checks.push({
			action: () => {
				const difference = matterMotorRemoteSpotCount - matterMotorRemoteCount;
				changeAccessory(matterMotorRemoteSku, matterMotorRemoteCount + difference);
			},
			warning: () => `You currently have ${matterMotorRemoteCount} Matter Motor(s) and ${matterMotorRemoteSpotCount} Matter Motor Remote(s). We recommend at least one (1) remote connection per Matter Motor.`,
			conditional: () => matterMotorRemoteSpotCount > matterMotorRemoteCount,
			warning_code: () => 'matter_motor_remote',
			actionLabel: () => "Add recommended connection(s)",
			manualLabel: () => "Got it, I’ll update myself",
			ignoreLabel: () => "Ignore and proceed"
		});
	}

	// // Charging Dongle
	// if(matterAssembllyCount > dongleCount) {
	// 	checks.push({
	// 		action: () => {
	// 			const difference =  dongleCount;
	// 			changeAccessory(dongleSku, dongleCount + difference);
	// 		},
	// 		warning: () => `You currently have ${dongleCount} USB-C Dongle(s) and ${matterAssembllyCount} Matter Motor(s). We recommend at least one (1) dongle per Matter Motor.`,
	// 		conditional: () => matterAssembllyCount > dongleCount,
	// 		warning_code: () => 'matter_motor_dongle',
	// 		actionLabel: () => "Add recommended connection(s)",
	// 		manualLabel: () => "Got it, I’ll update myself",
	// 		ignoreLabel: () => "Ignore and proceed"
	// 	});
	// }

	// Charging Cable
	if(matterAssembllyCount > matterMotorCableCount) {
		checks.push({
			action: () => {
				const difference = matterAssembllyCount - matterMotorCableCount;
				changeAccessory(matterMotorCableSku, matterMotorCableCount + difference);
			},
			warning: () => `You currently have ${matterMotorCableCount} USB-C Cable(s) and ${matterAssembllyCount} Matter Motor(s). We recommend at least one (1) cable per Matter Motor.`,
			conditional: () => matterAssembllyCount > matterMotorCableCount,
			warning_code: () => 'matter_motor_cable',
			actionLabel: () => "Add recommended connection(s)",
			manualLabel: () => "Got it, I’ll update myself",
			ignoreLabel: () => "Ignore and proceed"
		});
	}

	const currentNextLineNumber = accessories.reduce((max, next) => Math.max(max, next.line_number), 0) + 1;

	const changeAccessory = (quoteSkuToSaveOrAdd: string, quantity: number) => {
		const currentCount = getCount(quoteSkuToSaveOrAdd);
		const lineNumber = accessories.find((a) => a.part_number === quoteSkuToSaveOrAdd)?.line_number ?? 0;
		if (quantity === 0) {
			dispatch(deleteQuoteAccessory({
				lineNumber,
				quoteId: quote.id

			}));
		} else if (currentCount === 0) {
			dispatch(addQuoteAccessory({
				quoteId: quote.id,
				sku: quoteSkuToSaveOrAdd,
				quantity,
				parms: [],
				nextLineNumber: currentNextLineNumber
			}));
		} else {
			dispatch(updateQuoteAccessory({
				quoteId: quote.id,
				lineNumber: lineNumber,
				quantity,
				parms: []
			}));
		}
	};

	const changeQuote = (quotePartial: Partial<QuoteStore>) => {
		dispatch(updateQuoteMeta({
			quoteId: quote.id,
			changeQuote: (q) => ({ ...q, ...quotePartial })
		}));
	};

	// "Recommend Power Supply Brick with the RF Repeater" - "https://trello.com/c/aXH2QigW/515-recommend-power-supply-brick-with-the-rf-repeater"
	const rfRepeaterSku = "PS_RF_REPEATER_2023";
	const rfRepreaters = accessories.find((a) => a.part_number == rfRepeaterSku);
	const rfRepreatersCount = getCount(rfRepreaters);

	// "Shade Rules Control - Required Items" - https://trello.com/c/8HuMtZQq/306-shade-rules-control-required-items
	// This first check is for Remotes
	const singleChannelHandSku = "PSRMT_SC_2022";
	const singleChannelHand = accessories.find((a) => a.part_number == singleChannelHandSku);
	const singleChannelHandCount = getCount(singleChannelHand);

	const singleChannelWallSku = "PSRMT_SCOW_2022";
	const singleChannelWall = accessories.find((a) => a.part_number == singleChannelWallSku);
	const singleChannelWallCount = getCount(singleChannelWall);

	const multiChannelHandSku = "PSRMT_MC_2022";
	const multiChannelHand = accessories.find((a) => a.part_number == multiChannelHandSku);
	const multiChannelHandCount = getCount(multiChannelHand);

	const multiChannelWallSku = "PSRMT_MCOW_2022";
	const multiChannelWall = accessories.find((a) => a.part_number == multiChannelWallSku);
	const multiChannelWallCount = getCount(multiChannelWall);

	const shades = assemblies.reduce((list, sa) => {
		list.push(...(sa.shades.filter((s) => s.column_coordinate == 0)));
		return list;
	}, [] as ShadeStore[]);
	const numberOfRfShades = assemblies.reduce((total, sa) => {
		const isDual = sa.shades.some((s) => s.row_coordinate == 1);
		
		if(sa.shades.some(s => s.motor_type.toUpperCase().includes("POE"))) return total;

		if (sa.shades.some((s) => s.shade_type == 'motorized') && !sa.shades.some((s) => (s.motor ?? '').toUpperCase().includes('POE'))) {
			return (isDual ? 2 : 1) + total;
		}
		return total;
	}, 0);

	shades.filter((s) => !(s.motor ? s.motor.includes('Poe') : false) && s.shade_type == 'motorized').length;

	const numberOfRemoteSpots = multiChannelWallCount * 16 + multiChannelHandCount * 16 + singleChannelHandCount + singleChannelWallCount;

	checks.push({
		action: () => {
			const difference = numberOfRfShades - numberOfRemoteSpots;

			if (difference == 1 && numberOfRemoteSpots == 0) {
				changeAccessory(singleChannelHandSku, 1);
			} else {
				const numberOfMultiChannelsToAdd = Math.floor((difference - 1) / 16) + 1;

				changeAccessory(multiChannelHandSku, multiChannelHandCount + numberOfMultiChannelsToAdd);
			}
		},
		warning: () => `You currently have ${numberOfRfShades} RF shade(s) and ${numberOfRemoteSpots} connections to remotes. We recommend at least one (1) remote connection per RF shade.`,
		conditional: () => numberOfRfShades > numberOfRemoteSpots,
		warning_code: () => 'remote_count',
		actionLabel: () => "Add recommended connection(s)",
		manualLabel: () => "Got it, I’ll update myself",
		ignoreLabel: () => "Ignore and proceed"
	});

	// "Shade Rules Control - Required Items" - https://trello.com/c/8HuMtZQq/306-shade-rules-control-required-items
	// This is the second check, involving RF Gateways
	const rfGatewaySku = 'PSRFGW_2024';
	const rfGateway = accessories.find((a) => a.part_number == rfGatewaySku);
	const rfGatewayCount = getCount(rfGateway);

	
	const bondBridgeGatewaySku = 'BD-1750';
	const bondBridgeGateway = accessories.find((a) => a.part_number == bondBridgeGatewaySku);
	const bondBridgeGatewayCount = getCount(bondBridgeGateway);
	// Number of RF shades was already calculated.

	const rfGatewaySpots = rfGatewayCount * 15 + bondBridgeGatewayCount * 50;


	checks.push({
		action: () => {
			const difference = numberOfRfShades - rfGatewaySpots;

			const numberOfGatewaysToAdd = Math.floor((difference - 1) / 15) + 1;

			changeAccessory(rfGatewaySku, rfGatewayCount + numberOfGatewaysToAdd);
		},
		warning: () => `You currently have ${numberOfRfShades} RF shade(s) and ${rfGatewaySpots} RF gateway channels. An RF Gateway is required for automation with RF shades.`,
		conditional: () => rfGatewaySpots < numberOfRfShades,
		warning_code: () => 'rf_gateway_spot_count',
		actionLabel: () => "Add recommended connection(s)",
		ignoreLabel: () => "Ignore and proceed",
		manualLabel: () => "Got it, I’ll update myself",
	});

	// "Seamable Improvements" - https://trello.com/c/6QkTyUa5/525-seamable-improvements
	// This will let a user know that these have seams

	const shadesWithSeams = assemblies.filter((s) => s.will_be_seamed);
	const shadesWithSeamsString = shadesWithSeams.reduce((prev, next) => (prev ? `${prev},(${next.shade_name})` : `(${next.shade_name})`), "");

	checks.push({
		warning: () => `Shade(s) ${shadesWithSeamsString}, contain fabric that will be seamed. If you have a preference between where the seam(s) is placed, please declare your preference in the order notes.`,
		conditional: () => {
			if (shadesWithSeams.length > 0) {
				return true;
			}
			return false;
		},
		warning_code: () => 'seamed_shades',
		manualLabel: () => "Go to notes",
		ignoreLabel: () => "Ignore and proceed",
		actionLabel: false,
		action: () => {
			// Do something
		}
	});

	// "Fabric Will Rub"
	// This will let a user know that the fabric will rub on the header
	const shadesWithRubbing = assemblies.filter((s) => s.fabric_will_rub);
	const shadesWithRubbingString = shadesWithRubbing.reduce((prev, next) => (prev ? `${prev},(${next.shade_name})` : `(${next.shade_name})`), "");

	checks.push({
		warning: () => `Shade(s) ${shadesWithRubbingString}, contain fabric that will rub on the header. This may cause wear on the fabric over time.`,
		conditional: () => {
			if (shadesWithRubbing.length > 0) {
				return true;
			}
			return false;
		},
		warning_code: () => 'rubbing_shades',
		manualLabel: () => "Got it, I’ll adjust my selection",
		ignoreLabel: () => "Ignore and proceed",
		actionLabel: false,
		action: () => {
			// Do something
		}
	});

	// "Room Validation" - https://trello.com/c/ieeNy3NH/530-room-validation
	// This check will let the users know that a heaer size/color does not match.

	const differentRoomSets = assemblies.reduce((obj, next) => {
		let roomName = "none";

		const roomNameUnclean = `${next.room_name}`;

		if (!(roomNameUnclean == null || roomNameUnclean == undefined || roomNameUnclean == "")) {
			roomName = roomNameUnclean.trim().toLowerCase();
		}

		if (!obj[roomName]) {
			obj[roomName] = [];
		}
		const listToAdd = obj[roomName];

		if (listToAdd) {
			listToAdd.push(next);
		}

		return obj;
	}, {} as Record<string, AssemblyStore[]>);

	const roomSetsWithHeaderIssues = {} as Record<string, AssemblyStore[]>;
	const roomSetsWithMotorIssues = {} as Record<string, AssemblyStore[]>;
	const roomSetsWithTubeIssues = {} as Record<string, AssemblyStore[]>;
	const roomSetsWithFrontFabricIssues = {} as Record<string, AssemblyStore[]>;
	const roomSetsWithBackFabricIssues = {} as Record<string, AssemblyStore[]>;

	Object.entries(differentRoomSets).forEach(([room, shadesy]) => {
		const header = shadesy[0]?.header_line ?? "";

		const color = shadesy[0]?.hardware_color ?? "";

		const mismatchHWShades = shadesy.filter((s) => s.header_line != header || s.hardware_color != color);

		if (mismatchHWShades.length > 0) {
			roomSetsWithHeaderIssues[room] = shadesy;
		}

		const firstMotorizedShadeForTubes = shadesy.find((s) => s.shades[0]?.shade_type == "motorized");

		const tube = firstMotorizedShadeForTubes?.shades[0]?.tube_sku ?? "";

		const mismatchTubeShades = shadesy.filter((s) => s?.shades[0]?.shade_type == "motorized" && s?.shades[0]?.tube_sku != tube);

		const motorTypes: string[] = [];
		shadesy.map((s) =>
			s?.shades[0]?.motor_type
			&& !motorTypes.includes(s?.shades[0].motor_type)
			&& motorTypes.push(s?.shades[0]?.motor_type));

		if (motorTypes.length > 1) {
			roomSetsWithMotorIssues[room] = shadesy;
		}

		if (mismatchTubeShades.length > 0) {
			roomSetsWithTubeIssues[room] = shadesy;
		}

		const frontFabrics = assemblies
			.filter((a) => a.room_name === room)
			.map((s) => s.shades.filter((sh) => sh.row_coordinate === 0)[0]?.fabric_name)
			.filter((s) => s !== undefined && s !== null);
			
		const backFabrics = assemblies
			.filter((a) => a.room_name === room)
			.map((s) => s.shades.filter((sh) => sh.row_coordinate === 1)[0]?.fabric_name)
			.filter((s) => s !== undefined && s !== null);

		const frontFabric = frontFabrics[0];

		const mismatchFrontFabricShades = frontFabrics.filter((s) => s !== frontFabric);

		if (mismatchFrontFabricShades.length > 0) {
			roomSetsWithFrontFabricIssues[room] = shadesy;
		}

		if (backFabrics.length > 0) {
			const backFabric = backFabrics[0];
			const mismatchBackFabricShades = shadesy.filter((s) => (s.shades.find((sha) => sha.row_coordinate === 1)?.fabric_name ?? "") !== backFabric);
			if (mismatchBackFabricShades.length > 0) {
				roomSetsWithBackFabricIssues[room] = shadesy;
			}
		}
	});

	checks.push({
		warning: () => {
			let respString = "You currently have shades with differing headers/colors within the same room.";

			for (const [room, shades] of Object.entries(roomSetsWithHeaderIssues)) {
				// Getting differing header shades combos
				const headerShades = shades.reduce((obj, shade) => {
					const headerType = HeaderNameBySku(shade.header_line);
					let headerArr = obj[headerType] ?? [];
					if (!headerArr) {
						headerArr = [];
						obj[headerType] = headerArr;
					}

					headerArr.push(shade.shade_name);

					return obj;
				}, {} as Record<string, string[]>);

				const colorShades = shades.reduce((obj, shade) => {
					const hardwareColor = shade.hardware_color;

					if (!hardwareColor) {
						return obj;
					}

					let objList = obj[hardwareColor] ?? [];

					if (!objList) {
						objList = [];
						obj[hardwareColor] = objList;
					}

					objList.push(shade.shade_name);

					return obj;
				}, {} as Record<string, string[]>);

				let roomStr = `\n\nRoom: ${room} \n\n `;

				if (Object.keys(headerShades).length > 1) {
					let iterator = 0;

					for (const [headerType, shadeNames] of Object.entries(headerShades)) {
						const shadeString = shadeNames.reduce((prev, next) => (prev ? `${prev} ${next} :${headerType} \n` : `${next} :${headerType} \n`), "");

						roomStr += `${shadeString} `;
						iterator += 1;
					}
				}

				if (Object.keys(headerShades).length > 1 && Object.keys(colorShades).length > 1) {
					roomStr += `\n\n Room: ${room}\n\n Shades: `;
				}

				if (Object.keys(colorShades).length > 1) {
					const num = Object.keys(colorShades).length;
					let iterator = 0;

					let isFirstColor = true;

					for (const [color, shadeNames] of Object.entries(colorShades)) {
						if (!isFirstColor) {
							if (num - 1 == iterator) {
								roomStr += " and ";
							} else {
								roomStr += ", ";
							}
						} else {
							isFirstColor = false;
						}

						const shadeString = shadeNames.reduce((prev, next) => (prev ? `${prev}  ${next} :${color} \n` : `${next} :${color} \n`), "");
						roomStr += `${shadeString} `;
						iterator += 1;
					}

					roomStr += ".  \n\n";
				}

				respString += roomStr;
			}

			return respString;
		},
		conditional: () => Object.keys(roomSetsWithHeaderIssues).length > 0,
		warning_code: () => {
			const roomUnclean = Object.keys(roomSetsWithHeaderIssues).find(() => true) ?? "none";
			const simplifiedRoom = roomUnclean.replace(" ", "__").toLocaleLowerCase();

			return `differing_headers_${simplifiedRoom}`;
		},
		manualLabel: () => "Got it, I’ll adjust my selection",
		ignoreLabel: () => "Ignore and proceed with differing headers/colors",
		actionLabel: false,
		action: () => { }
	});

	for (const [room] of Object.entries(roomSetsWithTubeIssues)) {
		// const respString = `You currently have shades with differing tubes within the same room - ${room}. This might result in a differing shade speed in room ${room}.`;
		const respString = `You currently have shades with differing tubes within the same room - ${room}. This might result in a differing shade speed in room ${room}. If you would like to update all tubes to match, there may be additional charges. Please reach out to us to adjust your order as additional charges may incur. Please do this before finalizing your quote to your potential client.`;
		const simplifiedRoom = room.replace(" ", "__").toLocaleLowerCase();

		// Getting differing header shades combos
		// const tubeShades = shades.reduce((obj, shade) => {
		// 	const tubeType = shade.val('tube_sku');
		// 	if (!obj[tubeType]) {
		// 		obj[tubeType] = [];
		// 	}

		// 	obj[tubeType].push(shade.val('shade_name'));

		// 	return obj;
		// }, {});

		// let roomStr = `\n\nRoom: ${room} \n\n `;

		// if (Object.keys(tubeShades).length > 0) {
		// 	const num = Object.keys(tubeShades).length;
		// 	let iterator = 0;

		// 	const isFirstHeader = true;

		// 	for (const [tubeSku, shadeNames] of Object.entries(tubeShades)) {
		// 		const shadeString = shadeNames.reduce((prev, next) => (prev ? `${prev} ${next} :${tubeSku} \n` : `${next} :${tubeSku} \n`), null);

		// 		roomStr += `${shadeString} `;
		// 		iterator += 1;
		// 	}
		// }

		// respString += roomStr;

		// const largestTube = "";

		checks.push({
			warning: () => respString,
			conditional: () => true,
			warning_code: () => `differing_tubes_${simplifiedRoom}`,
			manualLabel: () => "Got it, I'll call PowerShades to adjust my order",
			ignoreLabel: () => "Ignore and proceed with differing tubes.",
			action: () => {

			},
			actionLabel: false,
		});
	}

	// checks.push({
	// 	warning: () => {
	// 		let respString = "You currently have shades with differing headers/colors within the same room.";

	// 		for (const [room, shades] of Object.entries(roomSetsWithHeaderIssues)) {
	// 			// Getting differing header shades combos
	// 			const headerShades = shades.reduce((obj, shade) => {
	// 				const headerType = HeaderNameBySku(shade.val('header_line'));
	// 				if (!obj[headerType]) {
	// 					obj[headerType] = [];
	// 				}

	// 				obj[headerType].push(shade.val('shade_name'));

	// 				return obj;
	// 			}, {});

	// 			const colorShades = shades.reduce((obj, shade) => {
	// 				const hardwareColor = shade.val('hardware_color');

	// 				if (!hardwareColor) {
	// 					return obj;
	// 				}

	// 				if (!obj[hardwareColor]) {
	// 					obj[hardwareColor] = [];
	// 				}

	// 				obj[hardwareColor].push(shade.val('shade_name'));

	// 				return obj;
	// 			}, {});

	// 			let roomStr = `\n\nRoom: ${room} \n\n `;

	// 			if (Object.keys(headerShades).length > 1) {
	// 				const num = Object.keys(headerShades).length;
	// 				let iterator = 0;

	// 				const isFirstHeader = true;

	// 				for (const [headerType, shadeNames] of Object.entries(headerShades)) {
	// 					const shadeString = shadeNames.reduce((prev, next) => (prev ? `${prev} ${next} :${headerType} \n` : `${next} :${headerType} \n`), null);

	// 					roomStr += `${shadeString} `;
	// 					iterator += 1;
	// 				}
	// 			}

	// 			if (Object.keys(headerShades).length > 1 && Object.keys(colorShades).length > 1) {
	// 				roomStr += `\n\n Room: ${room}\n\n Shades: `;
	// 			}

	// 			if (Object.keys(colorShades).length > 1) {
	// 				const num = Object.keys(colorShades).length;
	// 				let iterator = 0;

	// 				let isFirstColor = true;

	// 				for (const [color, shadeNames] of Object.entries(colorShades)) {
	// 					if (!isFirstColor) {
	// 						if (num - 1 == iterator) {
	// 							roomStr += " and ";
	// 						} else {
	// 							roomStr += ", ";
	// 						}
	// 					} else {
	// 						isFirstColor = false;
	// 					}

	// 					const shadeString = shadeNames.reduce((prev, next) => (prev ? `${prev}  ${next} :${color} \n` : `${next} :${color} \n`), null);
	// 					roomStr += `${shadeString} `;
	// 					iterator += 1;
	// 				}

	// 				roomStr += ".  \n\n";
	// 			}

	// 			respString += roomStr;
	// 		}

	// 		return respString;
	// 	},
	// 	conditional: () => Object.keys(roomSetsWithHeaderIssues).length > 0,
	// 	warning_code: () => `differing_headers-${Object.keys(roomSetsWithHeaderIssues).find(a => true) ?? "none"}`,
	// 	manualLabel: () => "Got it, I’ll adjust my selection",
	// 	ignoreLabel: () => "Ignore and proceed with differing headers/colors",
	// 	actionLabel: null
	// });

	Object.entries(roomSetsWithFrontFabricIssues).forEach(([room]) => {
		const respString = `You currently have shades with differing front (inside) fabrics within the same room - ${room}. This might result in a shade color mismatch in room  ${room}.`;
		const simplifiedRoom = room.replace(" ", "__").toLocaleLowerCase();

		// // Getting differing header shades combos
		// const fabricShades = shadesy.reduce((obj, shade) => {
		// 	const fabType = shade.val('fabric_name');
		// 	if (!obj[fabType]) {
		// 		obj[fabType] = [];
		// 	}

		// 	obj[fabType].push(shade.val('shade_name'));

		// 	return obj;
		// }, {});

		// let roomStr = `\n\nRoom: ${room} \n\n `;

		// if (Object.keys(fabricShades).length > 0) {
		// 	const num = Object.keys(fabricShades).length;
		// 	let iterator = 0;

		// 	const isFirstHeader = true;

		// 	for (const [tubeSku, shadeNames] of Object.entries(fabricShades)) {
		// 		const shadeString = shadeNames.reduce((prev, next) => (prev ? `${prev} ${next} :${tubeSku} \n` : `${next} :${tubeSku} \n`), null);

		// 		roomStr += `${shadeString} `;
		// 		iterator += 1;
		// 	}
		// }

		// respString += roomStr;

		checks.push({
			warning: () => respString,
			conditional: () => true,
			warning_code: () => `differing_front_fabrics_${simplifiedRoom}`,
			manualLabel: () => "Got it, I'll change my selection",
			ignoreLabel: () => "Ignore and proceed with differing fabrics",
			action: () => {

			},
			actionLabel: false,
		});
	});

	Object.entries(roomSetsWithBackFabricIssues).forEach(([room]) => {
		const respString = `You currently have shades with differing back (outside) fabrics within the same room - ${room}. This might result in a shade color mismatch.`;
		const simplifiedRoom = room.replace(" ", "__").toLocaleLowerCase();

		// Getting differing header shades combos
		// const fabricShades = shadesy.reduce((obj, shade) => {
		// 	const fabType = shade.val('fabric2_name');
		// 	if (!obj[fabType]) {
		// 		obj[fabType] = [];
		// 	}

		// 	obj[fabType].push(shade.val('shade_name'));

		// 	return obj;
		// }, {});

		// let roomStr = `\n\nRoom: ${room} \n\n `;

		// if (Object.keys(fabricShades).length > 0) {
		// 	const num = Object.keys(fabricShades).length;
		// 	let iterator = 0;

		// 	const isFirstHeader = true;

		// 	for (const [tubeSku, shadeNames] of Object.entries(fabricShades)) {
		// 		const shadeString = shadeNames.reduce((prev, next) => (prev ? `${prev} ${next} :${tubeSku} \n` : `${next} :${tubeSku} \n`), null);

		// 		roomStr += `${shadeString} `;
		// 		iterator += 1;
		// 	}
		// }

		// respString += roomStr;

		checks.push({
			warning: () => respString,
			conditional: () => true,
			warning_code: () => `differing_back_fabrics_${simplifiedRoom}`,
			manualLabel: () => "Got it, I'll change my selection",
			ignoreLabel: () => "Ignore and proceed with differing fabrics",
			action: () => {

			},
			actionLabel: false,
		});
	});

	// checks.push({
	// 	warning: () => {
	// 		let respString = "You currently have shades with differing tubes within the same room.";

	// 		for (const [room, shades] of Object.entries(roomSetsWithTubeIssues)) {
	// 			// Getting differing header shades combos
	// 			const tubeShades = shades.reduce((obj, shade) => {
	// 				const tubeType = shade.val('tube_sku');
	// 				if (!obj[tubeType]) {
	// 					obj[tubeType] = [];
	// 				}

	// 				obj[tubeType].push(shade.val('shade_name'));

	// 				return obj;
	// 			}, {});

	// 			let roomStr = `\n\nRoom: ${room} \n\n `;

	// 			if (Object.keys(tubeShades).length > 0) {
	// 				const num = Object.keys(tubeShades).length;
	// 				let iterator = 0;

	// 				const isFirstHeader = true;

	// 				for (const [tubeSku, shadeNames] of Object.entries(tubeShades)) {
	// 					const shadeString = shadeNames.reduce((prev, next) => (prev ? `${prev} ${next} :${tubeSku} \n` : `${next} :${tubeSku} \n`), null);

	// 					roomStr += `${shadeString} `;
	// 					iterator += 1;
	// 				}
	// 			}

	// 			if (Object.keys(tubeShades).length > 1 ) {
	// 				roomStr += `\n\n Room: ${room}\n\n Shades: `;
	// 			}

	// 			respString += roomStr;
	// 		}

	// 		return respString;
	// 	},
	// 	conditional: () => Object.keys(roomSetsWithTubeIssues).length > 0,
	// 	warning_code: () => 'differing_tubes',
	// 	manualLabel: () => "Got it, I’ll adjust my selection",
	// 	ignoreLabel: () => "Ignore and proceed with differing tubes"
	// });

	checks.push({
		warning: () => {
			let respString = "You currently have shades with differing motors within the same room.";

			for (const [room] of Object.entries(roomSetsWithMotorIssues)) {
				// Getting differing header shades combos

				const roomStr = `\n\nRoom: ${room} \n\n `;

				respString += roomStr;
			}

			return respString;
		},
		conditional: () => Object.keys(roomSetsWithMotorIssues).length > 0,
		warning_code: () => 'differing_motors',
		manualLabel: () => "Got it, I’ll adjust my selection",
		ignoreLabel: () => "Ignore and proceed with differing motors",
		actionLabel: false,
		action: () => { }
	});

	// Shade Rules: Power - Required Items - https://trello.com/c/uzzpmSGd/305-shade-rules-power-required-items
	// This card has three parts, with sub points.

	// Part one is low voltage hardwired, which includes a whip and PS power supplies depending on the number of shades.
	// This is point a which is the number and size of power supplies
	const lowVolatagDCMotorsCount = assemblies.reduce((count, currentAssembly) => {
		if (currentAssembly.shades.some((s) => s.motor_type == "low_voltage_hw")) {
			return currentAssembly.is_dual ? count + 2 : count + 1;
		}

		return count;
	}, 0);

	const powerSupply4Sku = "PC-U0405-PULQ";
	const powerSupply4 = accessories.find((a) => a.part_number == powerSupply4Sku);
	const powerSupply4Count = getCount(powerSupply4);

	const powerSupply9Sku = "PC-U0910-PULQ";
	const powerSupply9 = accessories.find((a) => a.part_number == powerSupply9Sku);
	const powerSupply9Count = getCount(powerSupply9);

	const powerSupply18Sku = "PC-U1830-PULQ";
	const powerSupply18 = accessories.find((a) => a.part_number == powerSupply18Sku);
	const powerSupply18Count = getCount(powerSupply18);

	const totalAvailable = powerSupply18Count * 18 + powerSupply9Count * 9 + powerSupply4Count * 4;

	checks.push({
		action: () => {
			let total18 = Math.floor(lowVolatagDCMotorsCount / 18);
			let total9 = 0;
			let total4 = 0;

			const leftOver = lowVolatagDCMotorsCount % 18;
			if (leftOver > 0) {
				if (leftOver > 9) {
					total18 += 1;
				} else if (leftOver > 4) {
					total9 += 1;
				} else {
					total4 += 1;
				}
			}

			changeAccessory(powerSupply9Sku, total9);
			changeAccessory(powerSupply18Sku, total18);
			changeAccessory(powerSupply4Sku, total4);

			return () => {
				// Callback?
			};
		},

		warning: () => `You currently have ${lowVolatagDCMotorsCount} shade motor(s) that need a low voltage power supply and ${totalAvailable} available low voltage power supply outputs. We recommend one power supply out per low voltage hardwired shade motor.`,
		conditional: () => lowVolatagDCMotorsCount > totalAvailable,
		warning_code: () => 'lv_dc_hw_power_supplies',
		actionLabel: () => `Add recommended power supplies`,
		manualLabel: () => `Got it, I’ll update myself`,
		ignoreLabel: () => `Ignore and proceed`,
	});

	// Part one point b is about the DC whips. This one is a bit simpler, a direct one to one.
	const DCWhipSku = "PSDCWHIP_2022";
	const DCWhip = accessories.find((a) => a.part_number == DCWhipSku);
	const DCWhipCount = getCount(DCWhip);

	checks.push({
		action: () => {
			changeAccessory(DCWhipSku, lowVolatagDCMotorsCount);

			return () => {
				// Callback?
			};
		},
		warning: () => `You currently have ${lowVolatagDCMotorsCount} low voltage shade motors and ${DCWhipCount} DC whips. We recommend at least one (1) DC whip per low voltage shade motor.`,
		conditional: () => lowVolatagDCMotorsCount > DCWhipCount,
		warning_code: () => 'lv_dc_hw_whip_count',
		actionLabel: () => `Add recommended whips`,
		manualLabel: () => `Got it, I’ll update myself`,
		ignoreLabel: () => `Ignore and proceed`,

	});

	// Now for part 2 point a. This involves low_voltage motors. Essentially, for 18mm or a 25mm motor we want one microUSB cable and one USB charger block is there per shade.  .
	const smallLowVolatagDCBatteryMotorsCount = assemblies.reduce((count, currentAssembly) => {
		if (currentAssembly.shades.some((s) => s.motor_type == 'low_voltage') 
			&& currentAssembly.shades.some((s) => s.shade_type === 'motorized') 
			&& !currentAssembly.shades.some((s) => (s).lv_power_source === 'dc_whip' )) {
			return currentAssembly.is_dual ? count + 2 : count + 1;
		}

		return count;
	}, 0);

	const largeLowVolatagDCBatteryMotorsCount = 0;
	/*assemblies.reduce((count, currentAssembly) => {
		if (currentAssembly.shades.some((s) => s.motor_type == 'low_voltage') 
			&& currentAssembly.shades.some((s) => s.shade_type === 'motorized') 
			&& !currentAssembly.shades.some((s) => (s).lv_power_source === 'dc_whip' )) {
			return currentAssembly.is_dual ? count + 2 : count + 1;
		}

		return count;
	}, 0);
*/
	const lowVolatagDCBatteryMotorsCount = smallLowVolatagDCBatteryMotorsCount + largeLowVolatagDCBatteryMotorsCount;
	const neededCableAndBricksCount = Math.ceil(lowVolatagDCBatteryMotorsCount / 5);

	const usbPowerSupplySku = 'PSUSBCHRGR_2022';
	const usbPowerSupply = accessories.find((a) => a.part_number == usbPowerSupplySku);
	const usbPowerSupplyCount = getCount(usbPowerSupply);

	const usbRAPowerSupplySku = 'PSUSBRABRICK_2024';
	const usbRAPowerSupply = accessories.find((a) => a.part_number == usbRAPowerSupplySku);
	const usbRAPowerSupplyCount = getCount(usbRAPowerSupply);

	

	// //This bit adds micro-usb chargers for the smaller low_voltage shades.
	// checks.push({
	//     action: () => {
	//         let totalItemsNeeded = smallLowVolatagDCBatteryMotorsCountNotSolar - totalSmallChargersWall;

	//         changeAccessory(microUSBCableSku, totalItemsNeeded + microUSBCableCount);
	//         changeAccessory(usbPowerSupplySku, totalItemsNeeded + microUSBCableCount);

	//         return () => {
	//             //Callback?
	//         }
	//     },
	//     warning: () => {
	//         return `You currently have ${smallLowVolatagDCBatteryMotorsCountNotSolar} small low voltage shade motors with batteries and ${totalSmallChargersWall} small shade wall charger(s). We recommend at least one (1) charger per low voltage shade motor battery.`;
	//     },
	//     conditional: () => smallLowVolatagDCBatteryMotorsCountNotSolar > totalSmallChargersWall,
	//     warning_code: () => {
	//         return 'lv_dc_small_charger_count';
	//     },
	//     actionLabel: () => {
	//         return `Add recommended chargers`;
	//     },
	//     manualLabel: () => {
	//         return `Got it, I’ll update myself`;
	//     },
	//     ignoreLabel: () => {
	//         return `Ignore and proceed`;
	//     },

	// });

	// checks.push({
	//     action: () => {
	//         let totalItemsNeeded = smallLowVolatagDCBatteryMotorsCountSolar - microSolarCount;

	//         changeAccessory(microSolarSku, totalItemsNeeded + microSolarCount);

	//         return () => {
	//             //Callback?
	//         }
	//     },
	//     warning: () => {
	//         return `You currently have ${smallLowVolatagDCBatteryMotorsCountSolar} small low voltage shade motors with batteries and ${totalSmallChargersSolar} small shade solar charger(s). We recommend at least one (1) charger per low voltage shade motor battery.`;
	//     },
	//     conditional: () => smallLowVolatagDCBatteryMotorsCountSolar > totalSmallChargersSolar,
	//     warning_code: () => {
	//         return 'lv_dc_small_solar_charger_count';
	//     },
	//     actionLabel: () => {
	//         return `Add recommended chargers`;
	//     },
	//     manualLabel: () => {
	//         return `Got it, I’ll update myself`;
	//     },
	//     ignoreLabel: () => {
	//         return `Ignore and proceed`;
	//     },

	// });

	// This bit is for chargers that are for larger motors.

	// checks.push({
	//     action: () => {
	//         changeAccessory(barrelChargerSku, largeLowVolatagDCBatteryMotorsCountNotSolar);

	//     },
	//     warning: () => {
	//         return `You currently have ${largeLowVolatagDCBatteryMotorsCountNotSolar} large low voltage shade motors with batteries and ${totalLargeChargersWall} large shade wall chargers. We recommend at least one (1) charger per low voltage shade motor.`;
	//     },
	//     conditional: () => largeLowVolatagDCBatteryMotorsCountNotSolar > totalLargeChargersWall,
	//     warning_code: () => {
	//         return 'lv_dc_large_charger_count';
	//     },
	//     actionLabel: () => {
	//         return `Add recommended chargers`;
	//     },
	//     manualLabel: () => {
	//         return `Got it, I’ll update myself`;
	//     },
	//     ignoreLabel: () => {
	//         return `Ignore and proceed`;
	//     },
	// });

	// checks.push({
	//     action: () => {
	//         changeAccessory(largeSolarSku, largeLowVolatagDCBatteryMotorsCountSolar);

	//     },
	//     warning: () => {
	//         return `You currently have ${largeLowVolatagDCBatteryMotorsCountSolar} large low voltage shade motors with batteries and ${totalLargeChargersSolar} large shade solar chargers. We recommend at least one (1) charger per low voltage shade motor.`;
	//     },
	//     conditional: () => largeLowVolatagDCBatteryMotorsCountSolar > totalLargeChargersSolar,
	//     warning_code: () => {
	//         return 'lv_dc_large_solar_charger_count';
	//     },
	//     actionLabel: () => {
	//         return `Add recommended chargers`;
	//     },
	//     manualLabel: () => {
	//         return `Got it, I’ll update myself`;
	//     },
	//     ignoreLabel: () => {
	//         return `Ignore and proceed`;
	//     },
	// });

	// This is part 3, no subpoints. Simply an AC hardwired set up.
	const ACWhipSku = "PS_AC_WHIP_2023_W";
	const ACWhip = accessories.find((a) => a.part_number == ACWhipSku);
	const ACWhipCount = getCount(ACWhip);

	const ACMotorsCount = assemblies.reduce((count, currentAssembly) => {
		if (
			currentAssembly.shades.some((s) => s.motor_type === 'high_voltage')
			&& (currentAssembly.shades.some((s) => s.shade_type === 'motorized')
				&& currentAssembly.shade_type_id != 3
				&& currentAssembly.shade_type_id != 2
			)) {
			return currentAssembly.is_dual ? count + 2 : count + 1;
		}

		return count;
	}, 0);

	checks.push({
		action: () => {
			changeAccessory(ACWhipSku, ACMotorsCount);

			return () => {
				// Callback?
			};
		},
		warning: () => `You currently have ${ACMotorsCount} AC shade motors and ${ACWhipCount} AC whips. We recommend at least one (1) AC whip per AC shade motor.`,
		conditional: () => ACMotorsCount > ACWhipCount,
		warning_code: () => 'ac_whip_count',
		actionLabel: () => `Add recommended whips`,
		manualLabel: () => `Got it, I’ll update myself`,
		ignoreLabel: () => `Ignore and proceed`,
	});

	// This check is for POE Shades and making sure they have a coupler a POE shade.

	// Inline
	const POEInlineCouplerSku = "PS-CAT6IL";
	const POEInlineCoupler = accessories.find((a) => a.part_number == POEInlineCouplerSku);
	const POEInlineCouplerCount = getCount(POEInlineCoupler);

	// Flush
	const POEFlushCouplerSku = "PS-CAT6FT";
	const POEFlushCoupler = accessories.find((a) => a.part_number == POEFlushCouplerSku);
	const POEFlushCouplerCount = getCount(POEFlushCoupler);

	const POEMotorsCount = assemblies.reduce((count, currentAssembly) => {
		if ((currentAssembly.shades.some((s) => s.motor_type?.toLocaleLowerCase()?.includes("poe") ?? false))) {
			return currentAssembly.is_dual ? count + 2 : count + 1;
		}
		return count;
	}, 0);

	const POECouplerCount = POEFlushCouplerCount + POEInlineCouplerCount;

	checks.push({
		action: () => {
			changeAccessory(POEInlineCouplerSku, POEMotorsCount);

			return () => {
				// Callback?
			};
		},
		warning: () => `You currently have ${POEMotorsCount} PoE shade motors and ${POECouplerCount} ethernet coupler(s). We recommend at least one (1) in-line ethernet coupler per PoE shade motor.`,
		conditional: () => POEMotorsCount > POECouplerCount,
		warning_code: () => 'poe_coupler_count',
		actionLabel: () => `Add recommended couplers`,
		manualLabel: () => `Got it, I’ll update myself`,
		ignoreLabel: () => `Continue without PoE couplers.`,
	});

	//
	// checks.push({
	// 	action: () => {
	// 		self.Data.QuoteFieldChange('ship_order', 1);

	// 		return () => {
	// 			// Callback?
	// 		};
	// 	},
	// 	warning: () => `You currently have this order set as "Do not ship this quote" The order will need to be picked up at the items respective manufacturers. If a shipment ends up being required, further costs may be incurred.`,
	// 	conditional: () => !shippingOrder,
	// 	warning_code: () => 'ship_this_quote',
	// 	actionLabel: () => `Ship this quote`,
	// 	ignoreLabel: () => `Do not ship this quote.`,
	// 	noManual: () => true
	// });

	const whipsWillShip = quote.pre_ship_whips == true;

	checks.push({
		action: () => {
			// self.Data.QuoteFieldChange('pre_ship_whips', 1);
			changeQuote({ pre_ship_whips: true });

			return () => {
				// Callback?
			};
		},
		warning: () => `Would you like to pre ship your whips for your hardwired shades?`,
		conditional: () => whipsWillShip != true && (ACWhipCount + DCWhipCount > 0),
		warning_code: () => 'pre_ship_whips',
		actionLabel: () => `Pre Ship Whips.`,
		ignoreLabel: () => `Do not pre-ship the whips.`,
		noManual: () => true,
		manualLabel: () => `Got it, I’ll update myself`,
	});

	// Inline
	const WindSensorSku = "MT02-0301-072001";
	const WindSensor = accessories.find((a) => a.part_number == WindSensorSku);
	const WindSensorCount = getCount(WindSensor);

	const outdoorAssemblies = assemblies.filter((s) => (s.shade_type_id === 2 || s.shade_type_id === 3) && s.shades.some((sha) => sha.shade_type?.toLocaleLowerCase() == "motorized"));

	const rooms = outdoorAssemblies.map((s) => {
		const value = s.room_name ?? '';

		return value.trim ? value.trim() : value;
	});

	function unique(arr) {
		const u = {}; const
			a = [] as string[];
		for (let i = 0, l = arr.length; i < l; ++i) {
			if (!u.hasOwnProperty(arr[i])) {
				a.push(arr[i]);
				u[arr[i]] = 1;
			}
		}
		return a;
	}

	const uniqueRooms = unique(rooms);

	const outdoorAreaCount = uniqueRooms.length;

	checks.push({
		action: () => {
			changeAccessory(WindSensorSku, outdoorAreaCount);

			return () => {
				// Callback?
			};
		},
		warning: () => `
            You currently have ${outdoorAreaCount} outdoor shade area(s) and ${WindSensorCount} wind sensor(s). We recommend at least one (1) wind sensor per outdoor shade area.`,
		conditional: () => outdoorAreaCount > WindSensorCount,
		warning_code: () => 'outdoor_wind_sensor_count',
		actionLabel: () => `Add recommended wind sensor(s).`,
		ignoreLabel: () => `Ignore and proceed`,
		manualLabel: () => `Got it, I’ll update myself`,
	});

	const rfRepeaterWindSensorCount = 10;

	const windsensorRFRepreatersCount = Math.ceil(WindSensorCount / rfRepeaterWindSensorCount);

	const repeaterSpotsNeeded = windsensorRFRepreatersCount > rfGatewayCount ? windsensorRFRepreatersCount : rfGatewayCount; 


	checks.push({
		action: () => {
			changeAccessory(rfRepeaterSku, repeaterSpotsNeeded);

			return () => {
				// Callback?
			};
		},
		warning: () => `
            You currently have ${rfGatewayCount} RF Gateway(s) ${WindSensorCount == 0 ? "" : ` and ${WindSensorCount} Wind Sensor(s)`}. We recommend at least one RF Repeater per RF Gateway${WindSensorCount === 0 ? "" : ` and we REQUIRE atleast one RF Repeater per ${rfRepeaterWindSensorCount} wind sensors`}. Motor status feedback (percent openness and battery voltage), require an RF Repeater to function properly.
            
			An RF Repeater is required to operate a windsensor.
			`,
		conditional: () => repeaterSpotsNeeded > rfRepreatersCount,
		warning_code: () => 'gateway_to_repeater',
		actionLabel: () => `Add recommended RF Repeater(s).`,
		ignoreLabel: () => `Ignore and proceed`,
		manualLabel: () => `Got it, I’ll update myself`,
	});

	// Checking is a shade has a sag
	// This will let a user know that these have a sag

	const shadesWithSags = assemblies.filter((s) => s.has_sag_warning); // TODO, make sag
	const shadesWithSagsString = shadesWithSags.reduce((prev, next) => (prev ? `${prev},(${next.shade_name})` : `(${next.shade_name})`), "");

	const sagCount = shadesWithSags.length;

	checks.push({
		warning: () =>
			`DEFLECTION WARNING: Shade${sagCount == 1 ? '' : 's'} ${shadesWithSagsString} ${sagCount == 1 ? "is" : "are"} over our recommended deflection. The fabric may have a v shape that the customer will not like. Please contact your PowerShades Sales Rep for options.`, // return `Shade(s) ${shadesWithSagsString}, contain fabric that will be seamed. If you have a preference between where the seam(s) is placed, please declare your preference in the order notes.`;

		conditional: () => {
			if (sagCount > 0) {
				return true;
			}
			return false;
		},
		warning_code: () => 'sagging_shades',
		ignoreLabel: () => "Ignore and proceed",
		noManual: () => true,
		action: () => { },
		actionLabel: false,
		manualLabel: () => "Got it, I'll update myself",
	});

	// Inline
	const DCWhipToMicroSku = "12VDC-5VDC TRANSFORMER";
	const DCWhipToMicro = accessories.find((a) => a.part_number == DCWhipToMicroSku);
	const DCWhipToMicroCount = getCount(DCWhipToMicro);


	const is_whip_needed = (shade: AssemblyStore) => 
		shade.shades.some((sha) => 
			sha.lv_power_source.includes("dc_whip")) 
		&& shade.shades.some((s) => s.shade_type === "motorized") 
		&& shade.shades.some((sha) => sha.motor_type === 'low_voltage');

	const small_shade_whip_count = assemblies.reduce((total, s) => {

		if(is_whip_needed(s))
		{
			return total + (s.is_dual ? 2 : 1);
		}

		return total;
	}, 0); // Needs 12VDC-5VDC TRANSFORMER
	// const large_shade_whip_count = whip_needed.length - small_shade_whip_count; // Needs PS_DC_CAB_2022

	// Inline
	const currentCountMagneticUSBSku = "PS_MGCBL_MC_3M_BLK";
	const currentCountMagneticUSB = accessories.find((a) => a.part_number == currentCountMagneticUSBSku);
	const currentCountMagneticUSBCount = getCount(currentCountMagneticUSB);

	const currentCountPSOutdoorWhipSku = "PS_AC_WHIP_2023_BK";
	const currentCountPSOutdoorWhip = accessories.find((a) => a.part_number == currentCountPSOutdoorWhipSku);
	const currentCountPSOutdoorWhipCount = getCount(currentCountPSOutdoorWhip);

	const totalPowerBlocks = usbPowerSupplyCount + usbRAPowerSupplyCount;

	// This one checks to make sure there are enough power blocks per the cord.
	checks.push({
		action: () => {
			changeAccessory(usbPowerSupplySku, currentCountMagneticUSBCount + rfRepreatersCount + matterAssembllyCount);

			return () => {
				// Callback?
			};
		},
		warning: () => `You currently have ${currentCountMagneticUSBCount} micro USB cable(s), ${rfRepreatersCount} RF repeater(s) and ${totalPowerBlocks} power blocks. We recommend at least one (1) power block per micro USB cable and RF repeater.`,
		conditional: () => currentCountMagneticUSBCount + rfRepreatersCount + matterAssembllyCount > totalPowerBlocks,
		warning_code: () => 'power_block_count',
		actionLabel: () => `Add recommended power blocks`,
		manualLabel: () => `Got it, I’ll update myself`,
		ignoreLabel: () => `Ignore and proceed`,
	});

	// Added this to try and simplify this very wide configuration request
	// addCleaner is short for "Add Cleaner way of configuring these differing requests."
	const addCleaner = (code, compare, message, action, actionLabel, ignoreMessage = `Ignore and proceed`, manualMessage = `Got it, I’ll update myself`) => {
		checks.push({
			action: !action ? (() => { }) : () => {
				action();

				return () => {
					// Callback?
				};
			},
			warning: () => message,
			conditional: () => compare(),
			warning_code: () => code,
			actionLabel: () => actionLabel,
			ignoreLabel: () => ignoreMessage,
			manualLabel: () => manualMessage,

		});
	};

	addCleaner(
		`outdoor_whips`,
		() => currentCountPSOutdoorWhipCount < outdoorAssemblies.length,
		`You currently have ${outdoorAssemblies.length} outdoor shade(s) and ${currentCountPSOutdoorWhipCount} outdoor shade whip(s). We recommend at least one (1) outdoor shade whip per outdoor shade area.`,
		() => {
			changeAccessory(currentCountPSOutdoorWhipSku, outdoorAssemblies.length);
		},
		`Add recommended outdoor shade whip(s).`
	);

	addCleaner(
		`lv_li_wall_small`,
		() => currentCountMagneticUSBCount < neededCableAndBricksCount,
		`You currently have ${lowVolatagDCBatteryMotorsCount} shade(s) designated to have a Magnetic-USB Charger, and only ${currentCountMagneticUSBCount} total Micro-USB Charger(s). We recommend one (1) charger for every five (5) shades. \n Your battery powered shades will come pre-installed with a magnetic charging dongle. There is no need to add them to your order unless you want extras for your project. `,
		() => {
			changeAccessory(currentCountMagneticUSBSku, neededCableAndBricksCount);
		},
		`Add Recommended Magnetic USB charging cable(s)`
	);

	addCleaner(
		`lv_li_whip_small`,
		() => DCWhipToMicroCount < small_shade_whip_count,
		`You currently have ${small_shade_whip_count} shade(s) designated to have a Micro-USB DC Whip, and only ${DCWhipToMicroCount} total Micro-USB DC Whip Charger(s).`,
		() => {
			changeAccessory(DCWhipToMicroSku, small_shade_whip_count);
		},
		`Add recommended Micro USB DC Whips.`
	);
	// addCleaner(
	// 	`lv_li_whip_large`,
	// 	() => DCWhipToBarrelCount < large_shade_whip_count,
	// 	`You currently have ${large_shade_whip_count} shade(s) designated to have a Barrel Ended DC Whip, and only ${DCWhipToBarrelCount} total Barrel Ended DC Whip Charger(s).`,
	// 	() => {
	// 		changeAccessory(DCWhipToBarrelSku, large_shade_whip_count);
	// 	},
	// 	`Add recommended Barrel Ended DC Whips.`
	// );

	// Inline
	const POE2WandSku = "PS_MTRWAND_2024";
	const POE2Wand = accessories.find((a) => a.part_number == POE2WandSku);
	const POE2WandSkuCount = getCount(POE2Wand);

	const poe2MotorCount = assemblies.reduce((count, currentAssembly) => {
		if (currentAssembly.shades.some((s) => s.motor_type === "poe 2")) {
			return currentAssembly.is_dual ? count + 2 : count + 1;
		}

		return count;
	}, 0);

	const expectedpoe2MotorCount = Math.ceil(poe2MotorCount / 20);

	addCleaner(
		`poe_gen_2_wand`,
		() => POE2WandSkuCount < expectedpoe2MotorCount,
		`You currently have ${poe2MotorCount} shade(s) designated to have a POE Gen 2 Motor, and only ${POE2WandSkuCount} total POE Gen 2 Motor Limit Wands. We recommend atleast one wand for every 20 motors.`,
		() => {
			changeAccessory(POE2WandSku, expectedpoe2MotorCount);
		},
		`Add recommended POE 2 wands.`
	);

	const firstDraftChecks = checks.map((c) => {
		const oldWarning = c.warning;

		const newMessage = c.warning()?.replace("'", "") ?? "";

		const oldCode = c.warning_code ? c.warning_code() : "";

		const newCode = oldCode.replace("'", "");

		return {
			...c,
			warning_code: () => newCode,
			message: newMessage,
			warning: () => {
				const warning = oldWarning();

				const idk = warning.replace("'", "");

				return idk;
			}
		};
	});

	// adds an optional callback parameter for actions
	const finalChecks = firstDraftChecks.map((c) => {
		const { action } = c;
		const optionalCallback = (callback) => {
			action && action();
			callback && callback();
		};
		const newAction = !action ? action : optionalCallback;

		return { ...c, action: newAction };
	});

	return finalChecks as QuoteWarningVerificationWithCallback[];
};

const UseUnAcknowledgedWarnings = (quoteId: number) => {
	const quote = useQuoteById(quoteId);
	const assemblies = useAssembliesByQuoteId(quoteId);
	const accessories = useQuoteAccessories(quoteId);
	const dispatch = UsePortalDispatch();
	const savedQuoteWarnings = useSavedQuoteWarnings(quoteId);
	const quoteType = useQuoteTypeNameByQuoteId(quote?.id ?? 0);
	const isPsAdmin = useIsPowerShadesAdmin();

	if (!quote || !assemblies || !accessories || !dispatch || ("rework" === quoteType && !isPsAdmin)) {
		return [];
	}

	const checks = QuoteWarningVerificationsFromStore(assemblies, accessories, quote, dispatch);

	const unAcknowledged = checks.filter((c) => {
		const warningCode = c.warning_code();
		const warningMessage = c.warning();

		const found = savedQuoteWarnings.find((w) => w.code === warningCode && w.message === warningMessage);

		return !found && c.conditional();
	});

	return unAcknowledged;
};

export {
	UseUnAcknowledgedWarnings
};

export default QuoteWarningVerificationsFromStore;
