import { useState, useEffect } from "react";
import {
	Accordion,
	Container,
	Col,
	Row,
	Card,
	Form,
	InputGroup,
} from "react-bootstrap/";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faSpinner,
	faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Popup from "reactjs-popup";
import { saveAs } from "file-saver";
import * as Sentry from "@sentry/react";

import api from "../PowerShadesAPI";
import Button from "../Parts/Button";

const Reports = (props) => {
	const [filterText, setFilterText] = useState("");

	const [reports, setReports] = useState([]);
	const [salesPeople, setSalesPeople] = useState([]);
	const [fetchingReports, setFetchingReports] = useState(true);
	const [fetchingSalesPeople, setFetchingSalesPeople] = useState(true);
	const [submitting, setSubmitting] = useState(false);
	const [errorTitle, setErrorTitle] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [activeKey, setActiveKey] = useState("0");
	const [extras, setExtras] = useState([]);
	const [extraData, setExtraData] = useState("");

	/*
	Expected Fields
	{
		"start_date": "2022-07-25T20:02:07.724Z",
		"end_date": "2022-07-25T20:02:07.724Z",
		"sales_person_id": 0,
		"date_location": "string",
		"power_type": "string"
		}
	*/
	const [dataFields, setDataFields] = useState({
		start_date: "",
		end_date: "",
		sales_person_id: "",
		date_location: "",
		power_type: "",
	});

	const updateDataFields = (update) => {
		setDataFields({ ...dataFields, ...update });
	};

	const clearError = () => {
		setErrorTitle("");
		setErrorMessage("");
	};

	const clearExtras = () => {
		setExtras([]);
	};

	const setError = (errorTitle, errorMessage) => {
		setErrorTitle(errorTitle);
		setErrorMessage(errorMessage);
	};

	const sortByName = (x, y) => x.name.localeCompare(y.name);

	useEffect(() => {
		/* Example response
		{
			"name": "Sales Report",
			"key": "sales_report",
			"has_end_date": true,
			"has_start_date": true,
			"has_sales_person_select": true,
			"has_date_location": true,
			"has_power_type_select": false
		}
		*/
		props.setTitle("Generate Reports");

		api.getReports().then((resp) => {
			if (resp.status !== 200) {
				console.error(resp);
				throw new Error('Something went wrong while fetching reports. Please check the console logs for resp data.');
			}
			const tempReports = [...resp.data.reports];
			const sortedReports = tempReports.sort(sortByName);
			setReports([...sortedReports]);
			setFetchingReports(false);
		}).catch((err) => {
			console.error(err);
			throw new Error('Something went wrong while fetching reports. Please check the console logs for resp data.');
		});

		api.getSalespeople().then((resp) => {
			const tempSalesPeople = [...resp.data.salespeople];
			const sortedSalesPeople = tempSalesPeople.sort(sortByName);
			setSalesPeople(sortedSalesPeople);
			setFetchingSalesPeople(false);
		});
	}, []);

	useEffect(() => {
		if (!fetchingSalesPeople && !fetchingReports) {
			props.loaded();
		}
	}, [fetchingSalesPeople]);

	const salesPeopleSelect = salesPeople.map((s) => (
		<option key={s.id} value={s.id}>
			{s.name}
		</option>
	));

	const exportCsv = (arrayHeader, arrayData, delimiter, fileName) => {
		const header = `${arrayHeader.join(delimiter)}\n`;
		let csv = header;
		arrayData.forEach((array) => {
			csv += `${array.join(delimiter)}\n`;
		});
		const csvData = new Blob([csv], { type: "text/csv" });
		const csvUrl = URL.createObjectURL(csvData);
		saveAs(csvUrl, fileName);
		URL.revokeObjectURL(csvUrl);
	};

	const dateConverter = (date, startOrEnd) => {
		const nonFormattedDate = new Date(`${date.toString()}T00:00-0800`);
		let formattedDateTime = "";
		// 2012-04-23T18:25:43.511Z
		const dd1 = String(nonFormattedDate.getDate()).padStart(2, "0");
		const mm2 = String(nonFormattedDate.getMonth() + 1).padStart(2, "0"); // January is 0!
		const yyyy3 = nonFormattedDate.getFullYear();
		// T23:59:59
		switch (startOrEnd) {
			case "start":
				return (formattedDateTime = `${yyyy3}-${mm2}-${dd1}T00:00:00`);
				break;
			case "end":
				return (formattedDateTime = `${yyyy3}-${mm2}-${dd1}T23:59:59`);
		}
	};

	const handleSubmit = (clickedKey) => {
		setSubmitting(true);
		let hasError = false;
		let today = new Date();
		const dd = String(today.getDate()).padStart(2, "0");
		const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
		const yyyy = today.getFullYear();
		today = `${yyyy}-${mm}-${dd}`;

		const report = reports.find((r) => r.key == clickedKey);
		const body = {};

		/*
			Example payload
			{
			"start_date": "2022-07-28T18:13:20.870Z",
			"end_date": "2022-07-28T18:13:20.870Z",
			"sales_person_id": 0,
			"date_location": "string",
			"power_type": "string"
			} */

		// Error checking
		if (
			(report.has_end_date && dataFields.end_date === "")
				|| (report.has_start_date && dataFields.start_date === "")
		) {
			ROOT.alert({
				text: "Invalid Date", quickFormat: "error", text: "Date field(s) cannot be left empty."
			});
			hasError = true;
		}
		if (report.has_date_location && dataFields.date_location == "") {
			ROOT.alert({
				text: "Please designate whether you want the report ran on shipped or placed orders.", title: "Invalid Placement Status", quickFormat: "error"
			});
			hasError = true;
		}

		if (hasError) {
			setSubmitting(false);
			return;
		}
		// End of error Checking

		// Body Creation
		if (report.has_end_date) {
			body.end_date = dateConverter(dataFields.end_date, "end");
		}
		if (report.has_start_date) {
			body.start_date = dateConverter(dataFields.start_date, "start");
		}
		if (
			report.has_sales_person_select
					&& dataFields.sales_person_id !== ""
		) {
			body.sales_person_id = dataFields.sales_person_id;
		}
		if (report.has_date_location) {
			body.date_location = dataFields.date_location;
		}
		if (
			report.has_power_type_select
					&& dataFields.power_type_select !== ""
		) {
			body.power_type = dataFields.power_type;
		}
		// End of Body creation

		api.RunReport(clickedKey, body).then((resp) => {
			if (resp.status !== 200) {
				ROOT.alert({
					title: "Error", text: "Something went wrong running the report", quickFormat: "error"
				});
				throw new Error('Something went wrong running the report');
			}
			const { data } = resp;
			const dataNoHeader = data.data.shift();
			const arrayLength = data.data.length;
			// if data returned is none, create a popup
			if (arrayLength > 0) {
				exportCsv(
					dataNoHeader,
					data.data,
					',',
					report.name + dataFields.start_date + dataFields.end_date
				);
				setSubmitting(false);
			} else {
				ROOT.alert({
					title: "No Data", text: "No data found with the filters provided", quickFormat: "error"
				});
				setSubmitting(false);
				return;
			}
			// On success
			// Display "Extras" (flat list, the key is the title and the value is the goods)
			if (resp.status !== 200) {
				ROOT.alert({ quickFormat: 'error', text: 'There was an error running the report. Please try again.' });
				console.error(resp);
				throw new Error('Something went wrong generating the report. Please check console for resp data.');
			}
			const tempExtras = resp?.data?.extras;
			try {
				if (Object.keys(tempExtras).length > 0) {
					setExtraData(
						Object.entries(tempExtras).map(([key, value], i) => (
							<Row>
								<Col key={key + value}>
									{key}
									:
									{value}
								</Col>
							</Row>
						))
					);
					setExtras(tempExtras);
				}
			} catch (err) {
				console.error(err);
				Sentry.captureException('Something went wrong generating extra data from the report. Please check console for resp data.');
				setExtras({});
			}
			// Download csv from data
			// console.log(extraData);
		});
	};

	const accordionItems = reports.filter((r) => {
		const trimmedStr = filterText.trim().toUpperCase();

		return r.name
			.toUpperCase()
			.includes(trimmedStr);
	}).map((r, i) => {
		r.label = r.name;
		const { key } = r;

		const submitDisabled = (r.has_start_date && dataFields.start_date == "")
			|| (r.has_end_date && dataFields.end_date == "")
			|| (r.has_date_location && dataFields.date_location == "")
			|| (r.has_power_type_select && dataFields.power_type == "")
			|| (r.has_sales_person_select && dataFields.sales_person_id == "");

		return (
			<Accordion.Item eventKey={r.key}>
				<Accordion.Header>{r.name}</Accordion.Header>
				<Accordion.Body>
					<Card.Body>
						<InputGroup>
							<Container>
								<Row>
									<Col xs={6}>
										{r.has_start_date ? (
											<Row>
												<Form.Label htmlFor="start-date">
													Start Date
												</Form.Label>
												<Form.Control
													onChange={(e) => {
														updateDataFields({
															start_date: e.target.value,
														});
													}}
													value={dataFields.start_date ?? ''}
													placeholder="Start Date"
													type="date"
												/>
											</Row>
										) : null}
										{r.has_end_date ? (
											<Row className="mt-1">
												<Form.Label htmlFor="end-date">End Date</Form.Label>
												<Form.Control
													onChange={(e) => {
														updateDataFields({
															end_date: e.target.value,
														});
													}}
													value={dataFields.end_date ?? ''}
													placeholder="End Date"
													type="date"
												/>
											</Row>
										) : null}
										{r.has_date_location ? (
											<Row className="mt-1">
												<Col className="mt-1 p-0">
													<Form.Label htmlFor="date-location">
														Order Placed or Shipped?
													</Form.Label>
													<select
														className="custom-select"
														onChange={(e) => {
															updateDataFields({
																date_location: e.target.value,
															});
														}}
														value={dataFields.date_location ?? ''}
													>
														<option defaultValue>
															Select an option
														</option>
														<option title="Only on RMA Report" value="Active">Active</option>
														<option value="Placed">Placed</option>
														<option value="Shipped">Shipped</option>
													</select>
												</Col>
											</Row>
										) : null}
										{r.has_power_type_select ? (
											<Row className="mt-1">
												<Col className="mt-1 p-0">
													<Form.Label htmlFor="date-location">
														Shade Type
													</Form.Label>
													<select
														onChange={(e) => {
															updateDataFields({
																power_type: e.target.value,
															});
														}}
														value={dataFields.power_type ?? ''}
														className="custom-select"
													>
														<option defaultValue>
															Select an option
														</option>
														<option value="All">All</option>
														<option value="Motorized">Motorized</option>
														<option value="Manual">Manual</option>
													</select>
												</Col>
											</Row>
										) : null}
									</Col>
									<Col xs={6}>
										{r.has_sales_person_select ? (
											<Row>
												<Col>
													<Form.Label htmlFor="date-location">
														Sales Person
													</Form.Label>
													<select
														onChange={(e) => {
															updateDataFields({
																sales_person_id: e.target.value,
															});
														}}
														value={dataFields.sales_person_id}
														className="custom-select"
													>
														<option key="Default" value="">
															Select an option
														</option>
														<option key={0} value={0}>
															All
														</option>
														{salesPeopleSelect}
													</select>
												</Col>
											</Row>
										) : null}
									</Col>
								</Row>
								<Row>
									<Col className="text-end">
										<Button
											color="green"
											onClick={() => {
												handleSubmit(key);
											}}
											disabled={submitDisabled || submitting}
											title={
												submitDisabled
													? 'You must input data before submitting'
													: null
											}
										>
											{submitting ? (
												<FontAwesomeIcon
													spin
													color="black"
													icon={faSpinner}
												/>
											) : (
												'Submit'
											)}
										</Button>
									</Col>
								</Row>
							</Container>
						</InputGroup>
					</Card.Body>
				</Accordion.Body>
			</Accordion.Item>
		);
	});
	/*
		let accordionItems = reports.map((r, i) => {
		r.label = r.name;
		let key = r.key;
	*/

	return (
		<>
			{/* Error Popup */}
			<div className="local-bootstrap">
				<Popup
					className="mobile-modal"
					contentStyle={{ width: "50vh", height: "40vh" }}
					open={Object.keys(extras).length > 0}
					onClose={clearExtras}
				>
					<Container className="local-bootstrap">
						<Row className="mt-1">
							<Col>
								<h5 className="green-text">
									Your report request returned with the following
									extras:
								</h5>
							</Col>
						</Row>
						<Row>
							<Col>{extraData}</Col>
						</Row>
						<Container
							className="local-bootstrap"
							style={{
								position: "absolute",
								bottom: 0,
								left: 0,
								float: "right",
							}}
						>
							<Row
								style={{
									marginBottom: "1vh",
								}}
							>
								<Col className="text-end">
									<Button
										color="gray"
										onClick={clearExtras}
									>
										Close
									</Button>
								</Col>
							</Row>
						</Container>
					</Container>
				</Popup>
				{/* Extra's Popup */}
				<Popup
					contentStyle={{ width: "50vh", height: "40vh" }}
					open={errorMessage !== "" || errorTitle !== ""}
					onClose={() => clearError()}
				>
					<Container className="local-bootstrap">
						<Row style={{ marginBottom: "2vh", marginTop: "2vh" }}>
							<Col xs={12} className="text-center">
								<FontAwesomeIcon
									style={{ fontSize: "10vh" }}
									color="#e30000"
									icon={faTimesCircle}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<h5 className="green-text">{errorTitle}</h5>
							</Col>
						</Row>
						<Row>
							<Col>{errorMessage}</Col>
						</Row>
						<Container
							className="local-bootstrap"
							style={{
								position: "absolute",
								bottom: 0,
								left: 0,
								float: "right",
							}}
						>
							<Row
								style={{
									marginBottom: "1vh",
								}}
							>
								<Col className="text-end">
									<Button
										color="green"
										onClick={clearError}
									>
										Close
									</Button>
								</Col>
							</Row>
						</Container>
					</Container>
				</Popup>
				<Container>
					<Row className="mb-2">
						<Col xs={6}>
							<input
								className="form-control"
								placeholder="Search..."
								value={filterText}
								onChange={(e) => setFilterText(e.target.value)}
							/>
						</Col>
					</Row>
					<Accordion className="reports-accordion">{accordionItems}</Accordion>
				</Container>
			</div>
		</>
	);
};

export default Reports;
