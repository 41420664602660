/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { PortalShadeOption } from "../../Store/entities/assemblies/types";
import { GroupHeader, OptionLabel } from "../../Parts/FormParts";
import "./ColorPaletteModal.scss";
import ColorOptionsAccordion from "./ColorOptionsAccordion";

type ColorPaletteModalProps = {
	colors: PortalShadeOption<string>[];
	colorPalettes: PortalShadeOption<string>;
	errorClass: (option: PortalShadeOption<any>) => "border border-danger" | "";
	quoteType: string;
};

const ColorPaletteModal = ({ colors, colorPalettes, errorClass, quoteType }: ColorPaletteModalProps) => {
	const { value: colorPaletteValue } = colorPalettes.getSelectedOption();
	
	const [palettesState, setPalettesState] = useState(colorPalettes);

	useEffect(() => {
		const initialPalettes = palettesState;
		setPalettesState(colorPaletteValue === "Custom Palette" ? initialPalettes : colorPalettes);
	}, [colorPaletteValue]);
	
	return (
		<Col className="color-palette-modal">
			{GroupHeader("Base Color Palette", quoteType)}

			<Row className="p-2">
				{OptionLabel(colorPalettes, false)}
				<ColorOptionsAccordion
					title="Base Color Palette"
					options={
						(palettesState.options || colorPalettes.options || [])
							.filter((palette) => palette.value !== "Custom Palette") as { value: string }[]
					}
					selectedOption={palettesState.getSelectedOption()}
					onSelect={(value) => colorPalettes.setValue(value)}
					colorPalettes={colorPalettes}
					className={errorClass(colorPalettes)}
				/>
			</Row>
			{GroupHeader("Color Overrides", quoteType)}
			{colors
				.filter((color) => color.label !== "Not Set")
				.sort((a, b) => a.label.localeCompare(b.label))
				.map((color) => {
					const defaultColor = palettesState.getSelectedOption().default_colors?.[color.label];
					return <Row key={color.varName} className="p-2">
						{OptionLabel(color, false)}
						<ColorOptionsAccordion
							title={color.varName}
							defaultColor={defaultColor as string}
							options={color.options || []}
							selectedOption={color.getSelectedOption()}
							onSelect={color.setValue}
							colorPalettes={palettesState}
							className={
								palettesState.getValue() !== "Custom Palette"
								&& defaultColor
								&& defaultColor !== "Missing Default Color"
								&& color.getValue() !== defaultColor
									? "border-primary border-3 rounded-3"
									: ""
							}
						/>
					</Row>;
				})}

		</Col>
	);
};

export default ColorPaletteModal;
