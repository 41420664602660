import React from 'react';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faFileContract } from '@fortawesome/free-solid-svg-icons';

import TextDivider from '../../Parts/TextDivider';
import { useIsPsAdmin } from '../../hooks';

import PaymentMethodButton from './PaymentMethodButton';
import PaymentStep from './PaymentStep';
import { useQuoteTypeNameByQuoteId } from '../../Store/entities/quotes/hooks';

/* eslint-disable camelcase */

const SelectPaymentMethod = ({
	quoteColor,
	quote,
	paymentMethodSelected,
	setAdminPayment,
	hideNext,
	hasCard,
	hasTerms,
	quoteType
}) => {
	const isPsAdmin = useIsPsAdmin();

	const quoteId = quote.id ;

	const PAYMENT_METHODS = {
		dealer_terms: (
			<PaymentMethodButton
				quoteColor={quoteColor}
				key="ct"
				icon={faFileContract}
				text="Dealer Terms"
				style={{ marginRight: '0.5em' }}
				onClick={() => {
					api.dealerTermsPressed(quoteId).then(() => {
						console.log('dealerTermsPressed');
					});

					paymentMethodSelected('dealer_terms');
				}}
			/>
		),

		card: (
			<PaymentMethodButton
				quoteColor={quoteColor}
				key="card"
				icon={faCreditCard}
				text="Card/ACH"
				style={{ marginRight: '0.5em' }}
				onClick={() => {
					api.ccAchPressed(quoteId).then(() => {
						console.log('ccAchPressed');
					});
					paymentMethodSelected('card');
				}}
			/>
		),

		ps_admin: (
			<PaymentMethodButton
				quoteColor={quoteColor}
				key="psaAdmin"
				icon={faCheck}
				text="PowerShades Admin"
				onClick={() => {
					api.psAdminPressed(quoteId).then(() => {
						console.log('psAdminPressed');
					});
					setAdminPayment(true);
				}}
			/>
		),

		rma_override: (
			<div title='Coming Soon'>
				<PaymentMethodButton
					quoteColor={quoteColor}
					key="rma"
					icon={faCheck}
					text="RMA Override"
					onClick={() => {
						// api.rmaOverridePressed(quoteId).then(() => {
						// 	console.log('rmaOverridePressed');
						// });
						paymentMethodSelected('rma_override');
					}}
				/>
			</div>
		)
	};

	const payment_options = [];

	if (!isPsAdmin) {
		parseInt(quote.require_payment, 10)
			|| (hasTerms && payment_options.push(PAYMENT_METHODS.dealer_terms));
		hasCard && payment_options.push(PAYMENT_METHODS.card);
	} else {
		payment_options.push(PAYMENT_METHODS.ps_admin);
	}

	if (isPsAdmin && quoteType === "rma") {
		payment_options.push(PAYMENT_METHODS.rma_override);

	}
	hideNext();

	return (
		<PaymentStep>
			<TextDivider>
				<h3 className={`text-${quoteType}`} style={{ fontWeight: 'normal' }}>
					Pay With
				</h3>
			</TextDivider>

			<div style={{ display: 'flex', flexDirection: 'row' }}>
				{payment_options.length > 0 ? (
					payment_options
				) : (
					<div>
						<h3
							className={`text-${quoteType}`}
							style={{ marginBottom: '5px', fontWeight: 'normal' }}
						>
							No Payment Methods Available
						</h3>
						<span style={{ fontWeight: 'normal' }}>
							Please contact your representative or PowerShades directly.
						</span>
					</div>
				)}
			</div>

			{parseInt(quote.require_payment, 10) ? (
				<span style={{ fontWeight: 'normal' }}>
					<br />
					<b>Dealer Credit Terms have been declined for this order.</b>
				</span>
			) : null}
		</PaymentStep>
	);
};

export default SelectPaymentMethod;
