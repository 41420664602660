import { useState } from 'react';
import { FormSelect } from "react-bootstrap";

import { useStates } from '../Quote/QuoteGlobals';

const StateSelector = (props) => {
	const states = useStates();
	const [value, setValue] = useState(props.defaultValue || null);

	function valueChanged(new_value) {
		new_value = parseInt(new_value);

		props.onChange && props.onChange(new_value);

		setValue(new_value);
	}

	const filtered_props = { ...props };

	['wrapperStyle', 'labelStyle', 'defaultValue']
		.forEach((key) => delete filtered_props[key]);
		
	const countries = states.reduce(
		(acc, state) =>
			(acc.includes(state.country_name) ? acc : [...acc, state.country_name]),
		[]
	);

	const options = countries
		.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
		.map((country) => (

			<optgroup key={country} label={country}>

				{
					states
						.filter((state) => state.country_name === country)
						.map((state) => <option key={state.id} value={state.id}>{ state.name }</option>)
				}

			</optgroup>
		));

	return (
		<>
			{props.bootstrap ? (
				<FormSelect
					name={props.label}
					{...filtered_props}
					value={value || ""}
					onChange={(e) => valueChanged(e.target.value)}
					size="sm"
				>
					{options}
				</FormSelect>
			) : (
				<div style={{ position: "relative", ...props.wrapperStyle }}>
					{props.label ? (
						<>
							<label htmlFor={props.label} style={props.labelStyle}>
								{props.label}
							</label>
							<br />
						</>
					) : null}

					<select
						name={props.label}
						{...filtered_props}
						value={value || ""}
						onChange={(e) => valueChanged(e.target.value)}
					>
						<option value="">- Select State -</option>
						{options}
					</select>

					{props.required != undefined ? (
						<span
							style={{
								display: "inline-block",
								position: "absolute",
								color: "red",
								top: "2px",
								right: "0",
							}}
						>
							*
						</span>
					) : null}
				</div>
			)}
		</>
	);
};

export default StateSelector;
