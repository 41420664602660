import { Card, Accordion, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import AddressSummary from './AddressSummary';
import Button from '../Parts/Button';

const AddressSummaryBlock = ({
	address,
	onEdit,
	showEdit,
	title,
	quoteType,
	isPropertyAddress
}) => {
	const [activeKey, setActiveKey] = useState({ title });

	const humanize = (str) => {
		let i;
		const frags = str.split('_');
		for (i = 0; i < frags.length; i++) {
			frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
		}
		return frags.join(' ');
	};

	const checkMissing = () => {
		const installationAddressRequiredKeys = [
			'company_name',
			'address',
			'county_subdivision_id',
			'city',
			'zip',
		];
		const shippingAddressRequiredKeys = [
			'company_name',
			'first_name',
			'last_name',
			'is_residential',
			'lift_gate',
			'address',
			'county_subdivision_id',
			'city',
			'zip',
		];
		let missingFields = [];
		const workingAddress = Object.entries(address ?? {});

		// ! This needs to be rewritten at some point using ES6, but works for the time being...

		if (workingAddress.length > 0) {
			for (const [key, value] of workingAddress) {
				if (title === 'Installation Address') {
					if (installationAddressRequiredKeys.includes(key)) {
						if (value === null || value === '') {
							missingFields.push(key);
						}
					}
				} else if (shippingAddressRequiredKeys.includes(key)) {
					if (value === null || value === '') {
						missingFields.push(key);
					}
				}
			}
		} else {
			missingFields = ['Missing all Fields'];
		}
		// missingFields.forEach((i) => console.log(humanize(i)));

		// console.log(missingFields);
		return missingFields;
	};
	/*
	let labeledKeys = tempKeys.map((keyName) => ({
				label: keyName,
				value: keyName,
				key: keyName,
			}));
	*/
	const missingFields = checkMissing();
	const missing = missingFields.length > 0;

	const prettyMissingFields = missingFields.map((mf) => {
		const prettyMF = humanize(mf);
		return (
			<Col key={mf}>
				<span className="text-danger">{prettyMF}</span>
			</Col>
		);
	});

	return (
		<Accordion className={`h-100 local-bootstrap accordion-${quoteType.trim()}`}>
			<Card className="h-100">
				<Accordion.Item
					className="d-lg-none"
					eventKey={title}
					onClick={() => {
						title === activeKey ? setActiveKey(undefined) : setActiveKey(title);
					}}
				>
					<Accordion.Header>
						<Col xs={10}>
							<h4
								className={missing ? 'text-danger' : `text-${quoteType.trim()}`}
							>
								{title}
							</h4>
						</Col>
						<Col className="text-end" xs={1}>
							{missing ? (
								<FontAwesomeIcon
									size="lg"
									title="Missing required data"
									className="text-danger"
									icon={faExclamationTriangle}
								/>
							) : null}
						</Col>
					</Accordion.Header>
				</Accordion.Item>
				<Card.Header className="d-none d-lg-block">
					<Row className="mt-2">
						<Col xs={10}>
							<h4
								className={missing ? 'text-danger' : `text-${quoteType.trim()}`}
							>
								{title}
							</h4>
						</Col>
						<Col xs={2}>
							{missing ? (
								<FontAwesomeIcon
									size="lg"
									title="Missing required data"
									className="text-danger"
									icon={faExclamationTriangle}
								/>
							) : null}
						</Col>
					</Row>
				</Card.Header>
				<Accordion.Collapse eventKey={title} className="d-lg-block">
					<>
						<Row>
							<AddressSummary
								isPropertyAddress={isPropertyAddress ?? false}
								address={address}
							/>
						</Row>
						<Col className="mb-2">
							<Row className={missing ? 'd-block' : 'd-none'}>
								<Col className="m-2">
									<Row>
										<Col>
											<h5 className="text-danger">Missing:</h5>
										</Col>
									</Row>
									<Row>{prettyMissingFields}</Row>
								</Col>
							</Row>
						</Col>
						{showEdit ? (
							<Row className="bottom-row">
								<Col className="text-end">
									<Button
										className="mb-2"
										size="sm"
										color={quoteType ?? 'green'}
										onClick={onEdit}
									>
										Edit
									</Button>
								</Col>
							</Row>
						) : null}
					</>
				</Accordion.Collapse>
			</Card>
		</Accordion>
	);
};

export default AddressSummaryBlock;
