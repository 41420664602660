import React from 'react';

import { isFunction } from 'lodash';

class SelectField extends React.Component {
	constructor(props) {
		super(props);

		const { option, keepOldStyle } = props;

		this.props = props;

		const value = String(this.props.value).endsWith("_B") ? this.props.value.substring(0, this.props.value.length - 2) : this.props.value;

		this.state = {
			value
		};

		this.changed = this.changed.bind(this);
	}

	getChoice(val) {
		let choice = null;
		let choices = null;

		const { option } = this.props;

		if (typeof option.choices === 'function') {
			if (option.shade) {
				choices = option.choices(option.shade);
			} else {
				choices = option.choices();
			}
		} else {
			choices = option.choices;
		}

		choices.forEach((c) => {
			if ((c.value && c.value == val) || (c.name == val)) {
				choice = c;
			}
		});

		return choice;
	}

	changed(new_val) {
		const { props } = this;

		const current_choice = this.getChoice(this.state.value);
		const new_choice = this.getChoice(new_val);

		current_choice && current_choice.onDeselect && current_choice.onDeselect(props.quote, props.shade);
		new_choice && new_choice.onSelect && new_choice.onSelect(props.quote, props.shade);

		// Update the state so the changes are reflected in the view.
		this.setState({
			value: new_val
		});

		props.onChange(new_val);
		props.onBlur();
	}

	getChoices(option) {
		const choices = [];
		let choice_arr = [];

		if (isFunction(option.choices)) {
			if (option.shade) {
				choice_arr = option.choices(option.shade);
			} else {
				choice_arr = option.choices();
			}
		} else {
			choice_arr = option.choices;
		}

		return choice_arr;
	}

	render() {
		const choices = [];
		const choice_arr = this.getChoices(this.props.option);

		const { value } = this.state;
		const { props } = this;
		const { option } = props;
		const { handleEnter } = this.props;

		const found_choice = choice_arr.filter((choice) => !option.shade || (!(choice.conditional != null && (!choice.conditional(option.shade))))).find((choice) => (choice.value != null ? choice.value : choice.name) == value);

		if (!found_choice && value != null && (`${value}`).length > 0) {
			const found_choice_2 = choice_arr.find((choice) => (choice.value != null ? choice.value : choice.name) == value);

			const o = found_choice_2 || option;

			choices.push(
				<option
					key={`old-val ${o.value}`}
					value={o.value}
				>
					{o.name || o.value}
				</option>
			);
		}

		if (value == null
            || (option.varName == 'fabric2_name' && option.value == '')) {
			choices.push(
				<option key="null-val-selector" value="">-</option>
			);
		}

		let choiceGroup = [];
		let choiceGroupName = '';

		choice_arr.filter((choice) => !option.shade || (!(choice.conditional != null && ((!option.shade) || !choice.conditional(option.shade))))).forEach((choice) => {
			const is_disabled = choice.conditional != null && (!choice.conditional(option.shade));

			if ((choiceGroup != null & choice.optGroup == null) | choiceGroupName != choice.optGroup) {
				if (choiceGroup.length > 0) {
					choices.push(
						<optgroup key={`choice-group-${choiceGroupName}`} label={choiceGroupName}>
							{choiceGroup}
						</optgroup>
					);
				}

				choiceGroup = [];
			}

			if (choice.optGroup != null) {
				choiceGroupName = choice.optGroup;

				choiceGroup.push(
					<option key={(choice.id ? choice.id : choice.name)} value={(choice.value != null ? choice.value : choice.name)} disabled={is_disabled}>{choice.name}</option>
				);
			} else {
				choices.push(
					<option key={(choice.id ? choice.id : choice.name)} value={(choice.value != null ? choice.value : choice.name)} disabled={is_disabled}>{choice.name}</option>
				);
			}
		});

		if (choiceGroup.length > 0) {
			choices.push(
				<optgroup key={`choice-group-${choiceGroupName}`} label={choiceGroupName}>
					{choiceGroup}
				</optgroup>
			);
		}

		return (

			<select
				className={props.keepOldStyle ? '' : 'form-select'}
				onKeyDown={(e) => handleEnter(e)}
				disabled={props.disabled}
				onBlur={props.onBlur}
				onFocus={props.onFocus}
				onChange={(e) => this.changed(e.target.value)}
				value={value || ''}
				style={{ minWidth: "55%" }}
			>

				{choices}

			</select>
		);
	}
}

export default SelectField;
