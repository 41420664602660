import Popup from 'reactjs-popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import Fraction from 'fraction.js';
import { isArray, replace, capitalize } from 'lodash';
import React from 'react';

import IndoorShadeFormFields from '../NewOptionFields/ShadeActions/IndoorShadeOptionsTwo';
import OptionField from '../NewOptionFields/OptionField';
import SelectBox from "./SelectBox";
import ShadeRowNew from './ShadeRowNew';
import ShadeActions from '../NewOptionFields/ShadeActions';
import QuoteAssemblyLockSelect from '../QuoteAssemblyLockSelect';
import AccessoryRow from './AccessoryRow';
import UnChangeableCell from './UnChangableCell';
import AccessoryQuantity from './AccessoryQuantity';
import TableComponent from './TableComponent';
import OutdoorShadeFormFields from '../NewOptionFields/ShadeActions/OutdoorShadeOptionsTwo';
import AccessoryAction from '../NewOptionFields/AccessoryActions';
import MassShadeActions from '../NewOptionFields/MassShadeActions';
import { checkIfQuoteHasAllSingleShades } from "../QuoteObjectExtensions.ts";
import Button from '../../Parts/Button';
import { selectQuoteAccessories } from '../../Store/entities/accessories/hooks';
import { deleteAssemblies, repriceAssemblies, setAssembliesSelected, setAssembliesUnlocked } from '../../Store/entities/assemblies';
import { captureSentryError } from '../../psUtil';
import { SelectIndoorFabrics } from '../../Store/entities/fabrics';
import PricingUI from '../../PricingUI';
import PriceErrors from '../PriceErrors';
import { selectAssemblyListByQuoteId } from '../../Store/entities/assemblies/hooks';

// TODO LIST:
// ! Convert to Typescript

class QuoteUI {
	constructor({
		QuoteFunctions, onDone, quoteLevelVariables
	}) {
		this.QuoteFunctions = QuoteFunctions;

		this.IndoorShadeOptions = IndoorShadeFormFields(this);
		this.OutdoorShadeOptions = OutdoorShadeFormFields(this);
		this.EditRow = this.EditRow.bind(this);

		const isSelected = () => { };

		const getAssemblyId = (assembly) => assembly.sequence_id;
		const getAllShadeIds = (assembly) => assembly.shades.map((s) => s.id);
		const getFrontIds = (assembly) => assembly.shades.filter((s) => s.row_coordinate === 0).map((s) => s.id);
		const getBackIds = (assembly) => assembly.shades.filter((s) => s.row_coordinate === 1).map((s) => s.id);

		this.onDone = onDone;

		const self = this;

		this.IndoorShadeColumns = () => {
			const shadeData = this.Data.shadeAssemblies.Items;
			const hasAllSingle = checkIfQuoteHasAllSingleShades(shadeData);

			const priceItem = self?.Money?.currentMultiplierName;

			let priceName = "MSRP";

			if (priceItem) {
				priceName = priceItem == 'buy' ? "My Price" : (priceItem == 'bidspec' ? "BidSell" : "MSRP");
			}

			const columns = [
				{
					name: "select",
					display: "Unlock/Select",
					dataReference: "select",
					onChange: isSelected,
					displayType: "select",
				},
				{
					name: "roomName",
					display: "Room Name",
					dataReference: "room_name",
					onChange: isSelected,
					displayType: "text",

				},
				{
					name: "shadeName",
					display: "Shade Name",
					dataReference: "shade_name",
					onChange: isSelected,
					displayType: "text",
				},
				{
					name: "shadeType",
					display: "Shade Type",
					dataReference: "single_dual",
					onChange: isSelected,
					displayType: "text",
				},
				{
					name: "insideFabric",
					display: hasAllSingle ? "Fabric" : "Inside Fabric",
					dataReference: "fabric_name",
					onChange: isSelected,
					displayType: "select",

				},

			];
			if (!hasAllSingle) {
				columns.push({

					name: "outsideFabric",
					display: "Outside Fabric",
					dataReference: "fabric2_name",
					realDataReference: "fabric_name",
					getValue: (assembly) => {
						const shade = assembly.shades.find((a) => a.row_coordinate == 1);
						if (shade) {
							return shade.fabric_name;
						}
						return null;
					},
					onChange: isSelected,
					displayType: "select",

				});
			}
			columns.push(
				{
					name: "width",
					display: "Width",
					dataReference: "width",
					onChange: isSelected,
					displayType: "select",
					saveType: this.BothShadeNum,

				},
				{
					name: "height",
					display: "Height",
					dataReference: "height",
					onChange: isSelected,
					displayType: "select",
					saveType: this.BothShadeNum,
				},
				{
					name: "money",
					display: (props) => (props.ISO ? `${priceName} (${props.ISO})` : `${priceName}`),
					dataReference: "msrp",
					onChange: isSelected,

				}
			);

			const isAdmin = (function (userData) {
				const user = userData;

				const isAdmin = userData ? userData.roles.some((u) => u.role_name === 'powershades_admin') : false;

				return isAdmin;
			}(this.Data.userData));

			if (isAdmin) {
				columns.push({
					name: "shipping",
					display: "Shipping",
					dataReference: "shipping",
					onChange: isSelected,

				});
			}

			columns.push({
				name: "actions",
				display: "Actions",
				dataReference: "actions",
				onChange: isSelected,

			});

			const { quoteSub, editable } = this.quoteLevelVariables();

			if (quoteSub.is_order && !editable) {
				const { length } = columns;
				columns.splice(0, 1);
			}

			return columns;
		};

		this.OutdoorShadeColumns = () => {
			const priceItem = self?.Money?.currentMultiplierName;

			let priceName = "MSRP";

			if (priceItem) {
				priceName = priceItem == 'buy' ? "My Price" : "MSRP";
			}

			const columns = [
				{
					name: "select",
					display: "Select",
					dataReference: "select",
					onChange: isSelected,
					displayType: "select",
					// getId: getAssemblyId,
					// saveType: this.SelectNum

				},
				{
					name: "roomName",
					display: "Room Name",
					dataReference: "room_name",
					onChange: isSelected,
					displayType: "text",
					// getId: getAssemblyId,
					// saveType: this.AssemblyNum

				},
				{
					name: "shadeName",
					display: "Shade Name",
					dataReference: "shade_name",
					onChange: isSelected,
					displayType: "text",
					// getId: getAssemblyId,
					// saveType: this.AssemblyNum

				},
				{
					name: "direction",
					display: "Direction",
					dataReference: "direction_facing",
					onChange: isSelected,
					displayType: "select",
					// getId: getAssemblyId,
					// saveType: this.AssemblyNum

				},
				{
					name: "insideFabric",
					display: "Inside Fabric",
					dataReference: "fabric_name",
					onChange: isSelected,
					displayType: "select",
					// getId: getFrontIds,
					// saveType: this.ShadeNum

				},
				{
					name: "sideChannels",
					display: "Side Channels",
					dataReference: "side_channels",
					onChange: isSelected,
					displayType: "select",
					// getId: getAssemblyId,
					// saveType: this.AssemblyNum

				},
				{
					name: "width",
					display: "Width",
					dataReference: "width",
					onChange: isSelected,
					displayType: "select",
					// getId: getAllShadeIds,
					// saveType: this.DualShadeNum

				},
				{
					name: "height",
					display: "Height",
					dataReference: "height",
					onChange: isSelected,
					displayType: "select",
					// getId: getAllShadeIds,
					// saveType: this.DualShadeNum

				},
				{
					name: "money",
					display: (props) => (props.ISO ? `${priceName} (${props.ISO})` : `${priceName}`),
					dataReference: "msrp",
					onChange: isSelected,
					displayType: "select",
					// getId: getAssemblyId,
					// saveType: this.AssemblyNum

				},
				{
					name: "actions",
					display: "Actions",
					dataReference: "actions",
					onChange: isSelected,
					displayType: "select",
					// getId: getAssemblyId,
					// saveType: this.AssemblyNum

				},

			];

			return columns;
		};

		this.AccessoryColumns = () => {
			const priceItem = self?.Money?.currentMultiplierName;

			let priceName = "MSRP";

			if (priceItem) {
				priceName = priceItem == 'buy' ? "My Price" : "MSRP";
			}

			const columns = [

				{
					name: "name",
					display: "Name",
					dataReference: "name",
					customCol: (qa) => `${qa.name} x ${qa.quantity}`
				},
				{
					name: "part_number",
					display: "Part Number",
					dataReference: "part_number",
				},
				{
					name: "msrp",
					display: (props) => (props.ISO ? `${priceName} (${props.ISO})` : `${priceName}`),
					dataReference: "msrp",
				},
				{
					name: "total",
					display: (props) => (props.ISO ? `Total ${priceName} (${props.ISO})` : `Total ${priceName}`),
					dataReference: "total",
				},

			];

			const isAdmin = (function (userData) {
				const user = userData;

				const isAdmin = userData ? userData.roles.some((u) => u.role_id === 8) : false;

				return isAdmin;
			}(this.Data.userData));

			if (isAdmin) {
				columns.push({
					name: "shipping",
					display: "Shipping",
					dataReference: "shipping",
				});
			}

			const { editable } = this.quoteLevelVariables();

			if (editable) {
				columns.push(
					{
						name: "actions",
						display: "Actions",
						dataReference: "actions",
					}
				);
			}

			return columns;
		};

		this.AllCells = {};

		this.quoteLevelVariables = quoteLevelVariables;

		this.ValueChanged = this.ValueChanged.bind(this);
		this.BoxSelected = this.BoxSelected.bind(this);
		this.hasChainsBanned = this.hasChainsBanned.bind(this);
	}

	get FabricSampleColumns() {
		const columns = [
			{
				name: 'name',
				display: 'Fabric Name',
				dataReference: "name"
			},
			{
				name: 'quantity',
				display: 'Quantity',
				dataReference: "quantity"
			},
			{
				name: 'msrp',
				display: (props) => (props.ISO ? `Price (${props.ISO})` : "Price"),
				dataReference: "msrp"
			}
		];

		const { editable } = this.quoteLevelVariables();

		if (editable) {
			columns.add({
				name: 'delete',
				display: 'Actions',
				dataReference: "delete"
			});
		}

		return columns;
	}

	get ShadeNum() {
		return 1;
	}

	get AssemblyNum() {
		return 2;
	}

	get AccessoryNum() {
		return 3;
	}

	get FabricNum() {
		return 4;
	}

	get DualShadeNum() {
		return 5;
	}

	get SelectNum() {
		return 6;
	}

	get IsDualNum() {
		return 7;
	}

	get IsCoupled() {
		return 8;
	}

	get IsDualOrCoupled() {
		return 9;
	}

	get IsWidth() {
		return 10;
	}

	get QuoteType() {
		return this.quoteLevelVariables().quoteType;
	}

	Set({ Money, Data }) {
		this.Data = Data;
		this.Money = Money;
	}

	IndoorTable(indoorMoney) {
		const indoorColumns = this.IndoorShadeColumns();

		const rows = this.IndoorRows(indoorMoney);

		/*
<span onClick={() => !unlocked ? () => { } : this.BoxSelected(shade.sequenceId, !item)} className="fa-layers fa-fw">
									<FontAwesomeIcon className={classNameUnlock} transform='grow-10 right-4' icon="lock-open" />
									{unlocked ? null : <FontAwesomeIcon className={classNameLock} transform='grow-10' icon="lock" />}
									{!item ? null : <FontAwesomeIcon icon="check" transform="shrink-4 right-1 down-6" color="blue" />}
								</span>

								<button 
				onClick={onIndoorAddClick}
			>Unlock All Shades </button>
		*/

		const { editable, store } = this.quoteLevelVariables();
		const allIndoorAssys = this.Data.shadeAssemblies.Items
			.filter(sa => sa.shade_type_id == 1);

		const allIndoorSeqIds = allIndoorAssys
			.map((sa) => sa.sequence_id);


		const onIndoorAddClick = () => {
			store.dispatch(setAssembliesUnlocked({ quoteId: this.quoteLevelVariables().quoteSub.ID, unlocked: true, sequenceIds: allIndoorSeqIds }));
		};

		const areAllAssembliesOpen = allIndoorAssys
			.reduce((unlocked, sa) => unlocked && (sa.unlocked ?? false), true);
		// lock-open
		const icon = areAllAssembliesOpen ? faLockOpen : faLock;

		const titleText = !areAllAssembliesOpen ? "Unlock All Shades" : "All Shades are already unlocked.";

		const buttonAdd = editable ? (
			<span title={`${titleText}`} onClick={() => (areAllAssembliesOpen ? () => { } : onIndoorAddClick())} className=" fa-fw">
				<FontAwesomeIcon className="icon-unlock unlock-all" transform="x-12 grow-6" icon={icon} />
			</span>

		) : null;

		const table = (
			<TableComponent
				quoteType={this.QuoteType}
				UpdateIso={this.Money.UpdateIso}
				ISO={this.Money.ISO}
				columns={indoorColumns}
				rows={rows}
				title="Indoor Shades"
				buttonAdd={buttonAdd}
			/>
		);

		return table;
	}

	// Indoor rows
	IndoorRows(indoorMoney) {
		const self = this;
		const columns = this.IndoorShadeColumns();

		const indoorShadeAssemblyRows = this.Data.IndoorShades.sort((s1, s2) => s1.series_id - s2.series_id).map((sa) => {
			const moneys = indoorMoney.filter((m) => m.sequenceId === sa.sequence_id);
			const msrp = moneys.find((m) => m.Type.includes("msrp"))?.Component;
			const shipping = moneys.find((m) => m.Type.includes("shipping"))?.Component;

			const shade = self.Data.shadeSubstitute(sa.sequence_id);
			return self.Row({
				money: msrp,
				shipping,
				shadeAssembly: sa,
				shade
			}, columns, self.IndoorShadeOptions);
		});

		this.IndoorShadeComponents = indoorShadeAssemblyRows;

		return this.IndoorShadeComponents;
	}

	OutdoorRows(outdoorMoney) {
		const self = this;
		const columns = this.OutdoorShadeColumns();

		const ourdoorShadeAssemblyRows = this.Data.OutdoorShades.sort((s1, s2) => s1.series_id - s2.series_id).map((sa) => {
			const shade = self.Data.shadeSubstitute(sa.sequence_id);
			return self.Row({
				money: outdoorMoney.find((s) => s.sequenceId === sa.sequence_id && s.Type.includes("msrp"))?.Component,
				shadeAssembly: sa,
				shade
			}, columns, self.OutdoorShadeOptions);
		});

		this.OutdoorShadeComponents = ourdoorShadeAssemblyRows;

		return this.OutdoorShadeComponents;
	}

	OutdoorTable(outdoorMoneys) {
		const outdoorColumns = this.OutdoorShadeColumns();

		const rowss = this.OutdoorRows(outdoorMoneys);

		const quoteVariables = this.quoteLevelVariables;

		return (
			<TableComponent
				UpdateIso={this.Money.UpdateIso}
				ISO={this.Money.ISO}
				columns={outdoorColumns}
				rows={rowss}
				title="Outdoor Shades"
				quoteType={quoteVariables().quoteType}
			/>
		);
	}

	setHooks(hooks) {
		this.hooks = hooks;
	}

	SelectStatus(sequenceId) {
		const {
			editable, quoteSub, quoteColor, editScreen, store,
		} = (this.quoteLevelVariables && this.quoteLevelVariables()) ?? {};

		const storeShade = store.getState()?.entity?.assemblies[quoteSub.ID]?.Items[sequenceId] ?? {};

		return storeShade.selected ?? false;
		const shades = (this.hooks
			&& this.hooks.selectedShades
			&& this.hooks.selectedShades());

		return (shades ?? []).includes(sequenceId);
	}

	Row(items, columns, options) {
		const {
			money, shadeAssembly, shipping, shade
		} = items;
		let cell = {};
		const row = [];
		let option = null;

		const quoteVariables = this.quoteLevelVariables;

		const {
			editable, quoteSub, quoteColor, editScreen, store,
		} = (quoteVariables && quoteVariables()) ?? {};

		const storeState = store?.getState();

		const storeShade = storeState?.entity?.assemblies[quoteSub.ID]?.Items[shadeAssembly.sequence_id] ?? {};
		const unlocked = storeShade.unlocked ?? false;

		const isOld = shade.needsRepricing();

		const errors = [];

		const isAdmin = (function (userData) {
			const user = userData;

			const isAdmin = userData ? userData.roles.some((ur) => ur.role_name === 'powershades_admin') : false;

			return isAdmin;
		}(this.Data.userData));
		const sErrorsD = shade?.getErrors() ?? [];
		const sErrors = sErrorsD.filter(sE => sE !== "Sorry, something went wrong.")

		const hasErrors = (storeShade.msrp == 0 || (sErrors?.length ?? 0) > 0);
		const willBeSeamed = shade.val('will_be_seamed') == 1;
		const hasSagWarning = shade.val('has_sag_warning') == 1;


		const motor = shade.val('motor');

		const hasLargeOutdoorMotor = motor == '45 mm Electronic Motor 50/13, 120V/60Hz, star head' && isAdmin;

		// TODO: Change this to the redux method
		const hasServiceAccess = (function (userData) {
			const user = userData;

			const isService = userData ? userData.is_service === true : false;
			const isEngineer = userData ? userData.is_engineering === true : false;

			return isService || isEngineer;
		}(this.Data.userData));

		const repriceShadeOrDont = () => {
			if (unlocked) {
				store.dispatch(repriceAssemblies({ quoteId: quoteSub.ID, sequenceIds: [shadeAssembly.sequence_id] }));

			}
		}

		const err = sErrors.length > 0 && sErrors[0] != "Sorry, something went wrong." ? sErrors.join(', ') : storeShade.error_messages ?? '';

		columns.forEach((c) => {
			const varName = c.dataReference;

			if (varName == `msrp`) {
				option = null;
				cell = (hasErrors ? (
					<Popup
						trigger={<span
							onClick={repriceShadeOrDont}
						>Error</span>}
						position="right center"
						on="hover"
					>
						<div>
							<h6>{`${err}`}</h6>
						</div>
					</Popup>
				) : <PricingUI msrp={shadeAssembly.msrp} quoteId={quoteSub.ID} assembly={shadeAssembly} />);

				// if (hasErrors) {
				// 	cell = (<div
				// 		onClick={repriceShadeOrDont}
				// 	>
				// 		Errors
				// 	</div>)
				// }
			} else if (varName == "select") {
				option = null;
				const item = this.SelectStatus(shadeAssembly.sequence_id);
				cell = this.GetSelectBox(shadeAssembly, this.BoxSelected, item, !editable);

				let classNameUnlock = unlocked ? '' : `icon-lock ${this.QuoteType.toLowerCase()}`;
				let classNameLock = unlocked ? ' ' : `icon-unlock ${this.QuoteType.toLowerCase()}`;

				if (isOld) {
					classNameLock += ' old';
					classNameUnlock += ' old';
				}

				// const selected = () => {
				// 	if(!editable)
				// 	{
				// 		editScreen(shade.sequenceId + 1)
				// 	} else if(unlocked)	{
				// 		this.quoteLevelVariables().store.dispatch(setAssemblySelected({quoteId: quoteSub.ID, sequenceId: shade.sequenceId, selected: !item}));
				// 	} else {
				// 		this.quoteLevelVariables().store.dispatch(setAssemblyUnlocked({quoteId: quoteSub.ID, sequenceId: shade.sequenceId, unlocked: !unlocked}));

				// 	}
				// }

				cell = (
					<QuoteAssemblyLockSelect
						quoteId={quoteSub.ID}
						sequenceId={shadeAssembly.sequence_id}
						unlockShadeBySequenceId={editScreen}
					/>
				);
			} else if (varName == "shipping") {
				option = null;

				if (hasErrors) {
					cell = (<>Errors</>);
				} else {
					cell = shipping;
				}
			} else if (varName == "actions") {
				option = null;
				cell = (
					<ShadeActions
						Data={this.Data}
						key={`actions-shade-${shadeAssembly.sequence_id}`}
						quoteType={this.QuoteType}
						oldQuoteData={this.Data.OldQuote}
						quoteId={quoteSub.ID}
						newQuote={this.Data}
						sequenceId={shadeAssembly.sequence_id}
						isEditable={editable}
						quoteData={this.Data}
						isAdmin={isAdmin}
						hasServiceAccess={hasServiceAccess}
					/>
				);
			} else if (varName == 'fabric2_name') {
				option = options.find((o) => o.varName == varName);

				let item = varName in shadeAssembly
					? shadeAssembly[varName]
					: null;

				if (c.getValue) {
					item = c.getValue(shadeAssembly);
				} else if (!item) {
					item = varName in shadeAssembly.shades[0]
						? shadeAssembly.shades[0][varName]
						: null;
				}


				if (shadeAssembly.single_dual.includes('Single')) {
					cell = <>N/A</>;
				} else {
					cell = this.GetGenericCell(shade, option, c, item, shadeAssembly, !editable, this.Data.FrontPageUpdate, willBeSeamed, unlocked, hasSagWarning);
				}
			} else {
				option = options.find((o) => o.varName == varName);

				let item = shadeAssembly[varName];

				if (c.getValue) {
					item = c.getValue(shadeAssembly);
				} else if (option?.getValue) {
					item = option.getValue(shadeAssembly);
				} else if (!item) {
					item = varName in shadeAssembly.shades[0]
						? shadeAssembly.shades[0][varName]
						: null;
				}


				cell = this.GetGenericCell(shade, option, c, item, shadeAssembly, !editable, this.Data.FrontPageUpdate, willBeSeamed, unlocked, hasSagWarning);
			}

			if (option && option.error) {
				const error = option.error(shade);
				if (error && error != '') {
					errors.push(error);
				}
			}

			this.AddCell(varName, cell);
			row.push(cell);
		});


		const fasciaSets = this.Data.shadeAssemblies.Advanced.find((a) => a.name == 'fascia_combinations')?.sets;

		const fasciaClass = (function (fasciaSets, sequenceId) {
			for (const index in fasciaSets) {
				const fasciaSet = fasciaSets[index];
				let first = true;

				if (!fasciaSet.assemblies) continue;

				const max = fasciaSet.assemblies.length;
				let increment = 1;

				for (const innerIndex in fasciaSet.assemblies) {
					const assembly = fasciaSet.assemblies[innerIndex];

					if (assembly.sequence_id == sequenceId) {
						if (first && increment == max) {
							return 'fascia-top fascia-bottom';
						}

						if (first) {
							return 'fascia-top';
						}

						if (increment == max) {
							return 'fascia-bottom';
						}

						return 'fascia-middle';
					}
					first = false;
					increment += 1;
				}
			}

			return "";
		}(fasciaSets, shade.sequenceId));

		let extraClasses = " ";
		extraClasses += fasciaClass;

		const finishedRow = this.GetShadeRow(row, `assy-${shade.sequenceId}-${shade.val("shade_name")}`, hasErrors, shade.sequenceId, willBeSeamed, extraClasses, hasSagWarning, hasLargeOutdoorMotor);

		return finishedRow;
	}

	CreateCells() {
		const indoorStarter = 'indoor';
		const outdoorStarter = 'outdoor';
		const accessoryStarter = 'accessory';
		const fabricStarter = 'fabric';

		const addCells = (rows, transform, starter) => {
			rows.forEach((row) => {
				const cells = transform(row);
				this.AddCell(`${starter}-`);
				this.AddCell(`${starter}-`);
			});
		};
	}

	AddCell(name, cell) {
		if (!(name in this.AllCells)) {
			this.AllCells[name] = [];
		}
		this.AllCells[name].push(cell);
	}

	FabricSamplesTable(fabricMoney, fabricSamples) {
		const quoteVariables = this.quoteLevelVariables;

		const rows = [];

		const fabricRows = fabricSamples
			.map((fab) => {
				const fMoney = fabricMoney.find((fm) => fm.Id == fab.id);

				const item = this.FabricSampleRow({
					fabricSample: fab,
					fMoney
				});

				return item;
			});

		const table = (
			<TableComponent
				quoteType={quoteVariables().quoteType}
				UpdateIso={this.Money.UpdateIso}
				ISO={this.Money.ISO}
				key={`fabrics-table-${fabricRows.length}`}
				rows={fabricRows}
				columns={this.AccessoryColumns()}
				title="Fabric Samples"
			/>
		);

		return table;
	}

	GetGenericCell(shade, options, column, cellValue, assembly, disabled, update, willBeSeamed, unlocked, hasSagWarning) {
		const key = `assy-cell-${shade?.sequenceId}-${options.varName}-${cellValue}`;

		disabled = disabled || !((options && options.skipReprice) || unlocked);
		const self = this;
		const item = (
			<OptionField
				disabled={disabled}
				key={key}
				option={options}
				column={column}
				value={cellValue}
				shade={shade}
				onChange={this.ValueChanged}
				id={options.getId(assembly)}
				update={update}
				frontPage
				prettyUpdate={this.Data.prettyUpdate}
				willBeSeamed={willBeSeamed}
				hasSagWarning={hasSagWarning}
				isCenterable
				small
				choices={SelectIndoorFabrics(self.quoteLevelVariables().store.getState())}
			/>
		);
		// Id, dataReference, newValue, col
		return item;
	}

	BoxSelected(sequenceId, isChecked) {
		this.quoteLevelVariables().store.dispatch(setAssembliesSelected({ quoteId: this.quoteLevelVariables().quoteSub.ID, sequenceId, selected: isChecked }));
	}

	OutdoorEditRow(onChange) {
		const self = this;

		const sequenceIds = () => self.Data.OutdoorShades.filter((s) => s.unlocked).map((sa) => sa.sequence_id);

		const massEditableColumns = this.OutdoorShadeColumns().filter((col) => [
			"select",
			"roomName",
			"shadeName",
			"shadeType",
			"direction",
			"insideFabric",
			"sideChannels",
			"width",
			"height",
			"actions",
		].includes(col.name));

		return this.EditRow(onChange, sequenceIds, massEditableColumns, "Outdoor Shades Mass Edit", this.OutdoorShadeOptions);
	}

	IndoorEditRow(onChange) {
		const self = this;

		const sequenceIds = () => self.Data.IndoorShades.filter((s) => s.unlocked).map((sa) => sa.sequence_id);

		const massEditableColumns = this.IndoorShadeColumns().filter((col) => [
			"select",
			"roomName",
			"shadeName",
			"shadeType",
			"insideFabric",
			"outsideFabric",
			"width",
			"height",
			"actions",
		].includes(col.name));

		return this.EditRow(onChange, sequenceIds, massEditableColumns, "Indoor Shades Mass Edit", this.IndoorShadeOptions);
	}

	EditRow(onChange, sequenceIds, columns, tableName, options) {
		const quoteVariables = this.quoteLevelVariables;

		const isLightGapEnabled = this.Data.quote.IsLightgapChoiceActive == true;

		const isIndoor = tableName.toLowerCase().includes('indoor');

		const self = this;

		const store = self.quoteLevelVariables().store;
		const assys = selectAssemblyListByQuoteId(quoteVariables().quoteSub.ID)(store.getState());
		const selectedAssySequenceIds = assys.filter((a) => a.selected && (
			isIndoor && a.shade_type_id == 1 || !isIndoor && a.shade_type_id == 2
		)).map((a) => a.sequence_id);

		const cells = columns.map((c) => {
			let option = options.find((o) => o.varName == c.dataReference);
			if (!option) {
				option = c.dataReference == 'select' ? {
					varName: 'select',
					type: 'checkbox'
				} : {
					varName: 'actions',
					type: 'action'
				};
			}
			if (c.dataReference == 'actions') {
				return (
					<MassShadeActions
						quoteType={this.QuoteType.toLowerCase()}
						key={`msa-${c.id}`}
						sequenceIds={sequenceIds}
						selectedSequenceIds={selectedAssySequenceIds}
						deleteAssemblies={(sIds) => store.dispatch(deleteAssemblies({ quoteId: quoteVariables().quoteSub.ID, sequenceIds: selectedAssySequenceIds }))}
						options={options}
						onChange={onChange}
						isLightGapEnabled={isLightGapEnabled}
					/>
				);
			}

			if (c.dataReference == "shade_name") {
				return (<div />);
			}

			return (
				<OptionField
					disabled={this.editable}
					key={`mass-edit-${c.dataReference}`}
					option={option}
					column={c}
					value={null}
					shade={null}
					onChange={onChange}
					id={sequenceIds}
					frontPage={false}
					isClearable={false}
					global
					small
				/>
			);
		});

		cells.push(<Button color={this.QuoteType.toLowerCase() ?? 'green'}>Apply</Button>);

		const rows = (
			<ShadeRowNew
				quoteType={this.QuoteType}
				key="indoor_edit"
				sequenceId={-1}
				columns={cells}
				hasErrors={false}
				update={this.Data.FrontPageUpdate}
			/>
		);

		this.zIndexCounter = (this.zIndexCounter ?? 1000) - 100;

		return (
			<TableComponent
				quoteColor={quoteVariables().quoteType}
				quoteType={quoteVariables().quoteType}

				UpdateIso={this.Money.UpdateIso}
				ISO={this.Money.ISO}
				key={`${tableName}-table`}
				rows={rows}
				columns={[...columns, { name: 'apply', display: 'Apply' }]}
				title={tableName}
				zIndex={this.zIndexCounter}
			/>
		);
	}

	GetSelectBox(shadeAssembly, boxSelected, value, disabled) {
		const sequenceId = shadeAssembly.sequence_id;

		const selectBox = (
			<SelectBox
				key={`${sequenceId}-assyselect`}
				Id={sequenceId}
				onChange={(e) => { boxSelected(sequenceId, e.target.checked); }}
				Checked={value}
				disabled={disabled}
			/>
		);

		return selectBox;
	}

	GetShadeRow(columns, key, hasErrors, sequenceId, willBeSeamed, extraClasses, hasSagWarning, hasLargeOutdoorMotor) {
		return (
			<ShadeRowNew
				quoteType={this.QuoteType}
				key={key}
				sequenceId={sequenceId}
				columns={columns}
				hasErrors={hasErrors}
				willBeSeamed={willBeSeamed}
				hasSagWarning={hasSagWarning}
				update={this.Data.FrontPageUpdate}
				extraClasses={extraClasses}
				hasLargeOutdoorMotor={hasLargeOutdoorMotor}
			/>
		);
	}

	setRenderPause() {
		this.DontRender = true;
	}

	reRenderTable(override = false, rerenderPage = true) {
		if (override) {
			this.DontRender = false;
		}

		if (this.renderTable && !this.DontRender) {
			this.renderTable();
			if (this.renderPage && rerenderPage) {
				this.renderPage();
			}
		}
	}

	ValueChanged(Id, dataReference, newValue, col, updateFrontScreen = false) {
		const sequenceId = !Array.isArray(Id) ? Id : Id[0].sequenceId;

		const prettify = (data) => {
			if (typeof data === "string") {
				data = capitalize(data);
				data = replace(data, "_", " ");
				if (data === "Fabric name") {
					data = "Fabric";
				}
			}

			if (typeof data === "number") {
				if (!Number.isNaN(data)) {
					const fractionConversion = new Fraction(Math.round(16 * Fraction(parseInt(data, 10))
						.valueOf()), 16);
					data = fractionConversion.toFraction(true);
				} else {
					captureSentryError(new Error(`Data is NaN: ${data} - related to sentry issue 4621675981`));
				}
			}

			return data;
		};

		if (!isArray(Id) || !dataReference == "width") {
			this.Data.prettyUpdate
				&& this.Data.prettyUpdate[sequenceId]
				&& this.Data.prettyUpdate[sequenceId][dataReference]
				&& this.Data.prettyUpdate[sequenceId][dataReference](newValue);
		}



		const oldAssembly = this.Data.shadeSubstitute(sequenceId);
		const hadErrors = oldAssembly.getErrorFields(oldAssembly).length > 0;

		if (col.saveType === this.ShadeNum) {
			if (oldAssembly.val(dataReference) !== newValue) {
				ROOT.toast(`${prettify(dataReference)} updated to ${prettify(newValue)}`, {
					type: "success", duration: 2000
				});
			}
			this.Data.ChangeShadeValue(Id, dataReference, newValue, col);
		} else if (col.saveType === this.AssemblyNum) {
			if (!oldAssembly.val(dataReference) !== newValue) {
				ROOT.toast(`${prettify(dataReference)} updated to ${prettify(newValue)}`, {
					type: "success", duration: 2000
				});
			}
			this.Data.ChangeAssemblyValue(Id, dataReference, newValue, col);
		} else if (col.saveType === this.AccessoryNum) {
			this.Data.ChangeAccessoryValue(Id, dataReference, newValue, col);
		} else if (col.saveType === this.FabricNum) {
			this.Data.ChangeFabricValue(Id, dataReference, newValue, col);
		} else if (col.saveType === this.SelectNum) {
			// this.Data.ChangeManyShadesValue(Id, dataReference, newValue, col);
		} else if (col.saveType === this.IsDualNum) {
			this.Data.ChangeDualStatus(Id, dataReference, newValue, col);
		} else if (col.saveType === this.IsCoupled) {
			this.Data.ChangeCoupledStatus(Id, dataReference, newValue, col);
		} else if (col.saveType === this.IsDualOrCoupled) {
			if (oldAssembly.val(dataReference) !== newValue) {
				ROOT.toast(`${prettify(oldAssembly.val('shade_name'))} updated to ${prettify(newValue)}`, {
					type: "success", duration: 2000
				});
			}
			this.Data.ChangeDualCoupledStatus(Id, dataReference, newValue, col);
		} else if (col.saveType === this.IsWidth) {
			if (oldAssembly.val(dataReference) !== newValue) {
				ROOT.toast(`${prettify(dataReference)} updated to ${prettify(newValue)}`, {
					type: "success", duration: 2000
				});
			}
			this.Data.ChangeWidth(Id, dataReference, newValue, col);
		}

		if (updateFrontScreen) {
			if (this.AllCells[dataReference]) {
				const item = this.AllCells[dataReference].find((i) => i.id);
			}
			// item.setNewValue(newValue);
		}

		this.Data.FrontPageUpdate
			&& this.Data.FrontPageUpdate[sequenceId]
			&& this.Data.FrontPageUpdate[sequenceId][dataReference]
			&& this.Data.FrontPageUpdate[sequenceId][dataReference](this.Data.shadeSubstitute(sequenceId));

		const newAssembly = this.Data.shadeSubstitute(sequenceId);

		const errsss = newAssembly.getErrorFields(newAssembly);

		if (newAssembly.getErrorFields(newAssembly).length > 0 || hadErrors) {
			this.Data.FrontPageUpdate
				&& this.Data.FrontPageUpdate[sequenceId]
				&& this.Data.FrontPageUpdate[sequenceId].row
				&& this.Data.FrontPageUpdate[sequenceId].row(newAssembly.getErrorFields(newAssembly).length > 0);
		}

		if (!updateFrontScreen) {
			this.reRenderTable();
		}

		if (!col.skipReprice) {
			this.Data.RePriceSingleRow(Id, !updateFrontScreen);
		}
	}

	AccessoryTable(accessoryMoney) {
		const self = this;

		const { editable, quoteType, store, quoteSub } = self.quoteLevelVariables();

		const qas = selectQuoteAccessories(quoteSub.ID)(store.getState());

		const accessoryRows = qas
			.filter((acc) => acc.quantity > 0)
			.map((acc) => {
				const accMoney = accessoryMoney.find((accM) => accM.Id == acc.line_number);

				const currencyMultiplier = self.Money.currencyMultiplier;
				const myPriceMultiplier = self.Money.currentMultiplier;

				let msrpComp = (accMoney.cost?.Component?.props?.amount ?? acc.msrp) / acc.quantity;
				let shippComp = accMoney.shipping?.Component?.props?.amount ?? acc.shipping;
				let totalComp = acc.msrp;

				msrpComp *= ((myPriceMultiplier ?? 1) * (currencyMultiplier ?? 1));
				shippComp *= ((myPriceMultiplier ?? 1) * (currencyMultiplier ?? 1));
				totalComp *= ((myPriceMultiplier ?? 1) * (currencyMultiplier ?? 1));

				const msrpCompClean = Number(msrpComp.toFixed(2));
				const totalCompClean = Number(totalComp.toFixed(2));
				const shippCompClean = Number(shippComp.toFixed(2));

				const item = this.AccessoryRow({
					accessory: acc,
					msrp: msrpCompClean,
					shipping: shippCompClean,
					total: totalCompClean
				}, !editable);

				return item;
			});

		const table = (
			<TableComponent
				quoteType={quoteType}
				UpdateIso={this.Money.UpdateIso}
				ISO={this.Money.ISO}
				key={`accessory-table-${accessoryRows.length}-${editable}`}
				rows={accessoryRows}
				columns={this.AccessoryColumns()}
				title="Accessories"
			/>
		);

		return table;
	}

	FabricSampleRow(values) {
		const { fabricSample, msrp } = values;

		const cells = [];
	}

	AccessoryRow(values, disabled) {
		const cells = [];

		const {
			accessory, msrp, shipping, total
		} = values;

		const { unlocked } = accessory;

		const isOld = this.Data.isAccessoryOld(accessory.part_number);
		const { quoteSub, editable } = this.quoteLevelVariables();
		const qId = quoteSub.ID;

		this.AccessoryColumns().forEach((accCol) => {
			const colName = accCol.dataReference;

			let cell = {};

			const key = `accessory-${colName}-${accessory.sequence_id}-${accessory.quantity}-{}`;

			if (colName === "msrp") {
				cell = (
					<UnChangeableCell
						key={`acc-${key}-msrp`}
						value={msrp}
						className={colName}
					/>
				);
			} else if (colName === "shipping") {
				cell = (
					<UnChangeableCell
						key={`acc-${key}-shipping`}
						value={shipping}
						className={colName}
					/>
				);
			} else if (colName === "total") {
				cell = (
					<UnChangeableCell
						key={`acc-${key}-total`}
						value={total}
						className={colName}
					/>
				);
			} else if (colName === "order_quantity") {
				cell = (
					<AccessoryQuantity
						key={`acc-${key}-order_quantity`}
						lineNumber={accessory.line_number}
						quoteId={qId}
						value={accessory.quantity}
						onChange={this.Data.setAccessoryQuantity}
						disabled={disabled}
					/>
				);
			} else if (colName === "actions") {
				cell = (
					<AccessoryAction
						quoteType={this.QuoteType.toLowerCase()}
						key={`acc-${key}-actions`}
						lineNumber={accessory.line_number}
						quoteId={qId}
						setAccessoryQuantity={this.Data.setAccessoryQuantity}
						legacyReprice={this.Money.Reprice}
					/>
				);
			} else {
				const accVal = accCol.customCol ? accCol.customCol(accessory) : accessory[colName];

				cell = (
					<UnChangeableCell
						key={`acc-${key}-${colName}`}
						value={accVal}
						className={colName}
					/>
				);
			}

			cells.push(cell);
		});

		const finishedRow = (
			<AccessoryRow
				key={`accessory-${accessory.id}-${accessory.line_number}`}
				cells={cells}
			/>
		);

		return finishedRow;
	}

	hasChainsBanned() {
		const {
			quoteSub
		} = this.quoteLevelVariables ? this.quoteLevelVariables() : { quoteSub: null };

		if (!quoteSub) {
			return false;
		}
		const currentTerritory = quoteSub.territory_data;

		const hasBannedChains = currentTerritory ? currentTerritory.has_chains_banned == true : false;

		return hasBannedChains;
	}
}

export default QuoteUI;
