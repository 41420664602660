import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Container, Card, Table } from 'react-bootstrap';

import Button from '../Parts/Button';

import apiCalls from '../PowerShadesAPIFunctions';

import type { AdminEmailAddress } from '../powershadesApiTypes';
import type { AdminEmailProps, UpdateOptions } from './types';

const AdminEmails = ({ ROOT }: AdminEmailProps) => {
	const {
		setTitle, toast, alert, loaded
	} = ROOT;
	const [emails, setEmails] = useState<AdminEmailAddress[]>([]);
	const [inputEmail, setInputEmail] = useState('');

	const refetchEmails = () => {
		apiCalls.getAdminEmailAddresses().then((resp) => setEmails(resp.data.email_addresses ?? []));
	};

	const deleteEmail = (email: AdminEmailAddress) => {
		alert({
			title: 'Delete Admin Email Address',
			text: `Are you sure you want to delete the admin email address: "${email.email}"?`,
			icon: 'warning',
			smallIcon: true,
		})
			.then(() => {
				apiCalls
					.deleteAdminEmailAddress(email.id)
					.then(() => {
						toast("Admin email deleted", { type: 'success' });
					})
					.finally(refetchEmails);
			})
			.catch(() => {
				toast('Admin email deletion canceled', { type: 'info' });
			});
	};

	const attemptAddEmail = () => {
		apiCalls.addAdminEmailAddress(inputEmail).finally(() => {
			toast('Admin email added', { type: 'success' });
			setInputEmail('');
			refetchEmails();
		});
	};

	const updateEmail = (email: AdminEmailAddress, updateOptions: UpdateOptions) => {
		const tempEmail = { ...email, ...updateOptions };

		const emailInd = emails.findIndex((e) => e.id === email.id);

		const newEmails = [...emails];

		apiCalls.updateAdminEmailAddress(
			tempEmail.id,
			!!tempEmail.receives_dealer_signup_info,
			!!tempEmail.receives_order_conversion_emails
		);

		newEmails.splice(emailInd, 1, tempEmail);

		setEmails(newEmails);
	};

	useEffect(() => {
		loaded();
		setTitle('Manage Admin Email Addresses');

		refetchEmails();
	}, [ROOT, setTitle]);

	const rows = emails.map((email) => {
		const rDsi = email.receives_dealer_signup_info;
		const rOce = email.receives_order_conversion_emails;

		return (
			<tr key={email.id}>
				<td>{email.email}</td>
				<td>
					<input
						type="checkbox"
						checked={rDsi}
						onClick={() => updateEmail(email, { receives_dealer_signup_info: !rDsi })}
					/>
				</td>
				<td>
					<input
						type="checkbox"
						checked={rOce}
						onClick={() =>
							updateEmail(email, { receives_order_conversion_emails: !rOce })}
					/>
				</td>
				<td>
					<Button
						size="sm"
						color="light"
						title="Delete"
						onClick={() => deleteEmail(email)}
					>
						<FontAwesomeIcon icon={faTrashAlt} />
					</Button>
				</td>
			</tr>
		);
	});

	return (
		<Container className="local-bootstrap">
			<Card body className="ml-2 mr-2">
				<div>
					<label>New Email</label>
					<br />

					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							marginBottom: '1em',
						}}
					>
						<input
							type="text"
							style={{ height: '100%' }}
							value={inputEmail}
							onChange={(e) => setInputEmail(e.target.value)}
						/>
						<Button
							className="ml-2"
							color="green"
							onClick={attemptAddEmail}
						>
							+ Add
						</Button>
					</div>
				</div>

				<Table responsive striped>
					<tr className="bold-row">
						<th colSpan={9}>Admin Email Addresses</th>
					</tr>

					<tr>
						<th>Email</th>
						<th>Dealer Signup</th>
						<th>Order Conversion</th>
						<th>Actions</th>
					</tr>
					<tbody>
						{rows}
					</tbody>
				</Table>
			</Card>
		</Container>
	);
};

export default AdminEmails;
