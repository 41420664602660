import {
	Form, Col, Row, Placeholder
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEdit, faKeySkeleton, faSave, faX
} from "@fortawesome/pro-regular-svg-icons";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import {
	faLink, faLinkSlash, faSpinner, faSync
} from "@fortawesome/free-solid-svg-icons";
import { capitalize, startCase } from "lodash";

import Button from "./Button";

import type { EntityPageHeaderProps, EntityHeaderEditableFields } from "./types";
import { useIsPsAdmin } from "../hooks";
import apiCalls from "../PowerShadesAPIFunctions";
import { initialUser } from "../Store/entities/users";
import { useModal } from "../Store/ui/hooks";

const EntityPageHeader = ({
	id = 0,
	editable,
	loading,
	onChange,
	name,
	email,
	phone,
	setEditable,
	onSave,
	showDealerControls = false,
	showUserControls = false,
	selectedUser = initialUser(),
	editPermissionsOverride,
	saveEnabled = true,
	onCancel,
	entityFieldsEditable = true,
	showZohoConnection = false,
	entityType = "",
	salesType,
}: EntityPageHeaderProps) => {
	// const disabled = !editable;

	const editing = editable && onChange !== undefined;

	const isPsAdmin = useIsPsAdmin();

	const modal = useModal();

	const fullSizedEditNeeded = !showUserControls && !showDealerControls && !showZohoConnection;

	const [openZSync, setOpenZSync] = useState(false);
	const [syncing, setSyncing] = useState(false);

	const [links, setLinks] = useState({
		books: "",
		crm: "",
	});

	const { books, crm } = links;

	useEffect(() => {
		if (!showZohoConnection) return;
		apiCalls
			.getZohoLinks(entityType, id)
			.then((resp) => {
				const { zcrm_account_link: crmLink, zbooks_contact_link: booksLink } = resp.data;

				setLinks({
					books: booksLink,
					crm: crmLink,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}, [id, entityType, showZohoConnection]);

	const onSyncClick = () => {
		setSyncing(true);
		apiCalls
			.postZohoSync(entityType, id)
			.then((resp) => {
				if (resp.status !== 200) throw resp.error;
				const { zcrm_account_link: crmLink, zbooks_contact_link: booksLink } = resp.data;

				setLinks({
					books: booksLink,
					crm: crmLink,
				});
				toast.success("Successfully synced!");
				setSyncing(false);
				setOpenZSync(false);
			})
			.catch((err) => {
				toast.error("Error syncing with Zoho");
				console.error({ err });
				throw new Error(err);
			});
		setSyncing(false);
	};

	const handleEdit = (updatedFields: {
		[key in keyof EntityHeaderEditableFields]?: EntityHeaderEditableFields[key];
	}) => {
		if (!onChange) return;
		onChange({
			name,
			email,
			phone,
			...updatedFields,
		});
	};

	if (loading) {
		return (
			<Placeholder animation="glow" className="row ms-0 mb-4 mt-2">
				{name !== undefined && (
					<Col xs={12} lg={6} className="ps-0">
						<Placeholder xs={8} md={6} lg={10} size="lg" bg="green" className="header-edit" />
					</Col>
				)}
				{showZohoConnection && (
					<>
						<Col lg={2} />
						<Col xs={3} lg={2} className="ps-0 pe-lg-0 text-lg-end">
							<Placeholder.Button
								xs={8}
								md={6}
								size="sm"
								bg="light"
								className="zoho-connect pt-1 pb-1"
							/>
						</Col>
					</>
				)}
				{showUserControls ? (
					<>
						<Col xs={7} md={8} className="d-lg-none ps-0">
							<Placeholder.Button variant="green" xs={12} className="d-lg-none" />
						</Col>
						{/* spacer column */}
						<Col lg={2} className="d-none d-lg-block" />
						{/* spacer column */}
						<Col lg={2} className="text-end d-none d-lg-block">
							<Placeholder.Button variant="light" xs={4} className="pb-lg-1 pt-lg-1" />
						</Col>
					</>
				) : null}
				{((!editPermissionsOverride && isPsAdmin) || editPermissionsOverride) && (
					<>
						{showZohoConnection && <Col xs={5} className="d-lg-none" />}
						<Col
							xs={fullSizedEditNeeded ? 12 : 4}
							lg={fullSizedEditNeeded ? 6 : 2}
							className="text-end pe-0 ps-lg-0"
						>
							<Placeholder.Button
								xs={fullSizedEditNeeded ? 3 : 6}
								size="sm"
								bg="light"
								className="pt-1 pb-1"
							/>
							{editable !== undefined && (
								<Placeholder.Button
									xs={fullSizedEditNeeded ? 3 : 6}
									className="pt-1 pb-1"
									size="sm"
									bg="light"
								/>
							)}
						</Col>
					</>
				)}
				{/* <Col xs={1} md={1} xl={1} /> */}
				{email !== undefined && (
					<Col xs={12} md={6} className="mt-2">
						<Row className="mb-1">
							<Placeholder xs={4} md={4} lg={5} className="mb-1" />
						</Row>
						<Row className="me-1">
							<Placeholder xs={12} md={10} lg={8} size="lg" />
						</Row>
					</Col>
				)}
				{phone !== undefined && (
					<Col xs={12} md={6} className="mt-2">
						<Row className="mb-1">
							<Placeholder xs={4} md={4} lg={5} className="mb-1" />
						</Row>
						<Row className="me-1">
							<Placeholder xs={12} md={10} lg={8} size="lg" />
						</Row>
					</Col>
				)}
			</Placeholder>
		);
	}

	/**
	 * @async
	 * @param userEmail - The email of the user to send the password reset email to.
	 * @returns A Promise that resolves when the password reset email is sent.
	 * @throws If there is an error sending the password reset email.
	 */
	const handleSendPasswordReset = async (userEmail: string) => {
		const url = window.location.href;
		const urlSplit = url.split("#");
		const urlCallback = `${urlSplit[0]}#ConfirmPasswordReset`;

		apiCalls
			.sendResetPasswordEmail(userEmail, urlCallback)
			.then((resp) => {
				if (resp.data.message === "Check your inbox, we have sent a link to reset email.") {
					toast.success("Password reset email sent!");
				} else {
					toast.error("Error sending password reset email");
				}
			})
			.catch((err) => {
				toast.error("Error sending password reset email");
				throw new Error(err);
			});
	};

	return (
		<>
			<Row className="mt-2 mb-2">
				<Col xs={12} md={6} lg={showUserControls ? 7 : 8}>
					{editing && entityFieldsEditable ? (
						<Form.Control
							className="header-edit"
							disabled={!editing}
							type="text"
							value={name}
							onChange={(e) => handleEdit({ name: e.target.value })}
						/>
					) : (
						<h2>{name}</h2>
					)}
				</Col>
				<Col
					className={showUserControls ? "text-end pe-3" : "d-none"}
					xs={8}
					lg={3}
				>
					{showUserControls && (
						<Row className="g-0">
							<Col xs={6} lg={9} />
							<Col lg={3} className="d-none d-lg-block">
								<Button
									fullWidth
									size="sm"
									color="light"
									title="Send Password Reset"
									onClick={() => {
										modal({
											icon: "info",
											title: "Send Password Reset",
											text: "Are you sure you want to send a password reset email to this user?",
											confirmButtonText: "Send",
											onConfirm: () => handleSendPasswordReset(selectedUser.email),
											onCancel: () => toast.info("Password reset canceled"),
										});
									}}
								>
									<FontAwesomeIcon icon={faKeySkeleton} size="lg" />
								</Button>
							</Col>
							<Col xs={12} className="d-lg-none">
								<Button
									fullWidth
									color="green"
									title="Send Password Reset"
									onClick={() => {
										modal({
											icon: "info",
											title: "Send Password Reset",
											text: "Are you sure you want to send a password reset email to this user?",
											confirmButtonText: "Send",
											onConfirm: () => handleSendPasswordReset(selectedUser.email),
											onCancel: () => toast.info("Password reset canceled"),
										});
									}}
								>
									Send Password Reset
								</Button>
							</Col>
						</Row>
					)}
				</Col>
				<Col
					className={showZohoConnection ? "text-end pe-3 pe-lg-0" : "d-none"}
					xs={3}
					sm={2}
					lg={2}
				>
					{showZohoConnection && (
						<Row className="g-lg-0">
							{/* spacer column */}
							<Col lg={9} />
							{/* spacer column */}
							<Col lg={3} className="pe-lg-0 ps-lg-0">
								<Button
									fullWidth
									color="light"
									onClick={() => setOpenZSync(true)}
									size="sm"
									title="Sync with Zoho"
								>
									<FontAwesomeIcon icon={faSync} size="lg" />
								</Button>
								<Popup
									open={openZSync}
									onClose={() => setOpenZSync(false)}
									closeOnDocumentClick
									className="mobile-modal zoho-sync"
								>
									<div>
										<Row>
											<h4>Zoho Sync</h4>
										</Row>
										<Row className="mb-2">
											<p className="text-muted user-select-none">
												If you&apos;d like to view this &nbsp;
												{entityType}
												&nbsp; in Zoho, click the buttons below. If they are grayed out, that
												means the &nbsp;
												{entityType}
												&nbsp; has not been synced. Click the sync button to sync the &nbsp;
												{entityType}
												&nbsp; to Zoho!
											</p>
										</Row>
										<Row>
											<Col xs={6} lg={5} xl={6}>
												<Button fullWidth color={books ? "green" : "gray"} disabled={!books}>
													<a href={books} target="_blank" rel="noopener noreferrer">
														<FontAwesomeIcon icon={books ? faLink : faLinkSlash} />
														&nbsp; View
														<span className="d-none d-sm-inline">
															&nbsp;
															{startCase(entityType)}
														</span>
														&nbsp;in&nbsp;
														<span className="d-none d-sm-inline">Zoho&nbsp;</span>
														Books
													</a>
												</Button>
											</Col>
											{/* spacer column */}
											<Col lg={2} className="d-xl-none" />
											{/* spacer column */}
											<Col xs={6} lg={5} xl={6}>
												<Button fullWidth color={crm ? "green" : "gray"} disabled={!crm}>
													<a href={crm} target="_blank" rel="noopener noreferrer">
														<FontAwesomeIcon icon={crm ? faLink : faLinkSlash} />
														&nbsp; View
														<span className="d-none d-sm-inline">
															&nbsp;
															{startCase(entityType)}
														</span>
														&nbsp;in&nbsp;
														<span className="d-none d-sm-inline">Zoho&nbsp;</span>
														CRM
													</a>
												</Button>
											</Col>
										</Row>
										<Row className="mt-4">
											<Col>
												<Button onClick={() => setOpenZSync(false)} color="gray">
													Cancel
												</Button>
											</Col>
											<Col className="text-end">
												<Button
													color={syncing ? "gray" : "green"}
													disabled={syncing}
													onClick={() => onSyncClick()}
												>
													{syncing ? (
														<FontAwesomeIcon transform="grow-5" icon={faSpinner} spin />
													) : (
														"Sync"
													)}
												</Button>
											</Col>
										</Row>
									</div>
								</Popup>
							</Col>
						</Row>
					)}
				</Col>
				{((!editPermissionsOverride && isPsAdmin) || editPermissionsOverride) && (
					<>
						{/* spacer column */}
						{showZohoConnection && <Col xs={5} sm={6} className="d-md-none" />}
						{/* spacer column */}
						<Col
							xs={fullSizedEditNeeded ? 12 : 4}
							lg={fullSizedEditNeeded ? 6 : 2}
							className={showZohoConnection ? "ps-lg-0" : ""}
						>
							
							<Row className="g-0 justify-content-between">
								{
									salesType
									&& <Col xs={fullSizedEditNeeded ? 9 : 6}>
										<span>
											Sales Type:&nbsp;
											{capitalize(salesType)}
										</span>
									</Col>
								}

								{editing ? (
									<Col xs={fullSizedEditNeeded ? 3 : 6} >
										<Button
											disabled={!saveEnabled}
											fullWidth
											color="light"
											size="sm"
											title="Save Entity"
											className="me-2"
											onClick={() => onSave && onSave()}
										>
											<FontAwesomeIcon icon={faSave} />
										</Button>
									</Col>
								) : (
									!salesType
									&& <Col xs={fullSizedEditNeeded ? 9 : 6} />
								)}
								{editable !== undefined && (
									<Col xs={fullSizedEditNeeded ? 3 : 6}>
										<Button
											fullWidth
											color="light"
											size="sm"
											title="Edit Entity"
											onClick={() => {
												if (editable) {
													onCancel && onCancel();
													setEditable && setEditable(!editable);
													return;
												}
												setEditable && setEditable(!editable);
											}}
										>
											<FontAwesomeIcon icon={editable ? faX : faEdit} />
										</Button>
									</Col>
								)}
							</Row>
						</Col>
					</>
				)}
			</Row>
			<Row className="mb-4">
				{email && (
					<Col sm={12} md={6} className="mb-2">
						<h6>Email</h6>
						<Form.Control
							disabled={!editing || !entityFieldsEditable}
							value={email}
							onChange={(e) => handleEdit({ email: e.target.value })}
						/>
					</Col>
				)}
				{phone && (
					<Col sm={12} md={6}>
						<h6>Phone</h6>
						<Form.Control
							disabled={!editing || !entityFieldsEditable}
							value={phone}
							onChange={(e) => handleEdit({ phone: e.target.value })}
						/>
					</Col>
				)}
			</Row>
		</>
	);
};

export default EntityPageHeader;
