import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { Col, Row } from 'react-bootstrap';
import { startCase } from 'lodash';

import type { AddUserAttributeModalProps } from './types';
import Button from '../Parts/Button';
import type { UserAttributes } from '../powershadesApiTypes';

const AddUserAttributeModal = ({
	open, onClose, setWorkingUser, attributeOptions
}: AddUserAttributeModalProps) => {
	const [selection, setSelection] = useState<keyof UserAttributes | null>(null);

	const handleClose = () => {
		onClose();
	}

	const handleAdd = () => {
		if (!selection) {
			return;
		}
		setWorkingUser(selection, true);
		onClose();
	}

	const selectOptions = Object.entries(attributeOptions).map(([displayName, value]) => (
		<option value={value}>{startCase(displayName)}</option>
	));

	selectOptions.unshift(<option disabled selected>Select an attribute</option>)

	return (
		<Popup
			open={open}
			closeOnDocumentClick={false}
			closeOnEscape={false}
			onClose={handleClose}
			className="mobile-modal"
		>
			<Row className="mb-2">
				<Col>
					<h4>Add User Attribute</h4>
				</Col>
			</Row>
			<Row>
				<Col>
					<select
						onChange={(e) => setSelection(e.target.value as keyof UserAttributes)}
						className="form-select"
					>
						{selectOptions}
					</select>
				</Col>
			</Row>
			<Row className="mt-4">
				<Col>
					<Button onClick={handleClose} color="gray">
						Close
					</Button>
				</Col>
				<Col className="text-end">
					<Button color={!selection ? "gray" : "green"} disabled={!selection} onClick={handleAdd}>
						Add
					</Button>
				</Col>
			</Row>
		</Popup>
	);
};

export default AddUserAttributeModal;
