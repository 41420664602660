const InternalLink = (props) => {
	const {
		content, style, children, keepStyle
	} = props;

	const onClick = (e) => {
		e.preventDefault();

		if (content) {
			window.ROOT.loadContent(content);
		}
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});

		props.onClick && props.onClick(); // eslint-disable-line
	};

	if (keepStyle) {
		return (
			<span className="internal-link" style={style}>
				<a
					href={content}
					onClick={onClick}
					style={{ textDecoration: 'none', color: 'unset' }}
				>
					{children}
				</a>
			</span>
		);
	}
	return (
		<a
			href={content}
			onClick={onClick}
			style={{ textDecoration: 'none', color: 'unset' }}
		>
			{children}
		</a>
	);
};

export default InternalLink;
