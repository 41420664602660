const TextDivider = (props) => (

	<div style={{
		display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', ...props.style
	}}
	>
		<div style={{
			width: '30%', flexGrow: '1', height: '1px', backgroundColor: 'black'
		}}
		/>
		<div style={{ margin: '0 1em', whiteSpace: 'nowrap' }}>{ props.children }</div>
		<div style={{
			width: '30%', flexGrow: '1', height: '1px', backgroundColor: 'black'
		}}
		/>
	</div>
);

export default TextDivider;
