const ShipmentModalHeadRow = (props) => (

	<tr className="bold-row">
		
		<th>Order Number</th>
		<th>Order Title</th>
		<th>Date Ordered</th>
		{ props.quote.is_shipped ? <th>Date Shipped</th> : null }

	</tr>
);

const ShipmentModalRow = (props) => {
	const d = new Date(parseInt(`${props.quote.ordered_time}000`));

	const date_string = `${(`${d.getMonth() + 1}`).padStart(2, '')}-${(`${d.getDate()}`).padStart(2, '0')}-${d.getFullYear()}`;

	const shipped_date = props.quote.date_shipped ? props.quote.date_shipped : '';

	return (

		<tr>
			<td>{ props.quote.ID }</td>
			<td>{ props.quote.title }</td>
			<td>{ date_string }</td>
			{ props.quote.is_shipped ? <td>{ shipped_date }</td> : null }
		</tr>
	);
};

export { ShipmentModalRow, ShipmentModalHeadRow };
