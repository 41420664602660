import React, { useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Placeholder, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import type { UserListProps } from './types';
import {
	useUsersList,
	useUsersLoadStatus,
} from '../Store/entities/hooks';
import { useIsAdmin } from '../hooks';
// import type { UserFilters } from '../powershadesApiTypeExtensions';
import { isSearchMatch } from '../psUtil';
import { TabPaneItem, TabPaneSelections } from '../Parts/types';
import TabPaneWithSearchFilter from '../Parts/TabPaneWithSearchFilter';
import Button from '../Parts/Button';
import AddUserModal from './AddUserModal';
import { UserStore } from '../powershadesApiTypeExtensions';

const UserList = ({ ROOT }: UserListProps) => {
	// const [activeFilter, setActiveFilter] = useState<UserFilters>('all');
	const [activeSearch, setActiveSearch] = useState<string>('');
	const [activeKey, setActiveKey] = useState(0);
	// const [searchParams, setSearchParams] = useSearchParams();

	const users = useUsersList();
	const userLoadStatus = useUsersLoadStatus();
	const [openUserModal, setOpenUserModal] = useState(false);

	const userIsAdmin = useIsAdmin();
	
	const { navigate, setTitle } = ROOT;

	useEffect(() => {
		setTitle('View Users');
	}, [setTitle]);

	const workingUsers = useMemo(
		() =>
			Object.values(users)
				.filter((u: UserStore) =>
					isSearchMatch(u.name, activeSearch)
					|| isSearchMatch(u.email, activeSearch))
				.sort((a: UserStore, b: UserStore) =>
					a.name.localeCompare(b.name)),
		[users, activeSearch]
	);

	/** 
	 * Function that turns the list of users into a list of tab panes.
	 * @notes Uses the entity_id attached to the users role to determine 
	 * which tab pane to put the user in.
	 * @returns TabPaneSelections
	 */
	const getTabSelectionsList = (): TabPaneSelections[] => {
		const workingTabPanes: TabPaneSelections[] = [];
		// Keep a mapping of entity ids to array indices
		// Keep a mapping of entity ids to array indices
		const entityIdToIndex = {};

		workingUsers.forEach((user) => {
			const userRoles = user.roles;
			const attachedEntities = userRoles.map((r) => ({
				id: r.entity_id,
				name: r.entity_name,
			}));
			attachedEntities.forEach((entity) => {
				// If entity is not in the workingTabPanes, add it
				if (entityIdToIndex[entity.id] === undefined) {
					entityIdToIndex[entity.id] = workingTabPanes.length; // remember the index
					workingTabPanes.push({
						id: entity.id,
						key: entity.name,
						title: entity.name,
						items: [],
					});
				}
			});
			userRoles.forEach((role) => {
				const userItem: TabPaneItem = {
					id: (role.entity_id + user.id).toString(),
					type: "user",
					name: user.name,
					title: user.name,
					onClick: () => {
						navigate(`/User?user_id=${user.id}`);
					},
				};
				// Add the user item to the entity's items array
				const entityIndex = entityIdToIndex[role.entity_id];
				workingTabPanes[entityIndex]?.items.push(userItem);
			});
		});

		// Sort the tab panes alphabetically by title.
		workingTabPanes.sort((a, b) => a.title.localeCompare(b.title));

		// Add an 'All' tab pane to the beginning of the array, only if the user 
		// has more than one entity
		if (Object.keys(entityIdToIndex).length > 1) {
			workingTabPanes.unshift({
				id: 0,
				key: 'all',
				title: 'All',
				items: [],
			});
		}
		if (workingTabPanes[0] !== undefined) {
			workingTabPanes[0].items = workingUsers.map((user) => ({
				id: user.id.toString(),
				type: "user",
				name: user.name,
				title: user.email,
				onClick: () => {
					navigate(`/User?user_id=${user.id}`);
				},
			}));
		}

		return workingTabPanes;
	};

	const tabSelectionsCurrent = getTabSelectionsList();
	const loading = userLoadStatus <= 3;

	return (
		<Container>
			<Card body className="entity-card">
				<AddUserModal open={openUserModal} onClose={() => setOpenUserModal(false)} />
				{userIsAdmin && (
					<Row className="justify-content-end mb-2">
						<Col className="text-end" xs={4}>
							{loading ? (
								<Placeholder animation="glow">
									<Placeholder.Button variant="green" xs={4} />
								</Placeholder>
							) : (
								<Button onClick={() => setOpenUserModal(true)}>
									<FontAwesomeIcon icon={faPlus} />
									&nbsp;Add New User
								</Button>
							)}
						</Col>
					</Row>
				)}
				<Row>
					<Col>
						<TabPaneWithSearchFilter
							key="user-list"
							selections={tabSelectionsCurrent}
							loading={loading}
							disabled={false}
							setActiveKey={setActiveKey}
							activeKey={activeKey}
							searchFilter={activeSearch}
							setSearchFilter={setActiveSearch}
						/>
					</Col>
				</Row>
			</Card>
		</Container>
	);
};

export default UserList;
