import { useEffect } from 'react';
import { createSelector } from 'reselect';
import { unwrapResult } from '@reduxjs/toolkit';
import type { QuoteTypeIds, FilterOptions } from '../../../powershadesApiTypes';

import store, { type AppDispatch, type AppState, UsePortalSelector } from '../..';
import {
	RefreshQuotePricing,
	duplicateQuote,
	loadFilteredHomePageData,
	loadFilteredQuoteIds,
	loadQuoteById,
	loadQuoteCounts,
	loadQuoteIds,
	// loadQuoteTypes,
	loadQuotesMetaData,
	loadShipments,
} from '.';
import { setCountsLoadStatus } from '..';
import { QuoteMultiplierRecord, QuoteStore, loadStatus } from '../entitiesType';
import { setFilterSettings, setVisibleQuoteIds, setVisibleQuoteIdsLoadingStatus } from '../../ui';
import { isThunkQuoteResponse } from './typeExtensions';
import { shallowEqual } from 'react-redux';
import { selectQuoteAccessories } from '../accessories/hooks';
import { selectAssemblyListByQuoteId } from '../assemblies/hooks';
import { selectIsPsAdmin } from '../../user/userPermissions';

/**
 * Hook that loads quote IDs from the server and updates the store.
 * 
 * @param  isLoggedIn - A boolean value indicating whether the user is logged in or not.
 * @returns void
 */
export const useQuoteIds = (isLoggedIn: boolean) => {
	const { dispatch } = store;

	useEffect(() => {
		if (isLoggedIn) {
			const quoteIdPayload = loadQuoteIds();
			dispatch(quoteIdPayload);
		}
	}, [dispatch, isLoggedIn]);
};

/**
 * Hook that fetches and sets the visible quote counts based on the provided filter settings.
 * 
 * @returns An async function that takes in filter settings and a boolean value 
 * indicating whether to set loading counts or not.
 * @param filterSettings - An object containing the filter settings to be applied.
 * @param setLoadingCounts - A boolean value indicating whether to set loading counts or not.
 */
export const useFetchAndSetVisibleQuoteCounts = () => {
	const { dispatch } = store;

	return async (filterSettings: FilterOptions, setLoadingCounts?: boolean) => {
		if (setLoadingCounts) {
			dispatch(setCountsLoadStatus(loadStatus.loading));
		}
		dispatch(loadQuoteCounts(filterSettings));
	};
};

/**
 * Selects the list of quote counts from the application state.
 * 
 * @param state - The current application state.
 * @returns An array of quote counts.
 */
const selectQuoteCounts = createSelector(
	(state: AppState) => state.entity.quotes.counts,
	(counts) => counts.list
);

/**
 * Hook that selects the list of quote counts from the application state.
 * 
 * @returns An array of quote counts.
 */
export const useQuoteCounts = () => UsePortalSelector(selectQuoteCounts);

const selectQuoteTypesUnsafe = (state: AppState) => state.entity.quotes.types;

/**
 * Selects the list of quote types from the application state and sorts them alphabetically by name.
 * 
 * @param state - The current application state.
 * @returns An array of quote types.
 */
const selectQuoteTypes = createSelector(
	selectQuoteTypesUnsafe,
	(types) =>
	(types.loadStatus === loadStatus.fullyLoaded
		? types.list
			.slice().sort((a, b) => a?.name?.localeCompare(b?.name))
		: [])
);

/**
 * Selects the list of quote types from the application state and creates 
 * a map of quote type IDs to their names.
 * 
 * @param state - The current application state.
 * @returns A Map object with quote type IDs as keys and their names as values.
 */
const selectQuoteTypesMap = createSelector(
	(state: AppState) => state.entity.quotes.types,
	(types) => new Map(types.list.map((type) => [type.id, type.name]))
);


/**
 * Hook that selects the load status of the quote types from the application state.
 * 
 * @returns LoadStatus of the quote types.
 */
export const useQuoteTypesLoadStatus = () => UsePortalSelector(
	(state) => state.entity.quotes.types.loadStatus);

/**
 * Hook that selects the list of quote types from the application state and sorts them 
 * alphabetically by name.
 * 
 * @returns An array of quote types.
 */
export const useQuoteTypes = () => UsePortalSelector(selectQuoteTypes);

/**
 * Hook that selects the list of quote types from the application state and creates a map of quote 
 * type IDs to their names.
 * 
 * @returns A Map object with quote type IDs as keys and their names as values.
 */
export const useQuoteTypesMap = () => UsePortalSelector(selectQuoteTypesMap);




/**
 * Selects the list of quotes from the application state and returns it as an array.
 * 
 * @param state - The current application state.
 * @returns An array of quotes.
 */
export const selectQuotesArray = createSelector(
	(state: AppState) => state.entity.quotes.list,
	(quotes) => Object.values(quotes)
);

/**
 * Selects the list of quotes from the application state and returns it as an object.
 * 
 * @param state - The current application state.
 * @returns An object containing all quotes.
 */
export const selectQuotes = createSelector(
	(state: AppState) => state.entity.quotes.list,
	(quotes) => quotes as Record<number, QuoteStore>
);

/**
 * Hook that returns a function to dispatch an action to load metadata for a list of quotes.
 * 
 * @returns A function that takes an array of quote IDs and 
 * dispatches an action to load their metadata.
 */
export const useQuoteMetaDispatch = () => (quoteIds: number[]) => {
	const { dispatch } = store;

	dispatch(loadQuotesMetaData(quoteIds));
};

/**
 * Hook that returns a function to dispatch an action to duplicate a quote.
 * 
 * @returns A function that takes a quote object and dispatches an action to duplicate it.
 * @param quote - The quote to duplicate.
 */
export const useQuoteDuplicateDispatch = () =>
	async (quote: QuoteStore, quoteType?: QuoteTypeIds) => {
		const { dispatch } = store;

		const typedDispatch = dispatch as AppDispatch;

		const duplicateQuoteType = quoteType ?? quote.quote_type_id ?? 5;
		const updatedQuote: QuoteStore = {
			...quote,
			quote_type_id: duplicateQuoteType,
		};
		const action = await typedDispatch(duplicateQuote(updatedQuote));
		const result = unwrapResult(action);
		return result;
	};

/**
 * Hook that returns a function to dispatch an action to load shipments for a quote.
 * 
 * @returns A function that takes a quote ID and dispatches an action to load its shipments.
 * @param quoteId - The ID of the quote to load shipments for.
 */
export const useQuoteShipmentDispatch = () => (quoteId: number) => {
	const { dispatch } = store;

	dispatch(loadShipments(quoteId));
};

/**
 * Selects a quote from the application state by its ID.
 * 
 * @param quoteId - The ID of the quote to select.
 * @returns The quote object with the specified ID.
 */

export const selectQuoteFromId = (quoteId: number) => createSelector(
	selectQuotes,
	(quotes) => quotes[quoteId] as QuoteStore
);

/**
 * Hook that selects a quote from the application state by its ID.
 * 
 * @param quoteId - The ID of the quote to select.
 * @returns The quote object with the specified ID.
 */
export const useQuoteById = (quoteId: number) => {
	const quote = UsePortalSelector(selectQuoteFromId(quoteId));

	if(quote?.loadStatus === loadStatus.notLoaded) {
		const { dispatch } = store;
		const typedDispatch = dispatch as AppDispatch;

		typedDispatch(loadQuotesMetaData([quoteId]));
	}
	
	return quote;

};

/**
 * Selects the list of quotes from the application state and returns it as an array.
 * 
 * @param state - The current application state.
 * @returns An array of quotes.
 */
export const selectQuoteList = createSelector(
	selectQuotes,
	(quotes) => Object.values(quotes)
);

/**
 * Hook that selects the list of quotes from the application state and returns it as an array.
 * 
 * @returns An array of quotes.
 */
export const useQuotesArray = () => UsePortalSelector(selectQuotesArray);

/**
 * Hook that selects the list of quotes from the application state and returns it as an object.
 * 
 * @returns An object containing all quotes.
 */
export const useQuotes = () => UsePortalSelector(selectQuotes);

/**
 * Selects the quoteType's name of a quote by its ID.
 * 
 * @param quoteId - The ID of the quote to select.
 * @returns The name of the quote type.
 */
const selectQuoteTypeNameByQId = (quoteId: number) => createSelector(
	[selectQuoteTypesUnsafe,
		selectQuoteFromId(quoteId)],
	(quoteTypes, quote) => {
		if (!quote) return 'CEDIA';

		const quoteType = quoteTypes?.list?.find((type) => type.id === quote.quote_type_id)?.zoho_reference ?? "cedia";
		return quoteType;
	}
);

/**
 * Hook that selects the quote type of the provided quote id by quote types and 
 * returns the quote type's name.
 * 
 * @param quoteId - The ID of the quote to select.
 * @returns The name of the quote type.
 */
export const useQuoteTypeNameByQuoteId = (quoteId: number) => UsePortalSelector(selectQuoteTypeNameByQId(quoteId));


export const selectQuoteColorByQuoteId = (quoteId: number) => createSelector(
	selectQuoteTypeNameByQId(quoteId),
	(quoteType) => {
		switch (quoteType) {
			case 'RMA':
				return '#BB4430';
			case 'Rework':
				return '#469e95';
			case 'CEDIA':
			case 'Demo':
			case 'Internal':
			case 'Window_Covering':
				return '#8dc63f';
			case 'Bidspec':
				return '#9611D0';
			default:
				return '#8dc63f';
		}
	}
);


export const useQuoteColorByQuoteId = (quoteId: number) => UsePortalSelector(selectQuoteColorByQuoteId(quoteId));

const selectQuoteLoadStatus = createSelector(
	(state: AppState) => state.entity.quotes,
	(quotes) => quotes.loadStatus
);

export const useQuoteLoadStatus = () => UsePortalSelector(selectQuoteLoadStatus);

export const UseSetVisibleQuoteIdsAndEnsureLoading = () => (visibleQuoteIds: number[]) => {
	const { dispatch } = store;

	const typedDispatch = dispatch as AppDispatch;

	typedDispatch(loadQuotesMetaData(visibleQuoteIds));

	typedDispatch(setVisibleQuoteIds(visibleQuoteIds));

	typedDispatch(setVisibleQuoteIdsLoadingStatus(loadStatus.fullyLoaded));
};

/**
 * Hook the takes the new filter settings and applies them while sending any necessary api calls.
 * 
 * @param filterSettings - The new filter settings.
 * @returns Returns a function to set the new filter settings.
 */

export const useSetFilterSettings = (resetVisibleEachTime = false) =>
	(filterSettings: FilterOptions) => {
		const { dispatch } = store;

		const typedDispatch = dispatch as AppDispatch;

		const {
			page_number
		} = filterSettings;

		const currentVisibleQuoteIds = store.getState().ui.home.visibleQuoteIds.list;

		const fetchingData = store.getState().ui.home.visibleQuoteIds.fetching;
        if (fetchingData) return;
		
		const setVisibleQuoteIds = UseSetVisibleQuoteIdsAndEnsureLoading();

		typedDispatch(setFilterSettings(filterSettings));

		typedDispatch(loadFilteredHomePageData(filterSettings)).then((action) => {
			const statusRejected = action.meta.requestStatus === 'rejected';
			const stringRejected = typeof action.payload === 'string';
			const thunkReturnedUndefined = !action.payload;
			const failedThunk = statusRejected || stringRejected || thunkReturnedUndefined;

			const currentFilterSettings = store.getState().ui.home.filterSettings;

			//	if any of the settings' properties don't match, return early
			if (
				currentFilterSettings.general_filter !== filterSettings.general_filter
				|| !shallowEqual(currentFilterSettings.dealer_ids, filterSettings.dealer_ids)
				|| !shallowEqual(currentFilterSettings.territory_ids, filterSettings.territory_ids)
				|| !shallowEqual(currentFilterSettings.quote_type_ids, filterSettings.quote_type_ids)
				|| currentFilterSettings.page_number !== filterSettings.page_number
				|| currentFilterSettings.results_per_page !== filterSettings.results_per_page
				|| !shallowEqual(currentFilterSettings.filter_names, filterSettings.filter_names)
				|| !shallowEqual(currentFilterSettings.sort_names, filterSettings.sort_names)
				|| currentFilterSettings.manufacturer_id !== filterSettings.manufacturer_id
			) return;




			if (failedThunk) {
				console.error("Something went wrong with loading quotes. This is probably related to sentry issue #4396960384");
				console.error("action.payload:");
				console.error(action.payload);
				return;
			}

			if (!isThunkQuoteResponse(action.payload)) {
				console.error("Something went wrong with loading quotes. This is probably related to sentry issue #4396960384");
				console.error("action.payload:");
				console.error(action.payload);
				return;
			}

			const newQuotesData = action.payload;

			const {
				quoteIds,
				counts
			} = newQuotesData;

			let newVisibleQuoteIdList: number[] = [];

			if (page_number === 1 || resetVisibleEachTime) {
				newVisibleQuoteIdList = [...quoteIds];
			} else {
				newVisibleQuoteIdList = [...currentVisibleQuoteIds, ...quoteIds];
			}

			setVisibleQuoteIds(newVisibleQuoteIdList);

			// Using the fulfilled action to set the counts load status to fully loaded
			const customAction = {
				type: loadQuoteCounts.fulfilled.type,
				payload: {
					list: counts,
					loadStatus: loadStatus.fullyLoaded,
				}
			};

			typedDispatch(customAction);
		});
	};

/**
 * Hook that fetches and sets visible quotes based on the provided filter settings.
 * 
 * @param filterSettingsRef - A reference to the current filter settings.
 * @returns An asynchronous function that fetches and sets 
 * visible quotes based on the provided filter settings.
 */
export const useFetchAndSetVisibleQuotes = (filterSettingsRef: FilterOptions) => {
	// Get the store dispatch function
	const { dispatch } = store;

	const typedDispatch = dispatch as AppDispatch;

	const loadedQuotes = useQuotes();

	return async (filterSettings: FilterOptions) => {
		// Get the current visible quote IDs from the application state
		const currentVisibleQuoteIds = store.getState().ui.home.visibleQuoteIds.list;

		// Load the filtered quote IDs from the server
		const loadFilteredQuoteIdsAction = await typedDispatch(
			loadFilteredQuoteIds({ ...filterSettings })
		);

		// If the filtered quote IDs failed to load, we stop the process
		if (!loadFilteredQuoteIds.fulfilled.match(loadFilteredQuoteIdsAction)) return;

		// Get the new quote IDs from the loadFilteredQuoteIdsAction payload
		const newQuoteIds = loadFilteredQuoteIdsAction.payload;

		// Filter out the quotes that are already loaded
		const unloadedQuotes = newQuoteIds.filter(
			(quoteId) => !(
				(loadedQuotes[quoteId]?.loadStatus ?? loadStatus.notLoaded) >= loadStatus.metaLoaded)
		);

		// If there are unloaded quotes, load their metadata from the server
		if (unloadedQuotes.length > 0) {
			const loadQuotesMetaDataAction = await typedDispatch(
				loadQuotesMetaData(newQuoteIds)
			);

			// If the meta data failed to load, we stop the process
			if (!loadQuotesMetaData.fulfilled.match(loadQuotesMetaDataAction)) return;
		}

		// Determine if the filter settings have changed the page number
		const differentPage = filterSettings.page_number !== filterSettingsRef.page_number;

		// Combine the current visible quote IDs with the new quote IDs
		const finalVisibleQuoteIds = differentPage
			? [...currentVisibleQuoteIds, ...newQuoteIds]
			: newQuoteIds;

		// Set the visible quote IDs in the application state
		dispatch(setVisibleQuoteIds(finalVisibleQuoteIds));
	};
};

/**
 * Selects the total number of quotes from the application state.
 * 
 * @param state - The current application state.
 * @returns The total number of quotes.
 */
export const selectTotalQuoteAmount = createSelector(
	(state: AppState) => state.entity.quotes.list,
	(quotes) => Object.values(quotes).length
);

/**
 * Hook that selects the total number of quotes from the application state and returns it.
 * 
 * @returns The total number of quotes.
 */
export const useTotalQuoteAmount = () => UsePortalSelector(selectTotalQuoteAmount);

/**
 * Selects the load status of the quote counts from the application state.
 * 
 * @param state - The current application state.
 * @returns LoadStatus of the quote counts.
 */
export const selectQuoteCountStatus = createSelector(
	(state: AppState) => state.entity.quotes.counts,
	(counts) => counts.loadStatus
);

/**
 * Hook that selects the load status of the quote counts from the application state.
 * 
 * @returns LoadStatus of the quote counts.
 */
export const useQuoteCountStatus = () => UsePortalSelector(selectQuoteCountStatus);

type QuoteStoreData = {
	quote: QuoteStore | undefined,
	quoteMultipliers: QuoteMultiplierRecord | undefined
};

export const selectQuoteData = (quoteId: number) => createSelector(
	(state: AppState) => state.entity,
	(entityItems) => {
		const quoteStoreData: QuoteStoreData = {
			quote: entityItems.quotes.list[quoteId],
			quoteMultipliers: entityItems.quoteMulitpliers[quoteId]
		};

		return quoteStoreData;
	}
);

export const selectQuoteIdAndDispatch = (quoteId: number) => createSelector(selectQuoteData(quoteId), (quotes) => {
	const dispatch = store.dispatch as AppDispatch;

	const selectedQuote = quotes.quote;
	const multipliers = quotes.quoteMultipliers;
	//console.log("selectedQuote?.loadStatus", selectedQuote?.loadStatus)
	const hasNotLoaded = (selectedQuote?.loadStatus ?? 0) < 3;
	const multipliersNotLoaded = ((multipliers?.list?.length ?? 0) === 0 && selectedQuote?.loadStatus !== 3)
	const isMetaLoaded = (selectedQuote?.loadStatus ?? 0) === 4;
	if (hasNotLoaded || multipliersNotLoaded || isMetaLoaded) {
		console.log("quote loading...")
		dispatch(loadQuoteById(quoteId)).then((action) => {
			if (loadQuoteById.fulfilled.match(action)) {
				dispatch(RefreshQuotePricing({ quoteId }));
			}
		});
	}

	return selectedQuote;
});

export const useQuoteIdAndDispatch = (quoteId: number) => UsePortalSelector(selectQuoteIdAndDispatch(quoteId));

export const selectQuoteStatusesById = (quoteId: number) => createSelector(selectQuotes, (quotes) => {
	const quoteState = quotes;
	const selectedQuote = quoteState[quoteId];
	return selectedQuote?.quote_statuses ?? [];
});

export const useQuoteStatusesById = (quoteId: number) => UsePortalSelector(selectQuoteStatusesById(quoteId));

export const selectQuoteShipmentsById = (quoteId: number) => createSelector(selectQuotes, (quotes) => {
	const quoteState = quotes;
	const selectedQuote = quoteState[quoteId];
	return selectedQuote?.shipments ?? [];
});

export const useQuoteShipmentsById = (quoteId: number) => UsePortalSelector(selectQuoteShipmentsById(quoteId));

/**
 * Selects the list of quote multipliers from the application state and returns it as an object.
 * 
 * @param state - The current application state.
 * @returns An object containing all quotes.
 */
export const selectQuoteMultipliers = createSelector(
	(state: AppState) => state.entity.quoteMulitpliers,
	(quotes) => quotes as Record<number, QuoteMultiplierRecord>
);


export const UseMultipliersByQuoteId = (quoteId: number) => UsePortalSelector(
	createSelector(selectQuoteMultipliers, (multipliers) => multipliers[quoteId])
);

export const selectOldestDateByQuoteId = (quoteId: number) => createSelector(
	[selectAssemblyListByQuoteId(quoteId), selectQuoteAccessories(quoteId)],
	(assemblies, qas) => {
		
		let oldestDate = assemblies?.reduce(
			(min, sa) => {
				const newDate = sa.msrp == 0 ? new Date(0) : new Date(sa.last_time_priced * 1000);

				if (newDate < min) {
					return newDate;
				}
				return min;
			},
			new Date()
		) ?? new Date();

		oldestDate = qas
			.filter((qasy) => qasy.last_time_priced !== -1)
			.reduce(
				(min, sa) => {
					const newDate = new Date(sa.last_time_priced * 1000);

					if (newDate < min) {
						return newDate;
					}
					return min;
				},
				oldestDate
			) ?? new Date();

		return oldestDate;
	}
);

export const selectQuoteTitleByQuoteId = (quoteId: number) => createSelector(
	[selectOldestDateByQuoteId(quoteId), selectQuoteTypeNameByQId(quoteId), selectQuoteFromId(quoteId), selectIsPsAdmin],
	(oldestDate, qt, q, isPsAdmin) => {
		const now = new Date();

		const Difference_In_Time = now.getTime() - oldestDate.getTime();

		// To calculate the no. of days between two dates 
		const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

		const numberOfDaysLeft = Math.ceil(90 - Difference_In_Days);

		const quoteType = qt;

		const quoteNum = `${q?.display_id ?? ""}`;

		// this.IsDemo ? `DEMO${this.Id}` : `PS${this.Id}`;

		const orderStatus = q?.order_status ?? "active_quote";

		const statusToTitle = ({
			active_quote: "Active Quote",
			archived: "Archived Quote",
			order_pending_approval: "Pending Approval",
			under_review: "Under Review",
			approved_order: "Ready For Manufacturing",
			mfg_accepted_order: "In Production",
			mfg_provisioned_order: "In Production",
			mfg_shipped_order: "Order Shipped",
			submitted_quote: "Submitted and Processing"
		})[orderStatus];

		const statusNeedsModified = orderStatus === "approved_order" && q?.manufacturer_id;
		const statusDisplay = statusNeedsModified ? "Submitted To Manufacturing" : statusToTitle;

		let titleMessage = "";
		const quoteShipped = orderStatus === 'mfg_shipped_order';

		const mostRecentShippingStatus = q?.quote_statuses?.reduce((acc, status) => {
			return acc > status.ship_date ? acc : status.ship_date;
		}, "") ?? "";

		if (q?.order_status !== 'active_quote') {
			if (quoteType === null || quoteType === '' || !isPsAdmin || quoteType === undefined) {
				titleMessage = `${quoteNum} - ${statusDisplay}`;
			} else {
				titleMessage = `${quoteNum} - ${quoteType} - ${statusDisplay}`;
			}
		} else if (orderStatus === 'active_quote' && numberOfDaysLeft <= -20000) {
			titleMessage = `${quoteNum} - Quote Has Error(s)`;
		} else if (numberOfDaysLeft <= 0) {
			titleMessage = `${quoteNum} - Quote Expired`;
		} else {
			const message = numberOfDaysLeft == 1 ? "day" : "days";
			titleMessage = `${quoteNum} - ${statusDisplay} - Pricing Expires In ${numberOfDaysLeft} ${message}`;
		}

		if (mostRecentShippingStatus !== "" && !quoteShipped) {
			titleMessage = `${titleMessage} : ${mostRecentShippingStatus}`;
		}

		return titleMessage;
	}
);

export const useQuoteTitle = (quoteId: number) => UsePortalSelector(selectQuoteTitleByQuoteId(quoteId));



	