/* eslint-disable camelcase */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @stylistic/no-mixed-operators */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @stylistic/multiline-ternary */
import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Container, Row, Col, OverlayTrigger, Tooltip, Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamationTriangle, faInfoCircle, faXmark, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faAlarmClock } from "@fortawesome/pro-solid-svg-icons";

import { toast } from "react-toastify";

import TextArea from "../../Parts/TextArea";
import Money from "../../Parts/Money";

import apiCalls from "../../PowerShadesAPIFunctions";

import store from "../../Store";
import { selectIsPsAdmin } from "../../Store/user/userPermissions";
import Button from "../../Parts/Button";
import { captureSentryError, isFailedApiCall } from "../../psUtil";

class DemoReview extends Component {
	constructor(props) {
		super(props);

		const { newQuote } = props;
		const dealerPricing = newQuote.dealer();
		const { multiplier } = dealerPricing.multiplier;

		const discountAmount = (0.7 - (1 - multiplier)) / multiplier;

		this.state = {
			confirmOpen: false,
			mainOpen: false,
			reviewType: "default",
			buttonText: "Demo Review",
			rejectionReason: "",
			inputValue: discountAmount * 100, // It's in decimal form, need to convert to percentage.
			previousDemoQuotes: [],
			freeShipping: false,
			errorOpen: false,
			errorMessage:
				"Operation Failed. If you are receiving this message, please reach out to PowerShades Engineering.",
			submitting: false,
			isPsAdmin: false,
			requestingReview: false,
		};

		this.openMain = this.openMain.bind(this);
		this.closeMain = this.closeMain.bind(this);
		this.openConfirm = this.openConfirm.bind(this);
		this.closeConfirm = this.closeConfirm.bind(this);
		this.mainButtonClicked = this.mainButtonClicked.bind(this);
		this.requestPricing = this.requestPricing.bind(this);
		this.setReviewType = this.setReviewType.bind(this);
		this.setRejectionReason = this.setRejectionReason.bind(this);
		this.setInputValue = this.setInputValue.bind(this);
		this.changeShipping = this.changeShipping.bind(this);
		this.submitApproval = this.submitApproval.bind(this);
		this.openError = this.openError.bind(this);
		this.closeError = this.closeError.bind(this);
		this.setError = this.setError.bind(this);
	}

	openMain() {
		this.setState({ mainOpen: true });
	}

	closeMain() {
		this.setState({ mainOpen: false });
	}

	changeShipping(allowShipping) {
		this.setState({ freeShipping: allowShipping });
	}

	openConfirm() {
		this.setState({ confirmOpen: true });
	}

	closeConfirm() {
		this.setState({ confirmOpen: false });
	}

	openError() {
		this.setState({ errorOpen: true });
	}

	closeError() {
		this.setState({ errorOpen: false });
	}

	setError(message) {
		this.setState({ errorMessage: message });
	}

	setSubmitting(bool) {
		this.setState({ submitting: bool });
	}

	mainButtonClicked() {
		const { newQuote } = this.props;

		const demoInfo = newQuote.GetDemoInfo();

		if (demoInfo === null || demoInfo === undefined) {
			this.openConfirm();
		} else {
			this.openMain();
		}
	}

	requestPricing() {
		const { newQuote } = this.props;
		const quoteId = newQuote.Id;
		this.setState({ requestingReview: true });

		apiCalls.requestDemoReview(quoteId).then((resp) => {
			if (isFailedApiCall(resp, "new_demo_review")) {
				console.error({ resp });
				console.error({ respData: resp?.data });
				console.error({ respError: resp?.error });
				captureSentryError(new Error("Error requesting demo review"));
				toast.error("Error requesting demo review");
				return;
			}
			// On success make the quote have demo review stuff
			const demoReviewObj = resp.data.new_demo_review;

			newQuote.SetDemoInfo(demoReviewObj);

			this.setState({ mainOpen: true, confirmOpen: false });
			toast.success("Demo review requested successfully");
		});
	}

	submitApproval() {
		this.setSubmitting(true);
		const { newQuote } = this.props;
		const demoReviewObj = newQuote.GetDemoInfo();
		const { reviewType, inputValue, freeShipping, rejectionReason } = this.state;
	
		const approvalTypes = {
			CASH_OVERRIDE: "cash",
			PERCENTAGE_DECREASE: "percentage",
			REJECT_DEMO: "reject",
		};
	
		const handleApiResponse = (resp) => {
			console.log("API response:", resp);
			if (isFailedApiCall(resp, "demoReview")) {
				throw new Error("API call failed");
			}
			this.setSubmitting(false);
			newQuote.SetDemoInfo(resp.data.demoReview || resp.data.demo_review);
			newQuote.forceRerender();
			toast.success("Demo review processed successfully");
		};
	
		const handleApiError = (error) => {
			this.setSubmitting(false);
			const errorMessage = error.data?.error || error.message || "An error occurred";
			this.setError(errorMessage);
			this.openError();
			console.error({ error });
			captureSentryError(new Error("Error processing demo review"));
			toast.error("Error processing demo review");
			newQuote.forceRerender();
		};
	
		if (reviewType === approvalTypes.REJECT_DEMO) {
			apiCalls
				.rejectDemoReview(newQuote.Id, demoReviewObj.demoRequestedTime, rejectionReason)
				.then(handleApiResponse)
				.catch(handleApiError);
			return;
		}
	
		if (reviewType === "default" || reviewType === "outdoor") {
			apiCalls
				.approveDemoReview(newQuote.Id, demoReviewObj.demoRequestedTime, "percentage", inputValue, freeShipping)
				.then(handleApiResponse)
				.catch(handleApiError);
		} else {
			apiCalls
				.approveDemoReview(newQuote.Id, demoReviewObj.demoRequestedTime, reviewType, inputValue, freeShipping)
				.then(handleApiResponse)
				.catch(handleApiError);
		}
	}

	setReviewType(type) {
		let updateObj = { reviewType: type };

		if (type === "default") {
			const { newQuote } = this.props;
			const dealerPricing = newQuote.dealer();
			const { multiplier } = dealerPricing.multiplier;

			const discountAmount = (0.7 - (1 - multiplier)) / multiplier;

			updateObj = { inputValue: discountAmount * 100, ...updateObj };
		}

		if (type === "outdoor") {
			const { newQuote } = this.props;
			const dealerPricing = newQuote.dealer();
			const { multiplier } = dealerPricing.multiplier;

			const discountAmount = (0.6 - (1 - multiplier)) / multiplier;

			updateObj = { inputValue: discountAmount * 100, ...updateObj };
		}

		this.setState(updateObj);
	}

	setRejectionReason(reason) {
		this.setState({ rejectionReason: reason });
	}

	setInputValue(value) {
		this.setState({ inputValue: value });
	}

	componentDidMount() {
		const { newQuote } = this.props;
		const { isPsAdmin } = this.state;

		const quoteSub = newQuote.QuoteSubstitution;
		const dealerId = quoteSub.dealer_id;

		store.subscribe(() => {
			const storeState = store.getState();
			const isPsAdmin = selectIsPsAdmin(storeState);
			this.setState({ isPsAdmin });
		});

		if (isPsAdmin) {
			apiCalls.GetDemoOrdersByOrganization(dealerId, "dealer").then((resp) => {
				if (isFailedApiCall(resp, "demo_quotes")) {
					throw resp;
				}
				const { data } = resp;
				this.setState({ previousDemoQuotes: data.demoQuotes });
			}).catch((error) => {
				if (error && error?.data && error?.data?.error) {
					this.setError(error.response.data.message);
				}
				if (error && error?.error) {
					this.setError(error.error);
				}
				this.openError();
				console.error({ error });
				captureSentryError(new Error("Error fetching previous demo quotes"));
				toast.error("Error fetching previous demo quotes");
			});
		}
	}

	render() {
		const {
			mainOpen,
			reviewType,
			confirmOpen,
			buttonText,
			rejectionReason,
			inputValue,
			previousDemoQuotes,
			freeShipping,
			errorOpen,
			errorMessage,
			requestingReview,
			submitting,
		} = this.state;

		const { isPsAdmin, newQuote, demoReviewFailed, quoteType } = this.props;

		const demoReviewObj = newQuote.GetDemoInfo();

		const reviewStatus = demoReviewObj?.demo_review_status ?? demoReviewObj?.demoReviewStatus;

		const approvalTypes = {
			CASH_OVERRIDE: "cash",
			PERCENTAGE_DECREASE: "percentage",
			REJECT_DEMO: "reject",
			DEMO_DEFAULT: "default",
			OUTDOOR_DEFAULT: "outdoor",
		};

		const approvalValueNeeded
			= reviewType === approvalTypes.CASH_OVERRIDE || reviewType === approvalTypes.PERCENTAGE_DECREASE;

		const approvalTypeOptions = [
			{
				value: approvalTypes.DEMO_DEFAULT,
				display: "Default DEMO70",
			},
			{
				value: approvalTypes.OUTDOOR_DEFAULT,
				display: "OUTDOOR 60",
			},
			{
				value: approvalTypes.CASH_OVERRIDE,
				display: "Cash Override",
			},
			{
				value: approvalTypes.PERCENTAGE_DECREASE,
				display: "Percentage Discount",
			},
			{
				value: approvalTypes.REJECT_DEMO,
				display: "Reject Demo",
			},
		];

		let dealerPricing = newQuote.buy();

		if (!dealerPricing) {
			dealerPricing = newQuote.buy();
		}

		const previousMSRP = dealerPricing?.totalPrice;

		const taxRate = dealerPricing.taxPercentage / 100;
		// const taxShipping = dealerPricing.tax_shipping;

		// const shippingTotal = freeShipping ? 0 : dealerPricing.shipping.total_shipping;
		
		const demo = newQuote.GetDemoInfo();
		const subtotal = dealerPricing?.pre_shipping;
		let newMsrp
			= demo
				? (
					demo?.demoApprovalType?.name !== 'cash'
						? subtotal * (1 - demo.value / 100)
						: demo.value
				)
				: (
					approvalTypes.CASH_OVERRIDE === reviewType
						? inputValue
						: approvalTypes.PERCENTAGE_DECREASE === reviewType || approvalTypes.DEMO_DEFAULT === reviewType
							? previousMSRP * (1 - inputValue / 100)
							: previousMSRP * (1 - 0.4 / 100) || approvalTypes.OUTDOOR_DEFAULT === reviewType
								? previousMSRP * (1 - inputValue / 100)
								: previousMSRP * (1 - 0.2 / 100)
				);

		console.log("#drc344 - ", { demo, subtotal, newMsrp });
		if (reviewStatus === "approved") {
			newMsrp
				= demoReviewObj?.demoApprovalType?.name !== "cash"
					? previousMSRP * (1 - demoReviewObj.value / 100)
					: demoReviewObj.value;
		}

		const newInvoiceTotal = newMsrp * (1 + taxRate);
		// + shippingTotal * (1 + (taxShipping ? taxRate : 0)); // eslint-disable-line

		const reviewDisplay = {
			pending: "Pending",
			rejected: "Rejected",
			approved: "Approved",
			void: "Void",
		};

		const reviewStatusDisplay = reviewDisplay[reviewStatus] ?? "Void";

		const statusDescriptions = {
			pending: "This demo is pending review from a PowerShades Territory Manager",
			rejected: "This demo has been rejected by a PowerShades Territory Manager",
			approved: "This demo has been approved by a PowerShades Territory Manager",
			void: "Something went wrong trying to load the demo status. Please refresh your page and try again.",
		};

		const prevQuoteRows = previousDemoQuotes.map((pq) =>
		// let readableDate = null;
			(
				<tr key={pq.timePlaced + pq.quoteId}>
					<td>{pq.quoteId}</td>
					<td>
						{pq.timePlaced !== null
							? (
								// readableDate =
								new Date(pq.timePlaced * 1000)).toLocaleString()
							: "Not Ordered Yet"}
					</td>
				</tr>
			));
		return (
			<div>
				{/* First Popup with terms and conditions */}
				{!demoReviewFailed ? (
					<>
						<Popup
							key="demo-review-confirm-popup"
							open={confirmOpen}
							onClose={this.closeConfirm}
							className="mobile-modal"
						>
							<Container fluid>
								<Row className="mt-2">
									<Col>
										<h4 className={`text-${quoteType}`}>Demo Pricing Request</h4>
									</Col>
								</Row>
								{/* Terms and Condition */}
								<hr />
								<Row className="mb-2">
									<Col>
										I declare that the following is true and correct: This order is for demo
										purposes only and will not be used or re-sold in any form outside the intended
										purpose. Failing to abide by these terms may result in the cancellation of any
										orders and could prohibit future access to PowerShades products and services.
									</Col>
								</Row>
								<hr />
								<Row>
									<Col>
										<Button color="gray" onClick={this.closeConfirm}>
											Cancel
										</Button>
									</Col>
									<Col className="text-end">
										<Button
											color={quoteType ?? "green"}
											loading={requestingReview}
											onClick={this.requestPricing}
										>
											Agree and Request Pricing
										</Button>
									</Col>
								</Row>
							</Container>
						</Popup>
						{/* Second Popup  */}
						<Popup
							key="demo-review-main-popup"
							open={mainOpen}
							closeOnDocumentClick={false}
							onClose={this.closeMain}
							className="mobile-modal"
						>
							<Container>
								<Row>
									<Col>
										<h4 className={`text-${quoteType}`}>Demo Review</h4>
									</Col>
									<Col className="text-end">
										<h5>
											<OverlayTrigger
												overlay={
													<Tooltip id="status-check">
														{statusDescriptions[reviewStatus]}
													</Tooltip>
												}
											>
												<Row className="user-select-none">
													<Col className="text-end">{reviewStatusDisplay}</Col>
													<Col xs={2}>
														{reviewStatusDisplay?.toLowerCase()?.includes("pending") && (
															<FontAwesomeIcon
																className={`text-${quoteType}`}
																transform="grow-3"
																icon={faAlarmClock}
															/>
														)}
														{reviewStatusDisplay?.toLowerCase()?.includes("approved") && (
															<FontAwesomeIcon transform="grow-3" icon={faCheck} />
														)}
														{reviewStatusDisplay?.toLowerCase()?.includes("rejected") && (
															<FontAwesomeIcon
																color="red"
																transform="grow-3"
																icon={faXmark}
															/>
														)}
														{reviewStatusDisplay?.toLowerCase()?.includes("void") && (
															<FontAwesomeIcon
																color="yellow"
																transform="grow-3"
																icon={faExclamationTriangle}
															/>
														)}
													</Col>
												</Row>
											</OverlayTrigger>
										</h5>
									</Col>
								</Row>
								<hr />
								{/* error popup in case reject review fails */}
								<Popup
									nested
									open={errorOpen}
									onClose={this.closeError}
									contentStyle={{
										width: "40%",
										height: "50%",
									}}
								>
									<h3>Error!</h3>
									<p>{errorMessage}</p>
									<Container
										style={{
											position: "absolute",
											bottom: 0,
											left: 0,
											width: "100%",
											marginBottom: "1em",
											paddingLeft: "0.5em",
											paddingRight: "0.5em",
										}}
									>
										<Row style={{ display: "flex" }}>
											<Col style={{ minWidth: "50%" }}>
												<Button color={quoteType ?? "green"} onClick={this.closeError}>
													Close
												</Button>
											</Col>
										</Row>
									</Container>
								</Popup>
								<Row>
									{!isPsAdmin && reviewStatus?.toLowerCase()?.includes("pending") && (
										<Col xs={12} className="mb-2">
											This demo is pending review from a PowerShades Territory Manager. Please
											check back later.
										</Col>
									)}
									<Col xs={12}>
										{reviewType !== approvalTypes.REJECT_DEMO && (
											<>
												{isPsAdmin && (
													<Row>
														<h6>
															Current Order Cost: (Before Shipping) &nbsp;
															<Money value={previousMSRP} />
														</h6>
													</Row>
												)}
												{reviewStatus?.toLowerCase()?.includes("approved") && (
													<Row>
														<h6>
															Updated Demo Cost: (Before Shipping) &nbsp;
															<Money
																value={
																	newInvoiceTotal
																	- dealerPricing?.shipping?.total_shipping
																}
															/>
														</h6>
													</Row>
												)}
												{isPsAdmin && (
													<Row>
														<h6>
															Discount Applied:&nbsp;
															<Money value={newMsrp - previousMSRP} />
														</h6>
													</Row>
												)}
												{reviewStatus?.toLowerCase()?.includes("approved") && (
													<Row>
														<h6>
															Shipping &nbsp;
															<Money value={dealerPricing?.shipping?.total_shipping} />
														</h6>
													</Row>
												)}
												{reviewStatus?.toLowerCase()?.includes("approved") && (
													<Row>
														<h6>
															Total Demo Cost &nbsp;
															<Money value={newInvoiceTotal} />
														</h6>
													</Row>
												)}

											</>
										)}
										{reviewStatus?.toLowerCase()?.includes("pending") && isPsAdmin && (
											<>
												<Row className="mb-2">
													<Col xs={6}>
														<Row>
															<Col>
																<OverlayTrigger
																	overlay={
																		<Tooltip id="demo-type-popup">
																			Select the discount type you would like to
																			apply to this order.
																			<br />
																			Default Dealer 70 is the original 70% off
																			MSRP.
																			<br />
																			Cash Override allows you to directly set the
																			invoice amount.
																			<br />
																			Percentage discount allows you to select a
																			percentage off of the original MSRP.
																			<br />
																			<strong>
																				Reject Demo declines the demo request -
																				double heads up - the rejection reason
																				is sent to the customer!
																			</strong>
																		</Tooltip>
																	}
																>
																	<h6>
																		Demo Type&nbsp;
																		<FontAwesomeIcon
																			icon={faInfoCircle}
																			className={`text-${quoteType}`}
																		/>
																	</h6>
																</OverlayTrigger>
															</Col>
														</Row>
														<Row>
															<Col>
																<select
																	className="w-100"
																	onChange={(e) => this.setReviewType(e.target.value)}
																	value={reviewType}
																>
																	{approvalTypeOptions.map((atp) => (
																		<option key={atp.value} value={atp.value}>
																			{atp.display}
																		</option>
																	))}
																</select>
															</Col>
														</Row>
													</Col>
													{reviewType !== approvalTypes.REJECT_DEMO && (
														<Col xs={6}>
															<Button
																fullWidth
																color={freeShipping ? "green" : "gray"}
																onClick={() => this.changeShipping(!freeShipping)}
																radioButton
															>
																Free Shipping&nbsp;
																<FontAwesomeIcon
																	icon={freeShipping ? faCheck : faTimes}
																/>
															</Button>
														</Col>
													)}
												</Row>
												{approvalValueNeeded && (
													<p>
														<input
															style={{
																maxWidth: "60%",
															}}
															onChange={(e) => this.setInputValue(e.target.value)}
															value={inputValue}
														/>
														{reviewType !== approvalTypes.CASH_OVERRIDE ? <>%</> : null}
													</p>
												)}
												{reviewType === approvalTypes.REJECT_DEMO && (
													<Row>
														<Col xs={12}>
															<OverlayTrigger
																overlay={
																	<Tooltip id="status-check">
																		<strong>Heads up!</strong>
																		This is front facing - the customer will receive
																		an email with this explanation.
																	</Tooltip>
																}
															>
																<>
																	Rejection Reason &nbsp;
																	<FontAwesomeIcon
																		icon={faInfoCircle}
																		className={`text-${quoteType}`}
																	/>
																</>
															</OverlayTrigger>
														</Col>
														<Col xs={12}>
															<TextArea
																key="demo-notes"
																wrapperStyle={{
																	alignSelf: "center",
																}}
																style={{
																	resize: "none",
																	minHeight: "7.5em",
																	width: "100%",
																}}
																disabled={false}
																defaultValue={rejectionReason}
																onChange={(new_value) =>
																	this.setRejectionReason(new_value)}
																maxLength={2000}
															/>
														</Col>
													</Row>
												)}
											</>
										)}
									</Col>
									{isPsAdmin ? (
										<Col xs={12} className="mb-4">
											<Accordion>
												<Accordion.Item eventKey="0">
													<Accordion.Header>Previous Demo Quotes</Accordion.Header>
													<Accordion.Body>
														{previousDemoQuotes.length > 0 ? (
															<table
																style={{
																	overflowY: "auto",
																	width: "100%",
																}}
																className="clickable-table"
																cellSpacing="0"
															>
																<tbody>
																	<tr className="bold-row">
																		<th>Quote Number</th>
																		<th>Date</th>
																	</tr>
																	{prevQuoteRows}
																</tbody>
															</table>
														) : (
															<>No previous demo quotes found.</>
														)}
													</Accordion.Body>
												</Accordion.Item>
											</Accordion>
										</Col>
									) : null}
								</Row>
								<Row>
									<Col className="text-start">
										<Button color="gray" onClick={this.closeMain}>
											Close
										</Button>
									</Col>
									{reviewStatus?.toLowerCase()?.includes("pending") && isPsAdmin && (
										<Col className="text-end">
											<Button
												color={quoteType ?? "green"}
												loading={submitting}
												onClick={() => {
													this.submitApproval();
												}}
											>
												Submit Review
											</Button>
										</Col>
									)}
									{reviewStatus?.toLowerCase()?.includes("approved") && !this.props.isOrder && (
										<Col>{this.props.placeOrderButton}</Col>
									)}
								</Row>
							</Container>
						</Popup>
					</>
				) : (
					<Popup
						open={confirmOpen}
						onClose={this.closeConfirm}
						className="mobile-modal"
						onOpen={() => {
							throw new Error("Demo Review clicked on errored quote");
						}}
					>
						<Container className="local-bootstrap">
							<Row>
								<Col>
									<h3>There was an issue fetching data.</h3>
								</Col>
							</Row>
							<Row>
								<Col>
									Please reach out to PowerShades Customer Service for assistance on placing this
									order.
								</Col>
							</Row>
							<Row className="align-text-bottom">
								<Col className="text-end">
									<Button onClick={() => this.closeConfirm()} color={quoteType ?? "green"}>
										Close
									</Button>
								</Col>
							</Row>
						</Container>
					</Popup>
				)}
				<Button color={quoteType ?? "green"} fluid fullWidth onClick={this.mainButtonClicked}>
					{buttonText}
				</Button>
			</div>
		);
	}
}

export default DemoReview;
