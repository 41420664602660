import { createSelector } from "reselect";
import { AppState, UsePortalDispatch, UsePortalSelector } from "../index";

import { logout } from "../upperLevelReducers";
import { selectCurrentUserData, selectIsLoggedIn } from "./userPermissions";
import { loadUserLinks, setTheme, setThemePreference } from ".";
import { fakeRootType } from "../../FakeRootType";
import { store } from "../configureStore";
import { loadStatus } from "../entities/entitiesType";

export const useLogout = (ROOT: fakeRootType) => {
	const dispatch = UsePortalDispatch();

	return () => {
		dispatch(logout());
		ROOT.loadContent("/Login");
	};
};

/**
 * Hook that selects the user from the app state.
 * @returns The user object from the app state.
 */
export const useUser = () => UsePortalSelector(selectCurrentUserData);

/**
 * Selects the user's logged in status from the app state.
 * @returns The user's logged in status from the app state.
 */
export const useIsLoggedIn = () => UsePortalSelector(selectIsLoggedIn);

const selectUserThemeUnsafe = (state: AppState) => state.user.theme ?? "light";

const selectUserTheme = createSelector(selectUserThemeUnsafe, (theme) => theme);

export const useUserTheme = () => UsePortalSelector(selectUserTheme);

export const useSetUserTheme = () => {
	const dispatch = UsePortalDispatch();
	
	return (theme: "light" | "dark") => {
		dispatch(setTheme(theme));
		dispatch(setThemePreference(theme));
	};
};

export const useIsEngineer = () => {
	const user = useUser();

	return user?.is_engineering ?? false;
}

export const useIsService = () => {
	const user = useUser();

	return user?.is_service ?? false;
}

export const useIsSales = () => {
	const user = useUser();

	return user?.is_sales_person ?? false;
}

export const useSupportLinks = () => {
	const user = useUser();

	const links = user?.links?.list ?? [];

	if((user?.id ?? 0) !== 0 && (user?.links?.loadStatus === loadStatus.needsLoaded || !user?.links?.loadStatus))
	{
		store.dispatch(loadUserLinks());
	}

	return links ?? [];
}