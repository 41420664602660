import React from "react";
import { toast } from "react-toastify";
import { Col, Placeholder, Row } from "react-bootstrap";
import { useIsPsAdmin } from "../hooks";
import Button from "./Button";
import { useDealerById } from "../Store/entities/hooks";
import { approveDealer, declineDealer } from "../Store/entities/organizations/dealers";
import { UsePortalDispatch } from "../Store";
import type { DealerApprovalProps } from "./types";

const DealerApproval = ({ dealerId, loading }: DealerApprovalProps) => {
	const dispatch = UsePortalDispatch();
	const isPsAdmin = useIsPsAdmin();
	console.debug(isPsAdmin);

	const dealer = useDealerById(dealerId);

	if (!dealer) {
		return null;
	}

	const { status } = dealer;

	const showDealerApproval = status === "pending";
	const showDealerDeactivate = status === "approved";

	/**
	 * Activates the dealer based on given Id.
	 * @async
	 * @returns void
	 */
	const activateDealer = async () => {
		try {
			const approveResponse = await dispatch(approveDealer(dealerId));
			if (approveDealer.fulfilled.match(approveResponse)) {
				toast.success('Dealer approved');
			} else {
				toast.warning('Error approving dealer');
			}
		} catch (error: any) {
			toast.warning('Error approving dealer');
			console.error(error);
			throw new Error(error);
		}
	};

	/**
	 * Deactivates the dealer based on given Id.
	 * @async
	 * @returns void
	 */
	const deActivateDealer = async () => {
		try {
			const declineResponse = await dispatch(declineDealer(dealerId));
			if (declineDealer.fulfilled.match(declineResponse)) {
				toast.success('Dealer declined');
			} else {
				toast.error('Error declining dealer');
			}
		} catch (error: any) {
			toast.error('Error declining dealer');
			console.error(error);
			throw new Error(error);
		}
	};

	if (loading) {
		return (
			<Placeholder animation="glow" className="row mb-4 mt-2">
				{showDealerApproval && (
					<>
						<Col xs={12} sm={6} lg={3}>
							<Placeholder.Button
								xs={12}
								variant="green"
								size="sm"
							/>
						</Col>
						<Col xs={12} sm={6} lg={3}>
							<Placeholder.Button
								xs={12}
								className="mt-2 mt-sm-0"
								variant="gray"
								size="sm"
							/>
						</Col>
					</>
				)}
				{showDealerDeactivate && (
					<Col xs={12} sm={6} lg={3}>
						<Placeholder.Button
							xs={12}
							className="mt-2 mt-sm-0"
							variant="gray"
							size="sm"
						/>
					</Col>
				)}
				{(!showDealerDeactivate && !showDealerApproval) && (
					<Col xs={12} sm={6} lg={3}>
						<Placeholder.Button
							xs={12}
							className="mt-2 mt-sm-0"
							variant="green"
							size="sm"
						/>
					</Col>
				)}
			</Placeholder>
		);
	}
	return (
		<Col
			xs={12}
		>
			{showDealerApproval && (
				<Col className="text-end">
					<Row>
						<Col sm={6} lg={3}>
							<Button
								fullWidth
								color="green"
								onClick={activateDealer}
							>
								Approve
							</Button>
						</Col>
						<Col sm={6} lg={3} className="mt-2 mt-sm-0">
							<Button
								fullWidth
								color="gray"
								onClick={deActivateDealer}
							>
								Decline
							</Button>
						</Col>
						<Col sm={12} lg={6}>
						<text>
						Date Application Sent: {dealer.date_application_sent}
						</text>
					</Col>
					</Row>
				</Col>
			)}
			{showDealerDeactivate && (
				<Row>
					<Col sm={6} lg={3}>
						<Button
							fullWidth
							color="gray"
							onClick={deActivateDealer}
						>
							Deactivate
						</Button>
					</Col>
					<Col sm={6} lg={3}>
						<text>
							Date Application Sent: {dealer.date_application_sent}
						</text>
					</Col>
				</Row>
			)}
			{(!showDealerDeactivate && !showDealerApproval) && (
				<Row>
					<Col sm={6} lg={3}>
						<Button
							fullWidth
							color="green"
							onClick={activateDealer}
						>
							Reactivate
						</Button>
					</Col>
				</Row>
			)}
		</Col>
	);
};

export default DealerApproval;
