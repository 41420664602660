import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseLaptop } from '@fortawesome/free-solid-svg-icons';

import type { DemoQuoteProps } from './types';

const DemoQuoteIcon = ({
	size = '1x', colorOverride = '', className = ''
}: DemoQuoteProps) => (
	<FontAwesomeIcon
		icon={faHouseLaptop}
		size={size}
		className={`${className} ${colorOverride && `text-${colorOverride}`}`}
	/>
);

export default DemoQuoteIcon;
