import {
	Table, Container, Card
} from "react-bootstrap";

// import wsClient from '../WebsocketClient';

const UsersOnline = () => {
	// const [users, setUsers] = useState(null);


	const users = [];

	// function refresh() {
	// 	// wsClient.usersOnline()
	// 	//    .then((resp) => setUsers(resp.data.users));
	// }

	// useEffect(() => {
	// 	// let refresh_interval = null;

	// 	// wsClient.onLoad.add(() => {
	// 	// 	refresh_interval = setInterval(refresh, 1500);
	// 	// });
		
	// 	// return () => clearInterval(refresh_interval);
	// }, []);
	
	// const parseViewing = (viewing) => {
	// 	let str = viewing.page + (Object.keys(viewing.args).length ? '?' : '') || '';

	// 	Object.keys(viewing.args)
	// 		  .forEach((key) => str += `${key}=${viewing.args[key]}`);

	// 	return str;
	// };

	const user_rows = [];

	return (
		<Container fluid className="local-bootstrap">
			<Card>
				<Card.Body>
					<div style={{ overflowX: "auto" }}>
						<Table
							striped
							bordered
							hover
							className="clickable-table"
							cellSpacing="0"
						>
							<tbody>
								<tr className="bold-row">
									<th colSpan={4}>
										{users == null
											? "Loading Users ..."
											: `${users.length} Users Online`}
									</th>
								</tr>

								<tr>
									<th>Name</th>
									<th>Email</th>
									<th>Viewing</th>
									<th className="hideable">Actions</th>
								</tr>

								{user_rows}
							</tbody>
						</Table>
					</div>
				</Card.Body>
			</Card>
		</Container>
	);
};

export default UsersOnline;
