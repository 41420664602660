import { createAction } from "@reduxjs/toolkit";
import { extraEntityReducersFunction, loadStatus } from "../entitiesType";

type changeQuoteMultiplierPayload = {
	quoteMultiplier: string;
	quoteId: number;
};

const changeQuoteMultiplier = createAction<changeQuoteMultiplierPayload>('multipliers/changeQuoteMultiplier');

const quoteMultiplierBuilder: extraEntityReducersFunction = (builder) => {
	builder.addCase(changeQuoteMultiplier, (state, action) => {
		const record = state.quoteMulitpliers[action.payload.quoteId];
		if (record) {
			record.currentMultiplier = action.payload.quoteMultiplier;
		} else {
			state.quoteMulitpliers[action.payload.quoteId] = {
				currentMultiplier: action.payload.quoteMultiplier,
				list: [],
				loadStatus: loadStatus.metaLoaded
			};
		}
	});
};

export { quoteMultiplierBuilder, changeQuoteMultiplier };
