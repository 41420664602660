import { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';

import api from '../PowerShadesAPI';
import Button from '../Parts/Button';
import Table from '../Parts/Table';

const RequestedConfigurations = (props) => {
	const {
		setTitle,
		loaded
	} = props;

	const [configurations, setConfigurations] = useState([]);
	const [selectedRequest, setSelectedRequest] = useState(0);
	const [onlyPending, setOnlyPending] = useState(true);
	const allDataOpen = selectedRequest != 0;

	const allDataConfig = configurations.find((c) => c.id == selectedRequest) ?? {};

	const resolveRecipe = (recipeSku) => {
		api.resolveConfigurationRequest(recipeSku).then((resp) => {
			if (resp && resp.data && resp.data.configurationRequests) {
				const items = resp.data.configurationRequests;

				const configurations = items.map((cr) => ({ ...cr.request, sales_person: cr.salesperson.name }));

				setConfigurations(configurations);
			} else {
				ROOT.alert({
					quickFormat: "error", title: "It broke", description: "Resolving this recipe failed. Contract engineering. Unless you are engineering then contact Lucy."
				});
			}
		});
	};

	const unresolveConfigurationRequest = (id) => {
		api.unResolveConfigurationRequest(id).then((resp) => {
			if (resp && resp.data && resp.data.configurationRequests) {
				const items = resp.data.configurationRequests;

				const configurations = items.map((cr) => ({ ...cr.request, sales_person: cr.salesperson.name }));

				setConfigurations(configurations);
			} else {
				ROOT.alert({
					quickFormat: "error", title: "It broke", description: "Resolving this recipe failed. Contract engineering. Unless you are engineering then contact Lucy."
				});
			}
		});
	};

	const configurationAction = (c) => {
		let buttonName = "Resolve";
		let action = () => {
			resolveRecipe(c.recipeSku);
		};

		if (c.status == 'resolved') {
			buttonName = "UnResolve";
			action = () => {
				unresolveConfigurationRequest(c.id);
			};
		}

		return (
			<div>
				<Button color="green" onClick={action}>{buttonName}</Button>
				<Button color="gray" onClick={() => setSelectedRequest(c.id)}>Goodies</Button>
			</div>
		);
	};

	const rows = configurations.filter((c) => (onlyPending ? c.status == 'pending' : true)).map((c) => (
		<tr key={c.id}>
			<td>{c.recipeSku}</td>
			<td>{c.requestedTime}</td>
			<td>{c.status}</td>
			<td>{c.quoteId}</td>
			<td>{c.sales_person}</td>
			<td>{configurationAction(c)}</td>
		</tr>
	));

	useEffect(() => {
		setTitle("Requested Configurations");

		api.getConfigurationRequests().then((resp) => {
			const items = resp.data.configurationRequests;

			const configurations = items.map((cr) => ({ ...cr.request, sales_person: cr.salesperson ? cr.salesperson.name : "" }));

			setConfigurations(configurations);

			loaded();
		});
	}, []);
    
	return (
		<>
			<div className="overflow-x-wrapper">
				<Table style={{ margin: 'auto', maxWidth: '75%', }}>

					<tr className="bold-row">

						<th colSpan={4}>Requested Configurations</th>
						<th colSpan={1}>Show Only Pending (Test Deployed ok)</th>
						<th colSpan={1}><input type="checkbox" onClick={() => setOnlyPending(!onlyPending)} value={onlyPending} /></th>

					</tr>

					<tr>
						<th>Sku</th>
						<th>Date</th>
						<th>Status</th>
						<th>QuoteId</th>
						<th>Sales Person</th>
						<th>Actions</th>
					</tr>

					{rows}

				</Table>
			</div>
			<Popup
				open={allDataOpen}
				onClose={() => setSelectedRequest(0)}
			>
				<>
					<div>
						<h2>
                            Details
							{' '}
							{allDataConfig.recipeSku ?? "Broke, refresh page"}
						</h2>
						<h3>Click on the input box, CTRL-A, and copy to another text page. These strings are too long for the webpage.</h3>
						<br />
						<label>Input</label>
						<input value={allDataConfig.inputJson} />
						<br />
						<label>Output</label>
						<input value={allDataConfig.responseJson} />

					</div>
				</>
			</Popup>

		</>
	);
};

export default RequestedConfigurations;
