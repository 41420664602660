import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { faSunBright, faMoonStars, faFileUser } from "@fortawesome/pro-solid-svg-icons";
import { upperFirst } from "lodash";
import { Col, Dropdown, Row } from "react-bootstrap";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import lightLogo from "../../assets/img/logos/bold/logo_400w_light.png";
import darkLogo from "../../assets/img/logos/bold/logo_400w_dark.png";

import InternalLink from "../InternalLink";
import GotoQuote from "../GoToQuote/GotoQuote";

import { useIsLoggedIn, useLogout, useSetUserTheme, useSupportLinks, useUserTheme } from "../../Store/user/hooks";
import { useIsDealer, useIsManufacturer, useIsPsAdmin, useUserData } from "../../hooks";

import type { HeaderProps } from "./types";

import styles from "./Header.module.scss";
import { getUserLink } from "../../psUtil";
import { useHeaderInjection } from "../../Store/ui/hooks";
import { usePortfolios } from "../../Store/entities/portfolios/hooks";
import { useQuoteById } from "../../Store/entities/quotes/hooks";

const Header = ({ ROOT, title }: HeaderProps) => {
	const userTheme = useUserTheme();
	const setUserTheme = useSetUserTheme();

	// const MINIFY_THRESHOLD = 40;

	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

	// const minified = useScrollPosition(MINIFY_THRESHOLD);

	const isManufacturer = useIsManufacturer();
	const isLoggedIn = useIsLoggedIn();
	const isPsAdmin = useIsPsAdmin();
	const isDealer = useIsDealer();
	const userData = useUserData();
	const userLink = getUserLink(userData.id);
	const headerInjection = useHeaderInjection();

	const closeMobileMenu = () => setMobileMenuOpen(false);

	const logoutUser = useLogout(ROOT);

	const handleUserThemeChange = () => {
		setUserTheme(userTheme === "light" ? "dark" : "light");
	};

	const themeIcon = userTheme === "light" ? faSunBright : faMoonStars;
	const logo = userTheme === "light" ? lightLogo : darkLogo;

	const hasAccessToPaymentMethods = isDealer || isPsAdmin;

	const search = document.location.href.split("?")[1] ?? "";
	const params = new URLSearchParams(search);

	const quoteIDStr = params.get("quoteID") ?? "0"; 
	const quoteID = parseInt(quoteIDStr, 10)
	const quoteIdFromUrl = quoteID;

	const portfolios = usePortfolios() ?? [];

	console.log("quoteIdFromUrl", quoteIdFromUrl);

	

	const quote = useQuoteById(quoteIdFromUrl);

	const supportLinksDirty = useSupportLinks();
	console.log("portfolios", portfolios);
	const quoteLinks = Array.isArray(portfolios) ? (portfolios?.find(s => s.code === quote?.portfolio_code)?.support_links ?? []) : [];

	const supportLinks = Array.isArray(supportLinksDirty) ? supportLinksDirty : [];

	const links = quoteIdFromUrl === 0 ? supportLinks : quoteLinks;

	const linkDropdown = mobileMenuOpen ? (
		<>
		{links.map((link, index) => (
			<span key={index} className="internal-link">
				<a href={link.link} className={styles.noStyleLink} target="_blank">
					{link.display_name}
				</a>
			</span>
		
		))}
		</>
	) : (
		<Dropdown className="dropdown-no-button no-dropdown-arrow header-dropdown">
			<Dropdown.Toggle variant="">Support</Dropdown.Toggle>
			<Dropdown.Menu>
				{
					links.map((link, index) => (
						<Dropdown.Item key={index} href={link.link} target="_blank">
							{link.display_name}
						</Dropdown.Item>
					))
				}
			</Dropdown.Menu>
		</Dropdown>
	)

	return (
		<div className="no-print">
			<div className={`${styles.header} header-menu`}>
				<div className={styles.logoHolder}>
					<InternalLink
						content="#Home"
						onClick={() => {
							if (isLoggedIn) {
								ROOT.sendHome();
							} else {
								ROOT.navigate("/login");
							}
						}}
					>
						<img src={logo} alt="PowerShades Logo" />
					</InternalLink>
				</div>

				<div className={styles.envIndicator}>{upperFirst(process.env.ENV)}</div>

				<button
					type="button"
					className={`${styles.mobileMenuToggle} ${
						mobileMenuOpen && styles.open
					} no-style mobile-menu-toggle`}
					onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
				>
					<div />
					<div>
						<div />
						<div />
					</div>
					<div />
				</button>

				<div className={`${styles.headerMenu} ${mobileMenuOpen && styles.open} header-menu`}>
					{!isLoggedIn ? (
						<InternalLink keepStyle onClick={handleUserThemeChange}>
							{mobileMenuOpen ? "Toggle Theme" : <FontAwesomeIcon icon={themeIcon as IconProp} />}
						</InternalLink>
					) : (
						<>
							{mobileMenuOpen ? (
								<>
									<InternalLink
										keepStyle
										onClick={() => {
											closeMobileMenu();
											logoutUser();
										}}
									>
										Logout
									</InternalLink>
									<InternalLink
										keepStyle
										onClick={() => {
											closeMobileMenu();
											handleUserThemeChange();
										}}
									>
										Toggle Theme
									</InternalLink>
									<InternalLink
										keepStyle
										onClick={() => {
											ROOT.loadContent(userLink);
											closeMobileMenu();
										}}
									>
										My Account
									</InternalLink>
								</>
							) : (
								<Dropdown className="dropdown-no-button no-dropdown-arrow user-accessor">
									<Dropdown.Toggle variant="">
										<FontAwesomeIcon icon={faUser} className="me-2" />
										<div className="d-inline me-2">{userData.name}</div>
										{mobileMenuOpen ? (
											<FontAwesomeIcon icon={faCaretUp} />
										) : (
											<FontAwesomeIcon icon={faCaretDown} />
										)}
									</Dropdown.Toggle>
									<Dropdown.Menu className={styles.userMenu}>
										<Dropdown.Item onClick={() => ROOT.loadContent(userLink)} className="w-100">
											<Row>
												<Col>My Account</Col>
												<Col className="text-end">
													<FontAwesomeIcon icon={faFileUser as IconProp} className="me-2" />
												</Col>
											</Row>
										</Dropdown.Item>
										<Dropdown.Item onClick={handleUserThemeChange}>
											<Row>
												<Col>Toggle Theme</Col>
												<Col className="text-end">
													<FontAwesomeIcon icon={themeIcon as IconProp} className="me-2" />
												</Col>
											</Row>
										</Dropdown.Item>
										<Dropdown.Divider />
										<Dropdown.Item onClick={logoutUser}>
											<Row>
												<Col>Logout</Col>
												<Col className="text-end">
													<FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
												</Col>
											</Row>
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							)}
							<span className="internal-link">
								<a className={styles.noStyleLink} href="https://dashboard.powershades.com">
									Dashboard
								</a>
							</span>
							{isManufacturer ? null : (
								<InternalLink
									keepStyle
									onClick={() => {
										closeMobileMenu();
										ROOT.createQuote();
									}}
								>
									Create a Quote
								</InternalLink>
							)}

							{hasAccessToPaymentMethods ? (
								<InternalLink keepStyle content="/PaymentMethods" onClick={closeMobileMenu}>
									Payment Methods
								</InternalLink>
							) : null}
							{linkDropdown}
							{isPsAdmin ? (
								<InternalLink keepStyle content="/AdminPanel" onClick={closeMobileMenu}>
									Admin Panel
								</InternalLink>
							) : null}

							<InternalLink
								onClick={() => {
									ROOT.sendHome();
									closeMobileMenu();
								}}
								keepStyle
							>
								Home
							</InternalLink>

							{/* <div
								className={`${styles.notificationWrapper} ${!minified && styles.notificationFadeIn}`}
							>
								<NotificationsReader showing={minified} />
							</div> */}
						</>
					)}
				</div>
			</div>

			<Row className={styles.orderFormBanner}>
				<Col className="ms-2 me-2 ms-sm-0 me-sm-0">
					<Row className={isPsAdmin && headerInjection ? "justify-content-end justify-content-lg-start" : ""}>
						<Col
							md={isPsAdmin ? 6 : 12}
							lg={isPsAdmin && headerInjection ? 4 : 7}
							xl={!headerInjection ? 8 : 4}
							className="header-title"
						>
							<h4>{title}</h4>
						</Col>
						{isPsAdmin && headerInjection && (
							<Col md={6} lg={5} xl={5}>
								{headerInjection}
							</Col>
						)}
						{isLoggedIn && (
							<Col
								className={`${styles.searchBar}`}
								md={6}
								lg={isPsAdmin && headerInjection ? 3 : 5}
								xl={!headerInjection ? 4 : 3}
							>
								<GotoQuote isPsAdmin={isPsAdmin} ROOT={ROOT} />
							</Col>
						)}
					</Row>
				</Col>
			</Row>
		</div>
	);
};

Header.whyDidYouRender = true;

export default Header;
// export default React.memo(Header);
