import {  PartCategories, ShadePart } from "../../../powershadesApiTypeExtensions";
import {
	cutPropertiesToDisplayNamesMap,
	type PartsToDisplayCategoriesType,
	CutNameProperties,
	cutPropertiesToBackEndNamesMap,
	CutOption,
} from "./types";

export const partsListInDisplayCategories = (
	parts: ShadePart[],
	partCategories: PartCategories
): PartsToDisplayCategoriesType => ({
	"Header Extrusion": parts.filter((part) =>
		partCategories.is_header_only.includes(part.category)),
	"Side/Sill Channels": parts.filter((part) => partCategories.is_channels_only.includes(part.category)),
	"Tube and Fabric": parts.filter((part) => partCategories.is_tube_and_fabric_only.includes(part.category)),
	Motor: parts.filter((part) => {
		const partInMotorCategory = partCategories.is_motor_only.includes(part.category);
		if (partInMotorCategory) return part;
		const partInCrownAndDriveCategory = partCategories.is_crown_and_drive_only.includes(part.category);
		if (partInCrownAndDriveCategory) return part;
		return false;
	}),
	Hardware: parts.filter((part) => partCategories.is_hardware_kit_only.includes(part.category)),
});

export const transformCutProperties = (shadeAssembly): CutOption[] => {
	const properties = Object.keys(cutPropertiesToDisplayNamesMap) as CutNameProperties[];
	const cutOptions = properties.map((property) => {
		const displayName = cutPropertiesToDisplayNamesMap[property];
		const backEndName = cutPropertiesToBackEndNamesMap[property];
		const cutOptionValue = shadeAssembly[property];
		const cutOption: CutOption = {
			displayName,
			backEndName,
			propertyName: property,
			state: cutOptionValue ?? false,
		};
		return cutOption;
	});
	return cutOptions;
};
