
import { AssemblyStore, ExternalShadeData, IndoorRollDirection, IndoorShadeOptionsDualDict } from "../types";

import InsideFabric from "../../../../assets/img/illus/inside_fabric.png";
import OutsideFabric from "../../../../assets/img/illus/outside_fabric.png";

import StandardRoll from "../../../../assets/img/illus/standard_roll.png";
import ReverseRoll from "../../../../assets/img/illus/reverse_roll.png";

const IndoorShadeOptionsDual: (assembly: AssemblyStore, setAssembly: (assembly: AssemblyStore) => void, externalData: ExternalShadeData) => IndoorShadeOptionsDualDict = (assembly, setAssembly, externalData) => {

    const indoorFabrics = externalData.fabrics;

    const fabOptions = indoorFabrics.map(fab => ({ label: fab.name, value: fab.name, title: fab.name }));

    const setFrontFabric = (newFabricName) => {
        setAssembly({
            ...assembly,
            shades: assembly.shades
            .map(s => s.row_coordinate === 0 ? ({
                ...s,
                fabric_name: newFabricName
            }) : s)
        })
    }

    const setBackFabric = (newFabricName) => {
        setAssembly({
            ...assembly,
            shades: assembly.shades
            .map(s => s.row_coordinate === 1 ? ({
                ...s,
                fabric_name: newFabricName
            }) : s)
        })
    }

    const setFrontRollDirection = (newRollDirection) => {
        setAssembly({
            ...assembly,
            shades: assembly.shades
            .map(s =>  s.row_coordinate === 0 ? ({
                ...s,
                roll_direction: newRollDirection
            }) : s)
        })
    }

    const setBackRollDirection = (newRollDirection) => {
        setAssembly({
            ...assembly,
            shades: assembly.shades
            .map(s => s.row_coordinate === 1 ? ({
                ...s,
                roll_direction: newRollDirection
            }) : s)
        })
    }

    const headers = externalData.assemblyOptions.headers;
    const currentHeader = headers?.find(header => header.name === assembly.header_line);

    const isStandardFrontRollAllowed = currentHeader?.dual_front_standard_allowed ?? true;
    const isReverseFrontRollAllowed = currentHeader?.dual_front_reverse_allowed ?? true;

    const frontRollDirectionOptions = [
        { label: "Standard", value: "standard" as IndoorRollDirection, title: "Standard", isActive: isStandardFrontRollAllowed },
        { label: "Reverse", value: "reverse" as IndoorRollDirection, title: "Reverse", isActive: isReverseFrontRollAllowed }
    ];

    const isStandardBackRollAllowed = currentHeader?.dual_back_standard_allowed ?? true;
    const isReverseBackRollAllowed = currentHeader?.dual_back_reverse_allowed ?? true;

    const backRollDirectionOptions = [
        { label: "Standard", value: "standard" as IndoorRollDirection, title: "Standard", isActive: isStandardBackRollAllowed },
        { label: "Reverse", value: "reverse" as IndoorRollDirection, title: "Reverse", isActive: isReverseBackRollAllowed }
    ];


    const frontShade = (assemblyItem: AssemblyStore) => assemblyItem.shades.find(shade => shade.row_coordinate === 0);
    const backShade = (assemblyItem: AssemblyStore) => assemblyItem.shades.find(shade => shade.row_coordinate === 1);

  
    const indoorShadeOptionsDual: IndoorShadeOptionsDualDict = {
        FrontFabric: {
            label: "Front Fabric",
            options: fabOptions,
            clearValue: () => {
                setFrontFabric("");
            },
            setValue: (value) => {
                setFrontFabric(value);
            },
            getSelectedOption: () => {
                return fabOptions.find(fab => fab.value === frontShade(assembly)?.fabric_name) ?? { label: "", value: "", title: "" };
            },
            varName: "fabric_name",
            error: [],
            info: [],
            getValue: () => frontShade(assembly)?.fabric_name ?? "",
            valuePopup: InsideFabric
        },
        BackFabric: {
            label: "Back Fabric",
            options: fabOptions,
            clearValue: () => {
                setBackFabric("");
            },
            setValue: (value) => {
                setBackFabric(value);
            },
            getSelectedOption: () => {
                return fabOptions.find(fab => fab.value === backShade(assembly)?.fabric_name) ?? { label: "", value: "", title: "" };
            },
            varName: "fabric_name",
            error: [],
            info: [],
            getValue: () => backShade(assembly)?.fabric_name ?? "",
            valuePopup: OutsideFabric
        },
        FrontRollDirection: {
            label: "Front Roll Direction",
            options: frontRollDirectionOptions,
            clearValue: () => {
                setFrontRollDirection("");
            },
            setValue: (value) => {
                setFrontRollDirection(value);
            },
            getSelectedOption: () => {
                return frontRollDirectionOptions.find(roll => roll.value === frontShade(assembly)?.roll_direction) ?? { label: "", value: "" as IndoorRollDirection, title: "" };
            },
            varName: "roll_direction",
            error: [],
            info: [],
            getValue: () => (frontShade(assembly)?.roll_direction ?? "") as IndoorRollDirection,
            valuePopup: frontShade(assembly)?.roll_direction === "standard" ? StandardRoll : ReverseRoll
        },
        BackRollDirection: {
            label: "Back Roll Direction",
            options: backRollDirectionOptions,
            clearValue: () => {
                setBackRollDirection("");
            },
            setValue: (value) => {
                setBackRollDirection(value);
            },
            getSelectedOption: () => {
                return backRollDirectionOptions.find(roll => roll.value === backShade(assembly)?.roll_direction) ?? { label: "", value: "" as IndoorRollDirection, title: "" };
            },
            varName: "roll_direction",
            error: [],
            info: [],
            getValue: () => (backShade(assembly)?.roll_direction ?? "") as IndoorRollDirection,
            valuePopup: backShade(assembly)?.roll_direction === "standard" ? StandardRoll : ReverseRoll
        }
    };

    return indoorShadeOptionsDual;
};

export default IndoorShadeOptionsDual;