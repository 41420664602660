import React, { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import PowerShadesTypeahead from '../Parts/PowerShadesTypeahead';

const TerritoryAssign = ({
	territories = [],
	territoryId,
	setTerritory,
	setDealerId,
	repriceQuote,
	editable,
	className,
	extraMargin
}) => {
	const [currentVal, setCurrentVal] = useState(territoryId);

	useEffect(() => {
		setCurrentVal(territoryId);
	}, [territoryId]);

	const changedTerritory = (newTerr) => {
		const selectedTerritory = newTerr[0] ?? { value: 0 };
		setTerritory(selectedTerritory.value);
		setDealerId({ value: 0 });

		repriceQuote();

		setCurrentVal(selectedTerritory.value);
	};

	const options = territories
		.map((territory) => ({ label: territory.name, value: territory.id }))
		.sort((s1, s2) => s1.label.localeCompare(s2.label));

	const currentTerritory = territories.find((territory) => parseInt(territory.id, 10) === currentVal);

	const transformedTerritory = currentTerritory
		? { label: currentTerritory.name, value: currentTerritory.id }
		: { label: '', value: 0 };

	return (
		<div className={`${className} ${extraMargin ? 'mb-1' : null}`} style={{ width: '100%', marginTop: '0.5em' }}>
			<label className={extraMargin ? 'mb-1' : null} htmlFor='territory-assign'>Territory</label>
			<br />
			<PowerShadesTypeahead
				clearButton
				id="territory-assign"
				options={options}
				// value={
				// 	currentTerritory
				// 		? { label: currentTerritory.name, value: currentTerritory.id }
				// 		: null
				// }
				selected={currentTerritory ? [transformedTerritory] : []}
				onChange={(newTerr) => changedTerritory(newTerr)}
				disabled={!editable}
				placeholder="Assign to Territory"
			/>
		</div>
	);
};

export default TerritoryAssign;
