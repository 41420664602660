import Button from '../Parts/Button';
import { ShipmentModalRow, ShipmentModalHeadRow } from './ShipmentModalRows';

const ShipmentSummaryModal = (props) => {
	const { shipments } = props.quote;

	const link_formats = {

		fedex: (tracking_number) => `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${tracking_number}`,
		ups: (tracking_number) => `https://www.ups.com/track?loc=en_US&tracknum=${tracking_number}`,
		usps: (tracking_number) => `https://tools.usps.com/go/TrackConfirmAction?tLabels=${tracking_number}`,
	};

	return (

		<div className="modal-holder">

			<div className="shipment-modal">
			
				<h1>Shipments Overview</h1>

				<table className="order-confirmation-table" cellSpacing="0">
					<tbody>

						<ShipmentModalHeadRow quote={props.quote} />
						<ShipmentModalRow quote={props.quote} />
					
					</tbody>
				</table>

				<table className="order-confirmation-table" cellSpacing="0">
					<tbody>
						<tr className="bold-row">
							<th>Date Shipped</th>
							<th>Carrier</th>
							<th>Tracking Code</th>
						</tr>

						{

							shipments.map((s) => {
								const lc_carrier = s.carrier.toLowerCase().trim();
								const link = (link_formats[lc_carrier] != null && (`${s.tracking_number}`).length > 0) ? <a href={link_formats[lc_carrier](s.tracking_number)} target="_blank" rel="noreferrer">{s.tracking_number}</a> : s.tracking_number;
							
								return (
									<tr key={s.id}>
										<td>{s.date}</td>
										<td>{s.carrier}</td>
										<td>{(`${link}`).length > 0 ? link : 'N/A'}</td>
									</tr>
								);
							})
						}

					</tbody>
				</table>

				<div className="button-holder">

					<Button color="gray" onClick={props.onClose}>
						{props.quote.is_shipped ? 'Close' : 'Cancel'}
					</Button>
				</div>

			</div>

		</div>
	);
};

export default ShipmentSummaryModal;
