import { createAsyncThunk } from "@reduxjs/toolkit";
import { extraEntityReducersFunction, loadStatus } from "../entitiesType";
import apiCalls from "../../../PowerShadesAPIFunctions";
import type { RMADepartmentType, RMARootCauseType, RMAType } from "../../../powershadesApiTypes";

// const initialRmaTypeState: () => RMAType = () => {
// 	const initRmaType: RMAType = {
// 		id: 0,
// 		name: '',
// 		description: '',
// 	};

// 	return initRmaType;
// };

type LoadRMATypes =
{
	types: RMAType[];
	departments: RMADepartmentType[];
	root_causes: RMARootCauseType[];
}

export const loadRmaTypesFull = createAsyncThunk<
	LoadRMATypes,
	void,
	{
		rejectValue: boolean;
	}
>('entities/GetRmaTypeList', async (_void, { rejectWithValue }) => {
	let rmaTypes: RMAType[] = [];
	let departmentTypes: RMADepartmentType[] = [];
	let rootCauseTypes: RMARootCauseType[] = [];

	try {
		const resp = await apiCalls.getRMATypes();

		rmaTypes = resp.data.rma_types ?? [];
		departmentTypes = resp.data.rma_departments ?? [];
		rootCauseTypes = resp.data.rma_root_causes ?? [];
	} catch (err: any) {
		// let _error: AxiosError = err;

		return rejectWithValue(false);
	}

	return {
		types: rmaTypes,
		departments: departmentTypes,
		root_causes: rootCauseTypes
	};
});

export const addRMAType = createAsyncThunk<
	RMAType,
	RMAType,
	{
		rejectValue: boolean;
	}
>('entities/AddRmaType', async (rmaTypeToSave, { rejectWithValue }) => {
	let newRmaType;

	try {
		const resp = await apiCalls.addRMAType(rmaTypeToSave);

		const { success, new_rma_type: newRmaTypeFromBe } = resp.data;

		newRmaType = newRmaTypeFromBe;
		
		if (!success) {
			return rejectWithValue(false);
		}
	} catch (err: any) {
		// let _error: AxiosError = err;

		return rejectWithValue(false);
	}

	return newRmaType;
});

export const archiveRMAType = createAsyncThunk<
	boolean,
	number,
	{
		rejectValue: boolean;
	}
>('entities/ArchiveRmaType', async (rmaTypeId, { rejectWithValue }) => {
	let success = false;

	try {
		const resp = await apiCalls.archiveRMAType(rmaTypeId);

		success = resp.data.success;

		if (!success) {
			return rejectWithValue(false);
		}
	} catch (err: any) {
		// let _error: AxiosError = err;

		return rejectWithValue(false);
	}

	return success;
});

export const addRMADepartment = createAsyncThunk<
	RMADepartmentType,
	RMADepartmentType,
	{
		rejectValue: boolean;
	}
>('entities/AddRmaDepartment', async (departmentToSave, { rejectWithValue }) => {
	let newDepartment;

	try {
		const resp = await apiCalls.addRMADepartment(departmentToSave.name, departmentToSave.description);

		const { success, new_rma_department: newDepartmentFromBe } = resp.data;

		newDepartment = newDepartmentFromBe;

		if (!success) {
			return rejectWithValue(false);
		}
	} catch (err: any) {
		// let _error: AxiosError = err;

		return rejectWithValue(false);
	}

	return newDepartment;
});

export const archiveRMADepartment = createAsyncThunk<
	boolean,
	number,
	{
		rejectValue: boolean;
	}
>('entities/ArchiveRmaDepartment', async (departmentId, { rejectWithValue }) => {
	let success = false;

	try {
		const resp = await apiCalls.archiveRMADepartment(departmentId);

		success = resp.data.success;

		if (!success) {
			return rejectWithValue(false);
		}
	} catch (err: any) {
		// let _error: AxiosError = err;

		return rejectWithValue(false);
	}

	return success;
});

export const addRMARootCause = createAsyncThunk<
	RMARootCauseType,
	RMARootCauseType,
	{
		rejectValue: boolean;
	}
>('entities/AddRmaRootCause', async (rootCauseToSave, { rejectWithValue }) => {
	let newRootCause;

	try {
		const resp = await apiCalls.addRMARootCause(rootCauseToSave.name, rootCauseToSave.description);

		const { success, new_rma_root_cause: newRootCauseFromBe } = resp.data;

		newRootCause = newRootCauseFromBe;

		if (!success) {
			return rejectWithValue(false);
		}
	} catch (err: any) {
		// let _error: AxiosError = err;

		return rejectWithValue(false);
	}

	return newRootCause;
});

export const archiveRMARootCause = createAsyncThunk<
	boolean,
	number,
	{
		rejectValue: boolean;
	}
>('entities/ArchiveRmaRootCause', async (rootCauseId, { rejectWithValue }) => {
	let success = false;

	try {
		const resp = await apiCalls.archiveRMARootCause(rootCauseId);

		success = resp.data.success;

		if (!success) {
			return rejectWithValue(false);
		}
	} catch (err: any) {
		// let _error: AxiosError = err;

		return rejectWithValue(false);
	}

	return success;
});





export const rmaTypeBuilder: extraEntityReducersFunction = (builder) => {
	builder.addCase(loadRmaTypesFull.fulfilled, (state, action) => {
		const rmaTypes = action.payload;
		state.rmaTypes.list = [...rmaTypes.types];
		state.rmaTypes.departments = [...rmaTypes.departments];
		state.rmaTypes.root_causes = [...rmaTypes.root_causes];

		state.rmaTypes.loadStatus = loadStatus.fullyLoaded;
		return state;
	});

	builder.addCase(loadRmaTypesFull.pending, (state) => {
		state.rmaTypes.loadStatus = loadStatus.loading;
		return state;
	});

	builder.addCase(addRMAType.fulfilled, (state, action) => {
		const newRmaType = { ...action.payload };
		state.rmaTypes.list = [...state.rmaTypes.list, newRmaType];
		return state;
	});

	builder.addCase(archiveRMAType.fulfilled, (state, action) => {
		state.rmaTypes.list = state.rmaTypes.list.filter((rmaType) => rmaType.id !== action.meta.arg);

		return state;
	});

	builder.addCase(addRMADepartment.fulfilled, (state, action) => {
		const newDepartment = { ...action.payload };
		state.rmaTypes.departments = [...state.rmaTypes.departments, newDepartment];
		return state;
	});

	builder.addCase(archiveRMADepartment.fulfilled, (state, action) => {
		state.rmaTypes.departments = state.rmaTypes.departments.filter((department) => department.id !== action.meta.arg);

		return state;
	});

	builder.addCase(addRMARootCause.fulfilled, (state, action) => {
		const newRootCause = { ...action.payload };
		state.rmaTypes.root_causes = [...state.rmaTypes.root_causes, newRootCause];
		return state;
	});

	builder.addCase(archiveRMARootCause.fulfilled, (state, action) => {
		state.rmaTypes.root_causes = state.rmaTypes.root_causes.filter((rootCause) => rootCause.id !== action.meta.arg);

		return state;
	});
};
