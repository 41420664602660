/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @stylistic/max-len */
/* eslint-disable @stylistic/jsx-indent */
/* eslint-disable @stylistic/indent */
import Popup from "reactjs-popup";
import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../Parts/Button";
import { UsePortalSelector } from "../../../../Store";
import { InitialAssemblyState } from "../../../../Store/entities/assemblies";
import { useAssembliesByQuoteId } from "../../../../Store/entities/assemblies/hooks";

import { useQuoteById, useQuoteIdAndDispatch, useQuoteTypeNameByQuoteId } from "../../../../Store/entities/quotes/hooks";
import PriceErrors from "../../../../Quote/PriceErrors";
import apiCalls from "../../../../PowerShadesAPIFunctions";
import { useNewIndoorShadeOptions } from "../../../../Store/entities/assemblies/IndoorHooks.ts";
import { AssemblyStore, AssemblyViewModelFromAssemblyStore } from "../../../../Store/entities/assemblies/types";
import { useAssemblyErrorsByStore } from "../../../../Store/entities/assemblies/errors/hooks";
import { useQuoteCombination } from "../../../../Store/entities/combinations/hooks";
import { initialQuote } from "../../../../Store/entities/quotes";
import { useIndoorFabrics } from "../../../../Store/entities/hooks";
import { useUser } from "../../../../Store/user/hooks";
import { useAssemblyOptions } from "../../../../Store/entities/assemblyOptions/hooks";
import EditIndoorShadeModalInner from "./EditIndoorShadeInner";
// import { FabricViewModel, loadStatusType } from "../../../../powershadesApiTypes";

type EditIndoorShadeModalBaseProps = {
	isOpen: boolean;
	onClose: () => void;
	quoteId: number;
	assembly: AssemblyStore;
	setAssembly: (assembly: AssemblyStore) => void;
	isNotAdd?: boolean;
	massEdit?: boolean | undefined;
};

const EditIndoorShadeModalBase = ({ isOpen, onClose, assembly: incomingAssembly, setAssembly: setAssemblyIncoming, isNotAdd = true, massEdit = false }: EditIndoorShadeModalBaseProps) => {
    const [assembly, setAssembly] = React.useState(incomingAssembly);
    const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
    const popupRef = useRef<HTMLDivElement | null>(null);

    const quoteId = assembly.quote_id ?? 0;

    const indoorFabrics = useIndoorFabrics(quoteId);
    // const [allowedIndoorFabrics, setAllowedIndoorFabrics] = useState<FabricViewModel[]>([]);

    const user = useUser();
    const quote = useQuoteById(quoteId);
    const assemblyOptions = UsePortalSelector(useAssemblyOptions(quoteId));

//    // Fetch allowed indoor fabrics from the backend
//    useEffect(() => {
//     const fetchAllowedIndoorFabrics = async () => {
//         try {
//             const response = await apiCalls.getFabricIndoorAllowedList(quoteId);
//             setAllowedIndoorFabrics(response.data.fabrics ?? []); // Store the fetched fabrics in state
//         } catch (error) {
//             console.error("Error fetching allowed indoor fabrics:", error);
//         }
//     };

//     fetchAllowedIndoorFabrics();
//     }, [quoteId]);

//     // Combine fetched allowed fabrics with the indoor fabrics
//     // Mapping to FabricStore when is FabricViewModel
//     const combinedIndoorFabrics = [
//         ...indoorFabrics,
//         ...allowedIndoorFabrics.map(fabric => ({
//             name: fabric.name,
//             imageUrl: fabric.image_url || "", 
//             imageLoaded: 1 as loadStatusType, 
//             isNextLoad: false, 
//             fabric_color: "", 
//             fabric_pattern: "", 
//             is_indoor: fabric.is_indoor,
//             is_outdoor: fabric.is_outdoor,
//             is_psoutdoor: fabric.is_psoutdoor,
//             is_railroadable: fabric.is_railroadable,
//             is_seamable: fabric.is_seamable,
//             is_discontinued: fabric.is_discontinued,
//             is_gone: fabric.is_gone
//         }))
//     ];



    const shadeOptions = useNewIndoorShadeOptions({ ...InitialAssemblyState(), ...assembly }, setAssembly, { fabrics: indoorFabrics, user, quote, assemblyOptions }, massEdit);

    const quoteType = useQuoteTypeNameByQuoteId(quoteId);

    const closeAll = () => {
        setIsColorPickerOpen(false);
        onClose();
    };

    useEffect(() => {
        setAssembly(incomingAssembly);
    }, [isOpen]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                closeAll();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [popupRef, onClose]);

    const submitShade = () => {
        setAssemblyIncoming(assembly);
        closeAll();
    };

    const [msrp, setMsrp] = React.useState(assembly.msrp);
    const [errorsFromBackend, setErrors] = React.useState(assembly.error_messages ?? "");

    const quoteStore = useQuoteIdAndDispatch(quoteId);

    const assemblies = useAssembliesByQuoteId(quoteId);

    const quoteCombinations = useQuoteCombination(quoteId);

    const feErrors = useAssemblyErrorsByStore(assembly, quoteStore ?? initialQuote(), assemblies, quoteCombinations ?? { Fascias: [] }, shadeOptions);

    let errorList = [
        ...feErrors.filter(e => !errorsFromBackend.includes(e.message.trim())).map((error) => error.message),
        
    ];

    if (errorsFromBackend !== "") {
        errorList = [ errorsFromBackend];
    }

    // Grab errors, delete extra commas, and join them into a single string
    const errors = massEdit ? "" : errorList.filter((error) => error !== "").reduce((acc, error) => `${acc + error} `, "");
    const feErrorsToSend = massEdit ? [] : feErrors;
    useEffect(() => {   
        if (quoteId === 0 || !isOpen) return;
        setMsrp(0);
        setErrors("");
        apiCalls.calculateAssemblyPriceNew(AssemblyViewModelFromAssemblyStore(assembly), quoteId, "Indoor").then((res) => {
            setMsrp(res?.data?.msrp ?? 0);
            const errorMessage = res?.data?.errors.map(e => e.message);
            const errorMessageListAsOneString = errorMessage?.join(", ") ?? "";
            setErrors(errorMessageListAsOneString);
        });
    }, [assembly, isOpen]);

    if (!isOpen) return null;

    return (
        <Popup open={isOpen} onClose={closeAll} className="mobile-modal p-2 m-2">
                <Row>
                    {
                        isNotAdd
                            ? <h2>Edit Shade</h2>
                            : <h2>Add Shade</h2>
                    }
                </Row>
                <EditIndoorShadeModalInner
                    options={shadeOptions}
                    quoteType={quoteType}
                    isNotAdd={isNotAdd}
                    massEdit={massEdit}
                    unlocked={assembly.unlocked}
                    errors={feErrorsToSend}
                    pickerState={isColorPickerOpen}
                    setPickerState={setIsColorPickerOpen}
                />
                {
                    !isColorPickerOpen && (
                        <PriceErrors quoteId={quoteId} msrp={errors.length > 0 ? 0 : msrp} error_messages={errors} assembly={assembly} noLoading={true} />
                    )
                }
                <Col className="d-flex flex-column align-items-center justify-content-center">
                    <Button
                    className="col-lg-4"
                        onClick={(
                            isColorPickerOpen
                            ? () => setIsColorPickerOpen(false)
                            : submitShade)}
                    >
                        Done
                    </Button>
                {
                    (!assembly.unlocked && isNotAdd)
                        ? <Button className="col-lg-4 mt-1" onClick={() =>{
                             setAssembly({ ...assembly, unlocked: true }); 
                             }}>
                                <FontAwesomeIcon icon={faLock} />
                            </Button>
                        : null
                }
                </Col>
        </Popup>
    );
};

export default EditIndoorShadeModalBase;
