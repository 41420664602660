import { InitialAssemblyState, updateAssembly } from ".";
import store, { UsePortalSelector } from "../..";
import { useUser } from "../../user/hooks";
import { useAssemblyOptions } from "../assemblyOptions/hooks";
import { useOutdoorFabrics } from "../hooks";
import { useQuoteById } from "../quotes/hooks";
import { UseAssembly } from "./hooks";
import { AssemblyStore, CORDriveType, CORHeaderTypeSku, COROutdoorColor, COROutdoorControlSide, COROutdoorHeaderMountType,
    //  COROutdoorHembarType, 
     COROutdoorShadeOptionsDict, COROutdoorShadeOptionsFunc, CORSideChannels, CardinalDirectionType, CurrentCOROutdoorShadeOptionsFunc } from "./types";

const useCurrentCOROutdoorHooks: CurrentCOROutdoorShadeOptionsFunc = (quoteId)=> (sequenceId) => {
    const assembly = UseAssembly(quoteId, sequenceId) ?? InitialAssemblyState();

    const setAssembly = (assembly: AssemblyStore) => {
        store.dispatch(updateAssembly({
            quoteId,
            sequenceId,
            assembly
        }))
        console.log(assembly);
    }

    const corFabrics = useOutdoorFabrics(quoteId);
    const user = useUser();
    const quote = useQuoteById(quoteId);

    const assemblyOptions = UsePortalSelector(useAssemblyOptions(quoteId));

    return useNewCOROutdoorHooks(assembly, setAssembly, { fabrics: corFabrics, user, quote, assemblyOptions });
}

const useNewCOROutdoorHooks: COROutdoorShadeOptionsFunc = (assembly, setAssembly, {fabrics: outdoorFabrics}) => {

    const outdoorFabricOptions = outdoorFabrics.map(fab => ({ label: fab.name, value: fab.name, title: fab.name }));

    const driveTypeOptions = [
        { label: "Motorized", value: "motorized" as CORDriveType, title: "Motorized" }
    ];

    const headerMountTypeOptions = [
        { label: "Ceiling", value: "Ceiling" as COROutdoorHeaderMountType, title: "Ceiling" },
        { label: "Wall", value: "Wall" as COROutdoorHeaderMountType, title: "Wall" }
    ];

    const headerTypeOptions = [
        { label: "Outdoor Harmony Premium Square Front", value: "outdoor_harmony_premium_square_front" as CORHeaderTypeSku, title: "Outdoor Harmony Premium Square Front" },
        { label: "Outdoor Harmony Standard Round Front", value: "outdoor_harmony_standard_round_front" as CORHeaderTypeSku, title: "Outdoor Harmony Standard Round Front" },
        { label: "Outdoor Apex", value: "outdoor_apex" as CORHeaderTypeSku, title: "Outdoor Apex" },
        { label: "Outdoor Meridian", value: "outdoor_meridian" as CORHeaderTypeSku, title: "Outdoor Meridian" }
    ];

    const colorOptions = [
        { label: "Anthracite", value: "outdoor_anthracite" as COROutdoorColor, title: "Outdoor Anthracite" },
        { label: "Carbon", value: "outdoor_carbon" as COROutdoorColor, title: "Outdoor Carbon" },
        { label: "Corten", value: "outdoor_corten" as COROutdoorColor, title: "Outdoor Corten" },
        { label: "Dune", value: "outdoor_dune" as COROutdoorColor, title: "Outdoor Dune" },
        { label: "Glacier", value: "outdoor_glacier" as COROutdoorColor, title: "Outdoor Glacier" },
        { label: "Granite", value: "outdoor_granite" as COROutdoorColor, title: "Outdoor Granite" },
        { label: "Urban Bronze", value: "outdoor_urban_bronze" as COROutdoorColor, title: "Outdoor Urban Bronze" },
        { label: "White Quartz", value: "outdoor_white_quartz" as COROutdoorColor, title: "Outdoor White Quartz" }
    ];

    const controlSideOptions = [
        { label: "Left", value: "Left" as COROutdoorControlSide, title: "Left" },
        { label: "Right", value: "Right" as COROutdoorControlSide, title: "Right" }
    ];

    // const hembarTypeOptions = [
    //     { label: "Heavy Duty Bottom Bar", value: "outdoor_heavy_duty_bottom_bar" as COROutdoorHembarType, title: "Heavy Duty Bottom Bar" }
    // ];

    const sideGuidesOptions = [
        { label: "Lightning Track", value: "outdoor_lightning_track" as CORSideChannels, title: "Lightning Track" },
        { label: "Wall Mount Cable", value: "outdoor_wall_mount_cable" as CORSideChannels, title: "Wall Mount Cable" },
        { label: "Floor Mount Cable", value: "outdoor_floor_mount_cable" as CORSideChannels, title: "Floor Mount Cable" }
    ];

    const directionFacingOptions = [
        { label: "North", value: "North" as CardinalDirectionType, title: "North" },
        { label: "South", value: "South" as CardinalDirectionType, title: "South" },
        { label: "East", value: "East" as CardinalDirectionType, title: "East" },
        { label: "West", value: "West" as CardinalDirectionType, title: "West" },
        { label: "Northeast", value: "Northeast" as CardinalDirectionType, title: "Northeast" },
        { label: "Northwest", value: "Northwest" as CardinalDirectionType, title: "Northwest" },
        { label: "Southeast", value: "Southeast" as CardinalDirectionType, title: "Southeast" },
        { label: "Southwest", value: "Southwest" as CardinalDirectionType, title: "Southwest" },
        { label: "Unknown", value: "Unknown" as CardinalDirectionType, title: "Unknown" }
    ];

    const setDriveType = (newDriveType) => {
        setAssembly({
            ...assembly,
            shades: assembly.shades.map(s => ({
                ...s,
                shade_type: newDriveType
            }))
        })
    }

    const setHeaderMountType = (newHeaderMountType) => {
        setAssembly({
            ...assembly,
            mount_style: newHeaderMountType
        })
    }

    const setWidth = (newWidth) => {
        setAssembly({
            ...assembly,
            shades: assembly.shades.map(s => ({
                ...s,
                width: newWidth
            }))
        })
    }

    const setHeight = (newHeight) => {
        setAssembly({
            ...assembly,
            shades: assembly.shades.map(s => ({
                ...s,
                height: newHeight
            }))
        })
    }

    const setColor = (newColor) => {
        setAssembly({
            ...assembly,
            hardware_color: newColor
        })
    }

    const setControlSide = (newControlSide) => {
        setAssembly({
            ...assembly,
            control_side: newControlSide
        })
    }

    const setFabric = (newFabric) => {
        setAssembly({
            ...assembly,
            shades: assembly.shades.map(s => ({
                ...s,
                fabric_name: newFabric
            }))
        })
    }

    // const setHembarType = (newHembarType) => {
    //     setAssembly({
    //         ...assembly,
    //         shades: assembly.shades.map(s => ({
    //             ...s,
    //             hembar_type: newHembarType
    //         }))
    //     })
    // }

    const setSideGuides = (newSideGuides) => {
        setAssembly({
            ...assembly,
            side_channels: newSideGuides
        })
    }

    const setDirectionFacing = (newDirectionFacing) => {
        setAssembly({
            ...assembly,
            direction_facing: newDirectionFacing
        })
    }

    const setRoomName = (newRoomName) => {
        setAssembly({
            ...assembly,
            room_name: newRoomName
        })
    }

    const setShadeName = (newShadeName) => {
        setAssembly({
            ...assembly,
            shade_name: newShadeName
        })
    }

    const setFloor = (newFloor) => {
        setAssembly({
            ...assembly,
            floor: newFloor
        })
    }

    const setHeaderType = (newHeaderType) => {
        setAssembly({
            ...assembly,
            header_line: newHeaderType
        })
    }










    const outdoorShadeOptions: COROutdoorShadeOptionsDict = {
        DriveType: {
            label: "Drive Type",
            options: driveTypeOptions,
            clearValue: () => {
                setDriveType("");
            },
            setValue: (value) => {
                setDriveType(value);
            },
            getSelectedOption: () => {
                return driveTypeOptions.find(drive => drive.value === assembly.shades[0]?.shade_type) ?? { label: "", value: "" as CORDriveType, title: "" };
            },
            varName: "drive_type",
            error: [],
            info: [],
            getValue: () => (assembly.shades[0]?.shade_type ?? "") as CORDriveType,
        },
        HeaderMountType: {
            label: "Mount Type",
            options: headerMountTypeOptions,
            clearValue: () => {
                setHeaderMountType("");
            },
            setValue: (value) => {
                setHeaderMountType(value);
            },
            getSelectedOption: () => {
                return headerMountTypeOptions.find(header => header.value === assembly.mount_style) ?? { label: "", value: "" as COROutdoorHeaderMountType, title: "" };
            },
            varName: "header_mount_type",
            error: [],
            info: [],
            getValue: () => (assembly.mount_style ?? "") as COROutdoorHeaderMountType,
        },
        Width: {
            label: "Width",
            options: [],
            clearValue: () => {
                setWidth(0);
            },
            setValue: (value) => {
                setWidth(value);
            },
            getSelectedOption: () => {
                return { label: "", value: 0, title: "" };
            },
            varName: "width",
            error: [],
            info: [],
            getValue: () => assembly.shades[0]?.width ?? 0,
            maxValue: 240,
            minValue: 0
        },
        Height: {
            label: "Height",
            options: [],
            clearValue: () => {
                setHeight(0);
            },
            setValue: (value) => {
                setHeight(value);
            },
            getSelectedOption: () => {
                return { label: "", value: 0, title: "" };
            },
            varName: "height",
            error: [],
            info: [],
            getValue: () => assembly.shades[0]?.height ?? 0,
            maxValue: 240,
            minValue: 0
        },
        HeaderType: {
            label: "Header Type",
            options: headerTypeOptions,
            clearValue: () => {
                setHeaderType("");
            },
            setValue: (value) => {
                setHeaderType(value);
            },
            getSelectedOption: () => {
                return headerTypeOptions.find(header => header.value === assembly.header_line) ?? { label: "", value: "" as CORHeaderTypeSku, title: "" };
            },
            varName: "header_line",
            error: [],
            info: [],
            getValue: () => (assembly.header_line ?? "") as CORHeaderTypeSku,
        },
        Color: {
            label: "Color",
            options: colorOptions,
            clearValue: () => {
                setColor("");
            },
            setValue: (value) => {
                setColor(value);
            },
            getSelectedOption: () => {
                return colorOptions.find(color => color.value === assembly.hardware_color) ?? { label: "", value: "" as COROutdoorColor, title: "" };
            },
            varName: "color",
            error: [],
            info: [],
            getValue: () => (assembly.hardware_color ?? "") as COROutdoorColor,
        },
        ControlSide: {
            label: "Control Side",
            options: controlSideOptions,
            clearValue: () => {
                setControlSide("");
            },
            setValue: (value) => {
                setControlSide(value);
            },
            getSelectedOption: () => {
                return controlSideOptions.find(control => control.value === assembly.control_side) ?? { label: "", value: "" as COROutdoorControlSide, title: "" };
            },
            varName: "control_side",
            error: [],
            info: [],
            getValue: () => (assembly.control_side ?? "") as COROutdoorControlSide,
        },
        Fabric: {
            label: "Fabric",
            options: outdoorFabricOptions,
            clearValue: () => {
                setFabric("");
            },
            setValue: (value) => {
                setFabric(value);
            },
            getSelectedOption: () => {
                return outdoorFabricOptions.find(fab => fab.value === assembly.shades[0]?.fabric_name) ?? { label: "", value: "", title: "" };
            },
            varName: "fabric_name",
            error: [],
            info: [],
            getValue: () => assembly.shades[0]?.fabric_name ?? "",
        },
        // HembarType: {
        //     label: "Hembar Type",
        //     options: hembarTypeOptions,
        //     clearValue: () => {
        //         setHembarType("");
        //     },
        //     setValue: (value) => {
        //         setHembarType(value);
        //     },
        //     getSelectedOption: () => {
        //         return hembarTypeOptions.find(hembar => hembar.value === assembly.shades[0]?.hembar_type) ?? { label: "", value: "" as COROutdoorHembarType, title: "" };
        //     },
        //     varName: "hembar_type",
        //     error: [],
        //     info: [],
        //     getValue: () => (assembly.shades[0]?.hembar_type ?? "") as COROutdoorHembarType,
        // },
        SideGuides: {
            label: "Side Guides",
            options: sideGuidesOptions,
            clearValue: () => {
                setSideGuides("");
            },
            setValue: (value) => {
                setSideGuides(value);
            },
            getSelectedOption: () => {
                return sideGuidesOptions.find(side => side.value === assembly.side_channels) ?? { label: "", value: "" as CORSideChannels, title: "" };
            },
            varName: "side_channels",
            error: [],
            info: [],
            getValue: () => (assembly.side_channels ?? "") as CORSideChannels,
        },
        DirectionFacing: {
            label: "Direction Facing",
            options: directionFacingOptions,
            clearValue: () => {
                setDirectionFacing("");
            },
            setValue: (value) => {
                setDirectionFacing(value);
            },
            getSelectedOption: () => {
                return directionFacingOptions.find(direction => direction.value === assembly.direction_facing) ?? { label: "", value: "" as CardinalDirectionType, title: "" };
            },
            varName: "direction_facing",
            error: [],
            info: [],
            getValue: () => (assembly.direction_facing ?? "") as CardinalDirectionType,
        },
        RoomName: {
            label: "Room Name",
            options: [],
            clearValue: () => {
                setRoomName("");
            },
            setValue: (value) => {
                setRoomName(value);
            },
            getSelectedOption: () => {
                return { label: "", value: "", title: "" };
            },
            varName: "room_name",
            error: [],
            info: [],
            getValue: () => assembly.room_name ?? "",
        },
        ShadeName: {
            label: "Shade Name",
            options: [],
            clearValue: () => {
                setShadeName("");
            },
            setValue: (value) => {
                setShadeName(value);
            },
            getSelectedOption: () => {
                return { label: "", value: "", title: "" };
            },
            varName: "shade_name",
            error: [],
            info: [],
            getValue: () => assembly.shade_name ?? "",
        },
        Floor: {
            label: "Floor",
            options: [],
            clearValue: () => {
                setFloor(0);
            },
            setValue: (value) => {
                setFloor(value);
            },
            getSelectedOption: () => {
                return { label: "", value: 0, title: "" };
            },
            varName: "floor",
            error: [],
            info: [],
            getValue: () => assembly.floor ?? 0,

        },
        Colors: []
    }

    Object.values(outdoorShadeOptions).forEach(dict => {
        
        if(Array.isArray(dict))
        {
            dict.forEach((opt) => {
                opt.active = opt.active ?? true;
                opt.options?.forEach((opt) => {
                    opt.isActive = opt.isActive ?? true;
                })
            })

        } else {
            dict.active = dict.active ?? true;
            dict.options?.forEach((opt) => {
                opt.isActive = opt.isActive ?? true;
            })
        }

       
    });

    return outdoorShadeOptions;
}
export {
    useCurrentCOROutdoorHooks
}

export default useNewCOROutdoorHooks;