/* eslint-disable max-len */ // Long comments.
import { createAction, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { AppDispatch, AppState } from "../..";
import { extraEntityReducersFunction, loadStatus } from "../entitiesType";
import { AssemblyStore, AssemblyStoreFromAssemblyViewModel, AssemblyViewModelFromAssemblyStore, } from "./types";
import { SelectAssembly, selectAssemblyListByQuoteId } from "./hooks";

import { AssemblyViewModel, DuplicateAssemblyInputs, ServiceScreenVariables, ShadeViewModel } from "../../../powershadesApiTypes";
import { captureSentryError, extractErrorMessage, isFailedApiCall } from "../../../psUtil";
import apiCalls from "../../../PowerShadesAPIFunctions";
import { ThunkAPI } from "../types";
import { wentToQuote } from "../quotes";

/*
	My justification for this system is not important. Here's how it works.

	Assemblies[QuoteId] returns the assembies attatched to a quote, in terms of a dictionary by sequence id to assembly object.

	So, to get the Assembly that has a sequence id of 5, and a quote id of 12543, you would use state.entities.assemblies[12543][5];    

	All assembly endpoints are based on sequence id and quote id. Think of the quote id combined with the sequence id to be
	the front end's way to have an effective combined primary key specific to each assembly.

	===

	Assemblies have Shades. A common term for Assemblies is ShadeAssemblies. ShadeAssemblies == Assemblies. ShadeAssemblies != Shades.

	Shades refer to the actual shade band, or covering (if shutters get added in). Width and length are stored at the shade level (along
	with other band specific properties) because there are assemblies that have more than one band, namely coupled and dual shades.

	Shades each have a row value and column value. 

					Top-Down View
						Window
		  ________________________________________________________
 Rows    / _______________   _______________   _______________   /
  1     / C_______________C C_______*_______C C_______________C /
	   /                                                       /
	  / _______________   _______________   _______________   /
  0  / C_______________C C_______________C C_______________C /
	/                                                       /
 Columns      0                   1                  2

						Room

	In this example, this assembly is both Dual and Coupled. The picture as a whole is an assembly, each individual band is a shade.
	
	Dual means there is a front fabric/tube and that there is a back fabric/tube. At
	the time of writing this, dual shades only have two differences between the front (row 0, closer to the room/inside) row, 
	and the back row (row 1, closer to the window./outside). 

	Coupled means there are multiple bands per row. The number of bands are consistent between rows, which is nice for the people
	coding the different cases. A coupled system can have (theoretically) anywhere between 2 and 10 bands (just to make some limits).
	Shades coupled together have the same Fabric, mostly because of the real world constraint that differing fabric widths will
	result in an un-level shade either at the top or the bottom.

	The row and column coordinates plus the assembly sequence id + the quote id are the effective primary key for all Shades to the
	front end. A Shade with Row 0 and Column  0, is the closest, and most left shade, if you are standing inside and looking out
	the window the shades are covering. As a quick example the shade with row 1 and column 1 is the shade with the asterisk up there.

	Only use selectors if you'd like to keep data access consistent.
*/

export const InitialShadeState: () => ShadeViewModel = () => {
	const newShade: ShadeViewModel = {
		id: 0,
		row_coordinate: 0,
		column_coordinate: 0,
		fabric_name: "",
		width: 0,
		height: 0,
		api_motor_id: 0,
		roll_diameter: 0,
		clearence_diameter: 0,
		torque_needed: 0,
		deflection: 0,
		weight: 0,
		shade_type: "",
		mfg_status: "",
		hembar_line: "",
		hembar_style: "",
		lv_power_source: "",
		manual_chain: "",
		manual_chain_color: "",
		motor: "",
		motor_type: "",
		roll_direction: "",
		tube_name: "",
		bearing_pin_sku: "",
		tube_sku: "",
		make_this_shade: true,
		custom_fabric_width: 0,
		fabric_alignment: "Center"

	};

	return newShade;
};

export const InitialAssemblyState: () => AssemblyStore = () => ({
	loadStatus: 1,
	timeLoaded: 0,
	shades: [],
	id: 0,
	control_side: "",
	direction_facing: "",
	mfg_status: "",
	room_name: "",
	shade_name: "",
	error_messages: "",
	hardware_color: "",
	side_channels: "None",
	side_channels_color: "",
	header_line: "",
	balanced_lightgaps: "balanced",
	end_caps: "",
	recipe_sku: "",
	mount_type: "",
	window_jamb_depth: 0,
	mount_style: "",
	crank_length: 0,
	total_width: 0,
	band_count: 0,
	floor: 0,
	shade_type_id: 0,
	quote_id: 0,
	sequence_id: 0,
	palette_color: "",
	last_time_priced: 0,
	last_time_priced_value_saved: 0,
	series_id: 0,
	will_be_seamed: false,
	fabric_will_rub: false,
	has_sag_warning: false,
	is_dual: false,
	is_coupled: false,
	is_channels_only: false,
	is_hardware_only: false,
	is_header_extrusion_only: false,
	is_motor_only: false,
	is_tube_and_fabric_only: false,
	is_crown_and_drive_only: false,

	is_shade_service: false,
	is_fabric_to_be_cut: false,
	is_header_extrusion_to_be_cut: false,
	is_tube_to_be_cut: false,
	is_hembar_to_be_cut: false,
	is_side_channels_to_be_cut: false,
	is_sill_channels_to_be_cut: false,

	side_channels_mount_type: "",
	msrp: 0,
	shipping: 0,
	cost: 0,
	labor: 0,
	markup: 0,
	overhead: 0,

	unlocked: false,
	selected: false,
	fascia_split: "",
	single_dual: "",
	tube_pricing_override: "",
	motor_pricing_override: "",
	colors: {},

	front_row_torque: 0,
	front_row_weight: 0,
	back_row_torque: 0,
	back_row_weight: 0,
});




const selectIndoorAssembliesByQuoteId = (quoteId: number) => createSelector(
	selectAssemblyListByQuoteId(quoteId),
	(items) => items.filter((a) => a.shade_type_id === 1)
);

// const SelectIndoorAssemblySequenceIds = createSelector([
// 	(state: AppState, quoteId: number) => selectIndoorAssembliesByQuoteId(state, quoteId),
// 	(state: AppState, quoteId: number) => quoteId
// ], (items, quoteId) => {
// 	return items.map(a => a.sequence_id);
// })

const selectOutdoorAssembliesByQuoteId = (quoteId: number) => createSelector(
	selectAssemblyListByQuoteId(quoteId),
	(items) => items.filter((a) => a.shade_type_id === 2)
);

const selectPSOutdoorAssembliesByQuoteId = (quoteId: number) => createSelector(
	selectAssemblyListByQuoteId(quoteId),
	(items) => items.filter((a) => a.shade_type_id === 3)
);

// const SelectOutdoorAssemblySequenceIds = createSelector([
// 	(state: AppState, quoteId: number) => selectOutdoorAssembliesByQuoteId(state, quoteId),
// 	(state: AppState, quoteId: number) => quoteId
// ], (items, quoteId) => {
// 	return items.map(a => a.sequence_id);
// })

// const SelectHasIndoorShades = createSelector([
// 	(state: AppState, quoteId: number) => SelectIndoorAssemblySequenceIds(state, quoteId),
// 	(state: AppState, quoteId: number) => quoteId
// ], (items, quoteId) => {
// 	return items.length > 0;
// })

// const SelectHasOutdoorShades = createSelector([
// 	(state: AppState, quoteId: number) => SelectOutdoorAssemblySequenceIds(state, quoteId),
// 	(state: AppState, quoteId: number) => quoteId
// ], (items, quoteId) => {
// 	return items.length > 0;
// })

const AssemblyTotalWidth = (QuoteId: number, SequenceId: number) => (state: AppState) => {
	const assemmbly = SelectAssembly(QuoteId, SequenceId)(state);

	if (!assemmbly) {
		return 0;
	}

	if (assemmbly.is_coupled) {
		return assemmbly.total_width;
	}
	return assemmbly.shades.filter((a) => a.row_coordinate === 0 && a.column_coordinate === 0)[0]?.width ?? 0.0;
};

/* Update Thunks */
type UpdateAssemblyResponse = {
	success: boolean,
	assembly: AssemblyStore | null,
	errors: string[],
	new_last_time_priced?: number
}

type UpdateAssembliesResponse = {
	success: boolean,
	assemblies: AssemblyStore[]
}

// const CreateAssemblyString = "entities/Quotes/Assemblies/Create";
// const UpdateAssemblyString = "entities/Quotes/Assemblies/Update";

// /* Reducer Builder */
// const UpdateAssemblyStore = (assembly: AssemblyStore) => ({
// 	type: UpdateAssemblyString,
// 	payload: assembly
// });

const UpdateAssemblyStore = createAction<AssemblyStore>('entities/Quotes/Assemblies/Update');

// const CreateAssemblyStore = (assembly: AssemblyStore) => ({
// 	type: CreateAssemblyString,
// 	payload: assembly
// });
/*
	This is the main function updating an assembly. It takes the quoteId, the sequenceId, and then the transformation function.
*/

export const updateAssembly = createAsyncThunk<UpdateAssemblyResponse, { quoteId: number, sequenceId: number, assembly: AssemblyStore }, ThunkAPI>(
	"entities/Quotes/Assemblies/Update",
	async ({ quoteId, sequenceId, assembly }, { rejectWithValue, dispatch }) => {
		try {
			dispatch(wentToQuote(quoteId));

			const asVm = AssemblyViewModelFromAssemblyStore(assembly);

			const resp = await apiCalls.updateAssembly(quoteId, sequenceId, asVm);

			const asStore = AssemblyStoreFromAssemblyViewModel(resp.data.assembly);


			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			return {
				success: true,
				assembly: asStore,
				errors: [],
				new_last_time_priced: resp.data?.reprice_needed ? (Date.now() / 1000) : asVm.last_time_priced
			};
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: "redux"
				},
				extra: {
					redux_action: "updateAssemblyAsyncThunk",
					file_origin: "src/Store/entities/assemblies/index.ts"
				}
			});

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}
	}
);

export const updateAssemblies = createAsyncThunk<UpdateAssembliesResponse, { quoteId: number, assemblies: AssemblyStore[] }, ThunkAPI>(
	"entities/Quotes/Assemblies/MassUpdate",
	async ({ quoteId, assemblies }, { rejectWithValue, dispatch }) => {
		try {
			dispatch(wentToQuote(quoteId));

			const asVms = assemblies.map(a => AssemblyViewModelFromAssemblyStore(a));

			const resp = await apiCalls.updateAssemblies(quoteId, asVms);

			const asStore = resp.data.assemblies.map(AssemblyStoreFromAssemblyViewModel);


			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			return {
				success: true,
				assemblies: asStore
			};
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: "redux"
				},
				extra: {
					redux_action: "updateAssemblyAsyncThunk",
					file_origin: "src/Store/entities/assemblies/index.ts"
				}
			});

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}
	}
);


// export const updateAssembly = (quoteId: number, sequenceId: number, changeAssembly: (assembly: AssemblyStore) => AssemblyStore) => (dispatch: AppDispatch, getState: () => AppState) => {
// 	const state = getState();

// 	const assembly = SelectAssembly(quoteId, sequenceId)(state);

// 	if (!assembly) {
// 		return new Promise<UpdateAssemblyResponse>((resolve) => {
// 			resolve({
// 				success: false,
// 				assembly,
// 				errors: ["Assembly doesn't seem to exist."]
// 			});
// 		});
// 	}

// 	const newAssembly = changeAssembly(assembly);

// 	// Update local state
// 	dispatch(UpdateAssemblyStore(newAssembly));

// 	return new Promise<UpdateAssemblyResponse>((_resolve, _reject) => {
// 		// apiCalls.updateAssembly(quoteId, sequenceId, newAssembly).then((resp) => {
// 		// 	let data = resp.data;

// 		// 	let success = data.success;

// 		// 	if (success) {
// 		// 		resolve({
// 		// 			assembly: newAssembly,
// 		// 			errors: [],
// 		// 			success: true
// 		// 		});
// 		// 	} else {
// 		// 		resolve({
// 		// 			assembly: newAssembly,
// 		// 			errors: data.errors,
// 		// 			success: false
// 		// 		});
// 		// 	};
// 		// }).catch((err) => {
// 		// 	resolve({
// 		// 		assembly: newAssembly,
// 		// 		errors: ["Something went wrong."],
// 		// 		success: false
// 		// 	});
// 		// });
// 	});
// };

export const updateAssemblyNoPrice = (quoteId: number, sequenceId: number, changeAssembly: (assembly: AssemblyStore) => AssemblyStore) => (dispatch: AppDispatch, getState: () => AppState) => {
	const state = getState();

	const assembly = SelectAssembly(quoteId, sequenceId)(state);

	if (!assembly) {
		return new Promise<UpdateAssemblyResponse>((resolve) => {
			resolve({
				success: false,
				assembly,
				errors: ["Assembly doesn't seem to exist."]
			});
		});
	}

	const newAssembly = changeAssembly({ ...assembly });

	// Update local state
	dispatch(UpdateAssemblyStore(newAssembly));

	return new Promise<UpdateAssemblyResponse>((_resolve, _reject) => {
		// apiCalls.updateAssembly(quoteId, sequenceId, newAssembly).then((resp) => {
		// 	let data = resp.data;

		// 	let success = data.success;

		// 	if (success) {
		// 		resolve({
		// 			assembly: newAssembly,
		// 			errors: [],
		// 			success: true
		// 		});
		// 	} else {
		// 		resolve({
		// 			assembly: newAssembly,
		// 			errors: data.errors,
		// 			success: false
		// 		});
		// 	};
		// }).catch((err) => {
		// 	resolve({
		// 		assembly: newAssembly,
		// 		errors: ["Something went wrong."],
		// 		success: false
		// 	});
		// });
	});
};

/*
 createAsyncThunk<
	Accessory[],
	void,
	{
		rejectValue: string;
	}
>("entities/getAccessoriesFull", async (_inputs, { rejectWithValue }) => {
	let accessories: Accessory[] = [];

	try {
		const resp = await apiCalls.getAccessories();

		if (isFailedApiCall(resp)) {
			throw resp.error;
		}

		accessories = resp.data.accessories;
	} catch (err: any) {
		captureSentryError(err, {
			tags: {
				section: "redux"
			},
			extra: {
				redux_action: "getAccessoriesFull",
				file_origin: "src/Store/entities/accessories/index.ts"
			}
		});

		const errorMessage = extractErrorMessage(err);
		return rejectWithValue(errorMessage);
	}

	return accessories;
});
*/

export const addAssembly = createAsyncThunk<UpdateAssemblyResponse, { quoteId: number, shadeType: string, newAssembly: AssemblyStore }, { rejectValue: string }>(
	"entities/Quotes/Assemblies/Create",
	async ({ quoteId, newAssembly, shadeType }, { rejectWithValue, dispatch }) => {

		await dispatch(wentToQuote(quoteId));

		try {
			const assemblyViewModel = AssemblyViewModelFromAssemblyStore(newAssembly);
			const resp =
				((shadeType?.toLowerCase() ?? "") == "indoor") ? await apiCalls.postINDOORCreateAssembly(quoteId, assemblyViewModel)
					: ((shadeType?.toLowerCase() ?? "") == "outdoor") ? await apiCalls.postOUTDOORCreateAssembly(quoteId, assemblyViewModel)
						: await apiCalls.postPSOUTDOORCreateAssembly(quoteId, assemblyViewModel);

			if (isFailedApiCall(resp)) {
				throw resp.error;
			}

			const asStore = AssemblyStoreFromAssemblyViewModel(resp.data.assembly);

			return {
				success: true,
				assembly: asStore,
				errors: []
			};
		} catch (err: any) {
			captureSentryError(err, {
				tags: {
					section: "redux"
				},
				extra: {
					redux_action: "addAssemblyAsyncThunk",
					file_origin: "src/Store/entities/assemblies/index.ts"
				}
			});

			const errorMessage = extractErrorMessage(err);
			return rejectWithValue(errorMessage);
		}
	}
);



// export const addAssembly = (quoteId: number, sequenceId: number, newAssembly: AssemblyStore) => (dispatch: AppDispatch, _getState: () => AppState) => {
// 	const assemblyToSend = {
// 		...newAssembly, quote_id: quoteId, sequence_id: sequenceId, series_id: sequenceId
// 	};



// 	dispatch(CreateAssemblyStore(assemblyToSend));

// 	return new Promise<UpdateAssemblyResponse>((_resolve, _reject) => {
// 		// TODO Add BE call to apiCalls
// 		apiCalls?.postPSOUTDOORCreateAssembly(quoteId, newAssembly).then((resp) => {
// 			_resolve({
// 				success: true,
// 				assembly: resp.data.assembly,
// 				errors: []
// 			});
// 		});
// 	});
// };

// export const updateAssemblyWhole = (quoteId: number, sequenceId: number, assemblyNew: AssemblyStore) => updateAssembly(quoteId, sequenceId, (_assembly) => {
// 	const newShades = assemblyNew.shades.map((s) => ({ ...s }));

// 	const newAssembly = { ...assemblyNew, shades: newShades };

// 	newAssembly.quote_id = quoteId;
// 	newAssembly.sequence_id = sequenceId;

// 	return newAssembly;
// });

// export const updateAssemblyPrice = (quoteId: number, sequenceId: number, price: number) => updateAssemblyNoPrice(quoteId, sequenceId, (assembly) => {
// 	const newAssembly = { ...assembly, msrp: price };
// 	console.log(`pricing_happened_${quoteId}-${sequenceId}-${price}`);
// 	return newAssembly;
// });

// export const updateFrontFabric = (quoteId: number, sequenceId: number, fabricName: string) => updateAssembly(quoteId, sequenceId, (assembly) => {
// 	const newShades = assembly.shades.map((s) => (s.row_coordinate === 0 ? ({ ...s, fabric_name: fabricName }) : s));

// 	const newAssembly = { ...assembly, shades: newShades };

// 	return newAssembly;
// });

// export const updateWidthMajor = (quoteId: number, sequenceId: number, width: number) => updateAssembly(quoteId, sequenceId, (assembly) => {
// 	if (assembly.is_coupled) {
// 		// Update total width
// 		return { ...assembly, total_width: width };
// 	}
// 	// Update column 0
// 	const newShades = assembly.shades.map((s) => (s.row_coordinate === 0 ? ({ ...s, width }) : s));
// 	const newAssembly = { ...assembly, shades: newShades };

// 	return newAssembly;
// });

// export const updateWidthByColumn = (quoteId: number, sequenceId: number, width: number, columnId: number) => updateAssembly(quoteId, sequenceId, (assembly) => {
// 	// Update 
// 	const newShades = assembly.shades.filter((s) => s.column_coordinate === columnId).map((s) => ({ ...s, width }));
// 	const newAssembly = { ...assembly, shades: newShades };
// 	return newAssembly;
// });

// export const updateHeightMajor = (quoteId: number, sequenceId: number, height: number) => updateAssembly(quoteId, sequenceId, (assembly) => {
// 	// Update 
// 	const newShades = assembly.shades.map((s) => ({ ...s, height }));
// 	const newAssembly = { ...assembly, shades: newShades };
// 	return newAssembly;
// });


const loadQuoteAssemblies = createAsyncThunk<
	AssemblyStore[],
	{
		quoteId: number;
		isAdd: boolean;
	},
	{
		rejectValue: string;
	}
>("entities/getQuoteAssemblies", async ({ quoteId }, { rejectWithValue }) => {
	let assemblies: AssemblyStore[] = [];
	try {
		const resp = await apiCalls.getQuoteAssemblies(quoteId);

		if (isFailedApiCall(resp)) {
			throw resp.error;
		}

		const assysAsStore = resp.data.assemblies.map(AssemblyStoreFromAssemblyViewModel);

		assemblies = assysAsStore;
	} catch (err: any) {
		captureSentryError(err, {
			tags: {
				section: "redux"
			},
			extra: {
				redux_action: "getQuoteAccessories",
				file_origin: "src/Store/entities/accessories/index.ts"
			}
		});

		const errorMessage = extractErrorMessage(err);
		return rejectWithValue(errorMessage);
	}

	return assemblies;
});

const deleteAssemblies = createAsyncThunk<
	void,
	{ quoteId: number, sequenceIds: number[] },
	{
		rejectValue: string;
	}
>("entities/deleteAssembly", async ({ quoteId, sequenceIds }, { rejectWithValue, dispatch }) => {
	try {
		dispatch(wentToQuote(quoteId));
		const resp = await apiCalls.deleteAssemblies(quoteId, sequenceIds);

		if (isFailedApiCall(resp)) {
			throw resp.error
		}
	}
	catch (err: any) {
		captureSentryError(err, {
			tags: {
				section: "redux"
			},
			extra: {
				redux_action: "deleteAssembly",
				file_origin: "src/Store/entities/assemblies/index.ts"
			}
		});

		const errorMessage = extractErrorMessage(err);
		rejectWithValue(errorMessage);
	}
});

const duplicateAssembly = createAsyncThunk<
	AssemblyStore,
	{ quoteId: number, sequenceId: number, inputs: DuplicateAssemblyInputs },
	{
		rejectValue: string;
	}
>("entities/duplicateAssembly", async ({ quoteId, sequenceId, inputs }, thunkApi_) => {
	let newAssembly: AssemblyStore = InitialAssemblyState();
	const thunkApi = thunkApi_ as ThunkAPI;

	const { rejectWithValue } = thunkApi;

	try {
		thunkApi.dispatch(wentToQuote(quoteId));
		const resp = await apiCalls.duplicateAssembly(quoteId, sequenceId, inputs);

		if (isFailedApiCall(resp)) {
			throw resp.error;
		}
		const asStore = AssemblyStoreFromAssemblyViewModel(resp.data.new_assembly);

		newAssembly = asStore;
	} catch (err: any) {
		captureSentryError(err, {
			tags: {
				section: "redux"
			},
			extra: {
				redux_action: "duplicateAssembly",
				file_origin: "src/Store/entities/assemblies/index.ts"
			}
		});

		const errorMessage = extractErrorMessage(err);
		rejectWithValue(errorMessage);
	}

	return newAssembly;
});

type DuplicateAssembliesInput = { quoteId: number, sequenceIds: number[], duplicateCount: number };

const duplicateAssemblies = createAsyncThunk<
	AssemblyViewModel[],
	DuplicateAssembliesInput,
	{
		rejectValue: string;
	}
>("entities/duplicateAssemblies", async ({ quoteId, sequenceIds, duplicateCount }, { rejectWithValue, dispatch }) => {

	let assemblies = [] as AssemblyViewModel[];

	try {
		dispatch(wentToQuote(quoteId));
		const resp = await apiCalls.duplicateAssemblies(quoteId, sequenceIds, duplicateCount);
		assemblies = resp.data.assemblies;

		if (isFailedApiCall(resp)) {
			throw resp.error;
		}
	}
	catch (err: any) {
		captureSentryError(err, {
			tags: {
				section: "redux"
			},
			extra: {
				redux_action: "duplicateAssemblies",
				file_origin: "src/Store/entities/assemblies/index.ts"
			}
		});

		const errorMessage = extractErrorMessage(err);
		rejectWithValue(errorMessage);
	}

	return assemblies;
});


const repriceAssemblies = createAsyncThunk<
	void,
	{ quoteId: number, sequenceIds: number[], acc_line_numbers: number[] },
	{
		rejectValue: string;
	}
>("entities/repriceAssemblies", async ({ quoteId, sequenceIds, acc_line_numbers }, { rejectWithValue, dispatch }) => {
	try {
		dispatch(wentToQuote(quoteId));
		const resp = await apiCalls.repriceAssemblies(quoteId, sequenceIds, acc_line_numbers);

		if (isFailedApiCall(resp)) {
			throw resp.error;
		}
	}
	catch (err: any) {
		captureSentryError(err, {
			tags: {
				section: "redux"
			},
			extra: {
				redux_action: "repriceAssemblies",
				file_origin: "src/Store/entities/assemblies/index.ts"
			}
		});

		const errorMessage = extractErrorMessage(err);
		rejectWithValue(errorMessage);
	}
});

const updateAssemblyServiceScreen = createAsyncThunk<
	AssemblyStore,
	{ quoteId: number, sequenceId: number, serviceScreen: ServiceScreenVariables },
	{
		rejectValue: string;
	}
>("entities/updateAssemblyServiceScreen", async ({ quoteId, sequenceId, serviceScreen }, { rejectWithValue, dispatch }) => {
	let newAssembly: AssemblyStore = InitialAssemblyState();

	try {
		dispatch(wentToQuote(quoteId));
		const resp = await apiCalls.setServiceScreenVariables(quoteId, sequenceId, serviceScreen);

		if (isFailedApiCall(resp)) {
			throw resp.error;
		}
		const asStore = AssemblyStoreFromAssemblyViewModel(resp.data.assembly);

		newAssembly = asStore;
	} catch (err: any) {
		captureSentryError(err, {
			tags: {
				section: "redux"
			},
			extra: {
				redux_action: "updateAssemblyServiceScreen",
				file_origin: "src/Store/entities/assemblies/index.ts"
			}
		});

		const errorMessage = extractErrorMessage(err);
		rejectWithValue(errorMessage);
	}

	return newAssembly;
});



const setAssembliesSelected = createAction<{ quoteId: number, sequenceIds: number[], selected: boolean }>("entities/assemblies/setSelected");
const setAssembliesUnlocked = createAction<{ quoteId: number, sequenceIds: number[], unlocked: boolean }>("entities/assemblies/setUnlocked");
const reloadAssembliesById = createAction<{ quoteId: number }>("entities/assemblies/reloadPricing");

const assemblyBuilder: extraEntityReducersFunction = (builder) => {
	builder.addCase(updateAssembly.pending, (state, action) => {
		const { meta } = action;
		const { quoteId, sequenceId, assembly } = meta.arg;

		const assemblyRecord = state.assemblies[quoteId]?.Items;

		if (assemblyRecord) {
			const oldAssembly = assemblyRecord[sequenceId];
			assemblyRecord[sequenceId] = { ...oldAssembly, ...assembly, last_time_priced_value_saved: (oldAssembly?.last_time_priced ?? 0) + 3 };
		}
	});

	builder.addCase(updateAssembly.fulfilled, (state, action) => {
		const { meta } = action;
		const { quoteId, sequenceId } = meta.arg;

		const assemblyRecord = state.assemblies[quoteId]?.Items;

		const newAssemblyRecord = action.payload.assembly;
		if (assemblyRecord) {
			const assy = assemblyRecord[sequenceId];
			if (assy) {
				assy.motor_pricing_override = newAssemblyRecord?.motor_pricing_override ?? "";
				assy.last_time_priced_value_saved = action.payload.new_last_time_priced ?? assy.last_time_priced_value_saved;
			}
		}
	});

	builder.addCase(updateAssemblies.pending, (state, action) => {
		const { meta } = action;
		const { quoteId, assemblies } = meta.arg;

		const assemblyRecord = state.assemblies[quoteId]?.Items;

		if (assemblyRecord) {
			assemblies.forEach((assembly) => {
				const { sequence_id: sequenceId } = assembly;
				const oldAssembly = assemblyRecord[sequenceId];
				assemblyRecord[sequenceId] = { ...oldAssembly, ...assembly, last_time_priced_value_saved: (oldAssembly?.last_time_priced ?? 0) + 3 };
			});
		}
	});

	builder.addCase(updateAssemblies.fulfilled, (state, action) => {
		const { meta } = action;
		const { quoteId, assemblies } = meta.arg;

		const assemblyRecord = state.assemblies[quoteId]?.Items;

		if (assemblyRecord) {
			assemblies.forEach((assembly) => {
				const { sequence_id: sequenceId } = assembly;
				const oldAssembly = assemblyRecord[sequenceId];
				assemblyRecord[sequenceId] = { ...oldAssembly, ...assembly, last_time_priced_value_saved: (oldAssembly?.last_time_priced ?? 0) + 1 };
			});
		}
	});

	builder.addCase(addAssembly.fulfilled, (state, action) => {
		const actualAction = action;

		const {
			payload,
		} = actualAction;

		const { assembly } = payload;
		if (!assembly) return;
		const {
			quote_id: quoteId,
			sequence_id: sequenceId
		} = assembly;

		const assemblyRecord = state.assemblies[quoteId];

		if (assemblyRecord) {
			assemblyRecord.Items[sequenceId] = { ...assembly, unlocked: true };
		}
	});

	builder.addCase(loadQuoteAssemblies.fulfilled, (state, action) => {
		const { quoteId, isAdd } = action.meta.arg;

		const assemblies = action.payload;
		assemblies.forEach((assembly) => {
			const { sequence_id: sequenceId } = assembly;

			const assemblyRecord = state.assemblies[quoteId];
			const currentAssembly = assemblyRecord?.Items[sequenceId] ?? { msrp: 0, shipping: 0, cost: 0, unlocked: false };
			const shipping = currentAssembly?.shipping !== 0 && assembly.shipping === 0 ? currentAssembly?.shipping : assembly.shipping ?? currentAssembly?.shipping;
			const cost = (assembly.cost ?? 0) === 0 ? currentAssembly?.cost : assembly.cost;
			const newMsrp = (assembly.msrp ?? 0) === 0 ? (currentAssembly?.msrp ?? assembly.msrp) : assembly.msrp;
			if (assemblyRecord) {
				assemblyRecord.Items[sequenceId] = { ...assembly, msrp: newMsrp, shipping: shipping, cost, unlocked: (isAdd || (currentAssembly?.unlocked ?? false)) };
			}
		});
		const assyRecord = state.assemblies[quoteId];

		if (assyRecord) {
			assyRecord.loadStatus = loadStatus.fullyLoaded;
		}

	});

	builder.addCase(loadQuoteAssemblies.pending, (state, action) => {
		const { quoteId } = action.meta.arg;
		const assy = state.assemblies[quoteId];

		if (!assy) {
			state.assemblies[quoteId] = {
				loadStatus: loadStatus.loading,
				Items: {}
			};
		} else {
			assy.loadStatus = loadStatus.loading;
		}


	})

	builder.addCase(deleteAssemblies.pending, (state, action) => {
		const { quoteId, sequenceIds } = action.meta.arg;

		const assemblyRecord = state.assemblies[quoteId]?.Items;

		if (assemblyRecord) {
			sequenceIds.forEach((sequenceId) => {
				delete assemblyRecord[sequenceId];
			});
		}
	}
	);

	builder.addCase(duplicateAssembly.pending, (state, action) => {
		const { quoteId, sequenceId, inputs } = action.meta.arg;
		const { requested_new_sequence_id: newSequenceId = 0 } = inputs;
		const assemblyRecord = state.assemblies[quoteId]?.Items ?? {};

		if (assemblyRecord) {
			const oldAssembly = assemblyRecord[sequenceId];
			if (!oldAssembly) return;
			assemblyRecord[newSequenceId] = { ...oldAssembly, sequence_id: newSequenceId, shade_name: inputs.new_shade_name ?? "", shades: oldAssembly.shades.map((s) => ({ ...s })) };
		}
	});

	builder.addCase(duplicateAssembly.fulfilled, (state, action) => {
		const { quoteId } = action.meta.arg;
		const newAssembly = action.payload;

		const assemblyRecord = state.assemblies[quoteId]?.Items;
		const receivedSeqId = newAssembly.sequence_id;
		const expectedSeqId = action.meta.arg.inputs.requested_new_sequence_id ?? 0;

		if (assemblyRecord) {

			if (receivedSeqId != expectedSeqId) {
				delete assemblyRecord[expectedSeqId];
			}

			const oldAssy = assemblyRecord[action.meta.arg.sequenceId];

			assemblyRecord[receivedSeqId] = {
				...oldAssy,
				...newAssembly,
				unlocked: oldAssy?.unlocked ?? false,
				msrp: oldAssy?.msrp ?? 0,
				shipping: oldAssy?.shipping ?? 0,
				cost: oldAssy?.cost ?? 0
			};

			state.assemblies[quoteId] = {
				loadStatus: loadStatus.fullyLoaded,
				Items: assemblyRecord
			};
		}
	});

	builder.addCase(duplicateAssemblies.fulfilled, (state, action) => {
		const { quoteId } = action.meta.arg;
		const assemblies = action.payload;

		const assemblyRecord = state.assemblies[quoteId]?.Items;

		if (assemblyRecord) {
			assemblies.forEach((assembly) => {
				const assyStore = AssemblyStoreFromAssemblyViewModel(assembly);
				const { sequence_id: sequenceId } = assembly;
				const oldAssembly = assemblyRecord[sequenceId];
				assemblyRecord[sequenceId] = { ...oldAssembly, ...assyStore, msrp: 0.01, shipping: oldAssembly?.shipping ?? 0, cost: oldAssembly?.cost ?? 0, unlocked: oldAssembly?.unlocked ?? false, last_time_priced: 0, last_time_priced_value_saved: 3 };
			});

		}

	});

	builder.addCase(setAssembliesSelected, (state, action) => {
		const { quoteId, sequenceIds, selected } = action.payload;

		const assemblyRecord = state.assemblies[quoteId]?.Items;

		if (assemblyRecord) {
			sequenceIds.forEach((sequenceId) => {
				const oldAssembly = assemblyRecord[sequenceId];
				if (!oldAssembly) return;
				assemblyRecord[sequenceId] = { ...oldAssembly, selected };
			});
		}
	});

	builder.addCase(setAssembliesUnlocked, (state, action) => {
		const { quoteId, sequenceIds, unlocked } = action.payload;

		const assemblyRecord = state.assemblies[quoteId]?.Items;

		if (assemblyRecord) {
			sequenceIds.forEach((sequenceId) => {
				const oldAssembly = assemblyRecord[sequenceId];
				if (!oldAssembly) return;
				assemblyRecord[sequenceId] = { ...oldAssembly, unlocked };
			});
		}
	});

	builder.addCase(reloadAssembliesById, (state, action) => {
		const { quoteId } = action.payload;

		const assemblyRecord = state.assemblies[quoteId];

		if (assemblyRecord) {
			assemblyRecord.loadStatus = loadStatus.needsLoaded;
		}
	});

	builder.addCase(repriceAssemblies.pending, (state, action) => {
		const { quoteId } = action.meta.arg;

		const assemblyRecord = state.assemblies[quoteId]?.Items;

		if (assemblyRecord) {
			Object.values(assemblyRecord).forEach((assembly) => {
				assembly.last_time_priced_value_saved = assembly.last_time_priced + 3;
			});
		}
	});

	builder.addCase(updateAssemblyServiceScreen.fulfilled, (state, action) => {
		const { quoteId, sequenceId } = action.meta.arg;

		const assemblyRecord = state.assemblies[quoteId]?.Items;

		if (assemblyRecord) {
			const newAssembly = action.payload;
			const oldAssembly = assemblyRecord[sequenceId];
			assemblyRecord[sequenceId] = { ...oldAssembly, ...newAssembly, unlocked: true }; //TODO check if this overrides pricing or physics values
		}
	});

	builder.addCase(UpdateAssemblyStore, (state, action) => {
		const assembly = action.payload;

		const { quote_id: quoteId, sequence_id: sequenceId } = assembly;

		const assemblyRecord = state.assemblies[quoteId]?.Items;

		if (assemblyRecord) {
			assemblyRecord[sequenceId] = assembly;
		}
	});



};

export {
	AssemblyTotalWidth, selectOutdoorAssembliesByQuoteId, selectIndoorAssembliesByQuoteId, SelectAssembly, assemblyBuilder,
	loadQuoteAssemblies, deleteAssemblies, duplicateAssembly, duplicateAssemblies, setAssembliesSelected, setAssembliesUnlocked, repriceAssemblies, selectPSOutdoorAssembliesByQuoteId, reloadAssembliesById, updateAssemblyServiceScreen
};
