import { entitySlice } from './entities';
import { fabricsAllowedReducer } from './entities/fabricsAllowedSlice'; // Importa el nuevo slice
import userReducer from './user';
import uiReducer from './ui';
import upperLevelReducer from './upperLevelReducers';
import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["user"],
};

// 🔹 Se mantiene solo una versión de `uncoupledReducer`
export const uncoupledReducer = combineReducers({
	user: userReducer,
	entity: entitySlice.reducer,
	fabricsAllowed: fabricsAllowedReducer,  // 🔹 Se mantiene la versión con `fabricsAllowed`
	ui: uiReducer,
});

// 🔹 Se mantiene solo una versión de `combinedReducer`
const combinedReducer = combineReducers({
	user: userReducer,
	entity: entitySlice.reducer,
	fabricsAllowed: fabricsAllowedReducer,  // 🔹 Se mantiene la versión con `fabricsAllowed`
	ui: uiReducer,
});

export const rootReducer = (state, action) => {
	const intermediateState = upperLevelReducer(state, action);
	const finalState = combinedReducer(intermediateState, action);
	return finalState;
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);
