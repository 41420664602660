import { useState } from 'react';
import { isFunction } from 'lodash';

export default function IntField(props) {
	const { option } = props;
	const [value, setValue] = useState(option.value);

	function blurred() {
		props.onChange();
		props.onBlur();
	}

	function changed(new_val) {
		option.value = Math.round(new_val);

		const min = option.minValue ? (isFunction(option.minValue) ? option.minValue(option.shade) : option.minValue) : null;
		
		if (option.maxValue && option.value > option.maxValue) {
			option.value = option.maxValue;
		} else if (min && option.value < min) {
			option.value = option.minValue;
		}

		setValue(option.value);
	}

	return (

		<input
			name={option.field_name}
			disabled={props.disabled}
			type="number"
			onBlur={blurred}
			onFocus={props.onFocus}
			value={option.value != null ? option.value : ''}
			onChange={(e) => changed(e.target.value)}
			placeholder={props.placeholderLabel ? option.label : '-'}
		/>
	);
}
