import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';

import type { RMAIconProps } from './types';

const RMAIcon = ({
	size = '1x', colorOverride = '', className = ''
}: RMAIconProps) => (
	<FontAwesomeIcon
		icon={faScrewdriverWrench}
		size={size}
		className={`${className} ${colorOverride && `text-${colorOverride}`}`}
	/>
);

export default RMAIcon;
