import React, { ReactNode } from 'react';
import { Col, ButtonGroup, Dropdown } from 'react-bootstrap';

import {
	useIsAdmin,
	useIsDealer,
	useIsDistributor,
	useIsPowerShadesAdmin,
	useIsRepresentative,
} from '../hooks';
import type { fakeRootType } from "../FakeRootType";
import { useIsSales } from '../Store/user/hooks';
import { usePendingDealerCount } from '../Store/entities/hooks';

type ManageEntitiesProps = {
	ROOT: fakeRootType;
};

const ManageEntities = ({ ROOT }: ManageEntitiesProps) => {
	const { navigate } = ROOT;

	const arr: JSX.Element[] = [];

	const isPowerShadesAdmin = useIsPowerShadesAdmin();
	const isRepresentative = useIsRepresentative();
	const isDistributor = useIsDistributor();
	const isDealer = useIsDealer();
	const isAdmin = useIsAdmin();

	const dealerCounts = usePendingDealerCount();

	const isSalesperson = useIsSales();
	
	const pending = isSalesperson ? dealerCounts.territory : dealerCounts.total;



	const goToContractors = () => {
		const load = ROOT.loadContent;
		load(`#Contractors`);
	};

	const fasClass = pending > 0 ? "fas" : ""


	const options = {
		1: (
			<Dropdown.Item
				key="view-reps"
				onClick={() => navigate('/Representatives')}
				eventKey="reps"
			>
				View Representatives
			</Dropdown.Item>
		),
		2: (
			<Dropdown.Item
				key="view-dist"
				onClick={() => navigate('/Distributors')}
				eventKey="distributors"
			>
				View Distributors
			</Dropdown.Item>
		),
		3: (
			<Dropdown.Item
				key="view-deal"
				onClick={() => navigate('/Dealers')}
				eventKey="dealers"
				className={fasClass}
				data-count={pending}
			>
				View Dealers
			</Dropdown.Item>
		),
		4: (
			<Dropdown.Item
				key="view-user"
				onClick={() => navigate('/Users')}
				eventKey="users"
			>
				View Users
			</Dropdown.Item>
		),
		5: (
			<Dropdown.Item
				key="view-terr"
				onClick={() => navigate('/Territories')}
				eventKey="territories"
			>
				View Territories
			</Dropdown.Item>
		),
		6: (
			<Dropdown.Item
				key="view-manu"
				onClick={() => navigate('/Manufacturing')}
				eventKey="manufacturers"
			>
				View Manufacturers
			</Dropdown.Item>
		),
		7: (
			<Dropdown.Item key="view-cont" onClick={() => goToContractors()} eventKey="contractors">
				Contractors
			</Dropdown.Item>
		),
	};

	if (isPowerShadesAdmin) {
		arr.push(
			options[1],
			options[2],
			options[3],
			options[4],
			options[5],
			options[6],
			options[7]
		);
	} else if (isRepresentative || isDistributor) {
		arr.push(options[3]);
		if (isAdmin) {
			// arr.push(options[7]);
			arr.push(options[4]);
		}
	} else if (isDealer && isAdmin) {
		arr.push(options[4]);
	}


	let manageEntities: ReactNode = 'Manage Entities';




	return (
		<div className={` ${fasClass} ${arr.length ? '' : 'hide'}`} data-count={pending}>
			<Col className="fas p-0 d-grid gap-2" style={{ zIndex: 999 }}>
				<Dropdown
					className="text-white"
					variant="green"
					as={ButtonGroup}
					menuVariant={"string"}
				>
					<Dropdown.Toggle className='fas text-white' variant="success" id="dropdown-basic">
						{manageEntities}
					</Dropdown.Toggle>
					<Dropdown.Menu>
						{arr}

					</Dropdown.Menu>
				</Dropdown>

			</Col>
		</div>
	);
};

export default ManageEntities;
