import { isFunction } from "lodash";

const HookImplementation = function () {
	const hooks = {};

	const nextID = () => {
		let i = 1;

		while (hooks[i] != null) i++;
      
		return i;
	};

	this.remove = (...hook_ids) => {
		hook_ids.forEach((hid) => delete hooks[hid]);
    
		return hooks;
	};

	this.add = (event_name, callback) => {
		const id = nextID();

		hooks[id] = { event_name, callback };

		return id;
	};

	this.execute = (event_name, ...args) => {
		Object.values(hooks)
			.filter((hook) => (hook.event_name == event_name && isFunction(hook.callback)))
			.forEach((hook) => hook.callback.call(null, args));
	};

	this.list = () => hooks;

	return this;
};

export default HookImplementation;
