import type { CallbackExtendFunc } from "./types";

const quoteCallbacks: CallbackExtendFunc = (withCallback) => {
	withCallback.add("quote/", (goods: string) => (_dispatch, _state, _invoke) => {
		console.log("quote/ callback");
		console.log(goods);
		console.log(_dispatch);
		console.log(_state);
		console.log(_invoke);

		return withCallback;
	});
};

export default quoteCallbacks;
