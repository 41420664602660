import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

import { useDealers, useDealersLoadStatus } from '../Store/entities/hooks';

import type { DealerSelectProps } from './types';
import { DealerStore } from '../powershadesApiTypes';

const DealerSelect = ({
	optionsOverride,
	selectedDealerId = 0,
	handleChange,
	disableOverride = false,
	placeholderText = 'Select a Dealer...'
}: DealerSelectProps) => {
	const dealers = useDealers();
	const dealersLoadStatus = useDealersLoadStatus();
	const dealersLoading = dealersLoadStatus <= 3;

	const shouldBeDisabled = dealersLoading || disableOverride;
	const selectedDealer = dealers.find((dealer) => dealer.id === selectedDealerId);
	
	return (
		<Typeahead
			id="dealer-select"
			clearButton
			paginate
			positionFixed
			labelKey="name"
			placeholder={dealersLoading ? 'Loading Dealers...' : placeholderText}
			options={optionsOverride ?? dealers}
			selected={selectedDealerId && selectedDealer ? [selectedDealer] : []}
			isLoading={dealersLoading}
			disabled={shouldBeDisabled}
			onChange={(selected) => {
				if (!selected) return;
				const dealer = selected as unknown as DealerStore;
				handleChange(dealer[0]?.id ?? 0);
			}}
		/>
	);
};

export default DealerSelect;
