import type { 
	Address, 
	BuyingGroupMeta, 
	Header, 
	DealerMeta, 
	DistributionEmail, 
	DistributorMeta, 
	HardwareKit, 
	Hembar, 
	IndoorFabric, 
	Motor, 
	OperationResult, 
	PriceData, 
	QuotePrefix, 
	QuoteStatusTypes, 
	QuoteTypeNames, 
	RepresentativeMeta, 
	ShadeAdds, 
	ShadeChannelInfo, 
	Tube, 
	SalesTaxExemptStatus,
	DistributorSalesType,
	RepresentativeTerritory,
	AdvancedQuoteOptions,
	loadStatusType,
	TermId,
	UserAttributes,
	DistributorTerritory,
	QuoteAccessories,
	QuoteInformation,
	ShallowHardwarePart,
	Accessory,
	Multiplier,
	Money,
	QuoteFabricSampleStore
} from './powershadesApiTypes.ts';

const QuoteOrderStatus: { [key: string]: QuoteStatusTypes } = {
	ActiveQuote: 'active_quote',
	ApprovedOrder: 'approved_order',
	MfgAcceptedOrder: 'mfg_accepted_order',
	MfgShippedOrder: 'mfg_shipped_order',
	OrderPendingApproval: 'order_pending_approval',
	SubmittedQuote: 'submitted_quote',
	UnderReview: 'under_review',
};

const QuoteTypeNamesById: { [key: number]: QuoteTypeNames } = {
	1: 'RMA',
	2: 'Rework',
	3: 'Bidspec',
	4: 'Internal',
	5: 'CEDIA',
	6: 'Demo',
	7: 'Window Covering'
};

const QuotePrefixById: { [key: number]: QuotePrefix } = {
	1: 'RMA',
	2: 'RWK',
	3: 'BID',
	4: 'INT',
	5: 'PS',
	6: 'DEMO',
	7: 'WC'
};

const GroupTypeOptions = ['dealers', 'distributors', 'territories', 'representatives', 'users', 'organizations'] as const;
type GroupTypes = typeof GroupTypeOptions[number];

const DealerFilterOptions = ['all', 'approved', 'pending', 'declined'] as const;
type DealerFilters = typeof DealerFilterOptions[number];

const QuoteFilterOptions = ['all', 'active', 'placed', 'RMA', 'rework', 'archived'] as const;
type QuoteFilters = 'all' | 'active' | 'placed' | 'RMA' | 'rework' | 'demo' | 'archived';

const UserFilterOptions = ['all', 'admin', 'user'] as const;
type UserFilters = 'all' | 'admin' | 'user';

const EmailDistributionOptions = ['all', 'order placed access', 'invoice access'] as const;
type EmailDistributionFilters = 'all' | 'order placed access' | 'invoice access';

type PossibleFilters = DealerFilters | QuoteFilters | UserFilters | EmailDistributionFilters;

const emptyDealerMeta: DealerMeta = {
	id: 0,
	name: '',
	email: '',
	phone: '',
	status: 'approved',
	allow_credit_card_payments: false,
	has_credit_terms: false,
	channel_id: 5,
	sales_tax_exempt_status: 'non_exempt',
	sales_tax_exempt_reason: 'non_exempt',
	sales_tax_exempt_certificate_location: '',
	sales_tax_exempt_certificate_number: '',
	federal_tax_id: '',
	federal_tax_certificate_location: '',
	territory_ids: [],
	has_fabric_book_override: false,
	current_portfolio: "",
	date_application_sent: '',
};

const emptyDistributorMeta: DistributorMeta = {
	id: 0,
	name: '',
	email: '',
	phone: '',
	sales_type: '',
	channel_id: 1,
	territory_ids: [],
	is_distributor_locations_active: false,
	current_portfolio: '',
};

const emptyRepresentativeMeta: RepresentativeMeta = {
	id: 0,
	name: '',
	email: '',
	phone: '',
	invite_code: '',
	territory_ids: [],
	current_portfolio: '',
};

const emptyDistributionEmail: DistributionEmail = {
	email: '',
	is_receiving_order_placed_emails: false,
	is_receiving_invoice_emails: false
};

const emptyBuyingGroup: BuyingGroupMeta = {
	id: 0,
	name: '',
	description: '',
};

const emptyAddress: Address = {
	first_name: '',
	last_name: '',
	email: '',
	phone: '',
	address: '',
	address2: '',
	city: '',
	state_code: '',
	state: '',
	zip: '',
	country: ''
};

const createOperationResult = (success: boolean, message: string): OperationResult => ({
	success,
	message,
});

const successResult = (message: string) => createOperationResult(true, message);

const errorResult = (message: string) => createOperationResult(false, message);

export type ShadePart = {
	sku: string;
	name: string;
	quantity: string;
	uom: string;
	category: string;
	is_active: boolean;
	is_hardware_kit_item: boolean;
	description: string;
	cost: number;
	msrp: number;
	assembly_category?: string;
};

export type ServiceShadeProperties =
	| "is_motor_only"
	| "is_tube_and_fabric_only"
	| "is_header_extrusion_only"
	| "is_channels_only"
	| "is_hardware_only"
	| "is_shade_service"
	| "is_crown_and_drive_only"
	| "is_shade_service";

export type AssemblyFieldBooleans = "IsMotorOnly"
	| "IsTubeAndFabricOnly"
	| "IsShadeService"
	| "IsHeaderExtrusionOnly"
	| "IsChannelsOnly"
	| "IsHardwareOnly"
	| "IsCrownAndDrive"
	| "MakeThisShade";

export type AssemblyFieldStrings = "RecipeSku"
	| "TubeOverride"
	| "MotorOverride";

export type AssemblyFieldCutBooleans = "IsHeaderExtrusionToBeCut"
	| "IsTubeToBeCut"
	| "IsSillToBeCut"
	| "IsSideToBeCut"
	| "IsFabricToBeCut"
	| "IsHembarToBeCut";

export type AssemblyFieldNames = AssemblyFieldBooleans | AssemblyFieldStrings | AssemblyFieldCutBooleans;

export const assemblyFieldToPropertyNameMap: { [key in AssemblyFieldNames]: ServiceShadeProperties | null } = {
	IsMotorOnly: "is_motor_only",
	IsTubeAndFabricOnly: "is_tube_and_fabric_only",
	IsShadeService: null,
	IsChannelsOnly: "is_channels_only",
	IsHardwareOnly: "is_hardware_only",
	IsHeaderExtrusionOnly: "is_header_extrusion_only",
	IsCrownAndDrive: "is_crown_and_drive_only",
	RecipeSku: null,
	TubeOverride: null,
	MotorOverride: null,
	IsHeaderExtrusionToBeCut: null,
	IsTubeToBeCut: null,
	IsSillToBeCut: null,
	IsSideToBeCut: null,
	IsFabricToBeCut: null,
	MakeThisShade: null,
	IsHembarToBeCut: null
} as const;

export type DisplayNames =
	| "Motor"
	| "Side/Sill Channels"
	| "Header Extrusion"
	| "Hardware"
	| "Crowns and Drives"
	| "Tube and Fabric"
	| "Service";

export const propertyToDisplayNameMap: {
	[key in ServiceShadeProperties]: DisplayNames;
} = {
	is_motor_only: "Motor",
	is_channels_only: "Side/Sill Channels",
	is_header_extrusion_only: "Header Extrusion",
	is_hardware_only: "Hardware",
	is_crown_and_drive_only: "Crowns and Drives",
	is_tube_and_fabric_only: "Tube and Fabric",
	is_shade_service: "Service",
} as const;

export type PropertyNames = keyof typeof propertyToDisplayNameMap;
export type PropertyDisplayNames = typeof propertyToDisplayNameMap[PropertyNames];

export const propertyToPartCategoryMap: {
	[key in ServiceShadeProperties]: DisplayNames;
} = {
	is_motor_only: "Motor",
	is_channels_only: "Side/Sill Channels",
	is_header_extrusion_only: "Header Extrusion",
	is_hardware_only: "Hardware",
	is_crown_and_drive_only: "Motor",
	is_tube_and_fabric_only: "Tube and Fabric",
	is_shade_service: "Service",
} as const;

export const partCategoryToPropertyMap: {
	[key in DisplayNames]: ServiceShadeProperties;
} = {
	Motor: "is_motor_only",
	"Crowns and Drives": "is_crown_and_drive_only",
	"Side/Sill Channels": "is_channels_only",
	"Header Extrusion": "is_header_extrusion_only",
	Hardware: "is_hardware_only",
	"Tube and Fabric": "is_tube_and_fabric_only",
	Service: "is_shade_service",
} as const;

export type PropertyPartCategoryNames = keyof typeof propertyToPartCategoryMap;
export type PropertyDisplayCategoryNames = typeof propertyToPartCategoryMap[PropertyPartCategoryNames];

export type PartCategoryNames =
	| "is_motor_only"
	| "is_channels_only"
	| "is_header_only"
	| "is_hardware_kit_only"
	| "is_crown_and_drive_only"
	| "is_tube_and_fabric_only";

export type PartCategories = {
	[key in PartCategoryNames]: string[];
};

const DealerUserRoles = ['dealer_admin', 'dealer_user', 'dealer_salesperson', 'dealer_purchasing'] as const;

const DistributorUserRoles = ['distributor_admin', 'distributor_user'] as const;

const DistributorLocationRoles = ['distributor_location_admin', 'distributor_location_user'] as const;

const RepresentativeUserRoles = ['rep_admin', 'rep_user'] as const;

const ManufacturerUserRoles = ['manufacturer_admin', 'manufacturer_user'] as const;

const OrganizationUserRoles = ['organization_admin', 'organization_user', 'org_user', 'org_admin', 'org_scheduler'] as const;

const PropertyUserRoles = ['property_admin', 'property_user', 'property_scheduler'] as const;

export const UserRoleNames = [...DealerUserRoles, ...DistributorUserRoles, ...DistributorLocationRoles, ...RepresentativeUserRoles, ...ManufacturerUserRoles, ...OrganizationUserRoles, ...PropertyUserRoles, 'powershades_admin'] as const;
export interface AuthUserRole {
	role_name: typeof UserRoleNames[number];
	entity_name: string | null;
	entity_id: number;
}

type UserRoleNames = typeof UserRoleNames[number];

type UserRole = {
	id: number;
	entity_name: string;
	role_name: UserRoleNames;
	role_id: number;
	entity_id: number;
}

type Role = {
	id: number;
	name: UserRoleNames;
}


type CallDataShade = {
	fabric_name: string;
	height: number;
	hembar_type: string;
	manual_chain?: string;
	manual_chain_color?: string;
	motor_type?: string;
	roll_direction: string;
	width: number;
	row_coordinate: number;
	column_coordinate: number;
	single_dual: string;
	tubeAndFabricOnly: 0 | 1;
	motorOnly: 0 | 1;
	noFascia: 0 | 1;
	is_control_column: 0 | 1;
}


type AssemblyPriceCallData = {
	id: number;
	end_caps: string;
	header_type: string;
	mount_type: string;
	window_jamb_depth: number;
	is_coupled: boolean;
	hardware_kit: string;
	hardware_color: string;
	channels?: string;
	channels_color?: string;
	sequence_id: number;
	shades: CallDataShade[];
	use_shades_api: 0 | 1;
	function: string;
	indoor_outdoor: string;
	control_column: number;
	is_motor_only: 0 | 1;
	is_channels_only: 0 | 1;
	is_tube_and_fabric_only: 0 | 1;
	is_header_extrusion_only: 0 | 1;
	is_hardware_only: 0 | 1;
	balance: 0 | 1;
	// ! This typo is in the backend
	is_railroad_check_overrided: 0 | 1;
	tube_override?: string;
	motor_override?: string;
	is_pricing_grid?: boolean;
}


type PricingData = {
	cost: number;
	msrp: number;
	torque0: number;
	torque1: number;
	// ! Typos are made in the back end
	clearence0: number;
	clearence1: number;
	rollDiameter0: number;
	rollDiameter1: number;
	deflection0: number;
	deflection1: number;
	motor: Motor;
	tube: Tube;
	fabric: IndoorFabric;
	hembar: Hembar;
	channels: ShadeChannelInfo;
	shade_adds: ShadeAdds;
	hardware_kit: HardwareKit;
	header: Header;
	will_be_seamed: 0 | 1;
	has_sag_warning: boolean;
	shipping: number;
	priceData: PriceData;
}

type GenericAssemblyCostBreakdownRow = {
	cost: number;
	msrp: number;
}

type ShadeError = {
	error_level: number;
	error_type: string;
	code: string;
	message: string;
}

type GenericAssemblyCostBreakdown = {
	fabric: GenericAssemblyCostBreakdownRow;
	hembar: GenericAssemblyCostBreakdownRow;
	motor: GenericAssemblyCostBreakdownRow;
	header: GenericAssemblyCostBreakdownRow;
	channels: GenericAssemblyCostBreakdownRow;
	tube: GenericAssemblyCostBreakdownRow;
	labor: GenericAssemblyCostBreakdownRow;
	overhead: GenericAssemblyCostBreakdownRow;
	packing: GenericAssemblyCostBreakdownRow;
	hardware_kit: GenericAssemblyCostBreakdownRow;
	hidden_shipping: GenericAssemblyCostBreakdownRow;
	category_missing: GenericAssemblyCostBreakdownRow;
	total: GenericAssemblyCostBreakdownRow;
	errors: {
		error_level: number;
		error_type: string;
		code: string;
		message: string;
	}[];
}


type AssemblyPrice = {
	assembly_id: number;
	prices: PricingData;
	type: string;
	sequenceId: number;
	callData: AssemblyPriceCallData;
}

type AssemblyShadePayloadShade = {
	id?: number | null;
	row_coordinate: number;
	column_coordinate: number;
	shade_assembly_id: number;
	fabric_name: string;
	hembar_type: string;
	roll_direction: string;
	width: number;
	height: number;
	motor_type?: string;
	shade_type: string;
	lv_power_source?: string;
	manual_chain?: string | null;
	manual_chain_color?: string | null;
	bearing_pin_type: string;
	motor: string;
	tube_sku: string;
	roll_diameter: number;
	clearance_diameter: number;
	deflection: number;
	torque_needed: number;
	// ! This needs verified, the call I checked returned null.
	tube?: string | null;
	tube_name?: string;
	single_dual?: 'Single' | 'Dual';
	previous_shade_assembly_id?: number;
	previous_shade_id?: number;
	make_this_shade?: boolean;
};

type AssemblyShadePayload = {
	id: number;
	indoor_outdoor: "indoor" | "outdoor";
	shades: AssemblyShadePayloadShade[];
	unlocked: boolean;
	sequence_id: number;
	single_dual: string;
	control_side: string;
	direction_facing: string;
	mount_type: string;
	room_name: string;
	floor: number;
	window_jamb_depth: number;
	side_channels: string;
	side_channels_color?: string;
	manual_chain?: string;
	shade_name: string;
	quick_shade?: boolean;
	header_type: string;
	hardware_color?: string | null;
	end_caps?: string;
	errors?: string[] | null;
	error_messages: string[] | null;
	msrp: number;
	shipping_price: number;
	cost: number;
	will_be_seamed: 0 | 1;
	has_sag_warning: string;
	hidden_shipping: number;
	last_time_priced: number;
	is_coupled?: boolean;
	band_count?: number;
	total_width?: number;
	fascia_split?: string;
	is_header_extrusion_to_be_cut?: boolean;
	is_tube_to_be_cut?: boolean;
	is_hembar_to_be_cut?: boolean;
	is_sill_channels_to_be_cut?: boolean;
	is_side_channels_to_be_cut?: boolean;
	is_fabric_to_be_cut?: boolean;
	is_motor_only?: boolean;
	is_tube_and_fabric_only?: boolean;
	is_header_extrusion_only?: boolean;
	is_hardware_only?: boolean;
	is_channels_only?: boolean;
	is_crown_and_drive_only?: boolean;
	is_shade_service?: boolean;
	recipe_sku?: string;
	RecipeSku?: string;
	quote_id: number;
	MotorPricingOverride?: any;
	TubePricingOverride?: any;
	previous_shade_assembly_id?: number;
	parts: {
		full_parts: ShadePart[];
		part_categories: PartCategories;
		actual_parts: ShadePart[];
	};
};

type SaveAssemblyResp = {
	quote: Quote;
	pricing: SaveAssemblyPricing[];
	taxes: string;
	pricing_log: PricingData[];
}

type SaveAssemblyPricing = {
	// TODO: Type this
	errors?: any;
	sequenceId: number;
	cost: number;
	msrp: number;
	shipping: number;
	will_be_seamed: 0 | 1;
	has_sag_warning: string;
	// TODO: type this
	errorsMore: any[];
	shades: AssemblyShadePayloadShade[];
}



// ! This needs to be renamed to something more descriptive
type QuoteShade = AssemblyShadePayload & {
	mfg_status: string;
	created: string;
	updated: string;
	shade_type_id: number;
	// ! This needs verified, test call returned null.
	last_local_id?: number;
	series_id: number;
	// ! This needs verified, test call returned null.
	installation_labor_cost?: number;
	// ! This needs verified, test call returned null.
	overhead?: number;
	// ! This needs verified, test call returned null.
	markup?: number;
	RecipeSku: string;
	recipe_sku?: string;
	quote_id: number;
	ShadesCost: number;
	ShadesMsrp: number;
	TubePricingOverride?: string;
	MotorPricingOverride?: string;
	parts: ShadePart[];
};


type PartsListResponse = {
	recipe_sku: string;
	full_parts: ShadePart[];
	actual_parts: ShadePart[];
	part_categories: PartCategories;
};

type UserWithoutAttributes = {
	id: number;
	name: string;
	email: string;
	phone: string;
	roles: UserRole[];
	default_currency: string;
}
type UserMeta = UserWithoutAttributes & UserAttributes;

type UserLink = {
	display_name: string;
	link: string;
}

type UserState = {
	id: number;
	loading: boolean;
	authenticated: boolean;
	name: string;
	email: string;
	roles: AuthUserRole[];
	error: string;
	jwt: string;
	refreshJwt: string;
	is_sales_person: boolean;
	is_disallowed_force_edit: boolean;
	is_engineering: boolean;
	is_service: boolean;
	is_manufacturing: boolean;
	is_using_old_homepage: boolean;
	is_allowed_parts_on_cedia: boolean;
	theme: 'light' | 'dark';
	links: {
		loadStatus: loadStatusType;
		list: UserLink[]
	};
};



type Entity = {
	loadStatus?: loadStatusType;
	terms_id?: TermId;
	distribution_emails?: DistributionEmail[];
	pricing_override?: boolean;
	allow_credit_card_payments?: boolean;
	has_credit_terms?: boolean;
	addr_id?: number;
	address?: string;
	address2?: string;
	billing_email?: string;
	billing_phone?: string;
	buying_groups?: BuyingGroupMeta[];
	channel_id?: number;
	city?: string;
	country?: string;
	email?: string;
	external_contact_id?: number;
	federal_tax_certificate_location?: string;
	federal_tax_id?: string;
	first_name?: string;
	id: number;
	invite_code?: string;
	last_name?: string;
	name: string;
	phone?: string;
	pricing_multiplier?: number;
	sales_tax_exempt_certificate_location?: string;
	sales_tax_exempt_certificate_number?: string;
	sales_tax_exempt_reason?: string;
	sales_tax_exempt_status?: SalesTaxExemptStatus;
	state?: string;
	zip?: string;
	users?: UserMeta[];
	quote_ids?: number[];
	territory_ids?: number[];
	representatives?: RepresentativeMeta[];
	dealers?: any[];
	mailing_addresses?: Address[];
	billing_address?: Address;
	multiplier?: number;
	sales_type?: DistributorSalesType;
	distributor_territories?: DistributorTerritory[];
	representative_territories?: RepresentativeTerritory[];
};

type QuoteShadeAssemblies = {
	Items: QuoteShade[];
	Cost: number;
	MSRP: number;
	Shipping: number;
	Advanced: AdvancedQuoteOptions[];
}


export type User = UserMeta & {
	quote_ids: number[];
	dealer: DealerMeta;
	distributor: DistributorMeta;
};

export type UserStore = User & {
	loadStatus: loadStatusType;
};

type QuoteShipping = {
	indoorShades: number;
	oversized: number;
	outdoorOutsideContinental: boolean;
	accessories?: number;
	total_shipping: number;
}

type QuoteAssemblyItems = {
	sequenceId: number;
	price: number;
	type: string;
}

type QuoteTotals = {
	name: string;
	pre_shipping: number;
	assemblies: number;
	accessories: number;
	hardware_parts: number;
	shipping: QuoteShipping;
	couponTotal: number;
	subtotal: number;
	taxes: number;
	tax_shipping: boolean;
	taxPercentage: number;
	taxableTotal: number;
	totalPrice: number;
	multiplier: Multiplier;
	assemblyItems: QuoteAssemblyItems[];
	accessoryItems: Accessory[];
}

type QuoteMSRP = {
	totals: QuoteTotals[];
}

// These are used values for shades using shade substitution.
// This includes a combination of shade attributes and assembly attributes.
// This is essentially the "key" of these objects - ! NOT THE VALUES !
type ShadeSubstitutionAttributes = AssemblyShadePayload & AssemblyShadePayloadShade;


type ShadeSubstitution = {
	valMatch: (variable: any, ...values: any) => boolean;
	val: (variable: any) => any;
	valIncludes: (variable: any, ...values: any) => boolean;
	changeAccessoryCharging: (isIncrease: boolean, isSolarOrAC: boolean, isWhip: boolean) => any;
	data: () => any;
	isNameUnique: () => boolean;
	getErrorFields: (shade: any) => any;
	clearErrors: () => any;
	getErrors: (idk: any, ignorePricingErrors: boolean) => any;
	getErrorSets: () => any;
	isOutdoorShade: () => any;
	getLocalID: () => number;
	isUnlocked: () => any;
	unlock: () => any;
	lock: () => any;
	needsRepricing: () => any;
	showLightGap: () => any;
	overrideRailRoad: () => any;
	needsFasciaSplit: () => any;
	isOutdoor?: () => any;
	isMotorized?: () => any;
	fieldHasErrors?: (shade: any, field_name: any) => any;
	savableObject?: () => any;
	isDualShade?: () => any;
	isCoupledShade?: () => any;
	sequenceId: number;
	options: (injectedMethods: any) => any;
}

type QuoteData = {
	quoteLevelVariables: QuoteLevelVariables;
	accessories?: QuoteAccessories;
	fabricSamples?: QuoteFabricSampleStore;
	quote?: QuoteInformation;
	hwRequestItems?: ShallowHardwarePart[];
	shadeAssemblies?: QuoteShadeAssemblies;
	// the above are from the initial construction, below are getters and setters
	readonly IndoorShades: QuoteShade[];
	// ! there's a fair chance this type isn't correct (outdoor shades), but the best I can do for now
	readonly OutdoorShades: QuoteShade[];
	// readonly AccessoryTotals: QuoteAccessories[];
	readonly QuoteId: number;
	readonly OldQuote: QuoteSubstitution;
	Load: (param: QuoteDataLoadParams) => void;
	Set: (param: QuoteDataSetParams) => void;
	getQuote: () => any;
	findShade(): () => Error;
	// TODO: Type... all of these lmao
	addAndGetNewShade(isOutdoor: any, callBack: any): () => any;
	lockAllShades: () => void;
	unlockShade: (sequenceId: number) => any;
	deleteAssembly: (sequenceId: number) => any;
	deleteAssemblies: (sequenceIds: any) => any;
	setUserData: (userData: any) => any;
	getUserData: () => any;
	// ! This method is pretty intense, will take a bit of work
	shadeSubstitute: (sequenceId: number) => any;
	increaseAccessory: (accessorySku: any) => any;
	decreaseAccessory: (accessorySku: any) => any;
	getAccessoryQuantity: (accessorySku: any) => any;
	selectedShades: (filterIds: any) => any;
	MassEdit: (
		assemblyIds: any,
		dataReference: any,
		newValue: any,
		option: any,
		selectOverride: boolean
	) => any;
	isAccessoryOld: (partNumber: any) => boolean;
	setAccessoryQuantity: (accessoryToSave: any, quantity: number) => any;
	QuoteFieldChange: (dataReference: any, newValue: any, override_shipping: boolean) => any;
	getOldDataStyle: (oldQuote: any) => any;
	getInitialValues: (quote: any, oldQuote: any) => any;
	changeFabricSampleQuantity: (fabric_id: any, quantity: number) => any;
	ChangeCoupledStatus: (
		sequenceId: number,
		dataReference: any,
		newValue: any,
		column: any,
		save: boolean
	) => any;
	ChangeWidth: (sequenceId: number, dataReference: any, newValue: any, column: any) => any;
	ChangeDualCoupledStatus: (
		sequenceId: number,
		dataReference: any,
		newValue: any,
		column: any,
		fromMassEdit: boolean
	) => any;
	ChangeAssemblyValue: (sequenceId: number, dataReference: any, newValue: any, column: any) => any;
	ChangeAccessoryValue: (accessoryId: any, dataReference: any, value: any) => any;
	shades: () => any;
	TransformIds: (shadeIds: any) => any;
	RePriceSingleRow: (sequenceIds: any, refreshFrontPage: any) => any;
	UpdateAssemblyMoney: (sequenceId: number, msrp: any, shipping: any) => any;
	GetPrice: (quote: any, callback: any) => any;
	RePriceWholeQuote: (callBack: any, override_shipping: boolean) => any;
	checkMotorChange: (newAssembly: any) => any;
	ChangeShadeValue: (shadeIds: any, dataReference: any, newValue: any, col: any) => any;
	ChangeDualStatus: (sequenceId: number, dataReference: any, newValue: any, column: any) => any;
	save: (json: any, callBack: boolean) => any;
	DuplicateShade: (sequenceId: number) => any;
	start: (item: any) => any;
	check: (keeGoing: boolean) => any;
	updateData: (resp: any) => any;
	reOrderSequences: (sequenceSets: any, noRerender: boolean) => any;
	setFasciaCombinations: (fasciaCombinations: any) => any;
	getFasciaCombinations: () => any;
	deleteFasciaCombination: (fc_id: number) => any;
	unlockAndRepriceWholeQuote: () => any;
	unlockAllShades: () => any;
	addHWItem: (sku: any, quantity: number) => any;
	updateHWItem: (lineNumber: number, quantity: number, cost: any, msrp: any) => any;
	deleteHWItem: (lineNumber: number) => any;
	setShadeLabor: (sequenceId: number, amount: any) => any;
	setShadeOverhead: (sequenceId: number, amount: any) => any;
	setShadeMarkup: (sequenceId: number, amount: any) => any;
	GetDemoInfo: () => any;
	SetDemoInfo: (info: any) => any;
	GetAssemblyBySequence: (sequenceId: number) => any;
	SetIsMotorOnly: (sequenceId: number, value: any) => any;
	SetIsTubeAndFabricOnly: (sequenceId: number, value: any) => any;
	SetIsHeaderExtrusionOnly: (sequenceId: number, value: any) => any;
	SetIsHardwareOnly: (sequenceId: number, value: any) => any;
	SetIsChannelsOnly: (sequenceId: number, value: any) => any;
	SetRecipeSku: (sequenceId: number, value: any) => any;
	SetTubeOverride: (sequenceId: number, value: any) => any;
	SetMotorOverride: (sequenceId: number, value: any) => any;
	SetIsCrownAndDriveOnly: (sequenceId: number, value: any) => any;
	SetSillChannelsToBeCut: (sequenceId: number, value: any) => any;
	SetHeaderExtrusionToBeCut: (sequenceId: number, value: any) => any;
	SetFabricToBeCut: (sequenceId: number, value: any) => any;
	SetSideChannelsToBeCut: (sequenceId: number, value: any) => any;
	SetHembarToBeCut: (sequenceId: number, value: any) => any;
	SetTubeToBeCut: (sequenceId: number, value: any) => any;
	SetIsShadeService: (sequenceId: number, value: any) => any;
	SetShadeToBeMade: (sequenceId: number, value: any, row: any, col: any) => any;
	BalanceAllAssemblies: () => any;
};

type QuoteMoney = {
	quoteLevelVariables: QuoteLevelVariables;
	userData?: UserState;
	money?: Money;
	shipping?: QuoteShipping;
	msrp?: QuoteMSRP;
	// ! not present in API
	currencies: undefined;
	// the above are from the initial construction, below are getters and setters
	Load({
		money,
		shipping,
		msrp,
		currencies
	}: {
		money: any;
		shipping: any;
		msrp: any;
		currencies: any;
	}): () => any;
	ApplyMSRPByName: (name: any, applyChanges: boolean) => any;
	ApplyMSRP: (currentMultiplier: any, applyChanges: boolean) => any;
	// ! Need to type this as actual currency symbols
	readonly Symbol: string;
	// ! Need to type this as actual ISO codes
	readonly ISO: string;
	Set({ UI, Data } : {UI: QuoteUI, Data: QuoteData}): () => any;
	Reprice: (data: any, refreshFrontPage: any) => any;
	quoteLevelMSRP: (current: any, taxMessage: any) => any;
	LoadMoney: () => any;
	readonly FabricMoney: any;
	AddAndGetMoney: (acc: any) => any;
	AddAssemblyMSRP: (assemblyId: any, amount: any, type: any) => any;
	AddAssemblyShipping: (assemblyId: any, amount: any, type: any) => any;
	MoneyItem: (sequenceId: any, amount: any, type: any, affectedByMultiplier: boolean) => any;
	RemoveSequenceIds: (sequenceIds: any) => any;
	GetMoneyById: (sequenceId: any, type: any) => any;
	GetMsrp: (...args: any) => any;
	GetShipping: (...args: any) => any;
	readonly AllItems: any;
	readonly AccessoryMoney: any;
	readonly OutdoorMoney: any;
	readonly IndoorMoney: any;
	readonly TotalMSRP: any;
};

type MoneyId = {
	sequenceId: number;
	amount: any;
	symbol: any;
	multiplier: any;
	type: any;
	currencyMultiplier: any;
	affectedByMultiplier: any;
	UpdateMoney: () => any;
	Component: () => any;
	UpdateMultipliers: (multiplier: any, currencyMultiplier: any) => any;
	UpdateSymbol: (symbol: any) => any;
	UpdateAmount: (amount: any) => any;
	value: () => any;
}

type QuoteUISetParams = {
	Data: QuoteData;
	Money: QuoteMoney;
}

type QuoteUI = {
	// TODO: confirm this type
	QuotaActions: null | any;
	onDone: any;
	quoteLevelVariables: QuoteLevelVariables;
	// the above are from the initial construction, below are getters and setters
	ShadeNum: () => number;
	AssemblyNum: () => number;
	AccessoryNum: () => number;
	FabricNum: () => number;
	DualShadeNum: () => number;
	SelectNum: () => number;
	IsDualNum: () => number;
	IsCoupled: () => number;
	IsDualOrCoupled: () => number;
	IsWidth: () => number;
	QuoteType: () => QuoteTypeNames;
	Set({ Data, Money }: QuoteUISetParams): () => void;
	// TODO: Type... all of these lmao
	IndoorShadeColumns: () => any;
	OutdoorShadeColumns: () => any;
	AccessoryColumns: () => any;
	IndoorTable: (IndoorMoney: any) => any;
	IndoorRows: (IndoorMoney: any) => any;
	OutdoorRows: (outdoorMoney: any) => any;
	OutdoorTable: (outdoorMoneys: any) => any;
	setHooks: (hooks: any) => any;
	SelectStatus: (sequenceId: any) => any;
	Row: (items: any, columns: any, options: any) => any;
	CreateCells: () => any;
	AddCell: (name: any, cell: any) => any;
	FabricSamplesTable: (fabricMoney: any, fabricSamples: any) => any;
	GetGenericCell: (
		shade: any,
		options: any,
		column: any,
		cellValue: any,
		assembly: any,
		disabled: any,
		update: any,
		willBeSeamed: any,
		unlocked: any,
		hasSagWarning: any
	) => any;
	BoxSelected: (sequenceId: any, isChecked: any) => any;
	OutdoorEditRow: (onChange: any) => any;
	IndoorEditRow: (onChange: any) => any;
	EditRow: (onChange: any, sequenceIds: any, columns: any, tableName: any, options: any) => any;
	GetSelectBox: (shadeAssembly: any, boxSelected: any, value: any, disabled: any) => any;
	GetShadeRow: (shadeAssembly: any, boxSelected: any, value: any, disabled: any) => any;
	setRenderPause: () => any;
	reRenderTable: (override: boolean) => any;
	ValueChanged: (
		Id: any,
		dataReference: any,
		newValue: any,
		col: any,
		updateFrontScreen: boolean
	) => any;
	AccessoryTable: (accessoryMoney: any) => any;
	FabricSampleRow: (values: any) => any;
	AccessoryRow: (values: any, disabled: any) => any;
	hasChainsBanned: () => any;
};

type QuoteUIObject = QuoteUI & {
	Data: QuoteData;
	Money: QuoteMoney;
}

type QuoteMoneyObject = QuoteMoney & {
	Data: QuoteData;
	UI: QuoteUI;
}

type QuoteDataObject = QuoteData & {
	Money: QuoteMoney;
	UI: QuoteUI;
}

type QuoteGlobals = {
	INDOOR_FABRICS: any;
	OUTDOOR_FABRICS: any;
	MOTORS: any;
	STATES: any;
	ACCESSORIES: any;
	FABRICS: any;
	getCountrySubdivision: (csd_id: number) => any;
	getMotor: (motor_id: number) => any;
	getAccessory: (acc_id: number) => any;
	onLoad: (callback: any) => any;
	onHWLoad: (callback: any) => any;
}

type QuoteDataLoadParams = {
	shadeAssemblies: QuoteShadeAssemblies;
	accessories: QuoteAccessories;
	fabricSamples: QuoteFabricSampleStore;
	quote: QuoteInformation;
	// cannot find this in the api call, think it's unused
	advanced: undefined;
	hwRequestItems: ShallowHardwarePart[];
}

type QuoteDataSetParams = {
	UI: QuoteUI;
	Money: QuoteMoney;
}


type QuoteSubstitutionMoney = {
	// ! typo is hard coded in :)
	sellCurrencly: string;
	buyCurrency: string;
}

type QuoteSubstitutionPricing = {
	money: QuoteSubstitutionMoney;
}

type OldQuoteTitle = {
	varName: string;
	type: string;
	label: () => "Order Title" | "Quote Title";
	value: string;
}

type OldQuoteReferenceNumber = {
	varName: string;
	type: string;
	label: string;
	value: string;
}

type OldQuoteNotes = {
	varName: string;
	type: string;
	label: string;
	value: string;
}

type OldQuoteOptions = {
	title: OldQuoteTitle;
	referenceNumber: OldQuoteReferenceNumber;
	notes: OldQuoteNotes;
}

type QuoteSubstitution = {
	pricing: QuoteSubstitutionPricing;
	pricing_as: string;
	editable: boolean;
	// TODO: confirm totalPrice
	totalPrice: () => number;
	quote_options?: OldQuoteOptions;
	shades: QuoteShadeAssemblies;
	property_address: Address;
	// TODO: type hooks
	hooks: any;
	hasOutdoorShades: () => boolean;
	getShadeCount: () => number;
	// TODO: type callback, confirm this function
	unlock: (callback: any) => void;
	is_order: boolean;
	is_paid: boolean;
	order_status_index: number;
	territory_id: number;
	manufacturer_id?: number;
	ID: number;
	getFabricSamplesQuantity: () => QuoteFabricSampleStore[];
	getShadeQuantity: () => number;
	// TODO: Type currentSaveData
	currentSaveData: () => any;
	// TODO: Type addCoupon
	addCoupon: (couponCode: any, callback: any) => any;
	showLightGap: () => boolean;
	success?: boolean;
	// * This is a hook that is added in Quote.js. It has an empty body.
	shadesUpdated?: () => void;
}

type QuoteLevelVariables = {
	// Quote is/isn't editable
	editable: boolean;
	// Poorly named, does the show edit quote disclaimer need to be shown
	editScreen: boolean;
	quoteSub: QuoteSubstitution;
	// this function just sets titleMessage if quote is expired
	checkExpiration: () => void;
	quoteType: () => QuoteTypeNames;
	quoteColor: () => QuotePrefix;
	noPricing: boolean;
	userData: UserState;
}

type Quote = {
	money: Money;
	shadeAssemblies: QuoteShadeAssemblies;
	accessories: QuoteAccessories;
	fabricSamples: QuoteFabricSampleStore;
	hwParts: ShallowHardwarePart[];
	Quote: QuoteInformation;
	Shipping: QuoteShipping;
	MSRP: QuoteMSRP;
};

type QuoteEvent = {
	id?: number;
	key?: string;
	user_id?: number;
	// TODO: Quote
	user: User;
	quote: any;
	quote_id: number;
	button_pressed: string;
	date: Date;
	email: string;
	user_name: string;
};


export {
	QuoteOrderStatus,
	QuoteTypeNamesById,
	QuotePrefixById,
	GroupTypeOptions,
	DealerFilterOptions,
	QuoteFilterOptions,
	UserFilterOptions,
	EmailDistributionOptions,
	emptyDealerMeta,
	emptyDistributorMeta,
	emptyRepresentativeMeta,
	emptyDistributionEmail,
	emptyBuyingGroup,
	emptyAddress,
	createOperationResult,
	successResult,
	errorResult,
	QuoteShade,
	AssemblyShadePayload,
	AssemblyShadePayloadShade,
	AssemblyPrice,
	AssemblyPriceCallData,
	PartsListResponse,
	UserRole,
	Role,
	SaveAssemblyResp,
	UserMeta,
	UserState,
	QuoteShadeAssemblies,
	ShadeSubstitution,
	ShadeSubstitutionAttributes,
	QuoteData,
	QuoteMoney,
	MoneyId,
	QuoteUISetParams,
	QuoteUI,
	QuoteUIObject,
	QuoteMoneyObject,
	QuoteDataObject,
	QuoteGlobals,
	QuoteEvent,
	Quote,
	QuoteLevelVariables,
	QuoteSubstitution,
	QuoteSubstitutionMoney,
	QuoteSubstitutionPricing,
	OldQuoteOptions,
	Entity,
	GenericAssemblyCostBreakdown,
	ShadeError,
	GenericAssemblyCostBreakdownRow

};


export type {
	GroupTypes, DealerFilters, QuoteFilters, UserFilters, PossibleFilters
};
