/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-body-style */ /* Because I hate it. */
import { formatMoney } from "../../Parts/Money";
import store from "../../Store";
import { selectUsersAndDispatch, useDealerById, useDistributorById, useDistributorLocationById, useRepresentativeById } from "../../Store/entities/hooks";
import { selectQuoteFromId } from "../../Store/entities/quotes/hooks";
import { selectIsPsAdmin } from "../../Store/user/userPermissions";
import { getDealerLink, getDistributorLink, getDistributorLocationLink, getQuoteLink, getRepresentativeLink, getUserLink, timeAgo } from "../../psUtil";

// THIS SHOULD NOT BE EXPORTED. IT IS ONLY USED INTERNAL TO THIS FILE IF USED CORRECTLY.
// These keys are used to order the columns / quote card pieces
type QuoteHomeColumnKey = "title" | "id" | "created_on" | "created_by" | "updated" | "msrp" | "status" | "type" | "actions" | "company" | "sale_price" | "approved" | "ordered" | "order_assigned_to_mfg" | "sent_to_production" | "order_shipped";

type QuoteColumnType = {
	title: string;
	accessor: () => string;
	key: QuoteHomeColumnKey;
	href?: string;
	userName?: () => string;
}

type QuoteHomeColumns = {
	columns: QuoteColumnType[];
	loaded: boolean;
};

type UseGetQuoteColumnsType = () => (quoteId: number) => QuoteHomeColumns

// Get the function to get the columns for the quote card/table
const useGetQuoteHomeColumns: UseGetQuoteColumnsType = () => {
	const { getState } = store;

	return (quoteId) => {
		const state = getState();

		// TODO: implement selector for user's ISO
		const quote = selectQuoteFromId(quoteId)(state);
		const isPsAdmin = selectIsPsAdmin(state);
		const users = selectUsersAndDispatch(state);

		const userOnQuote = Object.values(users).find((u) => u.email === quote?.created_by_email);

		const {
			filter_names: filterNames
		} = state.ui.home.filterSettings;

		const {
			dealer_id: dealerId,
			distributor_id: distributorId,
			representative_id: representativeId,
			distributor_location_id: distributorLocationId
		} = quote ?? {
			dealer_id: 0,
			distributor_id: 0,
			representative_id: 0,
			distributor_location_id: 0
		};

		const possibleDealerName = useDealerById(dealerId ?? 0)?.name;
		const possibleDistributorName = useDistributorById(distributorId ?? 0)?.name;
		const possibleRepresentativeName = useRepresentativeById(representativeId ?? 0)?.name;
		const possibleDistributorLocationName = useDistributorLocationById(distributorLocationId ?? 0)?.name;

		const createdTimeStamp = (quote?.created ?? 1) * 1000;
		const updatedTimeStamp = quote?.updated ? quote.updated * 1000 : null;
		const approvedTimeStamp = (quote?.order_approved_time ?? 1) * 1000;
		const orderedTimeStamp = (quote?.ordered_time ?? 1) * 1000;
		const orderAssignedToMfgTimeStamp = (quote?.mfg_assigned_time ?? 1) * 1000;
		const sentToProductionTimeStamp = (quote?.sent_to_production_time ?? 1) * 1000;
		const orderShippedTimeStamp = (quote?.order_shipped_time ?? 1) * 1000;

		const dateOptions: Intl.DateTimeFormatOptions = {
			month: 'short',
			day: 'numeric',
			year: 'numeric',
		};
		const createdDate = (quote?.created ?? 0) !== 0 ? new Date(createdTimeStamp).toLocaleDateString('en-US', dateOptions) : "...";
		const approvedDate = (quote?.order_approved_time ?? 0) !== 0 ? new Date(approvedTimeStamp).toLocaleDateString('en-US', dateOptions) : "Unapproved";
		const orderedDate = (quote?.ordered_time ?? 0) !== 0 ? new Date(orderedTimeStamp).toLocaleDateString('en-US', dateOptions) : "Unordered";
		const orderAssignedToMfgDate = (quote?.mfg_assigned_time ?? 0) !== 0 ? new Date(orderAssignedToMfgTimeStamp).toLocaleDateString('en-US', dateOptions) : "Unassigned";
		const sentToProductionDate = (quote?.sent_to_production_time ?? 0) !== 0 ? new Date(sentToProductionTimeStamp).toLocaleDateString('en-US', dateOptions) : "Not Sent";
		const orderShippedDate = (quote?.order_shipped_time ?? 0) !== 0 ? new Date(orderShippedTimeStamp).toLocaleDateString('en-US', dateOptions) : "Not Shipped";

		const updatedDate = updatedTimeStamp ? timeAgo(updatedTimeStamp) : "...";
		
		const entityName = possibleDealerName ?? possibleDistributorName ?? possibleRepresentativeName ?? possibleDistributorLocationName ?? "";

		const entityHref = dealerId ? getDealerLink(dealerId)
			: distributorId ? getDistributorLink(distributorId)
				: representativeId ? getRepresentativeLink(representativeId)
					: distributorLocationId ? getDistributorLocationLink(distributorLocationId)
						: "";
		const isPSAdmin = selectIsPsAdmin(state);
		const msrpDisplayValue = isPSAdmin ? quote?.msrp_sale_price_snapshot ?? 0 : (quote?.msrp_sale_price_snapshot ?? 0) * (quote?.territory_multiplier ?? 1)

		const unfilteredQhColumns: QuoteHomeColumns = {
			columns: [
				{
					key: "id",
					title: 'Quote #',
					accessor: () => quote.display_id ?? "",
					href: getQuoteLink(quoteId)
				},
				{
					key: "title",
					title: 'Title',
					accessor: () => quote.title ?? ""
				},
				{
					key: "created_by",
					title: 'Created By',
					href: userOnQuote ? getUserLink(userOnQuote.id ?? "") : "",
					accessor: () =>
						quote.created_by ?? "",
					userName: () => quote.created_by ?? "",
				},
				{
					key: "company",
					title: "Company",
					accessor: () => entityName ?? "",
					href: entityHref
				},
				{
					key: "created_on",
					title: 'Created On',
					accessor: () => createdDate
				},
				{
					key: "updated",
					title: 'Last Updated',
					accessor: () => updatedDate
				},
				{
					key: "sale_price",
					title: 'Dealer Price',
					accessor: () => `$${formatMoney(quote?.dealer_sale_price_snapshot ?? 0)}`
				},
				{
					key: "msrp",
					title: 'MSRP',
					accessor: () => `$${formatMoney(msrpDisplayValue)}`
				},
				{
					key: "approved",
					title: 'Order Approved',
					accessor: () => approvedDate
				},
				{
					key: "ordered",
					title: 'Date Ordered',
					accessor: () => orderedDate
				},
				{
					key: "order_assigned_to_mfg",
					title: 'Order Assigned to MFG',
					accessor: () => orderAssignedToMfgDate
				},
				{
					key: "sent_to_production",
					title: 'Sent to Production',
					accessor: () => sentToProductionDate
				},

				{
					key: "order_shipped",
					title: 'Order Shipped',
					accessor: () => orderShippedDate
				}
			],
			loaded: true
		};

		let filteredColumns: QuoteHomeColumnKey[] = [];

		const allColumns: QuoteHomeColumnKey[] = [
			"id",
			"title",
			"created_by",
			"company",
			"created_on",
			"updated",
		];

		const activeColumns: QuoteHomeColumnKey[] = [
			"id",
			"title",
			"created_by",
			"company",
			"created_on",
			"updated",
		];

		const awaitingPoColumns: QuoteHomeColumnKey[] = [
			"id",
			"title",
			"created_by",
			"company",
			"created_on",
			"updated",
			"ordered"
		];

		const readyForManufacturingColumns: QuoteHomeColumnKey[] = [
			"id",
			"title",
			"created_by",
			"company",
			"created_on",
			"updated",
			"approved",
		];

		const submittedToMfgColumns: QuoteHomeColumnKey[] = [
			"id",
			"title",
			"created_by",
			"company",
			"created_on",
			"updated",
			"order_assigned_to_mfg",
		];

		const inProductionColumns: QuoteHomeColumnKey[] = [
			"id",
			"title",
			"created_by",
			"company",
			"created_on",
			"updated",
			"sent_to_production",
		];

		const shippedOrdersColumns: QuoteHomeColumnKey[] = [
			"id",
			"title",
			"created_by",
			"company",
			"created_on",
			"updated",
			"order_shipped",
		];

		const currentFilter = filterNames[0] ?? "";

		filteredColumns = currentFilter === "active_quotes" ? activeColumns
			: currentFilter === "awaiting_po" ? awaitingPoColumns
				: currentFilter === "ready_for_manufacturing" ? readyForManufacturingColumns
					: currentFilter === "submitted_to_manufacturing" ? submittedToMfgColumns
						: currentFilter === "in_production" ? inProductionColumns
							: currentFilter === "shipped_orders" ? shippedOrdersColumns
								: allColumns;

		if (isPsAdmin) {
			filteredColumns.push("sale_price");
		}
		filteredColumns.push("msrp");

		/* This is where dynamic or custom vals on a card/table would go */

		const fullColumnList = filteredColumns.map((fc) => {
			const column = unfilteredQhColumns.columns.find((c) => c.key === fc);
			if (!column) {
				throw new Error(`Column ${fc} not found in column list`);
			}
			return column;
		});

		if (!quote) {
			return {
				columns: [] as QuoteColumnType[],
				loaded: false
			};
		}

		return {
			columns: fullColumnList,
			loaded: true
		};
	};
};

export { useGetQuoteHomeColumns };
