import axios, { AxiosResponse } from "axios";
import { apiPost } from "./psUtil";
import type { AuthResponse } from "./User/types";
// import Logout from "./Logout/Content";
export const getToken = () => {
	const goods = localStorage.getItem("user_data") ?? "";
	const goodsObj = goods == "" ? {} : JSON.parse(goods);

	const jwt = goodsObj?.jwt ?? "";

	return jwt;
};

const API_OPTIONS = () => ({

	headers: {
		Authorization: `Bearer ${getToken()}`,
	}
});

const backendRoot = process.env.BACKEND_ROOT;

function apiGet<Type>(url: string): Promise<{ data: Type }> {
	return axios.get<{ data: Type }, any>(`${backendRoot}/api/${url}`, API_OPTIONS());
}

export function apiPut<Type>(url: string, body: any): Promise<{ data: Type }> {
	const stuff = `${backendRoot}/api/${url}`;

	return axios.put<{ data: Type }, any>(stuff, body, API_OPTIONS());
}

export type userDataRep = {
	user_data: {
		name: string;
		default_currency: string;
		email: string;
		roles: {
			role_name: string;
			entity_id: number;
			entity_name: string;
		}[];

	};
	ids: number[];
}

export const getUserData = (user_jwt_override?: string) =>
	(user_jwt_override == null
		? apiGet<userDataRep>(`auth/UserData`)
		: axios.get<{ data: userDataRep }>(`${backendRoot}/api/auth/UserData`, {
			headers: {
				Authorization: `Bearer ${user_jwt_override}`,
			},
		}));


export const login = (email: string, password: string) => apiPost<AuthResponse>("auth/login", {
	email,
	password
});

const logout = () =>
// localStorage.removeItem('user_jwt');
// localStorage.removeItem('user_refresh');
// direct_to_login && windowPS.ROOT && windowPS.ROOT.loadContent("#Login");
	true;
// .logout = async function (direct_to_login = true) {

//     localStorage.removeItem('user_jwt');

//     localStorage.removeItem('user_refresh');

//     direct_to_login && window.ROOT && window.ROOT.loadContent("#Login");

//     //direct_to_login && window.ROOT.refreshContent();

//     user.hooks.execute('login_status_changed');

//     user.setToken('');

//     return true;

// }

const attemptLogin = (
	email: string,
	password: string,
	onSuccess: (message: string) => void,
	onError: (message: string) => void
) => {
	login(email, password)
		.then(async (resp) => {
			const { status } = resp as unknown as AxiosResponse<{
				refresh: string;
				access: string;
			}>;
			// localStorage.setItem("user_refresh", data.refresh);
			localStorage.setItem("tries_404", "0");

			const success = status < 300;

			// await user.clearCache();

			if (success) {
				// user.setToken2(data.access);
				// user.setToken(data.access);
				onSuccess("Login Successful");
			} else {
				onError("No active account found with the given credentials");
				// user.logout();
			}
		})
		.catch((err) => {
			if (
				err.response
				&& err.response.data
				&& err.response.data.non_field_errors
			) {
				onError(err.response.data.non_field_errors[0]);
			}
		});
};

const refreshToken = async (refreshJwt: string) => {
	if (!refreshJwt) return new Error("No refresh token found");
	const jwtRefreshResp = apiPost<AuthResponse>("auth/Refresh", { token: refreshJwt });
	
	return jwtRefreshResp;
}

const setTheme = (userId: number, themePreference: string) =>
	apiPut(`Users/${userId}/Attribute/theme`, { value: themePreference });

const setHomePage = (userId: number, prefersTable: boolean) =>
	apiPut(`Users/${userId}/Attribute/is_using_old_homepage`, {
		value: prefersTable
	});

const getLinks = () => apiGet<{
	support_links: {
	display_name: string;
	link: string;
}[]}>("AssemblyOptions/SupportLinks");

const userApiCalls = {
	login,
	logout,
	attemptLogin,
	setTheme,
	setHomePage,
	refreshToken,
	getLinks
	// refresh
};

export default userApiCalls;
