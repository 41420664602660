import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import type { Accessory, Address, AddressStore, AssemblyOptionsRecord,  BuyingGroupMeta, Channel, DealerStore, DealerTerritory, DistributionEmail, DistributorStore, FabricStore, HardwarePart, HomeTableSet, Motor, Portfolio, QuoteAccessoriesRecord, QuoteAssembliesRecord, QuoteFabricSamplesRecord, QuoteHWRItemsRecord, QuoteMetaDataOnlyId, QuoteShipment, QuoteStatus, QuoteType, QuoteWarningsRecord, RMADepartmentType, RMARootCauseType, RMAType, Representative, SalesPerson, TermsOptions, Territory, Tube, loadStatusType } from "../../powershadesApiTypes.ts";
import type { AddressStateStore } from "./addressStates";
import { Role, UserMeta, UserStore } from "../../powershadesApiTypeExtensions";
import { QuoteCombinations, QuoteMultiplier } from "./multipliers/types.js";
import { RMAItems } from "../../Quote/OrderModal/PaymentMethods/RMAPayment/types.js";

export type FullQuote = QuoteMetaDataOnlyId & {
    quote_statuses?: QuoteStatus[] | undefined;
    quote_shipments?: QuoteShipment[] | undefined;
    shipping_address?: Address | undefined;
    property_address?: Address | undefined;
    isEvolutionShippingForced?: boolean | undefined;
    overrideLightGap?: boolean | undefined;
    overrideRailRoad?: boolean | undefined;
};

export type ManufacturerStore = {
    manufacturer_id: number;
    name: string;
    loadStatus: loadStatusType;
};

export type ManufacturerState = {
    loadStatus: loadStatusType;
    list: Record<number, ManufacturerStore>;
};


export type QuoteStore = FullQuote & {
    loadStatus: loadStatusType;
    editable: boolean;
}

export type TableStore = {
    loadStatus: loadStatusType;
    list: HomeTableSet;
}

export type QuoteTypeStore = {
    list: QuoteType[];
    loadStatus: loadStatusType;
}

export type QuoteState = {
    loadStatus: loadStatusType;
    counts: TableStore;
    types: QuoteTypeStore;
    list: Record<number, QuoteStore>;
};

export type DistributorLocationStore = {

    /* Loading enum */
    loadStatus: loadStatusType;

    /* Basic Meta Data */
    id: number;
    name: string;
    email: string;
    phone: string;

    // This is the code used in sign up links
    invite_code: string;

    // This value is the ID of the channel the distributor is in. Usually CEDIA
    channel_id: number;

    territory_ids: number[];

    dealer_territories: DealerTerritory[];

    distributor_id: number;
    
	current_portfolio: string;

    users: UserMeta[]

    distribution_emails: DistributionEmail[]

    billing_address: AddressStore
}

export type TerritoryStore = Territory & {
    /* Loading enum */
    loadStatus: loadStatusType;
};

export type RepresentativeStore = Representative & {
    loadStatus: loadStatusType;
};

export type QuoteMultiplierRecord = {
    list: QuoteMultiplier[];
    loadStatus: loadStatusType;
    currentMultiplier: string;
};

const loadStatus = {
    notLoaded: 1 as loadStatusType,
    needsLoaded: 2 as loadStatusType,
    loading: 3 as loadStatusType,
    metaLoaded: 4 as loadStatusType,
    fullyLoaded: 5 as loadStatusType
};

type EntitiesState = {
    // This is the typscript way of typing a js object so it's reated
    // similiar to a dictionary in c#. This allows for quicket lookups.
    // It's less important when the quote ids are few, but will be helpful
    // as the homepage scales.
    quotes: QuoteState,
    organizations: {
        loadStatus: loadStatusType;
    };
    salespeople: {
        list: SalesPerson[];
        loadStatus: loadStatusType;
    };
    dealers: {
        [key: number]: DealerStore;
        loadStatus: loadStatusType;
        lastMetaLoadedTime: number; 
        territoryPendingDealers: number;
        totalPendingDealers: number;
    };
    distributors: {
        [key: number]: DistributorStore;
        loadStatus: loadStatusType;
    };
    distributorLocations: {
        [key: number]: DistributorLocationStore;
        loadStatus: loadStatusType;
    };
    representatives: {
        [key: number]: RepresentativeStore;
        loadStatus: loadStatusType;
    };
    users: {
        list: Record<number, UserStore>;
        roles: {
            loadStatus: loadStatusType;
            list: Record<number, Role>;
        };
        loadStatus: loadStatusType;
    };
    territories: {
        list: TerritoryStore[];
        loadStatus: loadStatusType;
    };
    // Getting a little weird here. Go to assemblies.ts for an explanation TLDR: 
    //    assemblies[quote_id][sequence_id]
    assemblies: Record<number, QuoteAssembliesRecord>;
    // //quoteAccessories[quote_id][accessory_id]
    // quoteAccessories: { [key: number]: { [key: number]: QuoteAccessoryStore } },
    // //hardwareRequests[quote_id][line_number]
    // hardwareRequests: { [key: number]: { [key: number]: HardwareRequestStore } },
    addresses: { [key: number]: AddressStore };
    addressStates: {
        loadStatus: loadStatusType;
        list: AddressStateStore[];
    };

    fabrics: {
        loadStatus: loadStatusType;
        list: FabricStore[];
    };
    // headers: {
    //     loadStatus : loadStatusType,
    //     list: HeaderStore[]
    // },
    // accessories : {
    //     loadStatus: loadStatusType,
    //     list : AccessoryStore[]
    // },
    // QuoteTypes: {
    //     loadStatus: loadStatusType;
    //     list: QuoteType[];
    // };

    termsOptions: {
        list: TermsOptions[];
        loadStatus: loadStatusType;
    };
    channels: {
        list: Channel[];
        loadStatus: loadStatusType;
    };
    buyingGroups: {
        list: BuyingGroupMeta[];
        loadStatus: loadStatusType;
    };
    manufacturers: {
        loadStatus: loadStatusType;
        list: Record<number, ManufacturerStore>;
    };
    rmaTypes: {
        loadStatus: loadStatusType;
        list: RMAType[];
        departments: RMADepartmentType[];
        root_causes: RMARootCauseType[]
    };
    rmas: {
        loadStatus: loadStatusType;
        list: RMAItems[];
        currentQuoteId?: number;
    }
    accessories: Record<number,{
        loadStatus: loadStatusType;
        list: Accessory[];
    }>;
    // quoteAccessories[QuoteId].Items[LineNumber];
    quoteAccessories: Record<number, QuoteAccessoriesRecord>;
    quoteFabricSamples: Record<number, QuoteFabricSamplesRecord>;
    hardwareRequestItems: Record<number, QuoteHWRItemsRecord>;
    quoteWarnings: Record<number, QuoteWarningsRecord>;
    parts: {
        motors: {
            loadStatus: loadStatusType;
            list: Motor[];
        };
        tubes: {
            loadStatus: loadStatusType;
            list: Tube[];
        };
        all: {
            loadStatus: loadStatusType;
            list: HardwarePart[];
        };

    };
    quoteMulitpliers: Record<number, QuoteMultiplierRecord>;
    quoteAssemblyCombinations: Record<number, QuoteCombinations>;
    assemblyOptions: Record<number, AssemblyOptionsRecord>;
    portfolios: {
        loadStatus: loadStatusType;
        list: Portfolio[];
    };
    

};

export type extraEntityReducersFunction = (builder: ActionReducerMapBuilder<EntitiesState>) => void;

export { loadStatus, EntitiesState };
