/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @stylistic/no-mixed-operators */
/* eslint-disable camelcase */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';

import CouponInput from '../../Parts/CouponInput';
import Money from '../../Parts/Money';
import { useQuoteAccessories } from '../../Store/entities/accessories/hooks';
import { useQuoteFabricSamplesByQuoteId } from '../../Store/entities/fabricSamples/hooks';
import { useAssembliesByQuoteId } from '../../Store/entities/assemblies/hooks';
import apiCalls from '../../PowerShadesAPIFunctions';
import { UseMultipliersByQuoteId, useQuoteById, useQuoteTypeNameByQuoteId } from '../../Store/entities/quotes/hooks';
import { QuotePricingVM } from '../../powershadesApiTypes';
import QuoteObject from './QuoteObject';
import { useHWRItemsByQuoteId } from '../../Store/entities/hardwareRequestItems/hooks';

type CouponInfoProps = {
	hideDeleteButtons: boolean;
	onCouponChange: (coupons: any) => void;
	quoteId: number;
	couponCode: string;
};

const CouponInfo = ({ couponCode, hideDeleteButtons, onCouponChange, quoteId }: CouponInfoProps) =>
	(couponCode === "" ? null : (

		<div
			style={{
				display: 'flex', flexDirection: 'column', alignItems: 'right'
			}}
		>
			<span
				key={couponCode}
				style={{
					backgroundColor: '#ffff90',
					padding: '0.5em',
					display: 'inline-flex',
					flexDirection: 'row',
					justifyContent: 'space-between'
				}}
			>
				<span style={{ fontWeight: 'normal' }}>
					Coupon:&nbsp;&nbsp;
					{couponCode}
				</span>
				{
					hideDeleteButtons

						? null
						: (
							<button
								style={{ backgroundColor: 'transparent', border: 'none' }}
								onClick={() => {
								// const coupons = quote.addCoupon(null, () => { onCouponChange([]); });
									apiCalls.addCouponToQuote(couponCode, quoteId, true).then((_) => {
										onCouponChange([]);
									});
								}}
							>
								x
							</button>
						)
				}
			</span>
		</div>

	));

const OrderPriceSummaryAccessoryRow = ({ accessory, multiplier }) => (
	<tr>

		<td>{accessory.quantity}</td>
		<td>{accessory.name}</td>
		<td>
			<Money>
				{accessory.msrp * multiplier.currency_multiplier * multiplier.multiplier}
			</Money>
		</td>

	</tr>
);

type NewOrderPriceSummaryTableProps = {
	quoteId: number;
	newQuote: QuoteObject;
};

const NewOrderPriceSummaryTable = ({ quoteId, newQuote }: NewOrderPriceSummaryTableProps) => {
	const fabricSamples = useQuoteFabricSamplesByQuoteId(quoteId);
	const fabric_sample_count = fabricSamples.length;

	// const pricingNew = useQuoteById(quoteId);

	const quoteType = useQuoteTypeNameByQuoteId(quoteId);

	const assemblies = useAssembliesByQuoteId(quoteId);
	// const qas = useQuoteAccessories(quoteId);
	// const hwrItems = useHWRItemsByQuoteId(quoteId);
	// const shadeCount = assemblies.length;

	const multipliers = UseMultipliersByQuoteId(quoteId);
	let show_my_price = multipliers?.list?.find((m) => m.name === "buy") ?? {
		multiplier: 1,
		currency_multiplier: 1
	};

	const dealerId = newQuote.dealerId ?? 0;
	const isDealer = dealerId !== 0 && dealerId !== null;

	const distributorId = newQuote.distributorId ?? 0;
	const isDistributor = distributorId !== 0 && distributorId !== null;

	
	if(isDistributor)
		{
			show_my_price = multipliers?.list?.find((m) => m.name === "Distributor Price") ?? {
				multiplier: 1,
				currency_multiplier: 1
			};
		}

	if(isDealer)
	{
		show_my_price = multipliers?.list?.find((m) => m.name === "Dealer Price") 
		?? multipliers?.list?.find((m) => m.name === "My Price") 
		
		?? {
			multiplier: 1,
			currency_multiplier: 1
		};
	}


	const quote = useQuoteById(quoteId);

	const quoteAccessories = useQuoteAccessories(quoteId);

	const [localQuoteTotals, setLocalQuoteTotals] = useState<QuotePricingVM>();

	const [couponState, setCouponState] = useState<null | "active" | "deleting">(null);

	useEffect(() => {
		apiCalls.getQuotePricing(quoteId).then((response) => {
			setLocalQuoteTotals(response.data.pricing);
		});
	}, [quote, couponState]);

	const acc_rows = quoteAccessories
		.filter((acc) => acc.quantity > 0)
		.map((acc) => (
			<OrderPriceSummaryAccessoryRow
				key={acc.Id}
				accessory={acc}
				multiplier={{
					multiplier: show_my_price.multiplier,
					currency_multiplier: show_my_price.currency_multiplier
				}}
			/>
		));

	const total_oversized_fees = localQuoteTotals?.oversized_shipping ?? 0;
	const total_outdoor_shades_shipping_price = assemblies.filter((a) => a.shade_type_id !== 1).map((a) => {
		const assyVM = localQuoteTotals?.assemblies?.find((assy) => assy.sequence_id === a.sequence_id);

		return assyVM?.shipping ?? 0;
	}).reduce((a, b) => a + b, 0);

	const total_amount_coupon = localQuoteTotals?.coupon_total ?? 0;

	const total_shades_shipping_price = assemblies.filter((a) => a.shade_type_id === 1).map((a) => {
		const assyVM = localQuoteTotals?.assemblies?.find((assy) => assy.sequence_id === a.sequence_id);

		return assyVM?.shipping ?? 0;
	}).reduce((a, b) => a + b, 0);

	const total_accessories_shipping_price = quoteAccessories.map((a) => {
		const assyVM = localQuoteTotals?.accessories?.find((assy) => assy.line_number === a.line_number);

		return assyVM?.shipping ?? 0;
	}).reduce((a, b) => a + b, 0);

	const hwItems = useHWRItemsByQuoteId(quoteId);
	const hwItemDisplay = localQuoteTotals?.hardware_request_items.reduce((total, hw) => {
		const hwItem = hwItems.find((item) => item.line_number === hw.line_number) ?? { quantity: 0 };
		return total + (hwItem.quantity * hw.msrp * show_my_price.currency_multiplier * show_my_price.multiplier);
	}, 0);

	const hwItemShipping = localQuoteTotals?.hardware_request_items.reduce((total, hw) => {
		const hwItem = hwItems.find((item) => item.line_number === hw.line_number) ?? { quantity: 0 };
		return total + (hw.shipping * hwItem.quantity);
	}, 0) ?? 0;

	const priceTitle = "My Price";
	const displyPrice = (localQuoteTotals?.assemblies?.reduce((a, b) =>
		a + (b.msrp * show_my_price.currency_multiplier * show_my_price.multiplier), 0)
	?? 0);

	const displayBeforeCoupon = (
		show_my_price.multiplier * show_my_price.currency_multiplier * ((localQuoteTotals?.subtotal ?? 0))
	)
	+ total_shades_shipping_price
	+ total_accessories_shipping_price
	+ total_oversized_fees
	+ total_outdoor_shades_shipping_price
	+ hwItemShipping;
	const subtotal = displayBeforeCoupon - total_amount_coupon;
	const taxes = localQuoteTotals?.total_taxes ?? 0;

	const displayTotalPrice = subtotal + taxes;

	const putLine = displayBeforeCoupon !== displayTotalPrice && taxes > 0;

	const demo = newQuote.GetDemoInfo();

	const isDemo = newQuote.IsDemo;

	let newDisplayTotalPrice = 0;

	let demoDiscount = 0;
	if (isDemo) {
		newDisplayTotalPrice = (demo?.demoApprovalType?.name !== 'cash'
			? subtotal * (1 - demo.value / 100)
			: demo.value);
		demoDiscount = newDisplayTotalPrice - displayTotalPrice;
	}

	return (

		<div>

			<table className={`order-confirmation-table ${quoteType}`} border={0} cellSpacing="0" cellPadding="0">
				<tbody>

					<tr className="bold-row">
						<th>Qty</th>
						<th>Product</th>
						<th>{priceTitle}</th>
					</tr>

					<tr>

						<td>{assemblies.length}</td>

						<td>Shades</td>

						<td>
							<Money>{displyPrice}</Money>
						</td>

					</tr>

					<tr>

						<td>1</td>

						<td>Shades Shipping</td>

						<td>
							<Money>{total_shades_shipping_price}</Money>
						</td>

					</tr>

					{acc_rows}

					{
						total_accessories_shipping_price

							? (
								<tr>

									<td>1</td>

									<td>Accessories Shipping</td>

									<td>
										<Money>{total_accessories_shipping_price}</Money>
									</td>

								</tr>
							)
							: null
					}

					{
						total_oversized_fees > 0
							? (
								<tr>
									<td>1</td>
									<td>Oversized Shipping Fee</td>
									<td>
										<Money>{total_oversized_fees}</Money>
									</td>
								</tr>
							)
							: null
					}

					{
						total_outdoor_shades_shipping_price > 0
							? (
								<tr>
									<td>1</td>
									<td>Outdoor Shades Freight</td>
									<td>
										<Money>{total_outdoor_shades_shipping_price}</Money>
									</td>
								</tr>
							)
							: null
					}
					{
						(hwItemDisplay ?? 0) > 0 ? (
							[
								<tr>
									<td>1</td>
									<td>Hardware Request Items</td>
									<td>
										<Money>{hwItemDisplay}</Money>
									</td>
								</tr>,
								<tr>
									<td>1</td>
									<td>Hardware Request Shipping</td>
									<td>
										<Money>{hwItemShipping}</Money>
									</td>
								</tr>
							]
						) : null
					}

					{
						fabric_sample_count > 0
							? (
								<tr>
									<td>{fabric_sample_count}</td>
									<td>Fabric Samples</td>
									<td><Money>{0}</Money></td>
								</tr>
							)
							: null
					}

				</tbody>
			</table>

			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div style={{ flexGrow: '1' }}>

					{
						!isDemo
							? (
								<CouponInput
									quoteId={quoteId}
									onCouponChange={(_) => {
										setCouponState("active");
									}}
								/>
							)
							: null
					}

					<div style={{ width: '100%', height: '9px' }} />

					<CouponInfo
						hideDeleteButtons={!(!isDemo)}
						couponCode={localQuoteTotals?.coupon_code ?? ''}
						onCouponChange={(_) => {
							setCouponState("deleting");
						}}
						quoteId={quote?.id}
					/>
				</div>

				<div
					style={{
						display: 'flex', flexDirection: 'column', textAlign: 'right', marginLeft: '2.25em'
					}}
				>

					{
						total_amount_coupon
							? (
								<>
									<span>
										{total_amount_coupon ? '' : 'Total'}
										Price:&nbsp;&nbsp;
										<Money>{displayBeforeCoupon}</Money>
									</span>

									<span>
										Discount:&nbsp;&nbsp;
										<span style={{ color: 'red' }}>
											-
											<Money>{total_amount_coupon}</Money>
										</span>
									</span>

								</>
							)
							: null
					}

					{(putLine)
						? (
							<span style={{ flexGrow: '1' }}>
								<div>
									Subtotal:
									{' '}
									<Money>{subtotal}</Money>
								</div>

								<div>
									Taxes :
									{' '}
									<Money>{taxes}</Money>
								</div>
							</span>
						)
						: null}

					{
						putLine ? <hr style={{ width: '100%' }} /> : null
					}

					<span className="total-price-text right-align">
						Total:&nbsp;&nbsp;
						<Money>{displayTotalPrice}</Money>
					</span>
					{isDemo ? (
						<>
							<span className="total-price-text right-align">
								Demo Discount:&nbsp;&nbsp;
								<Money>{demoDiscount}</Money>
							</span>
							<span className="total-price-text right-align">
								Demo Total:&nbsp;&nbsp;
								<Money>{newDisplayTotalPrice}</Money>
							</span>
						</>
					) : null}
				</div>

			</div>

		</div>
	);
};

export default NewOrderPriceSummaryTable;
