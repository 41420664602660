import React, { useState, useEffect } from 'react';

export default function IntInput(props) {
	const [tempValue, setTempValue] = useState(null);
		  const [value, setValue] = useState(null);

	function validateValue(new_value) {
		if (`${parseInt(new_value)}` === `${new_value}`) {
			new_value = parseInt(new_value);

			if (!props.noNegative | new_value >= 0) {
				return true;
			}
		}

		return false;
	}

	function valueChanged(new_value) {
		if (validateValue(new_value)) {
			props.onChange && props.onChange(parseInt(new_value));
			
			setValue(parseInt(new_value));
		} else {
			setTempValue(value);
		}
	}

	function tempValueChanged(new_temp_value) {
		new_temp_value = new_temp_value.replace(/e/g, '');

		const match = (`${new_temp_value}`).match(/^[0-9]*$/);

		if (match !== null && match !== undefined) {
			setTempValue(new_temp_value);
		}
	}

	useEffect(() => {
		if (props.defaultValue && validateValue(props.defaultValue)) {
			setValue(parseInt(props.defaultValue));
			setTempValue(parseInt(props.defaultValue));
		}
	}, []);
	
	return (

		<div style={props.wrapperStyle}>

			{
				props.label

					? (
						<>
							<label htmlFor={props.label} style={props.labelStyle}>
								{ props.label }
							</label>
							<br />
						</>
					)
					:					null
			}

			<input
				type="number"
				disabled={props.disabled}
				name={props.label}
				value={tempValue === null ? '' : tempValue}
				onChange={(e) => tempValueChanged(e.target.value)}
				onBlur={(e) => valueChanged(tempValue)}
			/>
			
		</div>
	);
}
