/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @stylistic/max-len */
/* eslint-disable @stylistic/jsx-indent */
/* eslint-disable @stylistic/indent */
import React from "react";
import store from "../../../../Store";
import { updateAssembly } from "../../../../Store/entities/assemblies";
import { UseAssembly } from "../../../../Store/entities/assemblies/hooks";
import { AssemblyStore } from "../../../../Store/entities/assemblies/types";
import EditIndoorShadeModalBase from "./EditIndoorShadeBase";

type EditIndoorShadeModalProps = {
    isOpen: boolean;
    onClose: () => void;
    quoteId: number;
    sequenceId: number;
    sequenceIds?: number[];
    massEdit?: boolean | undefined;
};

const EditIndoorShadeModal = ({ isOpen, onClose, quoteId, sequenceId, sequenceIds }: EditIndoorShadeModalProps) => {
    const assy = UseAssembly(quoteId, sequenceIds?.length ? sequenceIds[0] as number : sequenceId);

    const setAssembly = (assembly: AssemblyStore) => {
        sequenceIds
            ? sequenceIds.map((id) => {
                const newAssembly: AssemblyStore = { ...assembly, sequence_id: id };
                return store.dispatch(updateAssembly({ quoteId, assembly: newAssembly, sequenceId: id }));
            })
            : store.dispatch(updateAssembly({ quoteId, assembly, sequenceId }));
    };
    if (!assy) return null;
    return (
        <EditIndoorShadeModalBase
            isOpen={isOpen}
            onClose={onClose}
            quoteId={quoteId}
            assembly={assy}
            setAssembly={setAssembly}
        />
    );
};

export default EditIndoorShadeModal;

