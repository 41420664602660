import React, { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
const FabricSelectField = ({ option, quote, onChange, disabled }) => {
	const [value, setValue] = useState(option.value);
	const [internalChoices, setChoices] = useState([]);
	const [, setZIndex] = useState(30);

	const getChoice = (val) => internalChoices.find((c) => (c.value && c.value === val) || c.name === val)

	const loadChoices = () => {
		setChoices(option?.choices() ?? internalChoices);
	}

	const changed = (newValue) => {
		const currentChoice = getChoice(option.value);
		const newChoice = getChoice(newValue);

		currentChoice && currentChoice.onDeselect && currentChoice.onDeselect(quote);
		newChoice && newChoice.onSelect && newChoice.onSelect(quote);

		option.value = newValue;

		// Update the state so the changes are reflected in the view.
		setValue(option.value);

		onChange();
	}

	const options = internalChoices.map((choice) => (
		<option
			key={choice.id ? choice.id : choice.name}
			value={choice.value != null ? choice.value : choice.name}
			disabled={choice.conditional != null && !choice.conditional(option.shade)}
		>
			{choice.name}
		</option>
	));

	if (value !== null && `${value}`.length > 0) {
		options.unshift(
			<option key={`old-val ${option.value}`} value={value}>
				{value}
			</option>
		);
	}

	if (value === null || (option.varName === "fabric2_name" && option.value === "")) {
		options.unshift(
			<option key="null-val-selector" value="">
				-
			</option>
		);
	}

	// const opacity = (opacity = "") => ({
	// 	alignItems: "center",
	// 	display: "flex",

	// 	":before": {
	// 		borderRadius: 10,
	// 		content: `"${opacity || ""}"`,
	// 		display: "block",
	// 		marginRight: 8
	// 	}
	// });

	// const selectStyles = {
	// 	control: (provided) => ({
	// 		...provided,
	// 		height: "5px",
	// 		minHeight: "25px",
	// 		borderRadius: "2px"
	// 	}),
	// 	container: (provided) => ({
	// 		...provided,
	// 		width: "310px",
	// 		border: "1px solid gray",
	// 		borderRadius: "3px",
	// 		fontSize: "15px",
	// 		maxWidth: "100%",
	// 		flex: 1,
	// 		zIndex
	// 	}),
	// 	valueContainer: (provided) => ({
	// 		...provided,
	// 		height: "100%",
	// 		color: "red"
	// 	}),
	// 	dropdownIndicator: (provided) => ({
	// 		...provided,
	// 		height: "100%",
	// 		paddingTop: "3px"
	// 	}),
	// 	indicatorsContainer: (provided) => ({ ...provided, height: "100%" }),
	// 	menu: (provided) => ({
	// 		...provided,
	// 		position: "absolute !important",
	// 		zIndex: 102
	// 	}),
	// 	option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
	// 		...styles,
	// 		cursor: "pointer",
	// 		position: "relative",
	// 		padding: "4px",
	// 		fontSize: "16px",
	// 		position: "relative",
	// 		textAlign: "left",
	// 		whiteSpace: " no-wrap",
	// 		textOverflow: "ellipsis",

	// 		"::after": {
	// 			...styles[":before"],
	// 			content: `"| ${data.opacity || ""}"`,
	// 			display: "inline-block",
	// 			position: "absolute",
	// 			right: "9px",
	// 			transition: "0.5s ease",
	// 			backgroundColor: isFocused ? "rgba(255,255,255,0.2)" : "white"
	// 		}
	// 	}),
	// 	input: (styles) => ({ ...styles }),
	// 	placeholder: (styles) => ({ ...styles }),
	// 	singleValue: (styles, { data }) => ({ ...styles, ...opacity(data.opacity) })
	// };

	const focused = () => {
		if (internalChoices.length < 1) {
			loadChoices();
		}

		setZIndex(50);
	}

	const blurred = () => {
		setZIndex(1);
	}

	const selectionFromOptions = options.find((o) => o.value === value);

	return (
		<Typeahead
			id="fabric-select-2"
			disabled={disabled}
			options={internalChoices.map((c) => ({
				value: c.name,
				label: c.name,
				opacity: c.opacity
			}))}
			onChange={(e) => changed(e.value)}
			selected={[selectionFromOptions] ?? []}
			// styles={selectStyles}
			onFocus={focused}
			onBlur={blurred}
		/>

	/*
		<select 
			disabled={ props.disabled } 
			onBlur={ props.onBlur } 
			onFocus={ props.onFocus } 
			onChange={e=> changed(e.target.value) }
			onMouseDown={loadChoices}
			value={ value || '' }
		>
		
			{ options }

		</select> */
	);
};

export default FabricSelectField;
