import React from "react";
import Popup from "reactjs-popup";
import PowerShadesTypeahead from "../Parts/PowerShadesTypeahead";
import { CardinalDirectionType } from "../Store/entities/assemblies/types";
import FractionField from "./NewOptionFields/FractionField";
import QuoteAssemblyLockSelect from "./QuoteAssemblyLockSelect";
import { UseAssembly } from "../Store/entities/assemblies/hooks";
import FabricSelectField from "./NewOptionFields/FabricSelectField";
import { useQuoteById } from "../Store/entities/quotes/hooks";
import ShadeActions from "./NewOptionFields/ShadeActions";
import QuoteData from "./NewItems/QuoteData";
import TextField from "./NewOptionFields/TextField";
import { useCurrentCOROutdoorHooks } from "../Store/entities/assemblies/COROutdoorHooks";
import { useErrorsBySeqAndQuoteId } from "../Store/entities/assemblies/errors/hooks";
import PricingUI from "../PricingUI";

type COROutdoorRowProps = {
	quoteId: number;
	sequenceId: number;
	unlockShadeBySequenceId: (sequenceId: number) => void;
	quoteData: QuoteData;
};

const COROutdoorTableRow = ({ quoteId, sequenceId, unlockShadeBySequenceId, quoteData }: COROutdoorRowProps) => {
	const assembly = UseAssembly(quoteId, sequenceId);
	const psCOROutdoorShadeOptions = useCurrentCOROutdoorHooks(quoteId)(sequenceId);
	const errors = useErrorsBySeqAndQuoteId(quoteId, sequenceId);

	const { editable } = useQuoteById(quoteId) ?? { editable: false };
	const {
		Width,
		Height,
		RoomName,
		ShadeName,
		DirectionFacing,
		Fabric,
		SideGuides
	} = psCOROutdoorShadeOptions;

	if (!assembly) return null;

	const setNewHeight = (newHeight) => {
		Height.setValue(newHeight);
	};

	const setNewWidth = (newWidth) => {
		Width.setValue(newWidth);
	};

	const setNewShadeName = (newShadeName) => {
		ShadeName.setValue(newShadeName);
	};

	const setNewRoomName = (newRoomName) => {
		RoomName.setValue(newRoomName);
	};

	const assemblyErrorMessages = `${assembly.error_messages}${errors.reduce((acc, error) => `${acc} ${error.message}`, "")}`;

	const isErrored = (errors.length > 0) || (assembly.error_messages !== null && assembly.error_messages?.match(/^ *$/) === null && assembly.msrp === 0);
	const errored = `${isErrored ? "errored" : ""}`;

	return (
		<tr className={errored} key={assembly.sequence_id}>
			<td>
				<QuoteAssemblyLockSelect quoteId={quoteId} sequenceId={sequenceId} unlockShadeBySequenceId={unlockShadeBySequenceId} />
			</td>
			<td>
				<TextField
					disabled={!editable || !assembly.unlocked}
					value={RoomName.getValue() ?? ""}
					onBlur={setNewRoomName}
					handleEnter={setNewRoomName}
				/>
			</td>
			<td>
				<TextField
					disabled={!editable || !assembly.unlocked}
					value={ShadeName.getValue() ?? ""}
					onBlur={setNewShadeName}
					handleEnter={setNewShadeName}
				/>
			</td>

			<td>
				<PowerShadesTypeahead
					disabled={!editable || !assembly.unlocked}
					id="shade-configuration"
					options={DirectionFacing.options ?? []}
					selected={[DirectionFacing.getSelectedOption()]}
					positionFixed
					onChange={(selected) => {
						if (selected.length === 0) {
							DirectionFacing.clearValue();
						}
						const selectedObj = selected && selected[0] ? selected[0] as {
							value: CardinalDirectionType;
						} : { value: "" as CardinalDirectionType };
						DirectionFacing.setValue(selectedObj.value);
					}}
					className="text-center"
					style={{ width: "10em" }}
				/>
			</td>
			<td>
				<FabricSelectField
					className="-cell"
					style={{ width: "15em" }}
					disabled={!editable || !assembly.unlocked}
					value={assembly.shades[0]?.fabric_name ?? ""}
					choices={Fabric.options}
					canceled={Fabric.clearValue}
					onChange={(newFabricName) => {
						Fabric.setValue(newFabricName);
					}}
					onBlur={() => {
						console.log("blur");
					}}
				/>
			</td>
			<td>
				<PowerShadesTypeahead
					disabled={!editable || !assembly.unlocked}
					id="side-guides"
					options={SideGuides.options ?? []}
					selected={[SideGuides.getSelectedOption()]}
					positionFixed
					onChange={(selected) => {
						if (selected.length === 0) {
							SideGuides.clearValue();
						}
						const selectedObj = selected && selected[0] ? selected[0] as {
							value;
						} : { value: "" };
						SideGuides.setValue(selectedObj.value);
					}}
					className="text-center"
				/>
			</td>
			<td className="w-50">
				<FractionField
					key={`width-${sequenceId}`}
					disabled={!editable || !assembly.unlocked}
					value={assembly?.shades[0]?.width ?? 0.0}
					onBlur={setNewWidth}
					minValue={Width.minValue ?? 0.0}
					maxValue={Width.maxValue ?? 0.0}
					varName="width"
                    width={10}
				/>
			</td>
			<td>
				<FractionField
					key={`height-${sequenceId}`}
					disabled={!editable || !assembly.unlocked}
					value={assembly?.shades[0]?.height ?? 0.0}
					onBlur={setNewHeight}
					minValue={Height.minValue ?? 0.0}
					maxValue={Height.maxValue ?? 0.0}
					varName="height"
                    width={10}
				/>
			</td>
			<td>
				{
					isErrored ? (
						<Popup
							trigger={<span>Error</span>}
							position="right center"
							on="hover"
						>
							<div>
								<h6>{assemblyErrorMessages}</h6>
							</div>
						</Popup>
					) : <PricingUI msrp={assembly.msrp} quoteId={quoteId} assembly={assembly} />
				}

			</td>
			<td>
				<ShadeActions
					quoteId={quoteId}
					sequenceId={assembly.sequence_id}
					quoteData={quoteData}
				/>
			</td>

		</tr>);
};

export default COROutdoorTableRow;
