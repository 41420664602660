/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @stylistic/no-mixed-operators */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @stylistic/max-len */
/* eslint-disable camelcase */
import { capitalize } from "lodash";
import { InitialAssemblyState, InitialShadeState, updateAssembly } from "..";
import store, { UsePortalSelector } from "../../..";
import { useUser } from "../../../user/hooks";
import { UseAssembly } from "../hooks";
import { AssemblyStore, CurrentIndoorShadeOptionsFunc, IndoorMountType, IndoorShadeConfiguration, IndoorMotorType, IndoorShadeOptionsAllDict, IndoorSideChannels, IndoorShadeOptionsFunc, PortalShadeOptionItem, IndoorDriveType, IndoorManualChain, IndoorHembarType, IndoorLvPowerSource, IndoorControlSide, IndoorHeaderType, IndoorBalancedLightgaps, IndoorEndCaps, CardinalDirectionType, ShadeStore, IndoorDriveTypeOption, PortalShadeOption } from "../types";
import IndoorShadeOptionsCoupled from "./IndoorCoupledHooks";
import IndoorShadeOptionsDualCoupled from "./IndoorDualCoupledHooks";
import IndoorShadeOptionsDual from "./IndoorDualHooks";
import IndoorShadeOptionsSingle from "./IndoorSingleHooks";

import MotorizedShade from "../../../../assets/img/illus/motorized_shade.png";
import SpringPull from "../../../../assets/img/illus/spring_pull.png";
import ManualShade from "../../../../assets/img/illus/manual_shade.png";
import FabricWidth from "../../../../assets/img/illus/fabric_width.png";
import InsideMount from "../../../../assets/img/illus/inside_mount.png";
import OutsideMount from "../../../../assets/img/illus/outside_mount.png";
import RightControl from "../../../../assets/img/illus/right_control.png";
import LeftControl from "../../../../assets/img/illus/left_control.png";
// import InternalFlatHembar from "../../../../assets/img/internal_flat_hembar.png";
// import InternalRoundedHembar from "../../../../assets/img/internal_rounded_hembar.png";
// import ExternalHembar from "../../../../assets/img/external_hembar.png";
// import HembarWithMagnet from "../../../../assets/img/illus/magnet_hembar.png";
import SideChannels from "../../../../assets/img/illus/side_channels.png";
import SillPlate from "../../../../assets/img/illus/sill_plate.png";
import SideAndSill from "../../../../assets/img/illus/side_and_sill_channels.png";

import PoE from "../../../../assets/img/illus/poe-icon.png";
import AC from "../../../../assets/img/illus/ac-icon.png";
import DC from "../../../../assets/img/illus/dc-icon.png";
import Battery from "../../../../assets/img/illus/battery-icon.png";

import Cassette_3_FW_Single from '../../../../assets/img/Cassette_3_FW_Single.png';
import Fascia_3_Single from '../../../../assets/img/Fascia_3_Single.png';
import Fascia_5_Dual from '../../../../assets/img/Fascia_5_Dual.png';
import Fascia_5_Single from '../../../../assets/img/Fascia_5_Single.png';
import Open_Roll_Large_Dual from '../../../../assets/img/Open_Roll_Large_Dual.png';
import Open_Roll_Large_Single from '../../../../assets/img/Open_Roll_Large_Single.png';
import Open_Roll_Medium_Dual from '../../../../assets/img/Open_Roll_Medium_Dual.png';
import Open_Roll_Medium_Single from '../../../../assets/img/Open_Roll_Medium_Single.png';
import Open_Roll_Small_Single from '../../../../assets/img/Open_Roll_Small_Single.png';
// import Fascia_7_Dual from '../../assets/img/Fascia_7_Dual.png';
import Fascia_7_Dual from '../../../../assets/img/Fascia_7_Dual.png';
import Tube_Center from '../../../../assets/img/illus/fabric alignement/TUBE-CENTER.jpg';
import Tube_Left from '../../../../assets/img/illus/fabric alignement/TUBE-LEFT.jpg';
import Tube_Right from '../../../../assets/img/illus/fabric alignement/TUBE-RIGHT.jpg';
import { useIndoorFabrics } from "../../hooks";
import { useQuoteById } from "../../quotes/hooks";
import { useAssemblyOptions, useGetFullPaletteList } from "../../assemblyOptions/hooks";

const tubeImages = {
	Center: Tube_Center,
	Left: Tube_Left,
	Right: Tube_Right
};

const useCurrentIndoorShadeOptions: CurrentIndoorShadeOptionsFunc = (quoteId) => (sequenceId) => {
	const assembly = UseAssembly(quoteId, sequenceId) ?? InitialAssemblyState();

	const setAssembly = (assembly: AssemblyStore) => {
		store.dispatch(updateAssembly({
			assembly,
			quoteId,
			sequenceId
		}));
		console.log(assembly);
	};

	const indoorFabrics = useIndoorFabrics(quoteId);
	const user = useUser();
	const quote = useQuoteById(quoteId);
	const assemblyViewModel = UsePortalSelector(useAssemblyOptions(quoteId));

	return useNewIndoorShadeOptions(assembly, setAssembly, { fabrics: indoorFabrics, user, quote, assemblyOptions: assemblyViewModel });
};

// Generic Factory Function to make one list of options to rule them all.
const useNewIndoorShadeOptions: IndoorShadeOptionsFunc = (assembly, setAssemblyInit, externalDatas) => {
	const {
		is_coupled,
		is_dual
	} = assembly;

	const {
		quote,
		assemblyOptions: assemblyOptionSet
	} = externalDatas;

	const {
		shade_types = [],
		headers = [],
		full_palette_list = [],
		color_details = [],
		fabric_alignments = [],
		lv_power_supply = [],
		light_gaps = [],
		direction_facing = [],

	} = assemblyOptionSet;

	const headerTypeSelected = headers?.find((ht) => ht.name === assembly.header_line);

	const drive_types = headerTypeSelected?.drive_types ?? [];

	const isMotorized = assembly.shades[0]?.shade_type === "motorized";
	const isSpring = assembly.shades[0]?.manual_chain === "spring" && !isMotorized;
	const isFabricWidth = assembly.mount_style === "Fabric Width";

	// const userData = externalData.user;
	// const isPsAdmin = userData?.roles?.some(r => r.role_name === "powershades_admin") ?? false;
	// Hardcoded bidspec id. Still bad practice.
	// const isBidspec = quote?.quote_type_id === 3;

	const baseDict = (is_coupled && is_dual) ? IndoorShadeOptionsDualCoupled(assembly, setAssemblyInit, externalDatas)
		: (is_coupled) ? IndoorShadeOptionsCoupled(assembly, setAssemblyInit, externalDatas)
			: (is_dual) ? IndoorShadeOptionsDual(assembly, setAssemblyInit, externalDatas)
				: IndoorShadeOptionsSingle(assembly, setAssemblyInit, externalDatas);

	// // Header Mount Options by IndoorMountType
	// const headerMountOptions: PortalShadeOptionItem<IndoorMountType>[] = [
	//     { label: "Inside", value: "Inside", title: "Inside" },
	//     { label: "Outside", value: "Outside", title: "Outside" },
	//     { label: "Fabric Width", value: "Fabric Width", title: "Fabric Width" }
	// ];

	const driveTypeSelected = drive_types?.find((dt) => dt.value === (isMotorized ? assembly.shades[0]?.motor_type : assembly.shades[0]?.manual_chain));

	// Shade Configuration Options by IndoorShadeConfiguration
	const shadeConfigurationOptions: PortalShadeOptionItem<IndoorShadeConfiguration>[] = driveTypeSelected?.shade_configuration_allowances?.filter(sc => headerTypeSelected?.shade_configurations?.some(hsc => hsc.value === sc) ?? false)
		.map((st) => {
			const allowedConfiguration = headerTypeSelected?.shade_configurations ?? [];
			const shadeType = allowedConfiguration?.find((st2) => st === st2.value);
			return {
				label: shadeType?.name ?? "",
				value: shadeType?.value as IndoorShadeConfiguration,
				title: shadeType?.name ?? ""
			}
		}) ?? [];

	const shadeConfigType = assembly.is_coupled && assembly.is_dual ? "dual_coupled"
		: assembly.is_coupled ? "coupled"
			: assembly.is_dual ? "dual"
				: "single";

	const shadeConfigurationOptionSelected = shadeConfigurationOptions?.find((sco) => sco.value == shadeConfigType);

	// Direction Facing Options by string (North, South, East, West)
	const directionFacingOptions: PortalShadeOptionItem<CardinalDirectionType>[] = direction_facing?.map((df) => ({
		label: df.name,
		value: df.value as CardinalDirectionType,
		title: df.name
	}));

	// Drive Type Options by IndoorDriveType
	const shadeTypeOptions: PortalShadeOptionItem<IndoorDriveType>[] = shade_types?.map((dt) => ({
		label: dt.name,
		value: dt.value as IndoorDriveType,
		title: dt.name
	}));

	// // Motor Type Options by IndoorMotorType
	// const motorTypeOptions: PortalShadeOptionItem<IndoorMotorType>[] = [
	//     { label: "POE", value: "poe", title: "POE" },
	//     { label: "High Voltage", value: "high_voltage", title: "High Voltage" },
	//     { label: "Low Voltage HW", value: "low_voltage_hw", title: "Low Voltage HW" },
	//     { label: "Battery", value: "low_voltage", title: "Low Voltage" },
	//     { label: "POE Gen 2", value: "poe 2", title: "POE Gen2" }
	// ];
	// Motor Type Options by IndoorMotorType
	const motorTypeOptions: PortalShadeOptionItem<IndoorMotorType>[] = drive_types?.filter((dt) => dt.shade_type === "motorized").map((mt) => ({
		label: mt.name,
		value: mt.value as IndoorMotorType,
		title: mt.name
	}));

	// // Manual Type Options by IndoorManualChain
	// const manualTypeOptions: PortalShadeOptionItem<IndoorManualChain>[] = [
	//     { label: "Metal Chain", value: "metal", title: "Metal" },
	//     { label: "Plastic Chain", value: "plastic", title: "Plastic" },
	//     { label: "Spring Pull", value: "spring", title: "Spring" }
	// ];
	// Manual Type Options by IndoorManualChain
	const manualTypeOptions: PortalShadeOptionItem<IndoorManualChain>[] = drive_types?.filter((dt) => dt.shade_type === "manual").map((mt) => ({
		label: mt.name,
		value: mt.value as IndoorManualChain,
		title: mt.name
	}));

	// Indoor Power Supply by IndoorLvPowerSource
	const lvPowerSupplyOptions: PortalShadeOptionItem<IndoorLvPowerSource>[] = lv_power_supply?.map((ps) => ({
		label: ps.name,
		value: ps.value as IndoorLvPowerSource,
		title: ps.name
	})) ?? [];

	const isAc = assembly.shades[0]?.motor_type === "high_voltage";
	const isPoe = assembly.shades[0]?.motor_type === "poe" || assembly.shades[0]?.motor_type === "poe 2";

	const isManual = assembly.shades[0]?.shade_type === "manual";

	const headerTypeOptions: PortalShadeOptionItem<IndoorHeaderType>[] = headers?.filter((h) =>
		(h?.series_attatched_to_header?.includes("indoor_roller_shade") ?? false) &&
		(h.drive_types?.some((dt) => dt.shade_type === (isManual ? "manual" : "motorized")) ?? false)
	).map((ht) => ({
		label: ht.name,
		value: ht.name as IndoorHeaderType,
		title: ht.name
	}));

	const hembars = headerTypeSelected?.hembars ?? [];

	// Indoor Hembar type by IndoorHembarType
	const hembarLineOptions: PortalShadeOptionItem<IndoorHembarType>[] = hembars?.map((ht) => ({
		label: ht.name,
		value: ht.value as IndoorHembarType,
		title: ht.name
	})) ?? [];

	const currentHembarOption = hembars?.find((ho) => ho.value === assembly.shades[0]?.hembar_line);

	const hembarStyleOptions = currentHembarOption?.hembar_styles?.map((hs) => ({
		label: hs.name,
		value: hs.value as IndoorHembarType,
		title: hs.name
	})) ?? [];

	const fabricAlignmentOptions = fabric_alignments?.map((fa) => ({
		label: fa.name,
		value: fa.value,
		title: fa.name
	})) ?? [
			{
				label: "Center",
				value: "Center",
				title: "Center"
			}
		];

	// Header Mount Options by IndoorMountType
	const headerMountOptions: PortalShadeOptionItem<IndoorMountType>[] = headerTypeSelected?.mount_types
		.filter((mt) => mt.is_coupled_allowed === is_coupled || !is_coupled)
		.map((mt) => ({
			label: mt.name,
			value: mt.value as IndoorMountType,
			title: mt.name
		})) ?? [];

	const sideChannelOptions: PortalShadeOptionItem<IndoorSideChannels>[] = headerTypeSelected?.channels.map((sc) => ({
		label: sc.name,
		value: sc.value as IndoorSideChannels,
		title: sc.name
	})) ?? [];

	// type IndoorBalancedLightgaps = 'balanced' | 'unbalanced';
	const balancedLightGapsOptions: PortalShadeOptionItem<IndoorBalancedLightgaps>[] = light_gaps?.map((lg) => ({
		label: lg.name,
		value: lg.value as IndoorBalancedLightgaps,
		title: lg.name
	})) ?? [];

	const endCaps = headerTypeSelected?.end_caps ?? [];

	const endCapOptions: PortalShadeOptionItem<IndoorEndCaps>[] = endCaps?.map((ec) => ({
		label: ec.name,
		value: ec.value as IndoorEndCaps,
		title: ec.description
	})) ?? [];

	const isSideChannelsAllowed = currentHembarOption?.is_side_channels_allowed ?? true;
	const isLVBattery = assembly.shades[0]?.motor_type === "low_voltage";

	const bandCount = assembly?.band_count === 0 ? 1 : assembly?.band_count ?? 1;
	const totalWidth = assembly?.total_width ?? assembly?.shades?.find((s) => s.row_coordinate === 0 && s.column_coordinate === 0)?.width ?? 0;

	const isInsideMount = assembly.mount_style === "Inside";
	const isFabricWidthMount = assembly.mount_style === "Fabric Width";

	const controlSides = headerTypeSelected?.control_sides ?? [];

	const controlSideOptions: PortalShadeOptionItem<IndoorControlSide>[] = controlSides?.map((cs) => ({
		label: cs.name,
		value: cs.value as IndoorControlSide,
		title: cs.name
	})) ?? [];

	const widthInfo = (() => {
		if (totalWidth === 0) return [];
		const variableAmount = bandCount % 8;
		const bigNumberAmount = bandCount / 8;

		const bigNumberString = bigNumberAmount > 0 ? `${bigNumberAmount}` : '';
		const variableString = variableAmount > 0 ? `${variableAmount}/8` : '';

		let variableEigths = '';

		if (bigNumberAmount > 0) {
			variableEigths += bigNumberString;
		}

		if (bigNumberAmount > 0 && variableAmount > 0) {
			variableEigths += ' ';
		}

		if (variableAmount > 0) {
			variableEigths += variableString;
		}

		let widthString = `${totalWidth - bigNumberAmount} - ${totalWidth}`;

		if (!isInsideMount) {
			variableEigths = `${variableEigths}`;
			widthString = `${totalWidth} - ${totalWidth + bigNumberAmount}`;
		}

		let bracketString = `Actual Width (Bracket to Bracket):`;

		if (isFabricWidthMount) {
			bracketString = `Actual Width (Fabric edge to Fabric edge):`;
		}

		return [bracketString, `${widthString}`];
	})();

	const currentHeader = headers?.find((h) => h.name === assembly.header_line);

	const headerColors = currentHeader?.color_palettes ?? [];
	const getPalettes = useGetFullPaletteList(quote?.id ?? 0);

	// Set assembly partial for easy updates
	const setAssemblyPartial = (newAssembly: Partial<AssemblyStore>) => {
		// Check if any options are a list of options with only one option allowed
		// If so, set the value to the first option in the list.
		let editableNewAssembly = { ...newAssembly };
		const effectiveAssemblyForColors = {
			...assembly,
			...newAssembly
		}

		// Endcaps
		if (endCaps.length === 1) {
			editableNewAssembly.end_caps = endCaps[0]?.value ?? "";
		}

		if (!editableNewAssembly?.colors) {
			const palettesUsed = Object.entries(assembly.colors).map(c => ({ name: c[0], value: c[1] }));
			const fullColorsListExpected = getPalettes(effectiveAssemblyForColors);

			const colorsToRemove = palettesUsed.filter((pu) => !fullColorsListExpected.some((fcl) => fcl.name === pu.name));
			const colorsToAdd = fullColorsListExpected
				.filter((fcl) => !palettesUsed
					.some((pu) => pu.value != "Missing Default Color" && pu.value != "" && pu.name === fcl.name));


			const isCustomPalette = assembly.palette_color === "Custom Palette";
			const defaultColors = currentHeader?.default_color_palettes?.find((dcp) => dcp.name === assembly.palette_color)?.defaultColors ?? {};

			colorsToAdd.forEach((c) => {
				let defaultColor = defaultColors[c.name] ?? headerColors?.find((hc) => hc.choices.includes(c.name))?.choices[0] ?? "";
				if (defaultColor === "Missing Default Color") defaultColor = fullColorsListExpected?.find((hc) => hc.name == c.name)?.choices[0] ?? "";
				editableNewAssembly.colors = {
					...editableNewAssembly.colors,
					[c.name]: isCustomPalette ? "" : defaultColor
				};
			});

			const newColorObject = {
				...assembly.colors,
				...editableNewAssembly.colors
			};

			colorsToRemove.forEach((c) => {
				const colors = assembly.colors;
				if (!colors) return;
				delete newColorObject[c.name];
			});


			editableNewAssembly.colors = newColorObject
		}

		setAssemblyInit({
			...assembly,
			...editableNewAssembly
		});
	};

	const colorSelections = getPalettes(assembly).map((colPal) => {
		const colorChoices = colPal.choices.map((cc) => ({
			label: cc,
			value: cc,
			title: cc,
			hexCode: color_details.find((color) => color.name === cc)?.hexcode || "transparent"
		}));

		const setColorPartial = (colorKey: string, value: string) => {
			setAssemblyPartial({
				colors: {
					...assembly.colors,
					[colorKey]: value
				}
			});
		};

		const colorOption: PortalShadeOption<string> = {
			label: colPal.name,
			options: colorChoices,
			clearValue: () => {
				setAssemblyPartial({
					colors: {
						...assembly.colors,
						[colPal.name]: ""
					}
				});
			},
			setValue: (value) => {
				setColorPartial(colPal.name, value);
			},
			getSelectedOption: () => colorChoices?.find((opt) => opt.value?.toLowerCase() === assembly.colors[colPal.name]?.toLowerCase()) as PortalShadeOptionItem<string>,
			varName: "color_palette",
			error: [],
			getValue: () => assembly.colors[colPal.name] ?? "",
			valuePopup: colorChoices,
			info: [],
		};

		return colorOption;
	}) ?? [];

	const headerSelection = headers?.find((h) => h.name === assembly.header_line);
	const colorPaletteOptions = headerSelection?.default_color_palettes.map((cp) => ({
		label: cp.name,
		value: cp.name,
		title: cp.name,
		default_colors: cp.defaultColors,
		hexCode: color_details.find((color) => color.name === cp.name)?.hexcode || "transparent"
	})) ?? [];

	const currentPalette = colorPaletteOptions?.find((colPal) => colPal.value === assembly.palette_color);
	const hasPaletteBeenAltered = Object.keys(currentPalette?.default_colors ?? {}).some((key) => {
		if (!assembly.colors[key]) return false;

		const currentColor = assembly.colors[key];
		const paletteColor = currentPalette?.default_colors[key];

		if (paletteColor === "" || paletteColor === "Missing Default Color") return false;

		return currentColor !== paletteColor;
	});

	const colorPaletteOptionAll = [
		...colorPaletteOptions,
		{
			label: "Custom Palette",
			value: "Custom Palette",
			title: "Custom Palette",
			default_colors: {},
		}
	];

	const selectedEndCapOption = endCapOptions?.find((opt) => opt.value === assembly.end_caps) as PortalShadeOptionItem<IndoorEndCaps>;
	const allDict: IndoorShadeOptionsAllDict = {
		HeaderMountType: {
			label: "Mount Type",
			options: headerMountOptions,
			clearValue: () => {
				setAssemblyPartial({
					mount_style: ""
				});
			},
			setValue: (value) => {
				setAssemblyPartial({
					mount_style: value
				});
			},
			getSelectedOption: () => headerMountOptions?.find((opt) => opt.value?.toLowerCase() === assembly.mount_style?.toLowerCase()) as PortalShadeOptionItem<IndoorMountType>,
			varName: "mount_type",
			error: [],
			getValue: () => assembly.mount_style as IndoorMountType,
			valuePopup: isInsideMount ? InsideMount
				: isFabricWidth ? FabricWidth : OutsideMount,
			info: isInsideMount ? ["PowerShades advises measuring down to a 1/16” of an inch and subtracting 1/8” of an inch off the measurement from the top of the window for an inside mount. Please note that measurements may vary between the front and back of the window jamb. The 1/8” of an inch is to be taken from the narrowest measurement obtained."] : []
		},
		ShadeConfiguration: {
			label: "Shade Configuration",
			options: shadeConfigurationOptions,
			clearValue: () => {
				setAssemblyPartial({
					is_dual: false,
					is_coupled: false,
					band_count: 0,
					shades: [assembly?.shades?.find((cs) => cs.row_coordinate === 0 && cs.column_coordinate === 0) ?? InitialShadeState()],
				});
			},
			setValue: (value) => {
				const currentShades = assembly?.shades ?? [InitialShadeState()];
				const hasBackRow = currentShades.some((cs) => cs.row_coordinate === 1);
				const hasCoupledShades = currentShades.some((cs) => cs.column_coordinate > 0);

				const isCurrentCoupled = assembly?.is_coupled ?? false;
				const isCurrentDual = assembly?.is_dual ?? false;

				if (
					!isCurrentCoupled && !isCurrentDual && value === 'single'
					|| !isCurrentCoupled && isCurrentDual && value === 'dual'
					|| isCurrentCoupled && !isCurrentDual && value === 'coupled'
					|| isCurrentCoupled && isCurrentDual && value === 'dual_coupled'

				) {
					return;
				}

				if (value === "dual") {
					// From Single
					let newShades = [...currentShades, ...currentShades.map((s) => ({ ...s, row_coordinate: 1 }))];

					if (hasBackRow && hasCoupledShades) {
						// From Dual Coupled
						newShades = currentShades.filter((s) => s.column_coordinate === 0);
					} else if (hasCoupledShades) {
						// From Single Coupled
						const firstShade = currentShades?.find((s) => s.column_coordinate === 0 && s.row_coordinate === 0) ?? InitialShadeState();
						newShades = [firstShade, { ...firstShade, row_coordinate: 1 }];
					}

					setAssemblyPartial({
						is_dual: true,
						is_coupled: false,
						band_count: 1,
						shades: newShades
					});
				} else if (value === "coupled") {
					// From Single
					let newShades: ShadeStore[] = [...currentShades, { ...currentShades[0]!, column_coordinate: 1 }];
					let bandCount = 2;

					if (hasBackRow && hasCoupledShades) {
						// From Dual Coupled
						newShades = currentShades.filter((s) => s.row_coordinate === 0);
						bandCount = newShades.length ?? 1;
					} else if (hasBackRow) {
						// From Dual
						const frontFirstShade = currentShades?.find((s) => s.row_coordinate === 0 && s.column_coordinate === 0) ?? InitialShadeState();
						newShades = [frontFirstShade, { ...frontFirstShade, column_coordinate: 1 }];
					}
					setAssemblyPartial({
						is_dual: false,
						is_coupled: true,
						band_count: bandCount,
						shades: newShades
					});
				} else if (value === "dual_coupled") {
					// From Single
					const frontShade = currentShades?.find((a) => a.row_coordinate === 0) ?? InitialShadeState();
					const newBackShade = { ...frontShade, row_coordinate: 1 };
					let newShades = [frontShade, newBackShade, { ...frontShade, column_coordinate: 1 }, { ...newBackShade, column_coordinate: 1 }];
					const bandCount = 2;

					if (hasBackRow) {
						// From Dual
						const currentBackShade = currentShades?.find((cs) => cs.row_coordinate === 1 && cs.column_coordinate === 0) ?? InitialShadeState();
						newShades = [frontShade, currentBackShade, { ...frontShade, column_coordinate: 1 }, { ...currentBackShade, column_coordinate: 1 }];
					} else if (hasCoupledShades) {
						// From Single Coupled
						newShades = [...currentShades, ...currentShades.map((cs) => ({ ...cs, row_coordinate: 1 }))];
					}

					setAssemblyPartial({
						is_dual: true,
						is_coupled: true,
						band_count: bandCount,
						shades: newShades
					});
					// TO single
				} else {
					setAssemblyPartial({
						is_dual: false,
						is_coupled: false,
						band_count: 1,
						shades: [currentShades?.find((cs) => cs.row_coordinate == 0 && cs.column_coordinate == 0) ?? InitialShadeState()]
					});
				}
			},
			getSelectedOption: () => shadeConfigurationOptionSelected as PortalShadeOptionItem<IndoorShadeConfiguration>,
			varName: "shade_configuration",
			error: [],
			info: [],
			getValue: () => (assembly.is_coupled && assembly.is_dual ? "dual_coupled"
				: assembly.is_coupled ? "coupled"
					: assembly.is_dual ? "dual"
						: "single"),

		},
		DirectionFacing: {
			label: "Direction Facing",
			options: directionFacingOptions,
			clearValue: () => {
				setAssemblyPartial({
					direction_facing: ""
				});
			},
			setValue: (value) => {
				setAssemblyPartial({
					direction_facing: value
				});
			},
			getSelectedOption: () => directionFacingOptions?.find((opt) => opt.value === assembly.direction_facing) as PortalShadeOptionItem<string>,
			varName: "direction_facing",
			error: [],
			info: [],
			getValue: () => assembly.direction_facing,

		},
		RoomName: {
			label: "Room Name",
			options: [],
			clearValue: () => {
				setAssemblyPartial({
					room_name: ""
				});
			},
			setValue: (value) => {
				setAssemblyPartial({
					room_name: value
				});
			},
			getSelectedOption: () => ({ label: "", value: "", title: "" }),
			varName: "room_name",
			error: [],
			info: [],
			getValue: () => assembly.room_name,
		},
		ShadeName: {
			label: "Shade Name",
			options: [],
			clearValue: () => {
				setAssemblyPartial({
					shade_name: ""
				});
			},
			setValue: (value) => {
				setAssemblyPartial({
					shade_name: value
				});
			},
			getSelectedOption: () => ({ label: "", value: "", title: "" }),
			varName: "shade_name",
			error: [],
			info: [],
			getValue: () => assembly.shade_name,
		},
		Floor: {
			label: "Floor",
			options: [],
			clearValue: () => {
				setAssemblyPartial({
					floor: 0
				});
			},
			setValue: (value) => {
				setAssemblyPartial({
					floor: value
				});
			},
			getSelectedOption: () => ({ label: "", value: 0, title: "" }),
			varName: "floor",
			error: [],
			info: [],
			getValue: () => assembly.floor,
		},
		SideChannels: {
			label: "Side Channels",
			options: sideChannelOptions,
			clearValue: () => {
				setAssemblyPartial({
					side_channels: "None"
				});
			},
			setValue: (value) => {
				setAssemblyPartial({
					side_channels: value
				});
			},
			getSelectedOption: () => (sideChannelOptions?.find((opt) => opt.value === assembly.side_channels) ?? '') as PortalShadeOptionItem<IndoorSideChannels>,
			varName: "side_channels",
			error: [],
			info: [],
			getValue: () => ((assembly.side_channels === "") ? "None" : assembly.side_channels as IndoorSideChannels),
			active: isSideChannelsAllowed,
			valuePopup: assembly?.side_channels === "Both" ? SideAndSill
				: assembly?.side_channels === "Sill" ? SillPlate
					: assembly?.side_channels === "Side" ? SideChannels : null

		},
		// SideChannelsColor: {
		//     label: "Side Channels Color",
		//     options: sideChannelColorOptions,
		//     clearValue: () => {
		//         setAssemblyPartial({
		//             side_channels_color: ""
		//         });
		//     },
		//     setValue: (value) => {
		//         setAssemblyPartial({
		//             side_channels_color: value
		//         });
		//     },
		//     getSelectedOption: () => sideChannelColorOptions.find(opt => opt.value === assembly.side_channels_color) as PortalShadeOptionItem<IndoorSideChannelsColor>,
		//     varName: "side_channels_color",
		//     error: [],
		//     info: [],
		//     getValue: () => assembly.side_channels_color as IndoorSideChannelsColor,
		//     active: (assembly.side_channels !== "None" && assembly.side_channels !== "") && !(isExternalOrOvalHembar || isRoundFabricCassette)
		// },
		DriveType: {
			label: "Shade Type",
			options: shadeTypeOptions,
			clearValue: () => {
				setAssemblyPartial({
					shades: assembly.shades.map((s) => ({
						...s,
						shade_type: ""
					}))
				});
			},
			setValue: (value) => {
				setAssemblyPartial({
					shades: assembly.shades.map((s) => ({
						...s,
						shade_type: value
					}))
				});
			},
			getSelectedOption: () => shadeTypeOptions?.find((opt) => opt.value === assembly.shades[0]?.shade_type) as PortalShadeOptionItem<IndoorDriveType>,
			varName: "drive_type",
			error: [],
			info: [],
			getValue: () => assembly.shades[0]?.shade_type as IndoorDriveType,
			valuePopup: isMotorized ? MotorizedShade : ManualShade,
			setValueAndClearMotorOptions: (value) => {
				setAssemblyPartial({
					shades: assembly.shades.map((s) => ({
						...s,
						shade_type: value,
						manual_chain_color: "",
						manual_chain: "",
						motor_type: "",
						lv_power_source: "",
						hembar_type: "",
						hembar_line: "",
					})),
					control_side: "",
					window_jamb_depth: 0,
					side_channels_color: "",
					fascia_split: ""
				});
			}
		} as IndoorDriveTypeOption,
		MotorType: {
			label: "Motor Type",
			options: motorTypeOptions,
			clearValue: () => {
				setAssemblyPartial({
					shades: assembly.shades.map((s) => ({
						...s,
						motor_type: ""
					}))
				});
			},
			setValue: (value) => {
				setAssemblyPartial({
					shades: assembly.shades.map((s) => ({
						...s,
						motor_type: value
					}))
				});
			},
			getSelectedOption: () => motorTypeOptions?.find((opt) => opt.value === assembly.shades[0]?.motor_type) as PortalShadeOptionItem<IndoorMotorType>,
			varName: "motor_type",
			error: [],
			info: [],
			getValue: () => assembly.shades[0]?.motor_type as IndoorMotorType,
			active: !isManual,
			valuePopup: isPoe ? PoE
				: isLVBattery ? Battery
					: isAc ? AC
						: DC
		},
		ManualType: {
			label: "Manual Type",
			options: manualTypeOptions,
			clearValue: () => {
				setAssemblyPartial({
					shades: assembly.shades.map((s) => ({
						...s,
						manual_chain: ""
					}))
				});
			},
			setValue: (value) => {
				setAssemblyPartial({
					shades: assembly.shades.map((s) => ({
						...s,
						manual_chain: value
					}))
				});
			},
			getSelectedOption: () => manualTypeOptions?.find((opt) => opt.value === assembly.shades[0]?.manual_chain) as PortalShadeOptionItem<IndoorManualChain>,
			varName: "manual_chain",
			error: [],
			info: [],
			getValue: () => assembly.shades[0]?.manual_chain as IndoorManualChain,
			active: !isMotorized,
			valuePopup: isSpring ? SpringPull : ManualShade
		},
		TotalWidth: {
			label: is_coupled ? "Total Width" : "Width",
			options: [],
			clearValue: () => {
				setAssemblyPartial({
					total_width: 0
				});

				if (!assembly.is_coupled) {
					setAssemblyPartial({
						shades: assembly.shades.map((s) => ({
							...s,
							width: 0
						}))
					});
				}
			},
			setValue: (value) => {
				if (assembly.is_coupled) {
					setAssemblyPartial({
						total_width: value
					});
				} else if (currentHeader?.allow_fabric_alignment ?? false) {
					setAssemblyPartial({
						total_width: value,
						shades: assembly.shades.map((s) => ({
							...s,
							width: value,
							custom_fabric_width: value
						}))
					});
				} else {
					setAssemblyPartial({
						total_width: value,
						shades: assembly.shades.map((s) => ({
							...s,
							width: value
						}))
					});
				}
			},
			getSelectedOption: () => ({ label: "", value: 0, title: "" }),
			varName: "width",
			error: [],
			info: widthInfo,
			getValue: () => (!assembly?.is_coupled ? assembly.shades[0]?.width ?? 0 : assembly.total_width),
			minValue: 6,
			maxValue: 240
		},
		Height: {
			label: "Height",
			options: [],
			clearValue: () => {
				setAssemblyPartial({
					shades: assembly.shades.map((s) => ({
						...s,
						height: 0
					}))
				});
			},
			setValue: (value) => {
				setAssemblyPartial({
					shades: assembly.shades.map((s) => ({
						...s,
						height: value
					}))
				});
			},
			getSelectedOption: () => ({ label: "", value: 0, title: "" }),
			varName: "height",
			error: [],
			info: [
				"For Optimal Tracking, a width-to-length ratio of less than 3:1 is recommended."
			],
			getValue: () => assembly.shades[0]?.height ?? 0,
			maxValue: 240,
			minValue: 0
		},
		WindowJambDepth: {
			label: "Window Jamb Depth",
			options: [],
			clearValue: () => {
				setAssemblyPartial({
					window_jamb_depth: 0
				});
			},
			setValue: (value) => {
				setAssemblyPartial({
					window_jamb_depth: value
				});
			},
			getSelectedOption: () => ({ label: "", value: 0, title: "" }),
			varName: "window_jamb_depth",
			error: [],
			info: [],
			getValue: () => assembly.window_jamb_depth,
			maxValue: 240,
			minValue: 0,
			active: false
		},
		// HembarType: {
		//     label: "Hembar Type",
		//     options: hembarTypeOptions,
		//     active: false,
		//     clearValue: () => {
		//         setAssemblyPartial({
		//             shades: assembly.shades.map(s => ({
		//                 ...s,
		//                 hembar_type: ""
		//             }))
		//         });
		//     },
		//     setValue: (value) => {
		//         setAssemblyPartial({
		//             shades: assembly.shades.map(s => ({
		//                 ...s,
		//                 hembar_type: value
		//             }))
		//         });
		//     },
		//     getSelectedOption: () => hembarTypeOptions.find(opt => opt.value === assembly.shades[0]?.hembar_type) as PortalShadeOptionItem<IndoorHembarType>,
		//     varName: "hembar_type",
		//     error: [],
		//     info: [],
		//     getValue: () => assembly.shades[0]?.hembar_type as IndoorHembarType,
		//     valuePopup: assembly.shades[0]?.hembar_type?.includes("magnet") ? HembarWithMagnet :
		//                 assembly.shades[0]?.hembar_type?.includes("external") ? ExternalHembar :
		//                 assembly.shades[0]?.hembar_type?.includes("oval") ? InternalRoundedHembar :
		//                 assembly.shades[0]?.hembar_type?.includes("internal_sealed") ? InternalFlatHembar : null
		// },
		HembarLine: {
			label: "Hembar Line",
			options: hembarLineOptions,
			clearValue: () => {
				setAssemblyPartial({
					shades: assembly.shades.map((s) => ({
						...s,
						hembar_line: ""
					}))
				});
			},
			setValue: (value) => {
				setAssemblyPartial({
					shades: assembly.shades.map((s) => ({
						...s,
						hembar_line: value
					}))
				});
			},
			getSelectedOption: () => hembarLineOptions?.find((opt) => opt.value === assembly.shades[0]?.hembar_line) as PortalShadeOptionItem<string>,
			varName: "hembar_line",
			error: [],
			info: [],
			getValue: () => assembly.shades[0]?.hembar_line as string
		},
		HembarStyle: {
			label: "Hembar Style",
			options: hembarStyleOptions,
			clearValue: () => {
				setAssemblyPartial({
					shades: assembly.shades.map((s) => ({
						...s,
						hembar_style: ""
					}))
				});
			},
			setValue: (value) => {
				setAssemblyPartial({
					shades: assembly.shades.map((s) => ({
						...s,
						hembar_style: value
					}))
				});
			},
			getSelectedOption: () => hembarStyleOptions?.find((opt) => opt.value === assembly.shades[0]?.hembar_style) as PortalShadeOptionItem<string>,
			varName: "hembar_style",
			error: [],
			info: [],
			getValue: () => assembly.shades[0]?.hembar_style as string
		},

		// HembarColor: {
		//     label: "Hembar Color",
		//     options: hembarColorOptions,
		//     clearValue: () => {
		//         setAssemblyPartial({
		//             shades: assembly.shades.map(s => ({
		//                 ...s,
		//                 hembar_color: ""
		//             }))
		//         });
		//     },
		//     setValue: (value) => {
		//         setAssemblyPartial({
		//             shades: assembly.shades.map(s => ({
		//                 ...s,
		//                 hembar_color: value
		//             }))
		//         });
		//     },
		//     getSelectedOption: () => hembarColorOptions.find(opt => opt.value === assembly.shades[0]?.hembar_color) as PortalShadeOptionItem<IndoorHembarColor>,
		//     varName: "hembar_color",
		//     error: [],
		//     info: [],
		//     getValue: () => assembly.shades[0]?.hembar_color as IndoorHembarColor,
		//     active: false
		// },

		LVPowerSupply: {
			label: "LV Power Supply",
			options: lvPowerSupplyOptions,
			clearValue: () => {
				setAssemblyPartial({
					shades: assembly.shades.map((s) => ({
						...s,
						lv_power_source: ""
					}))
				});
			},
			setValue: (value) => {
				setAssemblyPartial({
					shades: assembly.shades.map((s) => ({
						...s,
						lv_power_source: value
					}))
				});
			},
			getSelectedOption: () => lvPowerSupplyOptions?.find((opt) => opt.value === assembly.shades[0]?.lv_power_source) as PortalShadeOptionItem<IndoorLvPowerSource>,
			varName: "lv_power_source",
			error: [],
			info: [],
			getValue: () => assembly.shades[0]?.lv_power_source as IndoorLvPowerSource,
			active: isLVBattery && !isManual
		},
		// ManualChainColor: {
		//     label: "Manual Chain Color",
		//     options: manualChainColorOptions,
		//     clearValue: () => {
		//         setAssemblyPartial({
		//             shades: assembly.shades.map(s => ({
		//                 ...s,
		//                 manual_chain_color: ""
		//             }))
		//         });
		//     },
		//     setValue: (value) => {
		//         setAssemblyPartial({
		//             shades: assembly.shades.map(s => ({
		//                 ...s,
		//                 manual_chain_color: value
		//             }))
		//         });
		//     },
		//     getSelectedOption: () => manualChainColorOptions.find(opt => opt.value === assembly.shades[0]?.manual_chain_color) as PortalShadeOptionItem<IndoorManualChainColor>,
		//     varName: "manual_chain_color",
		//     error: [],
		//     info: [],
		//     getValue: () => assembly.shades[0]?.manual_chain_color as IndoorManualChainColor,
		//     active: !isMotorized && !isSpring
		// },
		ControlSide: {
			label: is_dual ? "Inside Control Side" : "Control Side",
			options: controlSideOptions,
			clearValue: () => {
				setAssemblyPartial({
					control_side: ""
				});
			},
			setValue: (value) => {
				setAssemblyPartial({
					control_side: value
				});
			},
			getSelectedOption: () => controlSideOptions?.find((opt) => opt.value === assembly.control_side) as PortalShadeOptionItem<IndoorControlSide>,
			varName: "control_side",
			error: [],
			info: [],
			getValue: () => assembly.control_side as IndoorControlSide,
			valuePopup: assembly.control_side === "Left" ? LeftControl : RightControl

		},
		HeaderType: {
			label: "Header Type",
			options: headerTypeOptions,
			clearValue: () => {
				setAssemblyPartial({
					header_line: ""
				});
			},
			setValue: (value) => {
				const selectedHeaderType = headers?.find((opt) => opt.name === value);

				let endCaps = assembly?.end_caps ?? "";

				if (selectedHeaderType?.end_caps.length == 1) {
					endCaps = selectedHeaderType?.end_caps[0]?.value ?? "";
				}


				setAssemblyPartial({
					header_line: value,
					end_caps: endCaps
				});

			},
			getSelectedOption: () => headerTypeOptions?.find((opt) => opt.value === assembly.header_line) as PortalShadeOptionItem<IndoorHeaderType>,
			varName: "header_line",
			error: [],
			info: [],
			getValue: () => assembly.header_line as IndoorHeaderType,
			valuePopup: assembly.header_line?.includes("RC3080") && !is_dual ? Fascia_3_Single
				: assembly.header_line?.includes("RC4039") && is_dual ? Fascia_5_Dual
					: assembly.header_line?.includes("RC4039") && !is_dual ? Fascia_5_Single
						: assembly.header_line?.includes("RS-SK") ? Fascia_7_Dual
							: assembly.header_line?.includes("OR_1234") && !is_dual ? Open_Roll_Medium_Single
								: assembly.header_line?.includes("OR_1234") && is_dual ? Open_Roll_Medium_Dual
									: assembly.header_line?.includes("OR_0002") ? Open_Roll_Small_Single
										: assembly.header_line?.includes("OR_5678") && !is_dual ? Open_Roll_Large_Single
											: assembly.header_line?.includes("OR_5678") && is_dual ? Open_Roll_Large_Dual
												: assembly.header_line?.includes("RC3051") ? Cassette_3_FW_Single : null

		},
		BalancedLightGaps: {
			label: "Balanced Light Gaps",
			options: balancedLightGapsOptions,
			clearValue: () => {
				setAssemblyPartial({
					balanced_lightgaps: "balanced"
				});
			},
			setValue: (value) => {
				setAssemblyPartial({
					balanced_lightgaps: value
				});
			},
			getSelectedOption: () => balancedLightGapsOptions?.find((opt) => opt.value === assembly.balanced_lightgaps) as PortalShadeOptionItem<IndoorBalancedLightgaps>,
			varName: "balanced_light_gaps",
			error: [],
			info: [],
			getValue: () => assembly.balanced_lightgaps as IndoorBalancedLightgaps,
			active: quote.overrideLightGap ?? false
		},
		// HardwareColor: {
		//     label: "Hardware Color",
		//     options: hardwareColorOptions,
		//     clearValue: () => {
		//         setAssemblyPartial({
		//             hardware_color: ""
		//         });
		//     },
		//     setValue: (value) => {
		//         setAssemblyPartial({
		//             hardware_color: value
		//         });
		//     },
		//     getSelectedOption: () => hardwareColorOptions.find(opt => opt.value === assembly.hardware_color) as PortalShadeOptionItem<IndoorHardwareColor>,
		//     varName: "hardware_color",
		//     error: [],
		//     info: [],
		//     getValue: () => assembly.hardware_color as IndoorHardwareColor,
		//     active: !isFascia(assembly.header_line) && !isCassette(assembly.header_line)

		// },
		EndCaps: {
			label: "End Caps",
			options: endCapOptions,
			clearValue: () => {
				setAssemblyPartial({
					end_caps: ""
				});
			},
			setValue: (value) => {
				// const newEndCapOption = endCaps?.find((opt) => opt.value === value);
				// const newEndCapPalette = newEndCapOption?.extra_palettes ?? [];


				// if (newEndCapPalette.length > 0) {
				// 	const newColors = newEndCapPalette.reduce((acc, curr) => {
				// 		const colorPalette = colorPaletteOptions.find((colPal) => colPal.value === curr)?.default_colors ?? {};
				// 		const allColorKeys = [...getPalettes(assembly).map(ec => ec.name), ...Object.keys(colorPalette)];
				// 		acc = allColorKeys.reduce((accy, curr) => {
				// 			if (!colorSelectionsFull.some(cs => cs.name == curr)) {
				// 				return accy;
				// 			}
				// 			accy[curr] = colorPalette[curr] ?? "";
				// 			return accy;
				// 		}, acc);
				// 		return acc;
				// 	}, {} as Record<string, string>);

				// 	const currentColors = assembly.colors ?? {};
				// 	const combinedColors = { ...currentColors, ...newColors };

				// 	setAssemblyPartial({
				// 		end_caps: value,
				// 		colors: combinedColors
				// 	});

				// } else {
				setAssemblyPartial({
					end_caps: value
				});
				// }
			},
			getSelectedOption: () => selectedEndCapOption,
			varName: "end_caps",
			error: [],
			info: [],
			getValue: () => assembly.end_caps as IndoorEndCaps,
			active: endCapOptions?.length > 1
		},
		CustomFabricWidth: {
			label: "Custom Fabric Width",
			options: [],
			clearValue: () => {
				setAssemblyPartial({
					shades: assembly.shades.map((s) => ({
						...s,
						custom_fabric_width: 0,
					}))
				});
			},
			setValue: (value) => {
				if (currentHeader?.allow_fabric_alignment) {
					setAssemblyPartial({
						shades: assembly.shades.map((s) => ({
							...s,
							custom_fabric_width: value,
						}))
					});
				} else {
					setAssemblyPartial({
						shades: assembly.shades.map((s) => ({
							...s,
							custom_fabric_width: value,
							width: value
						}))
					});
				}
			},
			getSelectedOption: () => ({ label: "", value: 0, title: "" }),
			varName: "custom_fabric_width",
			error: [],
			info: [],
			getValue: () => assembly.shades[0]?.custom_fabric_width ?? 0.0,
			active: currentHeader?.allow_fabric_alignment ?? false
		},
		FabricAlignment: {
			label: "Fabric Alignment",
			options: fabricAlignmentOptions,
			clearValue: () => {
				setAssemblyPartial({
					shades: assembly.shades.map((s) => ({
						...s,
						fabric_alignment: ""
					}))
				});
			},
			setValue: (value) => {
				setAssemblyPartial({
					shades: assembly.shades.map((s) => ({
						...s,
						fabric_alignment: value
					})),
				});
			},
			getSelectedOption: () => fabricAlignmentOptions?.find((opt) => opt.value === (assembly.shades[0]?.fabric_alignment ?? "Center")) as PortalShadeOptionItem<string>,
			varName: "fabric_alignment",
			error: [],
			info: [],
			getValue: () => assembly.shades[0]?.fabric_alignment ?? "Center",
			active: currentHeader?.allow_fabric_alignment ?? false,
			valuePopup: fabricAlignmentOptions?.find((opt) => opt.value === (assembly.shades[0]?.fabric_alignment))
				&& assembly.shades[0]?.fabric_alignment && tubeImages[capitalize(assembly.shades[0]?.fabric_alignment)]
		},
		ColorPalettes: {
			label: "Color",
			options: colorPaletteOptionAll,
			clearValue: () => {
				setAssemblyPartial({
					hardware_color: "Custom Palette",
					palette_color: "Custom Palette",
					colors: colorPaletteOptions.find((colPal) => colPal.value === "Custom Palette")?.default_colors ?? {}
				});
			},
			setValue: (value) => {
				const selectedPalette = colorPaletteOptions.find((colPal) => colPal.value === value);

				const defAcc = selectedPalette?.default_colors ?? {};

				const selectedDefaultColors = getPalettes(assembly).reduce((acc, curr) => {

					let newColorValue = defAcc[curr.name] ?? "";

					if (newColorValue === "Missing Default Color") {
						newColorValue = full_palette_list?.find(fp => fp.name == curr.name)?.choices[0] ?? "";
					}

					acc[curr.name] = newColorValue;
					return acc;
				}, {} as Record<string, string>) ?? {};

				setAssemblyPartial({
					hardware_color: value,
					palette_color: value,
					colors: selectedDefaultColors
				});
			},
			getSelectedOption: () => (hasPaletteBeenAltered ?
				colorPaletteOptionAll?.find((colPal) => colPal.value === "Custom Palette") :
				(colorPaletteOptionAll?.find((colPal) => colPal.value === assembly.palette_color))
				?? colorPaletteOptionAll?.find((colPal) => colPal.value === "Custom Palette")) as PortalShadeOptionItem<string>,
			varName: "color",
			error: [],
			info: [],
			getValue: () => (hasPaletteBeenAltered ? "Custom Palette" : assembly.palette_color as string),
		},
		Colors: colorSelections,
	};

	const dictFull = { ...baseDict, ...allDict };

	Object.values(dictFull).forEach((dict) => {
		if (Array.isArray(dict)) {
			dict.forEach((opt) => {
				opt.active = opt.active ?? true;
				opt.options?.forEach((opt2) => {
					opt2.isActive = opt2.isActive ?? true;
				});
			});
		} else {
			dict.active = dict.active ?? true;
			dict.options?.forEach((opt) => {
				opt.isActive = opt.isActive ?? true;
			});
		}
	});

	return dictFull;
};

export {
	useNewIndoorShadeOptions,
	useCurrentIndoorShadeOptions
};
