import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { useTerritories, useTerritoriesLoadStatus } from '../Store/entities/hooks';

import TerritorySelect from '../Parts/TerritorySelect';
import Button from '../Parts/Button';

import type { AddTerritoryProps } from './types';

const AddTerritory = ({
	open,
	onClose,
	handleAdd,
	selectedTerritoryIds: existingSelectedTerritoryIds = [],
}: AddTerritoryProps) => {
	const [selectedTerritoryIds, setSelectedTerritoryIds] = useState<number[]>([]);

	const territories = useTerritories();
	const territoriesLoadStatus = useTerritoriesLoadStatus();
	const territoriesLoading = territoriesLoadStatus <= 3;

	const readyToSubmit = selectedTerritoryIds.length > 0 && !territoriesLoading;
	const filteredTerritories = territories.filter(
		(territory) => !existingSelectedTerritoryIds.includes(territory.id)
	);

	const handleClose = () => {
		onClose();
	};

	const handleSubmit = () => {
		try {
			handleAdd(selectedTerritoryIds);
			handleClose();
			toast.success('Territory Added');
		} catch (err) {
			toast.error('Error Adding Territory');
			throw err;
		}
	};

	return (
		<Popup
			open={open}
			closeOnDocumentClick={false}
			closeOnEscape={false}
			onClose={handleClose}
			className="mobile-modal"
		>
			<Row className="mb-2">
				<Col>
					<h4>Add Territory</h4>
				</Col>
			</Row>
			<Row className="mb-2">
				<Col>
					<input className="d-none" />
					<TerritorySelect
						selectedTerritoryIds={selectedTerritoryIds}
						optionsOverride={filteredTerritories}
						multiple
						handleMultiChange={(newTerritoryIds: number[]) => {
							setSelectedTerritoryIds(newTerritoryIds);
						}}
					/>
				</Col>
			</Row>
			<Row className="justify-content-end mt-4">
				<Col className="text-start">
					<Button color="gray" onClick={handleClose}>
						Close
					</Button>
				</Col>
				<Col className="text-end">
					<Button color="green" disabled={!readyToSubmit} onClick={handleSubmit}>
						Add
					</Button>
				</Col>
			</Row>
		</Popup>
	);
};

export default AddTerritory;
