import { createAsyncThunk } from "@reduxjs/toolkit";
import { extraEntityReducersFunction, loadStatus } from "../entitiesType";
import apiCalls from "../../../PowerShadesAPIFunctions";
import { RMAItems } from "../../../Quote/OrderModal/PaymentMethods/RMAPayment/types";
import { toCamelCase } from "../../../psUtil";

export const loadRmasFull = createAsyncThunk<
	RMAItems[],
	number,
	{
		rejectValue: boolean;
	}
>('entities/GetRmas', async (quoteId, { rejectWithValue }) => {
	try {
		const resp = await apiCalls.getRMAsByQuoteId(quoteId);
		return resp.data ?? [];
	} catch {
		return rejectWithValue(false);
	}
});

export const addRma = createAsyncThunk<
	RMAItems,
	RMAItems,
	{
		rejectValue: boolean;
	}
>('entities/AddRma', async (newRma, { rejectWithValue }) => {
	try {
		const resp = await apiCalls.addRMA(newRma);
		return toCamelCase(resp.data);
	} catch {
		return rejectWithValue(false);
	}
});

export const editRma = createAsyncThunk<
	RMAItems,
	Partial<RMAItems>,
	{
		rejectValue: boolean;
	}
>('entities/EditRma', async (updatedRma, { rejectWithValue }) => {
	try {
		const resp = await apiCalls.editRMA(updatedRma);
		return resp.data.rma;
	} catch {
		return rejectWithValue(false);
	}
});

export const deleteRma = createAsyncThunk<
	number,
	number,
	{
		rejectValue: boolean;
	}
>('entities/DeleteRma', async (rmaId, { rejectWithValue }) => {
	try {
		await apiCalls.deleteRMA(rmaId);
		return rmaId;
	} catch {
		return rejectWithValue(false);
	}
});

export const rmaBuilder: extraEntityReducersFunction = (builder) => {
	builder.addCase(loadRmasFull.pending, (state, action) => {
		state.rmas.loadStatus = loadStatus.loading;
		state.rmas.currentQuoteId = action.meta.arg;
	});
	
	builder.addCase(loadRmasFull.fulfilled, (state, action) => {
		state.rmas.list = action.payload;
		state.rmas.loadStatus = loadStatus.fullyLoaded;
		state.rmas.currentQuoteId = action.meta.arg;
	});
	
	builder.addCase(addRma.fulfilled, (state, action) => {
		state.rmas.list.push(action.payload);
		state.rmas.loadStatus = loadStatus.fullyLoaded;
		return state;
	});

	builder.addCase(addRma.pending, (state) => {
		state.rmas.loadStatus = loadStatus.loading;
		return state;
	});

	builder.addCase(editRma.fulfilled, (state, action) => {
		const index = state.rmas.list.findIndex((rma) => rma.id === action.payload.id);
		if (index !== -1) {
			state.rmas.list[index] = action.payload;
		}
		state.rmas.loadStatus = loadStatus.fullyLoaded;
		return state;
	});

	builder.addCase(editRma.pending, (state) => {
		state.rmas.loadStatus = loadStatus.loading;
		return state;
	});

	builder.addCase(deleteRma.fulfilled, (state, action) => {
		state.rmas.list = state.rmas.list.filter((rma) => rma.id !== action.payload);
		state.rmas.loadStatus = loadStatus.fullyLoaded;
		return state;
	});

	builder.addCase(deleteRma.pending, (state) => {
		state.rmas.loadStatus = loadStatus.loading;
		return state;
	});
};
