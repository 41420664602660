import React, { useState, useEffect, useMemo } from "react";
import { useTable, useFilters } from "react-table";
import { Container, Row, Col, Table, Button, Modal } from "react-bootstrap";
import Switch from "react-switch";
import { useUserData } from "../hooks";
import connection from "../Store/signalR/connection";


// Define a default column filter for text filtering
function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  const [inputValue, setInputValue] = useState(filterValue || "");

  const clearInput = () => {
    setInputValue("");
    setFilter(undefined);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setFilter(value || undefined);
  };

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <input
        value={inputValue}
        onChange={handleChange}
        placeholder={`Search...`}
        style={{
          width: "98%",
          height: "25px",
          padding: "2px 5px",
          fontSize: "14px",
          borderRadius: "4px",
          border: "1px solid #ccc",
        }}
      />
      {inputValue && (
        <button
          onClick={clearInput}
          style={{
            position: "absolute",
            right: "5px",
            top: "50%",
            transform: "translateY(-50%)",
            background: "none",
            border: "none",
            cursor: "pointer",
            fontSize: "14px",
            color: "#666",
            appearance: "button",
          }}
          aria-label="Clear search"
        >
          <span
            style={{
              display: "inline-block",
              width: "14px",
              height: "14px",
              borderRadius: "50%",
              backgroundColor: "#f8d7da",
              textAlign: "center",
              lineHeight: "14px",
              fontWeight: "bold",
              color: "#721c24"
            }}
          >
            ×
          </span>
        </button>
      )}
    </div>
  );
}

const FabricAllowedOutdoor = ({ newQuote, quote, onClose }) => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [loadingRows, setLoadingRows] = useState({});
  const userData = useUserData();
  const quoteId = quote;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.getFabricsPsOutdoorDiscontinuedAllowedByQuoteId(quoteId);
        const mappedData = response.data.map((fabric) => ({
          id: fabric.id,
          name: fabric.name,
          isAllowed: fabric.isAllowed,
        }));
        setData(mappedData);
      } catch (error) {
        console.error("Error fetching fabrics:", error);
      }
    };
    fetchData();
  }, [quoteId]);

  const toggleSelection = async (index, fabric) => {
    const { id, isAllowed } = fabric;
    const userId = userData.id;

    // Block
    setLoadingRows((prev) => ({ ...prev, [id]: true }));

    try {
      if (!isAllowed) {
        await api.postAddFabricAllowed({ quoteId, fabricId: id, userId });
      } else {
        await api.putDisableFabricAllowed({ quoteId, fabricId: id, userId });
      }

      setData((prevData) =>
        prevData.map((item, i) =>
          i === index ? { ...item, isAllowed: !isAllowed } : item
        )
      );
    } catch (error) {
      console.error("Error toggling fabric:", error);
    } finally {

      // Unblock 
      setLoadingRows((prev) => ({ ...prev, [id]: false }));
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Fabric Name",
        accessor: "name",
        Filter: DefaultColumnFilter,
        width: "85%",
      },
      {
        Header: (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <span style={{ textAlign: "center", verticalAlign: "middle" }}>Enable</span>
          </div>
        ),
        accessor: "isAllowed",
        Cell: ({ row }) => (
          <Switch
            onChange={() => toggleSelection(row.index, row.original)}
            checked={row.original.isAllowed}
            offColor="#ccc"
            onColor="#90c444"
            checkedIcon={false}
            uncheckedIcon={false}
            height={20}
            width={40}
            disabled={loadingRows[row.original.id]}
          />
        ),
        disableFilters: true,
        width: "15%",
      },
    ],
    [loadingRows] // Render
  );

  const handleClose = async () => {
    try {
      if (quoteId) {
        await connection.invoke("RequestPageReloadForQuote", quoteId);
        console.log("Event RequestPageReloadForQuote sent with quoteId:", quoteId);
      } else {
        console.warn("No quoteId available to send.");
      }
    } catch (error) {
      console.error("Error invoking RequestPageReloadForQuote:", error);
    }

    setTimeout(() => {
      setShowModal(false);
      window.location.reload();
    }, 500);
  };

  const defaultColumn = { Filter: DefaultColumnFilter };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, defaultColumn }, useFilters);

  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="lg" centered keyboard={false}>
        <div tabIndex="0">
          <Modal.Header closeButton>
            <Modal.Title>Enable Usage of Discontinued PsOutdoor Fabrics</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col>
                  <div
                    style={{
                      padding: "5px 10px", 
                      fontSize: "17px",
                      textAlign: "left",
                      backgroundColor: "#f8f9fa",
                      border: "1px solid #dee2e6",
                      borderRadius: "4px",
                      marginBottom: "10px", 
                    }}
                  >
                    Toggle the availability of discontinued PsOutdoor fabrics for this quote
                  </div>
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <Table
                      {...getTableProps()}
                      striped
                      bordered
                      hover
                      style={{ width: "100%", tableLayout: "fixed" }}
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                {...column.getHeaderProps()}
                                style={{
                                  width: column.width,
                                  textAlign: "center",
                                  backgroundColor: "#90c444",
                                  color: "white",
                                  fontWeight: "bold",
                                  padding: "5px 0",
                                }}
                              >
                                {column.render("Header")}
                                <div>{column.canFilter ? column.render("Filter") : null}</div>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => (
                                <td
                                  {...cell.getCellProps()}
                                  style={{ textAlign: "center" }}
                                >
                                  {cell.render("Cell")}
                                </td>
                              ))}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Done
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default FabricAllowedOutdoor;
