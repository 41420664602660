import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { startCase } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { useFilterSettings} from '../../Store/ui/hooks';

import type { StatusCardProps } from './types';

import styles from './StatusCards.module.scss';
import { useSetFilterSettings } from '../../Store/entities/quotes/hooks';
import { setVisibleQuoteIdsLoadingStatus } from '../../Store/ui';
import { UsePortalDispatch } from '../../Store';
import { loadStatus } from '../../Store/entities/entitiesType';

const StatusCard = ({
	title, loading, count, active, displayCount = true
}: StatusCardProps) => {
	const formattedTitle = startCase(title);
	const filterSettings = useFilterSettings();
	const setFilterSettings = useSetFilterSettings();
	const dispatch = UsePortalDispatch();

	const updateFilterSettings = () => {
		dispatch(setVisibleQuoteIdsLoadingStatus(loadStatus.loading));
		setFilterSettings({
			...filterSettings, filter_names: [title], page_number: 1
		});
	};

	return (
		<Col>
			<Card body onClick={() => updateFilterSettings()} className={`${styles.statusCard} ${active && styles.active} status-card ${active && "active"}`}>
				<Row>
					<Col>
						<h5>{loading ? (<FontAwesomeIcon icon={faSpinner} spin />) : displayCount && count}</h5>
					</Col>
				</Row>
				<Row>
					<Col>
						<h6>{formattedTitle}</h6>
					</Col>
				</Row>
			</Card>
		</Col>
	);
};

export default StatusCard;
