import React from 'react';

class Money extends React.Component {
	constructor(props) {
		super(props);

		this.props = props;

		const {
			amount, symbol, multiplier, update, currencyMultiplier
		} = props;

		this.state = {
			amount,
			symbol,
			multiplier,
			currencyMultiplier,
			functions: props.update()
		};

		this.Update = this.Update.bind(this);

		this.state.functions.Update = this.Update;
	}

	Update(input) {
		this.setState(input);
	}

	display() {
		let amount = parseFloat(this.state.amount * this.state.multiplier * this.state.currencyMultiplier);

		let decimalCount = 2; const decimal = "."; const
			thousands = ",";

		decimalCount = Math.abs(decimalCount);
		decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

		const negativeSign = amount < 0 ? "-" : "";

		const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
		const j = (i.length > 3) ? i.length % 3 : 0;

		return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
	}

	get value() {
		return parseFloat(this.state.amount * this.state.multiplier);
	}

	render() {
		const finalDisplayAmount = this.display((this.state.amount || this.props.children), this.props.decimalCount || 2);
		return (
			<>
				{this.state.symbol}
				{finalDisplayAmount}
			</>
		);
	}
}

export default Money;
