import React from 'react';
import Popup from 'reactjs-popup';
import { Row, Col } from 'react-bootstrap';

import Button from '../../Parts/Button';
import { useQuoteById, useQuoteTypeNameByQuoteId } from '../../Store/entities/quotes/hooks';
import { useAssembliesByQuoteId } from '../../Store/entities/assemblies/hooks';
import { useQuoteAccessories } from '../../Store/entities/accessories/hooks';
import { UsePortalDispatch } from '../../Store';
import { setQuoteToEditable, wentToQuote } from '../../Store/entities/quotes';
import { repriceAssemblies, setAssembliesUnlocked } from '../../Store/entities/assemblies';
type UnlockQuoteModalProps = {
	quoteId: number;
	sequenceId: number;
	clearSequenceId: () => void;
};

const UnlockQuoteModal = (props: UnlockQuoteModalProps) => {
	const {
		quoteId,
		sequenceId,
		clearSequenceId
	} = props;

	const quote = useQuoteById(quoteId) ?? { editable: false };

	const dispatch = UsePortalDispatch();

	const {
		editable
	} = quote;

	const [fakeOpen, setOpen] = React.useState(false);

	
	const fakeSeqId = sequenceId as any;
	let action: (null |(() => {})) = null;
	if(fakeSeqId.action)
	{
		action = fakeSeqId.action as (() => {});
	}
	const open = ((sequenceId > -1 || fakeSeqId.action) || fakeOpen) && !editable;

	const shadeAssemblies = useAssembliesByQuoteId(quoteId);
	const quoteAccessories = useQuoteAccessories(quoteId);

	const shadesThatNeedRepricing = shadeAssemblies.filter((s) => {
		const msrpIs0OrOld = s.msrp === 0 || s.msrp === null || s.msrp === undefined;
		const timestamp = s.last_time_priced;
		const lastTimePriced = new Date(timestamp * 1000);

		const currentDate = new Date();

		const dif = currentDate.getTime() - lastTimePriced.getTime();

		const days = dif / (1000 * 60 * 60 * 24);

		return days > 90 || msrpIs0OrOld
	});

	const quoteAccessoriesNeedingUnlocked =
		quoteAccessories.filter((qa) => {
			const msrpIs0OrOld = qa.msrp === 0 || qa.msrp === null || qa.msrp === undefined;
			const timestamp = qa?.last_time_priced;

			const lastTimePriced = new Date(timestamp);

			if (Number.isNaN(lastTimePriced)) return true;

			const currentDate = new Date();

			const dif = currentDate.getTime() - lastTimePriced.getTime();

			const days = dif / (1000 * 60 * 60 * 24);

			return days > 90 || msrpIs0OrOld;
		});

	const repriceCount =
		shadesThatNeedRepricing.length + quoteAccessoriesNeedingUnlocked.length;

	const quoteType = useQuoteTypeNameByQuoteId(quoteId);// TODO, get From Store Hook

	const onEditClick = () => {
		dispatch(wentToQuote(quoteId));
		dispatch(setQuoteToEditable(quoteId));
		if (sequenceId > -1) {
			dispatch(setAssembliesUnlocked({
				quoteId,
				sequenceIds: [(sequenceId)],
				unlocked: true
			}));
		}

		if (repriceCount > 0) {
			dispatch(repriceAssemblies({
				quoteId: quoteId,
				sequenceIds: shadesThatNeedRepricing.map((s) => s.sequence_id),
				acc_line_numbers: quoteAccessoriesNeedingUnlocked.map((s) => s.line_number)
			}))
		}

		if(action)
		{
			action(); 
			
		}
	}

	const EditQuoteHeader = (
		<Row>
			<Col>
				<h4 className={`text-${quoteType}`}>Edit Quote</h4>
			</Col>
		</Row>
	)

	const warningMessage = repriceCount == 0 ?
		(
			<Row>
				<Col>
					Would you like to unlock and edit this quote? Pricing
					will be recalculated for each item that you edit.
				</Col>
			</Row>
		)
		:
		(
			<Row>
				<Col>
					<Row>
						<Col>
							{"All prices are valid for 90 days without risk of price changes."}
						</Col>
					</Row >
					<Row>
						<Col>
							{`${repriceCount == 1
								? 'There is currently 1 shade/accessory '
								: `There are currently ${repriceCount} shades/accessories `
								}that need${repriceCount == 0 ? 's' : ''
								} repricing because they are older than 90 days.`}
						</Col>
					</Row>
					<Row>
						<Col>
							Would you like to unlock this quote and reprice
							these shades?
						</Col>
					</Row>
				</Col >
			</Row >
		);

	const editQuoteFooter = (
		<Row className="pt-5">

			<Col xs={6}>
				<Button
					color={quoteType}
					onClick={onEditClick}
				>
					Unlock and Edit
				</Button>
			</Col>
			<Col xs={6} className="text-end">
				<Button
					color="gray"
					onClick={() => {
						setOpen(false);
						clearSequenceId();
					}}
				>
					View Only
				</Button>
			</Col>
		</Row>

	);

	return (
		<>
			<Popup
				open={open}
				className="edit mobile-modal"
				modal
				closeOnDocumentClick={false}
				onClose={() => { }}
			>

				<Row>
					<Col>
						{EditQuoteHeader}
						{warningMessage}
						{editQuoteFooter}
					</Col>
				</Row>
			</Popup>
			<Button
				onClick={() => setOpen(true)}
				fluid
				fullWidth
				color={quoteType}
			>
				Edit Quote
			</Button>
		</>

	);

};

export default UnlockQuoteModal;