import powershadesLogo from './assets/img/logos/primary/Powershades_Logo_400.png';
import chromeLogo from './assets/img/browser_logos/chrome.png';
import firefoxLogo from './assets/img/browser_logos/firefox.png';
import edgeLogo from './assets/img/browser_logos/edge.png';

export default `
	<div id='unsupported-browser'>
		<img src='${powershadesLogo}'>

		<h1>It looks like you're using an unsupported browser.</h1>

		<br/><br/><br/>

		<span>Consider upgrading to one of the following browsers<br/> for the best experience:</span>
				
		<div>

			<div style='display: flex;flex-direction: column;'>
				<a href='https://www.google.com/chrome/'>
					<img src='${chromeLogo}'/>
				</a>
				<span style='font-size: 13px;'>
					Recommended
				</span>
			</div>

			<div>
				<a href='https://www.mozilla.org/en-US/firefox/'>
					<img src='${firefoxLogo}'/>
				</a>
			</div>

			<div>
				<a href='https://www.microsoft.com/en-us/windows/microsoft-edge'>
					<img src='${edgeLogo}'/>
				</a>
			</div>

		</div>
	</div>
`;
