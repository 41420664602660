import React, { useState, FC } from 'react';
import { Row, Container, Col, Form } from 'react-bootstrap';
import Popup from 'reactjs-popup';
import Button from '../../Parts/Button';
import apiCalls from '../../PowerShadesAPIFunctions';

type CorradiOnlyProps = {
	QuoteId: number;
}

type ShipCorradiOrder = {
	trackingNumber: string,
	shipDate: Date,
	shipMethod: string
}

const CorradiOnly: FC<CorradiOnlyProps> = ({ QuoteId }) => {
	const corradiMFGNum = 5;

	const [isOpen, setIsOpen] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [processing, setProcessing] = useState(false);

	const [shipCorradiOrder, setShipCorradiOrder_r] = useState<ShipCorradiOrder>({
		trackingNumber: "",
		shipDate: new Date(),
		shipMethod: ""
	});

	const setShipCorradiOrder = (input: ShipCorradiOrder) => {
		console.log("shipCorradiOrder");
		setShipCorradiOrder_r(input);
	}

	const submitCorradiOnlyShipment = () => {
		setProcessing(true);
		apiCalls.assignManufacturerToOrder(corradiMFGNum, QuoteId).then((res) => {
			console.log(res);
			apiCalls.shipOrder(QuoteId, shipCorradiOrder.shipMethod, shipCorradiOrder.trackingNumber)
				.then((resp) => {
					console.log(resp);
					setIsSuccess(true);
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				}).catch((err) => {
					console.log(err);
				});
		}).catch((err) => {
			console.log(err);
		});
	};

	// Copied from stack overflow
	const yyyymmdd = (date) => {
		const mm = date.getMonth() + 1; // getMonth() is zero-based
		const dd = date.getDate();

		return [date.getFullYear(),
			(mm > 9 ? '' : '0') + mm,
			(dd > 9 ? '' : '0') + dd
		].join('-');
	};


	const dateStr = yyyymmdd(shipCorradiOrder.shipDate);

	return (
		<>
			<Button onClick={() => setIsOpen(true)}>
				Corradi Only
			</Button>
			<Popup
				open={isOpen}
				onClose={() => setIsOpen(false)}
				contentStyle={{ width: "50%" }}
			>
				{isSuccess ? <h1>Success!</h1>
					: (
						<Container className="local-bootstrap" style={{ marginTop: "2em", minWidth: "50%", padding: "1em" }}>
							<Row>
								<h1>Ship Corradi Only Order</h1>
							</Row>
							<Row style={{ display: "flex", marginBottom: "0.5em" }}>
								<Col style={{ width: "45%" }}>
									<Form.Control
										onChange={(e) => {
											if(e.target.value === '' || e.target.value === null) {
											} else {

												const shipDateInit = (new Date(`${e.target.value}`));

												const shipDate = new Date(shipDateInit.getTime() + shipDateInit.getTimezoneOffset() * 60000);

												shipDate.setHours(0, 0, 0, 0);
												setShipCorradiOrder({
													...shipCorradiOrder,
													shipDate: shipDate
													
												});
											}
											
										}}
										value={dateStr}
										placeholder="Date Shipped"
										type="date"
									/>
								</Col>
								<Col style={{ width: "45%" }}>
									<Row>
										<Form.Control
											onChange={(e) => {
												setShipCorradiOrder({
													...shipCorradiOrder,
													shipMethod: e.target.value
												});
											}}
											value={shipCorradiOrder?.shipMethod ?? ''}
											placeholder="Shipping Method"
											type="input"
										/>
									</Row>
									<Row>
										<Form.Control
											onChange={(e) => {
												setShipCorradiOrder({
													...shipCorradiOrder,
													trackingNumber: e.target.value
												});
											}}
											value={shipCorradiOrder?.trackingNumber ?? ''}
											placeholder="Tracking #"
											type="input"
										/>
									</Row>
								</Col>
							</Row>
							<Row>
								<Button disabled={processing} style={{ minWidth: '90%' }} onClick={() => submitCorradiOnlyShipment()}>
									{processing ? `Processing` : `Submit`}
								</Button>

							</Row>

						</Container>
					)
				}


			</Popup>
		</>
	);
};

export default CorradiOnly;
