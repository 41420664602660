import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { Col, Row } from "react-bootstrap";
import apiCalls from "../PowerShadesAPIFunctions";
import Button from "../Parts/Button";
import GetExtensionFromMimetype from "./MimeFiles";
import { isFailedApiCall } from "../psUtil";
import type { DisplayEntityPdfProps } from "./types";

const DisplayEntityPdf = (props: DisplayEntityPdfProps) => {
	const [contentType, setContentType] = useState("");
	const expectedFileType = GetExtensionFromMimetype(contentType);
	const [pdfUrl, setPdfUrl] = useState<string | null>(null);
	const [showing, setShowing] = useState<boolean>(false);
	const [pdfVersion, setPdfVersion] = useState<number | null>(null);
	
	const { entityType, entityId, certType, fileName = `tax_certificate.${expectedFileType}`, btnClass = "", onChange } = props;

	const getPdfVersion = (base64: string): number | null => {
		const binaryString = atob(base64.substring(0, 50));

		const match = binaryString.match(/%PDF-(\d+\.\d+)/);

		return match ? parseFloat(match[1] || "0") : null;
	};

	useEffect(() => {
		apiCalls.getTaxCertificate(certType, entityId, entityType).then((resp) => {
			if (isFailedApiCall(resp)) return;
			
			setPdfUrl((resp.data as any) as string);
			setContentType(resp.headers["content-type"]);
			const version = getPdfVersion((resp.data as any) as string);
			setPdfVersion(version);
			((version && onChange) && (version <= 1.6)) && onChange();
		});
	}, [entityType, entityId]);

	const downloadBase64File = () => {
		const linkSource = `data:${contentType};base64,${pdfUrl}`;
		const downloadLink = document.createElement("a");
		downloadLink.href = linkSource;
		downloadLink.download = fileName;
		downloadLink.click();
	};

	const isPdf = expectedFileType === "pdf";

	return (
		<>
			<Popup
				open={showing}
				onOpen={() => setShowing(true)}
				onClose={() => setShowing(false)}
				className="mobile-modal"
			>
				{pdfUrl ? (
					<Col>
						<Row>
							{isPdf ? (
								<embed
									src={`data:${contentType};base64,${pdfUrl}`}
									type="application/pdf"
									width="100%"
									height="1000vh"
								/>
							) : (
								<img
									src={`data:${contentType};base64,${pdfUrl}`}
									alt=""
									width="100%"
									height="100%"
								/>
							)}
						</Row>
						<Row>
							<Col>
								<Button onClick={() => setShowing(false)} color="gray">
									Close
								</Button>
							</Col>
							<Col>
								<Button onClick={downloadBase64File}>Download</Button>
							</Col>
						</Row>
					</Col>
				) : null}
			</Popup>

			{(pdfUrl && isPdf && (pdfVersion !== null && pdfVersion >= 1.7)) || !isPdf ? (
				<Button onClick={() => setShowing(true)} className={btnClass}>View File Chosen</Button>
			) : (
				<span>{fileName}</span>
			)}
		</>
	);
};

export default DisplayEntityPdf;
