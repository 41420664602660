import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import apiCalls from '../PowerShadesAPIFunctions';
import ContractorList from './ContractorList';
import ContractorRead from './ContractorRead';

import { Channel, ChannelId, ChannelName } from '../powershadesApiTypes';
import type { fakeRootType } from "../FakeRootType";


const contractorString = 'contractor';

/* eslint-disable camelcase, no-param-reassign */

type ContractorsProps = {
	ROOT: fakeRootType;
}

const Contractors = ({ ROOT }: ContractorsProps) => {
	const { loaded, toast, alert } = ROOT;
	const navigate = useNavigate();
	const [urlParams,] = useSearchParams();
	const contractorId = urlParams.get('contractorId');

	const [channels, setChannels] = useState<Channel[]>([{
		id: 0 as ChannelId,
		name: '' as ChannelName,
		description: ''
	}]);

	const viewContractor = (orgID) => {
		navigate(`/Contractors?contractorId=${orgID}`);
	};

	const addContractor = (options, ...callbacks) => {
		options.organization_type = contractorString;

		apiCalls.createOrganization(options).then((resp) => {
			const { data } = resp;

			if (!data.success) {
				alert({ quickFormat: 'error', text: 'Something went wrong' });
				// error message
			} else {
				viewContractor(contractorId);
				toast('Contractor created', { type: 'success' });
			}

			callbacks.forEach((c) => c(data));
		});
	};

	useEffect(() => {
		apiCalls.listChannelTypes().then((resp) => {
			setChannels(resp && resp.data && resp.data.channels);
		});

		if (contractorId) {
			loaded();
		}
	}, [contractorId, loaded]);

	if (contractorId) {
		// List
		return (
			<ContractorRead
				channels={channels}
				ROOT={ROOT}
				loaded={loaded}
				contractorId={contractorId}
			/>
		);
	}
	// Solo Item
	return (
		<ContractorList
			contractorString={contractorString}
			channels={channels}
			view={viewContractor}
			add={addContractor}
		/>
	);
};

export default Contractors;
