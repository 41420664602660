import React, { useState, useEffect } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";

import apiCalls from "../PowerShadesAPIFunctions";
import { useModal } from "../Store/ui/hooks";

import Button from "../Parts/Button";
import SpinningWheel from "../Parts/SpinningWheel";

import type { ResetPasswordProps } from "./types";

import styles from "./ResetPassword.module.scss";

const ResetPassword = ({ ROOT }: ResetPasswordProps) => {
	const { setTitle, loaded, GET, navigate, toast } = ROOT;
	const [email, setEmail] = useState(GET.preset || "");
	const [passwordResetSubmitted, setPasswordResetSubmitted] = useState(false);

	useEffect(() => {
		setTitle("Reset Password");
		loaded();
	}, []);

	const modal = useModal();

	const attemptReset = () => {
		const url = window.location.href;

		const urlSplit = url.split("#");

		const callBack = `${urlSplit[0]}#ConfirmPasswordReset`;

		setPasswordResetSubmitted(true);

		apiCalls
			.sendResetPasswordEmail(email, callBack)
			.then((resp) => {
				if (resp.status === 200) {
					toast("Password reset email sent!", { type: "success"} );
					navigate("/");
				}
			})
			.catch((err) => {
				console.log(err);
				setPasswordResetSubmitted(false);
				if (err?.response?.data?.message) {
					modal({
						title: "Failed to Reset Password",
						icon: "error",
						text:
							err?.response?.data?.message ?? "Something went wrong trying to reset your password",
						showCancelButton: false
					});
				}
			});
	};

	const resetButtonDisabled = email === "" || passwordResetSubmitted;

	return (
		<Container>
			<Row className="justify-content-center">
				<Col sm={10} md={8} lg={6} xl={3} className={styles.resetCard}>
					<Card body border="secondary" className={styles.resetCardBody}>
						<Row>
							<Col className="mt-4 mb-4 text-center">
								<h3>Request Password Reset</h3>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col>
								Please enter your email address below - an email will be sent to you with
								instructions on how to reset your password.
							</Col>
						</Row>
						<Row className="mb-4">
							<Col>
								<label htmlFor="reset-email">Email Address</label>
								<Form.Control
									id="reset-email"
									type="email"
									onChange={(e) => setEmail(e.target.value)}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											attemptReset();
										}
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Button
									color="gray"
									onClick={() => {
										navigate("/");
									}}
								>
									Cancel
								</Button>
							</Col>
							<Col className="text-end">
								<Button
									disabled={resetButtonDisabled}
									color={resetButtonDisabled ? "gray" : "green"}
									onClick={() => {
										attemptReset();
									}}
								>
									{passwordResetSubmitted ? (
										<Row>
											<Col className="mt-2">
												<SpinningWheel
													style={{
														color: "black",
														marginLeft: "1em"
													}}
												/>
											</Col>
										</Row>
									) : (
										"Reset"
									)}
								</Button>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default ResetPassword;
