import React, { FC, useEffect, useMemo, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useRepresentatives, useTerritories } from "../Store/entities/hooks";
import TabPaneWithSearchFilter from "../Parts/TabPaneWithSearchFilter";
import { TabPaneSelections } from "../Parts/types";
import { fakeRootType } from "../FakeRootType";

type RepresentativeListProps = {
	ROOT: fakeRootType;
}

const RepresentativeList: FC<RepresentativeListProps> = (props) => {
	const [activeSearch, setActiveSearch] = useState<string>('');
	const [activeKey, setActiveKey] = useState(0);

	const { ROOT } = props;
	const { navigate, setTitle } = ROOT;

	const territories = useTerritories();

	const representatives = useRepresentatives();

	const searchMe = (search: string) => {
		if (activeSearch.length !== 0) {
			return search?.toLocaleLowerCase().includes(activeSearch?.toLocaleLowerCase() ?? "") ?? false;
		}
		return true;
	};

	const workingRepresentatives = useMemo(() => representatives
		.filter((d) => d)
		.filter((d) => searchMe(d.name))
		.sort((d1, d2) =>
			d1.name.toLocaleLowerCase().localeCompare(d2.name.toLocaleLowerCase())),
	[representatives, activeSearch]);

	const getTabSelectionsList = (): TabPaneSelections[] => {
		const workingTabPanes: TabPaneSelections[] = [];

		workingTabPanes[0] = {
			id: 0,
			title: "All Representatives",
			key: "representativeList",
			items: [],
			filters: []
		};

		territories.forEach((wt) => {
			workingTabPanes.push({
				id: wt.id,
				title: wt.name,
				key: wt.name,
				items: workingRepresentatives
					.filter((wr) => wr.territory_ids.includes(wt.id))
					.map((wr) => ({
						id: `${wr.id}`,
						type: "representative",
						name: wr.name,
						title: wr.name,
						onClick: () => {
							navigate(`/Representative?representative_id=${wr.id}`);
						}
					}))
			});
		});

		// Check if all tab exists, if so, push the dealer to it
		if (workingTabPanes[0] !== undefined) {
			workingTabPanes[0].items = workingRepresentatives.map((representative) => ({
				id: `${representative.id}`,
				type: "representative",
				name: representative.name,
				group: representative.territory_ids,
				title: representative.name,
				onClick: () => {
					navigate(`/Representative?representative_id=${representative.id}`);
				}
			}));
		}
		return workingTabPanes;
	};

	const tableSelectionsCurrent = getTabSelectionsList();

	const loading = tableSelectionsCurrent.length < 1;

	useEffect(() => {
		setTitle("View Representatives");
	}, [setTitle]);

	return (
		<Container className="local-bootstrap">
			<Card body className="entity-card">
				<Row>
					<Col>
						<TabPaneWithSearchFilter
							key="representative-list"
							selections={tableSelectionsCurrent}
							loading={loading}
							disabled={false}
							setActiveKey={setActiveKey}
							activeKey={activeKey}
							searchFilter={activeSearch}
							setSearchFilter={setActiveSearch}
						/>
					</Col>
				</Row>
			</Card>
		</Container>
	);
};

export default RepresentativeList;
