import { createSelector } from "reselect";
import store, { AppState, UsePortalSelector } from "../..";
import { loadStatus } from "../entitiesType";
import { loadRmaTypesFull } from ".";
import { selectCurrentUserData } from "../../user/userPermissions";




/**
 * Selects the RMA Type list
 * @returns The RMA Type list
 */
const selectRMATypeList = createSelector((state: AppState) =>
	{
        if (state?.entity?.rmaTypes?.loadStatus < loadStatus.needsLoaded) {
            store.dispatch(loadRmaTypesFull());
        }
        return state.entity.rmaTypes.list;
    }, (rmaTypes) => rmaTypes);

/**
 * Selects the RMA Type list and dispatches a load if needed
 * @returns The RMA Type list if user is a ps admin, otherwise an empty array
 */
export const selectRMATypeListAndDispatch = createSelector([selectRMATypeList, selectCurrentUserData], (rmaTypes, userData) => {
	const userRequestingIsPsAdmin = userData?.roles.some((role) => role.role_name === "powershades_admin");
	if (!userRequestingIsPsAdmin) return [];
	
	return rmaTypes;
});

/**
 * Custom hook that returns a list of RMA types if the requesting user is a PSadmin,
 * otherwise returns an empty array.
 * @returns a list of RMA Types
 */
export const useActiveRMATypes = () => UsePortalSelector(selectRMATypeListAndDispatch).filter((rmaType) => !rmaType.archived);
export const useAllRMATypes = () => UsePortalSelector(selectRMATypeListAndDispatch);

/**
 * Selects the RMA Type Department list
 * @returns The RMA Type Department list
 */
const selectRMADepartmentList = createSelector((state: AppState) =>
    {
        return state.entity.rmaTypes.departments;
    }, (rmaTypes) => rmaTypes);

/**
 * Selects the RMA Department Type list 
 * @returns The RMA Type list if user is a ps admin, otherwise an empty array
 */

export const selectRMADepartmentListAndDispatch = createSelector([selectRMADepartmentList, selectCurrentUserData], (rmaTypes, userData) => {
    const userRequestingIsPsAdmin = userData?.roles.some((role) => role.role_name === "powershades_admin");

    if (!userRequestingIsPsAdmin) return [];
    
    return rmaTypes;
});

/**
 * Custom hook that returns a list of RMA Department Options if the requesting user is a PSadmin,
 * otherwise returns an empty array.
 * @returns a list of RMA Type Departments
 */

export const useRMADepartmentList = () => UsePortalSelector(selectRMADepartmentList).filter((rmaType) => !rmaType.archived);
export const useAllRMADepartmentList = () => UsePortalSelector(selectRMADepartmentList);

/**
 * Selects the RMA Root Cause Type list
 * @returns The RMA Root Cause Type list
 */

const selectRMARootCauseList = createSelector((state: AppState) =>
    {
        return state.entity.rmaTypes.root_causes;
    }, (rmaTypes) => rmaTypes);

/**
 * Selects the RMA Root Cause Type list
 * @returns The RMA Type list if user is a ps admin, otherwise an empty array
 *  */

export const selectRMARootCauseListAndDispatch = createSelector([selectRMARootCauseList, selectCurrentUserData], (rmaTypes, userData) => {
    const userRequestingIsPsAdmin = userData?.roles.some((role) => role.role_name === "powershades_admin");

    if (!userRequestingIsPsAdmin) return [];
    
    return rmaTypes;


});

/**
 * Custom hook that returns a list of RMA Root Causes if the requesting user is a PSadmin,
 * otherwise returns an empty array.
 * @returns a list of RMA Root Causes
 */ 

export const useRMARootCauseList = () => UsePortalSelector(selectRMARootCauseList).filter((rmaType) => !rmaType.archived);
export const useAllRMARootCauseList = () => UsePortalSelector(selectRMARootCauseList);





