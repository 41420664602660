import React, { FC, useState } from "react";
import { Container, Col, Row, Form, DropdownButton, Dropdown } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDealers, useDistributorLocationById, useDistributorLocationsByDistributorId } from "../Store/entities/hooks";
import BillingInformationHandler from "../ManageEntities/BillingInformationHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faEdit, faPlus, faShuffle } from "@fortawesome/free-solid-svg-icons";
import AddDistributorLocationModal from "../DistributorLocation/AddDistributorLocationModal";
import SwapDealerBetweenDistributorsModal from "../DistributorLocation/SwapDealersBetweenDistributorsModal";
import { DistributorLocationStore } from "../Store/entities/entitiesType";

type DistributorDistributorLocationsViewProps = {
	distributorId: number;
}

const DistributorDistributorLocationsView:
	FC<DistributorDistributorLocationsViewProps> = (props) => {
		const {
			distributorId
		} = props;

		const distributorLocations = useDistributorLocationsByDistributorId(distributorId);

		const [selectedDistLocationId, setSelectedDistLocationId] = useState(0);
		const [addDistributorModelOpen, setAddDistributorModelOpen] = useState(false);
		const [swapDealerModalOpen, setSwapDealerModalOpen] = useState(false);

		const selectedDistLocation = distributorLocations.find(
			(dil) => dil.id === selectedDistLocationId
		);

		const dealers = useDealers();
		const _disLocDealers = dealers.filter(
			(dealer) => dealer?.distributor_location_territories
				.some((dlt) => dlt.distributor_location_id === selectedDistLocationId)
		);

		console.log(_disLocDealers);

		const currentDistLocation = useDistributorLocationById(selectedDistLocationId);
		const isLoaded = currentDistLocation?.id !== 0;

		return (
			<Row className="entity-container">
				<Col>
					<Row xs={12}>
						<Col xs={11}>
							<h4>Distributor Locations</h4>
						</Col>
						<Col xs={1}>
							<DropdownButton
								variant="green"
								className="quote-action"
								title={<FontAwesomeIcon icon={faBars} />}
							>
								<Dropdown.Item key={1} onClick={() => { setAddDistributorModelOpen(true); }}>
									<FontAwesomeIcon icon={faPlus} className="p-2" />
									Add
								</Dropdown.Item>
								<Dropdown.Item title="Swap Dealers Between Locations" key={2} onClick={() => { setSwapDealerModalOpen(true); }}>
									<FontAwesomeIcon icon={faShuffle} className="p-2" />
									Swap

								</Dropdown.Item>
								<Dropdown.Item href={`/#/distributorLocation?distributor_location_id=${currentDistLocation?.id}`} title="Edit Selected Distributor Location" key={3} onClick={() => { /* Insert Dealer Swap Here */ }}>
									<FontAwesomeIcon icon={faEdit} className="p-2"  />
									Edit
								</Dropdown.Item>
							</DropdownButton>
						</Col>

					</Row>
					<Row>
						<Typeahead
							id="dist-dist-location-select"
							placeholder="Select a Distributor Location"
							options={distributorLocations}
							isLoading={false}
							labelKey="name"
							// Not multiple but it acts better when marked as multiple
							multiple
							selected={selectedDistLocation ? [selectedDistLocation] : []}
							onChange={(selection) => {
								// The second selection is the new one, unless there isn't one
								const selectedCountry = selection[1] ?? selection[0];
								if (!selectedCountry) {
									setSelectedDistLocationId(0);
									return;
								}

								const distLoc = selectedCountry as DistributorLocationStore;

								setSelectedDistLocationId(distLoc.id);
							}}
						/>
					</Row>
					{(currentDistLocation && isLoaded) && (
						<Container>
							<Row>
								<Row>
									<br />
								</Row>
								<Row className="mb-4">
									<Col sm={12} md={6}>
										<h4>Email</h4>
										<Form.Control
											disabled
											type="text"
											value={currentDistLocation.email}
										/>
									</Col>
									<Col sm={12} md={6}>
										<h4>Phone</h4>
										<Form.Control
											disabled
											type="text"
											value={currentDistLocation.phone}
										/>
									</Col>
								</Row>
							</Row>
							<Row>
								<BillingInformationHandler
									disabled
									address={currentDistLocation.billing_address}
									onChange={() => {
										/* will never be called */
									}}
									loading={!isLoaded}
								/>
							</Row>
						</Container>
					)}
					<AddDistributorLocationModal
						distributorId={distributorId}
						allowedTerritoryIds={[]}
						open={addDistributorModelOpen}
						setOpen={(open) => setAddDistributorModelOpen(open)}

					/>
					<Row>
						<SwapDealerBetweenDistributorsModal
							distributorId={distributorId}
							initialDistributorLocationId={selectedDistLocationId}
							open={swapDealerModalOpen}
							setOpen={(open) => setSwapDealerModalOpen(open)}
						/>
					</Row>
				</Col>
			</Row>
		);
	};

export default DistributorDistributorLocationsView;
