/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @stylistic/max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from "react";
// import { useAssembliesByQuoteId } from "../Store/entities/assemblies/hooks";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faLock, faTrashAlt, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { Option } from "react-bootstrap-typeahead/types/types";
import Table from '../Parts/Table';
import Button from "../Parts/Button";
import { QuoteTypeNames } from "../powershadesApiTypes";
import type { QuoteData } from "./NewItems/QuoteData";
import { UsePortalDispatch, UsePortalSelector } from "../Store";
import PSOutdoorTableRow from "./PSOutdoorTableRow";
import { fakeRootType } from "../FakeRootType";
import { useNewPSOutdoorShadeOptions } from "../Store/entities/assemblies/PSOutdoorHooks";
import FabricSelectField from "./NewOptionFields/FabricSelectField";
import FractionField from "./NewOptionFields/FractionField";
import { AssemblyStore, ShadeStore } from "../Store/entities/assemblies/types";
import { useCurrentMultiplier } from "../Store/entities/multipliers/hooks";
import SelectBox from "./NewItems/SelectBox";
import PowerShadesTypeahead from "../Parts/PowerShadesTypeahead";
import { usePSOutdoorFabrics } from "../Store/entities/hooks";
import { useUser } from "../Store/user/hooks";
import { useAssemblyOptions } from "../Store/entities/assemblyOptions/hooks";
import MassEditPSOutdoorModal from "../Modals/ShadeModals/EditShade/EditPSOutdoorShade/MassEditPSOutdoorShade";
import { selectPSOutdoorAssembliesByQuoteId, InitialAssemblyState, updateAssembly, deleteAssemblies, setAssembliesUnlocked, setAssembliesSelected } from "../Store/entities/assemblies";
import { useQuoteById, useQuoteIdAndDispatch, useQuoteTypes, useQuoteTypeNameByQuoteId } from "../Store/entities/quotes/hooks";

type PSOutdoorTableProps = {
	quoteId: number;
	unlockShadeBySequenceId: (sequenceId: number) => void;
	quoteData: QuoteData;
};

const PSOutdoorTable = (props: PSOutdoorTableProps) => {
	const {
		quoteId,
		unlockShadeBySequenceId,
		quoteData
	} = props;

	const psOutdoorAssemblies = UsePortalSelector(selectPSOutdoorAssembliesByQuoteId(quoteId));
	const selectedPsOutdoorAssemblies = psOutdoorAssemblies.filter((assembly) => assembly.selected);
	const [foughAssembly, setFoughAssembly] = useState<AssemblyStore>(InitialAssemblyState());

	const user = useUser();
	const quote = useQuoteById(quoteId);

	const assemblyOptions = UsePortalSelector(useAssemblyOptions(quoteId));

	const psOutdoorFabrics = usePSOutdoorFabrics(quoteId);
	const psOutdoorOptions
		= useNewPSOutdoorShadeOptions(foughAssembly, setFoughAssembly, { fabrics: psOutdoorFabrics, user, quote, assemblyOptions }, true);

	const { Fabric } = psOutdoorOptions;

	const quoteMeta = useQuoteIdAndDispatch(quoteId);
	const quoteTypeId = quoteMeta?.quote_type_id;
	const quoteTypes = useQuoteTypes();
	const currentQuoteType = (quoteTypes.find((qt) => qt.id === quoteTypeId)?.name ?? "CEDIA") as QuoteTypeNames;
	// const selectedAssemblies =
	const isEditable = quoteMeta?.editable ?? false;

	const dispatch = UsePortalDispatch();
	const quoteType = useQuoteTypeNameByQuoteId(quoteId);

	const optionInitState = {
		roomName: "",
		shadeName: "",
		header: { label: '', value: "", title: "" },
		color: "",
		length: 0,
		width: 0
	};
	const [option, setOption] = useState(optionInitState);
	const setOptionPartial = (newOption?: Partial<typeof option>) => {
		newOption && setOption({ ...option, ...newOption });
	};

	const headerOptions: Option[] = [
		{ label: 'Open Roll', value: "Open Roll 120", title: "Open Roll 120" },
		{ label: 'Large Open Roll', value: "Open Roll Extreme", title: "Open Roll Extreme" },
		{ label: 'Box (120)', value: "Box 120", title: "Box 120" },
		{ label: 'Large Box (190)', value: "Box 190", title: "Box 190" }
	];

	const [shadeFormOpen, setShadeFormOpen] = useState(false);
	const currentMultiplier = useCurrentMultiplier(quoteId);
	const { label, currency } = currentMultiplier;

	const handleMassEditSubmit = () => {
		if (JSON.stringify(option) !== JSON.stringify(optionInitState)) {
			psOutdoorAssemblies.forEach((assembly) => {
				if (assembly.selected) {
					const updatedAssembly: AssemblyStore = {
						...assembly,
						room_name: option.roomName.length > 0 ? option.roomName : assembly.room_name,
						shade_name: option.shadeName.length > 0 ? option.shadeName : assembly.shade_name,
						header_line: option.header?.value?.length > 0 ? option.header.value : assembly.header_line,
						shades: [{
							...assembly.shades[0],
							fabric_name: option.color.length > 0 ? option.color : assembly.shades[0]?.fabric_name,
							height: option.length > 0 ? option.length : assembly.shades[0]?.height,
							width: option.width > 0 ? option.width : assembly.shades[0]?.width,
						}] as ShadeStore[]
					};
					dispatch(updateAssembly({
						quoteId,
						sequenceId: assembly.sequence_id,
						assembly: updatedAssembly
					}));
				}
			});
			setOption(optionInitState);
		}
	};

	const columns: {
		displayName: string;
	}[] = [
		{
			displayName: "Unlock/Select",
		},
		{
			displayName: "Room Name",
		},
		{
			displayName: "Shade Name",
		},
		{
			displayName: "Header",

		},
		{
			displayName: "Fabric Name",

		},
		{
			displayName: "Width",

		},
		{
			displayName: "Height",

		},
		{
			displayName: `${label} (${currency})`
		},
		{
			displayName: "Actions",
		}
	];

	const {
		alert,
		toast
	} = quoteData.quoteLevelVariables().ROOT as fakeRootType;

	const selectedAssemblySeqIds = selectedPsOutdoorAssemblies.map((a) => a.sequence_id);
	const onDelete = () => {
		const deletingShadeCount = selectedPsOutdoorAssemblies.length;
		alert({
			title: 'Are you sure you want to delete this shade?',
			text: `You are about to delete ${deletingShadeCount} shades! There's no way to undo this.`,
			icon: 'warning',
			quoteType,
		})
			.then(() => {
				dispatch(deleteAssemblies({
					quoteId,
					sequenceIds: selectedAssemblySeqIds,
				})).then(() => {
					toast(`${deletingShadeCount} shades deleted`, {
						type: 'success',
					});
				}).catch(() => {
					toast(`Deletion of ${deletingShadeCount} shades failed`, {
						type: 'info',
					});
				});
			})
			.catch(() => {
				toast(`Deletion of ${deletingShadeCount} shades canceled`, {
					type: 'info',
				});
			});
	};

	const massTableAction = (
		<Container className="local-bootstrap">
			<Row className="g-0">
				<MassEditPSOutdoorModal
					isOpen={shadeFormOpen}
					onClose={() => setShadeFormOpen(false)}
					quoteId={quoteId}
				/>
				<Col key="a2">
					<Button
						title="Edit"
						size="sm"
						hoverColor={currentQuoteType}
						fullWidth
						color="light"
						onClick={() => setShadeFormOpen(true)}
						disabled={selectedPsOutdoorAssemblies.length < 1}
					>
						<FontAwesomeIcon icon={faEdit} />
					</Button>
				</Col>
				<Col key="a3">
					<Button
						title="Delete"
						size="sm"
						hoverColor={currentQuoteType}
						fullWidth
						color="light"
						onClick={() => onDelete()}
						disabled={selectedPsOutdoorAssemblies.length < 1}
					>
						<FontAwesomeIcon icon={faTrashAlt} />
					</Button>
				</Col>
			</Row>

		</Container>
	);
	if (psOutdoorAssemblies.length === 0) {
		return null;
	}

	const isAllOutdoorShadesUnlocked = psOutdoorAssemblies.every((a) => a.unlocked);
	const unlockAllShades = () => {
		dispatch(setAssembliesUnlocked({
			quoteId,
			sequenceIds: psOutdoorAssemblies.map((a) => a.sequence_id),
			unlocked: true,
		}));
	};

	return (<div
		className="quote-table-psoutdoor"
		style={{
			overflowX: 'auto', overflowY: 'hidden', marginBottom: '0.25em'
		}}
	>
		{
			(
				isEditable && (
					<Table
						quoteType={currentQuoteType}
						className="quote-mass-table-psoutdoor"
						noTBody
						style={{
							width: '100%', zIndex: '22', marginBottom: '0.5em'
						}}
					>
						<tbody>

							<tr className="bold-row">
								<th className={currentQuoteType} colSpan={9}>
									{/* {buttonAdd ? <div className="table-to-right">{buttonAdd}</div> : null} */}
									<div className={`table-header ${currentQuoteType}`}>PS Outdoor Shades Mass Edit</div>
								</th>
							</tr>
							<tr>
								<th colSpan={1}>
									Select
								</th>
								<th colSpan={1}>
									Room Name
								</th>
								<th colSpan={1}>
									Shade Name
								</th>
								<th colSpan={1}>
									Header
								</th>
								<th colSpan={1}>
									Color
								</th>
								<th colSpan={1}>
									Width
								</th>
								<th colSpan={1}>
									Height
								</th>
								<th colSpan={1}>
									Actions
								</th>
								<th colSpan={1}>
									Apply
								</th>
							</tr>
							<tr>
								<td colSpan={1} >
									<SelectBox
										onChange={(e) => {
											const sequenceIds: number[] = [];
											(psOutdoorAssemblies.filter((a) => a.unlocked)
												.map((fa) => sequenceIds.push(fa.sequence_id)));
											dispatch(setAssembliesSelected({
												quoteId, sequenceIds, selected: e.target.checked
											}));
										}}
									/>
								</td>
								<td colSpan={1} >
									<input
										className="form-control"
										disabled={false}
										placeholder="-"
										value={option.roomName}
										onBlur={(e) => setOptionPartial({ roomName: e.target.value })}
										onChange={(e) => setOptionPartial({ roomName: e.target.value })}
									/>
								</td>
								<td colSpan={1} >
									<input
										className="form-control"
										disabled={false}
										placeholder="-"
										value={option.shadeName}
										onBlur={(e) => setOptionPartial({ shadeName: e.target.value })}
										onChange={(e) => setOptionPartial({ shadeName: e.target.value })}
									/>
								</td>
								<td colSpan={1} >
									<PowerShadesTypeahead
										style={{ width: "10rem", minWidth: "100%" }}
										clearButton={option.header.label !== ""}
										disabled={false}
										id="header-type"
										options={headerOptions ?? []}
										selected={[option.header]}
										onChange={(e) => setOptionPartial({
											header: e[0] as typeof option.header
												|| optionInitState.header
										})}
									/>
								</td>
								<td colSpan={1} >
									<FabricSelectField
										className="-cell"
										style={{ width: "15em", minWidth: "100%" }}
										disabled={false}
										value={option.color}
										choices={Fabric.options}
										canceled={Fabric.clearValue}
										onChange={(e) => setOptionPartial({ color: e })}
										onBlur={() => console.log('blur')}
									/>
								</td>
								<td colSpan={1} >
									<FractionField
										key="width-edit-mass"
										varName="width"
										disabled={false}
										value={option.width}
										onBlur={(e) => setOptionPartial({ width: e })}
										minValue={27}
										maxValue={238}
										width={10}
									/>
								</td>
								<td colSpan={1} >
									<FractionField
										key="height-edit-mass"
										varName="length"
										disabled={false}
										value={option.length}
										onBlur={(e) => setOptionPartial({ length: e })}
										minValue={12}
										maxValue={240}
										width={10}
									/>
								</td>
								<th colSpan={1}>{massTableAction}</th>
								<td colSpan={1}>
									<Button onClick={handleMassEditSubmit}>Apply</Button>
								</td>
							</tr>
						</tbody>
					</Table>
				))
		}
		<Table
			quoteType={currentQuoteType}
			className="quote-table"
			noTBody
			style={{ width: '100%', zIndex: '22' }}
		>
			<tbody>
				<tr className="bold-row">
					<th className={currentQuoteType} colSpan={9}>
						<div className="table-to-right">
							{isEditable && (
								<span title="unlock" onClick={() => (isAllOutdoorShadesUnlocked ? () => {} : unlockAllShades())} className=" fa-fw">
									<FontAwesomeIcon
										className="icon-unlock unlock-all"
										transform="x-12 grow-6"
										icon={
											isAllOutdoorShadesUnlocked ? faUnlock : faLock
										}
									/>
								</span>
							)}
						</div>
						<div className={`table-header ${quoteType}`}>PS Outdoor Shades</div>
					</th>
				</tr>
				<tr>
					{columns.map((column) => (
						<th key={column.displayName}>
							{column.displayName}
						</th>
					))}
				</tr>
				{psOutdoorAssemblies.map((assembly) => (
					<PSOutdoorTableRow
						quoteData={quoteData}
						unlockShadeBySequenceId={unlockShadeBySequenceId}
						quoteId={quoteId}
						sequenceId={assembly.sequence_id}
					/>

				))}
			</tbody>
		</Table>

	</div>);
};

export default PSOutdoorTable;

