/* eslint-disable indent */
/* eslint-disable arrow-body-style */
/* eslint-disable no-undef, func-names, object-shorthand */
import axios from "axios";
import getSymbolFromCurrency from "currency-symbol-map";

import {
	API_OPTIONS,
	apiDelete,
	apiGet,
	apiGetFile,
	apiPost,
	apiPut,
	apiUploadFile,
	atobReplacement,
	isFailedApiCall,
	legacyCall,
	toCamelCase,
} from "./psUtil";

// * Camel cases are disabled because the API uses snake case. Disabling no explicit any since we
// * working on migrating this to typescript.
/* eslint-disable camelcase, @typescript-eslint/no-explicit-any,
@typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return,
@typescript-eslint/no-unsafe-call */

import {
	Motor,
	Tube,
	HardwarePart,
	Territory,
	Dealer,
	AdminEmailAddress,
	Coupon,
	QuoteType,
	successResp,
	isEvolution,
	successError,
	DealerNameExistsResponse,
	possibleError,
	FieldErrors,
	assemblyBody,
	QuoteListResp,
	ReportResponse,
	Report,
	ReportBody,
	DemoReviewResponse,
	TerritoryTag,
	ConfigurationRequest,
	QuoteReview,
	OrderStatus,
	HardwareRequestItem,
	ShallowFabric,
	BankCard,
	BankAccount,
	CountrySubdivision,
	Accessory,
	Distributor,
	Manufacturer,
	DealerSignUpForm,
	Category,
	DownloadLink,
	Tag,
	EmailSent,
	Address,
	Channel,
	BuyingGroupMeta,
	QuoteAction,
	Notification,
	MyAddressResp,
	NewShadeInfo,
	QuoteMetaData,
	TermsOptions,
	EntityHistoryView,
	QuoteTypeIds,
	ExistsResp,
	DistributorLocation,
	DealerMeta,
	UpsertUserRoleBody,
	AddDistributorLocationInput,
	SwapDealersInput,
	RepresentativeMeta,
	Representative,
	QuoteShipment,
	DistributorMeta,
	QuoteCounts,
	successErrorMessage,
	QuoteAccessoryParameters,
	QuoteAccessoryStore,
	UserAttributes,
	QuoteFilteringResponse,
	IncorrectTerritoryMetaISOCodeKey,
	TransformedTerritoryMetaCall,
	IncorrectTerritoryISOCodeKey,
	TransformedTerritoryCall,
	TransformedTerritoriesCall,
	GetCreditLimitCheck,
	ZohoLinks,
	SalesPerson,
	ShadeAssembliesAndCoordinates,
	GetPaymentMethods2Response,
	ZohoLinkChecks,
	EntityListFullResp,
	FabricStore,
	RMAType,
	DealerStore,
	DeleteUserRoleInput,
	AddUserRoleInput,
	DeleteUserInput,
	AddUserInput,
	QuoteStatus,
	FilterOptions,
	TableCounts,
	QuotePricingVM,
	AssemblyViewModel,
	DuplicateAssemblyInputs,
	FabricViewModel,
	QuoteFabricSampleVM,
	RMADepartmentType,
	RMARootCauseType,
	QuoteVM,
	BidDataAssemblyAmounts,
	ServiceScreenVariables,
	AssemblyOptionsViewModel,
//	FabricsDiscontinued,
	FabricAllowedResponse,
	QuoteMultiplierVM,
	FasciaCombinationVM,
	QuoteAddressVM,
	HardwareRequestItemFromOptions,
	Portfolio
} from "./powershadesApiTypes";

import { AssemblyFieldNames, AssemblyPrice, AssemblyShadePayload, GenericAssemblyCostBreakdown, PartsListResponse, Quote, QuoteEvent, Role, SaveAssemblyResp, ShadeError, ShadePart, User, UserMeta, UserRole, assemblyFieldToPropertyNameMap } from "./powershadesApiTypeExtensions";
import { RMAItems } from "./Quote/OrderModal/PaymentMethods/RMAPayment/types";

// const getAlert = (() => {
// 	const anyWindow = window as any;
// 	const anyROOT = anyWindow.ROOT;
// 	const { alert } = anyROOT;

// 	return alert as (opts: any) => Promise<void>;
// });

// const alert = (opts: any) => getAlert()(opts);

export const getToken = () => {
	const goods = localStorage.getItem("user_data") ?? "";
	const goodsObj = goods === "" ? {} : JSON.parse(goods);

	const jwt = goodsObj?.jwt ?? "";

	return jwt;
};

const ReportAction = <Type>(
	start: string,
	end: string,
	name: string,
	extraParams?: any
): Promise<{ data: Type, status: number }> => {
	const input = {
		...extraParams,
		start_time: start,
		end_time: end,
		user_token: getToken(),
	};

	return axios.post(`${process.env.BACKEND_ROOT}${process.env.REPORTS_ENDPOINT}/${name}.php`, input, {
		responseType: "blob",
		timeout: 300000,
	});
};

const tokenPayload = (token: string) => {
	if ((token?.length ?? 0) < 1 || !token) {
		return null;
	}

	return JSON.parse(atobReplacement(token.split(".")[1] ?? ""));
};

const verifyAssemblyFieldName = (field: string): field is AssemblyFieldNames => {
	return Object.keys(assemblyFieldToPropertyNameMap).includes(field);
};

/**
 * @desc Helper function for updating assembly fields.
 * Type declaration can be found in powershadesApiTypes.ts
 * @param field - The field to update.
 * @param quoteId - The quote id.
 * @param sequenceId - The sequence id.
 * @param body - The body of the request.
 */
const updateAssembly = (field: AssemblyFieldNames, quoteId: number, sequenceId: number, body: assemblyBody) => {
	const assemblyFieldExists = verifyAssemblyFieldName(field);
	if (!assemblyFieldExists) {
		console.error({
			field,
			quoteId,
			sequenceId,
			body,
		});

		throw new Error(`Invalid assembly field name: ${field}`);
	}
	return apiPut<successError>(`Quotes/${quoteId}/Assembly/${sequenceId}/${field}`, body);
};

/**
 * @desc Function to update a boolean field in an assembly.
 * @param field - The field to update.
 * @param quoteId - The quote id.
 * @param sequenceId - The sequence id.
 * @param boolVal - The boolean value to set.
 * @returns Success response.
 */
const updateAssemblyBoolField = (field: AssemblyFieldNames, quoteId: number, sequenceId: number, boolVal: boolean) =>
	updateAssembly(field, quoteId, sequenceId, { value: boolVal === true });

/**
 * @desc Function to update a boolean field in an assembly.
 * @param field - The field to update.
 * @param quoteId - The quote id.
 * @param sequenceId - The sequence id.
 * @param stringVal - The string value to set.
 */
const updateAssemblyStringField = (field: AssemblyFieldNames, quoteId: number, sequenceId: number, stringVal: string) =>
	updateAssembly(field, quoteId, sequenceId, { value: stringVal });

/**
 * @desc Helper function used for logging button clicks.
 * @returns Nothing.
 */
const callEvent = (event: string) => (quoteId: number) => apiPost<void>(`QuoteEvents/${quoteId}/event/${event}`);

/**
 * @desc Function that checks if the user is logged in, and if so, returns the user's token.
 * Otherwise, returns null.
 */
const tokenData = () => {
	const token = getToken();
	const loggedIn = token !== null && token !== undefined && token !== "";

	if (loggedIn) {
		return tokenPayload(token);
	}
	return null;
};

/**
 * @desc Our Mega apiCall object that holds all of our functions.
 */
const apiCalls = {

	/**
	 * @desc Function that confirms the reset password.
	 * @param email - The users email.
	 * @param token - The users token.
	 * @param newPassword - The new password.
	 * @params reNewPassword - The re-entered new password.
	 * @todo Shape out the response object.
	 */
	confirmResetPassword: (email: string, token: string, newPassword: string, reNewPassword: string) =>
		axios.post(`${process.env.DASHBOARD_ROOT}/api/v1/auth/password/reset/confirm`, {
			email,
			passwordToken: token,
			password: newPassword,
			password_confirmation: reNewPassword,
		}),

	/**
	 * @desc Helper function that grabs currency data based off of the user's territories.
	 * @returns Currency data.
	 */
	currencyData: async () => {
		const territoriesResp = await apiCalls.getTerritoriesMeta();
		if (territoriesResp.status !== 200) {
			return {
				ISO: "USD",
				multiplier: 1,
				symbol: "$",
			};
		}
		const { territories } = territoriesResp.data;
		const territory = territories && territories.length > 0 ? territories[0] : null;
		if (!territory) {
			return {
				ISO: "USD",
				multiplier: 1,
				symbol: "$",
			};
		}
		const symbol = getSymbolFromCurrency(territory.iso_4217_currency_code_sell);
		return {
			ISO: territory.iso_4217_currency_code_sell,
			multiplier: territory.msrp_multiplier,
			symbol: symbol,
		};
	},

	/**
	 * @desc Returns a list of all the motors.
	 * Type declaration can be found in powershadesApiTypes.ts
	 */
	getMotors: () =>
		apiGet<{
			motors: Motor[];
		}>(`motors`),

	/**
	 * @desc Returns a list of all inclucing which systems it can go on.
	 * Type declaration can be found in powershadesApiTypes.ts
	 */
	getFabricList: (quoteId: number) => apiGet<{ fabrics: FabricViewModel[] }>(`AssemblyOptions/Fabrics/${quoteId}`),


	/**
		 * @desc Returns a list of all fabrics allowed indoor.
		 * Type declaration can be found in powershadesApiTypes.ts
		 */
		
	getFabricIndoorAllowedList: (quoteId: number) => apiGet<{fabrics: FabricViewModel[]}>(`Fabrics/IndoorAllowedByQuoteId/${quoteId}`),

	/**
		 * @desc Returns a list of all fabrics allowed outdoor..
		 * Type declaration can be found in powershadesApiTypes.ts
		 */
		
	getFabricPsOutdoorAllowedList: (quoteId: number) => apiGet<{fabrics: FabricViewModel[]}>(`Fabrics/OutdoorAllowedByQuoteId/${quoteId}`),


	getPopulateDashboardTask: (taskId: number) =>
		axios.get(`${process.env.DASHBOARD_ROOT}/api/v1/dashboard-tasks/${taskId}/`, API_OPTIONS("dashboard-tasks")),

	/**
	 * @desc Back end call for password resets.
	 * @param email - The email to send the reset link to.
	 * @returns Message string.
	 */
	sendResetPasswordEmail: (email: string, callback?: string) =>
		axios.post<{ message: string }>(`${process.env.DASHBOARD_ROOT}/api/v1/auth/password/reset`, {
			email,
			callback,
		}),

	// TODO: Type this & Add Doc info.
	// ! Not sure where to find this call in the backend.
	uploadToS3: (formData: FormData) =>
		axios.post(process.env.BACKEND_ROOT + process.env.UPLOAD_TO_S3_ENDPOINT, formData, {
			headers: {
				"content-type": "multipart/form-data",
				Authorization: `Bearer ${getToken()}`,
			},
		}),

	/**
	 * @desc Back end call for sales reports.
	 * @param startTime - The start date of the report.
	 * @param endTime - The end date of the report.
	 * @param reportType - The type of report (sales)
	 * @param selectedSalesPerson - The sales person to filter by.
	 * @returns Excel file with all of the data.
	 */
	salesReport: (startTime: string, endTime: string, reportType: string, selectedSalesPerson: number) =>
		ReportAction<ReportResponse>(startTime, endTime, "Sales", {
			type: reportType,
			salesperson_id: selectedSalesPerson,
		}),

	/**
	 * @desc Back end call for generating new quotes report.
	 * @param startTime - The start date of the report.
	 * @param endTime - The end date of the report.
	 * @returns A multi dimensional array with a bunch of strings (used for excel file conversion)
	 */
	newQuotes: (startTime: string, endTime: string) => ReportAction<ReportResponse>(startTime, endTime, "NewQuotes"),

	/**
	 * @desc Back end call for generating new dealer sign up report.
	 * @param startTime - The start date of the report.
	 * @param endTime - The end date of the report.
	 * @returns A multi dimensional array with a bunch of strings (used for excel file conversion)
	 */
	newDealerSignups: (startTime: string, endTime: string) =>
		ReportAction<ReportResponse>(startTime, endTime, "NewDealerSignups"),

	/**
	 * @desc Back end call for generating a report of motors used.
	 * @param startTime - The start date of the report.
	 * @param endTime - The end date of the report.
	 * @returns A multi dimensional array with a bunch of strings (used for excel file conversion)
	 *  */
	motorsUsed: (startTime: string, endTime: string) => ReportAction<ReportResponse>(startTime, endTime, "MotorsUsed"),

	/**
	 * @desc Back end call for generating a report of accessories used.
	 * @param startTime - The start date of the report.
	 * @param endTime - The end date of the report.
	 * @returns A multi dimensional array with a bunch of strings (used for excel file conversion)
	 */
	accessoriesUsed: (startTime: string, endTime: string) =>
		ReportAction<ReportResponse>(startTime, endTime, "AccessoriesUsed"),

	/**
	 * @desc Back end call for generating a report of shade cost break down.
	 * @param startTime - The start date of the report.
	 * @param endTime - The end date of the report.
	 * @param power_type - The type of shade(s) to retrieve (Manual / Motorized / All)
	 */
	shadeCostBreakdown: (startTime: string, endTime: string, power_type: "Manual" | "Motorized" | "All") =>
		ReportAction<ReportResponse>(startTime, endTime, "ShadeCostBreakdown", { power_type }),

	/**
	 * @desc Back end call for getting a list of existing demo reviews.
	 * @param quoteId - the quote id to get the dealer attached to grab existing demo reviews.
	 * @returns The list of demo reviews.
	 * @note - This is a weird call - it has to be done on a demo review quote.
	 * */
	getDemoReview: (quoteId: number) => apiGet<DemoReviewResponse>(`DemoReview/${quoteId}`),

	/**
	 * @desc Back end call for converting a quote to a demo.
	 * @param quoteId - the quote id to convert.
	 * @returns The status of the demo review & success response or an error.
	 */
	requestDemoReview: (quoteId: number) =>
		apiPost<(DemoReviewResponse & successResp) | possibleError>(`DemoReview/${quoteId}`, {}),

	/**
	 * @desc Back end call for approving a demo quote.
	 * @param quoteId - the quote id to approve.
	 * @param demoRequestTime - the time the demo was requested.
	 * @param demoType - the type of discount on the demo.
	 * @param demoValue - the cost of the demo order
	 * @param isShippingFree - ... is the shipping free?
	 * @returns The status of the demo review & success response or an error.
	 *  */
	approveDemoReview: (
		quoteId: number,
		demoRequestTime: Date,
		demoType: string,
		demoValue: number,
		isShippingFree: boolean
	) =>
		apiPost<(DemoReviewResponse & successResp) | possibleError>(`DemoReview/Approve/${quoteId}`, {
			is_shipping_free: isShippingFree,
			demo_type: demoType,
			demo_value: demoValue,
			demo_request_time: demoRequestTime,
		}).catch((err) => console.error("Error in approveDemoReview: ", err)),

	/**
	 * @desc Back end call for denying a demo quote.
	 * @param quoteId - the quote id to deny.
	 * @param demoRequestTime - the time the demo was requested.
	 * @param rejectionMessage - the reason for the denial.
	 * @returns The status of the demo review & success response or an error.
	 */
	rejectDemoReview: (quoteId: number, demoRequestTime: Date, rejectionMessage: string) =>
		apiPost<(DemoReviewResponse & successResp) | possibleError>(`DemoReview/Reject/${quoteId}`, {
			demo_request_time: demoRequestTime,
			message: rejectionMessage,
		}),

	/**
	 * @desc Back end call for deleting a demo request.
	 * @param quoteId - the quote id to delete.
	 * @returns Error or message saying void was successful.
	 *  */
	voidDemoReview: (quoteId: number) => apiDelete<possibleError | { message: string }>(`DemoReview/${quoteId}`),

	/**
	 * @desc Back end call for getting demo quotes from an organization.
	 * @param organizationId - the organization id to get the quotes from.
	 * @param organizationType - the type of organization (dealer, distr, etc.)
	 * @returns An array of demo quotes.
	 */
	GetDemoOrdersByOrganization: (organizationId: number, organizationType: string) =>
		apiGet<DemoReviewResponse[]>(`DemoReview/Organization/${organizationId}?organizationType=${organizationType}`),

	/**
	 * @desc Get's a list of available territory tags.
	 * @returns An array of territory tags.
	 */
	GetTerritoryTags: () => apiGet<TerritoryTag[]>(`Territory/Tags`),

	/**
	 * @desc Creates a territory tag.
	 * @param tagName - The name of the tag.
	 * @returns The created tag & success message or an error.
	 */
	AddTerritoryTag: (tagName: string) =>
		apiPost<{ message: string, tag: TerritoryTag } | possibleError>(`Territory/Tags`, { name: tagName }),

	/**
	 * @desc Deletes a territory tag.
	 * @param tagId - The id of the tag to delete.
	 * @returns The success message or an error.
	 */
	DeleteTerritoryTag: (tagId: number) => apiDelete<possibleError | { message: string }>(`Territory/Tags/${tagId}`),

	/**
	 * @desc Makes a quote a demo.
	 * @param quoteId - The id of the quote to make a demo.
	 * @returns The success boolean.
	 */
	makeQuoteDemo: (quoteId: number) => apiPost<successResp>(`DemoReview/MakeDemo/${quoteId}`),

	/**
	 * @deprecated in 1.5.0
	 */
	makePaymentNew: () => false,

	/**
	 * @desc Back end call (temp) for getting a list of quote_id's a user has access to.
	 */
	getAccessibleQuoteIds: () => apiGet<{ quote_ids: number[] }>(`Quotes/Accessible`),

	/**
	 * @desc Back end call (needs renamed probably) for getting quote counts per filter.
	 * @param filterInputs - The filter options to get counts for.
	 * @returns An object with the counts.
	 */
	getAccessibleQuoteCounts: (filterInputs: FilterOptions) =>
		apiPost<{ home_table_count: TableCounts }>(`Home/QuoteCounts`, filterInputs),

	/**
	 * @desc Back end call for getting a list of configuration requests.
	 * @returns An array of configuration requests.
	 */
	getConfigurationRequests: () => apiGet<ConfigurationRequest[]>(`Engineering/Configurations`),

	/**
	 * @desc Back end call for resolving (deleting) a configuration request.
	 * @param recipeSku - the sku of the recipe to resolve.
	 * @returns The updated list of configuration requests.
	 */
	resolveConfigurationRequest: (recipeSku: string) =>
		apiDelete<ConfigurationRequest[]>(`Engineering/Configurations/${recipeSku}`),

	/**
	 * @desc Back end call for un resolving a configuration request.
	 * @param id - the id of the configuration request to un-resolve.
	 * @returns The updated list of configuration requests.
	 */
	unResolveConfigurationRequest: (id: number) => apiPut<ConfigurationRequest[]>(`Engineering/Configurations/${id}`),

	/**
	 * @desc Back end call for submitting a credit card payment
	 * @param quoteId - the quote id to submit the payment for.
	 * @param cardZid - the zoho id of the card to use.
	 * @param IsAch - is the payment an ach payment?
	 * @param AssertedPrice - the price of the order.
	 * @returns The success boolean / error message.
	 */
	submitCreditCardPayment: (quoteId: number, cardZid: string, IsAch: boolean, AssertedPrice: number) => {
		const data = {
			CardZid: cardZid,
			IsAch,
			AssertedPrice,
		};

		return apiPost<successError>(`Pricing/Card/${quoteId}`, data);
	},

	/**
	 * @desc Back end call that submits a terms payment on a quote.
	 * @param quoteId - the quote id to submit the payment for.
	 * @param PoNumber - the poNumber provided by the customer.
	 * @returns The success boolean, error message, or a message.
	 */
	submitDealerTermsPayment: (quoteId: number, PoNumber: string) => {
		const data = { PoNumber };

		return apiPost<successError | (successError & { message: string })>(`Pricing/Terms/${quoteId}`, data)
			.catch((err) => console.error("Error in submitDealerTermsPayment: ", err));
	},

	/**
	 * @desc Back end call that submits an admin payment.
	 * @param quoteId - the quote id to submit the payment for.
	 * @param SalePrice - the sale price of the order.
	 * @param ShippingPrice - the shipping price of the order.
	 * @param TaxShipping - the tax on the shipping price.
	 * @param TaxPercentage - the percentage of tax on the order.
	 * @param InstallationCost - the installation cost of the order.
	 * @param MiscCost - the misc cost of the order.
	 * @param TotalPrice - the total price of the order.
	 * @param FinalPrice - the final price of the order (including installation, misc, etc)
	 * @param Multiplier - the multiplier of the order.
	 * @returns Success Boolean / Error Message.
	 */
	submitAdminPayment: (
		quoteId: number,
		SalePrice: number,
		ShippingPrice: number,
		TaxShipping: number,
		TaxPercentage: number,
		InstallationCost: number,
		MiscCost: number,
		TotalPrice: number,
		FinalPrice: number,
		Multiplier: number,
		CreditCard: boolean
	) => {
		const data = {
			SalePrice,
			ShippingPrice,
			TaxShipping,
			TaxPercentage,
			InstallationCost,
			MiscCost,
			TotalPrice,
			FinalPrice,
			Multiplier,
			CreditCard,
		};

		return apiPost<successError |(successError & { message: string })>(`Pricing/Admin/${quoteId}`, data).catch((error) => {
			return error;
	});
	},

	/**
	 * @desc Back end call that fetches pending quote reviews.
	 * @returns An array of pending quote reviews.
	 */
	getQuoteReviews: () => apiGet<QuoteReview[]>(`Engineering/QuoteReviews`),

	/**
	 * @desc Back end call for approving a quote review.
	 * @param quoteId - the quote id to approve.
	 * @returns The success boolean.
	 */
	approveQuoteReview: (quoteId: number) => apiPost<successResp>(`Engineering/QuoteReviews/${quoteId}`, {}),

	/**
	 * @deprecated in 1.5.0
	 */
	submitQuote: (quoteId: number) => apiPost<{ message: string }>(`Pricing/Submit/${quoteId}`, {}),

	/**
	 * @desc Back end call for rejecting a quote review.
	 * @param quoteId - the quote id to reject.
	 * @param notes - the reason for rejecting the quote.
	 * @returns The success boolean / error message
	 */
	rejectQuoteReview: (quoteId: number, notes: string) =>
		apiPut<successResp |(successResp & { message: string })>(`Engineering/QuoteReviews/${quoteId}`, { notes }),

	/**
	 * @desc Back end call for retrieving a quotes order status.
	 * @param quoteId - the quote id to get the order status for.
	 * @returns Nothing.
	 * @ignore This is an unused feature.
	 */
	getOrderStatus: (quoteId: number) =>
		apiGet<{
			quote_status: QuoteStatus[];
		}>(`Quotes/Status/${quoteId}`),

	/**
	 * @desc Back end call for adding a status to a quote.
	 * @param quoteId - the quote id to add the status to.
	 * @param explanation - the explanation of the status.
	 * @returns Success boolean + quote status.
	 * @ignore This is an unused feature.
	 */
	addOrderStatus: (quoteId: number, explanation: string) =>
		apiPut<successResp & OrderStatus>(`Quotes/Status/${quoteId}`, { explanation }),

	/**
	 * @desc Returns a list of all the hardware parts.
	 * Type declaration can be found in powershadesApiTypes.ts
	 */
	getHWParts: () => apiGet<{ parts: HardwarePart[] }>("parts"),

	/*
  // Get Hardware Items by quoteId
        [HttpGet("{quoteId}/HardwareItems"), Authorize(Policy = "RequireQuoteAccess")]
        public async Task<JsonResult> GetHardwareItemsByQuoteId (int quoteId)
        {
            var quote = _bll.Quote(quoteId);

            var hwItems = quote.HardwareItems;
            var hwItemVms = hwItems.Select(hwi => hwi.ToViewModel()).ToList();

            return new SnakeJsonResult(new
            {
                HardwareRequestItems = hwItemVms
            });
        }
	*/

	/**
	 * @desc Back end call for getting hardware items by quote id.
	 * @param quoteId - the quote id to get the hardware items for.
	 * @returns A list of the quotes hardware items.
	 */
	getHwParts: (quoteId: number) =>
		apiGet<{ hardware_request_items: HardwareRequestItem[] }>(`Quotes/${quoteId}/HardwareItems`),

	/**
	 * @desc Back end call for getting hardware items by quote id.
	 * @param quoteId - the quote id to get the hardware items for.
	 * @returns A list of the quotes hardware items.
	 */
	getAllowedHwParts: (quoteId: number) =>
		apiGet<{ parts: HardwareRequestItemFromOptions[] }>(`AssemblyOptions/Parts/${quoteId}`),

	// ? It looks like this function returns something different than the one above (getHwParts).
	// ? Could be my mistake, from mistyping hardware parts, but not 100% sure.
	/**
	 * @desc Back end call for adding a hardware item to a quote.
	 * @param quoteId - the quote id to add the hardware item to.
	 * @param sku - the sku of the hardware item to add.
	 * @returns A list of the quotes hardware items.
	 */
	createHWItem: (quoteId: number, sku: string, lineItemNumber: number) =>
		apiPost<{ hardware_request_items: HardwareRequestItem[] }>(`Quotes/${quoteId}/HardwareItems`, { Sku: sku, recommended_line_item_number: lineItemNumber }),

	/**
	 * @desc Back end call for updating a hardware item on a quote.
	 * @param quoteId - the quote id to update the hardware item on.
	 * @param lineNumber - the line number of the hardware item to update.
	 * @param quantity - the quantity of the hardware item to update.
	 * @returns A list of the quotes hardware items.
	 */
	updateHWItem: (quoteId: number, lineNumber: number, quantity: number) =>
		apiPut<{ hardware_request_items: HardwareRequestItem[] }>(`Quotes/${quoteId}/HardwareItems/${lineNumber}`, { quantity }),

	/**
	 * @desc Back end call for deleting a hardware item on a quote.
	 * @param quoteId - the quote id to delete the hardware item on.
	 * @param lineNumber - the line number of the hardware item to delete.
	 * @returns A list of the quotes hardware items.
	 */
	deleteHWItem: (quoteId: number, lineNumber: number) =>
		apiDelete<{ hardware_request_items: HardwareRequestItem[] }>(`Quotes/${quoteId}/HardwareItems/${lineNumber}`),

	/**
	 * @desc Back end call for setting if a shade (sequence id) is motor only.
	 * @param quoteId - the quote id that contains said shade
	 * @param sequenceId - the sequence id of the shade
	 * @param boolVal - the boolean value to set
	 * @returns Success boolean.
	 */
	setIsMotorOnly: (quoteId: number, sequenceId: number, boolVal: boolean) =>
		updateAssemblyBoolField("IsMotorOnly", quoteId, sequenceId, boolVal),

	/**
	 * @desc Back end call for setting if a shade (sequence id) is tube and fabric only.
	 * @param quoteId - the quote id that contains said shade
	 * @param sequenceId - the sequence id of the shade
	 * @param boolVal - the boolean value to set
	 * @returns Success boolean.
	 */
	setIsTubeAndFabricOnly: (quoteId: number, sequenceId: number, boolVal: boolean) =>
		updateAssemblyBoolField("IsTubeAndFabricOnly", quoteId, sequenceId, boolVal),

	/**
	 * @desc Back end call for setting if a shade (sequence id) is channels only.
	 * @param quoteId - the quote id that contains said shade
	 * @param sequenceId - the sequence id of the shade
	 * @param boolVal - the boolean value to set
	 * @returns Success boolean.
	 */
	setIsChannelsOnly: (quoteId: number, sequenceId: number, boolVal: boolean) =>
		updateAssemblyBoolField("IsChannelsOnly", quoteId, sequenceId, boolVal),

	/**
	 * @desc Back end call for setting if a shade (sequence id) is hardware only.
	 * @param quoteId - the quote id that contains said shade
	 * @param sequenceId - the sequence id of the shade
	 * @param boolVal - the boolean value to set
	 * @returns Success boolean.
	 */
	setIsHardwareOnly: (quoteId: number, sequenceId: number, boolVal: boolean) =>
		updateAssemblyBoolField("IsHardwareOnly", quoteId, sequenceId, boolVal),

	/**
	 * @desc Back end call for setting if a shade (sequence id) is header extrusion only.
	 * @param quoteId - the quote id that contains said shade
	 * @param sequenceId - the sequence id of the shade
	 * @param boolVal - the boolean value to set
	 * @returns Success boolean.
	 */
	setIsHeaderExtrusionOnly: (quoteId: number, sequenceId: number, boolVal: boolean) =>
		updateAssemblyBoolField("IsHeaderExtrusionOnly", quoteId, sequenceId, boolVal),

	/**
	 * @desc Back end call for setting if a shade (sequence id) is service (cut down, etc) only.
	 * @param quoteId - the quote id that contains said shade
	 * @param sequenceId - the sequence id of the shade
	 * @param boolVal - the boolean value to set
	 * @returns Success boolean.
	 */
	setIsShadeService: (quoteId: number, sequenceId: number, boolVal: boolean) =>
		updateAssemblyBoolField("IsShadeService", quoteId, sequenceId, boolVal),

	/**
	 * @desc Back end call for setting a shade's (sequence id) recipe sku.
	 * @param quoteId - the quote id that contains said shade
	 * @param sequenceId - the sequence id of the shade.
	 * @param stringVal - the string value to set as the recipe sku.
	 * @returns Success boolean.
	 */
	setRecipeSku: (quoteId: number, sequenceId: number, stringVal: string) =>
		updateAssemblyStringField("RecipeSku", quoteId, sequenceId, stringVal),

	/**
	 * @desc Back end call for setting a shade's (sequence id) tube sku.
	 * @param quoteId - the quote id that contains said shade
	 * @param sequenceId - the sequence id of the shade.
	 * @param stringVal - the string value to set as the tube sku.
	 * @returns Success boolean.
	 */
	setTubeSku: (quoteId: number, sequenceId: number, stringVal: string) =>
		updateAssemblyStringField("TubeOverride", quoteId, sequenceId, stringVal),

	/**
	 * @desc Back end call for setting a shade's (sequence id) motor sku.
	 * @param quoteId - the quote id that contains said shade
	 * @param sequenceId - the sequence id of the shade.
	 * @param stringVal - the string value to set as the motor sku.
	 * @returns Success boolean.
	 */
	setMotorSku: (quoteId: number, sequenceId: number, stringVal: string) =>
		updateAssemblyStringField("MotorOverride", quoteId, sequenceId, stringVal),

	/**
	 * @desc Back end call for setting if a shade (sequence id) is crowns and drives only.
	 * @param quoteId - the quote id that contains said shade
	 * @param sequenceId - the sequence id of the shade
	 * @param boolVal - the boolean value to set
	 * @returns Success boolean.
	 */
	setIsCrownAndDriveOnly: (quoteId: number, sequenceId: number, boolVal: boolean) =>
		updateAssemblyBoolField("IsCrownAndDrive", quoteId, sequenceId, boolVal),

	/**
	 * @desc Returns a list of all the tubes.
	 * Type declaration can be found in powershadesApiTypes.ts
	 */
	getTubes: () => apiGet<{ tubes: Tube[] }>(`tubes`),

	/**
	 * @desc Returns a list of all the motors.
	 * Type declaration can be found in powershadesApiTypes.ts
	 */
	getMotorsShades: () => apiGet<{ motors: Motor[] }>(`motors`),

	/**
	 * @desc Back end call for grabbing a list of the fabric families and their lead times
	 * @ignore Unused feature
	 * @returns A list of the fabric families and their lead times.
	 */
	getFabricFamilies: () =>
		apiGet<{ fabric_families: ShallowFabric[], fabric_to_leadtimes: ShallowFabric }>(`fabricFamilies`),

	/**
	 * @deprecated in 1.5.0
	 */
	updateZohoFabricFamilyLeadtimes: () => false,

	/**
	 * @desc Back end call for merging two dealers.
	 * @ignore Unused feature.
	 */
	mergeDealers: (oldDealerId: number, resultingDealerId: number) =>
		apiPost(`dealers`, {
			original_dealer_id: oldDealerId,
			swapped_contact_id: resultingDealerId,
		}),

	/**
	 * @desc Returns a list of all declined dealers.
	 * Type declaration can be found in powershadesApiTypes.ts
	 */
	getDeclinedDealers: () => apiGet<Dealer[]>(`dealers/declined`),

	/**
	 * @desc Back end call recieving quote Id and a file type to download
	 * a shade list to be uploaded.
	 * @param quoteId - the quote id to get shades from.
	 * @param fileType - the type of file to download.
	 * @returns Nothing
	 */
	getExportedQuoteShades: (quoteId: number, fileType: string) =>
		apiGetFile(`shadeimport/${fileType}/Quote/${quoteId}`, `PS${quoteId}-shade_export.${fileType}`),

	/**
	 * @desc Import a list of shades to a quote and update the widths, height, and names.
	 * @param quoteId - the quote id to import shades to.
	 * @param shadeFile - the file to import.
	 */
	importQuoteShades: (quoteId: number, shadeFile: File) =>
		apiUploadFile(
			`shadeimport`,
			// IFEE to create a form data object with the quote id and file.
			(() => {
				const formData = new FormData();
				formData.append("file", shadeFile);
				formData.append("quote_id", quoteId.toString());
				return formData;
			})()
		),

	/**
	 * @desc Back end call for deactivating a dealer.
	 * @param dealerId - the dealer id to deactivate.
	 * @returns Success boolean.
	 */
	deactivateDealer: (dealerId: number) => apiPost<successResp>(`dealers/${dealerId}/deactivate`),

	/**
	 * @desc Back end call for activating a dealer.
	 * @param dealerId - the dealer id to activate.
	 * @returns Success boolean.
	 */
	activateDealer: (dealerId: number) => apiPost<successResp>(`dealers/${dealerId}/activate`),

	/**
	 * @desc Back end call for getting a list of all the dealers.
	 * @returns Two lists - active and inactive dealers.
	 */
	quickDealers: () => apiGet<{ dealers: Dealer[], inactive_dealers: Dealer[] }>(`dealers/FilterList`),

	/**
	 * @desc Back end call for finding out if a terms retainer is required for a quote ID.
	 * @param quoteId - the quote id to check.
	 * @returns Boolean.
	 */
	termsRetainerNeeded: (quoteId: number) =>
		apiGet<{ is_retainer_required: boolean }>(`Pricing/NeedsRetainer/${quoteId}?payment_type=DT`),

	/**
	 * @desc Back end call for finding out if a card retainer is required for a quote ID.
	 * @param quoteId - the quote id to check.
	 * @returns Boolean.
	 */
	cardRetainerNeeded: (quoteId: number) =>
		apiGet<{ is_retainer_required: boolean }>(`Pricing/NeedsRetainer/${quoteId}?payment_type=CC`),

	/**
	 * @desc Back end call for finding out if an ACH retainer is required for a quote ID.
	 * @param quoteId - the quote id to check.
	 * @returns Boolean.
	 */
	achRetainerNeeded: (quoteId: number) =>
		apiGet<{ is_retainer_required: boolean }>(`Pricing/NeedsRetainer/${quoteId}?payment_type=ACH`),

	/**
	 * @desc Back end call for grabbing a list of bank accounts and cards attached to an entity.
	 * @param entityType - the entity type to check. (dealer, dist, etc)
	 * @param entityId - the entity id to check.
	 * @returns A list of bank accounts and cards.
	 */
	getPaymentMethods2: (entityType: string, entityId: number) =>
		apiGet<GetPaymentMethods2Response>(`PaymentMethods?entity_type=${entityType}&entity_id=${entityId}`, { timeout: 200000 }),

	/**
	 * @desc Back end call for getting the zoho link to add a card to an entities account.
	 * @param entityType - the entity type to check. (dealer, dist, etc)
	 * @param entityId - the entity id to check.
	 * @returns A link to add a card.
	 */
	getAddCardLink: (entityType: string, entityId: number) =>
		apiGet<{ link: string }>(`PaymentMethods/AddCardLink?entity_type=${entityType}&entity_id=${entityId}`),

	/**
	 * @desc Backend call for deleting a payment method from an entity.
	 * @param entityType - the entity type to check. (dealer, dist, etc)
	 * @param entityId - the entity id to check.
	 * @param itemZid - the zoho id of the payment method to delete.
	 * @returns Success boolean or the zoho entity.
	 */
	deletePaymentMethod: (entityType: string, entityId: number, itemZid: string) =>
		apiDelete<successResp>(`PaymentMethods?entity_type=${entityType}&entity_id=${entityId}&item_zid=${itemZid}`),

	/**
	 * @desc Back end call for verifying an ACH account.
	 * @param entityType - the entity type to check. (dealer, dist, etc)
	 * @param entityId - the entity id to check.
	 * @param accountZid - the zoho id of the account to verify.
	 * @param firstDeposit - the first deposit amount.
	 * @param secondDeposit - the second deposit amount.
	 * @returns Success boolean.
	 */
	updateACHVerification: (
		firstDeposit: number,
		secondDeposit: number,
		accountZid: string,
		entityType: string,
		entityId: number
	) =>
		apiPut<successResp & { message: string }>("PaymentMethods/VerifyAccount", {
			first_payment: firstDeposit,
			second_payment: secondDeposit,
			account_zid: accountZid,
			entity_type: entityType,
			entity_id: entityId,
		}),

	/**
	 * @desc Back end call for getting a list of reports.
	 * @returns A list of reports.
	 */
	getReports: () => apiGet<{ reports: Report[] }>("Reports"),

	/**
	 * @desc Back end call for running a report.
	 * @param key - the key of the report to run.
	 * @param body - the body of the report to run.
	 * @returns Report response
	 */
	RunReport: (key: string, body: ReportBody) => apiPost<ReportResponse>(`Reports/${key}`, body, { timeout: 200000 }),

	/**
	 * @desc Backend call for logging whenever the first "Place Order" button is pressed on a quote.
	 * @returns Nothing.
	 */
	placeOrderPressed: (quoteId: number) => callEvent("place_order_pressed")(quoteId),

	/**
	 * @desc Backend call for logging whenever the
	 * "Next" button is pressed on the "Ready to Place an Order?" modal.
	 * @returns Nothing.
	 */
	shippingNextPressed: (quoteId: number) => callEvent("shipping_next_pressed")(quoteId),

	/**
	 * @desc Backend call for logging whenever the next dialogue prompt for selecting which
	 * payment method will be used.
	 * @returns Nothing.
	 */
	psAdminPressed: (quoteId: number) => callEvent("ps_admin_pressed")(quoteId),

	/**
	 * @desc Backend call for logging whenever the next dialogue prompt for selecting which
	 * payment method will be used.
	 * @returns Nothing.
	 */
	dealerTermsPressed: (quoteId: number) => callEvent("dealer_terms_pressed")(quoteId),

	/**
	 * @desc Backend call for logging whenever the next dialogue prompt for selecting which
	 * payment method will be used.
	 * @returns Nothing.
	 */
	ccAchPressed: (quoteId: number) => callEvent("cc_ach_pressed")(quoteId),

	/**
	 * @desc Backend call for logging whenever card is selected for payment.
	 * @returns Nothing.
	 */
	cardSelected: (quoteId: number) => callEvent("card_selected")(quoteId),

	/**
	 * @desc Backend call for logging whenever ACH is selected for payment.
	 * @returns Nothing.
	 */
	achSelected: (quoteId: number) => callEvent("ach_selected")(quoteId),

	/**
	 * @desc Back end call for logging when the final button is clicked, once payment method has been
	 * selected and the order is good to go. If the order attached to this event was not placed,
	 * there was an error in order placement.
	 * @returns Nothing.
	 */
	submitOrderPressed: (quoteId: number) => callEvent("submit_order_pressed")(quoteId),

	/**
	 * @desc Backend call for retrieving the quote event filters.
	 * @returns a list of quote events (keys), quote ids, and users.
	 */
	getQuoteEventFilters: () =>
		apiGet<{
			keys: string[];
			quote_ids: number[];
			users: {
				user_id: number;
				user_email: string;
			}[];
		}>(`QuoteEvents/Filters`),

	/**
	 * @desc Backend call for retrieving the quote events.
	 * @returns A list of quote events.
	 */
	getAllQuoteEvents: () =>
		apiPost<QuoteEvent[]>(`QuoteEvents`, {
			startDate: new Date(Date.now() - 3).toLocaleTimeString,
			endDate: new Date(Date.now()).toLocaleTimeString,
		}),

	/**
	 * @desc Backend call for retrieving quote events with a filter.
	 * @param entityId - the entity id to check.
	 * @param entityType - the entity type to check. (dealer, dist, etc)
	 * @param userId - the user id to check.
	 * @param quoteId - the quote id to check.
	 * @param buttonPressed - the button pressed to check.
	 * @param startDate - the start date to check.
	 * @param endDate - the end date to check.
	 * @returns A list of quote events.
	 */
	getQuoteEventsFiltered: (
		entityId: number,
		entityType: string,
		userId: number,
		quoteId: number,
		buttonPressed: string,
		startDate: string,
		endDate: string
	) =>
		apiPost<{
			quote_events: QuoteEvent[];
		}>(`QuoteEvents`, {
			entity_id: entityId,
			entity_type: entityType,
			user_id: userId,
			quote_id: quoteId,
			button_pressed: buttonPressed,
			start_date: startDate,
			end_date: endDate,
		}),

	/**
	 * @desc Back end call for getting a list of dealers by distributor id.
	 * @param distributorId - the distributor id to check.
	 * @returns A list of dealers.
	 */
	getDealersByDistributorId: (distributorId: number) =>
		apiGet<{
			dealers: {
				dealer_id: number;
				dealer_name: string;
			}[];
		}>(`Dealers/ByDistributor?distributor_id=${distributorId}`),

	/**
	 *
	 * @param input Body for the swap dealer call.
	 * @returns Success Response
	 */
	SwapDealers: (input: SwapDealersInput) => apiPut<successResp>(`DistributorLocations/DealerSwap`, input),

	/**
	 * @desc Back end call for getting a tax certificate for an entity.
	 * @param entityId - the entity id to check.
	 * @param entityType - the entity type to check. (dealer, dist, etc)
	 * @param certType - the certificate type to check.
	 * @returns Content, content type, and status code.
	 */
	getTaxCertificate: (certType: string, entityId: number, entityType: string) =>
		apiGet<{
			content: string;
			content_type: string;
			status_code?: number;
		}>(`AmazonS3?certType=${certType}&entityType=${entityType}&entityIdStr=${entityId}`),

	/**
	 * @deprecated in 1.10.0
	 * @notes Use getTerritories instead.
	 */
	getUserTerritories: () => false,

	// TODO: Type this response & Add Doc info.
	// ! I couldn't find this call in the php backend - at least not in public methods.
	getTerritoryInfo: () => legacyCall("getTerritoryInfo"),

	/**
	 * @desc Call for getting information about a dealer by dealer id.
	 * @param dealerId - the dealer id to check.
	 * @returns An object containing a dealer.
	 */
	getDealer: (dealerId: number) => apiGet<{ dealer: Dealer }>(`Dealers/${dealerId}`),

	/**
	 * @desc Call for getting a list of quote meta data by dealer id.
	 * @param dealerId - the dealer id to check.
	 * @returns A list of quote meta data.
	 */
	getDealerQuotes: (dealerId: number) => apiGet<{ dealer_quotes: QuoteMetaData[] }>(`Dealers/${dealerId}/Quotes`),

	/**
	 * @deprecated in 1.10.0
	 * @notes Dealer ID should be accessed by Redux store.
	 */
	getDealerID: () => false,

	/**
	 * @deprecated in 1.10.0
	 * @notes Use getDealers instead.
	 */
	getMyDealers: () => false,

	/**
	 * @desc Call that gets dealer by territory ID.
	 * @param territoryId - the territory id to check.
	 * @returns A list of dealers.
	 */
	getDealersByTerritory: (territoryId: number) => apiGet<{ dealers: Dealer[] }>(`Dealers/ByTerritory/${territoryId}`),

	/**
	 * @desc Legacy call that gets a list of territories.
	 * @note This returns a full list of territories - can be a massive call depending on the user.
	 * Tread lightly.
	 * @returns A list of territories.
	 */
	getTerritories: (): TransformedTerritoriesCall =>
		apiGet<{ territories: IncorrectTerritoryISOCodeKey[] }>(`Territory/Full`).then((resp) => {
			if (isFailedApiCall(resp)) throw resp.error;
			const correctedTerritories = resp.data.territories.map((territory) => {
				const { iso4217_currency_code_buy, iso4217_currency_code_sell, ...rest } = territory;

				return {
					...rest,
					iso_4217_currency_code_buy: iso4217_currency_code_buy,
					iso_4217_currency_code_sell: iso4217_currency_code_sell,
				};
			});

			return {
				...resp,
				data: {
					...resp.data,
					territories: correctedTerritories,
				},
			};
		}),

	/**
	 * @desc Extension of the legacy call that gets a list of territories - this also
	 * sorts them by their id's.
	 * @returns A list of territory ID's.
	 */
	getUserTerritoriesID: async () => {
		const resp = await apiCalls.getTerritories();

		return resp.data.territories.map((t) => t.id);
	},

	tokenData,

	/**
	 * @desc Call for getting a list of all country subdivisions.
	 * @returns A list of country subdivisions.
	 */
	getCountrySubdivisions: async () =>
		apiGet<{ country_subdivisions: CountrySubdivision[] }>("Address/CountrySubdivisions"),

	/**
	 * @desc Generic function for setting a user attribute.
	 * @param attributeName - the name of the attribute to set.
	 * @param attributeValue - the value to set the attribute to.
	 * @param userId - the user id to set the attribute for.
	 * @returns Success with a message or error with a message.
	 */
	setUserAttribute: async (
		attributeName: keyof UserAttributes,
		attributeValue: string | boolean | number,
		userId: number
	) => apiPut<successErrorMessage>(`Users/${userId}/Attribute/${attributeName}`, { value: attributeValue }),

	/**
	 * @desc Legacy call for getting a list all accessories.
	 * @returns A list of accessories.
	 */
	getAccessories: () => apiGet<{ accessories: Accessory[] }>("Accessories"),

	/**
	 * @desc Legacy call for getting a list all accessories.
	 * @returns A list of accessories.
	 */
	getPortfolioAccessories: (quoteId: number) => apiGet<{ accessories: Accessory[] }>(`AssemblyOptions/Accessories/${quoteId}`),

	/**
	 * @desc Legacy call for getting a list all accessories.
	 * @returns A list of accessories.
	 */
	getPortfolioParts: (quoteId: number) => apiGet<{ parts: ShadePart[] }>(`AssemblyOptions/Parts/${quoteId}`),

	/**
	 * @desc Legacy call for getting a list of quote types.
	 * @returns a list of quote types and a success boolean.
	 */
	getQuoteTypes: () => legacyCall<{ success: boolean, quote_types: QuoteType[] }>("getQuoteTypes"),

	// ? How do we go about getting rid of this empty method?
	run: () => { },
	onLoad: {
		run: () => { },
		add: (callback: () => void) => {
			callback && callback();
		},
	},

	/**
	 * @desc Legacy call for adding an admin email address.
	 * @returns Nothing.
	 */
	addAdminEmailAddress: (newAdminEmailAddress: string) =>
		legacyCall<void>("addAdminEmailAddress", { email_address: newAdminEmailAddress }),

	/**
	 * @desc Legacy call for adding a coupon.
	 * @param code - the coupon "code" (e.g. "10OFF")
	 * @param note - the coupon "note", normally an explanation
	 * @param discount_type - the type of discount, e.g. "percent" or "fixed"
	 * @param discount_amount - the amount of the discount
	 * @param expires - the date the coupon expires
	 * @param max_total_uses - the maximum number of times the coupon can be used
	 * @param max_uses_per_dealer - the maximum number of times the coupon can be used per dealer
	 * @returns Nothing.
	 */
	addCoupon: (
		code: string,
		note: string,
		discount_type: string,
		discount_amount: number,
		expires: number,
		max_total_uses: number,
		max_uses_per_dealer: number
	) =>
		legacyCall<void>("addCoupon", {
			code,
			note,
			discount_amount,
			discount_type,
			expires,
			max_total_uses,
			max_uses_per_dealer,
		}),

	/**
	 * @desc Legacy call for adding a coupon to a quote.
	 * @param code - the coupon "code" (e.g. "10OFF")
	 * @param quote_id - the quote id to add the coupon to
	 * @param removal - if true, the coupon will be removed from the quote
	 * @returns Success boolean.
	 */
	addCouponToQuote: (code: string, quote_id: number, removal: boolean) =>
		legacyCall<successResp>(`addCouponToQuote`, {
			code,
			quote_id,
			removal,
		}),

	/**
	 * @desc Legacy call for adding a dashboard task to a quote.
	 * @param quote_id - the quote id to add the task to
	 * @param dashboard_task_id - the task to add
	 * @returns Success boolean.
	 */
	addDashboardTask: (quote_id: number, dashboard_task_id: number) =>
		legacyCall<successResp>(`addDashboardTask`, {
			quote_id,
			dashboard_task_id,
		}),

	/**
	 * @desc Legacy call for adding a download link.
	 * @param category_id - the category id to add the link to
	 * @param name - the name of the link
	 * @param url - the url of the link
	 * @param button_text - the text to display on the button
	 * @returns Success boolean.
	 */
	addDownloadLink: (category_id: number, name: string, url: string, button_text: string) =>
		legacyCall<successResp>(`addDownloadLink`, {
			category_id,
			name,
			url,
			button_text,
		}),

	/**
	 * @desc Legacy call for adding a download link category.
	 * @param name - the name of the category
	 * @returns Success boolean.
	 */
	addDownloadLinkCategory: (name: string) => legacyCall<successResp>(`addDownloadLinkCategory`, { name }),

	/**
	 * @desc Legacy call for adding a tag. (territory?)
	 * @ignore This isn't used anywhere in the app.
	 * @returns The new tag id.
	 */
	addTag: (text: string, color: string) =>
		legacyCall<{ new_tag_id: number } & successResp>(`addTag`, {
			text,
			color,
		}),

	/**
	 * @desc Legacy call for approving an order.
	 * @param quote_id - the quote id to approve
	 * @returns Success boolean.
	 */
	approveOrder: (quote_id: number) => legacyCall<successResp>(`approveOrder`, { quote_id }),

	/**
	 * @desc Legacy call for archiving a quote.
	 * @param quote_id - the quote id to archive
	 * @returns Success boolean.
	 */
	archiveQuote: (quote_id: number) => apiPut<{ success: boolean }>(`Quotes/${quote_id}/Archive`),

	/**
	 * @desc Call for un-archiving a quote.
	 * @param quote_id - the quote id to restore
	 * @returns Success boolean.
	 */
	restoreQuote: (quote_id: number) => apiPut<{ success: boolean }>(`Quotes/${quote_id}/Restore`),

	/**
	 * @desc Call for assigning a dealer to a quote.
	 * @param quote_id - the quote id to assign the dealer to
	 * @param dealer_id - the dealer id to assign to the quote
	 * @returns Success boolean.
	 */
	assignDealer: (dealer_id: number, quote_id: number) =>
		apiPut<successResp & isEvolution>(`Quotes/${quote_id}/DealerId`, {
			dealer_id,
		}),

	/**
	 * @desc Call for assigning a Distributor to a quote.
	 * @param quote_id - the quote id to assign the dealer to
	 * @param distributor_id - the distributor id to assign to the quote
	 * @returns Success boolean.
	 */
	assignDistributor: (distributor_id: number, quote_id: number) =>
		apiPut<successResp & isEvolution>(`Quotes/${quote_id}/DistributorId`, {
			distributor_id,
		}),

	/**
	 * @desc Call for assigning a Distributor to a quote.
	 * @param quote_id - the quote id to assign the dealer to
	 * @param distributor_id - the distributor id to assign to the quote
	 * @returns Success boolean.
	 */
	assignDistributorLocation: (distributor_location_id: number, quote_id: number) =>
		apiPut<successResp & isEvolution>(`Quotes/${quote_id}/DistributorLocationId`, {
			distributor_location_id,
		}),

	/**
	 * @desc Call for assigning a Representative to a quote.
	 * @param quote_id - the quote id to assign the dealer to
	 * @param representative_id - the representative id to assign to the quote
	 * @returns Success boolean.
	 */
	assignRepresentative: (representative_id: number, quote_id: number) =>
		apiPut<successResp & isEvolution>(`Quotes/${quote_id}/RepresentativeId`, {
			representative_id,
		}),

	/**
	 * @desc Call for assigning a territory to a quote.
	 * @param quote_id - the quote id to assign the dealer to
	 * @param territory_id - the territory id to assign to the quote
	 * @returns Success boolean.
	 */
	assignTerritory: (territory_id: number, quote_id: number) =>
		apiPut<successResp & isEvolution>(`Quotes/${quote_id}/TerritoryId`, {
			territory_id,
		}),

	/**
	 * @desc Call for assigning a whether or not the shipping
	 * 		 address is force to be at Evolution to a quote.
	 * @param quote_id - the quote id to assign the state to
	 * @param is_shipping_forced - a bool to force/unforce shipping
	 * @returns Success boolean.
	 */
	assignForcedEvolutionShipping: (is_shipping_forced: boolean, quote_id: number) =>
		apiPut<successResp & isEvolution>(`Quotes/${quote_id}/IsEvolutionShippingForced`, {
			is_evolution_shipping_forced: is_shipping_forced,
		}),

	/**
	 * @desc Legacy call for assigning a manufacturer to a quote.
	 * @param quote_id - the quote id to assign the manufacturer to
	 * @param manufacturer_id - the manufacturer id to assign to the quote
	 * @returns Success boolean.
	 */
	assignManufacturerToOrder: (manufacturer_id: number, quote_id: number) =>
		legacyCall<successError>(`assignManufacturerToOrder`, {
			manufacturer_id,
			quote_id,
		}),

	/**
	 * @desc Legacy call for converting a quote to an order.
	 * @param quote_id - the quote id to convert
	 * @returns Success boolean.
	 */
	convertToOrder: (quote_id: number) => legacyCall<successResp>(`convertToOrder`, { quote_id }),

	/**
	 * @desc Legacy call for creating a new distributor.
	 * @param distributor - Distributor object.
	 * @returns Success boolean.
	 */
	createDistributor: (distributor: Distributor) => legacyCall<successError>(`createDistributor`, { distributor }),

	/**
	 * @desc Legacy call for creating a global notification
	 * @param title - the title of the notification
	 * @param description - the message of the notification
	 * @param link - the link to open when the notification is clicked
	 * @returns Success boolean.
	 */
	createGlobalNotification: (title: string, description: string, link: string) =>
		legacyCall<successResp>(`createGlobalNotification`, {
			title,
			description,
			link,
		}),

	/**
	 * @desc Legacy call for creating a manufacturer.
	 * @param manufacturer - Manufacturer object.
	 * @returns Success boolean.
	 */
	createManufacturer: (manufacturer: Manufacturer) => legacyCall<successResp>(`createManufacturer`, { manufacturer }),

	/**
	 * @desc Legacy call for creating a representative.
	 * @param representative - Representative object.
	 * @returns Success boolean.
	 */
	createRepresentative: (representative: Representative) =>
		legacyCall<successResp>(`createRepresentative`, { representative }),

	/**
	 * @desc Legacy call for creating a new user attached to a dealer.
	 * @param dealer_id - the dealer id to create the user for
	 * @param user - User object.
	 * @returns Success boolean & potential field errors.
	 */
	dealerCreateUser: (dealer_id: number, user: User) =>
		legacyCall<successError & FieldErrors>(`dealerCreateUser`, {
			dealer_id,
			user,
		}),

	/**
	 * @desc Call for creating a user.
	 * @param user - User object.
	 * @returns Message of success, error message, and if successful, the new user ID.
	 */
	createUser: (user: AddUserInput) => apiPost<{ message: string, new_user_id?: number }>("Users", { ...user }),

	/**
	 * @desc Not implemented yet.
	 */
	updateUser: () => false,
	// apiPost<{ message: string, success: boolean }>(`Users/${user.user_id}`, { ...user }),

	/**
	 * @desc Call for deleting a user
	 * @param user - user_id and entity_id
	 * @returns Message of success, error message, success boolean.
	 */
	deleteUser: (user: DeleteUserInput) =>
		apiDelete<{ message: string, success: boolean }>(`Users/${user.user_id}?entity_id=${user.entity_id}`),

	/**
	 * @desc Call for adding a user role to... a user.
	 * @param role - role information
	 * @returns Message of success, error message, success boolean.
	 */
	addUserRole: (role: AddUserRoleInput) =>
		apiPost<{
			added_role?: UserRole;
			message: string;
			success: boolean;
		}>(`Users/${role.user_id}/Role`, { ...role }),

	/**
	 * @desc Call for deleting a user role from a user.
	 * @param role - role information
	 * @returns Message of success, error message, success boolean.
	 */
	deleteUserRole: (role: DeleteUserRoleInput) =>
		apiDelete<{
			message: string;
			success: boolean;
		}>(`Users/${role.user_id}/Role?role_id=${role.role_id}&entity_id=${role.entity_id}`),

	/**
	 * @desc Legacy call for creating a territory.
	 * @param territory - territory object.
	 * @returns Success boolean.
	 */
	createTerritory: (territory: Territory) => legacyCall<successResp>(`createTerritory`, { territory }),

	/**
	 * @desc Legacy call for signing up a dealer.
	 * @param form_data - The form data being sent over.
	 * @returns Success boolean or error.
	 */
	dealerSignup: (form_data: DealerSignUpForm) => apiPost<successErrorMessage>(`DealerSignup`, { ...form_data, billing_zip: form_data.billing_address_zip, billing_country_subdivision_id: form_data.billing_address_country_subdivision_id, billing_city: form_data.billing_address_city }),

	/**
	 * @desc Legacy call for deleting an admin email address.
	 * @param email_address_id - the email address id to delete
	 * @returns Success boolean.
	 */
	deleteAdminEmailAddress: (email_address_id: number) =>
		legacyCall<successResp>(`deleteAdminEmailAddress`, { email_address_id }),

	/**
	 * @desc Legacy call for deleting a coupon.
	 * @returns Success boolean.
	 */
	deleteCoupon: (code: string) => legacyCall<successResp>(`deleteCoupon`, { code }),

	/**
	 * @desc Legacy call for deleting a download link.
	 * @param download_link_id - the download link id to delete
	 * @returns Success boolean.
	 */
	deleteDownloadLink: (download_link_id: number) =>
		legacyCall<successResp>(`deleteDownloadLink`, { download_link_id }),

	/**
	 * @desc Legacy call deleting a download category.
	 * @param category_id - the download category id to delete
	 * @returns Success boolean.
	 */
	deleteDownloadLinkCategory: (category_id: number) =>
		legacyCall<successResp>(`deleteDownloadLinkCategory`, { category_id }),

	/**
	 * @desc Legacy call for duplicating a quote.
	 * @param quote_id - the quote id to duplicate
	 * @param quote_type - the quote type to duplicate to - expecting name
	 * @returns Success boolean.
	 * @todo Move this code to the dotnet backend.
	 */
	duplicateQuote: (quote_id: number, quote_type: string) =>
		apiPost<{ new_quote_id: number }>(`Quotes/${quote_id}/Duplicate`, { quote_type }),
		// legacyCall<{ new_quote_id: number }>(`duplicateQuote`, {
		// 	quote_id,
		// 	quote_type,
		// }),

	/**
	 * @deprecated in 1.5.0
	 */
	forceSaveQuote: () => false,

	/**
	 * @desc Legacy call for getting a list of all admin email addresses.
	 * @returns List of admin email addresses.
	 */
	getAdminEmailAddresses: () => legacyCall<{ email_addresses: AdminEmailAddress[] }>(`getAdminEmailAddresses`),

	/**
	 * @deprecated in 1.5.0
	 */
	getCardOnFile: () => false,

	/**
	 * @desc Legacy call for getting a list of all coupons.
	 * @returns List of coupons.
	 */
	getCoupons: () => legacyCall<{ coupons: Coupon[] }>(`getCoupons`),

	/**
	 * @deprecated in 1.5.0
	 */
	getCouponForQuote: () => false,

	/**
	 * @desc Legacy call for getting a list of email templates.
	 * @returns List of email templates.
	 */
	getEmailTemplatesList: () => legacyCall<{ templates: string[] }>(`getEmailTemplatesList`),

	/**
	 * @desc Call for getting a list of all dealers attached to the user.
	 * @returns List of dealers.
	 */
	getDealers: () => apiGet<{ dealers: DealerStore[] }>(`Dealers/Full`),

	/**
	 * @desc Legacy call for getting a list of pending dealers.
	 * @returns List of pending dealers.
	 */
	getPendingDealers: () => legacyCall<{ dealers: Dealer[] }>(`getPendingDealers`),

	/**
	 * @desc Call for getting a list of users attached to a dealer.
	 * @returns List of users.
	 */
	getDealerUsers: (id: number) => apiGet<{ users: User[] }>(`Dealers/${id}/Users`),

	/**
	 * @desc Legacy call for getting information about a distributor
	 * @param distributor_id - the distributor id to get information about
	 * @returns Distributor information.
	 */
	getDistributor: (distributor_id: number) => apiGet<{ distributor: Distributor }>(`distributor/${distributor_id}`),

	/**
	 * @desc Call to retrieve a list of distributors.
	 * @returns List of distributors
	 */
	getDistributorsMeta: () => apiGet<{ distributors: DistributorMeta[] }>(`Distributor`),

	/**
	 * @desc Call to retrieve list of distributor locations meta data.
	 * @returns List of distributor locations
	 */

	getDistributorLocationsMeta: () => apiGet<{ distributor_locations: DistributorLocation[] }>(`distributorlocations`),

	/**
	 * @desc Call to retrieve teh full view model of a distributor location.
	 * @returns List of distributor locations
	 */

	getDistributorLocationFull: (distLocationId: number) =>
		apiGet<{ distributor_location: DistributorLocation[] }>(`distributorlocations/${distLocationId}`),

	/**
	 * @desc Legacy call for getting information about a manufacturer.
	 * @param manufacturer_id - the manufacturer id to get information about
	 * @returns Manufacturer information.
	 */
	getManufacturer: (manufacturer_id: number) =>
		apiGet<{ manufacturer: Manufacturer }>(`Manufacturers/${manufacturer_id}`),

	/**
	 * @desc Legacy call for getting a list of manufacturers.
	 * @returns List of manufacturers.
	 */
	getManufacturers: () => apiGet<{ manufacturers: Manufacturer[] }>(`Manufacturers`),

	/**
	 * @desc Call for getting detailed list of representative information.
	 * @param representative_id - the representative id to get information about
	 * @returns Representative information.
	 */
	getRepresentative: (representative_id: number) =>
		apiGet<{ representative: Representative }>(`Representatives/${representative_id}`),

	/**
	 * @desc Call for getting a list of meta data for representatives.
	 * @returns List of representative meta data.
	 */
	getRepresentatives: () => apiGet<{ representatives: RepresentativeMeta[] }>(`Representatives`),

	/**
	 * @deprecated in 1.10.0
	 * @notes - data is fetched with getReps and sent to redux store. If you need to access any
	 * detailed information about a rep, you can use the getRep function.
	 */
	getRepresentativesMeta: () => false,

	/**
	 * @desc Legacy call for getting a list of categories.
	 * @returns List of categories.
	 */
	getDownloadLinkCategories: () => legacyCall<{ categories: Category[] }>(`getDownloadLinkCategories`),

	/**
	 * @desc Legacy call for getting a list of download links
	 * @returns List of download links.
	 */
	getDownloadLinks: () => legacyCall<{ links: DownloadLink[] }>(`getDownloadLinks`),

	/**
	 * @deprecated in 1.5.0
	 */
	saveQuoteAdminPayment: () => false,

	/**
	 * @deprecated in 1.5.0
	 */
	getManufacturerAcceptedOrders: () => false,

	/**
	 * @deprecated in 1.5.0
	 */
	getManufacturerShippedOrders: () => false,

	/**
	 * @deprecated in 1.5.0
	 */
	getManufacturerSubmittedOrders: () => false,

	/**
	 * @desc Legacy call for getting a list of submitted orders by manufacturer id.
	 * @param manufacturer_id - the manufacturer id to get orders for
	 * @param page_num - the page number to get
	 * @param results_per_page - the number of results per page
	 * @param search_query - the search query to filter by
	 * @param order_by - the order by field
	 * @param sort_direction - the sort direction
	 * @returns List of submitted orders.
	 */
	getSubmittedOrdersByManufacturer: (
		manufacturer_id: number,
		page_num: number,
		results_per_page: number,
		search_query: string,
		order_by: string,
		sort_direction: string
	) =>
		legacyCall<QuoteListResp>(`getSubmittedOrdersByManufacturer`, {
			manufacturer_id,
			page_num,
			results_per_page,
			search_query,
			order_by,
			sort_direction,
		}),

	/**
	 * @desc Legacy call for getting a list of tags.
	 * @returns List of tags.
	 */
	getTags: () =>
		legacyCall<{
			tags: Tag[];
		}>(`getTags`),

	/**
	 * @desc Legacy call for getting a list of quote tags.
	 * @returns List of quote tags.
	 */
	getQuoteTags: (quote_id: number) =>
		legacyCall<{
			tags: Tag[];
		}>(`getQuoteTags`, { quote_id }),

	/**
	 * @desc Returns full user information.
	 * @param user_id - the user id to get information about
	 * @returns User information.
	 */
	getUser: (user_id: number) => apiGet<{ user: User }>(`Users/${user_id}`),

	/**
	 * @desc Returns all accessible users in a list.
	 * @returns List of users (meta).
	 */
	getUsers: () =>
		apiGet<{
			users: UserMeta[];
		}>("Users"),

	/**
	 * @deprecated in 1.10.0 - use getUsers.
	 */
	getUsersList: () => false,

	/**
	 * @desc Legacy call for getting a list of orders in production by manufacturer id.
	 * @param manufacturer_id - the manufacturer id to get orders for
	 * @param page_num - the page number to get
	 * @param results_per_page - the number of results per page
	 * @param search_query - the search query to filter by
	 * @param order_by - the order by field
	 * @param sort_direction - the sort direction
	 * @returns List of orders in production.
	 */
	getInProductionOrdersByManufacturer: (
		manufacturer_id: number,
		page_num: number,
		results_per_page: number,
		search_query: string,
		order_by: string,
		sort_direction: string
	) =>
		legacyCall<QuoteListResp>(`getInProductionOrdersByManufacturer`, {
			manufacturer_id,
			page_num,
			results_per_page,
			search_query,
			order_by,
			sort_direction,
		}),

	/**
	 * @desc Legacy call for getting a list of sent emails.
	 * @param template_name - the template name to filter by
	 * @returns List of sent emails filtered by template_name.
	 */
	getSentEmails: (template_name: string) => legacyCall<{ emails: EmailSent[] }>(`getSentEmails`, { template_name }),

	/**
	 * @desc Legacy call for getting a list of shipped orders by manufacturer id.
	 * @param manufacturer_id - the manufacturer id to get orders for
	 * @param page_num - the page number to get
	 * @param results_per_page - the number of results per page
	 * @param search_query - the search query to filter by
	 * @param order_by - the order by field
	 * @param sort_direction - the sort direction
	 * @returns List of shipped orders.
	 */
	getShippedOrdersByManufacturer: (
		manufacturer_id: number,
		page_num: number,
		results_per_page: number,
		search_query: string,
		order_by: string,
		sort_direction: string
	) =>
		legacyCall<QuoteListResp>(`getShippedOrdersByManufacturer`, {
			manufacturer_id,
			page_num,
			results_per_page,
			search_query,
			order_by,
			sort_direction,
		}),

	/**
	 * @desc Legacy call for getting a list of submitted orders.
	 * @param page_num - the page number to get
	 * @param results_per_page - the number of results per page
	 * @param search_query - the search query to filter by
	 * @param order_by - the order by field
	 * @param sort_direction - the sort direction
	 * @notes This call is used by the manufacturer view specifically.
	 * @returns List of submitted orders
	 */
	getSubmittedOrders: (
		page_num: number,
		results_per_page: number,
		search_query: string,
		order_by: string,
		sort_direction: string,
		filter_tag: string,
		skip_totals: string,
		territory_filter: [],
		dealer_filter: []
	) =>
		legacyCall<QuoteListResp>(`getSubmittedOrders`, {
			page_num,
			results_per_page,
			search_query,
			order_by,
			sort_direction,
			filter_tag,
			skip_totals,
			territory_filter,
			dealer_filter,
		}),

	/**
	 * @desc Legacy call for getting a list not of submitted orders.
	 * @param page_num - the page number to get
	 * @param results_per_page - the number of results per page
	 * @param search_query - the search query to filter by
	 * @param order_by - the order by field
	 * @param sort_direction - the sort direction
	 * @notes This call is used by the manufacturer view specifically.
	 * @returns List of not submitted orders
	 */
	getNotSubmittedOrders: (
		page_num: number,
		results_per_page: number,
		search_query: string,
		order_by: string,
		sort_direction: string,
		filter_tag: string,
		skip_totals: string,
		territory_filter: [],
		dealer_filter: []
	) =>
		legacyCall<QuoteListResp>(`getNotSubmittedOrders`, {
			page_num,
			results_per_page,
			search_query,
			order_by,
			sort_direction,
			filter_tag,
			skip_totals,
			territory_filter,
			dealer_filter,
		}),

	/**
	 * @desc Legacy call for getting a list of in production orders.
	 * @param page_num - the page number to get
	 * @param results_per_page - the number of results per page
	 * @param search_query - the search query to filter by
	 * @param order_by - the order by field
	 * @param sort_direction - the sort direction
	 * @notes This call is used by the manufacturer view specifically.
	 * @returns List of in production orders.
	 */
	getInProductionOrders: (
		page_num: number,
		results_per_page: number,
		search_query: string,
		order_by: string,
		sort_direction: string,
		filter_tag: string,
		skip_totals: string,
		territory_filter: [],
		dealer_filter: []
	) =>
		legacyCall<QuoteListResp>(`getInProductionOrders`, {
			page_num,
			results_per_page,
			search_query,
			order_by,
			sort_direction,
			filter_tag,
			skip_totals,
			territory_filter,
			dealer_filter,
		}),

	/**
	 * @deprecated in 1.5.0
	 */
	getPopulateDashboardProperties: () => false,

	/**
	 * @desc Legacy call for getting a list of shipped orders.
	 * @param page_num - the page number to get
	 * @param results_per_page - the number of results per page
	 * @param search_query - the search query to filter by
	 * @param order_by - the order by field
	 * @param sort_direction - the sort direction
	 * @notes This call is used by the manufacturer view specifically.
	 * @returns List of shipped orders.
	 */
	getShippedOrders: (
		page_num: number,
		results_per_page: number,
		search_query: string,
		order_by: string,
		sort_direction: string,
		filter_tag: string,
		skip_totals: string,
		territory_filter: [],
		dealer_filter: []
	) =>
		legacyCall<QuoteListResp>(`getShippedOrders`, {
			page_num,
			results_per_page,
			search_query,
			order_by,
			sort_direction,
			filter_tag,
			skip_totals,
			territory_filter,
			dealer_filter,
		}),

	/**
	 * @deprecated in 1.5.0
	 */
	getOrders: () => false,

	/**
	 * @desc Legacy call for getting a list of pending orders.
	 * @param page_num - the page number to get
	 * @param results_per_page - the number of results per page
	 * @param search_query - the search query to filter by
	 * @param order_by - the order by field
	 * @param sort_direction - the sort direction
	 * @notes This call is used by the manufacturer view specifically.
	 * @returns List of pending orders.
	 */
	getPendingOrders: (
		page_num: number,
		results_per_page: number,
		search_query: string,
		order_by: string,
		sort_direction: string,
		filter_tag: string,
		skip_totals: string,
		territory_filter: [],
		dealer_filter: []
	) =>
		legacyCall<QuoteListResp>(`getPendingOrders`, {
			page_num,
			results_per_page,
			search_query,
			order_by,
			sort_direction,
			filter_tag,
			skip_totals,
			territory_filter,
			dealer_filter,
		}),

	/**
	 * @deprecated in 1.5.0
	 */
	getCreditDeclinedQuotes: (
		page_num: number,
		results_per_page: number,
		search_query: string,
		order_by: string,
		sort_direction: string,
		filter_tag: string,
		skip_totals: string,
		territory_filter: [],
		dealer_filter: []
	) =>
		legacyCall<QuoteListResp>(`getCreditDeclinedQuotes`, {
			page_num,
			results_per_page,
			search_query,
			order_by,
			sort_direction,
			filter_tag,
			skip_totals,
			territory_filter,
			dealer_filter,
		}),

	/**
	 * @deprecated in 1.5.0
	 */
	calculateQuoteMSRP: () => false,

	/**
	 * @deprecated in 1.5.0
	 */
	calculateShadeMSRPForQuote: () => false,

	/**
	 * @deprecated in 1.5.0
	 */
	updateShadesToSprings: () => false,

	/**
	 * @deprecated in 1.10.0 - use getDealerTermOptions instead
	 */
	getDealerTerms: () => false,

	/**
	 * @returns a list of terms options
	 */
	getDealerTermOptions: () => apiGet<{ terms: TermsOptions[] }>("Dealers/Terms"),

	/**
	 * @deprecated in 1.5.0
	 */
	getQuoteMSRP: () => false,

	/**
	 * @desc Legacy call function for getting a list of quotes.
	 * @param page_num - the page number to get
	 * @param results_per_page - the number of results per page
	 * @param search_query - the search query to filter by
	 * @param order_by - the order by field
	 * @param sort_direction - the sort direction
	 * @returns a list of quotes
	 */
	getQuotes: (
		page_num: number,
		results_per_page: number,
		search_query: string,
		order_by: string,
		sort_direction: string,
		filter_tag: string,
		skip_totals: boolean,
		territory_filter: string,
		dealer_filter: string
	) =>
		legacyCall<QuoteListResp>(`getQuotes`, {
			page_num,
			results_per_page,
			search_query,
			order_by,
			sort_direction,
			filter_tag,
			skip_totals,
			territory_filter,
			dealer_filter,
		}),

	/**
	 * @desc Updated call that gets quote ID's from filter parameters.
	 * @param general_filter - Typically a search attribute - such as the title,
	 * 	notes, references, etc.
	 * @param dealer_ids - the dealer ids to filter by
	 * @param territory_ids - the territory ids to filter by
	 * @param page_number - the page number to get
	 * @param results_per_page - the number of results per page. Defaults to 20.
	 * @param filter_names - the names of the filters to apply (quote status)
	 * @param sort_names - the names of the sorts to apply (updated, created, etc)
	 */
	getQuotesFiltered: (
		general_filter: string,
		dealer_ids: number[],
		territory_ids: number[],
		quote_type_ids: QuoteTypeIds[],
		page_number: number,
		results_per_page = 20,
		filter_names: string[] = [],
		sort_names: string[] = [],
		manufacturer_id = 0
	) =>
		apiPost<QuoteFilteringResponse>("Home/QuoteIdsFiltered", {
			general_filter,
			dealer_ids,
			quote_type_ids,
			territory_ids,
			page_number,
			results_per_page,
			filter_names,
			sort_names,
			manufacturer_id,
		}, {
			timeout: 100000,
		}),

	/**
	 * @desc Call for getting the list of RMAs.
	 * @returns List of RMAs
	 */
	getRMAsByQuoteId: async (quoteId) => {
		try {
			const response = await apiGet<RMAItems[]>(`RMA/${quoteId}`);
			return toCamelCase(response);
		} catch (error) {
			console.error("Error fetching RMAs:", error);
			throw error;
		}
	},

	/**
	 * @desc Call for adding a new RMA.
	 * @returns Created RMA.
	 */
	addRMA: (rma: RMAItems) =>
		apiPost<
			successError & RMAItems
		>(`RMA`, {
			quoteId: rma.quoteId,
			parentRmaId: rma?.parentRmaId,
			parentRmaTitle: rma?.parentRmaTitle,
			rmaTypeId: rma.rmaTypeId,
			rootCauseId: rma.rootCauseId,
			departmentId: rma.departmentId,
			sequenceId: rma.sequenceId,
			parts: rma.parts,
		}),

	/**
	 * @desc Call for editing an RMA.
	 * @returns Updated RMA.
	 */
	editRMA: (rma: Partial<RMAItems>) =>
		apiPut<
			successError & {
				rma;
			}
		>(`RMA/${rma.id}`, { ...rma }),

	/**
	 * @desc Call for removing RMA.
	 * @returns Success or error message.
	 */
	deleteRMA: (rmaId: number) => apiDelete<RMAItems[]>(`RMA/${rmaId}`),

	/**
	 * @desc Call for getting the list of RMA Types.
	 * @returns List of RMA Types, departments, and root causes
	 */
	getRMATypes: () => apiGet<{ rma_types: RMAType[], rma_departments: RMADepartmentType[], rma_root_causes: RMARootCauseType[] }>(`RMA/Types`),

	/**
	 * @desc Call for adding a new RMA Type.
	 * @returns Success or error message.
	 */
	addRMAType: (newRMAType: RMAType) =>
		apiPost<
			successError & {
				new_rma_type: RMAType;
			}
		>(`RMA/Types`, { ...newRMAType }),

	/**
	 * @desc Call for adding a new RMA Type.
	 * @returns Success or error message.
	 */
	archiveRMAType: (rmaTypeId: number) => apiDelete<successError>(`RMA/Types/${rmaTypeId}`),

	/**
	 * @desc Call for adding a new RMA Department.
	 * @returns Success or error message.
	 */
	addRMADepartment: (newRMADepartmentName: string, newDepartmentDescription: string) =>
		apiPost<
			successError & {
				new_rma_department: RMADepartmentType;
			}
		>(`RMA/Departments`, { name: newRMADepartmentName, description: newDepartmentDescription }),
	
	/**
	 * @desc Call for archiving a RMA Department.
	 * @returns Success or error message.
	 */
	archiveRMADepartment: (rmaDepartmentId: number) => apiDelete<successError>(`RMA/Departments/${rmaDepartmentId}`),

	/**
	 * @desc Call for adding a new RMA Root Cause.
	 * @returns Success or error message.
	 */
	addRMARootCause: (newRMARootCauseName: string, newRootCauseDescription: string) =>
		apiPost<
			successError & {
				new_rma_root_cause: RMARootCauseType;
			}
		>(`RMA/RootCauses`, { name: newRMARootCauseName, description: newRootCauseDescription }),

	/**
	 * @desc Call for archiving a RMA Root Cause.
	 * @returns Success or error message.
	 */
	archiveRMARootCause: (rmaRootCauseId: number) => apiDelete<successError>(`RMA/RootCauses/${rmaRootCauseId}`),

	/**
	 * @desc Call for getting full info of a territory.
	 * @param territory_id - the territory ID
	 * @returns Territory object with territory data.
	 */
	getTerritory: (territory_id: number): TransformedTerritoryCall =>
		apiGet<{ territory: IncorrectTerritoryISOCodeKey }>(`Territory/${territory_id}`).then((resp) => {
			if (isFailedApiCall(resp)) throw resp.error;
			const { iso4217_currency_code_buy, iso4217_currency_code_sell, ...rest } = resp.data.territory;

			const correctTerritory = {
				...rest,
				iso_4217_currency_code_buy: iso4217_currency_code_buy,
				iso_4217_currency_code_sell: iso4217_currency_code_sell,
			};

			return {
				...resp,
				data: {
					...resp.data,
					territory: correctTerritory,
				},
			};
		}),

	/**
	 * @desc Call for getting a list of meta data for territories.
	 * @notes This call does not fetch attached entities to said territories - just the territory meta
	 * such as name, ID, etc.
	 * @returns List of territories
	 */
	getTerritoriesMeta: (): TransformedTerritoryMetaCall =>
		apiGet<{ territories: IncorrectTerritoryMetaISOCodeKey[] }>(`Territory/Meta`).then((resp) => {
			if (isFailedApiCall(resp)) throw resp.error;
			const correctedTerritories = resp.data.territories.map((territory) => {
				const { iso4217_currency_code_buy, iso4217_currency_code_sell, ...rest } = territory;

				return {
					...rest,
					iso_4217_currency_code_buy: iso4217_currency_code_buy,
					iso_4217_currency_code_sell: iso4217_currency_code_sell,
				};
			});
			return {
				...resp,
				data: {
					...resp.data,
					territories: correctedTerritories,
				},
			};
		}),

	/**
	 * @deprecated in 1.5.0
	 */
	getUserInfo: () => false,

	/**
	 * @desc Legacy call for grabbing a users name.
	 * @param user_id - the user ID
	 * @returns User name or possible error
	 */
	getUserName: (user_id: number) => legacyCall<{ user_name: string } & possibleError>(`getUserName`, { user_id }),

	/**
	 * @desc Legacy call for getting a list of last updated quotes.
	 * @param page_num - the page number to get
	 * @param results_per_page - the number of results per page
	 * @param search_query - the search query to filter by
	 * @param order_by - the order by field
	 * @param sort_direction - the sort direction
	 * @param filter_tag - the filter tag to filter by
	 * @param skip_totals - is this checking shipped orders? (boolean)
	 * @param territory_filter - the territory filter
	 * @param dealer_filter - the dealer filter
	 * @returns List of last updated quotes
	 */

	getLastUpdatedQuotes: (
		page_num: number,
		results_per_page: number,
		search_query: string,
		order_by: string,
		sort_direction: string,
		filter_tag: string,
		skip_totals: boolean,
		territory_filter: string,
		dealer_filter: string
	) =>
		legacyCall<QuoteListResp>(`getLastUpdatedQuotes`, {
			page_num,
			results_per_page,
			search_query,
			order_by,
			sort_direction,
			filter_tag,
			skip_totals,
			territory_filter,
			dealer_filter,
		}),

	/**
	 * @desc Legacy call for populating the dashboard.
	 * @param quote_id - the quote ID to populate
	 */
	populateDashboard: (quote_id: number) => legacyCall<any>(`populateDashboard`, { quote_id }),

	/**
	 * @desc Legacy call for populating the dashboard.
	 * @param quote_id - the quote ID to populate
	 */
	populateDashboardV2: (quote_id: number) => apiPost<{
		populate_dashboard_input: any;
		populate_dashboard_output: any;
	}>(`nice/popdash/${quote_id}`),

	/**
	 * @desc Legacy call for getting a quote address snapshot.
	 * @param property_name - the property name to get
	 * @param dealer_id - the dealer attached to said property
	 * @notes This call i used to check if a property already exists on the dashboard.
	 * @returns Address snapshot
	 */
	getQuoteAddressSnapshot: (property_name: string, dealer_id: number) =>
		legacyCall<{ address_snapshot: Address }>(`getQuoteAddressSnapshot`, {
			property_name,
			dealer_id,
		}),

	/**
	 * @deprecated in 1.5.0
	 */
	uploadFederalCert: () => false,

	/**
	 * @desc Legacy call for getting a list of previous quote actions.
	 * @param quote_id - the quote ID to get actions for
	 * @returns List of previous quote actions.
	 */
	getQuoteHistory: (quote_id: number) => legacyCall<{ entries: QuoteAction[] }>(`getQuoteHistory`, { quote_id }),

	/**
	 * @desc Legacy call for getting a list of previous quote actions.
	 * @param quote_id - the quote ID to get actions for
	 * @returns List of previous quote actions on Accessories.
	 */
	getQuoteHistory_accessories: (quote_id: number) => legacyCall<{ entries: QuoteAction[] }>(`getQuoteHistory_accessories`, { quote_id }),

	/**
	 * @desc Legacy call for getting a list of previous quote actions.
	 * @param quote_id - the quote ID to get actions for
	 * @returns List of previous quote actions on Hardware.
	 */
	getQuoteHistory_hardware: (quote_id: number) => legacyCall<{ entries: QuoteAction[] }>(`getQuoteHistory_hardware`, { quote_id }),

	/**
	 * @desc Legacy call for getting a list of previous quote actions.
	 * @param quote_id - the quote ID to get actions for
	 * @returns List of previous quote actions on general actions.
	 */
	getGeneralQuoteActionsHistory: (quote_id: number) => legacyCall<{ entries: QuoteAction[] }>(`getGeneralQuoteActionsHistory`, { quote_id }),

	/**
	 * @desc Fetches a list of all indoor fabrics that are discontinued.
	 * @returns A list of discontinued indoor fabrics.
	 */
	//getFabricsIndoorDiscontinued: () =>
	//	apiGet<{ fabrics: FabricDiscontinued[] }>(`Fabrics/IndoorDiscontinued`),

	/**
	 * @desc Fetches a list of all indoor fabrics that are discontinued.
	 * @returns A list of discontinued indoor fabrics.
	 */
	//getFabricsOutdoorDiscontinued: () =>
	//	apiGet<{ fabrics: FabricDiscontinued[] }>(`Fabrics/OutdoorDiscontinued`),


	/**
	 * @desc Fetches a list of all indoor fabrics that are discontinued.
	 * @returns A list of discontinued indoor fabrics.
	 */
	getFabricsIndoorDiscontinuedAllowedByQuoteId: (quote_id) =>
		apiGet<FabricAllowedResponse[]>(`FabricsAllowed/${quote_id}/FabricIndoorWithAllowedStatusSelected`),

	/**
	 * @desc Fetches a list of all indoor fabrics that are discontinued.
	 * @returns A list of discontinued indoor fabrics.
	 */
	getFabricsPsOutdoorDiscontinuedAllowedByQuoteId: (quote_id) =>
		apiGet<FabricAllowedResponse[]>(`FabricsAllowed/${quote_id}/FabricPsOutdoorWithAllowedStatusSelected`),

	/**
	 * @desc Checks if a fabric exists for a specific quote.
	 * @param quoteId The ID of the quote.
	 * @param fabricId The ID of the fabric.
	 * @returns A boolean indicating if the fabric exists in the quote.
	 */
	doesFabricExistInQuote: (quoteId: number, fabricId: number) =>
		apiGet<{ exists: boolean }>(`/Fabrics/${quoteId}/${fabricId}/Exists`),

	/**
	 * @desc Adds a new FabricAllowed record for a specific quote and fabric.
	 * @param input Object containing quoteId, fabricId, and userId.
	 * @returns Response indicating success or failure.
	 */
	postAddFabricAllowed: (input: { quoteId: number; fabricId: number; userId: number }) =>
		apiPost<{ success: boolean; message: string }>(`FabricsAllowed/`, input),


	/**
	 * @desc Disables an existing FabricAllowed record by quoteId and fabricId.
	 * @param input Object containing quoteId, fabricId, and userId.
	 * @returns Response indicating success or failure.
	 */
	putDisableFabricAllowed: (input: { quoteId: number; fabricId: number; userId: number }) =>
		apiPut<{ success: boolean; message: string }>(`FabricsAllowed/disable`, input),


	/**
	 * @deprecated in 1.5.0
	 */
	upsertQuoteToZoho: () => false,

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	fiveMinuteRoutine: () => false,

	/**
	 * @deprecated in 1.5.0
	 */
	getCreditCards: () => false,

	/**
	 * @desc Legacy call for getting a zoho link for adding a credit card.
	 * @returns Zoho link for adding a credit card.
	 */
	getCreditCardLink: () => legacyCall<string>(`getCreditCardLink`),

	/**
	 * @desc Legacy call for getting a list of cards and bank accounts on file.
	 * @returns List of cards and bank accounts on file.
	 */
	getPaymentMethods: () => legacyCall<{ cards: BankCard[], accounts: BankAccount[] }>(`getPaymentMethods`),

	/**
	 * @deprecated in 1.5.0
	 */
	bulkDealerUpdate: () => false,

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	upsertSpecificDealers: () => false,

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	bulkDistributorUpdate: () => false,

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	bulkRepresentativeUpdate: () => false,

	/**
	 * @desc Legacy call for getting list of channel types.
	 * @returns List of channel types.
	 */
	listChannelTypes: () => legacyCall<{ channels: Channel[] }>(`listChannelTypes`),

	/**
	 * @desc Call for getting a list of buying groups.
	 * @returns List of buying groups.
	 */
	getBuyingGroups: () => apiGet<{ buying_groups: BuyingGroupMeta[] }>(`Organization/BuyingGroups`),

	/**
	 * @deprecated in 1.10.0
	 * @notes Use getBuyingGroups instead.
	 */
	listBuyingGroupsTypes: () => false,

	/**
	 * @desc Call for checking if a dealer name exists.
	 * @param dealer_name - the dealer name to check
	 * @param dealer_id - the dealer ID to check
	 * @returns Name Exists boolean.
	 */
	dealerNameExists: (dealer_name: string, dealer_id: number) =>
		apiPost<DealerNameExistsResponse>(`Dealers/NameExists`, {
			dealer_name,
			dealer_id,
		}),

	/**
	 * @desc Legacy call for modifying accessory quantity.
	 * @param quote_id - the quote ID to modify
	 * @param accessory_sku - the accessory SKU to modify
	 * @param quantity - the new quantity
	 * @returns Success boolean.
	 */
	changeAccessoryQuantity: (quote_id: number, accessory_sku: string, quantity: number) =>
		legacyCall<successResp>(`changeAccessoryQuantity`, {
			quantity,
			quote_id,
			accessory_sku,
		}),

	/**
	 * @desc Legacy call for modifying fabric sample quantity.
	 * @param quote_id - the quote ID to modify
	 * @param fabric_id - the fabric sample id to modify
	 * @param quantity - the new quantity
	 * @param name - the name of the fabric sample to modify
	 * @returns Success boolean.
	 */
	changeFabricSampleQuantity: (quote_id: number, fabric_id: string, quantity: string, name: string) =>
		legacyCall<successResp>(`changeFabricSampleQuantity`, {
			quote_id,
			fabric_id,
			quantity,
			name,
		}),

	/**
	 * @desc Legacy call for getting the number of quotes with filters.
	 * @param search_query - the search query to filter by
	 * @param filter_tag - the filter tag to filter by
	 * @param territory_filter - the territory filter to filter by
	 * @param dealer_filter - the dealer filter to filter by
	 * @returns A list of counts per category.
	 */
	getQuoteCounts: (search_query: string, filter_tag?: string, territory_filter?: string, dealer_filter?: string) =>
		legacyCall<{ counts: QuoteCounts }>(`getQuoteCounts`, {
			search_query,
			filter_tag,
			territory_filter,
			dealer_filter,
		}),

	/**
	 * @deprecated in 1.5.0
	 */
	getRecentNotifications: () => false,

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	ensureBigCommerceOrders: () => false,

	/**
	 * @desc Legacy call for getting a list of all notifications.
	 * @returns List of all notifications & the last time they were viewed.
	 */
	getAllNotifications: () =>
		legacyCall<{
			notifications: Notification[];
			last_read: Date;
		}>(`getAllNotifications`),

	/**
	 * @deprecated in 1.5.0
	 */
	getMyBillingAddresses: () => false,

	/**
	 * @desc Legacy call for getting a list of property addresses attached to the user.
	 * @returns List of property addresses attached to the user.
	 */
	getMyPropertyAddresses: (page_num, results_per_page, search_query) =>
		legacyCall<MyAddressResp>(`getMyPropertyAddresses`, {
			page_num,
			results_per_page,
			search_query,
		}),

	/**
	 * @desc Legacy call for getting a list of shipping addresses attached to the user.
	 * @returns List of shipping addresses attached to the user.
	 */
	getMyShippingAddresses: (page_num, results_per_page, search_query) =>
		legacyCall<MyAddressResp>(`getMyShippingAddresses`, {
			page_num,
			results_per_page,
			search_query,
		}),

	/**
	 * @deprecated in 1.5.0
	 */
	isQuoteLocked: () => false,

	/**
	 * @desc Legacy call for calculating assembly price.
	 * @param assemblies - the assembly data to send
	 * @param quote_id - the quote ID of the assembly
	 * @returns Assembly Price - typing can be found in powerShadesApiTypes.d.ts.
	 */
	calculateAssemblyPrice: (assemblies: AssemblyShadePayload, quote_id: number) =>
		legacyCall<AssemblyPrice>(`calculateAssemblyPrice`, {
			assemblies,
			quote_id,
		}),

	/**
	 * @desc Current call for calculating the MSRP of an Assembly View Model
	 * @param assembly - the assembly view model to calculate
	 * @param quote_id - the quote ID of the assembly
	 * @param type - the type of assembly to calculate
	 * @returns Assembly Price - Which has the price and errors.
	 */
	calculateAssemblyPriceNew: (assembly: AssemblyViewModel, quote_id: number, type: string) =>
		apiPost<{
			msrp: number;
			errors: ShadeError[];
		}>(`Quotes/${quote_id}/GetAssemblyPrice/${type}`, assembly),
	
	/**
	 * @desc Call for calculating assembly price.
	 * @param sequenceId - the sequence Id of the assembly
	 * @param quote_id - the quote ID of the assembly
	 * @returns GenericAssemblyCostBreakdown - typing can be found in powerShadesApiTypes.d.ts.
	 */
	getAssemblyCostBreakdown: (quote_id: number, sequenceId: number) =>
		apiGet<{
			cost_breakdown: GenericAssemblyCostBreakdown;
			part_list: ShadePart[];
		}>(`Quotes/${quote_id}/Assembly/${sequenceId}/CostBreakdown`),
	
	/*
		@desc Calls for creating a new Assembly
	*/
	postPSOUTDOORCreateAssembly: (quote_id: number, assy: AssemblyViewModel) =>
		apiPost<{
			success: boolean;
			sequence_id: number;
			assembly: AssemblyViewModel;
		}>(`Quotes/${quote_id}/Assembly/psoutdoor`, assy),

	postINDOORCreateAssembly: (quote_id: number, assy: AssemblyViewModel) =>
		apiPost<{
			success: boolean;
			sequence_id: number;
			assembly: AssemblyViewModel;
		}>(`Quotes/${quote_id}/Assembly/indoor`, assy),
	postOUTDOORCreateAssembly: (quote_id: number, assy: AssemblyViewModel) =>
		apiPost<{
			success: boolean;
			sequence_id: number;
			assembly: AssemblyViewModel;
		}>(`Quotes/${quote_id}/Assembly/outdoor`, assy),

	/*
		@desc Call for updating a list of assemblies on the quote.
		*/
	updateAssemblies: (quote_id: number, assemblies: AssemblyViewModel[]) =>
		apiPut<{
			success: boolean;
			assemblies: AssemblyViewModel[];
			reprice_needed: boolean;
		}>(`Quotes/${quote_id}/AssemblyMassUpdate`, assemblies),

	/*
		@desc Call for updating an Assembly
	*/
	updateAssembly: (quote_id: number, sequenceId: number, assy: AssemblyViewModel) =>
		apiPut<{
			success: boolean;
			assembly: AssemblyViewModel;
			reprice_needed: boolean;

		}>(`Quotes/${quote_id}/Assembly/${sequenceId}`, assy),

	/**
	 * @desc Call for repricing an assembly.
	 * @param sequenceId - the sequence id of the assembly being repriced
	 * @param quote_id - the quote ID of the assembly
	 * @returns void
	 */
	repriceAssembly: (quote_id: number, sequenceId: number) =>
		apiPut<void>(`Quotes/${quote_id}/Assembly/${sequenceId}/Reprice`),

	/**
	 * @desc Call for telling the backend to reprice the quote.
	 * @param quote_id - the quote ID to reprice
	 * @returns Success boolean.
	 */
	repriceQuote: (quote_id: number) =>
		apiPut<successResp>(`Quotes/${quote_id}/RepriceQuoteOnly`),

	/**
	 * @desc Legacy call for calculating and saving an assembly.
	 * @param quote - the quote object
	 * @param quote_id - the quote ID of the assembly
	 * @param no_repricing - override to just save the assembly and not reprice
	 * @returns SaveAssemblyResp - typing can be found in powerShadesApiTypes.d.ts.
	 */
	calculateAndSaveAssemblyPrice: (quote: Quote, quote_id: number, no_repricing: boolean) =>
		legacyCall<SaveAssemblyResp>(`calculateAndSaveAssemblyPrice`, {
			quote,
			quote_id,
			no_repricing,
		}),

	/**
	 * @desc Legacy call for deleting an assembly(s).
	 * @param quote_id - the quote ID of the assembly
	 * @param sequence_ids - the sequence IDs of the assembly to delete
	 * @returns Success boolean.
	 */
	DeleteAssembly: (quote_id: number, sequence_ids: number[]) =>
		legacyCall<successResp>(`DeleteAssembly`, {
			quote_id,
			sequence_ids,
		}),

	/**
	 * @desc Call for deleting requested assembly(s).
	 * @param quote_id - the quote ID of the assembly
	 * @param sequence_ids - the sequence IDs of the assembly(s) to delete
	 * @returns Success boolean.
	 */
	deleteAssemblies: (quote_id: number, sequence_ids: number[]) =>
		apiPost<successResp>(`Quotes/${quote_id}/DeleteAssemblies`, {
			sequence_ids,
		}),

	/**
	 * @desc Call for repricing requested assembly(s).
	 * @param quote_id - the quote ID of the assembly
	 * @param sequence_ids - the sequence IDs of the assembly(s) to delete
	 * @returns Success boolean.
	 *    public class RepriceAssemblyInput

	 */
	repriceAssemblies: (quote_id: number, sequence_ids: number[], acc_line_numbers: number[]) =>
		apiPut<successResp>(`Quotes/${quote_id}/Reprice`, {
			sequence_ids,
			accessory_line_item_numbers: acc_line_numbers
		}),
	
	/**
	 * @desc Call for repricing each quote line item.
	 * @param quote_id - the quote ID of the assembly
	 * @returns Success boolean.
	 *    public class RepriceAssemblyInput

	 */
	repriceWholeQuote: (quote_id: number) =>
		apiPut<successResp>(`Quotes/${quote_id}/Reprice/All`),
		
	/**
	 * @desc Call for duplicating an assembly.
	 * @param quote_id - the quote ID of the assembly
	 * @param sequence_id - the sequence ID of the assembly to duplicate
	 * @returns AssemblyId, sequenceId, and a next object.
	 */

	duplicateAssembly: (quote_id: number, sequence_id: number, DuplicateAssemblyInputs?: DuplicateAssemblyInputs) =>
		apiPut<{ new_assembly: AssemblyViewModel, inputs: DuplicateAssemblyInputs }>(`Quotes/${quote_id}/Assembly/${sequence_id}/Duplicate`, DuplicateAssemblyInputs ?? {}),
			
	/**
	 * @desc Call for duplicating an assembly.
	 * @param quote_id - the quote ID of the assembly
	 * @param sequence_ids - the sequence ID's of the assemblies to duplicate
	 * @returns Current List of assemblies.
	 */

	duplicateAssemblies: (quote_id: number, sequence_ids: number[], duplicate_count: number) =>
		apiPut<{ assemblies: AssemblyViewModel[] }>(`Quotes/${quote_id}/DuplicateMany`, { sequence_ids, new_assembly_count: duplicate_count }),

	/**
	 * @desc Legacy call for adding an assembly to a quote.
	 * @param quote_id - the quote ID of the assembly
	 * @param shade_type - the type of shade to add to the quote.
	 * @returns AssemblyId, sequenceId, and a next object.
	 * @todo - next object typing
	 */
	AddAssembly: (quote_id: number, shade_type: string) =>
		legacyCall<{ assemblyId: number, sequenceId: number, Next: any }>(`AddAssembly`, {
			quote_id,
			shade_type,
		}),

	/**
	 * @deprecated in 1.5.0
	 */
	loadQuote: (quote_id: number) =>
		legacyCall<any>(`loadQuote`, {
			quote_id,
		}),

	/**
	 * @desc Legacy call for updating a zoho invoice.
	 * @param quote_id - the quote ID of the assembly
	 * @returns Any.
	 * @todo - type response
	 */
	updateZohoInvoice: (quote_id: number) => legacyCall<any>(`updateZohoInvoice`, { quote_id }),

	/**
	 * @desc Legacy call for loading a quote.
	 * @param quote_id - the quote ID to load
	 * @returns Quote object. Typing can be found in powerShadesApiTypes.d.ts.
	 */
	loadQuoteTwo: (quote_id: number) => legacyCall<Quote>(`loadQuoteTwo`, { quote_id }),

	/**
	 * @desc Call for retrieving quote meta data from a list of quote ids.
	 * @param quote_ids - the quote IDs to retrieve meta data for
	 * @returns Array of QuoteMetaData.
	 */
	loadQuoteMetaData: (quote_ids: number[]) =>
		apiPost<{ quotes: QuoteMetaData[], success: boolean }>(`Home/MetaQuoteData`, quote_ids),

	/**
	 * @desc Legacy call for duplicating a shade.
	 * @param quote_id - the quote ID to duplicate
	 * @param new_shade_info - the new shade info to duplicate
	 */
	duplicateShade: (new_shade_info: NewShadeInfo, quote_id: number) =>
		legacyCall<{ sequenceId: number, assemblyId: number }>(`duplicateShade`, {
			new_shade_info,
			quote_id,
		}),

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	oneHourTimer: () => false,

	/**
	 * @desc Legacy call that grabs the invoice data from Zoho using the quote ID.
	 * @todo What is returned here? It's an object, but what's its shape?
	 */
	getInvoiceData: (quote_id: number) => legacyCall<any>(`getInvoiceData`, { quote_id }),

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	makePayment: () => false,

	/**
	 * @deprecated in 1.5.0
	 * @todo Remove from front end
	 * @desc Legacy call that grabs pricing data? Looking through PHP, it looks like this was made
	 * to retrieve pricing info for zoho parts, but it's an empty function in the PHP codebase.
	 */
	getPricingData: () => legacyCall<any>(`getPricingData`, {}),

	/**
	 * @desc Legacy call that marks a quote as paid.
	 * @todo Remove this from the front end (marking as paid is a no-no [it's time to move on])
	 * @params quote_id - The quote ID to mark as paid
	 */
	markAsPaid: (quote_id: number) => legacyCall<successResp>(`markAsPaid`, { quote_id }),

	/**
	 * @desc Legacy call that declines terms on a quote and requires credit card payment.
	 * @params quote_id - The quote ID to decline terms on
	 */
	markRequirePayment: (quote_id: number) => legacyCall<successResp>(`markRequirePayment`, { quote_id }),

	/**
	 * @desc Legacy call that begins production on an order.
	 * @params quote_id - The quote ID to begin production on.
	 */
	manufacturerAcceptOrder: (quote_id: number) => legacyCall<successResp>(`manufacturerAcceptOrder`, { quote_id }),

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	removeDealerFromQuote: () => false,

	/**
	 * @desc Legacy call that checks and saves errors on a quote.
	 * @params quote_id - The quote ID to check and save errors on.
	 * @params errors - The errors to save
	 * @todo Shape out the errors object (I believe it's an array of objects that have an
	 * 'error' key and a 'sequence_id' key)
	 */
	checkAndSaveErrors: (quote_id: number, errors: any) =>
		legacyCall<successError>(`checkAndSaveErrors`, {
			quote_id,
			errors,
		}),

	/**
	 * @desc Legacy call that removes a user from a dealer.
	 * @params user_id - The user ID to remove from the dealer
	 * @params entity_id - The entity (dealer) ID to remove the user from
	 */
	removeUserFromDealer: (user_id: number, entity_id: number) =>
		legacyCall<successError>(`removeUserFromDealer`, {
			user_id,
			entity_id,
		}),

	/**
	 * @desc Legacy call that removes a user from a distributor.
	 * @params user_id - The user ID to remove from the dealer
	 * @params distributor_id - The distributor ID to remove the user from
	 */
	removeUserFromDistributor: (user_id: number, distributor_id: number) =>
		legacyCall<successError>(`removeUserFromDistributor`, {
			user_id,
			distributor_id,
		}),

	/**
	 * @desc Legacy call that removes a user from a distributor.
	 * @params user_id - The user ID to remove from the dealer
	 * @params representative_id - The representative ID to remove the user from
	 */
	removeUserFromRepresentative: (user_id: number, representative_id: number) =>
		legacyCall<successError>(`removeUserFromRepresentative`, {
			user_id,
			representative_id,
		}),

	/**
	 * @desc Call that gets a list of available roles.
	 * @returns Array of roles.
	 */
	getRoles: () => apiGet<{ roles: Role[] }>("Auth/Roles"),

	// /**
	//  * @desc Call for upserting (updating/inserting) a user role.
	//  * @params user_id - The user ID to upsert the role for
	//  * @params email - The email of the user to upsert the role for
	//  * @params role_name - The role name to upsert
	//  * @params entity_id - The entity ID to upsert the role for
	//  * @params user_name - The user name to upsert the role for
	//  * @returns Success/error object
	//  */
	// upsertUserRole: (
	// 	user_id: number,
	// 	email: string,
	// 	role_name: string,
	// 	entity_id: number,
	// 	user_name: string
	// ) =>
	// 	apiPut<successError>('Auth/UpsertUserRole', {
	// 		user_id,
	// 		email,
	// 		role_name,
	// 		entity_id,
	// 		user_name,
	// 	}),

	/**
	 * @desc Call that checks if an order is in need of a credit card.
	 * @params quote_id - The quote ID to check if a credit card is needed.
	 */
	needsCreditCard: (quote_id: number) => apiGet<{ is_in_need_of_cc: boolean }>(`Quotes/${quote_id}/NeedsCreditCard`),

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	saveQuote: () => false,

	/**
	 * @desc Legacy call that saves an address to a quote.
	 * @params quote_id - The quote ID to save the address to
	 * @params address - The address to save
	 * @params address_type - The type of address to save (shipping or installation)
	 * @todo Shape out the address object
	 */
	saveQuoteAddress: (quote_id: number, address: any, address_type: string) =>
		legacyCall<any>(`saveQuoteAddress`, {
			quote_id,
			address,
			address_type,
		}),

	/**
	 * @desc Call that updates the notes on a quote.
	 * @params quoteId - The quote ID to save
	 * @params notes - The JSON data to save.
	 * @returns Success response
	 */
	updateQuoteNotes: (quoteId: number, notes: string) =>
		apiPut<successResp>(`Quotes/${quoteId}/Notes`, {
			notes: notes
		}),

	/**
	 * @desc Call that updates the data on a quote.
	 * @params quoteId - The quote ID to save
	 * @params quoteViewModel - The JSON data to save.
	 * @returns Success response
	 */
	updateQuoteVM: (quoteId: number, quoteViewModel: QuoteVM) =>
		apiPut<successResp>(`Quotes/${quoteId}/FromViewModel`, quoteViewModel),

	/**
	 * @desc Legacy call that saves a quote.
	 * @params quote_id - The quote ID to save
	 * @params quoteJson - The JSON data to save.
	 * @todo Shape out the quoteJson object
	 * @returns Success response with the quote address
	 */
	saveQuoteTwo: (quoteJson: any, quote_id: number) =>
		legacyCall<any>(`saveQuoteTwo`, {
			quoteJson,
			quote_id,
		}),

	/**
	 * @desc Legacy call that creates a quote.
	 * @params territory_id - The territory ID to create the quote in
	 * @params quote_type_id - The quote type ID to create the quote as
	 * @returns Success response with quote ID
	 */
	createQuote: (territory_id: number, quote_type_id: number) =>
		legacyCall<any>(`createQuote`, {
			territory_id,
			quote_type_id,
		}),

	/**
	 * @desc Legacy call that sends out dealer invite link to an array of emails.
	 * @params signup_link_query - The signup link query to send out
	 * @params recipients - The emails to send the link to
	 * @returns Success response / Error
	 */
	sendDealerInvite: (signup_link_query: string, recipients: string[]) =>
		legacyCall<successError>(`sendDealerInvite`, {
			signup_link_query,
			recipients,
		}),

	/**
	 * @desc Legacy call that sets the shipped status of an individual accessory.
	 * @params quote_id - The quote ID to set the accessory shipped status on
	 * @params accessory_id - The accessory ID to set the shipped status on
	 * @status shipped - The status to set
	 * @returns Success response
	 */
	setAccessoryMfgStatus: (quote_id: number, accessory_id: number, status: string) =>
		legacyCall<successResp>(`setAccessoryMfgStatus`, {
			quote_id,
			accessory_id,
			status,
		}),

	/**
	 * @desc Legacy call that sets a status (active/deactivated) of a dealer.
	 * @params dealer_id - The dealer ID to set the status on
	 * @params status - The status to set
	 * @returns Success response
	 */
	setDealerStatus: (dealer_id: number, status: string) =>
		legacyCall<successError>(`setDealerStatus`, {
			dealer_id,
			status,
		}),

	/**
	 * @desc Legacy call that sets quote tags on a quote.
	 * @params quote_id - The quote ID to set the tags on
	 * @params tags - The tags to set
	 * @todo Type tags. I can't tell what it is based off our current code.
	 */
	setQuoteTags: (quote_id: number, tags: any) =>
		legacyCall<successError>(`setQuoteTags`, {
			quote_id,
			tags,
		}),

	/**
	 * @desc Legacy call that sets the assembly status of an individual shade.
	 * @params quote_id - The quote ID to set the shade assembly status on
	 * @params shade_id - The shade ID to set the assembly status on
	 * @status status - The status to set
	 * @returns Success response / Error
	 */
	setShadeAssemblyMfgStatus: (quote_id: number, shade_id: number, status: string) =>
		legacyCall<successError>(`setShadeAssemblyMfgStatus`, {
			quote_id,
			shade_id,
			status,
		}),

	/**
	 * @desc Legacy call that marks a quote as shipped.
	 * @params quote_id - The quote ID to mark as shipped
	 * @params carrier_name - The carrier name to mark the quote as shipped with
	 * @params tracking_number - The tracking number of the shipment
	 * @returns Success response
	 */
	shipOrder: (quote_id: number, carrier_name: string, tracking_number: string) =>
		legacyCall<successResp>(`shipOrder`, {
			quote_id,
			carrier_name,
			tracking_number,
		}),

	/**
	 * @desc Call that gets any shipments attached to a quote.
	 * @params quoteId - The quote ID to get shipments for
	 * @returns Success response with shipments
	 */
	getQuoteShipments: (quoteId: number) =>
		apiGet<{ quote_shipments: QuoteShipment[] }>(`Quotes/${quoteId}/ShippingModel`),

	/**
	 *
	 */
	AttemptFixFromZoho: (quote_id: number) => apiPost<successResp>(`Quotes/${quote_id}/Shipping/FixFromZoho`),

	/**
	 * @desc Legacy call that runs if a PowerShades Admin unlocks a quote past it's unlockable point.
	 * @params quote_id - The quote ID to unlock
	 * @params reason - The reason for unlocking the quote
	 * @returns Success response
	 */
	unlockedQuote: (quote_id: number, reason: string) =>
		legacyCall<successResp>(`unlockedQuote`, {
			quote_id,
			reason,
		}),

	/**
	 * @desc Legacy call that updates an admin's email address (received emails)
	 * @params email_address_id - The email address ID to update
	 * @params receives_dealer_signup_info - Whether or not the admin receives dealer signup info
	 * @params receives_order_conversion_emails - Whether or not the admin receives order conversion
	 * emails
	 * @returns Success response
	 */
	updateAdminEmailAddress: (
		email_address_id: number,
		receives_dealer_signup_info: boolean,
		receives_order_conversion_emails: boolean
	) =>
		legacyCall<successResp>(`updateAdminEmailAddress`, {
			email_address_id,
			receives_dealer_signup_info,
			receives_order_conversion_emails,
		}),

	getDealerHistory: (dealer_id: number) => apiGet<{ history: EntityHistoryView[] }>(`Dealers/${dealer_id}/History`),

	/**
	 * @desc Call that updates a dealer.
	 * @params dealerId - The dealer ID to update
	 * @params dealer - The dealer object to update
	 * @returns Success response / Error
	 */
	updateDealer: (dealerId: number, dealer: Dealer) =>
		apiPut<successError & { dealer: Dealer }>(`Dealers/${dealerId}`, dealer),

	/**
	 * @desc Call that updates a dealer's tax info.
	 * @params dealerId - The dealer ID to update
	 * @params dealer - The dealer object to update
	 * @returns Success response / Error
	 */
	updateDealerTaxInfo: (dealerId: number, dealer) => {
		return apiPut<successError & { dealer: Partial<Dealer> }>(`Dealers/${dealerId}/Tax`, dealer);
	},

	/**
	 * @desc Call that updates a dealer.
	 * @params newDistributorLocation - The distributor location object to add
	 * @returns Success response / Error
	 */
	addDistributorLocation: (newDistributorLocation: AddDistributorLocationInput) =>
		apiPost<successError & { distributor_location: DistributorLocation }>(
			`DistributorLocations`,
			newDistributorLocation
		),

	/**
	 * @desc Call that updates a distributor.
	 * @params distributorId - The distributor ID to update
	 * @params distributor - The distributor object to update
	 * @returns Success response / Error
	 */
	updateDistributor: (distributor_id: number, distributor: Distributor) =>
		apiPut<successError & { distributor: Distributor }>(`Distributor/${distributor_id}`, distributor),

	/**
	 * @desc Call that updates a distributor location.
	 * @params distributorLocationId - The distributor location ID to update
	 * @params distributor - The distributor object to update
	 * @returns Success response / Error
	 */
	updateDistributorLocation: (distributorLocationId: number, distributorLocation: DistributorLocation) =>
		apiPut<successError & { distributor_location: DistributorLocation }>(
			`DistributorLocations/${distributorLocationId}`,
			distributorLocation
		),

	/**
	 * @desc Legacy call that updates a manufacturer.
	 * @params manufacturer_id - The manufacturer ID to update
	 * @params manufacturer - The manufacturer object to update
	 * @todo Shape out the manufacturer object
	 * @returns Success response / Error
	 */
	updateManufacturer: (manufacturer_id: number, manufacturer: any) =>
		legacyCall<successError>(`updateManufacturer`, {
			manufacturer_id,
			manufacturer,
		}),

	/**
	 * @desc Call using a quote id to get the effective buyer's credit limit and outstanding receivables.
	 * @params quoteId - The quote id we need the buyer credit limit of.
	 * @returns The credit limit check, which includes all values nescessary to check if we need a credit check.
	 */
	getCreditLimit: (quoteId: number) =>
		apiGet<{
			credit_check: GetCreditLimitCheck;
		}>(`quotes/${quoteId}/CreditCheck`),

	/**
	 * @desc Legacy call that updates a representative.
	 * @params representative_id - The representative ID to update
	 * @params manufacturer - The representative object to update
	 * @todo Shape out the representative object
	 * @returns Success response / Error
	 */
	updateRepresentative: (representative_id: number, representative: Representative) =>
		apiPut<successError & { representative: Representative }>(
			`Representatives/${representative_id}`,
			representative
		),

	/**
	 * @desc Legacy call that updates a territory.
	 * @params territory_id - The territory ID to update
	 * @params territory - The territory object to update
	 * @returns Success response / Error
	 */
	updateTerritory: (territory_id: number, territory: Territory) =>
		legacyCall<successError>(`updateTerritory`, {
			territory_id,
			territory,
		}),

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	updateJWT: () => false,

	/**
	 * @desc Legacy call that updates if a user has read a notification.
	 * @returns Success response
	 */
	userReadNotifications: () => legacyCall<successResp>(`userReadNotifications`),

	/**
	 * @desc Legacy call that returns a list of active users.
	 * @returns Success response
	 */
	usersOnline: () => legacyCall<any>(`usersOnline`),

	/**
	 * @desc Legacy call that checks if a quote exists.
	 * @params quote_id - The quote ID to check
	 * @returns Success response
	 */
	quoteExists: (quote_id: number) => legacyCall<ExistsResp>(`quoteExists`, { quote_id }),

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	viewingPage: () => false,

	/**
	 * @desc Legacy call for... doing a lot of Zoho tickling.
	 * @note Do not delete this function - it takes care of updating the accessories in Zoho.
	 * This call has a typo in the back end and is meant to be spelled as Acessories.
	 * @returns acc_got (list of accessories) & acc_made (a list of accessories that were added)
	 */
	updateAcessories: () => // cSpell:disable-line
		legacyCall<{ acc_got: Accessory[], acc_made: Accessory[] }>(`updateAcessories`), // cSpell:disable-line

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	bulkUpsertDealsToZoho: () => false,

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	cancelMFGOrder: () => false,

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	readyToSubmited: () => false, // cSpell:disable-line

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	submitedToProduction: () => false, // cSpell:disable-line

	/**
	 * @desc Legacy call that generates a fabric cut list (csv) for a quote.
	 * @params quote_id - The quote ID to generate the cut list for
	 * @returns Success response & the csv file
	 */
	getFabricCsv: (quote_id: number) => legacyCall<any>(`getFabricCsv`, { quote_id }),

	/**
	 * @desc Legacy call that saves quote warnings to a quote id.
	 * @params quote_id - The quote ID to save the warnings to
	 * @params verifications - The warnings to save
	 * @returns Success response, warnings, and error
	 * @todo Shape out the verifications object
	 */
	saveQuoteVerifications: (quote_id: number, verifications: any) =>
		legacyCall<any>(`saveQuoteVerifications`, {
			quote_id,
			verifications,
		}),

	/**
	 * @desc Call that updates bid spec data on a quote.
	 * @params quoteId - The quote ID to update
	 * @params bidSpecData - The bid spec data to update
	 * @returns Success response / Error
	 */
	updateBidPricing: (quoteId: number, bidSpecData: BidDataAssemblyAmounts[]) => {
		return apiPut<successError & {
			updated_assemblies: AssemblyViewModel[];
		}>(`Quotes/${quoteId}/BidSpec`, { amount_sets: bidSpecData });
	},

	/**
	 * @desc Legacy call that returns the quote warnings history for a quote id.
	 * @params quote_id - The quote ID to get the warnings history for
	 * @returns Success response, quote warnings, and error
	 */
	quoteWarningsHistory: (quote_id: number) => legacyCall<any>(`quoteWarningsHistory`, { quote_id }),

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	readyToSubmitted: () => false,

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	submittedToProduction: () => false,

	/**
	 * @desc Legacy call that checks if a quote needs a retainer.
	 * @params quote_id - The quote ID to check
	 * @params payment_type - The payment type to check against
	 * @returns Success response, quote id, and if the retainer is required
	 */
	willNeedRetainer: (quote_id: number, payment_type: string) =>
		legacyCall<any>(`willNeedRetainer`, {
			quote_id,
			payment_type,
		}),

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	orderShipped: () => false,

	/**
	 * @desc Legacy call that returns a list of accessible entities.
	 * @returns Success response, dealers (if applicable), distributors (if applicable),
	 * and representatives (if applicable)
	 * @todo Shape out the dealer, distributor, and representative objects
	 */
	getAccessableEntities: () => legacyCall<EntityListFullResp>(`getAccessableEntities`),

	/**
	 * @deprecated in 1.10.0 - use getDealer instead
	 */
	getDealerEmails: () => false,

	/**
	 * @deprecated in 1.10.0 - use getDistributor instead
	 */
	getDistributorEmails: () => false,

	/**
	 * @deprecated in 1.10.0 - use getRepresentative instead
	 */
	getRepresentativeEmails: () => false,

	/**
	 * @desc Legacy call that saves emails to a dealer.
	 * @params dealer_id - The dealer ID to get the emails for
	 * @returns Success response, dealer emails, and error
	 */
	saveDealerDistributionEmails: (dealer_id: string, emails: string[]) =>
		legacyCall<any>(`saveDealerDistributionEmails`, {
			dealer_id,
			emails,
		}),

	/**
	 * @desc Legacy call that saves emails to a distributor.
	 * @params distributor_id - The distributor ID to get the emails for
	 * @returns Success response, distributor emails, and error
	 */
	saveDistributorDistributionEmails: (distributor_id: string, emails: string[]) =>
		legacyCall<any>(`saveDistributorDistributionEmails`, {
			distributor_id,
			emails,
		}),

	/**
	 * @desc Legacy call that saves emails to a representative.
	 * @params representative_id - The representative ID to get the emails for
	 * @returns Success response, representative emails, and error
	 */
	saveRepresentativeDistributionEmails: (representative_id: string, emails: string[]) =>
		legacyCall<any>(`saveRepresentativeDistributionEmails`, {
			representative_id,
			emails,
		}),

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	getShippingInfo: () => false,

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	changeShadeOrder: () => false,

	/**
	 * @desc Legacy call that combines the fascia of shades
	 * @params quote_id - The quote ID to combine the fascia for
	 * @params sequence_ids - The sequence IDs of the shades to combine the fascia for
	 * @todo Type sequence_ids
	 * @returns Success response, sequence set, and fascia combinations
	 */
	combineFascia: (quote_id: number, sequence_ids: any) =>
		legacyCall<any>(`combineFascia`, {
			quote_id,
			sequence_ids,
		}),

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	willOrderShip: () => false,

	/**
	 * @desc Legacy call that deletes fascia combinations
	 * @params quote_id - The quote ID to combine the fascia for
	 * @params fascia_combination_id - The fascia combination ID to delete
	 * @returns Success response and fascia combinations
	 */
	deleteCombinedFascia: (quote_id: number, fascia_combination_id: number) =>
		legacyCall<any>(`deleteCombinedFascia`, {
			quote_id,
			fascia_combination_id,
		}),

	/**
	 * @desc Legacy call that updates a fascia combination
	 * @params quote_id - The quote ID to combine the fascia for
	 * @params fascia_combination_id - The fascia combination ID to update
	 * @params width - The new width of the fascia combination
	 * @returns Success response, fascia combinations, and error
	 */
	updateCombinedFascia: (quote_id: number, fascia_combination_id: number, total_width: number) =>
		legacyCall<any>(`updateCombinedFascia`, {
			quote_id,
			fascia_combination_id,
			total_width,
		}),

	/**
	 * @desc Legacy call the gets the sales people from the database.
	 * @returns Success response and sales people
	 */
	getSalespeople: () => legacyCall<{ salespeople: SalesPerson[] }>(`getSalespeople`),

	/**
	 * @desc Legacy call that saves an attribute to a designated user.
	 * @params user_id - The user ID to save the attribute to
	 * @params attribute_name - The attribute to save
	 * @params attribute_value - The value of the attribute to save
	 * @returns Success response
	 */
	saveUserAttribute: (user_id: number, attribute_name: string, attribute_value: string) =>
		legacyCall<successResp>(`saveUserAttribute`, {
			user_id,
			attribute_name,
			attribute_value,
		}),

	/**
	 * @desc Legacy call that lists organizations
	 * @params type - The type of organization to list
	 * @returns Success response, type? (not sure what this is), and organizations
	 */
	listOrganizations: (type: string) => legacyCall<any>(`listOrganizations`, { type }),

	/**
	 * @desc Legacy call that creates an organization
	 * @params options - ...? I have no idea
	 * @todo Type options
	 * @returns .. nothing?
	 */
	createOrganization: (options: any) => legacyCall<any>(`createOrganization`, options),

	/**
	 * @desc Legacy call that gets the data of an organization by it's id
	 * @params organization_id - The organization ID to get the data for
	 * @returns Organization data
	 * @todo Type organization data
	 */
	readOrganization: (organization_id: number) => legacyCall<any>(`readOrganization`, { organization_id }),

	/**
	 * @desc Legacy call that updates an organization
	 * @params options - ...? I have no idea
	 * @todo Type options
	 * @returns $idk.......
	 */
	updateOrganization: (organization_id: number, options: any) =>
		legacyCall<any>(`updateOrganization`, {
			organization_id,
			options,
		}),

	/**
	 * @deprecated in 1.5.0
	 */
	deleteOrganiztion: () => false, // cSpell:disable-line

	/**
	 * @desc Legacy call that assigns a contractor to a quote ID.
	 * @params quote_id - The quote ID to assign the contractor to
	 * @params contractor_id - The contractor ID to assign to the quote
	 * @returns Success response
	 */
	assignContractor: (contractor_id: number, quote_id: number) =>
		legacyCall<successResp>(`assignContractor`, {
			contractor_id,
			quote_id,
		}),

	/**
	 * @desc Legacy call that saves an address to an organization.
	 * @params organization_id - The organization ID to save the address to
	 * @params address - The address to save
	 * @todo Type address
	 * @returns Success response
	 */
	saveOrganizationAddress: (organization_id: number, address: any) =>
		legacyCall<successResp>(`saveOrganizationAddress`, {
			organization_id,
			address,
		}),

	/**
	 * @desc Legacy call that sets labor amount for an individual shade.
	 * @params quote_id - The quote ID to set the labor amount for
	 * @params sequence_id - The sequence ID to set the labor amount for
	 * @params labor_amount - The labor amount to set
	 * @returns Success response
	 */
	setShadeLabor: (quote_id: number, sequence_id: number, labor_amount: number) =>
		legacyCall<successResp>(`setShadeLabor`, {
			quote_id,
			sequence_id,
			labor_amount,
		}),

	/**
	 * @desc Legacy call that sets the overhead amount for an individual shade.
	 * @params quote_id - The quote ID to set the overhead amount for
	 * @params sequence_id - The sequence ID to set the overhead amount for
	 * @params overhead_amount - The overhead amount to set
	 * @returns Success response
	 */
	setShadeOverhead: (quote_id: number, sequence_id: number, overhead_amount: number) =>
		legacyCall<successResp>(`setShadeOverHead`, {
			quote_id,
			sequence_id,
			overhead_amount,
		}),

	/**
	 * @desc Legacy call that sets the markup amount for an individual shade.
	 * @params quote_id - The quote ID to set the markup amount for
	 * @params sequence_id - The sequence ID to set the markup amount for
	 * @params markup_amount - The markup amount to set
	 * @returns Success response
	 */
	setShadeMarkup: (quote_id: number, sequence_id: number, markup_amount: number) =>
		legacyCall<successResp>(`setShadeMarkup`, {
			quote_id,
			sequence_id,
			markup_amount,
		}),

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	getShadeAssembliesAsPricing: () => false,

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	GetQuoteInfo: () => false,

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	GetQuoteInfo2: () => false,

	/**
	 * @desc Legacy call that sets a PO number for a quote.
	 * @params quote_id - The quote ID to set the PO number for
	 * @params po_number - The PO number to set
	 * @returns Success response
	 */
	SetQuotePONum: (quote_id: number, po_num: string) =>
		legacyCall<successError>(`SetQuotePONum`, {
			quote_id,
			po_num,
		}),

	/**
	 * @desc Legacy call that gets a PO number for a quote.
	 * @params quote_id - The quote ID to get the PO number for
	 * @returns PO number
	 */
	GetQuotePONum: (quote_id: number) => legacyCall<any>(`GetQuotePONum`, { quote_id }),

	/**
	 * @desc Legacy call that changes the previous quote ID of a quote.
	 * @params quote_id - The quote ID to change the previous quote ID for
	 * @params previous_quote_id - The previous quote ID to set
	 * @returns Success response
	 */
	SetPreviousQuoteId: (quote_id: number, previous_quote_id: number) =>
		legacyCall<successResp>(`SetPreviousQuoteId`, {
			quote_id,
			previous_quote_id,
		}),

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	GetFakeJwt: () => false,

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	BuyerData: () => false,

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	submitOrder: () => false,

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	GetServerJWT: () => false,

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	oneTimeRecipe: () => false,

	/**
	 * @deprecated in 1.5.0 (front end, may still be used in back end)
	 */
	emailerTest: () => false,

	/**
	 * @desc A call that returns all dealers (their ids) the user currently has access to.
	 * @returns List of dealer ids
	 */
	getDealerIds: () =>
		apiGet<{
			success: boolean;
			ids: number[];
		}>("Entities/DealerIds"),

	/**
	 * @desc A call that returns a list of dealers and their meta data based on the dealer ids input.
	 * @params dealerIds - The list of dealer ids.
	 * @returns List of dealer meta data
	 */
	getDealersMeta: () =>
		apiGet<{
			dealers: DealerMeta[];
			territory_pending_dealer_count: number;
			pending_dealer_count: number;
		}>("Dealers"),

	/**
	 * @desc A call that returns a list of dealers and their meta data based on the dealer ids input.
	 * @params dealerIds - The list of dealer ids.
	 * @returns List of dealer meta data
	 */
	getDealerMeta: (dealerId) =>
		apiGet<{
			dealer: DealerMeta[];
		}>(`Dealers/${dealerId}/Meta`),

	/**
	 * @desc Call that returns two lists - one of all possible parts on the shade,
	 * one with the chosen boolean modifiers (actual_parts).
	 * @returns PartsListResponse
	 */
	getShadePartsList: (quoteId: number, sequenceId: number) =>
		apiGet<successErrorMessage & PartsListResponse>(`Quotes/${quoteId}/Assembly/${sequenceId}/PartLists`),

	/**
	 * @desc A call that returns a list of dealerIds based on
	 * user access and which territories the dealers are in.
	 * @params territoryId - The territoryId to filter on
	 * @returns List of dealerIds
	 */
	getDealerIdsByTerritory: (territoryId: number) =>
		apiGet<{
			success: boolean;
			ids: number[];
		}>(`Entities/DealersByTerritory/${territoryId}`),

	/**
	 * @desc A call that returns our list of
	 *
	 * @returns Object containing a list of fabrics (indoor)
	 */
	getFabrics: () => apiGet<{ fabrics: FabricStore[] }>(`fabrics`),

	/**
	 * @desc Inserts/Updates user role
	 * @params user - The user object to update
	 * @returns Success boolean and message
	 */
	upsertUserRole: (user: UpsertUserRoleBody) =>
		apiPut<{
			success: boolean;
			message: string;
		}>(`User/${user.user_id}`, {
			user,
		}),

	/**
	 * @desc Call that returns a list of channels
	 * @example [{id: 1, Name: "Channel 1", Description: "Channel 1 Description"}, ...
	 * @returns List of channels
	 */
	getChannels: () => apiGet<{ channels: Channel[] }>("Organization/Channels"),

	/**
	 * @param quoteId The Id of the quote whose accessory is being updated
	 * @param lineNumber The line number of the accessory being updated
	 * @param newQuantity The new quantity of the accessory
	 * @param parms The parameters of the accessory
	 * @returns The new status of the quote accessory updated.
	 */
	updateQuoteAccessory: (input: {
		quoteId: number;
		lineNumber: number;
		newQuantity: number;
		parms: QuoteAccessoryParameters[];
	}) =>
		apiPut<{ quote_accessory: QuoteAccessoryStore, legacy_pricing: SaveAssemblyResp }>(
			`Accessories`,
			{
				line_number: input.lineNumber,
				quantity: input.newQuantity,
				quote_id: input.quoteId,
				parameter_values: input.parms,
			},
			{
				timeout: 100000,
			}
		),

	updateQuoteAddress: (quoteId: number, address: Address, addressType: string) =>
		apiPut<{ address: Address }>(`Quotes/${quoteId}/Address/${addressType}`, address),

	/**
	 * @param quoteId The Id of the quote whose accessory is being updated
	 * @param lineNumber The line number of the accessory being updated
	 * @param newQuantity The new quantity of the accessory
	 * @param parms The parameters of the accessory
	 * @returns The new status of the quote accessory updated.
	 */
	addQuoteAccessory: (input: {
		quoteId: number;
		sku: string;
		newQuantity: number;
		parms: QuoteAccessoryParameters[];
		lineNumber: number;
	}) =>
		apiPost<{ quote_accessory: QuoteAccessoryStore, legacy_pricing: SaveAssemblyResp }>(
			`Accessories`,
			{
				quote_id: input.quoteId,
				part_number: input.sku,
				quantity: input.newQuantity,
				parameter_values: input.parms,
				recommended_line_number: input.lineNumber
			},
			{
				timeout: 100000,
			}
		),

	/**
	 * @desc Call for getting a list all accessories attatched to the quote specified.
	 * @param quoteId Quote id with accessories
	 * @returns A list of accessories, including accessory assemblies.
	 */
	getQuotePricing: (quoteId: number) =>
		apiGet<{ pricing: QuotePricingVM }>(`Quotes/${quoteId}/Pricing`),

	/**
	 * @desc Call for getting a list all accessories attatched to the quote specified.
	 * @param quoteId Quote id with accessories
	 * @returns A list of accessories, including accessory assemblies.
	 */
	getQuoteAccessories: (quoteId: number) =>
		apiGet<{ quote_accessories: QuoteAccessoryStore[] }>(`Accessories/Quote/${quoteId}`),

	/**
	 * @desc Call for getting a list all assemblies attatched to the quote specified.
	 * @param quoteId Quote id with accessories
	 * @returns A list of accessories.
	 */
	getQuoteAssemblies: (quoteId: number) =>
		apiGet<{ assemblies: AssemblyViewModel[] }>(`Quotes/${quoteId}/Assemblies`),

	/**
	 * @desc Call for getting a list all fabric samples attatched to the quote specified.
	 * @param quoteId Quote id with fabric samples
	 * @returns A list of fabric samples.
	 */
	getQuoteFabricSamples: (quoteId: number) =>
		apiGet<{ fabric_samples: QuoteFabricSampleVM[] }>(`Quotes/${quoteId}/FabricSamples`),

	/**
	 * @desc Call for adding a fabric sample to a quote.
	 * @param quoteId Quote id with the fabric sample to be added
	 * @param fabricSample Fabric sample to be added
	 * @returns The new fabric sample added.
	 */
	addQuoteFabricSample: (quoteId: number, fabricSample: QuoteFabricSampleVM) =>
		apiPost<{ fabric_sample: QuoteFabricSampleVM }>(`Quotes/${quoteId}/FabricSamples`, fabricSample),

	/**
	 * @desc Call for deleting a fabric sample attatched to this quote.
	 * @param quoteId Quote id with the fabric sample to be deleted
	 * @param fabricName Fabric name of fabric sample to be deleted.
	 * @returns Success bool.
	 */
	deleteQuoteFabricSample: (quoteId: number, fabricName: string) =>
		apiDelete<{ success: boolean }>(encodeURI(`Quotes/${quoteId}/FabricSamples/${fabricName.replace("/", "%2F")}`)),

	/**
	 * @desc Call for updating a quote fabric sample by quote id, fabric name, and quantity input.
	 * @param quoteId Quote id with the fabric sample to be updated
	 * @param fabricName Fabric name of fabric sample to be updated.
	 * @param quantity New quantity of fabric sample.
	 * @returns The new fabric sample updated.
	 */
	updateQuoteFabricSample: (quoteId: number, fabricName: string, quantity: number) =>
		apiPut<{ fabric_sample: QuoteFabricSampleVM }>(`Quotes/${quoteId}/FabricSamples/${fabricName.replace("/", "%2F")}`, {
			quantity,
		}),

	/**
	 * @desc Call for deleting a quote accessory attatched to this quote.
	 * @param QuoteId Quote id with the accessory to be deleted
	 * @param LineNumber Line Number of quote accessory to be deleted.
	 * @returns Success boool and the legacy pricing information.
	 */
	deleteQuoteAccessory: (quoteId: number, lineNumber: number) =>
		apiDelete<{ success: boolean, legacy_pricing: SaveAssemblyResp }>(
			`Accessories/Quote/${quoteId}/LineNumber/${lineNumber}`,
			{
				timeout: 100000,
			}
		),

	/**
	 * @desc Call that returns the links to Zoho based on the entity type and entity id
	 * @param entityType The type of entity to get the links for
	 * @param entityId The id of the entity to get the links for
	 * @returns A list of links to Zoho
	 */
	getZohoLinks: (entityType: string, entityId: number) =>
		apiGet<ZohoLinks>(`Organization/Links?entityType=${entityType}&entityId=${entityId}`),

	/**
	 * @desc Call that posts a Zoho Sync request based on the entity type and entity id and returns the links to Zoho
	 * @param entityType The type of entity to get the links for
	 * @param entityId The id of the entity to get the links for
	 * @returns A list of links to Zoho
	 */
	postZohoSync: (entityType: string, entityId: number) =>
		apiPost<ZohoLinks>(`Organization/Links?entityType=${entityType}&entityId=${entityId}`),

	/**
	* @desc Call that posts a Zoho Sync request based on the entity type and entity id and returns the links to Zoho
	* @param entityType The type of entity to get the links for
	* @param entityId The id of the entity to get the links for
	* @returns A list of links to Zoho
	*/
	postCheckZProperties: (entityType: string, entityId: number) =>
		apiPost<ZohoLinkChecks>(`Organization/TestLinks?entityType=${entityType}&entityId=${entityId}`),

	/**
* @desc Call that posts a Zoho Sync request based on the entity type and entity id and returns the links to Zoho
* @param entityType The type of entity to get the links for
* @param entityId The id of the entity to get the links for
* @returns A list of links to Zoho
*/
	GetZproperties: (entityType: string, entityId: number) =>
		apiGet<ZohoLinkChecks>(`Organization/TestLinks?entityType=${entityType}&entityId=${entityId}`, { timeout: 100000 }),

	/**
* @desc Call that gets a Zoho update request, but not send it, based on the quoteId and returns the links to Zoho
* @param quoteId The quote id to get the CRM deal and Books Invoice links
* @returns A list of links to Zoho
*/
	GetZQuoteproperties: (quoteId: number) =>
		apiGet<ZohoLinkChecks>(`Quotes/TestLinks?quoteId=${quoteId}`, { timeout: 100000 }),

	/**
	 * @desc Call that gets a list of assembly ids, row coordinates, and column coordinates
	 * used on a quote.
	 * @param quoteId The quote id to get the previous assembly ids and
	 * row coordinates for
	 * @returns A list of assembly ids, row coordinates, and column coordinates
	 */
	getAssemblyIdsAndCoordinates: (quoteId: number, sequenceId: number) =>
		apiGet<{
			previous_shade_assembly: ShadeAssembliesAndCoordinates;
		}>(`Quotes/${quoteId}/Assembly/${sequenceId}/PreviousIds`),

	/**
	 * @desc Call that saves the previous assembly id.
	 * @param quoteId The quote id for the assembly having it's previous assembly id set.
	 * @param sequenceId The sequence id for the assembly having it's previous assembly id set.
	 * @param previousId The previous assembly id to set.
	 * @returns Success Response
	 */
	setPreviousAssemblyId: (quoteId: number, sequenceId: number, previousId: number) =>
		apiPut<successResp>(`Quotes/${quoteId}/Assembly/${sequenceId}/PreviousAssemblyId`, {
			value: previousId,
		}),

	/**
	 * @desc Call that saves the previous shade id.
	 * @param quoteId The quote id for the shade having it's previous shade id set.
	 * @param sequenceId The sequence id for the shade having it's previous shade id set.
	 * @param previousId The previous shade id to set.
	 * @param row The row of the shade having it's previous shade id set.
	 * @param col The column of the shade having it's previous shade id set.
	 * @returns Success Response
	 */
	setPreviousShadeId: (quoteId: number, sequenceId: number, previousId: number, row: number, col: number) =>
		apiPut<successResp>(`Quotes/${quoteId}/Assembly/${sequenceId}/Row/${row}/Col/${col}/PreviousShadeId`, {
			value: previousId,
		}),

	/**
	 * @desc Call using a quote id to send an explanatory email to the territory manager of this order.
	 * @params quoteId - The quote id of the order needing territory manager attention.
	 * @returns success status of the email
	 */
	sendAccountingHoldEmail: (quoteId: number) => apiPost<successResp>(`quotes/${quoteId}/HoldAccountEmails`),

	/**
	 * @desc Back end call for setting if a header is to be cut.
	 * @param quoteId - The quote id that contains the assembly.
	 * @param sequenceId - The sequence id of the assembly.
	 * @param toBeCut - The boolean value to set for cutting the header.
	 * @returns Success boolean.
	 */
	setHeaderToBeCut: (quoteId: number, sequenceId: number, toBeCut: boolean) =>
		updateAssemblyBoolField("IsHeaderExtrusionToBeCut", quoteId, sequenceId, toBeCut),

	/**
	 * @desc Back end call for setting if a tube is to be cut.
	 * @param quoteId - The quote id that contains the assembly.
	 * @param sequenceId - The sequence id of the assembly.
	 * @param toBeCut - The boolean value to set for cutting the tube.
	 * @returns Success boolean.
	 */
	setTubeToBeCut: (quoteId: number, sequenceId: number, toBeCut: boolean) =>
		updateAssemblyBoolField("IsTubeToBeCut", quoteId, sequenceId, toBeCut),

	/**
	 * @desc Back end call for setting if a sill is to be cut.
	 * @param quoteId - The quote id that contains the assembly.
	 * @param sequenceId - The sequence id of the assembly.
	 * @param toBeCut - The boolean value to set for cutting the sill.
	 * @returns Success boolean.
	 */
	setSillToBeCut: (quoteId: number, sequenceId: number, toBeCut: boolean) =>
		updateAssemblyBoolField("IsSillToBeCut", quoteId, sequenceId, toBeCut),

	/**
	 * @desc Back end call for setting if a side is to be cut.
	 * @param quoteId - The quote id that contains the assembly.
	 * @param sequenceId - The sequence id of the assembly.
	 * @param toBeCut - The boolean value to set for cutting the side.
	 * @returns Success boolean.
	 */
	setSideToBeCut: (quoteId: number, sequenceId: number, toBeCut: boolean) =>
		updateAssemblyBoolField("IsSideToBeCut", quoteId, sequenceId, toBeCut),

	/**
	 * @desc Back end call for setting if fabric is to be cut.
	 * @param quoteId - The quote id that contains the assembly.
	 * @param sequenceId - The sequence id of the assembly.
	 * @param toBeCut - The boolean value to set for cutting the fabric.
	 * @returns Success boolean.
	 */
	setFabricToBeCut: (quoteId: number, sequenceId: number, toBeCut: boolean) =>
		updateAssemblyBoolField("IsFabricToBeCut", quoteId, sequenceId, toBeCut),

	/**
	 * @desc Back end call for setting if hembar is to be cut.
	 * @param quoteId - The quote id that contains the assembly.
	 * @param sequenceId - The sequence id of the assembly.
	 * @param toBeCut - The boolean value to set for cutting the fabric.
	 * @returns Success boolean.
	 */
	setHembarToBeCut: (quoteId: number, sequenceId: number, toBeCut: boolean) =>
		updateAssemblyBoolField("IsHembarToBeCut", quoteId, sequenceId, toBeCut),

	/**
	 * @desc Back end call for setting if a specific shade is to be made.
	 * @param quoteId - The quote id that contains the shade.
	 * @param sequenceId - The sequence id of the shade.
	 * @param rowCoordinate - The row coordinate of the shade.
	 * @param columnCoordinate - The column coordinate of the shade.
	 * @param toBeMade - The boolean value to set for making the shade.
	 * @returns Success boolean.
	 */
	setThisShadeToBeMade: (
		quoteId: number,
		sequenceId: number,
		rowCoordinate: number,
		columnCoordinate: number,
		toBeMade: boolean
	) => {
		return apiPut<successError>(`Quotes/${quoteId}/Assembly/${sequenceId}/Row/${rowCoordinate}/Col/${columnCoordinate}/MakeThisShade`, {
			value: toBeMade
		});
	},

	/**
	 * @desc Back end call for setting the service screen variables for an assembly and it's shades.
	 * @param quoteId - The quote id that contains the assembly.
	 * @param sequenceId - The sequence id of the assembly.
	 * @param input - The flags on the assembly to set.
	 * @returns Success boolean and the assembly updated.
	 */
	setServiceScreenVariables: (
		quoteId: number,
		sequenceId: number,
		input: ServiceScreenVariables
	) =>
		apiPut<{ assembly: AssemblyViewModel, success: boolean }>(
			`Quotes/${quoteId}/Assembly/${sequenceId}/ServiceScreen`,
			input
		),

	/**
	 * @desc Back end call te send an email every time the submit button is touched on the dealer signup page.
	 * @param data - Signup Form's current State
	 * @returns Success boolean.
	 */
	dealerSignupButtonClick: (data: any) => {
		return apiPut<successError>(`Nice/DealerSignupButtonClick`, data);
	},

	/**
	 * @desc Back end call to get the data needed for the AssemblyOptions from ShadesAPI.
	 * @param series - The series of the shade. (Indoor, PSOutdoor)
	 * @returns The data needed to populate fields in the Portal UI.
	 */

	getAssemblyOptions: (quoteId:number) => {
		return apiGet<AssemblyOptionsViewModel>(`AssemblyOptions/PortfolioHeaders/${quoteId}`);
	},

	/*
	* @desc Back end call to get the list of portfolios for a powershades organization.
	* @returns The list of portfolios for a powershades organization.
	*/
	getPortfolios: () => {
		return apiGet<Portfolio[]>(`AssemblyOptions/Portfolios`);
	},

	/*
	* @desc Back end call to get the list of portfolios for a powershades organization.
	* @returns The list of portfolios for a powershades organization.
	*/
	updateQuotePortfolio: (quoteId: number, portfolioCode: string) => {
		return apiPut<successError>(`AssemblyOptions/UpdateQuotePortfolio`, { quote_id: quoteId, portfolio_code: portfolioCode });
	},


	/*
	* @desc Back end call to get the list of accessorries attatched to a quote id's portfolio
	*/

	/**
	 * @desc Back end call to get meta data attatched to a quote
	 * @param quoteId Quote id with meta data
	 * @returns Meta data attatched to the quote
	 */
	getQuoteMeta: (quoteId: number) =>
		apiGet<{ quote: QuoteVM }>(`Quotes/${quoteId}/Meta`),

	/**
	 * @desc Back end call to get the list of multipliers that the user can access on this quote
	 * @param quoteId Quote id with multipliers
	 * @returns List of multipliers
	 */
	getQuoteMulipliers: (quoteId: number) =>
		apiGet<{ multipliers: QuoteMultiplierVM[] }>(`Quotes/${quoteId}/Multipliers`),

	/**
	 * @desc Back end call to get the list of fascia combinations for a quote
	 * @param quoteId Quote id with fascia combinations
	 * @returns List of fascia combinations
	 */
	getQuoteFasciaCombinations: (quoteId: number) =>
		apiGet<{ fascia_combinations: FasciaCombinationVM[] }>(`Quotes/${quoteId}/FasciaCombinations`),

	/**
	 * @desc Back end call to get the two different addresses attatched to a quote. Billing and Shipping.
	 * @param quoteId Quote id with addresses
	 * @returns The billing and shipping addresses attatched to the quote
	 */
	getQuoteAddresses: (quoteId: number) =>
		apiGet<QuoteAddressVM>(`Quotes/${quoteId}/Addresses`),
};

export default apiCalls;

export { apiGet };
