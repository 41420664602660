/* eslint-disable arrow-body-style */ // Gross
import * as React from 'react';
import { Card } from 'react-bootstrap';
import { useAccessories } from '../Store/entities/accessories/hooks';

const AccessoryView = () => {
	const accessories = useAccessories(1);

	const tableHeaders = [
		'Accessory ID',
		'Accessory Name',
		'Accessory Sku',
		'Accessory MSRP',
		'Accessory Description',
		'Accessory Parameter Count'
	];

	const headerRow = tableHeaders.map((header) => {
		return (
			<th key={header}>{header}</th>
		);
	});

	const tableRows = accessories.map((accessory) => {
		return (
			<tr key={accessory.id}>
				<td>{accessory.id}</td>
				<td>{accessory.name}</td>
				<td>{accessory.part_number}</td>
				<td>{accessory.msrp}</td>
				<td>{accessory.description}</td>
				<td>{accessory.parameters?.length ?? 1}</td>
			</tr>
		);
	});

	return (
		<Card>
			<div>
				<h1>AccessoryView</h1>
			</div>
			<table>
				<thead>
					<tr>
						{headerRow}
					</tr>
				</thead>
				<tbody>
					{tableRows}
				</tbody>
			</table>

		</Card>
	);
};

export default AccessoryView;
