import './Parts/DevTools';
import { createRoot } from 'react-dom/client';
import React from 'react';
import * as Sentry from '@sentry/react';
import Bowser from "bowser";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router';

import { persistor } from './Store';
import store from './Store';

import packageJson from '../package.json';

import Favicon from './assets/img/favicon.png';// 'assets/img/favicon.png';
import UnsupportedBrowserHTML from './UnsupportedBrowser';
import './assets/fonts/opensans_regular_macroman/stylesheet.scss';
import './main.scss';
import './WebsocketClient';

import Layout from './Layout';
// import { createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.css';

if (!process.env.ENV.includes("dev")) {
	Sentry.init({
		dsn: "https://977cc75fd5474348addfc65176909b2c@sentry.io/1495453",
		environment: process.env.ENV ?? "dev",
		replaysSessionSampleRate: process.env.ENV === "prod" ? 0.1 : 1,
		replaysOnErrorSampleRate: 1,
		release: packageJson.version,
		tracesSampleRate: process.env.ENV === "prod" ? 0.1 : 1,
		integrations: [
			new Sentry.BrowserTracing({
				routingInstrumentation: Sentry.reactRouterV6Instrumentation(
					React.useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes
				)
			})
		]
	});
}

/* eslint wrap-iife: "off" */

window.addEventListener('load', () => {
	(function initializeWindow() {
		const faviconEl = document.createElement('link');
		faviconEl.setAttribute('rel', 'icon');
		faviconEl.setAttribute('href', Favicon);

		const noCacheMeta1 = document.createElement('meta');
		noCacheMeta1.setAttribute('http-equiv', 'Cache-control');
		noCacheMeta1.setAttribute('content', 'no-cache, no-store, must-revalidate');

		const noCacheMeta2 = document.createElement('meta');
		noCacheMeta2.setAttribute('http-equiv', 'Pragma');
		noCacheMeta2.setAttribute('content', 'no-cache');

		const stripeScriptEl = document.createElement('script');

		document.head.appendChild(stripeScriptEl);

		const rootEl = document.createElement('div');
		rootEl.setAttribute('id', 'root');

		document.head.appendChild(faviconEl);
		document.head.appendChild(noCacheMeta1);
		document.head.appendChild(noCacheMeta2);

		document.body.appendChild(rootEl);
		// document.body.appendChild(googleTagManagerNoScript);
	})();

	const browser = Bowser.getParser(window.navigator.userAgent);

	// Comment that does nothing.

	const isValidBrowser = browser.satisfies({
		chrome: '>20',
		firefox: '>31',
		edge: '>2',
		safari: '>2',
		opera: ">=22",
	});

	if (isValidBrowser) {
		window.process = window.process ?? "";
		window.React = React;

		// This iife is to check location and clean up any url's
		(() => {
			const currentLocation = window.location.href;

			if (!currentLocation.includes("/#/")) {
				// A single attempt to fix legacy url's
				if (currentLocation.includes("/#")) {
					window.location.href = currentLocation.replace("/#", "/#/");
				} else if (!currentLocation.includes('#')) {
					window.location.href = `${currentLocation}#/`;
				}
			}
		})();

		const container = document.getElementById('root');

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const root = createRoot(container!); // createRoot(container!) if you use TypeScript
		
		root.render(
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<Layout />
				</PersistGate>
			</Provider>
		);
	} else {
		window.document.title = 'Unsupported Browser | PowerShades Dealer Portal';
		document.body.innerHTML = UnsupportedBrowserHTML;
	}
});
