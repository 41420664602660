import { useState } from 'react';
import Button from './Button';
import { useQuoteTypeNameByQuoteId } from '../Store/entities/quotes/hooks';
import React from 'react';
import apiCalls from '../PowerShadesAPIFunctions';

type CouponInputProps = {
	quoteId: number;
	onCouponChange: (coupons: any) => void;
};

const CouponInput = ({ quoteId, onCouponChange } : CouponInputProps) => {

	const quoteType = useQuoteTypeNameByQuoteId(quoteId);

	const [value, setValue] = useState('');

	const valueChanged = (new_value) => {
		if (new_value.match(/^[a-zA-Z0-9\-]*$/)) {
			setValue(new_value.toUpperCase());
		}
	}


	async function apply() {

		apiCalls.addCouponToQuote(value,quoteId, false).then((response) => {
			if (response.status === 200) {
				const couponData = response.data;
				onCouponChange([couponData]);
			}
		});
		
	}

	return (
		<>
			<div style={{
				display: 'flex', height: '22px', fontSize: '11px'
			}}
			>

				<input
					type="text"
					style={{
						fontSize: '1.2em', width: '9em', height: '100%'
					}}
					placeholder="COUPON CODE"
					value={value}
					onChange={(e) => valueChanged(e.target.value)}
					maxLength={30}
				/>

				<Button
					color={quoteType ?? 'green'}
					style={{
						borderBottomLeftRadius: '0',
						borderTopLeftRadius: '0',
						marginTop: '-1px',
						height: 'calc(100% + 2px)',
						border: '1px solid gray',
						lineHeight: '0',
						fontSize: '1em',
						marginLeft: '-2px',
						letterSpacing: '0px',
						width: '4em',
						padding: '0',
					}}
					onClick={() => apply()}
				>
					Apply
				</Button>

				<br />

			</div>


		</>
	);
};

export default CouponInput;
