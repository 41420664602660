// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login-module__login-card___hMeIe{min-width:20vw !important}@media(min-width: var(--bs-breakpoint-xxl)){.Login-module__login-card___hMeIe .Login-module__login-card___hMeIe{max-width:25vw !important}}.Login-module__login-card-body___d23tR{padding:1em !important}`, "",{"version":3,"sources":["webpack://./src/Login/Login.module.scss"],"names":[],"mappings":"AAAA,kCAMC,yBAAA,CALA,4CACC,oEACC,yBAAA,CAAA,CAMH,uCACC,sBAAA","sourcesContent":[".login-card {\n\t@media (min-width: var(--bs-breakpoint-xxl)) {\n\t\t.login-card {\n\t\t\tmax-width: 25vw !important;\n\t\t}\n\t}\n\tmin-width: 20vw !important;\n}\n\n.login-card-body {\n\tpadding: 1em !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login-card": `Login-module__login-card___hMeIe`,
	"loginCard": `Login-module__login-card___hMeIe`,
	"login-card-body": `Login-module__login-card-body___d23tR`,
	"loginCardBody": `Login-module__login-card-body___d23tR`
};
export default ___CSS_LOADER_EXPORT___;
