import { useState } from 'react';
import Styles from '../Styles';

import api from '../PowerShadesAPI';

import IntInput from '../Parts/IntInput';
import Button from '../Parts/Button';

const AddCoupon = (props) => {
	const has_initial_coupon = props.initialCoupon != null;

	const initialCoupon = has_initial_coupon ? props.initialCoupon : {};

	const [couponDiscountType, setCouponDiscountType] = useState(
		initialCoupon.discount_type || null
	);
	const [couponCode, setCouponCode] = useState(initialCoupon.code || '');
	const [couponNote, setCouponNote] = useState(initialCoupon.note || '');
	const [couponValue, setCouponValue] = useState(initialCoupon.discount_amount || null);
	const [hasExpiration, setHasExpiration] = useState(initialCoupon.expires != null);
	const [expirationDate, setExpirationDate] = useState(initialCoupon.expires || null);
	const [hasPerDealerLimit, setHasPerDealerLimit] = useState(
		initialCoupon.max_uses_per_dealer && initialCoupon.max_uses_per_dealer > 0
	);
	const [hasTotalUseLimit, setHasTotalUseLimit] = useState(
		initialCoupon.max_total_uses && initialCoupon.max_total_uses > 0
	);
	const [totalUseLimit, setTotalUseLimit] = useState(initialCoupon.max_total_uses || 50);
	const [perDealerLimit, setPerDealerLimit] = useState(initialCoupon.max_uses_per_dealer || 50);

	function isSubmittable() {
		return (
			`${couponCode}`.match(/^[a-zA-Z0-9\-]*$/) != null &&
			parseFloat(couponValue) > 0 &&
			(couponDiscountType === 'percentage') |
				(couponDiscountType === 'fixed_amount') |
				(couponDiscountType === 'invoice_percentage') |
				(couponDiscountType === 'multiplier_override')
		);
	}

	function addCoupon() {
		if (hasExpiration & !expirationDate) {
			ROOT.alert({
				title: 'Invalid Expiration Date',
				text: 'Please select an expiration date or uncheck "Has Expiration".',
				icon: 'error',
				smallIcon: true,
				noCancelButton: true,
				acceptButtonText: 'Dismiss',
			});
			return;
		}

		api.addCoupon(
			couponCode,
			couponNote,
			couponDiscountType,
			couponValue,
			hasExpiration ? expirationDate : null,
			hasTotalUseLimit ? totalUseLimit : null,
			hasPerDealerLimit ? perDealerLimit : null
		)
			.then(() => {
				if (props.initialCoupon) {
					api.deleteCoupon(props.initialCoupon.ID)
						.then(() => {
							ROOT.toast(`Coupon ${couponCode} updated`, { type: 'success'});
						})
						.finally(props.onClose);
				} else {
					ROOT.toast(`Coupon ${couponCode} added`, { type: 'success' });
					props.onClose();
				}
			})
			.catch(() =>
				ROOT.alert({
					title: 'Error',
					icon: 'error',
					noCancelButton: true,
					acceptButtonText: 'Dismiss',
					text: 'Error creating coupon. Please contact PowerShades Engineering.',
				})
			);
	}

	function onCouponCodeInput(new_code) {
		if (new_code.match(/^[a-zA-Z0-9\-]*$/)) {
			setCouponCode(new_code.toUpperCase());
		}
	}

	function onCouponValueInput(new_value) {
		const is_number = new_value.match(/^[0-9]*(?:\.[0-9]*)?$/);

		if (is_number != null) {
			setCouponValue(new_value);
		}
	}

	const Hr = () => <hr style={{ width: '100%', margin: '2em 0' }} />;

	const couponNameNumberName = (function (couponDiscountType) {
		if (couponDiscountType == 'percentage') {
			return 'Discount Percentage';
		} else if (couponDiscountType == 'multiplier_override') {
			return 'Percent off Multiplier Override';
		} else if (couponDiscountType == 'invoice_percentage') {
			return 'Percent off Invoice';
		} else if (couponDiscountType == 'fixed_amount') {
			return 'Fixed Discount Value';
		}
	})(couponDiscountType);

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: '1',
				margin: '0.5em auto',
				background: 'white',
				borderRadius: '3px',
				width: '320px',
				maxWidth: '100%',
			}}
		>
			<h2
				style={{
					fontSize: '1.3em',
					marginBottom: '0.5em',
					color: Styles.psGreen,
				}}
			>
				{props.initialCoupon ? 'Edit Coupon' : 'Create Coupon'}
			</h2>

			{has_initial_coupon ? (
				<div className="info-box" style={{ fontSize: '10px', padding: '5px' }}>
					Editing coupon will create a new coupon and archive the old.
					<br />
					"Times Used" will remain saved on the back-end but will be reset on the new
					coupon.
				</div>
			) : null}

			<div style={{ marginTop: '0.5em' }}>
				<label>Coupon Code</label>
				<br />
				<input
					style={{ width: '100%' }}
					type="text"
					value={couponCode}
					onChange={(e) => onCouponCodeInput(e.target.value)}
					maxLength="30"
				/>
			</div>

			<div style={{ marginTop: '0.5em' }}>
				<label>Note</label>
				<br />
				<input
					style={{ width: '100%' }}
					type="text"
					value={couponNote}
					onChange={(e) => setCouponNote(e.target.value)}
					maxLength="255"
					placeholder="What is this coupon for?"
				/>
			</div>

			<Hr />

			<div>
				<label>Discount Type</label>
				<br />
				<select
					value={couponDiscountType || ''}
					onChange={(e) => setCouponDiscountType(e.target.value)}
					style={{ marginRight: '0.5em', width: '100%' }}
				>
					<option value="">- Select Discount Type -</option>
					{/* <option value="percentage">Percentage</option> */}
					<option value="fixed_amount">Fixed Amount</option>
					{/* <option value="multiplier_override">% off MSRP</option> */}
					<option value="invoice_percentage">% off Invoice</option>
				</select>
			</div>

			<div>
				<label>{couponNameNumberName}</label>
				<br />
				<input
					style={{ width: '100%' }}
					type="text"
					disabled={!couponDiscountType}
					value={couponValue || ''}
					onChange={(e) => onCouponValueInput(e.target.value)}
				/>
			</div>

			<Hr />

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}
			>
				<div>
					<label htmlFor="has-per-dealer-limit">Per-Dealer Use Limit</label>
					<br />
					<input
						name="has-per-dealer-limit"
						type="checkbox"
						checked={hasPerDealerLimit}
						onChange={() => setHasPerDealerLimit(!hasPerDealerLimit)}
					/>
				</div>
				<IntInput
					disabled={!hasPerDealerLimit}
					defaultValue={perDealerLimit}
					noNegative
					onChange={(new_val) => setPerDealerLimit(new_val)}
					label="Max Uses"
				/>
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}
			>
				<div>
					<label htmlFor="has-expiration">Total Uses Limit</label>
					<br />
					<input
						name="has-expiration"
						type="checkbox"
						checked={hasTotalUseLimit}
						onChange={() => setHasTotalUseLimit(!hasTotalUseLimit)}
					/>
				</div>
				<IntInput
					disabled={!hasTotalUseLimit}
					defaultValue={totalUseLimit}
					noNegative
					onChange={(new_val) => setTotalUseLimit(new_val)}
					label="Max Uses"
				/>
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}
			>
				<div>
					<label htmlFor="has-expiration">Has Expiration</label>
					<br />
					<input
						name="has-expiration"
						type="checkbox"
						checked={hasExpiration}
						onChange={() => setHasExpiration(!hasExpiration)}
					/>
				</div>

				<div>
					<label htmlFor="expiration">Expires</label>
					<br />
					<input
						name="expiration"
						type="date"
						value={expirationDate}
						onChange={(e) => setExpirationDate(e.target.value)}
						disabled={!hasExpiration}
					/>
				</div>
			</div>

			<br />

			<div
				style={{
					justifyContent: 'space-between',
					display: 'flex',
					flexDirection: 'row',
					alignSelf: 'flex-end',
					margin: '1em 0',
					width: '100%',
				}}
			>
				<Button color="gray" onClick={props.onClose}>
					Cancel
				</Button>

				<Button
					color={isSubmittable() ? 'green' : 'gray'}
					disabled={!isSubmittable()}
					onClick={() => addCoupon()}
				>
					{props.initialCoupon ? 'Save Changes' : 'Add Coupon'}
				</Button>
			</div>
		</div>
	);
};

export default AddCoupon;
