/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @stylistic/max-len */
/* eslint-disable @stylistic/jsx-indent */
/* eslint-disable @stylistic/indent */
import React, { useEffect, useRef, useState } from "react";
import store, { UsePortalSelector } from "../../../../Store";
import { InitialAssemblyState, InitialShadeState, updateAssemblies } from "../../../../Store/entities/assemblies";
import { useAssembliesByQuoteId } from "../../../../Store/entities/assemblies/hooks";
import Popup from "reactjs-popup";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Button } from "react-bootstrap";
import PriceErrors from "../../../../Quote/PriceErrors";
import EditIndoorShadeModalInner from "./EditIndoorShadeInner";
import { useNewIndoorShadeOptions } from "../../../../Store/entities/assemblies/IndoorHooks.ts";
import { useQuoteById, useQuoteIdAndDispatch, useQuoteTypeNameByQuoteId } from "../../../../Store/entities/quotes/hooks";
import { useIndoorFabrics } from "../../../../Store/entities/hooks";
import { useUser } from "../../../../Store/user/hooks";
import { useAssemblyOptions, useGetColorOptions, useGetFullPaletteList } from "../../../../Store/entities/assemblyOptions/hooks";
import { useQuoteCombination } from "../../../../Store/entities/combinations/hooks";
import apiCalls from "../../../../PowerShadesAPIFunctions";
import { useAssemblyErrorsByStore } from "../../../../Store/entities/assemblies/errors/hooks";
import { AssemblyStore, AssemblyViewModelFromAssemblyStore, IndoorShadeOptionsDict, PortalShadeOption, PortalShadeOptionItem, ShadeStore } from "../../../../Store/entities/assemblies/types";
import { initialQuote } from "../../../../Store/entities/quotes";
import { AssemblyOptionColorPalette, ShadeViewModel } from "../../../../powershadesApiTypes";

type MassEditIndoorModalProps = {
    quoteId: number;
    isOpen: boolean;
    onClose: () => void;
};
const MassEditIndoorModal = ({ quoteId, isOpen, onClose }: MassEditIndoorModalProps) => {
    const assembly = { ...InitialAssemblyState(), unlocked: true, shades: [InitialShadeState()], quote_id: quoteId };
    const selectedAssemblies = useAssembliesByQuoteId(quoteId).filter((assy) => assy.selected);

    const [incomingAssembly, setAssembly] = React.useState(assembly);
    const colorOptions = useGetColorOptions(quoteId);
    const colorPalettesInner = useGetFullPaletteList(quoteId);

    const setAssemblyIncoming = (assy: AssemblyStore) => {
        let currentAssys = [...selectedAssemblies];

        Object.entries(assy).forEach(([key, value]) => {
            if (key === "shades") {

                currentAssys = currentAssys.map((currentAssembly) => {
                    const shadesValue = (value as ShadeViewModel[])
                    let newShades: ShadeStore[] = [];
                    shadesValue.forEach((shade) => {
                        const baseShade = assembly?.shades?.find(s => 
                            s.column_coordinate == shade.column_coordinate && s.row_coordinate == shade.row_coordinate
                        ) ?? InitialShadeState();

                        const originalShadeStore = currentAssembly?.shades?.find(s =>
                            s.column_coordinate == shade.column_coordinate && s.row_coordinate == shade.row_coordinate
                        ) ?? currentAssembly?.shades[0] ?? InitialShadeState();

                        let originalShade = { ...originalShadeStore };

                        // foreach key in the base shade, if the value is different from the original shade, update the originalShade 
                        // with the new value. 
                        Object.entries(baseShade).forEach(([shadeKey, shadeValue]) => {
                            if (shadeKey !== "id" && shadeKey !== "assembly_id" && shadeKey !== "quote_id" && shadeKey !== "sequence_id" && shadeKey !== "selected" && shadeKey !== "error_messages") {
                                if (shade[shadeKey] !== shadeValue) {
                                    originalShade = { ...originalShade, [shadeKey]: shade[shadeKey] };
                                }
                            }
                        });

                        newShades.push(originalShade);
                    });

                    return { ...currentAssembly, shades: newShades };
                });
              
            }
            if (assembly[key] !== value && key !== 'shades'  && key !== 'colors') {
                currentAssys = currentAssys.map((selectedAssy) => ({ ...selectedAssy, [key]: assy[key] }));
            }

            if(key === "colors")
            {
                const isMainPaletteSet = assy.palette_color !== "";

                currentAssys = currentAssys.map((selectedAssy) => {
                    let willBeCustomPalette = !selectedAssy.palette_color;
                    const assyWHeader = assy.header_line ? assy : selectedAssy;
                    const assyPC = assy.palette_color ? assy : selectedAssy;
                    const colorOptionlist = colorOptions({...selectedAssy, ...assyWHeader});
                    const paletteList = colorPalettesInner({...selectedAssy, ...assyWHeader});
                    const colorOption = colorOptionlist.find((color) => color.name === assyWHeader.palette_color);
                    const newColors = paletteList.reduce((total,pl) => {
                        if(colorOptionlist.some(col => col?.defaultColors[pl.name]))
                        {
                            let expectedOption = isMainPaletteSet ? colorOption?.defaultColors[pl.name] : (assy.colors[pl.name] ?? colorOption?.defaultColors[pl.name]);
                            
                            if(expectedOption === "Missing Default Color" || expectedOption === "")
                            {
                                expectedOption = paletteList.find(p => p.name == pl.name)?.choices[0] ?? "Missing Default Color";
                            }

                            total[pl.name] = expectedOption ?? "";
                        }
                        return total;

                    }, {...selectedAssy?.colors ?? {}});
                    return { ...selectedAssy, colors: newColors, palette_color: willBeCustomPalette ? "Custom Palette" : assyPC.palette_color };
                });
            }
        });
        store.dispatch(updateAssemblies({
            quoteId,
            assemblies: currentAssys
        }));
    };
    const quoteType = useQuoteTypeNameByQuoteId(quoteId);


        const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
        const popupRef = useRef<HTMLDivElement | null>(null);
    
        const indoorFabrics = useIndoorFabrics(quoteId);
    
        const user = useUser();
        const quote = useQuoteById(quoteId);
        const assemblyOptions = UsePortalSelector(useAssemblyOptions(quoteId));
    
        const shadeOptionsBase = useNewIndoorShadeOptions({ ...InitialAssemblyState(), ...incomingAssembly }, setAssembly, { fabrics: indoorFabrics, user, quote, assemblyOptions }, true);

        const getFullPaletteList = useGetFullPaletteList(quoteId);


        const colorPalettes: AssemblyOptionColorPalette[] = [];

        const assemblies = useAssembliesByQuoteId(quoteId);

        assemblies.forEach((assembly) => {
            const paletteColors = getFullPaletteList(assembly);

            paletteColors.forEach((color) => {
                if (!colorPalettes.some(c  => c.name == color.name) ) {
                    colorPalettes.push(color);
                }
            });

        });
        
        const possibleColors = assemblies.map(a => colorOptions(a))
        const possibleColorsCombined = possibleColors.reduce((acc, val) => {
            const temp = [...acc];

            val.forEach((color) => {
                if (!temp.some(c => c.name === color.name)) {
                    temp.push(color);
                }
            });

            return temp;
        }, []);

        const colorPaletteOption: PortalShadeOption<string> = {
            varName: "color_palette",
            options: possibleColorsCombined.map((palette) => {
                const item: PortalShadeOptionItem<string> = {
                    value: palette.name,
                    label: palette.name,
                    title: palette.name,
                };

                return item;
            }),
            label: "Color Palette",
            error: [],
            getSelectedOption: () => {
                const item = possibleColorsCombined.find((palette) => palette.name === assembly.palette_color);
                
                return {
                    label:item?.name ?? "Custom Palette",
                    value:( item?.name ?? "Custom Palette"),
                    title:( item?.name ?? "Custom Palette")
                };
            },
            setValue: (value) => setAssembly({ ...incomingAssembly, palette_color: value }),
            clearValue: () => setAssembly({ ...incomingAssembly, palette_color: "" }),
            active: true,
            info: [],
            valuePopup: false,
            getValue: () => assembly.palette_color,
            
        }

        const colorOptionss =selectedAssemblies.reduce((all,sa) => {
            const palettes = getFullPaletteList(sa);

            const next = [...all];

            palettes.forEach((pa)=> {
                if(!next.some(c => pa.name ===c.name))
                {
                    next.push({name: pa.name, choices: pa.choices.map(p => p)});
                }
            })

            return next;

        }, [] as AssemblyOptionColorPalette[])

        const colorOptionsTyped = colorOptionss.map((palette) => {
            const item: PortalShadeOption<string> = {
                varName: palette.name,
                options: palette.choices.map((color) => {
                    const item: PortalShadeOptionItem<string> = {
                        value: color,
                        label: color,
                        title: color,
                    };

                    return item;
                }),
                label: palette.name,
                error: [],
                getSelectedOption: () => {
                    const item = palette.choices.find((color) => incomingAssembly.colors[palette.name] === color);
                    return {
                        label: item ?? "Missing Default Color",
                        value: item ?? "Missing Default Color",
                        title: item ?? "Missing Default Color"
                    };
                },
                setValue: (value) => setAssembly({ ...assembly, colors: { ...incomingAssembly.colors, [palette.name]: value } }),
                clearValue: () => setAssembly({ ...assembly, colors: { ...incomingAssembly.colors, [palette.name]: "" } }),
                active: true,
                info: [],
                valuePopup: false,
                getValue: () => incomingAssembly.colors[palette.name] ?? "",

            }

            return item;
        });

        const shadeOptions: IndoorShadeOptionsDict = {
            ...shadeOptionsBase,
            ColorPalettes: colorPaletteOption,
            Colors: colorOptionsTyped
        };
    
    
        const closeAll = () => {
            setIsColorPickerOpen(false);
            onClose();
        };
    
        useEffect(() => {
            setAssembly(incomingAssembly);
        }, [isOpen]);
    
        useEffect(() => {
            const handleClickOutside = (event: MouseEvent) => {
                if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                    closeAll();
                }
            };
    
            document.addEventListener("mousedown", handleClickOutside);
    
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [popupRef, onClose]);
    
        const submitShade = () => {
            setAssemblyIncoming(incomingAssembly);
            closeAll();
        };
    
        const [msrp, setMsrp] = React.useState(assembly.msrp);
        const [errorsFromBackend, setErrors] = React.useState(assembly.error_messages ?? "");
    
        const quoteStore = useQuoteIdAndDispatch(quoteId);
    
        const quoteCombinations = useQuoteCombination(quoteId);
    
        const feErrors = useAssemblyErrorsByStore(assembly, quoteStore ?? initialQuote(), assemblies, quoteCombinations ?? { Fascias: [] }, shadeOptions);
    
        let errorList = [
            ...feErrors.filter(e => !errorsFromBackend.includes(e.message.trim())).map((error) => error.message),
        ];
    
        if (errorsFromBackend !== "") {
            errorList = [ errorsFromBackend];
        }
    
        // Grab errors, delete extra commas, and join them into a single string
        const errors = errorList.filter((error) => error !== "").reduce((acc, error) => `${acc + error} `, "");
        const feErrorsToSend = feErrors;
        useEffect(() => {   
            if (quoteId === 0 || !isOpen) return;
            setMsrp(0);
            setErrors("");
            if(feErrors.length > 0) return;
            apiCalls.calculateAssemblyPriceNew(AssemblyViewModelFromAssemblyStore(assembly), quoteId, "Indoor").then((res) => {
                setMsrp(res?.data?.msrp ?? 0);
                const errorMessage = res?.data?.errors.map(e => e.message);
                const errorMessageListAsOneString = errorMessage?.join(", ") ?? "";
                setErrors(errorMessageListAsOneString);
            });
        }, [assembly, isOpen]);
    
        if (!isOpen) return null;
    

    return (
        <Popup open={isOpen} onClose={closeAll} className="mobile-modal p-2 m-2">
                <Row>
                    {
                    <h2>Mass Edit Shade</h2>
                    }
                </Row>
                <EditIndoorShadeModalInner
                    options={shadeOptions}
                    quoteType={quoteType}
                    isNotAdd={true}
                    massEdit={true}
                    unlocked={assembly.unlocked}
                    errors={feErrorsToSend}
                    pickerState={isColorPickerOpen}
                    setPickerState={setIsColorPickerOpen}
                />
                {
                    !isColorPickerOpen && (
                        <PriceErrors quoteId={quoteId} msrp={ msrp} error_messages={msrp !== 0 ? "" :errors ?? ""} assembly={incomingAssembly} noLoading={true} />
                    )
                }
                <Col className="d-flex flex-column align-items-center justify-content-center">
                    <Button
                    className="col-lg-4"
                        onClick={(
                            isColorPickerOpen
                            ? () => setIsColorPickerOpen(false)
                            : submitShade)}
                    >
                        Done
                    </Button>
                {
                    (!assembly.unlocked)
                        ? <Button className="col-lg-4 mt-1" onClick={() =>{
                             setAssembly({ ...incomingAssembly, unlocked: true }); 
                             }}>
                                <FontAwesomeIcon icon={faLock} />
                            </Button>
                        : null
                }
                </Col>
        </Popup>
    )
};

export default MassEditIndoorModal;
