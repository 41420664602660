import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faPen } from '@fortawesome/free-solid-svg-icons';

import type { MarketingIconProps } from './types';

const NotPlacedIcon = ({
	size = '1x',
	bottomColorOverride = '',
	topColorOverride = '',
	className = '',
}: MarketingIconProps) => (
	<span className={`fa-stack ${className}`}>
		<FontAwesomeIcon
			icon={faFile}
			size={size}
			className={`fa-stack-2x ${bottomColorOverride && `text-${bottomColorOverride}`}`}
		/>
		<FontAwesomeIcon
			icon={faPen}
			size={size}
			className={`fa-stack-1x stacked-logo text-${topColorOverride}`}
		/>
	</span>
);

export default NotPlacedIcon;
