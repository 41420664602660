/* eslint-disable camelcase */
/* eslint-disable max-classes-per-file */
import React, { ReactNode, useState } from 'react';
import { faEdit, faList, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'react-bootstrap';

import ShadePriceBreakdown from './ShadeActions/ShadePriceBreakdown';
// import ShadeForm from '../ShadeForm/ShadeForm';

import ServiceAssembly from './ShadeActions/ServiceAssembly';
import Button from '../../Parts/Button';
import HeaderData from '../NewItems/HeaderData.js';
import { deleteAssemblies } from '../../Store/entities/assemblies/index';
import { UseAssembly } from "../../Store/entities/assemblies/hooks";
import { useQuoteById, useQuoteTypeNameByQuoteId } from '../../Store/entities/quotes/hooks';
import { UsePortalDispatch } from '../../Store/index';
import { useIsEngineer, useIsSales, useIsService } from '../../Store/user/hooks';
import { useIsPsAdmin } from '../../hooks.js';
import { QuoteData } from '../NewItems/QuoteData.js';
import ShadeSummary from './ShadeSummaryNew';
import EditPSOutdoorShadeModal from '../../Modals/ShadeModals/EditShade/EditPSOutdoorShade/EditPSOutdoorShadeModal';
import { fakeRootType } from '../../FakeRootType.js';
import EditIndoorShadeModal from '../../Modals/ShadeModals/EditShade/EditIndoorShade/EditIndoorShadeModal';
import { QuoteTypeNames } from '../../powershadesApiTypes';
import ShadeDuplicate from './ShadeDuplicate';
import EditCOROutdoorShadeModal from '../../Modals/ShadeModals/EditShade/EditCOROutdoorShade/EditCOROutdoorShadeModal';

type ShadeActionsProps = {
	quoteId: number;
	sequenceId: number;
	quoteData: QuoteData;
};

const ShadeActions = ({ quoteId, sequenceId, quoteData }: ShadeActionsProps) => {
	const quote = useQuoteById(quoteId);

	const assembly = UseAssembly(quoteId, sequenceId);

	const buttons = [] as ReactNode[];

	// const shade = this.props.quoteData.shadeSubstitute(sequenceId);
	const {
		editable,
		previous_quote_id
	} = quote;

	const quoteType = useQuoteTypeNameByQuoteId(quoteId);

	const [shadeFormOpen, setShadeFormOpen] = useState(false);
	const [shadePriceBreakdownOpen, setShadePriceBreakdownOpen] = useState(false);

	// const closeEditModel = () => {
	// 	quoteData.UI.reRenderTable(true);
	// 	setShadeFormOpen(false);
	// }

	const dispatch = UsePortalDispatch();
	const userIsPsAdmin = useIsPsAdmin();

	const isEngineer = useIsEngineer();
	const isService = useIsService();
	const isSales = useIsSales();
	const userHasServiceAccess = isEngineer || isService || isSales;
	if (!quote) return null;
	if (!assembly) return null;
	const { shade_name: shadeName } = assembly;
	const {
		toast,
		alert
	} = quoteData.quoteLevelVariables().ROOT as fakeRootType;

	const shade = quoteData.shadeSubstitute(sequenceId);

	// const onDuplicate = () => {
	// 	const maxSeqId = Math.max(...assys.map((a) => a.sequence_id));

	// 	const nameSet = assembly?.shade_name?.toString()?.split(" ") ?? "";

	// 	let number = nameSet[nameSet.length - 1] ?? "";

	// 	let newName = assembly.shade_name;

	// 	if (isNaN(Number(number)) || number === "") {
	// 		number = "1";
	// 		nameSet.push(number);
	// 	}
	// 	let newNumber = parseInt(number) + 1;

	// 	const shadeNames
	// 		= assys.map((sa) => sa?.shade_name?.toString().trim()) ?? "";

	// 	nameSet[nameSet.length - 1] = `${newNumber}`;

	// 	newName = nameSet.join(" ");

	// 	while (shadeNames.includes(newName.trim())) {
	// 		nameSet[nameSet.length - 1] = `${++newNumber}`;
	// 		newName = nameSet.join(" ");
	// 	}

	// 	dispatch(duplicateAssembly({
	// 		quoteId: quoteId,
	// 		sequenceId: sequenceId,
	// 		inputs: {
	// 			new_shade_name: `${newName}`,
	// 			requested_new_sequence_id: maxSeqId + 1,
	// 		}
	// 	})).then(() => {
	// 		toast(`${shadeName} duplicated`, {
	// 			type: 'success',
	// 		});
	// 	}).catch(() => {
	// 		toast(`Duplication of ${shadeName} failed`, {
	// 			type: 'info',
	// 		});
	// 	});

	// }

	const onDelete = () => {
		alert({
			title: 'Are you sure you want to delete this shade?',
			text: `You are about to delete ${shadeName}! There's no way to undo this.`,
			icon: 'warning',
			quoteType,
		})
			.then(() => {
				dispatch(deleteAssemblies({
					quoteId,
					sequenceIds: [sequenceId],
				})).then(() => {
					toast(`${shadeName} deleted`, {
						type: 'success',
					});
				}).catch(() => {
					toast(`Deletion of ${shadeName} failed`, {
						type: 'info',
					});
				});
			})
			.catch(() => {
				toast(`Deletion of ${shadeName} canceled`, {
					type: 'info',
				});
			});
	};

	const displayItems = [] as ReactNode[];

	const shadeData = shade?.data() ?? {};

	const ignoredFields = [
		'cost',
		'base_sale_price',
		'dealer_sale_price',
		'api_motor_id',
		'hidden_shipping',
		'ShadesCost',
		'ShadesMsrp',
		'tube_name'
	];

	const specialFields = [
		{
			varName: 'header_type',
			action: (shadeData) => {
				const headerType = HeaderData().find((i) => i.value == shadeData.header_type);

				return headerType?.name ?? shadeData.header_type;
			},
		},
		{
			varName: 'motor',
			action: (_shadeData, _shade, specifcShadeData) => {
				let { motor } = specifcShadeData;
				// Motor Name Substitution.
				if (motor == 'DM35CEQ/S-2/28') {
					motor = '35 mm 12VDC Hardwired Motor';
				} else if (motor == 'DM35LEQ/S-2/28') {
					motor = '35 mm Li-Ion RF Motor';
				}
				return motor;
			},
		},
	];

	let sd_index = 0;
	for (const sd in shadeData) {
		if (sd == 'shades' || ignoredFields.includes(sd)) {
			continue;
		}

		const special = specialFields.find((sf) => sf.varName == sd);

		const value = special ? special.action(shadeData, shade, {}) : shadeData[sd];
		sd_index++;
		displayItems.push(<tr key={`sd-${sd_index}`}>
			<th>
				{sd}
				:
				{' '}
			</th>
			<td>{value}</td>
		</tr>);
	}

	shadeData?.shades?.forEach((s) => {
		displayItems.push(<tr key={`${s.row_coordinate}-${s.column_coordinate}`}>
			<th>
				<h4>
					Shade Row
					{' '}
					{s.row_coordinate}
					{' '}
					Column
					{' '}
					{s.column_coordinate}
				</h4>
			</th>
		</tr>);

		let s_index = 0;

		for (const sKey in s) {
			if (ignoredFields.includes(sKey)) continue;

			const special = specialFields.find((sf) => sf.varName == sKey);

			const value = special ? special.action(shadeData, shade, s) : s[sKey];

			s_index++;

			displayItems.push(<tr key={`s-${s_index}`}>
				<th>
					{sKey}
					:
					{' '}
				</th>
				<td>{value}</td>
			</tr>);
		}
	});

	if (editable) {
		if (shade.isPSOutdoorShade()) {
			buttons.push(<Col>
				<EditPSOutdoorShadeModal
					isOpen={shadeFormOpen}
					onClose={() => setShadeFormOpen(false)}
					quoteId={quoteId}
					sequenceId={assembly.sequence_id}
				/>
				<Button
					title="Edit"
					size="sm"
					hoverColor={quoteType}
					fullWidth
					color="light"
					onClick={() => setShadeFormOpen(true)}
				>
					<FontAwesomeIcon icon={faEdit} />
				</Button>
			</Col>);
		} else if (shade.isOutdoorShade()) {
			buttons.push(<Col>
				<EditCOROutdoorShadeModal
					isOpen={shadeFormOpen}
					onClose={() => setShadeFormOpen(false)}
					quoteId={quoteId}
					sequenceId={assembly.sequence_id}
				/>
				<Button
					title="Edit"
					size="sm"
					hoverColor={quoteType}
					fullWidth
					color="light"
					onClick={() => setShadeFormOpen(true)}
				>
					<FontAwesomeIcon icon={faEdit} />
				</Button>
			</Col>);
		} else {
			buttons.push(<Col>
				<EditIndoorShadeModal
					isOpen={shadeFormOpen}
					onClose={() => setShadeFormOpen(false)}
					quoteId={quoteId}
					sequenceId={assembly.sequence_id}
				/>
				<Button
					title="Edit"
					size="sm"
					hoverColor={quoteType}
					fullWidth
					color="light"
					onClick={() => setShadeFormOpen(true)}
				>
					<FontAwesomeIcon icon={faEdit} />
				</Button>
			</Col>);
		}

		buttons.push(<Col>
			<ShadeDuplicate
				quoteId={quoteId}
				sequenceId={sequenceId}
			/>

		</Col>);

		buttons.push(<Col>
			<Button
				fullWidth
				color="light"
				size="sm"
				hoverColor={quoteType}
				key="delete"
				title="Delete"
				onClick={onDelete}
			>
				<FontAwesomeIcon icon={faTrashAlt} />
			</Button>
		</Col>);
	}

	buttons.push(<ShadeSummary
		quoteId={quoteId}
		sequenceId={sequenceId}
	/>);
	// Add ShadeSummary
	// Add PriceBreakdown
	if (userIsPsAdmin) {
		buttons.push(<Col>
			<Button
				fullWidth
				color="light"
				size="sm"
				title="Price Breakdown"
				hoverColor={quoteType}
				onClick={() => setShadePriceBreakdownOpen(true)}
				className=""
			>
				<FontAwesomeIcon icon={faList} />
			</Button>
			{shadePriceBreakdownOpen && <ShadePriceBreakdown
				quoteType={quoteType as QuoteTypeNames}
				sequenceId={sequenceId}
				quoteId={quoteId}
				// Old Object
				done={() => setShadePriceBreakdownOpen(false)}
				close={() => setShadePriceBreakdownOpen(false)}
				open={shadePriceBreakdownOpen}
			/>}
		</Col>);
	}

	if (userHasServiceAccess) {
		buttons.push(<ServiceAssembly
			// SetShadeToBeMadeFunction={(value, row, column) => quoteData.SetShadeToBeMade(sequenceId, value, row, column)}
			// SetFabricToBeCut={(value) => quoteData.SetFabricToBeCut(sequenceId, value)}
			// SetHeaderExtrusionToBeCut={(value) =>
			// 	quoteData.SetHeaderExtrusionToBeCut(sequenceId, value)
			// }
			// SetHembarToBeCut={(value) => quoteData.SetHembarToBeCut(sequenceId, value)}
			// SetSideChannelsToBeCut={(value) => quoteData.SetSideChannelsToBeCut(sequenceId, value)}
			// SetSillChannelsToBeCut={(value) => quoteData.SetSillChannelsToBeCut(sequenceId, value)}
			// SetTubeToBeCut={(value) => quoteData.SetTubeToBeCut(sequenceId, value)}
			quoteType={quoteType as QuoteTypeNames}
			key={`${sequenceId}-service-assembly`}
			// assembly={shade.data() as unknown as AssemblyShadePayload}
			// SetIsMotorOnly={(value) => quoteData.SetIsMotorOnly(sequenceId, value)}
			// SetIsChannelsOnly={(value) => quoteData.SetIsChannelsOnly(sequenceId, value)}
			SetRecipeSku={(value) => quoteData.SetRecipeSku(sequenceId, value)}
			// SetIsTubeAndFabricOnly={(value) => quoteData.SetIsTubeAndFabricOnly(sequenceId, value)}
			// SetIsHeaderExtrusionOnly={(value) =>
			// 	quoteData.SetIsHeaderExtrusionOnly(sequenceId, value)
			// }
			// SetIsHardwareOnly={(value) => quoteData.SetIsHardwareOnly(sequenceId, value)}
			// SetIsShadeService={(value) => quoteData.SetIsShadeService(sequenceId, value)}
			// SetTubeOverride={(value) => quoteData.SetTubeOverride(sequenceId, value)}
			// SetMotorOverride={(value) => quoteData.SetMotorOverride(sequenceId, value)}
			// SetIsCrownAndDriveOnly={(value) => quoteData.SetIsCrownAndDriveOnly(sequenceId, value)}
			previousQuoteId={previous_quote_id ?? 0}
			sequenceId={sequenceId}
			quoteId={quoteId}
		/>);
	}
	return (
		<div className="justify-content-between g-0" style={{ columnCount: 2, columnGap: 0 }}>{buttons}</div>
	);
};

export default ShadeActions;
