import { useState } from 'react';
import hash from 'object-hash';

import { isFunction } from 'lodash';
import InfoBox from './InfoBox';
import OptionField from '../OptionField';

export default function ShadeFormOptions(props) {
	const { quote, shade } = props;
	const [shadeHash, setShadeHash] = useState(null);

	function blurred(arg) {
		setShadeHash(hash(shade.savableObject()));

		props.onOptionBlur && props.onOptionBlur(arg);
	}

	function changed(arg) {
		setShadeHash(hash(shade.savableObject()));

		props.onOptionChange && props.onOptionChange(arg);
	}

	const fields = [];

	let current_group = [];
	let current_group_name = '';
	let last_opt;
	let i = 0;

	let form_group_z_index = 80;

	shade.options()
		.filter((opt) => opt.showOnForm || opt.showOnForm == null)
		.forEach((option) => {
			const opt_group_name = isFunction(option.groupName) ? option.groupName(shade) : option.groupName;

			if (!option.conditional || option.conditional(shade)) {
				if (!opt_group_name) {
					if (current_group.length > 0) {
						fields.push(

							<div key={`form-group-${current_group_name}`} className={`form-group no-bottom${last_opt.inlineGroup ? 'inline-group' : ''}`} style={{ zIndex: form_group_z_index-- }}>
								<span className="form-group-title">{current_group_name}</span>
								{current_group}
							</div>
						);
					}

					current_group = [];
					current_group_name = '';

					fields.push(

						<OptionField
							key={`${option.varName}-${option.value}`}
							option={option}
							onFocus={props.setFocusedOption}
							onBlur={blurred}
							onChange={changed}
							quote={quote}
						/>
					);

					if (option.info != null && option.info(shade) != null) {
						fields.push(
							<InfoBox key={`info-box-${option.varName}`} message={option.info(shade)} />
						);
					}
				} else if (opt_group_name != current_group_name) {
					if (current_group.length > 0) {
						fields.push(

							<div
								key={`form-group-${current_group_name}`}
								className={`form-group no-bottom ${last_opt.inlineGroup ? 'inline-group' : ''}`}
								style={{ zIndex: form_group_z_index-- }}
							>
								<span className="form-group-title">{current_group_name}</span>
								{current_group}
							</div>
						);
					}

					current_group = [];
					current_group_name = '';

					current_group_name = opt_group_name;

					current_group.push(

						<OptionField
							option={option}
							onFocus={props.setFocusedOption}
							onBlur={blurred}
							onChange={changed}
							quote={quote}
							key={`product-form${option.varName}`}
						/>
					);

					if (option.info != null && option.info(shade) != null) {
						current_group.push(
							<InfoBox message={option.info(shade)} />
						);
					}
				} else {
					current_group_name = opt_group_name;

					current_group.push(

						<OptionField
							option={option}
							onFocus={props.setFocusedOption}
							onBlur={blurred}
							quote={quote}
							key={`product-form${option.varName}`}
						/>
					);

					if (option.info != null && option.info(shade) != null) {
						current_group.push(
							<InfoBox message={option.info(shade)} />
						);
					}
				}
			}
			const a = 431;

			last_opt = option;

			i++;
		});

	if (current_group.length > 0) {
		fields.push(

			<div
				key={`form-group-${current_group_name}`}
				className={`form-group no-bottom ${(last_opt && last_opt.inlineGroup) ? 'inline-group' : ''}`}
				style={{ zIndex: form_group_z_index-- }}
			>
				<span className="form-group-title">{current_group_name}</span>
				{current_group}

			</div>
		);
	}

	return fields;
}
