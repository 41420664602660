import Popup from 'reactjs-popup';
import { useState } from 'react';
import QUOTE_GLOBALS from './QuoteGlobals';

export default function IndoorShadeFormFields() {
	return [

		{
			varName: 'indoor_outdoor',
			value: 'indoor',
		},
		{
			varName: 'shade_assembly_id'
		},
		{
			varName: 'shade_1_id'
		},
		{
			varName: 'shade_2_id'
		},
		{
			varName: 'tube_sku'
		},

		{
			varName: 'shade_type',
			label: 'Shade Type',
			type: 'select',
			choices: [
				{ name: 'Manual', value: 'manual' },
				{
					name: 'Motorized',
					value: 'motorized',
					onSelect: (quote, shade) => {
						const value = shade.val('lv_power_source');
						if (value !== '') {
							shade.changeAccessoryCharging(true, value === 'solar_panel');
						}
					},
					onDeselect: (quote, shade) => {
						const value = shade.val('lv_power_source');
						if (value !== '') {
							shade.changeAccessoryCharging(false, value === 'solar_panel');
						}
					}
				}
			],
			causesReprice: true,
			groupName: 'Motor',
			inlineGroup: true
		},

		{
			varName: 'manual_chain',
			label: 'Chain Type',
			type: 'select',
			groupName: 'Motor',
			inlineGroup: true,
			conditional: (shade) => shade.valMatch('shade_type', 'Manual'),
			choices: [{ name: 'Stainless Steel', value: 'stainless_steel' }, { name: 'Plastic', value: 'plastic' }]
		},

		{
			varName: 'motor_type',
			label: 'Motor Type',
			type: 'select',
			groupName: 'Motor',
			value: null,
			conditional: (shade) => shade.valMatch('shade_type', 'Motorized'),
			error: (shade) => { },
			choices: [
				{ name: 'PoE', value: 'poe' },
				{ name: 'High-Voltage', value: 'high_voltage' },
				{ name: 'Low-Voltage (Hardwired)', value: 'low_voltage_hw' },
				{
					name: 'Low-Voltage (Internal Li-Ion Battery)',
					value: 'low_voltage',
					onSelect: (quote, shade) => {
						const value = shade.val('lv_power_source');
						if (value !== '') {
							shade.changeAccessoryCharging(true, value === 'solar_panel');
						}
					},
					onDeselect: (quote, shade) => {
						const value = shade.val('lv_power_source');
						if (value !== '') {
							shade.changeAccessoryCharging(false, value === 'solar_panel');
						}
					}
				}
			],
			causesReprice: true,
		},

		{
			varName: 'width',
			label: 'Width',
			groupName: 'WH',
			inlineGroup: true,
			type: 'fraction',
			error: (shade) => {
				const fab1 = QUOTE_GLOBALS.FABRICS.find((f) => f.name === shade.val('fabric_name'));
				const fab2 = QUOTE_GLOBALS.FABRICS.find((f) => f.name === shade.val('fabric2_name'));

				if (fab1 && !fab1.is_railroadable && shade.val('width') > 144) {
					return `${shade.isDualShade() ? 'Inside fabric ' : 'Fabric'} is not railroadable. Please enter a width of 144" or less.`;
				} else if (fab2 && !fab2.is_railroadable && shade.val('width') > 144) {
					return `${shade.isDualShade() ? 'Outside fabric ' : 'Fabric'} is not railroadable. Please enter a width of 144" or less.`;
				}
			},
			info: (shade) => (shade.valMatch('shade_type', 'Manual') && shade.val('width') && shade.val('width') < 18 ? 'Shades less than 18 inches wide are not upgradeable to motorized shades.' : null),
			minValue: 0,
			maxValue: 300,
			causesReprice: true

		},

		{
			varName: 'height',
			label: 'Height',
			groupName: 'WH',
			inlineGroup: true,
			type: 'fraction',
			info: (shade) => (shade.val('width') && shade.val('height') && shade.val('height') >= shade.val('width') * 3
				? 'For Optimal Tracking, a width-to-length ratio of less than 3:1 is recommended.'
				: null
			),
			minValue: 1,
			maxValue: 384,
			causesReprice: true,
			error: (shade) => {
				if (shade.val('height') > 240) {
					return 'Please contact PowerShades for shades greater than 240" in length.';
				}
			}
		},

		{
			varName: 'mount_type',
			label: 'Mount Type',
			type: 'select',
			choices: [
				{ name: 'Inside' },
				{ name: 'Outside' },
				{ name: 'Fabric Width' }
			],
			groupName: 'mount_type_single_dual',
			inlineGroup: true
		},

		{
			varName: 'single_dual',
			label: 'Single or Dual Shade',
			type: 'select',
			choices: [{ name: 'Single' }, { name: 'Dual' }],
			causesReprice: true,
			groupName: 'mount_type_single_dual',
			inlineGroup: true
		},

		{
			varName: 'roll_direction',
			label: 'Roll Direction',
			groupName: (shade) => 'Roll Direction',
			type: 'select',
			description: <span>
Standard Roll (off the back of the roll)
				<br />
                      Reverse Roll (off the front of the roll)
                </span>,
			choices: [
				{ name: 'Standard Roll', value: 'standard' },
				{
					name: 'Reverse Roll', value: 'reverse', conditional: (shade) => !shade.val('header_type').includes('FASCIA')
				}
			]
		},

		{
			varName: 'roll_direction2',
			label: 'Outside Roll Direction',
			groupName: 'Roll Direction',
			type: 'select',
			conditional: (shade) => shade.isDualShade(),
			choices: [
				{ name: 'Standard Roll', value: 'standard' },
				{
					name: 'Reverse Roll', value: 'reverse', conditional: (shade) => !shade.val('header_type').includes('FASCIA')
				}
			]
		},

		{
			varName: 'header_type',
			label: 'Header Type',
			groupName: 'Header',
			type: 'select',
			error: (shade) => (shade.isDualShade() & (`${shade.val('header_type')}`).includes('Cassette') ? 'Cassette headers are not compatible with dual shades.' : null
                || shade.isDualShade() & (`${shade.val('header_type')}`).includes('Open Roll Small') ? 'Small Open Roll is not compatible with dual shades.' : null),
			causesReprice: true,
			choices: (shade) => {
				const bothOpen = [
					{
						name: 'Open Roll Medium', value: 'OR_1234', conditional: (shade) => true
					},
				];

				const threeInchCassette = [
					{
						optGroup: 'Cassette (Fabric Insert Included)',
						value: 'RC3051-A',
						name: 'Cassette 3 in. Anodized',
						conditional: (shade) => !shade.isDualShade()
					},
					{
						optGroup: 'Cassette (Fabric Insert Included)',
						value: 'RC3051-BK',
						name: 'Cassette 3 in. Black',
						conditional: (shade) => !shade.isDualShade()
					},
					{
						optGroup: 'Cassette (Fabric Insert Included)',
						value: 'RC3051-DBR',
						name: 'Cassette 3 in. Bronze',
						conditional: (shade) => !shade.isDualShade()
					},
					{
						optGroup: 'Cassette (Fabric Insert Included)',
						value: 'RC3051-LB',
						name: 'Cassette 3 in. Off-White',
						conditional: (shade) => !shade.isDualShade()
					},
					{
						optGroup: 'Cassette (Fabric Insert Included)',
						value: 'RC3051-W',
						name: 'Cassette 3 in. White',
						conditional: (shade) => !shade.isDualShade()
					},
					{
						optGroup: 'Cassette (Fabric Insert Included)',
						value: 'RC3051-AN',
						name: 'Cassette 3 in. Anthracite',
						conditional: (shade) => !shade.isDualShade()
					},
					{
						optGroup: 'Square Cassette',
						value: 'RC3029-A',
						name: 'Square Cassette 3 in. Anodized',
						conditional: (shade) => !shade.isDualShade()
					},
					{
						optGroup: 'Square Cassette',
						value: 'RC3029-BK',
						name: 'Square Cassette 3 in. Black',
						conditional: (shade) => !shade.isDualShade()
					},
					{
						optGroup: 'Square Cassette',
						value: 'RC3029-W',
						name: 'Square Cassette 3 in. White',
						conditional: (shade) => !shade.isDualShade()
					},
					{
						optGroup: 'Square Cassette',
						value: 'RC3029-LB',
						name: 'Square Cassette 3 in. Off-White',
						conditional: (shade) => !shade.isDualShade()
					},
				];

				const threeInchFascia = [
					{
						optGroup: 'Fascia',
						value: 'RC3080-A',
						name: 'Fascia 3 in. Anodized',
						conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC3080-AN',
						name: 'Fascia 3 in. Anthracite',
						conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC3080-BK',
						name: 'Fascia 3 in. Black',
						conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC3080-DBR',
						name: 'Fascia 3 in. Bronze',
						conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC3080-LB',
						name: 'Fascia 3 in. Off-White',
						conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC3080-W',
						name: 'Fascia 3 in. White',
						conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
					},
				];

				const fourInchFasciaCou = [
					{
						optGroup: 'Fascia',
						value: 'RC3158-A',
						name: 'Fascia 4 in. Anodized',
						conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC3158-AN',
						name: 'Fascia 4 in. Anthracite',
						conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC3158-BK',
						name: 'Fascia 4 in. Black',
						conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC3158-DBR',
						name: 'Fascia 4 in. Bronze',
						conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC3158-LB',
						name: 'Fascia 4 in. Off-White',
						conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC3158-W',
						name: 'Fascia 4 in. White',
						conditional: (shade) => !shade.isDualShade() // && !shade.valIncludes('motor_type', 'high_voltage')
					},
				];

				const fiveInchFascia = [
                    
					{
						optGroup: 'Fascia',
						value: 'RC4039-A',
						name: 'Fascia 5 in. Anodized',
						// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC4039-AN',
						name: 'Fascia 5 in. Anthracite',
						// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC4039-BK',
						name: 'Fascia 5 in. Black',
						// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC4039-DBR',
						name: 'Fascia 5 in. Bronze',
						// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC4039-LB',
						name: 'Fascia 5 in. Off-White',
						// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
					},
					{
						optGroup: 'Fascia',
						value: 'RC4039-W',
						name: 'Fascia 5 in. White',
						// conditional: shade =>  !shade.valIncludes('motor_type', 'high_voltage')
					},
				];

				const mediumLargeOpen = [
					{
						name: 'Open Roll Large (Single)', value: 'OR_COULLISE_LARGE_BALANCED', conditional: (shade) => !shade.isDualShade()
					},

				];

				const standardOpen = [
					{
						name: 'Open Roll Small',
						value: 'OR_0002',
						conditional: (shade) => shade.valMatch('shade_type', 'Manual') && !shade.isDualShade()
					},
				];

				const fourInchCassette = [
					{
						optGroup: 'Cassette (Fabric Insert Included)',
						value: 'RS-SU1',
						name: 'Cassette 4 in. White',
						conditional: (shade) => false
					},
					{
						optGroup: 'Cassette (Fabric Insert Included)',
						value: 'RS-SU2',
						name: 'Cassette 4 in. Bronze',
						conditional: (shade) => false
					},
					{
						optGroup: 'Cassette (Fabric Insert Included)',
						value: 'RS-SU3',
						name: 'Cassette 4 in. Alabaster',
						conditional: (shade) => false
					},
					{
						optGroup: 'Cassette (Fabric Insert Included)',
						value: 'RS-SU4',
						name: 'Cassette 4 in. Anodized',
						conditional: (shade) => false
					},
					{
						optGroup: 'Cassette (Fabric Insert Included)',
						value: 'RS-SU5',
						name: 'Cassette 4 in. Black',
						conditional: (shade) => false

					}
				];

				const heavyOpen = [
					{
						name: 'Open Roll Large (Dual)', value: 'OR_5678', conditional: (shade) => shade.valMatch('shade_type', 'Motorized')
					},
				];

				const fourInchFascia = [
					{
						optGroup: 'Fascia',
						value: 'RS-SC1',
						name: 'Fascia 4 in. White',
						conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
					},
					{
						optGroup: 'Fascia',
						value: 'RS-SC2',
						name: 'Fascia 4 in. Bronze',
						conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
					},
					{
						optGroup: 'Fascia',
						value: 'RS-SC3',
						name: 'Fascia 4 in. Alabaster',
						conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
					},
					{
						optGroup: 'Fascia',
						value: 'RS-SC4',
						name: 'Fascia 4 in. Anodized',
						conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
					},
					{
						optGroup: 'Fascia',
						value: 'RS-SC5',
						name: 'Fascia 4 in. Black',
						conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
					},
				];

				const fiveAndAHalfInchFascia = [
					{
						optGroup: 'Fascia',
						value: 'RS-SV4',
						name: 'Fascia 5.5 in. Anodized',
						conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
					},
					{
						optGroup: 'Fascia',
						value: 'RS-SV2',
						name: 'Fascia 5.5 in. Bronze',
						conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
					},
					{
						optGroup: 'Fascia',
						value: 'RS-SV1',
						name: 'Fascia 5.5 in. White',
						conditional: (shade) => !shade.isDualShade() && !shade.valIncludes('motor_type', 'low_voltage', 'low_voltage_hw')
					},
				];

				const sevenInchFascia = [
                    
					{
						optGroup: 'Fascia',
						value: 'RS-SK4',
						name: 'Fascia 7 in. Anodized',
						conditional: (shade) => shade.isDualShade() && shade.valMatch('shade_type', 'Motorized')
					},
					{
						optGroup: 'Fascia',
						value: 'RS-SK5',
						name: 'Fascia 7 in. Black',
						conditional: (shade) => shade.isDualShade() && shade.valMatch('shade_type', 'Motorized')
					},
					{
						optGroup: 'Fascia',
						value: 'RS-SK2',
						name: 'Fascia 7 in. Bronze',
						conditional: (shade) => shade.isDualShade() && shade.valMatch('shade_type', 'Motorized')
					},
					{
						optGroup: 'Fascia',
						value: 'RS-SK1',
						name: 'Fascia 7 in. White',
						conditional: (shade) => shade.isDualShade() && shade.valMatch('shade_type', 'Motorized')
					},
				];

				if (!(shade.valMatch('motor_type', 'high_voltage') && shade.isDualShade())) {
					heavyOpen.forEach((h) => h.conditional = () => false);
				}

				if (!shade.valMatch('motor_type', 'high_voltage') && !shade.isDualShade()) {
					fourInchCassette.forEach((h) => h.conditional = () => false);
					[
						...fourInchFascia,
						...fiveAndAHalfInchFascia,
						...sevenInchFascia
					].forEach((h) => h.conditional = () => false);
				} else {
					standardOpen.forEach((h) => h.conditional = () => false);
				}

				const items = [
					...standardOpen, // No Dual
					...bothOpen, // Dual
					...heavyOpen, // Dual
					...threeInchFascia, // No Dual
					...fourInchFasciaCou, // No Dual
					...fourInchFascia, // No Dual
					...fiveInchFascia, // Dual
					...fiveAndAHalfInchFascia, // Dual
					...sevenInchFascia, // Dual
					...threeInchCassette, // No Dual
					...fourInchCassette // No dual
				];

				return items;
			},
			value: null
		},

		{
			varName: 'end_caps',
			label: 'End Caps',
			groupName: 'Header',
			type: 'select',
			conditional: (shade) => !shade.valIncludes('header_type', 'RC3051', 'RS-SU'), // End caps not available for cassettes.
			choices: [

				{
					name: 'None',
					conditional: (shade) => {
						if (shade.valMatch('mount_type', 'Outside') | shade.valMatch('mount_type', 'Fabric Width')) {
							return false;
						}

						return true;
					}
				},
				{
					optGroup: 'Fascia', name: 'Fascia End Caps', conditional: (shade) => shade.valIncludes('header_type', 'RC3080', 'RC4039', 'RS-SC', 'RS-SK', 'RS-SV')
				},
				{
					optGroup: 'Open Roll', name: 'Metal End Caps', conditional: (shade) => shade.valMatch('header_type', 'OR_0002', 'OR_1234') && (!shade.isDualShade())
				},
				{
					optGroup: 'Open Roll', name: 'Plastic End Caps', conditional: (shade) => shade.valMatch('header_type', 'OR_0002', 'OR_1234', 'OR_5678')
				}
			]
		},

		{
			varName: 'open_roll_end_caps_color',
			label: (shade) => (shade.valMatch('end_caps', 'None') ? 'Bracket Color' : 'Open Roll Hardware Color'),
			groupName: 'Header',
			type: 'select',
			choices: [
				{
					conditional: (shade) => shade.valMatch('end_caps', 'None'), name: 'Black', value: 'Black'
				},
				{
					conditional: (shade) => shade.valMatch('end_caps', 'None'), name: 'Gray', value: 'Gray'
				},
				{
					conditional: (shade) => shade.valMatch('end_caps', 'None'), name: 'White', value: 'White'
				},

				{
					conditional: (shade) => shade.valMatch('end_caps', 'Plastic End Caps'), name: 'Anthracite', value: 'Anthracite'
				},
				{
					conditional: (shade) => shade.valMatch('end_caps', 'Plastic End Caps'), name: 'Black', value: 'Black'
				},
				{
					conditional: (shade) => shade.valMatch('end_caps', 'Plastic End Caps'), name: 'Bronze', value: 'Bronze'
				},
				{
					conditional: (shade) => shade.valMatch('end_caps', 'Plastic End Caps'), name: 'Gray', value: 'Gray'
				},
				{
					conditional: (shade) => shade.valMatch('end_caps', 'Plastic End Caps'), name: 'Off-White', value: 'Off-White'
				},
				{
					conditional: (shade) => shade.valMatch('end_caps', 'Plastic End Caps'), name: 'White', value: 'White'
				},

				{
					conditional: (shade) => shade.valMatch('end_caps', 'Metal End Caps'), name: 'Black Nickel', value: 'Black Nickel'
				},
				{
					conditional: (shade) => shade.valMatch('end_caps', 'Metal End Caps'), name: 'Chrome', value: 'Chrome'
				},
				{
					conditional: (shade) => shade.valMatch('end_caps', 'Metal End Caps'), name: 'Natural Aluminum', value: 'Natural Aluminum'
				},
				{
					conditional: (shade) => shade.valMatch('end_caps', 'Metal End Caps'), name: 'Old Brass', value: 'Old Brass'
				},
				{
					conditional: (shade) => shade.valMatch('end_caps', 'Metal End Caps'), name: 'Stainless Steel', value: 'Stainless Steel'
				},
				{
					conditional: (shade) => shade.valMatch('end_caps', 'Metal End Caps'), name: 'White', value: 'White'
				},
			],
			conditional: (shade) => shade.valMatch('header_type', 'OR_1234') || shade.valMatch('header_type', 'OR_5678') || shade.valMatch('header_type', 'OR_0002') || shade.valMatch('header_type', 'OR_COULLISE_LARGE_BALANCED'),
		},

		{
			value: null,
			varName: 'fabric_name',
			label: (shade) => {
				const [displayingIndoor, setDisplayingIndoor] = useState(false);

				const toggleHover = () => setDisplayingIndoor(!displayingIndoor);

				if (shade.isDualShade()) {
					return;
					<div>
						<a onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
							Inside
						</a>
						{' '}
						Fabric
					</div>;
				} return 'Fabric';
			},
			fullWidth: true,
			causesReprice: true,
			groupName: 'Fabric Select',
			type: 'fabricSelect',
			error: (shade) => { },
			choices: () => QUOTE_GLOBALS.INDOOR_FABRICS,
		},

		{
			value: null,
			varName: 'fabric2_name',
			label: 'Outside Fabric (Closest to Window)',
			causesReprice: true,
			fullWidth: true,
			groupName: 'Fabric Select',
			type: 'fabricSelect',
			conditional: (shade) => shade.isDualShade(),
			error: (shade) => { if (shade.isDualShade() && shade.valMatch('fabric2_name', '')) { return 'Please select an outside fabric.'; } },
			choices: () => QUOTE_GLOBALS.INDOOR_FABRICS
		},

		{
			varName: 'control_side',
			label: 'Control Side',
			type: 'select',
			choices: [
				{ name: 'Right' },
				{ name: 'Left' }
			],
			groupName: 'Mount Options',
			inlineGroup: true,
		},

		{
			varName: 'hembar_type',
			groupName: 'Mount Options',
			label: 'Hembar Type',
			type: 'select',
			choices: [
				{ name: 'Internal / Sealed Hembar with Sealed Ends', value: 'internal_sealed' },
				// {name: 'External / Fabric Wrapped', value: 'external_fabric_wrapped'},
				{ name: 'External / Metal', value: 'external_metal' }, // TODO: IF METAL HEMBAR match color options to fascia color choices.
			]
		},

		{
			varName: 'window_jamb_depth',
			label: 'Window Jamb Depth',
			type: 'fraction',
			groupName: 'Mount Options',
			inlineGroup: true,
			minValue: 0,
			maxValue: 384,
			conditional: (shade) => shade.valMatch('mount_type', 'Inside'),
		},

		{
			varName: 'hembar_color',
			groupName: 'Mount Options',
			label: 'Hembar Color',
			type: 'select',
			error: (shade) => (((shade.val('hembar_type').includes('External')) && `${shade.val('hembar_color')}` == '') ? 'Please select a hembar color.' : null),
			conditional: (shade) => shade.valMatch('hembar_type', 'External / Fabric Wrapped', 'External / Metal'),
			choices: (shade) => (

				shade.valMatch('hembar_type', 'External / Metal')

					? shade.fascia_color.choices
					: [
						{ name: 'White' },
						{ name: 'Black' },
						{ name: 'Anodized' }
					]
			)

		},

		{
			varName: 'side_channels',
			groupName: 'Options',
			label: 'Side Channels',
			type: 'select',
			conditional: (shade) => !(shade.val('hembar_type').includes('external')),
			value: "None",
			causesReprice: true,
			choices: [
				{ name: 'None' },
				{ name: 'Side Channels' },
				{ name: 'Sill Channel' },
				{ name: 'Both' }
			]
		},

		{
			varName: 'side_channels_color',
			groupName: 'Options',
			label: 'Side Channels Color',
			type: 'select',
			conditional: (shade) => !(shade.val('hembar_type').includes('external')) && !shade.valMatch('side_channels', 'None'),
			choices: [
				{ name: 'White' },
				{ name: 'Black' },
				{ name: 'Anodized' }
			]
		},

		{
			varName: 'manual_chain_color',
			label: 'Chain Color',
			type: 'select',
			groupName: 'Options',
			inlineGroup: true,
			conditional: (shade) => shade.valMatch('shade_type', 'Manual') && shade.valMatch('manual_chain', 'Plastic'),
			choices: [{ name: 'White', value: 'white' }, { name: 'Black', value: 'black' }]
		},

		{
			varName: 'lv_power_source',
			label: 'How will you power this shade?',
			type: 'select',
			groupName: 'Motor',
			value: null,
			error: (shade) => { },
			choices: [
				// {name: 'Hardwired', value: 'hardwired'},
				{
					name: 'Charge w/ Wall Charger',
					value: 'wall_charger',
					onSelect: (quote, shade) => {
						shade.changeAccessoryCharging(true, false);
					},
					onDeselect: (quote, shade) => {
						shade.changeAccessoryCharging(false, false);
					}
				},
				{
					name: 'Solar Panel',
					value: 'solar_panel',
					onSelect: (quote, shade) => {
						shade.changeAccessoryCharging(true, true);
					},
					onDeselect: (quote, shade) => {
						shade.changeAccessoryCharging(false, true);
					},
				}
			],
			causesReprice: true,
			conditional: (shade) => shade.valMatch('shade_type', 'Motorized') && shade.valMatch('motor_type', 'low_voltage')
		},

		{
			varName: 'floor',
			label: 'Floor',
			groupName: 'Attributes',
			type: 'int',
			minValue: -50,
			maxValue: 200,
			negative_prefix: 'B',
			nullable: () => false
		},

		{
			varName: 'direction_facing',
			label: 'Direction Facing',
			groupName: 'Attributes',
			type: 'select',
			nullable: () => true,
			choices: [
				{ name: 'Unknown', value: '' },
				{ name: 'North' },
				{ name: 'Northeast' },
				{ name: 'East' },
				{ name: 'Southeast' },
				{ name: 'South' },
				{ name: 'Southwest' },
				{ name: 'West' },
				{ name: 'Northwest' }
			]
		},

		{
			varName: 'room_name',
			label: 'Room Name',
			groupName: 'Attributes',
			type: 'text',
			nullable: () => false
		},

		{
			varName: 'shade_name',
			label: 'Shade Name',
			groupName: 'Attributes',
			type: 'text',
			error: (shade) => (shade.isNameUnique() ? null : 'Please create a unique shade name.'),
			nullable: () => false
		},

	];
}
