import React, { useState, Fragment } from 'react';
import Popup from 'reactjs-popup';
import { Container, Row, Col } from "react-bootstrap";

import SpinningWheel from '../../Parts/SpinningWheel';
import Button from '../../Parts/Button';
import QuoteWarningVerificationsFromStore, { UseUnAcknowledgedWarnings } from './QuoteWarningVerificationsFromStore';
import { useQuoteById } from '../../Store/entities/quotes/hooks';
import { useAssembliesByQuoteId } from '../../Store/entities/assemblies/hooks';
import { useQuoteAccessories } from '../../Store/entities/accessories/hooks';
import { UsePortalDispatch } from '../../Store';
import { addQuoteWarning } from '../../Store/entities/quotewarnings';

const QuoteVerifications = (props) => {
	const {
		newQuote,
		editable,
		setShowEditQuoteDisclaimer,
		showConfigurations,
		setShowConfigurations,
		quoteType,
		quoteId
	} = props;
	
	const [processing, setProcessing] = useState([]);

		// 
	// assemblies: AssemblyStore[], accessories: QuoteAccessoryStore[], quote: QuoteStore, dispatch: AppDispatch

	const quote = useQuoteById(quoteId);
	const assemblies = useAssembliesByQuoteId(quoteId);
	const accessories = useQuoteAccessories(quoteId);
	const dispatch = UsePortalDispatch();

	const configurationFixes = UseUnAcknowledgedWarnings(quoteId);
	const ignoreWarning = (warningMessage, warningCode) => {
		dispatch(addQuoteWarning({
			quoteId,
			code: warningCode,
			message: warningMessage
		}));
	};

	const spaces = configurationFixes.map((c) => {
		const str = c.warning(newQuote);
		const key = c.warning_code(newQuote);

		const strItems = str.split('\n');

		const display = (
			<Fragment key={`display-${key}`}>
				{strItems.map((s) => (
					<Fragment key={s}>
						{s}
						<br />
					</Fragment>
				))}
			</Fragment>
		);
		
		const elementIsProcessing = processing.includes(key);

		return (
			<Row className="mb-3 p-3" key={key}>
				<Col xs={12}>
					<Row>
						<Col>{display}</Col>
					</Row>
					{!elementIsProcessing ? (
						<Row className="mt-1">
							{(!c.action || !c.actionLabel) ? null : (
								<Col className="mb-1">
									<Button
										color={quoteType ?? 'green'}
										fluid
										size="sm"
										onClick={() => {
											setProcessing([...processing, key]);
											c.action(() => {
												const removeKey = processing.filter((item) => item !== key);
												setProcessing(removeKey);
											});
										}}
									>
										{c.actionLabel
											? c.actionLabel(newQuote)
											: "Fix"}
									</Button>
								</Col>
							)}
							{c.noManual ? null : (
								<Col>
									<Button
										color={quoteType ?? 'green'}
										size="sm"
										fluid
										onClick={() => setShowConfigurations(false)}
									>
										{c.manualLabel
											? c.manualLabel()
											: "Fix Manually"}
									</Button>
								</Col>
							)}
							<Col>
								<Button
									color={quoteType ?? 'green'}
									size="sm"
									fluid
									onClick={() =>
										ignoreWarning(
											c.warning(newQuote),
											c.warning_code(newQuote)
										)}
								>
									{c.ignoreLabel ? c.ignoreLabel() : "Ignore"}
								</Button>
							</Col>
						</Row>
					) : (
						<Row>
							<Col className="text-center">
								<SpinningWheel />
							</Col>
						</Row>
					)}
				</Col>
			</Row>
		);
	});
	
/*
const { warnings } = this.QuoteSubstitution;

		const replaceMe = warnings.find((w) => w.code == code);

		if (replaceMe) {
			replaceMe.message = message;
			replaceMe.code = code;
		} else {
			warnings.push({
				message,
				code
			});
		}

		api.saveQuoteVerifications(self.Id, warnings).then((resp) => {
			const newWarnings = resp.data.warnings;

			self.Data.quote.warnings = newWarnings;

			self.UI.reRenderTable();
		});
*/

	const noOpen = spaces.length === 0;

	const onOpen = () => {
		if (editable) {
			setShowConfigurations(true);
		} else {
			setShowEditQuoteDisclaimer({
				extraMessage: "",
				action: () => {
					setShowConfigurations(true);
				}
			});
		}
	};

	return (
		<div id={`Verify-Config-${newQuote.Id}`}>
			<div className="local-bootstrap">
				<Container>
					<Row>
						<Col className="text-end">
							<Button color="gray" onClick={onOpen}>
								Verify Config
							</Button>
						</Col>
					</Row>
				</Container>
			</div>
			<Popup
				className="edit mobile-modal"
				open={showConfigurations && !noOpen}
				onClose={() => setShowConfigurations(false)}
				modal
			>
				<Container
					className="local-bootstrap"
					style={{ overflowX: 'hidden', width: 'fit-content' }}
				>
					<Row>
						<Col>
							<h4 className={`text-${quoteType}`}>Verify Shade Configuration(s)</h4>
						</Col>
					</Row>
					<Row className="ms-2">
						<Col>{spaces}</Col>
					</Row>
					<Row>
						<Col className="text-end mb-2">
							<Button
								color={quoteType ?? 'green'}
								onClick={() => setShowConfigurations(false)}
							>
								Close
							</Button>
						</Col>
					</Row>
				</Container>
			</Popup>
		</div>
	);
};

export default QuoteVerifications;
