import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { AssemblyStore } from "../../../../Store/entities/assemblies/types";
import { ShadePart } from "../../../../powershadesApiTypeExtensions";
import apiCalls from "../../../../PowerShadesAPIFunctions";
import { isFailedApiCall } from "../../../../psUtil";
import PowerShadesTypeahead from "../../../../Parts/PowerShadesTypeahead";
import "./AssembliesPartsDropdown.scss";
import { RMAItems } from "./types";

type AssembliesPartsDropdownProps = {
	rma: RMAItems;
	setRma: (RMAItems) => void;
	editing: boolean;
	assemblies: AssemblyStore[];
};

const AssembliesPartsDropdown = ({
	rma,
	setRma,
	editing,
	assemblies,
}: AssembliesPartsDropdownProps) => {
	const { id: rmaId, quoteId, sequenceId, parts } = rma;

	const initialAssembly = assemblies.find((assy) => assy.id === sequenceId);
	const [selectedAssembly, setSelectedAssembly] = useState<AssemblyStore | undefined>(initialAssembly);
	
	const [assemblyParts, setAssemblyParts] = useState<ShadePart[]>([]);
	const [shades, setShades] = useState<ShadePart[]>([]);
	
	const initialParts = assemblyParts
		.concat(shades)
		.filter((part) => parts.some((p) => p.sku === part.sku));
	const [selectedParts, setSelectedParts] = useState<ShadePart[]>(initialParts);

	const [filteredAssemblyParts, setFilteredAssemblyParts] = useState<ShadePart[]>([]);
	const [filteredShades, setFilteredShades] = useState<ShadePart[]>([]);

	const [loadingParts, setLoadingParts] = useState<boolean>(false);
	const [failedPartsCall, setFailedPartsCall] = useState("");
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const dropdownRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	const setPartialRma = (key, value) => {
		setRma({ ...rma, [key]: value });
	};

	const assemblyOptions = assemblies.map((assy: AssemblyStore) => ({
		label: assy.shade_name ?? "",
		value: assy.sequence_id ?? "",
	}));

	const selectPart = (selected: ShadePart) => {
		if (selectedParts.includes(selected)) {
			const newParts = selectedParts.filter((part) => part !== selected);
			const newPartsDto = newParts.map((part) => ({
				sku: part.sku,
				partName: part.name,
				rmaId
			}));
			setSelectedParts(newParts);
			setPartialRma("parts", newPartsDto);
		} else {
			const newSelectedParts = [...selectedParts, selected];
			const newPartsDto = newSelectedParts.map((part) => ({
				sku: part.sku,
				partName: part.name,
				rmaId,
			}));
			setSelectedParts(newSelectedParts);
			setPartialRma("parts", newPartsDto);
		}
		inputRef.current?.focus();
	};

	const clearAllParts = () => {
		setSelectedParts([]);
		setPartialRma("parts", []);
	};

	const filterParts = (items: ShadePart[], query: string) => {
		const lowerCaseQuery = query.toLowerCase();
		return items.filter((item) => item.name.toLowerCase().includes(lowerCaseQuery));
	};

	const sortSelectedFirst = (items: ShadePart[], selectedItems: ShadePart[]) => {
		const selected = items.filter((item) => selectedItems.includes(item));
		const unselected = items.filter((item) => !selectedItems.includes(item));
		return [...selected, ...unselected];
	};

	useEffect(() => {
		!selectedAssembly && editing && setSelectedParts([]);

		if (editing && !failedPartsCall && quoteId && selectedAssembly?.sequence_id && !loadingParts) {
			setLoadingParts(true);
			apiCalls.getShadePartsList(quoteId, selectedAssembly.sequence_id).then((resp) => {
				if (isFailedApiCall(resp)) {
					if (!resp?.data?.success || !resp.data.message) {
						console.error({ resp, data: resp?.data, error: resp?.error, quoteId });
						throw new Error("Failed to get shade parts list.");
					}
					setFailedPartsCall(resp.data.message);
					return;
				}
				const { actual_parts: actualParts } = resp.data;
				if (actualParts && actualParts.length > 0) {
					setShades(actualParts.filter((part) => part.assembly_category === null));
					setAssemblyParts(actualParts.filter((part) => part.assembly_category !== null));
				}
				setLoadingParts(false);
			});
		} else {
			setShades([]);
			setAssemblyParts([]);
		}
	}, [selectedAssembly, editing, failedPartsCall, quoteId]);

	useEffect(() => {
		if (shades.length || assemblyParts.length) {
			setFilteredShades(shades);
			setFilteredAssemblyParts(assemblyParts);
		}
	}, [shades, assemblyParts]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setDropdownOpen(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [dropdownRef]);

	useEffect(() => {
		setSelectedParts(initialParts);
	}, [rma]);

	const PartBubble = ({ part }: { part: ShadePart }) => (
		<div
			className="part-bubble"
			role="button"
			tabIndex={0}
			onClick={() => selectPart(part)}
			onKeyDown={(e) => (e.key === "Enter" ? selectPart(part) : null)}
		>
			{part.name}
			<FontAwesomeIcon icon={faTimes} className="remove-part" onClick={() => selectPart(part)} />
		</div>
	);

	return (
		<>
			<Row>
				{editing ? (
					<Form.Label className="form-label">
						Assembly:&nbsp;
						<PowerShadesTypeahead
							id="assembly-list"
							options={assemblyOptions}
							onChange={(e) => {
								if (e.length) {
									const selected = e[0] as { value: string };
									const assembly = assemblies.find((assy) => assy.sequence_id === +selected.value);
									setSelectedAssembly(assembly);
									setPartialRma("sequenceId", selected.value);
								} else {
									setSelectedAssembly(undefined);
								}
							}}
							selected={selectedAssembly ? [selectedAssembly.shade_name] : [""]}
							labelKey="label"
						/>
					</Form.Label>
				) : (
					<span>
						{`Part${parts.length > 1 ? "s" : ""}: `}
						{parts.length > 3 ? (
							<a className="text-body-emphasis">
								{parts.length}
								{' '}
								Parts
							</a>
						) : (
							parts.map((part) => part.partName).join(", ")
						)}
					</span>

				)}
			</Row>
			{selectedAssembly && !loadingParts && (
				<Row>
					{editing && (shades.length || assemblyParts.length) && (
						<Form.Label className="form-label">
							Part:&nbsp;
							<Dropdown
								ref={dropdownRef}
								show={dropdownOpen}
								onToggle={(isOpen) => {
									setDropdownOpen(isOpen);
									if (isOpen) {
										inputRef.current?.focus();
									}
								}}
								className="dropdown-search"
								autoClose="outside"
							>
								<Dropdown.Toggle className="p-0 border-0 w-100" onMouseDown={(e) => e.preventDefault()}>
									<Form.Control as="div" className="selected-parts-input">
										{selectedParts.length > 3 ? (
											<div
												className="part-bubble summary"
												onClick={clearAllParts}
												role="button"
												tabIndex={0}
												onKeyDown={(e) => (e.key === "Enter" ? clearAllParts() : null)}
											>
												{selectedParts.length}
												{' '}
												Parts Selected
												<FontAwesomeIcon icon={faTimes} className="remove-part" />
											</div>
										) : (
											selectedParts.map((part) => <PartBubble part={part} key={part.sku} />)
										)}
										<input
											ref={inputRef}
											type="text"
											placeholder="Select parts..."
											onChange={(e) => {
												const { value } = e.target;
												setFilteredShades(value.length ? filterParts(shades, value) : shades);
												setFilteredAssemblyParts(value.length ? filterParts(assemblyParts, value) : assemblyParts);
											}}
											onFocus={() => setDropdownOpen(true)}
											className="typeahead-input"
										/>
									</Form.Control>
								</Dropdown.Toggle>
								<Dropdown.Menu className="dropdown-center">
									{filteredShades.length + filteredAssemblyParts.length === 0 && (
										<Dropdown.Header>No Results</Dropdown.Header>
									)}
									{filteredShades.length > 0 && (
										<>
											<Dropdown.Header onClick={(e) => e.preventDefault()}>Assembly Shades</Dropdown.Header>
											{sortSelectedFirst(filteredShades, selectedParts).map((shade) => {
												const isSelected = selectedParts.includes(shade);
												return (
													<Dropdown.Item
														key={shade.sku}
														active={isSelected}
														onClick={() => selectPart(shade)}
													>
														{shade.name}
														{isSelected && <FontAwesomeIcon icon={faCheck} />}
													</Dropdown.Item>
												);
											})}
										</>
									)}
									{filteredAssemblyParts.length > 0 && (
										<>
											<Dropdown.Header onClick={(e) => e.preventDefault()}>Assembly Parts</Dropdown.Header>
											{sortSelectedFirst(filteredAssemblyParts, selectedParts).map((part) => {
												const isSelected = selectedParts.includes(part);
												return (
													<Dropdown.Item
														key={part.sku}
														active={isSelected}
														onClick={() => selectPart(part)}
													>
														{part.name}
														{isSelected && <FontAwesomeIcon icon={faCheck} />}
													</Dropdown.Item>
												);
											})}
										</>
									)}
								</Dropdown.Menu>
							</Dropdown>
						</Form.Label>
					)}
				</Row>
			)}
		</>
	);
};

export default AssembliesPartsDropdown;
